import { NgModule } from '@angular/core';
import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { AngularMaterialModule } from '../lib/angular-material/angular.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { SafePipe } from '../framework/pipes/safe-pipe/safe-pipe.component';
import { PlayerComponent } from '../framework/player/player.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { EventsHistogramComponent } from './events-histogram/events-histogram.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { CameraStatusOverlayComponent } from './camera-status-overlay/camera-status-overlay.component';
import { ThumbnailDialogComponent } from './thumbnail/thumbnail-dialog/thumbnail-dialog.component';
import { PlayerControlsComponent } from './player-controls/player-controls.component';
import { PlayerSeekerComponent } from './player-seeker/player-seeker.component';
import { LineChartComponent } from './charts/analytics/line-chart/line-chart.component';
import { PlaybackPlayerComponent } from './playback-player/playback-player.component';
import { SearchSelectorComponent } from './advanced-search/search-selector/search-selector.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { NgxColorsModule } from 'ngx-colors';
import { UiKitModule } from './ui-kit/ui-kit.module';
import { MsgBoxComponent } from './msg-box/msg-box.component';
import { PlaybackTimelineComponent } from './playback-player/playback-timeline/playback-timeline.component';
import { CameraSelectorComponent } from './camera-selector/camera-selector.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { LocationRowComponent } from './camera-selector/location-row/location-row.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ZoneSelectorWrapperComponent } from './zone-selector-wrapper/zone-selector-wrapper.component';
import { MultiSearchResultsComponent } from './mulit-search-results/multi-search-results.component';
import { PlaybackPlayerDialogComponent } from './playback-player-dialog/playback-player-dialog.component';
import { CameraLiveViewDialogComponent } from './camera-live-view-dialog/camera-live-view-dialog.component';
import { WallCameraComponent } from './wall-camera/wall-camera.component';
import { PropertiesSelectorComponent } from './advanced-search/properties-selector/properties-selector.component';
import { UpdateSoftwareVersionModalComponent } from './modals/update-software-version-modal/update-software-version-modal.component';
import { StreamPlayerComponent } from '../framework/stream-player/stream-player.component';
import { DirectivesModule } from './directives/directives.module';
import { EthDeviceInfoModalComponent } from './modals/eth-device-info-modal/eth-device-info-modal.component';
import { PingAddressModalComponent } from './modals/ping-address-modal/ping-address-modal.component';
import { EthConfigurationModalComponent } from './modals/eth-configuration-modal/eth-configuration-modal.component';
import { DockerInspectModalComponent } from './modals/docker-inspect-modal/docker-inspect-modal.component';
import { NmcliModalComponent } from './modals/nmcli-modal/nmcli-modal.component';
import { DuModalComponent } from './modals/du-modal/du-modal.component';
import { LsModalComponent } from './modals/ls-modal/ls-modal.component';
import { NmcliDeviceShowModalComponent } from './modals/nmcli-device-show-modal/nmcli-device-show-modal.component';
import { SetDateModalComponent } from './modals/set-date-modal/set-date-modal.component';
import { SetTimezoneModalComponent } from './modals/set-timezone-modal/set-timezone-modal.component';
import { SetNtpModalComponent } from './modals/set-ntp-modal/set-ntp-modal.component';
import { EdgeSoftwareVersionModalComponent } from './modals/edge-software-version-modal/edge-software-version-modal.component';
import { UploadVideoStorageModalComponent } from './modals/upload-video-storage-modal/upload-video-storage-modal.component';
import { AddArchiveModalComponent } from './modals/add-archive-modal/add-archive-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { VideoSignatureModalComponent } from './modals/video-signature-modal/video-signature-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Pm2LogsModalComponent } from './modals/pm2-logs-modal/pm2-logs-modal.component';
import { ProbFileModalComponent } from './modals/prob-file-modal/prob-file-modal.component';
import { ObjectAttributesComponent } from './object-attributes/object-attributes.component';
import { AttributeValuesComponent } from './object-attributes/components/attribute-values/attribute-values.component';
import { AttributeRowComponent } from './object-attributes/components/attribute-row/attribute-row.component';
import { ChangeDateFormatModalComponent } from './modals/change-date-format-modal/change-date-format-modal.component';
import { CameraPickerComponent } from './camera-picker/camera-picker.component';
import { ObjectAttributesContentComponent } from './object-attributes/components/object-attributes-content/object-attributes-content.component';
import { WidgetGraphComponent } from './charts/workspace/widget-graph/widget-graph.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ConfirmationCodeModalComponent } from './modals/confirmation-code-modal/confirmation-code-modal.component';
import { InactiveDialogComponent } from './inactive-dialog/inactive-dialog.component';
import { InactivityComponent } from './inactivity/inactivity.component';
import { WebrtcPlayerComponent } from '../framework/webrtc-player/webrtc-player.component';
import { ThumbnailImageComponent } from './thumbnail/thumbnail-image/thumbnail-image.component';
import { ThumbnailLayoutsComponent } from './thumbnail/thumbnail-layouts/thumbnail-layouts.component';
import { ShareAccessComponent } from './modals/share-access/share-access.component';
import { ShowPasswordModalComponent } from './modals/show-password-modal/show-password-modal.component';
import { ShowPeopleModalComponent } from './modals/show-people-modal/show-people-modal.component';
import { GrantedAccessPasswordComponent } from './modals/granted-access-password/granted-access-password.component';
import { PlaybackPlayerSingleComponent } from './playback-player/playback-player-single/playback-player-single.component';
import { ThumbnailLayoutsSelectorComponent } from './thumbnail/thumbnail-layouts/thumbnail-layouts-selector/thumbnail-layouts-selector.component';
import { ViewStructureModalComponent } from './modals/view-structure-modal/view-structure-modal.component';
import { ShowFiltersModalComponent } from './modals/show-filters-modal/show-filters-modal.component';
import { CameraRowComponent } from './camera-selector/camera-row/camera-row.component';
import { InactivityModalComponent } from './modals/inactivity-modal/inactivity-modal.component';
import { SetNameModalComponent } from './modals/set-name-modal/set-name-modal.component';
import { WebrtcPlayerV2Component } from './webrtc-player/webrtc-player-v2.component';
import { WebrtcV2Component } from './webrtc-v2/webrtc-v2.component';
import { VideoPreviewModalComponent } from './modals/video-preview-modal/video-preview-modal.component';
import { CamerasStatsModalComponent } from './modals/cameras-stats-modal/cameras-stats-modal.component';
import { CameraStatRowComponent } from './modals/cameras-stats-modal/components/camera-stat-row/camera-stat-row.component';
import { EdgeStatRowComponent } from './modals/cameras-stats-modal/components/edge-stat-row/edge-stat-row.component';
import { SingleCameraStatsModalComponent } from './modals/single-camera-stats-modal/single-camera-stats-modal.component';
import { MatRippleModule } from '@angular/material/core';
import { ComponentStatusChipComponent } from './component-status-chip/component-status-chip.component';
// import { Ng2TelInputModule } from 'ng2-tel-input';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineSeriesComponent } from './timeline/timeline-series/timeline-series.component';
import { StorageHistogramOldComponent } from './storage-histogram-old/storage-histogram-old.component';
import { UpdateFailedDialogComponent } from './update-failed-dialog/update-failed-dialog.component';
import { StorageHistogramComponent } from './storage-histogram/storage-histogram.component';
import { ThumbnailHistogramComponent } from './thumbnail-histogram/thumbnail-histogram.component';
import { NotificationComponent } from './notification/notification.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RbacOrgAllowDirective } from '../authorization/rbac-org-allow.directive';
import { UiWsVideoPlayerComponent } from './ui-kit/ui-ws-video-player/ui-ws-video-player.component';
import { UiCameraStatusComponent } from './ui-kit/ui-camera-status/ui-camera-status.component';
import { LiveViewCameraComponent } from './live-view-camera/live-view-camera.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { VideoComponent } from './video/video.component';
import { WebrtcComponent } from './video/webrtc/webrtc.component';
import { HlsPlaybackComponent } from './video/hls-playback/hls-playback.component';
import { LivekitComponent } from './video/livekit/livekit.component';
import { HlsLocalComponent } from './video/hls-local/hls-local.component';
import { CameraControlsComponent } from './webrtc-v2/camera-controls/camera-controls.component';
import { HlsPlaybackStandaloneComponent } from './video/hls-playback-standalone/hls-playback-standalone.component';
import {
  WallTileCameraControlsComponent,
} from './webrtc-v2/wall-tile-camera-controls/wall-tile-camera-controls.component';


@NgModule({
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
  declarations: [
    LoaderComponent,
    StatusChipComponent,
    SafePipe,
    PlayerComponent,
    StreamPlayerComponent,
    MenuButtonComponent,
    ThumbnailComponent,
    EventsHistogramComponent,
    SearchResultsComponent,
    MultiSearchResultsComponent,
    CameraStatusOverlayComponent,
    ThumbnailDialogComponent,
    PlayerControlsComponent,
    PlayerSeekerComponent,
    LineChartComponent,
    PlaybackPlayerComponent,
    SearchSelectorComponent,
    AdvancedSearchComponent,
    MsgBoxComponent,
    PlaybackTimelineComponent,
    CameraSelectorComponent,
    LocationRowComponent,
    ZoneSelectorWrapperComponent,
    PlaybackPlayerDialogComponent,
    CameraLiveViewDialogComponent,
    WallCameraComponent,
    PropertiesSelectorComponent,
    UpdateSoftwareVersionModalComponent,
    EthDeviceInfoModalComponent,
    PingAddressModalComponent,
    EthConfigurationModalComponent,
    DockerInspectModalComponent,
    NmcliModalComponent,
    DuModalComponent,
    LsModalComponent,
    NmcliDeviceShowModalComponent,
    SetDateModalComponent,
    SetTimezoneModalComponent,
    SetNtpModalComponent,
    EdgeSoftwareVersionModalComponent,
    UploadVideoStorageModalComponent,
    AddArchiveModalComponent,
    VideoSignatureModalComponent,
    Pm2LogsModalComponent,
    ProbFileModalComponent,
    ObjectAttributesComponent,
    AttributeValuesComponent,
    AttributeRowComponent,
    ChangeDateFormatModalComponent,
    CameraPickerComponent,
    ObjectAttributesContentComponent,
    WidgetGraphComponent,
    ConfirmationCodeModalComponent,
    InactiveDialogComponent,
    InactivityComponent,
    WebrtcPlayerComponent,
    ThumbnailImageComponent,
    ThumbnailLayoutsComponent,
    ShareAccessComponent,
    ShowPasswordModalComponent,
    ShowPeopleModalComponent,
    GrantedAccessPasswordComponent,
    PlaybackPlayerSingleComponent,
    ThumbnailLayoutsSelectorComponent,
    ViewStructureModalComponent,
    ShowFiltersModalComponent,
    InactivityModalComponent,
    CameraRowComponent,
    SetNameModalComponent,
    WebrtcPlayerV2Component,
    WebrtcV2Component,
    VideoPreviewModalComponent,
    CamerasStatsModalComponent,
    CameraStatRowComponent,
    EdgeStatRowComponent,
    SingleCameraStatsModalComponent,
    ComponentStatusChipComponent,
    TimelineComponent,
    TimelineSeriesComponent,
    UpdateFailedDialogComponent,
    StorageHistogramOldComponent,
    StorageHistogramComponent,
    ThumbnailHistogramComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    LiveViewCameraComponent,
    VideoComponent,
    WebrtcComponent,
    HlsPlaybackComponent,
    LivekitComponent,
    HlsLocalComponent,
    CameraControlsComponent,
    HlsPlaybackStandaloneComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    NgxJsonViewerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LazyLoadImageModule,
    NgxColorsModule,
    UiKitModule,
    LetDirective, PushPipe,
    NgScrollbarModule,
    DirectivesModule,
    PipesModule,
    ClipboardModule,
    NgxSkeletonLoaderModule,
    MatRippleModule,
    NgxSliderModule,
    IntlTelInputNgModule, RbacOrgAllowDirective, UiWsVideoPlayerComponent, UiCameraStatusComponent, NgIf, NgOptimizedImage, WallTileCameraControlsComponent,
    // Ng2TelInputModule,
    // NgxSliderModule,

  ],
  exports: [
    CommonModule,
    AngularMaterialModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    NgxJsonViewerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LoaderComponent,
    StatusChipComponent,
    SafePipe,
    PlayerComponent,
    StreamPlayerComponent,
    LazyLoadImageModule,
    MenuButtonComponent,
    ThumbnailComponent,
    SearchResultsComponent,
    MultiSearchResultsComponent,
    CameraStatusOverlayComponent,
    SearchSelectorComponent,
    NgxColorsModule,
    MsgBoxComponent,
    WallCameraComponent,
    CameraSelectorComponent,
    CameraPickerComponent,
    ObjectAttributesComponent,
    ObjectAttributesContentComponent,
    WidgetGraphComponent,
    InactivityComponent,
    WebrtcPlayerComponent,
    ThumbnailLayoutsSelectorComponent,
    ThumbnailLayoutsComponent,
    ThumbnailImageComponent,
    WebrtcPlayerV2Component,
    PlaybackPlayerComponent,
    ComponentStatusChipComponent,
    // Ng2TelInputModule,
    NotificationComponent,
    ConfirmDialogComponent,
    ThumbnailHistogramComponent,
    LiveViewCameraComponent,
    EventsHistogramComponent,
    EventsHistogramComponent,
    TimelineComponent,
    CameraRowComponent,
    HlsPlaybackStandaloneComponent,
  ],
})
export class SharedModule {
}
