import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, shareReplay, Subject, Subscription, take, tap } from 'rxjs';
import { Playback } from '../../cameras/playback.model';
import { PlaybackService } from '../../cameras/playback.service';
import { DEFAULT_DURATION_TIME_IN_SECS, PlaybackPlayerResolutions } from './playback-player.model';
import { concatMap, map } from 'rxjs/operators';
import { CamerasService } from '../../cameras/cameras.service';
import { Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';

const playbackBaseSubjectInitialState: Playback.PlaybackMsgInterfaceBase = {
  cameraId: '',
  edgeId: '',
  locationId: '',
};

@Injectable({
  providedIn: 'root',
})
export class PlaybackPlayerService {
  private clipDuration = 0;

  private playingSubject = new BehaviorSubject<boolean>(false);
  private tagSubject = new BehaviorSubject<number>(new Date().getTime());
  private playbackBaseSubject = new BehaviorSubject<Playback.PlaybackMsgInterfaceBase>(playbackBaseSubjectInitialState);
  private timeSubject = new BehaviorSubject<number>(10);
  private startTimeSubject = new BehaviorSubject<number>(0);

  private streamBaseSubject = new Subject();
  private timelineInitSubject = new Subject<number>();

  public playing$: Observable<boolean> = this.playingSubject.asObservable();
  public time$: Observable<number> = this.timeSubject.asObservable();
  public startTime$: Observable<number> = this.startTimeSubject.asObservable();
  public playbackBase$: Observable<Playback.PlaybackMsgInterfaceBase> = this.playbackBaseSubject.asObservable();

  public streamBase$: Observable<any> = this.streamBaseSubject.asObservable();
  public timelineInit$: Observable<number> = this.timelineInitSubject.asObservable();

  private startSubscription: Subscription;

  constructor(private playbackService: PlaybackService, private camerasService: CamerasService, private store: Store) {
  }

  getTag() {
    return this.tagSubject.value;
  }

  setTag(tag: number) {
    return this.tagSubject.next(tag);
  }

  getSessionId(cameraId: string) {
    const storeSessionId = this.store.select(CameraSelectors.selectCameraPlaybackSessionIdById(cameraId))
      .pipe(take(1));
    const subjectSessionId = this.playbackBaseSubject.getValue().sessionId;

    return storeSessionId || of(subjectSessionId);
  }

  setPlaying(playing: boolean) {
    this.playingSubject.next(playing);
  }

  setStreamBase() {
    this.streamBaseSubject.next(null);
  }

  unsubscribeStreamExistSubscription() {
    if (!!this.startSubscription) {
      this.startSubscription.unsubscribe();
    }
  }

  setStreamExistSubscription(subscription: Subscription) {
    this.startSubscription = subscription;
  }

  isStreamExistSubscriptionSet() {
    return !!this.startSubscription;
  }

  setTime(time: number) {
    this.timeSubject.next(time);
  }

  getTime() {
    return this.timeSubject.value;
  }

  setClipDuration(duration: number) {
    this.clipDuration = duration;
  }

  setStartTime(startTime: number) {
    this.startTimeSubject.next(startTime);
  }

  setPlaybackBase(playbackBase: Playback.PlaybackMsgInterfaceBase) {
    this.playbackBaseSubject.next(playbackBase);
  }

  resetPlaybackBase() {
    this.playbackBaseSubject.next({
      cameraId: '',
      edgeId: '',
      locationId: '',
      sessionId: undefined,
    });
  }

  get currentTs(): number {
    return this.timeSubject.value; //+ this.startTimeSubject.value;
  }

  isDurationOutOfBounds() {
    return this.currentTs + DEFAULT_DURATION_TIME_IN_SECS * 1000 >= this.startTimeSubject.value + this.clipDuration;
  }

  // TODO: encode, encodingParams should be configurable from UI
  startPlayback(ts?: number, isLocal?: boolean, highQuality = false) {

    // this.playingSubject.next(true);

    const base = this.playbackBaseSubject.value;

    const startData: Partial<Playback.PlaybackStartRequest> = {
      encodingParams: {
        bitrate: 0,
        height: 0,
        width: 0,
      },
      encode: false,
      timestamp: Math.floor(ts ? ts : this.currentTs) - 2500, // rewind 2.5 seconds to let the stream start
      duration: DEFAULT_DURATION_TIME_IN_SECS,
      cloudMode: isLocal === undefined ? true : !isLocal,
      highQuality,
    };

    const data: Playback.PlaybackStartRequest = { ...base, ...startData };
    return this.getSessionId(data.cameraId)
      .pipe(
        tap(res => {

          if (!!res) {
            data.sessionId = res;
          }
        }),
        concatMap(() =>

          this.playbackService.playbackStart(data)
            .pipe(
              tap(res => {

                if (!data.sessionId) {
                  this.setSessionId(data.cameraId, res.sessionId);
                }
                return res;
              }),
            ),
        ),
      );
  }

  setSessionId(cameraId: string, sessionId: string) {
    const playbackBase = this.playbackBaseSubject.value;
    this.store.dispatch(
      CameraActions.SetPlaybackSessionId({
        payload: {
          cameraId,
          playbackSessionId: sessionId,
        },
      }),
    );
    // TODO: Might be deprecated - need to remove if so.
    this.playbackBaseSubject.next({ ...playbackBase, sessionId });
  }

  stopPlayback(stopData?: Partial<Playback.PlaybackStopRequest>) {
    this.playingSubject.next(false);
    const base = this.playbackBaseSubject.value;
    const data: Playback.PlaybackStartRequest = { ...base, ...stopData };
    this.playbackService.playbackStop(data)
      .subscribe();
  }

  seek(seekData: Partial<Playback.PlaybackLocationRequest>): Observable<any> {
    const base = this.playbackBaseSubject.value;
    if (!base.sessionId) {
      return of();
    }
    const data: Playback.PlaybackStartRequest = { ...base, ...seekData };
    return this.playbackService.playbackStart(data);
  }

  // rewind(durationInMs: number) {
  //   const base = this.playbackBaseSubject.value;
  //   const timestamp = this.timeSubject.value + durationInMs;
  //   if (!base.sessionId) {
  //     return of();
  //   }
  //   if (this.playingSubject.value) {
  //     return this.playbackService
  //       .playbackLocation({
  //         ...base,
  //         timestamp
  //       })
  //   }
  //   return of();
  // }

  rewind(durationInMs: number) {
    const base = this.playbackBaseSubject.value;
    const timestamp = this.timeSubject.value + durationInMs;
    this.timeSubject.next(timestamp);
    if (!base.sessionId) {
      return of(null);
    }
    // this.playingSubject.next(true);
    if (this.playingSubject.value) {
      return this.startPlayback(timestamp);
    }
    return of(null);
  }

  extend() {
    const base = this.playbackBaseSubject.value;
    return this.getSessionId(base.cameraId)
      .pipe(
        tap(res => {
          if (!!res) {
            base.sessionId = res;
          }
        }),
        concatMap(res => this.store.select(EdgeSelectors.selectLocalById(base.edgeId))
          .pipe(take(1))),
        concatMap((isLocal: boolean) =>
          this.playbackService.playbackExtend({
            ...base,
            duration: DEFAULT_DURATION_TIME_IN_SECS,
            cloudMode: !isLocal,
          }),
        ),
      );
  }

  extendProcess() {
    const base = this.playbackBaseSubject.value;
    this.playbackService
      .playbackExtendProcess({
        ...base,
      })
      .subscribe();
  }

  closePlayback() {
    this.playingSubject.next(false);
    const base = this.playbackBaseSubject.value;
    if (!base.sessionId) {
      return;
    }
    this.playbackService
      .playbackClose(base)
      .pipe(
        shareReplay(),
        tap(_ => this.playbackBaseSubject.next(playbackBaseSubjectInitialState)),
      )
      .subscribe();
  }

  // TODO:
  // exitAfterDone should be false in case the playback is playing
  // encode, encodingParams should be configurable from UI
  download() {
    const base = this.playbackBaseSubject.value;
    this.playbackService
      .playbackDownload({
        ...base,
        timestamp: this.startTimeSubject.value,
        duration: this.clipDuration,
        exitAfterDone: true,
        encodingParams: {
          bitrate: 0,
          height: 0,
          width: 0,
        },
        encode: true,
      })
      .subscribe();
  }

  speed(speed: number) {
    const base = this.playbackBaseSubject.value;
    this.playbackService
      .playbackSpeed({
        ...base,
        speed,
      })
      .subscribe();
  }

  resolution(resolution: PlaybackPlayerResolutions) {
    const base = this.playbackBaseSubject.value;
    let bitrate = 0;
    let height = 0;
    let width = 0;
    switch (resolution) {
      case PlaybackPlayerResolutions.AUTO:
      case PlaybackPlayerResolutions.R144:
      case PlaybackPlayerResolutions.R240:
      case PlaybackPlayerResolutions.R360:
      case PlaybackPlayerResolutions.R480:
      case PlaybackPlayerResolutions.R720:
      case PlaybackPlayerResolutions.R1080:
      default:
        bitrate = 0;
        height = 0;
        width = 0;
        break;
    }

    this.playbackService
      .playbackResolution({
        ...base,
        bitrate,
        height,
        width,
      })
      .subscribe();
  }

  initTimeLine(ts: number) {
    this.timelineInitSubject.next(ts);
  }
}
