import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UiBreadCrumbItem } from '../../../../shared/ui-kit/ui-header/ui-header.component';
import { routerSegments } from '@consts/routes';
import { EdgeCamera } from '../../../../cameras/camera.model';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { bufferTime, map, Observable, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import * as AlertMonitoringSelectors from '@states/alert-monitoring/alert-monitoring.selectors';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { PreloaderColor, UiCalendarPickerType, UIInputStyle } from '@enums/shared.enum';
import { OnRangeSelectedResult } from '../../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiTitlePopupComponent } from '../../../../shared/ui-kit/ui-title-popup/ui-title-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMonitoringViewModel } from '@models/alert-monitoring.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { alertDetectionTypesArray, analyticClassName, DetectionTypeLookup } from '@consts/alert-events.const';
import { AlertEntry } from '../../../../development/alerts.service';
import { LocationModel } from '../../../../locations/location.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SocketEvents } from '../../../../socket/socket.model';
import { ofType } from '@ngrx/effects';
import { SharedActions } from '@states/shared/shared.action-types';
import { SharedEffects } from '@effects/shared.effects';
import { ConfirmDialogType } from '../../../../shared/confirm-dialog/confirm-dialog.model';
import { AlertMonitoringEffect } from '@effects/alert-monitoring.effect';
import { AlertV2TypeGroup } from '@consts/alerts-v2.const';
import { AlertCategory, AlertsV2ShowSettings, CustomizedCapabilitiesType, EventV2Document, FlowLookup, IdentificationType, IntegrationsType, SafetyType, StatusType, TrackingType } from '@models/alerts-v2.model';
import { StepOptionSelectorGroupEntry } from '../../../alerts-v2/components/step-option-selector/step-option-selector.component';
import { features } from '@consts/text.const';
import * as UserSettingsActions from '@states/user-settings/user-settings.actions';
import * as UserSettingsAction from '@states/user-settings/user-settings.actions';
import { UserSettings } from '@models/user-settings';
import * as UserSettingsSelectors from '@states/user-settings/user-settings.selectors';
import { EdgeHeartbeatPulsationSelectors } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.selector-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import { CameraHeartbeatPulsationSelectors } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.selector-types';
import { MatSelectChange } from '@angular/material/select';
import { PermissionModel } from '@models/permission.model';
import { SocketMainService } from 'src/app/socket/socket-main.service';
import { DashboardModel } from '@models/dashboard.model';
import { AlertModel } from '@models/alert.model';
import { alertEventComparator } from '../../../../helpers/comparators';
import { AlertEventComparator } from '../../../../shared/ui-kit/ui-selector/comparators/alert-event-comparator';
import { CameraSelectorModalComponent } from '../../../../modals/camera-selector-modal/camera-selector-modal.component';
import { SelectedCamera } from '@models/alert-events.model';
import { removeSelectedCameraFilter } from '@states/alert-monitoring/alert-monitoring.actions';

@UntilDestroy()
@Component({
  selector: 'app-monitoring-view',
  templateUrl: './monitoring-view.component.html',
  styleUrls: ['./monitoring-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() widgetDataInfo: DashboardModel.AlertLinkObject;

  public noData = features.alertsMonitoring.noData;

  public selectIsLoading$: Observable<boolean> = this.store$.pipe(select(AlertMonitoringSelectors.selectIsLoading));
  public selectEventsIds$: Observable<string[]> = this.store$.pipe(select(AlertMonitoringSelectors.selectSelectedEventIds));

  public selectAutocompleteOptions$: Observable<EventV2Document[]> = this.store$.select(AlertMonitoringSelectors.selectEvents);
  public selectEventsLookup$: Observable<Dictionary<EventV2Document>> = this.store$.select(AlertMonitoringSelectors.selectEventsLookup);

  public selectTrackedObjects$: Observable<AnalyticClasses[]> = this.store$.select(AlertMonitoringSelectors.selectTrackedObjects);
  public selectDateRange$: Observable<OnRangeSelectedResult> = this.store$.select(AlertMonitoringSelectors.selectDateRange);
  public selectedView$: Observable<AlertMonitoringViewModel> = this.store$.select(AlertMonitoringSelectors.selectedView);
  public selectSelectedCameras$: Observable<Dictionary<SelectedCamera>> = this.store$.select(AlertMonitoringSelectors.selectSelectedCameras);
  public selectFlowTypes$: Observable<FlowLookup[]> = this.store$.pipe(select(AlertMonitoringSelectors.selectFlowTypes));
  public selectAcknowledges$: Observable<boolean[]> = this.store$.pipe(select(AlertMonitoringSelectors.selectAcknowledges));
  public selectSelectedUserSettings$: Observable<UserSettings> = this.store$.pipe(select(UserSettingsSelectors.selectSelectedUserSettings));
  public selectEdgeEntities$: Observable<Dictionary<PulsationModels.Store.EdgePulsation>> = this.store$.pipe(select(EdgeHeartbeatPulsationSelectors.selectEdgeEntities));
  //todo replace tt device status
  public selectCameraEntities$: Observable<Dictionary<PulsationModels.Store.CameraPulsation>> = this.store$.pipe(select(CameraHeartbeatPulsationSelectors.selectCameraEntities));
  public selectAllCameras$: Observable<string[]> = this.store$.pipe(select(CameraSelectors.selectAllCamerasIds))
    .pipe(map(ids => ids as string[]));

  public selectCameras$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.select(CameraSelectors.selectCamerasLookup);

  public selectAlertsViewMode$: Observable<AlertEntry[]> = this.store$.select(AlertMonitoringSelectors.selectAlertsViewMode);
  public selectAlertsViewCount$: Observable<number> = this.store$.select(AlertMonitoringSelectors.selectAlertsViewCount);
  public selectAlertsCount$: Observable<number> = this.store$.select(AlertMonitoringSelectors.selectAlertsCount);

  public selectCamerasLookup$: Observable<{
    [key: string]: EdgeCamera.CameraItem;
  }> = this.store$.select(CameraSelectors.selectCamerasLookup);

  public selectLocationLookup$: Observable<{
    [key: string]: LocationModel.LocationItem;
  }> = this.store$.select(LocationSelectors.selectLocationLookup);

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  public analyticClasses = AnalyticClasses;
  public inputStyles = UIInputStyle;
  public preloaderColor = PreloaderColor;
  public itemSize = 343;
  public permissions = PermissionModel.Permissions;
  public analyticClassName = analyticClassName;
  public detectionTypeLookup = DetectionTypeLookup;
  public alertDetectionTypesArray = alertDetectionTypesArray;

  public AlertV2TypeGroup = AlertV2TypeGroup.map(item => item.options)
    .flat();

  public breadCrumb: UiBreadCrumbItem[] = [
    { name: 'Alerts' },
  ];

  public acknowledgeFilter = [
    {
      label: 'Acknowledged',
      value: true,
    },
    {
      label: 'Unacknowledged',
      value: false,
    },
  ];


  public routerSegments = routerSegments;
  private selectedCameras: Dictionary<SelectedCamera>;
  public isScrollVisible: boolean = false;
  public newAlertsCount: number = 0;
  public virtualScrollHeight: number;
  public virtualScrollWidth: number;
  private setInterval;

  public isAbsoluteTime = false;
  public alertEventComparator = alertEventComparator;
  public alertEventSortComparator: AlertEventComparator;

  constructor(private dialog: MatDialog,
              private store$: Store<AppState>,
              private route: ActivatedRoute,
              private socketMainService: SocketMainService,
              private router: Router,
              private sharedEffects$: SharedEffects,
              private alertMonitoringEffects$: AlertMonitoringEffect) {
  }

  public compareFlowTypes(o1: any, o2: any) {
    if (o1.category == o2.category && o1.flowType == o2.flowType)
      return true;
    else return false;
  }


  ngOnInit(): void {
    this.alertEventSortComparator = new AlertEventComparator();
    this.selectAllCameras$
      .subscribe(res => console.log(res));
    if (!!this.widgetDataInfo) {
      this.store$.dispatch(
        AlertMonitoringActions.resetToInitialState(),
      );
      this.store$.dispatch(AlertMonitoringActions.setWidgetDataInfo({ widgetDataInfo: this.widgetDataInfo }));
      this.store$.dispatch(AlertMonitoringActions.getAlertsByFilters());
      return;
    }
    this.sharedEffects$.confirmation$
      .pipe(
        untilDestroyed(this),
        ofType(SharedActions.showConfirmModalResultConfirm),
      )
      .subscribe(result => {
        if (result.params['isAlert']) {
          this.store$.dispatch(
            AlertMonitoringActions.deleteAlert({ id: result.params['id'] }),
          );
        } else if (result.params['isView']) {
          this.store$.dispatch(
            AlertMonitoringActions.deleteView({ id: result.params['id'] }),
          );
        }
      });

    this.alertMonitoringEffects$.getView$
      .pipe(
        untilDestroyed(this),
        ofType(AlertMonitoringActions.getViewFail),
      )
      .subscribe(res => {
        this.router.navigateByUrl(routerSegments.monitoring);
      });

    this.alertMonitoringEffects$.deleteView$
      .pipe(
        untilDestroyed(this),
        ofType(AlertMonitoringActions.deleteViewSuccess),
      )
      .subscribe(res => {
        this.router.navigateByUrl(routerSegments.monitoring);
      });

    this.store$.dispatch(
      AlertMonitoringActions.resetToInitialState(),
    );
    this.store$.dispatch(AlertMonitoringActions.getEventsAutocomplete({ query: '' }));
    this.store$.dispatch(AlertMonitoringActions.getAlertsByFilters());
    if (this.isAbsoluteTime) {
      this.store$.dispatch(AlertMonitoringActions.countAlertsByFilters());
    }

    this.selectSelectedCameras$
      .pipe(untilDestroyed(this))
      .subscribe(cameras => {
        this.selectedCameras = cameras;
      });

    this.route.params
      .subscribe(res => {
        if (!!res['id']) {
          this.breadCrumb = [
            { name: 'Alerts', route: `../` },
            { name: 'Saved Alerts', route: `../../${routerSegments.viewList}` },
          ];
          this.store$.dispatch(
            AlertMonitoringActions.getView({
              id: res['id'],
            }),
          );
        }
      });

    this.selectedView$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.router.navigate(['../', routerSegments.monitoring, routerSegments.view, res._id]);
          this.breadCrumb[2] = { name: res.name };
        }
      });

    this.socketMainService.consume<{ result: AlertEntry[] }>(SocketEvents.edgeIncomingAnalyticAlerts)
      .pipe(
        untilDestroyed(this),
        bufferTime(1500),
        map(([res]) => {
          let contactedResult: AlertEntry[] = [];
          res?.result.forEach(result => {
              contactedResult = contactedResult.concat(result);
            },
          );
          return contactedResult;
        }),
        /**
         * Filter alerts by allowed cameraIds
         */
        withLatestFrom(this.selectAllCameras$),
        map(([alerts, cameraIds]) => {
          const filteredAlertsByCameraIds = alerts.filter(alert => cameraIds.includes(alert?.cameraId) || !alert?.cameraId);
          return AlertModel.filterAlertsByShowSettings(filteredAlertsByCameraIds, AlertsV2ShowSettings.ALERT_PAGE);
        }),
        withLatestFrom(this.store$.pipe(select(AlertMonitoringSelectors.selectSelectedEventIds))),
        map(([alerts, eventIds]) => {
          if (eventIds?.length) {
            return alerts.filter(alert => eventIds.includes(alert.eventId));
          } else {
            return alerts;
          }
        }),
        withLatestFrom(
          this.store$.pipe(select(AlertMonitoringSelectors.selectDateRange)),
          this.store$.pipe(select(AlertMonitoringSelectors.selectAcknowledges)),
        ),
      )
      .subscribe(([alerts, dateRange, acknowledges]) => {
        if (dateRange.type === UiCalendarPickerType.RELATIVE && (acknowledges.includes(false) || !acknowledges.length)) {
          this.newAlertsCount += alerts?.length;
          if (alerts.length) {
            this.store$.dispatch(
              AlertMonitoringActions.newAlertsValidation({
                alerts: alerts,
              }),
            );
          }
        }
      });

    this.setInterval = setInterval(() => {
      this.store$.dispatch(AlertMonitoringActions.refreshAlertsByAutoAcknowledgesAndFrequency());
    }, 1100);

    this.selectDateRange$.pipe(untilDestroyed(this), shareReplay())
      .subscribe((dateRange) => {
        this.isAbsoluteTime = dateRange.type === UiCalendarPickerType.ABSOLUTE;
      });
  }

  public ngAfterViewInit() {
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight;
    this.virtualScrollWidth = this.scrollContainer.nativeElement.offsetWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight;
    this.virtualScrollWidth = this.scrollContainer.nativeElement.offsetWidth;
  }

  public selectCamera(): void {
    this.dialog
      .open(CameraSelectorModalComponent, {
        width: '600px',
        height: '80vh',
        panelClass: 'modal-no-padding',
        disableClose: true,
        data: {
          multi: true,
          selectedCameras: this.selectedCameras,
        },
      })
      .afterClosed()
      .subscribe((cameras: Dictionary<SelectedCamera>) => {
        if (cameras) {
          this.store$.dispatch(AlertMonitoringActions.setSelectedCamera({ selectedCameras: cameras }));
        }
      });
  }

  public multiSelectorChange(ev: EventV2Document[]) {
    this.store$.dispatch(AlertMonitoringActions.setEventIdsFilter({ eventIds: ev.map(ev => ev._id) }));
    this.scrollTo();
  }

  public changeTrackObject(object: MatSelectChange): void {
    this.store$.dispatch(AlertMonitoringActions.setTrackedObjects({ trackedObjects: object.value }));
    this.scrollTo();
  }

  public changeDateRange(event: OnRangeSelectedResult): void {
    this.store$.dispatch(AlertMonitoringActions.setLoader({ isLoading: true }));
    this.store$.dispatch(AlertMonitoringActions.setDateRange({ dateRange: event }));
    if (this.isAbsoluteTime) {
      this.store$.dispatch(AlertMonitoringActions.countAlertsByFilters());
    }
    this.scrollTo();
  }

  public openViewNamePopup(view?: AlertMonitoringViewModel, asNew: boolean = false): void {
    this.dialog
      .open(UiTitlePopupComponent, {
        width: '400px',
        height: '384px',
        panelClass: 'modal-no-padding',
        data: {
          name: view?.name,
          isPublic: view?.isPublic,
        },
      })
      .afterClosed()
      .subscribe((data: { name: string; isPublic: boolean }) => {
        if (data) {
          this.store$.dispatch(
            AlertMonitoringActions.saveView({
              name: data?.name,
              isPublic: data?.isPublic,
              asNew,
            }),
          );
        }
      });
  }

  public openViews() {
    this.router.navigate(['..', routerSegments.monitoring, routerSegments.viewList]);
  }

  public onRemoveCamera(cameraId: string): void {
    this.store$.dispatch(AlertMonitoringActions.removeSelectedCameraFilter({ field: 'selectedCameras', value: cameraId }));
  }

  public onFilterRemoveClick(data: { field: string; value: any }): void {
    this.store$.dispatch(
      AlertMonitoringActions.removeFilter({
        field: data.field,
        value: data.value,
      }),
    );
  }

  public onScroll(ev): void {
    this.isScrollVisible = ev.target.scrollTop > this.itemSize;
    if (!ev.target.scrollTop) {
      this.newAlertsCount = 0;
    }
    if (ev.target.offsetHeight + ev.target.scrollTop + 1 >= ev.target.scrollHeight) {
      this.store$.dispatch(AlertMonitoringActions.nextPageAlertMonitoring());
      this.store$.dispatch(AlertMonitoringActions.getAlertsByFilters());
    }
  }

  public changeDetectionType(object: MatSelectChange): void {
    this.scrollTo();
    this.store$.dispatch(AlertMonitoringActions.setFlowTypes({ flowTypes: object.value }));
  }

  public changeAcknowledges(object: MatSelectChange): void {
    this.scrollTo();
    this.store$.dispatch(AlertMonitoringActions.setAcknowledges({ acknowledges: object.value }));
  }

  public scrollTo() {
  }

  public scrollTop() {
    this.newAlertsCount = 0;
    this.virtualScroll.scrollToIndex(0);
  }

  public trackByIdentityChunk = (index: number, item: any) => index;

  public trackByIdentity = (index: number, item: any) => item;

  public delete(view: AlertMonitoringViewModel) {
    this.store$.dispatch(SharedActions.showConfirmModal({
      options: {
        type: ConfirmDialogType.CONFIRM,
        msg: `Are you sure you want to delete ${view.name}?`,
        title: `Delete`,
        confirm: 'Yes',
        disableClose: true,
        params: {
          id: view._id,
          type: 1,
        },
      },
    }));
  }

  public flowName(category: AlertCategory, flowType: SafetyType | IdentificationType | TrackingType | StatusType | CustomizedCapabilitiesType | IntegrationsType) {
    if ((!category && category !== 0) || (!flowType && flowType !== 0)) {
      return null;
    }
    const options: StepOptionSelectorGroupEntry[] = AlertV2TypeGroup[category]?.options;
    return options?.filter((option) => option?.value?.type === flowType)[0]?.name;
  }

  public goToCreateAlertV2(): void {
    this.router.navigate([routerSegments.alertsV2, routerSegments.templates]);
  }

  public ngOnDestroy() {
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    this.store$.dispatch(AlertMonitoringActions.cancelNetworkRequests());
  }

  public toggleMuteAlertsSounds(isMuted: boolean): void {
    this.store$.dispatch(UserSettingsAction.setUserSettingProperty({ prop: 'alertMonitoringMuted', value: isMuted }));
    this.store$.dispatch(UserSettingsActions.saveSettings());
  }

  protected readonly Object = Object;
}
