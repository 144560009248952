import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { DashboardModel } from '@models/dashboard.model';
import { AlertV2TypeGroupFlat, DataSourceStr, YAxisTrackerClassStr, YAxisTrackerGroupStr, YAxisTrackerGroupTypeAlertsStr, YAxisTrackerGroupTypeStr, YAxisTypeStr } from '@consts/dashboard.const';
import { AlertV2TypeGroup } from '@consts/alerts-v2.const';
import { FlowLookup } from '@models/alerts-v2.model';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { select, Store } from '@ngrx/store';
import { Observable, shareReplay } from 'rxjs';
import { AlertEvent } from '@models/alert-events.model';
import * as AlertEventsSelectors from '@states/alert-events/alert-events.selectors';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ui-alert-option-selector',
  templateUrl: './ui-alert-option-selector.component.html',
  styleUrl: './ui-alert-option-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiAlertOptionSelectorComponent),
      multi: true,
    },
  ],
})
export class UiAlertOptionSelectorComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;

  public selectAlertEvents$: Observable<AlertEvent[]> = this.store$.pipe(select(AlertEventsSelectors.selectAlertEvents))
    .pipe(shareReplay());

  public YAxisTypeStr = YAxisTypeStr;
  public YAxisTrackerGroupType = DashboardModel.YAxisGroupType;
  public YAxisTrackerGroup = DashboardModel.YAxisTrackerGroup;
  public YAxisTrackerGroupTypeStr = YAxisTrackerGroupTypeAlertsStr;
  public YAxisTrackerGroupStr = YAxisTrackerGroupStr;
  public YAxisTrackerClassStr = YAxisTrackerClassStr;
  public AlertV2TypeGroup = AlertV2TypeGroupFlat;

  constructor(
    private store$: Store,
  ) {
    this.form = new FormGroup({
      alertGroupType: new FormControl<DashboardModel.YAxisGroupType>(DashboardModel.YAxisGroupType.All),
      eventId: new FormControl<string[]>([]),
      alertType: new FormControl<FlowLookup[]>([]),
    });

    this.form.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  public get alertGroupType() {
    return YAxisTrackerGroupTypeAlertsStr[this.form.get('alertGroupType').value];
  }

  public get alertType() {
    return this.form.get('alertType').value;
  }

  public get isGroupType() {
    return this.form.get('alertGroupType').value === DashboardModel.YAxisGroupType.Group;
  }

  public get isIndividualType() {
    return this.form.get('alertGroupType').value === DashboardModel.YAxisGroupType.Individual;
  }

  ngOnInit(): void {
    this.store$.dispatch(AlertEventsActions.getAlertEvents());
  }

  onChange = (_: any) => {
  };

  onTouched = () => {
  };


  writeValue(obj: any): void {
    if (!obj) return;
    this.form.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public updateArray(formControlName: string, value: string, isChecked: boolean) {
    const control = this.form.get(formControlName);
    const selectedValues = control.value;
    if (isChecked) {
      control.setValue([...selectedValues, value]);
    } else {
      control.setValue(selectedValues.filter(item => item !== value));
    }
  }

  public isChecked(formControlName: string, value: string): boolean {
    const control = this.form.get(formControlName);
    return control.value.includes(value);
  }

  public isCheckedAlertType(formControlName: string, value: FlowLookup): boolean {
    const control = this.form.get(formControlName);
    return control.value.some(
      (item: FlowLookup) => item.flowType === value.flowType
        && item.category === value.category);
  }

  public updateArrayAlertType(formControlName: string, value: FlowLookup, isChecked: boolean) {
    const control = this.form.get(formControlName);
    const selectedValues = control.value;
    if (isChecked) {
      control.setValue([...selectedValues, value]);
    } else {
      control.setValue(selectedValues.filter(item => item.category !== value.category || item.flowType !== value.flowType));
    }
  }

}
