import { AccessoryType, AgeType, CarryingType, GenderType, LowerBodyType, ObjectToTrack, UpperBodyType } from '@enums/alert-events.enum';

export const SearchSelectorOperators = <SearchSelectorOptions>[
  { name: 'AND', value: 'AND' },
  { name: 'OR', value: 'OR' },
];

// export const SearchSelectorPersonAccessories = <const>[
//   'shirt',
//   'shoes',
//   'hat',
//   'pants',
//   'hair'
// ]

export const forkliftValue = 'forklift';
export const containerValue = 'container';
export const SearchSelectorCarTypes: { name: string; value: string }[] = [
  { name: 'Car', value: 'car' },
  { name: 'Motorcycle', value: 'motorcycle' },
  { name: 'Bus', value: 'bus' },
  { name: 'Truck', value: 'truck' },
  { name: 'Pickup truck', value: 'pickup truck' },
  { name: 'Bicycle', value: 'bicycle' },
  { name: 'Boat', value: 'boat' },
  { name: 'Forklift', value: forkliftValue },
];

// export type SearchSelectorType = typeof SearchSelectorTypes[number];
// export type SearchSelectorPersonAccessory = typeof SearchSelectorPersonAccessories[number];
// export type SearchSelectorOperator = typeof SearchSelectorOperators[number];
//

export interface SearchSelectorOption {
  name: string;
  value: string;
  group?: boolean;
  options?: { name: string; value: string }[];
}

export const SearchSelectorTypes = <SearchSelectorOptions>[
  { name: 'Person', value: ObjectToTrack.Person },
  // {name: 'Cat', value: ObjectToTrack.Cat},
  // {name: 'Dog', value: ObjectToTrack.Dog},
  { name: 'Vehicle', value: ObjectToTrack.Vehicle },
  { name: 'Pet', value: ObjectToTrack.Pet },
];


export type SearchSelectorOptions = SearchSelectorOption[]; // string[];
// typeof SearchSelectorTypes
// | typeof SearchSelectorPersonAccessories
// | typeof SearchSelectorOperators
// | typeof SearchSelectorCarTypes;

export interface SearchSelectorProperty {
  name?: string;
  label?: string;
  type?: 'checkbox' | 'radio' | 'chips';
  options?: SearchSelectorOption[];
  colors?: boolean;
}

export interface SearchSelectorOption {
  name: string;
  value: string;
}

export const SearchSelectorPersonProperties: SearchSelectorProperty[] = [
  {
    name: 'genderType',
    label: 'Gender',
    type: 'checkbox',
    options: Object.entries(GenderType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0], value: e[1] };
        return o;
      }),
    colors: false,
  },
  {
    name: 'ageType',
    label: 'Age',
    type: 'radio',
    options: Object.entries(AgeType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0], value: e[1] };
        return o;
      }),
    colors: false,
  },
  {
    name: 'footwearType',
    label: 'Footwear',
    type: 'chips',
    // options: Object.entries(FootwearType).map(e => {
    //   const o: SearchSelectorOption = {name: e[0], value: e[1]};
    //   return o;
    // }),
    colors: true,
  },
  {
    name: 'lowerbodyType',
    label: 'Lowerbody',
    type: 'chips',
    options: Object.entries(LowerBodyType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0], value: e[1] };
        return o;
      }),
    colors: true,
  },
  {
    name: 'upperbodyType',
    label: 'Upperbody',
    type: 'chips',
    options: Object.entries(UpperBodyType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0].replace('_', ' '), value: e[1] };
        return o;
      }),
    colors: true,
  },
  {
    name: 'hairType',
    label: 'Hair',
    type: 'chips',
    // options: Object.entries(HairType).map(e => {
    //   const o: SearchSelectorOption = {name: e[0], value: e[1]};
    //   return o;
    // }),
    colors: true,
  },
  {
    name: 'accessoryType',
    label: 'Accessory',
    type: 'chips',
    options: Object.entries(AccessoryType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0].replace('_', ' '), value: e[1] };
        return o;
      }),
    colors: false,
  },
  {
    name: 'carryingType',
    label: 'Carrying',
    type: 'chips',
    options: Object.entries(CarryingType)
      .map(e => {
        const o: SearchSelectorOption = { name: e[0].replace('_', ' '), value: e[1] };
        return o;
      }),
    colors: false,
  },
];
