import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

;
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { EdgeService } from '../edge.service';
import { catchError, concatMap, map, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationModel } from '../../locations/location.model';
import { ipAddressExist } from '../validators/ip-address.validator';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { CameraActions } from '@states/camera/camera.action-types';
import { CameraDeviceOnvifInfoInterface, EdgeCamera } from 'src/app/cameras/camera.model';
import { environment } from '../../../environments/environment';

export interface AddCameraData {
  locationId: string;
  edgeId: string;
  cameraId?: string | undefined;
  ipAddress?: string;
  mac?: string;
  edit?: boolean;
  onvifData?: CameraDeviceOnvifInfoInterface;
}

export enum AddCameraStage {
  GET_DETAILS_FORM,
  CREATE_CAMERA_FORM,
}

export interface AddCameraDialogResult {
  cameraId: string;
  name: string;
  description: string;
  mac: string;
  ipAddress: string;
}

@Component({
  selector: 'app-add-camera',
  templateUrl: './add-camera.component.html',
  styleUrls: ['./add-camera.component.scss'],
})
export class AddCameraComponent implements OnInit {
  stage = AddCameraStage.GET_DETAILS_FORM;
  AddCameraStage: typeof AddCameraStage = AddCameraStage;

  form = this.fb.group({
    locationId: ['', Validators.required],
    edgeId: [''],
    cameraId: [''],
    username: ['', Validators.required],
    password: ['', Validators.required],
    ipAddress: [''],
    // ipAddress: ['', {
    //     validators: [Validators.required],
    //     asyncValidators: [ipAddressExist(this.store, this.data.locationId, this.data.edgeId)],
    //     updateOn: 'blur'
    // }],
    macAddress: [''],
    port: ['554', Validators.required],
    width: [0],
    height: [0],
    connectionString: ['/Streaming/Channels/101?transportmode=unicast&profile=Profile_1'],
  });

  img;

  detailsForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', []],
  });
  manual = true;
  // macAddress: string;
  loader = false;
  err;
  testSuccess = false;
  testLoader = false;
  cameraData: EdgeCamera.CameraDocument;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private edgeService: EdgeService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddCameraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCameraData,
  ) {
  }

  ngOnInit(): void {
    this.edgeService.currentEdge$.subscribe(edge => {
      this.form.get('edgeId')
        ?.setValue(edge.edgeId);
    });
    if (this.data) {
      if (this.data.cameraId) {
        this.manual = false;
        this.ipAddress.setValue(this.data.ipAddress);
        this.cameraId.setValue(this.data.cameraId);
        this.macAddress.setValue(<string>this.data.mac);
      }
      if (this.data.edit) {
        this.stage = AddCameraStage.CREATE_CAMERA_FORM;
      }

      this.edgeId.setValue(this.data.edgeId);
      this.locationId.setValue(this.data.locationId);
    }

    this.form.valueChanges.subscribe(() => {
      this.testSuccess = false;
    });
    this.detailsForm.valueChanges.subscribe(() => {
      if (this.manual) {
        this.testSuccess = true;
      }
    });
  }

  createCamera() {
    if (!this.detailsForm.valid) {
      this.detailsForm.markAllAsTouched();
      return;
    }

    if (!this.cameraData) {
      return;
    }

    this.loader = true;

    if (this.manual) {
      if (!this.testSuccess) {
        this.loader = false;
        return;
      }

      const request: LocationModel.AddCameraManuallyToLocationRequest = {
        camera: {
          ...this.cameraData,
          name: this.name.value,
          description: this.description.value,
        },
        edgeId: this.edgeId.value,
        locationId: this.locationId.value,
        defaultSnapshot: this.img,
      };

      this.edgeService
        .addCameraManuallyToLocation(request)
        .pipe(
          concatMap(res =>
            this.edgeService.subscribeToSessionStatus(res.token.session)
              .pipe(
                map(firebase => {
                  return res;
                }),
              ),
          ),
        )
        .subscribe(res => {
          this.edgeService
            .pollCreateCameraManually(res.token.session)
            .pipe(
              catchError(err => {
                this.loader = false;
                const error = JSON.parse(err.message);
                this.err = `${error.msg} [${error.status}]`;
                return throwError(() => err);
              }),
            )
            .subscribe(data => {
              this.store.dispatch(
                CameraActions.CreateLocationEdgeCameraNoBackendCall({
                  request: data.result!,
                }),
              );

              let result: AddCameraDialogResult = {
                cameraId: data.result?.edgeOnly.cameraId!,
                name: this.name.value,
                description: this.description.value,
                mac: this.macAddress.value,
                ipAddress: this.ipAddress.value,
              };

              this.dialogRef.close(result);
            });
        });
    } else {
      const request: LocationModel.AddCameraToLocationRequest = {
        camera: {
          ...this.cameraData,
          name: this.name.value,
          description: this.description.value,
        },
        edgeId: this.edgeId.value,
        locationId: this.locationId.value,
        defaultSnapshot: this.img,
      };

      this.edgeService.addCameraToLocation(request)
        .subscribe(res => {
          this.edgeService
            .pollCreateCamera(res.token.session)
            .pipe(
              catchError(err => {
                this.loader = false;
                const error = JSON.parse(err.message);
                this.err = `${error.msg} [${error.status}]`;
                return throwError(() => err);
              }),
            )
            .subscribe(data => {
              this.store.dispatch(
                CameraActions.CreateLocationEdgeCameraNoBackendCall({
                  request: data.result!,
                }),
              );

              let result: AddCameraDialogResult = {
                cameraId: data.result?.edgeOnly.cameraId!,
                name: this.name.value,
                description: this.description.value,
                mac: this.macAddress.value,
                ipAddress: this.ipAddress.value,
              };
              this.dialogRef.close(result);
            });
        });
    }
  }

  manualTest() {
    this.getCameraDetails(true);
  }

  getCameraDetails(test = false) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.err = null;
    const create = !test;

    const camera = {
      cameraId: this.macAddress?.value,
      macAddress: this.macAddress?.value,
      connectionString: this.connectionString?.value,
      ipAddress: this.ipAddress?.value,
      port: this.port?.value,
      username: this.username.value,
      password: this.password.value,
      profile: 0,
      width: this.width.value,
      height: this.height.value,
      startYear: this.startYear?.value,
      endYear: this.endYear?.value,
      onvifData: this.data.onvifData,
    };

    const getCameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
      locationId: this.locationId.value,
      edgeId: this.edgeId.value,
      camera,
      create,
    };

    if (test) {
      this.testLoader = true;
    } else {
      this.loader = true;
    }

    if (this.manual) {
      this.edgeService
        .getCameraDetailsManually(getCameraDetailsRequest)
        .pipe(
          concatMap(res =>
            this.edgeService.subscribeToSessionStatus(res.token.session)
              .pipe(
                map(firebase => {
                  return res;
                }),
              ),
          ),
        )
        .subscribe(res => {
          this.edgeService
            .pollGetCameraManuallyDetails(res.token.session)
            .pipe(
              catchError(err => {
                this.testLoader = false;
                this.loader = false;
                const error = JSON.parse(err.message);
                this.err = `${error.msg} [${error.status}]`;
                return throwError(() => err);
              }),
            )
            .subscribe(data => {
              this.testLoader = false;
              this.loader = false;
              this.cameraData = data.result?.camera!;
              this.img = `https://snapshots.lumix.ai/${data.result?.snapshotUrl}`;
              this.testSuccess = true;
            });
        });
    } else {
      this.edgeService.getCameraDetails(getCameraDetailsRequest)
        .subscribe(res => {
          this.edgeService
            .pollGetCameraDetails(res.token.session)
            .pipe(
              catchError(err => {
                this.testLoader = false;
                this.loader = false;
                const error = JSON.parse(err.message);
                this.err = `${error.msg} [${error.status}]`;
                return throwError(() => err);
              }),
            )
            .subscribe(data => {
              this.cameraData = data.result?.camera!;
              this.img = `${environment.snapshotsUrl}/${data.result?.snapshotUrl}`;
              this.stage = AddCameraStage.CREATE_CAMERA_FORM;
              this.loader = false;
            });
        });
    }
  }

  save() {
    this.err = null;
    switch (this.stage) {
      case AddCameraStage.GET_DETAILS_FORM:
        if (!this.manual) {
          this.getCameraDetails();
        } else {
          if (this.testSuccess) {
            this.stage = AddCameraStage.CREATE_CAMERA_FORM;
          }
        }
        break;
      case AddCameraStage.CREATE_CAMERA_FORM:
        if (this.data.edit) {
          this.updateCamera();
        } else {
          this.createCamera();
        }
        break;
    }
  }

  updateCamera() {
    if (!this.detailsForm.valid) {
      this.detailsForm.markAllAsTouched();
      return;
    }
    this.loader = true;
    this.cameraData.name = this.name.value;
    this.cameraData.description = this.description.value;
    this.edgeService
      .cameraUpdateNoSession({
        locationId: this.locationId.value,
        edgeId: this.edgeId.value,
        cameraId: this.cameraId.value,
        update: {
          name: this.name.value,
          description: this.description.value,
        },
      })
      .subscribe(_ => {
        this.loader = false;
        let result = {
          added: this.cameraId,
          name: this.name.value,
          description: this.description.value,
          mac: this.macAddress,
          ipAddress: this.ipAddress,
        };
        this.dialogRef.close(result);
      });
  }

  get macAddress(): AbstractControl {
    return this.form.controls['macAddress'];
  }

  get locationId(): AbstractControl {
    return this.form.controls['locationId'];
  }

  get edgeId(): AbstractControl {
    return this.form.controls['edgeId'];
  }

  get cameraId(): AbstractControl {
    return this.form.controls['cameraId'];
  }

  get startYear(): AbstractControl {
    return this.form.controls['startYear'];
  }

  get endYear(): AbstractControl {
    return this.form.controls['endYear'];
  }

  get username(): AbstractControl {
    return this.form.controls['username'];
  }

  get password(): AbstractControl {
    return this.form.controls['password'];
  }

  get connectionString(): AbstractControl {
    return this.form.controls['connectionString'];
  }

  get ipAddress(): AbstractControl {
    return this.form.controls['ipAddress'];
  }

  get port(): AbstractControl {
    return this.form.controls['port'];
  }

  get name(): AbstractControl {
    return this.detailsForm.controls['name'];
  }

  get description(): AbstractControl {
    return this.detailsForm.controls['description'];
  }

  get width(): AbstractControl {
    return this.form.controls['width'];
  }

  get height(): AbstractControl {
    return this.form.controls['height'];
  }
}
