
<ng-container *ngrxLet="selectAllCamerasStatus$; let camerasStatus">
  <ng-container *ngrxLet="selectAllEdgeStatus$; let edgeStatus">
    <div class="cameras-stats-modal">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between">
        {{data.location.name}}
        <mat-icon class="ui-mat-icon-16 close-icon" mat-dialog-close>close</mat-icon>
      </div>
      <div class="modal-body">
        <!--      FILTERS-->
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-select class="ui-selector" [(ngModel)]="isCameraSelected">
            <mat-option [value]="true">
              Cameras
            </mat-option>
            <mat-option [value]="false">
              Core
            </mat-option>
          </mat-select>
          <mat-select class="ui-selector" [(ngModel)]="selectedPeriod" (ngModelChange)="calculateStartEnd()">
            <mat-option *ngFor="let period of periods" [value]="period.value">
              {{period.title}}
            </mat-option>
          </mat-select>
        </div>
        <!--      MAIN STATISTIC-->
        <div fxLayout="row" fxLayoutAlign="space-between center" class="stat-wrapper">
          <div fxFlex="33" class="stat-card" fxLayout="column">
            <span class="title">{{isCameraSelected ? 'Cameras' : 'Core'}}</span>
            <span class="value">{{isCameraSelected ? cameras.length : edges.length}}</span>
          </div>
          <div fxFlex="33" class="stat-card pink" fxLayout="column">
            <span class="title">{{isCameraSelected ? 'Cameras' : 'Core'}} offline</span>
            <span *ngIf="isCameraSelected"
                  class="value"
            >{{data.location | locationCameraStatusTotal: camerasStatus:cameraStatusTypes.NotWorking}}</span>
            <span *ngIf="!isCameraSelected"
                  class="value"
            >{{data.location | locationEdgeStatusTotal: edgeStatus:edgeStatusTypes.NotWorking}}</span>
          </div>
          <div fxFlex="33" class="stat-card green" fxLayout="column">
            <span class="title">{{isCameraSelected ? 'Cameras' : 'Core'}} online</span>
            <span *ngIf="isCameraSelected"
                  class="value"
            >{{data.location | locationCameraStatusTotal: camerasStatus:cameraStatusTypes.Working}}</span>
            <span *ngIf="!isCameraSelected"
                  class="value"
            >{{data.location | locationEdgeStatusTotal: edgeStatus:edgeStatusTypes.Working}}</span>
          </div>
        </div>
        <!--      TIMELINE -->
        <div class="timeline" fxLayout="row">
          <span fxFlex="25"></span>
          <div fxFlex="75" fxLayout="row" fxLayoutAlign="space-between center">
            <ui-user-settings-date [date]="start"
                                   [dateTypeFormat]="format"
            ></ui-user-settings-date>
            <ui-user-settings-date [date]="start + ((end - start) / 4)"
                                   [dateTypeFormat]="format"
            ></ui-user-settings-date>
            <ui-user-settings-date [date]="(start + end) / 2"
                                   [dateTypeFormat]="format"
            ></ui-user-settings-date>
            <ui-user-settings-date [date]="end - ((end - start) / 4)"
                                   [dateTypeFormat]="format"
            ></ui-user-settings-date>
            <ui-user-settings-date [date]="end"
                                   [dateTypeFormat]="format"
            ></ui-user-settings-date>

          </div>
        </div>
        <!--      CAMERA LIST-->
        <div class="camera-list">
          <ng-container *ngIf="isCameraSelected">
            <app-camera-stat-row *ngFor="let camera of cameras"
                                 [camera]="camera"
                                 [bar]="statUptimePeriods[this.selectedPeriod].bar"
                                 [period]="statUptimePeriods[this.selectedPeriod].period"
                                 (click)="openCameraStat(camera)"
                                 [uptime]="this.cameraStats[camera?.edgeOnly?.cameraId]"
                                 [start]="start"
            >

            </app-camera-stat-row>
          </ng-container>
          <ng-container *ngIf="!isCameraSelected">
            <app-edge-stat-row *ngFor="let edge of edges"
                               [edge]="edge"
                               [bar]="statUptimePeriods[this.selectedPeriod].bar"
                               [period]="statUptimePeriods[this.selectedPeriod].period"
            ></app-edge-stat-row>
          </ng-container>
        </div>
      </div>
    </div>
</ng-container>
