import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-dialog',
  templateUrl: './inactive-dialog.component.html',
  styleUrls: ['./inactive-dialog.component.scss']
})
export class InactiveDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
