import { Component, OnInit } from '@angular/core';
import { SearchSelectorOperators, SearchSelectorTypes } from '../advanced-search/search-selector/search-selector.model';
import { SearchSelection } from '@models/search.model';
import { PersonSelectionFormFields, SearchObjectTypes, SearchQueryOperator, VehicleSelectionFormFields } from '@enums/search.enum';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as MultiSearchActions from '@states/multi-search/multi-search.actions';
import { Observable } from 'rxjs';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { SearchService } from '../search.service';
import { CameraEditSelectors } from '@states/camera-edit/camera-edit.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-object-attributes',
  templateUrl: './object-attributes.component.html',
  styleUrls: ['./object-attributes.component.scss'],
})
export class ObjectAttributesComponent implements OnInit {
  public selectObjectSelections$: Observable<any[]> = this.store$.pipe(select(MultiSearchSelectors.selectObjectSelections));

  public selectSearchHighConfidence$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectSearchHighConfidence));

  public selectOuterOperator$: Observable<SearchQueryOperator> = this.store$.pipe(select(MultiSearchSelectors.selectOuterOperator));

  public selectOrgProtectiveGear$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgProtectiveGear));

  public searchSelection: SearchSelection[] = [];
  selectedSelection: UntypedFormGroup;
  outerOperator = SearchQueryOperator.AND;
  SearchSelectorOperators: typeof SearchSelectorOperators = SearchSelectorOperators;
  SearchSelectorTypes: typeof SearchSelectorTypes = SearchSelectorTypes;
  highConfidence = true;
  public objectAttributeTypes: { name: string; value: SearchObjectTypes }[] = [
    {
      name: 'person',
      value: SearchObjectTypes.PERSON,
    },
    {
      name: 'vehicle',
      value: SearchObjectTypes.VEHICLE,
    },
    {
      name: 'pet',
      value: SearchObjectTypes.PET,
    },
  ];

  public searchObjectTypes = SearchObjectTypes;
  public selections: UntypedFormArray;
  public vehicleArray: UntypedFormArray;
  public petArray: UntypedFormArray;

  public selectedIndex: number;
  public selectedObjectType: SearchObjectTypes;

  orgProtectiveGear = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store,
    private searchService: SearchService,
    private dialog: MatDialogRef<ObjectAttributesComponent>,
  ) {
  }

  ngOnInit(): void {
    this.selectOrgProtectiveGear$.pipe(untilDestroyed(this))
      .subscribe((orgProtectiveGear) => {
        this.orgProtectiveGear = orgProtectiveGear;
      });
    this.selections = this.fb.array([]);
    this.vehicleArray = this.fb.array([]);
    this.petArray = this.fb.array([]);
    this.selectObjectSelections$.pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res?.length) {
          this.selections = this.fb.array([]);
          this.vehicleArray = this.fb.array([]);
          this.petArray = this.fb.array([]);
          let selectedForm;

          res.forEach((form, index) => {
            if (form.type === SearchObjectTypes.PERSON) {
              this.loadPersonForm(form);
              selectedForm = this.selections.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.VEHICLE) {
              this.loadVehicleForm(form);
              selectedForm = this.vehicleArray.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.PET) {
              this.loadPetForm(form);
              selectedForm = this.petArray.controls[0] as UntypedFormGroup;
            }
          });
          if (selectedForm.get('type').value === SearchObjectTypes.PERSON) {
            this.select(selectedForm, 0, SearchObjectTypes.PERSON);
          } else if (selectedForm.get('type').value === SearchObjectTypes.VEHICLE) {
            this.select(selectedForm, 0, SearchObjectTypes.VEHICLE);
          }
        }
      });
  }

  private loadPersonForm(form: any) {
    const searchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.initPersonProperties(),
    });
    searchSelection.patchValue(form);
    this.selections.push(searchSelection);
  }

  private loadVehicleForm(form: any) {
    const vehicleSearchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.initVehicleProperties(),
    });
    vehicleSearchSelection.patchValue(form);
    this.vehicleArray.push(vehicleSearchSelection);
  }

  private loadPetForm(form: any) {
    const petSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
    });
    petSelection.patchValue(form);
    this.petArray.push(petSelection);
  }

  private initPersonProperties(): UntypedFormGroup {
    const genderForm = this.fb.group({
      male: [false],
      female: [false],
    });
    const ageForm = this.fb.group({
      kids: [false],
      adults: [false],
    });
    const footWear = this.fb.group({
      colors: [],
    });
    const lowerBody = this.fb.group({
      type: [],
      colors: [],
    });
    const upperBody = this.fb.group({
      type: [],
      colors: [],
    });
    const hair = this.fb.group({
      colors: [],
    });
    const accessory = this.fb.group({
      type: [],
    });
    const carrying = this.fb.group({
      type: [],
    });
    const formGroup = this.fb.group({
      [PersonSelectionFormFields.genderType]: this.fb.group({
        enabled: [false],
        props: genderForm,
      }),
      [PersonSelectionFormFields.ageType]: this.fb.group({
        enabled: [false],
        props: ageForm,
      }),
      [PersonSelectionFormFields.footwearType]: this.fb.group({
        enabled: [false],
        props: footWear,
      }),
      [PersonSelectionFormFields.lowerbodyType]: this.fb.group({
        enabled: [false],
        props: lowerBody,
      }),
      [PersonSelectionFormFields.upperbodyType]: this.fb.group({
        enabled: [false],
        props: upperBody,
      }),
      [PersonSelectionFormFields.hairType]: this.fb.group({
        enabled: [false],
        props: hair,
      }),
      [PersonSelectionFormFields.accessoryType]: this.fb.group({
        enabled: [false],
        props: accessory,
      }),
      [PersonSelectionFormFields.carryingType]: this.fb.group({
        enabled: [false],
        props: carrying,
      }),
    });

    if (this.orgProtectiveGear) {
      const protectiveGear = this.fb.group({
        type: [],
      });
      formGroup.addControl(PersonSelectionFormFields.protectiveGear, this.fb.group({
        enabled: [false],
        beta: true,
        props: protectiveGear,
      }));
    }
    return formGroup;
  }

  private initVehicleProperties(): UntypedFormGroup {
    const additionalProperty = this.fb.group({
      region: [],
      plate: [],
    });

    const container = this.fb.group({
      serialNumber: [],
      sizeCode: [],
      ownerCode: [],
    });

    return this.fb.group({
      [VehicleSelectionFormFields.colors]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.make]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.model]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.type]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.additionalProperty]: this.fb.group({
        enabled: [false],
        props: additionalProperty,
      }),
      [VehicleSelectionFormFields.container]: this.fb.group({
        enabled: [false],
        props: container,
      }),
    });
  }

  public select(selection: UntypedFormGroup, index: number, type: SearchObjectTypes): void {
    this.selectedSelection = selection;
    this.selectedIndex = index;
    this.selectedObjectType = type;
  }

  update(update: { selections: UntypedFormArray; vehicleArray: UntypedFormArray; petArray: UntypedFormArray }) {
    this.selections = update.selections;
    this.vehicleArray = update.vehicleArray;
    this.petArray = update.petArray;
  }

  searchDisabled() {
    return this.selections.length === 0 && this.vehicleArray.length === 0 && this.petArray.length === 0;
  }

  public search() {
    const objectSelections = [...this.selections.value, ...this.vehicleArray.value, ...this.petArray.value];
    this.store$.dispatch(MultiSearchActions.setObjectSelection({ objectSelections }));
    this.searchService.triggerSearch();
    this.dialog.close();
    // this.store$.dispatch(
    //   MultiSearchActions.startSearch(),
    // );
  }

  public highConfidenceChanged(value: boolean) {
    this.store$.dispatch(MultiSearchActions.setHighConfidence({ highConfidence: value }));
  }
}
