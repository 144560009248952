export enum AblyTopics {
  CameraConfiguration,
  CameraDetails,
  CameraDiscovery,
  CameraList,
  CameraStatus,
  CameraThumbnails,
  EdgeConfigFile,
  EdgeDetails,
  EdgeHeartbeat,
  EdgeLogFile,
  EdgeStatus,
  EdgeConfirmation,
  CameraAnalyticResults,
  CameraMotionVectors,
  CameraDelete,
  CameraCreate,
  CameraUpdate,
  EdgeCreate,
  EdgeUpdate,
  EdgeDelete,
  CameraUpload,
  EdgeIp,
  CameraAnalyticAlerts,
  TrainingThumbanils,
  CameraDownloadArchive,
  Manager,
  EdgeInfo,
  EdgeDocument,
  EventCreate,
  EventDelete,
  EventUpdate,
  AnalyticConfig,
  AssetDownload,
  FileList,
  Logs,
  LiveView,
  Playback,
  ProbeVideo,
  LocalWebsite,
  VariableCreate,
  VariableDelete,
  VariableUpdate,
  VariableValueUpdate,
  EdgeSystemStatus,
  VideoStorageFiles,
  ProbeSubstream,
  WebRTCPeer,
  ProperFitting,
  EdgeHeartbeatOnDemand
}

export const AblyTopicsStr = Object.keys(AblyTopics)
  .filter(v => isNaN(Number(v)));
