import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-table-header-order',
  templateUrl: './ui-table-header-order.component.html',
  styleUrls: ['./ui-table-header-order.component.scss'],
})
export class UiTableHeaderOrderComponent implements OnInit {

  @Input() orderBy: string;
  @Input() orderDirection: number;
  @Input() fieldName: string;

  @Output() onOrderChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
