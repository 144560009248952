import { AlertMonitoringSearchFilter } from '@models/alert-monitoring.model';
import { AlertNotificationLogType } from '@enums/alert-monitoring.enum';

export const alertMonitoringSearchFilter: AlertMonitoringSearchFilter = {
  pushAlert: true,
};


export const alertNotificationLogTypeMap: { [Property in AlertNotificationLogType]: string } = {
  [AlertNotificationLogType.EMAIL]: 'Email',
  [AlertNotificationLogType.SMS]: 'SMS',
  [AlertNotificationLogType.MOBILE_PUSH]: 'Mobile Push',
};

export const alertNotificationLogTypes: { label: string, value: AlertNotificationLogType }[] = [
  {
    label: 'Email',
    value: AlertNotificationLogType.EMAIL,
  },
  {
    label: 'SMS',
    value: AlertNotificationLogType.SMS,
  },
  {
    label: 'Mobile Push',
    value: AlertNotificationLogType.MOBILE_PUSH,
  },
];
