import { EdgeCamera } from '../../../cameras/camera.model';
import { createReducer, on } from '@ngrx/store';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { AlertType, AnalyticClasses } from '@enums/alert-events.enum';
import * as moment from 'moment-timezone';
import { AlertMonitoringSearchFilter, AlertMonitoringViewModel } from '@models/alert-monitoring.model';
import { AlertEntry } from '../../../development/alerts.service';
import { alertMonitoringSearchFilter } from '@consts/alert-monitoring.const';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventV2Document, FlowLookup } from '@models/alerts-v2.model';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { DashboardModel } from '@models/dashboard.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';


const dateRangeInitial = {
  start: moment()
    .subtract(1, 'day')
    .toString(),
  end: moment()
    .toString(),
};

export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.minutes, value: 5 } };


export declare interface AlertMonitoringState extends EntityState<AlertEntry> {
  selectedCameras: Dictionary<SelectedCamera>;
  trackedObjects: AnalyticClasses[];
  dateRange: OnRangeSelectedResult;
  views: AlertMonitoringViewModel[];
  selectedView: AlertMonitoringViewModel;
  alerts: AlertEntry[];
  //filters
  query: string;
  flowTypes: FlowLookup[];
  alertTypes: AlertType[];
  paginationTimeStamps: number[];
  autocompleteOptions: string[];
  searchFilters: AlertMonitoringSearchFilter;
  lastViewListTimestamp: number;
  isLastViewListPage: boolean;
  limit: number;
  lastAlertMonitoringTimestamp: number;
  isLastAlertMonitoringPage: boolean;
  limitAlertMonitoringPage: number;
  isLoading: boolean;
  isFirstLoad: boolean;
  acknowledges: boolean[];
  count: number;
  widgetDataInfo: DashboardModel.AlertLinkObject;
  eventIds: string[];
  events: EventV2Document[];
  eventsLookup: { [key: string]: EventV2Document };
}

export const alertAdapter: EntityAdapter<AlertEntry> = createEntityAdapter<AlertEntry>({
  selectId: (alert: AlertEntry) => alert._id,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = alertAdapter.getSelectors();

const initialState: AlertMonitoringState = alertAdapter.getInitialState({
  selectedCameras: {},
  trackedObjects: [],
  dateRange: defaultDateRange,
  views: null,
  selectedView: null,
  alerts: null,
  query: '',
  flowTypes: [],
  alertTypes: [],
  paginationTimeStamps: [],
  autocompleteOptions: [],
  searchFilters: alertMonitoringSearchFilter,
  lastViewListTimestamp: null,
  isLastViewListPage: false,
  limit: 20,
  lastAlertMonitoringTimestamp: null,
  isLastAlertMonitoringPage: false,
  limitAlertMonitoringPage: 100,
  isLoading: true,
  isFirstLoad: false,
  acknowledges: [false],
  count: 0,
  widgetDataInfo: null,
  eventIds: [],
  events: [],
  eventsLookup: {},
});

export const alertMonitoringStateReducer = createReducer(
  initialState,
  on(AlertMonitoringActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlertMonitoringActions.setSelectedCamera, (state, { selectedCameras }) => {
    return {
      ...state,
      selectedCameras: selectedCameras,
    };
  }),
  on(AlertMonitoringActions.resetFilters, state => {
    return {
      ...initialState,
      views: state.views,
    };
  }),
  on(AlertMonitoringActions.setTrackedObjects, (state, { trackedObjects }) => {
    return {
      ...state,
      trackedObjects,
    };
  }),
  on(AlertMonitoringActions.setDateRange, (state, { dateRange }) => {
    return {
      ...state,
      dateRange,
      lastAlertMonitoringTimestamp: initialState.lastAlertMonitoringTimestamp,
      isLastAlertMonitoringPage: initialState.isLastAlertMonitoringPage,
    };
  }),
  on(AlertMonitoringActions.removeFilter, (state, { field, value }) => {
    return {
      ...state,
      [field]: removeFilter(state[field], value, field),
    };
  }),
  on(AlertMonitoringActions.removeSelectedCameraFilter, (state, { field, value }) => {
    return {
      ...state,
      selectedCameras: removeSelectedCameraFilter(state[field], value),
    };
  }),
  on(AlertMonitoringActions.getAlertMonitoringViewsSuccess, (state, { views }) => {
    return {
      ...state,
      views: state.views ? state.views.concat(views) : [...views],
      isLastViewListPage: views.length < initialState.limit,
      isFirstLoad: true,
    };
  }),
  on(AlertMonitoringActions.setSelectedAlertMonitoringView, (state, { selectedView }) => {
    return {
      ...state,
      selectedView,
    };
  }),
  on(AlertMonitoringActions.getAlertsByFiltersSuccess, (state, { alerts }) => {
    const concatenatedAlerts = state.alerts ? state.alerts.concat(alerts) : [...alerts];
    return alertAdapter.addMany(alerts, {
      ...state,
      alerts: concatenatedAlerts,
      isLastAlertMonitoringPage: alerts.length >= state.count,
      isFirstLoad: true,
    });
    // return {
    //   ...state,
    //   alerts: concatenatedAlerts,
    //   isLastAlertMonitoringPage: alerts.length < initialState.limitAlertMonitoringPage,
    //   archivedMonitoring: concatenatedAlerts.filter(alert => !!alert.archivedAt),
    // };
  }),
  on(AlertMonitoringActions.countAlertsByFiltersSuccess, (state, { count }) => {
    return {
      ...state,
      count,
    };
  }),
  on(AlertMonitoringActions.resetAlertsCount, (state, {}) => {
    return {
      ...state,
      count: 0,
    };
  }),
  on(AlertMonitoringActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(AlertMonitoringActions.setWidgetDataInfo, (state, { widgetDataInfo }) => {
    return {
      ...state,
      widgetDataInfo,
    };
  }),
  on(AlertMonitoringActions.setFlowTypes, (state, { flowTypes }) => {
    return {
      ...state,
      flowTypes,
    };
  }),
  on(AlertMonitoringActions.setAlertTypes, (state, { alertTypes }) => {
    return {
      ...state,
      alertTypes,
    };
  }),
  on(AlertMonitoringActions.nextPageAlertMonitoring, state => {
    const latestIndex = state.ids.length - 1;
    const latestId = state.ids[latestIndex];
    return {
      ...state,
      lastAlertMonitoringTimestamp: state.ids && state.ids.length ? state.entities[latestId].timestamp : null,
    };
  }),
  on(AlertMonitoringActions.getAlertsAutocompleteSuccess, (state, { autocompleteOptions }) => {
    return {
      ...state,
      autocompleteOptions,
    };
  }),
  on(AlertMonitoringActions.setEventIdsFilter, (state, { eventIds }) => {
    return {
      ...state,
      eventIds,
    };
  }),
  on(AlertMonitoringActions.setSearchFilter, (state, { prop, value }) => {
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        [prop]: value,
      },
    };
  }),
  on(AlertMonitoringActions.nextPageViewList, state => {
    return {
      ...state,
      lastViewListTimestamp: state.views ? state.views[state.views.length - 1].timestamp : null,
    };
  }),
  on(AlertMonitoringActions.setNewAlerts, (state, { alerts }) => {
    const concatenatedAlerts = [...alerts, ...selectAll(state)];
    return alertAdapter.setAll([...alerts, ...selectAll(state)], {
      ...state,
      alerts: concatenatedAlerts,
    });
  }),
  on(AlertMonitoringActions.archiveAlertSuccess, (state, { id, isArchive }) => {
    const alert = state.entities[id];
    return alertAdapter.updateOne({
      id,
      changes: {
        ...alert,
        //same logic as backend archive alert
        archivedAt: isArchive ? moment()
          .unix() * 1000 : null,
      },
    }, {
      ...state,
    });

  }),
  on(AlertMonitoringActions.deleteAlertSuccess, (state, { id }) => {
    const index = state.alerts.findIndex(alert => alert._id === id);
    const newAlerts = [...state.alerts];
    newAlerts.splice(index, 1);
    return alertAdapter.removeOne(id, {
      ...state,
      alerts: newAlerts,
    });
  }),
  on(AlertMonitoringActions.resetEntities, (state) => {
    return alertAdapter.removeAll({
      ...state,
      lastAlertMonitoringTimestamp: initialState.lastAlertMonitoringTimestamp,
      isLastAlertMonitoringPage: initialState.isLastAlertMonitoringPage,
      isLoading: initialState.isLoading,
      alerts: initialState.alerts,
      count: 0,
    });
  }),
  on(AlertMonitoringActions.setLoader, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(AlertMonitoringActions.resetAlertMonitoringViews, (state) => {
    return {
      ...state,
      views: initialState.views,
      isLastViewListPage: initialState.isLastViewListPage,
    };
  }),
  on(AlertMonitoringActions.setAcknowledges, (state, { acknowledges }) => {
    return {
      ...state,
      acknowledges,
    };
  }),
  on(AlertMonitoringActions.refreshAlertsByAutoAcknowledgesAndFrequency, (state) => {
    const entities: AlertEntry[] = Object.values(state.entities);
    if (state.dateRange.type !== UiCalendarPickerType.RELATIVE) {
      return {
        ...state,
      };
    }
    const frequencyTimer = moment()
      .subtract(state.dateRange.relative.value, state.dateRange.relative.unit)
      .unix() * 1000;

    const acknowledges = state.acknowledges;
    /**
     * Same logic as alert-preview.component.html
     */
    const filteredEntities = entities.filter(entity => {
      return entity.timestamp > frequencyTimer;
    });

    const filteredByAcknowledgeEntities = filteredEntities.filter(entity => {
      if (acknowledges.length === 1) {
        if (acknowledges.includes(true)) {
          return dateLessThanNow(entity);
        }
        if (acknowledges.includes(false)) {
          return !dateLessThanNow(entity);
        }
        throw Error('Not possible case');
      } else {
        return true;
      }
    });
    return alertAdapter.setAll(filteredByAcknowledgeEntities, {
      ...state,
      isLastAlertMonitoringPage: initialState.isLastAlertMonitoringPage,
      lastAlertMonitoringTimestamp: initialState.lastAlertMonitoringTimestamp,
      alerts: filteredByAcknowledgeEntities,
    });
  }),
  on(AlertMonitoringActions.changeDateRangePickerType, (state, { pickerType }) => {
    return {
      ...state,
      dateRange: {
        ...state.dateRange,
        type: pickerType,
      },
    };
  }),
  on(AlertMonitoringActions.getEventsAutocompleteSuccess, (state, { events }) => {
    const eventsLookup = {};
    events.forEach(ev => {
      eventsLookup[ev._id] = ev;
    });
    return {
      ...state,
      events,
      eventsLookup: eventsLookup,
    };
  }),
);

const removeFilter = (filtersArray: AnalyticClasses[] | string[], value: any, field: string): any => {
  const result = [...filtersArray];
  switch (field) {
    case 'trackedObjects':
      const indexTrackedObject = result.findIndex(item => item === value);
      if (indexTrackedObject !== -1) {
        result.splice(indexTrackedObject, 1);
      }
      break;
    case 'alertNamesFilters':
      const indexAlertNamesFilters = result.findIndex(item => item === value);
      if (indexAlertNamesFilters !== -1) {
        result.splice(indexAlertNamesFilters, 1);
      }
      break;
    case 'flowTypes':
      const indexDetectionTypesFilters = result.findIndex(item => item === value);
      if (indexDetectionTypesFilters !== -1) {
        result.splice(indexDetectionTypesFilters, 1);
      }
      break;
    case 'acknowledges':
      const indexAcknowledges = result.findIndex(item => item === value);
      if (indexAcknowledges !== -1) {
        result.splice(indexAcknowledges, 1);
      }
      break;
    case 'eventIds':
      const indexEvents = result.findIndex(item => item === value);
      if (indexEvents !== -1) {
        result.splice(indexEvents, 1);
      }
      break;
    case 'dateRange':
      return dateRangeInitial;
  }
  return result;
};

const removeSelectedCameraFilter = (selectedCameras: Dictionary<SelectedCamera>, value: any) => {
  const cameras = { ...selectedCameras };
  delete cameras[value];
  return cameras;
};

const dateLessThanNow = (alert: AlertEntry): boolean => {
  if (!alert?.archivedAt) {
    return false;
  }
  return alert.archivedAt <= moment()
    .unix() * 1000;
};
