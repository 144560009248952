import { ConfigModels } from '@models/config.model';
import { createReducer, on } from '@ngrx/store';
import * as GeneralActions from '@states/general/general.actions';

export declare interface GeneralState extends ConfigModels.ConfigDocument {}

export const initialState: GeneralState = {
  multiTracker: {
    trackingWindow: 12 * 60 * 60 * 1000,
    trackerThreshold: 0.5,
  },
};

export const generalStateReducer = createReducer(
  initialState,
  on(GeneralActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(GeneralActions.setGeneral, (state, { generalState }) => {
    return {
      ...state,
      ...generalState,
    };
  })
);
