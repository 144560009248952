import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import Hls from 'hls.js';
import { UtilsV2Service } from '../../../services/utils-v2.service';
import * as moment from 'moment-timezone';
import { UiAreaSelection } from '../ui-area-selector/ui-area-selector.component';
import { Point } from '@angular/cdk/drag-drop';

interface ZoomState {
  scale: number;
  initX: number;
  initY: number;
  moveX: number;
  moveY: number;
  boundryX: number;
  boundryY: number;
  dragging: boolean;
}


@Component({
  selector: 'ui-video-player',
  templateUrl: './ui-video-player.component.html',
  styleUrls: ['./ui-video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiVideoPlayerComponent implements OnInit {

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('playerWrapper') playerWrapper: ElementRef;
  @ViewChild('progressBar') progressBar: ElementRef;
  @ViewChild('previewCanvas') previewCanvas: ElementRef;
  @ViewChild('preview') previewWrapper: ElementRef;
  @Input() src: string;

  @Input() cameraName: string = '';
  @Input() locationName: string = '';

  @Input() showTime = false;
  @Input() timestamp: number;

  @Input() public allowZoom = true;
  @Input() public showZoomButtons = true;

  @ViewChild('player', { static: true })
  playerObj: ElementRef;
  video: HTMLVideoElement;

  public isError: boolean = false;
  private hls: Hls;

  seeking = false;
  seekPaused = false;

  public zoomState: ZoomState = {
    scale: 1,
    initX: 0,
    initY: 0,
    moveX: 0,
    moveY: 0,
    boundryX: 0,
    boundryY: 0,
    dragging: false,
  };

  // Additional Flags and declarations
  origHeight = 0;
  origWidth = 0;
  ratio = 0;
  ctx: CanvasRenderingContext2D;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.zoomState.scale > 1 && this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
  }

  constructor(private utilsV2Service: UtilsV2Service, private renderer: Renderer2, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.startHls();
  }

  public get isPlaying(): boolean {
    const video = this.video;
    return !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }

  public timezoneAbbreviation() {
    const userZone = moment()
      .tz(moment.tz.guess())
      .format('z');
    return userZone;
  }

  public takeSnapshot(ts?: number) {
    this.utilsV2Service.takeCameraSnapshot(this.cameraName, this.locationName, this.playerObj, ts);
  }

  public error(ev) {
    this.startHls();
  }

  public loaded(ev) {
  }

  public startHls() {
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    this.video = video;
    try {
      if (Hls.isSupported() && !!this.src) {
        const config = {
          lowLatencyMode: true,
          backBufferLength: 300,
        };

        this.hls = new Hls(config);
        try {
          this.hls.loadSource(this.src);
        } catch (e) {
        }
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, async () => {
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.src;
        video.addEventListener('loadedmetadata', async () => {
        });
      }
    } catch (e) {
    }
  }

  async _seek(event: MouseEvent) {
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    const percent = event.offsetX / this.progressBar.nativeElement.clientWidth;
    video.currentTime = percent * video.duration;
  }

  async clickSeek(event: MouseEvent) {
    await this._seek(event);
  }

  startSeek() {
    this.seeking = true;
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    this.seekPaused = video.paused;
    video.pause();
  }

  stopSeek() {
    this.seeking = false;
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    if (!this.seekPaused) {
      video.play();
    }
  }

  async seek(event: MouseEvent) {
    if (!this.seeking) {
      return;
    }
    await this._seek(event);
  }

  protected readonly alert = alert;

  public resetZoom() {
    this.zoomState.scale = 1;
    this.zoom(undefined, true);
  }

  public initZoom() {
    const elem = this.playerObj.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }


  public zoom(event, renderOnly = false) {
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj.nativeElement;
    if (!renderOnly) {
      if (event.deltaY < 0) {
        if (this.zoomState.scale < 5) {
          this.zoomState.scale += 0.2;
        } else {
          this.zoomState.scale = 5;
        }
        this.onResize();
        this.cd.detectChanges();
      } else {
        if (this.zoomState.scale >= 1.2) {
          this.zoomState.scale -= 0.2;
        } else {
          this.zoomState.scale = 1;
        }
      }
    }
    if (this.zoomState.scale === 1) {
      this.zoomState.moveX = 0;
      this.zoomState.moveY = 0;
      this.zoomState.boundryX = 0;
      this.zoomState.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.zoomState.moveX);
    const moveYDir = Math.sign(this.zoomState.moveY);
    this.zoomState.moveX = Math.abs(this.zoomState.moveX) > this.zoomState.boundryX ? this.zoomState.boundryX * moveXDir : this.zoomState.moveX;
    this.zoomState.moveY = Math.abs(this.zoomState.moveY) > this.zoomState.boundryY ? this.zoomState.boundryY * moveYDir : this.zoomState.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  dragStart(event: MouseEvent) {
    this.zoomState.dragging = true;
    this.zoomState.initX = event.clientX - this.zoomState.moveX;
    this.zoomState.initY = event.clientY - this.zoomState.moveY;
  }

  drag(event: MouseEvent) {
    if (this.zoomState.scale === 1 || !this.zoomState.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    this.zoomState.moveX += Math.abs(this.zoomState.moveX + event.movementX) >= this.zoomState.boundryX ? 0 : event.movementX;
    this.zoomState.moveY += Math.abs(this.zoomState.moveY + event.movementY) >= this.zoomState.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  calcBoundaries() {
    const elem = this.playerObj.nativeElement;
    const height = this.origHeight * this.zoomState.scale;
    const width = this.origWidth * this.zoomState.scale;
    this.zoomState.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.zoomState.scale : 0;
    this.zoomState.boundryY = (height - elem.clientHeight) / 2 / this.zoomState.scale;
  }

  public inZoom() {
    return this.zoomState.scale > 1;
  }

  public zoomIn() {
    this.zoom({ deltaY: -1 });
    this.computeFrame();
  }

  public zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  public zoomArea(area: UiAreaSelection) {
    if (!area?.start?.x || !area?.start?.y || !area?.end?.x || !area?.end?.y) {
      return;
    }
    this.initZoom();
    const wrapper = this.wrapper.nativeElement;
    const topLeft: Point = {
      x: Math.min(area.start.x, area.end.x),
      y: Math.min(area.start.y, area.end.y),
    };
    const width = Math.abs(area.start.x - area.end.x);
    const height = this.ratio * width;
    if (width <= 0 || height <= 0) {
      return;
    }
    this.zoomState.scale = wrapper.clientWidth / width;
    const elem = this.playerObj.nativeElement;

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale})`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    this.zoomState.moveX = this.zoomState.boundryX - topLeft.x;
    this.zoomState.moveY = this.zoomState.boundryY - topLeft.y;

    this.zoom(undefined, true);
  }

  computeFrame() {
    if (this.previewCanvas) {
      this.ctx.drawImage(this.playerObj.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
    }
  }

  async maximize() {
    // this.player.maximize();
    this.resetZoom();
    const elem = this.playerWrapper?.nativeElement;
    if (!this.isFullscreen) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }

    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }
  }

  get isFullscreen() {
    return this.utilsV2Service.isFullscreen();
  }
}
