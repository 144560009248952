import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, share, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { SessionDataAction } from '@enums/session-data.enum';
import { CamerasActions } from '@states/cameras/cameras.action-types';
import { CameraService } from 'src/app/development/camera.service';
import { SearchSelection } from '@models/search.model';
import { SearchObjectTypes } from '@enums/search.enum';
import { AnalyticClasses, ConfigurationFilterType } from '@enums/alert-events.enum';
import { AlertEventsConfigurationFilter } from '@models/alert-events.model';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';
import { LocationModel } from '../../locations/location.model';
import { EdgeService } from '../../edge/edge.service';
import { Camera, CameraCreateStatus, FileCameraCreate, Substream } from '../../cameras/camera.model';
import * as _ from 'lodash';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CameraEditActions } from '@states/camera-edit/camera-edit.action-types';

@Injectable()
export class CameraEffect {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.saveCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressContinue$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.continueCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressBack$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.backCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressRescan$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.rescan), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public setCameraNames$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.setCameraNames), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public getCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getCameraDetails),
      exhaustMap(({ manual, form, multiSession, multiple, index }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        CamerasActions.sendGetCameraDetails({
          manual,
          form,
          multiSession,
          multiple,
          index,
        }),
      ]),
    ),
  );

  public sendGetCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendGetCameraDetails),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ manual, form, multiSession, multiple, index }, { edgeId, locationId, scanData }]) => {
        const cameraObj = {
          ...form,
        };
        if (!!scanData) {
          form = {
            ...form,
            port: scanData.port,
            isOnvif: false,
            onvifData: null,
          };
        }
        const cameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
          edgeId,
          locationId,
          camera: {
            ...form,
          },
          create: !manual,
        };

        return this.edgeService.getCameraDetails(cameraDetailsRequest, manual)
          .pipe(
            mergeMap(res => {
              const actions: any[] = [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendGetCameraDetails,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
              if (!!multiSession) {
                actions.push(
                  CamerasActions.replaceCameraSession({
                    oldSession: multiSession,
                    newSession: res.token.session,
                  }),
                );
              }
              if (!!multiple) {
                actions.push(
                  CamerasActions.setSelectedCameraToken({ index, token: res.token.session }),
                  CamerasActions.setSelectedCameraLoading({ token: res.token.session, loading: true }),
                );
              }
              return actions;
            }),
            catchError(response => {
              return [SharedActions.setIsSessionDataLoading({ isSessionDataLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getMultipleCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getMultipleCameraDetails),
      exhaustMap(({ cameras }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        CamerasActions.sendGetMultipleCameraDetails({
          cameras,
        }),
      ]),
    ),
  );

  public sendGetMultipleCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendGetMultipleCameraDetails),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ cameras }, { edgeId, locationId }]) => {
        const cameraDetailsRequest: LocationModel.GetMultipleCameraDetailsRequest = {
          edgeId,
          locationId,
          cameras,
          create: false,
        };
        return this.edgeService.getMultipleCameraDetails(cameraDetailsRequest)
          .pipe(
            mergeMap(res => {
              const setFileCameraToken = res.map((msgInfo, index) => {
                return CamerasActions.setCameraSession({ index, session: msgInfo.token.session });
              });
              const subscribeToSessions = res.map(msgInfo => {
                return SharedActions.subscribeToSessionStatus({
                  token: msgInfo.token.session,
                  sessionDataAction: SessionDataAction.sendGetMultipleCameraDetails,
                  params: {
                    msTimeout: 40000,
                  },
                });
              });
              return [...setFileCameraToken, ...subscribeToSessions];
            }),
            catchError(response => {
              return [SharedActions.setIsSessionDataLoading({ isSessionDataLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public probeCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.probeCamera),
      exhaustMap(({ connectionString }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        CamerasActions.sendProbeCamera({
          connectionString,
        }),
      ]),
    ),
  );

  public sendProbeCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendProbeCamera),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{ connectionString }, { edgeId, locationId, cameraDetails }]) => {
        const probeCameraRequest: LocationModel.ProbeCameraRequest = {
          connectionString,
          password: cameraDetails.password,
          port: cameraDetails?.networkDetails?.port,
          username: cameraDetails.username,
          edgeId,
          locationId,
          ipAddress: cameraDetails?.networkDetails?.ipAddress,
        };
        return this.edgeService.probeCamera(probeCameraRequest)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.probeCamera,
                  params: {
                    msTimeout: 15000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSessionDataLoading({ isSessionDataLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  /**
   * @deprecated
   * Use startProbeSubStream$ instead
   * This method use wrong store
   */
  public probeSubstream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.probeSubstream),
      exhaustMap(({ action, index }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        CamerasActions.sendProbeSubstream({ action, index }),
      ]),
    ),
  );

  public startProbeSubStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraEditActions.startProbeSubStream),
      exhaustMap(({ action, index }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        CameraEditActions.sendProbeSubStreamV2({ action, index }),
      ]),
    ),
  );

  /**
   * @deprecated
   * This method use wrong store
   */
  public sendProbeSubstream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendProbeSubstream),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{ index, action }, { edgeId, locationId, cameraDetails, substreams }]) => {
        const sub: Substream = substreams[index];
        const probeSubstreamRequest: LocationModel.ProbeSubstreamRequest = {
          connectionString: sub.connectionString,
          action,
          cameraDocument: cameraDetails,
          edgeId,
          locationId,
        };
        return this.edgeService.probeSubstream(probeSubstreamRequest)
          .pipe(
            switchMap(res => {
              return [
                CamerasActions.setSubstreamSession({ index, session: res.token.session }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.probeSubstream,
                  params: {
                    msTimeout: 15000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSessionDataLoading({ isSessionDataLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );


  public sendProbeSubStreamV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraEditActions.sendProbeSubStreamV2),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ index, action }, { selectedCamera, subStreams }]) => {
        if (!selectedCamera) {
          throw Error('Camera is not set');
        }
        const sub: Substream = subStreams[index];
        const probeSubStreamRequest: LocationModel.ProbeSubstreamRequest = {
          connectionString: sub.connectionString,
          action,
          cameraDocument: selectedCamera.edgeOnly,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
        };
        return this.edgeService.probeSubstream(probeSubStreamRequest)
          .pipe(
            switchMap(res => {
              return [
                CameraEditActions.setSubStreamSession({ index, session: res.token.session }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.probeSubstream,
                  params: {
                    msTimeout: 15000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSessionDataLoading({ isSessionDataLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public createCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createCamera),
      exhaustMap(({}) => [
        SharedActions.setIsSaving({ isSaving: true }),
        CamerasActions.sendCreateCamera({})]),
    ),
  );

  public createMultipleCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createMultipleCameras),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      exhaustMap(([{}, { selectedCameras }]) => {
        const actions = [];
        for(let i = 0; i < selectedCameras.length; i++) {
          if (!selectedCameras[i].addState.error && !selectedCameras[i].addState.createSuccess) {
            actions.push(CamerasActions.sendCreateCamera({ index: i }));
            actions.push(CamerasActions.setSelectedCameraLoading({ index: i, loading: true }));
          }
        }
        return [
          SharedActions.setIsSaving({ isSaving: true }),
          ...actions,
        ];
      }),
    ),
  );

  public sendCreateCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendCreateCamera),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ index }, { cameraDetails, edgeId, locationId, snapshotUrl, selectedCameras }]) => {
        let request: LocationModel.AddCameraToLocationRequest;
        if (!!index || index === 0) {
          const camera = selectedCameras[index];
          const addState = camera.addState;
          request = {
            camera: {
              ..._.cloneDeep(addState.cameraDetails),
              autoConfigureMainstream: addState.autoConfigure,
              name: selectedCameras[index].name,
            },
            edgeId,
            locationId,
            defaultSnapshot: addState.snapshot,
          };
          if (addState?.autoConfigure && camera?.addState?.suggestedStreamCapabilities) {
            request.camera.streamCapabilities = camera.addState.suggestedStreamCapabilities;
          }
          if (!addState?.autoConfigure && camera?.addState?.streamCapabilities) {
            request.camera.streamCapabilities = camera.addState.streamCapabilities;
          }
          // if (!addState?.autoConfigure && request?.camera?.subStreams?.length) {
          //   if (request?.camera?.subStreams) {
          //     request.camera.subStreams = [];
          //   }
          //   if (request?.camera?.storageStream?.storageFromSubstream) {
          //     request.camera.storageStream.storageFromSubstream = false;
          //   }
          // }

        } else {
          request = {
            camera: cameraDetails,
            edgeId,
            locationId,
            defaultSnapshot: snapshotUrl,
          };
        }

        return this.edgeService.addCameraToLocation(request)
          .pipe(
            switchMap(res => {
              return [
                CamerasActions.setSelectedCameraToken({ index, token: res.token.session }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.createCamera,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public createCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createCameras),
      exhaustMap(({}) => [SharedActions.setIsSaving({ isSaving: true }), CamerasActions.sendCreateCameras()]),
    ),
  );

  public sendCreateCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendCreateCameras),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{}, { edgeId, locationId, listFromFile }]) => {
        const successCameras = listFromFile
          .filter(camera => !!camera?.success)
          .map(camera => {
            return {
              ...camera,
              loading: false,
              createStatus: CameraCreateStatus.LOADING,
            };
          });
        // Build request cameras
        const request: LocationModel.AddCamerasToLocationRequest = {
          edgeId,
          locationId,
          cameras: successCameras.map(fileCamera => {
            const add: FileCameraCreate = {
              camera: {
                ...fileCamera.camera,
                name: fileCamera.name,
                description: fileCamera.description,
              },
              defaultSnapshot: fileCamera.snapshotUrl,
            };
            return add;
          }),
        };

        return this.edgeService.addCamerasToLocation(request)
          .pipe(
            mergeMap(res => {
              const setFileCameraToken = res.map((msgInfo, index) => {
                return CamerasActions.setCameraSession({ index, session: msgInfo.token.session, reset: false });
              });
              const subscribeToSessions = res.map(msgInfo => {
                return SharedActions.subscribeToSessionStatus({
                  token: msgInfo.token.session,
                  sessionDataAction: SessionDataAction.createCamera,
                  params: {
                    msTimeout: 40000,
                  },
                });
              });
              return [CamerasActions.setListFromFile({ listFromFile: successCameras }), ...setFileCameraToken, ...subscribeToSessions];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getCameras),
      withLatestFrom(
        this.store$.pipe(select(CameraSelectors.selectAllCameras)),
        this.store$.pipe(select(state => state.camerasState)),
        // this.store$.pipe(select(state => state.cameraStatusState))
      ),
      switchMap(([, existingCameras, { edgeId, scanData, scanType, selectedCameraCreateType }]) => {
        return this.cameraService.discoverCameras(edgeId, scanType, selectedCameraCreateType, scanData)
          .pipe(
            switchMap((res: Camera[]) => {
              res.forEach(camera => {
                const idx = existingCameras.findIndex(cam => cam?.edgeOnly?.networkDetails?.ipAddress === camera.ipAddress);
                if (idx > -1) {
                  camera.name = existingCameras[idx].edgeOnly.name;
                  // camera.status = camerasStatus[existing[idx].edgeOnly.cameraId].cameraStatus;
                }
              });
              return [
                // CamerasActions.setCameras({ cameras: res }),
                // CamerasActions.setTotalItemsCount({ totalItemsCount: res.length }),
                // SharedActions.setIsLoading({ isLoading: false }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  private camerasCreateMenuLevel2: any;

  public loadLevel3Menu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.loadLevel3Menu),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{ copy }, { selectedCameraCreateType }]) => {
        switch (selectedCameraCreateType) {
          default:
            return [SharedActions.doNothing()];
        }
      }),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private cameraService: CameraService,
    private edgeService: EdgeService,
  ) {
  }
}

const typeFieldToColorField = (name: string) => {
  return name.replace('Type', 'Color');
};

const filtersAdapter = (searchSelections: SearchSelection[]): any => {
  let result: AlertEventsConfigurationFilter[] = searchSelections.map(searchSelection => {
    const configFilter: AlertEventsConfigurationFilter = {
      accessoryType: [],
      ageType: [],
      carryingType: [],
      colors: [],
      footwearColor: [],
      footwearType: [],
      genderType: [],
      greenList: '',
      hairColor: [],
      hairType: [],
      lowerbodyColor: [],
      lowerbodyType: [],
      make: [],
      model: [],
      redList: '',
      type: [],
      unrecognized: false,
      upperbodyColor: [],
      upperbodyType: [],
    };
    for(let property of Object.values(searchSelection.properties)) {
      if (searchSelection.type === SearchObjectTypes.PERSON) {
        if (!!property.enabled && !!property?.value?.length) {
          const propsToLower = property.value?.map(elem => elem.toLowerCase());
          configFilter[property.name] = propsToLower;
        }
        if (!!property.enabled && !!property.colors?.length) {
          const colorPropertyName = typeFieldToColorField(property.name);
          configFilter[colorPropertyName] = property.colors;
        }
      } else {
        for(let key of Object.keys(searchSelection.properties)) {
          configFilter[key] = searchSelection.properties[key];
        }
      }
    }
    return configFilter;
  });
  return result;
};

const loadConfigurationFilters = (state: AlertEventsState): any => {
  switch (state.trackedObject) {
    case AnalyticClasses.person:
      return {
        ageType: state.ageType,
        carryingType: state.carryingType,
        lowerbodyType: state.lowerBodyType,
        upperbodyType: state.upperBodyType,
        accessoryType: state.accessoryType,
        footwearType: state.footWearType,
        hairType: state.hairType,
        genderType: state.genderType,
        upperbodyColor: state.upperBodyColor,
        lowerbodyColor: state.lowerBodyColor,
        hairColor: state.hairColor,
        footwearColor: state.footWearColor,
      };
    case AnalyticClasses.vehicle:
      if (state.configurationFilterType === ConfigurationFilterType.specificAttributes) {
        return {
          make: state.carMake,
          model: state.carModel,
          type: state.carType,
          colors: state.carColor,
        };
      } else if (state.configurationFilterType === ConfigurationFilterType.licencePlate) {
        return {
          greenList: state.greenList,
          redList: state.redList,
          unrecognized: state.unrecognized,
        };
      }
      return {};
  }
};
