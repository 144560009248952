<ng-container *ngIf="!inline">
  <div
    [class.disabled]="disabled"
    (click)="toggleDialog()"
    #selectorInput
    class="date-range-wrapper"
    [class.single]="single"
    [class.no-label]="inputStyle === inputStyles.noLabel || inputStyle === inputStyles.noLabelThick"
    [class.no-label-thick]="inputStyle === inputStyles.noLabelThick"
    matRipple
  >
    <label *ngIf="inputStyle !== inputStyles.noLabel && inputStyle !== inputStyles.noLabelThick"
           class="label"
    >{{ label }}</label>
    <div *ngIf="!single" class="time-range">
      <span і class="from-to">From:</span> {{ startDateView }} - <span class="from-to">To:</span> {{ endDateView }}
    </div>
    <div *ngIf="single" class="time-range p-r">
      <span class="ui-icon-calendar icon" *ngIf="icon"></span>
      <ng-container *ngIf="showCurrentTime; else showSelected">
        {{ currentTime }} {{ timezoneAbbreviation() }}
      </ng-container>
      <ng-template #showSelected>
        {{ startDateView }} {{ timezoneAbbreviation() }}
      </ng-template>
    </div>
  </div>

  <ng-template #pickerTemplate hasBackdrop="false">
    <ng-container #dialogContainer *ngIf="opened">
      <ng-container *ngIf="single; else notSingle">

        <div class="p-20">
          <ui-date-time-header
            [activeStartDate]="startDate"
            [calendarLeft]="leftCalendar"
          >
          </ui-date-time-header>
          <div fxLayout="row">
            <div fxLayout="column" class="calendar-block single">
              <mat-calendar
                [headerComponent]="headerComponent"
                #leftCalendar
                [selected]="startDate"
                [dateClass]="dateClass"
                [maxDate]="now"
                (selectedChange)="dateChanged($event)"
              >
              </mat-calendar>
              <div fxLayout="row" fxLayoutAlign="center center">
                <ng-container *ngIf="limitStart && isStartToday">
                  <input class="time-input-single" type="time" [(ngModel)]="startTime"/>
                </ng-container>
                <ng-container *ngIf="!(limitStart && isStartToday)">
                  <input class="time-input-single" type="time" [(ngModel)]="startTime"/>
                </ng-container>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" class="picker-actions single">
            <button class="close-btn" mat-button (click)="dialogRef.close()">Cancel</button>
            <button mat-flat-button color="primary" (click)="emitDate()"
                    [disabled]="startDate === null || (isStartToday && futureHour)"
            >Done
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #notSingle>
        <div fxLayout="column">
          <!--        CHECK IF RELATIVE AVAILABLE-->
          <div *ngIf="isRelative" fxlayout="row" fxLayoutAlign="space-between center" class="type-selector-wrapper">
            <div fxLayoutAlign="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="type-selector">
              <div
                [class.selected]="pickerState.pickerType == PickerType.RELATIVE"
                (click)="changeType(PickerType.RELATIVE); $event.stopPropagation()"
              >
                Relative
              </div>
              <div
                (click)="changeType(PickerType.ABSOLUTE); $event.stopPropagation()"
                [class.selected]="pickerState.pickerType == PickerType.ABSOLUTE"
              >
                Calendar
              </div>
            </div>
            <div class="type-selector-timezone">{{ timezoneAbbreviation() }}</div>
          </div>

          <ng-container *ngIf="pickerState.pickerType == PickerType.RELATIVE">
            <div fxLayout="column" fxLayoutGap="10px" class="relative-wrapper">
              <div fxLayout="column" fxLayoutGap="10px">
                <!--              <div fxLayoutAlign="start center">-->
                <!--                <mat-radio-button color="primary" [checked]="!custom"-->
                <!--                                  (click)="custom = false">-->
                <!--                  Set predefined-->
                <!--                </mat-radio-button>-->
                <!--              </div>-->
                <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
                  <div>Minutes</div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let minute of minuteOptions; let idx = index"
                      (click)="selectMinutes(idx)"
                      [class.selected]="idx === pickerState?.minutes"
                      fxLayoutAlign="center center"
                    >
                      {{ minute }}
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
                  <div>Hours</div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let hour of hourOptions; let idx = index"
                      (click)="selectHours(idx)"
                      [class.selected]="idx === pickerState?.hours"
                      fxLayoutAlign="center center"
                    >
                      {{ hour }}
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
                  <div>Days</div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let day of dayOptions; let idx = index"
                      (click)="selectDays(idx)"
                      [class.selected]="idx === pickerState?.days"
                      fxLayoutAlign="center center"
                    >
                      {{ day }}
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
                  <div>Weeks</div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let week of weekOptions; let idx = index"
                      (click)="selectWeeks(idx)"
                      [class.selected]="idx === pickerState?.weeks"
                      fxLayoutAlign="center center"
                    >
                      {{ week }}
                    </div>
                  </div>
                </div>
                <!--              <div fxLayoutAlign="start center">-->
                <!--                <mat-radio-button color="primary" [checked]="custom"-->
                <!--                                  (click)="custom = true">-->
                <!--                  Set custom-->
                <!--                </mat-radio-button>-->
                <!--              </div>-->
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="narrow-input">
                  <mat-form-field appearance="outline" fxFlex="">
                    <input matInput placeholder="180" [(ngModel)]="pickerState.custom" (ngModelChange)="setCustom()"/>
                  </mat-form-field>
                  <mat-select [(ngModel)]="pickerState.customUnit" class="ui-selector">
                    <mat-option *ngFor="let unit of customUnits" [value]="unit">
                      {{ unit | titlecase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="pickerState.pickerType == PickerType.ABSOLUTE">
            <div class="p-20">
              <ui-date-time-header
                [activeStartDate]="startDate"
                [activeEndDate]="endDate"
                [calendarLeft]="leftCalendar"
                [calendarRight]="rightCalendar"
              >
              </ui-date-time-header>
              <div fxLayout="row">
                <div fxLayout="column" class="calendar-block">
                  <mat-calendar
                    [headerComponent]="headerComponent"
                    #leftCalendar
                    [startAt]="startDateLeftCalendar"
                    [dateClass]="dateClass"
                    [maxDate]="now"
                    (selectedChange)="dateChanged($event)"
                  >
                  </mat-calendar>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <input class="time-input" type="time" [(ngModel)]="startTime"/>
                  </div>
                </div>
                <div fxLayout="column" class="calendar-block">
                  <mat-calendar
                    [headerComponent]="headerComponent"
                    #rightCalendar
                    [maxDate]="now"
                    [dateClass]="dateClass"
                    [startAt]="startDateRightCalendar"
                    (selectedChange)="dateChanged($event)"
                  >
                  </mat-calendar>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <input class="time-input" type="time" [(ngModel)]="endTime" (ngModelChange)="validateTime($event)"/>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div fxLayout="row" fxLayoutAlign="end center" class="picker-actions">
            <button class="close-btn" mat-button (click)="dialogRef.close()">Cancel</button>
            <button mat-flat-button color="primary" (click)="emitDate()"
                    [disabled]="startDate == null || endDate == null"
            >Done
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>

<ng-container *ngIf="inline">
  <ng-container #dialogContainer>
    <div fxLayout="column">
      <!--        CHECK IF RELATIVE AVAILABLE-->
      <div *ngIf="isRelative" fxlayout="row" fxLayoutAlign="space-between center" class="type-selector-wrapper">
        <div fxLayoutAlign="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="type-selector">
          <div
            [class.selected]="pickerState.pickerType == PickerType.RELATIVE"
            (click)="changeType(PickerType.RELATIVE); $event.stopPropagation()"
          >
            Relative
          </div>
          <div
            (click)="changeType(PickerType.ABSOLUTE); $event.stopPropagation()"
            [class.selected]="pickerState.pickerType == PickerType.ABSOLUTE"
          >
            Calendar
          </div>
        </div>
        <div class="type-selector-timezone">{{ timezoneAbbreviation() }}</div>
      </div>

      <ng-container *ngIf="pickerState.pickerType == PickerType.RELATIVE">
        <div fxLayout="column" fxLayoutGap="10px" class="relative-wrapper">
          <div fxLayout="column" fxLayoutGap="10px">
            <!--              <div fxLayoutAlign="start center">-->
            <!--                <mat-radio-button color="primary" [checked]="!custom"-->
            <!--                                  (click)="custom = false">-->
            <!--                  Set predefined-->
            <!--                </mat-radio-button>-->
            <!--              </div>-->
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
              <div>Minutes</div>
              <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                <div
                  *ngFor="let minute of minuteOptions; let idx = index"
                  (click)="selectMinutes(idx)"
                  [class.selected]="idx === pickerState?.minutes"
                  fxLayoutAlign="center center"
                >
                  {{ minute }}
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
              <div>Hours</div>
              <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                <div
                  *ngFor="let hour of hourOptions; let idx = index"
                  (click)="selectHours(idx)"
                  [class.selected]="idx === pickerState?.hours"
                  fxLayoutAlign="center center"
                >
                  {{ hour }}
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
              <div>Days</div>
              <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                <div
                  *ngFor="let day of dayOptions; let idx = index"
                  (click)="selectDays(idx)"
                  [class.selected]="idx === pickerState?.days"
                  fxLayoutAlign="center center"
                >
                  {{ day }}
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers-wrapper">
              <div>Weeks</div>
              <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                <div
                  *ngFor="let week of weekOptions; let idx = index"
                  (click)="selectWeeks(idx)"
                  [class.selected]="idx === pickerState?.weeks"
                  fxLayoutAlign="center center"
                >
                  {{ week }}
                </div>
              </div>
            </div>
            <!--              <div fxLayoutAlign="start center">-->
            <!--                <mat-radio-button color="primary" [checked]="custom"-->
            <!--                                  (click)="custom = true">-->
            <!--                  Set custom-->
            <!--                </mat-radio-button>-->
            <!--              </div>-->
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="narrow-input">
              <mat-form-field appearance="outline" fxFlex="">
                <input matInput placeholder="180" [(ngModel)]="pickerState.custom" (ngModelChange)="setCustom()"/>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="">
                <mat-select [(ngModel)]="pickerState.customUnit" class="ui-selector">
                  <mat-option *ngFor="let unit of customUnits" [value]="unit">
                    {{ unit | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="pickerState.pickerType == PickerType.ABSOLUTE">
        <div class="p-20">
          <ui-date-time-header
            [activeStartDate]="startDate"
            [activeEndDate]="endDate"
            [calendarLeft]="leftCalendar"
            [calendarRight]="rightCalendar"
          >
          </ui-date-time-header>
          <div fxLayout="row">
            <div fxLayout="column" class="calendar-block">
              <mat-calendar
                [headerComponent]="headerComponent"
                #leftCalendar
                [startAt]="startDateLeftCalendar"
                [maxDate]="now"
                [dateClass]="dateClass"
                (selectedChange)="dateChanged($event)"
              >
              </mat-calendar>
              <div fxLayout="row" fxLayoutAlign="center center">
                <input class="time-input" type="time" [(ngModel)]="startTime"/>
              </div>
            </div>
            <div fxLayout="column" class="calendar-block">
              <mat-calendar
                [headerComponent]="headerComponent"
                #rightCalendar
                [maxDate]="now"
                [dateClass]="dateClass"
                [startAt]="startDateRightCalendar"
                (selectedChange)="dateChanged($event)"
              >
              </mat-calendar>
              <div fxLayout="row" fxLayoutAlign="center center">
                <input class="time-input" type="time" [(ngModel)]="endTime" (ngModelChange)="validateTime($event)"/>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
