<div class="location-module">
  <ui-header [breadCrumbs]="breadCrumb" *ngrxLet="viewType$; let viewType">
    <div class="flex-row gap-8 header-actions">
      @switch (viewType) {
        @case (ViewType.List) {
          <span class="ui-icon-list-view ui-mat-icon-button-16"
                [matMenuTriggerFor]="menu"
                matTooltip="List view"
                matTooltipClass="sidebar-tooltip"
          ></span>
        }
        @case (ViewType.Floors) {
          <span class="ui-icon-floor-view ui-mat-icon-button-16"
                [matMenuTriggerFor]="menu"
                matTooltip="Floor plan view"
                matTooltipClass="sidebar-tooltip"
          ></span>
        }
        @case (ViewType.Map) {
          <span class="ui-icon-map-view ui-mat-icon-button-16"
                [matMenuTriggerFor]="menu"
                matTooltip="Map view"
                matTooltipClass="sidebar-tooltip"
          ></span>
        }
      }
      <!--      <mat-select [value]="viewType"-->
      <!--                  placeholder="Type"-->
      <!--                  (selectionChange)="locationViewChange($event)"-->

      <!--      >-->
      <!--        <mat-select-trigger>-->
      <!--      -->
      <!--        </mat-select-trigger>-->
      <!--        <mat-option [value]="ViewType.List">List</mat-option>-->
      <!--        <mat-option [value]="ViewType.Floors">Floors</mat-option>-->
      <!--        <mat-option [value]="ViewType.Map">Map</mat-option>-->
      <!--      </mat-select>-->

      <mat-menu #menu="matMenu">
        <button mat-menu-item [class.menu-selected]="viewType === ViewType.List" (click)="setView(ViewType.List)">List
        </button>
        <button mat-menu-item [class.menu-selected]="viewType === ViewType.Floors" (click)="setView(ViewType.Floors)">
          Floors
        </button>
        <button mat-menu-item [class.menu-selected]="viewType === ViewType.Map" (click)="setView(ViewType.Map)">Map
        </button>
      </mat-menu>
      <span class="ui-icon-add-location ui-mat-icon-button-16"
            *ngIf="(isLocationCreatePermission | async) && viewType === ViewType.List"
            (click)="addLocation()"
            matTooltip="Add location"
            matTooltipClass="sidebar-tooltip"
      ></span>
    </div>
  </ui-header>
  <div class="w-100 h-100 router-container">
    <router-outlet></router-outlet>
  </div>
</div>
