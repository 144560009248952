import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ping-address-modal',
  templateUrl: './ping-address-modal.component.html',
  styleUrls: ['./ping-address-modal.component.scss'],
})
export class PingAddressModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public pingAddress: string;

  constructor(private matDialogRef: MatDialogRef<PingAddressModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.pingAddress);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
