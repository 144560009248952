import { createAction, props } from '@ngrx/store';
import { Organization } from '@models/organization.model';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';

export const resetToInitialState = createAction('[DEV TEAM] Reset to Initial state');

export const getUsers = createAction('[DEV TEAM] Get Users');

export const getUsersSuccess = createAction('[DEV TEAM] Get Users Success', props<{ users: any[] }>());

export const getOrganizations = createAction('[DEV TEAM] Get Organizations');

export const getOrganizationsSuccess = createAction(
  '[DEV TEAM] Get Organizations Success',
  props<{ organizations: { [key: string]: Organization } }>(),
);

export const setFilter = createAction('[DEV TEAM] Set Filter', props<{ property: string; value: any }>());

export const setDateRange = createAction('[DEV TEAM] Set Date Range', props<{ dateRange: OnRangeSelectedResult }>());

export const setPagination = createAction('[DEV TEAM] Set Pagination', props<{ page: number; perPage: number }>());

export const setTotalItemsCount = createAction('[DEV TEAM] Set Total Items Count', props<{ totalItemsCount: number }>());
