<div fxLayout="column" fxLayoutAlign="start start" fxFill class="ui-vehicle-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{data?.vehicle ? 'Seen' : 'Add manually'}}</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div [formGroup]="form"
       class="dialog-content narrower-input gap-16"
  >
    <div class="description">Complete vehicle’s validation. You may update the vehicles details.</div>
    <div class="flex-col gap-8 w-100">
      <div>Car owner's name</div>
      <input class="ui-input" formControlName="owner" placeholder="Type name">
    </div>
    <div class="flex-col gap-8 w-100">
      <div>License plate</div>
      <input class="ui-input" formControlName="plate" placeholder="Type license plate">
    </div>
    <div class="flex-col gap-8 w-100">
      <div>Vehicle make</div>
      <input class="ui-input" formControlName="make" placeholder="Type vehicle make">
    </div>
    <div class="flex-col gap-8 w-100">
      <div>Vehicle color</div>
      <input class="ui-input" formControlName="color" placeholder="Type vehicle color">
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button type="primary" text="Add" (clickAction)="add()"
    ></ui-button>
    <ui-preloader [color]="PreloaderColor.blue" [diameter]="20" *ngIf="loading"></ui-preloader>
  </div>

</div>
