import { createAction, props } from '@ngrx/store';
import { ApiKeyModel } from '@models/api-key.models';
import { ApiKeyDto } from '@models/organization.model';

export const resetToInitialState = createAction('[API-KEY] Reset to Initial state');

export const getApiKeys = createAction('[API-KEY] Get Org Api Keys');
export const getApiKeysSuccess = createAction('[API-KEY] Get Org Api Keys Success', props<{ apiKeys: ApiKeyModel.ApiKeyDocument[] }>());
export const getApiKeysFail = createAction('[API-KEY] Get Org Api Keys Fail');

export const generateApiKey = createAction('[API-KEY] Generate Api Key', props<{ dto: ApiKeyDto }>());
export const generateApiKeyServerCall = createAction('[API-KEY] Generate Api Key Server Call', props<{ dto: ApiKeyDto }>());
export const generateApiSuccess = createAction('[API-KEY] Generate Api Key Success', props<{ token: string }>());
export const generateApiFail = createAction('[API-KEY] Generate Api Key Fail');

export const revokeApiKey = createAction('[API-KEY] Revoke Api Key', props<{ id: string }>());
export const revokeApiKeySuccess = createAction('[API-KEY] Revoke Api Key Success');
export const revokeApiKeyFail = createAction('[API-KEY] Revoke Api Key Fail');

export const extendApiKey = createAction('[API-KEY] Extend Api Key', props<{ apiKey: string, extend: number }>());
export const extendApiKeySuccess = createAction('[API-KEY] Extend Api Key Success');
export const extendApiKeyFail = createAction('[API-KEY] Extend Api Key Fail');

/**
 * For table
 */
export const setIsLoading = createAction('[API-KEY] Set Is Loading', props<{ isLoading: boolean }>());
export const startLoadingApiKeys = createAction('[API-KEY] Start Loading Granted Access from Server');
export const setQuery = createAction('[API-KEY] Set Query', props<{ query: string }>());
export const setOrderDirection = createAction('[API-KEY] Set Order Direction', props<{ property: string }>());
export const resetList = createAction('[API-KEY] Reset Granted Access from Server');


