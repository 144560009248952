import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PersonSelectionFormFields, VehicleSelectionFormFields } from '@enums/search.enum';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CameraEditSelectors } from '@states/camera-edit/camera-edit.selector-types';

@Injectable({
  providedIn: 'root',
})
export class ObjectAttributesService {

  public selectOrgProtectiveGear$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgProtectiveGear));


  constructor(private fb: UntypedFormBuilder, private store$: Store) {
  }

  public initPersonProperties(orgProtectiveGear: boolean): UntypedFormGroup {
    const genderForm = this.fb.group({
      male: [false],
      female: [false],
    });
    const ageForm = this.fb.group({
      kids: [false],
      adults: [false],
    });
    const footWear = this.fb.group({
      colors: [],
    });
    const lowerBody = this.fb.group({
      type: [],
      colors: [],
    });
    const upperBody = this.fb.group({
      type: [],
      colors: [],
    });
    const hair = this.fb.group({
      colors: [],
    });
    const accessory = this.fb.group({
      type: [],
    });
    const carrying = this.fb.group({
      type: [],
    });
    const formGroup = this.fb.group({
      [PersonSelectionFormFields.genderType]: this.fb.group({
        enabled: [false],
        props: genderForm,
      }),
      [PersonSelectionFormFields.ageType]: this.fb.group({
        enabled: [false],
        props: ageForm,
      }),
      [PersonSelectionFormFields.footwearType]: this.fb.group({
        enabled: [false],
        props: footWear,
      }),
      [PersonSelectionFormFields.lowerbodyType]: this.fb.group({
        enabled: [false],
        props: lowerBody,
      }),
      [PersonSelectionFormFields.upperbodyType]: this.fb.group({
        enabled: [false],
        props: upperBody,
      }),
      [PersonSelectionFormFields.hairType]: this.fb.group({
        enabled: [false],
        props: hair,
      }),
      [PersonSelectionFormFields.accessoryType]: this.fb.group({
        enabled: [false],
        props: accessory,
      }),
      [PersonSelectionFormFields.carryingType]: this.fb.group({
        enabled: [false],
        props: carrying,
      }),
    });

    if (orgProtectiveGear) {
      const protectiveGear = this.fb.group({
        type: [],
      });
      formGroup.addControl(PersonSelectionFormFields.protectiveGear, this.fb.group({
        enabled: [false],
        beta: true,
        props: protectiveGear,
      }));
    }

    return formGroup;
  }

  public initVehicleProperties(standalone = false): UntypedFormGroup {
    const additionalProperty = this.fb.group({
      region: [],
      plate: [],
    });

    const container = this.fb.group({
      serialNumber: [],
      sizeCode: [],
      ownerCode: [],
    });

    let formGroup: UntypedFormGroup;


    if (standalone) {
      formGroup = this.fb.group({
        [VehicleSelectionFormFields.colors]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.plate]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.make]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.model]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.type]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.additionalProperty]: this.fb.group({
          enabled: [false],
          props: additionalProperty,
        }),
      });
    } else {
      formGroup = this.fb.group({
        [VehicleSelectionFormFields.colors]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.make]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.model]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.type]: this.fb.group({
          enabled: [false],
          value: [],
        }),
        [VehicleSelectionFormFields.additionalProperty]: this.fb.group({
          enabled: [false],
          props: additionalProperty,
        }),
        [VehicleSelectionFormFields.container]: this.fb.group({
          enabled: [false],
          props: container,
        }),
      });
    }
    return formGroup;
  }
}
