<div class="new-comment" [formGroup]="commentForm">
  <div fxLayout="column" class="ui-input-container-no-icon">
    <label>Name *</label>
    <input [class.invalid]="commentForm.get('name').invalid && commentForm.touched"
           class="with-label"
           placeholder="Name"
           formControlName="name"
    />
  </div>
  <div fxLayout="column" class="ui-input-container-no-icon">
    <label>Comment *</label>
    <textarea [class.invalid]="commentForm.get('text').invalid && commentForm.touched"
              class="with-label"
              placeholder="Comment"
              formControlName="text"
              rows="5"
    >
                    </textarea>
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="end center">
    <ui-button text="Cancel"
               colorBgr="white"
               colorText="#344054"
               colorBorder="#D0D5DD"
               (clickAction)="cancel()"
    ></ui-button>
    <ui-button text="Submit"
               colorBgr="#3456E0"
               colorText="white"
               colorBorder="#3456E0"
               (clickAction)="save()"
    ></ui-button>
    <!--    <div class="reply" fxLayout="row" fxLayoutAlign="start center" (click)="save()">-->
    <!--      <mat-icon svgIcon="reply"></mat-icon>-->
    <!--      <span>Reply</span>-->
    <!--    </div>-->
  </div>
</div>
