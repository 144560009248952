import { Directive, EventEmitter, Output, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDragDropFileUpload]',
})
export class DragDropFileUploadDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private background = 'var(--color-secondary-gray-50)';
  @HostBinding('style.border-color') private border = 'var(--color-secondary-gray-600)';

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'var(--color-primary-50)';
    this.border = 'var(--color-primary-500)';
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event'])
  public dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'var(--color-secondary-gray-50)';
    this.border = 'var(--color-secondary-gray-600)';
  }

  // Drop Event
  @HostListener('drop', ['$event'])
  public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'var(--color-primary-50)';
    this.border = 'var(--color-primary-500)';
    const files = event.dataTransfer.files; // disable multi upload
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
