<div fxLayout="row" fxLayoutAlign="space-between center" class="header-wrapper icon-16" [class.single]="!calendarRight">
  <mat-icon (click)="previousClicked('month')" svgIcon="side-menu-arrow-left"></mat-icon>
  <div *ngIf="calendarRight" class="active-range">
    {{ activeStartDate | date : (isSameYear ? 'EEE, MMM dd' : 'EEE, MMM dd, yyyy') }}
    -
    {{ activeEndDate ? (activeEndDate | date : 'EEE, MMM dd, yyyy') : 'End date' }}
  </div>
  <div *ngIf="!calendarRight" class="active-range">
    {{ calendarLeft.activeDate | date : 'MMM yyyy' }}
  </div>
  <mat-icon (click)="nextClicked('month')" svgIcon="side-menu-arrow-right"></mat-icon>
</div>
