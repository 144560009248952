import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NotFoundTemplates } from '@enums/shared.enum';
import { routerSegments } from '@consts/routes';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

  public template$: BehaviorSubject<NotFoundTemplates> = new BehaviorSubject<NotFoundTemplates>(NotFoundTemplates.Default);

  public notFoundTemplates = NotFoundTemplates;

  constructor(private location: Location,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(res => {
        this.template$.next(parseInt(res['template']));
      });
  }

  public back(): void {
    this.location.back();
  }

  public login(): void {
    this.router.navigate([routerSegments.auth]);
  }
}
