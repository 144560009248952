import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexToRgba',
})
export class HexToRgbaPipe implements PipeTransform {
  transform(value: string, opacity = 1): string {
    if (value.length === 1) {
      return 'rgba(67, 103, 246, 0.5)';
    }
    let hex = value.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }
}
