<ui-alert
  style="width: 400px; display: block"
  [type]="alertType"
  [visible]="true"
  [closable]="true"
  [title]="title"
  (closeAction)="action()"
  (mouseleave)="mouseout()"
  (mouseover)="mouseover()"
>
  <span>{{ message }}</span>
</ui-alert>
