import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { AppleTvModels } from '@models/apple-tv.models';

@Injectable({
  providedIn: 'root',
})
export class AppleTvService {
  constructor(private http: HttpClient) {
  }

  public getAll(filters: AppleTvModels.GetAppleTvRequestFilters): Observable<AppleTvModels.AppleTvWithWallDocument[]> {
    let url = `${api.appleTv.crud}?`;
    if (filters.locationId) {
      url += `locationId=${filters.locationId}`;
    }
    if (filters.query) {
      url += `&query=${encodeURIComponent(filters.query)}`;
    }

    return this.http.get<AppleTvModels.AppleTvWithWallDocument[]>(url);
  }

  public create(data: AppleTvModels.CreateAppleTvRequest): Observable<any> {
    return this.http.post<any>(api.appleTv.create, data);
  }

  public delete(locationId: string, edgeId: string): Observable<any> {
    return this.http.delete<any>(api.appleTv.delete(locationId, edgeId));
  }

  public getOne(edgeId: string): Observable<any> {
    return this.http.get<AppleTvModels.AppleTvWithWallDocument>(api.appleTv.one(edgeId));
  }

  public configureWall(appleTvDoc: Partial<AppleTvModels.CreateAppleTvRequest>): Observable<any> {
    return this.http.post<AppleTvModels.AppleTvWithWallDocument>(api.appleTv.configure, appleTvDoc);
  }

  public getWall(edgeId: string): Observable<any> {
    return this.http.get<AppleTvModels.AppleTvWithWallDocument>(api.appleTv.getWall(edgeId));
  }
}
