import { StatsEnum } from '@enums/stats.enum';
import { UiUserSettingsDateType } from '@enums/shared.enum';

export const statUptimePeriods: { [Property in StatsEnum] } = {
  [StatsEnum.hr1]: {
    title: '1 hour',
    period: 60 * 60 * 1000,
    bar: 1000 * 60,
  },
  [StatsEnum.day1]: {
    title: '1 day',
    period: 60 * 60 * 1000 * 24,
    bar: 1000 * 60 * 15,
  },
  [StatsEnum.day7]: {
    title: '7 days',
    period: 60 * 60 * 1000 * 24 * 7,
    bar: 1000 * 60 * 120,
  },
  [StatsEnum.day30]: {
    title: '30 days',
    period: 60 * 60 * 1000 * 24 * 30,
    bar: 1000 * 60 * 120 * 3,
  },
};

export const periods = [
  {
    title: '1 Hour',
    value: StatsEnum.hr1,
    format: UiUserSettingsDateType.time,
  },
  {
    title: '1 Day',
    value: StatsEnum.day1,
    format: UiUserSettingsDateType.mdTime,
  },
  {
    title: '7 Days',
    value: StatsEnum.day7,
    format: UiUserSettingsDateType.mdyTime,
  },
  {
    title: '30 Days',
    value: StatsEnum.day30,
    format: UiUserSettingsDateType.mdy,
  },
];
