<div fxFlexFill fxFlexAlign="center center">
  <ng-container *ngrxLet="selectInitialLoaded$; let initialLoaded">
    <ng-container *ngrxLet="isEmbedded$; let isEmbedded">
      <ng-container *ngrxLet="keepRatio$; let keepRatio">
        <ng-container *ngrxLet="selectSharedNameAndDate$;let sharedNameAndDate">
          <ng-container *ngrxLet="selectSelectedLocation$;let location">
            <ng-container *ngrxLet="selectType$; let type">
              <ng-container *ngrxLet="selectIsNotFound$; let isNotFound">
                <ng-container *ngrxLet="isRecordProtected$; let isProtected">
                  <ng-container *ngrxLet="selectAccessToken$; let accessToken">
                    <!--          FIRST CHECK IF ALL DATA RECEIVED AND W COULD RUN FLOW-->
                    <ng-container *ngIf="initialLoaded;else loader">
                      <!--            SECOND CHECK IF RECORD IS PROTECTED-->
                      <ng-container *ngIf="!isProtected;else requiredPassword">
                        <!--THIRD CHECK IF NOT FOUND. IN CASE OF ALERT NOT FOUND WILL BE SHOWN IN ARCHIVE OR ALERT IS NOT FOUND-->
                        <ng-container *ngIf="!isNotFound;else expiredLink">
                          <div fxLayout="column" class="granted-access" fxFlexFill>
                            <ng-container *ngIf="!isEmbedded">
                              <div fxLayout="row"
                                   fxLayoutAlign="space-between center"
                                   class="header"
                                   *ngrxLet="selectConfig$;let config"
                              >
                                <img class="lumana-logo" alt="logo" src="assets/lumana_logo.svg"/>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                  <ng-container *ngIf="config">
                                    <div fxLayout="row" fxLayoutAlign="start center" class="statistic-section">
                                      <mat-icon>person_outline</mat-icon>
                                      <span>{{ config.sharedCount }}</span>
                                    </div>
                                    <div>
                                      <span class="dot">.</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" class="statistic-section">
                                      <mat-icon>schedule</mat-icon>
                                    </div>
                                    <div>
                                      <span class="dot">.</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" class="statistic-section">
                                      <mat-icon>visibility</mat-icon>
                                      <span>{{ config?.views }} views</span>
                                    </div>
                                    <span *ngIf="config.allowDownload"
                                          class="ui-icon-arrow-download icon-hover ml-5 mr-5"
                                          (click)="downloadArchive()"
                                    ></span>
                                  </ng-container>

                                  <ui-button *ngIf="!this.authenticationService.isLoggedIn()"
                                             class="back-btn"
                                             colorBgr="#FFFFFF"
                                             colorText="#344054"
                                             text="Sign in"
                                             colorBorder="#D0D5DD"
                                             (click)="signIn()"
                                  >
                                  </ui-button>
                                </div>
                              </div>
                            </ng-container>
                            <!--                    <ng-container *ngIf="!isPasswordRequired;else requiredPassword">-->
                            <div class="content-wrapper h-100">
                              <div class="content-inner h-100" [class.embedded]="isEmbedded">
                                <div class="video-container">

                                  <ng-container [ngSwitch]="type">
                                    <ng-container *ngSwitchCase="grantedAccessTypes.ALERT">
                                      <app-shared-alert [sharedArchive]="selectSharedArchive$|ngrxPush"
                                                        [archiveUrl]="archiveUrl" [accessToken]="accessToken"
                                      ></app-shared-alert>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="grantedAccessTypes.ARCHIVE">
                                      <app-shared-archive [sharedArchive]="selectSharedArchive$|ngrxPush"
                                                          [archiveUrl]="archiveUrl"
                                      ></app-shared-archive>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="grantedAccessTypes.LIVE_VIEW">
                                      <div class="player-wrapper h-100"
                                           *ngIf="selectSharedLiveView$|async as  sharedLiveView"
                                      >
                                        <webrtc-player #webrtcPlayer [edgeId]="sharedLiveView.edgeId"
                                                       [cameraId]="sharedLiveView.cameraId"
                                                       [locationId]="sharedLiveView.locationId"
                                                       [cameraName]="sharedLiveView.name"
                                                       [autostart]="true"
                                                       [allowZoom]="true"
                                                       [accessToken]="accessToken"
                                                       [permissionCheck]="false"
                                                       [isRespectRatio]="keepRatio"
                                                       class="h-100"
                                                       style="max-height: 100vh;"
                                                       (clickTakeSnapshot)="webrtcPlayer.takeSnapshot(sharedLiveView.name, location.name, now)"
                                                       (clickFullScreen)="webrtcPlayer.maximize()"
                                        >
                                        </webrtc-player>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="grantedAccessTypes.PLAYBACK">
                                      <div class="player-wrapper" *ngrxLet="selectSharedPlayBack$; let sharedPlayBack">
                                        <ng-container *ngIf="sharedPlayBack">
                                          <app-playback-player
                                            #playback
                                            class="w-100"
                                            [permissionCheck]="false"
                                            [timezone]="sharedPlayBack.alertCameraTimezone"
                                            [cameraId]="sharedPlayBack.cameraId"
                                            [edgeId]="sharedPlayBack.edgeId"
                                            [locationId]="sharedPlayBack.locationId"
                                            [edgeRegion]="sharedPlayBack.region"
                                            [startTime]="sharedPlayBack.startTime"
                                            [time]="sharedPlayBack.alertTime"
                                            [duration]="sharedPlayBack.endTime - sharedPlayBack.startTime"
                                            fxFlex=""
                                          ></app-playback-player>
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      <ui-preloader [color]="loaderColor.blue"></ui-preloader>
                                    </ng-container>
                                  </ng-container>

                                </div>
                                <ng-container *ngIf="!isEmbedded">
                                  <div class="title">
                                    <ng-container *ngIf="type === grantedAccessTypes.LIVE_VIEW; else notLiveView">
                                      {{ (selectSharedLiveView$ | async).name }}
                                    </ng-container>
                                    <ng-template #notLiveView>

                                      {{ sharedNameAndDate.name }}
                                    </ng-template>
                                    , {{ location|locationAddress }}
                                  </div>
                                  <span class="description">{{ sharedNameAndDate.timestamp | date : 'dd MMMM, YYYY' }}</span>
                                  <div class="comment-container">
                                    <div class="title">Comments</div>
                                    <ng-container *ngrxLet="selectComments$;let comments">
                                      <ng-container *ngIf="comments?.length">
                                        <div class="comment-wrapper">
                                          <ng-container>
                                            <div class="comment" *ngFor="let comment of comments">
                                              <div fxLayout="row" fxLayoutAlign="start center">
                                                <ui-avatar class="avatar"
                                                           [color]="'#B2DF8A'"
                                                           [name]="comment.name"
                                                ></ui-avatar>
                                                <span class="name">{{ comment.name }}</span>
                                                <!--                      <span class="label1">at</span>-->
                                                <!--                      <span class="label1 time">03:32</span>-->
                                              </div>
                                              <div fxLayout="column" class="comment-body">
                                                <span class="text">{{ comment.text }}</span>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                         <span class="label1" style="margin-left: 0">
                           {{ comment.timestamp * 1000|date:'MM-dd-YY HH:mm' }}
                         </span>
                                                  <!--                        <span class="dot">.</span>-->
                                                  <!--                        <span class="label1">Delete</span>-->
                                                  <!--                        <span class="dot">.</span>-->
                                                  <!--                        <span class="label1">Edit</span>-->
                                                </div>
                                              </div>
                                              <!--                    <div class="actions" fxLayout="row" fxLayoutAlign="start center">-->
                                              <!--                      <div class="reply" fxLayout="row" fxLayoutAlign="start center">-->
                                              <!--                        <mat-icon svgIcon="reply"></mat-icon>-->
                                              <!--                        <span>Reply</span>-->
                                              <!--                      </div>-->
                                              <!--                    </div>-->
                                            </div>
                                          </ng-container>

                                        </div>
                                        <hr class="hr">
                                      </ng-container>
                                      <ng-container *ngIf="!comments?.length && addCommentHide">
                                        <div class="no-comment">
                                          <div class="title">
                                            There aren't any comments yet.
                                          </div>
                                          <div class="description">
                                            Be the first. Add a comment on the video above.
                                          </div>
                                          <ui-button type="default"
                                                     text="Comment"
                                                     (clickAction)="addCommentHide=!addCommentHide"
                                          >

                                          </ui-button>
                                        </div>
                                      </ng-container>
                                      <div class="new-comment">
                                        <ui-button *ngIf="addCommentHide && comments?.length"
                                                   text="Comment"
                                                   icon="chat_bubble_outline"
                                                   colorIcon="#667085"
                                                   colorBgr="white"
                                                   colorText="#344054"
                                                   colorBorder="#D0D5DD"
                                                   (clickAction)="addCommentHide=!addCommentHide"
                                        ></ui-button>

                                        <ui-comment-form *ngIf="!addCommentHide" (onSave)="commentAdd($event)"
                                                         (onCancel)="cancelComment()"
                                        ></ui-comment-form>
                                      </div>

                                    </ng-container>

                                  </div>
                                </ng-container>
                              </div>
                            </div>

                            <!--                    </ng-container>-->
                          </div>
                        </ng-container>
                      </ng-container>

                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #loader>
  <ui-preloader [color]="loaderColor.blue"></ui-preloader>
</ng-template>


<ng-template #requiredPassword>
  <div fxFlex fxLayoutAlign="center center">
    <div fxLayout="column" class="required-password">
      <div class="title">This video is private</div>
      <div class="sub-title">Enter the password to open this video</div>
      <div fxLayout="column" class="ui-input-container-no-icon">
        <label>Password</label>
        <input class="with-label password" [(ngModel)]="password" [type]="isPasswordVisible?'text':'password'"
               placeholder="Name"
        />
        <mat-icon class="lock-btn">lock</mat-icon>
        <div class="password-manipulation">
              <span *ngIf="!isPasswordVisible" class="pwd-btn" fxLayout="row" fxLayoutAlign="start center"
                    (click)="isPasswordVisible = !isPasswordVisible"
              >
                <mat-icon>visibility</mat-icon>
                Show password
              </span>
          <span *ngIf="isPasswordVisible" fxLayout="row" class="pwd-btn" fxLayoutAlign="start center"
                (click)="isPasswordVisible = !isPasswordVisible"
          >
                <mat-icon>visibility_off</mat-icon>
                Hide password
              </span>
        </div>
      </div>
      <ui-progress-button [disabled]="!password"
                          style="margin-top: 32px"
                          [loading]="isSubmit"
                          text1="Submit"
                          text2="Submit"
                          (clickAction)="submitPassword()"
      >
      </ui-progress-button>
    </div>
  </div>
</ng-template>

<ng-template #expiredLink>
  <div fxFlexFill class="link-expired">
    <div class="inner">
      <img class="lumana-logo" alt="logo" src="assets/lumana_logo.svg"/>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="description-block">
          <div class="level-2">Link expired</div>
          <div class="level-3">
            The link you're attempting to access has expired.<br>
            To get a new link, please reach out to the original link owner.<br> Sorry for the inconvenience.
          </div>
          <ui-button colorBgr="#4367F6"
                     colorText="white"
                     text="Go back"
                     class="back-btn"
                     (clickAction)="back()"
          ></ui-button>
        </div>
        <div>
          <!--          <img class="icon" alt="icon" src="assets/not-found-icon.svg"/>-->

        </div>
      </div>
    </div>
  </div>

</ng-template>
