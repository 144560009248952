<div fxLayout="column" fxLayoutAlign="start start" fxFill class="ui-door-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Add new door</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div [formGroup]="form"
       class="dialog-content narrower-input"
  >
    <ng-container [ngSwitch]="addState">
      <ng-container *ngSwitchCase="DoorAddState.CLOSE_STATE">
        <div class="mb-16">Complete door state validation:</div>
        <img class="ui-door-dialog-img mb-16" [lazyLoad]="closed"/>
        <div>Is the door closed?</div>
      </ng-container>
      <ng-container *ngSwitchCase="DoorAddState.OPEN_STATE">
        <div class="mb-16">Complete door state validation:</div>
        <img class="ui-door-dialog-img mb-16" [lazyLoad]="opened"/>
        <div>Is the door opened?</div>
      </ng-container>
      <ng-container *ngSwitchCase="DoorAddState.NAME">
        <img class="ui-door-dialog-img mb-16" [lazyLoad]="closed"/>
        <div fxLayout="column" fxLayoutGap="8px" class="w-100">
          <div>Door name</div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" formControlName="name"/>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="DoorAddState.NO_DATA">
        <div>
          In order to add this door, additional data needs to be gathered. You will be able to retry once the data has
          been collected.
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">

    <ng-container [ngSwitch]="addState">
      <ng-container *ngSwitchDefault>
        <ui-button type="default" text="No" (clickAction)="no()"
        ></ui-button>
        <ui-button type="primary" text="Yes" (clickAction)="yes()"
        ></ui-button>
      </ng-container>
      <ng-container *ngSwitchCase="DoorAddState.NAME">
        <ui-button type="primary" text="Add" (clickAction)="add()"
        ></ui-button>
      </ng-container>
      <ng-container *ngSwitchCase="DoorAddState.NO_DATA">
        <ui-button type="primary" text="Done" (clickAction)="done()"
        ></ui-button>
      </ng-container>
    </ng-container>
    <ui-preloader [color]="PreloaderColor.blue" [diameter]="20" *ngIf="loading"></ui-preloader>
  </div>

</div>
