import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { swapCamerasStart } from '@states/multi-playback/multi-playback.actions';


@Injectable()
export class MultiPlaybackEffect {

  public swapCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiPlaybackActions.swapCamerasStart),
      switchMap(() => of(SharedActions.doNothing())),
    ),
  );

  public setDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiPlaybackActions.setDefault),
      switchMap(() => of(SharedActions.doNothing())),
    ),
  );


  constructor(private actions$: Actions) {
  }
}
