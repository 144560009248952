<div class="p-20">
  <loader [loading]="loading" diameter="30"></loader>
  <table *ngIf="!loading" mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
    <ng-container matColumnDef="cameraId">
      <th mat-header-cell *matHeaderCellDef>Core ID</th>
      <td mat-cell *matCellDef="let element">{{ element.cameraId }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="camera-actions" mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="Start Camera" (click)="startCamera(element.cameraId)">
          <mat-icon>play_circle_outline</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Stop Camera" (click)="stopCamera(element.cameraId)">
          <mat-icon>pause_circle_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="c-p"></tr>
  </table>
  <!--    <button class="add-btn" mat-fab matTooltip="Add a new camera" (click)="addCamera()">-->
  <!--        <mat-icon>add</mat-icon>-->
  <!--    </button>-->
</div>
