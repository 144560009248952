import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';
import { SubstreamHeartbeatPulsationActions } from './substream-heartbeat-pulsation-types';

export interface SubstreamPulsationState extends EntityState<PulsationModels.Store.SubstreamPulsation> {
  message?: string;
  error?: string;
}

export const adapter: EntityAdapter<PulsationModels.Store.SubstreamPulsation> = createEntityAdapter<PulsationModels.Store.SubstreamPulsation>({
  selectId: (status: PulsationModels.Store.SubstreamPulsation) => status.cameraId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: SubstreamPulsationState = adapter.getInitialState({});

export const substreamPulsationStatusReducer = createReducer(
  initialState,

  on(SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess, (state, action) => {
    return adapter.setMany(action.payload, state);
  }),

  on(SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  })
);
