import { createAction, props } from '@ngrx/store';
import { WidgetType, WidgetViewMode } from '@enums/workspace.enum';
import { RelativeCustomDateRange, WidgetDeprecated, WidgetCounterData, Workspace, WorkspaceFavorite } from '@models/workspace.model';
import { VariableConfig } from '@models/variable.model';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { VariableType } from '@enums/variable.enum';

export const resetToInitialState = createAction('[WORKSPACE] Reset to Initial state');

export const addWidget = createAction('[WORKSPACE] Add Widget', props<{ widgetType: WidgetType; viewMode: WidgetViewMode }>());

export const changeWorkspaceName = createAction('[WORKSPACE] Change Workspace Name', props<{ name: string }>());

export const setSelectedWidget = createAction('[WORKSPACE] Set Selected Widget', props<{ widget: WidgetDeprecated }>());

export const saveWorkspace = createAction('[WORKSPACE] Save Workspace');

export const sendWorkspaceToServer = createAction('[WORKSPACE] Save Workspace To Server');

export const sendWorkspaceToServerSuccess = createAction('[WORKSPACE] Save Workspace To Server Success');

export const sendWorkspaceToServerFail = createAction('[WORKSPACE] Save Workspace To Server Fail');

export const getWorkspaces = createAction('[WORKSPACE] Get Workspaces');

export const getFavorites = createAction('[WORKSPACE] Get Favorite Workspaces');

export const getWorkspacesSuccess = createAction('[WORKSPACE] Get Workspaces Success', props<{ workspaces: Workspace[] }>());

export const getFavoritesSuccess = createAction('[WORKSPACE] Get Favorites Success', props<{ favorites: WorkspaceFavorite[] }>());

export const getWorkspacesFail = createAction('[WORKSPACE] Get Workspaces Fail');

export const setIsExpanded = createAction('[WORKSPACE] Set Is Expanded');

export const getWorkspace = createAction('[WORKSPACE] Get One Workspace', props<{ id: string }>());

export const getWorkspaceSuccess = createAction('[WORKSPACE] Get One Workspace Success', props<{ workspace: Workspace }>());

export const getWorkspaceFail = createAction('[WORKSPACE] Get One Workspace Fail');

export const setInitialWorkspace = createAction('[WORKSPACE] Set Initial Workspace');

export const resetSelectedWorkspace = createAction('[WORKSPACE] Reset Selected Workspace');

export const removeWorkspace = createAction('[WORKSPACE] Remove Workspace', props<{ id: string }>());

export const removeWorkspaceSuccess = createAction('[WORKSPACE] Remove Workspace Success');

export const removeWorkspaceFail = createAction('[WORKSPACE] Remove Workspace Fail');

export const updateWidget = createAction('[WORKSPACE] Update Widget', props<{ field: string; value: any; index: number }>());

export const updateSelectedWidget = createAction(
  '[WORKSPACE] Update Selected Widget',
  props<{ field: string; value: any; index: number }>()
);

export const removeWidget = createAction('[WORKSPACE] Remove Widget', props<{ localId: number }>());

export const getVariablesAutocomplete = createAction(
  '[WORKSPACE] Get Variables Autocomplete',
  props<{ query?: any; notType?: VariableType; variableType?: VariableType; cameraId?: string }>()
);

export const getVariablesAutocompleteSuccess = createAction(
  '[WORKSPACE] Get Variables Autocomplete Success',
  props<{ variablesAutoComplete: { _id: string; name: string }[] }>()
);

export const getVariablesAutocompleteFail = createAction('[WORKSPACE] Get Variables Autocomplete Fail');

export const getWidgetData = createAction(
  '[WORKSPACE] Get Widget Data',
  props<{
    widgetType: WidgetType;
    variables: string[];
    cameraId?: string;
    relative: {
      value: number;
      unit: CustomUnit;
    };
    absolute: {
      start: string;
      end: string;
      timezone: string;
    };
    relativeCustom: RelativeCustomDateRange;
    widgetIndex: number;
  }>()
);

export const getWidgetDataSuccess = createAction(
  '[WORKSPACE] Get Widget Data Success',
  props<{ data: WidgetCounterData; index: number }>()
);

export const getWidgetDataFail = createAction('[WORKSPACE] Get Widget Data Fail');

export const updateEditMode = createAction('[WORKSPACE] Update Edit Mode', props<{ isEditMode: boolean }>());

export const setQuery = createAction('[WORKSPACE] Set Query', props<{ query: string }>());

export const setPaginationParams = createAction('[WORKSPACE] Set Pagination Params', props<{ perPage: number; page: number }>());

export const setTotalItemsCount = createAction('[WORKSPACE] Set Total Item Counts', props<{ totalItemsCount: number }>());

export const setAbsoluteDate = createAction('[WORKSPACE] Set Workspace Filter', props<{ start: string; end: string; timezone: string }>());

export const addFavoriteWorkspace = createAction('[WORKSPACE] Add Favorite Workspace', props<{ workspace: Workspace }>());

export const addFavoriteWorkspaceSuccess = createAction('[WORKSPACE] Add Favorite Workspace Success', props<{ workspace: Workspace }>());

export const addFavoriteWorkspaceFail = createAction('[WORKSPACE] Add Favorite Workspace Fail');
export const removeFavoriteWorkspace = createAction('[WORKSPACE] Remove Favorite Workspace', props<{ workspaceId: string }>());

export const removeFavoriteWorkspaceSuccess = createAction(
  '[WORKSPACE] Remove Favorite Workspace Success',
  props<{ workspaceId: string }>()
);

export const removeFavoriteWorkspaceFail = createAction('[WORKSPACE] Remove Favorite Workspace Fail');

export const clearFilters = createAction('[WORKSPACE] Clear Filters');

export const resetSelectedWidget = createAction('[WORKSPACE] Reset Selected Widget');

export const cancelLoadWidgetData = createAction('[WORKSPACE] Cancel Load Widget Data');

export const removeVariable = createAction('[WORKSPACE] Remove Variable from Widget', props<{ widgetIndex: number; variable: string }>());

export const updateCustomRelativeTime = createAction('[WORKSPACE] Update Custom Relative Time', props<{ data: any; index: number }>());

export const updateRelativeTime = createAction('[WORKSPACE] Update Relative Time', props<{ data: any; index: number }>());

export const setInitialFalse = createAction('[WORKSPACE] Set Initial False');
