<div fxLayout="column" class="add-archive-modal" fxFlexFill>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
    Invite
    <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
  </div>
  <div class="body" fxLayout="column" fxFlex fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="narrow-input w-100 c-100">
        <div>Share via email</div>
        <div class="w-100 p-r">
          <div *ngIf="validateEmail(emailInput)" class="add-email-button c-p" (click)="addEmail()">
            <mat-icon>add</mat-icon>
          </div>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput
                   placeholder="Add email"
                   [(ngModel)]="emailInput"
                   [class.valid-email]="validateEmail(emailInput)"
                   (keyup.enter)="addEmail()"
            />
          </mat-form-field>
        </div>
        <div class="flex-row">
          <ui-filter-chip *ngFor="let email of invitedEmailUsers"
                          (onRemoveClicked)="removeEmail(email)"
                          [title]="email"
          ></ui-filter-chip>
        </div>

      </div>

    </div>
    <div fxLayout="column" fxLayoutGap="8px">
      <div>Share via SMS</div>
      <div fxLayout="row" class="p-r w-100 tel-wrapper c-100">
        <div *ngIf="phoneInput" class="add-phone-button c-p" (click)="addPhone()">
          <mat-icon>add</mat-icon>
        </div>
        <form #form="ngForm" class="w-100">
          <intl-tel-input
            #ng2Tel
            [(E164PhoneNumber)]="phoneInput"
            [cssClass]="'w-100 tel-input ui-input'"
            [options]="{
                   preferredCountries: ['us'],
               }"
            (keydown.enter)="addPhone()"
          ></intl-tel-input>
        </form>
      </div>
      <div class="flex-row">
        <ui-filter-chip *ngFor="let phone of invitedSMSUsers"
                        (onRemoveClicked)="removePhone(phone)"
                        [title]="phone"
        ></ui-filter-chip>
      </div>

    </div>
    <div fxLayout="column" class="ui-input-container-no-icon" style="margin-top:16px">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <label>Message</label>
        <div class="counter">{{ message?.length ?? 0 }}/100</div>
      </div>

      <textarea rows="3"
                class="with-label"
                maxlength="100"
                placeholder="Write a message"
                [(ngModel)]="message"
                style="resize: none;"
      ></textarea>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <ui-progress-button
        style="margin-left: 8px;"
        [loading]="selectIsSaving$ | ngrxPush"
        [disabled]="!sendValid"
        text1="Send"
        text2="Sent"
        (clickAction)="share()"
      >
      </ui-progress-button>
    </div>
  </div>
</div>
