import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralState } from './general.reducer';

export const selectGeneralState = createFeatureSelector<GeneralState>('generalState');

export const selectMultiTracker = createSelector(selectGeneralState, ({ multiTracker }: GeneralState) => multiTracker);

export const selectTrackingWindow = createSelector(selectMultiTracker, multiTracker => multiTracker.trackingWindow);

export const selectTrackerThreshold = createSelector(selectMultiTracker, multiTracker => multiTracker.trackerThreshold);
