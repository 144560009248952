<div class="door-icon" [class.small]="small">
  <img class="door-icon-img" [lazyLoad]="img"/>
  <ng-container *ngIf="allowDelete">
    <div
      fxLayoutAlign="center center"
      class="door-icon-action delete icon-16"
      [class.small]="small"
      [class.icon-12]="small"
      (click)="delete.emit()"
      matTooltipClass="sidebar-tooltip"
      [matTooltip]="door.name ? door.name + ' is not shown here, remove' : 'Not shown here, remove'"
    >
      <mat-icon>close</mat-icon>
    </div>
  </ng-container>

  <div *ngIf="!door?.isSaved"
       fxLayoutAlign="center center"
       class="door-icon-action add icon-16"
       [class.small]="small"
       [class.icon-12]="small"
       (click)="add.emit()"
  >
    <mat-icon>add</mat-icon>
  </div>
  <mat-menu #addMenu="matMenu" yPosition="below" xPosition="after">
    <button
      mat-menu-item
      (click)="add.emit()"
    >
      New
    </button>
    <button
      mat-menu-item
      (click)="addExisting.emit()"
    >
      Add to existing
    </button>
  </mat-menu>

</div>
