import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { api } from '../consts/url.const';
import { EdgeManagement } from './edge-management.model';

export enum EdgeManagerMsgType {
  Reboot = 'Reboot',
  DockerComposePull = 'DockerComposePull',
  DockerComposeDown = 'DockerComposeDown',
  DockerComposeUp = 'DockerComposeUp',
  DockerComposeBuild = 'DockerComposeBuild',
  DockerComposeCreate = 'DockerComposeCreate',
  DockerComposeKill = 'DockerComposeKill',
  DockerComposeRestart = 'DockerComposeRestart',
  DockerComposeStart = 'DockerComposeStart',
  DockerComposeStop = 'DockerComposeStop',
  UploadDockerCompose = 'UploadDockerCompose',
  DownloadDockerCompose = 'DownloadDockerCompose',
  NpmInstall = 'NpmInstall',
  DownloadDeviceMsgs = 'DownloadDeviceMsgs',
  UpdateDeviceMsgs = 'UpdateDeviceMsgs',
  GetDeviceMsgsVersion = 'GetDeviceMsgsVersion',
  DockerImagePrune = 'DockerImagePrune',
  DockerContainerPrune = 'DockerContainerPrune',
  DockerVolumePrune = 'DockerVolumePrune',
  DockerNetworkPrune = 'DockerNetworkPrune',
  DockerSystemPrune = 'DockerSystemPrune',
  DockerImageRemove = 'DockerImageRemove',
  GetSWVersion = 'GetSWVersion',
  SWUpdate = 'SWUpdate',
  GetEthDeviceInfo = 'GetEthDeviceInfo',
  Ifconfig = 'Ifconfig',
  RouteInfo = 'RouteInfo',
  InterfaceEthConf = 'InterfaceEthConf',
  Arp = 'Arp',
  Ping = 'Ping',
  Ifup = 'Ifup',
  Ifdown = 'Ifdown',
  NMCLIConfigure = 'NMCLIConfigure',
  DeleteEthManualConfiguration = 'DeleteEthManualConfiguration',
  DeleteLogs = 'DeleteLogs',
  GetDisksInfo = 'GetDisksInfo',
  Mount = 'Mount',
  DockerPs = 'DockerPs',
  DockerInspect = 'DockerInspect',
  DockerStats = 'DockerStats',
  NMCLI = 'NMCLI',
  DU = 'DU',
  LS = 'LS',
  NMCLIDeviceShow = 'NMCLIDeviceShow',
  NMCLIDeviceStatus = 'NMCLIDeviceStatus',
  CheckEdgeInstallation = 'CheckEdgeInstallation',
  GetDate = 'GetDate',
  SetDate = 'SetDate',
  SetTimezone = 'SetTimezone',
  SetNTP = 'SetNTP',
  PM2Logs = 'PM2Logs',
  PS = 'PS',
  GetEdgeIPInfos = 'GetEdgeIPInfos',
  GetExternalIP = 'GetExternalIP',
  CheckWhiteList = 'CheckWhiteList',
  SpeedtestInfo = 'SpeedtestInfo',
  Dig = 'Dig',
  Traceroute = 'Traceroute',
}

export enum EdgeDatabaseManagerMsgType {
  StopAllCamerasInDb = 'StopAllCamerasInDb',
  StartAllCamerasInDb = 'StartAllCamerasInDb',
  ReloadAllCamerasFromDb = 'ReloadAllCamerasFromDb',
}

@Injectable({
  providedIn: 'root',
})
export class EdgeManagementService {
  constructor(private http: HttpClient) {
  }

  public manageEdge(action: EdgeManagerMsgType, dto: EdgeManagement.ManageEdgeDto): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.edgeManagement.manageEdge(action), dto);
  }

  public manageEdgeDatabase(
    action: EdgeDatabaseManagerMsgType,
    dto: EdgeManagement.ManageEdgeDatabaseDto
  ): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.edgeManagement.manageEdgeDatabase(action), dto);
  }

  public manageEdgeAction(action: number, dto: EdgeManagement.ManageEdgeDto): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.edgeManagement.manageEdgeByActionId(action), dto);
  }

  public dispatchEdgeAction(action: number, dto: any): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.edge.action(action), dto);
  }

  public getEdgeSwUpdate(edgeId: string): Observable<any> {
    return this.http.post(api.edgeManagement.getEdgeSwUpdate, {edgeId});
  }
}
