import { BoxConfiguration, BoxPrice, CameraPrice, DeviceOptions, LicenseTerms, CameraOptions, RetentionOptions, StorageOptions, AddOnPrice, AddOnOptions, CameraType } from '@models/pricing.model';
import { KeyValuePairs } from '../core/interfaces';

export const PricingTermStr = [
  '1 Year',
  '3 Years',
  '5 Years',
];

export const PricingTermToYears = [
  1,
  3,
  5,
];

export const RetentionOptionsStr = [
  '30 Days',
  '60 Days',
  '90 Days',
];

export const ResolutionOptionsStr = [
  '5MP',
  '8MP',
  '12MP',
];

export const boxConfiguration: BoxConfiguration[] = [
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 12 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 8 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 7 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 7 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 3 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 3 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 1 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 1 },
];

export const boxPricing: BoxPrice[] = [
  {
    storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 749,
      [LicenseTerms.Years3]: 699,
      [LicenseTerms.Years5]: 679,
    },
  },
  {
    storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 659,
      [LicenseTerms.Years3]: 609,
      [LicenseTerms.Years5]: 589,
    },
  },
  {
    storage: StorageOptions.Storage32TB, device: DeviceOptions.RACK1U32TB, price: {
      [LicenseTerms.Years1]: 4499,
      [LicenseTerms.Years3]: 4199,
      [LicenseTerms.Years5]: 4099,
    },
  },
  {
    storage: StorageOptions.Storage64TB, device: DeviceOptions.RACK1U64TB, price: {
      [LicenseTerms.Years1]: 6799,
      [LicenseTerms.Years3]: 6399,
      [LicenseTerms.Years5]: 6099,
    },
  },
  {
    storage: StorageOptions.Storage88TB, device: DeviceOptions.RACK1U88TB, price: {
      [LicenseTerms.Years1]: 9499,
      [LicenseTerms.Years3]: 8899,
      [LicenseTerms.Years5]: 8499,
    },
  },
];

export const swLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const addOnPricing: AddOnPrice[] = [
  { type: AddOnOptions.Speaker, price: 575 },
  { type: AddOnOptions.CloudStorage30Days, price: 99 },
  { type: AddOnOptions.CloudStorage60Days, price: 198 },
  { type: AddOnOptions.CloudStorage90Days, price: 297 },
  { type: AddOnOptions.CloudStorage180Days, price: 594 },
  { type: AddOnOptions.CloudStorage365Days, price: 1188 },
  { type: AddOnOptions.ExternalStorage30Days, price: 19 },
  { type: AddOnOptions.ExternalStorage60Days, price: 38 },
  { type: AddOnOptions.ExternalStorage90Days, price: 57 },
  { type: AddOnOptions.ExternalStorage180Days, price: 114 },
  { type: AddOnOptions.ExternalStorage365Days, price: 228 },
  { type: AddOnOptions.AlertStorage, price: 129 },
  { type: AddOnOptions.EventTagsStorage, price: 39 },
];

export const speakerLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const sensorLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 99,
  [LicenseTerms.Years3]: 92,
  [LicenseTerms.Years5]: 85,
};

export const appleTvLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 399,
  [LicenseTerms.Years3]: 349,
  [LicenseTerms.Years5]: 319,
};

export const cameraPricing: CameraPrice[] = [
  {
    resolution: CameraOptions.Resolution5MP, price: {
      [LicenseTerms.Years1]: 32,
      [LicenseTerms.Years3]: 29,
      [LicenseTerms.Years5]: 27,
    }, own: 135,
  },
  {
    resolution: CameraOptions.Resolution8MP, price: {
      [LicenseTerms.Years1]: 42,
      [LicenseTerms.Years3]: 39,
      [LicenseTerms.Years5]: 37,
    },
    own: 185,
  },
  {
    resolution: CameraOptions.OpticalZoom5MP, price: {
      [LicenseTerms.Years1]: 54,
      [LicenseTerms.Years3]: 49,
      [LicenseTerms.Years5]: 47,
    },
    own: 235,
  },
  {
    resolution: CameraOptions.Fisheye12MP, price: {
      [LicenseTerms.Years1]: 192,
      [LicenseTerms.Years3]: 179,
      [LicenseTerms.Years5]: 167,
    },
    own: 835,
  },
  {
    resolution: CameraOptions.PTZ, price: {
      [LicenseTerms.Years1]: 64,
      [LicenseTerms.Years3]: 59,
      [LicenseTerms.Years5]: 57,
    },
    own: 285,
  },

];

export const cameraTypeStr: { [Property in CameraType]: string } = {
  [CameraType.Turret5MP]: 'Turret 5MP',
  [CameraType.Dome5MP]: 'Dome 5MP',
  [CameraType.Bullet5MP]: 'Bullet 5MP',
  [CameraType.Turret8MP]: 'Turret 8MP',
  [CameraType.Dome8MP]: 'Dome 8MP',
  [CameraType.Bullet8MP]: 'Bullet 8MP',
  // [CameraType.Fisheye12MP]: 'Fisheye 12MP',
  // [CameraType.PTZ]: 'PTZ',
  // [CameraType.Turret5MPZoom]: 'Turret 5MP Zoom',
};

export const costFieldsDisplay = [
  'box2tb',
  'box4tb',
  'box1u32tb',
  'box1u64tb',
  'box1u88tb',
  'camerasTurret5mp',
  'camerasDome5mp',
  'camerasBullet5mp',
  'camerasTurret8mp',
  'camerasDome8mp',
  'camerasBullet8mp',
  'camerasFisheye12mp',
  'camerasPtz',
  'camerasTurret5mpZoom',
  'license',
  'cloudStorage30',
  'cloudStorage60',
  'cloudStorage90',
  'cloudStorage180',
  'cloudStorage365',
  'externalStorage30',
  'externalStorage60',
  'externalStorage90',
  'externalStorage180',
  'externalStorage365',
  'metadataStorage30',
  'metadataStorage60',
  'metadataStorage90',
  'metadataStorage180',
  'metadataStorage365',
  'snapshotStorage30',
  'snapshotStorage60',
  'snapshotStorage90',
  'snapshotStorage180',
  'snapshotStorage365',
  'verificationEvents',
  'verificationGuns',
  'verificationFire',
  'additionalSpeakerLicense',
  'additionalSensorLicense',
  'additionalAppleTvLicense',
  'additionalAlertStorage',
  'additionalEventTagsStorage',
];

export const costFieldsStr: KeyValuePairs<string> = {
  'box2tb': 'Core 2TB',
  'box4tb': 'Core 4TB',
  'box1u32tb': '1U 32TB',
  'box1u64tb': '1U 64TB',
  'box1u88tb': '1U 88TB',
  'camerasTurret5mp': 'Turret 5MP',
  'camerasDome5mp': 'Dome 5MP',
  'camerasBullet5mp': 'Bullet 5MP',
  'camerasTurret8mp': 'Turret 8MP',
  'camerasDome8mp': 'Dome 8MP',
  'camerasBullet8mp': 'Bullet 8MP',
  'camerasFisheye12mp': 'Fisheye 12MP',
  'camerasPtz': 'PTZ 5MP',
  'camerasTurret5mpZoom': 'Turret 5MP Zoom',
  'license': 'Camera feeds',
  'cloudStorage30': 'Cloud storage 30 days',
  'cloudStorage60': 'Cloud storage 60 days',
  'cloudStorage90': 'Cloud storage 90 days',
  'cloudStorage180': 'Cloud storage 180 days',
  'cloudStorage365': 'Cloud storage 1 year',
  'externalStorage30': 'NAS connectivity 30 days',
  'externalStorage60': 'NAS connectivity 60 days',
  'externalStorage90': 'NAS connectivity 90 days',
  'externalStorage180': 'NAS connectivity 180 days',
  'externalStorage365': 'NAS connectivity 1 year',
  'metadataStorage30': 'Metadata storage 30 days',
  'metadataStorage60': 'Metadata storage 60 days',
  'metadataStorage90': 'Metadata storage 90 days',
  'metadataStorage180': 'Metadata storage 180 days',
  'metadataStorage365': 'Metadata storage 1 year',
  'snapshotStorage30': 'Snapshots storage 30 days',
  'snapshotStorage60': 'Snapshots storage 60 days',
  'snapshotStorage90': 'Snapshots storage 90 days',
  'snapshotStorage180': 'Snapshots storage 180 days',
  'snapshotStorage365': 'Snapshots storage 1 year',
  'verificationEvents': '100 events/month verification',
  'verificationGuns': 'Gun verification for 1 camera',
  'verificationFire': 'Fire verification for 1 camera',
  'additionalSpeakerLicense': 'Speaker subscription',
  'additionalSensorLicense': 'Sensor subscription',
  'additionalAppleTvLicense': 'Apple TV subscription',
  'additionalAlertStorage': '10K Alert storage',
  'additionalEventTagsStorage': '10K Event tags storage',
};


export const oneTimeCostFieldsDisplay = [
  'additionalSpeaker',
  'ownCamerasTurret5mp',
  'ownCamerasDome5mp',
  'ownCamerasBullet5mp',
  'ownCamerasTurret8mp',
  'ownCamerasDome8mp',
  'ownCamerasBullet8mp',
  'ownCamerasFisheye12mp',
  'ownCamerasPtz',
  'ownCamerasTurret5mpZoom',
];
export const oneTimeCostFieldsStr: KeyValuePairs<string> = {
  'additionalSpeaker': 'Speaker',
  'ownCamerasTurret5mp': 'Turret 5MP',
  'ownCamerasDome5mp': 'Dome 5MP',
  'ownCamerasBullet5mp': 'Bullet 5MP',
  'ownCamerasTurret8mp': 'Turret 8MP',
  'ownCamerasDome8mp': 'Dome 8MP',
  'ownCamerasBullet8mp': 'Bullet 8MP',
  'ownCamerasFisheye12mp': 'Fisheye 12MP',
  'ownCamerasPtz': 'PTZ 5MP',
  'ownCamerasTurret5mpZoom': 'Turret 5MP Zoom',
};
