<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxFill
  class="people-upload-dialog"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{ data?.isSearch ? 'Search person from image' : 'Add person' }}</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxFlex=""
       class="dialog-content narrower-input"
  >
    <ng-container *ngIf="!evaluateResponse || this.loading; else evalResponse">
      <div *ngIf="!selected" class="drag-drop-wrapper" fxLayout="column" fxLayoutAlign="center center"
           fxLayoutGap="16px" [class.error-msg]="!!errorMsg"
           appDragDropFileUpload (fileDropped)="onFileDrop($event)"
      >
        <div fxLayoutAlign="center center" class="drag-drop-icon icon-16">
          <mat-icon svgIcon="add-image"></mat-icon>
        </div>
        <h2>{{ errorMsg ? errorMsg : 'Drag and drop' }}</h2>
        <div class="drag-drop-description">Please upload photos that clearly display the face of only one person.
                                           Accepted file types are jpg or png up to 15MB.
        </div>
        <ui-button type="default" [text]="errorMsg ? 'Upload different images' : 'Or upload from your computer'"
                   (clickAction)="fileInput.click()"
        ></ui-button>
        <div>
          <input #fileInput type="file" hidden multiple name="files" (change)="onFileChange($event)"/>
          <!-- <ui-upload #uploadComponent (clickUpload)="upload($event)" [isUploading]="selectIsSaving$ | ngrxPush"></ui-upload> -->
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="file-list-wrapper p-r" *ngIf="selected">
        <div fxLayout="column" fxLayoutAlign="center center" class="loader-wrapper">
          <ui-preloader [color]="PreloaderColor.gray" [diameter]="80">{{ progress }}%</ui-preloader>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngFor="let file of selectedFiles"
             class="w-100"
        >
          <div class="icon-16" fxLayoutAlign="center center">
            <mat-icon svgIcon="images"></mat-icon>
          </div>
          <div>{{ file.name }}</div>
        </div>
      </div>
    </ng-container>
    <ng-template #evalResponse>
      <ng-container *ngIf="data?.multiple; else single">
        <div class="flex-col start-start matching-wrapper gap-16">
          <div *ngIf="multipleSuccess?.length" class="flex-col gap-8">
            <div class="bold">Added images</div>
            @for (item of multipleSuccess; track item.bestImage) {
              <div class="flex-row gap-8 start-center">
                <span class="ui-icon-status-online"></span>
                <img class="multiple-crop" [lazyLoad]="getBestImageUrl(item.bestImage)">
                <ui-truncated-html>{{ trimTs(item?.fileName) }}</ui-truncated-html>
              </div>
            }
          </div>
          <div *ngIf="multipleError?.length" class="flex-col gap-8 multiple-error-wrapper">
            <div class="flex-row start-start gap-8">
              <div class="center-center">
                <span class="ui-icon-status-offline"></span>
              </div>
              <div class="flex-col gap-8 start-start">
                <div class="bold">Some images where not added</div>
                <div>Images should clearly display the face of only one person</div>
                @for (item of multipleError; track item.bestImage) {
                  <div class="flex-row gap-8 start-center">
                    <img class="multiple-crop" [lazyLoad]="getBestImageUrl(item.bestImage)">
                    <ui-truncated-html>{{ trimTs(item?.fileName) }}</ui-truncated-html>
                  </div>
                }
              </div>
            </div>


          </div>
        </div>
      </ng-container>
      <ng-template #single>
        <div fxLayoutAlign="center center" class="matching-wrapper">
          <ng-container *ngIf="searchResponse && !data?.isSearch ; else noMatches">
            <div fxLayout="column" fxLayoutAlign="center center" class="matches w-100" fxLayoutGap="16px">
              <div>Is this the same person?</div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <img [lazyLoad]="bestImageUrl">
                <img style="background: #eee" [src]="img(searchResponse[matchingIdx])">
              </div>
            </div>
          </ng-container>
          <ng-template #noMatches>
            <div fxLayout="column" fxLayoutAlign="center center" class="no-matches w-100">
              <div class="p-r">
                <div class="info-icon svg-fill-gray-600 icon-16" matTooltip="This image is in the best quality
from the images you uploaded" matTooltipClass="sidebar-tooltip"
                >
                  <mat-icon svgIcon="info"></mat-icon>
                </div>
                <img [lazyLoad]="bestImageUrl">
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
    <div *ngIf="!data?.multiple" fxLayout="column" fxLayoutGap="8px" class="name-input-wrapper w-100">
      <div>Person name</div>
      <mat-form-field appearance="outline" class="w-100">
        <input matInput placeholder="Type name"
               [disabled]="!data?.isSearch && searchResponse && !!searchResponse[matchingIdx]?.name"
               [(ngModel)]="name"
        />
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="!(data?.multiple && !evaluateResponseMultiple)"
       fxLayout="row"
       fxLayoutAlign="end center"
       fxLayoutGap="10px"
  >
    <ng-container *ngIf="data?.multiple; else notMultiple">
      <div *ngIf="multipleError?.length" class="flex-row gap-8">
        <ui-button type="default" (clickAction)="reset()"
        >Back to uploader
        </ui-button>
        <ui-button type="primary" (clickAction)="saveMultiple()"
        >Continue anyway
        </ui-button>
      </div>
    </ng-container>
    <ng-template #notMultiple>
      <ui-button *ngIf="!searchResponse || data?.isSearch" colorBgr="#4367F6" colorText="white" text="Done"
                 (click)="save()"
                 [disabled]="!evaluateResponse || !name || loading"
      ></ui-button>
      <ng-container *ngIf="searchResponse && !data?.isSearch">
        <ui-button type="default" text="No" (clickAction)="matchingNo()"
        ></ui-button>
        <ui-button colorBgr="#4367F6" colorText="white" text="It's the same person" (clickAction)="matchingYes()"
        ></ui-button>
      </ng-container>
    </ng-template>
  </div>

</div>

