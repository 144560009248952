import { Component, OnInit } from '@angular/core';
import { AppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppUser } from '../../user/user.model';
import { AuthenticationService } from '../../authentication/authentication.service';
import { InviteSelectors } from '@states/invite/invite.selector-types';
import { InviteWithOrgName } from '@models/invite.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { routerSegments } from '@consts/routes';
import { PreloaderColor } from '@enums/shared.enum';
import User = AppUser.User;
import { UserSelectors } from '@states/user/user.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-organization-layout',
  templateUrl: './organization-layout.component.html',
  styleUrls: ['./organization-layout.component.scss'],
})
export class OrganizationLayoutComponent implements OnInit {
  public preloaderColor = PreloaderColor;

  public user$: Observable<User> = this.store$.select(UserSelectors.userProfile);

  public selectInvites$: Observable<InviteWithOrgName[]> = this.store$.select(InviteSelectors.selectInvites);

  public loading: boolean = true;

  constructor(private store$: Store<AppState>, private authenticationService: AuthenticationService, private router: Router) {
  }

  ngOnInit(): void {
    this.selectInvites$.pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.loading = false;
          if (!res.length) {
            this.router.navigate([`${routerSegments.selectOrganization}/create-organization/step1`]);
          }
        }
      });
  }

  public logout() {
    this.authenticationService.logout();
  }
}
