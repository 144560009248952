import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export enum Quality {
  SQ,
  HQ,
}

@Component({
  selector: 'ui-quality-selector',
  templateUrl: './quality-selector.component.html',
  styleUrls: ['./quality-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualitySelectorComponent),
      multi: true,
    },
  ],
})
export class QualitySelectorComponent implements OnInit {
  /**
   * @deprecated use model instead
   */
  @Output()
  qualityChanged = new EventEmitter<Quality>();

  @Input()
  scale = true;

  @Input()
  public value: Quality;

  Quality: typeof Quality = Quality;

  public loading = false;

  constructor() {
  }

  ngOnInit(): void {
    //if value is not set check scale
    if (isNaN(this.value)) {
      this.value = this.scale ? Quality.SQ : Quality.HQ;
    }
  }

  changeQuality(quality: Quality) {
    if (this.value === quality) {
      return;
    }
    this.loading = true;
    this.value = quality;
    this.qualityChanged.emit(quality);
    this.onChange(this.value);
    this.onTouched();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: Quality): void {
    this.value = val;
  }

  private onChange = (value: Quality) => {
  };
  private onTouched = () => {
  };

}
