import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { createReducer, on } from '@ngrx/store';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';

/**
 * State for store calculated status of devices
 */
export declare interface DeviceStatusState {
  cameraStatusMap: Dictionary<{ edgeId: string, cameraId: string, status: ComponentStatusDisplay }>;
  edgeStatusMap: Dictionary<ComponentStatusDisplay>;
  locationStatusMap: Dictionary<ComponentStatusDisplay>;
}


const initialState: DeviceStatusState = {
  cameraStatusMap: {},
  edgeStatusMap: {},
  locationStatusMap: {},
};

export const deviceStatusStateReducer = createReducer(
  initialState,
  on(DeviceStatusActions.setCamerasStatus, (state, { cameraStatusMap }) => {
    return {
      ...state,
      cameraStatusMap,
    };
  }),
  on(DeviceStatusActions.setEdgesStatus, (state, { edgeStatusMap }) => {
    return {
      ...state,
      edgeStatusMap,
    };
  }),
  on(DeviceStatusActions.setLocationStatus, (state, { locationStatusMap }) => {
    return {
      ...state,
      locationStatusMap,
    };
  }),
  on(DeviceStatusActions.setEdgeStatusById, (state, { edgeId, status }) => {
    return {
      ...state,
      edgeStatusMap: {
        ...state.edgeStatusMap,
        [edgeId]: status,
      },
    };
  }),
  on(DeviceStatusActions.setLocationStatusById, (state, { locationId, status }) => {
    return {
      ...state,
      locationStatusMap: {
        ...state.locationStatusMap,
        [locationId]: status,
      },
    };
  }),
  on(DeviceStatusActions.setCameraStatusById, (state, { cameraId, status }) => {
    return {
      ...state,
      cameraStatusMap: {
        ...state.cameraStatusMap,
        [cameraId]: {
          ...state.cameraStatusMap[cameraId],
          status,
        },
      },
    };
  }),
);
