import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment-timezone';
import { UIInputStyle } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from './ui-datetime-range-picker.model';
import { select, Store } from '@ngrx/store';
import { interval, map, Observable, shareReplay, take } from 'rxjs';
import { DateTimeRangePickerState } from './store/ui-datetime-range-picker.reducer';
import { DateTimeRangePickerSelectors } from './store/ui-datetime-range-picker.selector-types';
import { DateTimeRangePickerActions } from './store/ui-datetime-range-picker.action-types';
import { DatePipe, formatDate } from '@angular/common';
import * as _ from 'lodash';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import PickerType = UiDatetimeRangePickerModel.PickerType;
import ABSOLUTE_DIALOG_WIDTH = UiDatetimeRangePickerModel.ABSOLUTE_DIALOG_WIDTH;
import RELATIVE_DIALOG_WIDTH = UiDatetimeRangePickerModel.RELATIVE_DIALOG_WIDTH;
import SINGLE_DIALOG_WIDTH = UiDatetimeRangePickerModel.SINGLE_DIALOG_WIDTH;
import minuteOptions = UiDatetimeRangePickerModel.minuteOptions;
import hourOptions = UiDatetimeRangePickerModel.hourOptions;
import dayOptions = UiDatetimeRangePickerModel.dayOptions;
import weekOptions = UiDatetimeRangePickerModel.weekOptions;
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { SharedActions } from '@states/shared/shared.action-types';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmptyHeader } from '../ui-calendar/ui-calendar.component';

const MINUTE_MSEC = 60000;
const HOUR_MSEC = MINUTE_MSEC * 60;
const DAY_MSEC = HOUR_MSEC * 24;
const WEEK_MSEC = DAY_MSEC * 7;
const MONTH_MSEC = DAY_MSEC * 30;

@UntilDestroy()
@Component({
  selector: 'ui-datetime-range-picker',
  templateUrl: './ui-datetime-range-picker.component.html',
  styleUrls: ['./ui-datetime-range-picker.component.scss'],
})
/**
 * @deprecated
 * use ui-calendar instead
 */
export class UiDatetimeRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inline = false;
  @Input() limitStart = true;
  @Input() icon = false;


  PickerType: typeof PickerType = PickerType;
  CustomUnit: typeof UiDatetimeRangePickerModel.CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
  customUnits = Object.values(this.CustomUnit);

  pickerState$: Observable<DateTimeRangePickerState> = this.store$.pipe(
    select(DateTimeRangePickerSelectors.selectDateTimeRangePickerState),
  );

  pickerState: DateTimeRangePickerState;

  pickerType: PickerType = PickerType.RELATIVE;

  custom = false;

  public minuteOptions = UiDatetimeRangePickerModel.minuteOptions;
  public hourOptions = UiDatetimeRangePickerModel.hourOptions;
  public dayOptions = UiDatetimeRangePickerModel.dayOptions;
  public weekOptions = UiDatetimeRangePickerModel.weekOptions;

  @ViewChild('selectorInput')
  private selectorInput: ElementRef<HTMLElement>;

  @ViewChild('leftCalendar')
  private leftCalendar: MatCalendar<any>;

  @ViewChild('rightCalendar')
  private rightCalendar: MatCalendar<any>;

  @ViewChild('pickerTemplate')
  private selectorTemplate: TemplateRef<any>;

  @ViewChild('dialogContainer')
  private dialogContainer: ElementRef<HTMLElement>;

  @Output() onRangeSelected: EventEmitter<{ start: string; end: string }> = new EventEmitter<{ start: string; end: string }>();
  @Output() pickerOpen: EventEmitter<void> = new EventEmitter<void>();

  @Input() inputStyle: UIInputStyle = UIInputStyle.labelInside;

  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;

  public lastStartDate: Date;

  @Input() label: string = 'Dates';

  @Input() isRelative = true;

  @Input()
  disabled = false;

  @Input()
  timezone: string;

  @Input() single = false;

  @Input() showCurrentTime = false;

  @Input() timeFormat = 'MMM, DD. h:mm:ss A';

  public startDateView: string;
  public endDateView: string;

  public startTime: string;
  public endTime: string;

  public selectedRange: string[] = [];

  public startDateLeftCalendar: Date;
  public startDateRightCalendar: Date;

  public inputStyles = UIInputStyle;
  public opened = false;
  public dialogRef: MatDialogRef<any>;
  public selectorItemsViewportHeight: number = 400;
  public clickCounter = 1;

  public now: Date;

  public headerComponent = EmptyHeader;

  private dialogOpening = false;
  private calendarModalWidth: number = +RELATIVE_DIALOG_WIDTH.replace('px', '');
  private clickOutsideEventHandler: (event: any) => void;

  public currentTime: string = moment()
    .format(this.timeFormat);

  public maskChanges = false;

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private window: Window,
    private store$: Store,
    private camerasThumbnailsService: CamerasThumbnailsService,
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickOutsideEvent(event: MouseEvent) {
    if (this.clickOutsideEventHandler) {
      this.clickOutsideEventHandler(event);
    }
  }

  @HostListener('window:resize', ['$event'])
  public resize() {
    if (this.opened) {
      this.dialogRef.close();
      setTimeout(() => {
        this.toggleDialog();
      }, 500);
    }
  }

  public ngOnInit() {
    if (!this.isRelative) {
      this.pickerType = PickerType.ABSOLUTE;
      this.pickerState.pickerType = PickerType.ABSOLUTE;
      this.calendarModalWidth = +(
        this.pickerState.pickerType === PickerType.ABSOLUTE ? ABSOLUTE_DIALOG_WIDTH : RELATIVE_DIALOG_WIDTH
      ).replace('px', '');
    }

    if (this.single) {
      this.pickerState.pickerType = PickerType.ABSOLUTE;
      this.pickerType = PickerType.ABSOLUTE;
    }
    interval(1000) // Update every second
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        const now = this.convertTsToCameraZone(Date.now());
        this.currentTime = moment(now)
          .format(this.timeFormat);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.maskChanges) {
      // if (changes['startDate'].currentValue?.toString() !== changes['startDate'].previousValue?.toString()) {
      //   this.startDate = changes['startDate'].previousValue;
      // }
      return;
    }
    if (changes['startDate']) {
      this.lastStartDate = changes['startDate'].currentValue;
    }
    this.pickerState$.pipe(untilDestroyed(this), shareReplay(), take(1))
      .subscribe(pickerState => {
        this.pickerState = _.cloneDeep(pickerState);
        this.custom = this.pickerState.pickerCustom;
      });

    this.now = this.timezone ? new Date(this.convertTsToZone(Date.now(), moment.tz.guess(), this.timezone)) : new Date();

    if (!this.startDate) {
      const defaultDateStart = moment(this.now)
        .subtract(2, 'day');
      this.startDate = moment(defaultDateStart)
        .toDate();
    }

    if (!this.endDate) {
      const defaultDateEnd = moment(this.now)
        .add(1, 'day');
      this.endDate = moment(defaultDateEnd)
        .toDate();
    }

    // if (moment(this.endDate).isAfter(this.now)) {
    //   this.endDate = this.now;
    //   this.endDate = moment(this.endDate).hours(0).toDate();
    //   this.endDate = moment(this.endDate).minutes(0).toDate();
    // }

    const startDate = this.timezone ? this.convertTsToCameraZone(this.startDate.getTime()) : this.startDate.getTime();
    const endDate = this.timezone ? this.convertTsToCameraZone(this.endDate.getTime()) : this.endDate.getTime();

    this.startTime = moment(startDate)
      .format('HH:mm');
    this.endTime = moment(endDate)
      .format('HH:mm');

    this.startDateView = moment(startDate)
      .format(this.timeFormat);
    this.endDateView = moment(endDate)
      .format(this.timeFormat);

    this.generateSelectedRange();
  }

  public dateClass = (date: Date) => {
    const formattedDate = moment(date)
      .format('DD-MM-YYYY');
    if (moment(this.startDate)
      .format('DD-MM-YYYY') == formattedDate) {
      if (this.endDate) {
        return 'selected-limit selected-limit-left';
      } else {
        return 'selected-limit-no-bg';
      }
    } else if (moment(this.endDate)
      .format('DD-MM-YYYY') == formattedDate) {
      return 'selected-limit selected-limit-right';
    } else if (this.selectedRange.indexOf(formattedDate) !== -1) {
      return 'selected-date-range';
    } else {
      return '';
    }
  };

  public dateChanged(ev: Date) {
    if (!this.single) {
      switch (this.clickCounter) {
        case 1:
          this.endDate = null;
          this.startDate = ev;
          this.clickCounter++;
          break;
        case 2:
          const isToday = moment(ev)
            .isSame(moment(), 'day');
          if (moment(ev)
            .isBefore(this.startDate)) {
            this.startDate = ev;
            this.clickCounter++;
          } else if (isToday) {
            const now = moment();
            this.endDate = ev;
            this.endDate = moment(this.endDate)
              .hours(now.get('hours'))
              .toDate();
            this.endDate = moment(this.endDate)
              .minutes(now.get('minutes'))
              .toDate();
            this.endTime = moment(this.now)
              .format('HH:mm');
          } else {
            this.endDate = ev;
          }
          this.clickCounter--;
          break;
        default:
          throw Error('Impossible case');
      }
    } else {
      this.endDate = null;
      this.startDate = ev;
    }
    this.generateSelectedRange();
    this.leftCalendar.updateTodaysDate();
    if (!this.single) {
      this.rightCalendar.updateTodaysDate();
    }
  }

  public emitDate() {
    if (this.pickerState.pickerType === PickerType.RELATIVE && !this.single) {
      this.pickerState.pickerCustom = this.custom;
      this.store$.dispatch(
        DateTimeRangePickerActions.setPickerState({
          dateTimeRangePickerState: _.cloneDeep(this.pickerState),
        }),
      );
      // Calculate relative time in msecs
      let relativeTime = 0;
      if (this.custom) {
        let multiplyer = 1;
        switch (this.pickerState.customUnit) {
          case UiDatetimeRangePickerModel.CustomUnit.minutes:
            multiplyer = MINUTE_MSEC;
            break;
          case UiDatetimeRangePickerModel.CustomUnit.hours:
            multiplyer = HOUR_MSEC;
            break;
          case UiDatetimeRangePickerModel.CustomUnit.days:
            multiplyer = DAY_MSEC;
            break;
          case UiDatetimeRangePickerModel.CustomUnit.weeks:
            multiplyer = WEEK_MSEC;
            break;
          // case UiDatetimeRangePickerModel.CustomUnit.months:
          //   multiplyer = MONTH_MSEC;
          //   break;
        }
        relativeTime = (this.pickerState.custom ?? 180) * multiplyer;
      } else {
        relativeTime =
          (this.pickerState.minutes !== undefined ? minuteOptions[this.pickerState.minutes] * MINUTE_MSEC : 0) +
          (this.pickerState.hours !== undefined ? hourOptions[this.pickerState.hours] * HOUR_MSEC : 0) +
          (this.pickerState.days !== undefined ? dayOptions[this.pickerState.days] * DAY_MSEC : 0) +
          (this.pickerState.weeks !== undefined ? weekOptions[this.pickerState.weeks] * WEEK_MSEC : 0);
      }
      const nowTs = this.timezone ? this.convertTsToZone(new Date().getTime(), this.timezone, moment.tz.guess()) : new Date().getTime();
      this.endDate = new Date(nowTs);
      this.startDate = new Date(nowTs - relativeTime);

      this.startDateView = formatDate(this.startDate, this.timeFormat, 'en-US');
      this.endDateView = formatDate(this.endDate, this.timeFormat, 'en-US');

      this.onRangeSelected.emit({
        start: formatDate(this.startDate, 'YYYY-MM-dd HH:mm:ss zzzz', 'en-US'),
        end: formatDate(this.endDate, 'YYYY-MM-dd HH:mm:ss zzzz', 'en-US'),
      });

      this.dialogRef.close();
      return;
    }
    this.lastStartDate = this.startDate;
    const startTimeSplit = this.startTime.split(':');
    const endTimeSplit = this.endTime.split(':');
    this.startDate = moment(this.startDate)
      .hours(0)
      .toDate();
    this.startDate = moment(this.startDate)
      .minutes(0)
      .toDate();

    const outputStartDateHrs = moment(this.startDate)
      .add(startTimeSplit[0], 'hours');
    const outputStartDateMinutes = outputStartDateHrs.add(startTimeSplit[1], 'minutes');
    const origEnd = `${this.endDate}`;
    this.endDate = moment(this.endDate)
      .hours(0)
      .toDate();
    this.endDate = moment(this.endDate)
      .minutes(0)
      .toDate();
    // Handle daylight saving time switches
    const origDST = moment(origEnd)
      .isDST();
    const endDST = moment(this.endDate)
      .isDST();

    let dstDelta = 0;
    if (origDST === false && endDST === true) {
      dstDelta = 1;
    }
    if (origDST === true && endDST === false) {
      dstDelta = -1;
    }

    const outputEndDateHrs = moment(this.endDate)
      .add(+endTimeSplit[0] + dstDelta, 'hours');

    const outputEndDateMinutes = outputEndDateHrs.add(endTimeSplit[1], 'minutes');

    this.startDateView = outputStartDateMinutes.format(this.timeFormat);
    this.endDateView = outputEndDateMinutes.format(this.timeFormat);

    const isFuture = this.isStartToday && this.futureHour;

    if (isFuture) {
      this.store$.dispatch(SharedActions.showMessage({ warning: 'Start time cannot be in the future' }));
      return;
    }

    if (!this.single) {
      this.onRangeSelected.emit({
        start: formatDate(outputStartDateMinutes.toDate(), 'YYYY-MM-dd HH:mm:ss zzzz', 'en-US'),
        end: formatDate(outputEndDateMinutes.toDate(), 'YYYY-MM-dd HH:mm:ss zzzz', 'en-US'),
      });
    } else {
      this.onRangeSelected.emit({
        start: formatDate(outputStartDateMinutes.toDate(), 'YYYY-MM-dd HH:mm:ss zzzz', 'en-US'),
        end: null,
      });
    }

    this.dialogRef.close();
  }

  private generateSelectedRange() {
    this.selectedRange = [];
    let startRangeDate = moment(this.startDate);
    if (this.endDate) {
      const endRangeDate = moment(this.endDate);
      while (startRangeDate.isSameOrBefore(endRangeDate)) {
        this.selectedRange.push(startRangeDate.format('DD-MM-YYYY'));
        startRangeDate = moment(startRangeDate)
          .add(1, 'day');
      }
    } else {
      this.selectedRange.push(startRangeDate.format('DD-MM-YYYY'));
    }
  }

  public toggleDialog(): void {
    this.maskChanges = true;
    if (!this.dialogOpening) {
      this.dialogOpening = true;

      if (!this.opened) {
        const position = this.getPosition();
        /**
         * If date is now - left calendar one month before now, right - current month
         * if start date before than now - 1month, show start date
         */
        if (moment(this.startDate)
          .isBefore(moment(this.now)
            .subtract(1, 'month'))) {
          this.startDateLeftCalendar = moment(this.startDate)
            .toDate();
        } else {
          this.startDateLeftCalendar = moment(this.now)
            .subtract(1, 'month')
            .toDate();
        }
        this.startDateRightCalendar = moment(this.startDateLeftCalendar)
          .add(1, 'month')
          .toDate();

        this.dialogRef = this.dialog.open(this.selectorTemplate, {
          hasBackdrop: false,
          width: this.single ? SINGLE_DIALOG_WIDTH : this.pickerState.pickerType === PickerType.ABSOLUTE ? ABSOLUTE_DIALOG_WIDTH : RELATIVE_DIALOG_WIDTH,
          position,
          panelClass: 'ui-selector-panel',
          closeOnNavigation: true,
        });

        this.dialogRef
          .afterOpened()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = this.closeDialogByClickOutsideEvent;
            this.cdr.detectChanges();
            setTimeout(() => {
              // Fix some css issues when it's fast loads.
              this.opened = true;
            });
            this.dialogOpening = false;
            window.addEventListener('scroll', this.scroll.bind(this), true);
            if (!this.startDate) {
              this.startDate = this.lastStartDate;
              this.endDate = this.lastStartDate;
            }
          });

        this.dialogRef
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = null;
            this.opened = false;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            this.maskChanges = false;
            window.removeEventListener('scroll', this.scroll, true);
          });
      }
    }
  }

  public validateTime(ev: string) {
    if (moment(this.endDate)
      .format('DD-MM-YYYY') === moment(this.now)
      .format('DD-MM-YYYY')) {
      const selectedTimeSplit = ev.split(':');
      // if (parseInt(selectedTimeSplit[0]) > moment(this.now).hours()) {
      //   this.endTime = moment(this.now).format('HH:mm');
      // } else if (parseInt(selectedTimeSplit[1]) > moment(this.now).minutes()) {
      //   this.endTime = moment(this.now).format('HH:mm');
      // }
    }
  }

  /**
   * Position of calendar
   * Should be:
   * 1. If space bottom smaller than calendar height should open to top
   * 2. If space top smaller than calendar height should open to bottom
   * 3. If space right smaller than calendar width should open to left
   * 4. If space left smaller than calendar width should open to right
   * @private
   */
  private getPosition(): DialogPosition {
    const smallScreen = this.window.innerHeight < 900;

    const elementRectangle = this.selectorInput.nativeElement.getBoundingClientRect();
    const bottomSpace = this.window.innerHeight - elementRectangle.bottom;

    let panelHeight = this.selectorItemsViewportHeight;
    let top = elementRectangle.bottom;
    let left = Math.max(elementRectangle.left, 5);
    let right = Math.max(this.window.innerWidth - elementRectangle.right, 5);
    let smallerLeft = this.window.innerWidth - left > this.calendarModalWidth;
    let smallerRight = this.window.innerWidth - left < +ABSOLUTE_DIALOG_WIDTH.replace('px', '');

    /**
     * If space left smaller than calendar width should open to right
     */
    if (smallerLeft) {
      left = elementRectangle.left;
    } else if (smallerRight) {
      /**
       *  If space right smaller than calendar width should open to left
       */
      left = elementRectangle.right - this.calendarModalWidth;
    }
    panelHeight += 10;
    if (panelHeight < bottomSpace) {
      if (smallScreen) {
        top -= 60;
      }
      if (smallerRight) {
        return {
          right: `${right}px`,
          top: `${top}px`,
        };
      }
      return {
        left: `${left}px`,
        top: `${top}px`,
      };
    } else {
      let top = elementRectangle.top - panelHeight;
      if (top < 5) {
        top = 5;
      }
      if (smallerRight) {
        return { right: `${right}px`, top: `${top}px` };
      }
      return { left: `${elementRectangle.left}px`, top: `${top}px` };
    }
  }

  private closeDialogByClickOutsideEvent(event: MouseEvent) {
    const matDialogContainerElement = this.dialogContainer?.nativeElement?.parentElement;
    const elementRectangle = matDialogContainerElement?.getBoundingClientRect();
    if (
      event?.clientX <= elementRectangle?.left ||
      event?.clientX >= elementRectangle?.right ||
      event?.clientY <= elementRectangle?.top ||
      event?.clientY >= elementRectangle?.bottom
    ) {
      this.dialogRef.close();
    }
  }

  private scroll(): void {
    if (this.dialogRef && this.opened) {
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }
  }

  public ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  changeType(pickerType: PickerType) {
    switch (pickerType) {
      case PickerType.ABSOLUTE:
        if (!this.inline) {
          this.dialogRef.updateSize(ABSOLUTE_DIALOG_WIDTH);
          this.calendarModalWidth = +ABSOLUTE_DIALOG_WIDTH.replace('px', '');
        }
        // this.pickerType = PickerType.ABSOLUTE;
        break;
      case PickerType.RELATIVE:
        if (!this.inline) {
          this.dialogRef.updateSize(RELATIVE_DIALOG_WIDTH);
          this.calendarModalWidth = +RELATIVE_DIALOG_WIDTH.replace('px', '');
        }
        // this.pickerType = PickerType.RELATIVE;
        break;
    }
    this.pickerState.pickerType = pickerType;
    this.selectorItemsViewportHeight = pickerType === PickerType.ABSOLUTE ? 570 : 400;
    this.store$.dispatch(DateTimeRangePickerActions.setPickerType({ pickerType }));
    if (!this.inline) {
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }
  }

  resetTimes() {
    this.pickerState.minutes = undefined;
    this.pickerState.hours = undefined;
    this.pickerState.days = undefined;
    this.pickerState.weeks = undefined;
  }

  selectMinutes(idx: number) {
    if (idx === this.pickerState.minutes) {
      this.pickerState.minutes = undefined;
    } else {
      this.resetTimes();
      this.pickerState.minutes = idx;
    }
    this.updateCustom();
  }

  selectHours(idx: number) {
    if (idx === this.pickerState.hours) {
      this.pickerState.hours = undefined;
    } else {
      this.resetTimes();
      this.pickerState.hours = idx;
    }
    this.updateCustom();
  }

  selectDays(idx: number) {
    if (idx === this.pickerState.days) {
      this.pickerState.days = undefined;
    } else {
      this.resetTimes();
      this.pickerState.days = idx;
    }
    this.updateCustom();
  }

  selectWeeks(idx: number) {
    if (idx === this.pickerState.weeks) {
      this.pickerState.weeks = undefined;
    } else {
      this.resetTimes();
      this.pickerState.weeks = idx;
    }
    this.updateCustom();
  }

  convertTsToZone(ts: number, fromZone: string, toZone: string) {
    return this.camerasThumbnailsService.convertTsToZone(ts, fromZone, toZone);
  }

  public convertTsToCameraZone(ts: number) {
    return this.camerasThumbnailsService.convertTsToZone(ts, this.timezone!, moment.tz.guess());
  }

  get relativeTimeMsec() {
    return (
      (this.pickerState.minutes !== undefined ? minuteOptions[this.pickerState.minutes] * MINUTE_MSEC : 0) +
      (this.pickerState.hours !== undefined ? hourOptions[this.pickerState.hours] * HOUR_MSEC : 0) +
      (this.pickerState.days !== undefined ? dayOptions[this.pickerState.days] * DAY_MSEC : 0) +
      (this.pickerState.weeks !== undefined ? weekOptions[this.pickerState.weeks] * WEEK_MSEC : 0)
    );
  }

  updateCustom() {
    this.custom = false;
    this.pickerState.customUnit = CustomUnit.minutes;
    this.pickerState.custom = this.relativeTimeMsec / 1000 / 60;
  }

  setCustom() {
    this.resetTimePickers();
    this.custom = true;
  }

  resetTimePickers() {
    this.pickerState = {
      ...this.pickerState,
      minutes: undefined,
      hours: undefined,
      days: undefined,
      weeks: undefined,
    };
  }

  public timezoneAbbreviation() {
    if (this.timezone) {
      const zone = moment()
        .tz(this.timezone)
        .format('z');
      return zone;
    }
    const userZone = moment()
      .tz(moment.tz.guess())
      .format('z');
    return userZone;
  }

  public get isFuture() {
    return new Date(this.startDate).getTime() - new Date().getTime() > 0;
  }

  public get isStartToday() {
    return moment.tz(this.startDate, this.timezone)
      .isSame(moment(), 'day');
  }

  public get futureHour() {
    const hourNow = moment.tz(this.timezone)
      .format('HH:mm');
    const nowMinutes = moment.duration(hourNow)
      .asMinutes();
    const startMinutes = moment.duration(this.startTime)
      .asMinutes();
    return startMinutes - nowMinutes >= -2;
  }
}
