import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../edge/utils.service';

@Pipe({
  name: 'cameraThumbnailImagePipe',
})
export class CameraThumbnailImagePipe implements PipeTransform {
  constructor(
    private utilsService: UtilsService) {
  }

  transform(value: { edgeId: string, cameraId: string }): string {
    return this.utilsService.cameraThumbnailImage(value.edgeId, value.cameraId)
  }
}
