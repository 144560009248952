<div fxFlexFill>
  <div class="inner">
    <img alt="logo" src="assets/lumana_logo.svg"/>
    <ng-container [ngSwitch]="template$|ngrxPush">
      <ng-container *ngSwitchDefault>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="description-block">
            <div class="level-1">404 error</div>
            <div class="level-2">Page not found...</div>
            <div class="level-3">
              Sorry, the page you are looking for doesn't exist or has been moved. Try searching our site:
            </div>
            <ui-button type="primary"
                       text="Go back"
                       class="back-btn"
                       (clickAction)="back()"
            ></ui-button>
          </div>
          <div>
            <img class="icon" alt="icon" src="assets/not-found-icon.svg"/>

          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="notFoundTemplates.InviteNotFound">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="description-block">
            <div class="level-1">404 error</div>
            <div class="level-2">Invite is not found or expired</div>
            <ui-button type="primary"
                       text="Login"
                       class="back-btn"
                       (clickAction)="login()"
            ></ui-button>
          </div>
          <div>
            <img class="icon" alt="icon" src="assets/not-found-icon.svg"/>

          </div>
        </div>

      </ng-container>
      <ng-container *ngSwitchCase="notFoundTemplates.InviteAccepted">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="description-block">
            <div class="level-2">Invite is already accepted</div>
            <ui-button type="primary"
                       text="Login"
                       class="back-btn"
                       (clickAction)="login()"
            ></ui-button>
          </div>
          <div>
            <img class="icon" alt="icon" src="assets/not-found-icon.svg"/>

          </div>
        </div>

      </ng-container>
    </ng-container>

  </div>
</div>
