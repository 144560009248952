import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StateChange } from "ng-lazyload-image";

@Component({
  selector: 'ui-snapshot-v2',
  templateUrl: './ui-snapshot-v2.component.html',
  styleUrls: ['./ui-snapshot-v2.component.scss'],
})
export class UiSnapshotV2Component implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('container') container: ElementRef;
  @Output() onLoaded = new EventEmitter<boolean>();
  @Input() src: string;
  img: string;
  @Input() intervalMs = 1000;
  public loaded = false;

  passed = 0;

  interval;

  constructor(public cd: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']) {
      this.img = this.src;
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.img = this.src;
  }

  reload() {
    this.img = null;
    setTimeout(_ => {
      this.img = this.src;
      this.cd.detectChanges();
    }, 0)

  }

  public onLoad() {
    this.loaded = true;
    this.onLoaded.emit();
    clearInterval(this.interval);
  }

  public onError() {
    this.loaded = false;
    this.img = this.src;
  }

  public ngAfterViewInit(): void {
    this.img = this.src;
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.loaded = true;
        this.onLoaded.emit()
        break;
      case 'loading-failed':
        this.loaded = false;
        this.img = null;
        setTimeout(_ => {
          this.img = this.src;
        }, this.intervalMs);
        break;
    }
  }
}
