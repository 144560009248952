export enum ConfirmDialogSelection {
  CANCEL,
  OK,
}

export enum ConfirmDialogType {
  INFO,
  WARN,
  CONFIRM,
}

export interface ConfirmDialogOptions {
  disableClose?: boolean;
  type: ConfirmDialogType;
  title: string;
  msg: string;
  description?: string;
  confirm?: string;
  cancel?: string;
  hideCancel?: boolean;
  action?: () => {};
  cancelAction?: () => {};
  returnOnClose?: ConfirmDialogSelection;
  params?: {};
}
