import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { YoutubeModel } from '@models/youtube.model';
import { WallV2Model } from '@models/wall-v2.model';
import { JsonParseIfValid } from '../helpers/common.helpers';
import { Dictionary } from '@ngrx/entity/src/models';

@Injectable()
export class YoutubeService {

  constructor(private http: HttpClient) {
  }

  public create(data: YoutubeModel.YoutubeCreateDto): Observable<YoutubeModel.YoutubeMongoDocument> {
    return this.http.post<YoutubeModel.YoutubeMongoDocument>(api.youtube.crud, data);
  }

  public getAllByFilters(): Observable<YoutubeModel.YoutubeMongoDocument[]> {
    return this.http.get<YoutubeModel.YoutubeMongoDocument[]>(api.youtube.crud);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${api.youtube.crud}/${id}`);
  }
}
