import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { UserSettings } from '@models/user-settings';
import { AppUser } from '../user/user.model';

@Injectable()
export class UserSettingsService {
  constructor(private http: HttpClient) {}

  public create(data: UserSettings): Observable<any> {
    return this.http.post(api.userSettings.crud, data);
  }

  public me(): Observable<any> {
    return this.http.get(api.userSettings.me);
  }

  public update(data: UserSettings): Observable<any> {
    return this.http.put(api.userSettings.crud, data);
  }

  public verifyPhone(phone: string): Observable<any> {
    return this.http.post(api.verification.crud, { phone });
  }

  public sendCode(data: { confirmationCode: number; verificationId: string }): Observable<AppUser.User> {
    return this.http.put<AppUser.User>(api.verification.one(data.verificationId), data);
  }

  public mutePushMobile(data: { confirmationCode: number; verificationId: string }): Observable<AppUser.User> {
    return this.http.patch<AppUser.User>(api.userSettings.crud, data);
  }
}
