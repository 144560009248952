import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Invite } from '@models/invite.model';
import { select, Store } from '@ngrx/store';
import * as InviteSelectors from '@states/invite/invite.selectors';
import * as InviteActions from '@states/invite/invite.actions';
import { SharedActions } from '@states/shared/shared.action-types';
import { ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { SharedEffects } from '@effects/shared.effects';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
;
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { InviteUsersModalComponent } from '../invite-users-modal/invite-users-modal.component';
import { Dictionary } from '@ngrx/entity/src/models';

@UntilDestroy()
@Component({
  selector: 'app-pending-invitations-modal',
  templateUrl: './pending-invitations-modal.component.html',
  styleUrls: ['./pending-invitations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingInvitationsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectOrganizationInvites$: Observable<Invite[]> = this.store$.pipe(select(InviteSelectors.selectOrganizationInvites));
  public selectIsInitialLoaded$: Observable<boolean> = this.store$.pipe(select(InviteSelectors.selectIsInitialLoaded));
  public loaderColor = PreloaderColor;
  public noResults = features.pendingInvitations.noData;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  public viewPortHeight: number;

  constructor(private store$: Store,
              private sharedEffects$: SharedEffects,
              private dialogRef: MatDialogRef<PendingInvitationsModalComponent>,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.store$.dispatch(InviteActions.getInvitesByOrgId());
    this.sharedEffects$.confirmation$
      .pipe(
        untilDestroyed(this),
        ofType(SharedActions.showConfirmModalResultConfirm),
      )
      .subscribe(result => {
        if (result?.params['revokeId']) {
          this.store$.dispatch(InviteActions.removeInvite({ inviteId: result?.params['revokeId'] }));
        }
        if (result?.params['revokeAll']) {
          this.store$.dispatch(InviteActions.revokeAll());
        }
        if (result?.params['resendAll']) {
          this.store$.dispatch(InviteActions.resendAll());
        }

      });
  }

  public ngAfterViewInit() {
    this.viewPortHeight = this.scrollContainer.nativeElement.offsetHeight;
  }

  public resendInvite(inviteId: string) {
    this.store$.dispatch(InviteActions.resendInvite({ inviteId }));
  }

  public cancelInvite(inviteId: string) {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to revoke invite?`,
          title: `Revoke all`,
          confirm: 'Yes I\'m sure',
          disableClose: true,
          params: {
            revokeId: inviteId,
          },
        },
      }),
    );
  }

  public revokeAll() {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to revoke all invites?`,
          title: `Revoke all`,
          confirm: 'Yes I\'m sure',
          disableClose: true,
          params: {
            revokeAll: true,
          },
        },
      }),
    );
  }

  public resendAll() {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to resend all invites?`,
          title: `Resend all`,
          confirm: 'Yes I\'m sure',
          disableClose: true,
          params: {
            resendAll: true,
          },
        },
      }),
    );
  }

  public search(query: string) {
    this.store$.dispatch(InviteActions.setQueryParams({ query }));
  }

  public close(): void {
    this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.store$.dispatch(InviteActions.resetToInitialState());
  }


  public openInviteUsersModal(): void {
    this.dialog.open(InviteUsersModalComponent, {
        panelClass: 'modal-no-padding',
        width: '572px',
      })
      .afterClosed()
      .subscribe((invites: Dictionary<string>) => {
        if (Object.keys(invites)?.length) {
          this.store$.dispatch(InviteActions.sendInvites({ invites }));
        }
      });
  }
}
