import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userAvatar',
})
export class UserAvatarPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const valueArr = value?.split(' ');
    if (valueArr?.length > 1) {
      return `${valueArr[0].substring(0, 1)}${valueArr[1].substring(0, 1)}`;
    } else {
      return value.substring(0, 2);
    }
  }
}
