import { Component, Inject, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;

@Component({
  selector: 'ui-title-popup',
  templateUrl: './ui-title-popup.component.html',
  styleUrls: ['./ui-title-popup.component.scss'],
})
export class UiTitlePopupComponent implements OnInit {
  public inputStyles = UIInputStyle;

  public name: string;
  public isPublic: boolean = true;

  constructor(private dialogRef: MatDialogRef<UiTitlePopupComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                name: string,
                isPublic: boolean
              }) {
    if (data.name) {
      this.name = this.data.name;
    }
    if (typeof this.data?.isPublic !== 'undefined') {
      this.isPublic = this.data?.isPublic;
    }

  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close({
      name: this.name,
      isPublic: this.isPublic,
    });
  }
}
