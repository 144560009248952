<div fxLayout="row" class="bar-container p-r">
  <ng-container>
    <div fxFlex="25" class="camera-name" fxLayout="row" fxLayoutAlign="start center">
      <span class="ui-icon-camera"></span>
      {{ camera.edgeOnly.name }}
    </div>
    <div fxFlex="75" fxLayout="row" *ngIf="uptime; else checkLoader">
      <div
        #bar
        *ngFor="let online of uptime; let i = index"
        class="bar"
        fxLayout="column"
        (mouseenter)="bar.classList.add('over')"
        (mouseleave)="bar.classList.remove('over')"
      >
        <div *ngIf="!createdAt || endTs(i) >= createdAt" class="offline"
             [style.background-color]="uiBarColors[uiUptimeBarColors.offline]"
             [style.height]="(100 - online) + 'px'"
        ></div>
        <div *ngIf="!createdAt || endTs(i) >= createdAt" class="online"
             [style.background-color]="uiBarColors[uiUptimeBarColors.online]"
             [style.height]="online + 'px'"
        ></div>
      </div>
    </div>
  </ng-container>
  <ng-template #loader>
    <ui-preloader [color]="preloaderColor.blue" [diameter]="25"></ui-preloader>
  </ng-template>

  <ng-template #checkLoader>
    <ng-container *ngIf="isLoaded$|async; else loader">
      <div class="w-100 h-100" [style.background-color]="uiBarColors[uiUptimeBarColors.offline]"></div>
    </ng-container>
  </ng-template>
</div>
