<div class="select-camera-container" fxFlex>
  <ng-container *ngIf="edgeId && cameraId || loading else selectCamera">
    <ng-container *ngIf="imageSection && !loading">
      <ng-container *ngIf="error; else noError">
        <div fxLayout="row"
             fxLayoutAlign="center center"
             class="w-100 h-100"
             matTooltip="Retry"
             (click)="initThumbnails()"
        >
          <mat-icon>refresh</mat-icon>
        </div>
      </ng-container>
      <ng-template #noError>
        <div class="thumbnail-time-overlay" *ngIf="imageSection">{{time}} {{timezoneAbbreviation}}</div>
        <div fxLayout="column" fxLayoutAlign="center center" class="thumbnail-offline-overlay"
             [ngClass]="{ show: offline }"
        >
          <div *ngIf="offline">Image unavailable</div>
        </div>
        <ng-container *ngrxLet="selectPositions$; let positions">
          <div fxLayoutAlign="center center"
               class="camera-delete c-p icon-18"
               *ngIf="positions[cameraIdx]"
               (click)="deleteCamera(); $event.stopPropagation()"
          >
            <span class="ui-icon-close"></span>
          </div>
        </ng-container>
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="8px"
             class="camera-name"
             *ngIf="cameraIdx || cameraIdx === 0"
        >
          <!--          <div class="camera-color" [style.background-color]="ThumbnailHistogramColor[cameraIdx]"></div>-->
          <div>{{cameraName}}</div>
        </div>
        <div fxLayoutAlign="center center"
             class="svg-fill-primary w-100 h-100 image-loader"
             *ngIf="imageLoader && !offline"
        >
          <!--          <mat-spinner [diameter]="35"></mat-spinner>-->
          <ui-preloader [diameter]="45" [color]="PreloaderColor.blue"></ui-preloader>
        </div>
        <img *ngIf="imageSection && _reloadImage"
             #image
             class="card-image"
             [style.max-height]=""
             [lazyLoad]="img"
             (onStateChange)="imgStateChange($event)"
             [style.position]="relative? 'relative' : 'absolute'"
        />
      </ng-template>
    </ng-container>
    <ng-container *ngIf="imageSection && loading">
      <div fxLayoutAlign="center center" class="c-p svg-fill-primary no-camera-wrapper w-100 h-100">
        <mat-spinner [diameter]="28"></mat-spinner>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #selectCamera>
  <ng-container *ngIf="!withAlerts">
    <div fxLayoutAlign="center center" class="c-p svg-fill-primary no-camera-wrapper w-100 h-100 icon-18"
         fxFill (click)="select.emit(thumbnailImageSelectorTypes.Camera)"
    >
      <mat-icon svgIcon="video-cam"></mat-icon>
      <div class="ml-10">Select Camera</div>
    </div>
  </ng-container>

  <ng-container *ngIf="withAlerts">
    <div fxLayoutAlign="center center" class="c-p svg-fill-primary no-camera-wrapper w-100 h-100 icon-18"
         fxFill
    >
      <div *ngIf="!index" fxLayout="column" class="alert-camera-select-wrapper">
        <div class="title">Choose a tile type</div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top: 12px;">
          <div fxLayout="row">
            <mat-icon svgIcon="alerts"></mat-icon>
            <div class="ml-10" (click)="select.emit(thumbnailImageSelectorTypes.Alert)">Alerts</div>
          </div>
          <div fxLayout="row">
            <mat-icon svgIcon="video-cam"></mat-icon>
            <div class="ml-10" (click)="select.emit(thumbnailImageSelectorTypes.Camera)">Camera</div>
          </div>
        </div>
      </div>
      <div *ngIf="index" fxLayout="column" class="alert-camera-select-wrapper">
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-icon svgIcon="alerts" (click)="select.emit(thumbnailImageSelectorTypes.Alert)"></mat-icon>
          <mat-icon svgIcon="video-cam" (click)="select.emit(thumbnailImageSelectorTypes.Camera)"></mat-icon>
        </div>
      </div>
    </div>
  </ng-container>

</ng-template>

