import { createReducer, on } from '@ngrx/store';
import * as SharedActions from '@states/shared/shared.actions';
import { KeyValuePairs } from '../../../core/interfaces';

export declare interface SharedState {
  isSaving: boolean;
  isInviting: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  somethingWentWrong: boolean;
  isSessionDataLoading: boolean;
  isInactive: boolean;
  inactivityTime: number;
  inactivityCountdown: number;
  isApplicationLoaded: boolean;
  processingId: string; // variable for detect which exactly record is saving/deleting or e.t.c.
  permissions: {
    permissions: string[],
    entityIds: string[]
  };
  locks: KeyValuePairs<boolean>;
}

export const initialState: SharedState = {
  isSaving: false,
  isInviting: false,
  isLoading: false,
  isDeleting: false,
  somethingWentWrong: false,
  isSessionDataLoading: false,
  isInactive: false,
  inactivityTime: 0,
  inactivityCountdown: 0,
  isApplicationLoaded: false,
  processingId: null,
  permissions: {
    'permissions': [
      'CoreCreate',
      'CoreEdit',
      'CoreDelete',
      'LocationCreate',
      'LocationDelete',
      'location:631d899d32b1b5c5c0c63510:LocationEdit',
    ],
    'entityIds': [
      '631d899d32b1b5c5c0c63510',
    ],
  },
  locks: {},
};

export const sharedStateReducer = createReducer(
  initialState,
  on(SharedActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(SharedActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(SharedActions.setInactivityTime, (state, { inactivityTime }) => {
    return {
      ...state,
      inactivityTime,
    };
  }),
  on(SharedActions.setInactivityCountdown, (state, { inactivityCountdown }) => {
    return {
      ...state,
      inactivityCountdown,
    };
  }),
  on(SharedActions.setIsInviting, (state, { isInviting }) => {
    return {
      ...state,
      isInviting,
    };
  }),
  on(SharedActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(SharedActions.setSomethingWentWrong, (state, { somethingWentWrong }) => {
    return {
      ...state,
      somethingWentWrong,
    };
  }),
  on(SharedActions.setIsDeleting, (state, { isDeleting }) => {
    return {
      ...state,
      isDeleting,
    };
  }),
  on(SharedActions.setIsSessionDataLoading, (state, { isSessionDataLoading }) => {
    return {
      ...state,
      isSessionDataLoading,
    };
  }),
  on(SharedActions.getSessionDataFail, state => {
    return {
      ...state,
      sessionDataLoadingError: true,
    };
  }),
  on(SharedActions.subscribeToSessionStatusFail, state => {
    return {
      ...state,
      sessionDataLoadingError: true,
    };
  }),
  on(SharedActions.getSessionDataSuccess, state => {
    return {
      ...state,
      sessionDataLoadingError: false,
    };
  }),
  on(SharedActions.setIsInactive, (state, { isInactive }) => {
    return {
      ...state,
      isInactive,
    };
  }),
  on(SharedActions.setApplicationLoaded, (state, action) => {
    return {
      ...state,
      isApplicationLoaded: true,
    };
  }),
  on(SharedActions.setProcessingId, (state, { processingId }) => {
    return {
      ...state,
      processingId,
    };
  }),
  on(SharedActions.setLocksDebug, (state, { locks }) => {
    return {
      ...state,
      locks,
    };
  }),
);
