import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { OrgGroupsSelectors } from '@states/org-groups/org-groups.selector-types';
import { OrgGroupsService } from '../../../development/org-groups.service';
import { OrgGroupsActions } from '@states/org-groups/org-groups.action-types';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { OrgGroupModels } from '@models/org-group.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { selectAllOrgGroups } from "@states/org-groups/org-groups.selectors";
import { Person } from "@models/people.model";
import { PeopleSelectors } from "@states/people/people.selector-types";
import _ from "lodash";

export interface UiOrgGroupDialogData {
  orgGroup?: OrgGroupModels.OrgGroup;
}

export interface UiOrgGroupDialogResult {
  orgGroup: OrgGroupModels.OrgGroup;
}

@UntilDestroy()
@Component({
  selector: 'app-ui-orgGroup-dialog',
  templateUrl: './ui-org-group-dialog.component.html',
  styleUrls: ['./ui-org-group-dialog.component.scss'],
})
export class UiOrgGroupDialogComponent implements OnInit {

  public noSaved = features.orgGroups.noSaved;

  public PreloaderColor = PreloaderColor;

  loaded = false;
  public query = '';

  public loading = false;

  form = new FormGroup({
    name: new FormControl('',),
  });

  public orgGroup: OrgGroupModels.OrgGroup;


  constructor(
    private store$: Store,
    @Inject(MAT_DIALOG_DATA) public data: UiOrgGroupDialogData,
    private dialogRef: MatDialogRef<UiOrgGroupDialogComponent>) {
  }

  ngOnInit(): void {
    if (this.data?.orgGroup) {
      const orgGroup = this.data?.orgGroup;
      this.orgGroup = _.cloneDeep(orgGroup);
      if (orgGroup.name) {
        this.form.patchValue({name: orgGroup.name});
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.value.name);
  }

  public ngOnDestroy(): void {
  }

  public done() {
    const name = !!this.form.get('name').value ? this.form.get('name').value : 'Untitled group';
    const result: UiOrgGroupDialogResult = {
      orgGroup: {
        ...this.data.orgGroup,
        name,
        members: this.orgGroup.members,
      },
    };
    this.dialogRef.close(result)
  }

  public membersChange(members: OrgGroupModels.OrgGroupMember[]) {
    this.orgGroup.members = members;
  }

}
