<div class="w-100 h-100 thumbnail-layout-wrapper" *ngrxLet="selectedPositions$; let positions">
  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="WallLayout.GRID_1">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
        <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" class="h-100 w-100"
                         [cameraIdx]="positions[0]"
                         [withAlerts]="withAlerts"
                         [index]="0"
                         (select)="onThumbnailImageSelected($event,positions[0])"
                         (delete)="deleteCamera.emit(positions[0])"
        >
        </thumbnail-image>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WallLayout.GRID_2">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="2px" cdkDropListGroup>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="h-100">
          <div
            *ngFor="let i of [0]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100 w-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"

                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="h-100">
          <div
            *ngFor="let i of [1]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"

                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WallLayout.GRID_3">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="2px" cdkDropListGroup>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="h-100">
          <div
            *ngFor="let i of [0,1]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100 w-100"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"

                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="h-100">
          <div
            *ngFor="let i of [2]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"

                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WallLayout.GRID_4">
      <div fxLayout="column" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="2px" cdkDropListGroup>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [0,1]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100 w-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"

                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [2, 3]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WallLayout.GRID_6">
      <div fxLayout="column" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="2px" cdkDropListGroup>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [0,1,2]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100 w-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [3, 4, 5]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WallLayout.GRID_9">
      <div fxLayout="column" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="2px" cdkDropListGroup>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [0,1,2]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100 w-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [3, 4, 5]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="50" fxLayoutGap="2px" class="w-100">
          <div
            *ngFor="let i of [6, 7, 8]"
            class="camera-wrapper"
            fxLayoutAlign="center center"
            fxFlex="50"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i }"
            (cdkDropListDropped)="drop($event)"
          >
            <div cdkDrag [cdkDragDisabled]="!allowDrag" fxLayoutAlign="center center" class="camera-drag-wrapper">
              <thumbnail-image [fixedRange]="fixedRange" [imageSection]="imageSection" fxFlexFill
                               class="h-100"
                               [index]="i"
                               [cameraIdx]="positions[i]"
                               [withAlerts]="withAlerts"
                               (select)="onThumbnailImageSelected($event,positions[i])"
                               (delete)="deleteCamera.emit(positions[i])"
              ></thumbnail-image>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

  </ng-container>
</div>
