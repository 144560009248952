import { createReducer, on } from '@ngrx/store';
import * as SearchActions from '@states/track-object/track-object.actions';
import { Search } from '../../../shared/search.model';

export declare interface SearchState {
  dateTimeRange: Search.DateTimeRange;
}

export const initialState: SearchState = {
  dateTimeRange: null,
};

export const searchStateReducer = createReducer(
  initialState,
  on(SearchActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  })
);
