import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() { }

  setItem(key: string, val: any) {
    sessionStorage.setItem(key, val);
  }

  getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string) {
    return sessionStorage.removeItem(key);
  }

  clear() {
    return sessionStorage.clear();
  }
}
