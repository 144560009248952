<ng-container *ngIf="userNotification">
  <ng-container *ngIf="notifications[userNotification?.id]">
    <div fxLayout="column"
         class="notification-action"
         [class.unseen]="userNotification.state === notificationState.UNSEEN"
    >
      <!--      <button class="menu-icon" mat-icon-button [matMenuTriggerFor]="action">-->
      <!--        <mat-icon svgIcon="more-menu"></mat-icon>-->
      <!--      </button>-->
      <div fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <ng-container [ngSwitch]="iconType(notifications[userNotification?.id].type)">
            <ng-container *ngSwitchCase="NotificationIconType.ERROR">
              <img src="../../../../assets/notifications/notification-error.svg">
            </ng-container>
            <ng-container *ngSwitchCase="NotificationIconType.INFO">
              <img src="../../../../assets/notifications/notification-info.svg">
            </ng-container>
          </ng-container>
        </div>
        <div fxLayout="column" class="notification-container">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title">
              <ui-truncated-html>{{notifications[userNotification.id]?.title ?? 'Notification'}}</ui-truncated-html>
            </span>
            <div fxLayoutAlign="center center"
                 class="delete-notification icon-18 c-p"
                 (click)="deleteNotification(userNotification?.id)"
            >
              <mat-icon>close</mat-icon>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="3px"
                 class="task-completed-label icon-16"
                 *ngIf="notifications[userNotification.id]?.status === notificationStatus.COMPLETED"
            >
              <mat-icon>check_circle_outline</mat-icon>
              <div>Completed</div>
            </div>
            <span *ngIf="notifications[userNotification.id]?.status === notificationStatus.COMPLETED"
                  class="bullet-divider"
            >•</span>
            <span class="time">{{(notifications[userNotification.id]?.timestamp)|date:'MMM, dd HH:mm a'}}</span>
          </div>
          <div class="text">
            {{notifications[userNotification.id]?.description}}
          </div>
          <div fxLayout="row"
               fxLayoutAlign="start center"
               class="action-btn"
               fxLayoutGap="8px"
               *ngIf="notifications[userNotification.id]?.type === NotificationType.DOORS"
          >
            <ui-button
                type="primary"
                text="Add to database"
                (clickAction)="gotoDoors(notifications[userNotification.id])"
                fxFlex=""
            ></ui-button>
          </div>
          <div fxLayout="row"
               fxLayoutAlign="start center"
               class="action-btn"
               fxLayoutGap="8px"
               *ngIf="notifications[userNotification.id]?.type === NotificationType.PROPER_FITTING"
          >
            <ui-button
                type="default"
                text="Restart now"
                [disabled]="notifications[userNotification.id]?.status === notificationStatus.COMPLETED"
                (clickAction)="restartNow()"
                fxFlex=""
            ></ui-button>
            <ui-button
                type="default"
                text="Set date"
                [disabled]="notifications[userNotification.id]?.status === notificationStatus.COMPLETED"
                (clickAction)="setDateVisible=!setDateVisible"
                fxFlex=""
            ></ui-button>
          </div>
          <div *ngIf="notifications[userNotification.id]?.schedule?.msg"
               fxLayout="row"
               fxLayoutAlign="start center"
               class="schedule mt-10"
          >
            <ng-container
                *ngIf="notifications[userNotification.id]?.status === notificationStatus.COMPLETED; else notCompleted">
              <i>{{notifications[userNotification.id]?.schedule?.msg ?? 'Operation completed successfully'}}</i>
            </ng-container>
            <ng-template #notCompleted>
              <i>{{notifications[userNotification.id]?.schedule?.msg}}</i>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="setDateVisible" class="date-picker-block">
        <mat-calendar
          #leftCalendar
          [startAt]="startDateLeftCalendar"
          [dateClass]="dateClass"
          (selectedChange)="dateChanged($event)"
        >
        </mat-calendar>
        <div fxLayout="column">
          <label class="time">Time</label>
          <input class="time-input" type="time" [(ngModel)]="startTime" (ngModelChange)="changeTime()">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="calendar-buttons">
          <mat-select
              class="ui-selector"
              style="width: 86px; display: block"
              [(ngModel)]="currentTimezone"
              (ngModelChange)="setTimezone($event)"
          >
            <mat-option [value]="zone" *ngFor="let zone of timeZones">{{ zone }}</mat-option>
          </mat-select>
          <div fxLayout="row" fxLayoutAlign="end center">
            <ui-button
                class="back-btn"
                colorBgr="#FFFFFF"
                colorText="#344054"
                text="Cancel"
                colorBorder="#D0D5DD"
            ></ui-button>
            <ui-button
                class="back-btn"
                colorBgr="#4367F6"
                colorText="#FFFFFF"
                text="Save"
                colorBorder="#3456E0"
                (click)="save()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>

    <mat-menu #action="matMenu" xPosition="before">
      <button *ngIf="userNotification.state === notificationState.UNSEEN" mat-menu-item (click)="readNotification()">
        Read
      </button>
      <button *ngIf="userNotification.state === notificationState.SEEN" mat-menu-item (click)="readNotification()">
        Unread
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
