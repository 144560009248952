<div class="ui-title-popup">
  <div class="header flex-row space-between-center">
    {{ modalTitle }}
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>

  <div class="body">
    <div class="input-container">
      <div class="flex-col">
        <label>{{data.inputLabel}}</label>
        <input class="ui-input ui-input-40" [(ngModel)]="name" placeholder="Untitled name">
      </div>
    </div>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="end center">
    <ui-progress-button text1="Save"
                        text2="Saving"
                        (clickAction)="save()"
    ></ui-progress-button>
  </div>
</div>
