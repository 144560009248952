import { SQSMsgInfo } from '../core/interfaces';
import { ArchiveTimestampLocation } from '@enums/archive.enum';
import { FlowLookup } from '@models/alerts-v2.model';

export declare module Playback {

  export interface SelectedCameraBase {
    locationId: string;
    edgeId: string;
    cameraId: string;
  }

  export interface PlaybackMsgInterfaceBase extends SelectedCameraBase {
    sessionId?: string;
  }

  export interface PlayBackEncodingParams {
    width: number;
    height: number;
    bitrate: number;
  }

  export interface PlatbackCodecRequest extends SelectedCameraBase {

  }

  export interface PlaybackCodecResponse {
    bitrate: number,
    codec: string,
    width: number,
    height: number
    framerate: number
  }

  export interface PlaybackStartRequest extends PlaybackMsgInterfaceBase {
    timestamp?: number;
    duration?: number;
    encode?: boolean;
    encodingParams?: PlayBackEncodingParams;
    cloudMode?: boolean;
    highQuality?: boolean;
  }

  export interface PlaybackStartResponse {
    sqsMessgaeInfo: SQSMsgInfo;
    sessionId: string;
  }

  export interface PlaybackStopRequest extends PlaybackMsgInterfaceBase {
  }

  export interface PlaybackExtendRequest extends PlaybackMsgInterfaceBase {
    duration: number;
    cloudMode?: boolean;
  }

  export type PlaybackExtendProcessRequest = PlaybackMsgInterfaceBase;

  export interface PlaybackRewindRequest extends PlaybackMsgInterfaceBase {
    duration: number;
  }

  export interface PlaybackResolutionRequest extends PlaybackMsgInterfaceBase {
    width: number;
    height: number;
    bitrate: number;
  }

  export interface PlaybackSpeedRequest extends PlaybackMsgInterfaceBase {
    speed: number;
  }

  export interface PlaybackLocationRequest extends PlaybackMsgInterfaceBase {
    timestamp?: number;
  }

  export interface PlaybackDownloadRequest extends PlaybackMsgInterfaceBase {
    timestamp: number;
    duration: number;
    exitAfterDone: boolean;
    filename?: string;
    encode?: boolean;
    encodingParams?: PlayBackEncodingParams;
    storage?: ArchiveStorageType;
    blurFaces?: boolean;
    timestampLocation?: ArchiveTimestampLocation;
    // notifications?: ArchiveNotificationsRequest
    tags?: FlowLookup[];
  }

  export interface PlaybackCloseRequest extends PlaybackMsgInterfaceBase {
  }
}

export enum ArchiveStorageType {
  LOCAL, // need to calculate SNS based on progress only -> ip(cloudflare)/download/edgeId/cameraId/filename
  CLOUD, // need to calculate SNS based on progress & uploadProgress -> aws link
}

export enum PlaybackResponseCode {
  OK,
  Error,
  Unknown,
  TooManyInProgress,
}

export interface PlaybackResponseSNSMsg {
  edgeId: string;
  cameraId: string;
  sessionId: string;
  response: PlaybackResponseCode;
  token: string;
}
