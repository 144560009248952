export const carMadeModel = {
  Abarth: [
    '500',
    '595',
    '124 Spider',
    '1000',
    '500C',
    '595C',
    'Punto',
    'Scorpione',
    '695',
    'Grande Punto',
    'Simca 1300',
    '695C',
    'Berlina Corsa',
    '750',
    'Simca 2000',
    'Fiat-750',
    '205',
    '1500 Biposto',
    'Fiat-850TC',
    '207A',
    'Fiat 124 Rally',
    'Lombardi Grand Prix',
  ],
  AC: ['Schnitzer', 'Cobra', 'Ace', 'Aceca', '378 GT Zagato', '3000ME', 'Greyhound', '2-Litre', 'Frua', '428', '427', 'Petite', 'MK VI'],
  Acura: [
    'NSX',
    'TL',
    'MDX',
    'TLX',
    'ILX',
    'Integra',
    'RDX',
    'TSX',
    'RSX',
    'RL',
    'RLX',
    'ZDX',
    'CL',
    'Legend',
    'CSX',
    'CDX',
    'EL',
    'Vigor',
    'SLX',
    'ARX-02a',
    'ARX-01',
    'CL-X',
  ],
  Adam: ['Revo'],
  Adler: ['Trumpf Junior', 'Trumpf', 'Diplomat', 'Primus', 'Favorit', 'Standard 6', '2 Liter', '2.5-litre', 'Standard 8'],
  AEC: ['Matador', 'Militant', 'Armoured Car', 'Armoured Command Vehicle'],
  Aero: ['30 Roadster'],
  Aixam: [
    'Crossline',
    'City',
    '500',
    'Mega',
    '400',
    'GTO',
    'Scouty',
    'Coupe GTi',
    'Roadline',
    'A.721',
    'Crossover',
    'Microcar',
    'GTI',
    'Pick-up',
    '300',
    'Minivan',
    'Mega e-City',
    'MAC',
    'Berlines',
  ],
  Albion: ['CX22S'],
  'Alfa Romeo': [
    'Giulia',
    '4C',
    'Giulietta',
    '147',
    'MiTo',
    'Quadrifoglio',
    'Spider',
    'GTV',
    'Stelvio',
    '159',
    '156',
    'GT',
    'Alfetta',
    '146',
    'Alfasud',
    'Brera',
    '8C',
    '1900',
    '6C',
    '155',
    '75',
    '166',
    '145',
    '8C Competizione',
    'Sprint',
    'Berlina',
    'Milano',
    'GTV 2.0',
    '164',
    '33',
    '1750',
    'Alfasud Sprint',
    '2000 GTV',
    '2600',
    '2000',
    'GTA',
    'Montreal',
    '33 Stradale',
    'Disco Volante',
    '1900C',
    'Giulia TZ',
    'GT 1300 Junior',
    'SZ',
    'Giulietta Sprint Speciale',
    '2000 Berlina',
    'Arna',
    'Crosswagon',
    'Tipo 33',
    'Junior',
    'Junior Zagato',
    'Romeo',
    '1750 Berlina',
    '90',
    'Mille',
    'Bella',
    '2000 Spider Veloce',
    '2300',
    'Alfa 6',
    'RL',
    'Giulia (952)',
    'RZ',
    'Giulietta (940)',
    'Iguana',
    '2000 Sportiva',
    'Giulietta (116)',
    '33,2',
    'Dauphine',
    'Nuvola',
    'Matta',
    '12C',
    'Museum',
    'RM',
    'AR6',
    'Carabo',
    'Disco Volante by Touring',
    'Sports Sedan',
    'AR8',
    'Gran Sport Quattroruote',
    'G1',
    '105/115 Series Coupes',
    'GTV and Spider',
    '2uettottanta',
    'Giulietta (750/101)',
    'Proteo',
    'Pandion',
    'Brera and Spider',
    'Visconti',
    'Canguro',
    'Navajo',
    'Scighera',
    'BAT',
    'ALFA 24 HP',
  ],
  Allard: ['J2X', 'J2', 'K3', 'Palm Beach', 'K2', 'M', 'P1', 'L', 'Clipper', 'J2X-C'],
  Alpina: [
    'B7',
    'B3',
    'D3',
    'B5',
    'B6',
    'B10',
    'XD3',
    'D5',
    'D4',
    'B12',
    'B4',
    'C2',
    'B9',
    'C1',
    'B10 Bi-Turbo',
    'B11',
    'Roadster-V8',
    'Roadster',
    'Z8',
    'D10',
    'Roadster S',
    'B8',
    'B7 (E65)',
    'B3 (F30)',
    'B7 (F01)',
    'B7 (G12)',
    'B5 (F10)',
    'B6 (E63)',
    'B5 (E60)',
    'B3 (E90)',
  ],
  Alpine: [
    'A110',
    'A310',
    'A610',
    'A210',
    'A106',
    'V6',
    'Berlinette',
    'A108',
    'A110-50',
    'M64',
    'A110 (2017)',
    'M63',
    'A220',
    'Renault A-110',
    '1600',
    'M65',
  ],
  Alvis: [
    'Stalwart',
    'TD 21',
    'Saracen',
    'Speed 25',
    'TE 21',
    'Saladin',
    'Speed 20',
    'TA 14',
    '12/70',
    'TF 21',
    'Firefly',
    'TA 21',
    '12/50',
    'Firebird',
    'Salamander',
    'TC 21',
    'Stormer',
    'TC 108G',
    'Tactica',
    'TB 21',
    'TB 14',
    'Silver Crest',
  ],
  'AM General': ['Humvee', 'HMMWV'],
  AMC: [
    'Eagle',
    'Javelin',
    'AMX',
    'Gremlin',
    'Pacer',
    'Rambler',
    'Hornet',
    'Matador',
    'Machine',
    'Rambler American',
    'Ambassador',
    'Rambler Classic',
    'Concord',
    'Spirit',
    'Rebel',
    'Jeep CJ',
    'Rambler Marlin',
    'Jeep Wagoneer',
    'Metropolitan',
    'Nash Rambler',
    'Cavalier',
    'Nash Ambassador',
    'Hudson Hornet',
  ],
  Amilcar: ['Compound', 'CGSS', 'Pegase', 'CGS', 'C4', 'CC', 'C8'],
  Amphicar: ['770'],
  Anadol: ['STC-16', 'A2', 'A1', 'A8-16'],
  Apollo: ['GT'],
  Aprilia: ['RSV4'],
  'Arctic Cat': ['700', 'Thundercat', 'MudPro 700', 'Prowler 550 XT'],
  Ariel: ['Atom'],
  'Armstrong Siddeley': ['Sapphire', 'Hurricane', 'Lancaster', 'Typhoon'],
  'Arnolt-Bristol': ['Roadster'],
  ARO: ['243', 'M461', '240', '24', '10', '320', '244', '246', '241', '324', 'Spartana', '242', '328', '24 Series'],
  Artega: ['GT'],
  ASA: ['1000 GT'],
  Ascari: ['A10', 'KZ1', 'Ecosse'],
  Asia: ['Rocsta'],
  Aspark: ['OWL'],
  'Aston Martin': [
    'Vanquish',
    'Vantage',
    'V8 Vantage',
    'DB11',
    'V12 Vantage',
    'DB9',
    'DB5',
    'Volante',
    'Rapide',
    'One-77',
    'Lagonda',
    'Virage',
    'DBS',
    'Vulcan',
    'DB4',
    'DB4 GT Zagato',
    'Cygnet',
    'DB6',
    'DB2/4',
    'Zagato',
    'V12 Zagato',
    'DB10',
    'DB2',
    'DB7',
    'Le Mans',
    'V8',
    'DB AR1',
    'Valkyrie',
    'DBS V12',
    'Vantage GT4',
    'DP-100',
    'Racing',
    'DB7 Zagato',
    'Bulldog',
    'DB3',
    'CC100',
    'DB3S',
    'AMV8',
    'V12',
    'Owners Club',
    'Vantage AMR Pro',
    'DB Mark III',
    'Vantage (2018)',
    'Atom',
    'Lagonda Vignale',
    'Valhalla',
    'V8 Zagato',
    'Short Chassis Volante',
    '2-Litre Sports',
    'Vantage (2005)',
    'Rapide Bertone Jet 2+2',
    'DBR9',
    'Vantage GT2',
    'DBR1',
    'Vantage N24',
  ],
  ATS: ['2500 GT'],
  Audi: [
    'A4',
    'A6',
    'R8',
    'A3',
    'A5',
    'A1',
    'Q5',
    'TT',
    'Q7',
    'A8',
    'RS 6',
    'S3',
    '80',
    'TT RS',
    'SQ5',
    'A7',
    'S5',
    'Q3',
    'Q2',
    'S4',
    'RS 5',
    'RS 4',
    '100',
    'RS 3',
    'RS 7',
    'TTS',
    'S8',
    'S6',
    'S1',
    'SQ7',
    'S2',
    'RS',
    'A2',
    'Cabriolet',
    'e-tron',
    'RS Q3',
    'allroad quattro',
    'S7',
    'A6 allroad quattro',
    '90',
    'TTS Coupe',
    '200',
    'RS 2 Avant',
    'Q8',
    'V8',
    'Prologue',
    '100 Coupe S',
    'Sportback concept',
    'RS 3 Sportback',
    'Quattro S1',
    '50',
    'RSQ',
    '5000',
    'quattro concept',
    '4000',
    'Rosemeyer',
    'A7 sportback h-tron',
    '60',
    'Driving Experience',
    'Shooting Brake',
    'F103',
    'Coupe (B2)',
    'Nuvolari quattro',
    'A4 DTM',
    'Fox',
    'Front',
    'Roadjet',
    'Le Mans quattro',
    '920',
    'Ur-S4 / Ur-S6',
    'RS Models',
    'Avantissimo',
    'R8 LMS Cup',
    'Pikes Peak quattro',
    'Steppenwolf',
    'Type R',
    'Avus Concept',
    'e-tron (2018)',
    'Navigation Plus',
    'Avus quattro',
    '"S4 25quattro"',
    '72',
    'Type A',
    'Type C',
    'e-tron Vision Gran Turismo',
    'R8 (LMP)',
    'R18',
    'R8 (Type 42)',
    'Type D',
    'Type K',
    'R10 TDI',
    '5+5',
    'S and RS models',
  ],
  Aurus: ['Senat'],
  Austin: [
    'Healey',
    'Mini',
    'A35',
    'A30',
    'Cambridge',
    'Seven',
    'A40',
    'Allegro',
    'A40 Farina',
    '7',
    '1800',
    'Cooper',
    'Princess',
    'FX4',
    'Maxi',
    'Metro',
    'Maestro',
    'Westminster',
    '10',
    '1100',
    'Gipsy',
    'A40 Devon',
    'FX3',
    '8',
    '1300',
    '12/4',
    'A60',
    'America',
    'Montego',
    'A70',
    'Metropolitan',
    'Sheerline',
    'A40 Somerset',
    '12',
    'Ambassador',
    '18',
    'A40 Sports',
    'A90 Atlantic',
    'Marina',
    'A50',
    '16',
    'Champ',
    'A40 Countryman',
    'Healey 100S',
    '20',
    'K9',
    '3-Litre',
    'A55',
    'Sprite',
    'K5',
    '850',
    '10 hp',
    'A135',
    '14',
    'Mini MK',
    'Victoria',
    'Twenty',
    'Windsor',
    'Kimberley',
    'Apache',
    'A90',
    '20 hp',
    'Freeway',
    '2200',
    'Lancer',
    'London Taxicab',
    '12/6',
    'A105',
    'A40 Cambridge',
    'A95',
    'J4',
    '20/6',
    'Tasman',
    'K2/Y',
    'A40 Futura',
    'Loadstar',
    '16 hp',
    'Balanza',
    '20/4',
    'A99',
    'Ant',
  ],
  'Austin-Healey': ['Sprite', '3000', 'Sebring Sprite', '100', '100S'],
  Authi: ['Mini Cooper'],
  Autobianchi: ['A112', 'Bianchina', 'Y10', 'Stellina', 'Giardiniera', 'Primula', 'Bianchina Giardiniera', 'A111', 'A 1000'],
  Autocars: ['Sussita', 'Sabra', 'Carmel'],
  Autozam: ['AZ-1'],
  BAC: ['Mono'],
  Baojun: ['730', '510', '530', '630', '560', '310', '310W', '360', '610', '330', 'Lechi'],
  Barkas: ['B 1000'],
  BAW: ['Luba', 'Yusheng'],
  Bedford: [
    'TM',
    'TK',
    'Vehicles',
    'S type',
    'RL',
    'CF',
    'QLT',
    'QL',
    'OX',
    'CA',
    'QLB',
    'Rascal',
    'QLR',
    'TJ',
    'Dormobile',
    'Astravan',
    'HA',
    'Chevanne',
    'QLC',
    'ML',
    'TL',
    'Astramax',
    'Beagle',
    'OY',
    'MK',
    'Midi',
    'Green Goddess',
    'M series',
    'KB',
    'MW',
    'OXA',
  ],
  Bentley: [
    'Continental GT',
    'Mulsanne',
    'Continental',
    'Bentayga',
    'Flying Spur',
    'Continental GTC',
    'Arnage',
    'Continental Flying Spur',
    'S1',
    'Brooklands',
    'Turbo R',
    'Azure',
    'Continental R',
    '3 Litre',
    '4 Litre',
    'EXP 9 F',
    'Mark VI',
    'EXP 10 Speed 6',
    'Continental T',
    'Speed Six',
    'S2',
    'T1',
    'S3',
    'EXP 12 Speed 6e',
    'R Type',
    'T2',
    'State Limousine',
    'Turbo RT',
    'Grand Convertible',
    'Mulsanne (2010)',
    'Eight',
    '8 Litre',
    'Hunaudieres',
    'T-series',
    'Supersport',
    'Brooklands Coupe',
    'Corniche',
    '3.5 Litre',
    'Turbo S',
    'Continental S',
    'Flying Spur (2005)',
    'Mark V',
    'Java',
    'Boys',
  ],
  Bertone: [
    'Fiat X1/9',
    'Opel Astra',
    'Nuccio',
    'Lancia Stratos',
    'BMW 3200 CS',
    'Alfa Romeo GT',
    'Fiat Punto',
    'Alfa Romeo Montreal',
    'Freeclimber',
    'Alfa Romeo BAT',
    'Fiat Dino',
    'Alfa Romeo 2000 Sportiva',
    'Alfa Romeo 2600',
    'Maserati Khamsin',
    'Blitz',
    'Lamborghini Jarama',
    'Mantide',
    'Maserati Quattroporte II',
    'Alfa Romeo 90',
    'Lamborghini Silhouette',
  ],
  Besturn: ['B50', 'B30', 'B70', 'X80'],
  Bisu: ['T5', 'T3', 'M3'],
  Bitter: ['SC', 'Vero'],
  Bizzarrini: ['P538', 'Strada', '5300 Spyder S.I.', 'Europa'],
  BMC: ['Mini', 'ADO16', 'Mini Moke', 'ADO17'],
  BMW: [
    '3 Series',
    '5 Series',
    'M3',
    '1 Series',
    'X5',
    'Motorsport',
    'xDrive',
    '7 Series',
    'M4',
    '4 Series',
    '320i',
    'X3',
    'E30',
    'M5',
    'Z4',
    'E46',
    'X1',
    '335i',
    'i8',
    '325i',
    'M',
    '6 Series',
    'X6',
    'E36',
    'X6 M',
    '328i',
    'i3',
    '520d',
    '2002',
    'M6',
    '320d',
    '330i',
    '318i',
    'M2',
    '135i',
    '2 Series',
    'Gran-Turismo',
    '528i',
    '530d',
    '8 Series',
    '520i',
    'Z3',
    '525',
    '330Ci',
    '428i',
    'M135i',
    '118d',
    '535i',
    '323i',
    '318is',
    'E39',
    '3/20',
    '740i',
    'M Coupe',
    '525d',
    'M235i',
    'X5 M',
    '325Ci',
    'X4',
    '635 CSi',
    'F10',
    '650i',
    'Isetta',
    '750Li',
    'E60',
    '330d',
    '525i',
    'M1',
    'E34',
    '530i',
    'E92',
    '120d',
    'E90',
    '3.0 CSL',
    '435i',
    '540i',
    'X5 (E53)',
    'X7',
    'F30',
    '335xi',
    '730',
    '3 Series (E46)',
    '316i',
    'M140i',
    '5 Series (E60)',
    '550i',
    '740Li',
    'E21',
    '420d',
    'Z8',
    '3 Series (E30)',
    '130i',
    '640i',
    '335d',
    'E28',
    '750i',
    '335is',
    '318ti',
    '1M',
    '320ci',
    '535d',
    '525tds',
    '328ixD',
    'F80',
    '118i',
    '123d',
    '116i',
    '340i',
    '760Li',
    'Z4 (E85)',
    '120i',
    '3 Series (F30)',
    '5 Series (F10)',
    'X5 (E70)',
    '1 Series (F20)',
    '5 Series (E34)',
    'X2',
    '328',
    '730d',
    '325',
    '318ci',
    '740iL',
    'M50d',
    'E91',
    '850i',
    'Z4 (E89)',
    '523i',
    '328Ci',
    '1602',
    '125i',
    'M Roadster',
    'E38',
    '318d',
    '520',
    '507',
    '335',
    'Z1',
    '318',
    'X1 (F48)',
    'M760Li xDrive',
    '128i',
    '640d',
    'X5 (F15)',
    '645 Ci',
    '530',
    '325xi',
    '330xi',
    '735i',
    '120',
    '640',
    '325e',
    '116d',
    '5 Series (E39)',
    '3 Series (E90)',
    '535',
    '528',
    '3 Series (E36)',
    '745i',
    'M240i',
    '330e',
    '325is',
    'E61',
    '420i',
    '316',
    '535ixD',
    'ActiveHybrid 5',
    '3.0CS',
    'Motorrad',
    'Z',
    '545i',
    '430i',
    'E87',
    'E23',
    '440i',
    '633CSi',
    '323Ci',
    'X1 (E84)',
    '750iL',
    '330',
    '420',
    'F31',
    '1 Series (E87)',
    '2 Series Active Tourer',
    '325ix',
    '5 Series (G30)',
    '228i',
    'M235',
    '1600',
    '740',
    '428',
    '745Li',
    '7 Series (E38)',
    '750',
    '528xi',
    'E24',
    '760',
    '530xi',
    '728i',
    '3.0 CSi',
    '323ti',
    'E53',
    '435',
    'M535i',
    'F20',
    '220i',
    'E93',
    '330xd',
    '840Ci',
    '600',
    'M635Csi',
    'E32',
    '535xi',
    'X5 (G05)',
    '528ixD',
    '730Li',
    '730i',
    '7 Series (E23)',
    'ActiveHybrid 3',
    'E89',
    '220d',
    '216d',
    '850',
    'Turbo',
    '2000',
    '650',
    '745',
    '320ixD',
    '5 Series (E28)',
    '1 E87',
    '340',
    '1800',
    '7 Series (E32)',
    'F11',
    '850 CSi',
    '225xe',
    '730Ld',
    '118',
    'M760Li',
    '3.0Si',
    'M550i xDrive',
    '700',
    '7 Series (F01)',
    '325d',
    '725',
    '503',
    '540',
    '2800CS',
    'Art Car',
    'M550d xDrive',
    'V12 LMR',
    '328xi',
    '3 Series Compact',
    '4 Series (F32)',
    '523',
    'E9',
    '518i',
    '645i',
    'E85',
    '3/15',
    'E12',
    'E82',
    'M8',
    '125d',
    'E70',
    'Dixi',
    '3 Series (E21)',
    '218d',
    '135',
    '323',
    '850Ci',
    '728',
    '218i',
    '116',
    'X4 (F26)',
    '502',
    '7 Series (G11)',
    '2',
    '733i',
    '635',
    '327',
    'Bavaria',
    'M135',
    '518d',
    '335ixD',
    '216',
    'E71',
    '3 Series (G20)',
    '8',
    'F15',
    '316d',
    '230i',
    '428ixD',
    '430d',
    '7 Series (E65)',
    '114i',
    '535iGT',
    'E63',
    '501',
    '630i',
    '326',
    '1600-2',
    '518',
    'Neue Klasse',
    '528e',
    '645',
    '740d',
    'F06',
    '1500',
    '6 Series (E63)',
    '1802',
    '02 Series',
    '328d',
    '225i',
    '114d',
    '324',
    'E65',
    '700 LS',
    'E31',
    '725tds',
    'F25',
    '430',
    'Hydrogen 7',
    '840',
    'F13',
    '524td',
    '128',
    'L7',
    '545',
    '135is',
    '5 Series (E12)',
    'X2 (F39)',
    'F12',
    '2 Series (F22)',
    'E83',
    'E84',
    '1502',
    '418',
    'X4 (G02)',
    'i4',
    '328iC',
    '750LIXD',
    '435d',
    'F48',
    'F01',
    '125',
    '750IXD',
    '630',
    '760i',
    '123',
    '6 Series (F12)',
    '7 E32',
    '530xd',
    '3200 CS',
    '550',
    '325iC',
    '325es',
    'New Class',
    '130',
    '3200',
    '2000 TI',
    '535is',
    '218',
    '324d',
    'i',
    '8 Series (E31)',
    'E81',
    '328GTxD',
    '732i',
    '2500',
    '2800',
    '735',
    '6 Series (E24)',
    'X7 (G07)',
    '320si',
    '525xi',
    'Assist',
    '418d',
    '435ixD',
    'GT',
    '324td',
    '525td',
    '230',
    '640iGC',
    '328dxD',
    '740Le',
    '328is',
    '2000 C',
    '650iGC',
    '1 E81',
    '3 E21',
    '325xiT',
    'M8 GTE',
    '630Ci',
    'Turbo Concept',
    '628 CSi',
    '630Cs',
    '228',
    '303',
    '550ixD',
    '530Li',
    'Group Classic',
    '8 (E31)',
    '2000 tii',
    'Z4 GTE',
    '440xi',
    '214d',
    '740e',
    'H2R',
    '225d',
    '633',
    '1800 TI',
    '7 E23',
    'E88',
    '114',
    '550iGT',
    'M43',
    '321',
    'Brilliance',
    '225',
    '745d',
    '6 Series (F06/F12/F13)',
    '650iGCxD',
    '533i',
    '2000 touring',
    '3 E93',
    '523iA',
    '640GCxD',
    'N54',
    '735iL',
    '650ixD',
    '7er',
    '750Ld',
    '3.0CSA',
    '524',
    '525e',
    '340ixD',
    'X5 (F85)',
    '440',
    'F02',
    '220',
    '6 E24',
    '750d',
    '1 E88',
    'V12 LM',
    '3.0S',
    'New Six',
    '535dxD',
    '530 MLE',
    'Vision Efficient Dynamics',
    '520Li',
    '528Li',
    'X6 (G06)',
    '329',
    'M54',
    '1 E82',
    '228ixD',
    '545iA',
    '3.0 CSL E9 Coupe',
    '640ixD',
    '740LixD',
    '428GC',
    '428GCxD',
    '335GTxD',
    'L6',
    '535iGTxD',
    '523Li',
    'M30',
    'X',
    '2,6',
    'M41',
    '214',
    'M52',
    'M42',
    '2.5CS',
    'M51',
    'GTP',
    '733',
    '630Csi',
    'Z4 (G29)',
    'Zagato Coupe',
    'M40',
    '732',
    '6 Series (G32)',
    '840d',
    '3.3Li',
    'R100',
    'CleanEnergy',
    '325iT',
    '628',
    'M50',
    '8 Series (G15)',
    '525Li',
    '320/6',
    'in motorsport',
    '5er',
    'GS',
    '740Ld',
    '3.0Li',
    'M62',
    '535Li',
    '1 Series (F40)',
    'S85',
    '540d',
    'N55',
    '320 TC',
    '2600',
    'M44',
    '435GC',
    'ActiveE',
    '3er',
    '3.3L',
    'S65',
    'CCA Foundation',
    'in the United States',
    'R nineT',
    'Vision ConnectedDrive',
    'M12',
    'R1200',
    'Zagato Roadster',
    'M20',
    'M21',
    '550iGTxD',
    'M10',
    'M328',
    'M335',
    'GINA',
    'M47',
    'M57',
    'New Six CS',
    'F800R',
    '1er',
    'K1600',
    '6er',
    '740LdxD',
    'M106',
    '320iT',
    '1 Series (F52)',
    '435GCxD',
    'B48',
  ],
  Bolwell: ['Nagari'],
  Bond: ['Bug', 'Equipe', 'Minicar', '875'],
  Borgward: ['Isabella', 'BX7', 'BX5', 'BX6', 'P 100', 'BXi7'],
  Brabus: ['Rocket', 'Smart Roadster', 'E V12', 'Bullit', '800 iBusiness'],
  Bricklin: ['SV1'],
  Brilliance: ['V5', 'H230', 'M2', 'M1', 'H530', 'BS4', 'BS6', 'V3', 'H330', 'BS2', 'FRV', 'BC3', 'H3', 'V6', 'V7'],
  Bristol: [
    '405',
    '400',
    '411',
    '403',
    '401',
    'Blenheim',
    'Fighter',
    'Bullet',
    '402',
    '407',
    '406',
    '410',
    '404',
    '408',
    '603',
    'Britannia',
    'Brigand',
    '412',
    '409',
    '450',
    'Beaufighter',
    'Speedster',
    'Beaufort',
    'Series 6',
    'Type 603',
  ],
  'British Leyland': [
    'Mini',
    'Triumph Spitfire',
    'Austin Allegro',
    'Triumph Stag',
    'Triumph Dolomite',
    'Morris Marina',
    'Princess',
    'Triumph TR7',
    'Austin Maxi',
    'Jaguar XJ6',
    'Rover SD1',
  ],
  BSA: ['Scout'],
  Bufori: ['Geneva', 'La-Joya', 'MK II', 'BMS R1', 'Madison', 'V6i'],
  Bugatti: [
    'Veyron',
    'Chiron',
    'EB 110',
    'Type 57',
    'EB-16.4',
    'Type 41',
    'Type 50',
    'Type 101',
    'EB 112',
    'Type 55',
    'Type 13',
    'Type 46',
    'Type 43',
    'Type 40',
    'Type 49',
    'EB 218',
    'Type 44',
    'Type 30',
    'Type 23',
    'Gangloff',
    'Type 64',
    'Type 18',
    'Type 251',
    'Type 35',
    'Type 38',
    'Vision Gran Turismo',
    'EB 18/3 Chiron',
    'EB 18/4 Veyron',
    'Type 10',
    'EB 18/4 Veyron Concept',
    "Owners' Club",
    'Type 57S',
    'Royale',
    '16C Galibier',
    '18/3 Chiron',
    'Type 5',
    'Type 59',
    'Type 2',
    'Type 51',
    'Type 19',
    'Type 37',
    'EB 118',
    'Type 37A',
  ],
  Buick: [
    'Enclave',
    'Skylark',
    'LaCrosse',
    'LeSabre',
    'Envision',
    'Regal',
    'Encore',
    'Riviera',
    'Roadmaster',
    'Century',
    'Electra',
    'Reatta',
    'Verano',
    'Special',
    'Invicta',
    'Grand National',
    'Park Avenue',
    'Lucerne',
    'Limited',
    'Super',
    'Cascada',
    'Wildcat',
    'Rendezvous',
    'Gran Sport',
    'Centurion',
    'GS',
    'GSX',
    'Skyhawk',
    'GNX',
    'Estate',
    'Rainier',
    'Sedan',
    'Apollo',
    'Somerset',
    'Terraza',
    'GL8',
    'T-Type',
    'Excelle GT',
    'Master Six',
    'Excelle',
    'Electra Estate',
    'Excelle XT',
    'Sport Wagon',
    'Le Sabre Custom Convertible',
    'Opel',
    'Model C',
    'GS Convertible',
    'Encore GX',
    'Velite 5',
    'Velite Concept',
    'Blackhawk Concept',
    'Y-Job Concept',
    'Avenir',
    'Allure',
    '70 Riviera',
    'Sail',
    '70 Skylark',
    'Royaum',
    'Rainer',
    'Cielo Concept',
    'Model 38',
    'Velite',
    'GL6',
    'Bengal',
    'Model 32',
    'Model B',
    'XP-300 Concept',
    'Excelle GX',
    'Avista',
    'Signia Concept',
  ],
  BYD: [
    'F3',
    'e6',
    'F0',
    'Tang',
    'S6',
    'L3',
    'Flyer',
    'K9',
    'F6',
    'Qin',
    'G3',
    'G6',
    'Yuan',
    'S7',
    'Song',
    'e5',
    'M6',
    'Song Max',
    'F3DM',
    'F3-R',
    'S8',
    'G5',
    'G3-R',
    'E-Seed GT',
    'F8',
    'e2',
    'M6 MPV',
  ],
  Cadillac: [
    'de Ville',
    'Escalade',
    'CTS',
    'CTS-V',
    'Fleetwood',
    'Eldorado',
    'DTS',
    'SRX',
    'Coupe de Ville',
    'ATS',
    'Brougham',
    'XT5',
    'XTS',
    'CT6',
    'Series 62',
    'Fleetwood Brougham',
    'ATS-V',
    'STS',
    'Seville',
    'XLR',
    'Sedan de Ville',
    'ELR',
    'V series',
    'BLS',
    'Sixty Special',
    'DeVille',
    'Allante',
    'Catera',
    'LaSalle',
    'V-16',
    'STS-V',
    'Series 60',
    'Series 61',
    'XT4',
    'Sixteen',
    'Cimarron',
    'Calais',
    'CT4',
    'Evoq Concept',
    'Series 70',
    'Northstar LMP',
    'CT5',
    'V-12',
    'XT6',
    'Evoq',
    'XTS Platinum',
    'Runabout',
    'SLS',
    'Series 355',
    'de Ville series',
    'Voyage Concept',
    'Series 65',
    'Escala',
    'Elmiraj',
    'Commercial Chassis',
    'Type 51',
    'Type 53',
    'Model Thirty',
    'Converj',
    'Le Mans',
    'Ciel',
    'Model D',
    'Cien',
    'Runabout and Tonneau',
    'Urban Luxury Concept',
  ],
  Callaway: ['C12'],
  Carbodies: ['Taxi', 'Austin FX4'],
  Casalini: ['M14', 'Sulky'],
  Caterham: ['Seven', 'Superlight', 'Super Seven', 'Super 7', '7', 'Supersport', '620R', '21', 'CSR', 'CSR260', 'SP/300.R', '7 CSR'],
  Caterpillar: [
    'D4',
    'D7',
    'D5',
    'D8',
    '797F',
    '930G',
    'CS-533E',
    'D9',
    '797',
    'D6',
    'Sixty',
    'D10',
    'Twenty-Two',
    'D2',
    '924G',
    'D11',
    '345C L',
    'Challenger Tractor',
  ],
  Chana: ['Star', 'Benni', 'Star 2', 'Q20', 'CM8', 'Star 3', 'CX70', 'Honor', 'Star 4500', 'Eulove'],
  Changan: [
    'CS35',
    'Benni',
    'CS15',
    'Eado',
    'CS75',
    'CS95',
    'CS55',
    'CX20',
    'CS35 Plus',
    'Alsvin V7',
    'Eado XT',
    'Alsvin',
    'Benni Mini',
    'CM8',
    'Raeton',
    'BenBen',
    'CS85',
    'Alsvin V3',
    'CX30',
    'Raeton CC',
    'CX30 Sedan',
    'Eado DT',
    'Jiexun',
    'Eado EV',
    'CS75 Plus',
    'CS15 EV',
    'Z-Shine',
    'Kalash',
    'Eado XT RS',
    'G10',
  ],
  Changhe: ['Q7', 'Q25', 'Q35', 'Ideal', 'A6', 'Beidouxing', 'M70', 'Freedom'],
  Chatenet: ['CH 26', 'Barooder', 'CH 30', 'Media', 'CH 32', 'Speedino', 'Stella', 'Sporteevo', 'CH 28'],
  Checker: ['Marathon', 'Taxi', 'Aerobus', 'Model A'],
  Chery: [
    'Tiggo',
    'Qq',
    'Tiggo 2',
    'Tiggo 7',
    'Arrizo 5',
    'Amulet',
    'Fora',
    'Tiggo 5',
    'CrossEastar',
    'A15',
    'Tiggo 3',
    'Very',
    'Kimo',
    'Arrizo 7',
    'M11',
    'Bonus',
    'Fulwin',
    'IndiS',
    'QQ3',
    'Eastar',
    'A13',
    'Exeed TX',
    'Arrizo 3',
    'Fulwin 2',
    'J11',
    'Tiggo 5x',
    'X1',
    'QQ6',
    'Cowin',
    'A3',
    'J1',
    'Tiggo 8',
    'J2',
    'Tiggo 3x',
    'J3',
    'E5',
    'Arrizo GX',
    'A11',
    'A21',
    'A1',
    'Oriental-Son',
    'Arrizo M7',
    'A5',
    'V5',
    'QQme',
    'J5',
    'E3',
    'E8',
    'B11',
    'Eastar Cross',
    'Cowin 2',
    'eQ1',
    'Cowin 1',
    'Tiggo 3Xe',
    'Arrizo 5e',
    'Cowin X3',
    'Cowin 3',
    'M1',
  ],
  Chevrolet: [
    'Camaro',
    'Cruze',
    'Silverado',
    'Lacetti',
    'Malibu',
    'Corvette',
    'Niva',
    'Aveo',
    'Equinox',
    'Impala',
    'Caprice',
    'Chevy',
    'Lanos',
    'TrailBlazer',
    'Blazer',
    'Cobalt',
    'Nova',
    'Captiva',
    'Suburban',
    'Spark',
    'Express',
    'Volt',
    'Tahoe',
    'Astro',
    'Traverse',
    'Tracker',
    'Chevelle',
    'Colorado',
    'Lumina',
    '3100',
    'Trax',
    'Onix',
    'Kalos',
    'Matiz',
    'Bel Air',
    'Sonic',
    'Venture',
    'S-10',
    'LUV',
    'Cobalt SS',
    'Monte Carlo',
    'El Camino',
    'Epica',
    'Corsa',
    'Meriva',
    'K5 Blazer',
    'Orlando',
    'Opala',
    '1957 Chevrolet',
    'C10',
    'Cavalier',
    'Impala SS',
    'Omega',
    'Avalanche',
    'Tavera',
    'Corvair',
    'Chevette',
    '1955 Chevrolet',
    'Prisma',
    'Malibu Maxx',
    'Diplomata',
    'Tornado',
    'Celta',
    'Nubira',
    'Corvette (C7)',
    'Corvette (C6)',
    'N300',
    'Classic',
    'Rezzo',
    'Bolt',
    'HHR',
    'Trans-Sport',
    'Vectra',
    'SS',
    'Spin',
    'Cheyenne',
    'Master',
    '400',
    'Kadett',
    'Corvette (C3)',
    'Agile',
    'Chevy Malibu',
    'Fleetline',
    'Exclusive',
    'Montana',
    'Monza',
    'Astra',
    'Optra',
    'Zafira',
    'SSR',
    'Comodoro',
    'Beat',
    'Biscayne',
    'Deluxe',
    'S-10 Blazer',
    'Evanda',
    'Uplander',
    'Aveo (T200)',
    'Kodiak',
    'K30',
    'Grand Vitara',
    'Beretta',
    'Corvette (C5)',
    'Vega',
    'Tacuma',
    'C K 10 Series',
    'Chevy II',
    'Van',
    'Sail',
    'Brookwood',
    'Corvette (C1)',
    'Celebrity',
    'C1500',
    'Citation',
    'Nomad',
    'Lumina APV',
    'Corvette (C4)',
    'Superior',
    'Fleetmaster',
    '210',
    'Metro',
    'Tavera Neo',
    'C5500',
    'C7500',
    'Parkwood',
    'Corvette (C2)',
    'Corsica',
    'Viva',
    'Tri-Five',
    'G30',
    'Optra5',
    'CMP',
    'Calibra',
    'Alto',
    'D-20',
    'Advance Design',
    'Sprint',
    'C/K 1500 Series',
    'Super Carry',
    'Two-Ten',
    'K1500',
    'Chevelle Laguna',
    '150',
    'MW',
    'Kingswood',
    'Alero',
    'Spectrum',
    'Corvette C7 Z06',
    'Prizm',
    'Starcraft',
    'Lova',
    'Delray',
    'Jimny',
    'Trooper',
    'Yeoman',
    'Cosworth Vega',
    'Stylemaster',
    'C/K 3500 Series',
    'Enjoy',
    'Corvette C6 Z06',
    'Constantia',
    'SS (2013)',
    'Greenbrier',
    'Firenza',
    'Grand Vitara XL-7',
    'Eagle',
    'Lakewood',
    'Task Force',
    'One-Fifty',
    'Nexia',
    'Townsman',
    'Barina',
    'Damas',
    'N200',
    'Corvette C7.R',
    'Beauville',
    'Vivant',
    'Corvette C6.R',
    'Series 490',
    'LCF',
    'Chevair',
    'Corvette (C8)',
    'Mercury',
    'Tru 140S Concept',
    'Code 130R Concept',
    'Kommando',
    'C/K',
    'Hatch',
    'Joy',
    'Trafic',
    'Tosca',
    'Astro I',
    'Lova RV',
    'CMV',
    'Combo',
    'Series AB National',
    'Series D',
    'Series BA Confederate',
    'El Morocco',
    'Forester',
    'Cassia',
    'Gemini',
    'Groove',
    'Frontera',
    'Series AC International',
    'Chevy II / Nova',
    'Corvair Monza GT',
    'Wagon R',
    'Rodeo',
    'Astrovette',
    'Corvette GTP',
    'big-block engine',
    'Series H',
    'Standard Six',
    'small-block engine',
    'Series C Classic Six',
  ],
  Chrysler: [
    'PT Cruiser',
    '300C',
    '300',
    'Crossfire',
    '200',
    'New Yorker',
    'Town & Country',
    'Pacifica',
    'Newport',
    'Sebring',
    '300M',
    'Aspen',
    'Voyager',
    'Grand-Voyager',
    'Imperial',
    'Town and Country',
    'LHS',
    'Cordoba',
    'Stratus',
    '300C Touring',
    'Concorde',
    'Valiant',
    'LeBaron',
    'Plymouth',
    'Viper',
    'Conquest',
    'Prowler',
    'Windsor',
    'Royal',
    'Vision',
    'Neon',
    'Intrepid',
    'Airflow',
    'Saratoga',
    'Grand Caravan',
    'Daytona',
    'Viper GTS-R',
    '300F',
    '300B',
    'ME Four-Twelve',
    'Valiant Charger',
    'Cirrus',
    'TC',
    'Thunderbolt',
    'Laser',
    'Executive',
    'Fifth Avenue',
    'Delta',
    'Charger',
    'Ypsilon',
    '300D',
    '180',
    '300G',
    'Sunbeam',
    '200C EV',
    'Turbine Car',
    'by Chrysler',
    '300L',
    '150',
    'Panel Cruiser',
    'Valiant (VG)',
    'Avenger',
    'Centura',
    'Turbine',
    'Valiant (VC)',
    'Valiant Regal',
    '300F Convertible',
    'Atlantic Concept',
    '160',
    'Valiant (VF)',
    'Pacifica (minivan)',
    'Plainsman',
    'Valiant (VJ)',
    '300H',
    '300 letter series',
    'Phaeton',
    'ecoVoyager Concept',
    'Valiant (VH)',
    'Valiant (VE)',
    'Town And Country Wagon',
    'Valiant (AP5)',
    'Valiant (CM)',
    'Valiant (AP6)',
    'Airstream',
    'Valiant (CL)',
    '300K',
    '300E',
    'Alpine',
    'New Yorker Turbo',
    'Eagle Vision',
    'Touring',
    'Valiant (SV1)',
    'Galant',
    'Esplanada',
    'Sigma',
    'Nassau',
    'Imperial Parade Phaeton',
    'Firepower Concept',
    'Hunter',
    'Regal',
    'Dodge Daytona',
    'Lancer',
    'Wayfarer',
    '2 Litre',
    'Valiant (VK)',
    'Airflite',
    'California Cruiser Concept',
    'Drifter',
    'Valiant Valiant',
    'LLC',
    'E-Class',
    'Dynasty',
    'Atlantic',
    'GTX',
    '300K Convertible',
    'Firepower',
    '300 non-letter series',
    'ES',
    'GS',
    'VIP',
    'Valiant Galant',
    'Valiant (RV1)',
    'Colt',
    'Valiant Drifter',
    'Chronos Concept',
    'Falcon Concept',
    'TC by Maserati',
  ],
  Cisitalia: ['202', 'D46'],
  Citroen: [
    'C3',
    'C4',
    'C5',
    'Berlingo',
    'C4 Picasso',
    'DS 3',
    'C3 Picasso',
    'C1',
    '2CV',
    'C4 Cactus',
    'Xsara Picasso',
    'Grand C4 Picasso',
    'Xsara',
    'DS',
    'C2',
    'Jumper',
    'C4 Aircross',
    'Mehari',
    'DS 5',
    'DS 4',
    'Saxo',
    'Jumpy',
    'Nemo',
    'Berlingo Multispace',
    'Dyane',
    'DS 21',
    'C5 Tourer',
    'Xantia',
    'Space Tourer',
    'C-Zero',
    'C4 Coupe',
    'C3 Aircross',
    'C15',
    'Dispatch',
    'CX',
    'C5 Aircross',
    'C Elysee',
    'Traction Avant',
    'SM',
    'BX',
    'C3 Pluriel',
    'C6',
    'C5 II',
    'AX',
    'XM',
    'C8',
    'Ami 6',
    'Acadiane',
    'C4 II',
    'ZX',
    'E-Mehari',
    'GS',
    'Metropolis',
    'Ami 8',
    'C-Crosser',
    'Relay',
    'Survolt',
    'H Van',
    'Xsara WRC',
    'C25',
    'Revolte',
    'Activa',
    'ID 19',
    'U23',
    'Visa',
    'C3 II',
    'D Super',
    'DS3 WRC',
    'Nemo Multispace',
    'C3 WRC',
    'GSA',
    'C4 WRC',
    'Picasso',
    'ID',
    'Berlingo II',
    'DS 5LS',
    'C35',
    'Ami',
    '11CV',
    'Van',
    'Jumpy Multispace',
    'C3 R5',
    'Evasion',
    'Hypnos',
    'C3 III',
    'Rosalie',
    'Axel',
    'C4 Picasso II',
    'DS 7 Crossback',
    'C5 I',
    'Berlingo Electric',
    'D Special',
    'GS / GSA',
    'Lacoste',
    'C4 I',
    'Bijou',
    'LNA',
    'C-SportLounge',
    'Karin',
    'Berlingo III',
    'C3 I',
    'Jumper II',
    'Elysee',
    'LN',
    'Trefle',
    'GT by Citroen',
    'C-Airdream',
    'C-Cactus',
    'Jumpy II',
    'M35',
    'C-Metisse',
    'Ami Super',
    'C3-XR',
    'City car',
    'DS Inside',
    'GS Camargue',
    'C4 Picasso I',
    'DS 6',
    'C-Airplay',
    'Fukang',
    'C-Triomphe',
    'ID 21',
    'FAF',
    'C4 & C6',
    'Technospace',
    'C44',
    'C-Buggy',
    'DS 4S',
    'TUB',
    'Convertible',
    'Supermini',
    'Jumper I',
    'Jumpy I',
    'Type A',
    'Type C',
    'C1 I',
    'Berlingo electrique',
    'Osee',
    'LN / LNA',
    'Synergie',
    'Zabrus',
    'Type B2',
    'C1 II',
    'C6 Lignage',
    'Belphegor',
    'C-Elysse',
    'Executive car',
    'Type C 5HP',
    'Crossover SUV',
    'Economy car',
    'TUB / TUC',
  ],
  Cizeta: ['V16t', 'Moroder V16T'],
  Clan: ['Crusader'],
  Corvette: ['Stingray', 'Daytona Prototype'],
  Cowin: ['X3', 'C3'],
  Cunningham: ['C3'],
  Cupra: ['Ateca'],
  CWS: ['T-1'],
  Dacia: [
    'Duster',
    'Sandero',
    'Logan',
    '1300',
    'Dokker',
    'Lodgy',
    'Stepway',
    'SupeRNova',
    '1310',
    '1410',
    'Pick-Up',
    '1100',
    'Solenza',
    'Nova',
    '500 Lastun',
    '1320',
    '1325',
    '500',
    'Lastun',
    '1307',
    '2000',
    '1309',
    '1304',
    '1210',
    'Estafette',
  ],
  Dacon: ['828'],
  Dadi: ['Shuttle', 'Smoothing'],
  Daewoo: [
    'Nexia',
    'Matiz',
    'Nubira',
    'Lanos',
    'Winstorm',
    'Espero',
    'Kalos',
    'Lacetti',
    'Leganza',
    'Gentra',
    'Prince',
    'Rezzo',
    'Tico',
    'Magnus',
    'Cielo',
    'Labo',
    'Tacuma',
    'Super Salon',
    'Racer',
    'Damas',
    'Tosca',
    'Musso',
    'G2X',
    'Evanda',
    'LeMans',
    'Lublin',
    'Lacetti Premiere',
    'Chairman',
    'Arcadia',
    'Veritas',
    'Alpheon',
    'Korando',
    'Royale',
    'Brougham',
    'Rezzo-Tacuma',
    'Condor',
    'Statesman',
    'Maepsy',
    'Mirae',
    'Rexton',
    '1.5i',
    'Heaven',
    'Imperial',
    'Pointer',
    'Fantasy',
  ],
  DAF: ['66', 'SB220', '400', '55', 'MB200', '33', '44', 'Trucks', '46', '600', 'Daffodil', '750', 'Tjorven', 'XF'],
  Daihatsu: [
    'Terios',
    'Copen',
    'Xenia',
    'Mira',
    'Sigra',
    'Charade',
    'Taruna',
    'Rocky',
    'Hijet',
    'Sirion',
    'YRV',
    'Cuore',
    'Tanto',
    'Taft',
    'Move',
    'Mira e:S',
    'Boon',
    'Gran Max',
    'Cast',
    'Applause',
    'Feroza',
    'Esse',
    'Be-go',
    'Gran Move',
    'Atrai7',
    'Ceria',
    'Ayla',
    'Storia',
    'Mira Gino',
    'Pyzar',
    'Midget',
    'Mira Cocoa',
    'Luxio',
    'Materia',
    'Atrai',
    'Boon Luminas',
    'Charmant',
    'Compagno',
    'Opti',
    'Move Latte',
    'Tanto Exe',
    'Coo',
    'Fourtrak',
    'Altis',
    'Delta',
    'Grand Move',
    'Trevis',
    'Sportrak',
    'Domino',
    'Rugger',
    'Wake',
    'Mira Tocot',
    'Sonica',
    'Fellow Max',
    'Move Conte',
    'Wildcat',
    'Espass',
    'Max',
    'Naked',
    'Zebra',
    'Hi-Max',
    'Valera',
    'Leeza',
    'Fellow',
    'Thor',
    'Extol',
    'Perodua-Viva',
    'Consorte',
    'Bee',
    'Scat',
    'Handivan',
    'New Line',
    'Costa',
    'UFE-III',
    'P5',
    'Charade Centro',
  ],
  Daimler: [
    'SP250',
    'Double-Six',
    'Sovereign',
    'DB18',
    'Limousine',
    'Conquest',
    'V8-250',
    'DS420',
    '250',
    'Super-Eight',
    'V8',
    'Majestic Major',
    'Six',
    'Dart',
    'Consort',
    'DE36',
    'DE',
    'XJ40',
    'Majestic',
    'Saloon',
    'Regency',
    'XJ',
    'Super 8',
    'Landaulette',
    'Coupe',
    'Century',
    'DK400',
    'Fifteen',
    'One-O-Four',
  ],
  Dallara: ['SF14'],
  Darwen: ['Olympus'],
  Datsun: [
    '240 Z',
    '280Z',
    'Bluebird',
    '510',
    'on-Do',
    '1200',
    '280ZX',
    'Go+',
    '510 Wagon',
    'Skyline',
    '1600',
    '120 Y',
    'Fairlady',
    'mi-Do',
    '260Z',
    '2000',
    'Sunny',
    'Cherry',
    'Laurel',
    '710',
    'B210',
    'Go',
    '180B',
    'Roadster',
    '210',
    '510 Sedan',
    '310',
    'Violet',
    '280Z Coupe',
    '1000',
    '120Y Coupe',
    '610 Wagon',
    'Bluebird (910)',
    'Silvia',
    'redi-Go',
    '210 Wagon',
    '810 Wagon',
    '160J',
    'Truck',
    '200B',
    '200SX',
    'Stanza',
    '810',
    '120A Coupe',
    'Sports',
    '1800 SSS',
    'B-210 Coupe',
    '110',
    '280C',
    '140J',
    '240K',
    '260C',
  ],
  'David Brown': ['Speedback GT', 'Speedback'],
  DB: ['Coach'],
  DC: ['Avanti'],
  'De Tomaso': [
    'Pantera',
    'Mangusta',
    'Guara',
    'Deauville',
    'Longchamp',
    'Vallelunga',
    'P72',
    '505/38',
    'Innocenti Mini',
    'Bigua',
    'Sport 5000',
    'Qvale Mangusta',
    'F1',
  ],
  Delage: ['D8', 'D6', 'D4'],
  Delahaye: ['135', 'Type 135', '235', '175', 'Type 175', '180', '148', 'Delage D8'],
  DeLorean: ['DMC 12'],
  Dennis: ['Trident 2', 'Sabre'],
  Derways: ['Plutus', 'Aurora', 'Shuttle', 'Cowboy', 'Lifan'],
  DeSoto: ['Adventurer', 'Fireflite', 'FireDome', 'Firesweep', 'Custom'],
  Dino: ['246 GTS', '246 GT', '308 GT4', '206 SP', '206 GT', '206 S', '208 GT4'],
  DKW: [
    'Munga',
    'F7',
    'Sonderklasse',
    'F89',
    'F102',
    '3=6',
    'Junior',
    'Schnellaster',
    'Monza',
    'F5',
    'F8',
    'F 12 Roadster',
    'F1',
    'F9',
    'F2',
  ],
  Dodge: [
    'Challenger',
    'Charger',
    'Viper',
    'Dart',
    'Avenger',
    'Magnum',
    'Grand Caravan',
    'Durango',
    'Neon',
    'Journey',
    'Caliber',
    'Dakota',
    'Demon',
    'Power Wagon',
    'Sprinter',
    'Coronet',
    'Caravan',
    'Nitro',
    'Neon SRT-4',
    'Intrepid',
    'Stratus',
    'Charger Daytona',
    'Ramcharger',
    'Aspen',
    'Shadow',
    'Super Bee',
    'Daytona',
    'Polara',
    'Stealth',
    'Phoenix',
    'WC54',
    'Custom Royal',
    '600',
    'Lancer',
    'Monaco',
    '1955 Dodge',
    'Wayfarer',
    'Viper GTS-R',
    'Royal',
    'Ram SRT-10',
    'Omni',
    'Colt',
    'Spirit',
    'Diplomat',
    'Mirada',
    'Aries',
    'Custom',
    '330',
    'A100',
    'Raider',
    'Custom 880',
    'Warlock',
    'B series',
    'Deora',
    'Meadowbrook',
    'Dynasty',
    '440',
    'St. Regis',
    'Ram 50',
    '1958 Dodge',
    'Shelby Charger',
    'M37',
    'Rampage',
    '300',
    'Mini Ram',
    'Venom',
    'i10',
    'D-500',
    'D series',
    'Omni 024',
    'Attitude',
    'Matador',
    'Royal Monaco',
    'Colt Vista',
    'Charger (B-body)',
    'La Femme',
    'SE Sedan',
    'Conquest',
    'Little Red Wagon',
    'SpaceVan',
    'Kingsway',
    'Trazo',
    '400',
    'Deluxe',
    'WC series',
    'C series',
    'Zeo Concept',
    'Razor',
    'Alpine',
    'Bluesmobile',
    'Dart (PF)',
    'Copperhead',
    'Fast Four',
    'Job-Rated trucks',
    'Charger (LX/LD)',
    'T-Rex',
    '500',
    'Airflow truck',
    'EPIC',
    'Rampage Concept',
    'SX',
    'Viscount',
    'Tomahawk',
    'Atos',
    'Charger (L-body)',
    '50 Series',
    'Crusader',
    'Charger III',
    'Sidewinder',
    'Omni Charger',
    'Hornet',
    '1100',
    'Arrow',
    '2000 GTX',
    'Brisa',
    'T245',
  ],
  Dome: ['Zero'],
  Dongfeng: [
    'Fengshen S30',
    'Rich',
    'Fengshen AX7',
    'Fengshen A60',
    'Succe',
    'Fengshen H30',
    'Fengxing Lingzhi',
    'MPV',
    'Fengshen AX4',
    'Fengxing SX6',
    'Fengshen A30',
    'Fengxing CM7',
    'EQ2102',
    'Fengshen L60',
    'Mengshi',
    'Citroen Fukang',
    'Fengdu MX5',
    'Fengxing S500',
    'Fengshen A9',
    'Fengshen AX5',
    'Fengshen AX3',
    'Oting',
    'Fengxing T5',
    'Fengxing Jingyi S50',
  ],
  Donkervoort: ['D8 GTO', 'D8', 'D8 GT'],
  DS: ['3', '5', '4', '7 Crossback', '4 Crossback', '3 Cabrio', '7', '3 Crossback', '5LS', '6', '4S'],
  'Dual-Ghia': ['Convertible', 'L 6.4'],
  Ducati: ['Panigale', 'Scrambler', 'Diavel', 'SuperSport', 'Multistrada'],
  Duesenberg: ['Model J', 'Model SJ', 'Model A', 'Mormon Meteor'],
  Eagle: ['Talon', 'Vision', 'Summit Wagon', 'Premier'],
  'East Lancs': ['Spryte', 'Lolyne', 'Esteem', 'Olympus', 'Lowlander', 'Pyoneer', 'Vyking', 'Myllennium', 'Nordic', 'EL2000', 'European'],
  Edsel: ['Ranger', 'Citation', 'Corsair', 'Villager', 'Bermuda', 'Pacer', 'Pacer Convertible', 'Roundup'],
  Elfin: ['MS8 Streamliner', 'MR5', 'MS8 Clubman', 'Mallala', 'Type 600'],
  Elva: ['Courier'],
  EMW: ['327'],
  Enzmann: ['506'],
  ERA: ['R4D', '30'],
  Eunos: ['Roadster', 'Presso'],
  Exeed: ['TX', 'TXL'],
  'Facel Vega': ['HK500', 'Facellia', 'Excellence', 'Facel II', 'FVS'],
  FAW: [
    'Besturn',
    'V5',
    'Vita',
    'X-PV',
    'V2',
    'Sirius',
    'Jinn',
    'Carrier',
    'Senya',
    'Senia R9',
    'Jetta',
    'Junpai D60',
    'Junpai A50',
    'Bora',
    'Junpai CX65',
    'Audi 100',
    'Senya M80',
    'Senia R7',
    'Senya S80',
  ],
  Ferrari: [
    '458',
    'LaFerrari',
    'F430',
    'F40',
    'Maranello',
    'Testarossa',
    '488',
    'FF',
    '599 GTO',
    'F355',
    'F12',
    'California',
    'F12tdf',
    '360',
    '250',
    '599',
    '250 GTO',
    '275',
    'FXX-K',
    'Enzo',
    '812 Superfast',
    '330',
    'California T',
    '328 GTS',
    'FXX',
    'GTC4Lusso',
    '250 Testa Rossa',
    '430',
    '348',
    '599XX',
    '288 GTO',
    'Daytona',
    'Barchetta',
    '308 GTB',
    '308 GTS',
    'Dino',
    '308',
    '308 GT4',
    'F50',
    '612 Scaglietti',
    'Mondial',
    'Superamerica',
    '365',
    'Dino 246 GT',
    'Portofino',
    '512',
    'SA Aperta',
    '250 GT Lusso',
    '456',
    '512BB',
    '575M Maranello',
    '328 GTB',
    '212 Inter',
    'P4/5',
    'GTC4Lusso T',
    '550',
    'Monza',
    'F430 Challenge',
    '412',
    '365 GT4 BB',
    '400',
    'Challenge',
    '612',
    'F60 America',
    '312 P',
    '328',
    '575M',
    '750 Monza',
    '375 MM',
    '500 Superfast',
    'F512 M',
    '208 GTB',
    'Scuderia Spider 16M',
    '410 SuperAmerica',
    '365 GTC/4',
    '340',
    '212 Export',
    '500 Mondial',
    '208 GTS',
    'Berlinetta Boxer',
    '575 GTZ',
    'F8 Tributo',
    '375 Plus',
    '166 Inter',
    '195 Inter',
    '208',
    'F50 GT',
    'Club of America',
    '166',
    '375',
    '625',
    '208 GT4',
    'Dino 206 GT',
    '125 S',
    '308 GTBi',
    '812',
    '290 MM',
    'GG50',
    'SF90 Stradale',
    'P4/5 by Pininfarina',
    'Dino GT4',
    '500 TR',
    '333 SP',
    '488 GTE',
    '612 Sessanta',
    'F60',
    '575M SuperAmerica',
    '500 TRC',
    '412 T2',
    '312 PB',
    '335 S',
    '330 TRI/LM',
    'Pinin',
    'P',
    '315 S',
    '195 S',
    '342 America',
    '250 MM',
    '410 S',
    'GTC4',
    '196 S',
    'Land',
    'Ascari',
    '375 F1',
    'SF70H',
    'GT4',
    '308 GTB/GTS',
    'America',
    '288',
    '208/308',
    '125',
    'TR',
    '250 GT SWB Breadvan',
    '412 T1',
    '166 S',
    'Modulo',
    '268 SP',
    '625 F1',
    '408',
    '246 SP',
    '408 4RM',
    'Auto Avio Costruzioni 815',
    'Monza SP',
    '246 F1',
    '275 S',
    'Inter',
    '125 F1',
    '637',
    '159 S',
    '156 F1',
    'Mythos',
    'F2012',
    '250 TR 61 Spyder Fantuzzi',
    'F2008',
    'SF71H',
    'F80 Concept',
    'F2007',
    'flat-12 engine',
    '312',
    'Millechili',
    '150° Italia',
    'SF16-H',
    '365 GT4 2+2, 400 and 412',
  ],
  Fiat: [
    '500',
    'Punto',
    'Panda',
    'Ducato',
    'Doblo',
    '500L',
    '500X',
    '500C',
    'Grande Punto',
    'Bravo',
    'Fiorino',
    'Tipo',
    'Palio',
    '124 Spider',
    '1100',
    '850',
    'Uno',
    'Marea',
    'Stilo',
    '127',
    'Fullback',
    'Freemont',
    'Cinquecento',
    'Albea',
    'Multipla',
    'X1/9',
    'Mobi',
    '128',
    'Mille',
    'Palio Weekend',
    'Seicento',
    'Scudo',
    'Qubo',
    'Brava',
    'Strada',
    '126',
    'Croma',
    'Idea',
    'Sedici',
    'Toro',
    '600',
    'Pininfarina',
    'Vivace',
    'Linea',
    '147',
    '124',
    'Siena',
    'Barchetta',
    'Coupe',
    '500S',
    '131',
    'Tempra',
    'Dino',
    '2300',
    'Iveco',
    'Spazio',
    '1500',
    'Ritmo',
    'Egea',
    '124 Sport Spider',
    '125',
    'Duna',
    '500e',
    '508',
    'Viaggio',
    '8V',
    '124 Sport Coupe',
    '130',
    '509',
    'Talento',
    'Cronos',
    '1200',
    'Argo',
    'Campagnola',
    '"500 Topolino"',
    'Spider 2000',
    '600D',
    'Ulysse',
    'Abarth 750',
    '132',
    '238',
    'Regata',
    '133',
    'Premio',
    '501',
    '500 (2007)',
    '242',
    '70',
    '124 Spider (2016)',
    'Familiare',
    '514',
    'Argenta',
    'Bravo (2007)',
    'Panorama',
    '900',
    '525',
    'Oggi',
    '500c Abarth',
    'Elba',
    '1',
    'Marengo',
    'Turbina',
    '520',
    'Daily',
    'Brio',
    '503',
    'Tipo (2015)',
    '900T',
    '505',
    '1900',
    'New-500',
    '2800',
    'Perla',
    '241',
    'Zero',
    '521',
    'Oltre',
    'Egea/Tipo',
    '522',
    '507',
    'Abarth 850TC',
    'Citivan',
    'Brevetti',
    '1500 (1961)',
    'Mephistopheles',
    '130 HP',
    '519',
    'S74',
    '527',
    '518',
    '512',
    '1600 Berlina',
    'Oltre Concept',
    '1600 Sport',
    '502',
    '12 HP',
    '60 HP',
    'Regatta Weekend',
    '1800',
    '1500 (1935)',
    '524',
    'Abarth 124 Rally',
    'Lombardi Grand Prix',
    'Cabriolet Pininfarina',
    'Trepiuno',
    'S76 Record',
    '1100 (1937)',
    'Panda Hydrogen',
    'X1/23',
    '16-20 HP',
    'Pininfarina Cabriolet',
    '510',
    '2B',
    'Penny',
    '135',
    '8 HP',
    '1300 and 1500',
    '515',
    '900 T/E',
    '24-32 HP',
    'Bravo and Brava',
    'Veicoli Industriali',
    '10 HP',
    '6 HP',
    '4 HP',
    '1800 and 2100',
  ],
  Fiberfab: ['Bonito'],
  Fisker: ['Karma'],
  FMR: ['TG 500'],
  Foday: ['Landfort', 'Lion F22'],
  Ford: [
    'Focus',
    'Mustang',
    'Fiesta',
    'Mondeo',
    'F-150',
    'Fusion',
    'Escape',
    'Ranger',
    'Escort',
    'KA',
    'Explorer',
    'S-Max',
    'C-Max',
    'Kuga',
    'Super Duty',
    'Transit',
    'Falcon',
    'Transit Connect',
    'Galaxie',
    'Galaxy',
    'GT',
    'Taurus',
    'Crown Victoria',
    'EcoSport',
    'F-250',
    'Fairlane',
    'Edge',
    'Territory',
    'Granada',
    'Bronco',
    'Flex',
    'Thunderbird',
    'GT40',
    'B-MAX',
    'Model A',
    'Grand C-MAX',
    'Scorpio',
    'F-350',
    'Tourneo Connect',
    'Fusion Hybrid',
    'Transit Custom',
    'F-100',
    'F-450',
    'Sierra',
    'Capri',
    'Econoline',
    'Shelby GT 500',
    'Expedition',
    'LTD',
    'Fairmont',
    'F-650',
    'Crown Victoria Police Interceptor',
    'Five Hundred',
    'Puma',
    'Custom',
    'Shelby',
    'Maverick',
    'Taunus',
    'Deluxe',
    'Torino',
    'Model T',
    'Cortina',
    'Focus C-MAX',
    'Taurus SHO',
    'LTD Crown Victoria',
    'Everest',
    'Tempo',
    'Consul',
    'Figo',
    'Station Wagon',
    'Probe',
    'Tourneo-Custom',
    'Tourneo Courier',
    'Ranchero',
    'Crestline',
    'Futura',
    'Tourneo',
    'Cobra',
    'Starliner',
    'Excursion',
    'Laser',
    'Telstar',
    'Anglia',
    'Courier',
    'Corcel',
    'Falcon (XT)',
    'Pampa',
    'Mustang Mach 1',
    'Interceptor',
    'F Series',
    'Zephyr',
    'Explorer Sport Trac',
    'Escort RS Cosworth',
    'E-Series',
    'Shelby GT350',
    'RS200',
    'Festiva',
    'Sport Trac',
    'Bronco II',
    'Country Squire',
    'Super Deluxe',
    'Skyliner',
    'Versailles',
    'Windstar',
    'Sunliner',
    'Fordor',
    'Customline',
    'ZX2',
    'F-550',
    'Streetka',
    'Cougar',
    'Lotus Cortina',
    'Freestyle',
    'Lynx',
    'Popular',
    'Mercury',
    'Pinto',
    'Fiesta WRC',
    'Ranch Wagon',
    'Mustang SVT Cobra',
    'Endeavour',
    'Freestar',
    'Landau',
    'Transit Courier',
    'Bantam',
    'Orion',
    'Fairlane 500 Skyliner',
    'Aerostar',
    'Sportka',
    'Aspire',
    'Del Rey',
    'E-350',
    'GPA',
    'Torino Talladega',
    'Contour',
    'Fairlane Thunderbolt',
    'Ikon',
    'Model B',
    'Custom Deluxe',
    'Mustang RTR',
    'E-250',
    'Endura',
    'Ranger (T6)',
    'F-750',
    'F-800',
    'F-700',
    'E-450',
    'Marquis',
    'Custom 500',
    'Rural',
    'Executive',
    'E-150',
    'Falcon GT',
    'F-600',
    'Fiesta R5',
    'Econovan',
    'Model 48',
    'Tierra',
    'Mustang II',
    'Country Sedan',
    'Shelby GR-1',
    'Meteor',
    'Spectron',
    'Crestline Skyliner',
    'FK 1000',
    'Falcon (XL)',
    'P100',
    'Territory SX',
    'Model F',
    'Falcon (XB)',
    'XL',
    'Full-size Ford',
    'Mainline',
    'Consul Cortina',
    'Performance Vehicles',
    'Falcon (BA)',
    '500',
    'Freda',
    'Focus Electric',
    'Consul Classic',
    'Aston Martin',
    'Royale',
    'LTD II',
    'Transit Bus',
    'Fiesta RS WRC',
    'Comete',
    'E-100',
    '300',
    'Taunus Transit',
    'Verona',
    'Activa',
    'Model C',
    'Land Rover',
    'Territory SZ',
    'Zodiac Mk II',
    'EXP',
    'Falcon (XY)',
    'Model K',
    'Mustang I',
    'SVT Focus',
    'Falcon (AU)',
    'Zodiac',
    'Durango',
    'Falcon (XW)',
    'Model N',
    'Model B (1932)',
    'Taunus TC',
    'Pilot',
    'Focus RS WRC',
    'Falcon (XR)',
    'Pronto',
    'Gran Torino Station Wagon',
    'Falcon (XA)',
    'Consul 315',
    'M-151A2',
    'Del Rio',
    'Comuta',
    'Liata',
    'Falcon (XC)',
    'Elite',
    'Falcon (BF)',
    'Mustang GT-R',
    'GT90',
    'Supervan',
    'B series',
    'Consul Corsair',
    'Falcon (XF)',
    'Falcon (XP)',
    'Prefect',
    'F-53 Motor Home Chassis',
    'GT70',
    'Quadricycle',
    'Corsair',
    'Model AA',
    'Cargo',
    'Squire',
    'M-151',
    'Jaguar',
    'Model TT',
    'Falcon (XE)',
    'Fiesta S2000',
    'Trader',
    'Gran Torino Squire',
    'Airstream',
    'Falcon (XD)',
    'Ixion',
    'BA Fairlane',
    'Falcon Cobra',
    'Parklane',
    'Libre',
    'Rheinland',
    'Cobra Concept',
    'Falcon (EL)',
    'Model Y',
    'Taunus P5',
    'Monarch',
    'Raider',
    'Falcon (EF)',
    'F-250 Super Chief',
    'Falcon (XM)',
    'Carousel',
    'Vedette',
    'LCF L55',
    'LCF 450',
    'Falcon (EA)',
    '999',
    'Falcon (XK)',
    'Taunus P6',
    'Husky',
    'A series',
    'Taunus P3',
    'Galpin GTR1',
    'Ala Kart',
    'Consul Estate',
    'Corsair Convertible',
    'Mustang GTP',
    'Mustang FR500',
    'V8 Pilot',
    'Taunus P4',
    'Falcon (EB)',
    'Taunus P2',
    'XY Falcon GT',
    'Model C Ten',
    'El Matador',
    'Koln',
    'Atlas',
    'Falcon (XH)',
    'Falcon (ED)',
    'Reflex',
    'Falcon (XG)',
    'Mustang SSP',
    'C11ADF',
    'Escort RS 1700T',
    'Frontenac',
    'E-300',
    'LTD Country Squire',
    'Mutt M 825',
    'Model U',
    '20M',
    'Focus (third generation)',
    'Pygmy',
  ],
  Fornasari: ['Racing Buggy'],
  Foton: ['Tunland', 'Midi', 'Thunda', 'ISF', 'Saga'],
  FPV: ['GT', 'F6', 'Pursuit', 'Super Pursuit', 'GT F', 'GT-P', 'GT R-spec', 'GT-E', 'GS'],
  'Frazer-Nash': ['Le Mans Replica', 'LeMans', 'Targa Florio', 'Mille Miglia'],
  Freightliner: [
    'Business Class M2',
    'Cascadia',
    'Argosy',
    'Century Class',
    'Inspiration',
    'FS-65',
    'Coronado',
    'FL70',
    'Sprinter',
    'Trucks',
  ],
  FSC: ['Zuk', 'Lublin', 'Star'],
  FSO: ['Polonez', '125p', 'Warszawa', 'Syrena', 'Polski Fiat 125p', 'Syrena Sport', '126p', 'Prima', 'Syrena 110'],
  Galloper: ['Hyundai Galloper', 'Exceed', 'Santamo', 'Innovation'],
  GAZ: [
    'Volga',
    '31105',
    '69',
    '31029',
    'Volga Siber',
    'Sobol',
    'GAZelle',
    'Pobeda',
    'Siber',
    'GAZelle NEXT',
    '21',
    '24',
    'M20 Pobeda',
    '67',
    'Chaika',
    'M1',
    '3110',
    '66',
    '3102',
    '12 ZIM',
    '3309',
    '13',
    '3307',
    '2410',
    '69A',
    '14',
    '67B',
    '2402',
    '20',
    '22',
    '52',
    'A',
    '53A',
    '3111',
  ],
  Geely: [
    'MK',
    'Emgrand',
    'CK',
    'Emgrand EC7',
    'Emgrand X7',
    'LC',
    'Vision',
    'GC2',
    'GC6',
    'EC7',
    'Boyue',
    'Otaka',
    'MR',
    'Emgrand EC8',
    'Binyue',
    'Borui GE',
    'Jiaji',
    'Beauty-Leopard',
    'FC',
    'Borui',
    'EX7',
    'Emgrand GE',
    'HQ',
    'Xingyue',
    'Yuanjing',
    'Emgrand KC',
    'Binrui',
    'Yuanjing X3',
    'Yuanjing X1',
    'CD',
    'PU',
  ],
  'General Motors': ['Firebird', 'EV1', 'Le Sabre'],
  Genesis: ['G80', 'G90', 'G70'],
  Geo: ['Tracker', 'Metro', 'Prizm', 'Storm', 'Spectrum'],
  Ghia: ['1500 GT', '450 SS', '450 SS Convertible'],
  Ginetta: [
    'G55',
    'G50',
    'G4',
    'G40',
    'GT5 Challenge',
    'GT4 Supercup',
    'G15',
    'G20',
    'Junior Championship',
    'G33',
    'G60',
    'F400',
    'G27',
    'G26',
    'G34',
    'G60-LT-P1',
    'G32',
  ],
  Giovanni: ['Michelotti'],
  GM: ['Uzbekistan'],
  GMC: [
    'Sierra',
    'Acadia',
    'Savana',
    'Suburban',
    'Terrain',
    'Safari',
    'Denali',
    'Yukon XL',
    'Yukon',
    'Canyon',
    'Envoy',
    'Vandura',
    'Sonoma',
    'Jimmy',
    'Syclone',
    'TopKick',
    'Envoy XL',
    'Typhoon',
    'C/K 1500 Series',
    'C/K 3500 Series',
    'General',
    'W4500',
    'K5 Jimmy',
    'motorhome',
    'S-15 Jimmy',
    'Caballero',
  ],
  GME: ['Midi', 'Rascal'],
  Goggomobil: ['TS-250'],
  Gonow: ['Way', 'GX6', 'Xinglang'],
  'Great Wall': [
    'Hover',
    'Haval H6',
    'V200',
    'Safe',
    'Steed',
    'Deer',
    'X240',
    'Wingle',
    'Wingle 5',
    'Haval M4',
    'Voleex C10',
    'X200',
    'Voleex C30',
    'V240',
    'Haval H3',
    'Pegasus',
    'Haval H5',
    'Voleex',
    'Peri',
    'Coolbear',
    'Steed 5',
    'Sailor',
    'Florid',
    'Wingle 3',
    'SoCool',
    'Voleex C20R',
    'Haval H2',
    'Voleex C50',
    'SUV',
    'Wingle 6',
    'Haval H6 Coupe',
    'Cowry',
    'Haval H9',
    'Haval H7',
    'Haval H8',
    'Sing',
    'Haval H4',
    'Haval F7',
    'Haval M6',
  ],
  Griffith: ['200'],
  Grinnall: ['Scorpion III'],
  GTM: ['Libra', 'Coupe', 'Rossa'],
  Gumpert: ['Apollo'],
  Gurgel: ['Carajas', 'Supermini', 'BR-800', 'Itaipu'],
  GWM: ['M4'],
  Hafei: ['Brio', 'Ruiyi', 'Saibao', 'Simbo', 'Sigma', 'Lobo'],
  Haima: ['3', '2', 'M3', '7', 'Freema', 'Family', 'S5', 'M8', 'M6', 'Aishang', 'S5 Young'],
  Hanteng: ['X7', 'X5'],
  'Harley-Davidson': ['Sportster', 'Street', 'Hummer'],
  Haval: ['H2', 'H6 Coupe'],
  Healey: ['Silverstone', 'Westland'],
  Heinkel: ['Kabine'],
  Hennessey: ['Venom GT'],
  Henry: ['J'],
  HICOM: ['DRB-HICOM', 'Perkasa'],
  Hillman: ['Imp', 'Hunter', 'Minx', 'Avenger', 'Super Minx', 'Husky', 'Gazelle', '14', 'Estate Car', 'Arrow', 'Wizard', 'Vogue'],
  Hindustan: ['Ambassador', 'Contessa', 'Pajero', 'Lancer'],
  Hino: [
    'Ranger',
    "S'elega",
    'Rainbow',
    'Profia',
    'Dutro',
    'Contessa',
    '700',
    'Blue Ribbon',
    'Melpha',
    'Poncho',
    'Liesse',
    'XL',
    '600',
    'Briska',
    'TH-series',
    'Commerce',
  ],
  Holden: [
    'Commodore',
    'Colorado',
    'Cruze',
    'Captiva',
    'Calais',
    'Monaro',
    'Torana',
    'Astra',
    'Statesman',
    'Barina',
    'Trax',
    'Caprice',
    'Ute',
    'Crewman',
    'Premier',
    'Rodeo',
    'Kingswood',
    'Special Vehicles',
    'Barina Spark',
    'Sportwagon',
    'Commodore (VE)',
    'Adventra',
    'Spark',
    'Viva',
    'Jackaroo',
    'Berlina',
    'TrailBlazer',
    'Vectra',
    'Epica',
    'Frontera',
    'Acadia',
    'Malibu',
    'Belmont',
    'Equinox',
    'Cascada',
    'Commodore (VF)',
    'Sandman',
    'Gemini',
    'SS',
    'Caprice (WM)',
    'Apollo',
    'New Zealand',
    'Volt',
    'Nova',
    'Insignia',
    'Commodore (VZ)',
    'Special',
    'Combo',
    'GTS',
    'Commodore (VY)',
    'Tigra',
    'One Tonner',
    'Commodore (VT)',
    'Brougham',
    'Commodore (VL)',
    'Zafira',
    'Standard',
    'Camira',
    'Suburban',
    'Commodore (VN)',
    'Calibra',
    'Commodore (VS)',
    'Limited Edition',
    'Commodore (VX)',
    'Drover',
    'Dealer Team',
    'EH Sedan',
    'FJ',
    'EH',
    'FC Sedan',
    'HQ',
    'Statesmann',
    'Sunbird',
    'FJ Sedan',
    'Sportwagon VE',
    'HR Sedan',
    'Commodore (VB)',
    'Hurricane',
    'EJ',
    'Shuttle',
    'HR',
    'EJ Sedan',
    'FE Sedan',
    'EK',
    'V8 engine',
    'Red motor',
    'HJ',
    'FC',
    'Piazza',
    'Monterey',
    'HD',
    'EK Sedan',
    'FB Sedan',
    '48-215',
    'Coupe 60',
  ],
  Honda: [
    'Civic',
    'Accord',
    'CR-V',
    'Fit',
    'Odyssey',
    'HR-V',
    'Civic Type R',
    'Jazz',
    'S2000',
    'Stream',
    'Integra',
    'Civic Si',
    'Prelude',
    'Mobilio',
    'Brio',
    'Airwave',
    'Stepwgn',
    'NSX',
    'Crosstour',
    'Pilot',
    'Insight',
    'Torneo',
    'S660',
    'Ridgeline',
    'Inspire',
    'City',
    'CR-Z',
    'Vezel',
    'Partner',
    'Passport',
    'Shuttle',
    'CR-X',
    'Mobilio Spike',
    'Orthia',
    'Freed',
    'Legend',
    'Element',
    'Logo',
    'Ballade',
    'Avancier',
    'CR-X del Sol',
    'Domani',
    'Saber',
    'Capa',
    'Edix',
    'S-MX',
    'Elysion',
    'Life',
    'Ascot',
    'Fit Shuttle',
    'FR-V',
    'S800',
    'Clarity',
    'Crossroad',
    'Acty',
    'Stepwagon',
    'Gyro',
    'Amaze',
    'BR-V',
    'S600',
    'Beat',
    'Integra DC5',
    'N-WGN',
    'Civic Hybrid',
    'N-One',
    'Concerto',
    'N-Box',
    'N360',
    'Aerodeck',
    'Zest',
    'Z',
    'N600',
    'Vigor',
    'Vamos',
    'Ferio',
    'Grace Hybrid',
    'Rafaga',
    'Spirior',
    'WR-V',
    'Horizon',
    '600',
    'T360',
    '1300',
    'LaGreat',
    'S500',
    "That's",
    'Today',
    'TN360',
    'Crider',
    'S 600 Coupe',
    'Mdx',
    'S 800 Coupe',
    'Jade',
    'CBR600RR',
    'CBR1000RR',
    'Quintet',
    'FCX',
    'S 600 Roadster',
    'XR-V',
    'Monkey',
    'S series',
    'EV Concept',
    'S 800 Cabriolet',
    'Fireblade',
    'UR-V',
    'LN360',
    'EV Plus',
    'Nighthawk',
    'S360',
    'Ridgeline (2006–2014)',
    'Gold Wing',
    'Spike',
    'F-mx',
    'L700',
    'Scamp',
    'CB1000R',
    'CB750',
    'Accord (ninth generation)',
    '145',
    'CB150R',
    'CBR150R',
    'T500',
    'CBR600F',
    'N-Van',
    'e',
    'Activa',
    'Accord (sixth generation)',
    'Quint',
    'CBR125R',
    'Civic GX',
    'Odyssey (ATV)',
    'Breeze',
    'TRX450R',
    'Rincon',
    'CG125',
    'GL500',
    'CB Twister',
    'NSX (first generation)',
    'Civic (seventh generation)',
    'CRF150R',
    'Euro Sport',
    'ATC250R',
    'Civic (sixth generation)',
    'CBF600',
    'CBR1100XX',
    'CR-V (fourth generation)',
    'N400',
    'CB350',
    'CRF150L',
    'CRF250L',
    'HSV-010 GT',
  ],
  Hongqi: ['H7', 'L5', 'CA770', 'HQE', 'H5', 'LS5', 'E-HS3', 'CA72', 'L7', 'L9'],
  Hotchkiss: ['686', 'Anjou', 'Gregoire', 'M201', 'Artois', 'H35'],
  HRG: ['1500', '1100'],
  HSV: ['ClubSport', 'GTS', 'Maloo', 'Senator', 'W427', 'Grange', 'Avalanche', 'Coupe', 'GTO', 'VXR', 'Caprice'],
  Huanghai: ['Aurora', 'N3', 'N2', 'Landscape F1', 'Landscape V3'],
  Hudson: ['Hornet', 'Commodore', 'Wasp', 'Metropolitan', 'Rambler', 'Italia', 'Jet', 'Greater Eight', 'Super-Six Coach'],
  Humber: [
    'Hawk',
    'Super Snipe',
    'Sceptre',
    'Pullman',
    'Armoured Car',
    'Snipe',
    'Vogue',
    'Imperial',
    'Light Reconnaissance Car',
    '80',
    'Limited',
  ],
  Hummer: ['H3', 'H2', 'H1', 'H3T', 'H3 Alpha', 'H2 SUT', 'H1 Alpha', 'H3T Pick-Up', 'H1 4-Door Open-Top', 'H1 4-Door Hard-Top', 'HX'],
  Hyundai: [
    'Santa Fe',
    'Tucson',
    'Elantra',
    'Accent',
    'Sonata',
    'i30',
    'Solaris',
    'ix35',
    'Getz',
    'i20',
    'Veloster',
    'Grand Starex',
    'i10',
    'Avante',
    'i40',
    'Genesis Coupe',
    'Starex',
    'ix20',
    'i30cw',
    'Tiburon',
    'Genesis',
    'Matrix',
    'Creta',
    'Ioniq',
    'Santro Xing',
    'Atos Prime',
    'Grace',
    'H-1',
    'County',
    'Terracan',
    'Santro',
    'Atos',
    'Azera',
    'Grand i10',
    'Kona',
    'iLoad',
    'Verna',
    'Coupe',
    'Mighty',
    'i20 WRC',
    'Grandeur',
    'H-100',
    'iMax',
    'Trajet',
    'Click',
    'Eon',
    'Equus',
    'Veracruz',
    'Tuscani',
    'ix55',
    'NF',
    'Aero',
    'Libero',
    'HB20',
    'Universe',
    'Aero Town',
    'i45',
    'Porter',
    'i20 R5',
    'Santa',
    'Centennial',
    'i20 Coupe WRC',
    'Nexo',
    'Xcent',
    'Pony',
    'Lantra',
    'Aero City',
    'Excel',
    'Palisade',
    'H350',
    'Scoupe',
    'Intrado',
    'XG350',
    'Venue',
    'XG',
    'Lavita',
    'ix25',
    'Grandeur/Azera',
    'Vision G',
    'Entourage',
    'Stellar',
    'Satellite',
    'Nuvis',
    'Santamo',
    'ix25/Creta',
    'Chorus',
    'Maxcruz',
    'Reina',
    'Dynasty',
    'Mistra',
    'H-200',
    'Lafesta',
    'Shehzore',
    'i800',
    'Celesta',
    'RB',
    'Blue-Will',
    'Presto',
    'Elantra LaVita',
    'BlueOn',
    'Galloper',
    'H300',
    'Aslan',
    'Mighty II',
    'Marcia',
    'Clix',
    'Global 900',
    'Sonica',
    'X2',
    'HCD6',
    'FB',
    'e-Mighty',
  ],
  IFA: ['G5', 'W 50', 'F9', 'F8', 'H6', 'Barkas B 1000', 'DKW F8'],
  IKA: ['Estanciera', 'Renault Torino'],
  Imperial: ['Crown', 'Crown Imperial'],
  Infiniti: [
    'FX35',
    'Q50',
    'Q60',
    'G35',
    'M35',
    'QX50',
    'JX35',
    'G37',
    'G35 Coupe',
    'QX56',
    'Q30',
    'QX70',
    'G',
    'QX60',
    'FX',
    'QX30',
    'G37 Coupe',
    'I35',
    'QX80',
    'FX30d',
    'G37 Sedan',
    'FX37',
    'G35 Sedan',
    'JX',
    'Q70',
    'FX45',
    'Q45',
    'EX35',
    'FX50',
    'M37',
    'M45',
    'M',
    'QX4',
    'G25',
    'Q70L',
    'QX',
    'M35h',
    'EX25',
    'G37 Convertible',
    'EX37',
    'G20',
    'I30',
    'M56',
    'M30',
    'I',
    'EX',
    'EX30d',
    'J30',
    'FX30',
    'M25',
    'Q40',
    'G35 Sport Coupe',
    'EX30',
    'M56 Sedan',
    'G-series (Q40/Q60)',
  ],
  Innocenti: ['Mini', 'Lambretta', 'Elba', 'Mille', 'De Tomaso', 'Small', 'IM3', 'Koral', 'A40', 'Spider', 'C', 'Minitre', 'Regent'],
  Inokom: ['Atos Prima Hatchback'],
  Intermeccanica: ['Italia', 'Indra', 'Speedster', 'Roadster'],
  International: ['Harvester', 'DuraStar'],
  'International Harvester': [
    'Travelall',
    'Scout',
    'Metro Van',
    'Travelette',
    'Fleetstar',
    'Cub Cadet',
    'Payhauler',
    'Farmall M',
    'Farmall H',
    'Light Line pickup',
    'Cub Lo-Boy',
  ],
  Invicta: ['S1'],
  'Iran Khodro': ['Samand', 'Paykan', 'Soren', 'Peugeot Pars', 'IKCO Samand'],
  Irmscher: ['7', 'Senator', 'GT'],
  Isdera: ['Commendatore 112i', 'Imperator 108i', 'Autobahnkurier', 'Imperator', 'Commendatore', 'Commendatore GT'],
  Iso: ['Grifo', 'Isetta', 'Rivolta Lele', 'Fidia', 'Bizzarrini', 'Rivolta Fidia', 'Lele', 'Rivolta IR 300'],
  Isuzu: [
    'D-Max',
    'Giga',
    'Rodeo',
    'Bighorn',
    'Trooper',
    'KB',
    'MU-X',
    'Forward',
    'Wizard',
    'VehiCROSS',
    'Elf',
    'Panther',
    'Gemini',
    'NPR',
    'Bellett',
    'Amigo',
    'Erga',
    'Axiom',
    'Ascender',
    'Gala',
    'i-Series',
    'Fargo',
    'Hombre',
    'Campo',
    'Crosswind Sportivo',
    'Rodeo Denver',
    'Impulse',
    'NKR',
    'NNR',
    'MU',
    '117 Coupe',
    'MU-7',
    'Midi',
    'Philippines',
    'Como',
    'Oasis',
    'I-Mark',
    'Journey',
    "P'up",
    'Piazza',
    'Florian',
    'Aska',
    'Grafter',
    'Faster',
    'Erga Mio',
    'Bellel',
    'Hillman Minx',
    '4200R',
    'Stylus',
    'i-350',
    'Cubic',
    'Pa Nero',
    'MU Wizard',
    'Crosswind Xuv',
    'Heavy Duty',
    '810',
    'Turquoise',
    'Super Cruiser',
    'I-280',
    'C',
    'Gala Mio',
    'Journey-K',
    'Journey-Q',
    'Reach',
    'WFR',
    'C-Series',
    'Wasp',
    'H-Series',
    'Bellett Gemini',
  ],
  Italdesign: ['Giugiaro', 'Aztec'],
  Iveco: ['Daily', 'Massif', 'LMV', 'Campagnola', 'EuroCargo', 'Stralis', 'Ford Cargo', 'SuperAV', 'VBTP-MR Guarani', 'VM 90', 'EuroStar'],
  Izh: ['2126', '2715', '2717', '2125', '412', '21261'],
  JAC: [
    'J6',
    'T6',
    'T40',
    'J3',
    'J5',
    'J2',
    'Refine',
    'Refine S3',
    'Rein',
    'Refine S2',
    'Refine S5',
    'Refine S7',
    'iEVA50',
    'Refine M3',
    'Heyue A30',
    'Refine M5',
    'Binyue',
    'Yueyue',
    'Refine A60',
    'Refine M4',
    'Refine M6',
    'iEV6E',
    'Refine R3',
    'Refine M2',
    'Refine S4',
  ],
  Jaguar: [
    'F-Type',
    'XF',
    'F-Pace',
    'E-Type',
    'XE',
    'XJ',
    'XJ-S',
    'XKR',
    'S-Type',
    'XK',
    'X-Type',
    'E-Pace',
    'XK120',
    'MK II',
    'XK140',
    'XJ6',
    'XK-E',
    'XK8',
    'XJ220',
    'Daimler',
    'XJR',
    'D-Type',
    'XK150',
    'XFR',
    'Sovereign',
    'Land Rover',
    'MK 2',
    'XJ8',
    'XJ-SC',
    'C-Type',
    'XJ12',
    'Mark 2',
    'XKSS',
    'I-Pace',
    'SS 100',
    'Vanden Plas',
    'XJ40',
    '3.8 Litre',
    '420',
    'XJR-15',
    '420G',
    'Mark X',
    'R-Coupe',
    'Mark IV',
    'XJR-9',
    '3.4 Litre',
    'Mark V',
    'X308',
    'XJ13',
    'Super V8',
    'C-XF',
    'XJR-14',
    'V-12',
    'XF (X260)',
    'XJR-12',
    'XK (X150)',
    'XJR-8',
    'X300',
    'XF (X250)',
    'C-X16 Concept',
    '240',
    'XJ (X308)',
    'SS 1',
    'Mark 1',
    'Mark VII',
    '340',
    'XJ (X350)',
    'Mark IX',
    'XJR-11',
    'XJ (X351)',
    'XJ12L',
    'I',
    'XJ (X300)',
    'XJ (X358)',
    '2.4 Litre',
    'Mark VIII',
    'XK (X100)',
    'C-X75',
    'Majestic',
    'XJ (XJ40)',
    'XJ6C Coupe',
    'XKR-R',
    'Kensington',
    'XK60',
    'S-Type (1963)',
    'I-Pace eTrophy',
    'XK100',
    'R',
    'R-D6',
    'XK 180',
    'XJSc Convertible',
    'C-X16',
    'XJR-17',
    'C-X17',
    'SS 90',
  ],
  Jeep: [
    'Wrangler',
    'Grand Cherokee',
    'Cherokee',
    'Renegade',
    'Compass',
    'Willys',
    'Wrangler (JK)',
    'Grand Wagoneer',
    'Liberty',
    'Patriot',
    'Chrysler',
    'Cherokee (XJ)',
    'Trailhawk',
    'Wagoneer',
    'Comanche',
    'Wrangler (TJ)',
    'Grand Cherokee (WJ)',
    'CJ',
    'Commander',
    'Gladiator',
    'Grand Cherokee (WK)',
    'J10',
    'Wrangler (YJ)',
    'Grand Cherokee (ZJ)',
    'Grand Cherokee (WK2)',
    'Wrangler (JL)',
    'Liberty (KK)',
    'Willys-Overland',
    'Jeepster Commando',
    'Cherokee (KL)',
    'Liberty (KJ)',
    'J20',
    'Commander (XK)',
    'Forward Control',
    'Grand Commander',
    'DJ',
    'Station Wagon',
    'Renegade (BU)',
    'Kaiser Jeep',
    'Willys Truck',
    'Cherokee (SJ)',
    'Willys Station Wagon',
    'Rubicon Trail',
    'Gladiator (JT)',
    'Wagoneer (SJ)',
    'FJ',
    'Hurricane',
    'Willys2',
    'M 151',
    'Universal',
    'Rescue',
    'J8',
    'DaimlerChrysler',
  ],
  Jensen: ['Interceptor', 'FF', '541', 'C-V8', '541S', '541R', 'Healey', 'GT', 'S-V8', 'S-type', 'P66', 'MotorSport'],
  Jetour: ['X70', 'X90', 'X70S', 'X95'],
  Jiangling: ['Landwind'],
  Jiefang: ['CA-30'],
  Jiotto: ['Caspita'],
  JMC: ['Vigus', 'Yuhu'],
  'John Deere': ['Gator', 'Model A', '3020', 'DB120', 'MC', 'Model 4020', '4010', 'Timberjack', '9630', 'snowmobiles'],
  Jowett: ['Javelin', 'Jupiter', 'Bradford'],
  Kaiser: ['Darrin'],
  Kamaz: ['Oka', 'Typhoon', '7850'],
  Karma: ['Revero'],
  Karmann: ['Chrysler Crossfire', 'BMW E9'],
  Karry: ['Chery#Karry', 'K60'],
  Kawasaki: ['Ninja', 'Z125', 'Ninja ZX-6R', 'Z1000', 'Ninja 300', 'Vulcan', 'Z800', 'Ninja ZX-10R', 'Ninja H2'],
  Kenworth: ['T600', 'T680', 'W900', 'T660'],
  Kia: [
    'Sportage',
    'Rio',
    "Cee'd",
    'Sorento',
    'Picanto',
    'Optima',
    'Soul',
    'Cerato',
    'Carens',
    'Venga',
    'Spectra',
    'Forte',
    'Pride',
    'Sephia',
    'Carnival',
    'Stinger',
    "Pro cee'd",
    'Niro',
    'K900',
    'Magentis',
    'K5',
    'Cadenza',
    'Sedona',
    'Soul EV',
    'Visto',
    'Rio5',
    'Morning',
    'Stonic',
    'Joice',
    'Quoris',
    'Mohave',
    'Grand Carnival',
    'Shuma',
    'Clarus',
    'Opirus',
    'Rondo',
    'Telluride',
    'Pregio',
    'Borrego',
    'Amanti',
    'Lotze',
    'Bongo',
    'K2700',
    'Ray',
    'Forte5',
    'Avella',
    'Besta',
    'K9',
    'Retona',
    'Concord',
    'K3',
    'Granbird',
    'K2500',
    'K7',
    'Capital',
    'K2900',
    'Combi',
    'Credos',
    'Mentor',
    'X-Trek',
    'Potentia',
    'KX7',
    'Elan',
    'Enterprise',
    'Pegas',
    'Seltos',
    'K4',
    'K5 Sedan',
    'Roadster',
    'Brisa',
    "Soul'ster",
    'GT Concept',
    'K3 Sedan',
    "Track'ster",
    'Sportspace',
    'GT',
  ],
  Kleinschnittger: ['F-125'],
  Koenigsegg: ['Agera', 'Regera', 'One:1', 'CCX', 'CCXR', 'CCR', 'Trevita', 'CC8S', 'CCGT', 'CC', 'Jesko'],
  Komatsu: ['D475A', '930E', 'D575A', 'Limited', '830E', 'Haulpak', 'PC200-8 Hybrid', '96', 'LAV'],
  KTM: ['X-Bow'],
  Kurtis: ['500', 'Roadster'],
  Lada: [
    '2110',
    '2107',
    '2114',
    'Priora',
    '2109',
    '2112',
    '2106',
    '2115',
    'Kalina',
    '21099',
    '4x4 Niva',
    '2108',
    '2105',
    '2104',
    '2111',
    '2101',
    'Granta',
    '2113',
    'Oka',
    'Niva',
    'Xray',
    'Samara',
    'Vesta',
    '2103',
    'Urban',
    'Largus',
    '2121',
    '2102',
    'Taiga',
    '4x4',
    '1200',
    '1500',
    '2329',
    'VFTS',
    '1300',
    'Riva',
    'Nadezhda',
    '112',
    '4x4 Urban',
    '2131',
    '110',
    'Nova',
    '111',
    'Revolution',
    '1600',
    'Ellada',
    '2120',
    'Granta WTCC',
    'Silhouette',
  ],
  Lagonda: ['V12', 'Rapide', 'Rapier', '3-Litre', 'LG6', '2.6-Litre', 'Taraf', '16/80', '14/60'],
  Lamborghini: [
    'Aventador',
    'Huracan',
    'Gallardo',
    'Murcielago',
    'Countach',
    'Reventon',
    'Diablo',
    'Miura',
    'Centenario',
    'Super Trofeo',
    'Veneno',
    'LM002',
    'Espada',
    'Urus',
    'Marzal',
    '400 GT',
    'Estoque',
    'Sesto Elemento',
    'Concept S',
    'Urraco',
    '350 GT',
    'Jalpa',
    'Asterion',
    'Miura concept',
    'Terzo Millennio',
    'Islero',
    'Egoista',
    'Jarama',
    'Bravo',
    'Silhouette',
    'Ferruccio Lamborghini',
    'Athon',
    'Cala',
    'Valentino Balboni',
    '350 GTV',
    'Museo Lamborghini',
    'P140',
    '400 GT Monza',
    'Cheetah',
    'Flying Star II',
    'V12',
    'Bob Wallace',
    '3500 GTZ',
    '350 GTS',
    'LM001',
    'Alar',
    'Portofino',
    'V10',
    'Countach QVX',
    'Zagato Raptor',
    'Forged composite',
    'Marco Polo',
    'Faena',
    'LM004',
    'LM003',
    'LMA002',
    'History of Lamborghini',
    'Italdesign Giugiaro',
    'Concept vehicles',
  ],
  Lanchester: ['Fourteen', 'Ten', '28', '10', '40', 'Leda'],
  Lancia: [
    'Delta',
    'Ypsilon',
    'Stratos',
    'Fulvia',
    'Lybra',
    'Beta',
    'Aurelia',
    'Rally 037',
    'Musa',
    'Thema',
    'Flaminia',
    'Flavia',
    'Astura',
    'Delta S4',
    'Rally',
    'Gamma',
    'Montecarlo',
    'Y',
    'Phedra',
    'Thesis',
    'Voyager',
    'Ardea',
    'Zagato',
    'Beta Montecarlo',
    'Aprilia',
    'Appia',
    'Lambda',
    'Kappa',
    '2000',
    'Dedra',
    'Augusta',
    'LC2',
    'Prisma',
    'D24',
    'Dilambda',
    'Artena',
    'Y10',
    'A112',
    'Trevi',
    'Zeta',
    'Scorpion',
    'Alfa',
    'Theta',
    'Beta Trevi',
    'Jolly',
    'Alpha',
    'LC1',
    'HPE',
    'Hyena',
    'Superjolly',
    'Belna',
    'ECV',
    'Chrysler 300',
    'Lince',
    'Medusa',
    'Dialogos Concept',
    'Trikappa',
    'Epsilon',
    'Delta Group A',
    'Dialogos',
    'Dialfa',
    'Ardennes',
    'Beta (1909)',
    'Florida',
  ],
  'Land Rover': [
    'Defender',
    'Discovery',
    'Range Rover Sport',
    'Range Rover',
    'Freelander',
    'Range Rover Evoque',
    'Discovery Sport',
    'Discovery 4',
    'Discovery 3',
    'Range Rover Velar',
    'series',
    'Range Rover Vogue',
    'Evoque',
    'Range Rover Classic',
    'LR4',
    'Series III',
    'LR3',
    'LR2',
    'Series II',
    'Series IIa',
    'DC100',
    '110 Station Wagon',
    '110 Pick-Up',
    'Range Rover (L405)',
    '110 Hard Top',
    '90 Station Wagon',
    '90 Hard Top',
    'Discovery Sport (L550)',
    'Wolf',
    'Range Rover (L322)',
    '1/2 ton Lightweight',
    '101 Forward Control',
    'Hardtop',
    'Discovery Series I',
    'Discovery (L462)',
    'Perentie',
    '90/110',
    'Discovery Series II',
    'Range Rover (P38A)',
    'Snatch Land Rover',
  ],
  Landwind: ['X7', 'X8', 'X5', 'X6', 'X2', 'SUV', 'Xiaoyao'],
  LDV: ['V80', 'G10', 'Convoy', 'Pilot', 'Cub', 'Maxus', 'Limited'],
  Lexus: [
    'IS 250',
    'RX 300',
    'SC 430',
    'RX 350',
    'LS 460',
    'GS 300',
    'LX 470',
    'GS 350',
    'LX 570',
    'LS 400',
    'RX 450h',
    'CT 200h',
    'RX 330',
    'NX 200t',
    'GX 470',
    'LS',
    'GS',
    'IS F',
    'LS 430',
    'LF-A',
    'ES 350',
    'IS',
    'ES 330',
    'RC',
    'GX 460',
    'SC 400',
    'GS 430',
    'LC',
    'SC 300',
    'ES 300',
    'ES 300h',
    'NX',
    'HS 250h',
    'LS 600hl',
    'ES 250',
    'RC 350',
    'IS 350',
    'NX 300h',
    'GS 450h',
    'ES',
    'RX',
    'IS 220d',
    'LS 500h',
    'IS 200',
    'SC',
    'IS 300',
    'GS 250',
    'IS 300h',
    'LS 600h',
    'RX 400h',
    'LS 460 L',
    'IS 200t',
    'RX 200t',
    'GS 200t',
    'RX 450',
    'LX',
    'LS 500',
    'HS',
    'GX',
    'CT',
    'GS 300h',
    'RX 400',
    'IS 250C',
    'RX 270',
    'UX',
    'GS 460',
    'RC 200t',
    'GS 400',
    'IS 220',
    'LX 450',
    'IS 200d',
    'Link',
    'LX 450d',
    'IS (XE20)',
    'LS (XF40)',
    'F',
    'LM',
    'LF-LC',
    'GS (S190)',
    'LF-NX',
    'LF-FC',
    'Enform',
    'XV70',
    'LF-CC',
    'LF',
    'ES 260',
    'LF-Gh',
  ],
  Leyland: ['Comet', 'Hippo', 'Mini Moke', 'P76', 'Eight', 'Hippo Mk II', 'National'],
  Lifan: [
    'Solano',
    'X60',
    'Foison',
    'Breez',
    'Smily',
    'X70',
    'X80',
    '820',
    '530',
    '520',
    '320',
    'X50',
    '620',
    'Breez-(520)',
    '330',
    'Solano-(620)',
    '720',
    'Xuanlang',
    '520i',
    'Truck',
    '630',
    '650',
    'Maiwei',
    'Lotto',
  ],
  Ligier: [
    'JS 50',
    'X-Too',
    'Ixo',
    'Nova',
    'JS',
    'Ambra',
    'JS P2',
    'JS P3',
    'Optima',
    'JS P217',
    'Be-Up',
    'X-Pro',
    'Be-Two',
    'JS2',
    'X-Too2',
    'Optimax',
    'Due',
    '162',
    'JS11',
  ],
  Lincoln: [
    'Continental',
    'MKZ',
    'Town Car',
    'Navigator',
    'MKX',
    'Continental Mark IV',
    'MKC',
    'LS',
    'MKS',
    'Mark',
    'Premiere',
    'Continental Mark II',
    'Zephyr',
    'MKT',
    'Continental Mark V',
    'Aviator',
    'Mark VIII',
    'Continental Mark III',
    'Nautilus',
    'Blackwood',
    'Mark LT',
    'Mark VII',
    'Mark series',
    'Capri',
    'Mark VI',
    '1960 Lincoln',
    'Corsair',
    'Versailles',
    'Cosmopolitan',
    'MKR Concept',
    'Navicross',
    'Sport',
    'Sunshine Special',
    'Custom',
    'K series',
    'Mark X',
    'Mk 9 Concept',
    'L series',
    'Continental Mark VI',
    '(H-series)',
    'SS-100-X',
  ],
  'Lion-Peugeot': ['Type VA'],
  Lister: ['Storm', 'Le Mans', 'Storm LMP'],
  Lloyd: ['600'],
  Lombardi: ['Grand Prix'],
  Lotus: [
    'Evora',
    'Elise',
    'Exige',
    'Esprit',
    'Elan',
    'Europa',
    'Elite',
    'Cortina',
    'Carlton',
    'Eleven',
    'Seven',
    'Super Seven',
    '2-Eleven',
    '3-Eleven',
    'Europa S',
    'Eclat',
    '340R',
    'Excel',
    'Elise GT1',
    'Evija',
    '47',
    '17',
    'Esprit GT1',
    'GT1',
    'Wet Nellie',
    'Monte Carlo',
    '11',
    'Etna',
    'M250',
    'Mark VI',
    'APX',
    '14',
    'M90',
    'Sprint',
    '26',
    'Ethos',
    '71',
    'Sunbeam',
    '118',
    '28',
    '15',
    '90',
  ],
  LTI: ['TX1', 'TX4', 'TXII'],
  LuAZ: ['969'],
  Lucra: ['LC470'],
  Luxgen: ['U7', 'S3', 'M7', 'U6', 'Luxgen7-SUV', 'S5', 'U5', 'Luxgen7-MPV', 'Luxgen7', 'Luxgen5', 'V7'],
  'Lynk & Co': ['1', '2', '3'],
  Mahindra: [
    'Scorpio',
    'XUV500',
    'Thar',
    'Bolero',
    'Verito',
    'KUV100',
    'Xylo',
    'TUV300',
    'Goa',
    'XUV300',
    'Genio',
    'Maxximo',
    'Supro',
    'Roxor',
    'Quanto',
    'Scorpio Getaway',
    'NuvoSport',
    'Marazzo',
    'Maxx',
    'Major',
    'eVerito',
    'Armada',
    'Commander',
    'Logan',
    'Axe',
    'Marksman',
    'Marshal',
    'CL',
    'Electric',
    'Mine Protected Vehicle',
    'Voyager',
    'Legend',
  ],
  MAN: ['TGM', 'TGL', 'TGA', 'TGX', 'TGS', 'KAT1', 'CLA', "Lion's City"],
  Marcos: ['Mantis', 'GT', 'Mini Marcos', 'Mantara', 'TSO'],
  'Marmon-Herrington': ['Armoured Car', 'CTLS'],
  Marussia: ['B2', 'B1', 'Motors'],
  Maruti: [
    'Suzuki',
    'Celerio',
    'Wagon-R',
    'Swift',
    'Alto',
    'Baleno',
    'Ertiga',
    'A Star',
    'Eeco',
    '800',
    'Zen',
    'Gypsy',
    'SX4',
    'Esteem',
    'Omni',
    'Estilo',
    'Ciaz',
    'Ritz',
    'Versa',
    'XA Alpha',
    '1000',
    'Kizashi',
  ],
  'Maruti Suzuki': ['Swift', 'Baleno', 'Vitara', 'Ciaz', 'Alto', 'Wagon R', 'Ertiga', 'Dzire', 'Celerio', 'Vitara Brezza'],
  Maserati: [
    'Quattroporte',
    'GranTurismo',
    'Levante',
    'Ghibli',
    'MC12',
    'Grancabrio',
    'Bora',
    'Mistral',
    'Gransport',
    'Biturbo',
    '300S',
    'Merak',
    '3500 GT',
    '250F',
    'Spyder',
    'Khamsin',
    '3200 GT',
    'Kubang',
    'Indy',
    'Coupe',
    'Sebring',
    '5000 GT',
    'Mexico',
    'Shamal',
    'Chrysler TC by Maserati',
    'A6',
    'Tipo 61',
    '450S',
    'Quattroporte III',
    '4200',
    'Quattroporte V',
    'Kyalami',
    'Quattroporte IV',
    'Tipo 151',
    '3200',
    'Alfieri',
    'Barchetta',
    'A6G-54',
    'Ghibli (M157)',
    'Quattroporte VI',
    '8CM',
    'A6GCM',
    '200 Si',
    '228',
    '150S',
    'Quattroporte I',
    '430',
    'Quattroporte II',
    'Karif',
    '420',
    '200S',
    '8C',
    '6CM',
    'Royale',
    'Boomerang',
    'Kubang GT Wagon',
    'Tipo 26M',
    'Tipo 26',
    '4300 GT Coupe',
    '350S',
    'Tipo 26B',
    'Chubasco',
    'brothers',
    'V8RI',
  ],
  'Massey-Harris': ['Model 81'],
  Mastretta: ['MXT'],
  Matra: ['Djet', 'Bagheera', 'Murena', 'Rancho', '530', 'MS650', 'MS630', 'MS670', 'MS620'],
  Maxus: ['LDV Maxus', 'D90', 'G10', 'T60', 'D60', 'G20', 'G50', 'V90'],
  Maybach: ['57 S', '57', 'Landaulet', '62', '62 S', 'Exelero', 'Zeppelin', '57 and 62', '57 S Xenatec Coupe'],
  MAZ: ['trucks', 'Belarus', '203'],
  Mazda: [
    '3',
    '6',
    'Demio',
    'CX-5',
    'RX-7',
    'Miata',
    'Mazdaspeed',
    'Familia',
    '323',
    'RX-8',
    'MX-5',
    'CX-7',
    '2',
    '626',
    'CX-3',
    'Premacy',
    'MPV',
    'Capella',
    'Mazdaspeed 3',
    'Bongo',
    'CX-9',
    'Axela',
    'BT-50',
    '5',
    'Tribute',
    'Atenza',
    'Eunos',
    'Roadster',
    'B2200',
    'Titan',
    '323F',
    '121',
    'B2500',
    'B series',
    'Protege',
    'MX-3',
    'R100',
    'Millenia',
    'Mazdaspeed6',
    'RX-3',
    'Verisa',
    'Protege5',
    'Astina',
    'Efini',
    'Savanna',
    'B2000',
    'CX-8',
    'Cosmo',
    'Luce',
    'MX-6',
    'Biante',
    '929',
    'RX-2',
    'B4000',
    'Drifter',
    'Xedos 6',
    'MX-5 (NA)',
    'RX',
    'Familia Van',
    'RX-4',
    'Xedos 9',
    '323P',
    'RE Amemiya',
    'MX',
    'Autozam',
    'Carol',
    'RX-9',
    'CX-4',
    '808',
    'MX-5 (ND)',
    'MX-5 (NB)',
    'Proceed Marvie',
    'Lantis',
    '1300',
    'Scrum',
    'Bravo',
    '8',
    'MX-5 (NC)',
    'Flair',
    'Sentia',
    'Proceed Levante',
    'Fighter',
    'Vantrend',
    '1000',
    'R360',
    'Efini MS-8',
    'E2000',
    'CX-30',
    'E-Series',
    '323C',
    'AZ-Wagon',
    'VX-1',
    'Flair Wagon',
    'E2200',
    'Az-1',
    'Artis',
    'Cronos',
    'Xedos',
    '1500',
    'Allegro',
    '616',
    'Navajo',
    'Eunos 500',
    'Proceed',
    'Levante',
    'GLC',
    'MR90',
    'Protege5 Wagon',
    '618',
    'Scrum Wagon',
    'Porter',
    'RX-5',
    'Montrose',
    '1800',
    'Laputa',
    '818',
    '1200',
    'Etude',
    'E1800',
    'R 100 Coupe',
    'Traveller',
    'Rustler',
    'Persona',
    '800',
    'Chantez',
    'Revue',
    '787B',
    'Az-offroad',
    'Parkway',
    'RX-500',
    '2000',
    'K360',
    'Grand Familia',
    'Isamu Genki',
    'Hazumi',
    'Spiano',
    'MX-Crossport',
    'Roadpacer AP',
    'Mizer',
    'Clef',
    'B-Serie',
    'Takeri',
    'RX-01',
    'Metro',
    'RX-792P',
    'R130',
    'B360',
    'Furai',
    'B600',
    'MX-Micro Sport',
    'MXR-01',
    'Premacy Hydrogen RE Hybrid',
    'Autozam Scrum',
    'Kabura',
    'E-Serie',
  ],
  MCC: ['Smart'],
  McLaren: [
    'MP4-12C',
    '650S',
    'P1',
    '570S',
    '720S',
    '675LT',
    '12C',
    '570GT',
    'F1',
    '540C',
    'F1 GTR',
    'Senna',
    'F1 LM',
    '540C Coupe',
    'GT',
    'X-1',
    'P1 LM',
    '650',
    'Speedtail',
    'M6/GT',
    'MP4-X',
    'Mercedes-Benz SLR McLaren',
    '625C',
    '570GT Coupe',
    'MCL32',
    'MP4/4',
    'MCL33',
    'LM',
    'MP4/12',
    'MP4/6',
    'MP4/8',
    'M23',
    'MP4/5',
    'MP4-31',
    'MP4-30',
  ],
  Mega: ['MultiTruck', 'Track', 'Club', 'Monte-Carlo', 'Cabrio'],
  Melkus: ['RS 2000', 'RS 1000'],
  'Mercedes-AMG': ['S63', 'S65', 'GLA', 'SLS', 'CLS', 'GLS', 'GLE', 'CLA', 'CLK', 'GLC', 'SLK', 'GT'],
  'Mercedes-Benz': [
    'E-Class',
    'C-Class',
    'S-Class',
    'CLK',
    'C63 AMG',
    '190',
    'M-Class',
    '300 SL',
    'E 320',
    'Shooting-brake',
    'G-Class',
    'Mercedes-AMG GT',
    'Sprinter',
    'SLS AMG',
    'SL',
    'CLA',
    'E 250',
    'C200',
    'A-Class',
    'Vito',
    'C180',
    '200',
    'CLK-Class',
    'E',
    'CLK 320',
    'S63',
    'C250',
    'C',
    'ML 350',
    'CLS',
    'E 350',
    'C220',
    'SLK',
    'E 500',
    'B180',
    'B-Class',
    '190 SL',
    'B200',
    '300',
    '250',
    'GLE',
    'A 45 AMG',
    'A 180',
    'CLA250',
    'GLA',
    'A 200',
    'C350',
    'CLS 350',
    'SL-Class',
    'CLA200',
    'E 200',
    '230',
    'GL-Class',
    '280',
    'A 170',
    'W123',
    'S320',
    'W201',
    'GLC 300',
    'V-Class',
    'SLK 350',
    'SLK-Class',
    'GLC',
    'GLA 200',
    'E 220',
    '500',
    'CL500',
    'A 140',
    'C300',
    'S500',
    'ML',
    'GLK',
    'SLK 200',
    '450',
    'A 160',
    'E 280',
    'E 63 AMG',
    'G 63 AMG',
    'Actros',
    'Unimog',
    '220',
    'A 150',
    'GLK-Class',
    'Viano',
    'CLA45',
    'CLS-Class',
    'E 240',
    'Citan',
    'CL-Class',
    'CLS 63',
    'SLK 250',
    'GLK 350',
    'S',
    'W124',
    'Zetros',
    'AMG C 63',
    'G 500',
    'G',
    'B170',
    '500K',
    'E 270',
    'GL 450',
    'ML 320',
    'GLS',
    'E 400',
    'GLA 250',
    '170',
    'ML 250',
    'CLK GTR',
    'W115',
    'GLC 250',
    '540K',
    'S350',
    'ML 63 AMG',
    'SLC',
    'W126',
    'GLE 450',
    'GLC Coupe',
    'A 250',
    'Atego',
    '560',
    'C 43 AMG',
    'G 350',
    'GLC 220d',
    'CLS 500',
    'GLC 43',
    'SLS',
    'GLE 350',
    'R320',
    'SLR',
    'S400',
    'R231',
    '300D',
    'G 55 AMG',
    'SLK 55 AMG',
    'R-Class',
    'CLA-Class',
    'GL',
    'GLK 220',
    'CL',
    'V',
    '350',
    'W222',
    '500 SL',
    'G 300',
    'Limousine',
    'W111',
    'C63',
    'GLA-Class',
    'CL600',
    'R129',
    '320',
    'X-Class',
    'S600',
    '400',
    'GLE 43',
    '240',
    'R230',
    'W108',
    'W110',
    'G 65 AMG',
    'CLC',
    'X156',
    '180',
    'Ponton',
    'W114',
    'SSK',
    'GLE 250',
    'C400',
    'B160',
    'S430',
    'CL63',
    'C 32 AMG',
    'AMG C 43',
    '300 SLR',
    'Vaneo',
    'B 150',
    'GL 500',
    'C240',
    'Coupe',
    '380',
    'GLE 63',
    'C270',
    'W140',
    'SLC 300',
    'B250',
    'X166',
    'CLC 200',
    'W204',
    'GLK 250',
    'GLC 63',
    'A 220',
    'GLC-Class',
    'W208',
    'ML 55 AMG',
    'GLC 250d',
    'SLC 43',
    'V220',
    'W113',
    'GL 350',
    '600',
    'CLS 400',
    'GLK 300',
    'E 430',
    'Vario',
    'B',
    'W212',
    'GT',
    'ML 400',
    'GL 320',
    'S65',
    'W210',
    'G 400',
    '450SEL',
    'E 55 AMG',
    'ML 500',
    'GLE-Class',
    'CL65',
    'B220',
    'Marco Polo',
    'R350',
    'SLR McLaren',
    'SUV',
    'G 320',
    '260',
    'C 36 AMG',
    'SLK 230 Kompressor',
    'W202',
    'C111',
    'Pullman',
    'GLK 320',
    'W125',
    'W211',
    'R171',
    'W221',
    'R107',
    'R172',
    'A',
    'W203',
    'W209',
    'Metris',
    '500 E',
    'CLC 220',
    'SLK 300',
    '420',
    'GLB',
    'GLC 350e',
    'E 43 AMG',
    'CL55',
    'W116',
    'C217',
    'E 290',
    'ML 300',
    'GLC 200',
    'V250d',
    'A 190',
    '770',
    'MB100',
    'R170',
    '200T',
    'E 260',
    'CLS 250',
    'W163',
    'C209',
    'EQC',
    'W205',
    'E 420',
    'C208',
    'W136',
    'T1',
    'C126',
    'Mercedes AMG GLE63',
    'R63 AMG',
    'W213',
    'R280',
    'C124',
    'R500',
    'SLC 180',
    'SLC 200',
    'GLK 280',
    'Cabriolet',
    'R',
    'W164',
    'W100',
    '230 TE',
    'Roadster',
    'E 55',
    'W219',
    'W460',
    'C117',
    'G 280',
    'Valente',
    'W220',
    'C216',
    'W166',
    'Museum',
    'C257',
    'W120',
    '380SEL',
    'C 55 AMG',
    'S124',
    'W187',
    'SLC-Class',
    'C112',
    'W176',
    'CLC-Class',
    'M',
    'W121',
    'Vision SLR',
    'W218',
    'C190',
    'W168',
    '130',
    '/8',
    'S280',
    'W112',
    'C43',
    'EQ',
    'Heckflosse',
    'SS',
    'X204',
    'C160',
    'SLC 250d',
    'C11',
    'W463',
    'W186',
    'Fintail',
    'W180',
    'R300',
    'X164',
    'E 50',
    'A 210',
    'C197',
    'W150',
    'C215',
    'S55',
    'T2',
    'E 60 AMG',
    'Sedan',
    '219',
    'W109',
    'C205',
    'GLK 200',
    'SLK 200 Kompressor',
    'W117',
    'G 240',
    'W216',
    'C107',
    'W31',
    'L 319',
    'Vision SLA',
    'W246',
    'C207',
    'W169',
    'G 270',
    'C123',
    '260 D',
    'buses',
    'W128',
    'S205',
    'Pickup truck',
    'V220d',
    'ML 550',
    'W245',
    'C140',
    'V222',
    'X253',
    'C292',
    'CLA Shooting Brake',
    'Fashion Week',
    'S211',
    'W142',
    'A209',
    'S202',
    'S500 L',
    'F-Cell',
    'Mercedes-AMG Project One',
    'R199',
    'S212',
    'Vision GLK Freeside',
    'Typ 770',
    'Typ 200',
    'S350 L',
    'G 290',
    'W189',
    'W188',
    'Typ 290',
    'C204',
    'X',
    'W105',
    'A124',
    'CL203',
    'S210',
    'Typ 320',
    'V200',
    'Estate',
    'GLB-Class',
    'N1300',
    'Typ 300',
    'W215',
    'S600 L',
    'USA',
    'V140',
    'S204',
    'W198',
    'Citaro',
    'W251',
    'F 500 Mind Concept',
    'W143',
    'Typ Nurburg',
    'W07',
    'V124',
    'Typ S',
    'A208',
    'L3000',
    'S-Series',
    'GLC 350d',
    'Speedster',
    'W08',
    'Typ 170',
    'A217',
    'V221',
    'W638',
    'Pullmann',
    'C 30 AMG',
    'V126',
    'Saloon',
    'Typ SSK',
    'Typ 230',
    'W153',
    'S203',
    'W114/W115',
    '300 SEL 6.3',
    '180E',
    'R197',
    'Typ Stuttgart',
    'S-Class (W222)',
    'C253',
    'X350d',
    '450SEL 6.9',
    'E-Class (W212)',
    'A207',
    'W447',
    'X250d',
    'W639',
    'Typ Mannheim',
    '4-door coupe',
    'Axor',
    '320A',
    'C-Class (W205)',
    'V212',
    'A205',
    'W18',
    'W191',
    'G63 AMG 6x6',
    '430',
    'G 36 AMG',
    'C238',
    'Mercedes Simplex',
    'Typ SS',
    'T80',
    '/8 Coupe',
    'Typ 130',
    'Crossover',
    'SLK 250d',
    'B55 AMG',
    'R190',
    'C199',
    'S-Class (W221)',
    'S213',
    'Typ 380',
    'F125',
    'Classic Center',
    'W21',
    'Off-road vehicle',
    'W196',
    'Typ 8/38',
    'W461',
    'W177',
    'G500 4?4?',
    'W02',
    'E-Class (W213)',
    'W108/W109',
    'W23',
    'MPV',
    'Arocs',
    'Typ 170 V',
    'W29',
    'C-Class (W204)',
    'Ocean Drive',
    'A238',
    'C118',
    'C291',
    'N293',
    'X222',
    'W152',
    'R550',
    'Typ 150',
    'E-Class (W211)',
    'Z199',
    'X218',
    'W167',
    'W414',
  ],
  Mercury: [
    'Grand Marquis',
    'Cougar',
    'Cyclone',
    'Monterey',
    'Marauder',
    'Milan',
    'Topaz',
    'Comet',
    'Sable',
    'Montego',
    'Mountaineer',
    'Capri',
    'Montclair',
    'Station Wagon',
    'Mariner',
    'Monarch',
    'Coupe',
    'Park Lane',
    'Villager',
    'Convertible',
    'Marquis',
    'Tracer',
    'Mystique',
    'Eight',
    'Turnpike Cruiser',
    'Zephyr',
    'Colony Park',
    'Meteor',
    'Commuter',
    'Cyclone Spoiler II',
    'Lynx',
    'Bobcat',
    'Sport Sedan',
    'LN7',
    'Custom Convertible',
    'Custom Coupe',
    '2-Door Sedan',
    'Town Sedan',
    'Voyager',
    'Meta One',
    'XM-800',
    'Messenger',
    'Custom Sedan',
    'S-55',
    'Econoline',
    'M series',
    'My',
  ],
  Merkur: ['XR4Ti'],
  Messerschmitt: ['KR200', 'KR 175'],
  Meteor: ['Rideau'],
  MF: ['Centora'],
  MG: [
    'Midget',
    'MGB',
    'ZS',
    'Magnette',
    'ZR',
    'TF',
    'MGA',
    'TC',
    'TD',
    'F',
    'MGB Roadster',
    '3',
    '6',
    'ZT',
    'Metro',
    'GS',
    '1100',
    'MGC',
    'XPower SV',
    'RV8',
    '550',
    'TA',
    'TC Midget',
    'SA',
    '1300',
    'Maestro',
    'HS',
    'GT',
    'F / MG TF',
    'VA',
    'T-type',
    '5',
    'ZS SUV',
    'TD Midget',
    'MG6 GT',
    'TB',
    'F / TF',
    'C-type',
    'TF Roadster',
    'M-type',
    'Y-type',
    'Montego',
    'YA',
    '7',
    'EX-E',
    'WA',
    'F-type',
    'Q-type',
    'ZS EV',
    'TF Midget',
    'P-type',
    'L-type',
    'J-type',
    'ZB',
    'TA Midget',
    '1275',
    'Hector',
    'Sports Sedan',
    '18/80',
    'MGR',
    '14/40',
    'ZA',
    '14/28',
    'R-type',
    'KN',
    'Express',
    'Princess',
    'D-type',
    'RX5',
    'Magic Midget',
  ],
  Microcar: ['M.Go', 'MC2', 'Due', 'M8', 'Virgo', 'MC1', 'Flex', 'Cargo', 'Ecology/Lyra'],
  Mikrus: ['MR-300'],
  Mini: [
    'Cooper',
    'John Cooper Works',
    'Countryman',
    'Paceman',
    'One',
    'Clubman',
    'Cooper Clubman',
    'Roadster',
    'Cabrio',
    'Coupe',
    'Convertible',
    'John Cooper Works GP',
    'Hardtop',
    'Clubman (Mini)',
    '1275GT',
    'Hatch',
    'Moke',
    'John Cooper Works Countryman',
    '1000',
    'John Cooper Works Clubman',
    'Clubvan',
    'Cabriolet',
    'Electric',
    'E',
    '1300',
    'John Cooper Works WRC',
    'Clubman (2007)',
    'Riley Elf',
    'Beachcomber',
    'All4 Racing',
    'Coupe and Roadster',
    'ERA Turbo',
    'Mark III',
    'Mk III',
    'Mk VII',
    'Mk IV',
    'Mark I',
    'Mark VI',
    'Mk V',
    'Mark IV',
  ],
  Mitsubishi: [
    'Lancer',
    'Pajero',
    'Outlander',
    'Galant',
    'Colt',
    'ASX',
    'L200',
    'Lancer Evolution',
    'Eclipse',
    'Pajero Sport',
    'Delica',
    'RVR',
    'Triton',
    'Space Star',
    'Pajero Mini',
    'Pajero iO',
    'Mirage',
    'Montero Sport',
    '3000GT',
    'Montero',
    'Carisma',
    'Outlander Sport',
    'Diamante',
    'Airtrek',
    'Magna',
    'Libero',
    'Space Wagon',
    'i-MiEV',
    'Chariot',
    'Grandis',
    'Raider',
    'Nativa',
    'Lancer Evolution X',
    'Jolie',
    'Adventure',
    'Galant VR-4',
    'Legnum',
    'Jeep',
    'Dingo',
    'Canter',
    'Eclipse Cross',
    'Pajero Junior',
    'Pajero Pinin',
    'L300',
    'Starion',
    'Challenger',
    'FTO',
    'Pajero TR4',
    'Space Gear',
    'Galant Fortis',
    'Minica',
    'Dion',
    'i',
    'Endeavor',
    'Wagon',
    'GTO',
    'Xpander',
    'Eterna',
    'Strada',
    'Attrage',
    'Minicab',
    'Space Runner',
    'Star Wagon',
    'Shogun',
    'Pajero Evolution',
    '380',
    'Fuso Canter',
    'Toppo',
    'Sportero',
    'Sigma',
    'Mighty Max',
    'Warrior',
    'Debonair',
    'Kuda',
    'Colt T120SS',
    'Fuso Fighter',
    'Shogun Sport',
    'Celeste',
    'Aspire',
    'Montero iO',
    'Galant GTO',
    'Emeraude',
    'L 400',
    'Savrin',
    'Storm',
    'Sapporo',
    'Fuzion',
    'Grunder',
    'eK',
    'Express',
    'Fuso Aero Midi',
    'Fuso Super Great',
    'Verada',
    'Cordia',
    'Expo',
    'Proudia',
    'Galloper',
    'Shogun Pinin',
    'Town Box',
    'Fuso Aero Star',
    'Champ',
    'Mirage Dingo',
    'Precis',
    'Cedia',
    'Nimbus',
    'Colt Rodeo',
    'MiEV Evolution',
    'Lancer WRC',
    'Galant Lambda',
    'Colt Galant',
    'Expo LRV',
    'Freeca',
    'Dignity',
    'Pinin',
    'Racing Lancer',
    'G-Wagon',
    'Pistachio',
    'Fuso Rosa',
    'Colt Bakkie',
    'Tredia',
    '500',
    'Model A',
    'Fuso Truck and Bus Corporation',
    'Lancer Celeste',
    'Lancer (A70)',
    'Forte',
    'Type 73 Light Truck',
    'Fuso Aero Bus',
    'Scorpion',
    'Fuso Aero King',
    'Colt 1100',
    'Santamo',
    'L100',
    'Rodeo',
    'Town Bee',
    'Colt 1100F',
    'Galant FTO',
    'Colt 1200',
    'Colt 1000',
    'Colt 800',
    'Colt 600',
    'Towny',
    'Colt 1500',
    'Maven',
    'Magnum',
    '360',
    'Sigma Scorpion',
    'V3000',
    'i Concept',
    'Eclipse Concept-E',
  ],
  Mitsuoka: ['Orochi', 'Himiko', 'Viewt', 'Galue', 'Nouera', 'Ryoga', 'Le-Seyde', 'Micro-Car', 'MC-1', 'Like', 'Ray'],
  Miura: ['Targa', 'Spider'],
  Mochet: ['CM'],
  Monteverdi: ['High Speed', 'Hai 450', 'Safari', 'Sierra', 'Tiara', 'Hai 650 F1', 'Palm Beach'],
  Moretti: ['1200', '500', '500 Coupe'],
  Morgan: [
    'Plus 4',
    // '#ERROR!',
    'Plus 8',
    'Aero 8',
    'Roadster',
    '3-Wheeler',
    'Aero',
    'Plus',
    'Aeromax',
    '4/4',
    'Eva GT',
    'LIFEcar',
    'Four Seater',
  ],
  Morris: [
    'Minor',
    'Mini',
    'Oxford',
    'Mini Minor',
    'Marina',
    'Cowley',
    '1100',
    'Cooper',
    'J2',
    'J-type',
    'Eight',
    '1800',
    'Ital',
    'Major',
    'Ten',
    'Oxford MO',
    '1300',
    'Mascot',
    '850',
    'Mini Clubman',
    'Isis',
    'Oxford bullnose',
    'C8',
    'Oxford Series II',
    'Street',
    'CS9',
    'Oxford Series III',
    'CDSW',
    'J4',
    '8/40',
    'Six MS',
    'Twelve',
    '250 JU',
    '1500',
    'Oxford Six',
    '2200',
    'FE',
    'Fourteen',
    'Oxford Farina',
    'Monaco',
    'Minor (1928)',
    'Nomad',
    'Series Z',
    'FV',
    'Oxford flatnose',
    '18-22',
    'Marshal',
  ],
  Moskvitch: [
    '412',
    '2140',
    '407',
    '408',
    '2141',
    '401',
    '402',
    '400',
    '403',
    '400-420',
    'Aleko',
    '410',
    '402/403/407',
    '1500',
    '2142',
    'Izh 2126',
  ],
  Mosler: ['MT900', 'Consulier GTP', 'supercar', 'TwinStar'],
  Muntz: ['Jet'],
  Museo: ['Ferrari', 'Lamborghini', 'Casa Enzo Ferrari'],
  Nash: ['Ambassador', 'Metropolitan', 'Rambler', 'Statesman', '600', 'Healey'],
  Naza: ['Sutera'],
  NIO: ['EP9', 'ES8', 'ES6'],
  Nissan: [
    'Qashqai',
    'X-Trail',
    'Skyline',
    'Note',
    'Primera',
    'Juke',
    'Pathfinder',
    'Sentra',
    'Sunny',
    'Bluebird',
    'Micra',
    'Silvia',
    'GT-R',
    'Navara',
    'March',
    'Almera',
    '370Z',
    'Tiida',
    'Patrol',
    'Teana',
    'Altima',
    'Maxima',
    'Wingroad',
    'Cefiro',
    '300ZX',
    '350Z',
    'Laurel',
    'Pulsar',
    'Datsun',
    'Infiniti',
    'Cube',
    'Murano',
    'AD Wagon',
    'Fairlady Z',
    'Serena',
    'Terrano',
    '240SX',
    'Rogue',
    'Frontier',
    '180SX',
    'Grand Livina',
    'Latio',
    'Versa',
    'Quest',
    'Xterra',
    'Avenir',
    'Liberty',
    'Leaf',
    'Elgrand',
    'Cabstar',
    'Kicks',
    'Pick Up',
    'Titan',
    'Presage',
    'Skyline GT-R',
    '200SX',
    'Dualis',
    'Cedric',
    'Vanette',
    'Prairie',
    'Primastar',
    'Hardbody',
    'Fairlady',
    'Expert',
    'Livina',
    "R'nessa",
    'NV200',
    'Gloria',
    'Tino',
    'Civilian',
    'NP300',
    'Safari',
    'Rogue Sport',
    'Stagea',
    'Caravan',
    'Armada',
    'Largo',
    'Clipper',
    'Presea',
    'King-Cab',
    'Figaro',
    'Interstar',
    'Evalia',
    'Bassara',
    'Fuga',
    'Cima',
    'Dayz',
    'NP200',
    'Terra',
    'Terrano II',
    'Datsun Truck',
    'Lafesta',
    'Moco',
    'Pixo',
    'e-NV200',
    'Crew',
    'Sileighty',
    'Mistral',
    'EXA',
    '1400',
    'Pao',
    '100NX',
    'President',
    'NV400',
    'Lannia',
    'Rasheen',
    'Leopard',
    'NV',
    'Urvan',
    'Homy',
    'Z-car',
    'GT-R LM Nismo',
    'Cherry',
    'Stanza',
    'Sylphy',
    'R390 GT1',
    'AD Van',
    'Almera Tino',
    'Pino',
    'NX',
    'Be-1',
    '1400 Bakkie',
    '280ZX',
    'Langley',
    'Bluebird (U14)',
    'R89C',
    'DeltaWing',
    'Roox',
    'Otti',
    'Kubistar',
    'AD',
    'NP-300-Pick-up',
    'D21',
    'Pulsar NX',
    'Platina',
    'Impendulo',
    'March Bolero',
    'Gazelle',
    'NV350',
    'Lucino',
    'Trade',
    'Pintara',
    'Tsuru',
    'Cedric Y31',
    'Aprio',
    'NV300',
    'NT500',
    'GTP ZX-Turbo',
    'Auster',
    'March NISMO',
    'S-Cargo',
    'ZEOD RC',
    'MID 4',
    'Pulsar EXA',
    'R88C',
    'March Rafeet',
    'Liberta Villa',
    'El Grande',
    '240 RS',
    'Axxess',
    'Dayz Highway Star',
    'KIX',
    'Jonga',
    'Violet',
    'R381',
    '300C',
    'Junior',
    'E-4WD',
    'R90C',
    'Hypermini',
    'Z',
    'March Cabriolet',
    'NPT-90',
    'Z Series',
    'P35',
    'R391',
    'S130',
    'Forum',
    'Diesel Space Arrow',
    'R383',
    'Invitation',
    'Multi',
    'Saurus',
    'AD Max',
    '260Z',
    '100 NX Coupe',
    'Ute',
    'CUE-X Concept',
    'Sani',
    'Santana',
    'Prince Royal',
    'Diesel UA',
    'Altra',
    'Prince R380',
    'R382',
    'Atlas',
    'Hi-Cross',
    'C80',
    'Nomad',
    'R87E',
    '120 Y',
    'QX',
    'King Van',
    'Nails',
    'Land Glider',
    'Diesel Space Runner RM',
    'Diesel RN',
  ],
  Noble: ['M12', 'M600', 'M400', 'M14', 'M15', 'M10'],
  NSU: ['Ro 80', 'Prinz', 'TT', 'Sport Prinz', '1000', 'TTS', 'Wankel Spider', '1200', 'Spider', 'Typ 110', 'Motorenwerke'],
  Oldsmobile: [
    'Cutlass',
    'Cutlass Supreme',
    'Cutlass Ciera',
    '442',
    'Delta 88',
    'Toronado',
    'Eighty-Eight',
    'Starfire',
    'Ninety-Eight',
    'Super 88',
    'Alero',
    '88',
    '98',
    'Vista Cruiser',
    'Dynamic 88',
    'Custom Cruiser',
    'Hurst/Olds',
    'Bravada',
    'Aurora',
    'Silhouette',
    'Intrigue',
    'Cutlass Calais',
    'Cutlass Cruiser',
    'F-85',
    'Achieva',
    'Omega',
    'Delmont 88',
    'Regency',
    'Jetstar 88',
    'Jetfire',
    'LSS',
    'Curved Dash',
    'Golden Rocket 88',
    'Touring Sedan',
    'Series 60',
    'Firenza',
    'Jetstar I',
    'Series 70',
    'Royale',
    'DeLuxe 88',
    'Model 30',
    'F-88 Concept',
    'Viking',
    'Golden Rocket',
  ],
  Omega: ['GT'],
  Opel: [
    'Astra',
    'Corsa',
    'Zafira',
    'Insignia',
    'Meriva',
    'Vectra',
    'Vivaro',
    'Mokka',
    'Adam',
    'Omega',
    'Antara',
    'Combo',
    'Calibra',
    'Agila',
    'Tigra',
    'Karl',
    'Kadett',
    'Cascada',
    'Movano',
    'Signum',
    'Manta',
    'Frontera',
    'Ascona',
    'GT',
    'Rekord',
    'Corsa Utility',
    'Grandland X',
    'Cabrio',
    'Ampera',
    'Kadett C',
    'Crossland X',
    'Commodore',
    'Monterey',
    'Speedster',
    'Blitz',
    'Kapitan',
    'Senator',
    'Diplomat',
    'Kadett B',
    'Olympia',
    'Monza',
    'Admiral',
    'Corsavan',
    'Vita',
    'Rekord P2',
    'Sintra',
    'Kadett A',
    'Rekord P1',
    'Campo',
    'Lotus Omega',
    'Super 6',
    'Olympia Rekord',
    'GTC',
    'K 180',
    'Isuzu',
    'Monza Concept',
    'Bedford Blitz',
    '1900',
    'Vectra GTS V8 DTM',
    'P4',
    'Chevette',
    'Arena',
    'Blazer',
    'Kikinda',
    'Slalom',
    'Gelandesportwagen',
    'Regent',
    'Laubfrosch',
    'Calais',
    'Rekord Series E',
    '5/12 PS',
    'GTC Paris',
    'Rekord Series C',
    '1.2 litre',
    'RAK e',
    '1200',
    '1900 Coupe',
    '4/8 PS',
    'Rekord Series A',
  ],
  Optare: [
    'Versa',
    'Solo',
    'Spectra',
    'Delta',
    'Tempo',
    'MetroCity',
    'MetroRider',
    'Olympus',
    'MetroDecker',
    'Prisma',
    'Alero',
    'Excel',
    'Vecta',
    'Esteem',
    'CityPacer',
    'Sigma',
    'Rapta',
  ],
  Osca: ['MT4'],
  Oshkosh: ['Striker', 'M1070', 'L-ATV', 'M-ATV'],
  OSI: ['20M TS'],
  Packard: [
    'Clipper',
    'Super Eight',
    'Eight',
    'Twelve',
    'Caribbean',
    'Convertible',
    'Patrician',
    'Six',
    'Sedan',
    'Hawk',
    'Custom Eight',
    '200',
    '180',
    'Cavalier',
    '250',
    'Mayfair',
    'One-Twenty',
    'Executive',
    'Panther',
    '300',
    'Station Sedan',
    'Hardtop',
    'Station Wagon',
    'Light Eight',
    'Custom Convertible',
    'Four Hundred',
    'Pacific',
    'Model 30',
    'Pan-American',
    'One-Ten',
  ],
  Pagani: ['Huayra', 'Zonda', 'Zonda R'],
  Panhard: ['24', 'PL 17', 'Dyna Z', 'Dyna X', 'Dyna Junior', 'CD', 'Dynamic', 'Dynavia', 'et Levassor Dynamic', 'DB Le Mans', 'DB HBR 5'],
  Panoz: [
    'Esperante',
    'Esperante GTR-1',
    'Abruzzi',
    'AIV-Roadster',
    'Roadster',
    'DP01',
    'Avezzano',
    'LMP-1 Roadster-S',
    'LMP07',
    'G-Force GF09',
  ],
  Panther: ['Kallista', 'Lima', 'J72', 'De Ville', 'Westwinds', '6', 'Solo', 'Rio'],
  Passport: ['Optima'],
  Patrick: ['Dempsey'],
  Peel: ['P50', 'Trident'],
  Peerless: ['GT'],
  Pegaso: ['Z-102', 'Z-103'],
  Perodua: ['Myvi', 'Alza', 'Axia', 'Kelisa', 'Viva', 'Bezza', 'Kenari', 'Kancil', 'Kembara', 'Nautica', 'Aruz', 'Nippa', 'Rusa'],
  Peterbilt: ['379', '281'],
  Peugeot: [
    '308',
    '208',
    '206',
    '207',
    '2008',
    '3008',
    '307',
    '508',
    'Partner',
    'RCZ',
    '407',
    '107',
    '5008',
    '108',
    '306',
    '106',
    'Boxer',
    '205',
    '301',
    '309',
    'Expert',
    '206 CC',
    '207 CC',
    '407 Coupe',
    '406',
    '308 CC',
    '204',
    '504',
    '405',
    '307 CC',
    '404',
    '607',
    '807',
    '1007',
    '306 Cabriolet',
    '4007',
    '406 Coupe',
    'Bipper',
    '408',
    '505',
    '508 RXH',
    '403',
    '4008',
    '203',
    '304',
    'Traveller',
    '2008 DKR',
    'Ion',
    'Bipper Tepee',
    '205 Turbo 16',
    '104',
    '208 T16',
    '402',
    '3008 DKR',
    '206 WRC',
    '207 S2000',
    'Onyx',
    '605',
    'Rifter',
    '202',
    'SR1',
    '604',
    '806',
    '908',
    '305',
    '908 HDi FAP',
    'BB1',
    'J7',
    '201',
    '907',
    '205 Cabriolet',
    '905',
    'P4',
    'HR1',
    '908 RC',
    'J9',
    'Coupe',
    'Hoggar',
    'Pars',
    '307 WRC',
    'DMA',
    '308 (T7)',
    '405 Turbo 16',
    '301 (2012)',
    'Convertible',
    'Ludix',
    '401',
    'EX1',
    '308 (T9)',
    '302',
    '4002',
    'RC',
    '20Cup',
    'J5',
    'Proxima',
    'City car',
    'EX1 Concept',
    'Quadrilette',
    'D3',
    'D4',
    '601',
    'Speedfight',
    'Bebe',
    'Type 2',
    'Quasar',
    'Ranch',
    'JetForce',
    'Velv',
    'Vivacity',
    'Groupe PSA',
    '20coeur',
    'Oxia Concept',
    'Crossover',
    'Supermini',
    'Flux',
    '604 Limousine',
    'Hoggar (concept)',
    'Rapido',
    'Type 15',
    'Satelis Compressor',
    '506',
    'Large family car',
    'Type 177',
    'Type 153',
    'Type 56',
    'Executive car',
    'Type 184',
    'Type 5',
    'Type 190',
    'Type 163',
    'Type 174',
    'D3 and D4',
    'Quark',
    'Type 183',
    'Type 125',
  ],
  Piaggio: ['Porter', 'Ape', 'Quargo', 'M500'],
  Pininfarina: ['Fittipaldi EF7', 'Sergio', 'Enjoy', 'Lancia Florida', 'Volvo C70'],
  Plaxton: ['Prestige'],
  Plymouth: [
    'Prowler',
    'Barracuda',
    'Road Runner',
    'Fury',
    'Valiant',
    'Belvedere',
    'Laser',
    'Duster',
    'GTX',
    'Superbird',
    'Satellite',
    'Special De Luxe',
    'Savoy',
    'Neon',
    'Suburban',
    'Grand Voyager',
    'Gran Fury',
    'Acclaim',
    'Voyager',
    'De Luxe',
    'Cranbrook',
    'Scamp',
    'Reliant',
    'Breeze',
    'Arrow',
    'Plaza',
    'Volare',
    'Horizon',
    'Caravelle',
    'Colt',
    'Sundance',
    'Cambridge',
    'Turismo',
    'Trail Duster',
    'Cricket',
    'Sport Suburban',
    'Champ',
    'Custom Suburban',
    'Conquest',
    'Sapporo',
    'Concord',
    'Expresso',
    'Arrow Truck',
    'Horizon TC3',
    'Chrysler Corporation',
    'De Luxe Suburban',
    'Voyager 3',
    'Valiant Valiant',
  ],
  Polestar: ['2', '1'],
  'Polski Fiat': ['126 p', '125 p', '508', '126', '125'],
  Pontiac: [
    'Firebird',
    'Vibe',
    'GTO',
    'Grand Prix',
    'Trans Am',
    'Grand Am',
    'Sunfire',
    'Bonneville',
    'G8',
    'G6',
    'Sunbird',
    'Fiero',
    'Solstice',
    'LeMans',
    'Catalina',
    'Chieftain',
    'Trans Sport',
    'Star Chief',
    'Aztek',
    'G5',
    'Tempest',
    'Parisienne',
    'Montana',
    'Torrent',
    'Ventura',
    'Streamliner',
    '6000',
    'Torpedo',
    'Montana SV6',
    'Banshee',
    'Phoenix',
    'Laurentian',
    'Safari',
    'Grand Ville',
    'Pursuit',
    'Wave',
    'Acadian',
    'Matiz',
    'Astre',
    'G3',
    'Grand Safari',
    'G3 Wave',
    'G4',
    'Strato Chief',
    'Custom S',
    'Can Am',
    'Super Chief',
    'Banshee Concept',
    'Pathfinder',
    'Aztec',
    'T1000',
    '1000',
    'Grande Parisienne',
    'Sunrunner',
    '2+2',
    'J2000',
    'Executive',
    'Ventura II',
    '2000',
    'Firefly',
    'Firebird (third generation)',
    'Sunburst',
    'Bonneville Special',
    'Monkeemobile',
    'Le Mans Sedan',
    'De-Lux',
  ],
  Porsche: [
    '911',
    'Cayenne',
    'Carrera',
    'Panamera',
    'Boxster',
    'Macan',
    '356',
    'Cayman',
    'Carrera GT',
    '911 GT3',
    '944',
    '914',
    'Targa',
    '924',
    '918 Spyder',
    'Cayman GT4',
    '997',
    '991',
    '928',
    '996',
    '964',
    '993',
    '718 Boxster',
    'Carrera GTS',
    '912',
    '959',
    '918',
    '911 GT2',
    '718 Cayman',
    '968',
    '550',
    '718',
    '930',
    '911 (classic)',
    '901',
    '911 GT1',
    '914-6 GT',
    'Junior',
    'Carrera Cup',
    'family',
    'Super',
    'Carrera 6',
    '912E',
    'Boxster/Cayman',
    'Museum, Stuttgart',
    '981',
    '597',
    '987',
    '916',
    'RS Spyder',
    '986',
    '356/1',
    '9ff GT9',
    '919 Hybrid',
    'Panamericana',
    'Panamericana Concept',
    '917',
    '935',
    '992',
    '962',
    'P1',
    'RSK',
    '911 RSR (2017)',
    '360',
    '958',
    'Holding',
    'LMP1-98',
    '904',
    'Carrera Cup France',
    '64',
    '934/5',
    '908',
    'Taycan',
    '955',
    '956',
    '911 Turbo S LM-GT',
    'Challenge',
    'Type 12',
    '906',
  ],
  Praga: ['Piccolo', 'Alfa'],
  Premier: ['Padmini', 'Rio', '118'],
  Prince: ['Skyline', 'Gloria', 'R380', 'Clipper', 'Skyway'],
  Proton: [
    'Wira',
    'Saga',
    'Persona',
    'Satria',
    'Iriz',
    'Exora',
    'Ertiga',
    'Preve',
    'GEN-2',
    'Waja',
    'Perdana',
    'Suprima S',
    'Inspira',
    'Savvy',
    'Arena',
    'Jumbuck',
    'Juara',
    'Tiara',
    'Compact',
    'S16',
    'Putra',
    'Satria R3',
    'Persona (CM)',
    'Saloon',
    '400 Series',
    'M21',
    '300 Series',
    'Saga (first generation)',
    'Perdana (first generation)',
  ],
  PUCH: ['Pinzgauer', 'G-modell'],
  Puma: ['GTS', 'AMV'],
  Qoros: ['3', '3 City SUV', '5', '3 GT', 'Mile'],
  Qvale: ['Mangusta'],
  RAF: ['2203', '977'],
  RAM: ['1500', '3500', '2500', 'Trucks', 'PROMASTER', '5500', 'CHASSIS CAB', 'Dakota', 'Rebel TRX'],
  Rambler: ['Ambassador', 'American', 'Classic', 'Rebel', 'Marlin', 'Javelin', 'Matador', 'Hornet', 'AMX'],
  'Range Rover': ['Evoque', 'Sport', 'Velar'],
  'Rayton-Fissore': ['Magnum'],
  Reliant: [
    'Robin',
    'Scimitar',
    'Regal',
    'Rialto',
    'Kitten',
    'Scimitar SS1',
    'Rebel',
    'Bond Bug',
    'Sabre',
    'Fox',
    'Scimitar Sabre',
    'Ant',
    'TW9',
    'Scimitar SST',
  ],
  Rely: ['V5', 'X5', 'H5'],
  Renault: [
    'Megane',
    'Clio',
    'Logan',
    'Sandero',
    'Twingo',
    'Laguna',
    'Scenic',
    'Captur',
    'Kangoo',
    'Fluence',
    'Modus',
    'Duster',
    'Koleos',
    'Alpine',
    'Trafic',
    'Grand Scenic',
    'Vel Satis',
    'Espace',
    'Sport',
    'Sandero Stepway',
    'Kadjar',
    'Master',
    'Symbol',
    'Talisman',
    'Zoe',
    'Fuego',
    'Grand Modus',
    'Alaskan',
    'Twizy',
    '5',
    '4',
    'Kwid',
    'Super 5',
    '12',
    '5 Turbo',
    'Fregate',
    'Clio Sport',
    '17',
    'Argentina',
    '19',
    '4CV',
    'Grand Espace',
    'Dauphine',
    'R4',
    'Megane Sport',
    'Wind',
    'Latitude',
    'Caravelle',
    '11',
    '10',
    'Kaptur',
    'Estafette',
    '16',
    'Safrane',
    'Initiale Paris',
    '21',
    'Eolab',
    'Be Bop',
    'Duster Oroch',
    '18',
    'R5',
    'Kangoo Z.E.',
    'Avantime',
    'Fluence Z.E.',
    '25',
    'Samsung SM3',
    'Dacia Duster',
    'R',
    'DeZir',
    'Trucks',
    'Clio Williams',
    'Trezor',
    'Kangoo Express',
    'Spider',
    '6',
    'Mascott',
    'Colorale',
    'Sport Spider',
    'R19',
    'Express',
    'Arkana',
    'Lodgy',
    'Y',
    'Dokker',
    'Oroch',
    'Alliance',
    'R-Space',
    'Galion',
    'Z.E.',
    '40CV',
    'Floride',
    'Espana',
    'Alpine V6',
    '14',
    '3',
    'Mexico',
    'Sandero R.S. 2.0',
    'Frendzy',
    'Scala',
    'Torino',
    '30',
    'Premium',
    'Thalia',
    'Pulse',
    'R16',
    'Celtaquatre',
    'Rodeo',
    '1 000 kg',
    'NN',
    'Sport R.S. 01',
    'Samsung QM5',
    'G',
    'Rapid',
    'A',
    'C',
    'EZ-ULTIMO',
    'Samsung SM5',
    'Le Car',
    'EZ-GO',
    'Juvaquatre',
    '8',
    'D',
    'Ondine',
    'FT',
    'E',
    "Twin'Z",
    'Grand Prix',
    'K',
    'Messenger',
    'Dauphinoise',
    'Kwid concept',
    'Alpine A442',
    'Encore',
    "Twin'Run",
    'Nepta',
    'Samsung QM3',
    'IKA-Torino',
    'Vivastella',
    'Viva Grand Sport',
    'V',
    'Samsung SM7',
    '7',
    'Nervasport',
    'Lutecia',
    'do Brasil',
    '9',
    'Premier',
    'KZ',
    'Etoile Filante',
    'Vivaquatre',
    'R35',
    'Captur Concept',
    'TRM 10000',
    'Tondar 90+',
    'RS01',
    'R.S.16',
    'Primaquatre',
    'Triber',
    'Domaine',
    'Egeus',
    'AX',
    'Fluence Concept',
    'Suprastella',
    'Racoon',
    'Nervastella',
    'Fiftie',
    '15',
    'Clio V6 Sport',
    'R.S.17',
    'Medallion',
    'R.S.18',
    'GTA',
    '20/30',
    'R25',
    'Sherpa 2',
    'Engines',
    '20',
    '750',
    'Altica',
    'Trucks T',
    'Trucks C',
    'CH',
    'Monaquatre',
    'F',
    'Alpine GTA/A610',
    'Reinastella',
    'Monastella',
    'L',
    'Master Tipper',
    'Monasix',
    'Samsung QM6',
    'A110',
    'EZ-PRO',
    'Midlum',
    'Taxi de la Marne',
    'X',
    'Magnum',
    'Talisman Concept',
    'Nissan Micra',
    'Zoom',
    'Towncar',
    '1100',
    'Samsung SM6',
    'UE Chenillette',
    'Primastella',
    'AGP',
    'B',
    'Manoir',
    'A310',
    'AvtoVAZ',
    'Sofasa',
    'Virage',
    'R7',
    'Argos',
    'KJ',
    'P 1400',
    'Oyak-Renault',
    'Maxity',
    'Zo',
    'Super Goelette',
    'Novaquatre',
    'TN',
    'Vivasix',
    'Clio Williams Maxi',
    '9 and 11',
    'Trucks D',
    'Voiturette',
    'Vivasport',
    'Nueva Kangoo',
    'Motrio',
    'R30',
    'AGC',
    'Kerax',
    'AGR',
    'R212',
    'Trucks K',
    'PN',
    'EF',
    'R3',
    'F7R',
    'Pars',
    'Revoz',
    '8 and 10',
    '15 and 17',
    'AGK',
    'Alpine A110-50',
    'R312',
    'R.S.19',
  ],
  'Rene Bonnet': ['Missile', 'Matra Djet', 'Matra'],
  Riich: ['M1', 'G5', 'G6', 'X1', 'G3'],
  Riley: [
    'Elf',
    'RMA',
    'Kestrel',
    'Pathfinder',
    '12/4',
    'RME',
    'RM',
    'One-Point-Five',
    'MPH',
    'RMB',
    'Nine',
    'RMD',
    '4/72',
    'RMC',
    'Imp',
    'RMF',
    '4',
    'Sprite',
    '1? Litre',
    '2? Litre',
    '4/68',
    'Two-Point-Six',
    '12/6',
    '12',
    '16',
    '1300',
  ],
  Rimac: ['Concept_One', 'One'],
  Roewe: [
    'RX5',
    '750',
    '550',
    '350',
    'Marvel X',
    'RX8',
    'eRX5',
    'RX3',
    'W5',
    '950',
    'i6',
    '360',
    'e950',
    'E50',
    'i5',
    'e550',
    'ei6',
    'ei5',
  ],
  'Rolls-Royce': [
    'Phantom',
    'Ghost',
    'Silver Shadow',
    'Dawn',
    'Wraith',
    'Silver Cloud',
    'Silver Ghost',
    'Corniche',
    'Silver Spirit',
    'Phantom II',
    'Phantom Coupe',
    'Silver Spur',
    'Phantom Drophead Coupe',
    'Phantom VI',
    'Silver Wraith',
    'Phantom I',
    'Silver Dawn',
    'Phantom V',
    'Phantom III',
    'Silver Seraph',
    '20/25',
    'Park Ward',
    '25/30',
    'Phantom VIII',
    'Camargue',
    '20',
    'Armoured Car',
    'Silver Wraith II',
    'Ghost Extended Wheelbase',
    'Twenty',
    'Phantom IV',
    'Cullinan',
    'Phantom VII',
    '10 hp',
    '40/50 hp',
    'FAB 1',
    'Wraith (1938)',
    'Sweptail',
    'Wraith (2013)',
    'Limited',
    'Dawn (2015)',
    'Corniche (2000)',
    "Enthusiasts' Club",
    '15 hp',
    '30 hp',
  ],
  Ronart: ['Lightning'],
  Rootes: ['Arrow', 'Karrier'],
  Rossion: ['Q1'],
  Rover: [
    '75',
    'Mini',
    '100',
    '200',
    '45',
    '400',
    '25',
    'P4',
    'SD1',
    'Streetwise',
    'P6',
    'Metro',
    '110',
    '3500',
    '216',
    '2000',
    '800',
    '600',
    '90',
    'Vitesse',
    'Coupe',
    'Sterling',
    'P5',
    '12',
    '3? Litre',
    'MG',
    'CityRover',
    '3.5-Litre',
    '213',
    'Montego',
    '10',
    'MGF',
    '200 Coupe',
    '2600',
    '16',
    'Maestro',
    '20',
    'P3',
    '800 series',
    'Cabriolet',
    '95',
    '14',
    '200 / 25',
    '9',
    '600 Series',
    'JET1',
    'P2',
    '400 / 45',
    '416i',
    '60',
    '6',
    '2200',
    '105',
    '8',
    '80',
    '10/25',
    '2300',
    'Quintet',
    'CCV',
    'V8-S',
    'Meteor',
    'Commerce',
    'Light Six',
  ],
  Ruf: [
    'CTR3',
    'CTR',
    'RGT',
    'CTR2',
    'Rt 12',
    'Turbo R',
    'BTR',
    'SCR',
    'RK Coupe',
    'RTurbo',
    'RTR',
    '3400S',
    'R Kompressor',
    'SCR 2018',
    'Turbo Florio',
    'BTR2',
    'Dakara',
  ],
  Rumpler: ['Tropfenwagen'],
  Saab: [
    '9-5',
    '9-3',
    '900',
    'Aero',
    '99',
    '9000',
    '96',
    '9-4X',
    '9-2',
    '9-7X',
    '9-2X',
    'Turbo X',
    'Sonett',
    '9-3X',
    '90',
    'Sport',
    'Ursaab',
    '9-6X',
    'PhoeniX',
    'EV-1 Concept',
    'GT750',
    'Catherina',
    '600',
    'SAABO',
    'Aero-X',
    'Monster',
    '9-X Biohybrid',
    'Quantum',
  ],
  Saipa: ['Tiba', '141', 'Quick', '132', 'Roham', '111'],
  Saleen: ['S7', 'S5S Raptor', 'S281', 'S1', 'XP8'],
  'Santa Matilde': ['SM'],
  Santana: ['PS-10', '300/350', '300', '350'],
  Saturn: ['SL', 'Ion', 'S series', 'Vue', 'Sky', 'Aura', 'Outlook', 'L series', 'Astra', 'SC', 'Relay', 'LS', 'Curve', 'SW', 'LW'],
  Sbarro: ['Challenge'],
  Scammell: ['Pioneer', 'Mechanical Horse', 'Scarab', 'Commander', 'Townsman'],
  SCG: ['3', '003S'],
  Scion: ['tC', 'FR-S', 'xB', 'iA', 'xD', 'iM', 'iQ', 'xA', 'iQ Concept', 'FR-S Concept', 'Fuse', 'Hako Coupe'],
  SEAT: [
    'Leon',
    'Ibiza',
    'Altea',
    'Alhambra',
    'Mii',
    'Toledo',
    'Arosa',
    'Ateca',
    'Exeo',
    'Cordoba',
    'Malaga',
    '600',
    'Arona',
    'Bocanegra',
    '124',
    'Marbella',
    '127',
    '850',
    'Inca',
    'Tarraco',
    '131',
    '1430',
    '124 Sport',
    'Leon X-PERIENCE',
    '128',
    '1500',
    'Panda',
    '1400',
    'Ronda',
    '1200 Sport',
    '133',
    'Ritmo',
    '800',
    'Fura',
    'Salsa',
    'Cupra Ateca',
    '132',
    'Terra',
    '1430 Sport',
    'Trans',
    'Gredos',
  ],
  'Sebring-Vanguard': ['Citicar'],
  Senova: ['X25', 'X55', 'D50', 'D20', 'X35', 'D70', 'X65', 'D60'],
  Shanghai: ['SH 760'],
  Shelby: [
    'Mustang',
    'Cobra',
    'GT500',
    'Daytona',
    'AC Cobra',
    'GT350',
    'Carroll Shelby',
    'CSX',
    'Series 1',
    'American',
    'GLHS',
    'Omni GLH-S',
    'Dakota',
    'Charger',
    'Can-Am',
    'Lancer',
  ],
  ShuangHuan: ['Sceo'],
  Siata: ['Spring', 'Daina', '208s', '208 CS'],
  Simca: [
    '1000',
    'Aronde',
    'Chambord',
    '1100',
    '8',
    'Vedette',
    '1301',
    '1500',
    '1501',
    '1200S',
    '1200',
    '1000 Coupe',
    '5',
    '1307',
    '1308',
    'Horizon',
    'Ariane',
    '6',
    'do Brasil',
    'Matra Bagheera',
    'Rallye 3',
    '9',
    'Esplanada',
    '1204',
    'Matra Rancho',
    '1300/1500',
    '1301/1501',
    'Abarth 2000',
    'Comete',
    "Sim'4",
    'Matra Murena',
    'Profissional',
  ],
  Singer: [
    'Nine',
    'Gazelle',
    'Vehicle Design',
    'Chamois',
    'Vogue',
    'SM1500',
    'Roadster',
    'Hillman Imp',
    'Bantam',
    'Hunter',
    '9 sports',
    'SM Roadster',
  ],
  Sisu: ['Nasu', 'RA-140 DS'],
  Skoda: [
    'Octavia',
    'Fabia',
    'Superb',
    'Yeti',
    'Rapid',
    'Citigo',
    'Kodiaq',
    'Roomster',
    'Karoq',
    '120',
    '130',
    'Felicia',
    'Fabia R5',
    'Favorit',
    '1000 MB',
    '105',
    'Garde',
    'Vision E',
    '14Tr',
    'Praktik',
    'Laura',
    '100',
    '110 R',
    '15Tr',
    '105120',
    '1000',
    '110',
    '24Tr Irisbus',
    'Fabia S2000',
    '30Tr SOR',
    '1100 MB',
    'Pick-up',
    '1201',
    'Popular',
    'Rapid (2012)',
    '31Tr SOR',
    'Works',
    '1203',
    'Felicia Kit Car',
    'Scala',
    '1202',
    'Rapid (1984)',
    'Kamiq',
    'Vision iV',
  ],
  Smart: [
    'Fortwo',
    'Forfour',
    'Brabus',
    'Cabrio',
    'electric drive',
    'Roadster',
    'City Coupe',
    'Crossblade',
    'Forspeed',
    'Crosstown',
    'electric',
    'Formore',
  ],
  Solo: ['750'],
  Soueast: ['Lioncel', 'DX7', 'DX3', 'Delica', 'V6 Lingshi', 'V5', 'V3 Lingyue', 'DX7 Bolang'],
  Spyker: ['C8', 'B6', 'C12 Zagato', 'D8', 'C12 La Turbie', 'F8-VII', 'E12', 'Silvestris V8'],
  SS: ['Jaguar 100'],
  SsangYong: [
    'Kyron',
    'Rexton',
    'Actyon Sports',
    'Actyon',
    'Korando',
    'Tivoli',
    'Stavic',
    'Istana',
    'Rodius',
    'Musso',
    'Chairman',
    'Korando Sports',
    'Musso Sports',
    'Xlv',
    'Korando Turismo',
    'Nomad',
    'Korando Family',
    'Transstar',
    'Family',
    'Kallista',
    'WZ',
  ],
  Standard: [
    'Vanguard',
    'Eight',
    'Atlas',
    '2000',
    'Ten',
    '14',
    'Beaverette',
    'Gazel',
    'Ensign',
    'Pennant',
    'Companion',
    'Little Nine',
    'Flying Nine',
    'Flying Eight',
  ],
  Statesman: ['de Ville', 'Caprice'],
  Steyr: ['Puch Haflinger', 'Puch 500'],
  Studebaker: [
    'Golden Hawk',
    'Commander',
    'Champion',
    'Hawk',
    'President',
    'Avanti',
    'Lark',
    'Silver Hawk',
    'Starlight',
    'Dictator',
    'Daytona',
    'Gran Turismo Hawk',
    'Champ',
    'Coupe Express',
    'Transtar',
    'Land Cruiser',
    'Wagonaire',
    'Cruiser',
    'Sky Hawk',
    'Scotsman',
    'Power Hawk',
    'Conestoga',
    'Big Six',
    'Flight Hawk',
    'Speedster',
    'Light Six',
    'Special Six',
    'Erskine',
    'Electric',
    'M-series truck',
    'Packard Clipper',
    'US6 2?-ton 6x6 truck',
    'Rockne',
    'Packard Corporation',
    'E-series truck',
  ],
  Stutz: ['Bearcat', 'Blackhawk', 'IV-Porte', 'Diplomatica'],
  Subaru: [
    'Impreza',
    'Forester',
    'Legacy',
    'WRX',
    'Outback',
    'Crosstrek',
    'XV',
    'BRZ',
    'Liberty',
    'Levorg',
    'Exiga',
    'Leone',
    'Tribeca',
    'Justy',
    'R-2',
    'B9 Tribeca',
    'SVX',
    'Pleo',
    'Baja',
    'Sambar',
    '360',
    'Stella',
    'BRAT',
    'Vivio',
    'Trezia',
    'Ascent',
    'Traviq',
    'Domingo',
    'Libero',
    'Outback Sport',
    'XT',
    'Tecnica International',
    'Brumby',
    'Loyale',
    'R1',
    'Dex',
    'Alcyone SVX',
    'G3X Justy',
    'Alcyone',
    'BRZ Concept STI',
    'Lucra',
    'Liberty Exiga',
    'Rex',
    '1600',
    '1000',
    'Dias',
    'Shifter',
    'R1e',
    'World Rally Team',
    'VIZIV',
    'Mini Jumbo',
    'Bistro',
    'MV',
    'Sumo',
    'Corporation',
    '1500',
    'Vortex',
    'Impreza (second generation)',
    'FF-1 Star',
    'Chiffon',
    'Legacy (fourth generation)',
    'G',
    'Legacy (fifth generation)',
    'Legacy (third generation)',
    'Legacy (second generation)',
    'Legacy (first generation)',
    'Hybrid Tourer',
  ],
  Sunbeam: [
    'Alpine',
    'Tiger',
    'Rapier',
    'Talbot 90',
    'Imp',
    'Stiletto',
    'Rapier fastback',
    'Talbot Ten',
    '350HP',
    'Mk III',
    'Minx',
    'Hillman Imp',
    '1000 hp',
    '3-litre',
    'Talbot 80',
    'Sceptre',
    'Arrow',
    'Vogue',
    'Sport',
    'Talbot 2 Litre',
    'Silver Bullet',
    'Chamois',
    'Alpine fastback',
  ],
  Suzuki: [
    'Grand Vitara',
    'Escudo',
    'Swift',
    'Jimny',
    'SX4',
    'Swift+',
    'Vitara',
    'Baleno',
    'Alto',
    'Samurai',
    'Wagon R',
    'Ignis',
    'SX4 S-Cross',
    'Celerio',
    'Ertiga',
    'Liana',
    'Splash',
    'Kizashi',
    'Aerio',
    'Carry',
    'S-Cross',
    'Cultus',
    'Kei',
    'Sidekick',
    'Spacia',
    'Verona',
    'Sierra',
    'Every',
    'APV',
    'Grand-Escudo',
    'Cappuccino',
    'Karimun',
    'Bolan',
    'Cervo',
    'XL-7',
    'Forenza',
    'Solio',
    'Mehran',
    'Lapin',
    'SJ 410',
    'Ciaz',
    'MR Wagon',
    'Hustler',
    'Esteem',
    'Equator',
    'X-90',
    'Khyber',
    'SJ Samurai',
    'Fronte',
    'LJ80',
    'Margalla',
    'Ravi',
    'Landy',
    'Santana',
    'Palette',
    'SC100',
    'Fun',
    'Reno',
    'Cultus Crescent',
    'SJ 413',
    'Hayabusa',
    'Twin',
    'FX',
    'LJ',
    'GSX-R1000',
    'Every-Landy',
    'SJ',
    'Baleno (2015)',
    'GSX-R600',
    'Mighty Boy',
    'Nomade',
    'GSX-R750',
    'X-HEAD',
    'Fronte 800',
    'Cara',
    'Suzulight',
    'Satria',
    'SV650',
    'Potohar',
    'Hatch',
    'GSX-R/4',
    'GSX1300R',
    'Intruder',
    'Cappucino',
    'Cruze',
    'DR650',
    'Indomobil Motor',
    'V-Strom 650',
    'CV1',
    'GSX-S1000',
    'GSX-R1100',
    'GS500',
    'GSX-S750',
    'Burgman',
  ],
  SWM: ['X7', 'G01', 'X3'],
  TagAZ: ['Tager', 'Aquila', 'Vega', 'Road Partner', 'C-190', 'C10', 'Tingo', 'Estina', 'Santa Fe Classic', 'C30', 'Sonata', 'Accent'],
  Talbot: [
    'Samba',
    'Sunbeam',
    'Solara',
    'Horizon',
    'Rancho',
    'Murena',
    'Simca',
    'Express',
    '105',
    'Tagora',
    '150',
    'Lago Record',
    'Alpine',
    'Avenger',
    '1100',
    '1510',
    'Lago Sport',
    '180',
    '1200',
  ],
  Tarpan: ['Honker'],
  Tata: [
    'Indica',
    'Nexon',
    'Sumo',
    'Nano',
    'Safari',
    'Indigo',
    'Xenon',
    'Sumo Grande',
    'Hexa',
    'Tiago',
    'Sumo Victa',
    'Zest',
    'Bolt',
    'Aria',
    'Super Ace',
    'Harrier',
    'Vista',
    'Venture',
    'Magic',
    'Indigo Marina',
    'Sierra',
    'Indigo eCS',
    'Tigor',
    '407',
    'Magic Iris',
    'Marcopolo',
    'Manza',
    'Indigo XL',
    'Spacio',
    'Telcoline',
    'Pixel',
    'Winger',
    'Starbus',
    'Estate',
    'Pr1ma',
    'Cliffrider',
    'Elegante',
    'OneCAT',
    'Mint',
    'TL',
    'Ace',
    'Prima',
    'Construck',
  ],
  Tatra: [
    '603',
    '613',
    'T613',
    '87',
    '600',
    '77',
    '700',
    'T700',
    '57',
    '75',
    'MTX V8',
    'JK 2500',
    '97',
    '12',
    '52',
    '80',
    '816',
    'T77',
    'OA vz. 30',
    '623',
    'Prasident',
    '20',
    'V570',
    '30',
    '11',
    '17',
    '54',
    '49',
    '13',
    '815',
    '15',
    '605',
    '70',
    '602',
    '72',
    '10',
    'Baghira',
    '601',
    '90',
  ],
  Tazzari: ['Zero'],
  TechArt: ['Automobildesign GmbH', 'Magnum', '997 Turbo', 'GTsport'],
  Tesla: [
    'Model S',
    'Model X',
    'Model 3',
    'Roadster',
    'Supercharger',
    'Semi',
    'Roadster (2020)',
    'Model Y',
    'Roadster (2008)',
    'Energy',
    'Powerwall',
  ],
  Tianye: ['Admiral'],
  Tofas: ['Dogan', 'Kartal', 'Sahin', '131', 'Tempra', 'Serce', 'Tipo', 'Doblo', 'Palio'],
  'Touring Superleggera': ['Disco Volante 2012'],
  Toyota: [
    'Corolla',
    'Camry',
    'Land Cruiser',
    'Mark II',
    'RAV4',
    'Land Cruiser Prado',
    'Corona',
    'Vitz',
    'Caldina',
    'Chaser',
    'Carina',
    'Avensis',
    'Hilux',
    'Allion',
    'Vista',
    'Wish',
    'Crown',
    '4Runner',
    'Harrier',
    'Sprinter',
    'Ipsum',
    'Premio',
    'Prius+',
    'Celica',
    'Highlander',
    'Yaris',
    'Corolla Spacio',
    'Cresta',
    'Prius',
    'Supra',
    'HiAce',
    'Fortuner',
    'TownAce',
    'Tacoma',
    'Auris',
    'LiteAce',
    'GT86',
    'ist',
    'Passo',
    'Tundra',
    'Hilux Surf',
    'Altezza',
    'Soarer',
    'Allex',
    'Kluger',
    'Platz',
    'Probox',
    'Fun Cargo',
    'Nadia',
    'Ractis',
    'Estima',
    'MR2',
    'Mark X',
    'Starlet',
    'FJ Cruiser',
    'Raum',
    'Noah',
    'Celsior',
    'Aristo',
    'Succeed',
    'Gaia',
    'Windom',
    'C-HR',
    'Etios',
    'Sienna',
    'Voxy',
    'bB',
    'Aygo',
    'Carina ED',
    'Venza',
    'Tercel',
    'Opa',
    'Will',
    'Alphard',
    'Belta',
    'Carina E',
    'Sienta',
    'Prado',
    'MR-S',
    'Verso',
    'Corsa',
    'Avalon',
    'Verossa',
    'Isis',
    'Paseo',
    'Sprinter Marino',
    'Rush',
    'Innova',
    'Vellfire',
    'Aurion',
    'Matrix',
    'Prius C',
    'Duet',
    'Vios',
    'Sequoia',
    'Regius',
    'Corona EXiV',
    'Crown Majesta',
    'Porte',
    'AE86',
    '86',
    'Cressida',
    'iQ',
    'Quantum',
    'T100',
    'Corolla Verso',
    'Cami',
    'Avanza',
    'Corolla II',
    'Vanguard',
    'Solara',
    'Progres',
    'Cynos',
    'Prius V',
    'Passo Sette',
    'Corolla Rumion',
    'Voltz',
    'Previa',
    'Urban Cruiser',
    'Echo',
    'Kijang Innova',
    '2000GT',
    'Mirai',
    'Soluna',
    'MasterAce',
    'Proace',
    'Agya',
    'Brevis',
    'Grand HiAce',
    'Tarago',
    'Century',
    'Dyna',
    'Pixis Epoch',
    'Picnic',
    'Aqua',
    'Camry Solara',
    'Sports 800',
    'JZX100',
    'Celica GT-Four',
    'Prius Plug-in Hybrid',
    'Granvia',
    'Curren',
    '90C-V',
    'RunX',
    'RegiusAce',
    'Dream Car',
    'Coaster',
    'Cavalier',
    'Sera',
    'Scepter',
    'Fj',
    'Mark X ZiO',
    'Kijang',
    'Rukus',
    'Fielder',
    'Carina II',
    'Mega Cruiser',
    'Classic',
    'RAV4 EV',
    'Pronard',
    'FJ40',
    'Spacio',
    'RAV4 Adventure',
    'Publica',
    'Esquire',
    'NAV1',
    'Revo',
    'Origin',
    'Blizzard',
    'Soluna Vios',
    'Tamaraw',
    'Glanza',
    "Ses'fikile",
    'Pixis',
    'Spade',
    'Carib',
    'Roomy',
    'Tazz',
    'Sai',
    'Camry (XV40)',
    'Meru',
    'Stout',
    '88C',
    'Limo',
    'Corona Mark II',
    'Celica XX',
    'Tank',
    'Pixis Space',
    'AE85',
    'Mark II Blit',
    'Blade',
    'Super',
    'Daihatsu Ayla',
    'Lexcen',
    'Calya',
    'Celica LB Turbo',
    'Comfort',
    'Land Cruiser Colorado',
    'Stallion',
    'JPN Taxi',
    'Vienta',
    'GT-One',
    'Pixis Joy',
    'Qualis',
    'Pixis Mega',
    'Yaris Verso',
    'Master',
    'Daihatsu Sigra',
    'Sparky',
    'AE',
    'Condor',
    '88C-V',
    'Surf',
    'Spacia',
    '1000',
    'Lucida',
    'JPN Taxi Concept',
    'SA',
    '7',
    'Sport 800',
    'F',
    'X-Runner',
    'Pixis Truck',
    'C-HR concept',
    'Corolla (E110)',
    'G Sports',
    'Corolla (E10)',
    'Camry (XV10)',
    'Tiara',
    'Corolla (E120)',
    'BJ',
    'AA',
    '700',
    'HDJ',
    'Corolla (E100)',
    'Publica Sports',
    'Corolla (E70)',
    'Crown Eight',
    'AB',
    'Corolla (E90)',
    '92C-V',
    'Fine',
    'Family Wagon',
    'Corolla Furia',
    'Lite Stout',
    'Aygo Crazy',
    'Aurion (XV40)',
    'S-FR',
    'Corolla (E140)',
    'RAV4 Premium',
    'AC',
    'Hybrid X',
    'Daihatsu Boon',
    'FT-1',
    'Corolla (E80)',
    'i-Road',
    'Land Cruiser (J40)',
    'Prius (XW30)',
    'Corolla (E20)',
    'ToyoAce',
    'T-18',
    'FT-86 Concept',
    'Corolla (E170)',
    'Land Cruiser (J70)',
    'Corolla (E30)',
    'Daihatsu Storia',
    'QuickDelivery',
    'GR Super Sport Concept',
    'TMG EV P001',
    'IMV',
    'Entune',
    'FT-HS',
    'RiN',
    'Corolla (E160)',
    'SC',
    'Funtime',
    'SD',
    'Tj Cruiser',
    'RR',
    'Sports 800 Gas Turbine Hybrid',
    'Hilux Bruiser',
    'Master Line',
    'FT-AC',
    'Su-Ki',
    'RH',
    'Prius (XW20)',
    'A1',
    'Yaris HSD Concept',
    'FT-86 II',
  ],
  Trabant: ['601', 'P 601', '1,1'],
  Traum: ['Meet 3', 'Seek 5', 'S70'],
  Triumph: [
    'TR6',
    'Spitfire',
    'TR4',
    'TR4A',
    'TR3',
    'Stag',
    'TR250',
    'TR7',
    'Dolomite Sprint',
    'Dolomite',
    'Herald',
    'TR5',
    'GT6',
    'Vitesse',
    'TR',
    '2000',
    '2500',
    'TR2',
    'Roadster',
    'Mayflower',
    'Acclaim',
    '1800',
    'Italia',
    '2.5 PI',
    '1300',
    'Toledo',
    'TR8',
    'Gloria',
    '1500',
    'Renown',
    'Thunderbird',
    'Scrambler',
    'Daytona 675',
    'Chicane',
    '10',
    'Courier',
    'Trophy',
    'Tiger',
    '1200',
    'Thruxton',
    'Speed Triple',
    'Fury',
    'TR6 Trophy',
    'Super 7',
    'TR7 Sprint',
  ],
  Trumpchi: ['GS8', 'GS4', 'GS7', 'GA8', 'GA6', 'GM8', 'GS5', 'GA5', 'GA3S', 'GS3', 'GE3', 'GM6', 'GA4'],
  Tucker: ['48'],
  TVR: [
    'Sagaris',
    'Tuscan',
    'Chimaera',
    'Griffith',
    'Cerbera',
    'Vixen',
    'Tasmin',
    'Grantura',
    'T350',
    'Cerbera Speed 12',
    'Tamora',
    '3000 M',
    'Taimar',
    '350i',
    'S',
    '280i',
    'Cerbera Speed',
    'Tuscan Speed Six',
    'S series',
    'Typhon',
    'Griffith 200',
    '2500',
    'M series',
    'Griffith 400',
    'S3',
    'T400R',
    'S2',
    '3000',
    '400SE',
    'S1',
    '420 SEAC',
    'V8S',
    '390SE',
    'Wedges',
    '450 SEAC',
    '1600',
    '420',
    '350',
    '450SE',
    '400',
    '450',
    '280',
    '390',
    'Tasmin 200',
    '350SE',
  ],
  UAZ: [
    '3151',
    'Buhanka',
    'Patriot',
    '469',
    'Hunter',
    'Pickup',
    '3159',
    '31512',
    '452',
    '3303',
    '3909',
    '39625',
    '39094',
    '3962',
    '2206',
    '3163',
    '469B',
    '450',
    '31514',
    '3153',
    '451',
    'Simbir',
    '3164',
    '3741',
    '3160',
    'Simba',
  ],
  Ultima: ['GTR', 'Evolution', 'Sports'],
  UMM: ['Cournil'],
  Unimog: ['435'],
  'Vanden Plas': ['Princess', 'Princess 1300', 'Princess 1100', '1500', 'Princess 4-litre R', 'Princess 3-litre'],
  Vauxhall: [
    'Corsa',
    'Astra',
    'Vivaro',
    'Insignia',
    'VXR8',
    'Vectra',
    'Adam',
    'Zafira',
    'Cavalier',
    'Grandland X',
    'Mokka',
    'Victor',
    'Viva',
    'Nova',
    'Cresta',
    'Meriva',
    'Calibra',
    'Combo',
    'Tigra',
    'VX220',
    'VXR',
    'Monaro',
    'Velox',
    'Crossland X',
    'Carlton',
    'Chevette',
    'Movano',
    'Firenza',
    'Antara',
    'Ampera',
    'VX4/90',
    'Astravan',
    'Cascada',
    'Wyvern',
    'Omega',
    'Signum',
    'Lotus Carlton',
    'Frontera',
    'Ventora',
    'Agila',
    '30-98',
    'Senator',
    'Vx',
    'Magnum',
    'Chevette HSR',
    'Brava',
    'Speedster',
    'Chevette HS',
    'Royale',
    'Monterey',
    'Belmont',
    'E-type',
    'Sintra',
    '20-60',
    '12',
    'Viceroy',
    'Light Six',
    'D-Type',
    '14-6',
    'Cadet',
    'Ellesmere Port',
    '14-40',
    'VX2300',
    'Viscount',
    'Prince Henry',
    'Astramax',
    '10-4',
    'Big Six',
    '23-60',
    'Arena',
    'Rascal',
    'VX1800',
    'H Type',
    'A-Type',
    'Midi',
    '12-4',
    'HP Firenza',
  ],
  VAZ: [
    '2109',
    '2104',
    '2107',
    '21011',
    '2106',
    '2101',
    'Priora',
    '2108',
    '2103',
    '2112',
    '2170',
    '2110',
    '2105',
    '2115',
    'Zhiguli',
    '2113',
    '2121',
    'Kalina',
    '2114',
    '21099',
    'AvtoVAZ',
    '2102',
    '21061',
    '21063',
    '2111',
    '2171',
    '21111',
    '1111 Oka',
    '21091',
    '2131',
    'Oka',
    '2172',
    '21123',
    '21093',
    '21083',
    '21053',
    '21074',
    '1119',
    '21081',
    '21051',
    '21013',
    '2329',
    '1111',
    '21213',
    '2119',
    '21106',
    '21124',
    'Granta',
    '21214',
    'Largus',
    'Vesta',
    '2116',
    '2123',
    '2120',
    '21103',
    '4x4',
    '2117',
  ],
  Vector: ['W8', 'M12'],
  Vencer: ['Sarthe'],
  Venturi: ['400', 'Fetish', 'Atlantique', 'Eclectic', 'Astrolab', 'LC92', '260'],
  Venucia: ['T90', 'T70', 'R30', 'D60', 'M50V', 'T60'],
  Vespa: ['Vespacar'],
  Volkswagen: [
    'Golf',
    'Passat',
    'Polo',
    'Beetle',
    'Tiguan',
    'Touareg',
    'Touran',
    'Jetta',
    'Caddy',
    'Transporter',
    'Up',
    'Scirocco',
    'Caravelle',
    'Sharan',
    'Crafter',
    'Amarok',
    'Multivan',
    'Rabbit',
    'Fox',
    'New Beetle',
    'Golf Plus',
    'Passat CC',
    'CC',
    'Phaeton',
    'Eos',
    'Kombi',
    'Lupo',
    'Bora',
    'Maggiolino',
    'Coccinelle',
    'Voyage',
    'T5',
    'Passat (B5)',
    'Gol',
    'California',
    'SpaceFox',
    'Karmann Ghia',
    'T4',
    'Corrado',
    'Bus',
    'Saveiro',
    'Kaefer',
    'Arteon',
    'Kafer',
    'Vento',
    'Transporter (T5)',
    'Transporter (T6)',
    'Golf Mk7',
    'Passat (B2)',
    'LT',
    'T1',
    'Golf Mk1',
    'Escarabajo',
    'Golf Mk4',
    'Golf Mk2',
    'Passat (B6)',
    '1303',
    'Santana',
    'T2',
    'T3',
    'T-ROC',
    'Golf Mk6',
    'Brasilia',
    'XL1',
    'E-Golf',
    'CrossPolo',
    'Type 2',
    'Golf Mk3',
    'Polo R WRC',
    'Parati',
    'Golf Mk5',
    'Westfalia',
    'Thing',
    'Atlas',
    'Type 3',
    'Transporter (T4)',
    'T6',
    'SP2',
    'CrossTouran',
    'Carat',
    'T-Cross',
    'Vanagon',
    'Buggy',
    'Pointer',
    'Passat (B8)',
    'Porsche 914',
    'Taigun',
    'Quantum',
    'CrossGolf',
    'Routan',
    'Passat B3',
    '1600',
    'e-up!',
    'W12',
    'I.D. R',
    'K70',
    'Golf Estate',
    'Passat (B4)',
    'cross up!',
    'Campmobile',
    'Iltis',
    'Safari',
    '1200',
    'Polo Playa',
    '1302',
    'Schwimmwagen',
    '411',
    'Caribe',
    'Derby',
    'Lamando',
    'Polo G40',
    'Bulli',
    '1500',
    'Atlantic',
    'Polo Mk1',
    '181',
    '412',
    'Taro',
    'Gacel',
    'Citi Golf',
    'Lavida',
    'Dasher',
    'Polo Mk2',
    'Polo Mk3',
    '1300',
    'Passat (B1)',
    'Westfalia Camper',
    'Tharu',
    'Apollo',
    'SP',
    'New Beetle Convertible',
    'VW 181',
    'Type 4',
    'Jetta (A2)',
    'Polo Mk4',
    'Ameo',
    'Tayron',
    'Polo Mk5',
    'Passat Lingyu',
    'Corsar',
    'Phideon',
    'Country Buggy',
    'Herbie',
    'Tarek',
    'Variant II',
    'Scooter',
    'Kubelwagen',
    'Concept BlueSport',
    'Logus',
    'Volksbus',
    'Polo GTI R5',
    'Tarok',
    'Milano',
    'Chico',
    'Load Up',
    'ARVW',
    'Porsche 924',
    'Sedric',
    'Type 2 (T3)',
    'Jetta (A1)',
    'I.D. Buzz',
    'I.D. Crozz',
    'CrossBlue',
    'Concept D',
    'Passat (NMS)',
    'EA489 Basistransporter',
    'Wolfsburg Plant',
    'Type 147 Kleinlieferwagen',
    'Student',
    'Beetle (A5)',
    'Adventurewagen',
    'Auto 2000',
    'City Jetta',
    'Concept A',
    'Type 14A (Hebmuller Cabriolet)',
    'BUDD-e',
  ],
  Volvo: [
    'V40',
    'S60',
    'V60',
    'V70',
    'XC90',
    'XC60',
    'S40',
    'V50',
    'Cross Country',
    'C30',
    'S80',
    'XC70',
    'S90',
    '240',
    'Amazon',
    '850',
    'C70',
    '940',
    'P1800',
    '740',
    'XC40',
    'V90',
    'S60 Cross Country',
    'S70',
    'FH',
    'V90 Cross Country',
    'F16',
    '960',
    '244',
    '760',
    '480',
    'PV544',
    '945',
    '245',
    'Duett',
    '144',
    '142',
    '164',
    '340',
    '460',
    'F12',
    '360',
    '440',
    '780',
    'FM',
    '262C',
    '242',
    'FMX',
    '1800',
    '122',
    'F88',
    '264',
    '145',
    'PV444',
    'F89',
    '66',
    'FL',
    'P1900',
    '200 Series',
    'VN',
    '343',
    'F10',
    '300',
    'C303',
    '262',
    'Laplander',
    '140',
    'F86',
    '121',
    '345',
    '700 Series',
    'Titan',
    'PV 36 Carioca',
    '260',
    'FE',
    'FS7',
    '700',
    'OV 4',
    '140 Series',
    'Concept Car',
    '440/460',
    '123',
    'R',
    '265',
    '200',
    '300 Series',
    'PV800',
    '900 Series',
    'Viking',
    'PV 60',
    'V40 (P1)',
    'LCP 2000',
    'PV51',
    'I-Shift',
    '131',
    'Concept Coupe',
    'PV444/544',
    'F85',
    'PV650',
    '900',
    'PV650 Series',
    'Olympian',
    'F10, F12, and F16',
    'V90 XC',
    'F84',
    'PV800 Series',
    'Tundra',
    'Concept Estate',
    'Concept XC Coupe',
    'T6',
    'V8 engine',
    'D5 engine',
    'L340',
  ],
  Vortex: ['Estina', 'Tingo', 'Corda'],
  VUHL: ['5'],
  'W Motors': ['Lykan', 'Fenyr'],
  Wanderer: ['W24', 'W22', 'Puppchen', 'W21'],
  Warszawa: ['223'],
  Wartburg: ['353', '311', '1,3', '313', '312', 'Barkas', 'Knight'],
  Weiwang: ['M20', 'S50', 'M50F', '306'],
  Westfield: ['Megabusa', 'Sport', 'SEight', 'XTR2', 'XI'],
  Wiesmann: ['GT MF5', 'MF 5', 'GT MF 4', 'Roadster MF3', 'Roadster MF5', 'MF 4', 'MF 3', 'Roadster MF4', 'Roadster', 'GT', 'MF 30'],
  Willys: [
    'MB',
    'Jeep Truck',
    'Jeep CJ',
    'Aero',
    'Jeep Station Wagon',
    'Americar',
    'Overland',
    '77',
    'M38A1',
    'M38',
    'Jeep Forward Control',
    'Jeep Gladiator',
    'Jeep FJ',
    'Jeep DJ',
  ],
  Wolseley: [
    'Hornet',
    '1500',
    '6/80',
    'Six',
    '4/44',
    '16/60',
    '15/50',
    '1300',
    '6/90',
    '15/60',
    '6/110',
    '24/80',
    '1100',
    '4/50',
    '14/60',
    '6/99',
    'Ten',
    'Saloon',
    'Wasp',
    'Eight',
  ],
  Wuling: ['Hongguang S', 'Cortez', 'Hongguang', 'Almaz', 'Hongguang S1'],
  Xiali: ['N3', 'N5', 'N7'],
  Yamaha: [
    'Rhino',
    'Grizzly',
    'OX99-11',
    'WaveBlaster',
    'bravo',
    'Grizzly 600',
    'SuperJet',
    'MT-07',
    'FX-1',
    'Fino',
    'TMAX',
    'MT-09',
    'YZ250F',
    'MT-03',
    'Phazer',
    'Raptor 660',
    'YFZ450',
  ],
  Yema: ['T80', 'T70', 'F16', 'F99', 'F12', 'F10', 'Spica'],
  Yugo: ['Florida'],
  Zastava: [
    '750',
    'Yugo',
    '101',
    'Koral',
    '128',
    '1300',
    'Skala',
    '1100',
    '10',
    'Florida',
    '1500',
    '1400 BJ',
    '850',
    '600',
    '125p',
    '620B',
  ],
  ZAZ: [
    '965',
    '1103-Slavuta',
    'Chance',
    'Vida',
    'Zaporozhets',
    'Slavuta',
    'Sens',
    '968',
    'Tavria',
    '1103',
    '968 M',
    '1102',
    'Lanos',
    '966',
    'Forza',
    'Dana',
  ],
  Zender: ['Fact 4'],
  Zenvo: ['ST1', 'TS1 GT', 'TS1'],
  ZiL: ['111', '117', '4104', '114', '41047', '130', '131', '157', 'BTR-152', '4102'],
  Zimmer: ['Golden Spirit'],
  Zotye: [
    'T600',
    'Z100',
    'T700',
    'Hunter',
    'Z300',
    'E200',
    'T500',
    'Z500',
    'SR9',
    'T300',
    'SR7',
    'T600 Coupe',
    'Z700',
    'T600 Sport',
    'Domy X7',
    'Damai X7',
    'Z200',
    'T800',
    '5008',
    '2008',
    'T200',
    'Damai X5',
    'E200 EV',
    'E30',
    'E30 EV',
    'Domy X5',
  ],
  ZX: ['Landmark', 'Admiral', 'Grand Tiger', 'Terralord'],
};
