<div fxLayout="column"
     fxLayoutAlign="start start"
     fxFill
     class="step-schedule-dialog"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Select time</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="space-around start"
    fxFlex=""
    class="dialog-content narrower-input p-r"
  >
    <div fxLayout="column" fxLayoutAlign="start center" class="w-100 child-100" fxLayoutGap="16px">
      <div>Schedule when the alert will be active</div>
      <div fxLayout="column" fxLayoutAlign="start center" class="child-100" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="row" fxFlex="100px"></div>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="">
            <div fxLayout="row"
                 class="hour-label"
                 *ngFor="let hour of hours"
                 [class.black]="hour === '12 AM' || hour === '12 PM'"
            >{{hour}}</div>
          </div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="8px"
             class="day-wrapper p-r"
             *ngFor="let day of schedule; let i = index"
        >
          <div fxLayout="row" fxFlex="46px">
            <ui-toggle [(ngModel)]="day.enabled" (ngModelChange)="onToggleDay(i)">

            </ui-toggle>
          </div>
          <div fxLayout="row" fxFlex="46px">{{nameDayOfWeek[day.day] | slice : 0 : 3 | titlecase }}</div>
          <div #dayWrapper [class.disable]="!day.enabled"
               (mousemove)="setTooltipPosition($event, dayWrapper)"
               (mouseenter)="showTooltip(i)"
               (mouseleave)="hideTooltip()"
               (click)="selectionMenu($event, matTrigger, spanTrigger, dayWrapper, i)"
               fxLayout="row"
               fxLayoutAlign="center center"
               fxFlex=""
               class="day-timeline c-p p-r h-100"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              style="position: fixed; height:1px; width: 1px;"
              #spanTrigger
              [matMenuTriggerFor]="rangePicker"
              #matTrigger="matMenuTrigger"

            ></div>
            <mat-menu #rangePicker="matMenu"
                      xPosition="after"
                      yPosition="below"
                      class="menu-no-padding radius"
                      (close)="resetSelection()"
            >
              <div class="selection-dialog" (click)="$event.stopPropagation();"
              >
                <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                  <div fxLayout="column" class="selection-dialog-content" fxFlex="" fxLayoutGap="24px">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                        <div>From</div>
                        <div>
                          <input class="time-input"
                                 type="time"
                                 [(ngModel)]="currentSelection.from"
                                 (input)="setTimes()"
                                 (keydown.tab)="$event.stopPropagation()"
                          >
                        </div>
                      </div>
                      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                        <div>&nbsp;</div>
                        <div>-</div>
                      </div>
                      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                        <div>To</div>
                        <div>
                          <input class="time-input"
                                 type="time"
                                 [(ngModel)]="currentSelection.to"
                                 (input)="setTimes()"
                          >
                        </div>
                      </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <div>Repeat on</div>
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="repeat"
                             fxLayoutAlign="center center"
                             *ngFor="let day of schedule; let repeatIndex = index"
                             [class.repeat-selected]="inRepeat(repeatIndex)" (click)="toggleRepeat($event, repeatIndex)"
                        >{{nameDayOfWeek[day.day] | slice : 0 : 1 | titlecase}}</div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="selection-dialog-actions">
                    <div>
                      <div *ngIf="selectedRangeIndex || selectedRangeIndex === 0"
                           fxLayoutAlign="center"
                           class="svg-fill-primary icon-16 c-p"
                           (click)="delete(matTrigger)"
                           matTooltip="Delete time slot"
                      >
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                    </div>
                    <ui-button colorBgr="#4367F6"
                               [disabled]="!rangeValid"
                               colorText="white" (clickAction)="selectTimes(matTrigger)" text="Done"
                    ></ui-button>
                  </div>
                </div>
              </div>

            </mat-menu>

            <div fxLayout="column"
                 fxLayoutAlign="center center"
                 class="tooltip-wrapper"
                 *ngIf="tooltipVisible && tooltipIndex === i"
                 [style.left]="tooltipPosition < 1 ? '1%' : (tooltipPosition > 97 ? '97%' : tooltipPosition + '%')"
            >
              <div class="tooltip"
              >{{tooltipTime}}</div>
            </div>
            <div class="tooltip-line"
                 (mousemove)="$event.preventDefault(); $event.stopPropagation();"
                 *ngIf="tooltipVisible && tooltipIndex === i"
                 [style.left]="tooltipPosition + '%'"
            ></div>

            <div *ngFor="let range of day.times; let rangeIndex = index"
                 [style.left]="barOffset(range)"
                 [style.width]="barWidth(range)"
                 (click)="selectionMenu($event, matTrigger, spanTrigger, dayWrapper, i, rangeIndex);"
                 class="time-slot"
                 fxLayoutAlign="start center"
                 [class.selected]="i === selectionIndex && rangeIndex === selectedRangeIndex"
                 #slotWrapper
                 cdkDrag
                 [cdkDragBoundary]="dayWrapper"
                 (cdkDragMoved)="dragSlot(i, rangeIndex, $event, dayWrapper, slotWrapper)"
                 (cdkDragStarted)="dragStart()"
                 (cdkDragEnded)="dragEnd(i)"
            >
              <div class="time-slot-drag left"
                   #leftHandle
                   cdkDrag
                   [cdkDragBoundary]="slotWrapper"
                   cdkDragLockAxis="x"
                   (cdkDragMoved)="dragLeft(i, rangeIndex, $event, dayWrapper, slotWrapper, leftHandle)"
                   (click)="$event.stopPropagation()"
                   (cdkDragStarted)="dragStart()"
                   (cdkDragEnded)="dragEnd(i, leftHandle)"
              ></div>
              <ui-truncated-html class="w-100" [disableTooltip]="!!drag && !!dragStartFrom" tooltipPosition="below">
                {{range.from}} - {{range.to}}
              </ui-truncated-html>
              <div class="time-slot-drag right"
                   #rightHandle
                   cdkDrag
                   [cdkDragBoundary]="slotWrapper"
                   cdkDragLockAxis="x"
                   (cdkDragMoved)="dragRight(i, rangeIndex, $event, dayWrapper, slotWrapper, rightHandle)"
                   (click)="$event.stopPropagation()"
                   (cdkDragStarted)="dragStart()"
                   (cdkDragEnded)="dragEnd(i, rightHandle)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <div class="reset icon-16" (click)="reset()" fxLayoutAlign="center center">
      <mat-icon svgIcon="reset"></mat-icon>
    </div>
    <ui-button colorBgr="#4367F6" colorText="white" text="Save" (clickAction)="save()"></ui-button>
  </div>

</div>


