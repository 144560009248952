import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { routerSegments } from '@consts/routes';
import { UserSelectors } from '@states/user/user.selector-types';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DeveloperGuard  {
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));

  constructor(private store$: Store<AppState>, private router: Router,
              private route: ActivatedRoute) {
  }

  public canActivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.selectIsDeveloper$.pipe(
      untilDestroyed(this),
      map((res: boolean) => {
        if (!res) {
          this.router.navigate([routerSegments.notFound], { relativeTo: this.route, skipLocationChange: true });
        }
        return res;
      }),
    );
  }
}
