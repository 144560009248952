<div>
  <div>
    <mat-label>Timezone</mat-label>
    <mat-select
      class="ui-selector"
      style="width: 200px; display: block"
      [(ngModel)]="currentTimezone"
      (ngModelChange)="setTimezone($event)"
    >
      <mat-option [value]="zone" *ngFor="let zone of timeZones">{{ zone }}</mat-option>
    </mat-select>
  </div>
</div>
