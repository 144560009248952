import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { YoutubeModel } from '@models/youtube.model';
import { YoutubeActions } from '@states/youtube/youtube.action-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { sortArrByField } from '../../../helpers/common.helpers';

export interface YoutubeState extends EntityState<YoutubeModel.YoutubeMongoDocument> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed
  isSaving: boolean;

  toRemove: Dictionary<boolean>;
}

export const youtubeAdapter: EntityAdapter<YoutubeModel.YoutubeMongoDocument> = createEntityAdapter<YoutubeModel.YoutubeMongoDocument>({
  selectId: (document: YoutubeModel.YoutubeMongoDocument) => document._id,
});


const initialState: YoutubeState = youtubeAdapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,
  isSaving: false,
  toRemove: {},
});


export const youtubeStateReducer = createReducer(
  initialState,
  on(YoutubeActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(YoutubeActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(YoutubeActions.getYoutubeSuccess, (state, { documents }) => {
    return youtubeAdapter.setAll(documents, {
      ...state,
      initialLoaded: true,
      notEmpty: !!documents.length,
    });
  }),
  on(YoutubeActions.deleteYoutubeById, (state, { id }) => {
    return {
      ...state,
      toRemove: {
        ...state.toRemove,
        [id]: true,
      },
    };
  }),
  on(YoutubeActions.deleteYoutubeByIdSuccess, (state, { id }) => {
    const toRemove = { ...state.toRemove };
    delete toRemove[id];
    return youtubeAdapter.removeOne(id, {
      ...state,
      toRemove,
    });
  }),
  on(YoutubeActions.deleteYoutubeByIdFail, (state, { id }) => {
    const toRemove = { ...state.toRemove };
    delete toRemove[id];
    return {
      ...state,
      toRemove,
    };
  }),
  on(YoutubeActions.serverRequestCreateYoutubeSuccess, (state, { document }) => {
    const documents = {
      ...state.entities,
      [document._id]: document,
    };
    const documentArray = Object.values(documents ?? {});
    const sortedAlerts = sortArrByField<YoutubeModel.YoutubeMongoDocument>(documentArray, 'createdAt', 'desc');
    return youtubeAdapter.setAll(sortedAlerts, {
      ...state,
    });
  }),
);

