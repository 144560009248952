import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[CAMERA-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetCameraPulsationStatus = createAction('[CAMERA-HEARTBEAT-PULSATION] GetCameraPulsationStatus');

export const GetCameraPulsationStatusSuccess = createAction('[CAMERA-HEARTBEAT-PULSATION] GetCameraPulsationStatusSuccess',
    props<{
        payload: PulsationModels.Store.CameraPulsation[]
    }>());

export const GetCameraPulsationStatusFail = createAction('[CAMERA-HEARTBEAT-PULSATION] GetCameraPulsationStatusFail', props<{ message?: string }>());

