import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PreloaderColor, UiButtonStyle } from '@enums/shared.enum';

const buttonTypes = {
  default: {
    bg: 'var(--button-bg-default)',
    color: 'var(--text-default)',
    border: 'var(--color-secondary-gray-400)',
  },
  primary: {
    bg: 'var(--button-bg-primary)',
    color: 'var(--button-text-primary)',
    border: '#CFD8FA',
  },
  secondary: {
    bg: 'var(--button-bg-secondary)',
    color: 'var(--text-color-text-primary)',
    border: 'var(--button-border-primary)',
  },
};

@Component({
  selector: 'ui-progress-button',
  templateUrl: './ui-progress-button.component.html',
  styleUrls: ['./ui-progress-button.component.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'firsLoad',
        style({
          opacity: 1,
        }),
      ),
      state(
        'in',
        style({
          opacity: 1,
        }),
      ),
      transition('void => in', [animate('150ms ease-in')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiProgressButtonComponent implements OnInit, AfterViewInit {
  @Input() loading: boolean = false;
  @Input() icon: string;
  @Input() text1: string = '';
  @Input() text2: string = '';
  @Input() disabled: boolean = false;
  @Input() color: UiButtonStyle = UiButtonStyle.default;
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Input() colorBgr = '';
  @Input() colorBorder = '';
  @Input() colorText = '';
  @Input() preloaderColor = PreloaderColor.white;
  @Input() type: string;
  @Input() size: string = 'default';

  public showText2Subject = new BehaviorSubject<boolean>(false);
  public showText2State = this.showText2Subject.asObservable();
  private showText2Timeout: ReturnType<typeof setTimeout>;

  public uiButtonStyles = UiButtonStyle;
  private firstLoad: boolean;

  constructor(public elRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.type) {
      this.elRef.nativeElement.style.backgroundColor = buttonTypes[this.type].bg;
      this.elRef.nativeElement.firstChild.lastChild.style.color = buttonTypes[this.type].color;
      this.elRef.nativeElement.style.borderColor = buttonTypes[this.type].border;
    }
  }

  ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.firstLoad = changes['loading']?.firstChange;
    if (changes['loading']?.previousValue === true && changes['loading']?.currentValue === false) {
      this.showText2Subject.next(true);
      clearTimeout(this.showText2Timeout);
      this.showText2Timeout = setTimeout(() => {
        this.showText2Subject.next(false);
      }, 1500);
    }
  }

  public click(): void {
    if (!this.loading && !this.disabled) {
      this.clickAction.emit();
    }
  }

  public get animState(): string {
    return this.firstLoad ? 'firsLoad' : 'in';
  }
}
