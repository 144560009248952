import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { FitAddon } from 'xterm-addon-fit';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import * as SharedActions from '@states/shared/shared.actions';
import * as TerminalActions from '@states/terminal/terminal.actions';
import { SessionDataAction } from '@enums/session-data.enum';
import { SharedEffects } from '@effects/shared.effects';
import { Observable, map, tap } from 'rxjs';
import * as TerminalSelectors from '@states/terminal/terminal.selectors';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { LocationSelectors } from '@states/location/location.selector-types';
import { TerminalEffect } from '@effects/terminal.effect';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { EdgeCamera } from 'src/app/cameras/camera.model';
import * as minimist from 'minimist';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { AppUser } from 'src/app/user/user.model';
import { ActiveOrganization, OrganizationUser } from '@models/organization.model';
import { EdgeStatusService } from 'src/app/edge/edge-status.service';
import { OperatorService } from '../../../operator/operator.service';
import { TerminalState } from '@states/terminal/terminal.reducer';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { UserSelectors } from '@states/user/user.selector-types';
import { DeviceStatusSelectors } from '@states/device-status/device-status.selector-types';
import { Dictionary } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';

import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;

const SPACE = '                              ';
const TERMINAL_PREFIX = '$ ';

const TERMINAL_KEY_CODES = {
  ARROW_UP: '\x1B[A',
  ARROW_DOWN: '\x1B[B',
  ARROW_LEFT: '\x1b[D',
  ARROW_RIGHT: '\x1b[C',
  CTRL_A: '\u0001',
  CTRL_C: '\u0003',
  BACKSPACE: '\u007F',
  ENTER: '\r',
};

const TERMINAL_KEY_INPUT = {
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
};

export const TERMINAL_COLORS = {
  Red: '\x1b[1;31m',
  Green: '\x1b[1;32m',
  Yellow: '\x1b[1;33m',
  Purple: '\x1b[1;35m',
  Reset: '\x1b[0m',
};

@UntilDestroy()
@Component({
  selector: 'ui-terminal',
  templateUrl: './ui-terminal.component.html',
  styleUrls: ['./ui-terminal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTerminalComponent implements OnInit, AfterViewInit {
  @ViewChild('xterm') xterm: ElementRef;

  private baseTheme = {
    foreground: '#F8F8F8',
    background: '#2D2E2C',
    selection: '#5DA5D533',
    black: '#1E1E1D',
    brightBlack: '#262625',
    red: '#CE5C5C',
    brightRed: '#FF7272',
    green: '#5BCC5B',
    brightGreen: '#72FF72',
    yellow: '#CCCC5B',
    brightYellow: '#FFFF72',
    blue: '#5D5DD3',
    brightBlue: '#7279FF',
    magenta: '#BC5ED1',
    brightMagenta: '#E572FF',
    cyan: '#5DA5D5',
    brightCyan: '#72F0FF',
    white: '#F8F8F8',
    brightWhite: '#FFFFFF',
  };

  public selectContext$: Observable<TerminalState> = this.store$.pipe(select(TerminalSelectors.selectContext));

  public selectAllCameras$: Observable<any[]> = this.store$.pipe(select(CameraSelectors.selectAllCameras));

  public selectAllEdges$: Observable<any[]> = this.store$.pipe(select(EdgeSelectors.selectAllEdges));

  public selectAllLocations$: Observable<any[]> = this.store$.pipe(select(LocationSelectors.selectAllLocations));

  public selectActiveOrganization$: Observable<any> = this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization));

  public selectOrganizationUsers$: Observable<OrganizationUser[]> = this.store$.pipe(select(OrganizationSelectors.selectOrganizationUsers));

  public selectUser$: Observable<AppUser.User> = this.store$.pipe(select(UserSelectors.selectUser));

  public selectIdToken$: Observable<string> = this.store$.pipe(select(AuthenticationSelectors.idToken));

  public selectEdgeStatus$: Observable<Dictionary<ComponentStatusDisplay>> = this.store$.pipe(select(DeviceStatusSelectors.selectEdgeStatus));

  public term: Terminal;
  private command = '';
  private commandsHistory: string[] = [];
  private commandCounter: number = 0;
  private terminalCommands = {
    probe: {
      start: (argv): void => {
        const { ...params } = argv;

        // combineLatest(
        //   [
        //     this.store$.pipe(select(state => state.terminalState))
        //   ]
        // )
        //   .pipe(
        //     switchMap(([{ locationId, edgeId }]) => {

        //       if ((!params || !params['edgeId']) && (!edgeId || !locationId)) {
        //         this.term.writeln(`${TERMINAL_COLORS.Red}${'CoreId and location Id is not set. please set context or provide --edgeId parameter'}${TERMINAL_COLORS.Reset}`);
        //         this.prompt();
        //         return of()
        //       }

        //       edgeId = !!params && params['edgeId'] ? params['edgeId'] as string : edgeId;

        //       return this.edgeStatusService.getEdgeLatestStatusFirestore(edgeId)
        //         .pipe(
        //           take(1),
        //           tap(res => {
        //             if (typeof res === 'object') {
        //               this.term.writeln([JSON.stringify(res)].join('\n\r'));
        //             } else {
        //               const dataArray = (res as string)?.toString().split('\n');
        //               for (let i in dataArray) {
        //                 this.term.writeln(dataArray[i]);
        //               }
        //             }
        //             this.prompt();
        //           })
        //         )

        //     })
        //   )
        //   .subscribe()

        if ((!params || !params['edgeId'])) {

          this.term.writeln(`${TERMINAL_COLORS.Red}${'CoreId is not set. please provide --edgeId parameter'}${TERMINAL_COLORS.Reset}`);
          this.prompt();
          return;
        }


        this.selectEdgeStatus$
          .pipe(
            map(res => res?.[params['edgeId']]),
            tap(res => {
              if (typeof res === 'object') {
                this.term.writeln([JSON.stringify(res)].join('\n\r'));
              } else {
                const dataArray = (res as string)?.toString()
                  .split('\n');
                for (let i in dataArray) {
                  this.term.writeln(dataArray[i]);
                }
              }
              this.prompt();
            }),
          )
          .subscribe();


      },
      description: 'Check edge status Ex: probe --edgeId={number}',
    },
    manage: {
      start: (argv): void => {
        const { msgType, msTimeout, ...params } = argv;
        this.store$.dispatch(TerminalActions.manage({ action: msgType as number, params, msTimeout }));
      },
      description: 'Call manage request Ex: manage --msgType={number} --key=value',
    },
    dispatch: {
      start: (argv): void => {
        const { msgType, msTimeout, ...params } = argv;
        this.store$.dispatch(TerminalActions.dispacth({ action: msgType as number, params, msTimeout }));
      },
      description: 'Call manage request Ex: manage --msgType={number} --key=value',
    },
    storage: {
      start: (argv): void => {
        for (let i of argv?._) {
          switch (i) {
            case 'upload':
              let uploadLine = `${this.setColor(TERMINAL_COLORS.Red, 'not supported')}\n\r`;
              this.term.writeln(uploadLine);
              break;
            case 'list':
              this.store$.dispatch(EdgeEditActions.setSelectedEdgeId({ selectedEdgeId: this.context.edgeId }));
              this.store$.dispatch(EdgeEditActions.setSelectedLocationId({ selectedLocationId: this.context.locationId }));

              const { dirPattern, cameraId, msTimeout } = argv;
              this.store$.dispatch(EdgeEditActions.listVideoStorage({ dirPattern, cameraId }));

              break;
            default:
              this.term.writeln(this.setColor(TERMINAL_COLORS.Yellow, `unknown argument ${i}`));
          }
        }
      },
      description: 'Call edge storage request Ex: storage --msgType={number} --key=value',
    },
    organization: {
      start: argv => {
        for (let i of argv?._) {
          switch (i) {
            case 'users':
              let line = '[';

              for (const user of this.organizationUsers) {
                line += `{`;
                line += ` \f${this.setColor(TERMINAL_COLORS.Red, 'userId')}: ${this.setColor(TERMINAL_COLORS.Green, user.id)}\n\r`;
                line += `   \f${this.setColor(TERMINAL_COLORS.Red, 'email')}: ${this.setColor(TERMINAL_COLORS.Green, user.email)}\n\r`;
                line += `   \f${this.setColor(TERMINAL_COLORS.Red, 'roles')}: ${this.setColor(TERMINAL_COLORS.Green, user.roles)}\n\r`;

                line += `},\n\r`;
              }

              line += ']\n\r';
              this.term.writeln(line);
              break;
            default:
              this.term.writeln(this.setColor(TERMINAL_COLORS.Yellow, `unknown argument ${i}`));
          }
        }
        this.prompt();
      },
      description: 'Set value to terminal storage. Ex. set --edge = <edgeIdString>',
    },
    clean: {
      start: () => {
        this.term.clear();
        this.term.write('\r\n$ ');
        this.command = '';
      },
      description: 'Clean the terminal',
    },
    clear: {
      start: () => {
        this.term.clear();
        this.term.write('\r\n$ ');
        this.command = '';
      },
      description: 'Clean the terminal',
    },
    help: {
      start: () => {
        this.term.writeln(
          [
            'Try some of the commands below.',
            '',
            ...Object.keys(this.terminalCommands)
              .map(e => `  ${e.padEnd(10)} ${this.terminalCommands[e].description}`),
          ].join('\n\r'),
        );
        this.prompt();
      },
      description: 'Print all existing commands',
    },
    set: {
      start: argv => {
        for (let i in argv) {
          switch (i) {
            case '_':
              break;
            case 'edge':
              this.store$.dispatch(TerminalActions.setEdgeId({ edgeId: argv[i] }));
              break;
            case 'location':
              this.store$.dispatch(TerminalActions.setLocationIdTerminal({ locationId: argv[i] }));
              break;
            default:
              this.term.writeln(this.setColor(TERMINAL_COLORS.Yellow, `unknown argument ${i}`));
          }
        }
        this.prompt();
      },
      description: 'Set value to terminal storage. Ex. set --edge = <edgeIdString>',
    },
    getContext: {
      start: () => {
        for (let i in this.context) {
          this.term.writeln([`${i}:${this.context[i]}`].join('\n\r'));
        }
        this.prompt();
      },
      description: 'Display all set values from terminal storage',
    },
    whoami: {
      start: () => {
        let line = '{';

        (line += ` \f${this.setColor(TERMINAL_COLORS.Red, 'userId')}: ${this.setColor(TERMINAL_COLORS.Green, this.user._id)}\n\r`),
          (line += `  \f${this.setColor(TERMINAL_COLORS.Red, 'email')}: ${this.setColor(TERMINAL_COLORS.Green, this.user.email)}\n\r`),
          (line += `  \f${this.setColor(TERMINAL_COLORS.Red, 'organizationId')}: ${this.setColor(
            TERMINAL_COLORS.Green,
            this.activeOrganization.orgId,
          )}\n\r`),
          (line += `  \f${this.setColor(TERMINAL_COLORS.Red, 'organizationName')}: ${this.setColor(
            TERMINAL_COLORS.Green,
            this.activeOrganization.orgName,
          )}\n\r`),
          (line += `  \f${this.setColor(TERMINAL_COLORS.Red, 'organizationRoles')}: ${this.setColor(
            TERMINAL_COLORS.Green,
            this.activeOrganization.roles,
          )}\n\r`),
          (line += `  \f${this.setColor(TERMINAL_COLORS.Red, 'token')}: ${this.setColor(TERMINAL_COLORS.Green, this.idToken)}\n\r`),
          (line += '}');
        this.term.writeln(line);
        this.prompt();
      },
      description: 'Display information about current user',
    },
    ls: {
      start: argv => {
        for (let i of argv?._) {
          switch (i) {
            case 'camera':
              this.term.writeln([`ID${SPACE}NAME`].join('\n\r'));
              for (let camera in this.cameraList) {
                const tmpCamera = this.cameraList[camera].edgeOnly;
                const resSpace = this.spaceLength(`ID${SPACE}`.length - `${tmpCamera.cameraId}`.length);
                const line = [`${tmpCamera.cameraId}${resSpace}${tmpCamera.name}`].join('\n\r');
                this.term.writeln(this.setColor(TERMINAL_COLORS.Green, line));
              }
              break;
            case 'edge':
              this.term.writeln([`ID${SPACE}NAME`].join('\n\r'));
              for (let edge in this.edgeList) {
                const tmpEdge = this.edgeList[edge];
                const resSpace = this.spaceLength(`ID${SPACE}`.length - `${tmpEdge.edgeId}`.length);
                const line = [`${tmpEdge.edgeId}${resSpace}${tmpEdge.name}`].join('\n\r');
                this.term.writeln(this.setColor(TERMINAL_COLORS.Green, line));
              }
              break;
            case 'location':
              this.term.writeln([`ID${SPACE}NAME`].join('\n\r'));
              for (let location in this.locationList) {
                const tmpLocation = this.locationList[location];
                const resSpace = this.spaceLength(`ID${SPACE}`.length - `${tmpLocation._id}`.length);
                const line = [`${tmpLocation._id}${resSpace}${tmpLocation.name}`].join('\n\r');
                this.term.writeln(this.setColor(TERMINAL_COLORS.Green, line));
              }
              break;
            default:
              this.term.writeln(`${i}: is incorrect parameter`);
          }
        }
        this.prompt();
      },
      description: 'Display list of entities (cameras, edges, locations). Available commands: <ls camera > <ls edge>, <ls location>',
    },
    getUnitEdge: {
      start: argv => {
        for (let i in argv) {
          switch (i) {
            case '_':
              break;
            case 'edge':
              this.store$.dispatch(TerminalActions.manage({
                action: undefined, params: {
                  edgeId: argv[i],
                  ecmd: 'get-edge-doc',
                  doc: 'edge',
                }, msTimeout: 15000,
              }));
              break;
            default:
              this.term.writeln(this.setColor(TERMINAL_COLORS.Yellow, `unknown argument ${i}`));
          }
        }
        this.prompt();
      },
      description: 'Display unit edge. Ex. getUnitEdge --edge = <edgeIdString>',
    },
    getCloudEdge: {
      start: argv => {

        for (let i in argv) {
          switch (i) {
            case '_':
              break;
            case 'edge':
              this.operatorService.getEdge({ edgeId: argv[i] })
                .pipe(
                  tap(res => {
                    if (typeof res === 'object') {
                      this.term.writeln([JSON.stringify(res)].join('\n\r'));
                    } else {
                      const dataArray = (res as string)?.toString()
                        .split('\n');
                      for (let i in dataArray) {
                        this.term.writeln(dataArray[i]);
                      }
                    }
                    this.prompt();
                  }),
                )
                .subscribe();
              break;
            default:
              this.term.writeln(this.setColor(TERMINAL_COLORS.Yellow, `unknown argument ${i}`));
          }
        }
        this.prompt();
      },
      description: 'Display cloud edge. Ex. getCloudEdge --edge = <edgeIdString>',
    },
  };

  private context: TerminalState;
  private cameraList: EdgeCamera.CameraItem[] = [];
  private edgeList = [];
  private locationList = [];
  private activeOrganization: ActiveOrganization;
  private organizationUsers: OrganizationUser[];
  private user: AppUser.User;
  private idToken: string;

  constructor(
    private edgeStatusService: EdgeStatusService,
    private store$: Store<AppState>,
    private sharedEffects: SharedEffects,
    private terminalEffect: TerminalEffect,
    private operatorService: OperatorService) {
  }

  ngOnInit(): void {
    this.sharedEffects.getSessionData$.pipe(untilDestroyed(this), ofType(SharedActions.getSessionDataSuccess))
      .subscribe(res => {
        switch (res.sessionDataAction) {
          case SessionDataAction.manageAction:
            if (typeof res.payload.result.data === 'object') {
              this.term.writeln([JSON.stringify(res.payload.result)].join('\n\r'));
            } else {
              const dataArray = res?.payload?.result?.data.split('\n');
              for (let i in dataArray) {
                this.term.writeln(dataArray[i]);
              }
            }
            this.prompt();
            break;
          case SessionDataAction.dispatch:
            // let data = '';

            // if (typeof res.payload.result.data === 'object') {
            //   // data = JSON.stringify(res.payload.result.data, undefined, 1);
            //   data = JSON.stringify(res.payload.result, undefined, 1);
            // }

            // const dataArray = data.split('\n');
            // for (let i in dataArray) {
            //   this.term.writeln(dataArray[i]);
            // }

            let result = JSON.stringify(res.payload.result, undefined, 1);

            if (navigator.clipboard) {
              navigator.clipboard.writeText(result);
            }

            const dataArray = result.split('\n');
            for (let i in dataArray) {
              this.term.writeln(dataArray[i]);
            }
            // setColor

            this.prompt();
            break;
          case SessionDataAction.UploadVS:
          case SessionDataAction.ListVS:
            if (typeof res.payload.result.data === 'object') {
              const files = res.payload.result.data.files;
              // this.term.writeln([JSON.stringify(res.payload.result.data)].join('\n\r'));

              for (let file in files) {
                const tmpFile = JSON.stringify(files[file]);
                const line = tmpFile.concat('\n\r');
                this.term.writeln(this.setColor(TERMINAL_COLORS.Green, line));
              }
            } else {
              const dataArray = res?.payload?.result?.data.split('\n');
              for (let i in dataArray) {
                this.term.writeln(dataArray[i]);
              }
            }

            this.prompt();
            break;
        }
      });

    this.sharedEffects.getSessionStatusFailed$.pipe(untilDestroyed(this), ofType(SharedActions.getSessionStatusFailed))
      .subscribe(res => {
        switch (res.sessionDataAction) {
          case SessionDataAction.manageAction:
            this.term.writeln(`\x1b[31;1m${res.err}\x1b[0m`);
            this.prompt();
            break;
        }
      });

    this.selectContext$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.context = res;
      });

    this.selectAllCameras$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.cameraList = res;
      });

    this.selectAllEdges$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.edgeList = res;
      });

    this.selectAllLocations$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.locationList = res;
      });

    this.selectActiveOrganization$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.activeOrganization = res;
      });

    this.selectOrganizationUsers$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.organizationUsers = res;
      });

    this.selectUser$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.user = res;
      });

    this.selectIdToken$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.idToken = res;
      });

    this.terminalEffect.setCommandFail$.pipe(untilDestroyed(this), ofType(TerminalActions.setCommandFailError))
      .subscribe(res => {
        this.term.writeln(`${TERMINAL_COLORS.Red}${res.msg}${TERMINAL_COLORS.Reset}`);
        this.prompt();
      });
  }

  public ngAfterViewInit(): void {
    this.term = new Terminal({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      theme: this.baseTheme,
      cursorBlink: true,
    });

    // this.addDecoration(term);
    const fitAddon = new FitAddon();
    const weblinkAddon = new WebLinksAddon();
    this.term.loadAddon(fitAddon);
    this.term.loadAddon(weblinkAddon);

    this.term.open(this.xterm.nativeElement);
    fitAddon.fit();

    this.term.writeln(['Welcome to Terminal! Try to type help to see available command list.'].join('\n\r'));
    this.term.write('$ ');

    // this.term.onKey(e => {

    //   const { key } = e.domEvent

    //   const totalOffsetLength = this.command.length + TERMINAL_PREFIX.length
    //   const currentOffsetLength = this.term['_core'].buffer.x
    //   const command = e.key;

    //   switch (key) {
    //     case TERMINAL_KEY_INPUT.ARROW_LEFT:

    //       if (currentOffsetLength > TERMINAL_PREFIX.length) {
    //         this.term.write(command) // '\x1b[D'
    //       }
    //       break;

    //     case TERMINAL_KEY_INPUT.ARROW_RIGHT:
    //       if (currentOffsetLength < totalOffsetLength && currentOffsetLength < this.command.length) {
    //         this.term.write(command) // '\x1b[C'
    //       }
    //       break

    //     case TERMINAL_KEY_INPUT.BACKSPACE:

    //       if (currentOffsetLength > TERMINAL_PREFIX.length) {
    //         const cursorOffSetLength = this.getCursorOffsetLength(totalOffsetLength - currentOffsetLength, TERMINAL_KEY_CODES.ARROW_LEFT)

    //         this.term['_core'].buffer.x = currentOffsetLength - 1

    //         const cursorShift = `\x1b[?K${this.command.slice(currentOffsetLength - TERMINAL_PREFIX.length)}`

    //         this.term.write(cursorShift);

    //         this.command = `${this.command.slice(0, currentOffsetLength - TERMINAL_PREFIX.length - 1)}${this.command.slice(currentOffsetLength - TERMINAL_PREFIX.length)}`

    //       }
    //       break
    //     default:

    //       if (totalOffsetLength >= this.term.cols) {
    //         break;
    //       }

    //       if (currentOffsetLength >= totalOffsetLength) {
    //         this.term.write(command)
    //         this.command += command;
    //         break
    //       }

    //       const cursorOffSetLength = this.getCursorOffsetLength(totalOffsetLength - currentOffsetLength, TERMINAL_KEY_CODES.ARROW_LEFT)
    //       this.term.write('\x1b[?K' + `${command}${this.command.slice(currentOffsetLength - TERMINAL_PREFIX.length)}`)
    //       this.term.write(cursorOffSetLength)
    //       this.command = this.command.slice(0, currentOffsetLength) + command + this.command.slice(totalOffsetLength - currentOffsetLength)

    //       break;
    //   }

    // })

    this.term.onData(e => {
      const totalOffsetLength = this.command.length + TERMINAL_PREFIX.length;
      const currentOffsetLength = this.term['_core'].buffer.x;
      const cursorOffSetLength = this.getCursorOffsetLength(totalOffsetLength - currentOffsetLength, TERMINAL_KEY_CODES.ARROW_LEFT);

      switch (e) {
        case TERMINAL_KEY_CODES.ARROW_UP:
          if (this.commandCounter) {
            this.commandCounter--;
          }
          this.command = this.commandsHistory[this.commandCounter] ?? ''; //can be undefined if history is empty
          this.term.write('\x1b[2K\r$ ');
          this.term.write(this.command);
          break;
        case TERMINAL_KEY_CODES.ARROW_DOWN:
          if (this.commandCounter < this.commandsHistory.length - 1) {
            this.commandCounter++;
          }
          this.command = this.commandsHistory[this.commandCounter] ?? ''; //can be undefined if history is empty
          this.term.write('\x1b[2K\r$ ');
          this.term.write(this.command);
          break;

        case TERMINAL_KEY_CODES.ARROW_LEFT:
          if (currentOffsetLength > TERMINAL_PREFIX.length) {
            this.term.write(e); // '\x1b[D'
          }
          break;

        case TERMINAL_KEY_CODES.ARROW_RIGHT:
          if (currentOffsetLength <= totalOffsetLength && currentOffsetLength <= this.command.length + 1) {
            this.term.write(e); // '\x1b[C'
          }
          break;

        case TERMINAL_KEY_CODES.CTRL_A:
          return;
        case TERMINAL_KEY_CODES.CTRL_C:
          this.term.write(this.setColor(TERMINAL_COLORS.Red, '^Canceled'));
          this.prompt();
          this.store$.dispatch(EdgeEditActions.cancelManageRequest());
          break;
        case TERMINAL_KEY_CODES.ENTER:
          this.commandsHistory.push(this.command);
          this.commandCounter = this.commandsHistory.length;
          this.runCommand(this.command);
          break;
        case TERMINAL_KEY_CODES.BACKSPACE:
          if (currentOffsetLength > TERMINAL_PREFIX.length) {
            const cursorOffSetLength = this.getCursorOffsetLength(totalOffsetLength - currentOffsetLength, TERMINAL_KEY_CODES.ARROW_LEFT);
            this.term['_core'].buffer.x = currentOffsetLength - 1;
            const cursorShift = `\x1b[?K${this.command.slice(currentOffsetLength - TERMINAL_PREFIX.length)}`;
            this.term.write(cursorShift);
            this.term.write(cursorOffSetLength);
            this.command = `${this.command.slice(0, currentOffsetLength - TERMINAL_PREFIX.length - 1)}${this.command.slice(
              currentOffsetLength - TERMINAL_PREFIX.length,
            )}`;
          }
          break;

        default:
          if (totalOffsetLength >= this.term.cols) {
            break;
          }

          if (currentOffsetLength >= totalOffsetLength) {
            this.term.write(e);
            this.command += e;
            break;
          }

          const cursorOffSetLength = this.getCursorOffsetLength(totalOffsetLength - currentOffsetLength, TERMINAL_KEY_CODES.ARROW_LEFT);
          this.term.write('\x1b[?K' + `${e}${this.command.slice(currentOffsetLength - TERMINAL_PREFIX.length)}`);
          this.term.write(cursorOffSetLength);

          const start = this.command.slice(0, currentOffsetLength - TERMINAL_PREFIX.length);
          const end = this.command.slice(currentOffsetLength - totalOffsetLength);
          this.command = start + e + end;

          break;
      }
    });
    this.term.focus();
  }

  private getCursorOffsetLength(offsetLength, subString = '') {
    let cursorOffsetLength = '';
    for (let offset = 0; offset < offsetLength; offset++) {
      cursorOffsetLength += subString;
    }

    return cursorOffsetLength;
  }

  private spaceLength(len: number): string {
    let space = '';
    for (let i = 0; i < len; i++) {
      space += ' ';
    }
    return space;
  }

  private setColor(color, text): string {
    return `${color}${text}${TERMINAL_COLORS.Reset}`;
  }

  private prompt() {
    this.command = '';
    this.term.write('\r\n$ ');
  }

  private runCommand(text) {
    const input = text.toString()
      .trim()
      .split(' ');
    const command = input[0];
    const argv = minimist(input.slice(1));

    if (command.length > 0) {
      this.term.writeln('');
      if (command in this.terminalCommands) {
        this.terminalCommands[command].start(argv);
        return;
      }
      this.term.writeln(`${command}: command not found`);
    }

    this.prompt();
  }
}
