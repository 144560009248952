import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import {
  AsyncSubject,
  catchError,
  concatMap,
  defer,
  filter,
  finalize,
  interval,
  map,
  mergeMap,
  MonoTypeOperatorFunction,
  Observable,
  of,
  scan,
  startWith,
  Subject,
  switchMap,
  switchMapTo,
  take,
  takeWhile,
  tap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { TokenDataMessageBase, TokenDataStatus } from './messaging.interfaces';
import * as moment from 'moment-timezone';
import { BYPASS_AUTHENTICAION_TOKEN } from './authentication-interceptor.service';
import { Store } from '@ngrx/store';
import { PlaybackSelectors } from '@states/playback/playback.selector-types';
import { PlaybackActions } from '@states/playback/playback.action-types';
import { Upload } from '../shared/ui-kit/ui-upload/ui-upload.models';
import { UiUploadService } from '../shared/ui-kit/ui-upload/ui-upload.service';
import * as _ from 'lodash';

export interface HttpToStore {
  isCompleted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private pendingHTTPRequests$ = new Subject<void>();

  constructor(public http: HttpClient, public store: Store, private uiUploadService: UiUploadService) {
  }

  cache: { [key: string]: AsyncSubject<HttpToStore> } = {};

  public cancelPendingRequests() {
    this.pendingHTTPRequests$.next();
  }

  public onCancelPendingRequests() {
    return this.pendingHTTPRequests$.asObservable();
  }

  // TODO: Catch error on timeout
  poll<T extends TokenDataMessageBase>(url: string, ms_interval: number, filter_function: any, ms_timeout: number): Observable<T> {
    return interval(ms_interval).pipe(
      startWith(0),
      switchMap(() => this.http.get<T>(url)),
      tap((res: T) => {
        if (res.status === TokenDataStatus.ERROR) {
          const err = new Error(
            JSON.stringify({
              status: res.responseCode || 555,
              msg: res.msg || res.responseCodeR || 'Please try again',
            })
          );
          return throwError(() => err);
        } else {
          return res;
        }
      }),
      filter(filter_function),
      take(1),
      timeout(ms_timeout),
      catchError(err => {
        return throwError(() => err);
      })
    );
  }

  pollPost<T extends TokenDataMessageBase>(
    url: string,
    ms_interval: number,
    payload: any,
    filter_function: any,
    ms_timeout: number
  ): Observable<T> {
    return interval(ms_interval).pipe(
      startWith(0),
      switchMap(() => this.http.post<T>(url, payload)),
      tap((res: T) => {
        if (res.status === TokenDataStatus.ERROR) {
          const err = new Error(
            JSON.stringify({
              status: res.responseCode || 555,
              msg: res.msg || res.responseCodeR || 'Please try again',
            })
          );
          return throwError(() => err);
        } else {
          return res;
        }
      }),
      filter(filter_function),
      take(1),
      timeout(ms_timeout),
      catchError(err => {
        return throwError(() => err);
      })
    );
  }

  // TODO: temporary until the stream exist poll will comply with the session base fields
  pollStream<T>(url: string, ms_interval: number, payload: any, filter_function: any, ms_timeout: number, get = false): Observable<T> {
    return interval(ms_interval).pipe(
      startWith(0),
      switchMap(() => {
        if (get) {
          return this.http.get<T>(url);
        }
        return this.http.post<T>(url, payload);
      }),
      filter(filter_function),
      take(1),
      timeout(ms_timeout),
      catchError(err => throwError(() => err))
    );
  }

  // TODO: temporary until the stream exist poll will comply with the session base fields
  pollStreamHead<T>(url: string, ms_interval: number, ms_timeout: number): Observable<T> {
    return interval(ms_interval).pipe(
      startWith(0),
      switchMap(() => this.http.get<T>(url)),
      timeout(ms_timeout)
    );
  }

  pollStreamGet(url: string, ms_interval: number, ms_timeout: number, playback = false, ts?: number): Observable<any> {
    // 'Pragma': 'no-cache','Expires': '0'
    return interval(ms_interval).pipe(
      startWith(0),
      mergeMap(() =>
        this.http
          .get(url + `?_=${new Date().getTime()}`, {
            responseType: 'text',
            observe: 'response',
            headers: new HttpHeaders({
              'Cache-Control': 'no-store',
            }),
          })
          .pipe(
            catchError(err => {
              return of({ headers: undefined });
            })
          )
      ),
      map(res => {
        return !!res?.headers && +moment.tz(res?.headers?.get('last-modified'), 'GMT').format('x') >= new Date().getTime() - 10000;
      }),
      filter(res => !!res),
      take(1),
      timeout(ms_timeout)
    );
  }

  pollStreamGetPlayback(url: string, ms_interval: number, ms_timeout: number, playback = false, ts?: number): Observable<any> {
    // 'Pragma': 'no-cache','Expires': '0'
    return interval(ms_interval).pipe(
      startWith(0),
      mergeMap(() =>
        this.http
          .get(url + `?_=${new Date().getTime()}`, {
            responseType: 'text',
            observe: 'response',
            headers: new HttpHeaders({
              'Cache-Control': 'no-store',
            }),
          })
          .pipe(
            catchError(err => {
              return of({ body: undefined, headers: undefined });
            })
          )
      ),
      map(res => {
        return res.body;
      }),
      concatMap(body => {
        return this.store.select(PlaybackSelectors.selectTag).pipe(
          take(1),
          map(tag => [body, tag])
        );
      }),
      map(([body, tag]) => {
        if (body?.includes(`#EXT-X-TAG:${tag || new Date().getTime()}`)) {
          return false;
        } else {
          const newTag = body?.split('#EXT-X-TAG:')[1]?.split('\n')[0];
          this.store.dispatch(PlaybackActions.setTag({ tag: newTag }));
          return newTag === undefined ? false : true;
        }
      }),
      filter(shouldPlay => !!shouldPlay),
      take(1),
      timeout(ms_timeout)
    );
  }

  poll2<T>(pollInterval: number): MonoTypeOperatorFunction<T> {
    return source$ => timer(0, pollInterval).pipe(switchMapTo(source$));
  }

  pollHead(url: string, ms_interval: number, ms_timeout: number) {
    return defer(() => this.http.head(url)).pipe(
      catchError(() => of({ response: 'Fallback todo' })),
      this.poll2(ms_interval)
    );
  }

  setCacheCompleted(key: string, setCache = true) {
    return source$ =>
      source$.pipe(
        tap(res => {
          if (setCache) {
            this.cache[key] = new AsyncSubject();
            this.cache[key].next({ isCompleted: true });
            this.cache[key].complete();
          }
        })
      );
  }

  storeNotification(key: string, intervalRate = 1000, msTimeout = 5000): Observable<boolean> {
    try {
      const success = interval(intervalRate).pipe(
        filter(_ => !!this.cache[key]),
        concatMap(_ => {
          return this.cache[key].asObservable();
        }),
        map(res => res.isCompleted),
        filter(res => {
          return !!res;
        }),
        takeWhile(res => {
          const completed = !!res === !res;
          return completed;
        }, true),
        finalize(() => {
          if (this.cache[key]) {
            this.cache[key].unsubscribe();
            delete this.cache[key];
          }
        })
      );

      return success.pipe(timeout(msTimeout));
    } catch (error) {
      return throwError(() => error);
    }
  }

  get<T>(url: string) {
    return this.http.get<T>(url);
  }


  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
  }


  uploadPresignedUrl<T extends { url: string, file: Blob, filename?: string }>(request: T) {
    const data = new FormData();
    data.append('file', request.file);
    const initialState: Upload = { state: 'PENDING', progress: 0, fileProgress: {} };
    const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
      if (this.isHttpProgressEvent(event)) {
        const current = this.uiUploadService.getProgress();
        const singleProgress = event.total ? Math.round((100 * event.loaded) / event.total) : current.progress;
        const fileProgress = current.fileProgress;
        fileProgress[request?.filename ?? request?.url] = singleProgress;
        const globalProgress = _.sum(Object.values(fileProgress)) / (Object.values(fileProgress).length * 100);
        return {
          progress: globalProgress,
          state: 'IN_PROGRESS',
          fileProgress
        };
      }
      if (this.isHttpResponse(event)) {
        const current = this.uiUploadService.getProgress();
        const singleProgress = 100;
        const fileProgress = current.fileProgress;
        fileProgress[request?.filename ?? request?.url] = singleProgress;
        const globalProgress = Object.values(fileProgress).reduce((acc, curr) => acc + curr, 0) / Object.values(fileProgress).length;

        return {
          progress: globalProgress,
          state: 'DONE',
          fileProgress
        };
      }
      return upload;
    };

    return this.http
      .put(request.url, request.file, {
        // headers: new HttpHeaders({
        //   'Access-Control-Allow-Origin': '*',
        // }),
        context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        scan(calculateState, initialState),
        tap((state: Upload) => {
          this.uiUploadService.setProgress(state);
        }),
        filter(val => val.state === 'DONE'),
      );
  }
}
