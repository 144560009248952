import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel } from '../locations/location.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';

const Working = [PulsationModels.ComponentStatus.Online, PulsationModels.ComponentStatus.Streaming];

export enum StatusField {
  cameraStatus,
  analyticStatus,
  edgeStatus,
  storage,
}

export enum CameraStatusType {
  Working,
  NotWorking,
  Loading,
}

@Pipe({
  name: 'locationCameraStatusTotal',
})
export class LocationCameraStatusTotalPipe implements PipeTransform {
  constructor() {
  }

  transform(
    value: LocationModel.LocationItem,
    allCameras: { timestamp: number; value: Dictionary<PulsationModels.Store.CameraPulsation> | Dictionary<PulsationModels.Store.AnalyticPulsation> },
    type: CameraStatusType,
  ) {
    let count = 0;
    const cameraList = allCameras.value;
    Object.values(value.edges)
      .forEach(edge => {
        if (edge.cameras) {
          Object.values(edge.cameras)
            .forEach(camera => {
              const selectedCamera = cameraList[camera.edgeOnly.cameraId];
              if (Object.values(cameraList)?.length && selectedCamera) {
                let statusValue;
                statusValue = selectedCamera.status;
                if (type === CameraStatusType.Working) {
                  if (
                    Working.includes(statusValue)
                    // && allCameras?.timestamp &&
                    // allCameras?.timestamp - Number(selectedCamera?.timestamp) < 360000
                  ) {
                    count++;
                  }
                } else if (type === CameraStatusType.NotWorking) {
                  if (
                    !Working.includes(statusValue)
                    // || (allCameras?.timestamp && allCameras?.timestamp - Number(selectedCamera?.timestamp) > 360000)
                  ) {
                    count++;
                  }
                }
              }
            });
        }
      });
    return count;
  }
}
