<div class="sort-wrapper">
  <div class="header-sort"
       [class.active]="orderBy===fieldName"
       (click)="onOrderChanged.emit(fieldName)"
  >
 <span class="ui-icon-table-order-arrow-asc col-order asc c-p"
       [class.selected]="orderBy===fieldName && orderDirection ===1"
 >
 </span>
    <span class="ui-icon-table-order-arrow-desc col-order  c-p"
          [class.selected]="orderBy===fieldName && orderDirection === -1"
    ></span>
  </div>
</div>
