<div fxLayoutAlign="center center"
     class="ui-preloader"
     [style.height.px]="diameter ?? 80"
     [style.width.px]="diameter ?? 80"
     [class.ui-preloader-white]="color === preloader.white"
     [class.ui-preloader-gray]="color === preloader.gray"
     [class.ui-preloader-red]="color === preloader.red"
     [class.ui-preloader-blue]="color === preloader.blue"
>
  <ng-content></ng-content>
</div>
