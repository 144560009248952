<div #wrapper fxLayout="column" fxFlex="" class="w-100">
  <div fxLayout="column" fxLayoutAlign="center center" class="h-100" class="mt-40" *ngIf="initMsg" fxFlex="">
    <img class="pin-img mb-20" src="assets/location.png"/>
    <div style="font-size: 18px; font-weight: 500; color: #070707">
      Please select cameras and enter a search criteria to start searching
    </div>
    <!--    <div>{{searchSelections | json}}</div>-->
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="h-100"
       *ngIf="started && !more && !fetching && rendered.length === 0">
    No Results
  </div>
  <div
      *ngIf="fetching && this.thumbs.length === 0"
      fxLayoutAlign="start center"
      class="w-100"
      [style.height.px]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + 152"
  >
    <div fxLayoutAlign="center center" [style.width.px]="(wrapper.clientWidth - 60) / 3">
      <mat-spinner diameter="35"></mat-spinner>
    </div>
  </div>
  <cdk-virtual-scroll-viewport
      fxLayout="column"
      [style.height]="wrapper.clientHeight"
      fxFlex=""
      (scrolledIndexChange)="scrolledIndexChange($event)"
      [itemSize]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + 152"
      [minBufferPx]="((((wrapper.clientWidth - 60) / 3) * 9) / 16 + 152) * 2"
      [maxBufferPx]="((((wrapper.clientWidth - 60) / 3) * 9) / 16 + 152) * 5"
      class="thumbnail-scroller"
  >
    <!--    <div *cdkVirtualFor="let thumb of thumbnails; let i = index" class="example-item">{{thumb}} </div>-->
    <div *ngIf="started" fxLayout="column" fxLayoutAlign="start start">
      <ng-container *cdkVirtualFor="let thumbRow of thumbs; let idx = index">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px grid" class="w-100 mb-0">
          <ng-container *ngFor="let thumb of thumbRow; let i = index">
            <app-thumbnail
                style="animation: fadeIn 5s"
                [showEventsCounter]="false"
                [rtl]="rtl"
                *ngIf="!!thumb"
                [optionsInput]="thumb.options"
                [events]="thumb.thumbnails"
                [searchEvents]="thumb.searchEvents"
                [objects]="thumb.objects"
                [edgeId]="edgeId!"
                [cameraId]="cameraId!"
                [displayName]="true"
                [defaultThumbnail]="thumb.defaultThumb"
                [fxFlex]="100 / 3 + '%'"
            ></app-thumbnail>
            <div
                *ngIf="thumbRow.length < 3 && i === thumbRow.length - 1"
                fxLayoutAlign="center center"
                [fxFlex]="100 / 3 + '%'"
                [style.height.px]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + 132"
            >
              <div *ngIf="fetching" fxLayoutAlign="center center">
                <mat-spinner diameter="35"></mat-spinner>
              </div>
              <div *ngIf="started && !more && !fetching">
                {{ rendered.length > 0 ? 'No more results' : 'No results' }}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="this.thumbs?.length">
        <div
            *ngIf="started && this.thumbs[this.thumbs.length - 1].length === 3"
            fxLayoutAlign="center center"
            [style.width.px]="(wrapper.clientWidth - 60) / 3"
            [style.minHeight]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + 132 + 'px'"
        >
          <div *ngIf="!more && !fetching">No more results</div>
          <div *ngIf="more && fetching" fxLayoutAlign="center center">
            <mat-spinner diameter="35"></mat-spinner>
          </div>
        </div>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>

  <!--  <div-->
  <!--      #scroller-->
  <!--      class="thumbnail-scroller"-->
  <!--      fxFlex=""-->
  <!--      infiniteScroll-->
  <!--      [infiniteScrollDistance]="2"-->
  <!--      [infiniteScrollThrottle]="5"-->
  <!--      (scrolled)="onScrollDown()"-->
  <!--      [scrollWindow]="false">-->
  <!--    <div-->
  <!--      *ngIf="started"-->
  <!--      fxLayout="row wrap"-->
  <!--      fxLayoutAlign="start start"-->
  <!--      fxLayoutGap="20px grid">-->
  <!--      <app-thumbnail-->
  <!--        style="animation: fadeIn 5s"-->
  <!--        [showEventsCounter]="false"-->
  <!--        [rtl]="rtl"-->
  <!--        *ngFor="let thumb of rendered"-->
  <!--        [options]="thumb.options"-->
  <!--        [events]="thumb.thumbnails"-->
  <!--        [searchEvents]="thumb.searchEvents"-->
  <!--        [objects]="thumb.objects"-->
  <!--        [edgeId]="edgeId!"-->
  <!--        [cameraId]="cameraId!"-->
  <!--        [displayName]="true"-->
  <!--        [hideDebug]="true"-->
  <!--        [fxFlex]="100/3 + '%'"></app-thumbnail>-->
  <!--      <div fxLayoutAlign="center center" [fxFlex]="'25%'" style="height: 230px">-->
  <!--        <mat-spinner *ngIf="loadingMore" diameter="35"></mat-spinner>-->
  <!--        <div *ngIf="loader && !loadingMore" fxLayoutAlign="center center">-->
  <!--          <mat-spinner diameter="35"></mat-spinner>-->
  <!--        </div>-->
  <!--        <div *ngIf="started && !more && !loadingMore">-->
  <!--          {{ rendered.length > 0 ? 'No more results' : 'No results' }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
