import { Component, Inject, OnInit } from '@angular/core';
import { LocationModel } from '../../../locations/location.model';
import { StatsEnum } from '@enums/stats.enum';
import { EdgeCamera } from '../../../cameras/camera.model';
import { periods, statUptimePeriods } from '@consts/stats.const';
import { Edge } from '../../../edge/edge.model';
import { SingleCameraStatsModalComponent } from '../single-camera-stats-modal/single-camera-stats-modal.component';
import { Observable, timestamp } from 'rxjs';
import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import { CameraStatusType, StatusField } from '../../../pipes/location-camera-status-total.pipe';
import { AppState } from '../../../store/app.state';
import { EdgeStatusType } from '../../../pipes/location-edge-status-total.pipe';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeHeartbeatPulsationSelectors } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.selector-types';
import { CameraHeartbeatPulsationSelectors } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.selector-types';
import { StatResult, StatType } from '@states/stats/stats.reducer';
import * as StatsSelectors from '@states/stats/stats.selectors';
import * as StatsActions from '@states/stats/stats.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyValuePairs } from '../../../core/interfaces';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-cameras-stats-modal',
  templateUrl: './cameras-stats-modal.component.html',
  styleUrls: ['./cameras-stats-modal.component.scss'],
})
export class CamerasStatsModalComponent implements OnInit {
  public selectAllCamerasStatus$: Observable<{ timestamp: number; value: Dictionary<PulsationModels.Store.CameraPulsation> }> = this.store$.pipe(
    select(CameraHeartbeatPulsationSelectors.selectCameraEntities),
    timestamp(),
  );

  public selectAllEdgeStatus$: Observable<{ timestamp: number; value: Dictionary<PulsationModels.Store.EdgePulsation> }> = this.store$.pipe(
    select(EdgeHeartbeatPulsationSelectors.selectEdgeEntities),
    timestamp(),
  );

  public selectEdgeUptimeAll$: Observable<StatResult[]> = this.store$.pipe(select(StatsSelectors.selectEdgeUptimeAll));

  public start: number;
  public end: number;
  public periods = periods;
  public statUptimePeriods = statUptimePeriods;
  public selectedPeriod = StatsEnum.day1;
  public isCameraSelected = true;
  public format = this.periods[this.selectedPeriod].format;
  public cameras: EdgeCamera.CameraItem[] = [];
  public edges: Edge.EdgeDocument[] = [];
  public statusFields = StatusField;

  public cameraStatusTypes = CameraStatusType;
  public edgeStatusTypes = EdgeStatusType;

  public edgeStats: StatResult[] = [];

  public cameraStats: KeyValuePairs<number[]> = {};

  constructor(
    private store$: Store<AppState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      location: LocationModel.LocationItem,
    },
  ) {
  }

  public get statPeriods() {
    return this.statUptimePeriods[this.selectedPeriod];
  }

  ngOnInit(): void {
    this.calculateStartEnd();
    this.edges = Object.values(this.data.location.edges);
    this.edges.map(edge => {
      const cameras = Object.values(edge?.cameras ?? {});
      this.cameras = this.cameras.concat(cameras);
    });

    for(let edge of this.edges) {
      this.store$.dispatch(
        StatsActions.getEdgeUptimeAll({
          edgeId: edge.edgeId,
          locationId: this.data.location._id,
          period: this.statPeriods.period,
          bar: this.statPeriods.bar,
        }),
      );
    }

    this.selectEdgeUptimeAll$.pipe(untilDestroyed(this))
      .subscribe(stats => {
        this.edgeStats = this.edgeStats.concat(stats);
        for(let statRes of this.edgeStats.filter(res => res?.type === StatType.Camera)) {
          this.cameraStats[statRes.cameraId] = statRes.stats;
        }
      });
  }

  public getCameraStats(cameraId: string) {
    return this.edgeStats?.find(stat => stat.cameraId === cameraId).stats;
  }

  public calculateStartEnd() {
    this.end = new Date().getTime();
    this.start = this.end - statUptimePeriods[this.selectedPeriod].period;

    this.edgeStats = [];
    this.store$.dispatch(StatsActions.resetEdgeUptimeAll());
    for(let edge of this.edges) {
      this.store$.dispatch(
        StatsActions.getEdgeUptimeAll({
          edgeId: edge.edgeId,
          locationId: this.data.location._id,
          period: this.statPeriods.period,
          bar: this.statPeriods.bar,
        }),
      );
    }
  }

  public openCameraStat(camera: EdgeCamera.CameraItem) {
    this.dialog.open(SingleCameraStatsModalComponent, {
      width: '100vh',
      panelClass: 'modal-no-padding',
      data: {
        camera,
        period: this.selectedPeriod,
      },
    });
  }

}
