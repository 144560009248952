import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { Archive } from '@models/archive.model';
import { GrantedAccessModel } from '@models/granted-access-model';
import { LocationModel } from '../locations/location.model';
import { GrantedAccessType } from '@enums/granted-access.enum';
import ArchiveDocument = Archive.ArchiveDocument;
import { SortDirection } from '@angular/material/sort';
import { PaginationResult } from '@models/shared.model';
import { AlertEvent, SelectedCamera } from '@models/alert-events.model';
import { AlertEntry } from './alerts.service';

@Injectable()
export class GrantedAccessService {
  constructor(private http: HttpClient) {
  }

  public getGrantedAccessList(
    page: number,
    perPage: number,
    query: string,
    filters: GrantedAccessModel.GrantedAccessFilters & { lastId: string },
  ): Observable<PaginationResult<GrantedAccessModel.GrantedAccessDocument>> {
    let url = `${api.grantedAccess.getByFilter}?limit=${perPage}&page=${page}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<GrantedAccessModel.GrantedAccessDocument>>(url, filters);
  }

  public getOne(id: string, body?: { password: string }): Observable<GrantedAccessModel.GrantedAccessResponse> {
    let url = `${api.grantedAccess.one(id)}`;
    if (body?.password) {
      url += `?password=${body.password}`;
    }
    return this.http.get<GrantedAccessModel.GrantedAccessResponse>(url);
  }

  public create(dto: GrantedAccessModel.GrantedAccessModelRequest): Observable<GrantedAccessModel.CreateGrantedAccessResponse> {
    let endPoint;
    switch (dto.type) {
      case GrantedAccessType.ALERT:
        endPoint = 'alert';
        break;
      case GrantedAccessType.ARCHIVE:
        endPoint = 'archive';
        break;
      case GrantedAccessType.LIVE_VIEW:
        endPoint = 'live-view';
        break;
      case GrantedAccessType.PLAYBACK:
        endPoint = 'playback';
        break;
    }
    return this.http.post<GrantedAccessModel.CreateGrantedAccessResponse>(`${api.grantedAccess.crud}/${endPoint}`, dto);
  }

  public getByEntityId(entityId: string): Observable<ArchiveDocument[]> {
    return this.http.get<ArchiveDocument[]>(api.grantedAccess.getByEntityId(entityId));
  }

  public removeGrantedAccess(id: string): Observable<any> {
    return this.http.delete<any>(api.grantedAccess.one(id));
  }

  public getLocationByEdgeId(id: string): Observable<LocationModel.LocationItem> {
    return this.http.get<LocationModel.LocationItem>(api.location.getByEdge(id));
  }

  public getLocationTimezoneByEdgeId(edgeId: string): Observable<{ timezone: string }> {
    return this.http.get<{ timezone: string }>(api.location.getEdgeTimezone(edgeId), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getCameraRatio(camera: SelectedCamera): Observable<{ aspectRatio: number }> {
    return this.http.get<{ aspectRatio: number }>(api.location.getCameraAspectRatio(camera), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getCameraRetentionDays(camera: SelectedCamera): Observable<{ retentionDays: number }> {
    return this.http.get<{ retentionDays: number }>(api.location.getCameraRetentionDays(camera), {
      params: {
        sharedToken: true,
      },
    });
  }

  public extend(id: string, period: number): Observable<any> {
    return this.http.put<{ url: string }>(api.grantedAccess.extend(id), { period });
  }

  public notify(id: string): Observable<any> {
    return this.http.post<{ url: string }>(api.grantedAccess.notify(id), {});
  }

  public viewAuth(id: string): Observable<any> {
    return this.http.post<{ url: string }>(api.grantedAccess.viewAuth(id), {});
  }

  public viewNoAuth(id: string): Observable<any> {
    return this.http.post<{ url: string }>(api.grantedAccess.viewNoAuth(id), {});
  }

  public shareWith(id: string, dto: { emails: string[], phones: string[], message: string }): Observable<any> {
    return this.http.post<{ url: string }>(api.grantedAccess.shareWith(id), dto);
  }

  public getStats(id: string): Observable<GrantedAccessModel.GrantedAccessConfig> {
    return this.http.get<GrantedAccessModel.GrantedAccessConfig>(api.grantedAccess.stats(id));
  }

  public getPreviewUrl(dto: { entityId: string, type: GrantedAccessType }): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(api.grantedAccess.getPreviewUrl(dto.entityId), dto);
  }

  public getSharedArchive(id: string): Observable<ArchiveDocument> {
    return this.http.get<ArchiveDocument>(api.grantedAccess.getSharedArchive(id));
  }

  public revokeGrantedAccess(id: string): Observable<GrantedAccessModel.GrantedAccessDocument> {
    return this.http.put<GrantedAccessModel.GrantedAccessDocument>(api.grantedAccess.revoke(id), {});
  }

  public revokeAll(): Observable<any> {
    return this.http.post<any>(api.grantedAccess.revokeAll, {});
  }


}
