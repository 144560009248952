import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Notification, NotificationIconType, NotificationStatus, NotificationType, UserNotification, UserNotificationState } from '@models/notification.model';
import { MatCalendar } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { routerSegments } from '@consts/routes';
import { NotificationActions } from '@states/notification/notification.action-types';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ui-notification-action',
  templateUrl: './ui-notification-action.component.html',
  styleUrls: ['./ui-notification-action.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UiNotificationActionComponent implements OnInit {

  public NotificationType = NotificationType;
  public NotificationIconType = NotificationIconType;
  @Input() userNotification: UserNotification;
  @Input() notifications: { [key: string]: Notification };

  public setDateVisible: boolean = false;
  public startTime: string;

  public timeZones = moment.tz.names();
  public currentTimezone = moment.tz.guess();
  public notificationState = UserNotificationState;
  public notificationStatus = NotificationStatus;
  public startDateLeftCalendar: Date = new Date();
  @ViewChild('leftCalendar')
  private leftCalendar: MatCalendar<any>;

  @Output() onSeen: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSave: EventEmitter<{
    notificationId: string;
    timeZone: string;
    schedule: string;
  }> = new EventEmitter<{
    notificationId: string;
    timeZone: string;
    schedule: string;
  }>();
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeBar: EventEmitter<void> = new EventEmitter<void>();

  constructor(public router: Router, private store$: Store) {
  }

  ngOnInit(): void {
    this.startTime = moment(this.startDateLeftCalendar)
      .format('HH:mm');
  }

  public setTimezone(timezone: string): void {
    console.time(timezone);
  }

  public readNotification(): void {
    this.onSeen.emit(this.userNotification.id);
  }

  public dateChanged(ev: Date) {
    this.startDateLeftCalendar = ev;
    this.changeTime();
    this.leftCalendar.updateTodaysDate();

  }

  public changeTime() {
    const startTimeSplit = this.startTime.split(':');
    this.startDateLeftCalendar = moment(this.startDateLeftCalendar)
      .hours(+startTimeSplit[0])
      .minutes(+startTimeSplit[1])
      .seconds(0)
      .toDate();
  }

  public save() {
    this.onSave.emit({
      notificationId: this.userNotification.id,
      timeZone: this.currentTimezone,
      schedule: dateToCron(this.startDateLeftCalendar),
    });
  }

  public restartNow() {
    const now = new Date().getTime() + 120 * 1000;
    const schedule = dateToCron(moment(now)
      .clone()
      .toDate());
    this.onSave.emit({
      notificationId: this.userNotification.id,
      timeZone: this.currentTimezone,
      schedule,
    });
  }

  public gotoDoors(notification: Notification) {
    this.router.navigate([routerSegments.database, routerSegments.doors], { queryParams: { ts: notification?.timestamp } });
    this.closeBar.emit();
    this.store$.dispatch(NotificationActions.deleteNotification({ id: notification.id }));

  }

  public dateClass = (date: Date) => {
    const formattedDate = moment(date)
      .format('DD-MM-YYYY');
    if (moment(this.startDateLeftCalendar)
      .format('DD-MM-YYYY') == formattedDate) {
      return 'selected-limit selected-limit-left';
    }
    return '';
  };

  public deleteNotification(id: string) {
    this.onDelete.emit(id);
  }

  public iconType(type: NotificationType): NotificationIconType {
    switch (type) {
      case NotificationType.PROPER_FITTING:
        return NotificationIconType.ERROR;
      case NotificationType.DOORS:
      default:
        return NotificationIconType.INFO;
    }
  }
}

const dateToCron = (date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const days = date.getDate();
  const months = date.getMonth() + 1;

  return `${minutes} ${hours} ${days} ${months} *`;
};
