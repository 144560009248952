import { OrganizationUserFilters, UserFilters, UserOrganizationDropDown } from '@models/organization.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import _ from 'lodash';
import { OrganizationsActions } from '@states/organization/organization.actions-types';
import { PermissionGroupModels, SystemSecurityGroupModels } from '@models/group.model';
import { AppUser } from '../../../user/user.model';
import User = AppUser.User;

export declare interface OrganizationUsersState extends EntityState<User> {
  filters: OrganizationUserFilters;
  isLoading: boolean;
  page: number;
  perPage: number;
  isLastPage: boolean;
  isInitialLoaded: boolean;
  permissionGroups: PermissionGroupModels.PermissionGroupDocumentBase[];
  systemPermissionGroups: SystemSecurityGroupModels.SystemSecurityGroupDocument[];
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (organizationUser: User) => organizationUser._id,
});

export const initialState: OrganizationUsersState = adapter.getInitialState({
  filters: {
    permissions: null,
    orderDirection: -1,
    orderBy: null,
    query: '',
  },
  isLoading: false,
  page: 0,
  perPage: 20,
  isLastPage: false,
  isInitialLoaded: false,
  permissionGroups: [],
  systemPermissionGroups: [],
});

export const organizationUsersStateReducer = createReducer(
  initialState,
  on(OrganizationUsersActions.resetToInitialState, () => {
    return {
      ...initialState,
    };
  }),
  on(OrganizationUsersActions.getOrganizationUsersSuccess, (state, { organizationUsers }) => {
    return adapter.addMany(organizationUsers, {
      ...state,
      isLastPage: organizationUsers.length < state.perPage,
      page: state.page + 1,
      isInitialLoaded: true,
    });
  }),
  on(OrganizationUsersActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(OrganizationUsersActions.setOrderDirection, (state, { property }) => {
    let field = property;
    let orderDirection = -1; // first click on field always set -1
    if (property === state.filters.orderBy) {
      if (state.filters.orderDirection === -1) {
        orderDirection = 1;
      } else {
        field = null;
        orderDirection = -1;
      }
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        orderBy: field,
        orderDirection,
      },
    };
  }),
  on(OrganizationUsersActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(OrganizationUsersActions.resetEntities, (state) => {
    return adapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
    });
  }),
  on(OrganizationUsersActions.removeFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: rmFilters(state.filters, property, value),
    };
  }),
  on(OrganizationsActions.removeUserSuccess, (state, { userId }) => {
    return adapter.removeOne(userId, { ...state });
  }),
  on(OrganizationUsersActions.getOrganizationPermissionGroupsSuccess, (state, { permissionGroups }) => {
    return {
      ...state,
      permissionGroups,
    };
  }),

  on(OrganizationUsersActions.getSystemPermissionGroupsSuccess, (state, { permissionGroups }) => {
    return {
      ...state,
      systemPermissionGroups: permissionGroups,
    };
  }),
  on(OrganizationUsersActions.updateOrganizationUserPermissionsLocally, (state, { userId, groupIds }) => {
    return {
      ...state,
      permissionGroups: updateOrganizationUserPermissionsLocally(userId, groupIds, state.permissionGroups),
    };
  }),
);


const rmFilters = (filters: UserFilters, prop: string, value: any): UserFilters => {
  const _filters = _.cloneDeepWith(filters);
  switch (prop) {
    case 'query':
      _filters.query = null;
      break;
    case 'groups':
      const indexPermissions = _filters.groups.findIndex(item => item === value);
      _filters.groups.splice(indexPermissions, 1);
      break;
  }
  return _filters;
};

const updateOrganizationUserPermissionsLocally = (userId: string, groupIds: string[], permissionGroups: PermissionGroupModels.PermissionGroupDocumentBase[]): PermissionGroupModels.PermissionGroupDocumentBase[] => {
  return permissionGroups.map(group => {
    if (groupIds.includes(group._id)) {
      return {
        ...group,
        users: {
          ...group.users,
          [userId]: {},
        },
      };
    } else {
      const groupCopy = _.cloneDeep(group);
      delete groupCopy.users[userId];
      return groupCopy;
    }
  });


};
