<div fxLayout="column" fxLayoutAlign="start start" fxFill class="save-search-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Save search</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="" class="dialog-content narrower-input">
    <div class="opacity-50">You can filter the results page and save it as a new search. It will appear in “Saved
      searches” page.
    </div>
    <div fxLayout="column" fxLayoutGap="5px" class="w-100">
      <div class="bold">Search name</div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="w-100">
        <mat-form-field appearance="outline" fxFlex="">
          <input matInput [(ngModel)]="searchName">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button class="back-btn" colorBgr="#FFFFFF" colorText="#344054" text="Cancel" colorBorder="#D0D5DD"
      mat-dialog-close>
    </ui-button>
    <ui-button colorBgr="#4367F6" colorText="white" text="Save" (clickAction)="close()"
      [disabled]="!this.searchName?.length"></ui-button>
  </div>

</div>