import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logLink',
})
export class LogLinkPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value.split('/')[2];
  }
}
