import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import { select, Store } from '@ngrx/store';
import { DeviceStatusSelectors } from '@states/device-status/device-status.selector-types';
import { UiDeviceStatusComponent } from '../ui-device-status/ui-device-status.component';
import { LetDirective } from '@ngrx/component';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';

@UntilDestroy()
@Component({
  selector: 'ui-camera-status',
  standalone: true,
  imports: [
    UiDeviceStatusComponent,
    LetDirective,
  ],
  templateUrl: './ui-camera-status.component.html',
  styleUrl: './ui-camera-status.component.scss',
})
export class UiCameraStatusComponent implements OnChanges {
  @Input() cameraId: string;

  @Input()
  public showStatus: boolean = false;

  @Input()
  public iconSizePx: number = 20;

  @Input() showTime = false;
  @Input() timezone: string;
  @Input() playing = false;

  public selectCameraStatus$: Observable<Dictionary<{
    edgeId: string,
    cameraId: string,
    status: PulsationModels.ComponentStatusDisplay
  }>> = this.store$.pipe(select(DeviceStatusSelectors.selectTransformedCamerasStatus));

  public cameraStatus$: BehaviorSubject<ComponentStatusDisplay> = new BehaviorSubject<ComponentStatusDisplay>(null);

  constructor(private store$: Store) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    let loadingTimeout;
    this.selectCameraStatus$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const currentStatus = this.cameraStatus$.getValue();
        if (loadingTimeout) {
          clearTimeout(loadingTimeout);
        }
        if (!res[this.cameraId] && !currentStatus) {
          loadingTimeout = setTimeout(() => {
            this.cameraStatus$.next(ComponentStatusDisplay.Offline);
            this.store$.dispatch(DeviceStatusActions.setCameraStatusById({ cameraId: this.cameraId, status: ComponentStatusDisplay.Offline }));
          }, 2000);
        } else {
          this.cameraStatus$.next(res[this.cameraId]?.status);
        }
      });
  }
}
