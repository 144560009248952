import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PulsationModels } from '@models/pulsation.model';
import { PreloaderColor } from '@enums/shared.enum';
import * as moment from 'moment-timezone';
import { UtilsService } from '../../edge/utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { EdgeHeartbeatPulsationSelectors } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-component-status-chip',
  templateUrl: './component-status-chip.component.html',
  styleUrls: ['./component-status-chip.component.scss'],
})
export class ComponentStatusChipComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('wrapper')
  wrapper: ElementRef;
  public preloaderColor = PreloaderColor;
  public ComponentStatus = PulsationModels.ComponentStatus;

  public selectLoadingPulsation: Observable<boolean> = this.store$.pipe(select(EdgeHeartbeatPulsationSelectors.selectLoadingPulsation$())).pipe(untilDestroyed(this));


  @Input()
  status: PulsationModels.ComponentStatus;
  @Input() public isSmallTile: boolean = false;

  statusString = 'Online';
  color = 'yellow';

  @Input()
  text = true;

  @Input() showStatus: boolean = false;
  @Input() hideSpinner: boolean = false;

  @Input() showIcon: boolean = true;

  @Input()
  customTooltip: string;

  @Input()
  device: 'camera' | 'edge' | 'analytic' | 'alert' | 'location' | 'storage' = 'camera';

  @Input() noBg = false;

  //todo changed to ui-icons from material
  @Input() iconClass = 'icon-16';

  @Input() noPadding = false;

  @Input() showTime = false;
  @Input() timezone: string;
  @Input() playing = false;

  @Input() fontSize;
  @Input() tooltipSecond: string;

  public time: string;
  public time$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public initTs = Date.now();

  timeInterval;

  constructor(private utilsService: UtilsService, private store$: Store) {
  }


  public get statusTitle() {
    const str = this.customTooltip || this.statusString;
    return str.charAt(0)
      .toUpperCase() + str.slice(1);
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
  }

  ngOnInit(): void {
    if (this.showTime) {
      this.timeInterval = setInterval(() => {
        this.computeTime();
      }, 1000);
    }
  }

  computeTime() {
    const now = new Date().getTime();
    this.time = moment(now)
      .tz(this.timezone)
      .format('hh:mm:ss A z');
    this.time$.next(this.time);
  }


  ngAfterViewInit(): void {
    if (this.text && this.status) {
      this.wrapper?.nativeElement?.classList?.remove('no-text');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.status) {
      this.setObject();
      this.setColor();
    }
  }

  setObject() {
    switch (this.device) {
      case 'camera':
      case 'location':
      case 'edge':
        if (this.status === PulsationModels.ComponentStatus.NotRecording) {
          this.statusString = 'Recording is not scheduled';
          break;
        }
        this.statusString = this.status.toString()
          .replace(/([a-z])([A-Z])/g, '$1 $2');
        break;
      case 'analytic':
        this.statusString =
          this.status === this.ComponentStatus.Online
            ? 'AI On'
            : 'AI Off';
        break;
    }
  }

  setColor() {

    if (this.device === 'camera' || this.device === 'edge') {
      switch (this.status) {
        case this.ComponentStatus.Online:
        case this.ComponentStatus.Streaming:
          this.color = 'green';
          break;

        case this.ComponentStatus.Offline:
        case this.ComponentStatus.StreamNotFound:
        case this.ComponentStatus.Unknown:
          this.color = 'red';
          break;
        case PulsationModels.ComponentStatus.NotRecording:
          this.color = 'gray';
          break;
        case this.ComponentStatus.Stopped:
        case this.ComponentStatus.NotAuthenticated:
        case this.ComponentStatus.Init:
        case this.ComponentStatus.Connected:
        case this.ComponentStatus.Installing:
        case this.ComponentStatus.Rebooting:
        case this.ComponentStatus.NotConnected:
        default:
          this.color = 'yellow';
          break;
      }
    }
  }
}
