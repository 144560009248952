import { createAction, props } from '@ngrx/store';
import { CustomEventModel } from '@models/custom-event.model';

export const resetToInitialState = createAction('[CUSTOM-EVENTS] Reset to Initial state');
export const resetUnsaved = createAction('[CUSTOM-EVENTS] Reset unsaved to Initial state');

export const getCustomEvents = createAction('[CUSTOM-EVENTS] Get CustomEvents');
export const getCustomEventsSuccess = createAction('[CUSTOM-EVENTS] Get CustomEvents Success', props<{ customEvents: CustomEventModel.CustomEvent[] }>());

export const setCustomEvents = createAction('[CUSTOM-EVENTS] Set CustomEvents', props<{ customEvents: CustomEventModel.CustomEvent[] }>());

export const addCustomEvent = createAction('[CUSTOM-EVENTS] Save CustomEvent', props<{ customEvent: CustomEventModel.CustomEvent }>());
export const addCustomEventSuccess = createAction('[CUSTOM-EVENTS] Save CustomEvent Success', props<{ customEvent: CustomEventModel.CustomEvent }>());

export const removeCustomEvent = createAction('[CUSTOM-EVENTS] Remove CustomEvent', props<{ customEvent: CustomEventModel.CustomEvent }>());
export const removeCustomEventSuccess = createAction('[CUSTOM-EVENTS] Remove CustomEvent Success', props<{ customEvent: CustomEventModel.CustomEvent }>());

export const editCustomEvent = createAction('[CUSTOM-EVENTS] Edit CustomEvent', props<{ customEvent: CustomEventModel.CustomEvent }>());
export const editCustomEventSuccess = createAction('[CUSTOM-EVENTS] Edit CustomEvent Success', props<{ customEvent: CustomEventModel.CustomEvent }>());

export const pressSave = createAction('[CUSTOM-EVENTS] Press Save');









