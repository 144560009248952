import { Component, OnDestroy, OnInit } from '@angular/core';
import { SplashService } from './splash.service';
import { catchError, concatMap, filter, interval, Observable, Subscription, tap, throwError, timeout } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';

const SPLASH_INTERVAL = 2000;
const SPLASH_TIMEOUT = 30000;

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit, OnDestroy {
  public selectIsDefaultOrganization$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.selectIsDefaultOrganization));
  show$: Observable<boolean>;

  subscription: Subscription;

  constructor(
    private splashService: SplashService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private store$: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.show$ = this.splashService.show$;

    this.subscription = interval(SPLASH_INTERVAL)
      .pipe(
        map(_ => {
          const isLoggedIn = this.authenticationService.isLoggedIn();
          const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();
          return isLoggedIn && authProviderId;
        }),
        filter(res => !!res),
        concatMap(() => {
          return this.selectIsDefaultOrganization$;
        }),
        tap(res => {
          /**
           * Redirect to home only if user have active organization not default.
           */
          if (!res) {
            this.router.navigate(['/home']);
          }
        }),
        filter(res => !!res),
        timeout(SPLASH_TIMEOUT),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe({
        error: () => {
          this.store$.dispatch(AuthenticationActions.Logout({ reason: null }));
        },
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
