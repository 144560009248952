<div *ngIf="style === inputStyle.labelInside"
     class="ui-input-wrapper p-r label-in"
     [class.invalid]="invalid"
     [class.narrow]="narrow"
     fxLayout="column"
>
  <label *ngIf="label" class="label input-label">{{ label }} <span *ngIf="required" class="required">*</span></label>
  <!--  <div *ngIf="!!suffix" class="suffix">{{ suffix }}</div>-->
  <input class="ui-input"
         [placeholder]="placeholder"
         [ngModel]="value"
         [disabled]="disabled"
         (ngModelChange)="updateValue($event)"
  />
  <div *ngIf="validationEnabled" class="validation-msg-block">
    <ng-container *ngIf="invalid">
      <ng-container [ngSwitch]="validationKeys[0]">
        <ng-container *ngSwitchCase="'required'">
          <label class="label validation-label"> Field is required</label>
        </ng-container>
        <ng-container *ngSwitchCase="'exist'">
          <label class="label validation-label">{{ validationErrors['exist'] }}</label>
        </ng-container>
        <ng-container *ngSwitchCase="'invalid'">
          <label class="label validation-label">{{ validationErrors['invalid'] }}</label>
        </ng-container>
        <ng-container *ngSwitchCase="'email'">
          <label class="label validation-label">Email invalid</label>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<div *ngIf="style === inputStyle.labelOutside" fxLayout="column" class="ui-input-wrapper p-r label-out"
     [class.white-bg]="whiteBg" [class.narrow]="narrow">
  <label *ngIf="label" class="label">{{ label }}</label>
  <!--  <div *ngIf="!!suffix" class="suffix" [class.disabled]="disabled">{{ suffix }}</div>-->
  <input
      [disabled]="disabled"
      class="ui-input"
      [placeholder]="placeholder"
      [class.invalid]="invalid"
      [ngModel]="value"
      (ngModelChange)="updateValue($event)"
  />
  <div *ngIf="validationEnabled" class="validation-msg-block">
    <ng-container *ngIf="invalid">
      <ng-container [ngSwitch]="validationKeys[0]">
        <ng-container *ngSwitchCase="'required'">
          <label class="label validation-label"> Field is required</label>
        </ng-container>
        <ng-container *ngSwitchCase="'exist'">
          <label class="label validation-label">{{ validationErrors['exist'] }}</label>
        </ng-container>
        <ng-container *ngSwitchCase="'invalid'">
          <label class="label validation-label">{{ validationErrors['invalid'] }}</label>
        </ng-container>
        <ng-container *ngSwitchCase="'email'">
          <label class="label validation-label">Email invalid</label>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

</div>
