import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'terminalMsg',
})
export class TerminalMsgPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const replace1 = value?.replace(/\\n/g, '<br />');
    return replace1?.replace(/\n/g, '<br />');
  }
}
