<div class="flex-col wrapper gap-16" *ngrxLet="selectAllCustomEvents$; let events;">
  <div class="flex-row p-r w-100">
    <div class="flex-row center-center clear-selection icon-16 c-p"
         *ngIf="!!selectedCustomEvent"
         (click)="clearCustomEvent()"
    >
      <span class="ui-icon-close"></span>
    </div>
    <mat-select class="ui-selector w-100" placeholder="Choose event tag" [class.selected]="!!selectedCustomEvent"
                (ngModelChange)="customEventSelected($event)"
                [(ngModel)]="selectedCustomEvent"
    >
      <mat-option *ngFor="let event of events" [value]="event">
        {{ event.name }}
      </mat-option>
    </mat-select>
  </div>
  <ng-container *ngFor="let field of localCustomEvent?.fields">
    <div class="flex-col gap-16">
      <div class="flex-row gap-16">
        <ui-toggle [(ngModel)]="field.enabled" (ngModelChange)="triggerChange()"></ui-toggle>
        <div>{{field.name}}</div>
      </div>
      <div class="flex-col field">
        <ng-container [ngSwitch]="field.type">
          <div class="flex-row" *ngSwitchCase="CustomEventFieldType.Text">
            <mat-select class="ui-selector"
                        [(ngModel)]="field.operator"
                        (ngModelChange)="triggerChange()"
                        placeholder="Operator"
            >
              <mat-option *ngFor="let operator of CustomEventFieldTextOperatorsA" [value]="operator">
                {{ CustomEventFieldTextOperatorsStr[operator] }}
              </mat-option>
            </mat-select>
            <input class="ui-input next-to-operator" type="text" placeholder="Type text"
                   [(ngModel)]="field.value" (ngModelChange)="triggerChange()"
            >
          </div>
          <div class="flex-row" *ngSwitchCase="CustomEventFieldType.Decimal">
            <mat-select class="ui-selector"
                        [(ngModel)]="field.operator"
                        (ngModelChange)="triggerChange()"
                        placeholder="Operator"
            >
              <mat-option *ngFor="let operator of CustomEventFieldDecimalOperatorsA" [value]="operator">
                {{ CustomEventFieldDecimalOperatorsStr[operator] }}
              </mat-option>
            </mat-select>
            <input class="ui-input next-to-operator"
                   type="number"
                   placeholder="0"
                   [(ngModel)]="field.value"
                   (ngModelChange)="triggerChange()"
            >
          </div>
          <div class="flex-row" *ngSwitchCase="CustomEventFieldType.Number">
            <mat-select class="ui-selector"
                        [(ngModel)]="field.operator"
                        (ngModelChange)="triggerChange()"
                        placeholder="Operator"
            >
              <mat-option *ngFor="let operator of CustomEventFieldNumberOperatorsA" [value]="operator">
                {{ CustomEventFieldNumberOperatorsStr[operator] }}
              </mat-option>
            </mat-select>
            <input class="ui-input next-to-operator"
                   type="number"
                   placeholder="0"
                   [(ngModel)]="field.value"
                   (ngModelChange)="triggerChange()"
            >
          </div>
          <div class="flex-row" *ngSwitchCase="CustomEventFieldType.Boolean">
            <mat-select class="ui-selector boolean" [(ngModel)]="field.value" (ngModelChange)="triggerChange()">
              <mat-option [value]="true">
                True
              </mat-option>
              <mat-option [value]="false">
                False
              </mat-option>
            </mat-select>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
