import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserGroupsModel } from '@models/user-groups.model';
import { UserGroupsActions } from '@states/user-groups/user-groups.action-types';
import { setIsInitialLoaded } from '@states/user-groups/user-groups.actions';

export interface UserGroupState extends EntityState<UserGroupsModel.UserGroupDocument> {
  selectedUserGroup: UserGroupsModel.UserGroupDocument;
  filters: UserGroupsModel.UserGroupFilters;
  isLoading: boolean;
  isInitialLoaded: boolean;
  page: number,
  perPage: number,
  isLastPage: boolean,
}

export const adapter: EntityAdapter<UserGroupsModel.UserGroupDocument> = createEntityAdapter<UserGroupsModel.UserGroupDocument>({
  selectId: (userGroupDocument: UserGroupsModel.UserGroupDocument) => userGroupDocument._id,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: UserGroupState = adapter.getInitialState({
  selectedUserGroup: null,
  filters: {
    orderDirection: -1,
    orderBy: null,
    query: '',
  },
  isLoading: false,
  isInitialLoaded: false,
  page: 0,
  perPage: 20,
  isLastPage: false,
});

export const userGroupStateReducer = createReducer(
  initialState,
  on(UserGroupsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(UserGroupsActions.getUserGroupsSuccess, (state, { userGroups }) => {
    return adapter.addMany(userGroups, {
      ...state,
      isLastPage: userGroups.length < state.perPage,
      page: state.page + 1,
      isInitialLoaded: true,
    });
  }),
  on(UserGroupsActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(UserGroupsActions.setIsInitialLoaded, (state, { isInitialLoaded }) => {
    return {
      ...state,
      isInitialLoaded,
    };
  }),
  on(UserGroupsActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
);
