import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { getAuth, signInWithCustomToken, signOut, UserCredential } from '@angular/fire/auth';
import { Firebase } from './firebase.model';
import { BehaviorSubject, filter, lastValueFrom, Observable, Subject } from 'rxjs';
import { BYPASS_AUTHENTICAION_TOKEN } from '../core/authentication-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firebaseUserSubject = new BehaviorSubject<Firebase.SignInWithCustomTokenResponse | undefined>(undefined);

  public user$: Observable<Firebase.SignInWithCustomTokenResponse | undefined> = this.firebaseUserSubject
    .asObservable()
    .pipe(filter(user => !!user));

  constructor(private http: HttpClient) {
  }

  getFirebaseToken(auth: Firebase.GenerateFirebaseCustomTokenRequest, options: Firebase.GenerateFirebaseCustomTokenRequestOptions = { persist: false, notifyLocalObserver: true }) {
    return this.http.get<Firebase.GenerateFirebaseCustomTokenResponse>(`${environment.apiUrl}/users/firebase/${auth.userId}`,
      options.authToken ? {
        context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
        headers: { Authorization: `Bearer ${options.authToken}` },
      } : undefined,
    );
  }

  async signInToFirebasePromise(
    auth: Firebase.GenerateFirebaseCustomTokenRequest,
    options: Firebase.GenerateFirebaseCustomTokenRequestOptions = { persist: false, notifyLocalObserver: true },
  ): Promise<UserCredential | null> {
    try {
      const source$ = this.getFirebaseToken(auth, options);
      const res = await lastValueFrom(source$);
      const fAuth = getAuth();
      const signInResponse = await signInWithCustomToken(fAuth, res.token);

      if (options.notifyLocalObserver) {
        this.firebaseUserSubject.next(signInResponse);
      }

      return signInResponse;
    } catch (err) {
      console.log('google error', err);
      return null;
    }
  }

  signOutFromFirebase() {
    const auth = getAuth();
    return signOut(auth)
      .then(() => {
        this.firebaseUserSubject.next(undefined);
      })
      .catch(err => {
        // console.log('[FIREBASE-SERVICE]: signOut from firebse error', err);
        this.firebaseUserSubject.next(undefined);
      })
      .finally(() => {
        this.firebaseUserSubject.next(undefined);
      });
  }

  async isLoggedIn(): Promise<boolean> {
    const fUser = this.firebaseUserSubject?.value;
    if (!fUser?.user) {
      // console.log('[FIREBASE-SERVICE]: firebase not loggedIn');
      return false;
    }

    // console.log('[FIREBASE-SERVICE]: firebase user context is available - attempting to authenticate');

    const firebaseToken = await fUser.user.getIdToken();

    // console.log(`[FIREBASE-SERVICE]: firebase user context extended token: ${firebaseToken}`);

    await fUser.user.reload();

    // console.log(`[FIREBASE-SERVICE]: firebase user context reloaded}`);

    return !!firebaseToken;
  }

  async authenticateToFirebase(
    auth: Firebase.GenerateFirebaseCustomTokenRequest,
    options: Firebase.GenerateFirebaseCustomTokenRequestOptions = { persist: false, notifyLocalObserver: true },
  ): Promise<UserCredential | null> {
    console.log('[FIREBASE-SERVICE]: checking firebase login status');
    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn) {
      const user = await this.signInToFirebasePromise(auth, options);
      return user;
    } else {
      return this.firebaseUserSubject.value!;
    }
  }
}
