<div fxLayout="column" fxLayoutAlign="start start" fxFill class="confirm-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Automatic update failed</div>
    <span class="ui-icon-close c-p" mat-dialog-close
    ></span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxFlex=""
       class="dialog-content narrower-input" fxLayoutGap="25px"
  >
    <div fxFlexFill fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div>The automatic update has failed. Please update the version manually.</div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="w-100">
        <div>Mac</div>
        <ui-input [disabled]="true" placeholder="Cmd + Shift + R" class="w-100"></ui-input>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="w-100">
        <div>Windows</div>
        <ui-input [disabled]="true" placeholder="Ctrl + F5" class="w-100"></ui-input>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button type="default" (click)="close()"
               text="Update later"
    >
    </ui-button>
  </div>

</div>

