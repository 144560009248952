<ng-container *ngrxLet="selectMaxRetentionDays$; let maxRetentionDays">
  <div fxLayout="column" *ngIf="{hideAdvancedOptions:hideAdvancedOptions$|ngrxPush} as conditions">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="type-selector-wrapper">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="type-selector">
        <div *ngIf="relativeEnabled" (click)="changeType(pickerType.RELATIVE)"
             [class.selected]="selectedPickerType == pickerType.RELATIVE"
        >
          <label>Relative</label>
        </div>
        <div *ngIf="absoluteEnabled" (click)="changeType(pickerType.ABSOLUTE)"
             [class.selected]="selectedPickerType == pickerType.ABSOLUTE"
        >
          <label>Calendar</label>
        </div>
      </div>
      <label class="type-selector-timezone">{{ timezoneAbbreviation() }}</label>
    </div>
    <ng-container *ngIf="selectedPickerType == pickerType.RELATIVE">
      <div fxLayout="column" fxLayoutGap="10px" class="relative-wrapper">
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
            <div><label>Minutes</label></div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
              <div *ngFor="let minute of minuteOptions" (click)="selectMinutes(minute)"
                   [class.selected]="minute == pickerState?.minutes" fxLayoutAlign="center center"
              >
                <label>{{ minute }}</label>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
            <div><label>Hours</label></div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
              <div *ngFor="let hour of hourOptions" (click)="selectHours(hour)"
                   [class.selected]="hour == pickerState?.hours" fxLayoutAlign="center center"
              >
                <label>{{ hour }}</label>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
            <div><label>Days</label></div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
              <div *ngFor="let day of dayOptions" (click)="selectDays(day)" [class.selected]="day == pickerState?.days"
                   fxLayoutAlign="center center"
              >
                <label> {{ day }}</label>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
            <div *ngIf="maxRetentionDays > 7"><label>Weeks</label></div>
            <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
              <ng-container *ngFor="let week of weekOptions">
                <div *ngIf="week * 7 <= maxRetentionDays" (click)="selectWeeks(week)"
                     [class.selected]="week == pickerState?.weeks" fxLayoutAlign="center center"
                >
                  <label>{{ week }}</label>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="flex-row space-between-center narrow-input mt-5 mb-16">
            <div class="flex-col start-start w-50 mr-15">
              <label class="mb-8">Custom</label>
              <input class="ui-input w-100"
                     placeholder="180"
                     [(ngModel)]="pickerState.custom"
                     (ngModelChange)="setCustom(maxRetentionDays)"
              />
            </div>
            <div class="flex-col start-start w-50">
              <label class="mb-8" style="height: 20px"></label>
              <mat-select class="ui-selector w-100"
                          [(ngModel)]="pickerState.customUnit"
                          (ngModelChange)="setCustom(maxRetentionDays)"
              >
                <mat-option *ngFor="let unit of customUnits" [value]="unit">
                  {{ unit | titlecase }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedPickerType == pickerType.ABSOLUTE">
      <div class="p-20 mb-16" style="padding-bottom: 0!important;">
        <mat-calendar #leftCalendar
                      [dateClass]="dateClass"
                      [startAt]="startDateLeftCalendar"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      (selectedChange)="dateChanged($event)"
        >
        </mat-calendar>
        <div class="flex-row space-between-center narrow-input mt-5 mb-16">
          <div class="flex-col start-start w-50 mr-15">
            <label class="mb-8">From</label>
            <input class="time-input ui-input w-100"
                   type="time"
                   [(ngModel)]="startTime"
                   (ngModelChange)="emitDateRangeChanged()"
            />
            <span class="date-small-label">{{ startDate| date:'YYYY-MM-dd' }}</span>

          </div>
          <div class="flex-col start-start w-50">
            <label class="mb-8">To</label>
            <input class="time-input ui-input w-100"
                   type="time"
                   [(ngModel)]="endTime"
                   (ngModelChange)="emitDateRangeChanged()"
            />
            <span class="date-small-label">{{ endDate| date:'YYYY-MM-dd' }}</span>
          </div>
        </div>
        <div *ngIf="isSaveEmit" fxLayout="row" fxLayoutAlign="end center" class="picker-actions">
          <button mat-flat-button color="primary" (click)="emitDateRangeChanged()"
                  [disabled]="startDate == null || endDate == null"
          >
            Save
          </button>
        </div>
      </div>
    </ng-container>
    <div *ngIf="showAdvancedOption" class="p-20" style="padding-top: 0!important;">
      <hr class="hr mt-8 mb-8">
      <div class="mt-8 mb-8 advanced-toggler c-p" (click)="hideAdvancedOptions()">
        {{ conditions.hideAdvancedOptions ? 'Show advanced options' : 'Hide advanced options' }}
      </div>
      <div *ngIf="!conditions.hideAdvancedOptions">
        <ng-container *ngrxLet="calendarSelectedDays$;let calendarSelectedDays">
          <div class="include-title">Include results at</div>
          <div class="flex-row mt-4">
                <span class="day-week c-p"
                      *ngFor="let day of calendarDays; let i = index"
                      [class.selected]="calendarSelectedDays[i]"
                      [class.disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                      (click)="selectCalendarDay(i)"
                >{{ day }}</span>
          </div>
          <div class="flex-row space-between-center mt-16">
            <div class="flex-col start-start w-50 mr-15">
              <label class="mb-8">Between hours</label>
              <input class="ui-input w-100"
                     type="time"
                     [disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                     [(ngModel)]="startTimeBetween"
                     (ngModelChange)="emitDateRangeChanged()"

              />
            </div>
            <div class="flex-col start-start w-50">
              <label style="height: 20px" class="mb-8"></label>
              <input class="ui-input w-100"
                     type="time"
                     [disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                     [(ngModel)]="endTimeBetween"
                     (ngModelChange)="emitDateRangeChanged()"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</ng-container>
