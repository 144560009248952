<span [matTooltip]="toolTip"
      matTooltipClass="sidebar-tooltip"
      class="menu-item ui-icon-copy1 c-p"
      [style.font-size]="fontSize +'px'"
      *ngIf="copiedIndex !== 1"
      [cdkCopyToClipboard]="copyContent"
      (click)="copyIndex(1)"
></span>
<span *ngIf="copiedIndex === 1"
      class="menu-item ui-icon-checkmark"
      [style.font-size]="fontSize + 'px'"
></span>
