import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prob-file-modal',
  templateUrl: './prob-file-modal.component.html',
  styleUrls: ['./prob-file-modal.component.scss'],
})
export class ProbFileModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public filename: string;

  constructor(private matDialogRef: MatDialogRef<ProbFileModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.filename);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
