import { createReducer, on } from '@ngrx/store';
import * as TrackObjectActions from '@states/track-object/track-object.actions';
import { Search } from '../../../shared/search.model';

export declare interface TrackObjectState extends Search.SearchObject {
  start?: string;
  end?: string;
  cameraId?: string;
  threshold?: number;
  multi?: boolean;
  alert?: boolean;
}

export const initialState: TrackObjectState = {
  start: undefined,
  end: undefined,
  idBase: undefined,
  idIndex: undefined,
  url: undefined,
  descriptor: false,
  cameraId: undefined,
  threshold: 0.5,
  multi: false,
  alert: false,
};

export const trackObjectStateReducer = createReducer(
  initialState,
  on(TrackObjectActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(TrackObjectActions.setTrackObject, (state, { trackObjectState }) => {
    return {
      ...state,
      ...trackObjectState,
    };
  }),
  on(TrackObjectActions.setRange, (state, { start, end }) => {
    return {
      ...state,
      start,
      end,
    };
  }),
);
