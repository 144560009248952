import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserFilters } from '@models/organization.model';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { api } from '@consts/url.const';
import { UserGroupsModel } from '@models/user-groups.model';

@Injectable()
export class UserGroupsServices {
  constructor(private http: HttpClient) {
  }

  public findAll(filters: UserFilters, pagination: { page: number; perPage: number }): Observable<PaginationResult<UserGroupsModel.UserGroupDocument>> {
    return this.http.post<PaginationResult<UserGroupsModel.UserGroupDocument>>(`${api.userGroups.crud}?page=${pagination.page}&perPage=${pagination.perPage}`, filters);
  }
}

