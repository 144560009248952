<div fxLayout="row" fxLayoutAlign="space-between center" class="advanced-search-header">
  <h4>Object attributes</h4>
  <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography advanced-search">
  <!--  <div-->
  <!--    fxLayout="row"-->
  <!--    fxLayoutAlign="start start"-->
  <!--    fxFlexFill-->
  <!--  >-->
  <!--    <div fxLayout="row"-->
  <!--         class="w-100">-->
  <!--      <div fxFlex="23"-->
  <!--           fxLayout="column"-->
  <!--           fxLayoutAlign="space-between start"-->
  <!--           class="side-panel">-->
  <!--        <div class="w-100">-->

  <!--          <ng-container *ngFor="let objectAttribute of objectAttributeTypes;">-->
  <!--            <div fxLayoutAlign="start center"-->
  <!--                 class="subject label">-->
  <!--              {{objectAttribute.name|titlecase}}-->
  <!--            </div>-->
  <!--            <ng-container *ngFor="let s of selections.controls; let sIdx = index">-->
  <!--              <div *ngIf="s.get('type').value === objectAttribute.value"-->
  <!--                   [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"-->
  <!--                   fxLayout="row"-->
  <!--                   fxLayoutAlign="start center"-->
  <!--                   class="subject c-p"-->
  <!--                   (click)="select($any(s), sIdx,objectAttribute.value)">-->
  <!--                <div fxFlex="row"-->
  <!--                     fxLayoutAlign="start center"-->
  <!--                     fxLayoutGap="10px">-->
  <!--                  <mat-icon>person_outline</mat-icon>-->
  <!--                  <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
  <!--                </div>-->
  <!--                <div class="remove-button" (click)="rmObjectAttribute(sIdx,objectAttribute.value)">-->
  <!--                  <mat-icon>close</mat-icon>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </ng-container>-->
  <!--            <ng-container *ngFor="let s of vehicleArray.controls; let sIdx = index">-->
  <!--              <div *ngIf="s.get('type').value === objectAttribute.value"-->
  <!--                   [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"-->
  <!--                   fxLayout="row"-->
  <!--                   fxLayoutAlign="start center"-->
  <!--                   class="subject c-p"-->
  <!--                   (click)="select($any(s), sIdx,objectAttribute.value)">-->
  <!--                <div fxFlex="row"-->
  <!--                     fxLayoutAlign="start center"-->
  <!--                     fxLayoutGap="10px">-->
  <!--                  <mat-icon>directions_car</mat-icon>-->
  <!--                  <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
  <!--                </div>-->
  <!--                <div class="remove-button" (click)="rmObjectAttribute(sIdx,objectAttribute.value)">-->
  <!--                  <mat-icon>close</mat-icon>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </ng-container>-->
  <!--            <ng-container *ngFor="let s of petArray.controls; let sIdx = index">-->
  <!--              <div *ngIf="s.get('type').value === objectAttribute.value"-->
  <!--                   [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"-->
  <!--                   fxLayout="row"-->
  <!--                   fxLayoutAlign="start center"-->
  <!--                   class="subject c-p"-->
  <!--                   (click)="select($any(s), sIdx,objectAttribute.value)">-->
  <!--                <div fxFlex="row"-->
  <!--                     fxLayoutAlign="start center"-->
  <!--                     fxLayoutGap="10px">-->
  <!--                  <mat-icon>pets</mat-icon>-->
  <!--                  <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
  <!--                </div>-->
  <!--                <div class="remove-button" (click)="rmObjectAttribute(sIdx,objectAttribute.value)">-->
  <!--                  <mat-icon>close</mat-icon>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </ng-container>-->

  <!--            <div mat-stroked-button-->
  <!--                 fxLayout="row"-->
  <!--                 fxLayoutAlign="start center"-->
  <!--                 fxLayoutGap="10px"-->
  <!--                 class="add-button"-->
  <!--                 (click)="add(objectAttribute.value)">-->
  <!--              <mat-icon>add</mat-icon>-->
  <!--              <div>Add {{objectAttribute.name|titlecase}}</div>-->
  <!--            </div>-->

  <!--          </ng-container>-->

  <!--        </div>-->

  <!--        <div fxLayout="column" fxLayoutGap="10px" class="outer-operator-wrapper">-->

  <!--          <mat-select-->
  <!--            matTooltip="Currently available - OR logic - Show results from all objects attributes"-->
  <!--            [ngModel]="selectOuterOperator$|ngrxPush" (ngModelChange)="outerOperatorChanged($event)"-->
  <!--            class="outer-operator" disabled>-->
  <!--            <mat-option *ngFor="let opt of SearchSelectorOperators" [value]="opt.value">{{opt.name}}</mat-option>-->
  <!--          </mat-select>-->

  <!--        </div>-->
  <!--        &lt;!&ndash; DISABLED - select menu &ndash;&gt;-->
  <!--        <div *ngIf="false" fxLayoutAlign="start center" class="w-100 mt-10 ml-20">-->
  <!--          <button mat-stroked-button class="plus-button" [matMenuTriggerFor]="subjectMenu">-->
  <!--            <mat-icon>add</mat-icon>-->
  <!--            Add Search Subject-->
  <!--          </button>-->
  <!--          <mat-menu #subjectMenu="matMenu" yPosition="above">-->
  <!--            &lt;!&ndash;            <button mat-menu-item *ngFor="let type of SearchSelectorTypes"&ndash;&gt;-->
  <!--            &lt;!&ndash;                    (click)="add(type.value)">&ndash;&gt;-->
  <!--            &lt;!&ndash;              <mat-icon>{{type.value === 'vehicle' ? 'directions_car' : type.value === 'person' ? 'person_outline' : type.value === 'pet' ? 'pets' : ''}}</mat-icon>&ndash;&gt;-->
  <!--            &lt;!&ndash;              {{type.name}}</button>&ndash;&gt;-->
  <!--          </mat-menu>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="properties-panel" fxFlex="" *ngIf="selectedSelection">-->
  <!--        <app-attribute-values [selectedObject]="selectedSelection"></app-attribute-values>-->
  <!--      </div>-->
  <!--      <div *ngIf="!selectedSelection" fxLayout="column" fxLayoutAlign="center center" fxFlex="">-->
  <!--        <div fxLayout="column" fxLayoutAlign="center center" class="properties-placeholder">-->
  <!--          <img-->
  <!--            class="pin-img"-->
  <!--            src="assets/alert.svg"-->
  <!--            style="height: 120px; width: 120px"/>-->
  <!--          <h1 class="mt-10">Filter results by object attributes</h1>-->
  <!--          <p style="text-align: center; line-height: 24px;" class="c-gray">Start by choosing an object, and define-->
  <!--            attributes for it.-->
  <!--            You<br>-->
  <!--            can add-->
  <!--            multiple objects and attributes.-->
  <!--          </p>-->
  <!--          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="add-object-buttons w-100 mt-20">-->
  <!--            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"-->
  <!--                    (click)="add(searchObjectTypes.PERSON)"-->
  <!--                    fxFlex="">-->
  <!--              <mat-icon>person_outline</mat-icon>-->
  <!--              <div>Add person</div>-->
  <!--            </button>-->
  <!--            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"-->
  <!--                    (click)="add(searchObjectTypes.VEHICLE)"-->
  <!--                    fxFlex="">-->
  <!--              <mat-icon>directions_car</mat-icon>-->
  <!--              <div>Add vehicle</div>-->
  <!--            </button>-->
  <!--            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"-->
  <!--                    (click)="add(searchObjectTypes.PET)"-->
  <!--                    fxFlex="">-->
  <!--              <mat-icon>pets</mat-icon>-->
  <!--              <div>Add Pet</div>-->
  <!--            </button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->
  <app-object-attributes-content (update)="update($event)"
                                 [inline]="false"
                                 [variable]="false"
                                 [displayOperator]="false"
  ></app-object-attributes-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      matTooltip="High Confidence search will only return objects that match the search criteria with high confidence"
    >
      <ui-toggle [ngModel]="selectSearchHighConfidence$ | ngrxPush"
                 (ngModelChange)="highConfidenceChanged($event)"
      ></ui-toggle>
      <div style="color: #667085; font-size: 13px">High Confidence</div>
    </div>
    <div>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial [disabled]="searchDisabled()" (click)="search()">Search
      </button>
    </div>
  </div>
</mat-dialog-actions>
