export namespace GroupModels {

  export interface FileAsset {
    file: Blob;
    asset: GroupModels.FaceAsset;
  }

  export interface FaceAsset {
    filename: string;
  }

  export interface GetFaceAssetPresignedUrlRequest {
    asset: GroupModels.FaceAsset;
  }

  export interface UploadFaceAssetPresignedUrlRequest {
    url: string;
    file: Blob;
    filename: string;
  }

  export interface LearnFaceRequest {
    fileUrls: string[];
    multiple?: boolean;
  }

  export interface LearnFaceResponse {
    fileName?: string;
    bestImage: string;
    code: number;
    faceConfidence: string;
    faceId: string;
    persons: Person[];
  }

  export interface SearchFaceRequest {
    descriptor: string;
    faceConfidence?: string;
  }

  export type SearchFaceResponse = Person[];

  export interface CreateRequest {
    bestImage?: string;
    faceConfidence?: string | number;
    faceId?: string;
    name?: string;
  }

  export interface AttachRequest extends CreateRequest {
    notUpdateBestImage?: boolean;
    personId?: number;
    representatives?: string[] | Representative[];
  }

  export interface Representative {
    personId: number;
    faceId: string;
    faceScore: number;
    faceConfidence: number;
  }

  export interface SuggestedPersonOrPersonId {
    token: string;
    personId?: number;
    name?: string;
    persons?: Person[];
    representatives?: Representative[];
    highQuality?: boolean;
  }

  export type CreateNewRequest = SuggestedPersonOrPersonId

  export interface GroupSearchItem {
    bestImage?: string;
    fileName?: string;
    personId?: number;
    personIds?: number[];
    descriptor?: string;
    faceConfidence?: number;
    faceId?: string;
    name?: string;
    representatives?: string[];
  }
}

/**
 * @deprecated
 * use Person instead
 */
export interface Group {
  id: string;
  name: string;
  bestImage: string;
  orgId?: string;
  bestImageString?: string;
  zoomImageHex?: string;
  representativesHex: string[];
  isSaved: boolean;
  appearances: number;
  edgeId: string;
  cameraId: string;
  lastSeen?: number;
}

export interface Person {
  personId?: number;
  name?: string;
  orgId?: string;
  bestImage?: string;
  bucketId?: number;
  lastSeen?: number;
  representatives?: string[];
}

export interface Person1 {
  personId?: number;
  bestImage?: string;
  bestTracker?: string;
  lastSeen?: string;
  name: string;
}

export interface Buckets {
  bucketId: number;
  bestImage: string;
  lastSeen: number;
}

export enum GroupStatus {
  Saved = 'saved',
  Unsaved = 'unsaved',
}

// export interface GetPeopleResponse {
//   people: Person[];
// }

export type GetPeopleResponse = Person[];

export type GetUnsavedResponse = {
  persons: Person[];
  token: string;
}

export interface GetPeopleCountResponse {
  count: Person[];
}

export interface AddPeopleRequest {
  personId?: number,
  name?: string
}

export interface UpdatePersonRequest {
  bucketId?: number;
  name?: string;
  bestImage: string;
  token?: string;
}

export interface MergePeopleRequest {
  bucketId: number;
  personId: number;
}
