import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GrantedAccessState } from '@states/granted-access/granted-access.reducer';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';
import { selectAlertEventsState } from '@states/alert-events/alert-events.selectors';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { sortBy } from 'lodash';
import { sortArrByField } from '../../../helpers/common.helpers';

export const selectGrantedAccessState = createFeatureSelector<GrantedAccessState>('grantedAccessState');

// export const selectGrantedEntity = createSelector(selectGrantedAccessState, ({ entity }: GrantedAccessState) => entity);

export const selectGrantedList = createSelector(selectGrantedAccessState, ({ grantedList }: GrantedAccessState) => grantedList);

export const selectSelectedGrantedAccess = createSelector(
  selectGrantedAccessState,
  ({ selectedGrantedAccess }: GrantedAccessState) => selectedGrantedAccess,
);

export const selectSelectedLocation = createSelector(
  selectGrantedAccessState,
  ({ selectedLocation }: GrantedAccessState) => selectedLocation,
);

export const selectConfig = createSelector(
  selectGrantedAccessState,
  ({ config }: GrantedAccessState) => config,
);

export const selectType = createSelector(
  selectGrantedAccessState,
  ({ selectedType }: GrantedAccessState) => selectedType,
);

export const selectAccessToken = createSelector(
  selectGrantedAccessState,
  ({ accessToken }: GrantedAccessState) => accessToken,
);


export const selectComments = createSelector(
  selectGrantedAccessState,
  ({ comments }: GrantedAccessState) => comments,
);

export const selectTotalItemsCount = createSelector(selectGrantedAccessState, ({ totalItemsCount }: GrantedAccessState) => totalItemsCount);

export const selectPage = createSelector(selectGrantedAccessState, ({ page }: GrantedAccessState) => {
  return page;
});

export const selectPerPage = createSelector(selectGrantedAccessState, ({ perPage }: GrantedAccessState) => perPage);

export const selectSearchQuery = createSelector(selectGrantedAccessState, ({ query }: GrantedAccessState) => query);

//todo find way to sort inside entity somehow
export const selectGrantedAccessList = createSelector(selectGrantedAccessState, ({ entities }: GrantedAccessState) => {
  const array = Object.values(entities ?? {});
  const sortedArray = sortArrByField(array, 'timestamp');
  return sortedArray;
});

export const selectEntityParams = createSelector(selectGrantedAccessState, ({ entityParams }: GrantedAccessState) => entityParams);

export const selectSharedAlert = createSelector(selectGrantedAccessState, ({ sharedAlert }: GrantedAccessState) => sharedAlert);
export const selectSharedArchive = createSelector(selectGrantedAccessState, ({ sharedArchive }: GrantedAccessState) => sharedArchive);

export const selectSharedNameAndDate = createSelector(selectGrantedAccessState, ({ sharedArchive, sharedAlert }: GrantedAccessState) => {
  return {
    name: sharedAlert?.eventName || sharedArchive?.name,
    timestamp: sharedAlert?.timestamp || sharedArchive?.start * 1000,
  };
});

export const selectIsRecordRemoved = createSelector(selectGrantedAccessState, ({ isRecordRemoved }: GrantedAccessState) => isRecordRemoved);
export const selectFilters = createSelector(selectGrantedAccessState, ({ filters }: GrantedAccessState) => filters);
export const selectIsLoading = createSelector(selectGrantedAccessState, ({ isLoading }: GrantedAccessState) => isLoading);
export const selectInitialLoaded = createSelector(selectGrantedAccessState, ({ initialLoaded }: GrantedAccessState) => initialLoaded);

export const selectIsNotFound = createSelector(selectGrantedAccessState, ({ sharedArchive, sharedAlert, sharedLiveView, sharedPlayback, selectedType, entityParams }: GrantedAccessState) => {
  switch (selectedType) {
    case GrantedAccessType.ALERT:
      // if (entityParams?.hasOwnProperty('archiveId')) {
      //   return !sharedAlert || !sharedArchive;
      // }
      return !sharedAlert;
    case GrantedAccessType.ARCHIVE:
      return !sharedArchive;
    case GrantedAccessType.LIVE_VIEW:
      return !sharedLiveView;
    case GrantedAccessType.PLAYBACK:
      return !sharedPlayback;
    default:
      return true;
  }
});

export const selectSharedLiveView = createSelector(selectGrantedAccessState, ({ sharedLiveView }: GrantedAccessState) => sharedLiveView);
export const selectSharedPlayback = createSelector(selectGrantedAccessState, ({ sharedPlayback }: GrantedAccessState) => sharedPlayback);

