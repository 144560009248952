/**
 * @deprecated
 */
export enum SearchQueryOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface SearchSelectionProperty {
  name?: string;
  colors?: string[];
  value?: string[];
  operator?: string;
  enabled?: boolean;
}

export interface SearchSelectionAlpr {
  exists?: boolean;
  plate?: string;
  region?: string;
  model?: string[];
  make?: string[];
  types?: string[];
  operator?: string;
  colors?: string[];
}

/**
 * @deprecated
 */
export interface SearchSelection {
  type: string;
  properties?: SearchSelectionProperty[];
  alpr?: SearchSelectionAlpr;
  operator?: SearchQueryOperator;
  idx?: number;
}
