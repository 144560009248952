import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, mergeMap, share, switchMap } from 'rxjs';
import { VehiclesActions } from '@states/vehicles/vehicles.action-types';
import { VehiclesService } from 'src/app/development/vehicles.service';

@Injectable()
export class VehiclesEffect {

  public getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.getVehicles),
      mergeMap(() => {
          return this.vehiclesService.getVehicles()
            .pipe(
              switchMap(res => {
                return [SharedActions.setIsLoading({ isLoading: false }), VehiclesActions.getVehiclesSuccess({ vehicles: res })];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public getUnsavedVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.getUnsavedVehicles),
      mergeMap(() => {
          return this.vehiclesService.getUnsavedVehicles(1, 30)
            .pipe(
              switchMap(res => {
                return [SharedActions.setIsLoading({ isLoading: false }), VehiclesActions.getUnsavedVehiclesSuccess({ vehicles: res })];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  public addVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.addVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.addVehicleSuccess({ vehicle }), VehiclesActions.removeUnsavedVehicle({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.editVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.editVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.editVehicleSuccess({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removeVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.removeVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.removeVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.removeVehicleSuccess({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private vehiclesService: VehiclesService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

}
