import { createAction, props } from '@ngrx/store';
import { Edge } from '../../../edge/edge.model';
import { LocationModel } from '../../../locations/location.model';
import { EdgeConfigFileType, EthConfiguration } from '@models/edge.model';
import { EdgeDocumentType } from '@enums/edge.enum';
import { HeartbeatEntry } from '../../../development/heartbeat.service';
import { EdgeCamera } from '../../../cameras/camera.model';
import { ThumbnailEntity } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { NtpModels } from '@models/ntp.model';

export const resetToInitialState = createAction('[EDIT-EDGE] Reset to Initial state');

export const setSelectedEdgeId = createAction('[EDIT-EDGE] Set Selected edge Id', props<{ selectedEdgeId: string }>());

export const setSelectedLocationId = createAction('[EDIT-EDGE] Set Selected Location Id', props<{ selectedLocationId: string }>());

export const getLocalIpAddresses = createAction('[EDIT-EDGE] Get Local Ip Addresses');

export const setEth0 = createAction('[EDIT-EDGE] Set ETH0', props<{ eth0: string }>());

export const setEth1 = createAction('[EDIT-EDGE] Set ETH1', props<{ eth1: string }>());

export const setSelectedEdge = createAction('[EDIT-EDGE] Set Selected core', props<{ selectedEdge: Edge.EdgeDocument }>());

export const getEdgeConfig = createAction('[EDIT-EDGE] Get Config', props<{ configType: EdgeConfigFileType }>());

export const getEdgeSwUpdate = createAction('[EDIT-EDGE] Get edge SW Update');

export const loadEdgeConfig = createAction('[EDIT-EDGE] Load Config', props<{ configType: EdgeConfigFileType }>());

export const loadEdgeSwUpdate = createAction('[EDIT-EDGE] Load edge SW Update');

export const loadEdgeSwUpdateSuccess = createAction('[EDIT-EDGE] Load edge SW Update Success', props<{ result: Object }>());

export const loadEdgeSwUpdateFailed = createAction('[EDIT-EDGE] Load edge SW Update Failed', props<{ error: string }>());

export const uploadEdgeConfigJson = createAction('[EDIT-EDGE] Upload edge Config JSON', props<{ config: string, configType: EdgeConfigFileType }>());

export const sendEdgeConfigJson = createAction('[EDIT-EDGE] Send edge Config JSON', props<{ config: string, configType: EdgeConfigFileType }>());

export const pressSave = createAction('[EDIT-EDGE] Press Save');

export const pressRefresh = createAction('[EDIT-EDGE] Press Refresh');

export const getEdgeStatus = createAction('[EDIT-EDGE] Get edge Status');

export const setSendUpdate = createAction('[EDIT-EDGE] Set Send Update', props<{ sendUpdate: boolean }>());

export const uploadEdgeConfig = createAction('[EDIT-EDGE] Upload edge Config', props<{ file: any }>());

export const sendEdgeConfig = createAction('[EDIT-EDGE] Send edge Config', props<{ file: any }>());

export const saveEdgeEditForm = createAction('[EDIT-EDGE] Save edge Edit Form', props<{ edgeUpdate: Partial<Edge.EdgeDocument> }>());

export const sendEdgeEditForm = createAction(
  '[EDIT-EDGE] Send edge Edit Form to Server',
  props<{ edgeUpdate: Partial<Edge.EdgeDocument> }>(),
);

export const updateEdgeInLocation = createAction(
  '[EDIT-EDGE] Update edge In Location',
  props<{
    updateEdgeInLocationRequest: LocationModel.UpdateEdgeInLocationRequest;
  }>(),
);

export const approveEdgeConfig = createAction('[EDIT-EDGE] Approve edge Config');

export const sendApproveEdgeConfig = createAction('[EDIT-EDGE] Send approve edge Config');

export const getEdgeDocument = createAction('[EDIT-EDGE] Get edge Document', props<{ docType: EdgeDocumentType }>());

export const getEdgeInfo = createAction('[EDIT-EDGE] Get edge Info');

export const getDeviceMsgsVersion = createAction('[EDIT-EDGE] Get Device Msgs Version');

export const updateSoftwareVersion = createAction('[EDIT-EDGE] Update Software Version', props<{ version: string }>());

export const getEthDeviceInfo = createAction('[EDIT-EDGE] Get Eth Device Info', props<{ ethInterface: number }>());

export const interfaceEthConf = createAction('[EDIT-EDGE] Interface Eth Conf', props<{ ethInterface: number }>());

export const ifUp = createAction('[EDIT-EDGE] ifUp', props<{ ethInterface: number }>());

export const ifDown = createAction('[EDIT-EDGE] Ifdown', props<{ ethInterface: number }>());

export const writeEthManualConfiguration = createAction(
  '[EDIT-EDGE] Write Eth Manual Configuration',
  props<{ ethConfig: EthConfiguration }>(),
);

export const ping = createAction('[EDIT-EDGE] ping', props<{ pingAddress: string }>());

export const dig = createAction('[EDIT-EDGE] dig', props<{ address: string }>());
export const traceroute = createAction('[EDIT-EDGE] traceroute', props<{ address: string }>());
export const firewallTest = createAction('[EDIT-EDGE] Firewall test');
export const speedTest = createAction('[EDIT-EDGE] Speed test');

export const deleteEthManualConfiguration = createAction('[EDIT-EDGE] deleteEthManualConfiguration', props<{ ethInterface: number }>());

export const getSoftwareVersion = createAction('[EDIT-EDGE] Get Software Version');

export const ifconfig = createAction('[EDIT-EDGE] ifconfig');

export const routeInfo = createAction('[EDIT-EDGE] RouteInfo');

export const arp = createAction('[EDIT-EDGE] arp');

export const deleteLogs = createAction('[EDIT-EDGE] deleteLogs');

export const getdisksinfo = createAction('[EDIT-EDGE] getdisksinfo');

export const mount = createAction('[EDIT-EDGE] mount');

export const getEdgeLogs = createAction('[EDIT-EDGE] Get edge Logs', props<{ daysBack: number }>());

export const requestEdgeLogs = createAction('[EDIT-EDGE] Request edge Logs from Server', props<{ daysBack: number }>());

export const getEdgeAWSLogs = createAction('[EDIT-EDGE] Request edge AWS Logs from Server');

export const setEdgeAWSLogs = createAction('[EDIT-EDGE] Set edge AWS Logs from Server', props<{ logs: string[] }>());

export const dockerPs = createAction('[EDIT-EDGE] dockerPs');

export const dockerInspect = createAction('[EDIT-EDGE] dockerInspect', props<{ container: string }>());

export const dockerStats = createAction('[EDIT-EDGE] dockerStats');

export const nMCLI = createAction('[EDIT-EDGE] nMCLI', props<{ args: string }>());

export const du = createAction('[EDIT-EDGE] du', props<{ args: string }>());

export const ls = createAction('[EDIT-EDGE] ls', props<{ args: string }>());

export const nMCLIDeviceShow = createAction('[EDIT-EDGE] nMCLIDeviceShow', props<{ ethInterface: number }>());

export const nMCLIDeviceStatus = createAction('[EDIT-EDGE] nMCLIDeviceStatus');

export const checkEdgeInstallation = createAction('[EDIT-EDGE] checkEdgeInstallation');

export const getHeartbeats = createAction('[EDIT-EDGE] Get Heartbeats');

export const getHeartbeatsSuccess = createAction('[EDIT-EDGE] Get Heartbeats Success', props<{ heartbeats: HeartbeatEntry[] }>());

export const getCameraThumbnails = createAction(
  '[EDIT-EDGE] Get Camera Thumbnails',
  props<{ start: number; end: number; selectedCamera: EdgeCamera.CameraItem }>(),
);

export const getCameraThumbnailsSuccess = createAction(
  '[EDIT-EDGE] Get Camera Thumbnails Success',
  props<{ thumbnailsBitmap: ThumbnailEntity[] }>(),
);


export const setEdgeSystemStatusPagination = createAction('[EDIT-EDGE] Set edge System Status Pagination TimeStamps');

export const setHeartbeatsDateRAnge = createAction('[EDIT-EDGE] Set Heartbeats Date Range', props<{ from: number; to: number }>());

export const getDate = createAction('[EDIT-EDGE] getDate');

export const setDate = createAction('[EDIT-EDGE] setDate', props<{ date: string }>());

export const setTimezone = createAction('[EDIT-EDGE] setTimezone', props<{ date: string }>());

export const setNtp = createAction('[EDIT-EDGE] setNtp', props<{ ntpEnabled: boolean }>());

export const getEdgeById = createAction('[EDIT-EDGE] Get edge By Id');

export const getEdgeSWByIds = createAction('[EDIT-EDGE] get edge SW', props<{ edgeId: string; locationId: string }>());

export const uploadVideoStorage = createAction(
  '[EDIT-EDGE] Upload Video Storage',
  props<{ dirPattern: string; filePattern: string; cameraId: string }>(),
);

export const listVideoStorage = createAction('[EDIT-EDGE] List Video Storage', props<{ dirPattern: string; cameraId: string }>());

export const sendUploadVideoStorage = createAction(
  '[EDIT-EDGE] Send Upload Video Storage',
  props<{ dirPattern: string; filePattern: string; cameraId: string }>(),
);

export const sendListVideoStorage = createAction('[EDIT-EDGE] Send List Video Storage', props<{ dirPattern: string; cameraId: string }>());

export const rmEdgeLink = createAction('[EDIT-EDGE] Remove edge Link', props<{ link: string }>());

export const rmCameraFromEdge = createAction('[EDIT-EDGE] Remove Camera From core', props<{ cameraId: string }>());

export const sendRmCameraFromEdge = createAction('[EDIT-EDGE] Send Remove Camera From core', props<{ cameraId: string }>());

export const rmEventFromEdge = createAction('[EDIT-EDGE] Rm Event From core', props<{ eventId: string; cameraId: string }>());

export const getAnalyticConfig = createAction('[EDIT-EDGE] Get Analytic Config', props<{ cameraId: string }>());

export const updateAnalyticConfigJson = createAction(
  '[EDIT-EDGE] Upload Analytic Config JSON',
  props<{ config: string; cameraId: string }>(),
);

export const sendUpdateAnalyticConfigJson = createAction(
  '[EDIT-EDGE] Send  Update Analytic Config JSON',
  props<{ config: string; cameraId: string }>(),
);

export const pM2Logs = createAction('[EDIT-EDGE] pM2Logs', props<{ lines: number }>());

export const pS = createAction('[EDIT-EDGE] pS');

export const getEdgeIPInfos = createAction('[EDIT-EDGE] getEdgeIPInfos');

export const getExternalIP = createAction('[EDIT-EDGE] getExternalIP');

export const probFile = createAction('[EDIT-EDGE] probFile', props<{ filename: string }>());

export const manage = createAction('[EDIT-EDGE] manage', props<{ action: number; params: { [key: string]: string | number } }>());

export const cancelManageRequest = createAction('[EDIT-EDGE] Cancel Manage Request');

export const restoreEdge = createAction('[EDIT-EDGE] Restore core', props<{ locationId: string; edgeId: string }>());
export const restoreEdgeSuccess = createAction('[EDIT-EDGE] Restore edge Success', props<{ result: any }>());
export const restoreEdgeFail = createAction('[EDIT-EDGE] Restore edge Fail');

export const setHasChanged = createAction('[EDIT-EDGE] Set Has Changed', props<{ hasChanged: boolean }>());

export const getEdgeNtp = createAction('[EDIT-EDGE] Get edge NTP');
export const setEdgeNtp = createAction('[EDIT-EDGE] Set edge NTP', props<{ enable: boolean, servers: NtpModels.NtpServerInterface[] }>());

export const getCoreRecoveryDocument = createAction('[EDIT-EDGE] Get Core Recovery Document');
export const getCoreRecoveryDocumentSuccess = createAction('[EDIT-EDGE] Get Core Recovery Document Success', props<{ edgeRecoveryDocument: any }>());
export const getCoreRecoveryDocumentFail = createAction('[EDIT-EDGE] Get Core Recovery Document Fail');
export const saveCoreRecoveryDocument = createAction('[EDIT-EDGE] Save Core Recovery Document', props<{ body: Object }>());
export const serverCallSaveCoreRecoveryDocument = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document', props<{ body: Object }>());
export const serverCallSaveCoreRecoveryDocumentSuccess = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document Success');
export const serverCallSaveCoreRecoveryDocumentFail = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document Fail');

export const getEdgeHeartBeatStatus = createAction('[EDIT-EDGE] Get Edge HeartBeat Status');
export const getEdgeHeartBeatStatusSuccess = createAction('[EDIT-EDGE] Get Edge HeartBeat Status Success', props<{ body: any[] }>());
export const getEdgeHeartBeatStatusFail = createAction('[EDIT-EDGE] Get Edge HeartBeat Status Fail');
