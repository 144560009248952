<ng-container *ngrxLet="selectSharedAlert$;let sharedAlert">
  <!--  COULD BE 2 CASES-->
  <!--  1. IF ALERT REMOVED-->
  <!--  2. IF ARCHIVE FOR THIS ALERT REMOVED-->
  <ng-container *ngIf="sharedAlert; else entityRemoved">
    <ng-container *ngIf="true || !sharedArchive; else archiveCase">
      <app-thumbnail
        *ngIf="dialogData"
        [checkPermissionEnabled]="false"
        [isShareVisible]="false"
        [allowDialogOpen]="false"
        [optionsInput]="dialogData.options"
        [rtl]="false"
        [events]="dialogData.events"
        [searchEvents]="dialogData.searchEvents"
        [objects]="dialogData.objects"
        [cameraId]="dialogData.cameraId"
        [defaultThumbnail]="dialogData.defaultThumb"
        [seconds]="dialogData.seconds"
        [type]="dialogData.type"
        [showObjects]="dialogData.showObjects"
        [allowTrack]="dialogData.allowTrack"
        [alertName]="dialogData.alertName"
        [isDialog]="true"
        [name]="dialogData.name"
        [shareName]="dialogData?.shareName"
        [alertId]="sharedAlert._id"
        [isSharedAlert]="true"
        [selectedCamera]="dialogData.selectedCamera"
        [online]="true"
        fxFlex=""
      ></app-thumbnail>
    </ng-container>
    <ng-template #archiveCase>
      <mat-tab-group class="h-100">
        <mat-tab label="Images">
          <app-thumbnail
            *ngIf="dialogData"
            [checkPermissionEnabled]="false"
            [isShareVisible]="false"
            [allowDialogOpen]="false"
            [optionsInput]="dialogData.options"
            [rtl]="false"
            [events]="dialogData.events"
            [searchEvents]="dialogData.searchEvents"
            [objects]="dialogData.objects"
            [cameraId]="dialogData.cameraId"
            [defaultThumbnail]="dialogData.defaultThumb"
            [seconds]="dialogData.seconds"
            [type]="dialogData.type"
            [showObjects]="dialogData.showObjects"
            [allowTrack]="dialogData.allowTrack"
            [alertName]="dialogData.alertName"
            [isDialog]="false"
            [name]="dialogData.name"
            [shareName]="dialogData?.shareName"
            [alertId]="dialogData?.alertId"
            [alertInstanceId]="dialogData?.alertInstanceId"
            [isSharedAlert]="true"
            [selectedCamera]="dialogData.selectedCamera"
            fxFlex=""
          ></app-thumbnail>
        </mat-tab>
        <!--      SHOW TAB IF ARCHIVE IS EXIST-->
        <mat-tab *ngIf="sharedArchive" label="Video">
          <ng-container *ngrxLet="selectSelectedLocation$;let location">
            <div class="video-container">
              <ng-container *ngrxLet="selectIsRecordRemoved$; let isRecordRemoved">
                <ng-container *ngIf="!isRecordRemoved;else entityRemoved">
                  <ng-container [ngSwitch]="sharedArchive?.status">
                    <ng-container *ngSwitchCase="archiveStatuses.ERROR">
                      <div class="error-container">
                        <span class="ui-icon-warning"></span>
                        <div class="description">
                          The video has failed to upload. Please contact you person at Lumix.ai
                        </div>
                        <ui-button type="primary" text="Contact" (click)="openMail()"></ui-button>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="archiveStatuses.UPLOADING">
                      <div class="error-container">
                        <span class="ui-icon-loading"></span>
                        <div class="description">
                          Video is loading. This might take a few minutes
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="archiveStatuses.COMPLETED">
                      <ui-video-player *ngIf="sharedArchive"
                                       [src]="archiveUrl" [title]="sharedArchive?.name"
                                       [timestamp]="sharedArchive.start * 1000"
                                       [locationName]="location?.name"
                      ></ui-video-player>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>


            </div>
          </ng-container>
        </mat-tab>
        <mat-tab *ngIf="!!dialogData?.objects?.length" label="Objects">
          <div style="height:650px">
            <ng-container *ngIf="dialogData">
              <div fxLayout="column"
                   fxLayoutGap="3px"
                   class="objects-wrapper p-25"
              >
                <div *ngFor="let object of dialogData.objects; let i = index"
                     fxLayout="row wrap"
                     fxLayoutAlign="space-around center"
                     fxLayoutGap="6px grid"
                >
                  <ng-container *ngIf="i % 4 === 0">
                    <ng-container *ngFor="let idx of [0, 1, 2, 3]">
                      <div fxLayout="row" fxLayoutAlign="start start" class="w-100" fxLayoutGap="6px">
                        <div
                          *ngIf="dialogData.objects[i + idx]?.url"
                          fxLayoutAlign="center center"
                          class="best-image-wrapper"
                          fxFlex=""
                          style="height: 40vh; max-height: 40vh; background: #70908e12"
                        >
                          <img #best
                               style="height: 100%; width: auto"
                               *ngIf="bestImageUri(dialogData.objects[i + idx]?.url)"
                               [src]="bestImageUri(dialogData.objects[i + idx]?.url)"
                          />
                        </div>
                        <div
                          *ngIf="dialogData.objects[i + idx]?.zoomImage"
                          fxLayoutAlign="center center"
                          class="best-image-wrapper"
                          fxFlex="25"
                          style="height: 40vh; max-height: 40vh; min-width: 50%; background: #70908e12"
                        >
                          <img #best
                               style="height: auto; width: auto"
                               *ngIf="dialogData.objects[i + idx]?.zoomImage"
                               [src]="bestImageUri(dialogData.objects[i + idx]?.zoomImage)"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

            </ng-container>
          </div>

        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </ng-container>
</ng-container>


<ng-template #entityRemoved>
  <div class="error-container">
    <span class="ui-icon-warning"></span>
    <div class="description">
      The video has failed to upload. Please contact you person at Lumix.ai
    </div>
    <ui-button type="primary" text="Contact" (click)="openMail()"></ui-button>
  </div>
</ng-template>
