import { Component, Inject, OnInit } from '@angular/core';
import { PreloaderColor } from '@enums/shared.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupModels } from '@models/people.model';
import { DashboardActions } from '@states/dashboard/dashboard.action-types';
import { select, Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { Upload } from '../ui-upload/ui-upload.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { UiUploadService } from '../ui-upload/ui-upload.service';
import { DashboardEffects } from '@effects/dashboard.effects';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';

@UntilDestroy()
@Component({
  selector: 'ui-image-upload',
  templateUrl: './ui-image-upload-dialog.component.html',
  styleUrl: './ui-image-upload-dialog.component.scss',
})
export class UiImageUploadDialogComponent implements OnInit {

  uploadProgress$: Observable<Upload>;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public PreloaderColor = PreloaderColor;

  public uploadErrorMsg: string;

  selectedFiles: File[] = [];
  progress = 0;
  uploadDone = false;
  selected = false;
  loading = false;

  images: string[] = [];

  public imageError: string = '';
  private orgId: string;

  constructor(
    private store$: Store,
    private uiUploadService: UiUploadService,
    public dialogRef: MatDialogRef<UiImageUploadDialogComponent>,
    private dashboardEffects: DashboardEffects,
  ) {
  }

  ngOnInit(): void {
    this.uploadProgress$ = this.uiUploadService.uploadProgress$;
    this.uploadProgress$.pipe(untilDestroyed(this))
      .subscribe((upload) => {
        this.progress = upload.progress;
        if (upload.state === 'DONE') {
          this.uploadDone = false;
        }
      });

    this.dashboardEffects.uploadImagesSend$
      .pipe(untilDestroyed(this), ofType(DashboardActions.UploadImagesSendSuccess))
      .subscribe((result) => {
        console.log(result);
        const response: Upload[] = result?.response;
        const files = Object.keys(response[0].fileProgress);
        for(let file of files) {
          this.images.push(`https://dashboard.lumix.ai/${this.orgId}/${file}`);
        }
        this.loading = false;
      });
    this.selectActiveOrganization$.pipe(take(1))
      .subscribe((org) => {
        this.orgId = org?.orgId;
      });
  }

  save() {
    this.dialogRef.close({ images: this.images });
  }


  onFileDrop(event: any) {
    this.selectedFiles = event;
    this.uploadMulti(this.selectedFiles);
  }

  onFileChange(event: any) {
    this.uploadErrorMsg = '';
    this.selectedFiles = event.target.files;
    this.uploadMulti(this.selectedFiles);
  }

  public uploadMulti(file: File[]): void {
    const assets: GroupModels.FileAsset[] = [];
    this.selected = true;

    for(const file of this.selectedFiles) {
      const name = file.name.split('.')
        .slice(0, -1)
        .join('.');
      const chartNameRegex = new RegExp(/[^a-zA-Z0-9-_]/g);
      const isNameNotOk = chartNameRegex.test(name);
      if (isNameNotOk) {
        this.selected = false;
        this.selectedFiles = [];
        this.uploadErrorMsg = `Name must contain only letters, numbers or underscore`;
        return;
      }
    }

    for(const file of this.selectedFiles) {
      assets.push({
        file,
        asset: {
          filename: `${Date.now()
            .toString()}_${file.name}`,
        },
      });
    }

    this.loading = true;

    this.store$.dispatch(
      DashboardActions.UploadImages({
        request: assets,
      }),
    );
  }

}
