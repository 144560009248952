import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InviteState } from '@states/invite/invite.reducer';

export const selectInviteState = createFeatureSelector<InviteState>('inviteState');

export const selectOrganizationInvites = createSelector(selectInviteState, ({ entities, query }: InviteState) => {
  const result = entities ? Object.values(entities) : [];
  return result.filter(invite => query ? invite.userEmail.includes(query) : true);
});

export const selectInvites = createSelector(selectInviteState, ({ invites }: InviteState) => invites);
export const selectIsInitialLoaded = createSelector(selectInviteState, ({ isInitialLoaded }: InviteState) => isInitialLoaded);
export const selectSelectedInvite = createSelector(selectInviteState, ({ selectedInvite }: InviteState) => selectedInvite);
