import { createFeatureSelector, createSelector } from '@ngrx/store';
import { alertAdapter, AlertMonitoringState } from '@states/alert-monitoring/alert-monitoring.reducer';


export const selectAlertMonitoringState = createFeatureSelector<AlertMonitoringState>('alertMonitoringState');

export const {selectAll, selectEntities, selectIds, selectTotal} = alertAdapter.getSelectors();


export const selectSelectedCameras = createSelector(
  selectAlertMonitoringState,
  ({selectedCameras}: AlertMonitoringState) => selectedCameras,
);

export const selectTrackedObjects = createSelector(
  selectAlertMonitoringState,
  ({trackedObjects}: AlertMonitoringState) => trackedObjects,
);

export const selectDateRange = createSelector(selectAlertMonitoringState, ({dateRange}: AlertMonitoringState) => dateRange);

export const selectViews = createSelector(selectAlertMonitoringState, ({views}: AlertMonitoringState) => views);

export const selectedView = createSelector(selectAlertMonitoringState, ({selectedView}: AlertMonitoringState) => selectedView);

export const selectSearchQuery = createSelector(selectAlertMonitoringState, ({query}: AlertMonitoringState) => query);

export const selectIsFirstLoad = createSelector(selectAlertMonitoringState, ({isFirstLoad}: AlertMonitoringState) => isFirstLoad);

export const selectAlerts = createSelector(selectAlertMonitoringState, ({entities}: AlertMonitoringState) => Object.values(entities));
export const selectAlertsViewMode = createSelector(selectAlertMonitoringState, ({alerts}: AlertMonitoringState) => alerts);
export const selectAlertsViewCount = createSelector(selectAlertMonitoringState, ({alerts}: AlertMonitoringState) => alerts?.length ?? 0);
export const selectAlertsCount = createSelector(selectAlertMonitoringState, ({count}: AlertMonitoringState) => count ?? 0);


export const selectSelectedAlert = (id: string) => createSelector(selectAlertMonitoringState, ({entities}: AlertMonitoringState) => entities[id]);

export const selectPaginationTimeStamps = createSelector(
  selectAlertMonitoringState,
  ({paginationTimeStamps}: AlertMonitoringState) => paginationTimeStamps,
);

export const selectAutocompleteOptions = createSelector(selectAlertMonitoringState, ({autocompleteOptions}: AlertMonitoringState) => {
  return autocompleteOptions;
});

export const selectEvents = createSelector(
  selectAlertMonitoringState,
  ({ events }: AlertMonitoringState) => events,
);

export const selectFlowTypes = createSelector(
  selectAlertMonitoringState,
  ({flowTypes}: AlertMonitoringState) => flowTypes,
);

export const selectAlertTypes = createSelector(selectAlertMonitoringState, ({alertTypes}: AlertMonitoringState) => alertTypes);

export const selectSearchFilters = createSelector(selectAlertMonitoringState, ({searchFilters}: AlertMonitoringState) => searchFilters);

export const selectIsLoading = createSelector(selectAlertMonitoringState, ({entities, isLoading}: AlertMonitoringState) => {
  return !Object.values(entities).length && isLoading;
});

export const selectAcknowledges = createSelector(selectAlertMonitoringState, ({acknowledges}: AlertMonitoringState) => acknowledges);
export const selectSelectedEventIds = createSelector(selectAlertMonitoringState, ({ eventIds }: AlertMonitoringState) => eventIds);


export const selectEventsLookup = createSelector(
  selectAlertMonitoringState,
  ({ eventsLookup }: AlertMonitoringState) => eventsLookup,
);
