<!--    CHECK IF ARCHIVE IS EXIST-->
<ng-container *ngrxLet="selectIsRecordRemoved$; let isRecordRemoved">
  <ng-container *ngIf="!isRecordRemoved;else entityRemoved">
    <ng-container *ngrxLet="selectSelectedLocation$;let location">
      <div class="h-100">
        <ng-container [ngSwitch]="sharedArchive?.status">
          <ng-container *ngSwitchCase="archiveStatuses.ERROR">
            <div class="error-container">
              <span class="ui-icon-warning"></span>
              <div class="description">
                The video has failed to upload. Please contact you person at Lumix.ai
              </div>
              <ui-button type="primary" text="Contact" (click)="openMail()"></ui-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="archiveStatuses.UPLOADING">
            <div class="error-container">
              <span class="ui-icon-loading">
              </span>
              <div class="description">
                Video is loading. This might take a few minutes
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="archiveStatuses.COMPLETED">
            <ui-video-player [src]="archiveUrl" [title]="sharedArchive?.name"
                             [timestamp]="sharedArchive.start * 1000"
                             [locationName]="location?.name"
            ></ui-video-player>
          </ng-container>
        </ng-container>

      </div>
    </ng-container>
  </ng-container>
</ng-container>


<ng-template #entityRemoved>
  <div class="error-container">
    <span class="ui-icon-warning"></span>
    <div class="description">
      The video has failed to upload. Please contact you person at Lumix.ai
    </div>
    <ui-button type="primary" text="Contact" (click)="openMail()"></ui-button>
  </div>
</ng-template>

