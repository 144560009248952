import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { map, Observable, switchMap, take } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { SortDirection } from '@angular/material/sort';
import { UserService } from '../user/user.service';
import { DashboardModel } from '@models/dashboard.model';
import { YAxisMeasureStr, YAxisTrackerClassStr } from '@consts/dashboard.const';
import { GroupModels } from '@models/people.model';
import { HttpService } from '../core/http.service';
import { Store } from '@ngrx/store';
import { selectFilters } from '@states/dashboard/dashboard.selectors';
import { DashboardSelectors } from '@states/dashboard/dashboard.selector-types';
import { withLatestFrom } from 'rxjs/operators';
import { OnRangeSelectedResult } from '../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UtilsV2Service } from '../services/utils-v2.service';
import * as moment from 'moment-timezone';
import { WidgetDataInfoDialogData } from '../pages/dashboards/components/widget-data-info-dialog/widget-data-info-dialog.component';

@Injectable()
export class WidgetService {
  constructor(private userService: UserService,
              private http: HttpClient,
              private store$: Store,
              private utilsV2Service: UtilsV2Service,
              private httpService: HttpService) {
  }

  public create(data: DashboardModel.Widget): Observable<any> {
    return this.http.post(api.widget.crud, data);
  }

  public update(data: DashboardModel.Widget): Observable<any> {
    return this.http.put(api.widget.crud, data);
  }

  public get(): Observable<PaginationResult<DashboardModel.Widget>> {
    return this.http.get<PaginationResult<DashboardModel.Widget>>(api.widget.crud);
  }

  public one(id: number): Observable<DashboardModel.Widget> {
    return this.http.get<DashboardModel.Widget>(api.widget.one(id));
  }

  public remove(id: number): Observable<DashboardModel.Widget> {
    return this.http.delete<DashboardModel.Widget>(api.widget.one(id));
  }

  public getVisualization(id: number): Observable<any> {
    const timezone = moment.tz.guess();
    return this.store$.select(DashboardSelectors.selectFilters)
      .pipe(
        take(1),
        switchMap((filters) => {
          let query = '';
          if (filters.selectedCameras?.length > 0) {
            const cameraIds = filters.selectedCameras.map(camera => camera?.edgeOnly?.cameraId ?? camera?.cameraId);
            query += `&cameras=${cameraIds.join(',')}`;
          }
          if (filters.trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.Group && filters?.trackerOptions?.trackerGroup?.length) {
            query += `&trackerGroup=${filters.trackerOptions.trackerGroup.join(',')}`;
          } else if (filters.trackerOptions.trackerGroupType === DashboardModel.YAxisGroupType.Individual && filters?.trackerOptions?.trackerClass?.length) {
            query += `&trackerClass=${filters.trackerOptions.trackerClass.join(',')}`;
          }
          const dateRange: OnRangeSelectedResult = filters.dateRange;
          const { start, end } = this.utilsV2Service.dateRangeToServerRequest(dateRange);

          return this.http.get(`${api.widget.visualization(id)}&timezone=${timezone}&start=${start}&end=${end}${query}`);
        }),
      );
  }

  public getPreview(data: DashboardModel.Widget): Observable<any> {
    if (data.layout.yAxis[0].trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.All) {
      data.layout.yAxis[0].trackerOptions.trackerClass = [];
      data.layout.yAxis[0].trackerOptions.trackerGroup = [];
    }
    if (data.layout.yAxis[0].trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.Group) {
      data.layout.yAxis[0].trackerOptions.trackerClass = [];
    } else if (data.layout.yAxis[0].trackerOptions.trackerGroupType === DashboardModel.YAxisGroupType.Individual) {
      data.layout.yAxis[0].trackerOptions.trackerGroup = [];
    }

    if (data.layout.yAxis[0].alertsOptions?.alertGroupType === DashboardModel.YAxisGroupType.All) {
      data.layout.yAxis[0].alertsOptions.alertType = [];
      data.layout.yAxis[0].alertsOptions.eventId = [];
    }
    if (data.layout.yAxis[0].alertsOptions?.alertGroupType === DashboardModel.YAxisGroupType.Group) {
      data.layout.yAxis[0].alertsOptions.eventId = [];
    } else if (data.layout.yAxis[0].alertsOptions?.alertGroupType === DashboardModel.YAxisGroupType.Individual) {
      data.layout.yAxis[0].alertsOptions.alertType = [];
    }

    const request: DashboardModel.WidgetPreviewRequest = {
      ...data,
      timezone: moment.tz.guess(),
    };

    const timezone = moment.tz.guess();
    return this.store$.select(DashboardSelectors.selectFilters)
      .pipe(
        take(1),
        switchMap((filters) => {
          let query = '';
          if (filters.selectedCameras?.length > 0) {
            const cameraIds = filters.selectedCameras.map(camera => camera?.edgeOnly?.cameraId);
            query += `&cameras=${cameraIds.join(',')}`;
          }
          if (filters.trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.Group) {
            query += `&trackerGroup=${filters.trackerOptions.trackerGroup.join(',')}`;
          } else if (filters.trackerOptions.trackerGroupType === DashboardModel.YAxisGroupType.Individual) {
            query += `&trackerClass=${filters.trackerOptions.trackerClass.join(',')}`;
          }
          const dateRange: OnRangeSelectedResult = filters.dateRange;
          const { start, end } = this.utilsV2Service.dateRangeToServerRequest(dateRange);

          return this.http.post(`${api.widget.preview}?timezone=${timezone}&start=${start}&end=${end}${query}`, request);
        }),
      );
  }

  public getWidgets(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
  ): Observable<DashboardModel.Widget[]> {
    let url = `${api.widget.crud}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<DashboardModel.Widget[]>(url);
  }

  public DbOperationAdapter(measure: DashboardModel.YAxisMeasure): DashboardModel.DbOperation {
    switch (measure) {
      case DashboardModel.YAxisMeasure.Count:
        return DashboardModel.DbOperation.Count;
      case DashboardModel.YAxisMeasure.Average:
        return DashboardModel.DbOperation.Avg;
      case DashboardModel.YAxisMeasure.Sum:
        return DashboardModel.DbOperation.Sum;
      case DashboardModel.YAxisMeasure.Max:
        return DashboardModel.DbOperation.Max;
      case DashboardModel.YAxisMeasure.Min:
        return DashboardModel.DbOperation.Min;
      default:
        return DashboardModel.DbOperation.Count;
    }
  }

  public groupTimeAdapter(groupTime: DashboardModel.XAxisTimeType): DashboardModel.GroupTime {
    switch (groupTime) {
      case DashboardModel.XAxisTimeType.None:
        return DashboardModel.GroupTime.DAY;
      case DashboardModel.XAxisTimeType.Hour:
        return DashboardModel.GroupTime.HOUR;
      case DashboardModel.XAxisTimeType.Day:
        return DashboardModel.GroupTime.DAY;
      case DashboardModel.XAxisTimeType.Week:
        return DashboardModel.GroupTime.WEEK;
      case DashboardModel.XAxisTimeType.Month:
        return DashboardModel.GroupTime.MONTH;
      default:
        return DashboardModel.GroupTime.DAY;
    }
  }

  public trackerClassAdapter(trackerGroup: DashboardModel.YAxisTrackerOptions): DashboardModel.YAxisTrackerClass[] {
    switch (trackerGroup.trackerGroupType) {
      case DashboardModel.YAxisGroupType.All:
        return Object.keys(YAxisTrackerClassStr)
          .map(key => +key);
      case DashboardModel.YAxisGroupType.Group:
        const trackerClass: DashboardModel.YAxisTrackerClass[] = [];
        if (trackerGroup.trackerGroup.includes(DashboardModel.YAxisTrackerGroup.Person)) {
          trackerClass.push(
            DashboardModel.YAxisTrackerClass.UnknownPerson,
            DashboardModel.YAxisTrackerClass.AdultFemale,
            DashboardModel.YAxisTrackerClass.AdultMale,
            DashboardModel.YAxisTrackerClass.Child,
          );
        }
        if (trackerGroup.trackerGroup.includes(DashboardModel.YAxisTrackerGroup.Vehicle)) {
          trackerClass.push(
            DashboardModel.YAxisTrackerClass.Car,
            DashboardModel.YAxisTrackerClass.Motorcycle,
            DashboardModel.YAxisTrackerClass.Bicycle,
            DashboardModel.YAxisTrackerClass.Bus,
            DashboardModel.YAxisTrackerClass.Truck,
            DashboardModel.YAxisTrackerClass.Forklift,
          );
        }
        if (trackerGroup.trackerGroup.includes(DashboardModel.YAxisTrackerGroup.Animal)) {
          trackerClass.push(
            DashboardModel.YAxisTrackerClass.Animal,
          );
        }
        return trackerClass;
      case DashboardModel.YAxisGroupType.Individual:
        return trackerGroup.trackerClass;
      default:
        return [];
    }
  }

  public createVisualization(data: DashboardModel.WidgetLayout): DashboardModel.VisualizeRequest {
    // TODO - extend to multiple measure groups
    const dbOperations: DashboardModel.ColumnOperation[] = data.yAxis.map((yAxisTracker) => {
      const measure = yAxisTracker.measure;
      return { name: YAxisMeasureStr[measure], operation: this.DbOperationAdapter(measure) };
    });
    const groupTime = this.groupTimeAdapter(data.xAxis[0].value);
    const cameras = data.cameras.map((camera) => {
      return { cameraId: camera.cameraId, edgeId: camera.edgeId };
    });
    const request: DashboardModel.VisualizeRequest = {
      type: 'visualize',
      tableName: DashboardModel.AttributeTableNames.TRACKERS2,
      columns: ['id'],
      operations: dbOperations,
      groupTime,
      sortBy: { field: 'groupTime', order: 'desc' },
      // timeRange: {
      //   type: DataAnalyticsModel.Visualize.TimeRangeType.RELATIVE,
      //   timeType: DataAnalyticsModel.Visualize.GroupTime.DAY,
      //   count: 7,
      // },
      filters: {
        cameras,
        trackers: {
          // TODO - extend to multiple measure groups
          trackerClass: this.trackerClassAdapter(data.yAxis[0].trackerOptions),
        },
      },
    };
    return request;

  }

  public getImagePreSignedUrl(request: GroupModels.GetFaceAssetPresignedUrlRequest, file: Blob): Observable<{
    url: string;
    filename: string,
    file: Blob
  }> {
    return this.http.post<{
        url: string;
        filename: string
      }>(api.widget.upload, request)
      .pipe(
        map(res => {
          return {
            ...res,
            file,
          };
        }),
      );
  }

  uploadImagePresignedUrl(request: GroupModels.UploadFaceAssetPresignedUrlRequest) {
    return this.httpService.uploadPresignedUrl(request);
  }

  getRedirectRouting(request: DashboardModel.RedirectRouterRequest) {
    return this.http.post<WidgetDataInfoDialogData>(api.widget.redirectRouter, request);
  }

}
