import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, take } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UtilsService } from 'src/app/edge/utils.service';
import { CamerasService } from 'src/app/cameras/cameras.service';
import { CameraSelectors } from '@states/camera/camera.selector-types';

export interface Cell {
  x: number;
  y: number;
}

@UntilDestroy()
@Component({
  selector: 'ui-heatmap',
  templateUrl: './ui-heatmap.component.html',
  styleUrls: ['./ui-heatmap.component.scss'],
})
export class UiHeatmapComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() cameraId: string;
  @Input() data: number[];

  @Input() reader: HTMLImageElement;
  @Input() hide = false;

  @Input() preview = false;
  @Input() opacity = 50;

  @ViewChild('heatmapWrapper') heatmapWrapper: ElementRef;
  @ViewChild('snapshot') snapshot: ElementRef;

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('small', { static: true }) small: ElementRef<HTMLCanvasElement>;
  @ViewChild('tempCanvas', { static: true }) destCanvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;
  private readerCtx: CanvasRenderingContext2D;
  private readerData;

  public height = 345;
  public width = 613;

  @Input() maxWidth = 613;
  @Input() maxHeight = 345;

  imageLoaded = false;

  observer: ResizeObserver;

  edgeId = '';

  constructor(private utilsService: UtilsService, private camerasService: CamerasService, private store$: Store) {
  }

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(entries => {
      this.heatmapWrapper.nativeElement.style.height = `${this.snapshot.nativeElement.clientHeight}px`;
    });
    // this.observer.observe(this.snapshot.nativeElement);
  }

  ngOnDestroy(): void {
    // this.observer.unobserve(this.snapshot.nativeElement);
    this.observer.disconnect();
  }

  ngOnInit(): void {
    this.ctx = this.small.nativeElement.getContext('2d')!;
  }

  getReader() {
    if (!this.reader.width || !this.reader.height || !!this.readerData) {
      return;
    }
    // Create an empty canvas element
    var canvas = document.createElement('canvas');
    canvas.width = this.reader.width;
    canvas.height = this.reader.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext('2d');
    ctx.drawImage(this.reader, 0, 0);
    const rawReaderData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    this.readerData = _.chunk(rawReaderData.data, 4)
      .splice(0, this.reader.width);
    canvas.remove();
  }

  getColor(percent: number) {
    if (percent > 100) {
      percent = 100;
    }
    const pixel = Math.floor(percent / 100 * (this.readerData.length - 1));
    const c = this.readerData[pixel]; // get rgba color in [R,G,B,A] format (each is 0-255)
    return `rgba(${c[0]},${c[1]},${c[2]}, 1)`;
  }

  public resize() {
    // if (!this.snapshot) {
    //   return;
    // }
    this.draw();
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  onImageLoad(event: any) {
    this.imageLoaded = true;
    const width = event.target.width;
    const height = event.target.height;
    this.height = height;
    this.width = width;
    this.resize();
  }

  percentToColor() {
  }

  drawCell(ctx: CanvasRenderingContext2D, x: number, y: number, color = '#00FF19') {
    ctx.fillStyle = color;
    ctx.fillRect(x, y, 1, 1);
  }

  draw() {
    if (!this.small || !this.readerData || !this.ctx) {
      return;
    }
    this.ctx?.clearRect(0, 0, this.width, this.height);
    let matrixCanvas = this.small.nativeElement;
    matrixCanvas.width = 32;
    matrixCanvas.height = 32;
    const ctx = matrixCanvas.getContext('2d')!;
    for(let i = 0; i < this.data.length; i++) {
      const x = i % 32;
      const y = Math.floor(i / 32);
      if (this.data[i]) {
        this.drawCell(ctx, x, y, this.getColor(this.data[i]));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['cameraId']) {
      this.store$
        .select(CameraSelectors.selectCameraById(this.cameraId))
        .pipe(take(1))
        .subscribe(camera => {
          this.edgeId = camera?.edgeId;
          if (this.ctx) {
            this.ctx?.clearRect(0, 0, this.width, this.height);
          }
        });
    }
    if (!!changes['data']) {
      this.getReader();
      this.draw();
    }
    if (!!changes['maxHeight'] || !!changes['maxWidth']) {
      this.resize();
    }
  }

}

