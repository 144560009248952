import { createReducer, on } from '@ngrx/store';
import * as DoorsActions from '@states/doors/doors.actions';
import { DoorModels } from '@models/door.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface DoorsState extends EntityState<DoorModels.Door> {

}

export const adapter: EntityAdapter<DoorModels.Door> = createEntityAdapter<DoorModels.Door>({
  selectId: (door: DoorModels.Door) => door.id,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

const testState: DoorsState = adapter.getInitialState({
  ids: [605815355, 605815354, 1, 2, 3, 4, 5],
  entities: {
    605815355: {
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 605815355,
      'cameraId': '65262425bf607043d4082ebd',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'orgId': '651d82e4984ba62cf7828432',
      'timestamp': 1696949160851,
      'type': 1,
      'isSaved': false,
      'locationId': '63146417eb9c1420f9bf0c01',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    605815354: {
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 605815354,
      'cameraId': '65262425bf607043d4082ebc',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'orgId': '651d82e4984ba62cf7828432',
      'timestamp': 1696949160851,
      'type': 1,
      'isSaved': false,
      'locationId': '63146417eb9c1420f9bf0c01',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    1: {
      'name': 'Front Door',
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 1,
      'cameraId': '65262425bf607043d4082ebd',
      'isSaved': true,
      'locationId': '63146417eb9c1420f9bf0c01',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    2: {
      'name': 'Front second',
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 2,
      'cameraId': '65262425bf607043d4082ebc',
      'isSaved': true,
      'locationId': '63146417eb9c1420f9bf0c01',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    3: {
      'name': 'Back',
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 3,
      'cameraId': '6526247fbf607043d4082ebf',
      'isSaved': true,
      'locationId': '63146417eb9c1420f9bf0c01',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    4: {
      'name': 'Entrance',
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 4,
      'cameraId': '65262425bf607043d4082ebd',
      'isSaved': true,
      'locationId': '63146417eb9c1420f9bf0c01',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },
    5: {
      'name': 'Other',
      'edgeId': '65261f43bf607043d4082eb6',
      'id': 5,
      'cameraId': '6526247fbf607043d4082ebf',
      'isSaved': true,
      'locationId': '63146417eb9c1420f9bf0c01',
      'openState': 'training-1695915578084.jpg',
      'closeState': 'training-1695821214798.jpg',
      'position': [0.16436993, 0.31453401, 0.40508104, 0.99509772]
    },

  },
});

const initialState: DoorsState = adapter.getInitialState({});
export const doorsStateReducer = createReducer(
  initialState,
  on(DoorsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(DoorsActions.resetUnsaved, state => {
    const unsaved: string[] = Object.values(state.entities)
      .filter((door: DoorModels.Door) => !door.isSaved)
      .map((door: DoorModels.Door) => door.id);
    return adapter.removeMany(unsaved, state);
  }),
  on(DoorsActions.setDoors, (state, {doors}) => {
    return {
      ...state,
    };
  }),
  on(DoorsActions.getDoorsSuccess, (state, {doors}) => {
    return adapter.setMany(doors, state);
  }),
  on(DoorsActions.addDoorSuccess, (state, {door}) => {
    const update: DoorModels.Door = {
      ...door,
      isSaved: true,
    };
    return adapter.setOne(update, state);
  }),
  on(DoorsActions.editDoor, (state, {door}) => {
    const update: DoorModels.Door = {
      ...door,
    };
    return adapter.setOne(update, state);
  }),
  on(DoorsActions.removeDoorSuccess, (state, {door}) => {
    return adapter.removeOne(door.id, state);
  }),
);
