import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[PERMISSIONS] Reset to Initial state');

export const getPermissions = createAction('[PERMISSIONS] Get User Permissions');
export const getPermissionsSuccess = createAction('[PERMISSIONS] Get User Permissions Success',
  props<{
    permissions: string[];
    entityIds: string[];
  }>());
export const getPermissionsFail = createAction('[PERMISSIONS] Get User Permissions Fail');

export const updateUserGroups = createAction('[PERMISSIONS] UpdateUserGroups', props<{
  groupIds: string[];
  userId: string;
}>());
