import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-timezone-modal',
  templateUrl: './set-timezone-modal.component.html',
  styleUrls: ['./set-timezone-modal.component.scss'],
})
export class SetTimezoneModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public date: string = null;

  constructor(private matDialogRef: MatDialogRef<SetTimezoneModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.date);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
