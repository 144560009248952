import { Component, Input, OnInit } from '@angular/core';
import { AlertCategory, AlertDurationUnit, alertsV2PeopleAppearsStr, AlertsV2Plates, CustomizedCapabilitiesType, DoorSystemType, EventTagAppearance, IdentificationType, IntegrationsType, LocationOptions, ObjectOptions, ProtectiveGearOptions, ProtectiveGearWearOptions, SafetyType, StatusType, TrackingType } from '@models/alerts-v2.model';
import { select, Store } from '@ngrx/store';
import { catchError, filter, lastValueFrom, Observable, take, timeout } from 'rxjs';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { SelectedCamera } from '@models/alert-events.model';
import { DoorModels } from '@models/door.model';
import { DoorsSelectors } from '@states/doors/doors.selector-types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { LocationSelectors } from '@states/location/location.selector-types';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { objectsStr } from '../../../pages/alerts-v2/components/step-objects-selector/step-objects-selector.component';
import { CustomEventsSelectors } from '@states/custom-events/custom-events.selector-types';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import { glovesKeyValuePair, protectiveGearOptionsMap } from '@consts/alerts-v2.const';
import { AccessDoorsModel } from '@models/access-doors.model';


@Component({
  selector: 'ui-event-sentence',
  templateUrl: './ui-event-sentence.component.html',
  styleUrls: ['./ui-event-sentence.component.scss'],
})
export class UiEventSentenceComponent implements OnInit {

  public glovesKeyValuePair = glovesKeyValuePair;

  public selectAllCameras$ = this.store$.select(CameraSelectors.selectAllCameras)
    .pipe(take(1));
  public selectAllLocations$ = this.store$.select(LocationSelectors.selectAllLocations)
    .pipe(take(1));
  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.select(OrganizationSelectors.selectActiveOrganization)
    .pipe(take(1));

  public AlertCategory = AlertCategory;
  public SafetyType = SafetyType;
  public IdentificationType = IdentificationType;
  public TrackingType = TrackingType;
  public StatusType = StatusType;
  public CustomizedCapabilitiesType = CustomizedCapabilitiesType;
  public IntegrationsType = IntegrationsType;
  public AlertLoiteringUnit = AlertDurationUnit;

  @Input() category: AlertCategory;
  @Input() flowType: SafetyType | IdentificationType | TrackingType | StatusType | CustomizedCapabilitiesType | IntegrationsType;
  @Input() formValue: any;
  @Input() allBold = false;

  private camerasString = '';
  private doorsString = '';
  private accessDoorsString = '';
  private eventTagString = '';
  private systemTypeString = '';
  private edgeName;
  private scheduleText;

  constructor(private store$: Store) {
  }

  get objects() {
    const objects = !!this.formValue?.objects ? this.formValue?.objects?.map(item => objectsStr[item.type]) : [];
    return !!objects?.length ? objects.join(', ') : 'objects';
  }

  public get camera() {
    if (this.camerasString) {
      return this.camerasString;
    }
    return 'camera';
  }

  public get eventTag() {
    if (this.eventTagString) {
      return this.eventTagString;
    }
    return 'event tag';
  }

  public get systemType() {
    if (this.systemTypeString) {
      return this.systemTypeString;
    }
    return 'on system';
  }

  public get doorId() {
    return this.formValue?.doorId ?? 'door';
  }

  public get doors() {
    if (this.doorsString) {
      return this.doorsString;
    }
    return 'doors';
  }

  public get accessDoors() {
    if (this.accessDoorsString) {
      return this.accessDoorsString;
    }
    return 'doors';
  }

  public get openState() {
    switch (+this.formValue?.eventType) {
      case DoorModels.DoorAlertType.closed:
        return 'closed';
      case DoorModels.DoorAlertType.changed:
        return 'in motion';
      case DoorModels.DoorAlertType.open:
        return 'open';
    }
    return 'open';
  }

  public get edge() {
    return this.edgeName ?? 'core';
  }

  public get zone() {
    if (this.camerasString) {
      return this.camerasString;
    }
    return 'zone';
  }

  public get amount() {
    return this.formValue?.amount ?? 'X';
  }

  public get location() {
    if (this.formValue?.location || this.formValue?.location === 0) {
      return LocationOptions[this.formValue?.location];
    }
    return 'indoor';
  }

  public get duration() {
    return this.formValue?.duration ?? 'X';
  }

  public get durationUnit() {
    return AlertDurationUnit[this.formValue?.durationUnit] ?? 'seconds';
  }

  public get period() {
    return this.formValue?.period ?? 'X';
  }

  public get periodUnit() {
    return AlertDurationUnit[this.formValue?.periodUnit] ?? 'seconds';
  }

  public get disablePeriod() {
    return this.formValue?.disablePeriod ?? 'X';
  }

  public get disablePeriodUnit() {
    return AlertDurationUnit[this.formValue?.disablePeriodUnit] ?? 'seconds';
  }

  public get sensitivity() {
    if (this.formValue?.sensitivity || this.formValue?.sensitivity === 0) {
      return this.formValue?.sensitivity + '%';
    }
    return 'X%';
  }

  public get proximity() {
    if (this.formValue?.proximity || this.formValue?.proximity === 0) {
      return this.formValue?.proximity ? 'are close to each other' : 'appear together';
    }
    return 'are close to each other';
  }

  public get plateAndPeople() {
    const plates: AlertsV2Plates = this.formValue?.plates;
    if (plates) {
      return !!plates.appears ? 'appears on the list' : ' doesn\'t appear on the list';
    }
    return !!this.formValue?.people?.appears ? 'appears on the list' : ' doesn\'t appear on the list';
  }

  public get appears() {
    const appears = this.formValue?.appears;
    if (!appears) {
      return 'appears';
    }
    return alertsV2PeopleAppearsStr[appears];
  }

  public get appearance() {
    const appear = this.formValue?.appear || this.formValue?.appear === 0;
    if (!appear) {
      return 'appearance';
    }
    return EventTagAppearance[this.formValue?.appear];
  }

  public get wear() {
    const wear: ProtectiveGearWearOptions = +this.formValue?.wear;
    return wear === ProtectiveGearWearOptions.wear ? 'are wearing' : 'are not wearing';
  }

  public get gear() {
    const gear: ProtectiveGearOptions = +this.formValue?.gear;
    return protectiveGearOptionsMap[gear] ?? 'safety helmet';
  }

  public get wearGloves() {
    const wear: ProtectiveGearWearOptions = +this.formValue?.wear;
    return wear === ProtectiveGearWearOptions.wear ? 'wearing' : `not wearing`;
  }

  public get gloves() {
    const gloves: ProtectiveGearWearOptions = this.formValue?.gloves;
    return this.glovesKeyValuePair[gloves] ?? 'gloves';
  }

  // defineScheduleText() {
  //   const schedule = this.formValue?.schedule;
  //   if (!schedule?.length) {
  //     this.scheduleText = 'all times';
  //     return;
  //   }
  //   // Create an object to map day of week enums to day names
  //   const dayNames = {
  //     0: 'Monday',
  //     1: 'Tuesday',
  //     2: 'Wednesday',
  //     3: 'Thursday',
  //     4: 'Friday',
  //     5: 'Saturday',
  //     6: 'Sunday',
  //   };
  //
  //   // Filter out the disabled days from the schedule
  //   const enabledDays = schedule?.filter(day => !day.disabled);
  //
  //   // Create an array to store the descriptions for each day
  //   const dayDescriptions = [];
  //
  //   // Loop through each enabled day in the schedule
  //   let i = 0;
  //   while (i < enabledDays.length) {
  //     const day = enabledDays[i];
  //
  //     // If the day is an all-day schedule, add the day name to the descriptions array
  //     if (day.allDay) {
  //       dayDescriptions.push(dayNames[day.day]);
  //       i++;
  //       continue;
  //     }
  //
  //     // Otherwise, find the next day with a different schedule, or the end of the week
  //     let j = i + 1;
  //     while (j < enabledDays.length && (enabledDays[j].allDay || enabledDays[j].day === (enabledDays[j - 1].day + 1) % 7 || enabledDays[j].day === 0) && enabledDays[j].from === day.from && enabledDays[j].to === day.to) {
  //       j++;
  //     }
  //
  //     // Add the day range to the descriptions array
  //     const lastDay = enabledDays[j - 1];
  //     const firstDayName = dayNames[day.day];
  //     const lastDayName = dayNames[lastDay.day];
  //     const timeRange = `${day.from}-${day.to}`;
  //     if (j - i > 2) {
  //       dayDescriptions.push(`${firstDayName}-${lastDayName}, ${timeRange}`);
  //     } else if (j - i === 2) {
  //       dayDescriptions.push(`${firstDayName}, ${lastDayName}, ${timeRange}`);
  //     } else {
  //       dayDescriptions.push(`${firstDayName}, ${timeRange}`);
  //     }
  //
  //     // Set the loop index to the end of the range we just processed
  //     i = j;
  //   }
  //
  //   // Join the descriptions array with commas and return the result
  //   this.scheduleText = dayDescriptions.join(', ');
  // }

  defineScheduleText() {
    const schedule = this.formValue?.schedule;
    if (!schedule?.length) {
      this.scheduleText = 'all times';
      return;
    }
    // Create an object to map day of week enums to day names
    const dayNames = {
      0: 'Monday',
      1: 'Tuesday',
      2: 'Wednesday',
      3: 'Thursday',
      4: 'Friday',
      5: 'Saturday',
      6: 'Sunday',
    };

    // Filter out the disabled days from the schedule
    const enabledDays = schedule?.filter(day => day.enabled && !!day?.times?.length);

    // Create an array to store the descriptions for each day
    const dayDescriptions = [];

    // Loop through each enabled day in the schedule
    let i = 0;
    while (i < enabledDays.length) {
      const day = enabledDays[i];

      // If the day is an all-day schedule, add the day name to the descriptions array
      if (day.allDay) {
        dayDescriptions.push(dayNames[day.day]);
        i++;
        continue;
      }

      // Otherwise, find the next day with a different schedule, or the end of the week
      let j = i + 1;
      while (j < enabledDays.length && (enabledDays[j].allDay || enabledDays[j].day === (enabledDays[j - 1].day + 1) % 7 || enabledDays[j].day === 0) && enabledDays[j].from === day.from && enabledDays[j].to === day.to) {
        j++;
      }

      // Add the day range to the descriptions array
      const lastDay = enabledDays[j - 1];
      const firstDayName = dayNames[day.day];
      const lastDayName = dayNames[lastDay.day];

      if (j - i >= 2) {
        dayDescriptions.push(`${firstDayName}-${lastDayName}`);
      } else if (j - i === 2) {
        dayDescriptions.push(`${firstDayName}, ${lastDayName}`);
      } else {
        dayDescriptions.push(`${firstDayName}`);
      }

      // Set the loop index to the end of the range we just processed
      i = j;
    }

    // Join the descriptions array with commas and return the result
    this.scheduleText = dayDescriptions.join(', ');
  }


  public get schedule() {
    if (!this.formValue?.schedule) {
      return 'all times';
    }
    return this.scheduleText ?? 'all times';
  }

  selectedCameraName$(camera: SelectedCamera) {
    return this.store$.select(CameraSelectors.selectCameraNameById(camera?.cameraId))
      .pipe(take(1));
  }

  selectedDoorName$(doorId: string) {
    return this.store$.select(DoorsSelectors.selectDoorNameById(doorId))
      .pipe(take(1));
  }

  selectedEventTagName$(eventTagId: string) {
    return this.store$.select(CustomEventsSelectors.selectCustomEventNameById(eventTagId))
      .pipe(filter(name => !!name), take(1), timeout(5000), catchError(() => {
        return 'Deleted Event Tag';
      }));
  }

  async parseCamerasString(cameras: SelectedCamera[]) {
    this.camerasString = '';

    const orgCams = await lastValueFrom(this.selectAllCameras$);

    const names = [];
    for(let camera of cameras) {
      const name = await lastValueFrom(this.selectedCameraName$(camera));
      if (name) {
        names.push(name);
      }
    }

    if (orgCams?.length === cameras?.length) {
      const org = await lastValueFrom(this.selectActiveOrganization$);
      this.camerasString = `Organization ${org?.orgName}`;
      return;
    }

    const locations = await lastValueFrom(this.selectAllLocations$);
    for(let location of locations) {
      const cams = await lastValueFrom(this.store$.pipe(select(CameraSelectors.selectCamerasByLocationId(location._id)))
        .pipe(take(1)));
      if (!!cams?.length && cams?.length === cameras?.filter(c => c.locationId === location._id).length) {
        this.camerasString += `Location ${location.name}, `;
        cameras = cameras.filter(c => c.locationId !== location._id);
      }
    }

    for(let i = 0; i < names.length; i++) {
      if (i < 2) {
        this.camerasString += names[i] + (i !== names?.length - 1 && i < 1 ? ', ' : '');
      }
    }
    if (names?.length > 2) {
      this.camerasString += ` +${cameras?.length - 2} More`;
    }

    if (names.length === 0 && cameras.length > 0) {
      this.camerasString = `${cameras.length} hidden cameras`;
    }

  }

  async parseDoorsString(doors: DoorModels.Door[]) {
    this.doorsString = '';
    if (doors?.length === 1) {
      const name = await lastValueFrom(this.selectedDoorName$(doors[0].id));
      if (name) {
        this.doorsString = name;
      } else {
        this.doorsString = 'Deleted Door';
      }
      return;
    }
    for(let [index, door] of doors?.entries()) {
      let name = await lastValueFrom(this.selectedDoorName$(door.id));
      name = name ?? door?.name;
      if (index < 2) {
        this.doorsString += (name ?? 'Deleted Door') + (index !== doors?.length - 1 && index < 1 ? ', ' : '');
      }
    }
    if (doors?.length > 2) {
      this.doorsString += ` +${doors?.length - 2} More`;
    }
  }

  async parseAccessDoorsString(doors: AccessDoorsModel.DocumentMongo[]) {
    this.accessDoorsString = '';
    if (doors?.length === 1) {
      const name = doors[0]?.doorName;
      if (name) {
        this.accessDoorsString = name + ' is';
      } else {
        this.accessDoorsString = 'Deleted Door';
      }
      return;
    }
    for(let [index, door] of doors?.entries()) {
      const name = door?.doorName;
      if (index < 2) {
        this.accessDoorsString += (name ?? 'Deleted Door') + (index !== doors?.length - 1 && index < 1 ? ', ' : '');
      }
    }
    if (doors?.length > 2) {
      this.accessDoorsString += ` +${doors?.length - 2} More`;
    }
    this.accessDoorsString += ' are';
  }

  async parseEventTagString(eventTagId: string) {
    this.eventTagString = '';
    const name = await lastValueFrom(this.selectedEventTagName$(eventTagId));
    this.eventTagString = name;
  }

  async parseSystemTypeString(systemType: string) {
    this.systemTypeString = '';
    this.systemTypeString = DoorSystemType[systemType];
  }


  ngOnInit(): void {
    if (this.formValue?.camera) {
      this.parseCamerasString(this.formValue?.camera);
    }

    if (this.formValue?.doors) {
      if (this.category === AlertCategory.Integrations) {
        this.parseAccessDoorsString(this.formValue?.doors);
        return;
      }
      this.parseDoorsString(this.formValue?.doors);
    }

    if (this.formValue?.eventTagId) {
      this.parseEventTagString(this.formValue?.eventTagId);
    }

    if (this.formValue?.systemType || this.formValue?.systemType === 0) {
      this.parseSystemTypeString(this.formValue?.systemType);
    }

    if (this.formValue?.edges?.length) {
      const edgeId = this.formValue?.edges[0];
      if (edgeId) {
        this.store$.select(EdgeSelectors.selectEdgeById(edgeId))
          .pipe(take(1))
          .subscribe((edge) => {
            this.edgeName = edge?.name;
          });
      }
    }

    this.defineScheduleText();

  }

}
