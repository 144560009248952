<div class="ui-modal">
  <div class="header flex-row space-between-center">Browser compatibility alert
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>
  <div class="body">
    Our platform currently works best on Google Chrome & Edge browsers. Support for Firefox will be available shortly.
    In the
    meantime, please switch to Chrome. We are sorry for the inconvenience.
  </div>

  <div class="footer flex-row end-center">
    <ui-button
      type="default"
      text="Done"
      (clickAction)="close()"
    ></ui-button>
  </div>
</div>
