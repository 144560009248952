import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeopleService } from '../../../development/people.service';
import { Person } from '@models/people.model';

@Component({
  selector: 'ui-person-card',
  templateUrl: './ui-person-card.component.html',
  styleUrls: ['./ui-person-card.component.scss'],
})
export class UiPersonCardComponent implements OnInit {

  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  @Input() name: string;
  @Input() personId: number;
  @Input() bestImage: string;
  @Input() bestImageString: string;
  @Input() selected = false;
  @Input() allowSelection = false;
  @Input() multiSelect = false;
  @Input() allowEdit = true;
  img;

  constructor(
    private peopleService: PeopleService,
  ) {
  }


  ngOnInit(): void {
    const group : Person = {
      personId: this.personId,
      name: this.name,
      bestImage: this.bestImage,
    }
    this.img = this.peopleService.getPersonImagePath(group);
  }

}
