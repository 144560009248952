import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ui-time-period-picker',
  templateUrl: './ui-time-period-picker.component.html',
  styleUrls: ['./ui-time-period-picker.component.scss'],
})
export class UiTimePeriodPickerComponent implements OnChanges, OnInit {
  @Input() removeHours: boolean = false;
  @Input() removeMinutes: boolean = false;

  @Input() period: number;
  @Input() min: number;
  @Input() default: number;

  @Output() onTimeChanged: EventEmitter<number> = new EventEmitter<number>();
  public hours: number = 0;
  public minutes: number = 0;
  public seconds: number = 0;

  constructor() {}

  public ngOnInit(): void {
    if (!!this.default) {
      this.seconds = this.default;
      this.changeTime(this.default);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const timeInSeconds = changes['period'].currentValue;
    if (timeInSeconds) {
      this.hours = Math.floor(timeInSeconds / 3600);
      this.minutes = Math.floor((timeInSeconds - this.hours * 3600) / 60);
      this.seconds = timeInSeconds - this.hours * 3600 - this.minutes * 60;
    }
  }

  public changeTime(value: number) {
    const timeInSec = this.seconds + this.minutes * 60 + this.hours * 3600;
    this.onTimeChanged.emit(timeInSec);
  }
}
