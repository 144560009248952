<div class="ui-time-period-picker" fxLayout="row" fxLayoutAlign="space-between center">
  <div *ngIf="!removeHours" class="number-wrapper">
    <input class="number-input" type="number" [(ngModel)]="hours" (ngModelChange)="changeTime($event)"/>
    <span class="label">hours</span>
  </div>
  <div *ngIf="!removeMinutes" class="number-wrapper">
    <input class="number-input" type="number" [(ngModel)]="minutes" (ngModelChange)="changeTime($event)"/>
    <span class="label">min</span>
  </div>
  <div class="number-wrapper">
    <input class="number-input" type="number" [min]="min" [(ngModel)]="seconds" (ngModelChange)="changeTime($event)"/>
    <span class="label">sec</span>
  </div>
</div>
