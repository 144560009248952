<ng-container *ngrxLet="selectLocations$; let locationIds">
  <ng-container *ngrxLet="selectActiveOrganization$; let org">
    <div fxLayout="column" fxLayoutAlign="start start" fxFill class="ui-door-selector-dialog">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>Select doors</div>
        <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </div>
      </div>
      <div
          class="dialog-content narrower-input"
      >
        <ui-search class="mb-16"
                   (onInput)="search($event)"

                   placeholder="Search for door"
        ></ui-search>
        <div class="selector-wrapper c-p">
          <div class="org-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-checkbox color="primary" [checked]="atLeastOneInOrg()"
                          (click)="$event.preventDefault(); selectOrg();$event.stopPropagation();">

            </mat-checkbox>
            <div>{{org?.orgName}}</div>
          </div>
          <div *ngFor="let location of locations">
            <div class="location-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px"
                 (click)="toggleExpand(location)"
            >
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <mat-checkbox color="primary" [checked]="atLeastOneDoor(location)"
                              (click)="$event.preventDefault(); selectAll(location);$event.stopPropagation();">

                </mat-checkbox>
                <div>{{location.name}}</div>
              </div>
              <div class="icon-16" fxLayoutAlign="center center">
                <mat-icon [svgIcon]="location.expanded ? 'arrow_drop_down_big' : 'arrow_drop_up_big'"></mat-icon>
              </div>
            </div>
            <div *ngIf="location.expanded || query">
              <div class="door-item" *ngFor="let door of query ? filtered(location.doors) : location.doors"
                   fxLayout="row" fxLayoutAlign="start center"
                   fxLayoutGap="12px" (click)="selectDoor(door)">
                <mat-checkbox color="primary" [checked]="doorSelected(door.id)" (click)="$event.preventDefault();">

                </mat-checkbox>
                <div fxLayoutAlign="center center" class="door-image">
                  <img [lazyLoad]="img(door)">
                </div>
                <div>{{door.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <ui-button type="primary" text="Select" (clickAction)="select()"
        ></ui-button>
      </div>

    </div>
  </ng-container>
</ng-container>
