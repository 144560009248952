<div #wrapper fxLayout="column" fxFlex="" class="p-wrapper p-r">
  <div *ngIf="loader" fxLayoutAlign="center center" style="position: absolute; z-index: 999" class="w-100 h-100">
    <mat-spinner diameter="45" color="primary"></mat-spinner>
  </div>
  <div *ngIf="allowZoom && scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label hover"
       (click)="resetZoom()">
    <div class="reset">Reset</div>
    <div class="zoom">{{ scale * 100 | number : '1.0-0' }}%</div>
  </div>
  <div *ngIf="allowZoom && showZoomButtons" fxLayout="column" fxLayoutGap="5px" class="manual-zoom-wrapper c-p hover">
    <div fxLayoutAlign="center center" (click)="zoomIn()">
      <mat-icon>add</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="zoomOut()">
      <mat-icon>remove</mat-icon>
    </div>
  </div>
  <div
    #preview
    class="preview"
    [class.display-none]="!inZoom()"
    cdkDragBoundary=".p-wrapper"
    cdkDrag
    (cdkDragStarted)="preview.classList.add('move')"
    (cdkDragEnded)="preview.classList.remove('move')"
    [ngClass]="{ invisible: this.scale === 1 }"
  >
    <canvas #previewCanvas id="c1" width="208" height="117"></canvas>
  </div>
  <div
    #vidWrapper
    class="video-wrapper"
    [style.height.px]="(vidWrapper.clientWidth * 9) / 16 + 1"
    (mousewheel)="zoom($event)"
    (mousedown)="dragStart($event)"
    (mouseup)="dragging = false"
    (mousemove)="drag($event)"
    [ngClass]="{ grab: dragging }"
  >
    <video #player controls crossorigin playsinline muted="muted" id="player" allow="autoplay"></video>
  </div>
</div>
