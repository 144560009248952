<div fxLayout="column" fxLayoutAlign="start start" [class.no-box-shadow]="noBoxShadow"
     class="radio-button-wrapper ui-attribute-row p-r" [class.panel]="panel">
  <div *ngIf="beta" class="beta">Beta</div>
  <div class="radio-row header" fxLayout="row" fxLayoutAlign="start center">
    <ui-toggle [(ngModel)]="hidden" (ngModelChange)="updateValue($event)"></ui-toggle>
    <div class="radio-title-wrapper" fxLayout="column">
      <div class="radio-title">{{ title }}</div>
      <div *ngIf="description" class="radio-description">{{ description }}</div>
    </div>
  </div>
  <div *ngIf="hidden" class="content-wrapper">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
