<video [class.isCoverMode]="!isRespectRatio"
       [class.isDefault]="isRespectRatio"
       #player
       controls
       crossorigin
       playsinline
       muted="muted"
       id="player"
       appVisibilityChange
       (visibilityChanged)="checkHiddenDocument($event)"
       (timeupdate)="onTimeUpdate($event)"
></video>

<!--<div -->
<!--     fxLayout="row"-->
<!--     fxLayoutAlign="start center"-->
<!--     fxLayoutGap="10px"-->
<!--     class="stats-container"-->
<!--&gt;-->
<!--  <div>{{ stats.bitrate | number : '1.0-0' }} Kbit/s</div>-->
<!--  <div class="stats hover" *ngIf="developer" matTooltip="Show statistics">-->
<!--    <mat-icon (click)="openStats();$event.stopPropagation()">equalizer</mat-icon>-->
<!--  </div>-->
<!--</div>-->
