import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { catchError, debounce, debounceTime, Observable, of, Subject, throwError, timeout, timer } from 'rxjs';
import Hls, { ErrorData, Events, HlsListeners, ManifestParsedData } from 'hls.js';
import { CamerasService } from '../../../cameras/cameras.service';
import { VideoService } from '../../../development/video.service';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { LiveStreamModels } from '@models/live-stream.model';
import { PlayerBaseComponent } from '../player-base/player-base.component';
import { routerSegments } from '@consts/routes';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { ConfirmDialogType } from '../../confirm-dialog/confirm-dialog.model';

const hlsConfig = {
  enableWorker: true,
  lowLatencyMode: true,
  liveSyncDurationCount: 1,
  liveMaxLatencyDurationCount: 2,
  initialLiveManifestSize: 1,
  backBufferLength: 30,
  // highBufferWatchdogPeriod: 1,
  liveDurationInfinity: true,
};

@UntilDestroy()
@Component({
  selector: 'video-hls-playback',
  templateUrl: './hls-playback.component.html',
  styleUrl: './hls-playback.component.scss',
})
export class HlsPlaybackComponent extends PlayerBaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() forceMp4 = new EventEmitter<void>();

  private hlsEventListeners: Partial<HlsListeners> = {};

  private hlsDebug = false;
  private hls: Hls | null = null;

  private hlsPlaybackTs: number;
  public hlsPlaybackDuration: number;
  public hlsPlaybackSessionId: string;
  private hlsErrorCounter = 0;
  private wallCameraNum = 0;

  private moveTs: number;
  private isGrantedAccess = false;

  public incompatibleCodec = false;
  startPlaybackDebouncer: Subject<number> = new Subject<number>();
  seekPlaybackDebouncer: Subject<number> = new Subject<number>();

  constructor(
    private router: Router,
    cd: ChangeDetectorRef,
    private ngZone: NgZone,
    store$: Store,
    private camerasService: CamerasService,
    private confirm: ConfirmDialogService,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }

  ngAfterViewInit(): void {
    this.initPlayer();
    // if (!this.cameraView) {
    //   this.play();
    // }
  }

  override ngOnDestroy(): void {
    this.clearHls();
    if (this.hlsPlaybackSessionId) {
      const request: LiveStreamModels.StopHlsPlaybackRequest = {
        cameraId: this.cameraId,
        sessionId: this.hlsPlaybackSessionId,

      };
      this.camerasService.stopHlsPlayback(request)
        .subscribe();
    }
  }

  private getDebounceDuration(value: number): Observable<number> {
    return timer(1000);
  }

  setPlaybackSpeed(speed: number): void {
    try {
      if (this.hls) {
        if (speed > 1) {
          this.hls.config.maxBufferLength = 30 / speed; // reduce buffer for faster speeds
          this.hls.config.maxMaxBufferLength = 60 / speed;
        } else {
          this.hls.config.maxBufferLength = 60;
          this.hls.config.maxMaxBufferLength = 120;
        }
      }
      if (this.video) {
        this.video.playbackRate = speed;
        console.log(`[HLS] Playback speed set to:`, speed);
      } else {
        console.warn(`[HLS] Video element is not initialized.`);
      }
    } catch (error) {
      console.error(`[HLS] Error setting playback speed:`, error);
    }
  }

  override ngOnInit(): void {
    this.isGrantedAccess = this.router.url.includes(routerSegments.grantedAccess);
    this.initVideoControls();

    // Subscribe to speed changed
    this.videoService._speed$.pipe(untilDestroyed(this))
      .subscribe(speed => {
        if (this.hls) {
          this.setPlaybackSpeed(speed);
        }
      });

    this.startPlaybackDebouncer.pipe(
        debounce((value) => this.getDebounceDuration(value)),
      )
      .subscribe((ts) => {
        this.playbackStart(ts);
      });

    this.seekPlaybackDebouncer.pipe(
        debounce((value) => this.getDebounceDuration(value)),
      )
      .subscribe((ts) => {
        this.playbackStart(ts);
      });
    super.ngOnInit();
    
  }

  pause(): void {
    this.video?.pause();
  }

  clearHls() {
    if (this.hls) {
      Object.keys(this.hlsEventListeners)
        .forEach((eventKey) => {
          const event = eventKey as keyof HlsListeners;
          const listener = this.hlsEventListeners[event];
          if (listener) {
            this.hls.off(event, listener);
          }
        });
      this.hlsEventListeners = {};
      this.hls.detachMedia();
      this.hls.destroy();
      this.hls = null;
    }
  }

  stop(): void {
    // this.setPlaceholder();
    this.playerObj.nativeElement.pause();
    // Create a new video element
    this.clearVideo();
    this.clearHls();
    delete this.hlsPlaybackTs;
    delete this.hlsPlaybackDuration;

  }

  public async playbackStart(ts: number) {
    const now = Date.now();
    const lastFiveMinutes = now - 5 * 60 * 1000;

    let end = Math.min(now - 2000, ts + 30 * 60 * 1000);
    if (this.isGrantedAccess) {
      const timeline = this.videoService.timeLine;
      end = timeline.end;
    }

    ts = Math.floor(ts);
    const request: LiveStreamModels.StartHlsPlaybackRequest = {
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId,
      smartStorage: false,
      start: now - ts <= 4000 ? 0 : ts,
      end: ts > lastFiveMinutes ? 0 : end,
    };

    if (this.hlsPlaybackSessionId) {
      request.sessionId = this.hlsPlaybackSessionId;
    }

    this.setLoader.emit(true);
    this.camerasService.startHlsPlayback(request)
      .pipe(
        untilDestroyed(this),
        timeout(15000),
        catchError((err) => {
          console.log(err);
          const errObj = err?.error?.error;
          if (errObj?.statusCode === LiveStreamModels.HlsPlaybackErrors.NO_HLS) {
            this.forceMp4.emit();
            this.stop();
            if (request.start >= Date.now() - 60 * 1000) {
              this.play();
            }
            delete this.hlsPlaybackSessionId;
            delete this.hlsPlaybackTs;
            return of(err);
          }
          if (err?.name === 'TimeoutError') {
            this.playbackErrors.error = 'Timeout error';
            this.playbackErrors.statusCode = LiveStreamModels.HlsPlaybackErrors.FAILED_TO_JOIN;
          } else {
            this.playbackErrors.error = errObj?.error;
            this.playbackErrors.statusCode = errObj?.statusCode ?? LiveStreamModels.HlsPlaybackErrors.FAILED_TO_JOIN;
          }
          this.clearHealthCheck();
          this.setLoader.emit(false);
          return throwError(err);
        }))
      .subscribe((res) => {
        const connectionData: LiveStreamModels.ConnectionData = {
          cameraStatus: true,
          url: res.url,
          resolution: LiveStreamModels.StreamResolution.AUTO,
        };
        this.hlsPlaybackTs = ts;
        this.setupHls(connectionData);
        this.hlsPlaybackSessionId = res.sessionId;
        console.log('start playback response', res);
      });
    return;

  }

  public async seek(ts: number) {
    console.log('[SEEK]');
    this.pause();
    this.setPlaceholder();
    this.maskTimeUpdate = true;
    this.setLoader.emit(true);
    clearTimeout(this.canPlayTimeout);
    if (!!this.hlsPlaybackSessionId) {
      this.video.pause();
      const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
      if (ts < lastFiveMinutes && ts > this.hlsPlaybackTs && ts < this.hlsPlaybackTs + this.hlsPlaybackDuration * 1000) {
        console.log('Seeking to ', new Date(ts));
        const video = this.playerObj.nativeElement;
        const seekTime = (ts - this.hlsPlaybackTs) / 1000; // Convert milliseconds to seconds
        this.lastCurrentTime = seekTime;
        video.currentTime = seekTime; // Use the correct property
        this.health.prevTime = 0;
        this.playVideo();
      } else {
        await this.stop();
        if (!ts) {
          console.log('Received null ts 1090');
          return;
        }
        this.lastCurrentTime = 0;
        // this.playbackStart(ts);
        this.start(ts);
      }
    } else {
      if (!ts) {
        console.log('Received null ts 1096');
        return;
      }
      this.lastCurrentTime = 0;
      // this.playbackStart(ts);
      this.start(ts);
    }
  }

  start(ts: number) {
    if (!!this.hlsPlaybackSessionId) {
      this.seekPlaybackDebouncer.next(ts);
    } else {
      this.startPlaybackDebouncer.next(ts);
    }
  }

  async play(): Promise<void> {
    const ts = this.videoService.ts;
    if (!!this.hlsPlaybackSessionId) {
      return this.seek(ts);
    }
    // this.playbackStart(ts);
    this.start(ts);

  }

  extend(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  resetState(): void {
    throw new Error('Method not implemented.');
  }

  checkHiddenDocument(visibilityChanged: VisibilityChanged): Promise<void> {
    throw new Error('Method not implemented.');
  }

  changeResolution(resolution: LiveStreamModels.StreamResolution): void {
    this.qualityChange.emit(true);
    this.resolution = resolution;
    this.stop();
    this.play();
  }

  override clearVideo(): void {
    const videoElement = this.playerObj.nativeElement;
    if (videoElement) {
      // Remove video event listeners
      Object.keys(this.videoEventListeners)
        .forEach(event => {
          videoElement.removeEventListener(event, this.videoEventListeners[event]);
        });
      this.videoEventListeners = {};

      // Reset video element state
      videoElement.pause();
      videoElement.src = '';
      videoElement.load();
      videoElement.srcObject = null;
      videoElement.currentTime = 0;
    }
  }

  // Define event listener methods
  private onManifestParsed = (event: Events.MANIFEST_PARSED, data: ManifestParsedData) => {
    this.hlsPlaybackDuration = this.hls.levels[0].details.totalduration;
    console.log('[HLS] Manifest parsed, duration:', this.hlsPlaybackDuration);

    this.ngZone.run(() => {
      this.playVideo();
    });
  };

  private onHlsError = (event: Events.ERROR, data: ErrorData) => {
    if (data.fatal) {
      console.error('[HLS] Fatal HLS.js error:', event, data);
      if (data.details === Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR || data.details === Hls.ErrorDetails.BUFFER_INCOMPATIBLE_CODECS_ERROR) {

        console.log('[HLS] Incompatible codecs error, show error confirm');
        this.playbackErrors.error = 'Not supported',
          this.playbackErrors.statusCode = LiveStreamModels.HlsPlaybackErrors.NOT_SUPPORTED;
        this.hlsErrorCounter++;
        console.log('[HLS] Error counter:', this.hlsErrorCounter);
        this.setLoader.emit(false);
        // if (!this.incompatibleCodec) {
        //   this.showCodecNotSupportedError();
        // }
        this.incompatibleCodec = true;
        return;
      }

      this.hlsErrorCounter++;
      console.log('[HLS] Error counter:', this.hlsErrorCounter);
      if (!this.incompatibleCodec) {
        this.recoverHls();
      }
    }
  };

  setupHls(connectionData: LiveStreamModels.ConnectionData): void {
    const { resolution, url } = connectionData;
    this.resolution = resolution;
    this.qualityChange.emit(false);
    if (this.hlsDebug) {
      console.log(`[HLS] starting hls stream at resolution:`, resolution, 'url:', url);
    }
    try {
      // Create a new video element
      this.clearVideo();
      // this.video = this.createVideoElement();

      const video = this.video;
      const hlsUrl = url;
      if (this.hlsDebug) {
        console.log(`[HLS] hlsUrl:`, hlsUrl);
      }

      this.clearHls();

      const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
      if (this.hlsPlaybackTs > lastFiveMinutes) {
        hlsConfig['startPosition'] = 0;
        delete hlsConfig['liveMaxLatencyDurationCount'];
      } else {
        hlsConfig['liveMaxLatencyDurationCount'] = 2;
        delete hlsConfig['startPosition'];
      }

      if (Hls.isSupported()) {
        this.ngZone.runOutsideAngular(() => {
          this.hls = new Hls(hlsConfig);
          this.hls.attachMedia(video);

          this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            this.hls.loadSource(url);
          });

          // Store event handlers with correct types
          this.hlsEventListeners[Events.MANIFEST_PARSED] = this.onManifestParsed;
          this.hls.on(Events.MANIFEST_PARSED, this.onManifestParsed);

          this.hlsEventListeners[Events.ERROR] = this.onHlsError;
          this.hls.on(Events.ERROR, this.onHlsError);
          this.setPlaybackSpeed(this.videoService.speed);

        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = hlsUrl;

        this.setPlaybackSpeed(this.videoService.speed);

        // Store event handlers to remove them later
        this.videoEventListeners['loadedmetadata'] = () => {
          this.playVideo();
        };
        video.addEventListener('loadedmetadata', this.videoEventListeners['loadedmetadata']);

        this.videoEventListeners['error'] = (event) => {
          const error = video.error;
          console.error('[HLS] Video element error:', error);
          this.hlsErrorCounter++;
          this.recoverHls();
        };
        video.addEventListener('error', this.videoEventListeners['error'], { once: true });
      }
    } catch (error) {
      console.error('[HLS] Error setting up HLS:', error);
      this.hlsErrorCounter++;
      console.log('[HLS] Fatal error counter:', this.hlsErrorCounter);
      this.recoverHls();
    }
  }

  public async recoverHls() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    this.stop();
    this.play();
  }

  public showCodecNotSupportedError() {
    this.confirm.open({
        title: `Browser not supported`,
        msg: `Browser does not support the codec required to play this video. Please use a different browser or device.`,
        type: ConfirmDialogType.WARN,
        confirm: 'OK',
      })
      .subscribe(res => {
      });
  }

}
