import { createAction, props } from '@ngrx/store';
import { VehicleModels } from '@models/vehicle.model';

export const resetToInitialState = createAction('[VEHICLES] Reset to Initial state');
// export const resetUnsaved = createAction('[VEHICLES] Reset unsaved to Initial state');

export const getVehicles = createAction('[VEHICLES] Get Vehicles');
export const getVehiclesSuccess = createAction('[VEHICLES] Get Vehicles Success', props<{ vehicles: VehicleModels.Vehicle[] }>());

export const getUnsavedVehicles = createAction('[VEHICLES] Get Unsaved Vehicles');
export const getUnsavedVehiclesSuccess = createAction('[VEHICLES] Get Unsaved Vehicles Success', props<{ vehicles: VehicleModels.Vehicle[] }>());


export const setVehicles = createAction('[VEHICLES] Set Vehicles', props<{ vehicles: VehicleModels.Vehicle[] }>());

export const addVehicle = createAction('[VEHICLES] Save Vehicle', props<{ vehicle: VehicleModels.Vehicle }>());
export const addVehicleSuccess = createAction('[VEHICLES] Save Vehicle Success', props<{ vehicle: VehicleModels.Vehicle }>());

export const removeVehicle = createAction('[VEHICLES] Remove Vehicle', props<{ vehicle: VehicleModels.Vehicle }>());
export const removeVehicleSuccess = createAction('[VEHICLES] Remove Vehicle Success', props<{ vehicle: VehicleModels.Vehicle }>());

export const removeUnsavedVehicle = createAction('[VEHICLES] Remove Unsaved Vehicle', props<{ vehicle: VehicleModels.Vehicle }>());

export const editVehicle = createAction('[VEHICLES] Edit Vehicle', props<{ vehicle: VehicleModels.Vehicle }>());
export const editVehicleSuccess = createAction('[VEHICLES] Edit Vehicle Success', props<{ vehicle: VehicleModels.Vehicle }>());









