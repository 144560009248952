import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CamerasState } from '@states/cameras/cameras.reducer';

export const selectCamerasState = createFeatureSelector<CamerasState>('camerasState');
export const selectSelectedCameraCreateType = createSelector(
  selectCamerasState,
  ({ selectedCameraCreateType }: CamerasState) => selectedCameraCreateType,
);

export const selectSelectedCamera = createSelector(selectCamerasState, ({ selectedCamera }: CamerasState) => selectedCamera);
export const selectSelectedCameras = createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => selectedCameras);
export const selectSelectedCreatedCamerasCount = createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => selectedCameras.filter(camera => !!camera?.addState?.createSuccess)?.length ?? 0);
export const selectSelectedCamerasLoading = createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => !!selectedCameras.filter(camera => !!camera?.addState?.loading).length);
export const selectSelectedConnectedCamerasCount = createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => selectedCameras.filter(camera => !!camera?.addState?.cameraDetails)?.length ?? 0);
export const selectSelectedCreatedErrorCamerasCount = createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => selectedCameras.filter(camera => !!camera?.addState?.createErrorMsg)?.length ?? 0);
export const selectIsOnvif = createSelector(selectCamerasState, ({ isOnvif }: CamerasState) => isOnvif);
export const selectIsWebrtc = createSelector(selectCamerasState, ({ isWebrtc }: CamerasState) => isWebrtc);
export const selectIsWebrtcRelay = createSelector(selectCamerasState, ({ isWebrtcRelay }: CamerasState) => isWebrtcRelay);

export const selectSnapshotUrl = createSelector(selectCamerasState, ({ snapshotUrl }: CamerasState) => snapshotUrl);

export const selectCreateEnabled = createSelector(selectCamerasState, ({ createEnabled }: CamerasState) => createEnabled);

export const selectLocationId = createSelector(selectCamerasState, ({ locationId }: CamerasState) => locationId);

export const selectFile = createSelector(selectCamerasState, ({ file }: CamerasState) => file);

export const selectListFromFile = createSelector(selectCamerasState, ({ listFromFile }: CamerasState) => listFromFile);

export const selectCameraDetails = createSelector(selectCamerasState, ({ cameraDetails }: CamerasState) => cameraDetails);
export const selectCamerasDetails = createSelector(selectCamerasState, ({ camerasDetails }: CamerasState) => camerasDetails);

export const selectCameraProfiles = createSelector(selectCamerasState, ({ cameraProfiles }: CamerasState) => cameraProfiles);

export const selectLevel2MenuValid = createSelector(selectCamerasState, ({ level2MenuValid }: CamerasState) => level2MenuValid);

export const selectEdgeId = createSelector(selectCamerasState, ({ edgeId }: CamerasState) => edgeId);
export const selectCameras = createSelector(selectCamerasState, ({ cameras }: CamerasState) => cameras);
export const selectSubstreams = createSelector(selectCamerasState, ({ substreams }: CamerasState) => substreams);

export const selectQuery = createSelector(selectCamerasState, ({ query }: CamerasState) => query);
export const selectScanData = createSelector(selectCamerasState, ({ scanData }: CamerasState) => scanData);

export const selectTotalItemsCount = createSelector(selectCamerasState, ({ totalItemsCount }: CamerasState) => totalItemsCount);

export const selectPage = createSelector(selectCamerasState, ({ page }: CamerasState) => {
  return page;
});


export const selectPerPage = createSelector(selectCamerasState, ({ perPage }: CamerasState) => perPage);
export const selectCamerasAutoComplete = createSelector(selectCamerasState, ({ camerasAutoComplete }: CamerasState) => camerasAutoComplete);
export const selectSelectedCameraByIndex = (index: number) => createSelector(selectCamerasState, ({ selectedCameras }: CamerasState) => selectedCameras[index]);


