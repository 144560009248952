import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { api } from '@consts/url.const';
import { PermissionGroupModels } from '@models/group.model';
import AccessRightsModel = PermissionGroupModels.AccessRightsModel;
import { AppUser } from '../user/user.model';
import User = AppUser.User;
import { Dictionary } from '@ngrx/entity/src/models';

@Injectable()
export class PermissionGroupService {
  constructor(private http: HttpClient) {
  }

  public findAll(queryParams: PermissionGroupModels.QueryParams, pagination: { page: number; perPage: number }): Observable<PaginationResult<PermissionGroupModels.PermissionGroupDocumentBase>> {
    const query = queryParams.query ? queryParams.query : '';
    const url = `${api.permissionGroups.getByFilters}?page=${pagination.page}&size=${pagination.perPage}&query=${query}`;
    return this.http.post<PaginationResult<PermissionGroupModels.PermissionGroupDocumentBase>>(url, {});
  }

  public create(role: PermissionGroupModels.PermissionGroupDocumentBase): Observable<PermissionGroupModels.PermissionGroupDocumentBase> {
    return this.http.post<PermissionGroupModels.PermissionGroupDocumentBase>(api.permissionGroups.crud, role);
  }

  public findOne(id: string): Observable<PermissionGroupModels.PermissionGroupDocumentBase> {
    return this.http.get<PermissionGroupModels.PermissionGroupDocumentBase>(`${api.permissionGroups.crud}/${id}`);
  }

  public update(group: PermissionGroupModels.PermissionGroupDocumentBase): Observable<PermissionGroupModels.PermissionGroupDocumentBase> {
    return this.http.put<PermissionGroupModels.PermissionGroupDocumentBase>(`${api.permissionGroups.crud}/${group._id}`, group);
  }

  public updateUsers(groupId: string, users: Dictionary<any>): Observable<PermissionGroupModels.PermissionGroupDocumentBase> {
    return this.http.put<PermissionGroupModels.PermissionGroupDocumentBase>(`${api.permissionGroups.updateUsers(groupId)}`, { users });
  }

  public remove(id: string): Observable<PermissionGroupModels.PermissionGroupDocumentBase> {
    return this.http.delete<PermissionGroupModels.PermissionGroupDocumentBase>(`${api.permissionGroups.crud}/${id}`);
  }

  public getUserPermissions(): Observable<{ permissions: string[], entityIds: string[] }> {
    return this.http.get<{ permissions: string[], entityIds: string[] }>(`${api.permissionGroups.getUserPermissions}`);
  }

  public getUserPermissionsById(userId: string): Observable<AccessRightsModel & { user: Partial<User> }> {
    return this.http.get<AccessRightsModel & { user: Partial<User> }>(`${api.permissionGroups.getPermissionByUserId(userId)}`);
  }

  public updateUserGroups(groupIds: string[], userId: string): Observable<{ permissions: string[], entityIds: string[] }> {
    return this.http.put<any>(`${api.permissionGroups.updateUserGroups(userId)}`, { groupIds });
  }

}
