import { Analytics } from '../../../analytics/analytics.model';
import { createReducer, on } from '@ngrx/store';
import * as AnalyticsActions from '@states/analytics/analytics.actions';
import { AnalyticsChart } from '@models/analytic-chart.models';
import { Sort } from '../../../shared/shared.model';

export declare interface AnalyticsState {
  /**
   * @deprecated
   */
  items: Analytics.AnalyticDocument[];
  analyticsChartItems: AnalyticsChart[];
  analyticsChartAxisXLabels: string[];
  analyticsChartLegends: string[];
  analyticsChartAxisYMax: number;
  perPage: number;
  page: number;
  totalItemsCount: number;
  edgeId: string;
  cameraId: string;
  start: number;
  end: number;
  latestTs: number; // send with request
  sortDir: Sort;
  isLastPage: boolean;
  pagesTimestamp: number[];
}

export const initialState: AnalyticsState = {
  items: null,
  analyticsChartItems: null,
  analyticsChartAxisXLabels: null,
  analyticsChartLegends: null,
  analyticsChartAxisYMax: 0,
  perPage: 50,
  page: 0,
  totalItemsCount: 0,
  edgeId: null,
  cameraId: null,
  start: 0,
  end: 0,
  latestTs: null,
  sortDir: Sort.DESC,
  isLastPage: false,
  pagesTimestamp: [],
};

export const analyticsStateReducer = createReducer(
  initialState,
  /**
   * Does not reset sortDir cause it comes from camera page/state
   * to-do move sortDir co camera store
   */
  on(AnalyticsActions.resetToInitialState, state => {
    return {
      ...initialState,
      sortDir: state.sortDir,
    };
  }),
  on(AnalyticsActions.resetItems, state => {
    return {
      ...state,
      analyticsChartItems: initialState.analyticsChartItems,
    };
  }),
  on(AnalyticsActions.getAnalyticsOccupancyCounterSuccess, (state, { items }) => {
    return {
      ...state,
      items,
    };
  }),
  on(AnalyticsActions.setPagination, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(AnalyticsActions.setFilterSettings, (state, { edgeId, cameraId, start, end }) => {
    return {
      ...state,
      edgeId,
      cameraId,
      start,
      end,
    };
  }),
  on(AnalyticsActions.setAnalyticsChartItems, (state, { analyticsChartItems }) => {
    return {
      ...state,
      analyticsChartItems,
    };
  }),
  on(AnalyticsActions.setAnalyticsChartLegends, (state, { analyticsChartLegends }) => {
    return {
      ...state,
      analyticsChartLegends,
    };
  }),
  on(AnalyticsActions.setAnalyticsChartAxisYMax, (state, { analyticsChartAxisYMax }) => {
    return {
      ...state,
      analyticsChartAxisYMax,
    };
  }),
  on(AnalyticsActions.setAnalyticsChartAxisXLabels, (state, { analyticsChartAxisXLabels }) => {
    return {
      ...state,
      analyticsChartAxisXLabels,
    };
  }),
  on(AnalyticsActions.setLatestTs, (state, { latestTs }) => {
    return {
      ...state,
      latestTs,
    };
  }),
  on(AnalyticsActions.setIsLastPage, (state, { isLastPage }) => {
    return {
      ...state,
      isLastPage,
    };
  }),
  on(AnalyticsActions.setOrderDirection, (state, { orderDirection }) => {
    return {
      ...state,
      sortDir: orderDirection,
    };
  }),
  on(AnalyticsActions.setPageTimestamp, (state, { pagesTimestamp }) => {
    return {
      ...state,
      pagesTimestamp,
    };
  })
);
