import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationActions } from '@states/location/location.action-types';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, exhaustMap, of, share } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LocationsService } from '../../locations/locations.service';
import { LocationModel } from '../../locations/location.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AppState } from '../app.state';
import { EdgeService } from '../../edge/edge.service';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { SharedActions } from '@states/shared/shared.action-types';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { AuthenticationActions } from '@states/authentication/authentication.action-types';

@Injectable()
export class LocationEffects {
  GetLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetLocations),
      withLatestFrom(this.store.pipe(select(LocationSelectors.isFirstLocationLoaded))),
      filter(([action, isFirstLoaded]) => !isFirstLoaded),
      map(([action, isLocationsLoaded]) => action),
      concatMap(action => {
        return this.locationService.getAllLocations()
          .pipe(
            map(res => {
              const locationLookup = {};
              const edges = res
                .map(e => {
                  locationLookup[e._id] = e;
                  return !!e.edges ? Object.values(e.edges!) : [];
                })
                .flat();
              const cameras = !!edges?.length ? edges.map(e => (!!e.cameras ? Object.values(e.cameras!) : []))
                .flat() : [];
              return {
                locations: res,
                edges,
                cameras,
                locationLookup,
              };
            }),
            switchMap(res => {
              return [
                LocationActions.GetLocationsSuccess({ payload: res.locations }),
                LocationActions.SetLocationsLookup({ lookup: res.locationLookup }),
                EdgeActions.GetLocationEdgesNoBackendCall({ payload: res.edges }),
                CameraActions.GetLocationEdgesCamerasSnapshots({ payload: res.cameras }),
                CameraActions.GetLocationEdgesCamerasSuccess({ payload: res.cameras }),
              ];
            }),
            catchError(err => {
              const msg = err?.error?.message || 'uknown error occured get Locations Fail';
              return [
                LocationActions.GetLocationsFail({
                  message: msg,
                }),
                AuthenticationActions.Logout({ reason: msg }),
              ];
            }),
          );
      }),
      catchError(err => {
        return of(
          LocationActions.GetLocationsFail({
            message: err?.error?.message || 'uknown error occured',
          }),
        );
      }),
    ),
  );

  CreateLocationBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.CreateLocationBackendCall),
      map(action => action.request),
      concatMap((request: LocationModel.LocationCreateRequest) => {
        return this.locationService.createLocation(request)
          .pipe(
            map((res: LocationModel.LocationCreateResponse) => {
              if (!res._id) {
                const error: any = new Error(`Couldn't get location generated id`);
                error.timestamp = Date.now();
                throw error;
              }
              const location: LocationModel.LocationItem = {
                ...request,
                _id: res._id,
              };
              return LocationActions.CreateLocationSuccess({
                response: location,
              });
            }),
            catchError((err: Error) => of(LocationActions.CreateLocationFail({ message: err.message }))),
          );
      }),
    ),
  );

  CreateLocationNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.CreateLocationNoBackendCall),
      map(action => action.request),
      tap(res => {
        if (!res || !res._id) {
          const error: any = new Error(`location id is required`);
          error.timestamp = Date.now();
          throw error;
        }
      }),
      switchMap(response => {
        // new added location become automatically online
        return [
          LocationActions.CreateLocationSuccess({ response }),
          DeviceStatusActions.setLocationStatusById({ locationId: response._id, status: ComponentStatusDisplay.Online })];
      }),
      catchError((err: Error) => of(LocationActions.CreateLocationFail({ message: err.message }))),
    ),
  );

  DeleteLocationNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteLocationNoBackendCall),
      mergeMap(action => {
        return combineLatest([of(action), this.store.pipe(select(LocationSelectors.selectLocationById(action.request.locationId)))]);
      }),
      filter(([action, locationItem]) => {
        return !!locationItem;
      }),
      map(([action, locationItem]) => {
        return LocationActions.DeleteLocationSuccess({
          response: {
            locationId: locationItem!._id,
            result: true,
          },
        });
      }),
      catchError((err: Error) => {
        return of(
          LocationActions.DeleteLocationFail({
            message: err.message || 'Unknown error occurred',
          }),
        );
      }),
    ),
  );

  DeleteLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteLocation),
      map(action => action.request),
      concatMap((request: LocationModel.LocationDeleteRequest) => {
        return this.locationService.deleteLocation(request.locationId, true)
          .pipe(
            map((res: boolean) => {
              if (!res) {
                const error: any = new Error(`Couldn't get location generated id`);
                error.timestamp = Date.now();
                throw error;
              }

              return LocationActions.DeleteLocationSuccess({
                response: { result: res, locationId: request.locationId },
              });
            }),
            catchError((err: Error) => of(LocationActions.DeleteLocationFail({ message: err.message }))),
          );
      }),
    ),
  );

  DeleteCameraNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteCameraNoBackendCall),
      mergeMap(action => {
        return combineLatest([of(action), this.store.pipe(select(LocationSelectors.selectLocationById(action.request.locationId)))]);
      }),
      filter(([action, locationItem]) => {
        return !!locationItem;
      }),
      map(([action, locationItem]) => {
        return LocationActions.DeleteCameraSuccess({
          response: {
            location: locationItem!,
            ...action.request,
          },
        });
      }),
      catchError((err: Error) => {
        return of(
          LocationActions.DeleteCameraFail({
            message: err.message || 'Unknown error occurred',
          }),
        );
      }),
    ),
  );

  public updateEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.UploadEdgeConfig),
      exhaustMap(({ file, edgeId, locationId }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), LocationActions.SendEdgeConfig({ file, edgeId, locationId })];
      }),
    ),
  );

  public updateEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.UploadEdgeConfigJson),
      exhaustMap(({ config, edgeId, locationId }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), LocationActions.SendEdgeConfigJson({ config, edgeId, locationId })];
      }),
    ),
  );

  public sendEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.SendEdgeConfig),
      switchMap(({ locationId, edgeId, file }) => {
        return this.locationService.uploadEdgeConfig(locationId, edgeId, file)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                LocationActions.StartSendEdgeConfigSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.SendEdgeConfigJson),
      switchMap(({ locationId, edgeId, config }) => {
        //todo check if needs this effect
        return this.locationService.uploadEdgeConfigJson(config, locationId, edgeId, null)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                LocationActions.StartSendEdgeConfigSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public startSendEdgeConfigSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.StartSendEdgeConfigSubscribeToSessionStatus),
      exhaustMap(({ token }) => {
        return [SharedActions.setIsLoading({ isLoading: true }), LocationActions.SendEdgeConfigSubscribeToSessionStatus({ token })];
      }),
    ),
  );

  public SendEdgeConfigEdgeConfigSubscribeToSessionStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.SendEdgeConfigSubscribeToSessionStatus),
      switchMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            switchMap(res => {
              return [
                SharedActions.setIsLoading({ isLoading: false }),
                LocationActions.SendEdgeConfigSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), SharedActions.showMessage({ error: 'Timout occurred' })];
            }),
          );
      }),
      share(),
    ),
  );

  public SendEdgeConfigSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.SendEdgeConfigSessionStatusChanged),
      switchMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            switchMap(session => {
              return [
                LocationActions.SetEdgeConfigUploaded({ uploaded: true }),
                SharedActions.showMessage({
                  success: 'Config has been uploaded',
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeConfig),
      switchMap(({ locationId, edgeId }) => {
        return this.locationService.getEdgeConfig(locationId, edgeId)
          .pipe(
            switchMap(res => {
              return [
                LocationActions.GetEdgeConfigSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public GetEdgeConfigSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeConfigSubscribeToSessionStatus),
      switchMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            switchMap(res => {
              return [
                LocationActions.GetEdgeConfigSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Timout occurred' }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public GetEdgeConfigSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeConfigSessionStatusChanged),
      switchMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            switchMap(session => {
              return [
                LocationActions.SetEdgeConfig({
                  edgeConfigJson: session.result,
                }),
              ];
            }),
            catchError(response => {
              return [
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
                SharedActions.showMessage({ error: JSON.stringify(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public approveEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.ApproveEdgeConfig),
      exhaustMap(({ edgeId, locationId }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), LocationActions.SendApproveEdgeConfig({ edgeId, locationId })];
      }),
      catchError(response => {
        return [SharedActions.doNothing()];
      }),
      share(),
    ),
  );

  public sendApproveEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.SendApproveEdgeConfig),
      switchMap(({ locationId, edgeId }) => {
        return this.locationService.sendApproveEdgeConfig(locationId, edgeId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                LocationActions.StartApproveEdgeConfigSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public startApproveEdgeConfigSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.StartApproveEdgeConfigSubscribeToSessionStatus),
      exhaustMap(({ token }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), LocationActions.ApproveEdgeConfigSubscribeToSessionStatus({ token })];
      }),
    ),
  );

  public approveEdgeConfigSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.ApproveEdgeConfigSubscribeToSessionStatus),
      switchMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                LocationActions.ApproveEdgeConfigSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), SharedActions.showMessage({ error: 'Timout occurred' })];
            }),
          );
      }),
      share(),
    ),
  );

  public approveEdgeConfigSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.ApproveEdgeConfigSessionStatusChanged),
      switchMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            switchMap(session => {
              return [
                SharedActions.showMessage({
                  success: 'Config has been approved',
                }),
                LocationActions.ApproveEdgeConfigSessionStatusChangedSuccess(),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeDocumentSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeDocumentSubscribeToSessionStatus),
      switchMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            switchMap(res => {
              return [
                LocationActions.GetEdgeDocumentSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Timout occurred' }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeDocumentSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeDocumentSessionStatusChanged),
      switchMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            switchMap(session => {
              return [
                LocationActions.SetEdgeDocument({
                  edgeDocument: session.result,
                }),
              ];
            }),
            catchError(response => {
              return [
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
                SharedActions.showMessage({ error: JSON.stringify(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  /**
   *  edge info get
   */

  public getEdgeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeInfo),
      switchMap(({ locationId, edgeId }) => {
        return this.locationService.getEdgeInfo(locationId, edgeId)
          .pipe(
            switchMap(res => {
              return [
                LocationActions.GetEdgeInfoSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeInfoSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeInfoSubscribeToSessionStatus),
      switchMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            switchMap(res => {
              return [
                LocationActions.GetEdgeInfoSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Timout occurred' }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeInfoSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeInfoSessionStatusChanged),
      switchMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            switchMap(session => {
              return [
                LocationActions.SetEdgeInfo({
                  edgeInfo: session.result,
                }),
              ];
            }),
            catchError(response => {
              return [
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
                SharedActions.showMessage({ error: JSON.stringify(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private locationService: LocationsService,
    private authenticationService: AuthenticationService,
    private edgeService: EdgeService,
  ) {
  }
}
