import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplashService {
  private showSubject = new BehaviorSubject<boolean>(false);

  public show$: Observable<boolean> = this.showSubject.asObservable();

  constructor() {}

  show() {
    
    this.showSubject.next(true);
  }

  hide() {
    this.showSubject.next(false);
  }
}
