import { Component, Input, OnInit } from '@angular/core';
import { CameraStatus, CameraStatusToString } from '../../cameras/camera.model';

@Component({
  selector: 'app-camera-status-overlay',
  templateUrl: './camera-status-overlay.component.html',
  styleUrls: ['./camera-status-overlay.component.scss'],
})
export class CameraStatusOverlayComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  status: CameraStatus | null;

  @Input()
  displayStr = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  get statusStr() {
    return CameraStatusToString[this.status!];
  }

  get green() {
    return this.status === CameraStatus.Online || this.status === CameraStatus.Streaming;
  }
}
