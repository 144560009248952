<div class="ui-modal">
  <div class="header flex-row space-between-center">
    <div class="flex-row gap-8 start-center">
      <span>Structure</span>
      <span class="ui-icon-copy1 c-p"
            matTooltip="Copy document"
            [cdkCopyToClipboard]="toString()"
      ></span>
    </div>
    <span class="ui-icon-close c-p" matDialogClose></span>
  </div>

  <div class="body">
    <ng-scrollbar>
      <ngx-json-viewer [expanded]="false" [json]="data"></ngx-json-viewer>
    </ng-scrollbar>
  </div>

</div>
