import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-ntp-modal',
  templateUrl: './set-ntp-modal.component.html',
  styleUrls: ['./set-ntp-modal.component.scss'],
})
export class SetNtpModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public ntpEnabled: string = null;

  constructor(private matDialogRef: MatDialogRef<SetNtpModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.ntpEnabled);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
