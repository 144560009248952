import { createAction, props } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;

export const calculateTrigger = createAction(
  '[DeviceStatus] Calculation Trigger',
);

export const setCamerasStatus = createAction(
  '[DeviceStatus] Set Calculated Camera statuses',
  props<{
    cameraStatusMap: Dictionary<{ edgeId: string, cameraId: string, status: ComponentStatusDisplay }>
  }>(),
);


export const setEdgesStatus = createAction(
  '[DeviceStatus] Set Calculated Edges statuses',
  props<{
    edgeStatusMap: Dictionary<ComponentStatusDisplay>
  }>(),
);


export const setLocationStatus = createAction(
  '[DeviceStatus] Set Calculated Location statuses',
  props<{
    locationStatusMap: Dictionary<ComponentStatusDisplay>
  }>(),
);


export const setEdgeStatusById = createAction(
  '[DeviceStatus] Set Edge Status By Id',
  props<{
    edgeId: string;
    status: ComponentStatusDisplay
  }>(),
);

export const setCameraStatusById = createAction(
  '[DeviceStatus] Set Camera Status By Id',
  props<{
    cameraId: string;
    status: ComponentStatusDisplay
  }>(),
);

export const setLocationStatusById = createAction(
  '[DeviceStatus] Set Location Status By Id',
  props<{
    locationId: string;
    status: ComponentStatusDisplay
  }>(),
);


export const calculateLocationStatus = createAction(
  '[DeviceStatus] Calculation Location statuses',
);

export const calculateEdgeStatus = createAction(
  '[DeviceStatus] Calculation Edge statuses',
);
