import { createReducer, on } from '@ngrx/store';
import { CertificationActions } from './certifications.action-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Edge } from '../../../edge/edge.model';

export declare interface CertificationState extends EntityState<Edge.EdgeCertificationManageDocument> {
  isLoading: boolean;
  isValidationLoading: boolean;
}

export const adapter: EntityAdapter<Edge.EdgeCertificationManageDocument> = createEntityAdapter<Edge.EdgeCertificationManageDocument>({
  selectId: (entity: Edge.EdgeCertificationManageDocument) => entity.id,
});


export const initialState: CertificationState = adapter.getInitialState({
  isLoading: true,
  isValidationLoading: false,
});

export const certificationStateReducer = createReducer(
  initialState,
  on(CertificationActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(CertificationActions.setCertificationsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading: isLoading,
    };
  }),
  on(CertificationActions.getCertificationsSuccess, (state, { entities }) => {
    return adapter.setAll(entities, {
      ...state,
    });
  }),
  on(CertificationActions.setValidationLoading, (state, { validationLoading }) => {
    return {
      ...state,
      isValidationLoading: validationLoading,
    };
  }),
);
