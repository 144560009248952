<div class="ui-modal">
  <div class="header flex-row space-between-center">
    <div>{{ options.title }}</div>
    <span *ngIf="!options?.hideCancel"  class="ui-icon-close c-p"
          (click)="!!options.returnOnClose ? close(options.returnOnClose) : close(ConfirmDialogSelection.CANCEL)"
    ></span>
  </div>
  <div class="body">
    <div [innerHTML]="options.msg"></div>
    <div *ngIf="!!options.description">{{ options.description }}</div>
  </div>
  <div class="footer flex-row end-center">
    <ng-container *ngIf="options.type === ConfirmDialogType.INFO">
      <ui-button type="default" (click)="close(ConfirmDialogSelection.CANCEL)" [text]="options.cancel || 'OK' ">
      </ui-button>
    </ng-container>

    <ng-container *ngIf="options.type === ConfirmDialogType.CONFIRM">
      <ui-button *ngIf="!options?.hideCancel" type="default" (click)="close(ConfirmDialogSelection.CANCEL)" [text]="options.cancel || 'Cancel'">
      </ui-button>
      <ui-button type="primary"
                 (clickAction)="close(ConfirmDialogSelection.OK)"
                 [text]="options.confirm || 'OK'"
      >
      </ui-button>
    </ng-container>
  </div>

</div>

