import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { DashboardModel } from '@models/dashboard.model';
import { allYAxisTrackerClass, allYAxisTrackerGroups, DataSourceStr, YAxisTrackerClassStr, YAxisTrackerGroupStr, YAxisTrackerGroupTypeStr, YAxisTypeStr } from '@consts/dashboard.const';
import { UtilsService } from 'src/app/edge/utils.service';

@Component({
  selector: 'ui-tracker-class-selector',
  templateUrl: './ui-tracker-class-selector.component.html',
  styleUrl: './ui-tracker-class-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTrackerClassSelectorComponent),
      multi: true,
    },
  ],
})
export class UiTrackerClassSelectorComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;

  public YAxisTypeStr = YAxisTypeStr;
  public YAxisTrackerGroupType = DashboardModel.YAxisGroupType;
  public YAxisTrackerGroup = DashboardModel.YAxisTrackerGroup;
  public YAxisTrackerGroupTypeStr = YAxisTrackerGroupTypeStr;
  public YAxisTrackerGroupStr = YAxisTrackerGroupStr;
  public YAxisTrackerClassStr = YAxisTrackerClassStr;


  constructor(
    private utilsService: UtilsService,
  ) {
    this.form = new FormGroup({
      trackerClass: new FormControl<DashboardModel.YAxisTrackerClass[]>([]),
      trackerGroupType: new FormControl<DashboardModel.YAxisGroupType>(DashboardModel.YAxisGroupType.All),
      trackerGroup: new FormControl<DashboardModel.YAxisTrackerGroup[]>([]),
    });

    this.form.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  public get trackerGroupType() {
    return YAxisTrackerGroupTypeStr[this.form.get('trackerGroupType').value];
  }

  public get trackerGroup() {
    return this.form.get('trackerGroup').value;
  }

  public get trackerClass() {
    return this.form.get('trackerClass').value;
  }

  public get isGroupType() {
    return this.form.get('trackerGroupType').value === DashboardModel.YAxisGroupType.Group;
  }

  public get isIndividualType() {
    return this.form.get('trackerGroupType').value === DashboardModel.YAxisGroupType.Individual;
  }

  public get allIndividualSelected() {
    return this.utilsService.isArrEqual(this.trackerClass, allYAxisTrackerClass);
  }

  public get someIndividualSelected() {
    return !!this.trackerClass?.length && !this.allIndividualSelected;
  }

  public selectAllIndividual() {
    if (this.allIndividualSelected) {
      this.form.get('trackerClass')
        .setValue([]);
      return;
    }
    this.form.get('trackerClass')
      .setValue(allYAxisTrackerClass);
  }

  public get allGroupsSelected() {
    return this.utilsService.isArrEqual(this.trackerGroup, allYAxisTrackerGroups);
  }

  public get someGroupsSelected() {
    return !!this.trackerGroup?.length && !this.allGroupsSelected;
  }

  public selectAllGroups() {
    if (this.allGroupsSelected) {
      this.form.get('trackerGroup')
        .setValue([]);
      return;
    }
    this.form.get('trackerGroup')
      .setValue(allYAxisTrackerGroups);
  }

  ngOnInit(): void {
  }

  onChange = (_: any) => {
  };

  onTouched = () => {
  };


  writeValue(obj: any): void {
    if (!obj) return;
    this.form.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public updateArray(formControlName: string, value: number, isChecked: boolean) {
    const control = this.form.get(formControlName);
    const selectedValues = control.value;
    if (isChecked) {
      control.setValue([...selectedValues, value]);
    } else {
      control.setValue(selectedValues.filter(item => item !== value));
    }
  }

  public isChecked(formControlName: string, value: number): boolean {
    const control = this.form.get(formControlName);
    return control.value.includes(value);
  }


  protected readonly DataSourceStr = DataSourceStr;
}
