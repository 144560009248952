import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-something-went-wrong',
  templateUrl: './ui-something-went-wrong.component.html',
  styleUrls: ['./ui-something-went-wrong.component.scss'],
})
export class UiSomethingWentWrongComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
