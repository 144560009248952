import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationState } from '@states/organization/organization.reducer';
import * as _ from 'lodash';
import { selectUserState } from '@states/user/user.selectors';
import { UserState } from '@states/user/user.reducer';


export const selectOrganizationState = createFeatureSelector<OrganizationState>('organizationState');
export const isDeveloper = createSelector(selectUserState, (user: UserState) => {
  return user?.roles?.includes('developer');
});

export const selectPage = createSelector(selectOrganizationState, ({ page }: OrganizationState) => page);

export const selectPerPage = createSelector(selectOrganizationState, ({ perPage }: OrganizationState) => perPage);

export const selectTotalItemsCount = createSelector(selectOrganizationState, ({ totalItemsCount }: OrganizationState) => totalItemsCount);

export const selectActiveOrganization = createSelector(
  selectOrganizationState,
  ({ activeOrganization }: OrganizationState) => activeOrganization,
);

export const selectOrganizationUsers = createSelector(
  selectOrganizationState,
  ({ organizationUsers }: OrganizationState) => organizationUsers,
);

export const selectCreatedOrganization = createSelector(
  selectOrganizationState,
  ({ createdOrganization }: OrganizationState) => createdOrganization,
);

export const selectUserOrganizationsDropdown = createSelector(
  selectOrganizationState,
  ({ userOrganizations }: OrganizationState) => userOrganizations,
);

export const selectUserOrganizationsMember = createSelector(selectOrganizationState, ({ userOrganizations }: OrganizationState) =>
  userOrganizations,
);

export const selectOrganizationNameExist = createSelector(
  selectOrganizationState,
  ({ organizationNameExist }: OrganizationState) => organizationNameExist,
);

export const selectOrganizationEmailExist = createSelector(
  selectOrganizationState,
  ({ organizationEmailExist }: OrganizationState) => organizationEmailExist,
);

export const selectCreatedOrganizationInvitedUsers = createSelector(
  selectOrganizationState,
  ({ createdOrganizationInvitedUsers }: OrganizationState) => createdOrganizationInvitedUsers,
);

export const selectIsDefaultOrganization = createSelector(
  selectOrganizationState,
  ({ isDefaultOrganization }: OrganizationState) => isDefaultOrganization,
);

export const selectMaxRetentionDays = createSelector(
  selectOrganizationState,
  ({ maxRetentionDays }: OrganizationState) => maxRetentionDays,
);

export const selectOrgNameExistLoading = createSelector(
  selectOrganizationState,
  ({ orgNameExistLoading }: OrganizationState) => orgNameExistLoading,
);

export const selectActiveOrganizationAndIsDefaultOrganization = createSelector(
  selectActiveOrganization,
  selectIsDefaultOrganization,
  (org, selectIsDefaultOrganization) => {
    return {
      activeOrg: org,
      isDefaultOrganization: selectIsDefaultOrganization,
    };
  },
);


export const selectOrganizationGlobalLoader = createSelector(
  selectOrganizationState,
  ({ organizationGlobalLoader }: OrganizationState) => organizationGlobalLoader,
);
//todo replace groups
/**
 * @deprecated
 * @param allowedRoles
 */
export const isAuthorized = (allowedRoles: string[] = ['owner', 'admin']) =>
  createSelector(selectActiveOrganization, selectIsDefaultOrganization, isDeveloper, (org, isDefaultOrganization, isDevTeam) => {
    if (isDevTeam) {
      return true;
    }
    if (isDefaultOrganization) {
      return true;
    }

    if (!allowedRoles || !org?.roles?.length) {
      return false;
    }

    return _.intersection(allowedRoles, org.roles).length > 0;
  });
