import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor() { }

  setItem(key: string, value, days = 365) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${key}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
  }

  getItem(key: string) {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(row => row.startsWith(`${key}=`));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
  }

  removeItem(key: string) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }

  clear() {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const equalsIndex = cookie.indexOf('=');
      const key = equalsIndex > -1 ? cookie.slice(0, equalsIndex) : cookie;
      document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    });
  }
}
