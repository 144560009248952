import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[SAMRT-STORAGE-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetSmartStoragePulsationStatus = createAction('[SAMRT-STORAGE-HEARTBEAT-PULSATION] GetSmartStoragePulsationStatus');

export const GetSmartStoragePulsationStatusSuccess = createAction('[SAMRT-STORAGE-HEARTBEAT-PULSATION] GetSmartStoragePulsationStatusSuccess',
    props<{
        payload: PulsationModels.Store.SmartStoragePulsation[]
    }>());

export const GetSmartStoragePulsationStatusFail = createAction('[SAMRT-STORAGE-HEARTBEAT-PULSATION] GetSmartStoragePulsationStatusFail', props<{ message?: string }>());

