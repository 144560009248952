import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as AlertEventsSelectors from '@states/alert-events/alert-events.selectors';
import { AlertEventLineCrossing, AlertEventLineCrossingDirection, AlertEventLineCrossingState } from '@models/alert-events.model';
import * as _ from 'lodash';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AlertEventsEffect } from '@effects/alert-events.effect';
import { ActivatedRoute, Router } from '@angular/router';
import { UiZoneSelectorComponent } from '../ui-zone-selector/ui-zone-selector.component';
import { UiHowToDrawComponent, UiHowToDrawType } from '../ui-how-to-draw/ui-how-to-draw.component';
import { EdgeCamera } from '../../../cameras/camera.model';
import { CamerasService } from '../../../cameras/cameras.service';
import { AppState } from '../../../store/app.state';
import { UtilsService } from '../../../edge/utils.service';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'ui-line-crossing',
  templateUrl: './ui-line-crossing.component.html',
  styleUrls: ['./ui-line-crossing.component.scss'],
})
export class UiLineCrossingComponent implements OnInit, AfterViewInit {
  AlertEventLineCrossingDirection: typeof AlertEventLineCrossingDirection = AlertEventLineCrossingDirection;

  @ViewChild('selector')
  selector: UiZoneSelectorComponent;

  @ViewChild('lineSelectorWrapper')
  lineSelectorWrapper: ElementRef;

  @ViewChild('snapshot')
  snapshot: ElementRef;

  public UiHowToDrawType = UiHowToDrawType;

  @ViewChild('howToDraw')
  howToDraw: UiHowToDrawComponent;

  @ViewChild('p1')
  p1: ElementRef;
  @ViewChild('p2')
  p2: ElementRef;
  @ViewChild('line')
  line: ElementRef;

  @Input() public lineCrossing: AlertEventLineCrossing;

  selectSelectedCamera$: Observable<EdgeCamera.CameraItem> = this.store$.pipe(select(AlertEventsSelectors.selectSelectedCamera));

  // For testing purposes we will set the cameraId explicitly;
  @Input() cameraId;
  @Input() edgeId;

  imageLoaded = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.drawLine();
  }

  isAlerts = true;

  public snapshots: string[] = [];
  public snapshotIdx = 0;
  public selectedSnapshot = '';

  constructor(
    private camerasService: CamerasService,
    private store$: Store<AppState>,
    private alertEventsEffect: AlertEventsEffect,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private camerasThumbnailsService: CamerasThumbnailsService,
  ) {
  }

  ngOnInit(): void {
    this.camerasThumbnailsService.getLastSnapshots(this.cameraId)
      .subscribe((res) => {
        this.snapshots = res.bestThumbnails;
      });
  }

  saveState() {
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  onImageLoad() {
    this.imageLoaded = true;
    if (this.lineCrossing?.p1?.x) {
      this.drawLine();
    }
  }

  ngAfterViewInit(): void {
    if (!!this.lineCrossing) {

    } else {
      this.lineCrossing = {
        p1: undefined,
        p2: undefined,
        d: AlertEventLineCrossingDirection.ANY,
        state: AlertEventLineCrossingState.NO_SELECTION,
      };
    }
    if (!!this.lineCrossing && !!this.lineCrossing?.p1?.x) {
      const wrapper = this.lineSelectorWrapper.nativeElement;
      const bound = wrapper.getBoundingClientRect();
      const p1Style = this.p1.nativeElement.style;
      const p2Style = this.p2.nativeElement.style;
      p1Style.left = `${this.lineCrossing.p1.x * 100}%`;
      p1Style.top = `${this.lineCrossing.p1.y * 100}%`;
      p2Style.left = `${this.lineCrossing.p2?.x * 100}%`;
      p2Style.top = `${this.lineCrossing.p2?.y * 100}%`;
      this.onResize();
    }
  }

  noSelection() {
    return this.lineCrossing?.state === AlertEventLineCrossingState.NO_SELECTION;
  }

  p1Selected() {
    return this.lineCrossing?.state === AlertEventLineCrossingState.P1_SELECTED;
  }

  p2Selected() {
    return this.lineCrossing?.state === AlertEventLineCrossingState.P2_SELECTED;
  }

  changeDir() {
    this.lineCrossing.d = (this.lineCrossing.d + 1) % 3;
    this.saveState();
  }

  changeName(event: Event) {
    const name = (event.target as HTMLInputElement).value;
    this.lineCrossing.name = name;
    this.saveState();
  }

  moveP1(event: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    const p1Style = this.p1.nativeElement.style;
    p1Style.left = `${event.clientX - bound.left}px`;
    p1Style.top = `${event.clientY - bound.top}px`;
  }

  click(event: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    const p1 = this.p1.nativeElement;
    const p2 = this.p2.nativeElement;
    const p1Style = this.p1.nativeElement.style;
    const p2Style = this.p2.nativeElement.style;

    const percentLeft = +((event.clientX - bound.left) / bound.width).toFixed(2);
    const percentTop = +((event.clientY - bound.top) / bound.height).toFixed(2);

    const percentLeftStyle = percentLeft * 100;
    const percentTopStyle = percentTop * 100;

    switch (this.lineCrossing.state) {
      case AlertEventLineCrossingState.NO_SELECTION:
        // p1Style.left = `${event.clientX - bound.left}px`;
        // p1Style.top = `${event.clientY - bound.top}px`;
        p1Style.left = `${percentLeftStyle}%`;
        p1Style.top = `${percentTopStyle}%`;
        p2Style.left = `${percentLeftStyle}%`;
        p2Style.top = `${percentTopStyle}%`;
        this.lineCrossing.p1 = { x: percentLeft, y: percentTop };
        this.lineCrossing.state = AlertEventLineCrossingState.P1_SELECTED;
        break;
      case AlertEventLineCrossingState.P1_SELECTED:
        p2Style.left = `${percentLeftStyle}%`;
        p2Style.top = `${percentTopStyle}%`;
        this.lineCrossing.p2 = { x: percentLeft, y: percentTop };
        this.lineCrossing.state = this.lineCrossing.state = AlertEventLineCrossingState.P2_SELECTED;
        this.drawLine(event);
        break;
      case AlertEventLineCrossingState.P2_SELECTED:
        this.clear();
        break;
    }
    this.saveState();
  }

  clear(fromTemplate = false) {
    this.lineCrossing.state = this.lineCrossing.state = AlertEventLineCrossingState.NO_SELECTION;
    this.line.nativeElement.style.width = '0px';
    this.lineCrossing.p1 = undefined;
    this.lineCrossing.p2 = undefined;
    this.lineCrossing.d = AlertEventLineCrossingDirection.ANY;
    if (fromTemplate) {
      this.saveState();
    }
  }

  drawLine(event?: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    //set this.p2 point to where the mouse currently is at.
    if (!!event) {
      const percentLeft = +((event.clientX - bound.left) / bound.width).toFixed(2);
      const percentTop = +((event.clientY - bound.top) / bound.height).toFixed(2);

      const percentLeftStyle = percentLeft * 100;
      const percentTopStyle = percentTop * 100;

      if (percentLeftStyle > 100 || percentLeftStyle < 0 || percentTopStyle > 100 || percentTopStyle < 0) {
        return;
      }
      this.p2.nativeElement.style.left = `${percentLeftStyle}%`;
      this.p2.nativeElement.style.top = `${percentTopStyle}%`;
    }

    //find absolute center between both points.
    //find angle between the two.
    //find distance between the two.

    //first dot
    const posax = this.p1.nativeElement.offsetLeft;
    const posay = this.p1.nativeElement.offsetTop;

    //last dot
    const posbx = this.p2.nativeElement.offsetLeft;
    const posby = this.p2.nativeElement.offsetTop;

    //temp calculation variables
    let centerx;
    let centery;
    let distance;

    //find center points;
    centerx = (posax + posbx) / 2;
    centery = (posay + posby) / 2;

    //angle
    let angle = (Math.atan2(posay - posby, posax - posbx) * 180) / Math.PI;

    //distance
    distance = Math.sqrt(Math.pow(posbx - posax, 2) + Math.pow(posby - posay, 2));

    //bring all the work together
    this.line.nativeElement.style.width = `${distance}px`;
    this.line.nativeElement.style.transform = `rotate(${angle}deg)`;
    this.line.nativeElement.style.top = `${centery - this.line.nativeElement.clientHeight / 2 + this.p1.nativeElement.clientHeight / 2}px`;
    this.line.nativeElement.style.left = `${centerx - this.line.nativeElement.clientWidth / 2 + this.p1.nativeElement.clientWidth / 2}px`;
  }

  move(event: MouseEvent) {
    switch (this.lineCrossing.state) {
      case AlertEventLineCrossingState.NO_SELECTION:
        break;
      case AlertEventLineCrossingState.P1_SELECTED:
        this.drawLine(event);
        break;
    }
  }

  public displayHowToDraw() {
    this.howToDraw.displayHowToDraw();
  }

  nextSnapshot() {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    this.snapshotIdx = Math.max(this.snapshotIdx - 1, 0);
    this.selectedSnapshot = `${environment.trainingThumbnailsUrl}/snapshot/${edgeId}/${cameraId}/${this.snapshots[this.snapshotIdx]}`;
  }

  prevSnapshot() {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    this.snapshotIdx = Math.min(this.snapshotIdx + 1, this.snapshots.length - 1);
    this.selectedSnapshot = `${environment.trainingThumbnailsUrl}/snapshot/${edgeId}/${cameraId}/${this.snapshots[this.snapshotIdx]}`;
  }
}
