import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { ExternalStorageModels } from '@models/external-storage.models';

@Injectable({
  providedIn: 'root',
})
export class ExternalStorageService {
  constructor(private http: HttpClient) {
  }

  public getAll(filters: ExternalStorageModels.GetExternalStorageRequestFilters): Observable<ExternalStorageModels.ExternalStorageDocument[]> {
    let url = `${api.externalStorage.crud}?`;
    if (filters.locationId) {
      url += `locationId=${filters.locationId}`;
    }
    if (filters.query) {
      url += `&query=${encodeURIComponent(filters.query)}`;
    }

    return this.http.get<ExternalStorageModels.ExternalStorageDocument[]>(url);
  }

  public create(data: ExternalStorageModels.CreateExternalStorageRequest): Observable<any> {
    return this.http.post<any>(api.externalStorage.crud, data);
  }

  public save(data: ExternalStorageModels.SaveExternalStorageRequest): Observable<any> {
    return this.http.put<any>(api.externalStorage.one(data._id), data);
  }

  public testExternalStorage(data: ExternalStorageModels.TestExternalStorageRequest): Observable<any> {
    return this.http.post<any>(api.externalStorage.test, data);
  }

  public delete(locationId: string, edgeId: string): Observable<any> {
    return this.http.delete<any>(api.externalStorage.delete(locationId, edgeId));
  }

  public getOne(edgeId: string): Observable<any> {
    return this.http.get<ExternalStorageModels.ExternalStorageDocument>(api.externalStorage.one(edgeId));
  }

  public configureWall(externalStorageDoc: Partial<ExternalStorageModels.CreateExternalStorageRequest>): Observable<any> {
    return this.http.post<ExternalStorageModels.ExternalStorageDocument>(api.externalStorage.configure, externalStorageDoc);
  }
}
