import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ThumbOptions } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

;
import { AnalyticsService } from 'src/app/development/analytics.service';
import { Analytics } from 'src/app/analytics/analytics.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import * as AnalyticsActions from '@states/analytics/analytics.actions';
import * as AnalyticsSelectors from '@states/analytics/analytics.selectors';
import { AnalyticsChart } from '@models/analytic-chart.models';
import { Sort } from '../../shared.model';
import { Search } from '../../search.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { CameraLookup } from '@models/camera.model';
import { SelectedCamera } from '@models/alert-events.model';

export enum ThumbnailDialogType {
  SEARCH,
  ALERT,
}

export interface ThumbnailDialogData {
  name?: string;
  events: number[];
  searchEvents?: number[][];
  objects: Search.SearchObject[];
  options: ThumbOptions;
  edgeId: string;
  cameraId: string;
  rtl: boolean;
  defaultThumb: string;
  seconds?: boolean;
  type?: ThumbnailDialogType;
  showObjects: boolean;
  isShareVisible?: boolean;
  allowTrack?: boolean;
  alertName?: string;
  shareName?: string;
  alertId?: string;
  alertInstanceId?: string;
  selectedCamera?: SelectedCamera;
  alertTs?: number;
  initialTs?: number;
  cameraView?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-thumbnail-dialog',
  templateUrl: './thumbnail-dialog.component.html',
  styleUrls: ['./thumbnail-dialog.component.scss'],
})
export class ThumbnailDialogComponent implements OnInit, OnDestroy {
  public selectItems$: Observable<Analytics.AnalyticDocument[]> = this.store$.pipe(select(AnalyticsSelectors.selectItems));
  public selectAnalyticsChartItems$: Observable<AnalyticsChart[]> = this.store$.pipe(select(AnalyticsSelectors.selectAnalyticsChartItems));
  public selectAnalyticsChartAxisXLabels$: Observable<string[]> = this.store$.pipe(
    select(AnalyticsSelectors.selectAnalyticsChartAxisXLabels),
  );
  public selectAnalyticsChartAxisYMax$: Observable<number> = this.store$.pipe(select(AnalyticsSelectors.selectAnalyticsChartAxisYMax));
  public selectAnalyticsChartLegends$: Observable<string[]> = this.store$.pipe(select(AnalyticsSelectors.selectAnalyticsChartLegends));
  public selectPage$: Observable<number> = this.store$.pipe(select(AnalyticsSelectors.selectPage));
  public selectPerPage$: Observable<number> = this.store$.pipe(select(AnalyticsSelectors.selectPerPage));
  public selectIsLastPage$: Observable<boolean> = this.store$.pipe(select(AnalyticsSelectors.selectIsLastPage));

  public isChartVisible: boolean = false;
  rtl = false;

  constructor(
    private store$: Store<AppState>,
    private AnalyticsService: AnalyticsService,
    public dialogRef: MatDialogRef<ThumbnailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ThumbnailDialogData,
    private cameraThumbnailsService: CamerasThumbnailsService,
  ) {
  }

  ngOnInit(): void {
    this.rtl = this.data.rtl;
    const filterSetting: Analytics.AnalyticsOccupancyCounterQuery = {
      edgeId: this.data.edgeId,
      cameraId: this.data.cameraId,
      start: this.data.options.startTime,
      end: this.data.options.endTime,
      sortDir: this.rtl ? Sort.DESC : Sort.ASC,
    };
    this.store$.dispatch(
      AnalyticsActions.setFilterSettings({
        edgeId: filterSetting.edgeId,
        cameraId: filterSetting.cameraId,
        start: filterSetting.start,
        end: filterSetting.end,
      }),
    );
    this.cameraThumbnailsService.closeDialog$.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.close();
      });
    // this.store$.dispatch(AnalyticsActions.getAnalyticsOccupancyCounter());
  }

  public paginate(paginationData: { page: number; perPage: number }) {
    this.store$.dispatch(
      AnalyticsActions.setPagination({
        page: paginationData.page,
        perPage: paginationData.perPage,
      }),
    );
    this.store$.dispatch(AnalyticsActions.resetItems());
    // this.store$.dispatch(AnalyticsActions.getAnalyticsOccupancyCounter());
  }

  close() {
    this.dialogRef.close();
  }

  public ngOnDestroy() {
    this.store$.dispatch(AnalyticsActions.resetToInitialState());
  }

  public showChart(isVisible: boolean): void {
    this.isChartVisible = isVisible;
  }
}
