import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmDialogOptions, ConfirmDialogSelection, ConfirmDialogType } from './confirm-dialog.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogResult {
  selection: ConfirmDialogSelection;
  type: ConfirmDialogType;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  ConfirmDialogSelection: typeof ConfirmDialogSelection = ConfirmDialogSelection;
  ConfirmDialogType: typeof ConfirmDialogType = ConfirmDialogType;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogResult>,
    @Inject(MAT_DIALOG_DATA) public options: ConfirmDialogOptions
  ) {}

  ngOnInit(): void {}

  close(selection: ConfirmDialogSelection) {
    this.dialogRef.close({ selection, type: this.options.type });
  }
}
