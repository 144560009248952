import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-code-modal',
  templateUrl: './confirmation-code-modal.component.html',
  styleUrls: ['./confirmation-code-modal.component.scss'],
})
export class ConfirmationCodeModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public code: string;

  constructor(private matDialogRef: MatDialogRef<ConfirmationCodeModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(parseInt(this.code));
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
