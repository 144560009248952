<div class="ui-modal">
  <div class="header">
    <span class="ui-icon-close"></span>
  </div>
  <div class="body">
    <ui-input [(ngModel)]="code" label="Confirmation Code" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="ok()">Ok</button>
  </div>
</div>



