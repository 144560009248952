import { createAction, props } from '@ngrx/store';
import { WidgetTypeDecprecated, WidgetViewModeDeprecated } from '@enums/dashboard.enum';
import { RelativeCustomDateRange, WidgetDeprecated, WidgetCounterData, DashboardFavorite, DashboardModel } from '@models/dashboard.model';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { GroupModels } from "@models/people.model";

export const refreshWidgets = createAction('[DASHBOARD] Refresh Widgets');
export const refreshWidget = createAction('[DASHBOARD] Refresh Widget', props<{ id: number | string }>());

export const resetToInitialState = createAction('[DASHBOARD] Reset to Initial state');

export const addWidget = createAction('[DASHBOARD] Add Widget', props<{
  widgetType: WidgetTypeDecprecated;
  viewMode: WidgetViewModeDeprecated
}>());

export const changeDashboardName = createAction('[DASHBOARD] Change Dashboard Name', props<{
  name: string
}>());

export const setSelectedWidget = createAction('[DASHBOARD] Set Selected Widget', props<{
  widget: WidgetDeprecated
}>());

export const saveDashboard = createAction('[DASHBOARD] Save Dashboard', props<{
  dashboard: DashboardModel.Dashboard
}>());

export const sendDashboardToServer = createAction('[DASHBOARD] Save Dashboard To Server', props<{
  dashboard: DashboardModel.Dashboard
}>());

export const sendDashboardToServerSuccess = createAction('[DASHBOARD] Save Dashboard To Server Success', props<{
  dashboard: DashboardModel.Dashboard
}>());

export const sendDashboardToServerFail = createAction('[DASHBOARD] Save Dashboard To Server Fail');

export const getDashboards = createAction('[DASHBOARD] Get Dashboards');

export const getDashboardsSuccess = createAction('[DASHBOARD] Get Dashboards Success', props<{
  dashboards: DashboardModel.Dashboard[]
}>());

export const getDashboardsFail = createAction('[DASHBOARD] Get Dashboards Fail');

export const getDashboard = createAction('[DASHBOARD] Get One Dashboard', props<{
  id: number
}>());

export const getDashboardSuccess = createAction('[DASHBOARD] Get One Dashboard Success', props<{
  dashboard: DashboardModel.Dashboard
}>());

export const getDashboardFail = createAction('[DASHBOARD] Get One Dashboard Fail');

export const setInitialDashboard = createAction('[DASHBOARD] Set Initial Dashboard');

export const resetSelectedDashboard = createAction('[DASHBOARD] Reset Selected Dashboard');

export const removeDashboard = createAction('[DASHBOARD] Remove Dashboard', props<{
  id: number
}>());

export const removeDashboardSuccess = createAction('[DASHBOARD] Remove Dashboard Success');

export const removeDashboardFail = createAction('[DASHBOARD] Remove Dashboard Fail');

export const updateWidget = createAction('[DASHBOARD] Update Widget', props<{
  field: string;
  value: any;
  index: number
}>());

export const updateSelectedWidget = createAction(
  '[DASHBOARD] Update Selected Widget',
  props<{
    field: string;
    value: any;
    index: number
  }>(),
);

export const removeWidget = createAction('[DASHBOARD] Remove Widget', props<{
  localId: number
}>());

export const getWidgetData = createAction(
  '[DASHBOARD] Get Widget Data',
  props<{
    widgetType: WidgetTypeDecprecated;
    variables: string[];
    cameraId?: string;
    relative: {
      value: number;
      unit: CustomUnit;
    };
    absolute: {
      start: string;
      end: string;
      timezone: string;
    };
    relativeCustom: RelativeCustomDateRange;
    widgetIndex: number;
  }>(),
);

export const getWidgetDataSuccess = createAction(
  '[DASHBOARD] Get Widget Data Success',
  props<{
    data: WidgetCounterData;
    index: number
  }>(),
);

export const getWidgetDataFail = createAction('[DASHBOARD] Get Widget Data Fail');

export const setQuery = createAction('[DASHBOARD] Set Query', props<{
  query: string
}>());

export const setPaginationParams = createAction('[DASHBOARD] Set Pagination Params', props<{
  perPage: number;
  page: number
}>());

export const setTotalItemsCount = createAction('[DASHBOARD] Set Total Item Counts', props<{
  totalItemsCount: number
}>());

export const clearFilters = createAction('[DASHBOARD] Clear Filters');

export const resetSelectedWidget = createAction('[DASHBOARD] Reset Selected Widget');

export const cancelLoadWidgetData = createAction('[DASHBOARD] Cancel Load Widget Data');


export const UploadImages = createAction(
  '[DASHBOARD] Upload Images',
  props<{
    request: GroupModels.FileAsset[]
  }>(),
);

export const UploadImagesSend = createAction(
  '[DASHBOARD] Upload Images Send',
  props<{
    request: GroupModels.FileAsset[]
  }>(),
);

export const UploadImagesSendSuccess = createAction(
  '[DASHBOARD] Upload Images Send Success',
  props<{
    response: any
  }>(),
);

export const UploadImagesSendError = createAction(
  '[DASHBOARD] Upload Images Send Error'
);

export const setFilter = createAction('[DASHBOARD] Set Filter', props<{ filter: string; value: any }>());
export const rmFilter = createAction('[DASHBOARD] Rm Filter', props<{ filter: string; value: any }>());

