import { EdgeCamera } from '../../cameras/camera.model';
import { Zones } from '../../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { AlertEventLineCrossing, AlertEventTrafficControl } from '@models/alert-events.model';
import { ObjectData } from '@models/alerts-v2.model';
import { WallV2Model } from '@models/wall-v2.model';

export enum PrivacyType {
  pixelate = 'pixelate',
  blacken = 'blacken'
}

export interface CameraPrivacy {
  zones: Zones;
  zonesExclude?: boolean;
  objectsToPixelate: AnalyticClasses[];
  definedZones: boolean;
  markedIdx: number[];
  privacyType: PrivacyType;
}

export declare interface CameraAsset {
  _id?: string;
  cameraId: string;
  edgeId: string;
  name: string;
  orgId: string;
}

export interface UploadCameraAssetParams {
  locationId: string;
  edgeId: string;
  cameraId: string;
  file: Blob;
  asset: EdgeCamera.CameraAsset;
}

export interface NetworkManualTestForm {
  connectionString: string;
  ipAddress: string;
  port: number;
  username: string;
  password: string;
  forceTcp?: boolean;
}

export interface CameraLookup {
  locationId: string;
  edgeId: string;
  cameraId: string;
  zones?: Zones;
  markedIdx?: number[];
  lineCrossing?: AlertEventLineCrossing;
  trafficControl?: AlertEventTrafficControl;
  zonesExclude?: boolean;
  // missing object data
  objectsData?: ObjectData[];
}

export interface CameraHeatmapLookup extends CameraLookup {
  savedZones?: string;
}

export interface CameraEventSelectionResult {
  event: WallV2Model.SelectedEvent,
  camera: EdgeCamera.CameraItem
}
