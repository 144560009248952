import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { WeekDay } from '../ui-relative-time-range-picker/ui-relative-time-range-picker.component';

export const weekDayMap: { [P in WeekDay]: string } = {
  [WeekDay.Mon]: 'Mo',
  [WeekDay.Tue]: 'Tu',
  [WeekDay.Wed]: 'We',
  [WeekDay.Thu]: 'Th',
  [WeekDay.Fri]: 'Fr',
  [WeekDay.Sat]: 'Sa',
  [WeekDay.Sun]: 'Su',
};

@Component({
  selector: 'ui-week-day-picker',
  templateUrl: './ui-week-day-picker.component.html',
  styleUrls: ['./ui-week-day-picker.component.scss'],
})
export class UiWeekDayPickerComponent implements OnInit, OnChanges {
  @Input() selectedWeekDays: WeekDay[];
  @Input() startWeek: WeekDay;
  @Input() viewMode = false;

  private weekDaysArray = [WeekDay.Mon, WeekDay.Tue, WeekDay.Wed, WeekDay.Thu, WeekDay.Fri, WeekDay.Sat, WeekDay.Sun];
  public formattedWeekDays = [];
  public weekDayMap = weekDayMap;

  @Output() onDaySelected: EventEmitter<WeekDay[]> = new EventEmitter<WeekDay[]>();
  constructor() {}

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges) {
    if (this.startWeek) {
      const selectedWeekDay = this.weekDaysArray.indexOf(this.startWeek);
      this.generateWeekDaysArray(selectedWeekDay);
    } else {
      for (let i = 0; i < this.weekDaysArray.length; i++) {
        this.formattedWeekDays.push(this.weekDaysArray[i]);
      }
    }
  }

  public isDaySelected(day: WeekDay): boolean {
    const selectedWeekDays = this.selectedWeekDays ?? [];
    return selectedWeekDays.includes(day);
  }

  public selectWeekDay(day: WeekDay): void {
    const selectedWeekDays = [...(this.selectedWeekDays ?? [])];
    const index = selectedWeekDays.indexOf(day);
    if (index > -1) {
      selectedWeekDays.splice(index, 1);
    } else {
      selectedWeekDays.push(day);
    }
    this.onDaySelected.emit(selectedWeekDays);
  }

  private generateWeekDaysArray(selectedWeekDay: number): void {
    this.formattedWeekDays = [];
    for (let i = selectedWeekDay; i < this.weekDaysArray.length; i++) {
      this.formattedWeekDays.push(this.weekDaysArray[i]);
    }
    for (let i = 0; i < selectedWeekDay; i++) {
      this.formattedWeekDays.push(this.weekDaysArray[i]);
    }
  }
}
