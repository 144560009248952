import { WidgetDeprecated, Workspace } from '@models/workspace.model';
import { WidgetViewMode } from '@enums/workspace.enum';

export const initialWorkspace: Workspace = {
  name: null,
  isPublic: true,
  widgets: [],
  isInitial: true,
};

export const initialWidget: WidgetDeprecated = {
  relativeCustom: null,
  counter: null,
  heatmap: null,
  type: null,
  name: 'initial widget',
  viewMode: WidgetViewMode.LARGE,
  variables: null,
  relative: null,
  absolute: null,
};

export const defaultCounterWidget = {
  total: 0,
  latest: 0,
  peak: 0,
  minimum: 0,
  average: 0,
};
