import { createAction, props } from '@ngrx/store';
import { EdgeCamera } from '../../../cameras/camera.model';
import { AccessoryType, AgeType, AlertSettingSendFrequency, AlertType, AnalyticClasses, CarryingType, CarType, ConfigurationFilterType, DetectionType, FootwearType, GenderType, HairType, LowerBodyType, UpperBodyType } from '@enums/alert-events.enum';
import { AlertActionForm, AlertEvent, AlertEventLineCrossing, AlertEventNotification, AlertEventSendModel, AlertEventTrafficControl, ObjectInterface } from '@models/alert-events.model';
import { Zones } from '../../../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import { MenuKey } from '@enums/menu.enum';
import { FlowLookup } from '@models/alerts-v2.model';
import { CameraLookup } from '@models/camera.model';

export const resetToInitialState = createAction('[ALERT-EVENTS] Reset to Initial state');

export const setSelectedCamera = createAction('[ALERT-EVENTS] Set Selected Camera', props<{ selectedCamera: EdgeCamera.CameraItem }>());

export const setTrackedObject = createAction('[ALERT-EVENTS] Set Tracked Object', props<{ trackedObject: AnalyticClasses }>());

export const setConfigurationFilterType = createAction(
  '[ALERT-EVENTS] Set Configuration Filter Type',
  props<{ configurationFilterType: ConfigurationFilterType }>(),
);

export const setAlertType = createAction('[ALERT-EVENTS] Set Alert Type', props<{ alertType: AlertType }>());

export const setZones = createAction('[ALERT-EVENTS] Set Zones', props<{ zones: Zones }>());

export const setDefinedZones = createAction('[ALERT-EVENTS] Set Defined Zones', props<{ definedZones: boolean }>());

export const setMeasureCrossZones = createAction('[ALERT-EVENTS] Set Measure Cross Zones', props<{ measureCrossZones: boolean }>());

export const setMarkedIdx = createAction('[ALERT-EVENTS] Set Marked Indexes', props<{ markedIdx: number[] }>());

export const continueAlertEvents = createAction('[ALERT-EVENTS] Continue Alert Events');

export const backAlertEvents = createAction('[ALERT-EVENTS] Back Alert Events');

export const setDetectionType = createAction('[ALERT-EVENTS] Set Detection Type', props<{ detectionType: DetectionType }>());

export const setNotifications = createAction('[ALERT-EVENTS] Set Notifications', props<{ notifications: AlertEventNotification }>());

export const setMultipleObjects = createAction('[ALERT-EVENTS] Set Multiple Objects', props<{ multipleObjects: ObjectInterface[] }>());

export const setOffenderObject = createAction('[ALERT-EVENTS] Set Offender Object', props<{ offenderObject: AnalyticClasses }>());

export const setAlertName = createAction('[ALERT-EVENTS] Set Alert Name', props<{ alertName: string }>());

export const validateDetails = createAction('[ALERT-EVENTS] Validate Details');
export const validateConfiguration = createAction('[ALERT-EVENTS] Validate Configuration');

export const clearFilter = createAction('[ALERT-EVENTS] Clear Filter', props<{ filter: string }>());

export const setGenderType = createAction('[ALERT-EVENTS] Set Gender Type', props<{ genderType: GenderType }>());

export const setAgeType = createAction('[ALERT-EVENTS] Set Age Type', props<{ ageType: AgeType }>());

export const setFootwearType = createAction('[ALERT-EVENTS] Set Footwear Type', props<{ footwearType: FootwearType }>());

export const setFootwearColor = createAction('[ALERT-EVENTS] Set Footwear Color', props<{ footwearColor: string[] }>());

export const setLowerBodyColor = createAction('[ALERT-EVENTS] Set Lower Body Color', props<{ lowerBodyColor: string[] }>());

export const setLowerBodyType = createAction('[ALERT-EVENTS] Set Lower Body Type', props<{ lowerBodyType: LowerBodyType }>());

export const setUpperBodyColor = createAction('[ALERT-EVENTS] Set Upper Body Color', props<{ upperBodyColor: string[] }>());

export const setUpperBodyType = createAction('[ALERT-EVENTS] Set Upper Body Type', props<{ upperBodyType: UpperBodyType }>());

export const setHairColor = createAction('[ALERT-EVENTS] Set Hair Color', props<{ hairColor: string[] }>());

export const setHairType = createAction('[ALERT-EVENTS] Set Hair Type', props<{ hairType: HairType }>());

export const setAccessoryType = createAction('[ALERT-EVENTS] Set Accessory Type', props<{ accessoryType: AccessoryType }>());

export const setCarryingType = createAction('[ALERT-EVENTS] Set Carrying Type', props<{ carryingType: CarryingType }>());

export const saveAlert = createAction('[ALERT-EVENTS] Save Alert');
export const sendAlert = createAction('[ALERT-EVENTS] Send Alert');
export const saveAlertSuccess = createAction('[ALERT-EVENTS] Save Alert Success');

export const addValidMenuPoint = createAction('[ALERT-EVENTS] Add Valid Menu Point', props<{ point: MenuKey }>());

export const rmValidMenuPoint = createAction('[ALERT-EVENTS] Rm Valid Menu Point', props<{ point: MenuKey }>());

export const validateZones = createAction('[ALERT-EVENTS] Validate Zones');
export const validateLineCrossing = createAction('[ALERT-EVENTS] Validate Line Crossing');

export const validateTrafficControl = createAction('[ALERT-EVENTS] Validate Traffic Control');

export const setSettingsSendFrequency = createAction(
  '[ALERT-EVENTS] Set Settings Send Frequency',
  props<{ frequency: AlertSettingSendFrequency }>(),
);

export const setSettingsBlockNotificationPeriod = createAction(
  '[ALERT-EVENTS] Set Settings Block Notification Period',
  props<{ blockNotificationPeriod: number }>(),
);

export const setSettingsAdditionalOptions = createAction(
  '[ALERT-EVENTS] Set Settings Additional Options',
  props<{ additionalOptions: boolean }>(),
);

export const resetAlertEvents = createAction('[ALERT-EVENTS] Reset Alert Events from Server');

export const getAlertEvents = createAction('[ALERT-EVENTS] Get Alert Events from Server');

export const setAlertEvents = createAction('[ALERT-EVENTS] Set Alert Events from Server', props<{ alertEvents: AlertEvent[] }>());

export const setTotalItemsCount = createAction('[ALERT-EVENTS] Set Total Item Counts', props<{ totalItemsCount: number }>());

export const getSelectedAlert = createAction('[ALERT-EVENTS] Get Selected Alert', props<{ id: string }>());

export const setSelectedAlert = createAction('[ALERT-EVENTS] Set Selected Alert', props<{ selectedAlertEvent: AlertEvent }>());

export const setCarColor = createAction('[ALERT-EVENTS] Set Car Color', props<{ carColor: string[] }>());

export const setCarMake = createAction('[ALERT-EVENTS] Set Car Make', props<{ carMake: string[] }>());

export const setCarModel = createAction('[ALERT-EVENTS] Set Car Model', props<{ carModel: string[] }>());

export const setCarType = createAction('[ALERT-EVENTS] Set Car Type', props<{ carType: CarType }>());

export const setLineCrossing = createAction('[ALERT-EVENTS] Set Line Crossing', props<{ lineCrossing: AlertEventLineCrossing }>());

export const setTrafficControl = createAction('[ALERT-EVENTS] Set Traffic Control', props<{ trafficControl: AlertEventTrafficControl }>());

export const removeEvent = createAction(
  '[ALERT-EVENTS] Remove Event',
  props<{ id: string; }>(),
);

export const removeEventSuccess = createAction(
  '[ALERT-EVENTS] Remove Event Success',
);

export const enableEvent = createAction('[ALERT-EVENTS] Enable Event', props<{ alert: AlertEventSendModel; enabled: boolean }>());

export const setQuery = createAction('[ALERT-EVENTS] Set Query', props<{ query: string }>());
export const setCameras = createAction('[ALERT-EVENTS] Set Cameras', props<{ cameras: CameraLookup[] }>());
export const setFlowTypes = createAction('[ALERT-EVENTS] Set Flow Types', props<{ flowTypes: FlowLookup[] }>());

/**
 * @deprecated dont use in terms of infinity scroll
 */
export const setPaginationParams = createAction('[ALERT-EVENTS] Set Pagination Params', props<{ perPage: number; page: number }>());

export const setSettingScheduleDayFrom = createAction(
  '[ALERT-EVENTS] Set Setting Schedule Day From',
  props<{ day: string; from: string }>(),
);

export const setSettingScheduleDayTo = createAction('[ALERT-EVENTS] Set Setting Schedule Day To', props<{ day: string; to: string }>());

export const setEnableScheduleDay = createAction('[ALERT-EVENTS] Set Enable Schedule Day', props<{ day: string; enabled: boolean }>());

export const setGreenListValue = createAction('[ALERT-EVENTS] Set Green List Value', props<{ greenList: string }>());

export const setRedListValue = createAction('[ALERT-EVENTS] Set Red List Value', props<{ redList: string }>());

export const setUnrecognizedValue = createAction('[ALERT-EVENTS] Set Unrecognized Value', props<{ unrecognized: boolean }>());

export const setDetectionDirection = createAction('[ALERT-EVENTS] Set Detection Direction', props<{ direction: string }>());

export const setDetectionCount = createAction('[ALERT-EVENTS] Set Detection Count', props<{ count: number }>());

export const setDetectionSensitivity = createAction('[ALERT-EVENTS] Set Detection Sensitivity', props<{ sensitivity: number }>());

export const setSettingsEnableNotificationSound = createAction(
  '[ALERT-EVENTS] Set Settings Enable Notification Sound',
  props<{ enableNotificationSound: boolean }>(),
);

export const setSettingsPushAlert = createAction('[ALERT-EVENTS] Set Settings Push Alert', props<{ pushAlert: boolean }>());

export const setSettingsEnableAlertThumbnail = createAction(
  '[ALERT-EVENTS] Set Settings Enable Alert Thumbnail',
  props<{ alertThumbnail: boolean }>(),
);

export const setSettingsEnableAlertZoomThumbnail = createAction(
  '[ALERT-EVENTS] Set Settings Enable Alert Zoom Thumbnail',
  props<{ alertZoomThumbnail: boolean }>(),
);

export const syncEvent = createAction('[ALERT-EVENTS] Sync Event', props<{ alert: AlertEventSendModel; synced: boolean }>());
export const migrateEvent = createAction('[ALERT-EVENTS] Migrate Event to v2', props<{ alert: AlertEventSendModel; synced: boolean }>());

export const setEdgeTimezone = createAction('[ALERT-EVENTS] Set Settings Timezone', props<{ timezone: string }>());
export const setAutoArchive = createAction('[ALERT-EVENTS] Set Auto Archive Enable', props<{ enabled: boolean }>());
export const setAutoArchiveTtl = createAction('[ALERT-EVENTS] Set Auto Archive TTL Msecs', props<{ ttlSeconds: number }>());

export const setTreshholdTime = createAction('[ALERT-EVENTS] Set Treshhold Time', props<{ tresholdTime: number }>());

export const setActions = createAction('[ALERT-EVENTS] Set Actions', props<{ actions: AlertActionForm[] }>());

export const setMultiCameras = createAction('[ALERT-EVENTS] Set Multi Cameras', props<{ multiCameras: EdgeCamera.CameraItem[] }>());

export const checkDisabledMenu = createAction('[ALERT-EVENTS] Check Disabled Menu');
export const resetAlertEventsState = createAction('[ALERT-EVENTS] Reset to Alert Events Initial state');
