<div
    fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
    class="ui-button-wrapper"
    (click)="click()"
    [class.disabled]="disabled || loading"
>
  <div matRipple class="h-100 w-100 ui-button"
       [class.default]="size === buttonSizes.Default"
       [class.button50]="size === buttonSizes.Button50"
       [class.loading]="loading"
  >
    <mat-icon *ngIf="icon && !loading">{{ icon }}</mat-icon>

    <div *ngIf="loading">
      <ui-preloader [color]="preloaderColor"
                    *ngIf="loading"
                    [diameter]="18"
                    class="mr-16"
      ></ui-preloader>
    </div>
    <span class="ui-sub-title text">
      <ng-content></ng-content>
      {{ text }}
  </span>
  </div>
</div>
