import { createAction, props } from '@ngrx/store';
import { EdgeCamera } from '../../../cameras/camera.model';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { SearchQueryOperator } from '@enums/search.enum';
import { Search, SearchType } from '../../../shared/search.model';
import { SearchSelection, SearchSelectionPersonProperty } from '@models/search.model';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { CustomEventModel } from '@models/custom-event.model';

export const resetToInitialState = createAction('[MULTI_SEARCH] Reset to Initial state');

export const setSelectedCameras = createAction(
  '[MULTI_SEARCH] Set Selected Cameras',
  props<{ selectedCameras: EdgeCamera.CameraItem[]; noReset?: boolean }>(),
);

export const setSearchSelections = createAction('[MULTI_SEARCH] Set Search Selections', props<{ searchSelections: SearchSelection[] }>());
export const setSavedSearches = createAction('[MULTI_SEARCH] Set Saved Searches', props<{ savedSearches: Search.SavedSearch[] }>());
export const setUseSingleStore = createAction('[MULTI_SEARCH] Set Use SingleStore', props<{ useSingleStore: boolean }>());
export const setTotalItemsCount = createAction('[MULTI_SEARCH] Set Total Item Counts', props<{ totalItemsCount: number }>());
export const setDwellRange = createAction('[MULTI_SEARCH] Set Dwell Range', props<{ dwellRange: Search.SearchDwellRange }>());

export const setSelectedSavedSearch = createAction(
  '[MULTI_SEARCH] Set Selected Saved Search',
  props<{ selectedSavedSearch: Search.SavedSearch }>(),
);

export const setHighConfidence = createAction('[MULTI_SEARCH] Set Search High Confidence', props<{ highConfidence: boolean }>());
export const setPrecision = createAction('[MULTI_SEARCH] Set Search Precision', props<{ precision: Search.Precision }>());
export const setSearchV2 = createAction('[MULTI_SEARCH] Set Search V2', props<{ searchV2: boolean }>());

export const getCamerasSearchSettings = createAction('[MULTI_SEARCH] Get Camera Search Settings');

export const getCamerasSearchSettingsSuccess = createAction(
  '[MULTI_SEARCH] Get Camera Search Settings Success',
  props<{ cameraSearchSettings: CameraSearchSettings[]; camerasCountRequested: number }>(),
);

export const setObjectSelection = createAction('[MULTI_SEARCH] Set Object Selection', props<{ objectSelections: any }>());
export const setRelativeTime = createAction('[MULTI_SEARCH] Set Relative Time');
export const deleteObjectFromSelection = createAction('[MULTI_SEARCH] Delete Object From Selection', props<{ index: number }>());
export const deletePropertyFromSelection = createAction(
  '[MULTI_SEARCH] Delete property from selection',
  props<{ index: number; property: any; value: any }>(),
);
export const startSearch = createAction('[MULTI_SEARCH] Start Search');

export const setObjectSelectionFormatted = createAction(
  '[MULTI_SEARCH] Set Object Selection Formatted',
  props<{ objectSelectionsFormatted: any }>(),
);

export const setOuterOperator = createAction('[MULTI_SEARCH] Set Outer Operator', props<{ outerOperator: SearchQueryOperator }>());

export const setDateRange = createAction('[MULTI_SEARCH] Set Date Range', props<{ dateRange: Search.DateRange }>());
export const setDateTimeRange = createAction('[MULTI_SEARCH] Set Date Time Range', props<{ dateTimeRange: OnRangeSelectedResult }>());

export const removeSavedSearch = createAction('[MULTI_SEARCH] Remove Saved Search', props<{ id: string }>());

export const saveSearch = createAction('[MULTI_SEARCH] Save Search', props<{
  name: string,
  motionSearch?: boolean;
  unusualEventSearch?: boolean;
  customEventSearch?: boolean;
}>());
export const updateSearch = createAction('[MULTI_SEARCH] Update Search', props<{
  name: string, motionSearch?: boolean;
  unusualEventSearch?: boolean;
  customEventSearch?: boolean;
}>());
export const sendSavedSearch = createAction('[MULTI_SEARCH] Send saved search', props<{
  name: string, update?: boolean, motionSearch?: boolean;
  unusualEventSearch?: boolean;
  customEventSearch?: boolean;
}>());
export const getSavedSearches = createAction('[MULTI_SEARCH] Get saved searches');

export const setQuery = createAction('[MULTI_SEARCH] Set Query', props<{ query: string }>());
export const removeSelectedCamera = createAction('[MULTI_SEARCH] Remove selected camera', props<{ cameraId: string }>());
export const loadSavedSearch = createAction('[MULTI_SEARCH] Load saved search');

export const setPaginationParams = createAction('[MULTI_SEARCH] Set Pagination Params', props<{ perPage: number; page: number }>());
export const cameraChipChange = createAction('[MULTI_SEARCH] Camera Chip Change');

export const setCustomEvent = createAction('[MULTI_SEARCH] Set Custom Event', props<{ customEvent: CustomEventModel.CustomEvent }>());
export const setMotion = createAction('[MULTI_SEARCH] Set Motion Sensitivity', props<{ motion: Search.MotionSearchParams }>());
export const setUnusualEvent = createAction('[MULTI_SEARCH] Set Unusual event Sensitivity', props<{ unusualEvent: Search.UnusualEventSearchParams }>());
export const setSearchType = createAction('[MULTI_SEARCH] Set Search Type', props<{ searchType: SearchType }>());
export const setFaces = createAction('[MULTI_SEARCH] Set Faces', props<{ faces: Search.Faces }>());
