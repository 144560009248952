<div [formGroup]="form" class="narrower-input person-selector p-r">
  <div *ngIf="canUploadImage" (click)="uploadImage.emit()" class="upload-image flex-col center-center">
    <span class="ui-icon-camera1"></span>
  </div>
  <div class="beta-label" *ngIf="beta" [class.with-upload]="canUploadImage">Beta</div>
  <mat-form-field style="width:100%" appearance="outline" class="no-hint">
    <ui-button *ifDeveloper
               (click)="selectAll()"
               type="default" class="select-all-button"
    >Select all
    </ui-button>
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Person name"
      matInput
      formControlName="query"
      [matAutocomplete]="auto"
      (click)="initSelector()"
    />
    <button *ngIf="form?.get('query')?.value?.bestImage" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText">
      <ng-container *ngFor="let vehicle of filteredOptions | async">
        <mat-option *ngIf="!selectedIds?.includes(vehicle.id)" [value]="vehicle">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span class="ui-icon-object-vehicle"></span>
            <div class="person-name">{{ vehicle.plate }}</div>
          </div>
        </mat-option>
      </ng-container>
    </mat-autocomplete>

  </mat-form-field>
</div>
