import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, skip, Subject } from 'rxjs';
import { TimelineDragEvent } from '../shared/timeline/timeline-series/timeline-series.component';
import { Store } from '@ngrx/store';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { MediaCacheService } from '../shared/media-cache/media-cache.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {

  private _primaryEdgeId: string;
  private _primaryCameraId: string;

  private timelineDragStart = new Subject<void>();
  public timelineDragStart$ = this.timelineDragStart.asObservable();

  private timelineDragEnd = new BehaviorSubject<TimelineDragEvent>({ end: 0, start: 0 });
  public timelineDragEnd$ = this.timelineDragEnd.asObservable()
    .pipe(skip(1));

  private _timeline: TimelineDragEvent = { start: 0, end: 0 };

  private _speed = new BehaviorSubject<number>(1);
  public _speed$ = this._speed.asObservable();

  private timeChanged = new Subject<TimelineDragEvent>();
  public timeChanged$ = this.timeChanged.asObservable();

  private seekStart = new Subject<void>();
  public seekStart$ = this.seekStart.asObservable();

  private seekEnd = new Subject<TimelineDragEvent>();
  public seekEnd$ = this.seekEnd.asObservable();

  private pause = new Subject<void>();
  public pause$ = this.pause.asObservable();

  private _play = new Subject<boolean>();
  public play$ = this._play.asObservable()
    .pipe(skip(1));

  private _stop = new Subject<void>();
  public stop$ = this._stop.asObservable();

  private _emulate = new Subject<void>();
  public emulate$ = this._emulate.asObservable();

  private placeholder = new BehaviorSubject<boolean>(false);
  public placeholder$ = this._play.asObservable();

  private _errorMsg = new BehaviorSubject<string>('');
  public errorMsg$ = this._play.asObservable();

  private toggleToLiveView = new Subject<void>();
  public toggleToLiveView$ = this.toggleToLiveView.asObservable();

  private showCreateArchiveModal = new Subject<void>();
  public showCreateArchiveModal$ = this.showCreateArchiveModal.asObservable();

  public _ts = new BehaviorSubject<number>(0);
  public _live = new BehaviorSubject<boolean>(true);

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove);

  constructor(private store$: Store, private mediaCacheService: MediaCacheService) {
    this.selectMove$.subscribe(move => {
      if (move) {
        this._ts.next(move.timestamp);
      }
    });
  }


  public get timeLine() {
    return this._timeline;
  }

  public set timeLine(timeline: TimelineDragEvent) {
    this._timeline = timeline;
  }

  public setSpeed(speed: number) {
    this._speed.next(speed);
  }

  public get speed() {
    return this._speed.value;
  }

  public setPrimaryCamera(edgeId: string, cameraId: string) {
    this._primaryEdgeId = edgeId;
    this._primaryCameraId = cameraId;
  }

  public get primaryCameraId() {
    return this._primaryCameraId;
  }

  public get primaryEdgeId() {
    return this._primaryEdgeId;
  }

  public get isPlaceholder() {
    return this.placeholder.value;
  }

  public set ts(ts: number) {
    this._ts.next(ts);
  }

  public get ts(): number {
    return this._ts.value;
  }

  public get isLive(): boolean {
    return this._live.value;
  }

  public get errorMsg(): string {
    return this._errorMsg.value;
  }

  public setErrorMsg(msg: string) {
    this._errorMsg.next(msg);
  }

  public OnTimelineDragStart() {
    this.timelineDragStart.next();
  }

  public OnTimelineDragEnd(event: TimelineDragEvent) {
    this.setLive(false);
    this.timelineDragEnd.next(event);
  }

  public OnTimeChanged(event: TimelineDragEvent) {
    this.timeChanged.next(event);
  }

  public OnSeekStart() {
    this.seekStart.next();
  }

  public OnSeekEnd(event: TimelineDragEvent) {
    this.seekEnd.next(event);
  }

  public OnPause() {
    this.pause.next();
  }

  public OnInactive() {
    this.store$.dispatch(SharedActions.setIsInactive({ isInactive: true }));
  }

  public async OnPlay() {
    this.store$.dispatch(SharedActions.setIsInactive({ isInactive: false }));
    const offline = await this.isOffline();
    if (offline && !this.isLive) {
      this._emulate.next();
    } else {
      this._play.next(false);
    }
  }

  public OnStop() {
    return this._stop.next(null);
  }

  public OnEmulate() {
    this._emulate.next();
  }

  public OnToggleToLiveView() {
    this.toggleToLiveView.next();
  }

  public OnShowCreateArchiveModal() {
    this.showCreateArchiveModal.next();
  }

  public setPlaceholder() {
    this.placeholder.next(true);
  }

  public removePlaceholder() {
    this.placeholder.next(false);
  }

  public play(live = true) {
    this._play.next(live);
  }

  public setLive(live: boolean) {
    this._live.next(live);
  }

  public reset() {
    this._live.next(true);
    this._ts.next(0);
  }

  public async isOffline() {
    const offline = await this.mediaCacheService.isOffline(this._primaryEdgeId, this._primaryCameraId, this.ts);
    return offline;
  }

}
