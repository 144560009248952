import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[ANALYTIC-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetAnalyticPulsationStatus = createAction('[ANALYTIC-HEARTBEAT-PULSATION] GetAnalyticPulsationStatus');

export const GetAnalyticPulsationStatusSuccess = createAction('[ANALYTIC-HEARTBEAT-PULSATION] GetAnalyticPulsationStatusSuccess',
    props<{
        payload: PulsationModels.Store.AnalyticPulsation[]
    }>());

export const GetAnalyticPulsationStatusFail = createAction('[ANALYTIC-HEARTBEAT-PULSATION] GetAnalyticPulsationStatusFail', props<{ message?: string }>());

