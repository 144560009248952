<div #wrapper class="w-100 h-100" (wheel)="zoom($event)">
  <div class="w-100 h-100 p-r drag-wrapper"
       cdkDrag
       [cdkDragBoundary]="wrapper"
       cdkDragLockAxis="x"
       [class.camera-view]="isCameraView"
       (cdkDragMoved)="dragMove($event)"
       (cdkDragEnded)="dragEnd($event)"
       (cdkDragStarted)="dragStart($event)"
       [class.dragging]="dragging"
  >
    <div class="round-time" *ngFor="let serie of series; let i = index" [style.left]="serie.percentage + '%'">
      <div *ngIf="+serie?.percentage >= (wrapper?.clientWidth > 600 ? 5 : 7) && +serie?.percentage <= (wrapper?.clientWidth > 600 ? 95 : 93)"
           class="line p-r"
      >
        <div class="hour">{{ serie.time }}</div>
      </div>
    </div>
  </div>
</div>
