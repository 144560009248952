import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { map, Observable, of, Subject, Subscription } from 'rxjs';
import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { environment } from '../../environments/environment';
import { doc, docSnapshots, DocumentData, DocumentReference, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import * as ArchiveActionsV2 from '@states/archive/archive.actions';
import { Playback } from '../cameras/playback.model';
import { PaginationResult } from '@models/shared.model';
import { Archive, ArchiveRequestFilter, ArchiveStatus } from '@models/archive.model';
import { HttpClient } from '@angular/common/http';
import { GrantedAccessModel } from '@models/granted-access-model';
import { api } from '@consts/url.const';

export interface ArchiveQuery {
  edgeId?: string;
  cameraId?: string;
  timestamp?: number;
  start?: string | number;
  end?: string | number;
  id?: number;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ArchiveService {

  subscriptions: KeyValuePairs<Subscription> = {};

  constructor(private httpService: HttpService, private http: HttpClient, private firestore: Firestore, private store: Store) {
  }

  createArchive(
    data: Playback.PlaybackDownloadRequest,
    grantedAccess?: GrantedAccessModel.GrantedAccessModelRequest,
  ): Observable<{ sqsMessgaeInfo: SQSMsgInfo; filename: string; sessionId: string; archiveId: string; }> {
    const url = `${environment.apiUrl}/cameras/camera/playback/download`;
    return this.httpService.http.post<{ sqsMessgaeInfo: SQSMsgInfo; filename: string; sessionId: string; archiveId: string; }>(url, {
      ...data,
      grantedAccess,
    });
  }

  delete(sessionId: string) {
    const url = `${environment.apiUrl}/archive/${sessionId}`;
    return this.httpService.http.delete(url);
  }

  downloadMp4(sessionId: string): Observable<{ url: string }> {
    return this.httpService.http.get<{ url: string }>(api.archive.download(sessionId), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getAll(page: number, size = 20, filter?: ArchiveRequestFilter): Observable<PaginationResult<Archive.ArchiveDocument>> {
    let url = `${environment.apiUrl}/archive/all?page=${page}&size=${size}`;
    if (filter?.selectedCameras?.length) {
      url += `&selectedCameras=${filter.selectedCameras.map(camera => camera.cameraId)
        .join(',')}`;
    }
    if (filter?.start && filter?.end) {
      url += `&start=${filter.start}&end=${filter.end}`;
    }
    if (filter?.query) {
      url += `&query=${filter.query}`;
    }
    if (filter?.status?.length) {
      url += `&status=${filter.status.join(',')}`;
    }
    return this.http.get<PaginationResult<Archive.ArchiveDocument>>(url);
  }

  public updateArchive(data: Archive.ArchiveDocument): Observable<any> {
    const url = `${environment.apiUrl}/archive/${data._id}`;
    return this.httpService.http.put(url, data);
  }


  downloadHls(sessionId: string): Observable<Blob> {
    return this.httpService.http.get(api.archive.download(sessionId), {
      responseType: 'blob',
      params: {
        sharedToken: true,
      },
    });
  }
}
