import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';


@Directive({
  selector: '[appElementLoaded]',
})
export class ElementLoadedDirective implements AfterViewInit {
  @Output() onLoaded: EventEmitter<number> = new EventEmitter<number>();

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.onLoaded.emit(this.el.nativeElement.offsetHeight);
    }, 100);
  }
}
