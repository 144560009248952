import { createAction, props } from '@ngrx/store';
import { Invite, InviteWithOrgName } from '@models/invite.model';
import { Dictionary } from '@ngrx/entity/src/models';

export const resetToInitialState = createAction('[INVITE] Reset to Initial state');

export const getInvitesByOrgId = createAction('[INVITE] Get Invites By Org ID');

export const getInvitesByOrgIdSuccess = createAction('[INVITE] Get Invites By Org ID Success', props<{ invites: Invite[] }>());

export const resendInvite = createAction('[INVITE] Resend Invite', props<{ inviteId: string }>());

export const resendInviteServer = createAction('[INVITE] Resend Invite to Server', props<{ inviteId: string }>());

export const cancelInvite = createAction('[INVITE] Cancel Invite', props<{ inviteId: string }>());

export const cancelInviteServer = createAction('[INVITE] Cancel Invite Server', props<{ inviteId: string }>());

export const resendInviteSuccess = createAction('[INVITE] Resend Invite Success');

export const cancelInviteSuccess = createAction('[INVITE] Cancel Invite Success');

export const getActiveInvites = createAction('[INVITE] Get Active Invites');

export const getActiveInvitesSuccess = createAction('[INVITE] Get Active Invites Success', props<{ invites: InviteWithOrgName[] }>());

export const acceptInvite = createAction('[INVITE] Accept Invite', props<{ id: string }>());

export const sendAcceptInvite = createAction('[INVITE] Send Accept Invite', props<{ id: string }>());

export const sendAcceptSuccess = createAction('[INVITE] Send Accept Invite Success');

export const setQueryParams = createAction('[INVITE] Set Query Params', props<{ query: string }>());

export const resendAll = createAction('[INVITE] Resend All');

export const revokeAll = createAction('[INVITE] Revoke All');

export const removeInvite = createAction('[INVITE] Remove Invite', props<{ inviteId: string }>());

export const removeInviteSuccess = createAction('[INVITE] Remove Invite Success', props<{ inviteId: string }>());

export const sendInvites = createAction(
  '[INVITE] Send Invites to Server',
  props<{ invites: Dictionary<string> }>(),
);


export const acceptInviteNoAuth = createAction('[INVITE] Accept Invite NoAuth', props<{ id: string }>());
export const acceptInviteNoAuthSuccess = createAction('[INVITE] Accept Invite NoAuth Success');
export const acceptInviteNoAuthFail = createAction('[INVITE] Accept Invite NoAuth Fail');
