<div class="flex-col gap-16">
  <div *ngFor="let thumbGroup of rendered"
       class="flex-row gap-16"
       [style.max-width]="thumbGroup.length < elemInRow && rendered?.length > 1 ? 'calc(100% - ' + 16 * (elemInRow - 1) + 'px' : 'none'"
  >
    <div class="flex-row flex"
         *ngFor="let thumb of thumbGroup"
         [style.max-width]="100 / elemInRow + '%'"
    >
      <app-thumbnail class="w-100"
                     [rtl]="rtl"
                     [optionsInput]="thumb.options"
                     [events]="thumb.thumbnails"
                     [edgeId]="edgeId"
                     [cameraId]="cameraId"
                     [isShareVisible]="isShareVisible"
                     (onTimePeriodChangeEvent)="onTimePeriodChange($event)"
                     [timeIsClickable]="true"
                     [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE1"
                     (onOpen)="thumbOpened.emit()"
                     (onClosed)="thumbClosed.emit()"
                     [cameraView]="true"
                     tabindex="0"
      ></app-thumbnail>
    </div>
  </div>
</div>

