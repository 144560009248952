import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptorService } from './core/authentication-interceptor.service';
import { CreateNewEdgeComponent } from './edge/create-new-edge/create-new-edge.component';
import { EdgeInfoComponent } from './edge/edge-info/edge-info.component';
import { AddCameraComponent } from './edge/add-camera/add-camera.component';
import { CameraListComponent } from './edge/camera-list/camera-list.component';
import { CameraDiscoverComponent } from './edge/camera-discover/camera-discover.component';
import { SplashComponent } from './framework/splash/splash.component';
import { AuthorizationModule } from './authorization/authorization.module';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthorizationGuard } from './core/authorization-guard.service';
import { Router } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AppStoreModule } from './store/app-store.module';
import { OrganizationService } from './development/organization.service';
import { ToastrModule } from 'ngx-toastr';
import { SharedService } from './development/shared.service';
import { LetDirective, PushPipe } from '@ngrx/component';
import { UiKitModule } from './shared/ui-kit/ui-kit.module';
import { AlertEventsService } from './development/alert-events.service';
import { OrganizationLayoutComponent } from './pages/organization-layout/organization-layout.component';
import { AppSettingsModule } from './settings/app-settings.module';
import { SearchConfigurationService } from './development/search-configuration.service';
import { AlertMonitoringService } from './development/alert-monitoring.service';
import { CameraService } from './development/camera.service';
import { CameraSearchSettingsService } from './development/camera-search-settings.service';
import { NoAuthComponent } from './pages/no-auth/no-auth.component';
import { GrantedAccessComponent } from './pages/granted-access/granted-access.component';
import { GrantedAccessService } from './development/granted-access.service';
import { StatsService } from './development/stats.service';
import { UserSettingsService } from './development/user-settings.service';
import { ResizableModule } from 'angular-resizable-element';
import { ActivityLogService } from './development/activity-log.service';
import { VariableService } from './development/variable.service';
import { WorkspaceService } from './development/workspace.service';
import { TermsComponent } from './eula/terms/terms.component';
import { PrivacyComponent } from './eula/privacy/privacy.component';
import { DashboardHealthComponent } from './pages/dashboard-health/dashboard-health.component';
import { SearchComponent } from './pages/search/search.component';
import { SavedSearchService } from './development/saved-search.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AppConfig } from './services/app.config';
import { PipesModule } from './pipes/pipes.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommentService } from './services/comment.service';
import { WebrtcDebugDialogComponent } from './framework/webrtc-player/webrtc-debug-dialog/webrtc-debug-dialog.component';
import { MonitoringModule } from './pages/monitoring/monitoring.module';
import { UtilsV2Service } from './services/utils-v2.service';
import { PeopleService } from './development/people.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarV2Component } from './layout/sidebar-v2/sidebar-v2.component';
import { MatRippleModule } from '@angular/material/core';
import { DeveloperGuard } from './guards/developer.guard';
import { LocationEditService } from './development/location-edit.service';
import { LocationComponent } from './pages/location/location.component';
import { FullPageComponent } from './layout/full-page/full-page.component';
import * as Sentry from '@sentry/angular-ivy';
import { SharedAlertComponent } from './pages/granted-access/components/shared-alert/shared-alert.component';
import { SharedArchiveComponent } from './pages/granted-access/components/shared-archive/shared-archive.component';
import { InviteUsersModalComponent } from './modals/invite-users-modal/invite-users-modal.component';
import { PendingInvitationsModalComponent } from './modals/pending-invitations-modal/pending-invitations-modal.component';
import { DoorsService } from './development/doors.service';
import { CustomEventsService } from './development/custom-events.service';
import { UserGroupsServices } from './services/user-groups.services';
import { VehiclesService } from './development/vehicles.service';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { PermissionGroupService } from './services/permission-group.service';
import { UsersListModalComponent } from './modals/user-list-modal/users-list-modal.component';
import { OrgGroupsService } from './development/org-groups.service';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import { OrganizationSettingsService } from './services/organization-settings.service';
import { CameraEditGuard } from './guards/camera-edit.guard';
import { RouterPermissionGuard } from './guards/router-permission-guard';
import { CameraCreateGuard } from './guards/camera-create.guard';
import { DashboardService } from './development/dashboard.service';
import { UiDropdownComponent } from './shared/ui-kit/ui-dropdown/ui-dropdown.component';
import { RbacOrgAllowDirective } from './authorization/rbac-org-allow.directive';
import { PermissionService } from './services/permission.service';
import { WidgetService } from './development/widget.service';
import { NgxSliderModule } from 'ngx-slider-v2';
import { LockService } from './development/lock.service';
import { BrowserCompatibilityModalComponent } from './modals/browser-compatibility-modal/browser-compatibility-modal.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { IdentityService } from './services/identity.service';
import { LivekitService } from './development/livekit.service';
import { SupportService } from './services/support.service';
import { UserDeviceService } from './services/user-device.service';
import { MfaService } from './services/mfa.service';
import { WebhookService } from './services/webhook.service';
import { FloorService } from './development/floor.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { WallV2Service } from './services/wall-v2.service';
import { YoutubeService } from './services/youtube.service';

export const initializeApp1 = (appInitService: AppConfig): () => Promise<any> => {
  return (): Promise<any> => appInitService.load();
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateNewEdgeComponent,
    EdgeInfoComponent,
    AddCameraComponent,
    CameraListComponent,
    CameraDiscoverComponent,
    SplashComponent,
    OrganizationLayoutComponent,
    NoAuthComponent,
    GrantedAccessComponent,
    TermsComponent,
    PrivacyComponent,
    DashboardHealthComponent,
    SearchComponent,
    NotFoundComponent,
    WebrtcDebugDialogComponent,
    SidebarV2Component,
    LocationComponent,
    FullPageComponent,
    SharedAlertComponent,
    SharedArchiveComponent,
    InviteUsersModalComponent,
    PendingInvitationsModalComponent,
    UsersListModalComponent,
    BrowserCompatibilityModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, // routings available
    AuthorizationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SharedModule,
    provideFirebaseApp(() => {
      const app = initializeApp({ ...environment.firebase });
      return app;
    }),
    provideFirestore(() => getFirestore()),
    AppStoreModule,
    StoreRouterConnectingModule.forRoot(),
    // StoreRouterConnectingModule.forRoot({
    //   stateKey: 'router',
    //   routerState: RouterState.Minimal,
    // }),
    LazyLoadImageModule,
    LetDirective, PushPipe,
    UiKitModule,
    MonacoEditorModule.forRoot(),
    AppSettingsModule,
    ResizableModule,
    PipesModule,
    NgScrollbarModule,
    MonitoringModule,
    ServiceWorkerModule.register('ngsw-custom-worker.js', {
      enabled: true,
      //   enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatRippleModule,
    IntlTelInputNgModule.forRoot(),
    UiDropdownComponent,
    RbacOrgAllowDirective,
    NgxSliderModule,
  ],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppConfig], multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    {
      provide: 'operatorOnlyGuard',
      useFactory: (authenticationService: AuthenticationService, router: Router) => {
        return new AuthorizationGuard(['operator'], authenticationService, router);
      },
      deps: [AuthenticationService, Router],
    },
    { provide: Window, useValue: window },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    OrganizationService,
    SharedService,
    AlertEventsService,
    VariableService,
    SavedSearchService,
    SearchConfigurationService,
    AlertMonitoringService,
    CameraService,
    CameraSearchSettingsService,
    GrantedAccessService,
    LocationEditService,
    StatsService,
    UserSettingsService,
    ActivityLogService,
    WorkspaceService,
    CommentService,
    UtilsV2Service,
    PeopleService,
    DeveloperGuard,
    DoorsService,
    VehiclesService,
    CustomEventsService,
    UserGroupsServices,
    OrgGroupsService,
    PermissionGroupService,
    OrganizationSettingsService,
    CameraEditGuard,
    RouterPermissionGuard,
    CameraCreateGuard,
    DashboardService,
    PermissionService,
    WidgetService,
    LockService,
    IdentityService,
    LivekitService,
    SupportService,
    UserDeviceService,
    MfaService,
    WebhookService,
    FloorService,
    WallV2Service,
    YoutubeService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {

  constructor(trace: Sentry.TraceService) {
  }

}
