import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

export const BYPASS_AUTHENTICAION_TOKEN = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {

  private idToken;
  private accessToken;

  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.idToken) {
      this.idToken = this.authenticationService.getIdTokenFromLocalStorage();
    }
    if (!this.accessToken) {
      this.accessToken = this.authenticationService.getShareAccessTokenFromSessionStorage();
    }
    if (req.context.get(BYPASS_AUTHENTICAION_TOKEN) === true) {
      return next.handle(req);
    }

    if (req.params.get('sharedToken')) {
      if (this.accessToken) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this.accessToken),
        });
        return next.handle(cloned);
      }
    }

    if (this.idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.idToken),
      });
      return next.handle(cloned);
    }

    return next.handle(req);
  }
}
