import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CameraEditState } from '@states/camera-edit/camera-edit.reducer';

export const selectSearchConfigurationState = createFeatureSelector<CameraEditState>('cameraEditState');

export const selectSelectedCamera = createSelector(selectSearchConfigurationState, ({ selectedCamera }: CameraEditState) => selectedCamera);

export const selectZones = createSelector(selectSearchConfigurationState, ({ zones }: CameraEditState) => zones);

export const selectDefinedZones = createSelector(selectSearchConfigurationState, ({ definedZones }: CameraEditState) => definedZones);
export const selectProtectiveGear = createSelector(selectSearchConfigurationState, ({ protectiveGear }: CameraEditState) => protectiveGear);
export const selectForklift = createSelector(selectSearchConfigurationState, ({ forklift }: CameraEditState) => forklift);
export const selectContainer = createSelector(selectSearchConfigurationState, ({ container }: CameraEditState) => container);
export const selectShoppingCart = createSelector(selectSearchConfigurationState, ({ shoppingCart }: CameraEditState) => shoppingCart);
export const selectZonesExclude = createSelector(selectSearchConfigurationState, ({ zonesExclude }: CameraEditState) => zonesExclude);
export const selectOrgProtectiveGear = createSelector(selectSearchConfigurationState, ({ orgProtectiveGear }: CameraEditState) => orgProtectiveGear);
export const selectOrgForklift = createSelector(selectSearchConfigurationState, ({ orgForklift }: CameraEditState) => orgForklift);
export const selectOrgContainer = createSelector(selectSearchConfigurationState, ({ orgContainer }: CameraEditState) => orgContainer);
export const selectOrgShoppingCart = createSelector(selectSearchConfigurationState, ({ orgShoppingCart }: CameraEditState) => orgShoppingCart ?? false);

export const selectMarkedIdx = createSelector(selectSearchConfigurationState, ({ markedIdx }: CameraEditState) => markedIdx);

export const selectObjectsToScan = createSelector(selectSearchConfigurationState, ({ objectsToScan }: CameraEditState) => objectsToScan);

export const selectObjectsToPixelate = createSelector(
  selectSearchConfigurationState,
  ({ objectsToPixelate }: CameraEditState) => objectsToPixelate,
);

export const selectLicensePlates = createSelector(selectSearchConfigurationState, ({ licensePlates }: CameraEditState) => licensePlates);

export const selectVehicleMMC = createSelector(selectSearchConfigurationState, ({ vehicleMMC }: CameraEditState) => vehicleMMC);

export const selectLevel2MenuValid = createSelector(
  selectSearchConfigurationState,
  ({ level2MenuValid }: CameraEditState) => level2MenuValid,
);

export const selectSelectedSearchConfigurationId = createSelector(
  selectSearchConfigurationState,
  ({ selectedSearchConfigurationId }: CameraEditState) => selectedSearchConfigurationId,
);

export const selectCameraAssets = createSelector(selectSearchConfigurationState, ({ cameraAssets }: CameraEditState) => cameraAssets);

export const selectAddCameraManuallySuccess = createSelector(
  selectSearchConfigurationState,
  ({ addCameraManuallySuccess }: CameraEditState) => addCameraManuallySuccess,
);

export const selectCameraSearchSettingsFilters = createSelector(
  selectSearchConfigurationState,
  ({ cameraSearchSettings }: CameraEditState) => cameraSearchSettings?.filters,
);

export const selectPrivacy = createSelector(selectSearchConfigurationState, ({ privacy }: CameraEditState) => privacy);

export const selectPrivacyZones = createSelector(selectSearchConfigurationState, ({ privacy }: CameraEditState) => privacy.zones);

export const selectPrivacyDefinedZones = createSelector(
  selectSearchConfigurationState,
  ({ privacy }: CameraEditState) => privacy.definedZones,
);

export const selectPrivacyMarkedIdx = createSelector(selectSearchConfigurationState, ({ privacy }: CameraEditState) => privacy.markedIdx);

export const selectPrivacyObjectsToPixelate = createSelector(
  selectSearchConfigurationState,
  ({ privacy }: CameraEditState) => privacy.objectsToPixelate,
);

export const selectCameraVpnConfiguration = createSelector(selectSearchConfigurationState, ({ vpnConfiguration }: CameraEditState) => vpnConfiguration);
export const selectIsTestSubStreamSuccess = createSelector(selectSearchConfigurationState, ({ isTestSubStreamSuccess }: CameraEditState) => isTestSubStreamSuccess);

