import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ui-truncated-html',
  templateUrl: './ui-truncated-html.component.html',
  styleUrls: ['./ui-truncated-html.component.scss'],
})
export class UiTruncatedHtmlComponent {
  @ViewChild('wrapper') wrapperElement: ElementRef;

  @Input() disableTooltip = false;
  @Input() tooltipPosition: TooltipPosition = 'above';

  private domElement: any;

  public tooltipContents = '';

  public tooltipEnabled = false;

  public constructor() {
  }

  @HostListener('mouseover')
  private setToolTip(): void {
    this.domElement = this.wrapperElement.nativeElement;
    this.tooltipEnabled = (this.domElement.offsetWidth < this.domElement.scrollWidth) && !this.disableTooltip;
    if (this.tooltipEnabled) {
      this.tooltipContents = innerTextSplitByWhitespaces(this.domElement.innerHTML);
    }
  }
}

const innerTextSplitByWhitespaces = (html: string): string => {
  let result = html.replace(/<[^<>]+>/g, ' ');
  result = result.replace(/&[#A-Za-z0-9]+;/gi, ' ');
  result = result.replace(/[\n\r]+|[\s]{2,}/g, ' ');
  // result = result.replace(/(?:[a-z0-9]) [,.!?]/gi, ',');
  return result.trim();
};
