import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedActions } from '@states/shared/shared.action-types';
import { KeyValuePairs } from '../core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LockService {
  private locks: Map<string, boolean> = new Map<string, boolean>();

  constructor(private store$: Store) {
  }

  acquireLock(lockId: string): boolean {
    if (!this.locks.has(lockId)) {
      this.locks.set(lockId, true);
      return true; // Lock acquired
    } else {
      return false; // Lock already held
    }
    const debug: KeyValuePairs<boolean> = {};
    this.locks.forEach((value, key) => {
      debug[key] = value;
    });
    this.store$.dispatch(SharedActions.setLocksDebug({ locks: debug }));
  }

  releaseLock(lockId: string) {
    this.locks.delete(lockId);
    const debug: KeyValuePairs<boolean> = {};
    this.locks.forEach((value, key) => {
      debug[key] = value;
    });
    this.store$.dispatch(SharedActions.setLocksDebug({ locks: debug }));
  }

  isLocked(lockId: string): boolean {
    return this.locks.has(lockId);
  }
}
