import {
  CameraCloudOnly,
  CameraDeviceOnvifInfoInterface,
  EdgeCamera,
  FileCamera,
  FileCameraCreate,
  GetCameraDetailsCamera,
} from '../cameras/camera.model';
import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { Edge } from '../edge/edge.model';
import { AddLocationStep } from './add-location/add-location.component';
import { Part } from 'hls.js';
import { DeviceModel } from '@models/device.model';
import { SensorModel } from '@models/sensor.model';

export namespace LocationModel {

  export interface EdgeLookupParams {
    edgeId?: string;
    edgeIdHash?: number;
    cameraId?: string;
    cameraIdHash?: number;
    locationId?: string;
    locationIdHash?: number;
    orgId?: string;
    orgIdHash?: number;
  }


  import CameraDocument = EdgeCamera.CameraDocument;

  export interface LocationCreateRequest {
    _id?: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    timezone: string;
    contact: string;
    phone: string;
    complianceOption?: string;
    genderClassification?: boolean;
    faceRecognition?: boolean;
  }

  export interface LocationDeleteRequest {
    locationId: string;
  }

  export interface LocationDeleteResponse {
    result: boolean;
    locationId: string;
  }

  export interface GetCameraDetailsRequest {
    locationId?: string;
    edgeId?: string;
    camera: {
      cameraId?: string;
      macAddress?: string;
      ipAddress?: string;
      port?: number;
      connectionString?: string;
      username?: string;
      password?: string;
      profile?: number;
      snapshotWidth?: number;
      snapshotHeight?: number;
      onvifData?: CameraDeviceOnvifInfoInterface;
    };
    create?: boolean;
  }

  export interface GetMultipleCameraDetailsRequest {
    locationId?: string;
    edgeId?: string;
    cameras: GetCameraDetailsCamera[];
    create?: boolean;
  }

  export type GetCameraDetailsManuallyRequest = GetCameraDetailsRequest;

  export interface ChangeSubstreamListMsg {
    action: ChangeSubstreamListAction;
    cameraDocument: CameraDocument;
    connectionString: string;
  }

  export interface ProbeSubstreamRequest extends ChangeSubstreamListMsg {
    locationId: string;
    edgeId: string;
  }

  export interface AddCameraToLocationRequest {
    locationId: string;
    edgeId: string;
    camera: EdgeCamera.CameraDocument;
    defaultSnapshot: string;
  }

  export interface AddCamerasToLocationRequest {
    locationId: string;
    edgeId: string;
    cameras: FileCameraCreate[];
  }

  export type AddCameraToLocationResponse = EdgeCamera.CameraItem;
  export type UpdateCameraInLocationResponse = EdgeCamera.CameraItem;

  export interface DeleteCameraFromLocationRequest {
    locationId: string;
    edgeId: string;
    cameraId: string;
  }

  export interface DeleteCameraFromLocationResponse {
    locationId: string;
    edgeId: string;
    cameraId: string;
  }

  export interface DeleteEdgeFromLocationRequest {
    locationId: string;
    edgeId: string;
  }

  export interface DeleteEdgeFromLocationResponse {
    locationId: string;
    edgeId: string;
  }

  export type AddCameraManuallyToLocationRequest = AddCameraToLocationRequest;

  export interface AddEdgeToLocationRequest {
    locationId: string;
    edgeId: string;
    name: string;
  }

  export interface UpdateEdgeInLocationRequest extends AddEdgeToLocationRequest {
    sendUpdate: boolean;
    update?: Partial<Edge.EdgeDocument>;
    edge?: Edge.EdgeDocument;
  }

  export interface NotifyCameraAssetUploadedRequest {
    locationId: string;
    edgeId: string;
    cameraId: string;
    filename: string;
  }

  export interface GetCameraAssetPresignedUrlRequest {
    locationId: string;
    edgeId: string;
    cameraId: string;
    asset: EdgeCamera.CameraAsset;
  }

  export interface UploadCameraAssetPresignedUrlRequest {
    url: string;
    file: Blob;
  }

  export type AddEdgeToLocationResponse = Edge.EdgeDocument;

  export interface LocationItem {
    _id?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    status?: string;
    timezone?: string;
    contact?: string;
    zip?: string;
    phone?: string;
    org?: any;
    edges?: Edge.EdgeMap;
    devices?: DeviceModel.DeviceMap;
    expanded?: boolean;
    complianceOption?: string;
    genderClassification?: boolean;
    faceRecognition?: boolean;
    sensors?: SensorModel.Sensor[];
  }

  import Camera = Edge.Camera;

  export interface LocationCameraItem {
    locationId?: string;
    edgeId?: string;
    edgeOnly: EdgeCamera.CameraDocument;
    cloudOnly: CameraCloudOnly;
  }

  export type LocationCamerasMap = KeyValuePairs<LocationCameraItem>;

  export interface LocationEdgeItem {
    edgeId?: string;
    name?: string;
  }

  export interface LocationCreateResponse {
    _id: string;
  }

  export interface DeleteCameraFromLocationRequest {
    locationId: string;
    edgeId: string;
    cameraId: string;
  }

  export interface AddedCameras {
    cameraId: string;
    mac: string;
    ipAddress: string;
    name: string;
    onvifCompliant: boolean;
  }

  export interface AddLocationOptions {
    location?: LocationModel.LocationItem;
    edgeId?: string;
    edgeName?: string;
    step?: AddLocationStep;
    cameras?: AddedCameras[];
  }

  export type GetLocationsResponse = LocationItem[];

  export interface ProbeCameraRequest {
    locationId: string;
    edgeId: string;
    ipAddress: string;
    port: number;
    username: string;
    password: string;
    connectionString: string;
  }

  export enum ViewType {
    Home,
    Devices,
    Floors,
    Map
  }

}

export enum ChangeSubstreamListAction {
  Add,
  Remove,
  Probe_only
}

export enum ComplianceOption {
  INDEPENDENT,
  ILLINOIS,
  PORTLAND,
  TEXAS,
}
