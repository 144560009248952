import { Pipe, PipeTransform } from '@angular/core';
import { AlertNotificationLogType } from '@enums/alert-monitoring.enum';

@Pipe({
  name: 'alertNotificationLogStatus',
})
export class AlertNotificationLogStatusPipe implements PipeTransform {


  transform(value: any, type: AlertNotificationLogType): boolean {
    switch (type) {
      case AlertNotificationLogType.EMAIL:
        return value.length ? value[0]?.statusCode === 202 : false;
      case AlertNotificationLogType.SMS:
        return value?.status === 'queued';
      case AlertNotificationLogType.MOBILE_PUSH:
        return value?.success;
      default:
        return false;
    }
  }
}
