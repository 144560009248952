import { createReducer, on } from '@ngrx/store';
import { DashboardActions } from '@states/dashboard/dashboard.action-types';
import { initialDashboard, initialDashboardFilter } from '@consts/dashboard.const';
import { SortDirection } from '@angular/material/sort';
import * as _ from 'lodash';
import { DashboardFavorite, DashboardModel, WidgetDataTypes, WidgetDeprecated } from '@models/dashboard.model';

export interface DashboardState {
  widgets: { [key: string]: WidgetDataTypes };
  dashboards: DashboardModel.Dashboard[];
  favorites: DashboardFavorite[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  filters: DashboardModel.DashboardFilters;

  query: string;
  selectedWidget: WidgetDeprecated;
  selectedDashboard: DashboardModel.Dashboard;
  variablesAutoComplete: { _id: string; name: string }[];
  variablesAutoCompleteMap: { [key: string]: { _id: string; name: string } };
  isEditMode: boolean;
  isExpanded: boolean;
  filter: {
    start: any;
    end: any;
  };
}

const initialState: DashboardState = {
  widgets: {},
  selectedWidget: null,
  dashboards: null,
  favorites: null,
  totalItemsCount: 0,
  page: 0,
  perPage: 10,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  filters: initialDashboardFilter,

  selectedDashboard: null,
  variablesAutoComplete: [],
  variablesAutoCompleteMap: {},
  isEditMode: false,
  isExpanded: true,
  filter: null,
};

export const dashboardStateReducer = createReducer(
  initialState,
  on(DashboardActions.resetToInitialState, state => {
    return {
      ...initialState,
      favorites: state.favorites,
    };
  }),
  /**
   * for Count for now
   */
  // on(DashboardActions.addWidget, (state, { widgetType, viewMode }) => {
  //   const localIndex = state.selectedDashboard.widgets.length;
  //   const widget: Widget = {
  //     ...initialWidget,
  //     type: widgetType,
  //     viewMode,
  //     name: `Widget ${localIndex + 1}`,
  //     localIndex: localIndex,
  //     variables: widgetType === WidgetType.HEATMAP ? [] : null,
  //   };
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: state.selectedDashboard.widgets.concat(widget),
  //     },
  //   };
  // }),
  on(DashboardActions.changeDashboardName, (state, {name}) => {
    return {
      ...state,
      selectedDashboard: {
        ...state.selectedDashboard,
        name,
      },
    };
  }),
  on(DashboardActions.setSelectedWidget, (state, {widget}) => {
    return {
      ...state,
      selectedWidget: state.selectedWidget?.localIndex === widget.localIndex ? null : widget,
    };
  }),

  on(DashboardActions.getDashboardsSuccess, (state, {dashboards}) => {
    return {
      ...state,
      dashboards: dashboards,
    };
  }),
  on(DashboardActions.getDashboardSuccess, (state, {dashboard}) => {
    return {
      ...state,
      selectedDashboard: {
        ...dashboard,
      },
    };
  }),
  on(DashboardActions.setInitialDashboard, state => {
    return {
      ...state,
      selectedDashboard: initialDashboard,
      selectedWidget: null,
    };
  }),
  on(DashboardActions.resetSelectedDashboard, state => {
    return {
      ...state,
      selectedDashboard: initialState.selectedDashboard,
      filters: initialState.filters,
    };
  }),
  // on(DashboardActions.updateSelectedWidget, (state, { field, value, index }) => {
  //   return {
  //     ...state,
  //     selectedWidget: {
  //       ...state.selectedWidget,
  //       [field]: value,
  //     },
  //   };
  // }),
  // on(DashboardActions.updateWidget, (state, { field, value, index }) => {
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: updateWidgets(index, state.selectedDashboard.widgets, field, value),
  //     },
  //     selectedWidget: {
  //       ...state.selectedWidget,
  //       [field]: value,
  //     },
  //   };
  // }),
  // on(DashboardActions.removeWidget, (state, { localId }) => {
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: deleteWidget(localId, state.selectedDashboard.widgets),
  //     },
  //   };
  // }),
  on(DashboardActions.getWidgetDataSuccess, (state, {data, index}) => {
    return {
      ...state,
      selectedDashboard: {
        ...state.selectedDashboard,
        // widgets: updateWidgets(index, state.selectedDashboard.widgets, 'counter', data),
      },
      widgets: {
        ...state.widgets,
        [index]: data,
      },
    };
  }),
  // on(DashboardActions.updateEditMode, (state, { isEditMode }) => {
  //   return {
  //     ...state,
  //     isEditMode,
  //   };
  // }),
  // on(DashboardActions.setAbsoluteDate, (state, { start, end, timezone }) => {
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: updateWidgetsAbsoluteTimeRangeFilters(state.selectedDashboard.widgets, start, end, timezone),
  //     },
  //     filter: {
  //       start,
  //       end,
  //     },
  //     widgets: initialState.widgets,
  //   };
  // }),
  on(DashboardActions.setQuery, (state, {query}) => {
    return {
      ...state,
      query,
    };
  }),
  on(DashboardActions.setFilter, (state, {filter, value}) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    };
  }),
  // on(DashboardActions.setIsExpanded, state => {
  //   return {
  //     ...state,
  //     isExpanded: !state.isExpanded,
  //   };
  // }),
  // on(DashboardActions.clearFilters, state => {
  //   return {
  //     ...state,
  //     filter: initialState.filter,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //     },
  //     widgets: initialState.widgets,
  //   };
  // }),
  // on(DashboardActions.resetSelectedWidget, state => {
  //   return {
  //     ...state,
  //     selectedWidget: initialState.selectedWidget,
  //   };
  // }),
  // on(DashboardActions.updateCustomRelativeTime, (state, { data, index }) => {
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: updateRelativeWidgets(index, state.selectedDashboard.widgets, 'relativeCustom', data),
  //     },
  //     selectedWidget: {
  //       ...state.selectedWidget,
  //       relativeCustom: data,
  //       relative: null,
  //     },
  //   };
  // }),
  // on(DashboardActions.updateRelativeTime, (state, { data, index }) => {
  //   return {
  //     ...state,
  //     selectedDashboard: {
  //       ...state.selectedDashboard,
  //       widgets: updateRelativeWidgets(index, state.selectedDashboard.widgets, 'relative', data),
  //     },
  //     selectedWidget: {
  //       ...state.selectedWidget,
  //       relativeCustom: null,
  //       relative: data,
  //     },
  //   };
  // }),
);

const updateWidgets = (index: number, widgets: WidgetDeprecated[], field: string, value: any): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    if (widget.localIndex === index) {
      return {
        ...widget,
        [field]: value,
      };
    } else {
      return widget;
    }
  });
};

const updateRelativeWidgets = (index: number, widgets: WidgetDeprecated[], field: string, value: any): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    if (widget.localIndex === index) {
      if (field === 'relative') {
        return {
          ...widget,
          relative: value,
          relativeCustom: null,
        };
      } else {
        return {
          ...widget,
          relative: null,
          relativeCustom: value,
        };
      }
    } else {
      return widget;
    }
  });
};

const deleteWidget = (index: number, widgets: WidgetDeprecated[]): WidgetDeprecated[] => {
  const indexWidget = widgets.findIndex(widget => widget.localIndex === index);
  const _widgets = _.cloneDeep(widgets);
  _widgets.splice(indexWidget, 1);
  for (let [index, widget] of _widgets.entries()) {
    widget.localIndex = index;
  }
  return _widgets;
};

const updateWidgetsAbsoluteTimeRangeFilters = (widgets: WidgetDeprecated[], start: string, end: any, timezone: string): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    return {
      ...widget,
      absolute: {
        start,
        end,
        timezone,
      },
      // relative: null,
    };
  });
};

const rmWidgetsAbsoluteTimeRangeFilters = (widgets: WidgetDeprecated[]): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    return {
      ...widget,
      absolute: null,
      // relative: null,
    };
  });
};

