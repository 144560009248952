import { Component, Input, OnInit } from '@angular/core';
import { ToastPackage, ToastrService } from 'ngx-toastr';
// @ts-ignore
import Timeout = NodeJS.Timeout;
import { MsgBoxType } from '../../msg-box/msg-box.model';

@Component({
  selector: 'ui-custom-toast',
  templateUrl: './ui-custom-toast.component.html',
  styleUrls: ['./ui-custom-toast.component.scss'],
})
export class UiCustomToastComponent implements OnInit {
  @Input() message: string;
  @Input() alertType: MsgBoxType;
  @Input() title: string;
  public toastId: number;
  public closeTimeout: Timeout;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {}

  public ngOnInit() {
    this.startTimeout();
  }

  action(): void {
    this.toastrService.remove(this.toastId);
  }

  mouseover() {
    clearTimeout(this.closeTimeout);
  }

  mouseout() {
    this.startTimeout();
  }

  public startTimeout() {
    this.closeTimeout = setTimeout(() => {
      this.action();
    }, 5000);
  }
}
