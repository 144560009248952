<div class="ui-chip-select" (mouseover)="mouseover = true" (mouseleave)="mouseover = false">
  <!--  [selected]="selected"-->
  <mat-chip class="c-p" [class.selected]="selected" (click)="click()">
    <div class="chip-inner flex-row center-center">
      <ng-content></ng-content>
      <ng-container *ngIf="selected">
        <ng-container *ngIf="mouseover">
          <span class="ui-icon-close ml-10"></span>
        </ng-container>
        <ng-container *ngIf="!mouseover">
          <span class="ui-icon-checkmark ml-10"></span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!selected">
        <span class="ui-icon-plus ml-10"></span>
      </ng-container>
      <!--      <mat-icon class="ml-10">{{ selected ? (mouseover ? 'close' : 'check') : 'add' }}</mat-icon>-->
    </div>
  </mat-chip>

</div>

