import { createAction, props } from '@ngrx/store';
import { EdgeCamera } from '../../../cameras/camera.model';
import { AlertType, AnalyticClasses } from '@enums/alert-events.enum';
import { AlertMonitoringViewModel } from '@models/alert-monitoring.model';
import { AlertEntry } from '../../../development/alerts.service';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { EventV2Document, FlowLookup } from '@models/alerts-v2.model';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { DashboardModel } from '@models/dashboard.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';

export const resetToInitialState = createAction('[ALERT-MONITORING] Reset to Initial state');

export const resetEntities = createAction('[ALERT-MONITORING] Reset Entities');

export const resetFilters = createAction('[ALERT-MONITORING] Reset Filters');

export const setSelectedCamera = createAction(
  '[ALERT-MONITORING] Set Selected Camera',
  props<{ selectedCameras: Dictionary<SelectedCamera> }>(),
);

export const setTrackedObjects = createAction('[ALERT-MONITORING] Set Tracked Objects', props<{ trackedObjects: AnalyticClasses[] }>());

export const setFlowTypes = createAction('[ALERT-MONITORING] Set Flow Types', props<{ flowTypes: FlowLookup[] }>());

export const setAlertTypes = createAction('[ALERT-MONITORING] Set Alert Types', props<{ alertTypes: AlertType[] }>());

export const setDateRange = createAction(
  '[ALERT-MONITORING] Set Date Range',
  props<{
    dateRange: OnRangeSelectedResult;
  }>(),
);

export const removeFilter = createAction('[ALERT-MONITORING] Remove Filter', props<{ field: string; value: any }>());

export const saveView = createAction('[ALERT-MONITORING] Save View', props<{ name: string; isPublic: boolean; asNew: boolean }>());

export const sendView = createAction('[ALERT-MONITORING] Send View', props<{ name: string; isPublic: boolean; asNew: boolean }>());

export const saveViewSuccess = createAction('[ALERT-MONITORING] Save View Success');

export const getAlertMonitoringViews = createAction('[ALERT-MONITORING] Get Alert Monitoring Views',
  props<{ query: string; }>());

export const getAlertMonitoringViewsSuccess = createAction(
  '[ALERT-MONITORING] Get Alert Monitoring Views Success',
  props<{ views: AlertMonitoringViewModel[] }>(),
);

export const selectAlertMonitoringView = createAction(
  '[ALERT-MONITORING] Select Alert Monitoring View',
  props<{ selectedView: AlertMonitoringViewModel }>(),
);

export const setSelectedAlertMonitoringView = createAction(
  '[ALERT-MONITORING] Set Selected Alert Monitoring View',
  props<{ selectedView: AlertMonitoringViewModel }>(),
);

export const deleteView = createAction('[ALERT-MONITORING] Delete View', props<{ id: string }>());
export const deleteViewSuccess = createAction('[ALERT-MONITORING] Delete View Success');

export const getAlertsByFilters = createAction('[ALERT-MONITORING] Get Alerts By Filters');
export const countAlertsByFilters = createAction('[ALERT-MONITORING] Count Alerts By Filters');
export const resetAlertsCount = createAction('[ALERT-MONITORING] Reset Alerts Count');

export const sendGetAlertsByFilters = createAction(
  '[ALERT-MONITORING] Send Get Alerts By Filters',
);

export const getAlertsByFiltersSuccess = createAction(
  '[ALERT-MONITORING] Get Alerts By Filters Success',
  props<{ alerts: AlertEntry[] }>(),
);

export const sendCountAlertsByFilters = createAction(
  '[ALERT-MONITORING] Send Count Alerts By Filters',
);

export const countAlertsByFiltersSuccess = createAction(
  '[ALERT-MONITORING] Count Alerts By Filters Success',
  props<{ count: number }>(),
);

export const exportAlertsByFiltersToCSVSuccess = createAction(
  '[ALERT-MONITORING] Export Alerts By Filters To CSV Success',
  props<{ alerts: AlertEntry[] }>(),
);

export const setQuery = createAction('[ALERT-MONITORING] Set Query', props<{ query: string }>());
export const setWidgetDataInfo = createAction('[ALERT-MONITORING] Set Widget Data Info', props<{ widgetDataInfo: DashboardModel.AlertLinkObject }>());

// export const getAlertsAutocomplete = createAction('[ALERT-MONITORING] Get Alerts Autocomplete', props<{ query: string }>());

export const getAlertsAutocompleteSuccess = createAction(
  '[ALERT-MONITORING] Get Alerts Autocomplete Success',
  props<{ autocompleteOptions: string[] }>(),
);

// export const setAlertNamesFilters = createAction('[ALERT-MONITORING] Set Alert Names Filters', props<{ alertNamesFilters: string[] }>());

export const setSearchFilter = createAction('[ALERT-MONITORING] Set Search Filter', props<{ prop: string; value: string | boolean }>());

export const getView = createAction(
  '[ALERT-MONITORING] Get View By Id',
  props<{ id: string }>(),
);

export const getViewFail = createAction(
  '[ALERT-MONITORING] Get View By Id Fail',
);

export const nextPageViewList = createAction('[ALERT-MONITORING] Next Page View List');

export const archiveAlert = createAction('[ARCHIVE] Set Alert Archived', props<{ id: string, isArchive: boolean }>());

export const nextPageAlertMonitoring = createAction('[ALERT-MONITORING] Next Page Alert Monitoring');

export const setNewAlerts = createAction('[ALERT-MONITORING] Set New Alert', props<{ alerts: AlertEntry[] }>());

export const archiveAlertSuccess = createAction('[ALERT-MONITORING] Set Alert Archived Success', props<{ id: string, isArchive: boolean }>());

export const refreshArchiveCountSuccess = createAction('[ALERT-MONITORING] Refresh Archive Count Success', props<{ removeArchived: boolean }>());

export const deleteAlert = createAction('[ALERT-MONITORING] Delete Alert', props<{ id: string }>());
export const deleteAlertSuccess = createAction('[ALERT-MONITORING] Delete Alert Success', props<{ id: string }>());

export const setLoader = createAction('[ALERT-MONITORING] Set Loader', props<{ isLoading: boolean }>());

export const resetAlertMonitoringViews = createAction('[ALERT-MONITORING] Reset Alert Monitoring Views');

export const newAlertsValidation = createAction('[ALERT-MONITORING] New Alert Validation', props<{ alerts: AlertEntry[] }>());

export const cancelNetworkRequests = createAction('[ALERT-MONITORING] Cancel Network Requests');

export const setAcknowledges = createAction('[ALERT-MONITORING] Set Acknowledges', props<{ acknowledges: boolean[] }>());

export const refreshAlertsByAutoAcknowledgesAndFrequency = createAction('[ALERT-MONITORING] Refresh Alerts By Acknowledges and frequency');

export const changeDateRangePickerType = createAction(
  '[ALERT-MONITORING] Changed Date Range Picker Type',
  props<{
    pickerType: UiCalendarPickerType;
  }>(),
);

export const getEventsAutocomplete = createAction('[ALERT-MONITORING] Get Events Autocomplete', props<{ query: string }>());
export const setEventIdsFilter = createAction('[ALERT-MONITORING] Set Event ids filter', props<{ eventIds: string[] }>());
export const getEventsAutocompleteSuccess = createAction('[ALERT-MONITORING] Get Events Autocomplete Success', props<{ events: EventV2Document[] }>());

export const removeSelectedCameraFilter = createAction('[ALERT-MONITORING] Remove Selected Camera Filter', props<{ field: string; value: any }>());
