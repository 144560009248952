import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel } from '../locations/location.model';
import { UiLocationSelectItem } from '@models/ui.model';

@Pipe({
  name: 'locationCameraCounter',
})
export class LocationCameraCounterPipe implements PipeTransform {
  transform(location: LocationModel.LocationItem | UiLocationSelectItem): number {
    let count = 0;
    Object.values(location.edges)
      .forEach(edge => {
        if (edge?.cameras) {
          count += Object.keys(edge.cameras)?.length;
        }
      });
    return count;
  }
}
