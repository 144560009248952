import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'chunkArray',
})
export class ChunkPipe implements PipeTransform {
  transform(array: any[], cols: number): any[][] {
    return _.chunk(array, Math.floor(cols));
  }
}
