import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Edge } from '../../../../../edge/edge.model';
import { StatsEnum } from '@enums/stats.enum';
import { Observable, share } from 'rxjs';
import { StatsService } from '../../../../../development/stats.service';
import { PreloaderColor, UiUptimeBarColors } from '@enums/shared.enum';
import { uiUptimeBarColors } from '@consts/shared.const';

@Component({
  selector: 'app-edge-stat-row',
  templateUrl: './edge-stat-row.component.html',
  styleUrls: ['../camera-stat-row/camera-stat-row.component.scss'],
})
export class EdgeStatRowComponent implements OnChanges {
  public selectEdgeUptime$: Observable<number[]>;

  @Input() edge: Edge.EdgeDocument;

  @Input()
  public selectPeriod: StatsEnum;

  @Input()
  public period: number;

  @Input()
  public bar: number;

  public uiBarColors = uiUptimeBarColors;
  public uiUptimeBarColors = UiUptimeBarColors;
  public preloaderColor = PreloaderColor;

  constructor(private statsService: StatsService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.selectEdgeUptime$ = this.statsService
      .getEdgeStats({
        edgeId: this.edge.edgeId,
        period: this.period,
        bar: this.bar,
      })
      .pipe(share());
  }

}
