import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserSettingsAction from '@states/user-settings/user-settings.actions';
import { catchError, exhaustMap, Observable, share, switchMap, timeout, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as SharedActions from '@states/shared/shared.actions';
import { AppState } from '../app.state';
import { UserSettingsService } from '../../development/user-settings.service';
import { UserSettings } from '@models/user-settings';
import { UtilsService } from 'src/app/edge/utils.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';
import { startConfirmCode } from '@states/user-settings/user-settings.actions';

@Injectable()
export class UserSettingsEffect {
  public saveSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.startUpdateUserSettingsProcess),
      exhaustMap(({ userSettings }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        UserSettingsAction.updateUserSettingsRequest({ userSettings }),
      ]),
    ),
  );

  public sendSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.updateUserSettingsRequest),
      withLatestFrom(this.store$.pipe(select(state => state.userSettingsState))),
      switchMap(([{ userSettings }, userSettingsState]) => {
          const userSettingsMerged: UserSettings = {
            ...userSettingsState.userSettings,
            ...userSettings,
          };
          return this.sendSetting(userSettingsMerged)
            .pipe(
              switchMap((res) => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }),
                  UserSettingsAction.setUserSettings({ userSettings: res }),
                  SharedActions.showMessage({ success: 'Settings has been updated' }),
                ];
              }),
              catchError(err => [
                SharedActions.showMessage({ error: this.utilsService.errMessage(err) }),
                SharedActions.setIsSaving({ isSaving: false })]),
            );
        },
      ),
      share(),
    ),
  );

  public getMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.getMe),
      switchMap(() =>
        this.userSettingsService.me()
          .pipe(
            switchMap(res => {
              if (!res) {
                return [SharedActions.doNothing()];
              }
              return [UserSettingsAction.setUserSettings({ userSettings: res })];
            }),
            catchError(err => [SharedActions.showMessage({ error: this.utilsService.errMessage(err) })]),
          ),
      ),
      share(),
    ),
  );

  public verifyPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.verifyPhone),
      withLatestFrom(this.store$.pipe(select(UserSelectors.selectUserState))),
      switchMap(([, { authProviderId, phone }]) =>
        this.userSettingsService.verifyPhone(phone)
          .pipe(
            switchMap(res => {
              return [
                UserSettingsAction.verifyPhoneSuccess({ id: res['_id'] }),
                SharedActions.showMessage({ success: 'SMS with code sent' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: this.utilsService.errMessage(err) })]),
          ),
      ),
      share(),
    ),
  );

  public startConfirmCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.startConfirmCode),
      switchMap(({ code, id }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        UserSettingsAction.confirmCode({ code, id }),
      ]),
      share(),
    ),
  );

  public confirmCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.confirmCode),
      switchMap(({ code, id }) =>
        this.userSettingsService.sendCode({ confirmationCode: code, verificationId: id })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                UserActions.setPhoneVerified({ isPhoneVerified: true }),
                UserActions.CreateOrGetUserProfileSuccess({ payload: res }),
                UserSettingsAction.confirmCodeSuccess(),
              ];
            }),
            catchError(err => [
              SharedActions.setIsSaving({ isSaving: false }),
              SharedActions.showMessage({ error: this.utilsService.errMessage(err) })]),
          ),
      ),
      share(),
    ),
  );

  public validate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsAction.validateUserSettingProperty),
      withLatestFrom(this.store$.pipe(select(state => state.userSettingsState))),
      switchMap(([{ prop, value }]) => {
          if (this.validateUserSettingProperty(prop, value)) {
            return [
              UserSettingsAction.setValidateStatus({ isValid: true }),
              UserSettingsAction.setUserSettingProperty({ prop, value }),
            ];
          } else {
            return [UserSettingsAction.setValidateStatus({ isValid: false })];
          }
        },
      ),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userSettingsService: UserSettingsService,
    private utilsService: UtilsService,
  ) {
  }

  private sendSetting(userSettings: UserSettings): Observable<any> {
    const _settings = {
      ...userSettings,
      autoLogout: +userSettings.autoLogout,
    };
    if (userSettings._id) {
      return this.userSettingsService.update(_settings);
    } else {
      return this.userSettingsService.create(_settings);
    }
  }

  private isAutoLogoutValid(value): boolean {
    const number = Number(value);
    return !isNaN(number) && number > 0;
  }

  private validateUserSettingProperty(prop: string, value: string) {
    switch (prop) {
      case 'autoLogout':
        return this.isAutoLogoutValid(value);
      default:
        return true;
    }

  }
}
