import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StringOrEmpty } from 'src/app/app.model';
import { StorageModel } from '@models/storage.model';
import { StorageActions } from './storage.action-types';
import { updateStorageCacheByOldest } from '@states/storage/storage.actions';

export interface StorageState extends EntityState<StorageModel.StorageCacheItem> {
  isFirstLoaded: boolean;
  isAllLoaded: boolean;
  isPagination: boolean;
  error: StringOrEmpty;
  currentOfflineStorage: number[][];
  currentSmartStorage: number[][];
}

export const adapter: EntityAdapter<StorageModel.StorageCacheItem> = createEntityAdapter<StorageModel.StorageCacheItem>({
  selectId: (storage: StorageModel.StorageCacheItem) => storage.cacheId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialStorageState: StorageState = adapter.getInitialState({
  isFirstLoaded: false,
  isAllLoaded: false,
  isPagination: false,
  error: undefined,
  currentOfflineStorage: [],
  currentSmartStorage: [],
});

const mergeEntities = (existingEntity: StorageModel.StorageCacheItem, newEntity: StorageModel.StorageCacheItem): StorageModel.StorageCacheItem => {

  return {
    ...existingEntity,
    ...newEntity,
  };
};

export const storageStateReducer = createReducer(
  initialStorageState,
  on(StorageActions.setStorageCache, (state, action) => {
    // return adapter.upsertMany(action.storage, {
    //   ...state,
    //   isFirstLoaded: true,
    // });
    const updates = action.storage
      .filter((storage) => state.entities[storage?.cacheId])
      .map((storage) => ({
        id: storage?.cacheId,
        changes: mergeEntities(state.entities[storage?.cacheId], storage),
      }));

    const inserts = action.storage.filter((storage) => !state.entities[storage?.cacheId]);

    return adapter.updateMany(updates, {
      ...adapter.addMany(inserts, state),
      isFirstLoaded: true,
    });
  }),

  on(StorageActions.resetStorageCache, (state, action) => {
    return adapter.removeAll({
      ...state,
    });
  }),

  on(StorageActions.setOfflineStorageStats, (state, { offlineStorage }) => {
    return {
      ...state,
      currentOfflineStorage: offlineStorage,
    };
  }),

  on(StorageActions.setSmartStorageStats, (state, { smartStorage }) => {
    return {
      ...state,
      currentSmartStorage: smartStorage,
    };
  }),

  on(StorageActions.updateStorageCacheByOldest, (state, action) => {
    const entities = state.entities;
    const entityKeys = Object.keys(entities);
    const storage: StorageModel.StorageOldest[] = action.storage;
    const updatedEntities = [];
    for(let s of storage) {
      const cacheSub = `${s.edgeId}:${s.cameraId}:`;
      const existingKeys = entityKeys.filter(key => key.startsWith(cacheSub));
      for(let key of existingKeys) {
        const entity = entities[key];
        const base = +key.replace(cacheSub, '');
        if (base > s.oldestSmartVideoTS) {
          continue;
        }
        const smartStorage = entity.smartStorage.filter(item => item[0] >= s.oldestSmartVideoTS - base);
        const updatedEntity = {
          ...entity,
          smartStorage,
        };
        updatedEntities.push(updatedEntity);
      }
    }
    adapter.removeMany(updatedEntities.map(item => item.cacheId), {
      ...state,
      isFirstLoaded: true,
    });
    return adapter.upsertMany(updatedEntities, {
      ...state,
      isFirstLoaded: true,
    });
  }),
);
