import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AppUser } from '../user/user.model';
import { Store } from '@ngrx/store';
import { UserService } from '../user/user.service';
import { AppState } from '../store/app.state';

@Directive({
  selector: '[rbacAllow]',
})
export class RbacAllowDirective implements OnDestroy {
  allowedRoles: string[];
  user?: AppUser.User;

  sub: Subscription;
  roles: string[] | null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    private userService: UserService
  ) {
    // this.sub = this.store.pipe(select(UserSelectors.roles)).subscribe(roles => {
    //     this.roles = roles;
    //     this.showIfUserAuthorized();
    // });
    this.sub = this.userService.user$.subscribe(user => {
      this.user = user;
      this.showIfUserAuthorized();
    });
  }

  @Input()
  set rbacAllow(roles: string[]) {
    this.allowedRoles = roles;
    this.showIfUserAuthorized();
  }

  showIfUserAuthorized() {
    if (!this.allowedRoles || this.allowedRoles.length === 0 || !this.user?.roles) {
      this.viewContainer.clear();
      return;
    }

    const isAuthorized = _.intersection(this.allowedRoles, this.user?.roles).length > 0;

    if (isAuthorized) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
