import { QueryByEdgeId } from '../development/motion-vectors.service';
import { SearchObjectTypes, SearchQueryOperator } from '@enums/search.enum';
import { UiDatetimeRangePickerModel } from './ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import { SearchCamera, SearchSelection, SearchSelectionForm } from '@models/search.model';
import { OnRangeSelectedResult } from './ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { Zones } from './ui-kit/ui-zone-selector/ui-zone-selector.model';
import { Period } from './ui-kit/ui-period/ui-period.component';
import { CustomEventModel } from '@models/custom-event.model';
import { SearchObjectAttributes } from './thumbnail/thumbnail.model';
import { DashboardModel } from '@models/dashboard.model';
import { TimeRange } from '@models/alerts-v2.model';
import { Sort } from './shared.model';

export namespace Search {

  import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

  export interface SearchQueryParams {
    start?: string | number;
    end?: string | number;
    size?: number;
    page?: number;
    sort?: Sort;
  }

  export interface MotionVectorSearchRequest extends SearchQueryParams {
    flatArray?: number[];
    markedIdx?: number[];
    points?: number[][];
    sensitivity: number;
  }

  export interface MotionSearchRequest extends SearchQueryParams {
    minMotion?: number;
    cameras?: SearchCamera[];

  }

  export interface UnusualEventSearchRequest {
    sensitivity?: number;
    start?: string | number;
    end?: string | number;
    cameras?: SearchCamera[];
    size?: number;
    page?: number;
  }

  export interface AnalyticSearchRequest extends SearchQueryParams {
    flatArray?: number[];
    markedIdx?: number[];
    points?: number[][];
    type?: string;
    searchSelections?: SearchSelection[];
    highConfidence?: boolean;
    precision?: Precision;
    dwellRange?: SearchDwellRange;
    operator?: SearchQueryOperator;
    cameras?: SearchCamera[];
    useSingleStore?: boolean;
    maxStart?: number;
    maxEnd?: number;
    searchV2?: boolean;
    timeRange?: { start: number; end: number }[];
    faces?: Faces;
  }

  export interface CustomEventSearchRequest extends SearchQueryParams {
    cameras?: SearchCamera[];
    customEvents?: CustomEventModel.CustomEventRequestData[];
  }


  export interface MotionSearchParams {
    sensitivityEnabled?: boolean;
    minMotion?: number;
  }

  export interface UnusualEventSearchParams {
    sensitivity?: number;
  }

  export interface MultiTrackerSearchRequest extends AnalyticSearchRequest {
    idBase?: number;
    idIndex?: number;
    orgEdges?: string[];
    threshold?: number;
    onlyFaceId?: boolean;
    timeRange?: { start: number; end: number }[];
  }

  export interface SearchObject {
    id?: number;
    idBase?: number;
    idIndex?: number;
    url: string;
    zoomImage?: string;
    descriptor: boolean;
    score?: number;
    onlyFaceId?: boolean;
    personId?: number;
    groupId?: number;
    attributes?: SearchObjectAttributes;
    type?: SearchObjectTypes;
    maxPerson?: number;
    maxVehicle?: number;
    eventMessage?: string; // unusual alerts
    eventTypeId?: number; //unusual alerts
  }

  export interface SearchResponse {
    results: number[][];
    searchEvents?: number[][][];
    cameraIds?: string[];
    edgeIds?: string[];
    searchObjects?: SearchObject[][];
    more: boolean;
    latestTs?: number;
  }

  export type SearchQuery = QueryByEdgeId;

  export type MotionVectorSearchResponse = SearchResponse;
  export type AnalyticSearchResponse = SearchResponse;

  export interface MultiAnalyticsInfo {
    edgeId: string;
    cameraId: string;
    more: boolean;
    latestTs: number;
  }

  export interface MultiAnalytic {
    window: number[];
    searchEvents: number[][];
    bestImage: Search.SearchObject[];
    edgeId: string;
    cameraId: string;
  }

  export interface SearchCamera {
    edgeId?: string;
    cameraId: string;
    start?: number;
    end?: number;
    markedIdx?: number[];
    zones?: Zones;
    zonesExclude?: boolean;
  }

  export interface MultiAnalyticResponse {
    info: MultiAnalyticsInfo[];
    results: MultiAnalytic[];
  }

  export interface MultiAnalyticSearchRequest {
    searchSelections?: SearchSelection[];
    cameras: SearchCamera[];
    highConfidence?: boolean;
    precision?: Search.Precision;
    operator?: SearchQueryOperator;
  }

  export interface DateRange {
    start: string;
    end: string;
  }

  /**
   * Sidebar Search Models
   */
  export interface DateTimeRange {
    relative: {
      value: number;
      unit: CustomUnit;
    };
    absolute: { start: string; end: string };
  }

  export interface SearchDwellRange {
    period?: Period;
    start?: number;
    end?: number;
  }

  export enum Precision {
    LOW = 3,
    MEDIUM = 5,
    HIGH = 7,
  }

  export interface SavedSearch {
    _id?: string;
    name?: string;
    selectedCameras?: SearchCamera[];
    highConfidence?: boolean;
    precision?: Precision;
    dwellRange?: SearchDwellRange;
    cameraSearchSettingsFilters?: { [key: string]: boolean };
    objectSelections?: SearchSelectionForm[];
    objectSelectionsFormatted?: SearchSelectionForm[];
    outerOperator?: SearchQueryOperator;
    dateTimeRange?: OnRangeSelectedResult;
    created?: number;
    faces?: Search.Faces;
    customEvent: CustomEventModel.CustomEvent;
    motion: Search.MotionSearchParams;
    unusualEvent?: Search.UnusualEventSearchParams;
    searchType: SearchType;
    motionSearch?: boolean;
    unusualEventSearch?: boolean;
    customEventSearch?: boolean;
  }

  export interface SaveSearchRequest {
    name: string;
    savedSearch: SavedSearch;
  }

  export enum FacesOpeartion {
    Separate = 'separate',
    Together = 'together'
  }

  export interface SearchImage {
    descriptor: string;
    faceConfidence: number;
  }

  export interface FaceObject {
    bestImage?: string;
    fileName?: string;
    personId?: number;
    image?: SearchImage;
  }

  export interface Faces {
    operation: FacesOpeartion;
    objects: FaceObject[];
  }

  export interface SearchBody extends SearchQueryParams {
    markedIdx?: number[];
    type?: string;
    searchSelections: SearchSelection[];
    trackerClass?: DashboardModel.YAxisTrackerClass;
    highConfidence?: boolean;
    cameras?: SearchCamera[];
    operator?: SearchQueryOperator;
    dwellRange?: Search.SearchDwellRange;
    isCount?: boolean;
    orgId?: string;
    precision?: ConfidenceLevel;
    timeRange?: TimeRange[];
    faces?: Faces;
    idBase?: number;
    idIndex?: number;
    searchV2?: boolean;
    orgEdges?: string[];
    threshold?: number;
    maxStart?: number;
    maxEnd?: number;
  }

  export interface TimeRange {
    start: number;
    end: number;
  }

  export enum ConfidenceLevel {
    None = 0,
    LOW = 3,
    MEDIUM = 5,
    HIGH = 7,
  }

}

export enum SearchType {
  AI,
  MOTION,
  EVENT_TYPE,
  UNUSUAL_EVENT
}

