import { createReducer, on } from '@ngrx/store';
import * as AlertsV2Actions from '@states/alerts-v2/alerts-v2.actions';
import { AlertsV2DisplaySettings, AlertsV2PicInPicPosition, AlertsV2SettingPriority, AlertsV2Settings, AlertsV2SettingsBase, AlertsV2ShowSettings, AlertV2SelectedAction, AlertV2SelectedFlow, EventV2Document } from '@models/alerts-v2.model';
import { getMessagesByFlowType } from '@consts/alerts-v2.const';
import { WebhookModel } from '@models/webhook.model';

export declare interface AlertsV2State {
  selectedEvent?: EventV2Document;
  settings: AlertsV2Settings;
  name: string;
  selectedFlow: AlertV2SelectedFlow;
  actions: AlertV2SelectedAction[];
  valid?: boolean;
  changed?: boolean;
  webhooks: WebhookModel.DocumentMongo[];
}

export const initialAlertSettings: AlertsV2Settings = {
  priority: AlertsV2SettingPriority.High,
  confidence: AlertsV2SettingPriority.High,
  autoArchive: {
    enabled: false,
    duration: 10,
  },
  reactivationTh: 0,
  show: {
    disabled: false,
    on: [AlertsV2ShowSettings.ALERT_PAGE, AlertsV2ShowSettings.WALLS],
  },
};

export const baseAlertsV2Settings: AlertsV2SettingsBase = {
  priority: AlertsV2SettingPriority.High,
  confidence: AlertsV2SettingPriority.High,
  autoArchive: {
    enabled: false,
    duration: 10,
  },
  reactivationTh: 0,
  show: {
    disabled: false,
    on: [AlertsV2ShowSettings.ALERT_PAGE, AlertsV2ShowSettings.WALLS],
  },
};

export const baseAlertsV2Settings2: AlertsV2SettingsBase = {
  priority: AlertsV2SettingPriority.High,
  confidence: AlertsV2SettingPriority.High,
  autoArchive: {
    enabled: false,
    duration: 10,
  },
  reactivationTh: 0,
  show: {
    disabled: false,
    on: [AlertsV2ShowSettings.WALLS, AlertsV2ShowSettings.ALERT_PAGE],
  },
};

export const initialState: AlertsV2State = {
  selectedEvent: null,
  name: null,
  settings: initialAlertSettings,
  selectedFlow: {
    category: null,
    formValue: null,
    flowType: null,
  },
  actions: [],
  valid: false,
  changed: false,
  webhooks: null,
};

export const alertsV2StateReducer = createReducer(
  initialState,
  on(AlertsV2Actions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlertsV2Actions.setSettings, (state, { settings }) => {
    return {
      ...state,
      settings,
    };
  }),
  on(AlertsV2Actions.resetSettings, (state, { settings }) => {
    const defaultMessage = getMessagesByFlowType(state.selectedFlow.flowType, state.selectedFlow.category);
    return {
      ...state,
      settings: {
        ...settings,
        message: defaultMessage,
      },
    };
  }),
  on(AlertsV2Actions.setName, (state, { name }) => {
    return {
      ...state,
      name,
    };
  }),
  on(AlertsV2Actions.setSelectedFlow, (state, { selectedFlow }) => {
    const message = getMessagesByFlowType(selectedFlow.flowType, selectedFlow.category);
    const settingMessage = state.settings.message;
    if (!settingMessage) {
      return {
        ...state,
        selectedFlow,
        settings: {
          ...state.settings,
          message,
        },
      };
    }
    return {
      ...state,
      selectedFlow,
    };
  }),
  on(AlertsV2Actions.setActions, (state, { actions }) => {
    return {
      ...state,
      actions,
    };
  }),
  on(AlertsV2Actions.setValid, (state, { valid }) => {
    return {
      ...state,
      valid,
    };
  }),
  on(AlertsV2Actions.setChanged, (state, { changed }) => {
    return {
      ...state,
      changed,
    };
  }),
  on(AlertsV2Actions.setSelectedEvent, (state, { selectedEvent }) => {
    return {
      ...state,
      selectedEvent,
      name: selectedEvent.name,
    };
  }),
  on(AlertsV2Actions.getWebhooksSuccess, (state, { webhooks }) => {
    return {
      ...state,
      webhooks,
    };
  }),
);
