import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateNotificationService {

  private notificationSource = new BehaviorSubject<string[]>([]);
  private manuallyClosed = false;

  currentNotification = this.notificationSource.asObservable();

  private testJsonSource = new BehaviorSubject<any>(null);
  testJsonData = this.testJsonSource.asObservable();

  get isManuallyClosed(): boolean {
    return this.manuallyClosed;
  }

  // changeNotification(message: string) {
  //   if (!this.manuallyClosed) {
  //     this.notificationSource.next(message);
  //   }
  // }

  changeNotification(message: string, aggregate = false) {
    if (this.manuallyClosed) {
      return;
    }

    let currentMessages = this.notificationSource.getValue();
    if (aggregate) {
      this.notificationSource.next([...currentMessages, message]);
    } else {
      this.notificationSource.next([message]);
    }
  }

  resetManuallyClosed() {
    this.manuallyClosed = false;
  }

  setManuallyClosed() {
    this.manuallyClosed = true;
  }

  startEmittingTestData(): Observable<any> {
    return interval(1000).pipe(
      map(i => ({ message: `Test Notification #${i}`, timestamp: new Date() }))
    );
  }

}
