import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Observable } from 'rxjs';
import * as MenuSelectors from '@states/menu/menu.selectors';
import { MenuItem } from '@models/menu.model';
import { Router } from '@angular/router';
import { MenuKey } from '@enums/menu.enum';
import { selectLevel3Menu } from '@states/menu/menu.selectors';

@Component({
  selector: 'ui-menu-level2',
  templateUrl: './ui-menu-level2.component.html',
  styleUrls: ['./ui-menu-level2.component.scss'],
})
export class UiMenuLevel2Component implements OnInit {
  @Input() validPoints: { [key: number]: boolean } = {};
  @Input() showValidPoints = true;
  @Input() collapseEnabled = true;

  public collapsed = false;

  public selectMenuItems$: Observable<MenuItem[]> = this.store$.pipe(select(MenuSelectors.selectLevel2Menu));

  public selectLevel3Menu$: Observable<MenuItem[]> = this.store$.pipe(select(MenuSelectors.selectLevel3Menu));

  public selectActiveLevel2$: Observable<MenuKey> = this.store$.pipe(select(MenuSelectors.selectActiveLevel2));

  public selectActiveLevel3$: Observable<MenuKey> = this.store$.pipe(select(MenuSelectors.selectActiveLevel3));

  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit(): void {}

  public goTo(route: string, valid = false) {
    if (valid) {
      this.router.navigate([route]);
    }
  }

  toggleMenu() {
    this.collapsed = !this.collapsed;
  }
}
