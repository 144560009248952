import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { api } from '@consts/url.const';
import { PulsationModels } from '@models/pulsation.model';

export interface HeartbeatEntry {
  _id?: string;
  edgeId: string;
  timestamp: string;
  cameraStatus?: Array<{
    cameraId: string;
    cameraStatus: CameraStatus | string;
  }>;
  data: string;
}

export interface HeartbeatQuery {
  edgeId?: string;
  start?: string | number;
  end?: string | number;
}

export interface SystemStatusQuery {
  edgeId?: string;
  start?: string | number;
  end?: string | number;
}

export enum CameraStatus {
  Online,
  Offline,
  Streaming,
  Stopped,
  NotAuthenticated,
  StreamNotFound,
  Unknown,
  Init,
  Connected,
}

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  constructor(private http: HttpClient) {
  }

  parseObject(parse = true) {
    return source$ =>
      source$.pipe(
        map((res: Array<HeartbeatEntry>) => {
          return parse
            ? res.map(item => {
              let data = item.data;

              if (data) {
                try {
                  data = JSON.parse(data);
                } catch (error) {
                  data = item.data;
                }
              }

              if (item['timestamp']) {
                item['day'] = new Date(item['timestamp']);
              }

              const CameraStatusKeys = Object.keys(CameraStatus)
                .filter(v => isNaN(Number(v)));

              if (!!item?.cameraStatus?.length) {
                item.cameraStatus.forEach((element, index) => {
                  const statusIndex = item.cameraStatus![index].cameraStatus;
                  item.cameraStatus![index].cameraStatus = CameraStatusKeys[statusIndex];
                });
              }

              const e = !!data ? { ...item, ...{ data } } : { ...item };
              return e;
            })
            : res;
        }),
      );
  }

  getAllHeartbeats(page: number, size = 20, filter?: HeartbeatQuery): Observable<HeartbeatEntry[]> {
    let url = `${environment.apiUrl}/edge-heartbeats?page=${page}&size=${size}`;

    if (filter?.edgeId) {
      url = url.concat(`&edgeId=${filter.edgeId}`);
    }
    if (filter?.start) {
      url = url.concat(`&start=${filter.start}`);
    }
    if (filter?.end) {
      url = url.concat(`&end=${filter.end}`);
    }
    return this.http.get<HeartbeatEntry[]>(url)
      .pipe(this.parseObject(true));
  }

  public getEdgeHeartBeatsStatus(edgeId: string): Observable<{ res: any[] }> {
    let url = api.pulsation.heartbeatStatus(edgeId);
    return this.http.get<{ res: HeartbeatEntry[] }>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public getEdgePulsationsStatus(edgeId: string, rebootCounter?: number): Observable<PulsationModels.PulsationResponse> {
    let url = api.pulsation.heartbeatStatus(edgeId);
    if (rebootCounter) {
      url += `&rebootCounter=${rebootCounter}`;
    }
    return this.http.get<PulsationModels.PulsationResponse>(url);
  }
}
