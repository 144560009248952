import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { validateEmail } from '../../../helpers/common.helpers';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as SharedSelectors from '@states/shared/shared.selectors';
import { AppState } from '../../../store/app.state';
import * as GrantedAccessActions from '@states/granted-access/granted-access.actions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrantedAccessModel } from '@models/granted-access-model';

export enum WallAvatarColors {
  GRAY = '#D5DBEE',
  RED = '#E8513F',
  PEACH = '#FB9A99',
  BLUE = '#AFBFF9',
  DARK_BLUE = '#1F78B4',
  PINK = '#DDAFF9',
  GREEN = '#B2DF8A',
}

@Component({
  selector: 'app-show-people-modal',
  templateUrl: './show-people-modal.component.html',
  styleUrls: ['./show-people-modal.component.scss'],
})
export class ShowPeopleModalComponent implements OnInit {

  @ViewChild('ng2Tel') ng2Tel: any;


  public emailInput: string;
  public phoneInput: string;

  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsSaving));

  public colors = WallAvatarColors;
  public invitedEmailUsers: string[] = [];
  public invitedSMSUsers: string[] = [];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public message: string;

  constructor(
    private store$: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: GrantedAccessModel.GrantedAccessModelResponse,
    private matDialogRef: MatDialogRef<ShowPeopleModalComponent>,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
  }

  public removeEmail(email: string): void {
    const index = this.invitedEmailUsers.indexOf(email);

    if (index >= 0) {
      this.invitedEmailUsers.splice(index, 1);
    }
  }

  public removePhone(phone: string): void {
    const index = this.invitedSMSUsers.indexOf(phone);

    if (index >= 0) {
      this.invitedSMSUsers.splice(index, 1);
    }
  }

  public addEmail(): void {
    const value = this.emailInput.trim();

    if (validateEmail(value) && !this.invitedEmailUsers.includes(value)) {
      this.invitedEmailUsers.push(value);
      this.emailInput = '';
    }
  }

  public addPhone(): void {
    const value = this.phoneInput.trim();
    if (!value) {
      return;
    }

    if (!this.invitedSMSUsers.includes(value)) {
      if (this.phoneInput[0] === '0') {
        this.phoneInput = this.phoneInput.slice(1);
      }
      this.invitedSMSUsers.push(this.phoneInput);
      this.phoneInput = '';
      this.ng2Tel.phoneNumber = '';
    }
  }

  sanitizePhoneNumber(input: string) {
    // This regular expression matches any character that is not a number or a plus sign
    const regex = /[^0-9+]/g;

    // Replace all matched characters with an empty string
    return input.replace(regex, '');
  }

  public share() {
    if (this.validateEmail(this.emailInput)) {
      this.addEmail();
    }
    if (this.phoneInput) {
      this.addPhone();
    }
    for(let i = 0; i < this.invitedSMSUsers.length; i++) {
      this.invitedSMSUsers[i] = this.sanitizePhoneNumber(this.invitedSMSUsers[i]);
    }
    const invitedUsers = [...this.invitedEmailUsers, ...this.invitedSMSUsers];
    this.store$.dispatch(GrantedAccessActions.shareWith({ id: this.data._id, users: invitedUsers, message: this.message }));
    this.invitedEmailUsers = [];
    this.invitedSMSUsers = [];
    this.matDialogRef.close();
  }

  public validateEmail = validateEmail;

  public get sendValid(): boolean {
    return !!this.phoneInput || this.validateEmail(this.emailInput) || this.invitedSMSUsers.length > 0 || this.invitedEmailUsers.length > 0;
  }
}
