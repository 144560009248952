import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Comment } from '@models/comment';

@Component({
  selector: 'ui-comment-form',
  templateUrl: './ui-comment-form.component.html',
  styleUrls: ['./ui-comment-form.component.scss'],
})
export class UiCommentFormComponent implements OnInit {
  public commentForm: UntypedFormGroup;
  @Input() replyId: string;

  @Output() onSave: EventEmitter<Comment> = new EventEmitter<Comment>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(public fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.commentForm = this.fb.group({
      replyId: [this.replyId],
      name: ['', Validators.required],
      text: ['', Validators.required],
    });
  }


  public save() {
    this.commentForm.markAllAsTouched();
    if (this.commentForm.valid) {
      this.onSave.emit(this.commentForm.value as Comment);
      this.commentForm.reset();
    }
  }

  public cancel() {
    this.onCancel.emit();
  }

}
