import { createReducer, on } from '@ngrx/store';
import * as PlaybackActions from '@states/playback/playback.actions';

export declare interface PlaybackState {
  tag: string;
}

export const initialState: PlaybackState = {
  tag: undefined,
};

export const playbackStateReducer = createReducer(
  initialState,
  on(PlaybackActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(PlaybackActions.setTag, (state, { tag }) => {
    return {
      ...state,
      tag,
    };
  })
);
