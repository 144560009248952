<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100" fxFlex="56px">
    <div>{{ data?.fetchMissingObjectData ? 'Mark object' : 'Select region of interest' }}</div>
    <div mat-dialog-close class="c-p">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div style="padding: 24px 16px;" fxFlex="" class="w-100 child-100">
    <!--    <div #zoneSelectorWrapper fxLayout="row" fxLayoutAlign="center center" class="zone-selector-wrapper"-->
    <!--         [style]="'min-height:' + zoneSelectorWrapper.clientWidth * 9/16 + 'px'"-->
    <!--    >-->
    <div #zoneSelectorWrapper
         fxLayout="row"
         fxLayoutAlign="center center"
         class="zone-selector-wrapper p-r"
         [class.loading]="!imageLoaded"
         [style.height.px]="!imageLoaded ? basicHeight : 'auto'"
    >
      <ui-zone-selector #selector
                        [style.height.px]="snapshot.clientHeight"
                        (update)="update($event)"
                        [value]="zones"
                        [exclude]="exclude"
                        [mask]="maskEvents"
      ></ui-zone-selector>
      <div *ngIf="!imageLoaded">
        <ui-preloader></ui-preloader>
      </div>
      <img
        #snapshot
        (load)="onImageLoad()"
        (error)="onImageError()"
        [errorImage]="cameraSnapshot(data.camera.edgeOnly.cameraId) | async"
        [lazyLoad]="!selectedSnapshot ? ({edgeId: data.camera.edgeId, cameraId: data.camera.edgeOnly.cameraId } | cameraBestImagePipe | async) : selectedSnapshot"

        (error)="snapshot.alt = 'error'"
        [class.hidden]="snapshot.alt === 'error'"
      />


    </div>
    <div class="next-prev flex-row">
      <span matRipple
            matTooltip="Previous snapshot"
            matTooltipClass="sidebar-tooltip"
            class="ui-icon-prev-frame"
            [class.disabled]="snapshotIdx === snapshots?.length - 1"
            (click)="prevSnapshot()"
      ></span>
      <span matRipple
            matTooltip="Next snapshot"
            matTooltipClass="sidebar-tooltip"
            class="ui-icon-next-frame"
            [class.disabled]="snapshotIdx === 0"
            (click)="nextSnapshot()"
      ></span>
    </div>
    <div *ngIf="enableExclude" class="flex-row gap-8 mt-20">
      <ui-toggle [(ngModel)]="exclude"></ui-toggle>
      Exclude
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="56px" class="w-100">
    <button mat-stroked-button (click)="selector.clear()">Reset</button>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="select()">Select</button>
    </div>
  </div>
</div>
