import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from '@states/dashboard/dashboard.reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboardState');

export const selectSelectedDashboard = createSelector(selectDashboardState, ({selectedDashboard}: DashboardState) => selectedDashboard);

export const selectSelectedWidget = createSelector(selectDashboardState, ({selectedWidget}: DashboardState) => selectedWidget);

export const selectDashboards = createSelector(selectDashboardState, ({dashboards, query}: DashboardState) => {
  if (!!query) {
    return dashboards.filter(dashboard => dashboard.name.toLowerCase()
      .includes(query.toLowerCase()));
  }
  return dashboards;
});

export const selectFavoriteDashboards = createSelector(selectDashboardState, ({favorites}: DashboardState) => favorites);

export const selectVariablesAutoComplete = createSelector(
  selectDashboardState,
  ({variablesAutoComplete}: DashboardState) => variablesAutoComplete,
);

export const selectIsEditMode = createSelector(selectDashboardState, ({isEditMode}: DashboardState) => isEditMode);

export const selectQuery = createSelector(selectDashboardState, ({query}: DashboardState) => query);
export const selectFilters = createSelector(selectDashboardState, ({filters}: DashboardState) => filters);

export const selectWidgets = createSelector(selectDashboardState, ({widgets}: DashboardState) => widgets);

export const selectTotalItemsCount = createSelector(selectDashboardState, ({totalItemsCount}: DashboardState) => totalItemsCount);

export const selectPage = createSelector(selectDashboardState, ({page}: DashboardState) => {
  return page;
});

export const selectPerPage = createSelector(selectDashboardState, ({perPage}: DashboardState) => perPage);

export const selectDashboardById = (dashboardId: number) =>
  createSelector(selectDashboardState, ({dashboards}: DashboardState) => {
    const idx = dashboards.findIndex(item => item.id === dashboardId);
    return dashboards[idx];
  });

export const selectIsExpanded = createSelector(selectDashboardState, ({isExpanded}: DashboardState) => isExpanded);

export const selectFilter = createSelector(selectDashboardState, ({filter}: DashboardState) => filter);
export const selectVariablesAutoCompleteMap = createSelector(
  selectDashboardState,
  ({variablesAutoCompleteMap}: DashboardState) => variablesAutoCompleteMap,
);
