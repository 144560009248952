import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { combineLatest, map, Observable, take } from 'rxjs';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { checkPermission } from '../helpers/common.helpers';
import { PermissionModel } from '@models/permission.model';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { Injectable } from '@angular/core';

@UntilDestroy()
@Injectable()
export class PermissionService {
  public selectPermissions$: Observable<{
    permissions: string[],
    entityIds: string[]
  }> = this.store$.pipe(select(PermissionSelectors.selectPermissions));
  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  constructor(private store$: Store<AppState>) {
  }


  public checkPermission(permission: PermissionModel.Permissions, entityIds: string[]) {
    return combineLatest([this.selectPermissions$, this.isDeveloper$])
      .pipe(
        untilDestroyed(this),
        map(([permissions, isDeveloper]) => {
          const checkPerms = checkPermission(permission, permissions, entityIds);
          const isNotAuthorised = !checkPerms && !isDeveloper;
          return !isNotAuthorised;
        }),
        take(1),
      );
  }
}
