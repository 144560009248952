import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertEventLineColors, AlertEventLineColorsHex, AlertEventLineCrossing, AlertEventLineCrossingDirection, AlertEventLineCrossingState, AlertEventTrafficControl, AlertEventTrafficControlLine } from '@models/alert-events.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UiZoneSelectorComponent } from '../ui-zone-selector/ui-zone-selector.component';
import { UiHowToDrawComponent, UiHowToDrawType } from '../ui-how-to-draw/ui-how-to-draw.component';
import { CamerasService } from '../../../cameras/cameras.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { environment } from '../../../../environments/environment';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';

@UntilDestroy()
@Component({
  selector: 'ui-traffic-control',
  templateUrl: './ui-traffic-control.component.html',
  styleUrls: ['./ui-traffic-control.component.scss'],
})
export class UiTrafficControlComponent implements OnInit, AfterViewInit, OnDestroy {
  AlertEventLineCrossingDirection: typeof AlertEventLineCrossingDirection = AlertEventLineCrossingDirection;
  AlertEventLineColorsHex: typeof AlertEventLineColorsHex = AlertEventLineColorsHex;
  AlertEventLineColors: typeof AlertEventLineColors = AlertEventLineColors;

  @ViewChild('selector')
  selector: UiZoneSelectorComponent;

  @ViewChild('lineSelectorWrapper')
  lineSelectorWrapper: ElementRef;

  @Input() saveOnChange = false;

  parent: HTMLDivElement;
  observer: ResizeObserver;

  @ViewChild('snapshot')
  snapshot: ElementRef;

  public UiHowToDrawType = UiHowToDrawType;

  @ViewChild('howToDraw')
  howToDraw: UiHowToDrawComponent;

  @ViewChild('p1')
  p1: ElementRef;
  @ViewChild('p2')
  p2: ElementRef;
  @ViewChild('line')
  line: ElementRef;

  @ViewChild('lines')
  lines: ElementRef;

  lineCrossing: AlertEventLineCrossing;
  @Input() public trafficControl: AlertEventTrafficControl;

  currentLine: AlertEventTrafficControlLine = {
    p1: { x: 0, y: 0 },
    p2: { x: 0, y: 0 },
    d: AlertEventLineCrossingDirection.ANY,
    color: 'blue',
  };

  // For testing purposes we will set the cameraId explicitly;
  @Input() cameraId = '';
  @Input() edgeId = '';

  @Input() asLineCrossing = false;

  imageLoaded = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.redraw();
  }

  isAlerts = true;

  public snapshots: string[] = [];
  public snapshotIdx = 0;
  public selectedSnapshot = '';


  constructor(
    private camerasService: CamerasService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private camerasThumbnailsService: CamerasThumbnailsService,
  ) {
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.parent);
    this.observer.disconnect();
  }

  ngOnInit(): void {
    if (!!this.trafficControl) {

    } else {
      this.trafficControl = {
        lines: [],
        distance: null,
        distanceUnits: 'meter',
        state: AlertEventLineCrossingState.NO_SELECTION,
      };
    }

    this.camerasThumbnailsService.getLastSnapshots(this.cameraId)
      .subscribe((res) => {
        this.snapshots = res.bestThumbnails;
      });

  }

  currentColor() {
    const selectedColors = this.trafficControl?.lines?.map(line => line.color);
    let color;
    for(let c of AlertEventLineColors) {
      if (!selectedColors?.includes(c)) {
        color = c;
        break;
      }
    }

    return !!color ? color : AlertEventLineColors[this.trafficControl?.lines?.length % AlertEventLineColors.length];
  }

  saveState() {
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.trafficControl.lines, event.previousIndex, event.currentIndex);
  }

  redraw() {
    this.lines.nativeElement.innerHTML = '';
    for(let [index, line] of this.trafficControl?.lines.entries()) {
      if (!!line?.p1?.x) {
        this.drawExistingLine(line, index);
      }
    }
  }

  onImageLoad() {
    this.imageLoaded = true;
    if (this.trafficControl?.lines?.length > 0) {
      this.redraw();
    }
  }

  ngAfterViewInit(): void {

    this.parent = this.elementRef.nativeElement.parentElement;
    this.observer = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        this.redraw();
      });
    });
    this.observer.observe(this.parent);
  }

  noSelection() {
    return this.trafficControl?.state === AlertEventLineCrossingState.NO_SELECTION;
  }

  p1Selected() {
    return this.trafficControl?.state === AlertEventLineCrossingState.P1_SELECTED;
  }

  p2Selected() {
    return this.trafficControl?.state === AlertEventLineCrossingState.P2_SELECTED;
  }

  changeCurrentDir() {
    this.currentLine.d = (this.currentLine.d + 1) % 3;
  }

  changeColor(event: MatSelectChange, index: number) {
    const name = event.value;
    this.trafficControl.lines[index].color = name;
    this.saveState();
    this.redraw();
  }

  changeDir(index: number) {
    this.trafficControl.lines[index].d = (this.trafficControl.lines[index].d + 1) % 3;
    this.saveState();
    this.redraw();
  }

  changeName(event: Event, index: number) {
    const name = (event.target as HTMLInputElement).value;
    this.trafficControl.lines[index].name = name;
  }

  moveP1(event: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    const p1Style = this.p1.nativeElement.style;
    p1Style.left = `${event.clientX - bound.left}px`;
    p1Style.top = `${event.clientY - bound.top}px`;
  }

  addLine() {
    this.trafficControl.lines.push(_.cloneDeep(this.currentLine));
    this.redraw();
    this.saveState();
  }

  click(event: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    const p1 = this.p1.nativeElement;
    const p2 = this.p2.nativeElement;
    const p1Style = this.p1.nativeElement.style;
    const p2Style = this.p2.nativeElement.style;

    const percentLeft = +((event.clientX - bound.left) / bound.width).toFixed(2);
    const percentTop = +((event.clientY - bound.top) / bound.height).toFixed(2);

    const percentLeftStyle = percentLeft * 100;
    const percentTopStyle = percentTop * 100;

    switch (this.trafficControl.state) {
      case AlertEventLineCrossingState.NO_SELECTION:
        this.currentLine.color = this.currentColor();
        p1Style.left = `${percentLeftStyle}%`;
        p1Style.top = `${percentTopStyle}%`;
        p2Style.left = `${percentLeftStyle}%`;
        p2Style.top = `${percentTopStyle}%`;
        this.currentLine.p1 = { x: percentLeft, y: percentTop };
        this.trafficControl.state = AlertEventLineCrossingState.P1_SELECTED;
        break;
      case AlertEventLineCrossingState.P1_SELECTED:
        p2Style.left = `${percentLeftStyle}%`;
        p2Style.top = `${percentTopStyle}%`;
        this.currentLine.p2 = { x: percentLeft, y: percentTop };
        this.trafficControl.state = this.trafficControl.state = AlertEventLineCrossingState.NO_SELECTION;
        this.addLine();
        this.clear();
        break;
      case AlertEventLineCrossingState.P2_SELECTED:
        this.clear();
        break;
    }
    this.saveState();
  }

  delete(index: number) {
    this.trafficControl.lines.splice(index, 1);
    this.saveState();
    this.redraw();
  }

  reset() {
    this.clear();
    this.trafficControl.lines = [];
    this.trafficControl.distance = null;
    this.trafficControl.distanceUnits = 'meter';
    this.trafficControl.state = AlertEventLineCrossingState.NO_SELECTION;
    this.redraw();
  }

  clear(fromTemplate = false) {
    this.line.nativeElement.style.width = '0px';
    this.currentLine.p1 = undefined;
    this.currentLine.p2 = undefined;
    this.currentLine.d = AlertEventLineCrossingDirection.ANY;
    if (fromTemplate) {
      this.saveState();
    }
  }

  drawExistingLine(line: AlertEventTrafficControlLine, index: number) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();

    const lines = this.lines.nativeElement;

    const p1: HTMLDivElement = this.renderer.createElement('div');
    const p2: HTMLDivElement = this.renderer.createElement('div');
    const l: HTMLDivElement = this.renderer.createElement('div');
    const d: HTMLDivElement = this.renderer.createElement('div');
    const dIcon: HTMLDivElement = this.renderer.createElement('mat-icon');

    switch (line.d) {
      case AlertEventLineCrossingDirection.ANY:
        dIcon.innerHTML = 'remove';
        break;
      case AlertEventLineCrossingDirection.CCW:
        dIcon.innerHTML = 'arrow_upward';
        break;
      case AlertEventLineCrossingDirection.CW:
        dIcon.innerHTML = 'arrow_downward';
        break;
    }

    d.addEventListener('click', event => {
      event.stopPropagation();
      return this.changeDir(index);
    });

    dIcon.classList.add('mat-icon', 'material-icons');
    d.classList.add('direction');
    l.classList.add('line', 'complete');

    this.renderer.appendChild(d, dIcon);
    this.renderer.appendChild(l, d);

    p1.classList.add('point', line.color);
    p2.classList.add('point', line.color);
    p1.style.left = `${line.p1.x * 100}%`;
    p1.style.top = `${line.p1.y * 100}%`;
    p2.style.left = `${line.p2.x * 100}%`;
    p2.style.top = `${line.p2.y * 100}%`;

    this.renderer.appendChild(this.lines.nativeElement, p1);
    this.renderer.appendChild(this.lines.nativeElement, p2);
    this.renderer.appendChild(this.lines.nativeElement, l);

    //find absolute center between both points.
    //find angle between the two.
    //find distance between the two.

    //first dot
    const posax = p1.offsetLeft;
    const posay = p1.offsetTop;

    //last dot
    const posbx = p2.offsetLeft;
    const posby = p2.offsetTop;

    //temp calculation variables
    let centerx;
    let centery;
    let distance;

    //find center points;
    centerx = (posax + posbx) / 2;
    centery = (posay + posby) / 2;

    //angle
    let angle = (Math.atan2(posay - posby, posax - posbx) * 180) / Math.PI;

    //distance
    distance = Math.sqrt(Math.pow(posbx - posax, 2) + Math.pow(posby - posay, 2));

    //bring all the work together

    l.style.width = `${distance}px`;
    l.style.transform = `rotate(${angle}deg)`;
    l.style.top = `${centery - l.clientHeight / 2 + p1.clientHeight / 2}px`;
    l.style.left = `${centerx - l.clientWidth / 2 + p1.clientWidth / 2}px`;
  }

  drawLine(event?: MouseEvent) {
    const wrapper = this.lineSelectorWrapper.nativeElement;
    const bound = wrapper.getBoundingClientRect();
    //set this.p2 point to where the mouse currently is at.
    if (!!event) {
      const percentLeft = +((event.clientX - bound.left) / bound.width).toFixed(2);
      const percentTop = +((event.clientY - bound.top) / bound.height).toFixed(2);

      const percentLeftStyle = percentLeft * 100;
      const percentTopStyle = percentTop * 100;

      if (percentLeftStyle > 100 || percentLeftStyle < 0 || percentTopStyle > 100 || percentTopStyle < 0) {
        return;
      }
      this.p2.nativeElement.style.left = `${percentLeftStyle}%`;
      this.p2.nativeElement.style.top = `${percentTopStyle}%`;
    }

    //find absolute center between both points.
    //find angle between the two.
    //find distance between the two.

    //first dot
    const posax = this.p1.nativeElement.offsetLeft;
    const posay = this.p1.nativeElement.offsetTop;

    //last dot
    const posbx = this.p2.nativeElement.offsetLeft;
    const posby = this.p2.nativeElement.offsetTop;

    //temp calculation variables
    let centerx;
    let centery;
    let distance;

    //find center points;
    centerx = (posax + posbx) / 2;
    centery = (posay + posby) / 2;

    //angle
    let angle = (Math.atan2(posay - posby, posax - posbx) * 180) / Math.PI;

    //distance
    distance = Math.sqrt(Math.pow(posbx - posax, 2) + Math.pow(posby - posay, 2));

    //bring all the work together
    this.line.nativeElement.style.width = `${distance}px`;
    this.line.nativeElement.style.transform = `rotate(${angle}deg)`;
    this.line.nativeElement.style.top = `${centery - this.line.nativeElement.clientHeight / 2 + this.p1.nativeElement.clientHeight / 2}px`;
    this.line.nativeElement.style.left = `${centerx - this.line.nativeElement.clientWidth / 2 + this.p1.nativeElement.clientWidth / 2}px`;
  }

  move(event: MouseEvent) {
    switch (this.trafficControl.state) {
      case AlertEventLineCrossingState.NO_SELECTION:
        break;
      case AlertEventLineCrossingState.P1_SELECTED:
        this.drawLine(event);
        break;
    }
  }

  onChange() {
    if (this.saveOnChange) {
      this.saveState();
    }
  }

  displayHowToDraw() {
    this.howToDraw.displayHowToDraw();
  }

  nextSnapshot() {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    this.snapshotIdx = Math.max(this.snapshotIdx - 1, 0);
    this.selectedSnapshot = `${environment.trainingThumbnailsUrl}/snapshot/${edgeId}/${cameraId}/${this.snapshots[this.snapshotIdx]}`;
  }

  prevSnapshot() {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    this.snapshotIdx = Math.min(this.snapshotIdx + 1, this.snapshots.length - 1);
    this.selectedSnapshot = `${environment.trainingThumbnailsUrl}/snapshot/${edgeId}/${cameraId}/${this.snapshots[this.snapshotIdx]}`;
  }
}
