import { createReducer, on } from '@ngrx/store';
import { DHCPActions } from '@states/dhcp/dhcp.action-types';
import { Edge } from '../../../edge/edge.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { attachDevice, setAddReservedAddressLoading, updateReservedAddress } from '@states/dhcp/dhcp.actions';

export interface DCHPState extends EntityState<Edge.DhcpDevice> {
  initialLoading: boolean; // loader when page is start
  getDhcpLoading: boolean; // locader when enable/disable request happen
  attachedAddresses: Edge.DhcpDevice[]; // attached devices table
  enabled: boolean; // state of enable/disable form button
  reservedAddressesNewRecordsMap: Dictionary<Edge.DhcpDevice>; // updated state of records which is different from entities until edit or discard needs for attached device button
  addReservedAddressLoading: boolean;
}

export const adapter: EntityAdapter<Edge.DhcpDevice> = createEntityAdapter<Edge.DhcpDevice>({
  selectId: (device: Edge.DhcpDevice) => device.macAddress,
});

export const initialState: DCHPState = adapter.getInitialState({
  initialLoading: true,
  getDhcpLoading: false,
  attachedAddresses: null,
  enabled: false,
  reservedAddressesNewRecordsMap: {},
  addReservedAddressLoading: false,
});

export const dhcpStateReducer = createReducer(
  initialState,
  on(DHCPActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(DHCPActions.setGetDhcpLoading, (state, { getDhcpLoading }) => {
    return {
      ...state,
      getDhcpLoading,
    };
  }),
  on(DHCPActions.setEnabled, (state, { enabled }) => {
    return {
      ...state,
      enabled,
    };
  }),
  on(DHCPActions.serverRequestGetDhcpSuccess, (state, { reservedAddresses, attachedAddresses }) => {
    const records = {};
    reservedAddresses.forEach(item => {
      records[item.macAddress] = item;
    });
    return adapter.setAll(reservedAddresses, {
      ...state,
      reservedAddressesNewRecordsMap: records,
      attachedAddresses,
    });
  }),
  on(DHCPActions.addReservedAddress, (state, { document }) => {
    const reservedAddressesNewRecords = { ...state.reservedAddressesNewRecordsMap };
    reservedAddressesNewRecords[document.macAddress] = document;
    return {
      ...state,
      reservedAddressesNewRecordsMap: reservedAddressesNewRecords,
    };
  }),
  on(DHCPActions.updateReservedAddress, (state, { document, oldMacAddress }) => {
    const reservedAddressesNewRecords = { ...state.reservedAddressesNewRecordsMap };
    delete reservedAddressesNewRecords[oldMacAddress];
    reservedAddressesNewRecords[document.macAddress] = document;
    return {
      ...state,
      reservedAddressesNewRecordsMap: reservedAddressesNewRecords,
    };
  }),
  on(DHCPActions.discardReservedAddress, (state) => {
    return {
      ...state,
      reservedAddressesNewRecordsMap: state.entities,
    };
  }),
  on(DHCPActions.deleteReservedAddress, (state, { macAddress }) => {
    const reservedAddressesNewRecords = { ...state.reservedAddressesNewRecordsMap };
    delete reservedAddressesNewRecords[macAddress];
    return {
      ...state,
      reservedAddressesNewRecordsMap: reservedAddressesNewRecords,
    };
  }),
  on(DHCPActions.serverRequestSaveReservedAddressSuccess, (state, { reservedAddresses, attachedAddresses }) => {
    const dictionary = {};
    reservedAddresses.forEach(item => {
      dictionary[item.macAddress] = item;
    });
    return adapter.setAll(reservedAddresses, {
      ...state,
      reservedAddressesNewRecordsMap: dictionary,
      attachedAddresses,
    });
  }),
  on(DHCPActions.attachDevice, (state, { device }) => {
    const reservedAddressesNewRecords = { ...state.reservedAddressesNewRecordsMap };
    reservedAddressesNewRecords[device.macAddress] = device;
    return {
      ...state,
      reservedAddressesNewRecordsMap: reservedAddressesNewRecords,
    };
  }),
  on(DHCPActions.setAddReservedAddressLoading, (state, { addReservedAddressLoading }) => {
    return {
      ...state,
      addReservedAddressLoading,
    };
  }),
  on(DHCPActions.serverRequestGetDhcpFail, (state) => {
    return {
      ...state,
      enabled: !state.enabled,
    };
  }),
  on(DHCPActions.setInitialLoaded, (state) => {
    return {
      ...state,
      initialLoading: false,
    };
  }),
);
