import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface Color {
  value: string;
  hex: string;
}

@Component({
  selector: 'ui-color-select',
  templateUrl: './ui-color-select.component.html',
  styleUrls: ['./ui-color-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiColorSelectComponent),
      multi: true,
    },
  ],
})
export class UiColorSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Output('selectionChange')
  selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  value: string[];

  colors: Color[] = [
    { value: 'black', hex: '#000000' },
    { value: 'blue', hex: '#3B82F6' },
    { value: 'brown', hex: '#B45309' },
    { value: 'green', hex: '#16A34A' },
    { value: 'grey', hex: '#98A2B3' },
    { value: 'orange', hex: '#EA580C' },
    { value: 'pink', hex: '#DB2777' },
    { value: 'purple', hex: '#9333EA' },
    { value: 'red', hex: '#DC2626' },
    { value: 'yellow', hex: '#EAB308' },
    { value: 'white', hex: '#D0D5DD' },
  ];

  selected: Set<string> = new Set<string>();

  constructor() {
  }

  ngOnInit(): void {
    if (!!this.value) {
      for(let color of this.value) {
        this.selected.add(color);
      }
    }
  }

  selectColor(color: string) {
    this.selected.add(color);
  }

  removeColor(color: string) {
    this.selected.delete(color);
  }

  isSelected(color: string) {
    return this.selected.has(color);
  }

  selection() {
    const value = Array.from(this.selected);
    this.selectionChange.emit(value);
    this.onChange(value);
    this.onTouched();
  }

  toggle(color: string) {
    if (this.isSelected(color)) {
      this.removeColor(color);
    } else {
      this.selectColor(color);
    }
    this.selection();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: string[]): void {
    if (!!val) {
      for(let color of val) {
        this.selected.add(color);
      }
    }
  }

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['value'].currentValue) {
      this.selected.clear();
      for(let color of this.value) {
        this.selected.add(color);
      }
    }
  }
}
