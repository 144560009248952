import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertMonitoringViewModel, AlertNotificationLog, AlertNotificationLogFilter } from '@models/alert-monitoring.model';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';


@Injectable()
export class AlertMonitoringService {
  constructor(private http: HttpClient) {
  }

  public saveView(view: AlertMonitoringViewModel): Observable<AlertMonitoringViewModel> {
    return this.http.post<AlertMonitoringViewModel>(api.alertMonitoring.crud, view);
  }

  public updateView(view: AlertMonitoringViewModel): Observable<AlertMonitoringViewModel> {
    return this.http.put<AlertMonitoringViewModel>(api.alertMonitoring.one(view._id), view);
  }

  public get(dto: { query: string, timestamp: number, limit: number }): Observable<AlertMonitoringViewModel[]> {
    return this.http.post<AlertMonitoringViewModel[]>(api.alertMonitoring.getViews, dto);
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(`${api.alertMonitoring.crud}/${id}`);
  }

  public getView(id: string): Observable<AlertMonitoringViewModel> {
    return this.http.get<AlertMonitoringViewModel>(api.alertMonitoring.getView(id));
  }

  public archiveAlert(id: string, isArchive): Observable<boolean> {
    return this.http.patch<boolean>(api.alerts.archive(id), { archived: isArchive });
  }

  public deleteAlert(id: string): Observable<AlertMonitoringViewModel> {
    return this.http.delete<AlertMonitoringViewModel>(api.alerts.one(id));
  }

  public getAlertNotificationLogs(filter: AlertNotificationLogFilter, queryParams: { limit: number, offset: number }): Observable<AlertNotificationLog[]> {
    return this.http.post<AlertNotificationLog[]>(`${api.alerts.getAlertNotificationLogs}?limit=${queryParams.limit}&offset=${queryParams.offset}`, filter);
  }
}
