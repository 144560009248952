export enum LicenseTerms {
  Years1,
  Years3,
  Years5
}

export enum RetentionOptions {
  Days30,
  Days60,
  Days90,
  Cloud_add_ons
}

export enum CameraOptions {
  Resolution5MP,
  Resolution8MP,
  Fisheye12MP,
  OpticalZoom5MP,
  PTZ
}

export enum AddOnOptions {
  Speaker,
  CloudStorage30Days,
  CloudStorage60Days,
  CloudStorage90Days,
  CloudStorage180Days,
  CloudStorage365Days,
  ExternalStorage30Days,
  ExternalStorage60Days,
  ExternalStorage90Days,
  ExternalStorage180Days,
  ExternalStorage365Days,
  AlertStorage,
  EventTagsStorage,
  AppleTvLicense,
}

export enum StorageOptions {
  Storage1TB,
  Storage2TB,
  Storage4TB,
  Storage32TB,
  Storage64TB,
  Storage88TB
}

export enum DeviceOptions {
  Nano8GBOrin,
  XavierNX16GB,
  OrinNX16GB,
  RACK1U32TB,
  RACK1U64TB,
  RACK1U88TB
}

export interface BoxConfiguration {
  retention: RetentionOptions;
  storage: StorageOptions;
  device: DeviceOptions;
  resolution: CameraOptions;
  maxNumberOfCameras: number;
}

export interface BoxPrice {
  storage: StorageOptions;
  device: DeviceOptions;
  price: Record<LicenseTerms, number>;
}

export interface CameraPrice {
  resolution: CameraOptions,
  price: Record<LicenseTerms, number>
  own?: number;
}

export interface AddOnPrice {
  type: AddOnOptions;
  price: number;
}

export interface Pricing {
  boxes: number;
  boxesRegular?: number;
  license: number;
  cameras: number;
  cloud?: number;
  total: number;
  regularPrice: number;
  oneTimePrice?: number;
  addOns?: number;
  shipping: number;
}

export interface PricingCount {
  // Boxes
  box2tb: number;
  box4tb: number;
  box1u32tb: number;
  box1u64tb: number;
  box1u88tb: number;
  // Lease Cameras
  camerasTurret5mp: number;
  camerasDome5mp: number;
  camerasBullet5mp: number;
  camerasTurret8mp: number;
  camerasDome8mp: number;
  camerasBullet8mp: number;
  camerasFisheye12mp: number;
  camerasPtz: number;
  camerasTurret5mpZoom: number;
  // Own Cameras
  ownCamerasTurret5mp: number;
  ownCamerasDome5mp: number;
  ownCamerasBullet5mp: number;
  ownCamerasTurret8mp: number;
  ownCamerasDome8mp: number;
  ownCamerasBullet8mp: number;
  ownCamerasFisheye12mp: number;
  ownCamerasPtz: number;
  ownCamerasTurret5mpZoom: number;
  // License
  license: number;
  // Cloud Storage
  cloudStorage30?: number;
  cloudStorage60?: number;
  cloudStorage90?: number;
  cloudStorage180?: number;
  cloudStorage365?: number;
  // External Storage
  externalStorage30?: number;
  externalStorage60?: number;
  externalStorage90?: number;
  externalStorage180?: number;
  externalStorage365?: number;
  // Metadata Storage
  metadataStorage30?: number;
  metadataStorage60?: number;
  metadataStorage90?: number;
  metadataStorage180?: number;
  metadataStorage365?: number;
  // Snapshot Storage
  snapshotStorage30?: number;
  snapshotStorage60?: number;
  snapshotStorage90?: number;
  snapshotStorage180?: number;
  snapshotStorage365?: number;
  // Video verification
  verificationEvents?: number;
  verificationGuns?: number;
  verificationFire?: number;
  // AdditionalOptions
  additionalSpeaker?: number;
  additionalSpeakerLicense?: number;
  additionalSensorLicense?: number;
  additionalAppleTvLicense?: number;
  additionalAlertStorage?: number;
  additionalEventTagsStorage?: number;
}

export interface PricingLocationInfo {
  numberOfCameras: number;
  retention: RetentionOptions;
  resolution: CameraOptions;
  numberOfNewCameras: number;
  terms: LicenseTerms;
}

export interface PricingLocation {
  name: string;
  info: PricingLocationInfo[];
  include1u?: boolean;
}

export enum CameraType {
  Turret5MP,
  Dome5MP,
  Bullet5MP,
  Turret8MP,
  Dome8MP,
  Bullet8MP,
  // Fisheye12MP,
  // PTZ,
  // Turret5MPZoom
}

export enum SummarySection {
  SubscriptionAndCores,
  LeaseCameras,
  OwnCameras,
  MetadataStorage,
  VideoStorage,
  ExternalStorage,
  SnapshotStorage,
  Verification,
  AdditionalOptions

}
