import { createReducer, on } from '@ngrx/store';
import { CustomEventModel } from '@models/custom-event.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomEventsActions } from './custom-events.action-types';

export interface CustomEventsState extends EntityState<CustomEventModel.CustomEvent> {

}

export const adapter: EntityAdapter<CustomEventModel.CustomEvent> = createEntityAdapter<CustomEventModel.CustomEvent>({
  selectId: (customEvent: CustomEventModel.CustomEvent) => customEvent._id,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const testState: CustomEventsState = adapter.getInitialState({
  ids: [605815355],
  entities: {
    605815355: {
      _id: 605815355,
      name: 'Untitled name',
      videoLength: 10,
      timestamp: 1700047629376,
      fields: [],
    },

  },
});

const initialState: CustomEventsState = adapter.getInitialState({});
export const customEventsStateReducer = createReducer(
  initialState,
  on(CustomEventsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(CustomEventsActions.getCustomEventsSuccess, (state, { customEvents }) => {
    return adapter.setMany(customEvents, state);
  }),
  // on(CustomEventsActions.addCustomEventSuccess, (state, { customEvent }) => {
  //   const update: CustomEventModel.CustomEvent = {
  //     ...customEvent,
  //   };
  //   return adapter.setOne(update, state);
  // }),
  on(CustomEventsActions.editCustomEvent, (state, { customEvent }) => {
    const update: CustomEventModel.CustomEvent = {
      ...customEvent,
    };
    return adapter.setOne(update, state);
  }),
  on(CustomEventsActions.removeCustomEventSuccess, (state, { customEvent }) => {
    return adapter.removeOne(customEvent._id, state);
  }),
);
