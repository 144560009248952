import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateNewEdgeComponent } from './edge/create-new-edge/create-new-edge.component';
import { EdgeInfoComponent } from './edge/edge-info/edge-info.component';
import { SplashComponent } from './framework/splash/splash.component';
import { FullPageComponent } from './layout/full-page/full-page.component';
import { routerSegments } from '@consts/routes';
import { LocationsResolver } from './locations/locations.resolver';
import { AuthenticationGuardService } from './core/authentication-guard.service';
import { SidebarV2Component } from './layout/sidebar-v2/sidebar-v2.component';
import { DeveloperGuard } from './guards/developer.guard';
import { EmailVerificationComponent } from './authentication/email-verification/email-verification.component';
import { isDefaultOrgGuard } from './guards/is-default-org.guard';
import { RouterPermissionGuard } from './guards/router-permission-guard';
import { PermissionModel } from '@models/permission.model';
import { SensorsViewComponent } from './pages/sensors-view/sensors-view.component';

const authenticatedRoutes: Routes = [
  {
    path: 'home',
    children: [
      {
        path: '**',
        redirectTo: `/${routerSegments.locationV2}/list`,
      },
    ],
  },
  {
    path: 'alerts',
    loadChildren: () => import('./pages/alert/alert.module').then(m => m.AlertModule),
  },
  {
    path: routerSegments.alertsV2,
    loadChildren: () => import('./pages/alerts-v2/alerts-v2.module').then(m => m.AlertsV2Module),
  },
  {
    path: 'search',
    canActivate: [RouterPermissionGuard],
    data: {
      permissions: [PermissionModel.Permissions.SearchView],
    },
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
  },
  {
    path: 'cameras',
    loadChildren: () => import('./cameras/cameras.module').then(m => m.CamerasModule),
  },
  {
    path: 'camera',
    loadChildren: () => import('./pages/camera/camera.module').then(m => m.CameraModule),
  },
  {
    path: routerSegments.wallV2,
    loadChildren: () => import('./pages/wall-v2/wall-v2.module').then(m => m.WallV2Module),
  },
  {
    path: 'operator',
    loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule),
  },
  {
    path: routerSegments.terminal,
    canActivate: [DeveloperGuard],
    loadChildren: () => import('./pages/terminal/terminal.module').then(m => m.TerminalModule),
  },
  // TODO: move to seperate module (edge module) and master slave with 'create' component
  {
    path: 'edge/create',
    children: [
      {
        path: '',
        component: CreateNewEdgeComponent,
      },
    ],
  },
  {
    path: 'edge/info',
    children: [
      {
        path: '',
        component: EdgeInfoComponent,
      },
    ],
  },
  {
    path: routerSegments.cameraEdit,
    loadChildren: () => import('./pages/camera-edit/camera-edit.module').then(m => m.CameraEditModule),
  },
  {
    path: routerSegments.edgeEdit,
    loadChildren: () => import('./pages/edge-edit/edge-edit.module').then(m => m.EdgeEditModule),
  },
  {
    path: routerSegments.editLocation,
    loadChildren: () => import('./pages/edit-location/edit-location.module').then(m => m.EditLocationModule),
  },
  {
    path: routerSegments.archive,
    loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule),
  },
  {
    path: routerSegments.account,
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
  },
  {
    path: routerSegments.settings,
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: routerSegments.database,
    loadChildren: () => import('./pages/database/database.module').then(m => m.DatabaseModule),
  },
  {
    path: routerSegments.devTeam,
    loadChildren: () => import('./pages/dev-team/dev-team.module').then(m => m.DevTeamModule),
  },
  {
    path: routerSegments.mainSettings,
    loadChildren: () => import('./pages/main-settings/main-settings.module').then(m => m.MainSettingsModule),
  },
  {
    path: routerSegments.dashboards,
    canActivate: [RouterPermissionGuard],
    data: {
      permissions: [PermissionModel.Permissions.DashboardView],
    },
    loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
  },
  {
    path: routerSegments.dashboardHealth,
    loadChildren: () => import('./pages/dashboard-health/dashboard-health.module').then(m => m.DashboardHealthModule),
  },
  {
    path: routerSegments.pricing,
    loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule),
  },
  {
    path: routerSegments.reports,
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: routerSegments.help,
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule),
  },
  {
    path: routerSegments.locationV2,
    loadChildren: () => import('./pages/location/location.module').then(m => m.LocationModule),
  },
  {
    path: routerSegments.support,
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule),
  },
  {
    path: `${routerSegments.sensors}/:locationId/:sensorId`,
    component: SensorsViewComponent,
  },
  {
    path: `${routerSegments.editLocation}/:locationId/${routerSegments.sensors}/${routerSegments.view}/:sensorId`,
    component: SensorsViewComponent,
  },
  {
    path: '**',
    redirectTo: `${routerSegments.locationV2}/${routerSegments.home}`,
  },
];

const routes: Routes = [
  {
    path: routerSegments.noAuth,
    loadChildren: () => import('./pages/no-auth/no-auth.module').then(m => m.NoAuthModule),
  },
  {
    path: routerSegments.notFound,
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: routerSegments.auth,
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: routerSegments.emailVerification,
    component: EmailVerificationComponent,
  },
  {
    path: `${routerSegments.grantedAccess}`,
    loadChildren: () => import('./pages/granted-access/granted-access.module').then(m => m.GrantedAccessModule),
  },
  {
    path: `${routerSegments.alertVideo}`,
    loadChildren: () => import('./pages/alert-video/alert-video.module').then(m => m.AlertVideoModule),
  },
  {
    path: 'splash',
    component: FullPageComponent,
    pathMatch: 'full',
    children: [
      {
        path: '',
        component: SplashComponent,
      },
    ],
  },
  {
    path: routerSegments.terms,
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: routerSegments.privacy,
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: routerSegments.selectOrganization,
    loadChildren: () => import('./pages/organization-layout/organization-layout.module').then(m => m.OrganizationLayoutModule),
    canActivate: [isDefaultOrgGuard],
  },
  {
    path: routerSegments.public,
    loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule),
  },
  {
    path: '',
    children: authenticatedRoutes,
    component: SidebarV2Component,
    canActivate: [AuthenticationGuardService],
    resolve: {
      // userProfileResolver: UserResolver,
      locationResolver: LocationsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
