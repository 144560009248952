import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, shareReplay, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LocationSelectors } from '@states/location/location.selector-types';
import { DeviceModel } from '@models/device.model';
import { speakerComparator } from '../../../helpers/comparators';
import { AppUser } from '../../../user/user.model';
import { UserSelectors } from '@states/user/user.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TwilioService } from '../../../authentication/twilio.service';
import { UserActions } from '@states/user/user.action-types';


@UntilDestroy()
@Component({
  selector: 'ui-ptt',
  templateUrl: './ui-ptt.component.html',
  styleUrl: './ui-ptt.component.scss',
})
export class UiPttComponent implements OnInit, OnDestroy {

  public selectSipSpeakers$: Observable<DeviceModel.LocationDeviceDocument[]>;

  public speakerComparator = speakerComparator;

  public selectedSpeaker: DeviceModel.LocationDeviceDocument;
  public selectTwilioPttDetails$: Observable<AppUser.TwilioPttDetails> = this.store$.pipe(select(UserSelectors.selectTwilioPttDetails));


  @Input() locationId: string;
  @Input() cameraId: string;

  private twilioPttDetails: AppUser.TwilioPttDetails;

  constructor(private store$: Store, private twilioService: TwilioService) {
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  ngOnInit(): void {
    this.selectSipSpeakers$ = this.store$.pipe(shareReplay(), select(LocationSelectors.selectSipSpeakers(this.locationId, this.cameraId)));
    this.selectSipSpeakers$.pipe(take(1))
      .subscribe((speakers) => {
        if (!!speakers?.length) {
          this.selectedSpeaker = speakers[0];
        }
      });

    this.selectTwilioPttDetails$.pipe(untilDestroyed(this))
      .subscribe((twilioPttDetails) => {
        this.twilioPttDetails = twilioPttDetails;
      });
  }

  async startCall() {
    // if (!this.twilioPttDetails || this.twilioPttDetails.expiresAt + 2 * 60 * 1000 > Date.now()) {
    //   this.store$.dispatch(UserActions.renewTwilioPttToken());
    //   setTimeout(() => {
    //     this.startCall();
    //   }, 2000);
    //   return;
    // }
    if (this.inCall) {
      this.disconnect();
      return;
    }
    await this.twilioService.talk('sip:' + this.selectedSpeaker.sipUrl);
  }

  public get callStatus() {
    return this.twilioService.callStatus;
  }

  public get inCall() {
    return this.callStatus === 'open' || this.callStatus === 'connecting' || this.callStatus === 'ringing';
  }

  public get ringing() {
    return this.callStatus === 'ringing';
  }

  public get open() {
    return this.callStatus === 'open';
  }

  disconnect() {
    this.twilioService.hangUp();
  }

}
