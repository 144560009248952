import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-img-ratio',
  templateUrl: './ui-img-ratio.component.html',
  styleUrls: ['./ui-img-ratio.component.scss']
})
export class UiImgRatioComponent {
  imageClass: string = ''; // Variable to hold the class based on ratio
  @Input() src: string = ''; // Input property to receive the image source

  onImageLoad(event: any): void {
    const width = event.target.width;
    const height = event.target.height;

    const ratio = width / height;

    // Determine the class based on the ratio
    // Example: 'landscape' for width > height, 'portrait' for height > width, 'square' for equal
    if (ratio > 1) {
      this.imageClass = 'landscape';
    } else if (ratio < 1) {
      this.imageClass = 'portrait';
    } else {
      this.imageClass = 'square';
    }
  }
}
