<router-outlet></router-outlet>
<ng-container *ngrxLet="selectIsDeveloper$; let isDevTeam">
  <!--  &lt;!&ndash;  TERMINAL CONTAINER&ndash;&gt;-->
  <!--  <div-->
  <!--    *ngIf="isDevTeam"-->
  <!--    class="terminal-container"-->
  <!--    #terminal-->
  <!--    mwlResizable-->
  <!--    [enableGhostResize]="true"-->
  <!--    (resizing)="onResizeEnd($event)"-->
  <!--    fxLayout="column"-->
  <!--  >-->
  <!--    &lt;!&ndash;    HEADER&ndash;&gt;-->
  <!--    <div class="resize-handler-wrapper">-->
  <!--      <div fxLayout="row" fxLayoutAlign="space-between center" class="resize-handle-top" mwlResizeHandle-->
  <!--           [resizeEdges]="{ top: true }"-->
  <!--      >-->
  <!--        <span-->
  <!--        >_TERMINAL <span class="context">{{ selectContext$ | ngrxPush | json }}</span></span-->
  <!--        >-->
  <!--      </div>-->
  <!--      <mat-icon fxLayoutAlign="center center" class="expand-btn"-->
  <!--                (click)="resizeManually(isExpanded ? 15 : 300); $event.stopPropagation()"-->
  <!--      >{{ isExpanded ? 'expand_more' : 'expand_less' }}-->
  <!--      </mat-icon>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;  TERMINAL&ndash;&gt;-->
  <!--    <ui-terminal #terminalComponent></ui-terminal>-->
  <!--  </div>-->
</ng-container>
<!-- <app-notification *ngIf="showNotification" [messages]="notificationMessage" (close)="hideNotification()"></app-notification> -->

<!-- <div class="entity-grid">
  <ng-container *ngFor="let entityId of [1,2,3,4,5, 6, 7, 8, 9,10]">
    <app-notification *ngIf="showNotification" [isInGrid]="true" [messages]="notificationMessage"
      (close)="hideNotification()"></app-notification>
  </ng-container>
</div> -->