import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketBaseService {

  protected socket: Socket | undefined;

  constructor() {
  }

  protected isValid(): boolean {
    return !!this.socket && !!this.socket.connected;
  }

  protected async connect(url: string, token: string) {
    return new Promise((resolve, reject) => {
      this.socket = io(url, {
        auth: {
          authorization: token,
        },
        withCredentials: true,
      });
      this.socket.on('error', (error) => {
        this.onError();
        reject(error);
        console.error('Socket error:', error);
      });

      this.socket.on('connect', () => {
        this.onConnect();
        resolve(true);
        console.log('Connected to', url);
      });
    });
  }

  disconnect(): void {
    if (!!this.socket) {
      this.socket.disconnect();
    }
  }

  emit(eventName: string, message: any): void {
    if (!this.isValid()) {
      console.log('socket emit failed since connection failed to establish');
      return;
    }
    this.socket.emit(eventName, message);
  }

  consume<T>(eventName: string): Observable<T> {
    if (!this.isValid()) {
      console.log('socket consume failed since connection failed to establish');
      return of();
    }

    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  onConnectionError(): Observable<any> {

    return new Observable((subscriber) => {
      this.socket.on('connect_error', (data) => {

        setTimeout(() => {
          this.reconnect();
        }, 1000);

        subscriber.next(data);

      });
    });
  }

  protected onError(): void {
    // To be overridden by subclasses if needed
  }

  protected onConnect(): void {
    // To be overridden by subclasses if needed
  }

  protected reconnect(): void {
    // To be overridden by subclasses if needed
  }

}
