<div class="vehicle-icon" [class.small]="small"
     [matTooltip]="vehicle?.plate.toUpperCase()"
     matTooltipClass="sidebar-tooltip"
>
  <ui-preloader class="preloader" [diameter]="20" *ngIf="!imageLoaded && !imageError"
                [color]="PreloaderColor.gray"
  ></ui-preloader>
  <!--  <img class="vehicle-icon-img" [lazyLoad]="img" (onStateChange)="imgStateChange($event)"/>-->
  <ui-snapshot class="vehicle-icon-img" [interval]="1000" [src]="img" (load)="load($event)"></ui-snapshot>
  <ng-container *ngIf="allowDelete">
    <div
      fxLayoutAlign="center center"
      class="vehicle-icon-action delete icon-16"
      [class.small]="small"
      [class.icon-12]="small"
      (click)="delete.emit()"
      matTooltipClass="sidebar-tooltip"
      [matTooltip]="vehicle.plate ? vehicle.plate + ' is not shown here, remove' : 'Not shown here, remove'"
    >
      <mat-icon>close</mat-icon>
    </div>
  </ng-container>

  <div
    fxLayoutAlign="center center"
    class="vehicle-icon-action add icon-16"
    [class.small]="small"
    [class.icon-12]="small"
    (click)="add.emit()"
  >
    <mat-icon>add</mat-icon>
  </div>
  <mat-menu #addMenu="matMenu" yPosition="below" xPosition="after">
    <button
      mat-menu-item
      (click)="add.emit()"
    >
      New
    </button>
    <button
      mat-menu-item
      (click)="addExisting.emit()"
    >
      Add to existing
    </button>
  </mat-menu>

</div>
