<div class="flex-col start-start flex-fill" class="dialog">
  <div class="flex-row space-between-center">
    <div class="flex-row gap-8 start-center">
      <span class="ui-icon-status-online"></span>
      <div>{{ data.camera.name }}</div>
    </div>
    <div class="flex-row center-center c-p icon-18 " mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-content narrower-input flex-col start-start gap-16 c-100"
  >

    <app-wall-camera #wallCamera
                     [allowQuality]="true"
                     [camera]="data.camera"
                     [showPlaceholder]="false"
    ></app-wall-camera>
  </div>
  <div></div>
</div>
