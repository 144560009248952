import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-granted-access-password',
  templateUrl: './granted-access-password.component.html',
  styleUrls: ['./granted-access-password.component.scss'],
})
export class GrantedAccessPasswordComponent implements OnInit {

  public inputStyle = UIInputStyle;
  public password: number = null;

  constructor(private matDialogRef: MatDialogRef<GrantedAccessPasswordComponent>) {
  }

  ngOnInit(): void {
  }

  public ok() {
    this.matDialogRef.close(this.password);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }

}
