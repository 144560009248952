import { AfterViewInit, Directive, EventEmitter, HostListener, Output } from '@angular/core';

export interface VisibilityChanged {
  hidden: boolean;
}

@Directive({
  selector: '[appVisibilityChange]',
})
export class VisibilityChangeDirective implements AfterViewInit {
  @Output() visibilityChanged = new EventEmitter<VisibilityChanged>();
  @Output() test = new EventEmitter<boolean>();

  constructor() {
  }

  @HostListener('window:visibilitychange', ['$event'])
  visibilityange() {
    this.visibilityChanged.emit({ hidden: !!document.hidden });
  }

  public ngAfterViewInit() {
    this.test.emit(true);
  }
}
