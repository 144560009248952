import { createReducer, on } from '@ngrx/store';
import * as VehiclesActions from '@states/vehicles/vehicles.actions';
import { VehicleModels } from '@models/vehicle.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface VehiclesState extends EntityState<VehicleModels.Vehicle> {
  unsaved: VehicleModels.Vehicle[];
}

export const adapter: EntityAdapter<VehicleModels.Vehicle> = createEntityAdapter<VehicleModels.Vehicle>({
  selectId: (vehicle: VehicleModels.Vehicle) => vehicle.plate,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const testState: VehiclesState = adapter.getInitialState({
  ids: ['PB4WEGO', 'PB4WEG1', 'PB4WEG2'],
  entities: {
    PB4WEGO: {
      'plate': 'PB4WEGO',
      'owner': 'Ross Geller',
      'make': 'Toyota',
      'lastSeen': 1704112654748,
    },
    PB4WEG1: {
      'plate': 'ABCDE2A',
      'owner': 'Chandler Bing',
      'make': 'Ford',
      'lastSeen': 1703112654748,
    },
    PB4WEG2: {
      'plate': 'L33T3R',
      'owner': 'Rachel Green',
      'make': 'Suzuki',
      'lastSeen': 1702112654748,
    },
  },
  unsaved: [],
});

const initialState: VehiclesState = adapter.getInitialState({ unsaved: [] });
export const vehiclesStateReducer = createReducer(
  initialState,
  on(VehiclesActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  // on(VehiclesActions.resetUnsaved, state => {
  //   const unsaved: string[] = Object.values(state.entities)
  //     .filter((vehicle: VehicleModels.Vehicle) => !vehicle.isSaved)
  //     .map((vehicle: VehicleModels.Vehicle) => vehicle.plate);
  //   return adapter.removeMany(unsaved, state);
  // }),
  on(VehiclesActions.setVehicles, (state, { vehicles }) => {
    return {
      ...state,
    };
  }),
  on(VehiclesActions.getVehiclesSuccess, (state, { vehicles }) => {
    return adapter.setMany(vehicles, state);
  }),
  on(VehiclesActions.getUnsavedVehiclesSuccess, (state, { vehicles }) => {
    return {
      ...state,
      unsaved: vehicles,
    };
  }),
  on(VehiclesActions.addVehicleSuccess, (state, { vehicle }) => {
    const update: VehicleModels.Vehicle = {
      ...vehicle,
    };
    return adapter.setOne(update, state);
  }),
  on(VehiclesActions.removeUnsavedVehicle, (state, { vehicle }) => {
    return {
      ...state,
      unsaved: [...state.unsaved].filter(p => p.plate !== vehicle.plate),
    };
  }),
  on(VehiclesActions.editVehicle, (state, { vehicle }) => {
    const update: VehicleModels.Vehicle = {
      ...vehicle,
    };
    return adapter.setOne(update, state);
  }),
  on(VehiclesActions.removeVehicleSuccess, (state, { vehicle }) => {
    return adapter.removeOne(vehicle.plate, state);
  }),
);
