import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Canvas, Circle, Line, SelectionColor, SelectionColors } from '@models/canvas-selector.model';
import Point = Canvas.Point;
import { Zones } from './ui-zone-selector.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'ui-zone-selector',
  templateUrl: './ui-zone-selector.component.html',
  styleUrls: ['./ui-zone-selector.component.scss'],
})
export class UiZoneSelectorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Output()
  update: EventEmitter<{ zones: Zones; markedIdx: number[] }> = new EventEmitter<{ zones: Zones; markedIdx: number[] }>();

  height: number;
  width: number;

  @Input('value')
  zones: Zones = {};

  @Input() exclude = false;

  @Input() mask = true;

  markedIdx: number[];

  /**
   * Canvas related variables
   */
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('destCanvas', { static: true })
  destCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('zoneCanvas', { static: true })
  zoneCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('pointCanvas', { static: true })
  pointCanvas: ElementRef<HTMLCanvasElement>;

  private pointCtx: CanvasRenderingContext2D;
  private ctx: CanvasRenderingContext2D;
  private destCtx: CanvasRenderingContext2D;
  private zoneCtx: CanvasRenderingContext2D;

  mousePos: Point;
  draw: false;
  currentSelectionStart: Point | undefined;
  currentSelection = new Set<Point>();

  selectionColorIdx = 0;
  selectionColor = SelectionColors[0];

  public sensitivity = 50;

  parent: HTMLDivElement;

  observer: ResizeObserver;

  patternImg: HTMLImageElement;
  pattern: CanvasPattern;

  constructor(
    private elementRef: ElementRef) {
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.parent);
    this.observer.disconnect();
  }

  ngOnInit(): void {
    this.pointCtx = this.pointCanvas.nativeElement.getContext('2d')!;
    this.ctx = this.canvas.nativeElement.getContext('2d')!;
    this.destCtx = this.destCanvas.nativeElement.getContext('2d')!;
    this.zoneCtx = this.zoneCanvas.nativeElement.getContext('2d')!;
    this.patternImg = new Image();
    this.patternImg.src = 'assets/area-exclude-pattern.png';
    this.patternImg.onload = () => {
      this.pattern = this.pointCtx?.createPattern(this.patternImg, 'repeat');
    };
  }

  initZones() {
    this.resize();
    this.updateMarkedIdx();
    this.emitUpdate();
  }

  ngAfterViewInit(): void {
    if (!this.zones) {
      this.zones = {};
    }
    this.parent = this.elementRef.nativeElement.parentElement;
    if (Object.entries(this.zones).length > 0) {
      setTimeout(() => this.initZones(), 1000);
    }

    this.observer = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        this.resize();
      });
    });
    this.observer.observe(this.parent);
  }

  resize() {
    this.width = this.parent.clientWidth;
    this.height = this.parent.clientHeight;
    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;
    this.pointCanvas.nativeElement.width = this.width;
    this.pointCanvas.nativeElement.height = this.height;
    this.zoneCanvas.nativeElement.width = this.width;
    this.zoneCanvas.nativeElement.height = this.height;
    this.currentSelection = new Set<Point>();
    this.currentSelectionStart = undefined;
    this.redraw();
  }

  relative(mouseEvent: MouseEvent) {
    const x = mouseEvent.offsetX;
    const y = mouseEvent.offsetY;
    this.mousePos = { x, y };
  }

  checkClose(point: Point): boolean {
    const CLOSE_RADIUS = 20;
    const start = this.currentSelectionStart!;
    if (start === point) {
      return false;
    }
    if (
      Math.abs(start.x * this.width - point.x * this.width) < CLOSE_RADIUS &&
      Math.abs(start.y * this.height - point.y * this.height) < CLOSE_RADIUS
    ) {
      this.drawArea(Array.from(this.currentSelection));
      return true;
    }
    return false;
  }

  drawPoint(point?: Point, color?: SelectionColor) {
    this.pointCtx.fillStyle = color ? color.line : this.selectionColor.line; //'#00FF19';
    const circle = new Circle(this.pointCtx);
    circle.draw(point ? point.x * this.width : this.mousePos.x, point ? point.y * this.height : this.mousePos.y);
  }

  line(x1: number, y1: number, x2: number, y2: number, color?: SelectionColor) {
    const line = new Line(this.pointCtx);
    this.pointCtx.strokeStyle = color ? color.line : this.selectionColor.line;
    line.draw(x1, y1, x2, y2);
  }

  drawLine(last: boolean = false) {
    const posX = last ? this.currentSelectionStart!.x * this.width : this.mousePos.x;
    const posY = last ? this.currentSelectionStart!.y * this.height : this.mousePos.y;
    const lastPoint = Array.from(this.currentSelection)
      .pop()!;
    this.line(posX, posY, lastPoint.x * this.width, lastPoint.y * this.height);
  }

  drawArea(points: Point[], color?: SelectionColor, clear = false) {
    this.pointCtx.beginPath();
    // this.ctx.fillStyle = 'rgba(0, 255, 25, 1)';
    this.pointCtx.fillStyle = color ? color.area : this.selectionColor.area;
    this.pointCtx.moveTo(points[0].x * this.width, points[0].y * this.height);
    for(let point of points) {
      this.pointCtx.lineTo(point.x * this.width, point.y * this.height);
    }
    this.pointCtx.fill();
    if (this.exclude) {
      this.pointCtx.fillStyle = this.pattern;
      this.pointCtx.fill();
    }

    this.pointCtx.closePath();
  }

  public clear() {
    this.zones = {};
    this.currentSelection = new Set<Point>();
    this.resize();
    this.markedIdx = [];
    this.emitUpdate();
  }

  insertSelection() {
    const key = JSON.stringify(this.currentSelectionStart);
    this.zones[key] = {
      name: '',
      color: this.selectionColor.name,
      selection: Array.from(this.currentSelection),
      markedIdx: this.calculateZoneMarkedIdx(Array.from(this.currentSelection)),
    };
    this.emitUpdate();
    this.currentSelection.clear();
    this.currentSelectionStart = undefined;
  }

  updateColor() {
    this.selectionColorIdx++;
    if (this.selectionColorIdx === SelectionColors.length) {
      this.selectionColorIdx = 0;
    }
    this.selectionColor = SelectionColors[this.selectionColorIdx];
  }

  addPoint() {
    if (this.mask) {
      return;
    }
    const point: Point = { x: this.mousePos.x / this.width, y: this.mousePos.y / this.height };
    if (this.currentSelection.size === 0) {
      this.currentSelectionStart = point;
    }
    if (!this.checkClose(point)) {
      this.drawPoint();
      if (this.currentSelection.size !== 0) {
        this.drawLine();
      }
      this.currentSelection.add(point);
    } else {
      this.drawLine(true);
      this.insertSelection();
      this.updateColor();
      if (this.exclude) {
        this.redraw();
      }
    }
  }

  resetColor() {
    this.selectionColor = SelectionColors[0];
    this.selectionColorIdx = 0;
  }

  clearCanvas() {
    const pointCanvas = this.pointCanvas.nativeElement;
    const canvas = this.canvas.nativeElement;
    const destCanvas = this.destCanvas.nativeElement;
    this.pointCtx?.clearRect(0, 0, pointCanvas.width, pointCanvas.height);
    this.destCtx?.clearRect(0, 0, destCanvas.width, destCanvas.height);
    this.ctx?.clearRect(0, 0, canvas.width, canvas.height);
  }

  isCCW(points: Point[]) {
    let sum = 0;
    for(let i = 0; i < points.length; i++) {
      let p1 = points[i];
      let p2 = points[(i + 1) % points.length];
      sum += (p2.x - p1.x) * (p2.y + p1.y);
    }
    return sum > 0;
  }

  drawExclude() {
    const zones = _.cloneDeep(this.zones);
    for(let [key, zone] of Object.entries(zones)) {
      const points = zone.selection;
      if (!this.isCCW(points)) {
        points.reverse();
      }
    }

    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.beginPath();
    this.ctx.rect(0, 0, width, height);
    for(let [key, zone] of Object.entries(zones)) {
      const points = zone.selection;
      for(let [index, point] of points.entries()) {
        if (index === 0) {
          this.ctx.moveTo(point.x * width, point.y * height);
        } else {
          this.ctx.lineTo(point.x * width, point.y * height);
        }
      }
      this.ctx.clip();
      this.updateColor();
    }

    this.ctx.fillStyle = SelectionColors[0].area; // Exclude will be green
    this.ctx.fillRect(0, 0, width, height);
    this.ctx.restore();
  }

  redraw() {
    this.clearCanvas();
    this.resetColor();
    if (this.exclude) {
      this.drawExclude();
      if (!this.currentSelectionStart) {

        return;
      }
    }
    let color = this.selectionColor;
    for(let [key, zone] of Object.entries(this.zones)) {
      const selection = zone.selection;
      for(let pointIdx in selection) {
        if (+pointIdx === 0) {
          if (!!this.zones[key]) {
            color = SelectionColors.find(c => c.name === this.zones[key].color);
          }
        }
        this.drawPoint(selection[pointIdx], color);
        if (+pointIdx !== 0) {
          const c = selection[pointIdx];
          const p = selection[+pointIdx - 1];
          this.line(c.x * this.width, c.y * this.height, p.x * this.width, p.y * this.height, color);
        }
        if (+pointIdx === selection.length - 1) {
          const c = selection[pointIdx];
          const p = selection[0];
          this.line(c.x * this.width, c.y * this.height, p.x * this.width, p.y * this.height, color);
          this.drawArea(selection, color);
        }
      }
      this.updateColor();
    }

  }

  emitUpdate(updateMarkedIdx = true) {
    if (updateMarkedIdx) {
      this.updateMarkedIdx();
    }
    this.update.emit({
      zones: this.zones,
      markedIdx: this.markedIdx ?? [],
    });
  }

  public deleteZone(key: string) {
    delete this.zones[key];
    this.resize();
    this.updateMarkedIdx();
    this.emitUpdate();
  }

  public renameZone(key: string, event: Event) {
    const name = (event.target as HTMLInputElement).value;
    this.zones[key].name = name;
    this.emitUpdate();
  }

  public colorZone(key: string, event: MatSelectChange) {
    const color = event.value;
    this.zones[key].color = color;
    this.redraw();
    this.emitUpdate();
  }

  public getSelectionColorByName(color: string): SelectionColor {
    return SelectionColors.find(c => c.name === color);
  }

  drawZoneArea(points: Point[]) {
    const canvas = this.zoneCanvas.nativeElement;
    // Clear zone canvas
    this.zoneCtx.clearRect(0, 0, canvas.width, canvas.height);
    //
    this.zoneCtx.beginPath();
    this.zoneCtx.fillStyle = '#000000';
    this.zoneCtx.moveTo(points[0].x * this.width, points[0].y * this.height);
    for(let point of points) {
      this.zoneCtx.lineTo(point.x * this.width, point.y * this.height);
    }
    this.zoneCtx.fill();
    this.zoneCtx.closePath();
  }

  calculateZoneMarkedIdx(selection: Point[]) {
    this.drawZoneArea(selection);
    return this.calculateMarkedIdx(this.zoneCanvas);
  }

  calculateInverseMarkedIdx(markedIdx: number[]): number[] {
    const inverse = [];
    for(let i = 0; i < 1024; i++) {
      if (!markedIdx?.includes(i)) {
        inverse.push(i);
      }
    }
    return inverse;
  }

  calculateMarkedIdx(srcCanvas?: ElementRef<HTMLCanvasElement>): number[] {
    const canvas = srcCanvas ? srcCanvas.nativeElement : this.pointCanvas.nativeElement;
    if (!!canvas) {
      if (canvas.width === 0 || canvas.height === 0) {
        return [];
      }
    } else {
      return [];
    }
    const destCanvas = this.destCanvas.nativeElement;

    const destCtx = destCanvas.getContext('2d')!;
    this.destCtx?.clearRect(0, 0, destCanvas.width, destCanvas.height);
    const origData = this.pointCtx?.getImageData(0, 0, canvas.width, canvas.height);

    destCtx.drawImage(canvas, 0, 0, 32, 32);
    const imgData = destCtx.getImageData(0, 0, 32, 32);
    const data = imgData.data;

    const flat: number[] = Array(1024)
      .fill(0);
    const markedIdx: Set<number> = new Set<number>();
    /// RGBA (4 cells) * 1024 = 4096 cells in imgData

    for(let i = 0; i < 4096; i++) {
      if (data[i] > 0) {
        const index = Math.floor(i / 4);
        markedIdx.add(index);
        flat[index] = 1;
      }
    }
    return markedIdx.size !== 0 ? Array.from(markedIdx) : undefined;
  }

  updateMarkedIdx(srcCanvas?: ElementRef<HTMLCanvasElement>) {
    const markedIdx = this.calculateMarkedIdx();
    this.markedIdx = this.exclude ? this.calculateInverseMarkedIdx(markedIdx) : markedIdx;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['exclude']) {
      if (!this.mask && this.pointCtx) {
        if (this.exclude) {
          this.updateMarkedIdx();
        }
        this.redraw();
        this.emitUpdate(!this.exclude);
      }
    }
    if (!!this.parent) {
      this.resize();
    }
  }
}
