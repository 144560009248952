import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatsState } from '@states/stats/stats.reducer';

export const selectStatsState = createFeatureSelector<StatsState>('statsState');

export const selectCameraUptime = createSelector(selectStatsState, ({ cameraUptime }: StatsState) => cameraUptime);

export const selectCameraUptimeOnlinePercentage = createSelector(selectStatsState, ({ cameraUptime }: StatsState) => {
  const initialValue = 0;
  const total = cameraUptime?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  if (!total) {
    return 0;
  }
  return Math.floor(total / cameraUptime.length);
});

export const selectAnalyticUptime = createSelector(selectStatsState, ({ analyticUptime }: StatsState) => analyticUptime);

export const selectAnalyticUptimeOnlinePercentage = createSelector(selectStatsState, ({ analyticUptime }: StatsState) => {
  const initialValue = 0;
  const total = analyticUptime?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  if (!total) {
    return 0;
  }
  return Math.floor(total / analyticUptime.length);
});

export const selectEdgeUptime = createSelector(selectStatsState, ({ edgeUptime }: StatsState) => edgeUptime);
export const selectEdgeUptimeAll = createSelector(selectStatsState, ({ edgeUptimeAll }: StatsState) => edgeUptimeAll);

export const selectEdgeUptimeOnlinePercentage = createSelector(selectStatsState, ({ edgeUptimeAll }: StatsState) => {
  if (!edgeUptimeAll) {
    return 0;
  }
  const initialValue = 0;
  const total = edgeUptimeAll[0]?.stats?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  if (!total) {
    return 0;
  }
  return Math.floor(total / edgeUptimeAll[0]?.stats?.length);
});
