<video [class.isCoverMode]="!isRespectRatio"
       [class.isDefault]="isRespectRatio"
       appVisibilityChange
       (visibilityChanged)="checkHiddenDocument($event)"
       #player
       controls
       crossorigin
       playsinline
       muted="muted"
       id="player"
       (timeupdate)="timeUpdate()"
></video>
