import { createAction, props } from '@ngrx/store';
import { StorageModel } from '@models/storage.model';

export const resetStorageCache = createAction('[STORAGE] Reset Storage Cache');

export const setStorageCache = createAction(
  '[STORAGE] Set Storage Cache',
  props<{ storage: StorageModel.StorageStatsOfDay[] | StorageModel.StorageOldest[] }>(),
);

export const updateStorageCacheByOldest = createAction(
  '[STORAGE] Update Storage Cache By Oldest',
  props<{ storage: StorageModel.StorageOldest[] }>(),
);

export const setOfflineStorageStats = createAction(
  '[STORAGE] Set Offline Storage Stats',
  props<{ offlineStorage: number[][] }>(),
);

export const setSmartStorageStats = createAction(
  '[STORAGE] Set Smart Storage Stats',
  props<{ smartStorage: number[][] }>(),
);

export const getCamerasStorageStats = createAction(
  '[STORAGE] Get Cameras Storage Stats',
  props<{ request: StorageModel.GetStorageStatsRequest }>(),
);

export const getCamerasHlsStorageStats = createAction(
  '[STORAGE] Get Cameras HLS Storage Stats',
  props<{ request: StorageModel.GetStorageStatsRequest }>(),
);

export const getCamerasStorageStatsAbly = createAction(
  '[STORAGE] Get Cameras Storage Stats Ably',
  props<{ request: StorageModel.GetStorageStatsRequest }>(),
);

export const getCamerasStorageStatsSuccess = createAction(
  '[STORAGE] Get Cameras Storage Stats Success',
  props<{ result: StorageModel.StorageStatsTimeRangeResponse }>(),
);

export const getCamerasHlsStorageStatsSuccess = createAction(
  '[STORAGE] Get Cameras HLS Storage Stats Success',
  props<{ result: StorageModel.StorageStatsTimeRangeResponse }>(),
);

export const getCamersOldestVids = createAction(
  '[STORAGE] Get Cameras Oldest Vids',
  props<{ request: StorageModel.GetStorageStatsRequest }>(),
);

export const getCamerasOldestVidsSuccess = createAction(
  '[STORAGE] Get Cameras Oldest Vids Success',
  props<{ result: StorageModel.StorageStatsTimeRangeResponse }>(),
);
