import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel } from '../locations/location.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';

const Working = [PulsationModels.ComponentStatus.Online];

export enum EdgeStatusType {
  Working,
  NotWorking,
  Loading,
}

@Pipe({
  name: 'locationEdgeStatusTotal',
})
export class LocationEdgeStatusTotalPipe implements PipeTransform {
  constructor() {
  }

  transform(value: LocationModel.LocationItem, allEdges: { timestamp: number; value: Dictionary<PulsationModels.Store.EdgePulsation> }, type: EdgeStatusType) {
    let count = 0;
    const edgesList = allEdges.value;

    Object.values(value.edges)
      .forEach(edge => {
        const selectedEdge = edgesList[edge.edgeId];

        if (Object.values(allEdges)?.length && selectedEdge) {
          const statusValue = selectedEdge.status;
          if (type === EdgeStatusType.Working) {
            if (Working.includes(statusValue)
              // && allEdges?.timestamp && allEdges?.timestamp - Number(selectedEdge?.timestamp) < 360000
            ) {
              count++;
            }
          } else if (type === EdgeStatusType.NotWorking) {
            if (!Working.includes(statusValue)
              // || (allEdges?.timestamp && allEdges?.timestamp - Number(selectedEdge?.timestamp) > 360000)
            ) {
              count++;
            }
          }
        }
      });
    return count;
  }
}
