import { doc, docSnapshots, DocumentData, DocumentReference, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Notification, UserNotification } from '@models/notification.model';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';


@Injectable({
  providedIn: 'root',
})

export class NotificationService {

  constructor(private firestore: Firestore,
              private http: HttpClient) {
  }

  public getNotificationsByUser(orgId: string, userId: string): Observable<{ notifications: UserNotification[] }> {
    const document: DocumentReference = doc(this.firestore, `/user-notifications/${orgId}-${userId}`);

    return docSnapshots(document)
      .pipe(
        map((doc: DocumentSnapshot<DocumentData>) => {
          const data = doc.data() as { notifications: UserNotification[] } | undefined;
          return data;
        }),
      );
  }

  public getNotificationById(notificationId: string): Observable<Notification> {
    const document: DocumentReference = doc(this.firestore, `/notifications/${notificationId}`);

    return docSnapshots(document)
      .pipe(
        map((doc: DocumentSnapshot<DocumentData>) => {
          const data = doc.data() as Notification | undefined;
          return data;
        }),
        // take(1),
      );
  }

  public seenNotificationById(notificationId: string): Observable<any> {
    return this.http.patch(api.notification.seen(notificationId), {});
  }

  public deleteNotificationById(notificationId: string): Observable<any> {
    return this.http.delete(api.notification.delete(notificationId), {});
  }

  public deleteAllNotifications(): Observable<any> {
    return this.http.delete(api.notification.deleteAll, {});
  }

  public schedule(data: {
    notificationId: string;
    timeZone: string;
    schedule: string;
  }): Observable<any> {
    return this.http.post(api.notification.schedule, data);
  }

  public subscribeToWebPushNotifications(sub: PushSubscription) {
    return this.http.post(api.device.registerWeb, sub);
  }
}
