import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';

@Component({
  selector: 'app-change-date-format-modal',
  templateUrl: './change-date-format-modal.component.html',
  styleUrls: ['./change-date-format-modal.component.scss'],
})
export class ChangeDateFormatModalComponent implements OnInit {
  public inputStyle = UIInputStyle;

  constructor() {}

  ngOnInit(): void {}
}
