import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[matSelectStyle]'
})
export class MatSelectStyleDirective implements OnChanges {
  @Input() dynamicOverlayClass: string | string[];

  constructor(private matSelect: MatSelect) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dynamicOverlayClass']) {
      this.applyOverlayClass();
    }
  }

  private applyOverlayClass(): void {
    if (this.matSelect) {
      // Workaround to access the internal property. Use with caution.
      const selectComponent: any = this.matSelect;
      if ('_overlayPanelClass' in selectComponent) {
        selectComponent._overlayPanelClass = this.dynamicOverlayClass;
      } else {
        // Handle cases where the internal property might not exist
        console.warn('Unable to find _overlayPanelClass in MatSelect');
      }
      // Note: You might still need to manage panel re-opening or other actions to ensure the class is applied.
    }
  }
}
