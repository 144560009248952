import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as Plyr from 'plyr';
import Hls, { ErrorData, HlsConfig } from 'hls.js';
import { FadeInAnimation } from '../animations';

export interface MediaEvents {
  event: string;
  playing?: boolean;
  error?: boolean;
  message?: string;
}

@Component({
  selector: 'player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  animations: [FadeInAnimation],
})
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('player', { static: true })
  playerObj: ElementRef;

  @Output()
  mediaEvents: EventEmitter<MediaEvents> = new EventEmitter<MediaEvents>();

  @Input()
  playback = false;

  @Input()
  src;

  @Input()
  allowZoom = false;

  @Input()
  defaultRatio = true;

  @Output()
  error: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  timeUpdate: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  playing: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  autostart = true;

  player: Plyr;

  hls: Hls;

  restartCount = 0;

  stopped: false;
  // Initializing values
  public isPlaying = false;

  scale = 1;
  initX = 0;
  initY = 0;
  moveX = 0;
  moveY = 0;
  boundryX = 0;
  boundryY = 0;

  dragging = false;

  origHeight = 0;
  origWidth = 0;
  ratio = 0;

  @ViewChild('preview')
  previewWrapper: ElementRef;

  @ViewChild('previewCanvas')
  previewCanvas: ElementRef;

  ctx: CanvasRenderingContext2D;

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.scale > 1 && !!this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
  }

  public setSrc(src: string) {
    this.src = src;
  }

  public getPreviewCanvas() {
    return this.previewCanvas;
  }

  ngOnInit(): void {
  }

  public async play() {
    await this.playVid();
  }

  ngAfterViewInit(): void {
    this.initPlayer();

    if (this.autostart) {
      this.load();
    }

    const video = this.playerObj.nativeElement;

    video.onplaying = function() {
      this.isPlaying = true;
    };

    video.onpause = function() {
      this.isPlaying = false;
    };

    if (this.allowZoom) {
      this.ctx = this.previewCanvas?.nativeElement?.getContext('2d');
      video.addEventListener('play', () => {
        this.timerCallback();
      });
    }

    this.onResize();
  }

  public async playVid() {
    const video = this.playerObj.nativeElement;
    if (video.paused && !this.isPlaying) {
      return video.play();
    }
  }

  public pauseVid() {
    const video = this.playerObj.nativeElement;
    if (!video.paused && this.isPlaying) {
      video.pause();
    }
  }

  private setPlayerOptions() {
    const options: Plyr.Options = {
      hideControls: true,
      clickToPlay: false,
    };

    if (this.defaultRatio) {
      options.ratio = '16:9';
    }

    this.player = new Plyr(this.playerObj.nativeElement, options);

    // Remove player controls
    this.player.elements.controls.style.display = 'none';
  }

  private subscribeToPlayerEvents() {
    this.player.on('play', event => {
      const message = 'player emit play event';
      this.mediaEvents.emit({
        event: 'play',
        message,
      });
    });

    this.player.on('enterfullscreen', event => {
      const message = 'player emit enterfullscreen event';

      const elem = this.playerObj.nativeElement;
      this.renderer.setStyle(elem, 'transform', `scale(1)`);

      this.mediaEvents.emit({
        event: 'enterfullscreen',
        message,
      });
    });

    this.player.on('exitfullscreen', event => {
      const message = 'player emit exitfullscreen event';

      const elem = this.playerObj.nativeElement;
      this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);

      this.mediaEvents.emit({
        event: 'exitfullscreen',
        message,
      });
    });

    this.player.on('playing', event => {
      const message = 'player emit playing event';

      this.playing.emit('Player playing event');
      this.isPlaying = true;

      this.mediaEvents.emit({
        event: 'playing',
        playing: true,
        error: false,
        message,
      });
    });

    this.player.on('timeupdate', event => {
      // const message = `player timeupdate playing event. currentTime: ${this.player.currentTime}, update: ${event.timeStamp}`
      // if (this.player.currentTime < event.timeStamp) {
      //   this.isPlaying = true;
      //   this.playing.emit('Player timeupdate event')
      //   this.mediaEvents.emit({
      //     event: 'timeupdate',
      //     playing: true,
      //     error: false,
      //     message
      //   })
      // }
    });

    this.player.on('canplay', event => {
      const message = 'player emit canplay event';
      if (!this.player.playing) {
        this.player.play();
        this.play();
      }
    });

    this.player.on('canplaythrough', event => {
      const message = 'player emit canplaythrough event';
      if (!this.player.playing) {
        this.player.play();
      }
      this.mediaEvents.emit({
        event: 'canplaythrough',
        message,
      });
    });

    this.player.on('waiting', event => {
      const message = 'player emit waiting event';

      this.mediaEvents.emit({
        event: 'waiting',
        message,
      });
    });

    this.player.on('stalled', event => {
      const message = 'player emit waiting event';

      this.mediaEvents.emit({
        event: 'stalled',
        message,
      });
    });
  }

  private unsubscribeFromPlayerEvents() {
    this.player.off('play', e => {
      console.log('removed listener for player play event');
    });
    this.player.off('enterfullscreen', e => {
      console.log('removed listener for player enterfullscreen event');
    });
    this.player.off('exitfullscreen', e => {
      console.log('removed listener for player exitfullscreen event');
    });
    this.player.off('playing', e => {
      console.log('removed listener for player playing event');
    });
    this.player.off('canplay', e => {
      console.log('removed listener for player canplay event');
    });
    this.player.off('canplaythrough', e => {
      console.log('removed listener for player canplaythrough event');
    });
    this.player.off('waiting', e => {
      console.log('removed listener for player waiting event');
    });
    this.player.off('stalled', e => {
      console.log('removed listener for player stalled event');
    });
    this.player.off('error', e => {
      console.log('removed listener for player error event');
    });
    this.player.off('timeupdate', e => {
      console.log('removed listener for player timeupdate event');
    });
  }

  initPlayer() {
    this.setPlayerOptions();
    this.subscribeToPlayerEvents();
  }

  public stopPlayer() {
    if (!!this.player) {
      this.unsubscribeFromPlayerEvents();
      this.player.stop();
      this.player.destroy();
    }
  }

  ngOnDestroy(): void {
    this.stopHls();
    this.stopPlayer();
  }

  public stop() {
    this.player.stop();
    this.player.off('error', this.emitError);
    this.pauseVid();
  }

  public destroyHls() {
    this.player.stop();
    this.hls?.destroy();
  }

  async playerPause() {
    await this.player.pause();
  }

  async playerPlay() {
    await this.player.play();
    await this.playVid();
  }

  public restart() {
    const video = this.playerObj.nativeElement;
    if (!!this.hls) {
      this.hls.recoverMediaError();
    } else {
      const config: Partial<HlsConfig> = this.playback
        ? {}
        : {
          liveDurationInfinity: true,
        };

      this.hls = new Hls(config);
      this.hls.loadSource(this.src);
      this.hls.attachMedia(video);
    }
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      this.player.restart();
      this.player.on('error', this.emitError);
    });
  }

  emitError(error) {
    this.error?.emit();
  }

  public resetTime() {
  }

  public async load() {
    if (this.hls) {
      this.hls.destroy();
    }
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    this.pauseVid();
    this.stop();
    try {
      if (Hls.isSupported() && !!this.src) {
        const config = this.playback
          ? {}
          : {
            lowLatencyMode: true,
            liveSyncDurationCount: 0,
            liveMaxLatencyDurationCount: 1,
            initialLiveManifestSize: 1,
            backBufferLength: 30,
          };
        this.hls = new Hls(config);
        try {
          this.hls.loadSource(this.src);
        } catch (e) {
        }
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, async () => {
          await this.playVid();
          this.player.on('error', this.emitError);
          this.player.on('timeupdate', event => {
            this.timeUpdate.emit(this.player.currentTime);
          });
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.src;
        video.addEventListener('loadedmetadata', async () => {
        });
      }
    } catch (e) {
    }
  }

  public stopHls() {
    if (!!this.hls) {
      this.hls.off(Hls.Events.ERROR);
      this.hls.off(Hls.Events.MEDIA_ATTACHED);
      this.hls.stopLoad();
      this.hls.destroy();
      this.hls = null;
    }

    if (!this.player?.paused) {
      this.player.pause();
    }
  }

  public async reload() {
    const video: HTMLVideoElement = this.playerObj.nativeElement;

    try {
      if (Hls.isSupported() && !!this.src) {
        try {
          if (!!this.hls) {
            this.stopHls();
          }

          const config = this.playback
            ? {
              liveDurationInfinity: true,
            }
            : {
              enableWorker: true,
              lowLatencyMode: true,
              liveSyncDurationCount: 2,
              liveMaxLatencyDurationCount: 3,
              initialLiveManifestSize: 1,
              backBufferLength: 0,
              highBufferWatchdogPeriod: 1,
              liveDurationInfinity: true,
            };

          this.hls = new Hls(config);

          this.hls.attachMedia(video);

          this.hls.loadSource(this.src);
        } catch (e) {
        }

        this.handleHlsErrors();
        this.handleManifestParsed();
        this.handleMediaAttached();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.src;
        video.addEventListener('loadedmetadata', async () => {
        });
      }
    } catch (e) {
    }
  }

  handleMediaAttached() {
    this.hls.on(Hls.Events.MEDIA_ATTACHED, async () => {
    });
  }

  handleManifestParsed() {
    this.hls.on(Hls.Events.MANIFEST_PARSED, async (event, data) => {
      if (!!this.player) {
        this.player.muted = true;
        if (this.playback) {
          this.player.on('timeupdate', event => {
            this.timeUpdate.emit(this.player.currentTime);
          });
        }
      }

      if (this.player?.paused) {
        this.player.play();
      }
    });
  }

  handleHlsErrors() {
    this.hls.on(Hls.Events.ERROR, async (event, data: ErrorData) => {
      let errorType = data.type;
      let errorDetails = data.details;
      let errorFatal = data.fatal;

      if (errorFatal) {
        console.log('fatal error encountered - attempting to manually recover');
        switch (errorType) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.log('fatal network error encountered, attempting to manually recover');
            this.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, attempting to manually recover');
            this.hls.recoverMediaError();
            break;
          default:
            console.log('fatal media error encountered and could not be recovered. destroying hls');
            this.stopHls();
            break;
        }
      } else {
        if (errorDetails === 'bufferNudgeOnStall') {
          this.mediaEvents.emit({ event: 'bufferNudgeOnStall' });
        }
        if (errorDetails === 'bufferStalledError') {
          this.mediaEvents.emit({ event: 'bufferStalledError' });
        }
        if (errorDetails === 'bufferAppendError') {
          this.mediaEvents.emit({ event: 'bufferAppendError' });
        }
      }
    });
  }

  calcBoundaries() {
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];
    const ratio = elem.videoWidth / elem.videoHeight;
    const height = this.origHeight * this.scale;
    const width = this.origWidth * this.scale;
    this.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.scale : 0;
    this.boundryY = (height - elem.clientHeight) / 2 / this.scale;
  }

  initZoom() {
    const elem = this.playerObj.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }

  zoomIn() {
    this.zoom({ deltaY: -1 });
  }

  zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  zoom(event) {
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj.nativeElement;
    if (event.deltaY < 0) {
      if (this.scale < 5) {
        this.scale += 0.2;
      } else {
        this.scale = 5;
      }
      this.onResize();
      this.cd.detectChanges();
    } else {
      if (this.scale >= 1.2) {
        this.scale -= 0.2;
      } else {
        this.scale = 1;
      }
    }
    if (this.scale === 1) {
      this.moveX = 0;
      this.moveY = 0;
      this.boundryX = 0;
      this.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
    if (this.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.moveX);
    const moveYDir = Math.sign(this.moveY);
    this.moveX = Math.abs(this.moveX) > this.boundryX ? this.boundryX * moveXDir : this.moveX;
    this.moveY = Math.abs(this.moveY) > this.boundryY ? this.boundryY * moveYDir : this.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
  }

  dragStart(event: MouseEvent) {
    this.dragging = true;
    this.initX = event.clientX - this.moveX;
    this.initY = event.clientY - this.moveY;
  }

  dragEnd() {
  }

  drag(event: MouseEvent) {
    if (this.scale === 1 || !this.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    const height = rect.height;
    const width = rect.height * ratio;
    this.moveX += Math.abs(this.moveX + event.movementX) >= this.boundryX ? 0 : event.movementX;
    this.moveY += Math.abs(this.moveY + event.movementY) >= this.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
  }

  public maximize() {
    this.player.fullscreen.enter();
  }

  timerCallback() {
    if (this.playerObj.nativeElement.paused || this.playerObj.nativeElement.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 0);
  }

  computeFrame() {
    this.ctx.drawImage(this.playerObj.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
  }

  public inZoom() {
    return this.scale > 1;
  }
}
