import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-structure-modal',
  templateUrl: './view-structure-modal.component.html',
  styleUrls: ['./view-structure-modal.component.scss'],
})
export class ViewStructureModalComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any) {
  }

  ngOnInit(): void {
  }


  public toString() {
    return JSON.stringify(this.data);
  }
}
