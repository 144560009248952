import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { HttpService } from '../core/http.service';
import { CustomEventModel } from '@models/custom-event.model';

@Injectable()
export class CustomEventsService {
  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  public getCustomEvents(): Observable<CustomEventModel.GetCustomEventsResponse> {
    return this.http.get<CustomEventModel.GetCustomEventsResponse>(api.customEvents.crud);
  }

  public addCustomEvent(customEvent: CustomEventModel.CustomEvent) {
    const request: CustomEventModel.AddCustomEventRequest = {
      ...customEvent,
    };
    return this.http.put(api.customEvents.add, request);
  }

  public editCustomEvent(customEvent: CustomEventModel.CustomEvent) {
    const request: CustomEventModel.AddCustomEventRequest = {
      ...customEvent,
    };
    return this.http.patch(api.customEvents.edit, request);
  }

  public removeCustomEvent(customEvent: CustomEventModel.CustomEvent) {
    return this.http.delete(api.customEvents.remove(customEvent._id));
  }

}
