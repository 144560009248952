import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LocationActions } from './location.action-types';
import { LocationModel } from '../../../locations/location.model';
import { StringOrEmpty } from '../../../app.model';
import { Update } from '@ngrx/entity/src/models';
import { Edge } from '../../../edge/edge.model';
import * as _ from 'lodash';
import ViewType = LocationModel.ViewType;
import { CameraActions } from '@states/camera/camera.action-types';
import { EdgeCamera } from '../../../cameras/camera.model';
import { PulsationModels } from '@models/pulsation.model';

export interface LocationState extends EntityState<LocationModel.LocationItem> {
  isFirstLocationLoaded: boolean;
  isAllLocationLoaded: boolean;
  isPagination: boolean;
  error: StringOrEmpty;
  edgeConfigJson: any;
  edgeConfigUploaded: boolean;
  edgeDocument: Edge.EdgeMap;
  edgeInfo: any;
  locationLookup: { [key: string]: LocationModel.LocationItem };
  filteredLocations: LocationModel.LocationItem[];
  healthFilter: {
    locationIds: string[];
    edgeIds: string[];
    cameraIds: string[];
  };
  viewType?: LocationModel.ViewType;
  query: string;
  statusFilter: PulsationModels.ComponentStatus[];
}

export const adapter: EntityAdapter<LocationModel.LocationItem> = createEntityAdapter<LocationModel.LocationItem>({
  selectId: (location: LocationModel.LocationItem) => location._id,
});

const initialHealthFilter = {
  locationIds: [],
  edgeIds: [],
  cameraIds: [],
};
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialLocationState: LocationState = adapter.getInitialState({
  isFirstLocationLoaded: false,
  isAllLocationLoaded: false,
  isPagination: false,
  error: undefined,
  edgeConfigJson: null,
  edgeDocument: null,
  edgeInfo: null,
  edgeConfigUploaded: false,
  locationLookup: {},
  filteredLocations: [],
  healthFilter: initialHealthFilter,
  viewType: null,
  query: null,
  statusFilter: [],
});

export const locationReducer = createReducer(
  initialLocationState,

  on(LocationActions.GetLocationsSuccess, (state, action) => {
    return adapter.addMany(action.payload, {
      ...state,
      isFirstLocationLoaded: true,
      filteredLocations: action.payload,
    });
  }),

  on(LocationActions.GetLocationsFail, (state, action) => {
    return {
      ...state,
      error: action.message || 'GetLocationsFail - unknown error',
    };
  }),

  on(LocationActions.CreateLocationSuccess, (state, action) => {
    return adapter.upsertOne(action.response, state);
  }),

  on(LocationActions.CreateLocationFail, (state, action) => {
    return {
      ...state,
      error: action.message || 'CreateLocationFail - unknown error',
    };
  }),

  on(LocationActions.DeleteCameraSuccess, (state, action) => {
    const { locationId, edgeId, cameraId } = action.response;
    const location = _.cloneDeep(action.response.location);
    delete location.edges[edgeId].cameras[cameraId];
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        ...location,
      },
    };

    return adapter.updateOne(update, state);
  }),

  on(LocationActions.DeleteLocationSuccess, (state, action) => {
    const { locationId } = action.response;

    return adapter.removeOne(locationId, state);
  }),

  on(LocationActions.DeleteCameraFail, (state, action) => {
    return {
      ...state,
      error: action.message || 'DeleteCameraFail - unknown error',
    };
  }),
  on(LocationActions.ResetEdgeConfig, state => {
    return {
      ...state,
      edgeConfigJson: initialLocationState.edgeConfigJson,
    };
  }),
  on(LocationActions.SetEdgeConfig, (state, { edgeConfigJson }) => {
    return {
      ...state,
      edgeConfigJson,
    };
  }),
  on(LocationActions.SetEdgeConfigUploaded, (state, { uploaded }) => {
    return {
      ...state,
      edgeConfigUploaded: uploaded,
    };
  }),
  on(LocationActions.SetLocationsLookup, (state, { lookup }) => {
    return {
      ...state,
      locationLookup: lookup,
    };
  }),
  on(LocationActions.ResetEdgeDocument, state => {
    return {
      ...state,
      edgeDocument: initialLocationState.edgeDocument,
    };
  }),
  on(LocationActions.SetEdgeDocument, (state, { edgeDocument }) => {
    return {
      ...state,
      edgeDocument,
    };
  }),
  on(LocationActions.ResetEdgeInfo, state => {
    return {
      ...state,
      edgeInfo: initialLocationState.edgeInfo,
    };
  }),
  on(LocationActions.SetEdgeInfo, (state, { edgeInfo }) => {
    return {
      ...state,
      edgeInfo,
    };
  }),
  on(LocationActions.filterLocationByField, (state, { field, value }) => {
    const healthFilters = setHealthFilter(state.healthFilter, Object.values(state.entities), field, value);
    const filteredLocations = filterLocationByField(healthFilters, Object.values(state.entities));
    return {
      ...state,
      healthFilter: healthFilters,
      filteredLocations,
    };
  }),
  on(LocationActions.removeFilterLocationByField, (state, { field, value }) => {
    const editedFilter = removeHealthFilter(state.healthFilter, field, value);
    const healthFilters = setHealthFilter(state.healthFilter, Object.values(state.entities), field, editedFilter);
    return {
      ...state,
      healthFilter: healthFilters,
      filteredLocations: filterLocationByField(healthFilters, Object.values(state.entities)),
    };
  }),
  on(LocationActions.searchLocationByQuery, (state, { query }) => {
    const filteredLocations = filterLocationByField(state.healthFilter, Object.values(state.entities));
    return {
      ...state,
      filteredLocations: filterLocationByQuery(query, filteredLocations),
    };
  }),
  on(LocationActions.filterCamerasByStatus, (state, { cameraIds }) => {
    let filteredCameraIds = cameraIds;
    if (state.healthFilter.cameraIds.length) {
      filteredCameraIds = cameraIds.filter(id => state.healthFilter.cameraIds.includes(id));
    }
    return {
      ...state,
      filteredLocations: filteredCameraIds.length
        ? filterLocationByField({ ...initialHealthFilter, cameraIds: filteredCameraIds }, Object.values(state.entities))
        : [],
    };
  }),
  on(LocationActions.UpdateLocationNoBackendCall, (state, { location }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: location._id,
      changes: {
        ...location,
      },
    };

    return adapter.updateOne(update, state);
  }),

  on(LocationActions.updateEdgeNoBackendCall, (state, { locationId, edge }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state.entities[locationId].edges,
          [edge.edgeId]: edge,
        },
      },
    };

    return adapter.updateOne(update, state);
  }),

  on(CameraActions.CreateLocationEdgeCameraNoBackendCall, (state, action) => {
    const locationId = action?.request?.locationId;
    const edgeId = action?.request?.edgeId;
    const cameraId = action?.request?.edgeOnly.cameraId;
    const camera = action.request as unknown as LocationModel.LocationCameraItem;
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state.entities[locationId].edges,
          [edgeId]: {
            ...state.entities[locationId].edges[edgeId],
            cameras: {
              ...state.entities[locationId].edges[edgeId].cameras,
              [cameraId]: camera,
            },
          },
        },
      },
    };

    return adapter.updateOne(update, state);
  }),

  on(CameraActions.DeleteCameraNoBackendCall, (state, action) => {
    const locationId = action?.request?.locationId;
    const edgeId = action?.request?.edgeId;
    const cameraId = action?.request?.cameraId;
    const cameras = _.cloneDeep(state?.entities[locationId]?.edges[edgeId]?.cameras);
    delete cameras[cameraId];
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state.entities[locationId].edges,
          [edgeId]: {
            ...state.entities[locationId].edges[edgeId],
            cameras,
          },
        },
      },
    };

    return adapter.updateOne(update, state);
  }),

  on(LocationActions.updateCameraNoBackendCall, (state, { locationId, edgeId, camera }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state.entities[locationId].edges,
          [edgeId]: {
            ...state.entities[locationId].edges[edgeId],
            cameras: {
              ...state.entities[locationId].edges[edgeId]?.cameras,
              [camera.cameraId]: {
                ...state.entities[locationId].edges[edgeId]?.cameras[camera.cameraId],
                ...camera,
              },
            },
          },
        },
      },
    };

    return adapter.updateOne(update, state);
  }),
  on(LocationActions.ExpandLocation, (state, { locationId }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        expanded: true,
      },
    };

    return adapter.updateOne(update, state);
  }),
  on(LocationActions.CollapseLocation, (state, { locationId }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        expanded: false,
      },
    };

    return adapter.updateOne(update, state);
  }),
  on(LocationActions.ExpandLocationEdge, (state, { locationId, edgeId }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state?.entities[locationId]?.edges,
          [edgeId]: {
            ...state?.entities[locationId]?.edges[edgeId],
            expanded: true,
          },
        },
      },
    };

    return adapter.updateOne(update, state);
  }),
  on(LocationActions.CollapseLocationEdge, (state, { locationId, edgeId }) => {
    const update: Update<LocationModel.LocationItem> = {
      id: locationId,
      changes: {
        edges: {
          ...state?.entities[locationId]?.edges,
          [edgeId]: {
            ...state?.entities[locationId]?.edges[edgeId],
            expanded: false,
          },
        },
      },
    };

    return adapter.updateOne(update, state);
  }),
  on(LocationActions.ExpandAll, (state) => {
    const locations = Object.values(state.entities);
    const update: Update<LocationModel.LocationItem>[] = locations.map(location => {
      const edges = _.cloneDeep(location.edges);
      for(let edge of Object.values(edges)) {
        edge.expanded = true;
      }
      return {
        id: location._id,
        changes: {
          expanded: true,
          edges,
        },
      };
    });
    return adapter.updateMany(update, state);
  }),

  on(LocationActions.CollapseAll, (state) => {
    const locations = Object.values(state.entities);
    const update: Update<LocationModel.LocationItem>[] = locations.map(location => {
      const edges = _.cloneDeep(location.edges);
      for(let edge of Object.values(edges)) {
        edge.expanded = false;
      }
      return {
        id: location._id,
        changes: {
          expanded: false,
          edges,
        },
      };
    });
    return adapter.updateMany(update, state);
  }),
  on(LocationActions.removeCameraById, (state, { cameraId, locationId, edgeId }) => {
    const locations = _.cloneDeep(state.entities);
    const location = locations[locationId];
    delete location.edges[edgeId].cameras[cameraId];
    return adapter.updateOne({ id: location._id, changes: { edges: location.edges } }, state);
  }),
  on(LocationActions.setViewType, (state, { viewType }) => {
    return {
      ...state,
      viewType,
    };
  }),
  on(LocationActions.getViewTypeFromLocalStorageSuccess, (state, { viewType }) => {
    return {
      ...state,
      viewType,
    };
  }),
  on(LocationActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(LocationActions.setStatusFilter, (state, { statusFilter }) => {
    return {
      ...state,
      statusFilter,
    };
  }),
on(LocationActions.ResetSensors, (state) => {
  const updates: Update<LocationModel.LocationItem>[] = Object.values(state.entities).map(location => ({
    id: location._id,
    changes: {
      sensors: null
    }
  }));
  return adapter.updateMany(updates, state);
}),
on(LocationActions.GetSensorsSuccess, (state, { sensors }) => {
  if (!sensors) {
    return {
      ...state,
    };
  }
  // Group sensors by locationId
  const sensorsByLocation = sensors?.reduce((acc, sensor) => {
    if (!acc[sensor.locationId]) {
      acc[sensor.locationId] = [];
    }
    acc[sensor.locationId].push(sensor);
    return acc;
  }, {});

  // Create updates for each location that has sensors
  const updates: Update<LocationModel.LocationItem>[] = Object.keys(sensorsByLocation).map(locationId => ({
    id: locationId,
    changes: {
      sensors: sensorsByLocation[locationId]
    }
  }));

  return adapter.updateMany(updates, state);
}),
);

const filterLocationByField = (
  filters: { locationIds: string[]; edgeIds: string[]; cameraIds: string[] },
  initialLocations: LocationModel.LocationItem[],
): LocationModel.LocationItem[] => {
  let filteredLocationIds: string[] = [];
  if (filters.locationIds?.length) {
    filteredLocationIds = filteredLocationIds.concat(filters.locationIds);
  }
  return initialLocations
    .map(location => {
      let edges = {};
      const filteredEdgeIds: string[] = [];

      Object.keys(location.edges)
        .forEach(edgeKey => {
          const edge = location.edges[edgeKey];
          let cameras = {};
          /**
           * Start filter by camera if filter exist
           */
          if (filters?.cameraIds?.length) {
            const edgeCameras = edge.cameras ?? {};
            Object.keys(edgeCameras)
              .forEach(cameraKey => {
                if (filters.cameraIds.includes(cameraKey)) {
                  cameras[cameraKey] = edgeCameras[cameraKey];
                  filteredEdgeIds.push(edge.edgeId);
                }
              });

            if (filteredEdgeIds.includes(edgeKey)) {
              edges[edgeKey] = {
                ...location.edges[edgeKey],
                cameras,
              };
              filteredLocationIds.push(location._id);
            }
          } else {
            // filter by edge ids
            if (filters.edgeIds?.length) {
              if (filters.edgeIds.includes(edgeKey)) {
                edges[edgeKey] = {
                  ...location.edges[edgeKey],
                };
                filteredLocationIds.push(location._id);
              }
            } else {
              // In case of filter does not have edgeIds => show all edges
              edges[edgeKey] = {
                ...location.edges[edgeKey],
              };
            }
          }
        });
      return {
        ...location,
        edges,
      };
    })
    .filter(location => {
      if (filteredLocationIds?.length) {
        return filteredLocationIds.includes(location._id);
      } else {
        return true;
      }
    });
};

const setHealthFilter = (
  existingFilters: {
    locationIds: string[];
    edgeIds: string[];
    cameraIds: string[];
  },
  initialLocations: LocationModel.LocationItem[],
  field: string,
  value: string[],
) => {
  const filters = { ...existingFilters };
  switch (field) {
    case 'locationIds':
      // do only if select.
      filters.locationIds = value;
      // if (existingFilters.locationIds.length < value.length) {
      const filteredLocations = initialLocations.filter(item => value.includes(item._id));
      filters.edgeIds = [];
      filters.cameraIds = [];
      filteredLocations.forEach(location => {
        filters.edgeIds = filters.edgeIds.concat(Object.keys(location.edges));
        Object.values(location.edges)
          .forEach(edge => {
            const edgeCameras = edge.cameras ?? {};
            filters.cameraIds = filters.cameraIds.concat(Object.keys(edgeCameras));
          });
      });
      // }
      break;
    case 'edgeIds':
      // do only if select.
      filters.edgeIds = value;
      // if (existingFilters.edgeIds.length < value.length) {
      const filteredEdges = [];
      initialLocations.forEach(location => {
        const edge = Object.values(location.edges)
          .find(edge => value.includes(edge.edgeId));
        if (edge) {
          filteredEdges.push(edge);
        }
      });
      filters.cameraIds = [];
      filteredEdges.forEach(edge => {
        const edgeCameras = edge.cameras ?? {};
        filters.cameraIds = filters.cameraIds.concat(Object.keys(edgeCameras));
      });
      // }
      break;
    case 'cameraIds':
      filters.cameraIds = value;
      break;
  }
  return filters;
};

const removeHealthFilter = (
  existingFilters: {
    locationIds: string[];
    edgeIds: string[];
    cameraIds: string[];
  },
  field: string,
  value: string,
): string[] => {
  let filters = [];
  switch (field) {
    case 'locationIds':
      filters = [...existingFilters.locationIds];
      const indexLocation = existingFilters.locationIds.findIndex(location => location === value);
      filters.splice(indexLocation, 1);
      break;
    case 'edgeIds':
      filters = [...existingFilters.edgeIds];
      const indexEdge = existingFilters.edgeIds.findIndex(edge => edge === value);
      filters.splice(indexEdge, 1);
      break;
    case 'cameraIds':
      filters = [...existingFilters.cameraIds];
      const indexCamera = existingFilters.cameraIds.findIndex(camera => camera === value);
      filters.splice(indexCamera, 1);
      break;
  }
  return filters;
};

const filterLocationByQuery = (query: string, filteredLocations: LocationModel.LocationItem[]): LocationModel.LocationItem[] => {
  const locationIds = [];
  return filteredLocations
    .map(location => {
      const edges = {};
      Object.values(location.edges)
        .forEach(edge => {
          const cameras = {};
          const edgeCameras = edge.cameras ?? {};
          Object.values(edgeCameras)
            .forEach(camera => {
              if (camera?.edgeOnly?.name.toUpperCase()
                .indexOf(query.toUpperCase()) > -1) {
                cameras[camera.edgeOnly.cameraId] = camera;
                locationIds.push(location._id);
              }
            });
          edges[edge.edgeId] = {
            edge,
            cameras: cameras,
          };
        });
      return {
        ...location,
        edges,
      };
    })
    .filter(location => locationIds.includes(location._id));
};
