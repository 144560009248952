import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, of, share, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { WebRtcActions } from '@states/webrtc/webrtc.action-types';
import { WebrtcService } from '../../development/webrtc.service';
import { withLatestFrom } from 'rxjs/operators';
import { WebRtcSelectors } from '@states/webrtc/webrtc.selector-types';
import { GrantedAccessSelectors } from '@states/granted-access/granted-access.selector-types';
import { WebRtcActiveSessionActions } from '@states/webrtc-active-sessions/webrtc-active-sessions.action-types';

@Injectable()
export class WebRtcEffect {

  public deleteWebRtcSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.DeleteWebRtcSession),
      mergeMap(({ sessionId }) => {
        return [SharedActions.deleteSessionDataDocument({ token: sessionId })];
      }),
      share(),
      catchError(response => {
        return [
          this.catchError(response),
        ];
      }),
    ),
  );

  public getCreatedSessionId$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(WebRtcActions.GetCreatedSessionDetails),
        exhaustMap(({ createToken, sessionId, credentials }) => {
          return [
            WebRtcActions.GetCreatedSessionDetailsSuccess({ createToken, sessionId, credentials }),
          ];
        }),
        catchError(response => {
          return [
            this.catchError(response),
          ];
        }),
      );
    },
  );

  public sendWebRtcICE$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.SendWebRtcICE),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([{ peerData }, authToken]) => {
        return this.webrtcService
          .ice(peerData, authToken)
          .pipe(
            mergeMap(res => {
              return [SharedActions.doNothing()];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public sendWebRtcSDP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.SendWebRtcSDP),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([{ peerData }, authToken]) => {
        return this.webrtcService
          .sdp(peerData, authToken)
          .pipe(
            switchMap(res => {
              return [SharedActions.doNothing()];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public sendWebRtcControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.SendWebRtcControl),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([{ peerData }, authToken]) => {
        return this.webrtcService
          .control(peerData, authToken)
          .pipe(
            switchMap(res => {
              return [SharedActions.doNothing()];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public createWebRtcSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.CreateWebRtcSession),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([{ peerData, createToken }, authToken]) => {
        return this.webrtcService
          .start(peerData, authToken)
          .pipe(
            switchMap(res => {
              return [
                WebRtcActions.GetCreatedSessionDetails({ createToken, sessionId: res.token.session, credentials: res.credentials }),
                WebRtcActions.SubscribeToSessionData({ token: res.token.session }),
              ];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      catchError(response => {
        return [
          this.catchError(response),
        ];
      }),
      share(),
    ),
  );

  public subscribeToSessionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActions.SubscribeToSessionData),
      mergeMap(({ token }) => {
        return this.webrtcService.subscribeToSession(token, true)
          .pipe(
            withLatestFrom(this.store$.pipe(select(WebRtcSelectors.selectSessionById(token)))),
            mergeMap(([{ data, status }, session]) => {
              // TODO: Check if exist, if exist - Update, else Create Session Success
              const exists = !!session;
              return [
                exists ?
                  WebRtcActions.UpdateWebRtcSession({ peerData: { ...data, sessionId: token } }) :
                  WebRtcActions.CreateWebRtcSessionSuccess({ peerData: { ...data, sessionId: token } }),
              ];
            }),
            catchError((err, caught) => {
              return [
                WebRtcActions.CreateWebRtcSessionFail({ message: err.message }),
                SharedActions.deleteSessionDataDocument({ token }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setActiveSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActiveSessionActions.setActiveSession),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([state, authToken]) => {
        const { edgeId, cameraId, sessionState } = state;
        this.webrtcService
          .setActiveSession(edgeId, cameraId, sessionState);
        return [WebRtcActiveSessionActions.setActiveSessionSuccess(state), SharedActions.doNothing()];
      }),
      share(),
    ),
  );

  public deleteActiveSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebRtcActiveSessionActions.deleteActiveSession),
      withLatestFrom(this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken))),
      mergeMap(([{ edgeId, cameraId }, authToken]) => {
        this.webrtcService
          .deleteActiveSession(edgeId, cameraId);
        return of()
          .pipe(
            switchMap(res => {
              return [SharedActions.doNothing()];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  private catchError(response) {
    return SharedActions.doNothing();
  }

  constructor(private actions$: Actions, private store$: Store<AppState>, private webrtcService: WebrtcService) {
  }
}
