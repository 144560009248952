// import { PersonSelectionFormFields, SearchSelectionFormValueTypes } from '@enums/search.enum';
//
// export const personSelectionFormFieldsValueType: { [Property in PersonSelectionFormFields]: SearchSelectionFormValueTypes } = {
//   [PersonSelectionFormFields.genderType]: SearchSelectionFormValueTypes.checkbox,
//   [PersonSelectionFormFields.ageType]: SearchSelectionFormValueTypes.radio,
//   // [PersonSelectionFormFields.footwearType]: SearchSelectionFormValueTypes.radio,
//   ,
//   footwearType,
//   lowerbodyType,
//   upperbodyType,
//   hairType,
//   accessoryType,
//   carryingType,
// };

import { Search } from '../shared/search.model';

export const precisionStr: { [Property in Search.Precision] } = {
  [Search.Precision.LOW]: 'Low',
  [Search.Precision.MEDIUM]: 'Medium',
  [Search.Precision.HIGH]: 'High',
};
