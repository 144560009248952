<h2 *ngIf="!loader && stage === AddCameraStage.GET_DETAILS_FORM && !manual">
  Please provide all information<br/>
  to add this camera to Lumix.ai
</h2>
<h2 *ngIf="!loader && stage === AddCameraStage.GET_DETAILS_FORM && manual">Add Camera</h2>
<h2 *ngIf="!loader && stage === AddCameraStage.CREATE_CAMERA_FORM">Please add name and description to your camera</h2>

<mat-dialog-content class="mat-typography">
  <div *ngIf="loader" class="add-camera-loader" fxLayoutAlign="center center" fxFlex="">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner diameter="45"></mat-spinner>
      <div class="mt-30">
        {{
        stage === AddCameraStage.GET_DETAILS_FORM
          ? 'Getting camera details...'
          : data.edit
            ? 'Updating camera details...'
            : 'Creating camera... this might take a minute'
        }}
      </div>
    </div>
  </div>

  <form *ngIf="!loader && stage === AddCameraStage.GET_DETAILS_FORM" [formGroup]="form" fxFill>
    <div fxFlex="100">
      <div *ngIf="manual" fxLayoutAlign="center center" class="camera-preview-wrapper mb-20">
        <mat-icon *ngIf="!img" class="mr-10">videocam</mat-icon>
        <div *ngIf="!img">Camera Preview</div>
        <img *ngIf="img" style="width: 100%; height: 100%" [lazyLoad]="img"/>
      </div>
      <div *ngIf="false" fxLayout="column">
        <mat-label>CoreId</mat-label>
        <mat-form-field *ngIf="manual" appearance="outline" class="col">
          <input matInput type="text" name="edgeId" placeholder="edgeId" formControlName="edgeId"/>
          <mat-error *ngIf="edgeId?.errors?.['required']">CoreId is Required</mat-error>
        </mat-form-field>
        <div *ngIf="!manual" class="non-manual-field">{{ edgeId.value }}</div>
      </div>
      <div *ngIf="false" fxLayout="column">
        <mat-label>Camera ID</mat-label>
        <mat-form-field *ngIf="manual" appearance="outline">
          <input matInput type="text" name="cameraId" placeholder="cameraId" formControlName="cameraId"/>
          <mat-error *ngIf="cameraId?.errors?.['required']">cameraId is Required</mat-error>
        </mat-form-field>
        <div *ngIf="!manual" class="non-manual-field">{{ cameraId.value }}</div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div *ngIf="manual" fxLayout="column" fxFlex="">
          <mat-label>IP Address</mat-label>
          <mat-form-field *ngIf="manual" appearance="outline">
            <input matInput type="text" name="ipAddress" placeholder="ipAddress" formControlName="ipAddress"/>
            <mat-error *ngIf="ipAddress?.errors?.['required']">IP Address is Required</mat-error>
            <mat-error *ngIf="ipAddress.errors?.['ipExist']">IP Address is already in use</mat-error>
          </mat-form-field>
          <div *ngIf="!manual" class="non-manual-field">
            {{ ipAddress.value }}
          </div>
        </div>

        <div *ngIf="manual" fxLayout="column" fxFlex="">
          <mat-label>RTSP Port</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="port" placeholder="port" formControlName="port"/>
            <mat-error *ngIf="port?.errors?.['required']">Port is Required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <mat-label>Username</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" name="username" placeholder="username" formControlName="username"/>
          <mat-error *ngIf="username?.errors?.['required']">Username is Required</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <mat-label>Password</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="password" name="password" placeholder="password" formControlName="password"/>
          <mat-error *ngIf="password?.errors?.['required']">Password is Required</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" *ngIf="manual">
        <mat-label>Connection string</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" name="connectionString" placeholder="/s0" formControlName="connectionString"/>
        </mat-form-field>
      </div>
      <!--      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">-->
      <!--        <div *ngIf="manual" fxLayout="column" fxFlex="">-->
      <!--          <mat-label>Width</mat-label>-->
      <!--          <mat-form-field *ngIf="manual" appearance="outline">-->
      <!--            <input-->
      <!--                    matInput-->
      <!--                    type="text"-->
      <!--                    name="width"-->
      <!--                    placeholder="width"-->
      <!--                    formControlName="width" />-->
      <!--          </mat-form-field>-->
      <!--          <mat-error *ngIf="width?.errors?.['required']">Width is Required</mat-error>-->
      <!--        </div>-->

      <!--        <div *ngIf="manual" fxLayout="column" fxFlex="">-->
      <!--          <mat-label>Height</mat-label>-->
      <!--          <mat-form-field appearance="outline">-->
      <!--            <input-->
      <!--                    matInput-->
      <!--                    type="text"-->
      <!--                    name="height"-->
      <!--                    placeholder="height"-->
      <!--                    formControlName="height" />-->
      <!--          </mat-form-field>-->
      <!--          <mat-error *ngIf="height?.errors?.['required']">Width is Required</mat-error>-->

      <!--        </div>-->
      <!--      </div>-->

      <div *ngIf="err" class="c-red">{{ err }}</div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <button mat-flat-button
                color="primary"
                *ngIf="manual"
                (click)="manualTest()"
                [disabled]="!form.valid || testLoader || testSuccess"
        >
          Test
        </button>
        <mat-spinner diameter="20" *ngIf="testLoader"></mat-spinner>
        <mat-icon class="c-green bold" *ngIf="testSuccess">check</mat-icon>
      </div>
    </div>
  </form>
  <form *ngIf="!loader && stage === AddCameraStage.CREATE_CAMERA_FORM" [formGroup]="detailsForm" fxFill>
    <div fxFlex="100">
      <div *ngIf="!data.edit" fxLayoutAlign="center center" class="camera-preview-wrapper mb-20">
        <mat-icon class="mr-10" *ngIf="!img">videocam</mat-icon>
        <div *ngIf="!img">Camera Preview</div>
        <img style="width: 100%; height: 100%" [lazyLoad]="img"/>
      </div>
      <div fxLayout="column">
        <mat-label>Name</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" name="port" placeholder="name" formControlName="name"/>
          <mat-error *ngIf="port?.errors?.['required']">Port is Required</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <mat-label>Description</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" name="port" placeholder="description" formControlName="description"/>
          <mat-error *ngIf="port?.errors?.['required']">Description is Required</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!loader">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" cdkFocusInitial (click)="save()" [disabled]="manual && !testSuccess">
    {{ stage === AddCameraStage.GET_DETAILS_FORM ? 'Confirm' : 'Done' }}
  </button>
</mat-dialog-actions>
