export enum PaginationDirections {
  next,
  back,
}


export enum AlertNotificationLogType {
  EMAIL,
  SMS,
  MOBILE_PUSH
}
