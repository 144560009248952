import { createReducer, on } from '@ngrx/store';
import * as PeopleActions from '@states/people/people.actions';
import { Buckets, Person } from '@models/people.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface PeopleState extends EntityState<Person> {
  buckets: Person[];
  token: string;
}

export const adapter: EntityAdapter<Person> = createEntityAdapter<Person>({
  selectId: (person: Person) => person.personId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: PeopleState = adapter.getInitialState({
  buckets: [],
  token: null
});
export const peopleStateReducer = createReducer(
  initialState,
  on(PeopleActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(PeopleActions.resetUnsaved, state => {
    return {
      ...state,
      buckets: []
    };
  }),
  on(PeopleActions.setPeople, (state, { people }) => {
    return {
      ...state,
    };
  }),
  on(PeopleActions.getPeopleSuccess, (state, { people }) => {
    return adapter.setMany(people, state);
  }),
  on(PeopleActions.getBucketsSuccess, (state, { buckets, token }) => {
    return {
      ...state,
      buckets,
      token
    };
  }),
  on(PeopleActions.addPersonSuccess, (state, { person }) => {
    const update: Person = {
      ...person,
    };
    return adapter.setOne(update, state);
  }),
  on(PeopleActions.editPersonSuccess, (state, { person }) => {
    const update: Person = {
      ...person,
    };
    return adapter.setOne(update, state);
  }),
  on(PeopleActions.removePersonSuccess, (state, { personId }) => {
    return adapter.removeOne(personId, state);
  }),
);
