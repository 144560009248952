import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubstreamPulsationState } from './substream-heartbeat-pulsation.reducer';
import { PulsationModels } from '@models/pulsation.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { entityStatusByEdgeStatus } from '../../../helpers/common.helpers';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { selectEdgeStatus } from '@states/device-status/device-status.selectors';

export const selectSubstreamStatusState = createFeatureSelector<SubstreamPulsationState>('substreamPulsationState');

export const selectSubstreamStatusByCameraId = (cameraId: string) =>
  createSelector(selectEdgeStatus, selectSubstreamStatusState, (edges: Dictionary<ComponentStatusDisplay>, cameras: SubstreamPulsationState) => {
    return entityStatusByEdgeStatus(cameraId, edges, cameras);
  });

export const selectSubstreamStatusPulsationByCameraId = (cameraId: string) =>
  createSelector(selectSubstreamStatusState, (cameras: SubstreamPulsationState) => {
    return cameras.entities[cameraId];
  });

export const selectSubstreamEntities = createSelector(selectSubstreamStatusState, selectEdgeStatus, (cameras: SubstreamPulsationState, edges: Dictionary<ComponentStatusDisplay>) => {
  const cameraEntitiesIds = Object.keys(cameras.entities);
  const cameraStatusesWithEdgeStatuses: Dictionary<PulsationModels.Store.CameraPulsation> = {};
  cameraEntitiesIds.forEach(cameraId => {
    cameraStatusesWithEdgeStatuses[cameraId] = {
      ...cameras.entities[cameraId],
      status: entityStatusByEdgeStatus(cameraId, edges, cameras),
    };
  });
  return cameraStatusesWithEdgeStatuses;
});
