
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, fromEvent } from 'rxjs';
import { mergeWith } from 'rxjs/operators';
import { SocketEvents } from '../socket/socket.model';
import { SocketMainService } from '../socket/socket-main.service';

export const SwBroadcastChannel = "sw-messages";
export const TenantBroadcastChannel = "tenant-messages";

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerHandlerService implements OnDestroy {

  private messageSource = new Subject<MessageEvent>();
  public message$: Observable<MessageEvent>;

  private swBroadcastChannel: BroadcastChannel;
  private tenantBroadcastChannel: BroadcastChannel;

  constructor(private socketMainService: SocketMainService) {

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', this.handleMessage.bind(this));


      if ('BroadcastChannel' in window) {
        this.swBroadcastChannel = new BroadcastChannel(SwBroadcastChannel);
        const swBroadcastChannelMessages = fromEvent<MessageEvent>(this.swBroadcastChannel, 'message');

        this.tenantBroadcastChannel = new BroadcastChannel(TenantBroadcastChannel);
        const tenantBroadcastChannelMessages = fromEvent<MessageEvent>(this.tenantBroadcastChannel, 'message');


        this.message$ = this.messageSource.asObservable().pipe(
          mergeWith(swBroadcastChannelMessages, tenantBroadcastChannelMessages)
        );

      } else {
        this.message$ = this.messageSource.asObservable();
      }

    } else {
      this.setupWebSocketFallback();
    }
  }

  private handleMessage(event: MessageEvent) {
    this.messageSource.next(event);
  }

  public sendMessage(type: string, body = {}, fallback = true): boolean {

    let res = false;

    const worker = navigator?.serviceWorker?.controller;
    if (worker) {
      const messageChannel = new MessageChannel();
      console.log('Posting SKIP_WAITING to Service Worker');
      worker.postMessage({ type, body }, [messageChannel.port2]);
      res = true
    } else {
      if (fallback) {
        this.socketMainService.emit(SocketEvents.serviceWorkerMessage, type);
        res = true
      }
    }

    return res;
  }

  private setupWebSocketFallback() {
    this.socketMainService.consume<{ type: any }>(SocketEvents.serviceWorkerMessage)
      .subscribe((data) => {
        const transformedEvent = this.transformToMessageEvent(data);
        this.messageSource.next(transformedEvent);
      });
  }

  private transformToMessageEvent(data: any): MessageEvent {
    return new MessageEvent('message', { data });
  }

  ngOnDestroy() {
    this.swBroadcastChannel.close();
    this.tenantBroadcastChannel.close();
  }
}
