export enum SessionDataAction {
  removeEvent,
  sync,
  sendEdgeConfigJson,
  sendEdgeConfig,
  updateEdgeInLocation,
  sendGetCameraDetailsManually,
  sendCameraUpdate,
  getEdgeDocument,
  getEdgeInfo,
  sendApproveEdgeConfig,
  sendAlert,
  getDeviceMsgsVersion,
  updateSoftwareVersion,
  getSoftwareVersion,
  getEdgeLatestStatusFirestore,
  getEdgeConfig,
  getEdgeSwUpdate,
  getEthDeviceInfo,
  ifConfig,
  routeInfo,
  interfaceEthConf,
  arp,
  ifUp,
  IfDown,
  deleteEthManualConfiguration,
  deleteLogs,
  ping,
  writeEthManualConfiguration,
  getDisksInfo,
  mount,
  getEdgeLogs,
  dockerPs,
  dockerInspect,
  dockerStats,
  nMCLI,
  dU,
  lS,
  nMCLIDeviceShow,
  nMCLIDeviceStatus,
  checkEdgeInstallation,
  getDate,
  setDate,
  setTimezone,
  setNTP,
  UploadVS,
  ListVS,
  rmCamera,
  getAnalyticConfig,
  sendUpdateAnalyticConfigJson,
  pM2Logs,
  pS,
  getEdgeIPInfos,
  getExternalIP,
  probFile,
  manageAction,
  sendVariable,
  dispatch,
  sendGetCameraDetails,
  createCamera,
  probeCamera,
  probeSubstream,
  sendGetMultipleCameraDetails,
  removeSavedSearch,
  deleteCameraFromLocation,
  migrate,
  getCameraVpnConfiguration,
  updateCameraVpnConfiguration,
  vpnReboot,
  vpnTest,
  testSpeaker,
  createArchive,
  getStorageStats,
  getNtpServers,
  setNtpServers,
  dig,
  traceroute,
  speedtest,
  firewall,
  getBandwidth,
  updateSearchConfiguration
}
