<ng-container *ngrxLet="selectSelectedArchive$; let selectedArchive">
  <ng-container *ngrxLet="selectSelectedGrantedAccess$; let grantedAccess">
    <ng-container *ngrxLet="selectIsArchiveValid$; let isArchiveValid">
      <div fxLayout="column" class="add-archive-modal">

        <ng-container *ngIf="!isShareMode">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
            Create archive
            <mat-icon class="c-p" (click)="close()">close</mat-icon>
          </div>

          <div [class.disabled]="archiveCreated" class="body" [formGroup]="archiveForm">
            <div class="input-row">
              <ui-input
                formControlName="name"
                [style]="uiInputStyles.labelOutside"
                [invalid]="archiveForm.get('name').touched && archiveForm.get('name').invalid"
                [validationEnabled]="true"
                [validationErrors]="archiveForm.get('name').errors"
                label="Name"
                [placeholder]="placeholder"
              ></ui-input>
            </div>
            <div class="column">
              <div class="input-row" *ngIf="!(selectAddArchiveModalConfig$ | ngrxPush).disableSelectedCamera">
                <div fxLayout="column" class="ui-input-wrapper label-out">
                  <label class="label">Camera</label>
                  <div class="ui-input-container" (click)="selectCamera()">
                    <img class="input-icon-start" src="../../../../../assets/input/camera-outline.svg"/>
                    <div class="camera-selected-name">
                      {{ selectedArchive.selectedCamera?.edgeOnly.name ?? 'Select Camera' }}
                    </div>
                    <img class="input-icon-end" src="../../../../../assets/input/input-arrow-bottom.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row gap-8 mb-16"
                 *ngIf="!!(selectAddArchiveModalConfig$ | ngrxPush).hasSmartStorage && selectedArchive?.smartStorageInterval?.length"
            >
              <ui-toggle [(ngModel)]="isSmartStorage" [ngModelOptions]="{standalone: true}"
                         (ngModelChange)="smartStorageChange(selectedArchive?.smartStorageInterval[0], selectedArchive.smartStorageInterval[1])"
              ></ui-toggle>
              <div>Smart storage</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" class="w-100" fxLayoutGap="8px">
              <div fxLayout="column" class="w-100"
                   [style.pointer-events]="(selectAddArchiveModalConfig$ | ngrxPush).disableSelectDateRange ? 'none' : ''"
              >
                <label class="label">From</label>
                <ui-datetime-range-picker
                  [single]="true"
                  [inputStyle]="uiInputStyles.noLabelThick"
                  [startDate]="(isSmartStorage ? getFormattedDate(selectedArchive.smartStorageInterval[0]) : selectedArchive.start) | stringToDate"
                  [endDate]="selectedArchive.end | stringToDate"
                  (onRangeSelected)="changeDateRange($event)"
                  [timezone]="selectedArchive.timezone"
                  [disabled]="isSmartStorage"
                ></ui-datetime-range-picker>
                <!--                <ng-container *ngrxLet="selectArchiveDuration$; let duration">-->
                <!--                  <div fxLayout="row"-->
                <!--                       fxLayoutAlign="start center"-->
                <!--                       fxLayoutGap="16px"-->
                <!--                       class="description"-->
                <!--                       *ngIf="duration > 5"-->
                <!--                  >-->
                <!--                    <span class="icon-yellow ui-icon-warning icon-16"></span>-->
                <!--                    <div>Archive is limited to 5 minutes</div>-->
                <!--                  </div>-->
                <!--                </ng-container>-->
              </div>
              <div fxLayout="column" class="w-100">
                <label class="label">To</label>
                <ui-datetime-range-picker
                  [single]="true"
                  [inputStyle]="uiInputStyles.noLabelThick"
                  [startDate]="(isSmartStorage ? getFormattedDate(selectedArchive.smartStorageInterval[1]) : selectedArchive.end) | stringToDate"
                  (onRangeSelected)="changeDateRange($event)"
                  [disabled]="true"
                  [timezone]="selectedArchive.timezone"
                ></ui-datetime-range-picker>
              </div>

            </div>
            <div fxLayout="column" fxLayoutAlign="start start" class="mt-24 mb-10 w-100 duration">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <label>Duration</label>
                <div *ngIf="isSmartStorage" class="smart-storage-duration">
                  00:{{ Math.floor((selectedArchive?.smartStorageInterval[1] - selectedArchive?.smartStorageInterval[0])) | date: 'mm:ss' }}
                </div>
              </div>
              <div *ngIf="!isSmartStorage" fxLayout="row" fxLayoutAlign="start center" class="w-100">
                <div class="range-label">1 min</div>
                <!--                <mat-slider min="1" fxFlex=""-->
                <!--                            color="primary"-->
                <!--                            [max]="maxRange(selectedArchive)"-->
                <!--                            step="1"-->
                <!--                            [(ngModel)]="range"-->
                <!--                            (change)="updateRange()"-->
                <!--                            class="w-100"-->
                <!--                            [thumbLabel]="true"-->
                <!--                            thumbLabelColor="primary"-->
                <!--                ></mat-slider>-->
                <div class="custom-slider w-100"
                     [class.is-min]="range === 1"
                     [class.is-max]="range === maxRange(selectedArchive)"
                >
                  <ngx-slider #slider fxLayout="row" fxFlex="" [(value)]="range"
                              [options]="{floor: 1, ceil: maxRange(selectedArchive), showTicks: false, showSelectionBar: true}"
                              (userChangeEnd)="updateRange()"
                  ></ngx-slider>
                </div>
                <div class="range-label">{{ maxRange(selectedArchive) }} min</div>
              </div>
            </div>

            <!--            <ui-button-->
            <!--              [disabled]="!isArchiveValid"-->
            <!--              colorText="var(&#45;&#45;color-secondary-gray-800)"-->
            <!--              colorBorder="var(&#45;&#45;color-secondary-gray-400)"-->
            <!--              text="Share Archive"-->
            <!--              icon="share"-->
            <!--              colorIcon="var(&#45;&#45;color-secondary-gray-800)"-->
            <!--              (clickAction)="isShareMode = !isShareMode"-->
            <!--            ></ui-button>-->
            <div>
              <div style="margin-top: 10px" *ngIf="grantedAccess.emails?.length">
                Granted access for : {{ grantedAccess.emails.join(', ') }}
              </div>
            </div>
          </div>

        </ng-container>
        <ng-container *ngIf="isShareMode">
          <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="open-share-modal-btn" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon (click)="isShareMode = false" style="cursor: pointer;">arrow_back_ios</mat-icon>
              <div>Share the archive</div>
            </div>
            <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
          </div>
          <app-share-access [showHeader]="false"></app-share-access>
          <!--        <app-archive-shared-modal [showHeader]="false"></app-archive-shared-modal>-->
        </ng-container>

        <div class="footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
          <ng-container *ngIf="archiveCreated">
            <div class="created-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex="">
              <div fxLayoutAlign="center center" class="icon-16 svg-fill-primary">
                <mat-icon svgIcon="info"></mat-icon>
              </div>
              <div>Creating an archive</div>
            </div>
            <div class="share-button c-p"
                 fxLayoutAlign="center center"
                 matRipple
                 matTooltip="Share archive"
                 (click)="openShare()"
            >
              <span class="ui-icon-share ui-mat-icon-16"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="archiveError">
            <div class="error-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex="">
              <div fxLayoutAlign="center center" class="icon-16 svg-fill-red">
                <mat-icon svgIcon="status-offline"></mat-icon>
              </div>
              <div class="w-100 error-msg-wrapper">
                <ui-truncated-html>{{ archiveErrorMsg }}</ui-truncated-html>
              </div>
            </div>
          </ng-container>
          <ui-progress-button
            *ngIf="!isShareMode"
            [disabled]="!(isArchiveValid  && archiveForm.valid) || archiveCreated"
            [loading]="creatingArchive"
            [text1]="receivedError ? 'Retry' : 'Create'"
            [text2]="receivedError ? 'Retry' : 'Create'"
            (clickAction)="create(selectedArchive)"
          >
          </ui-progress-button>
          <ui-progress-button *ngIf="isShareMode"
                              [disabled]="!isArchiveValid"
                              text1="Save"
                              icon="save"
                              (clickAction)="create()"
          ></ui-progress-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #selectedOptionTemplate let-option>
  <span>
    {{ option.email }}
  </span>
</ng-template>
