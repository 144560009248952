import { Injectable } from '@angular/core';
import { AppleTvService } from '../../services/apple-tv.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concat, debounceTime, exhaustMap, map, mapTo, of, share, switchMap, takeUntil, tap, timeout, TimeoutError, timer, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AppleTvActions } from '@states/apple-tv/apple-tv.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { AppleTvModels } from '@models/apple-tv.models';
import { mergeMap } from 'rxjs/operators';
import { WallV2Service } from '../../services/wall-v2.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Injectable()
export class AppleTvEffects {

  public getAppleTvList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.getAppleTv),
      withLatestFrom(
        this.store$.pipe(select(state => state.appleTvState)),
        this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([, { filters }, { selectedLocationId }]) => {
        return this.appleTvService
          .getAll({
            locationId: selectedLocationId,
            ...filters,
          })
          .pipe(
            switchMap(res => {
              return [
                AppleTvActions.getAppleTvSuccess({ documents: res }),
              ];
            }),
            catchError(response => {
              return [
                AppleTvActions.getAppleTvFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.setQueryStringFilter),
      debounceTime(400),
      exhaustMap(() => [AppleTvActions.resetEntities(), AppleTvActions.getAppleTv()]),
    ),
  );

  public startSaveAppleTv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.startSaveAppleTv),
      switchMap(({ document, isEdit }) =>
        concat(
          of(AppleTvActions.setIsDocumentSaving({ isSaving: true })),
          of(AppleTvActions.saveAppleTvRequest({ document, isEdit })),
        ),
      ),
    ),
  );

  public saveAppleTvRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.saveAppleTvRequest),
      withLatestFrom(
        this.store$.pipe(select(state => state.locationEditState)),
      ),
      switchMap(([{ document, isEdit }, { selectedLocationId }]) => {
        return this.appleTvSaveService(isEdit, selectedLocationId, { ...document, name: document.name })
          .pipe(
            timeout(15000),
            takeUntil(this.actions$.pipe(ofType(AppleTvActions.cancelNetworkRequests))),
            switchMap((res) => {
              return [
                AppleTvActions.getWallFromDevice({ edgeId: document.edgeId }),
                AppleTvActions.saveAppleTvRequestSuccess({ isEdit }),
                AppleTvActions.setIsDocumentSaving({ isSaving: false }),
                AppleTvActions.rmEdgeIdFromConfigureEdges({ edgeId: document.edgeId }),
              ];
            }),
            catchError(response => {
              return [
                AppleTvActions.getWallFromDevice({ edgeId: document.edgeId }),
                AppleTvActions.setIsDocumentSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.getErrorMessage(response) }),
                AppleTvActions.saveAppleTvRequestFail(),
                AppleTvActions.rmEdgeIdFromConfigureEdges({ edgeId: document.edgeId }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public startDeleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.startDeleteDevice),
      debounceTime(400),
      exhaustMap(({ edgeId }) => [AppleTvActions.deleteDevice({ edgeId })]),
    ),
  );

  public deleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.deleteDevice),
      withLatestFrom(
        this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([{ edgeId }, { selectedLocationId }]) => {
        return this.appleTvService
          .delete(selectedLocationId, edgeId)
          .pipe(
            timeout(15000),
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Device has been removed' }),
                // AppleTvActions.deleteDeviceSuccess({ edgeId: edgeId }),
              ];
            }),
            catchError(response => {
              return [
                AppleTvActions.deleteDeviceFail({ edgeId: edgeId }),
                // SharedActions.showMessage({ error: this.getErrorMessage(response) }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public getOneAppleTv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.getOneAppleTv),
      switchMap(({ id }) => {
        return this.appleTvService
          .getOne(id)
          .pipe(
            switchMap(res => {
              return [
                AppleTvActions.getOneAppleTvSuccess({ document: res }),
              ];
            }),
            catchError(response => {
              return [
                AppleTvActions.getOneAppleTvFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public getWalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.getWalls),
      switchMap(() => {
        return this.wall2Service.getAppleTvWalls()
          .pipe(
            switchMap(res => {
              return [
                AppleTvActions.getWallsSuccess({ walls: res }),
              ];
            }),
            catchError(response => {
              return [
                AppleTvActions.getWallsFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public getWallFromDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.getWallFromDevice),
      switchMap(({ edgeId }) => {
        return this.appleTvService
          .getWall(edgeId)
          .pipe(
            timeout(15000),
            mergeMap(res => {
              const data = res.data;
              return [
                AppleTvActions.getWallFromDeviceSuccess({ edgeId: res.edgeId, wall: data.wall, isEqual: data.isEqual }),
              ];
            }),
            catchError(response => {
              const msg = this.getErrorMessage(response);
              let isNotFoundWall = response.status === HttpStatusCode.NotFound;
              return [
                AppleTvActions.getWallFromDeviceFail({ edgeId, message: msg, wallNotFound: isNotFoundWall }),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public startConfigureAppleTv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppleTvActions.startConfigureAppleTv),
      debounceTime(400),
      mergeMap(({ document }) => [
        AppleTvActions.saveAppleTvRequest({ document, isEdit: true }),
      ]),
    ),
  );

  constructor(private appleTvService: AppleTvService,
              private actions$: Actions,
              private store$: Store<AppState>,
              private wall2Service: WallV2Service) {
  }

  private appleTvSaveService(isEdit: boolean, selectedLocationId: string, document: Partial<AppleTvModels.AppleTvDocument>) {
    if (!isEdit) {
      return this.appleTvService
        .create({
          locationId: selectedLocationId,
          edgeId: document.edgeId,
          name: document.name,
          wallId: document.wallId,
        });
    } else {
      return this.appleTvService
        .configureWall({
          locationId: selectedLocationId,
          edgeId: document.edgeId,
          name: document.name,
          wallId: document.wallId,
        });
    }
  }


  private getErrorMessage(response: HttpErrorResponse | TimeoutError) {
    if (response instanceof TimeoutError) {
      return 'Couldn\'t connect. Ensure the device is on, connected to the internet, and try again.';
    } else {
      if (AppleTvModels.IsDeviceErrorResponse(response)) {
        const error = response.error as AppleTvModels.DeviceErrorResponse;
        return error?.error?.message ?? 'Unknown error';
      } else {
        return response?.error?.message ?? 'Unknown error';
      }
    }
  }
}
