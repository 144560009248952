import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { api } from '@consts/url.const';
import { HttpService } from '../core/http.service';
import { DoorModels } from '@models/door.model';
import { AddPeopleRequest, Person } from "@models/people.model";

@Injectable()
export class DoorsService {
  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  public getDoors(): Observable<DoorModels.GetDoorsResponse> {
    return this.http.get<DoorModels.GetDoorsResponse>(api.doors.crud);
  }

  public getUnsavedDoors(page?: number, size?: number): Observable<DoorModels.GetDoorsResponse> {
    let url = `${api.doors.crud}?status=unsaved`;
    if ((page || page === 0) && size) {
      url += `&pageNumber=${page}&pageSize=${size}`;
    }
    return this.http.get<DoorModels.GetDoorsResponse>(url);
  }

  public getSavedDoors(): Observable<DoorModels.GetDoorsResponse> {
    const url = `${api.doors.crud}?status=saved`;
    return this.http.get<DoorModels.GetDoorsResponse>(url);
  }

  public addDoor(door: DoorModels.Door) {
    const request: DoorModels.AddDoorRequest = {
      newName: door.name,
      isSaved: true,
    };
    return this.http.put(api.doors.add(door.id), request);
  }

  public editDoor(door: DoorModels.Door) {
    const request: DoorModels.AddDoorRequest = {
      newName: door.name,
      isSaved: true,
    };
    return this.http.put(api.doors.edit(door.id), request);
  }

  public removeDoor(door: DoorModels.Door) {
    return this.http.delete(api.doors.remove(door.id));
  }

}
