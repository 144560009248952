<div fxLayout="column" class="search-block">
  <div class="ui-input-container">
    <span class="input-icon-start ui-icon-search ui-mat-icon-16" [class.input-40]="heightPx === 40"></span>
    <input
      #inputElement
      class="ui-input search-input"
      [placeholder]="placeholder"
      [ngModel]="searchQuery"
      [class.ui-input-40]="heightPx === 40"
      [matAutocomplete]="auto"
      (ngModelChange)="change($event)"
      (focus)="onFocus.emit()"
      (blur)="onBlur.emit()"
      [class.no-focus]="noFocus"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
