import { createAction, props } from '@ngrx/store';
import { PermissionGroupModels } from '@models/group.model';
import { PermissionModel } from '@models/permission.model';
import { AppUser } from '../../../user/user.model';
import { UserOrganizationDropDown } from '@models/organization.model';
import AccessRightsModel = PermissionGroupModels.AccessRightsModel;
import User = AppUser.User;

export const resetToInitialState = createAction('[PermissionGroup] Reset to Initial state');
export const resetEntities = createAction('[PermissionGroup] Reset Entities');

export const beforeGetPermissionGroups = createAction('[PermissionGroup] Before Get PermissionGroups');
export const getPermissionGroups = createAction('[PermissionGroup] Get PermissionGroups from Server');
export const getPermissionGroupsSuccess = createAction('[PermissionGroup] Get PermissionGroups Success', props<{ permissionGroups: PermissionGroupModels.PermissionGroupDocumentBase[] }>());
export const getPermissionGroupsFail = createAction('[PermissionGroup] Set PermissionGroups Fail');

export const setIsLoading = createAction('[PermissionGroup] Set Is Loading', props<{ isLoading: boolean }>());
export const setFilter = createAction('[PermissionGroup] Set Filter Property', props<{ property: string, value: any }>());

export const getSelectedPermissionGroup = createAction('[PermissionGroup] Get Selected PermissionGroup', props<{ id: string }>());
export const getSelectedPermissionGroupFail = createAction('[PermissionGroup] Get Selected PermissionGroup Fail');
export const setSelectedPermissionGroup = createAction('[PermissionGroup] Set Selected PermissionGroup', props<{ selectedPermissionGroup: PermissionGroupModels.PermissionGroupDocumentBase }>());

export const savePermissionGroup = createAction('[PermissionGroup] Save PermissionGroup', props<{ permissionGroup: PermissionGroupModels.PermissionGroupDocumentBase }>());
export const savePermissionGroupSuccess = createAction('[PermissionGroup] Save PermissionGroup Success', props<{ permissionGroup: PermissionGroupModels.PermissionGroupDocumentBase }>());
export const savePermissionGroupFail = createAction('[PermissionGroup] Save PermissionGroup Fail');

export const changeUsers = createAction('[PermissionGroup] Change PermissionGroup Users', props<{ users: { [userId: string]: Object } }>());
export const changeUsersSuccess = createAction('[PermissionGroup] Change PermissionGroup Users Success', props<{ users: { [userId: string]: Object } }>());
// export const selectPermissionsByEntity = createAction('[PermissionGroup] Select Permissions by Entity', props<{ entity: PermissionGroupModels.PermissionListItem }>());
// export const selectAllPermissions = createAction('[PermissionGroup] Select All Permissions');

export const updatePermissionGroup = createAction('[PermissionGroup] Update Permission Group');
export const updatePermissionGroupSuccess = createAction('[PermissionGroup] Update Permission Group  Success', props<{ permissionGroup: PermissionGroupModels.PermissionGroupDocumentBase }>());
export const updatePermissionGroupFail = createAction('[PermissionGroup] Update Permission Group  Fail');


export const removePermissionGroup = createAction('[PermissionGroup] Remove PermissionGroup', props<{ id: string }>());
export const removePermissionGroupSuccess = createAction('[PermissionGroup] Remove PermissionGroup Success', props<{ id: string }>());
export const removePermissionGroupFail = createAction('[PermissionGroup] Remove PermissionGroup Fail', props<{ id: string }>());

export const removeGroupUser = createAction('[PermissionGroup] Remove Group User', props<{ userId: string }>());
export const removeGroupUserSuccess = createAction('[PermissionGroup] Remove Group User Success', props<{ userId: string }>());
export const removeGroupUserFail = createAction('[PermissionGroup] Remove Group User Fail', props<{ userId: string }>());

export const changePermission = createAction('[PermissionGroup] Change Role Permission', props<{ permission: PermissionModel.Permissions }>());
export const selectPermissionsByEntity = createAction('[PermissionGroup] Select Permissions by Entity', props<{ entity: PermissionModel.PermissionListItem }>());
export const selectAllPermissions = createAction('[PermissionGroup] Select All Permissions');

export const setActiveEntity = createAction('[PermissionGroup] Set Active Entity', props<{ selectedEntity: PermissionModel.SelectedEntity }>());
export const makeInherited = createAction('[PermissionGroup] Make Inherited');

export const registerParentStructure = createAction('[PermissionGroup] Register Parent Structure', props<{ entityId: string, parentId: string }>());

export const getOrganizationUsers = createAction('[PermissionGroup] Get Organization Users');
export const getOrganizationUsersSuccess = createAction('[PermissionGroup] Get Organization Users Success', props<{ users: UserOrganizationDropDown[] }>());
export const getOrganizationUsersFail = createAction('[PermissionGroup] Get Organization Users Fail');


export const getSelectedUserPermissions = createAction('[PermissionGroup] Get Selected User Permissions', props<{ userId: string; }>());
export const getSelectedUserPermissionsSuccess = createAction('[PermissionGroup] Get Selected User Permissions Success',
  props<{
    selectedUserPermissions: AccessRightsModel,
    user: Partial<User>
  }>());
export const getSelectedUserPermissionsFail = createAction('[PermissionGroup] Get Selected User Permissions Fail');

