import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addId',
})
export class AddId implements PipeTransform {
  transform(value: string[], ...args: unknown[]): { _id: string }[] {
    return value?.map(val => {
      return {
        _id: val,
      };
    });
  }
}
