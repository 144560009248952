import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VariableState } from '@states/variable/variable.reducer';

export const selectVariableState = createFeatureSelector<VariableState>('variableState');

export const selectSelectedCameras = createSelector(selectVariableState, ({ selectedCameras }: VariableState) => selectedCameras);

export const selectLevel2MenuValid = createSelector(selectVariableState, ({ level2MenuValid }: VariableState) => level2MenuValid);

export const selectVariables = createSelector(selectVariableState, ({ variables }: VariableState) => variables);

export const selectQuery = createSelector(selectVariableState, ({ query }: VariableState) => query);

export const selectTotalItemsCount = createSelector(selectVariableState, ({ totalItemsCount }: VariableState) => totalItemsCount);

export const selectPage = createSelector(selectVariableState, ({ page }: VariableState) => {
  return page;
});

export const selectPerPage = createSelector(selectVariableState, ({ perPage }: VariableState) => perPage);

export const selectObjectSelections = createSelector(selectVariableState, ({ objectSelections }: VariableState) => objectSelections);

export const selectSelectedPersonObjectType = createSelector(
  selectVariableState,
  ({ selectedPersonObjectType }: VariableState) => selectedPersonObjectType
);
