<div fxLayout="column" fxLayoutAlign="start center" class="layout-selector" [class.thumbnail]="thumbnail">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 layout-selector-title">
    <div>Change layout</div>
    <div fxLayoutAlign="center center" class="icon-18">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div *ngIf="!thumbnail" fxLayout="row wrap" fxLayoutAlign="start center" class="layout-list w-100">
    <mat-icon svgIcon="1-cam" (click)="select(WallLayout.GRID_1)"></mat-icon>
    <mat-icon svgIcon="2-cam" (click)="select(WallLayout.GRID_2)"></mat-icon>
    <mat-icon svgIcon="3-cam" (click)="select(WallLayout.GRID_3)"></mat-icon>
    <mat-icon svgIcon="4-cam" (click)="select(WallLayout.GRID_4)"></mat-icon>
    <mat-icon svgIcon="4-cam_1_3" (click)="select(WallLayout.GRID_4_1_3)"></mat-icon>
    <mat-icon svgIcon="5-cam_1_4" (click)="select(WallLayout.GRID_5_1_4)"></mat-icon>
    <mat-icon svgIcon="6-cam_1_5" (click)="select(WallLayout.GRID_6_1_5)"></mat-icon>
    <mat-icon svgIcon="6-cam_2_4" (click)="select(WallLayout.GRID_6_2_4)"></mat-icon>
    <mat-icon svgIcon="6-cam" (click)="select(WallLayout.GRID_6)"></mat-icon>
    <mat-icon svgIcon="6-cam_v" (click)="select(WallLayout.GRID_6_VERTICAL)"></mat-icon>
    <mat-icon svgIcon="7-cam_3_4" (click)="select(WallLayout.GRID_7_3_4)"></mat-icon>
    <mat-icon svgIcon="7-cam_1_6" (click)="select(WallLayout.GRID_7_1_6)"></mat-icon>
    <mat-icon svgIcon="8-cam_v" (click)="select(WallLayout.GRID_8_VERTICAL)"></mat-icon>
    <mat-icon svgIcon="8-cam_h" (click)="select(WallLayout.GRID_8)"></mat-icon>
    <mat-icon svgIcon="9-cam" (click)="select(WallLayout.GRID_9)"></mat-icon>
    <mat-icon svgIcon="9-cam_1_8" (click)="select(WallLayout.GRID_9_1_8)"></mat-icon>
    <mat-icon svgIcon="10-cam_2_8" (click)="select(WallLayout.GRID_10_2_8)"></mat-icon>
    <mat-icon svgIcon="10-cam_2_8_v" (click)="select(WallLayout.GRID_10_2_8_v)"></mat-icon>
    <mat-icon svgIcon="12-cam_1_11" (click)="select(WallLayout.GRID_12_1_11)"></mat-icon>
    <mat-icon svgIcon="12-cam_12" (click)="select(WallLayout.GRID_12_12)"></mat-icon>
    <mat-icon svgIcon="13-cam_1_center" (click)="select(WallLayout.GRID_13_1_center)"></mat-icon>
    <mat-icon svgIcon="13-cam_1_12" (click)="select(WallLayout.GRID_13_1_12)"></mat-icon>
    <mat-icon svgIcon="16-cam" (click)="select(WallLayout.GRID_16)"></mat-icon>
    <mat-icon svgIcon="16-cam_1_15" (click)="select(WallLayout.GRID_16_1_15)"></mat-icon>
    <mat-icon svgIcon="20-cam" (click)="select(WallLayout.GRID_20)"></mat-icon>
    <mat-icon svgIcon="20-cam_2_18" (click)="select(WallLayout.GRID_20_2_18)"></mat-icon>
    <mat-icon svgIcon="25-cam" (click)="select(WallLayout.GRID_25)"></mat-icon>
    <mat-icon svgIcon="30-cam" (click)="select(WallLayout.GRID_30)"></mat-icon>
    <mat-icon svgIcon="36-cam" (click)="select(WallLayout.GRID_36)"></mat-icon>
    <mat-icon svgIcon="48-cam" (click)="select(WallLayout.GRID_48)"></mat-icon>
  </div>
  <div *ngIf="thumbnail" fxLayout="row wrap" fxLayoutAlign="center center" class="layout-list w-100">
    <mat-icon svgIcon="1-cam" (click)="select(WallLayout.GRID_1)"></mat-icon>
    <mat-icon svgIcon="2-cam" (click)="select(WallLayout.GRID_2)"></mat-icon>
    <mat-icon svgIcon="3-cam" (click)="select(WallLayout.GRID_3)"></mat-icon>
    <mat-icon svgIcon="4-cam" (click)="select(WallLayout.GRID_4)"></mat-icon>
    <mat-icon svgIcon="6-cam" (click)="select(WallLayout.GRID_6)"></mat-icon>
    <mat-icon svgIcon="9-cam" (click)="select(WallLayout.GRID_9)"></mat-icon>
  </div>
</div>
