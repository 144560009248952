import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import * as GrantedAccessActions from '@states/granted-access/granted-access.actions';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { Archive, ArchiveStatus } from '@models/archive.model';
import { PreloaderColor } from '@enums/shared.enum';
import * as LocationModel from '../../locations/location.model';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../authentication/authentication.service';
import { GrantedAccessEffect } from '@effects/granted-access.effect';
import { ofType } from '@ngrx/effects';
import { GrantedAccessModel } from '@models/granted-access-model';
import { Comment, CommentEntityType, CommentRequest } from '@models/comment';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { PlaybackPlayerService } from 'src/app/shared/playback-player/playback-player.service';
import { PlaybackPlayerComponent } from 'src/app/shared/playback-player/playback-player.component';
import { ArchiveEffect } from '@effects/archive.effect';
import * as moment from 'moment-timezone';
import { ARCHIVE_ON_ERROR_MIN } from '@consts/archive.conts';
import ArchiveDocument = Archive.ArchiveDocument;
import { routerSegments } from '@consts/routes';
import * as ArchiveActions from '@states/archive/archive.actions';
import { HttpStatusCode } from '@angular/common/http';
import { SocketEvents } from '../../socket/socket.model';
import { SocketMainService } from '../../socket/socket-main.service';
import { getArchiveCloudPreviewUri } from '../../helpers/common.helpers';
import * as ArchiveAction from '@states/archive/archive.actions';


@UntilDestroy()
@Component({
  selector: 'app-granted-access',
  templateUrl: './granted-access.component.html',
  styleUrls: ['./granted-access.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrantedAccessComponent implements OnInit {

  @ViewChild('playback')
  playback: PlaybackPlayerComponent;

  public selectInitialLoaded$: Observable<boolean> = this.store$.pipe(select(GrantedAccessSelectors.selectInitialLoaded));
  public selectIsNotFound$: Observable<boolean> = this.store$.pipe(select(GrantedAccessSelectors.selectIsNotFound));
  public isRecordProtected$: Observable<boolean> = this.grantedAccessEffect
    .checkGrantedAccess$
    .pipe(ofType(GrantedAccessActions.checkGrantedAccessProtected), tap(() => this.isSubmit = false), map(res => res.isProtected));

  public selectSharedArchive$: Observable<ArchiveDocument> = this.store$.pipe(select(GrantedAccessSelectors.selectSharedArchive));
  public selectSharedLiveView$: Observable<GrantedAccessModel.SharedLiveView> = this.store$.pipe(select(GrantedAccessSelectors.selectSharedLiveView));
  public selectSharedPlayBack$: Observable<GrantedAccessModel.SharedPlayback> = this.store$.pipe(select(GrantedAccessSelectors.selectSharedPlayback));


  public selectSelectedLocation$: Observable<LocationModel.LocationModel.LocationItem> = this.store$.pipe(select(GrantedAccessSelectors.selectSelectedLocation));
  public selectSharedNameAndDate$: Observable<{ name: string; timestamp: number }> = this.store$.pipe(select(GrantedAccessSelectors.selectSharedNameAndDate));
  public selectConfig$: Observable<GrantedAccessModel.GrantedAccessConfig> = this.store$.pipe(select(GrantedAccessSelectors.selectConfig));
  public selectType$: Observable<GrantedAccessType> = this.store$.pipe(select(GrantedAccessSelectors.selectType));
  public selectAccessToken$: Observable<string> = this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken));
  public selectComments$: Observable<CommentRequest[]> = this.store$.pipe(select(GrantedAccessSelectors.selectComments));

  public isEmbedded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public keepRatio$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public archiveUrl: string;
  public sharedArchive: ArchiveDocument;
  public grantedAccessTypes = GrantedAccessType;

  public loaderColor = PreloaderColor;
  public isPasswordVisible: boolean = false;
  public isSubmit: boolean = false;
  public password: string;
  public isExpired: boolean = false;
  @ViewChild('player') videoPlayer: ElementRef;
  public addCommentHide: boolean = true;

  private isFirebaseSubscribed = false;
  private id: string;

  constructor(
    private playbackPlayerService: PlaybackPlayerService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store<AppState>,
    public authenticationService: AuthenticationService,
    private grantedAccessEffect: GrantedAccessEffect,
    private location: Location,
    private archiveEffects: ArchiveEffect,
    private socketMainService: SocketMainService) {
  }

  public get now() {
    return Date.now();
  }

  ngAfterViewInit(): void {
    this.playbackPlayerService
      .playbackBase$
      .pipe(filter(res => !!res.sessionId))
      .subscribe(res => {
        this.playback?.reloadPlayer();
      });

    this.selectSharedArchive$
      .pipe(
        untilDestroyed(this),
        filter(archive => !!archive),
      )
      .subscribe(res => {
        this.archiveUrl = this.getCloudPreviewUri(res as ArchiveDocument);
        this.sharedArchive = res as ArchiveDocument;
      });
  }

  ngOnInit(): void {
    this.selectAccessToken$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        take(1),
      )
      .subscribe(async res => {
        await this.socketMainService.connect(res);
        this.socketMainService.consume<{ data: Archive.ArchiveDocument, error: string, statusCode: HttpStatusCode }>(SocketEvents.edgeArchiveProgressNotification)
          .subscribe((res) => {
            this.store$.dispatch(ArchiveActions.archiveProgressSocketUpdate({ archive: res.data, error: res.error, statusCode: res.statusCode }));
          });
      });

    this.route.params.pipe(
        untilDestroyed(this))
      .subscribe(res => {
        this.id = res['id'];
        this.store$.dispatch(GrantedAccessActions.checkGrantedAccess({ id: this.id }));
      });

    this.route.queryParams
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(res => {
        if (res['embedded'] === 'true') {
          this.isEmbedded$.next(true);
        }
        if (res['ratio'] === 'true') {
          this.keepRatio$.next(true);
        }
      });

    this.selectSharedPlayBack$
      .pipe(
        untilDestroyed(this),
        filter(res => !res),
      )
      .subscribe(res => {
        this.initBaseOnEntityType(res);
      });


    this.grantedAccessEffect
      .checkGrantedAccess$
      .pipe(
        untilDestroyed(this),
        ofType(GrantedAccessActions.checkGrantedAccessSuccess),
      )
      .subscribe(() => {
        this.loadPage();
      });

    /**
     * Needs to generate download url for header.
     */
    this.selectSharedArchive$
      .pipe(
        untilDestroyed(this),
        filter(res => !!res),
      )
      .subscribe(res => {
        const diff = moment()
          .diff(moment(res.progressUpdateTime * 1000));
        const duration = moment.duration(diff);
        if ((res.status === ArchiveStatus.UPLOADING) && duration.asMinutes() > ARCHIVE_ON_ERROR_MIN) {
          this.store$.dispatch(GrantedAccessActions.updateSharedArchive({ archive: { ...res, status: ArchiveStatus.ERROR } }));
        }

      });

    this.archiveEffects.archiveProgressSocketUpdate$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(res => {
        if (res.statusCode !== HttpStatusCode.NotFound) {
          this.store$.dispatch(GrantedAccessActions.updateSharedArchive(res));
        }
      });

    this.archiveEffects.downloadArchive$
      .pipe(
        untilDestroyed(this),
        ofType(ArchiveAction.downloadArchiveSuccess),
      )
      .subscribe(res => {
        window.open(res.url, '_blank');
      });
  }

  public signIn(): void {
    this.router.navigate([routerSegments.auth]);
  }

  public back() {
    this.location.back();
  }

  public submitPassword() {
    this.isSubmit = true;
    this.store$.dispatch(GrantedAccessActions.checkGrantedAccess({ id: this.id, body: { password: this.password } }));
  }

  public commentAdd(comment: Comment) {
    this.store$.dispatch(GrantedAccessActions.addComment({
      comment: {
        ...comment,
        entityId: this.id,
        entityType: CommentEntityType.grantedAccess,
      },
    }));
    this.addCommentHide = !this.addCommentHide;
  }

  public cancelComment() {
    this.addCommentHide = true;
  }

  initBaseOnEntityType(entity: GrantedAccessModel.SharedPlayback) {

    if (!!entity?.alertTime) {
      this.playbackPlayerService.setTime(entity.alertTime);
      // setTimeout(() => {
      //
      //   this.playbackPlayerService.initTimeLine(entity.alertTime);
      // }, 2000)

    }
  }

  public downloadArchive() {
    this.store$.dispatch(ArchiveActions.downloadArchive({ archive: this.sharedArchive }));
  }

  private loadPage(): void {
    this.store$.dispatch(GrantedAccessActions.getConfig({ id: this.id }));
    this.store$.dispatch(GrantedAccessActions.viewShared({ id: this.id }));
    this.store$.dispatch(GrantedAccessActions.getComments({ id: this.id }));
  }

  public getCloudPreviewUri(archive: ArchiveDocument) {
    return getArchiveCloudPreviewUri(archive);
  }


}
