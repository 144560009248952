<div fxLayout="column" fxLayoutAlign="start start" fxFill class="ui-orgGroup-dialog" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{ !!data?.orgGroup?.id ? 'Edit ' + data?.orgGroup?.name : 'Create a group' }}</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div [formGroup]="form"
       class="dialog-content narrower-input"
  >
    <div fxLayout="column" class="w-100 gap-16">
      <div>Group name</div>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Untitled group" formControlName="name"/>
      </mat-form-field>
      <ui-org-group-member-selector [members]="data?.orgGroup?.members" (membersChange)="membersChange($event)"
                                    [type]="data?.orgGroup?.type"
      ></ui-org-group-member-selector>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button type="primary" (clickAction)="done()"
    >
      {{ !!data?.orgGroup?.id ? 'Save' : 'Create' }}
    </ui-button>
    <ui-preloader [color]="PreloaderColor.blue" [diameter]="20" *ngIf="loading"></ui-preloader>
  </div>

</div>
