import { PulsationModels } from '@models/pulsation.model';

export const pulsationComponentConverter: { [Property in PulsationModels.StreamerComponentAsNumber] } = {
  [PulsationModels.StreamerComponentAsNumber.Input]: 'input', // camera main stream
  [PulsationModels.StreamerComponentAsNumber.Hls]: 'hls',
  [PulsationModels.StreamerComponentAsNumber.HlsScaled]: 'hlsscaled',
  [PulsationModels.StreamerComponentAsNumber.HlsScaledHQ]: 'hlsscaledhq',
  [PulsationModels.StreamerComponentAsNumber.Storage]: 'storage', // storage
  [PulsationModels.StreamerComponentAsNumber.SmartStorage]: 'smartstorage', // smart storage
  [PulsationModels.StreamerComponentAsNumber.WebRTC]: 'webrtc',
  [PulsationModels.StreamerComponentAsNumber.WebRTCHQ]: 'webrtchq',
  [PulsationModels.StreamerComponentAsNumber.Analytics]: 'analytics', // analytics
  [PulsationModels.StreamerComponentAsNumber.Edge]: 'edge', // edge
  [PulsationModels.StreamerComponentAsNumber.SubStream1]: 'substream1', // storage stream
  [PulsationModels.StreamerComponentAsNumber.Dockers]: 'dockers', // dockers

};

export const pulsationStatusConverter: { [Property in PulsationModels.ComponentStatusAsNumber] } = {
  [PulsationModels.ComponentStatusAsNumber.Online]: 'online',
  [PulsationModels.ComponentStatusAsNumber.Offline]: 'offline',
  [PulsationModels.ComponentStatusAsNumber.Streaming]: 'streaming',
  [PulsationModels.ComponentStatusAsNumber.Stopped]: 'stopped',
  [PulsationModels.ComponentStatusAsNumber.NotAuthenticated]: 'notAuthenticated',
  [PulsationModels.ComponentStatusAsNumber.StreamNotFound]: 'streamNotFound',
  [PulsationModels.ComponentStatusAsNumber.Unknown]: 'unknown',
  [PulsationModels.ComponentStatusAsNumber.Init]: 'init',
  [PulsationModels.ComponentStatusAsNumber.Connected]: 'connected',
  [PulsationModels.ComponentStatusAsNumber.Installing]: 'installing',
  [PulsationModels.ComponentStatusAsNumber.Rebooting]: 'rebooting',
  [PulsationModels.ComponentStatusAsNumber.NotConnected]: 'notConnected',
  [PulsationModels.ComponentStatusAsNumber.Exit]: 'exit',
  [PulsationModels.ComponentStatusAsNumber.NotRecording]: 'notRecording',
};
