import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[STORAGE-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetStoragePulsationStatus = createAction('[STORAGE-HEARTBEAT-PULSATION] GetStoragePulsationStatus');

export const GetStoragePulsationStatusSuccess = createAction('[STORAGE-HEARTBEAT-PULSATION] GetStoragePulsationStatusSuccess',
    props<{
        payload: PulsationModels.Store.StoragePulsation[]
    }>());

export const GetStoragePulsationStatusFail = createAction('[STORAGE-HEARTBEAT-PULSATION] GetStoragePulsationStatusFail', props<{ message?: string }>());

