import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, ThumbnailsState } from './thumbnails.reducer';
import * as moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { ThumbnailModel } from '@models/thumbnail.model';

const FREQUENCY = 2000;
const BASE_16 = 16000;

export const selectThumbnailsState = createFeatureSelector<ThumbnailsState>('thumbnailsState');

export const selectAllThumbnails = createSelector(selectThumbnailsState, selectAll);

export const selectEventsByEdgeIdCameraIdAndBase = (selector: { edgeId: string; cameraId: string; base: number }) =>
  createSelector(
    selectThumbnailsState,
    (thumbnails: ThumbnailsState) => thumbnails.entities[`${selector.edgeId}:${selector.cameraId}:${selector.base}`],
  );

export const selectBitsByEdgeIdCameraIdAndBase = (selector: { edgeId: string; cameraId: string; base: number }) =>
  createSelector(
    selectThumbnailsState,
    (thumbnails: ThumbnailsState) => thumbnails.entities[`${selector.edgeId}:${selector.cameraId}:${selector.base}`]?.bits,
  );



export const selectEventsByEdgeIdCameraIdAndRange = (selector: { edgeId: string; cameraId: string; start: number; end: number }) =>
  createSelector(selectThumbnailsState, (thumbnails: ThumbnailsState) => getSlice(selector, thumbnails));

const getBaseInLocale = (dateRaw: Date, tz: string = 'GMT') => {
  /**
   * dateRaw is given in browser timezone, and should be 'converted' to edge timezone
   * conversion is done by calculating the offset between the browser timezone and the edge timezone
   * then the offset is added to the original date, so it will be aligned with the edge time.
   * Then, we calculate the GMT timestamp using a conversion with moment and the original timezone
   * (which is the browser tz)
   */
  const browserTz = moment.tz.guess(); // ex. 'America/San Jose'
  const timezone = 'GMT';
  const dateInEdgeTz = moment.tz(dateRaw, timezone)
    .toString();
  const startOfDay = DateTime.fromJSDate(new Date(dateInEdgeTz))
    .setZone(timezone)
    .startOf('day')
    .toJSDate();
  const currentLocale = moment.tz(startOfDay, timezone)
    .format('YYYY-MM-DD HH:mm');
  return moment.tz(currentLocale, 'GMT')
    .unix() * 1000;
};

const getEventLocation = (timestamp: number, base: number, freq: number = FREQUENCY) => {
  return Math.floor((timestamp - base) / freq);
};



const spreadEvents = (eventsRaw: number[]): number[] => {
  const events: number[] = [];
  for (let index = 0; index < eventsRaw.length; index++) {
    let val = eventsRaw[index];
    if (val) {
    }
    events.push(val & 0x0000000f);
    events.push((val & 0x000000f0) >> (4 * 1));
    events.push((val & 0x00000f00) >> (4 * 2));
    events.push((val & 0x0000f000) >> (4 * 3));
    events.push((val & 0x000f0000) >> (4 * 4));
    events.push((val & 0x00f00000) >> (4 * 5));
    events.push((val & 0x0f000000) >> (4 * 6));
    events.push((val & 0xf0000000) >> (4 * 7));
  }
  return events;
};

const getSlice = (selector: ThumbnailModel.SelectSlice, state: ThumbnailsState) => {
  const startTime = selector.start;
  const endTime = selector.end;
  const base = getBaseInLocale(new Date(selector.start));
  const baseEnd = getBaseInLocale(new Date(selector.end));
  const eventsBase = state.entities[`${selector.edgeId}:${selector.cameraId}:${base}`]?.events;
  const eventsBaseEnd = state.entities[`${selector.edgeId}:${selector.cameraId}:${baseEnd}`]?.events;
  if (!eventsBase || !eventsBaseEnd) {
    // TODO: Subscribe to error in store
    return null;
  }
  const clipInSeconds = (selector.end - selector.start) / 1000;
  const rawStart = getEventLocation(startTime, base, BASE_16);
  const rawEnd = getEventLocation(endTime, baseEnd, BASE_16);
  if (base !== baseEnd && baseEnd !== selector.end) {
    const spreadStart = spreadEvents(eventsBase.slice(rawStart, eventsBase.length));
    const spreadEnd = spreadEvents(eventsBaseEnd.slice(0, rawEnd + 1));
    const tempBase = base + rawStart * BASE_16;
    const indexStart = getEventLocation(startTime, tempBase);
    const indexEnd = getEventLocation(endTime, baseEnd);
    // We need to combine 2 days to one array
    let first: number[] = [];
    let second: number[] = [];
    if (!!eventsBase) {
      first = spreadStart.slice(indexStart, eventsBase.length);
    }
    if (!!eventsBaseEnd) {
      second = spreadEnd.slice(0, indexEnd);
    }
    return first.concat(second);
  } else {
    const spread = spreadEvents(eventsBase.slice(rawStart, rawEnd + 1));
    const tempBase = base + rawStart * BASE_16;
    const indexStart = getEventLocation(startTime, tempBase);
    const indexEnd = getEventLocation(endTime, tempBase);
    return spread.slice(indexStart, indexEnd);
  }
};
