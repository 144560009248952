<div class="video-wrapper">
<div #playerWrapper fxLayout="column" fxLayoutAlign="center center" class="player-wrapper p-r"
     [style.height.px]="(playerWrapper.clientWidth * 9) / 16 + 1"
>
  <ui-area-selector *ngIf=" !inZoom()"
                    (selected)="zoomArea($event)"
  ></ui-area-selector>
  <div fxLayout="row" fxFlex="" #wrapper class="video-wrapper">
    <!--  <video #player-->
    <!--         [src]="src"-->
    <!--         autoplay-->
    <!--         id="video"-->
    <!--         (load)="loaded($event)"-->
    <!--         (error)="error($event);isError = true;"-->
    <!--         crossorigin="anonymous"-->
    <!--         [poster]="isError?'assets/player/archive-poster.jpg':''"-->
    <!--         controlsList="nodownload"-->
    <!--  >-->
    <!--  </video>-->
    <div class="status-chip">
      {{timestamp + video.currentTime * 1000 | date : 'hh:mm:ss'}} {{timezoneAbbreviation()}}
    </div>
    <div *ngIf="allowZoom && zoomState.scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label"
         (click)="resetZoom()"
    >
      <div class="reset">Reset</div>
      <div class="zoom">{{ zoomState.scale * 100 | number : '1.0-0' }}%</div>
    </div>
    <div *ngIf="allowZoom && showZoomButtons"
         fxLayout="column"
         fxLayoutGap="5px"
         class="manual-zoom-wrapper c-p"
    >
      <div fxLayoutAlign="center center" (click)="zoomIn();$event.stopPropagation()">
        <mat-icon>add</mat-icon>
      </div>
      <div fxLayoutAlign="center center" (click)="resetZoom();$event.stopPropagation()">
        <mat-icon>refresh</mat-icon>
      </div>
      <div fxLayoutAlign="center center" (click)="zoomOut();$event.stopPropagation()">
        <mat-icon>remove</mat-icon>
      </div>
    </div>
    <!--    <div #preview class="preview" [class.display-none]="!inZoom()" cdkDragBoundary=".p-wrapper" cdkDrag-->
    <!--         (cdkDragStarted)="preview.classList.add('move')" (cdkDragEnded)="preview.classList.remove('move')"-->
    <!--         [ngClass]="{ invisible: zoomState.scale === 1 }" (mousedown)="$event.stopPropagation();"-->
    <!--    >-->
    <!--      <canvas #previewCanvas id="c1" width="208" height="117"></canvas>-->
    <!--    </div>-->
    <div class="video-wrapper"
         (mousewheel)="zoom($event)"
         (mousedown)="dragStart($event);$event.stopPropagation()"
         (mouseup)="zoomState.dragging = false"
         (mousemove)="drag($event);$event.stopPropagation()"
         [ngClass]="{ grab: zoomState.dragging }"
    >
      <video #player
             [src]="src"
             autoplay
             id="video"
             (load)="loaded($event)"
             (error)="error($event);isError = true;"
             crossorigin="anonymous"
             controlsList="nodownload"
      >
      </video>
    </div>
    <div class="controls" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="icon ui-icon-player-backward" (click)="player.currentTime = player.currentTime - 15"></span>
        <span *ngIf="!player.paused" class="icon ui-icon-pause" (click)="player.pause()"></span>
        <span *ngIf="player.paused" class="icon ui-icon-play" (click)="player.play()"></span>
        <span class="icon ui-icon-player-backward forward" (click)="player.currentTime=player.currentTime+15"></span>
      </div>
      <div fxFlex class="progress-wrapper p-r"

      >
        <div #progressBar
             (mousedown)="startSeek()"
             (mouseup)="stopSeek()"
             (mouseleave)="stopSeek()"
             (mousemove)="seek($event)"
             (click)="clickSeek($event)"
             class="progress-overlay"

        ></div>
        <mat-progress-bar class="video-progress"
                          mode="determinate"
                          [value]="player.currentTime * 100 / player.duration"

        ></mat-progress-bar>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
       <span fxLayout="row" fxLayoutAlign="start center" class="time">
                    {{player.currentTime|secToMin }} / {{player.duration|secToMin }}
       </span>
        <span class="icon ui-icon-snapshoot" (click)="takeSnapshot()"></span>
        <span class="icon ui-icon-fullscreen" (click)="maximize()"></span>
      </div>
    </div>
  </div>
</div>
