import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public version = packageInfo.version;

  constructor(private dialogRef: MatDialogRef<AboutComponent>) {
  }

  ngOnInit(): void {
  }


  public openTerms() {
    window.location.href = 'https://www.lumana.ai/terms-of-use';
  }

  public openPrivacy() {
    window.location.href = 'https://www.lumana.ai/privacy-policy';
  }

  public close() {
    this.dialogRef.close();
  }
}
