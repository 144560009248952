import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-browser-compatibility-modal',
  templateUrl: './browser-compatibility-modal.component.html',
  styleUrl: './browser-compatibility-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowserCompatibilityModalComponent {

  constructor(private dialogRef: MatDialogRef<BrowserCompatibilityModalComponent>) {
  }

  public close(): void {
    this.dialogRef.close();
  }
}
