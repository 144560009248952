import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;
import { Observable } from 'rxjs';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { VehicleModels } from '@models/vehicle.model';
import { VehiclesSelectors } from '@states/vehicles/vehicles.selector-types';

export interface UiVehicleDialogData {
  vehicle?: VehicleModels.Vehicle;
  toExisting?: boolean;
  selector?: boolean;
  list?: VehicleModels.Vehicle[];
}

@UntilDestroy()
@Component({
  selector: 'app-ui-vehicle-dialog',
  templateUrl: './ui-vehicle-dialog.component.html',
  styleUrls: ['./ui-vehicle-dialog.component.scss'],
})
export class UiVehicleDialogComponent implements OnInit {

  public noSaved = features.vehicles.noSaved;

  public PreloaderColor = PreloaderColor;
  public selectSavedVehicles$: Observable<VehicleModels.Vehicle[]> = this.store$.pipe(select(VehiclesSelectors.selectSavedVehicles));

  public selectedVehicleId = '';
  public selectedVehicleIds = [];
  loaded = false;
  closed;
  opened;

  public query = '';

  public loading = false;

  form = new UntypedFormGroup({
    owner: new UntypedFormControl(''),
    plate: new UntypedFormControl('', [Validators.required]),
    make: new UntypedFormControl(''),
    color: new UntypedFormControl(''),
  });


  constructor(
    private store$: Store,
    @Inject(MAT_DIALOG_DATA) public data: UiVehicleDialogData,
    private dialogRef: MatDialogRef<UiVehicleDialogComponent>) {
  }

  public get formControls() {
    return Object.keys(this.form?.controls);
  }

  toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0)
        .toUpperCase() + txt.substr(1)
        .toLowerCase();
    });
  }

  ngOnInit(): void {
    if (this.data?.vehicle) {
      const vehicle = this.data?.vehicle;
      if (vehicle.plate) {
        this.form.patchValue({
          ...vehicle,
          plate: vehicle.plate.toUpperCase(),
          color: this.toTitleCase(vehicle.color),
        });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close({
      ...this.form.value,
      plate: this.form.value.plate.toLowerCase(),
    });
  }

  public ngOnDestroy(): void {
  }

  select(id: string) {
    if (this.data.selector) {
      if (this.selectedVehicleIds.includes(id)) {
        this.selectedVehicleIds.splice(this.selectedVehicleIds.indexOf(id), 1);
      } else {
        this.selectedVehicleIds.push(id);
      }
      return;
    }
    this.selectedVehicleId = id;
  }

  public filtered(vehicles: VehicleModels.Vehicle[]) {
    return vehicles.filter(p => p.plate.toLowerCase()
      .includes(this.query.toLowerCase()));
  }

  public exists(id: string) {
    return !this.data.list.map(p => p.plate)
      .includes(id);
  }


}
