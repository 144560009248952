<form #form="ngForm">
  <intl-tel-input
    #tell
    [options]="{
                   preferredCountries: ['us'],
               }"
    [(E164PhoneNumber)]="value"
    [cssClass]="'w-100 tel-input ui-input '+ inputClass"
    (input)="updateValue()"
  ></intl-tel-input>
  <span *ngIf="isVerified" class="ui-icon-done"></span>
</form>
