import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter, map, Observable, shareReplay, take, takeWhile, tap } from 'rxjs';
import { ActiveOrganization, OrganizationUser } from '@models/organization.model';
import { select, Store } from '@ngrx/store';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import * as OrganizationActions from '@states/organization/organization.actions';
import { AlertEventNotification } from '@models/alert-events.model';

import { KeyValuePairs } from 'src/app/core/interfaces';

import { AlertNotificationMethod } from "@models/alerts-v2.model";

@Component({
  selector: 'ui-user-selector',
  templateUrl: './ui-user-selector.component.html',
  styleUrls: ['./ui-user-selector.component.scss'],
})
export class UiUserSelectorComponent implements OnInit {
  searchString: string = '';

  AlertNotificationMethod: typeof AlertNotificationMethod = AlertNotificationMethod;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public selectOrganizationUsers$: Observable<OrganizationUser[]> = this.store$.pipe(select(OrganizationSelectors.selectOrganizationUsers));

  users: OrganizationUser[] = [];

  @Input() orgUsers: OrganizationUser[] = [];
  @Input() manualUsers: OrganizationUser[] = [];
  @Input() notificationMethods: KeyValuePairs<AlertNotificationMethod[]> = {};
  @Input() notifications: AlertEventNotification;

  @Output() change: EventEmitter<AlertEventNotification> = new EventEmitter<AlertEventNotification>();

  loaded = false;

  constructor(
    private store$: Store,
    public dialogRef: MatDialogRef<UiUserSelectorComponent>,
  ) {
  }

  ngOnInit(): void {
    this.store$.dispatch(OrganizationActions.setOrganizationUsers({users: []}));
    this.selectOrganizationUsers$
      .pipe(
        shareReplay(),
        // takeWhile(orgs => orgs.length === 0, true),
        tap(orgs => {
        }),
        filter(orgs => orgs.length !== 0),
      )
      .subscribe(users => {
        this.loaded = true;
        this.users = users;
      });

    this.store$.dispatch(OrganizationActions.setQuery({query: ''}));
    this.store$.dispatch(OrganizationActions.getOrganizationUsers());

  }

  public get filteredUsers() {
    return this.users.filter(user => !this.orgUsers.some(u => u.id === user.id));
  }

  public onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.store$.dispatch(OrganizationActions.setQuery({query: searchQuery}));
  }

  save() {
    this.notifications.orgUsers = Array.from(this.orgUsers);
    this.notifications.notificationMethods = this.notificationMethods;
    this.change.emit(this.notifications);

  }

  isUserSelected(user: OrganizationUser) {
    return !!this.notificationMethods[user.id];
  }

  addUser(user: OrganizationUser) {
    this.orgUsers.push({...user});
    this.notificationMethods[user.id] = this.defaultMethod(user);
    this.save();
    this.searchString = '';
    this.store$.dispatch(OrganizationActions.setQuery({query: ''}));
  }

  defaultMethod(user: OrganizationUser): AlertNotificationMethod[] {
    return [AlertNotificationMethod.EMAIL, AlertNotificationMethod.SMS, AlertNotificationMethod.MOBILE];
  }

  getOptionText(person: OrganizationUser) {
    return person?.firstname;
  }
}
