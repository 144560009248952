import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { AsyncPipe, NgIf } from '@angular/common';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UiCameraLocationRowComponent } from '../ui-camera-selector/components/ui-camera-location-row/ui-camera-location-row.component';
import { UiKitModule } from '../ui-kit.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-ui-dropdown',
  standalone: true,
  imports: [
    MatBadge,
    AsyncPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    NgIf,
    UiCameraLocationRowComponent,
    UiKitModule,
  ],
  templateUrl: './ui-dropdown.component.html',
  styleUrl: './ui-dropdown.component.scss',
})
export class UiDropdownComponent implements OnChanges {
  public opened = false;
  @Input() height: number;
  private dialogOpening = false;
  private dialogRef: MatDialogRef<any>;
  private clickOutsideEventHandler: (event: any) => void;

  @ViewChild('selectorTemplate')
  private selectorTemplate: TemplateRef<any>;

  @ViewChild('selectorInput')
  private selectorInput: ElementRef<HTMLElement>;

  @ViewChild('dialogContainer')
  private dialogContainer: ElementRef<HTMLElement>;

  constructor(public dialog: MatDialog, private cdr: ChangeDetectorRef, private window: Window) {
  }

  @HostListener('document:click', ['$event'])
  clickOutsideEvent(event: MouseEvent) {
    if (this.clickOutsideEventHandler) {
      this.clickOutsideEventHandler(event);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes['height'].firstChange){
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }

  }

  public toggleDialog(): void {
    if (!this.dialogOpening) {
      this.dialogOpening = true;

      if (!this.opened) {
        const position = this.getPosition();

        this.dialogRef = this.dialog.open(this.selectorTemplate, {
          hasBackdrop: false,
          backdropClass: 'ui-selector-backdrop',
          position,
          panelClass: 'ui-selector-panel',
          closeOnNavigation: true,
        });

        this.dialogRef
          .afterOpened()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = this.closeDialogByClickOutsideEvent;
            this.opened = true;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            window.addEventListener('scroll', this.scroll.bind(this), true);
          });

        this.dialogRef
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = null;
            this.opened = false;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            window.removeEventListener('scroll', this.scroll, true);
          });
      }
    }
  }


  private closeDialogByClickOutsideEvent(event: MouseEvent) {
    this.dialogRef.close();
    // const matDialogContainerElement = this.dialogContainer.nativeElement.parentElement;
    // const elementRectangle = matDialogContainerElement.getBoundingClientRect();
    // if (
    //   event.clientX <= elementRectangle.left ||
    //   event.clientX >= elementRectangle.right ||
    //   event.clientY <= elementRectangle.top ||
    //   event.clientY >= elementRectangle.bottom
    // ) {
    //   this.dialogRef.close();
    // }
  }

  private scroll(): void {
    if (this.dialogRef && this.opened) {
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }
  }

  private getPosition(): DialogPosition {
    const elementRectangle = this.selectorInput?.nativeElement.getBoundingClientRect();
    const bottomSpace = this.window.innerHeight - elementRectangle.bottom;

    let panelHeight = this.height;
    if (panelHeight < bottomSpace) {
      return { left: `${elementRectangle.left + elementRectangle.width + 7}px`, top: `${elementRectangle.bottom}px` };
    } else {
      let top = this.window.innerHeight - panelHeight;
      if (top < 5) {
        top = 5;
      }
      return { left: `${elementRectangle.left + elementRectangle.width + 7}px`, top: `${top - 5}px` };
    }
  }
}
