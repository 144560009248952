import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';


export const resetToInitialState = createAction('[EDGE-HEARTBEAT-PULSATION] Reset to Initial state');
export const setLoading = createAction('[EDGE-HEARTBEAT-PULSATION] Set Loading', props<{ loading: boolean }>());

export const GetEdgePulsationStatus = createAction('[EDGE-HEARTBEAT-PULSATION] GetEdgePulsationStatus');

export const GetEdgePulsationStatusSuccess = createAction('[EDGE-HEARTBEAT-PULSATION] GetEdgePulsationStatusSuccess',
  props<{
    payload: PulsationModels.Store.EdgePulsation,
    session?: PulsationModels.HeartbeatStatusSessionDocument
  }>());

export const GetEdgePulsationStatusFail = createAction('[EDGE-HEARTBEAT-PULSATION] GetEdgePulsationStatusFail', props<{ message?: string }>());

