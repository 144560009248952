import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss'],
})
export class BillingSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
