import { createAction, props } from '@ngrx/store';
import { WebhookModel } from '@models/webhook.model';

export const resetToInitialState = createAction('[WEBHOOK] Reset to Initial state');

export const getOrganizationWebhooks = createAction('[WEBHOOK] Get Organization Webhooks');
export const getOrganizationWebhooksSuccess = createAction('[WEBHOOK] Get Organization Webhooks Success', props<{ documents: WebhookModel.DocumentMongo[] }>());
export const getOrganizationWebhooksFail = createAction('[WEBHOOK] Get Organization Webhooks Fail');

export const startCreateWebhook = createAction('[WEBHOOK] Start create webhook', props<{ webhook: WebhookModel.CreateDocumentDto }>());
export const sendCreateWebhookRequest = createAction('[WEBHOOK] Send create webhook request', props<{ webhook: WebhookModel.CreateDocumentDto }>());
export const sendCreateWebhookRequestSuccess = createAction('[WEBHOOK] Send create webhook request Success', props<{ webhook: WebhookModel.DocumentMongo }>());
export const sendCreateWebhookRequestFail = createAction('[WEBHOOK] Send create webhook request Fail');

export const setIsCreatingLoader = createAction('[WEBHOOK] Set is creating Loader', props<{ isCreating: boolean }>());

export const startDeleteWebhook = createAction('[WEBHOOK] Start delete webhook', props<{ id: string }>());
export const sendDeleteWebhookRequest = createAction('[WEBHOOK] Send delete webhook request', props<{ id: string }>());
export const sendDeleteWebhookRequestSuccess = createAction('[WEBHOOK] Send delete webhook request Success', props<{ id: string }>());
export const sendDeleteWebhookRequestFail = createAction('[WEBHOOK] Send delete webhook request Fail', props<{ id: string }>());

export const startUpdateWebhook = createAction('[WEBHOOK] Start Update webhook', props<{ webhook: WebhookModel.DocumentMongo }>());
export const sendUpdateWebhookRequest = createAction('[WEBHOOK] Send Update webhook request', props<{ webhook: WebhookModel.DocumentMongo }>());
export const sendUpdateWebhookRequestSuccess = createAction('[WEBHOOK] Send Update webhook request Success', props<{ webhook: WebhookModel.DocumentMongo }>());
export const sendUpdateWebhookRequestFail = createAction('[WEBHOOK] Send Update webhook request Fail');
