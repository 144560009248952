import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as moment from 'moment';

@Component({
  selector: 'widget-graph-chart',
  templateUrl: './widget-graph.component.html',
  styleUrls: ['./widget-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetGraphComponent implements OnChanges, AfterViewInit {
  @Input() data: { name: string; data: { count: number; timestamp: number }[] }[];

  @ViewChild('svgContainer', { read: ElementRef, static: true })
  svgContainerRef!: ElementRef<HTMLDivElement>;
  public svg!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private axisXLabels: number[];
  private axisYMax: number = 0;
  private axisYMin: number = -0.5;
  private axisXMax: number;
  private axisXMin: number;
  public legends: string[] = [];
  private dataReady: any;
  private margin = { top: 10, right: 30, bottom: 70, left: 50 };
  private isRendered = false;
  private height: number = 350;
  private width: number = 0;
  public isFirstInit: boolean = true;
  private isViewInited: boolean = false;
  public colors: string[] = ['#e41a1c', '#377eb8', '#4daf4a', '#800080', '#ffa500'];

  public selectedLegends: string[] = [];

  public variableColors = {};
  public selectedAll: boolean = true;
  private chartNameRegex = new RegExp(/[^a-zA-Z\d-]+/g);
  constructor() {}

  public ngAfterViewInit() {
    /**
     * Calculate width when view is inited and all widgt is accessible
     */
    this.width = this.svgContainerRef.nativeElement.getBoundingClientRect().width - this.margin.left - this.margin.right;
    this.isViewInited = true;
    if (this.data && this.isViewInited) {
      this.init();
    }
  }

  public ngOnChanges() {
    this.axisYMax = 0;
    // clear data of svg each time
    this.svgContainerRef.nativeElement.innerHTML = '';
    this.isRendered = false;
    this.isFirstInit = true;
    if (this.data && this.isViewInited) {
      this.init();
    }
  }

  private init(): void {
    this.legends = this.data.map(variable => variable.name);
    if (this.isFirstInit) {
      this.selectedLegends = [...this.legends];
      this.isFirstInit = false;
    }
    /**
     * Need to find the largest array. Cause can receive arrays with different data size.
     */
    if (!this.data.length) {
      return;
    }
    const dataArraysLengths = this.data.map(item => item.data.length);
    const largestArray = Math.max(...dataArraysLengths);
    const indexOfLargestArray = dataArraysLengths.indexOf(largestArray);
    this.axisXLabels = this.data[indexOfLargestArray].data.map(variable => variable.timestamp).reverse();
    Object.values(this.data).forEach(variable => {
      variable.data.forEach(data => {
        if (this.axisYMax < data.count) {
          this.axisYMax = data.count;
        }
        if (this.axisYMin > data.count) {
          this.axisYMin = data.count;
        }
      });
    });
    const axisX = this.data[indexOfLargestArray].data.map(variable => variable.timestamp);
    this.axisXMax = Math.max(...axisX);
    this.axisXMin = Math.min(...axisX);
    if (this.axisYMax === 0) {
      this.axisYMax = 1;
    }
    if (this.axisXMax < 1) {
      this.axisXMax = 1;
    }
    this.dataReady = this.data.map((variable, index) => {
      this.variableColors[variable.name] = this.colors[index];
      return {
        name: variable.name,
        values: variable.data.map(function (d) {
          return { time: d.timestamp, value: d.count };
        }),
      };
    });
    this.createGroupedChart();
  }

  public selectAll() {
    this.selectedAll = !this.selectedAll;
    if (!this.selectedAll) {
      this.selectedLegends = [];
    } else {
      this.selectedLegends = [...this.legends];
    }
    this.legends.forEach(legend => {
      this.hideLegend(legend, !this.selectedAll ? 1 : 0);
    });
  }

  public hideLegend(name: string, visible?: number) {
    const query = name.replace(this.chartNameRegex, '');
    let currentOpacity = visible;
    if (typeof visible === 'undefined') {
      currentOpacity = parseInt(this.svg.selectAll('.' + query).style('opacity'));
      const index = this.selectedLegends.indexOf(name);
      if (index > -1) {
        this.selectedLegends.splice(index, 1);
      } else {
        this.selectedLegends.push(name);
      }
    }

    this.svg.selectAll('.' + query)
      .transition()
      .style('opacity', currentOpacity === 1 ? 0 : 1);
  }

  private createGroupedChart() {
    if (!this.isRendered) {
      this.createSVG();
    }

    // color palette = one color per subgroup
    const myColor = d3.scaleOrdinal().domain(this.legends).range(this.colors);

    // Add X axis --> it is a date format
    const x = d3.scaleLinear().domain([this.axisXMin, this.axisXMax]).range([0, this.width]);
    // .padding(0.2);

    this.svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .style('font-size', '12px')
      /**
       * Highlight 1-st and last elements
       */
      .style('font-weight', (d, i) => {
        if (i == 0 || i == this.axisXLabels.length - 1) {
          return 'bold';
        }
        return 'normal';
      })
      .text((d: any) => {
        let format = 'DD/MM HH:mm';
        const duration = moment.duration(moment(this.axisXMax).diff(this.axisXMin));
        if (duration.asHours() < 1) {
          format = 'HH:mm:ss';
        }
        return moment.unix(parseInt(d) / 1000).format(format);
      });

    // Add Y axis
    const y = d3.scaleLinear().domain([this.axisYMin, this.axisYMax]).range([this.height, 0]);
    this.svg.append('g').call(d3.axisLeft(y));

    // Add the lines
    const line = d3
      .line()
      .x((d: any) => {
        return x(+d.time);
      })
      .y((d: any) => y(+d.value));

    this.svg
      .selectAll('myLines')
      .data(this.dataReady)
      .enter()
      .append('path')
      .attr('class', (d: any) => {
        return d.name.replace(this.chartNameRegex, '');
      })
      .attr('d', (d: any) => {
        return line(d.values);
      })
      // @ts-ignore

      .attr('stroke', (d: any) => {
        return myColor(d.name);
      })
      .style('stroke-width', 1)
      .style('fill', 'none')
      .style('opacity', (d: any) => (this.selectedLegends.includes(d.name) ? 1 : 0));

    // Add the points
    this.svg
      // First we need to enter in a group
      .selectAll('myDots')
      .data(this.dataReady)
      .enter()
      .append('g')
      // @ts-ignore
      .style('fill', (d: any) => {
        return myColor(d.name);
      })
      .style('opacity', (d: any) => (this.selectedLegends.includes(d.name) ? 1 : 0))
      .attr('class', (d: any) => {
        return d.name.replace(this.chartNameRegex, '');
      })
      // Second we need to enter in the 'values' part of this group
      .selectAll('myPoints')
      .data((d: any) => {
        return d.values;
      })
      .enter()
      .append('circle')
      .attr('cx', function (d: any) {
        return x(d.time);
      })
      .attr('cy', function (d: any) {
        return y(d.value);
      })
      .attr('r', 1.5);
    // .attr('stroke', 'white');

    // tooltip
    d3.selectAll('circle')
      .on('mouseover', () => {
        return tooltip.style('visibility', 'visible');
      })
      // @ts-ignore
      .on('mousemove', (event, data: { time: string; value: any }) => {
        const formattedDate = moment.unix(parseInt(data.time) / 1000).format('DD/MM HH:mm');
        return tooltip
          .text(`${formattedDate}, ${data?.value}`)
          .style('top', event.pageY + 'px')
          .style('left', event.pageX + 15 + 'px')
          .style('width', '120px')
          .style('color', '#fff')
          .style('text-align', 'center')
          .style('border-radius', '6px')
          .style('padding', '5px 0')
          .style('background-color', 'black');
      })
      .on('mouseout', () => {
        return tooltip.style('visibility', 'hidden');
      });

    // create a tooltip
    const tooltip = d3
      .select(this.svgContainerRef.nativeElement)
      .append('div')
      .style('position', 'fixed')
      .style('visibility', 'hidden')
      .attr('class', 'tooltiptext');
  }

  private createSVG(): void {
    this.isRendered = true;
    this.height = 350 - this.margin.top - this.margin.bottom;
    // append the svg object to the svg container
    this.svg = d3
      .select(this.svgContainerRef.nativeElement)
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('width', this.width + this.margin.left + this.margin.right);
  }
}
