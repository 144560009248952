import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, forkJoin, lastValueFrom, map, Observable, of, switchMap, take, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { routerSegments } from '@consts/routes';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RouterPermissionGuard implements CanActivate {
  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  constructor(private store$: Store<AppState>, private router: Router,
              private route: ActivatedRoute) {
  }

  public canActivate(
    currentRoute: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isDeveloper$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.store$.pipe(select(PermissionSelectors.checkAccessPermissions(currentRoute.data['permissions'] ?? [], [])))),
        map(([isDeveloper, isAllowed]) => {
          const isNotAuthorised = !isAllowed && !isDeveloper;
          if (isNotAuthorised) {
            this.router.navigate([routerSegments.notFound], { relativeTo: this.route, skipLocationChange: true });
          }
          return !isNotAuthorised;
        }),
      );
  }
}
