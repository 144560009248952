<ng-container *ngrxLet="selectSelectedUserSettings$; let selectedUserSettings">
  <ng-container [ngSwitch]="dateTypeFormat">
    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.timeSec">
      {{ date | date : (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm:ss a' : 'HH:mm:ss') }}
    </ng-container>

    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.time">
      {{ date | date : (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm a' : 'HH:mm') }}
    </ng-container>

    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.mdTime">
      <ng-container *ngIf="selectedUserSettings.dateFormat === uiUserSettingsDateFormats.ddMMyy;else mdTimeDefault">
        {{ date | date : 'dd/MM ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm a' : 'HH:mm') }}
      </ng-container>
      <ng-template #mdTimeDefault>
        {{ date | date : 'MM/dd ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm a' : 'HH:mm') }}
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.mdyTime">
      <ng-container *ngIf="selectedUserSettings.dateFormat === uiUserSettingsDateFormats.ddMMyy;else mdyTimeDefault">
        {{ date | date : 'dd/MM/yy ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm a' : 'HH:mm') }}
      </ng-container>
      <ng-template #mdyTimeDefault>
        {{ date | date : 'MM/dd/yy ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm a' : 'HH:mm') }}
      </ng-template>

    </ng-container>

    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.mdyTimeSec">
      <ng-container *ngIf="selectedUserSettings.dateFormat === uiUserSettingsDateFormats.ddMMyy;else mdyTimeDefault">
        {{ date | date : 'dd/MM/yy ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm:ss a' : 'HH:mm:ss') }}
      </ng-container>
      <ng-template #mdyTimeDefault>
        {{ date | date : 'MM/dd/yy ' + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? 'h:mm:ss a' : 'HH:mm:ss') }}
      </ng-template>

    </ng-container>

    <ng-container *ngSwitchCase="uiUserSettingsDateTypes.mdy">
      <ng-container *ngIf="selectedUserSettings.dateFormat === uiUserSettingsDateFormats.ddMMyy;else mdy">
        {{ date | date : 'dd/MM/yy' : timezone }}
      </ng-container>
      <ng-template #mdy>
        {{ date | date : 'MM/dd/yy' : timezone }}
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{
      date
        | date
        : selectedUserSettings.dateFormat + (selectedUserSettings.hoursFormat === hoursFormat.hours12 ? ' h:mm a' : ' HH:mm')
      }}
    </ng-container>
  </ng-container>
  {{ timeZoneStr }}
</ng-container>

