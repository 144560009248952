import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UiUploadService } from './ui-upload.service';
import { Upload } from './ui-upload.models';

export enum UiUploadLayout {
  DEFAULT,
  LARGE,
}

@Component({
  selector: 'ui-upload',
  templateUrl: './ui-upload.component.html',
  styleUrls: ['./ui-upload.component.scss'],
})
export class UiUploadComponent implements OnInit {
  @Output() onUploaded: EventEmitter<any> = new EventEmitter<any>();

  @Output() clickUpload: EventEmitter<File> = new EventEmitter<File>();

  @Input() isUploading: boolean = false;

  @Input() progressEnabled: boolean = true;

  @Input() layout: UiUploadLayout = UiUploadLayout.DEFAULT;

  @Input() hideList = false;

  public UiUploadLayout = UiUploadLayout;

  public uploadedFile: File;

  uploadProgress$: Observable<Upload>;

  constructor(private uiUploadService: UiUploadService) {
  }

  ngOnInit(): void {
    this.uploadProgress$ = this.uiUploadService.uploadProgress$;
  }

  public upload(ev) {
    this.fileChangeEvent({
      currentTarget: {
        files: ev,
      },
      target: {
        files: ev,
      },
      srcElement: {
        files: ev,
      },
    });
    this.uiUploadService.setProgress({state: 'PENDING', progress: 0});
  }

  public fileChangeEvent(event: any): void {
    this.uploadedFile = event.target.files[0];
    this.onUploaded.emit(event);
  }

  public uploadAction() {
    this.clickUpload.emit(this.uploadedFile);
  }

  public clear() {
    this.uploadedFile = null;
  }
}
