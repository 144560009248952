<div>
  <camera-picker
    [edgeId]="data.edgeId"
    [multi]="data.multi"
    [inputSelectedCameras]="data.selectedCameras??[]"
    [withAlerts]="data.withAlerts"
    (onSave)="save($event)"
    [atLeastOne]="data.atLeastOne"
    [zoneSelection]="data.zoneSelection"
    [zoneSelectionType]="data.zoneSelectionType"
    [edgeOnlySelect]="data.edgeOnlySelect"
    [selectedEdges]="data.selectedEdges"
    [cameraSelectionLimit]="data.cameraSelectionLimit"
    [selectedCamerasV2]="data.selectedCamerasV2"
    (onSaveEdges)="saveEdges($event)"
    [fetchMissingObjectData]="data.fetchMissingObjectData"
  ></camera-picker>
</div>
