import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PeopleState, selectAll } from '@states/people/people.reducer';

export const selectPeopleState = createFeatureSelector<PeopleState>('peopleState');

export const selectAllPeople = createSelector(selectPeopleState, selectAll);
export const selectSavedPeople =
  createSelector(
    selectAllPeople,
    people =>
      people.filter(person => !!person),
    // .sort((a, b) => b.appearances - a.appearances),
  );

export const selectUnsavedPeople =
  createSelector(
    selectPeopleState,
    state => state.buckets,
  );

export const selectPersonById = (id: number) => createSelector(selectPeopleState, (people: PeopleState) => people.entities[id]);
export const selectBucketById = (id: number) => createSelector(selectPeopleState,
  (state: PeopleState) => state.buckets.filter(bucket => bucket.bucketId === id)[0]);

export const selectToken =
  createSelector(
    selectPeopleState,
    state => state.token,
  );

export const selectPersonNameById = (personId: number) => createSelector(selectPeopleState, (people: PeopleState) => people.entities[personId]?.name);


