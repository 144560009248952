import { Component, OnInit } from '@angular/core';
import { SelectionColors } from '@models/canvas-selector.model';
import { Zones } from '../ui-kit/ui-zone-selector/ui-zone-selector.model';

@Component({
  selector: 'app-zone-selector-wrapper',
  templateUrl: './zone-selector-wrapper.component.html',
  styleUrls: ['./zone-selector-wrapper.component.scss'],
})
export class ZoneSelectorWrapperComponent implements OnInit {
  SelectionColors: typeof SelectionColors = SelectionColors;

  zones: Zones = {
    ['{x: 0.5, y: 0.5}']: {
      name: 'Zone1',
      color: 'green',
      selection: [
        { x: 0.5, y: 0.5 },
        { x: 0.9, y: 0.7 },
        { x: 0.9, y: 0.9 },
        { x: 0.5, y: 0.5 },
      ],
      markedIdx: [],
    },
  };

  markedIdx: number[];

  constructor() {
  }

  ngOnInit(): void {
  }

  update(update: { zones: Zones; markedIdx: number[] }) {
    this.zones = update.zones;
    this.markedIdx = update.markedIdx;
  }
}
