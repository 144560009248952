export namespace OrgGroupModels {

  export interface OrgGroupMember {
    memberId: number | string;
  }

  export enum OrgGroupType {
    Vehicles = 'vehicles',
    People = 'people',
    Doors = 'doors',
    Users = 'users'
  }

  export interface OrgGroup {
    id?: string;
    name: string;
    type: OrgGroupType;
    members: OrgGroupMember[];
  }

  export type GetOrgGroupsResponse = OrgGroup[];

  export type AddOrgGroupRequest = OrgGroupModels.OrgGroup;

}



