import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, mergeMap, share, switchMap } from 'rxjs';
import { OrgGroupsActions } from '@states/org-groups/org-groups.action-types';
import { OrgGroupsService } from 'src/app/development/org-groups.service';

@Injectable()
export class OrgGroupsEffect {

  public getOrgGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgGroupsActions.getOrgGroups),
      mergeMap(({orgGroupType}) => {
          return this.orgGroupsService.getOrgGroups(orgGroupType)
            .pipe(
              switchMap(res => {
                return [SharedActions.setIsLoading({isLoading: false}), OrgGroupsActions.getOrgGroupsSuccess({orgGroups: res})];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({isLoading: false}), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public addOrgGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgGroupsActions.addOrgGroup),
      mergeMap(({orgGroup}) => {

          return this.orgGroupsService.addOrgGroup(orgGroup)
            .pipe(
              switchMap(res => {
                return [OrgGroupsActions.addOrgGroupSuccess({orgGroup: res})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editOrgGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgGroupsActions.editOrgGroup),
      mergeMap(({orgGroup}) => {

          return this.orgGroupsService.editOrgGroup(orgGroup)
            .pipe(
              switchMap(res => {
                return [OrgGroupsActions.editOrgGroupSuccess({orgGroup})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removeOrgGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgGroupsActions.removeOrgGroup),
      mergeMap(({orgGroup}) => {

          return this.orgGroupsService.removeOrgGroup(orgGroup)
            .pipe(
              switchMap(res => {
                return [OrgGroupsActions.removeOrgGroupSuccess({orgGroup})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private orgGroupsService: OrgGroupsService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({error: response?.error?.message});
  }

}
