import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, debounceTime, exhaustMap, mergeMap, of, share, switchMap, takeUntil } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { WorkspaceActions } from '@states/workspace/workspace.action-types';
import { withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { WorkspaceService } from '../../development/workspace.service';
import { WidgetDeprecated, WidgetBase, WorkspaceRequest } from '@models/workspace.model';
import { VariableService } from '../../development/variable.service';

@Injectable()
export class WorkspaceEffects {
  public saveWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.saveWorkspace),
      withLatestFrom(this.store$.pipe(select(state => state.workspaceState))),
      exhaustMap(([{}, {selectedWorkspace}]) => {
        if (!selectedWorkspace.name) {
          return of(SharedActions.showMessage({error: 'Workspace name is required'}));
        }
        return [SharedActions.setIsSaving({isSaving: true}), WorkspaceActions.sendWorkspaceToServer()];
      })
    )
  );

  public sendWorkspaceToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.sendWorkspaceToServer),
      withLatestFrom(this.store$.pipe(select(state => state.workspaceState))),
      switchMap(([{}, {selectedWorkspace}]) => {
        const request: WorkspaceRequest = {
          ...selectedWorkspace,
          widgets: this.transformWidgets(selectedWorkspace.widgets),
        };

        if (selectedWorkspace._id) {
          return this.workspaceService.update(request).pipe(
            switchMap(() => {
              return [
                WorkspaceActions.sendWorkspaceToServerSuccess(),
                SharedActions.showMessage({success: 'Workspace has been successfully updated'}),
                WorkspaceActions.getFavorites(),
                WorkspaceActions.updateEditMode({isEditMode: false}),
              ];
            }),
            catchError(err => of(SharedActions.doNothing()))
          );
        } else {
          return this.workspaceService.create(request).pipe(
            switchMap(() => {
              return [
                WorkspaceActions.sendWorkspaceToServerSuccess(),
                WorkspaceActions.getFavorites(),
                WorkspaceActions.updateEditMode({isEditMode: false}),
                SharedActions.showMessage({success: 'Workspace has been successfully created'}),
              ];
            }),
            catchError(err => of(SharedActions.doNothing()))
          );
        }
      }),
      share()
    )
  );

  public getWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.getWorkspaces),
      withLatestFrom(this.store$.pipe(select(state => state.workspaceState))),
      switchMap(([, {page, perPage, orderBy, orderDirection, query}]) => {
        return this.workspaceService.getWorkspaces(page, perPage, orderBy, orderDirection, query).pipe(
          switchMap(result => {
            return [
              WorkspaceActions.getWorkspacesSuccess({workspaces: result.items}),
              WorkspaceActions.setTotalItemsCount({
                totalItemsCount: result.totalItemsCount,
              }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.getFavorites),
      withLatestFrom(this.store$.pipe(select(state => state.workspaceState))),
      switchMap(() => {
        return this.workspaceService.getFavorites().pipe(
          switchMap(result => {
            return [WorkspaceActions.getFavoritesSuccess({favorites: result})];
          })
        );
      }),
      share()
    )
  );

  public getWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.getWorkspace),
      switchMap(({id}) => {
        return this.workspaceService.one(id).pipe(
          switchMap(workspace => {
            return [WorkspaceActions.getWorkspaceSuccess({workspace})];
          }),
          catchError(err => of(WorkspaceActions.getWorkspaceFail()))
        );
      })
    )
  );

  public removeWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.removeWorkspace),
      switchMap(({id}) => {
        return this.workspaceService.remove(id).pipe(
          switchMap(workspace => {
            return [
              WorkspaceActions.removeWorkspaceSuccess(),
              SharedActions.showMessage({success: 'Workspace has been successfully removed'}),
              WorkspaceActions.getWorkspaces(),
              WorkspaceActions.getFavorites(),
            ];
          }),
          catchError(err => of(WorkspaceActions.removeWorkspaceFail()))
        );
      })
    )
  );

  public addFavoriteWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.addFavoriteWorkspace),
      switchMap(({workspace}) => {
        return this.workspaceService.addToFavorite({_id: workspace._id}).pipe(
          switchMap(_ => {
            return [
              WorkspaceActions.addFavoriteWorkspaceSuccess({workspace}),
              SharedActions.showMessage({success: 'Workspace has been added to favorites'}),
            ];
          }),
          catchError(err => of(WorkspaceActions.addFavoriteWorkspaceFail()))
        );
      })
    )
  );

  public removeFavoriteWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.removeFavoriteWorkspace),
      switchMap(({workspaceId}) => {
        return this.workspaceService.removeFromFavorites({_id: workspaceId}).pipe(
          switchMap(_ => {
            return [
              WorkspaceActions.removeFavoriteWorkspaceSuccess({workspaceId}),
              SharedActions.showMessage({success: 'Workspace has been removed from favorites'}),
            ];
          }),
          catchError(err => of(WorkspaceActions.removeFavoriteWorkspaceFail()))
        );
      })
    )
  );

  public getVariablesAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.getVariablesAutocomplete),
      switchMap(({query, notType, cameraId, variableType}) => {
        return this.variablesService.autocomplete(query, notType, cameraId, variableType).pipe(
          switchMap(res => {
            return [WorkspaceActions.getVariablesAutocompleteSuccess({variablesAutoComplete: res})];
          }),
          catchError(err => of(WorkspaceActions.getVariablesAutocompleteFail()))
        );
      })
    )
  );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setQuery),
      debounceTime(400),
      switchMap(() => of(WorkspaceActions.getWorkspaces()))
    )
  );

  public getWidgetData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.getWidgetData),
      mergeMap(({variables, relative, relativeCustom, widgetIndex, absolute, widgetType, cameraId}) => {
        return this.workspaceService
          .getWidgetData({
            widgetType,
            variables,
            relative,
            absolute: absolute
              ? {
                start: new Date(absolute.start).getTime(),
                end: new Date(absolute.end).getTime(),
                timezone: absolute.timezone,
              }
              : null,
            cameraId,
            relativeCustom: relativeCustom,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(WorkspaceActions.cancelLoadWidgetData, WorkspaceActions.clearFilters, WorkspaceActions.setAbsoluteDate)
              )
            ),
            mergeMap(res => {
              return [WorkspaceActions.getWidgetDataSuccess({data: res, index: widgetIndex})];
            }),
            catchError(err => of(WorkspaceActions.getWidgetDataFail()))
          );
      }),
      share()
    )
  );

  public removeVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.removeVariable),
      withLatestFrom(this.store$.pipe(select(state => state.workspaceState))),
      switchMap(([{widgetIndex, variable}, {selectedWidget}]) => {
        const variables = [...selectedWidget.variables];
        const rmIdx = variables.findIndex(variable => variable);
        variables.splice(rmIdx, 1);
        return of(WorkspaceActions.updateWidget({field: 'variables', value: variables, index: widgetIndex}));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private workspaceService: WorkspaceService,
    private variablesService: VariableService
  ) {
  }

  private transformWidgets(widgets: WidgetDeprecated[]): WidgetBase[] {
    return widgets.map(widget => {
      return {
        type: widget.type,
        name: widget.name,
        viewMode: widget.viewMode,
        variables: widget.variables,
        variableConfigs: widget.variableConfigs,
        absolute: widget.absolute,
        relative: widget.relative,
        cameraId: widget.cameraId,
        relativeCustom: widget.relativeCustom,
      };
    });
  }
}
