import { createAction, props } from '@ngrx/store';
import { ExternalStorageModels } from '@models/external-storage.models';

export const resetToInitialState = createAction('[EXTERNAL-STORAGE] Reset to Initial state');

export const getExternalStorage = createAction('[EXTERNAL-STORAGE] Get External Storage');
export const getExternalStorageSuccess = createAction('[EXTERNAL-STORAGE] Get External Storage Success', props<{ documents: ExternalStorageModels.ExternalStorageDocument[] }>());
export const getExternalStorageFail = createAction('[EXTERNAL-STORAGE] Get External Storage Fail');

export const setQueryStringFilter = createAction('[EXTERNAL-STORAGE] Set Query String Filter', props<{ prop: string; value: string }>());
export const resetEntities = createAction('[EXTERNAL-STORAGE] Reset Entities');

export const startSaveExternalStorage = createAction('[EXTERNAL-STORAGE] Start Save External Storage', props<{ document: Partial<ExternalStorageModels.ExternalStorageDocument>, isEdit: boolean }>());
export const saveExternalStorageRequest = createAction('[EXTERNAL-STORAGE] Save External Storage Request', props<{
  document: Partial<ExternalStorageModels.ExternalStorageDocument>,
  isEdit: boolean
}>());
export const saveExternalStorageRequestSuccess = createAction('[EXTERNAL-STORAGE] Save External Storage Request Success');
export const saveExternalStorageRequestFail = createAction('[EXTERNAL-STORAGE] Save External Storage Request Fail');

export const setIsSaving = createAction('[EXTERNAL-STORAGE] Set Is Saving', props<{ isSaving: boolean }>());

export const startDeleteExternalStorage = createAction('[EXTERNAL-STORAGE] Start Delete External Storage', props<{ storageId: string }>());
export const deleteExternalStorage = createAction('[EXTERNAL-STORAGE] Delete External Storage', props<{ storageId: string }>());
export const deleteExternalStorageSuccess = createAction('[EXTERNAL-STORAGE] Delete External Storage Success', props<{ storageId: string }>());
export const deleteExternalStorageFail = createAction('[EXTERNAL-STORAGE] Delete External Storage Fail');

export const onSocketCreatedSuccess = createAction('[EXTERNAL-STORAGE] On Socket Created Success', props<{ document: ExternalStorageModels.ExternalStorageDocument }>());
export const onSocketCreatedError = createAction('[EXTERNAL-STORAGE] On Socket Created Error', props<{ error: string, authProviderId: string }>());
export const saveExternalStorageSetOne = createAction('[EXTERNAL-STORAGE] Save External Storage Set One ', props<{ document: ExternalStorageModels.ExternalStorageDocument }>());

export const getOneExternalStorage = createAction('[EXTERNAL-STORAGE] Get One External Storage', props<{ id: string }>());
export const getOneExternalStorageSuccess = createAction('[EXTERNAL-STORAGE] Get One External Storage Success', props<{ document: ExternalStorageModels.ExternalStorageDocument }>());
export const getOneExternalStorageFail = createAction('[EXTERNAL-STORAGE] Get One External Storage Fail');


export const changeFormValidation = createAction('[EXTERNAL-STORAGE] Change Form Validation ', props<{ isFormValid: boolean }>());

export const startConfigureExternalStorage = createAction('[EXTERNAL-STORAGE] Start Configure External Storage', props<{ document: Partial<ExternalStorageModels.ExternalStorageDocument> }>());

export const testExternalStorage = createAction('[EXTERNAL-STORAGE] Test External External Storage');
export const testExternalStorageSend = createAction('[EXTERNAL-STORAGE] Test External External Storage Send', props<{ request: ExternalStorageModels.TestExternalStorageRequest }>());
export const testExternalStorageSuccess = createAction('[EXTERNAL-STORAGE] Test External External Storage Success');
export const testExternalStorageError = createAction('[EXTERNAL-STORAGE] Test External External Storage Error', props<{ errorMsg: string }>());

export const setSelectedExternalStorage = createAction('[EXTERNAL-STORAGE] Set Selected External Storage', props<{ document: ExternalStorageModels.ExternalStorageDocument }>());
