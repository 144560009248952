import { createAction, props } from '@ngrx/store';
import { PermissionGroupModels, SystemSecurityGroupModels } from '@models/group.model';
import { AppUser } from '../../../user/user.model';
import User = AppUser.User;

export const resetToInitialState = createAction('[ORGANIZATION-USERS] Reset to Initial state');
export const resetEntities = createAction('[ORGANIZATION-USERS] Reset Entities');

export const beforeGetOrganizationUsers = createAction('[ORGANIZATION-USERS] Before Get Organization Users');
export const getOrganizationUsers = createAction('[ORGANIZATION-USERS] Get Organization Users from Server');
export const getOrganizationUsersSuccess = createAction('[ORGANIZATION-USERS] Get Organization Users from Server Success', props<{ organizationUsers: User[] }>());
export const getOrganizationUsersFail = createAction('[ORGANIZATION-USERS] Set Organization Users from Server Fail');

export const setFilter = createAction('[ORGANIZATION-USERS] Set Filter Property', props<{ property: string, value: any }>());
export const removeFilter = createAction('[ORGANIZATION-USERS] Remove Filter', props<{ property: string, value: any }>());
export const setOrderDirection = createAction('[ORGANIZATION-USERS] Set Order Direction', props<{ property: string }>());

export const setIsLoading = createAction('[ORGANIZATION-USERS] Set Is Loading', props<{ isLoading: boolean }>());

export const checkIfUserExists = createAction('[ORGANIZATION-USERS] Check If User Exists', props<{ email: string }>());
export const checkIfUserExistsSuccess = createAction('[ORGANIZATION-USERS] Check If User Exists Success', props<{ isExists: boolean }>());
export const checkIfUserExistsFail = createAction('[ORGANIZATION-USERS] Check If User Exists Fail');

export const getOrganizationPermissionGroups = createAction('[ORGANIZATION-USERS] Get Organization Permission Groups');
export const getOrganizationPermissionGroupsSuccess = createAction('[ORGANIZATION-USERS] Get Organization Permission Groups Success', props<{
  permissionGroups: PermissionGroupModels.PermissionGroupDocumentBase[]
}>());

export const updateOrganizationUserPermissionsLocally = createAction('[ORGANIZATION-USERS] Update organization user permissions locally', props<{
  groupIds: string[];
  userId: string;
}>());
export const getOrganizationPermissionGroupsFail = createAction('[ORGANIZATION-USERS] Get Organization Permission Groups Fail');


export const getSystemPermissionGroups = createAction('[ORGANIZATION-USERS] Get System Permission Groups');
export const getSystemPermissionGroupsSuccess = createAction('[ORGANIZATION-USERS] Get Organization Permission Groups Success', props<{
  permissionGroups: SystemSecurityGroupModels.SystemSecurityGroupDocument[]
}>());
export const getSystemPermissionGroupsFail = createAction('[ORGANIZATION-USERS] Get Organization Permission Groups Fail');
