import { createReducer, on } from '@ngrx/store';
import * as TerminalActions from '@states/terminal/terminal.actions';

export interface TerminalState {
  edgeId: string;
  edgeIdHash?: number;
  cameraId?: string;
  cameraIdHash?: number;
  locationId?: string;
  locationIdHash?: number;
  orgId?: string;
  orgIdHash?: number;
}

export const initialState: TerminalState = {
  edgeId: null,
  edgeIdHash: null,
  cameraId: null,
  cameraIdHash: null,
  locationId: null,
  locationIdHash: null,
  orgId: null,
  orgIdHash: null,
};

export const terminalStateReducer = createReducer(
  initialState,
  on(TerminalActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(TerminalActions.setEdgeIdTerminal, (state, { edgeId }) => {
    return {
      ...state,
      edgeId,
    };
  }),
  on(TerminalActions.setLocationId, (state, { locationId }) => {
    return {
      ...state,
      locationId,
    };
  }),

  on(TerminalActions.setContext, (state, { context }) => {
    return {
      ...state,
      ...context
    };
  }),

  on(TerminalActions.setLocationIdTerminal, (state, { locationId }) => {
    return {
      ...state,
      locationId,
      edgeId: initialState.edgeId,
    };
  })
);
