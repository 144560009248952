import { createReducer, on } from '@ngrx/store';
import { PermissionActions } from '@states/permissions/permissions.action-types';
import { getPermissionsSuccess } from '@states/permissions/permissions.actions';

export declare interface PermissionsState {
  permissions: string[];
  entityIds: string[];
}

export const initialState: PermissionsState = {
  permissions: [],
  entityIds: [],
};

export const permissionsStateReducer = createReducer(
  initialState,
  on(PermissionActions.resetToInitialState, (state) => {
    return {
      ...initialState,
    };
  }),
  on(PermissionActions.getPermissionsSuccess, (state, { entityIds, permissions }) => {
    return {
      ...state,
      entityIds,
      permissions,
    };
  })
);
