<mat-form-field *ngIf="!asButton" appearance="outline" class="search-selector">
  <mat-label>{{ label }}</mat-label>
  <mat-select #selector (selectionChange)="emitSelection($event)" [value]="value" [disabled]="disabled">
    <div *ngFor="let type of searchSelectorOptions">
      <mat-option *ngIf="!type.group" [value]="type.value">{{ type.name }}</mat-option>
      <div *ngIf="type.group">
        <mat-optgroup [label]="type.name">
          <mat-option *ngFor="let opt of type.options" [value]="opt.value">
            {{ opt.name }}
          </mat-option>
        </mat-optgroup>
      </div>
    </div>
    <mat-option *ngIf="showAdvanced" class="advanced-button" value="advanced" (click)="openAdvanced()">
      <div class="advanced-button-inner">Advanced search</div>
    </mat-option>
  </mat-select>
</mat-form-field>
<button *ngIf="asButton" mat-stroked-button class="advanced-search-button" (click)="openAdvanced()">
  {{ label }}
</button>
