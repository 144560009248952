import { Injectable } from '@angular/core';
import * as DevTeamActions from '@states/dev-team/dev-team.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { UserService } from '../../user/user.service';
import { catchError, debounceTime, share, switchMap, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { UtilsV2Service } from '../../services/utils-v2.service';
import * as moment from 'moment';

@Injectable()
export class DevTeamEffect {
  public getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevTeamActions.getUsers, DevTeamActions.setFilter, DevTeamActions.setDateRange),
      debounceTime(400),
      withLatestFrom(this.store$.pipe(select(state => state.devTeamState))),
      switchMap(([, { filters, page, perPage }]) => {
        const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);

        return this.userService
          .findAll(
            {
              orgIds: filters.selectedOrganizations.map(org => org.orgId),
              start: moment(dateRangeFilter.start)
                .toString(),
              end: moment(dateRangeFilter.end)
                .toString(),
              query: filters?.query,
            },
            { page, perPage },
          )
          .pipe(
            switchMap(res => {
              return [
                DevTeamActions.getUsersSuccess({ users: res.items }),
                DevTeamActions.setTotalItemsCount({ totalItemsCount: res.totalItemsCount }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err }), SharedActions.setIsSaving({ isSaving: false })]),
          );
      }),
      share(),
    ),
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private userService: UserService,
              private utilsV2Service: UtilsV2Service,
  ) {
  }
}
