<div *ngIf="!inline" class="flex-col in-dialog">
  <div class="search-wrapper">
    <ui-search style="min-width: 320px"
               (onInput)="search($event)"
               [placeholder]="'Search ' + type + ' to add or remove'"
    ></ui-search>
  </div>
  <div class="search-results flex-col">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="groupType.Users">
        <div *ngIf="query ? filteredPeople.length === 0 : allPeople.length === 0">No results</div>
        <div class="flex-row space-between-center" *ngFor="let item of query ? filteredUsers : allUsers"
             (click)="toggle($event, item._id)">
          <div class="flex-row start-center gap-8">
            <div>
              <mat-checkbox [checked]="isMember(item._id)" color="primary"
                            (click)="$event.preventDefault();"></mat-checkbox>
            </div>
            <div>
              {{item?.firstname ? item?.firstname + ' ' + item?.lastname : item.email}}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="groupType.People">
        <div *ngIf="query ? filteredPeople.length === 0 : allPeople.length === 0">No results</div>
        <div class="flex-row space-between-center" *ngFor="let item of query ? filteredPeople : allPeople"
             (click)="toggle($event, item.personId)">
          <div class="flex-row start-center gap-8">
            <div>
              <mat-checkbox [checked]="isMember(item.personId)" color="primary"
                            (click)="$event.preventDefault();"></mat-checkbox>
            </div>
            <div class="avatar">
              <ui-person-icon [person]="item" [tiny]="true"></ui-person-icon>
            </div>
            <div>{{item?.name}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="groupType.Vehicles">
        <div *ngIf="query ? filteredVehicles.length === 0 : allVehicles.length === 0">No results</div>
        <div class="flex-row space-between-center" *ngFor="let item of query ? filteredVehicles : allVehicles"
             (click)="toggle($event, item.id)">
          <div class="flex-row start-center gap-8">
            <div>
              <mat-checkbox [checked]="isMember(item.id)" color="primary"
                            (click)="$event.preventDefault();"></mat-checkbox>
            </div>
            <div>{{item?.plate}}</div>
          </div>
          <div class="vehicle-description">
            {{item.make}} {{item.owner ? '(' + item.owner + ')' : ''}}
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="groupType.Doors">
        <div *ngIf="query ? filteredDoors.length === 0 : allDoors.length === 0">No results</div>
        <div class="flex-row space-between-center" *ngFor="let item of query ? filteredDoors : allDoors"
             (click)="toggle($event, item.id)">
          <div class="flex-row start-center gap-8">
            <div>
              <mat-checkbox [checked]="isMember(item.id)" color="primary"
                            (click)="$event.preventDefault();"></mat-checkbox>
            </div>
            <div>{{item?.name}}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </div>
</div>
<div *ngIf="inline" class="flex-col p-r inline">
  <ui-search style="min-width: 320px"
             (onInput)="search($event)"
             [placeholder]="'Search ' + type + ' to add or remove'"
             #searchInput
             (onFocus)="selecting = true"
  ></ui-search>
  <div #list *ngIf="selecting" class="search-results flex-col" (click)="searchInput.focus()">
    <div class="inner flex-col">

      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="groupType.Users">
          <div *ngIf="query ? filteredPeople.length === 0 : allPeople.length === 0">No results</div>
          <div class="flex-row space-between-center" *ngFor="let item of query ? filteredUsers : allUsers"
               (click)="toggle($event, item._id)">
            <div class="flex-row start-center gap-8">
              <div>
                <mat-checkbox [checked]="isMember(item._id)" color="primary"
                              (click)="$event.preventDefault();"></mat-checkbox>
              </div>
              <div>
                {{item?.firstname ? item?.firstname + ' ' + item?.lastname : item.email}}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="groupType.People">
          <div *ngIf="query ? filteredPeople.length === 0 : allPeople.length === 0">No results</div>

          <div class="flex-row space-between-center" *ngFor="let item of query ? filteredPeople : allPeople"
               (click)="toggle($event, item.personId)">
            <div class="flex-row start-center gap-8">
              <div>
                <mat-checkbox [checked]="isMember(item.personId)" color="primary"
                              (click)="$event.preventDefault()"></mat-checkbox>
              </div>
              <div class="avatar">
                <ui-person-icon [person]="item" [tiny]="true"></ui-person-icon>
              </div>
              <div>{{item?.name}}</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="groupType.Vehicles">
          <div *ngIf="query ? filteredVehicles.length === 0 : allVehicles.length === 0">No results</div>
          <div class="flex-row space-between-center" *ngFor="let item of query ? filteredVehicles : allVehicles"
               (click)="toggle($event, item.id)">
            <div class="flex-row start-center gap-8">
              <div>
                <mat-checkbox [checked]="isMember(item.id)" color="primary"
                              (click)="$event.preventDefault();"></mat-checkbox>
              </div>
              <div>{{item?.plate}}</div>
            </div>
            <div class="vehicle-description">
              {{item.make}} {{item.owner ? '(' + item.owner + ')' : ''}}
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="groupType.Doors">
          <div *ngIf="query ? filteredDoors.length === 0 : allDoors.length === 0">No results</div>
          <div class="flex-row space-between-center" *ngFor="let item of query ? filteredDoors : allDoors"
               (click)="toggle($event, item.id)">
            <div class="flex-row start-center gap-8">
              <div>
                <mat-checkbox [checked]="isMember(item.id)" color="primary"
                              (click)="$event.preventDefault();"></mat-checkbox>
              </div>
              <div>{{item?.name}}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>

