<div fxLayout="column" class="search-list-wrapper" fxFlexFill>
  <ui-header [breadCrumbs]="breadCrumb">
  </ui-header>
  <ng-container *ngrxLet="selectViews$;let views">
    <div class="content-wrapper ui-page" fxLayout="column" fxFlex>
      <div>
        <div style="width:320px">
          <ui-search (onInput)="search($event)"></ui-search>
        </div>
      </div>
      <ng-container *ngIf="!!views?.length; else noContent">

        <div class="table-wrapper" fxLayout="column" fxFlex>
          <div fxLayout="row" class="header">
            <div fxFlex="5">Private</div>
            <div fxFlex="15">Name</div>
            <div fxFlex="15">Created</div>
            <div fxFlex="50">Filters</div>
            <div fxFlex="15"></div>
          </div>

          <div #scrollContainer fxFlex>
            <div *ngIf="views;else loader" fxFlex>
              <cdk-virtual-scroll-viewport (scroll)="onScroll($event)" [itemSize]="itemSize" [style.height]="'100%'">
                <div *cdkVirtualFor="let view of views; let i = index" fxLayout="row" fxLayoutAlign="start center"
                     class="table-row"
                >
                  <div fxFlex="5" class="td" fxLayoutAlign="start center">
                    <mat-icon *ngIf="!view.isPublic"
                              class="ui-mat-icon-16"
                              style="color:#667085"
                              matTooltip="This view available only for you"
                    >
                      lock_outline
                    </mat-icon>
                  </div>

                  <div fxFlex="15" class="name td" fxLayoutAlign="start center" (click)="openView(view)">
                    <span style="margin-left: 4px">{{view.name}}</span>
                  </div>
                  <div *ngIf="view.timestamp"
                       fxFlex="15"
                       fxLayoutAlign="start center"
                       class="td"
                  >{{view.timestamp * 1000 | date: 'MMM dd, YYYY'}}</div>
                  <div *ngIf="!view.timestamp" fxFlex="15" class="td">N/a</div>
                  <div fxLayoutAlign="start center" fxFlex="50" fxLayout="row" class="td" (click)="openFilters(view)">
                    <ui-filter-chip *ngFor="let object of objectChips(view)" class="filter-chip"
                                    field="alertNamesFilters" [title]="object | titlecase" [allowDelete]="false"
                    ></ui-filter-chip>
                  </div>
                  <ng-container *ngrxLet="selectUser$;let user">
                    <ng-container *ngrxLet="selectOrganization$;let organization">
                      <div fxFlex="15" fxLayout="row" class="td" fxLayoutAlign="center center">
                        <mat-icon svgIcon="more-menu" style="cursor: pointer" [matMenuTriggerFor]="action"></mat-icon>
                        <mat-menu #action="matMenu" xPosition="before">
                          <button mat-menu-item (click)="openView(view)">
                            Open
                          </button>
                          <button *ngIf="user.authProviderId === view.authProviderId ||
                        organization.roles.includes('admin') ||
                        organization.roles.includes('owner')" mat-menu-item (click)="delete(view)"
                          >
                            Delete
                          </button>
                        </mat-menu>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

              </cdk-virtual-scroll-viewport>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noContent>

  <ng-container *ngrxLet="selectIsFirstLoad$;let isFirstLoad">

    <ng-container *ngrxLet="selectSearchQuery$;let searchQuery">

      <ui-no-data *ngIf="!searchQuery && isFirstLoad" [imgSrc]="noData.imgSrc" [title]="noData.title"
                  [description]="noData.description" [createButtonText]="noData.createButtonText"
                  (onCreate)="goToAlertsMonitoring()"
      >
      </ui-no-data>

      <ui-no-data *ngIf="!!searchQuery && isFirstLoad" [imgSrc]="noResults.imgSrc" [title]="noResults.title"
                  [description]="noResults.description" [createButtonText]="noResults.createButtonText"
                  (onCreate)="goToAlertsMonitoring()"
      >
      </ui-no-data>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>
