import { NgModule } from '@angular/core';
import { RbacAllowDirective } from './rbac-allow.directive';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [RbacAllowDirective],
  imports: [SharedModule],
  exports: [RbacAllowDirective],
})
export class AuthorizationModule {}
