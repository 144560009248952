import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AlertLogsFilter } from '@models/alerts-v2.model';
import { createReducer, on } from '@ngrx/store';
import { AlertLogsActions } from '@states/alert-logs/alert-logs.action-types';
import _ from 'lodash';
import { AlertNotificationLog } from '@models/alert-monitoring.model';
import { UiCalendarPickerType } from '@enums/shared.enum';
import * as moment from 'moment-timezone';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

const dateRangeInitial = {
  start: moment()
    .subtract(1, 'day')
    .toString(),
  end: moment()
    .toString(),
};
export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.minutes, value: 5 } };

export declare interface AlertLogsState extends EntityState<AlertNotificationLog> {
  filters: AlertLogsFilter;
  isLoading: boolean;
  page: number;
  perPage: number;
  isLastPage: boolean;
  isInitialLoaded: boolean;
}

export const adapter: EntityAdapter<AlertNotificationLog> = createEntityAdapter<AlertNotificationLog>({
  selectId: (notificationLog: AlertNotificationLog) => notificationLog.id,
});

export const initialState: AlertLogsState = adapter.getInitialState({
  filters: {
    dateRange: defaultDateRange,
    query: '',
    notificationTypes: [],
  },
  isLoading: false,
  page: 0,
  perPage: 20,
  isLastPage: false,
  isInitialLoaded: false,
});

export const alertLogsStateReducer = createReducer(
  initialState,
  on(AlertLogsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlertLogsActions.getAlertLogsSuccess, (state, { alertLogs }) => {
    return adapter.addMany(alertLogs, {
      ...state,
      isLastPage: alertLogs.length < state.perPage,
      page: state.page + 1,
      isInitialLoaded: true,
    });
  }),
  on(AlertLogsActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(AlertLogsActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(AlertLogsActions.resetEntities, (state) => {
    return adapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
    });
  }),
  on(AlertLogsActions.removeFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: rmFilters(state.filters, property, value),
    };
  }),
);

const rmFilters = (filters: AlertLogsFilter, prop: string, value: any): AlertLogsFilter => {
  const _filters = _.cloneDeepWith(filters);
  switch (prop) {
    case 'query':
      _filters.query = null;
      break;
    case 'notificationTypes':
      const indexTypes = _filters.notificationTypes.findIndex(type => type === value);
      _filters.notificationTypes.splice(indexTypes, 1);
      break;
  }
  return _filters;
};

