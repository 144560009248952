import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { map, Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { SortDirection } from '@angular/material/sort';
import { UserService } from '../user/user.service';
import { DashboardModel } from '@models/dashboard.model';
import { GroupModels } from '@models/people.model';
import { HttpService } from '../core/http.service';

@Injectable()
export class DashboardService {
  constructor(private userService: UserService, private http: HttpClient, private httpService: HttpService) {
  }

  public create(data: DashboardModel.Dashboard): Observable<any> {
    return this.http.post(api.dashboard.crud, data);
  }

  public update(data: DashboardModel.Dashboard): Observable<any> {
    return this.http.put(api.dashboard.crud, data);
  }

  public get(): Observable<PaginationResult<DashboardModel.Dashboard>> {
    return this.http.get<PaginationResult<DashboardModel.Dashboard>>(api.dashboard.crud);
  }

  public one(id: number): Observable<DashboardModel.Dashboard> {
    return this.http.get<DashboardModel.Dashboard>(api.dashboard.one(id));
  }

  public remove(id: number): Observable<DashboardModel.Dashboard> {
    return this.http.delete<DashboardModel.Dashboard>(api.dashboard.one(id));
  }

  public getDashboards(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
  ): Observable<DashboardModel.Dashboard[]> {
    let url = `${api.dashboard.crud}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<DashboardModel.Dashboard[]>(url);
  }

  public updateDashboardFilters(id: number, filters: DashboardModel.Filters): Observable<any> {
    return this.http.put(api.dashboard.updateFilters(id), filters);
  }

}
