import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-ws-video-player',
  standalone: true,
  imports: [],
  templateUrl: './ui-ws-video-player.component.html',
  styleUrl: './ui-ws-video-player.component.scss',
})
export class UiWsVideoPlayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {


  public ws_protocol: string = 'ws';
  @Input() ws_hostname: string = '192.168.100.119';
  public ws_port: string = '12345';

  private queue = [];
  private startTimeSet = false;
  private playbackOptionSequence = false;
  private sourceBuffer = null;
  private streamingStarted = false;
  private webSocket = null;
  private enableVerboseMsgLog = false;
  private mimeCodec = '';

  @ViewChild('videoElement') videoElement: ElementRef;

  public ngOnInit() {

  }

  public ngAfterViewInit() {
    // Makes sure that user typed username and message before sending
    if ((this.ws_protocol === '') || (!this.ws_hostname || this.ws_hostname === '') || (this.ws_port === '')) {
      //todo error
      // errorToast('Please fill out all the configuration fields above!');
    } else {
      // this.initMediaSource();
      // document.getElementById('incomingMsgOutput').value = '';
      // document.getElementById('btnConnect').disabled = true;
      this.openWSConnection(this.ws_protocol, this.ws_hostname, this.ws_port);
    }

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes['ws_hostname'].firstChange) {
      this.webSocket?.close();
      this.videoElement?.nativeElement.pause();

      // this.initMediaSource();
      this.openWSConnection(this.ws_protocol, this.ws_hostname, this.ws_port);
    }

  }

  public setMimeCodec(mc) {
    this.mimeCodec = mc;
    const mimeCodec = this.mimeCodec;
    const succ = MediaSource.isTypeSupported(mimeCodec);
    if (!succ) {
      console.error('Unsupported MIME type or codec: ' + mimeCodec);
      // document.getElementById('incomingMsgOutput').value += 'error: Unsupported MIME type or codec' + '\r\n';
    }
    this.initMediaSource();

    return succ;

  }

  // Init the Media Source and add event listener
  public initMediaSource() {
    this.startTimeSet = false;
    this.videoElement.nativeElement.onerror = elementError;
    this.videoElement.nativeElement.loop = false;
    this.videoElement.nativeElement.addEventListener('canplay', (event) => {
      console.log('Video can start, but not sure it will play through.');
      this.videoElement.nativeElement.play();
    });
    this.videoElement.nativeElement.addEventListener('paused', (event) => {
      console.log('Video paused for buffering...');
      setTimeout(() => {
        this.videoElement.nativeElement.play();
      }, 2000);
    });

    let mimeCodec = this.mimeCodec; //'video/mp4; codecs="avc1.64001e"';
    //var mimeCodec = 'video/mp4; codecs="avc1.4D0033"'; AVC Main Level 5.1
    //var mimeCodec = 'video/mp4; codecs="avc1.42E01E"'; baseline
    //var mimeCodec = 'video/mp4; codecs="avc1.4d002a"'; AVC Main Level 4.2
    //var mimeCodec = 'video/mp4; codecs="avc1.64001E"'; AVC High Level 3

    //var mimeCodec = 'video/mp4; codecs="hev1.1.6.L120.90"'; HEVC main
    //var mimeCodec = 'video/mp4; codecs="hev1.2.4.L120.90"'; HEVC main 10
    //var mimeCodec = 'video/mp4; codecs="hev1.3.E.L120.90"'; HEVC main still-picture
    //var mimeCodec = 'video/mp4; codecs="hev1.4.10.L120.90"'; HEVC range extensions

    // 'video/mp4; codecs="avc1.42401E"',
    // 'video/mp4; codecs="avc1.42C01E"',
    // 'video/mp4; codecs="avc1.42001E"',
    // 'video/mp4; codecs="avc1.4D401E"',
    // 'video/mp4; codecs="avc1.4D001E"',
    // 'video/mp4; codecs="avc1.640032"',
    // 'video/mp4; codecs="avc1.640C32"',
    // 'video/mp4; codecs="avc1.F4001F"',
    // 'video/mp4; codecs="hvc1.1.6.L93.B0"',
    // 'video/mp4; codecs="hev1.1.6.L93.B0"',
    // 'video/mp4; codecs="hev1.2.4.L120.B0"',

    if (!window.MediaSource) {
      console.error('No Media Source API available');
      // document.getElementById('incomingMsgOutput').value += 'error: No Media Source API available' + '\r\n';
      return;
    }

    if (!MediaSource.isTypeSupported(mimeCodec)) {
      console.error('Unsupported MIME type or codec: ' + mimeCodec);
      // document.getElementById('incomingMsgOutput').value += 'error: Unsupported MIME type or codec' + '\r\n';
      return;
    }

    var ms = new MediaSource();
    this.videoElement.nativeElement.src = window.URL.createObjectURL(ms);

    const onMediaSourceOpen = () => {
      this.sourceBuffer = ms.addSourceBuffer(mimeCodec);
      // optional ways to get the stream time right!
      // OPTION A: let the buffer set the timestamps
      if (this.playbackOptionSequence) {
        this.sourceBuffer.mode = 'sequence';
      }
      this.sourceBuffer.addEventListener('updateend', loadPacket);
      this.sourceBuffer.addEventListener('onerror', sourceError);
    };

    ms.addEventListener('sourceopen', onMediaSourceOpen);

    const loadPacket = () => { // called when sourceBuffer is ready for more
      if (!this.sourceBuffer.updating) {
        if (this.queue.length > 0) {
          console.log('push to sourceBuffer');
          const data = this.queue.shift(); // pop from the beginning
          this.appendToBuffer(data);
        } else { // the queue runs empty, so we must force-feed the next packet
          this.streamingStarted = false;
        }
      } else {
      }
    };

    function sourceError(event) {
      console.log('Media source error');
    }

    function elementError(event) {
      console.log('Media element error');
    }
  }


  // Append AV data to source buffer
  private appendToBuffer(videoChunk) {
    if (videoChunk) {
      this.sourceBuffer.appendBuffer(videoChunk);
      // OPTION B: adjust the playback timestamp to where we are
      if (!this.playbackOptionSequence && this.videoElement.nativeElement.buffered.length >= 1 && !this.startTimeSet) {
        this.videoElement.nativeElement.currentTime = this.videoElement.nativeElement.buffered.start(0);
        this.startTimeSet = true;
      }
    }
  }

  // Open a new WebSocket connection using the given parameters
  private openWSConnection(protocol: string, hostname: string, port: string) {

    var webSocketURL = null;
    var keepAliveCount = 0;

    webSocketURL = protocol + '://' + hostname + ':' + port;
    console.log('openWSConnection::Connecting to: ' + webSocketURL);

    const offline = `<h4><span class="badge bg-danger">Disconnected</span></h4>`;
    const online = `<h4><span class="badge bg-success">Connected</span></h4>`;


    try {
      this.webSocket = new WebSocket(webSocketURL);
      this.webSocket.debug = true;
      this.webSocket.timeoutInterval = 3000;
      this.webSocket.onopen = (openEvent) => {
        var open = JSON.stringify(openEvent, null, 4);
        console.log('WebSocket open');
        // document.getElementById('btnConnect').disabled = true;
        // document.getElementById('btnDisconnect').disabled = false;
        // document.getElementById('incomingMsgOutput').value += 'WebSocket connected' + '\r\n';
      };
      this.webSocket.onclose = (closeEvent) => {
        var closed = JSON.stringify(closeEvent, null, 4);
        console.log('WebSocket closed');
        // document.getElementById('btnConnect').disabled = false;
        // document.getElementById('btnDisconnect').disabled = true;
        // document.getElementById('incomingMsgOutput').value += 'WebSocket closed' + '\r\n';
        this.videoElement.nativeElement.pause;
      };
      this.webSocket.onerror = (errorEvent) => {
        var error = JSON.stringify(errorEvent, null, 4);
        console.log('WebSocket ERROR: ' + error);
        // document.getElementById('btnConnect').disabled = false;
        // document.getElementById('incomingMsgOutput').value += 'error: Websocket connection failed' + '\r\n';
      };
      this.webSocket.onmessage = (messageEvent) => {
        var wsMsg = messageEvent.data;
        if (typeof wsMsg === 'string') {
          if (wsMsg.indexOf('mimecodec:') == 0) {
            this.setMimeCodec(wsMsg.split(':')[1]);
            // document.getElementById("incomingMsgOutput").value += "Got MIME codec " + mimeCodec + "\r\n";
          } else {
            // document.getElementById("incomingMsgOutput").value += "message: " + wsMsg + "\r\n";
          }
        } else {
          var arrayBuffer;
          var fileReader = new FileReader();
          fileReader.onload = (event) => {
            arrayBuffer = event.target.result;
            var data = new Uint8Array(arrayBuffer);
            // document.getElementById('incomingMsgOutput').value += 'received: ' + data.length + ' bytes\r\n';
            if (this.enableVerboseMsgLog)
              console.log('received: ' + data.length + ' bytes\r\n');
          };
          if (!this.streamingStarted) {
            this.appendToBuffer(arrayBuffer);
            this.streamingStarted = true;
            return;
          }
          this.queue.push(arrayBuffer); // add to the end
        }
        ;
        fileReader.readAsArrayBuffer(wsMsg);
        /* NOTE: the web server has a idle-timeout of 60 seconds,
         so we need to send a keep-alive message regulary */
        keepAliveCount++;
        if (keepAliveCount >= 10 && this.webSocket.readyState == WebSocket.OPEN) {
          keepAliveCount = 0;
          this.webSocket.send('keep-alive');
        }
      };
    } catch (exception) {
      console.error(exception);
    }
  }

  public ngOnDestroy() {
    this.webSocket.close();
    this.videoElement.nativeElement.pause();
  }
}
