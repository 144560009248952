<div [matMenuTriggerFor]="trackerClassMenu" class="flex-row space-between-center ui-selector w-100">
  <div>{{ trackerGroupType }}</div>
  <span class="ui-icon-expand-arrow"></span>
</div>
<mat-menu #trackerClassMenu="matMenu" xPosition="after" yPosition="below" class="menu-no-padding">
  <div [formGroup]="form" class="flex-col start-start ui-tracker-class-menu" (click)="$event.stopPropagation();">
    <mat-radio-group class="flex-col" aria-label="Select an option" formControlName="trackerGroupType">
      <mat-radio-button
        [value]="+YAxisTrackerGroupType.All"
      >{{ YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.All] }}
      </mat-radio-button>
      <mat-radio-button
        [value]="+YAxisTrackerGroupType.Group"
      >{{ YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.Group] }}
      </mat-radio-button>
      <ng-container *ngIf="isGroupType">
        <div class="flex-col option-list">
          <mat-checkbox [checked]="allGroupsSelected" [indeterminate]="someGroupsSelected"
                        (change)="selectAllGroups()"
          >Select all
          </mat-checkbox>
          <mat-checkbox *ngFor="let groupType of YAxisTrackerGroupStr | keyvalue"
                        [value]="groupType.key"
                        [checked]="isChecked('trackerGroup', +groupType.key)"
                        (change)="updateArray('trackerGroup', +groupType.key, $event.checked)"
          >{{ groupType.value }}
          </mat-checkbox>
        </div>
      </ng-container>
      <mat-radio-button
        [value]="+YAxisTrackerGroupType.Individual"
      >{{ YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.Individual] }}
      </mat-radio-button>
      <ng-container *ngIf="isIndividualType">
        <div class="flex-col option-list">
          <mat-checkbox [checked]="allIndividualSelected" [indeterminate]="someIndividualSelected"
                        (change)="selectAllIndividual()"
          >Select all
          </mat-checkbox>
          <mat-checkbox *ngFor="let classType of YAxisTrackerClassStr | keyvalue"
                        [value]="classType.key"
                        [checked]="isChecked('trackerClass', +classType.key)"
                        (change)="updateArray('trackerClass', +classType.key, $event.checked)"
          >{{ classType.value }}
          </mat-checkbox>
        </div>
      </ng-container>
    </mat-radio-group>
  </div>
</mat-menu>
