import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { StringOrEmpty } from 'src/app/app.model';
import { ThumbnailModel } from '@models/thumbnail.model';
import { ThumbnailsActions } from '@states/thumbnails/thumbnails.action-types';
import _ from 'lodash';

export interface ThumbnailsState extends EntityState<ThumbnailModel.ThumbnailDocument> {
  isFirstLoaded: boolean;
  isAllLoaded: boolean;
  isPagination: boolean;
  error: StringOrEmpty;
}

export const adapter: EntityAdapter<ThumbnailModel.ThumbnailDocument> = createEntityAdapter<ThumbnailModel.ThumbnailDocument>({
  selectId: (thumbnail: ThumbnailModel.ThumbnailDocument) => thumbnail.cacheId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialThumbnailState: ThumbnailsState = adapter.getInitialState({
  isFirstLoaded: false,
  isAllLoaded: false,
  isPagination: false,
  error: undefined,
});

const mergeEntities = (existingEntity: ThumbnailModel.ThumbnailDocument, newEntity: ThumbnailModel.ThumbnailDocument): ThumbnailModel.ThumbnailDocument => {
  return _.merge(_.cloneDeep(existingEntity), newEntity);
};


export const thumbnailsStateReducer = createReducer(
  initialThumbnailState,
  on(ThumbnailsActions.setThumbnailsCache, (state, action) => {

    const updates = action.thumbnails
      .filter((thumbnail) => state.entities[thumbnail?.cacheId])
      .map((thumbnail) => ({
        id: thumbnail?.cacheId,
        changes: mergeEntities(state.entities[thumbnail?.cacheId], thumbnail),
      }));

    const inserts = action.thumbnails.filter((thumbnail) => !state.entities[thumbnail?.cacheId]);

    return adapter.updateMany(updates, {
      ...adapter.addMany(inserts, state),
      isFirstLoaded: true,
    });
  }),

  on(ThumbnailsActions.setBitsCache, (state, { edgeId, cameraId, cacheId, bits }) => {
    const update: Update<ThumbnailModel.ThumbnailDocument> = {
      id: cacheId,
      changes: {
        bits: bits,
      },
    };
    if (state.entities[cacheId] === undefined) {
      return adapter.addOne({
        edgeId,
        cameraId,
        cacheId: cacheId,
        bits: bits,
      }, {
        ...state,
        isFirstLoaded: true,
      });
    } else {
      return adapter.updateOne(update, {
        ...state,
        isFirstLoaded: true,
      });
    }
  }),

  on(ThumbnailsActions.resetThumbnailsCache, (state, action) => {
    return adapter.removeAll({
      ...state,
    });
  }),
);
