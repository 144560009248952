<div class="quality-selector">
  <div class="quality-btn c-p"
       [class.active]="value === Quality.SQ"
       (click)="changeQuality(Quality.SQ);$event.stopPropagation()"
  >SQ
  </div>
  <div class="quality-btn c-p"
       [class.active]="value === Quality.HQ"
       (click)="changeQuality(Quality.HQ);$event.stopPropagation()"
  >HQ
  </div>
</div>
