<mat-tab-group fxFill (selectedTabChange)="tabChanged($event)">
  <!--    <mat-tab label="Info"><div>{{edge?.edgeId}}</div></mat-tab>-->
  <mat-tab label="Info"
    ><div>{{ edge?.edgeId }}</div></mat-tab
  >
  <mat-tab label="My Cameras">
    <app-camera-list></app-camera-list>
  </mat-tab>
  <mat-tab label="Camera Discovery">
    <app-camera-discover></app-camera-discover>
  </mat-tab>
</mat-tab-group>
