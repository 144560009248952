import { ChangeDetectorRef, Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EdgeCamera } from '../../../cameras/camera.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { periods, statUptimePeriods } from '@consts/stats.const';
import { StatsEnum } from '@enums/stats.enum';
import { BehaviorSubject, Observable, share, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { StatsService } from '../../../development/stats.service';
import { PreloaderColor, UiUptimeBarColors } from '@enums/shared.enum';
import { uiUptimeBarColors } from '@consts/shared.const';
import { CamerasService } from '../../../cameras/cameras.service';
import { HoursFormat } from '@enums/user-settings.enum';
import { formatDate } from '@angular/common';
import { initialUserSettings, uiUserSettingsDateFormatMap } from '@consts/user-settings.const';
import * as moment from 'moment-timezone';
import { CameraSelectors } from "@states/camera/camera.selector-types";

@Component({
  selector: 'app-single-camera-stats-modal',
  templateUrl: './single-camera-stats-modal.component.html',
  styleUrls: ['./single-camera-stats-modal.component.scss'],
})
export class SingleCameraStatsModalComponent implements OnInit {
  public selectCameraUptime$: Observable<number[]> = new Observable(null);

  public periods = periods;
  public selectedPeriod = StatsEnum.day1;
  public statUptimePeriods = statUptimePeriods;

  public start: number;
  public end: number;
  public format = this.periods[this.selectedPeriod].format;
  public uiBarColors = uiUptimeBarColors;
  public uiUptimeBarColors = UiUptimeBarColors;
  public preloaderColor = PreloaderColor;
  public cameraUptimeOnlinePercentage: number;
  public isPreviewExpanded = false;
  public userDateFormat = initialUserSettings.dateFormat;
  public userHoursFormat = initialUserSettings.hoursFormat;
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private timeout;

  public createdAt: number;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                camera: EdgeCamera.CameraItem,
                period: StatsEnum
              },
              private store$: Store<AppState>,
              private statsService: StatsService,
              private camerasService: CamerasService,
              private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.createdAt$().subscribe(createdAt => this.createdAt = createdAt);
    this.selectedPeriod = this.data.period ?? StatsEnum.day1;
    this.calculateStartEnd();

    this.timeout = setTimeout(() => {
      this.isLoaded$.next(true);
      this.cameraUptimeOnlinePercentage = 0;
    }, 5000);
  }

  public detectChanges() {
    this.cd.detectChanges();
  }

  public calculateStartEnd() {
    this.end = new Date().getTime();
    this.start = this.end - statUptimePeriods[this.selectedPeriod].period;
    this.selectCameraUptime$ = this.statsService
      .getCameraStats({
        edgeId: this.data.camera.edgeId,
        cameraId: this.data.camera.edgeOnly.cameraId,
        period: statUptimePeriods[this.selectedPeriod].period,
        bar: statUptimePeriods[this.selectedPeriod].bar,
      })
      .pipe(share());

    this.selectCameraUptime$
      .subscribe(cameraUptime => {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.isLoaded$.next(true);
        }
        const initialValue = 0;
        const total = cameraUptime?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
        if (!total) {
          this.cameraUptimeOnlinePercentage = 0;
        }
        this.cameraUptimeOnlinePercentage = Math.floor(total / cameraUptime?.length);
      });
  }

  public cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  public timezoneAbbreviation() {
    const userZone = moment()
      .tz(moment.tz.guess())
      .format('z');
    return userZone;
  }

  public toolTipStatus(online: number, i: number) {
    const offline = 100 - online;
    const offlinePercentage = offline / 100;
    let string = '';
    let time = 0;
    const isOnline = online === 100;

    switch (this.selectedPeriod) {
      case StatsEnum.hr1:
        time = Math.floor(offlinePercentage * 60);
        string = isOnline ? `Online for 1 minute` : `Offline for ${time} ${time === 1 ? 'second' : 'seconds'} out of 1 minute`;
        break;
      case StatsEnum.day1:
        time = Math.floor(offlinePercentage * 15);
        if (!isOnline && time === 0) {
          time = Math.floor(offlinePercentage * 900);
          string = `Offline for ${time} ${time === 1 ? 'second' : 'seconds'} out of 15 minutes`;
          break;
        }
        string = isOnline ? `Online for 15 minutes` : `Offline for ${time} ${time === 1 ? 'minute' : 'minutes'} out of 15 minutes`;
        break;
      case StatsEnum.day7:
        time = Math.floor(offlinePercentage * 120);
        if (time < 60) {
          string = isOnline ? `Online for 2 hours` : `Offline for ${time} ${time === 1 ? 'minute' : 'minutes'} out of 2 hours`;
        } else {
          if (time === 120) {
            string = `Offline for 2 hours`;
          } else {
            string = isOnline
              ? `Online for 2 hours`
              : `Offline for 1 hour and ${time - 60} ${time - 60 === 1 ? 'minute' : 'minutes'} out of 2 hours`;
          }
        }
        break;
      case StatsEnum.day30:
        time = Math.floor(offlinePercentage * 6);
        if (time === 0) {
          time = Math.floor(360 * offlinePercentage);
          string = isOnline ? `Online for 6 hours` : `Offline for ${time} ${time === 1 ? 'minutes' : 'minutes'} out of 6 hours`;
        } else {
          string = isOnline ? `Online for 6 hours` : `Offline for ${time} ${time === 1 ? 'hour' : 'hours'} out of 6 hours`;
        }
        break;
    }
    return string;
  }

  public endTs(i: number) {
    return this.start + (i + 1) * statUptimePeriods[this.selectedPeriod].bar;
  }

  public toolTipTime(online: number, i: number) {
    const format = `${uiUserSettingsDateFormatMap[this.userDateFormat]} ${
      this.userHoursFormat === HoursFormat.hours12 ? 'h:mm a' : 'HH:mm'
    }`;
    const times = `(${formatDate(this.start + i * statUptimePeriods[this.selectedPeriod].bar, format, 'en')} -
       ${formatDate(this.start + (i + 1) * statUptimePeriods[this.selectedPeriod].bar, format, 'en')}) ${this.timezoneAbbreviation()}`;
    return times;
  }

  public createdAt$() {
    return this.store$.select(CameraSelectors.selectCameraCreatedAtById(this.data?.camera?.edgeOnly?.cameraId))
      .pipe(take(1));
  }
}
