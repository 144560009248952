<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="radio-button-wrapper ui-attribute-row ui-settings-day-schedule"
     [class.disabled]="disabled"
>
  <div class="radio-row header" fxLayout="row" fxLayoutAlign="start center">
    <ui-toggle [(ngModel)]="toggled" (ngModelChange)="disableDay($event)"></ui-toggle>
    <div class="radio-title-wrapper" fxLayout="column">
      <div class="radio-title">{{ nameDayOfWeek[title] }}</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" class="day-schedule">
    <input class="checkbox"
           type="checkbox"
           [disabled]="!toggled"
           [(ngModel)]="allDay"
           (ngModelChange)="allDayChecked($event)"
    />
    <span class="all-day">All day</span>
    <mat-select class="time-selector"
                [value]="from"
                [disabled]="allDay || !toggled"
                (selectionChange)="fromSelectionChange($event)"
    >
      <ng-container *ngFor="let time of alertDayTime">
        <mat-option *ngIf="time !== '11:59 PM'" [value]="time">{{ time }}</mat-option>
      </ng-container>
    </mat-select>
    <span class="minus">-</span>
    <mat-select class="time-selector"
                [value]="to"
                [disabled]="allDay || !toggled"
                (selectionChange)="toSelectionChange($event)"
    >
      <ng-container *ngFor="let time of alertDayTime">
        <mat-option *ngIf="time !== '11:59 PM' || allDay" [value]="time">{{ time }}</mat-option>
      </ng-container>
    </mat-select>
  </div>
</div>
