import { Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { SharedEffects } from '@effects/shared.effects';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import * as SharedActions from '@states/shared/shared.actions';
import { SessionDataAction } from '@enums/session-data.enum';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { PreloaderColor } from '@enums/shared.enum';
import { Observable } from 'rxjs';
import * as SharedSelector from '@states/shared/shared.selectors';
import { SocketMainService } from 'src/app/socket/socket-main.service';
import { SocketMainConsumerService } from 'src/app/socket/socket-main-consumer.service';

@UntilDestroy()
@Component({
  selector: 'app-edge-software-version-modal',
  templateUrl: './edge-software-version-modal.component.html',
  styleUrls: ['./edge-software-version-modal.component.scss'],
})
export class EdgeSoftwareVersionModalComponent implements OnInit {
  public selectSomethingWentWrong$: Observable<boolean> = this.store$.pipe(select(SharedSelector.selectSomethingWentWrong));
  public versions: any[] = null;
  public preloaderColor = PreloaderColor;

  constructor(
    private store$: Store<AppState>,
    private sharedEffects: SharedEffects,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      edgeId: string;
      locationId: string;
    },
    private socketMainConsumerService: SocketMainConsumerService,
  ) { }

  ngOnInit(): void {
    this.store$.dispatch(EdgeEditActions.getEdgeSWByIds({ edgeId: this.data.edgeId, locationId: this.data.locationId }));

    // this.socketMainConsumerService.getSwVersion()
    //   .subscribe()

    this.sharedEffects.getSessionData$.pipe(untilDestroyed(this), ofType(SharedActions.getSessionDataSuccess)).subscribe(res => {
      switch (res.sessionDataAction) {
        case SessionDataAction.getSoftwareVersion:
          this.versions = res.payload.result.data.versions;
          break;
      }
    });

    this.sharedEffects.getSessionStatusFailed$.pipe(untilDestroyed(this), ofType(SharedActions.getSessionStatusFailed)).subscribe(res => {
      switch (res.sessionDataAction) {
        case SessionDataAction.getSoftwareVersion:
          this.store$.dispatch(
            SharedActions.setSomethingWentWrong({
              somethingWentWrong: true,
            })
          );
          break;
      }
    });
  }
}
