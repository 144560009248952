import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Edge } from '../../edge/edge.model';
import { concatMap, mergeMap, filter, map, Observable, tap, catchError, throwError, of, take, timeout } from 'rxjs';
import { SQSMsgInfo } from '../../core/interfaces';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { EdgeService } from 'src/app/edge/edge.service';
import { GetEdgeIpAddressToken } from '../sessions/get-edge-ip-address-session';
import { EdgeActions } from '@states/edge/edge.action-types';
import { select, Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { api } from '@consts/url.const';
import { GrafanaModels } from '@models/grafana.model';


@Injectable({
  providedIn: 'root',
})
export class GetEdgeIpAddressService {
  constructor(private http: HttpClient, private store: Store, private edgeService: EdgeService) {
  }

  getEdgeIpAddressInfo(data: Edge.GetEdgeIpAddressRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/get-edge-ip-address`;
    return this.http.post<SQSMsgInfo>(url, data)
      .pipe(
        timeout(4000),
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  getEdgeIpAddressInfoFromGrafana(data: Edge.GetEdgeIpAddressRequest): Observable<GrafanaModels.GetEdgeIpAddressInfoFromGraphana> {
    const url = `${api.stats.grafana}/edge/get-edge-ip-address/${data.edgeId}`;
    return this.http.get<GrafanaModels.GetEdgeIpAddressInfoFromGraphana>(url)
      .pipe(
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  getLastVideoDates(data: Edge.GetLastVideoDatesRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/get-last-video-dates`;
    return this.http.post<SQSMsgInfo>(url, data)
      .pipe(
        timeout(4000),
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  subscribeToGetEdgeIpAddressInfo(data: Edge.GetEdgeIpAddressRequest) {
    const http$ = this.getEdgeIpAddressInfo(data);
    const firebase$ = (token: string) =>
      this.edgeService.subscribeToSessionStatus(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );
    const session$ = (token: string) =>
      this.edgeService.getSessionData<GetEdgeIpAddressToken.AllSessionData>(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );

    return http$.pipe(
      concatMap(res => firebase$(res.token.session)),
      filter(state => state?.status === TokenDataStatus.COMPLETED),
      concatMap(state => session$(state.token)),
      catchError(err => {
        return throwError(() => err);
      }),
    );
  }

  unsubscribeToGetEdgeIpAddressInfo(token): Observable<void> {
    const delete$ = (token: string) => this.edgeService.deleteDocument(token);
    return delete$(token);
  }

  createOrUpdateLocalProxy(prefix: string, localIp: string) {
    const data: Edge.ConfigureEdgeProxy = {
      prefix,
      localIp,
    };
    const url = `${environment.apiUrl}/cameras/camera/local/configure`;
    return this.http.post(url, data);
  }

  calculateExpiryTime(expiresInSeconds): number {
    return new Date().getTime() + expiresInSeconds * 1000;
  }

  async onIpAddressReceived(session: GetEdgeIpAddressToken.AllSessionData, edgeId: string) {
    const ipAddresses: Edge.EdgeIpAddressInfo = session.result?.edgeIpAddressInfo;
    for(let key in ipAddresses) {
      if (key !== 'eth0' && key !== 'eth1') {
        continue;
      }
      const ip = ipAddresses[key][0].address;
      const prefix = `${edgeId}-${key}`;
      this.createOrUpdateLocalProxy(prefix, ip)
        .pipe(
          concatMap(_ => {
            return this.edgeService.getEdgeLocalNetwork(prefix)
              .pipe(
                catchError(err => of({})),
              );
          }),
          tap((res: Edge.EdgeDocument) => {
            if (res.edgeId === edgeId) {
              console.log(`edge: ${edgeId} can stream locally`);
            }
            return res;
          }),
          filter(res => res.edgeId === edgeId),
          tap(_ => {
            const localStreamUrl = `${edgeId}.lumixai.com-${prefix}`; //${environment.edge.edgeHttpsUrl(prefix)}`;
            const request: Edge.UpdateEdgeLocalAddressRequest = {
              edgeId,
              isLocal: true,
              localExpiry: this.calculateExpiryTime(1800),
              localUrl: localStreamUrl,
            };
            this.store.dispatch(EdgeActions.UpdateEdgeLocalAddress({ request }));
          }),
        )
        .subscribe({
          complete: () => {
          },
          error: () => {
          },
        });
    }
  }

  onIpAddressReceivedError(message = 'unknown error occured') {
    // this.snackBar.open(message, '', { duration: 5000 });
  }

  canLocalStream(locationId: string, edgeId: string) {
    this.store
      .pipe(
        select(EdgeSelectors.selectEdgeById(edgeId!)),
        // filter(edge => !edge?.isLocal || edge?.localExpiry < new Date().getTime()),
        filter(edge => !edge?.isLocal),
        concatMap(edge =>
          this.subscribeToGetEdgeIpAddressInfo({
            locationId,
            edgeId,
          }),
        ),
        tap(session => this.onIpAddressReceived(session, edgeId)),
        concatMap(state => this.unsubscribeToGetEdgeIpAddressInfo(state.token)),
        take(1),
      )
      .subscribe({
        next: () => {
        },
        complete: () => {
        },
        error: (err: HttpErrorResponse | Error) => {
          let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;
          this.onIpAddressReceivedError(msg);
        },
      });
  }
}
