import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { OrganizationService } from '../../development/organization.service';
import { ApiKeyActions } from '@states/api-key/api-key.action-types';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ApiKeyEffect {

  public startLoadingApiKeys = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.startLoadingApiKeys),
      exhaustMap(() => [ApiKeyActions.setIsLoading({ isLoading: true }), ApiKeyActions.getApiKeys()]),
    ),
  );


  public getApiKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.getApiKeys),
      withLatestFrom(this.store$.pipe(select(state => state.apiKeyState))),
      switchMap(([, { page, perPage, query, filters, isLastPage }]) => {
          if (!isLastPage) {
            return this.organizationService.getApiKeys(page, perPage, query, filters)
              .pipe(
                switchMap(res => {
                  return [
                    ApiKeyActions.getApiKeysSuccess({ apiKeys: res.items }),
                    ApiKeyActions.setIsLoading({ isLoading: false }),
                  ];
                }),
                catchError(response => {
                  return [
                    this.catchError(response), ApiKeyActions.setIsLoading({ isLoading: false }),
                  ];
                }),
              );
          } else {
            return of(ApiKeyActions.setIsLoading({ isLoading: false }));
          }
        },
      ),
      share(),
    ),
  );

  public generateApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.generateApiKey),
      exhaustMap(({ dto }) =>
        [
          SharedActions.setIsSaving({ isSaving: true }),
          ApiKeyActions.generateApiKeyServerCall({ dto }),
        ],
      ),
      share(),
    ),
  );

  public generateApiKeyServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.generateApiKeyServerCall),
      switchMap(({ dto }) =>
        this.organizationService.generateApiKey(dto)
          .pipe(
            switchMap(res => {
              return [
                ApiKeyActions.generateApiSuccess({ token: res.token }),
                SharedActions.showMessage({ success: 'Api key has been generated' }),
                ApiKeyActions.resetList(),
                ApiKeyActions.getApiKeys(),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [
                this.catchError(response),
                SharedActions.setIsSaving({ isSaving: false }),
                ApiKeyActions.generateApiFail(),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public revokeApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.revokeApiKey),
      switchMap(({ id }) =>
        this.organizationService.revokeApiKey(id)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Api key has been revoked' }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          ),
      ),
      share(),
    ),
  );

  public extendApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiKeyActions.extendApiKey),
      switchMap(({ apiKey, extend }) =>
        this.organizationService.extentApiKey({ apiKey, extend })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Api key has been extended' }),
                ApiKeyActions.getApiKeys(),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          ),
      ),
      share(),
    ),
  );


  public filterTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiKeyActions.setQuery,
        ApiKeyActions.setOrderDirection,
      ),
      debounceTime(400),
      switchMap(() => [
        ApiKeyActions.setIsLoading({ isLoading: true }),
        ApiKeyActions.resetList(),
        ApiKeyActions.startLoadingApiKeys(),
      ]),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private organizationService: OrganizationService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }
}
