import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-du-modal',
  templateUrl: './du-modal.component.html',
  styleUrls: ['./du-modal.component.scss'],
})
export class DuModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public args: string = null;

  constructor(private matDialogRef: MatDialogRef<DuModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.args);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
