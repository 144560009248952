import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { catchError, concatMap, filter, map, Observable, of, shareReplay, Subscription, take, throwError, timeout } from 'rxjs';
import { CameraStreamExistsRequest, EdgeCamera } from '../../cameras/camera.model';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { MediaEvents, PlayerComponent } from '../../framework/player/player.component';
import { CamerasService } from '../../cameras/cameras.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Quality, QualitySelectorComponent } from '../ui-kit/ui-quality-selector/quality-selector.component';
import { Region } from '@enums/shared.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { EdgeService } from '../../edge/edge.service';
import { TokenDataMessageBase, TokenDataStatus } from '../../core/messaging.interfaces';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { WebrtcPlayerComponent } from '../../framework/webrtc-player/webrtc-player.component';
import { CamerasSelectors } from '@states/cameras/cameras.selector-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { WallV2Model } from '@models/wall-v2.model';

export interface CameraOptions {
  allowZoom: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-live-view-camera',
  templateUrl: './live-view-camera.component.html',
  styleUrls: ['./live-view-camera.component.scss'],
})
export class LiveViewCameraComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public selectAllCameras$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store.pipe(select(CameraSelectors.selectCamerasLookup));

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  @Input() index: number;

  @Input() options: CameraOptions = {
    allowZoom: false,
  };

  @Input()
  camera: WallV2Model.WallCamera;

  selectIsLocal$: Observable<boolean>;

  @Input()
  edit: boolean;

  @ViewChild('player')
  player: PlayerComponent;

  @ViewChild('snapshot')
  snapshot: ElementRef;

  @ViewChild('webrtcPlayer')
  webrtcPlayer: WebrtcPlayerComponent;

  @ViewChild('qualitySelector')
  qualitySelector: QualitySelectorComponent;

  @ViewChild('playerWrapper')
  playerWrapper: ElementRef;

  @Input()
  allowQuality = false;

  webrtc = false;
  webrtcRelay = false;

  scale = true;

  loadvid = true;
  public isLocal = false;
  localUrl;
  public forceCloud = false;
  playable = false;
  startedPlaying = false;
  polling = false;

  pollingSub: Subscription;

  extendInterval;

  edgeRegion: Region;

  focusReload = false;

  allowStream = false;

  waitingTime = 0;
  waitingTimeIntervalId = null;

  bufferNudgeOnStallTime = 0;
  bufferNudgeOnStallIntervalId = null;

  bufferStalledErrorTime = 0;
  bufferStalledErrorIntervalId = null;

  visibilityChangeTime = 0;
  visibilityChangeIntervalId = null;

  liveViewSubscription$: Subscription;

  canPlayStartSub: Subscription;

  isInactive$: Observable<boolean> = this.store.select(SharedSelectors.selectIsInactive);
  isWebrtc$: Observable<boolean> = this.store.select(CamerasSelectors.selectIsWebrtc);
  isWebrtcRelay$: Observable<boolean> = this.store.select(CamerasSelectors.selectIsWebrtcRelay);
  inactive = false;

  countVisibilityChangeTime() {
    this.visibilityChangeIntervalId = setInterval(() => {
      this.visibilityChangeTime++;

      console.log(`visibility time for id: ${this.visibilityChangeIntervalId} is: ${this.visibilityChangeTime}`);

      if (this.visibilityChangeTime === 30) {
        console.log(
          `visibility time for id: ${this.visibilityChangeIntervalId} reached the limit of: ${this.visibilityChangeTime} seconds wait`,
        );
        console.log('tab defocus detected - stopping');
        this.inactive = true;
        this.pause();
      }
    }, 1000);
  }

  pause(setLoad = false) {
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }

    if (this.webrtc) {
      if (!!this.webrtcPlayer) {
        this.webrtcPlayer.stop();
      }
    }
    if (!this.webrtc) {
      if (!!this.player) {
        this.player.stopHls();
      }
    }
    this.loadvid = false;
    this.startedPlaying = false;
    this.clearEventIntervals();

    this.clearVisibilityChangeTime();
  }

  clearVisibilityChangeTime() {
    if (!!this.visibilityChangeIntervalId) {
      console.log(`clearing visibility time id: ${this.visibilityChangeIntervalId}`);
      clearInterval(this.visibilityChangeIntervalId);
      this.visibilityChangeTime = 0;
      this.visibilityChangeIntervalId = null;
    }
  }

  checkHiddenDocument(visibilityChanged: any) {
    if (visibilityChanged.hidden) {
      this.countVisibilityChangeTime();
    } else {
      this.clearVisibilityChangeTime();
      if (!!this.playing && !this.tabFocus && this.loadvid) {
        this.tabFocus = true;
        console.log('tab focus detected - reloading');
        // this.startLiveViewNew();
      }
    }
  }

  set tabFocus(focus) {
    console.log(`setting tab focus to: ${focus}`);
    this.focusReload = focus;
  }

  get tabFocus() {
    return this.focusReload;
  }

  constructor(
    private store: Store,
    private edgeStatusService: EdgeStatusService,
    private edgeService: EdgeService,
    private camerasService: CamerasService,
    private snackBar: MatSnackBar,
    private utilsV2Service: UtilsV2Service,
  ) {
  }

  ngOnInit(): void {
    if (!this.camera) {
      return;
    }
    this.selectIsLocal$ = this.store.pipe(select(EdgeSelectors.selectLocalById(this.camera.edgeId)));
    this.store
      .select(EdgeSelectors.selectEdgeRegion(this.camera.edgeId))
      .pipe(take(1))
      .subscribe(region => {
        this.edgeRegion = region;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['camera']) {
      this.camera = _.cloneDeep(changes['camera'].currentValue);
    }
  }

  getStreamPrefix() {
    return this.camerasService.getStreamPrefix(this.edgeRegion);
  }

  getStreamUrl(edgeId, cameraId): string {
    return `${this.getStreamPrefix()}/${this.scale ? 'video_streams_scale' : 'video_streams_scale_hq'}/${edgeId}/${cameraId}/s.m3u8`;
  }

  getLocalStreamUrl(localUrl: string) {
    return `${localUrl}/streams/${this.scale ? 'scale/' : 'scaleHQ/'}${this.camera.edgeId}/${this.camera.cameraId}/video/manifest.m3u8`;
  }

  playing() {
    this.loadvid = false;
    if (!!this.qualitySelector) {
      this.qualitySelector.loading = false;
    }
  }

  onPlaying() {
    this.loadvid = false;
  }

  getCameraStatus(): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getCameraPulsationStatus(this.camera?.cameraId);
  }

  canPlay(status: PulsationModels.ComponentStatus) {
    return status === PulsationModels.ComponentStatus.Online || status === PulsationModels.ComponentStatus.Streaming || status === PulsationModels.ComponentStatus.Connected;
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  error() {
    this.camera.err = true;
    this.startedPlaying = false;
    if (!!this.qualitySelector) {
      this.qualitySelector.loading = false;
    }
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
  }

  resetError() {
    this.camera.err = false;
  }

  canPlayStart() {
    if (!!this.canPlayStartSub) {
      this.canPlayStartSub?.unsubscribe();
    }
    this.camera.err = false;
    this.canPlayStartSub = this.getCameraStatus()
      .pipe(untilDestroyed(this), shareReplay())
      .subscribe(status => {
        this.playable = this.canPlay(status);
        if (this.playable) {
          if (!this.startedPlaying) {
            this.startedPlaying = true;
            if (this.webrtc) {
              this.webrtcPlayer.play();
              if (this.extendInterval) {
                clearInterval(this.extendInterval);
              }
              this.extendInterval = setInterval(() => {
                this.webrtcPlayer.extend();
              }, 180000);
            } else {
              this.startLiveViewNew();
            }
          }
          this.canPlayStartSub?.unsubscribe();
        }
      });
  }

  ngAfterViewInit(): void {
    if (!!this.camera?.edgeId) {
      if (this.edit) {
        this.loadvid = false;
        return;
      }

      this.isInactive$.pipe(untilDestroyed(this))
        .subscribe(isInactive => {
          // Active -> inactive - Pause
          if (this.inactive === false && !!isInactive) {
            this.pause();
          }
          // Inactive -> active - Start
          if (!!this.inactive && !isInactive) {
            this.canPlayStart();
          }
          this.inactive = isInactive;
        });

      this.isWebrtc$.pipe(untilDestroyed(this))
        .subscribe(isWebrtc => {
          this.webrtc = isWebrtc;
          if (this.startedPlaying) {
            if (this.webrtc && this.liveViewSubscription$) {
              this.liveViewSubscription$.unsubscribe();
            }
            this.startedPlaying = false;
            this.inactive = false;
            this.loadvid = true;
            this.canPlayStart();
          }
        });

      this.isWebrtcRelay$.pipe(untilDestroyed(this))
        .subscribe(webrtcRelay => {
          this.webrtcRelay = webrtcRelay;
          if (this.startedPlaying) {
            if (this.webrtc && this.liveViewSubscription$) {
              this.liveViewSubscription$.unsubscribe();
              this.webrtcPlayer.stop();
            }
            this.startedPlaying = false;
            this.inactive = false;
            this.loadvid = true;
            this.canPlayStart();
          }
        });

      if (!this.webrtc) {
        this.store.pipe(untilDestroyed(this), shareReplay(), select(EdgeSelectors.selectLocalById(this.camera.edgeId)))
          .subscribe(isLocal => {
            console.log('Is Local value: ' + isLocal);

            this.isLocal = isLocal;
            this.setLocalStatus();
            if (isLocal) {
              this.store.pipe(untilDestroyed(this), select(EdgeSelectors.selectEdgeById(this.camera.edgeId)), take(1))
                .subscribe(edge => {
                  this.localUrl = edge.localUrl;
                  this.player.setSrc(`${edge.localUrl}/streams/scale/${edge.edgeId}/${this.camera.cameraId}/video/manifest.m3u8`);
                });
            } else {
              this.player.setSrc(this.getStreamUrl(this!.camera.edgeId, this!.camera.cameraId));
              console.log('should play cloud: ' + this.player.src);
            }
            this.canPlayStart();
          });
      } else {
        this.canPlayStart();
      }
    }
  }

  startLiveViewNew(reload = true) {
    if (!this.camera) {
      return of(true);
    }
    if (!this.edgeStatusService.cameraStreamAllowed(this.camera.cameraId)) {
      return of(true);
    }
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    this.extendInterval = setInterval(() => {
      this.startLiveViewNew(false);
    }, 180000);
    const edgeId = this.camera.edgeId;
    const cameraId = this.camera.cameraId;
    const locationId = this.camera.locationId;
    if (reload) {
      this.loadvid = true;
    }
    const payload: CameraStreamExistsRequest = {
      edgeId: edgeId,
      cameraId: cameraId,
      scale: this.scale,
      region: this.edgeRegion,
    };

    const dispatch$ = this.camerasService.startLiveView(edgeId, locationId, cameraId, this.scale, !this.isLocal || this.forceCloud);
    const firebase$ = (token: string) =>
      this.edgeService.subscribeToSessionStatus(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );

    // const componentStatus$ = () =>
    //   this.store.select(EdgeStatusSelectors.selectEdgeStatusByEdgeId(edgeId))
    //     .pipe(
    //       map(edgeStatus => edgeStatus.cameraStatus.filter(item => item.cameraId === cameraId)),
    //       filter(
    //         cameraStatus => cameraStatus[0].componentsStatus[this.scale ? 'hlsscaled' : 'hlsscaledhq'] === StreamerComponentStatus.Available,
    //       ),
    //       take(1),
    //     );

    const session$ = (token: string) =>
      this.edgeService.getSessionData<TokenDataMessageBase>(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );
    const delete$ = (token: string) => this.edgeService.deleteDocument(token);

    this.camera.err = false;
    if (!reload) {
      return dispatch$.subscribe();
    }

    this.liveViewSubscription$ = dispatch$
      .pipe(
        concatMap(res => firebase$(res.token.session)),
        filter(state => state?.status === TokenDataStatus.COMPLETED),
        concatMap(state => session$(state.token)),
        concatMap(state => delete$(state.token)),
        // concatMap(state => componentStatus$()),
        timeout(40000),
      )
      .subscribe({
        complete: () => {
          this.camerasService
            .pollStreamExists({
              edgeId,
              cameraId,
              scale: this.scale,
              local: this.isLocal && !this.forceCloud,
              localUrl: this.localUrl,
              region: this.edgeRegion,
            })
            .pipe(
              untilDestroyed(this),
              catchError(err => {
                this.camera.err = true;
                this.startedPlaying = false;
                this.loadvid = false;
                if (this.extendInterval) {
                  clearInterval(this.extendInterval);
                }
                return throwError(() => err);
              }),
            )
            .subscribe(async () => {
              console.log('stream exist detected');
              this.tabFocus = false;
              if (reload) {
                this.reloadPlayer();
              }
            });
        },
        error: (err: HttpErrorResponse | Error) => {
          console.log('startLiveViewNew subscribtion error');

          let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;

          this.onStreamError(msg);
        },
      });

    return this.liveViewSubscription$;
  }

  onStreamError(message = 'unknown error occured') {
    console.log(`live view error occured: ${message}`);
    this.loadvid = false;
    if (!!this.qualitySelector) {
      this.qualitySelector.loading = false;
    }
    this.camera.err = true;
    this.snackBar.open(message, '', { duration: 5000 });
  }

  async reloadPlayer() {
    return this.player.reload();
  }

  getEdge() {
    return this.store.pipe(untilDestroyed(this), select(EdgeSelectors.selectEdgeById(this.camera.edgeId)), take(1));
  }

  public setRelayStatus(relay: boolean) {

  }

  setLocalStatus() {
  }

  toggleCameraLocal() {
    if (!this.isLocal) {
      return;
    }
    this.player.stop();
    this.loadvid = true;
    this.forceCloud = !this.forceCloud;
    this.setLocalStatus();
    if (!!this.forceCloud) {
      this.player.setSrc(this.getStreamUrl(this.camera.edgeId, this.camera.cameraId));
      this.canPlayStart();
    } else {
      this.getEdge()
        .pipe(untilDestroyed(this), take(1))
        .subscribe(edge => {
          if (!edge?.localUrl) {
            return;
          }
          this.player.setSrc(this.getLocalStreamUrl(edge.localUrl));
          this.canPlayStart();
        });
    }
  }

  get isFullscreen() {
    return this.utilsV2Service.isFullscreen();
  }

  async maximize() {
    // this.player.maximize();
    const elem = this.playerWrapper?.nativeElement;
    if (!this.isFullscreen) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }

    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }
  }

  ngOnDestroy(): void {
    if (!!this.liveViewSubscription$) {
      this.liveViewSubscription$.unsubscribe();
    }

    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    if (!!this.player) {
      this.player.stopHls();
    }
    this.clearEventIntervals();
  }

  countWaitingTime() {
    this.waitingTimeIntervalId = setInterval(() => {
      this.waitingTime++;

      console.log(`wait for id: ${this.waitingTimeIntervalId} is: ${this.waitingTime}`);

      if (this.waitingTime === 2) {
        console.log(`wait for id: ${this.waitingTimeIntervalId} reached the limit of: ${this.waitingTime} seconds wait, restarting..`);
        this.clearWaitingTime();
      }
    }, 2000);
  }

  clearWaitingTime() {
    if (!!this.waitingTimeIntervalId) {
      console.log(`clearing wait id: ${this.waitingTimeIntervalId}`);
      clearInterval(this.waitingTimeIntervalId);
      this.waitingTime = 0;
      this.waitingTimeIntervalId = null;
    }
  }

  countBufferNudgeOnStallTime() {
    this.bufferNudgeOnStallIntervalId = setInterval(() => {
      this.bufferNudgeOnStallTime++;

      console.log(`bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} is: ${this.bufferNudgeOnStallTime}`);

      if (this.bufferNudgeOnStallTime === 3) {
        console.log(
          `bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} reached the limit of: ${this.bufferNudgeOnStallTime} seconds wait, restarting..`,
        );
        this.clearBufferNudgeOnStallTime();
      }
    }, 1000);
  }

  clearBufferNudgeOnStallTime() {
    if (!!this.bufferNudgeOnStallIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferNudgeOnStallIntervalId}`);
      clearInterval(this.bufferNudgeOnStallIntervalId);
      this.bufferNudgeOnStallTime = 0;
      this.bufferNudgeOnStallIntervalId = null;
    }
  }

  countBufferStalledErrorTime() {
    this.bufferStalledErrorIntervalId = setInterval(() => {
      this.bufferStalledErrorTime++;

      console.log(`bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} is: ${this.bufferStalledErrorTime}`);

      if (this.bufferStalledErrorTime === 4) {
        console.log(
          `bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} reached the limit of: ${this.bufferStalledErrorTime} seconds wait, restarting..`,
        );
        this.clearBufferStalledErrorTime();
      }
    }, 1000);
  }

  clearBufferStalledErrorTime() {
    if (!!this.bufferStalledErrorIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferStalledErrorIntervalId}`);
      clearInterval(this.bufferStalledErrorIntervalId);
      this.bufferStalledErrorTime = 0;
      this.bufferStalledErrorIntervalId = null;
    }
  }

  handleMediaEvents(mediaEvent: MediaEvents) {
    if (mediaEvent.event === 'freeze') {
      console.log('FREEZE - send start');
      this.startLiveViewNew(false);
    }

    if (mediaEvent.event === 'bufferNudgeOnStall') {
      console.log('checking bufferNudgeOnStall severity');
      if (!this.bufferNudgeOnStallIntervalId) {
        this.countBufferNudgeOnStallTime();
      } else {
        console.log(`bufferNudgeOnStall resolve in progress with id: ${this.bufferNudgeOnStallIntervalId}`);
      }
    }

    if (mediaEvent.event === 'bufferStalledError') {
      console.log('checking bufferStalledError severity');
      if (!this.bufferStalledErrorIntervalId) {
        this.countBufferStalledErrorTime();
      } else {
        console.log(`bufferStalledError resolve in progress with id: ${this.bufferStalledErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'waiting') {
      console.log('checking wait severity');
      if (!this.waitingTimeIntervalId) {
        this.countWaitingTime();
      } else {
        console.log(`wait resolve in progress with id: ${this.waitingTimeIntervalId}`);
      }
    }

    if (!!mediaEvent.playing) {
      this.clearEventIntervals();

      if (!!this.playing && !!this.loadvid) {
        this.loadvid = false;
        if (!!this.qualitySelector) {
          this.qualitySelector.loading = false;
        }
        this.camera.err = false;
      }
    }

    if (!!mediaEvent.error) {
      this.onStreamError(`player emiited error with message: ${mediaEvent.message || 'unknown error occured'}`);
    }
  }

  clearEventIntervals() {
    this.clearWaitingTime();
    this.clearBufferNudgeOnStallTime();
    this.clearBufferStalledErrorTime();
  }

  deleteCamera() {
    this.delete.emit();
  }

  changeQuality(quality: Quality) {
    if (quality === Quality.SQ) {
      this.scale = true;
    } else {
      this.scale = false;
    }
    if (this.forceCloud || !this.isLocal) {
      this.player.setSrc(this.getStreamUrl(this.camera.edgeId, this.camera.cameraId));
      this.startLiveViewNew();
    } else {
      this.getEdge()
        .pipe(take(1))
        .subscribe(edge => {
          if (!edge?.localUrl) {
            return;
          }
          this.localUrl = edge.localUrl;
          this.player.setSrc(
            `${edge.localUrl}/streams/${this.scale ? 'scale/' : 'scaleHQ/'}${edge.edgeId}/${this.camera.cameraId}/video/manifest.m3u8`,
          );
          this.startLiveViewNew();
        });
    }
  }

  public retry() {
    this.canPlayStart();
  }
}
