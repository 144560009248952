<div fxLayout="column" class="add-archive-modal">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
    {{data.title}}
    <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
  </div>
  <div fxLayout="row wrap" class="search-list-wrapper" fxLayoutGap="8px grid">
    <ui-filter-chip *ngFor="let object of data.filters"
                    class="filter-chip"
                    field="alertNamesFilters"
                    [title]="object | titlecase"
                    [allowDelete]="false"
    ></ui-filter-chip>
  </div>
</div>
