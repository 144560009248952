import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PeriodUnit } from '../ui-period/ui-period.component';
import { ChangeContext, Options } from 'ngx-slider-v2';

@Component({
  selector: 'ui-range-slider',
  templateUrl: './ui-range-slider.component.html',
  styleUrls: ['./ui-range-slider.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UiRangeSliderComponent implements OnInit, OnChanges {

  @Input() minValue: number = 50;
  @Input() maxValue: number = 200;
  @Input() min: number = 0;
  @Input() max: number = 0;
  options: Options = {
    floor: 0,
    ceil: 60,
    showTicks: false,
  };

  @Input() unit: PeriodUnit = PeriodUnit.seconds;

  @Output() rangeChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  public PeriodUnit = PeriodUnit;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['min']) {
      this.options = {
        ...this.options,
        floor: this.min,
      };
    }
    if (changes['max']) {
      this.options = {
        ...this.options,
        ceil: this.max,
      };
    }
  }

  ngOnInit(): void {
  }

  change(event: ChangeContext) {
    const value = [event.value, event.highValue];
    this.rangeChanged.emit(value);
  }
}


