import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, Observable, shareReplay, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OnRangeSelectedResult } from '../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import * as moment from 'moment-timezone';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import { CameraThumbnailsSort } from '../../cameras/camera-thumbnails/camera-thumbnails.component';
import { SearchService } from '../../shared/search.service';
import { EdgeCamera } from '../../cameras/camera.model';
import { CameraPickerComponent } from '../../shared/camera-picker/camera-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { SaveSearchDialogComponent } from './dialogs/save-search-dialog/save-search-dialog.component';
import { routerSegments } from '@consts/routes';
import { MultiSearchSelectors } from '@states/multi-search/multi-search.selector-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { ThumbnailTemplate } from 'src/app/shared/thumbnail/thumbnail.component';
import { MainMenuService } from '../../layout/main-menu.service';
import { TrackObjectState } from '@states/track-object/track-object.reducer';
import * as TrackObjectSelectors from '@states/track-object/track-object.selectors';
import { MultiSearchEffect } from '@effects/multi-search.effect';
import { Period, PeriodUnit } from '../../shared/ui-kit/ui-period/ui-period.component';
import * as _ from 'lodash';
import { CustomEventModel } from '@models/custom-event.model';
import { SearchResultsComponent } from '../../shared/search-results/search-results.component';
import { environment } from '../../../environments/environment';
import { CustomEventsSelectors } from '@states/custom-events/custom-events.selector-types';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import { SearchSelectionForm } from '@models/search.model';
import { Search, SearchType } from '../../shared/search.model';
import { precisionStr } from '@consts/search.const';
import { Options } from 'ngx-slider-v2';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { CameraEditActions } from '@states/camera-edit/camera-edit.action-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { MatExpansionPanel } from '@angular/material/expansion';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('motionPanel') motionPanel: MatExpansionPanel;
  @ViewChild('eventTagsPanel') eventTagsPanel: MatExpansionPanel;
  @ViewChild('unusualPanel') unusualPanel: MatExpansionPanel;
  @ViewChild('cameraPicker') cameraPicker: CameraPickerComponent;
  @ViewChild('searchResults') searchResults: SearchResultsComponent;

  public precisionStr = precisionStr;

  public ThumbnailTemplate: typeof ThumbnailTemplate = ThumbnailTemplate;

  public routerSegments = routerSegments;
  public PeriodUnit = PeriodUnit;

  public sidebarExpand = true;
  public selectDateTimeRange$: Observable<OnRangeSelectedResult> = this.store$.pipe(select(MultiSearchSelectors.selectDateTimeRange));
  public selectDwellRange$$: Observable<Search.SearchDwellRange> = this.store$.pipe(select(MultiSearchSelectors.selectDwellRange));

  public selectUseSingleStore$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectUseSingleStore));

  public selectSelectedSavedSearch$: Observable<Search.SavedSearch> = this.store$.pipe(
    select(MultiSearchSelectors.selectSelectedSavedSearch),
  );
  public selectSelectedCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.pipe(select(MultiSearchSelectors.selectSelectedCameras));
  public selectCameraChipChange$: Observable<any> = this.multiSearchEffect.cameraChipChange$.pipe(untilDestroyed(this));

  public selectAllCustomEvents$: Observable<CustomEventModel.CustomEvent[]> = this.store$.pipe(select(CustomEventsSelectors.selectAllCustomEvents))
    .pipe(untilDestroyed(this));

  public selectObjectSelectionsFormatted$: Observable<SearchSelectionForm[]> = this.store$.pipe(select(MultiSearchSelectors.selectObjectSelectionsFormatted));

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  selectHighConfidence$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectSearchHighConfidence));

  trackObject$: Observable<TrackObjectState> = this.store$.pipe(select(TrackObjectSelectors.selectTrackObjectState));

  _reloadPicker = true;

  sort: CameraThumbnailsSort = CameraThumbnailsSort.DESC;
  timezone: string = moment.tz.guess();

  options = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(new Date(new Date().getTime() - 24 * 2 * 3600 * 1000).setHours(0, 0, 0, 0))),
    end: new UntypedFormControl(new Date()),
  });

  precisionOptions: Options = {
    floor: 3,
    ceil: 7,
    step: 2,
    showTicks: false,
    showSelectionBar: true,
  };

  public personArray: UntypedFormArray;
  public vehicleArray: UntypedFormArray;
  public petArray: UntypedFormArray;
  public shoppingCartArray: UntypedFormArray;

  highConfidence = true;
  precision = Search.Precision.HIGH;
  searchV2 = false;
  useSingleStore = false;
  public period = {
    unit: PeriodUnit.minutes,
    start: 0,
    end: 61,
  };

  savedSearchName: string;

  selectedCameras: EdgeCamera.CameraItem[] = [];

  public maskDateChange = false;

  public eventTypes: CustomEventModel.CustomEvent[];

  public motionSearch = false;
  public customEventSearch = false;
  public unusualEventSearch = false;

  public isDev = environment.env === 'localNoKube' || environment.env === 'dev-hosted' || environment.env === 'localDev';

  public SearchType = SearchType;
  public searchType = SearchType.AI;
  public pickerTypes = UiCalendarPickerType;

  constructor(
    private mainMenuService: MainMenuService,
    private multiSearchEffect: MultiSearchEffect,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog, private store$: Store, private searchService: SearchService,
    private utilsV2Service: UtilsV2Service) {
  }

  ngAfterViewInit(): void {
    this.selectSavedSearch()
      .subscribe(selectedSavedSearch => {
        // this.search();
      });
    if (this.motionSearch) {
      this.motionPanel?.open();
    } else if (this.unusualEventSearch) {
      this.unusualPanel?.open();
    } else if (this.customEventSearch) {
      this.eventTagsPanel.open();
    }
  }


  ngOnInit(): void {

    this.selectSavedSearch()
      .subscribe(selectedSavedSearch => {
        this.savedSearchName = selectedSavedSearch.name;
        this.store$.dispatch(MultiSearchActions.loadSavedSearch());
        for(let [index, selectedCamera] of selectedSavedSearch.selectedCameras.entries()) {
          this.selectCamera(selectedCamera.cameraId)
            .subscribe(camera => {
              if (!!camera) {
                this.selectedCameras.push({
                  ...camera,
                  markedIdx: selectedCamera.markedIdx,
                  zones: selectedCamera.zones,
                  zonesExclude: selectedCamera.zonesExclude,
                });
              }
            });
        }
        this.store$.dispatch(
          MultiSearchActions.setSelectedCameras({
            selectedCameras: this.selectedCameras,
            noReset: true,
          }),
        );
        this.onTimeRangeSelected(selectedSavedSearch.dateTimeRange);
        this.highConfidence = selectedSavedSearch.highConfidence;
        this.store$.dispatch(MultiSearchActions.setFaces({ faces: selectedSavedSearch.faces }));
        if (selectedSavedSearch?.motionSearch) {
          this.motionSearch = true;
          this.setMotion(selectedSavedSearch.motion);
        } else if (selectedSavedSearch?.customEventSearch) {
          this.customEventSearch = true;
          this.setCustomEventSearch();
        } else if (selectedSavedSearch?.unusualEventSearch) {
          this.unusualEventSearch = true;
          this.setUnusualEvent(selectedSavedSearch.unusualEvent);
        }
      });

    this.selectDateTimeRange$.pipe(take(1))
      .subscribe(dateTimeRange => {
        this.onTimeRangeSelected(dateTimeRange);
      });
    this.selectSelectedCameras$.pipe(untilDestroyed(this))
      .subscribe(cameras => {
        this.selectedCameras = cameras;
      });

    this.selectCameraChipChange$.subscribe(() => {
      this.reloadPicker();
    });

    this.selectHighConfidence$.pipe(untilDestroyed(this))
      .subscribe(highConfidence => {
        this.highConfidence = highConfidence;
      });

    this.selectDwellRange$$.pipe(untilDestroyed(this))
      .subscribe(dwellRange => {
        this.period = _.cloneDeep(dwellRange?.period);
      });

    this.selectUseSingleStore$.pipe(untilDestroyed(this))
      .subscribe(useSingleStore => {
        this.useSingleStore = useSingleStore;
      });

    this.store$.dispatch(CameraEditActions.getHasProtectiveGear());
    this.store$.dispatch(CameraEditActions.getHasShoppingCart());
    this.store$.dispatch(CameraEditActions.getHasOrgForklift());
    this.store$.dispatch(CameraEditActions.getHasOrgContainer());
    this.store$.dispatch(CustomEventsActions.getCustomEvents());

  }

  reloadPicker() {
    setTimeout(() => (this._reloadPicker = false));
    setTimeout(() => (this._reloadPicker = true));
  }

  private selectSavedSearch() {
    return this.selectSelectedSavedSearch$.pipe(
      take(1),
      filter(selectedSavedSearch => !!selectedSavedSearch),
    );
  }

  private selectCamera(cameraId: string) {
    return this.store$.select(CameraSelectors.selectCameraById(cameraId))
      .pipe(take(1));
  }

  public search() {
    this.selectDateTimeRange$.pipe(shareReplay(), take(1))
      .subscribe((dateTimeRange) => {
        // this.onTimeRangeSelected(dateTimeRange);
        this.searchService.triggerSearch();
      });
  }

  get start() {
    return this.options.get('start')?.value;
  }

  get end() {
    return this.options.get('end')?.value;
  }

  public toggleSidebar(): void {
    this.sidebarExpand = !this.sidebarExpand;
  }

  public onTimeRangeSelected(event: OnRangeSelectedResult, mask = false) {
    this.store$.dispatch(
      MultiSearchActions.setDateTimeRange({
        dateTimeRange: event,
      }),
    );
    if (!mask) {
      this.maskDateChange = false;
    }
    if (event.type === UiCalendarPickerType.RELATIVE) {
      const start = moment()
        .subtract(event.relative.value, event.relative.unit);
      const end = moment()
        .subtract(2, 'minutes');
      //todo do formatting must be in effect before request

      this.options.setValue({
        start,
        end,
      });
    } else if (event.type === UiCalendarPickerType.ABSOLUTE) {
      this.options.setValue({
        ...event.absolute,
      });
    }
    const timeRange = this.utilsV2Service.dateRangeWithAdvancedOptionsToServerRequestV2(event);
    this.options.patchValue({ 'timeRange': timeRange });
  }

  public userTimezoneAbbreviation() {
    return moment.tz.guess();
  }

  public onCamerasSelected(cameras: EdgeCamera.CameraItem[]) {
    this.store$.dispatch(MultiSearchActions.setSelectedCameras({ selectedCameras: cameras, noReset: true }));
    this.search();
  }

  public updateObjects(update: { selections: UntypedFormArray; vehicleArray: UntypedFormArray; petArray: UntypedFormArray; shoppingCartArray: UntypedFormArray }) {
    this.personArray = update.selections;
    this.vehicleArray = update.vehicleArray;
    this.petArray = update.petArray;
    this.shoppingCartArray = update.shoppingCartArray;
    const objectSelections = [...this.personArray.value, ...this.vehicleArray.value, ...this.petArray.value, ...this.shoppingCartArray.value];
    this.selectDateTimeRange$.pipe(shareReplay(), take(1))
      .subscribe((dateTimeRange) => {
        this.maskDateChange = true;
        this.onTimeRangeSelected(dateTimeRange, true);
        this.store$.dispatch(MultiSearchActions.setObjectSelection({ objectSelections }));
      });
  }

  public resetSaved() {
    this.store$.dispatch(MultiSearchActions.setSelectedSavedSearch({ selectedSavedSearch: null }));
    delete this.savedSearchName;
    this.reset();
  }

  public reset() {
    this.searchService.reset();
    this.cameraPicker.reset();
    this.store$.dispatch(MultiSearchActions.setObjectSelection({ objectSelections: [] }));
    this.store$.dispatch(MultiSearchActions.setSelectedCameras({ selectedCameras: [] }));
    this.store$.dispatch(MultiSearchActions.setFaces({ faces: null }));
    // this.store$.dispatch(MultiSearchActions.resetToInitialState());
  }

  public updateSearch() {
    this.store$.dispatch(MultiSearchActions.updateSearch({ name: this.savedSearchName }));
  }

  public saveSearch() {
    this.dialog
      .open(SaveSearchDialogComponent, {
        width: '400px',
        height: '320px',
        panelClass: 'modal-no-padding',
      })
      .afterClosed()
      .subscribe(searchName => {
        if (!!searchName) {
          this.store$.dispatch(MultiSearchActions.saveSearch({
            name: searchName,
            motionSearch: this.motionSearch,
            unusualEventSearch: this.unusualEventSearch,
            customEventSearch: this.customEventSearch,
          }));
        }
      });
  }

  public highConfidenceChange() {
    this.store$.dispatch(MultiSearchActions.setHighConfidence({ highConfidence: this.highConfidence }));
    this.search();
  }

  public setPrecision() {
    this.store$.dispatch(MultiSearchActions.setPrecision({ precision: this.precision }));
    this.search();
  }

  public searchV2Change() {
    this.store$.dispatch(MultiSearchActions.setSearchV2({ searchV2: this.searchV2 }));
    this.search();
  }

  ngOnDestroy(): void {
    this.trackObject$.pipe(untilDestroyed(this), take(1))
      .subscribe(searchObject => {
        if (!searchObject.cameraId && !searchObject.idBase) {
          this.store$.dispatch(MultiSearchActions.resetToInitialState());
        }
      });
  }

  setUseSingleStore() {
    this.store$.dispatch(MultiSearchActions.setUseSingleStore({ useSingleStore: this.useSingleStore }));
  }

  dwellChanged(event: Period) {
    let multiplier = 1;
    switch (event.unit) {
      case PeriodUnit.seconds:
        break;
      case PeriodUnit.minutes:
        multiplier = 60;
        break;
      case PeriodUnit.hours:
        multiplier = 60 * 60;
        break;
    }
    this.period = event;
    const dwellRange: Search.SearchDwellRange = {
      period: this.period,
      start: event.start * multiplier,
      end: event.end * multiplier,
    };
    this.store$.dispatch(MultiSearchActions.setDwellRange({ dwellRange }));
    this.search();
  }

  searchEventType(customEvent: CustomEventModel.CustomEvent) {
    this.store$.dispatch(MultiSearchActions.setCustomEvent({ customEvent }));
    this.searchService.triggerSearch();
  }

  setMotion(motion?: Search.MotionSearchParams) {
    if (!motion) {
      this.motionSearch = true;
      this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.MOTION }));
      this.cd.detectChanges();
      motion = {
        sensitivityEnabled: true,
        minMotion: 50,
      };
    }
    this.store$.dispatch(MultiSearchActions.setMotion({ motion }));
    this.searchService.triggerSearch();
  }

  resetMotion() {
    this.motionSearch = false;
    this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.AI }));
    this.cd.detectChanges();
    this.searchResults.reset();
  }

  setCustomEventSearch() {
    this.customEventSearch = true;
    this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.EVENT_TYPE }));
    this.cd.detectChanges();
    this.searchService.triggerSearch();
  }

  resetCustomEventSearch() {
    this.customEventSearch = false;
    this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.AI }));
    this.cd.detectChanges();
    this.searchResults.reset();
  }

  public searchTypeChanged() {
    switch (this.searchType) {
      case SearchType.AI:
        this.resetCustomEventSearch();
        this.resetCustomEventSearch();
        break;
      case SearchType.MOTION:
        this.resetCustomEventSearch();
        this.setMotion();
        break;
      case SearchType.EVENT_TYPE:
        this.resetMotion();
        this.setCustomEventSearch();
        break;
    }
    this.searchResults.reset();
    this.search();
  }

  public refresh() {
    // If timerange is relative compute new timerange for now and search again
    this.selectDateTimeRange$.pipe(shareReplay(), take(1))
      .subscribe((dateTimeRange) => {
        if (dateTimeRange.type === UiCalendarPickerType.RELATIVE) {
          const start = moment()
            .subtract(dateTimeRange.relative.value, dateTimeRange.relative.unit);
          const end = moment()
            .subtract(2, 'minutes');
          this.options.setValue({
            start,
            end,
          });
        }
        this.search();
      });
  }

  setUnusualEvent(unusualEvent?: Search.UnusualEventSearchParams) {
    if (!unusualEvent) {
      this.unusualEventSearch = true;
      this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.UNUSUAL_EVENT }));
      this.cd.detectChanges();
      unusualEvent = {
        sensitivity: unusualEvent?.sensitivity || 50,
      };
    }
    this.store$.dispatch(MultiSearchActions.setUnusualEvent({ unusualEvent }));
    this.searchService.triggerSearch();
  }

  resetUnusualEvent() {
    this.unusualEventSearch = false;
    this.store$.dispatch(MultiSearchActions.setSearchType({ searchType: SearchType.AI }));
    this.cd.detectChanges();
    this.searchResults.reset();
  }

}
