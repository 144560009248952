import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'ui-date-time-header',
  templateUrl: './ui-date-time-header.component.html',
  styleUrls: ['./ui-date-time-header.component.scss'],
})
export class UiDateTimeHeaderComponent implements OnInit, OnChanges {
  @Input() calendarLeft: MatCalendar<any>;
  @Input() calendarRight: MatCalendar<any>;

  @Input() activeStartDate: Date;
  @Input() activeEndDate: Date;

  public isSameYear: boolean = true;

  constructor(private _dateAdapter: DateAdapter<any>) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isSameYear = moment(this.activeStartDate)
      .year() === moment(this.activeEndDate)
      .year();
  }

  public previousClicked(mode: 'month' | 'year') {
    this.calendarLeft.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, -1);

    if (this.calendarRight) {
      this.calendarRight.activeDate =
        mode === 'month'
          ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1)
          : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);
    }
  }

  public nextClicked(mode: 'month' | 'year') {
    if (this.calendarRight) {
      const momentCalendarRight = moment(this.calendarRight.activeDate);
      if (momentCalendarRight.month() === moment()
        .month() && momentCalendarRight.year() === moment()
        .year()) {
        return;
      }
    }
    this.calendarLeft.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);

    if (this.calendarRight) {
      this.calendarRight.activeDate =
        mode === 'month'
          ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1)
          : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);
    }
  }
}
