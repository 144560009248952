<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="row">
          <mat-form-field class="col">
            <input matInput type="text" name="edgeId" placeholder="edgeId" formControlName="edgeId"/>
            <mat-error *ngIf="edgeId?.errors?.['required']">CoreId is Required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--            <div class="col-md-8">-->
      <!--                <div class="row">-->
      <!--                    <mat-form-field>-->
      <!--                        <input matInput type="text" name="cameraId" placeholder="cameraId" formControlName="cameraId">-->
      <!--                        <mat-error *ngIf="cameraId?.errors?.['required']">cameraId is Required</mat-error>-->
      <!--                    </mat-form-field>-->
      <!--                </div>-->
      <!--            </div>-->

      <!--            <div class="col-md-8">-->
      <!--                <div class="row">-->
      <!--                    <mat-form-field>-->
      <!--                        <input matInput type="text" name="startYear" placeholder="startYear"-->
      <!--                            formControlName="startYear">-->
      <!--                        <mat-error *ngIf="startYear?.errors?.['required']">startYear is Required</mat-error>-->
      <!--                    </mat-form-field>-->
      <!--                </div>-->
      <!--            </div>-->

      <!--            <div class="col-md-8">-->
      <!--                <div class="row">-->
      <!--                    <mat-form-field>-->
      <!--                        <input matInput type="text" name="endYear" placeholder="endYear" formControlName="endYear">-->
      <!--                        <mat-error *ngIf="endYear?.errors?.['required']">endYear is Required</mat-error>-->
      <!--                    </mat-form-field>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>

    <div class="d-flex justify-content-center">
      <button mat-flat-button [disabled]="!form.valid" color="accent" type="submit">Create</button>
    </div>
  </form>
</div>
