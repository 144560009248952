import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, concatMap, debounceTime, exhaustMap, forkJoin, map, mergeMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DashboardService } from '../../development/dashboard.service';
import { WidgetDeprecated, WidgetBaseDecprecated, DashboardModel } from '@models/dashboard.model';
import { DashboardActions } from '@states/dashboard/dashboard.action-types';
import { OrganizationSelectors } from "@states/organization/organization.selector-types";
import { GroupModels } from "@models/people.model";
import { ActiveOrganization } from "@models/organization.model";
import { WidgetService } from "../../development/widget.service";
import * as ArchiveAction from "@states/archive/archive.actions";

@Injectable()
export class DashboardEffects {

  public pressRefresh$ = createEffect(() => this.actions$.pipe(ofType(DashboardActions.refreshWidgets), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public refreshWidget$ = createEffect(() => this.actions$.pipe(ofType(DashboardActions.refreshWidget), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public saveDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.saveDashboard),
      withLatestFrom(this.store$.pipe(select(state => state.dashboardState))),
      exhaustMap(([{dashboard}, {}]) => {
        if (!dashboard.name) {
          // return of(SharedActions.showMessage({error: 'Dashboard name is required'}));
          dashboard.name = 'Untitled dashboard';
        }
        return [SharedActions.setIsSaving({isSaving: true}), DashboardActions.sendDashboardToServer({dashboard})];
      }),
    ),
  );

  public sendDashboardToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.sendDashboardToServer),
      withLatestFrom(this.store$.pipe(select(state => state.dashboardState))),
      switchMap(([{dashboard}, {}]) => {
        const request: DashboardModel.Dashboard = {
          ...dashboard,
        };
        if (dashboard.id) {
          return this.dashboardService.update(request)
            .pipe(
              switchMap((res) => {
                return [
                  DashboardActions.sendDashboardToServerSuccess({dashboard: res}),
                  SharedActions.showMessage({success: 'Dashboard has been successfully updated'}),
                  SharedActions.setIsSaving({isSaving: false}),
                ];
              }),
              catchError(err => of(SharedActions.doNothing())),
            );
        } else {
          return this.dashboardService.create(request)
            .pipe(
              switchMap((res) => {
                return [
                  DashboardActions.getDashboards(),
                  DashboardActions.sendDashboardToServerSuccess({dashboard: res}),
                  SharedActions.showMessage({success: 'Dashboard has been successfully created'}),
                  SharedActions.setIsSaving({isSaving: false}),
                ];
              }),
              catchError(err => of(SharedActions.doNothing())),
            );
        }
      }),
      share(),
    ),
  );

  public sendDashboardToServerSuccess$ = createEffect(() => this.actions$.pipe(ofType(DashboardActions.sendDashboardToServerSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public getDashboards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getDashboards),
      withLatestFrom(this.store$.pipe(select(state => state.dashboardState))),
      switchMap(([, {page, perPage, orderBy, orderDirection, query}]) => {
        return this.dashboardService.getDashboards(page, perPage, orderBy, orderDirection, query)
          .pipe(
            switchMap(result => {
              return [
                DashboardActions.getDashboardsSuccess({dashboards: result}),
                DashboardActions.setTotalItemsCount({
                  totalItemsCount: result?.length ?? 0,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getDashboard),
      switchMap(({id}) => {
        return this.dashboardService.one(id)
          .pipe(
            switchMap(dashboard => {
              return [DashboardActions.getDashboardSuccess({dashboard})];
            }),
            catchError(err => of(DashboardActions.getDashboardFail())),
          );
      }),
    ),
  );

  public removeDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.removeDashboard),
      switchMap(({id}) => {
        return this.dashboardService.remove(id)
          .pipe(
            switchMap(dashboard => {
              return [
                DashboardActions.removeDashboardSuccess(),
                SharedActions.showMessage({success: 'Dashboard has been successfully removed'}),
                DashboardActions.getDashboards(),
              ];
            }),
            catchError(err => of(DashboardActions.removeDashboardFail())),
          );
      }),
    ),
  );


  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setQuery),
      debounceTime(400),
      switchMap(() => of(DashboardActions.getDashboards())),
    ),
  );

  // public getWidgetData$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DashboardActions.getWidgetData),
  //     mergeMap(({ variables, relative, relativeCustom, widgetIndex, absolute, widgetType, cameraId }) => {
  //       return this.dashboardService
  //         .getWidgetData({
  //           widgetType,
  //           variables,
  //           relative,
  //           absolute: absolute
  //             ? {
  //                 start: new Date(absolute.start).getTime(),
  //                 end: new Date(absolute.end).getTime(),
  //                 timezone: absolute.timezone,
  //               }
  //             : null,
  //           cameraId,
  //           relativeCustom: relativeCustom,
  //         })
  //         .pipe(
  //           takeUntil(
  //             this.actions$.pipe(
  //               ofType(DashboardActions.cancelLoadWidgetData, DashboardActions.clearFilters, DashboardActions.setAbsoluteDate)
  //             )
  //           ),
  //           mergeMap(res => {
  //             return [DashboardActions.getWidgetDataSuccess({ data: res, index: widgetIndex })];
  //           }),
  //           catchError(err => of(DashboardActions.getWidgetDataFail()))
  //         );
  //     }),
  //     share()
  //   )
  // );

  UploadImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.UploadImages),
      map(res => res.request),
      exhaustMap((request) => {
        return [
          // SharedActions.setIsSaving({isSaving: true}),
          DashboardActions.UploadImagesSend({
            request
          }),
        ];
      }),
    ),
  );

  uploadImagesSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.UploadImagesSend),
      withLatestFrom(this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization),)),
      map((res) => {
        return {
          request: res[0].request,
          org: res[1]
        }
      }),
      switchMap((data: {
        request: GroupModels.FileAsset[],
        org: ActiveOrganization
      }) => {
        const orgId = data.org.orgId;

        const presignedReqeust$ = data.request.map(e => this.widgetService.getImagePreSignedUrl({asset: e.asset}, e.file));
        const presignedResponse$ = forkJoin(presignedReqeust$);

        return presignedResponse$
          .pipe(
            mergeMap(res => {
              const uploadReqeust$ = res.map(e => this.widgetService.uploadImagePresignedUrl({url: e.url, file: e.file, filename: e.filename}));
              const uploadResponse$ = forkJoin(uploadReqeust$);

              return uploadResponse$;
            }),
            switchMap(response => {
              return [DashboardActions.UploadImagesSendSuccess({response})];
            }),
            catchError(err => {
              return [DashboardActions.UploadImagesSendError()];
            }),
          )

        return [];
      }),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private dashboardService: DashboardService,
    private widgetService: WidgetService,
  ) {
  }

  private transformWidgets(widgets: WidgetDeprecated[]): WidgetBaseDecprecated[] {
    return widgets.map(widget => {
      return {
        type: widget.type,
        name: widget.name,
        viewMode: widget.viewMode,
        variables: widget.variables,
        variableConfigs: widget.variableConfigs,
        absolute: widget.absolute,
        relative: widget.relative,
        cameraId: widget.cameraId,
        relativeCustom: widget.relativeCustom,
      };
    });
  }

  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setFilter, DashboardActions.rmFilter),
      debounceTime(400),
      exhaustMap(() => [DashboardActions.refreshWidgets()]),
    ),
  );
}
