<ng-container *ngrxLet="selectSipSpeakers$; let speakers">
  <div class="ptt-wrapper flex-row start-center">
    <div *ngIf="speakers?.length > 1">
      <mat-select class="speaker-selector"
                  [(ngModel)]="selectedSpeaker"
                  [compareWith]="speakerComparator"
                  panelWidth="250" panelClass="resolution-selector-panel"
      >
        <mat-option *ngFor="let speaker of speakers" [value]="speaker">{{ speaker.name }}</mat-option>
      </mat-select>
    </div>
    <div matTooltip="Push to talk" class="microphone-wrapper p-r flex-row center-center" (click)="startCall()">

      <span class="ui-icon-microphone p-r" [class.active]="open">

      </span>
      <div class="calling-animation" *ngIf="ringing">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  </div>
</ng-container>
