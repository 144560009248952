import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { catchError, concatMap, filter, map, Observable, Subject, Subscription, take, takeUntil, tap, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as SharedSelector from '@states/shared/shared.selectors';
import { PlaybackSpeeds } from '../playback-player.model';
import { StreamPlayerComponent } from '../../../framework/stream-player/stream-player.component';
import { PlaybackTimelineComponent } from '../playback-timeline/playback-timeline.component';
import { PlayerControlsComponent } from '../../player-controls/player-controls.component';
import { Quality, QualitySelectorComponent } from '../../ui-kit/ui-quality-selector/quality-selector.component';
import { Region } from '@enums/shared.enum';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import * as SharedActions from '@states/shared/shared.actions';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SNSResponseCode, TokenDataMessageBase, TokenDataStatus } from '../../../core/messaging.interfaces';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { PlaybackResponseCode, PlaybackResponseSNSMsg } from '../../../cameras/playback.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PLAYBACK_TIMEOUT } from '../playback-player.component';
import { MediaEvents } from '../../../framework/player/player.component';
import { PlaybackPlayerService } from '../playback-player.service';
import { EdgeService } from '../../../edge/edge.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CamerasService } from '../../../cameras/cameras.service';
import { APP_INACTIVITY_THRESHOLD, APP_INACTIVITY_TIME } from '@consts/general.const';
import { UtilsV2Service } from '../../../services/utils-v2.service';

@Component({
  selector: 'app-playback-player-single',
  templateUrl: './playback-player-single.component.html',
  styleUrls: ['./playback-player-single.component.scss'],
})
export class PlaybackPlayerSingleComponent implements OnInit {

  public selectIsInactive$: Observable<boolean> = this.store.pipe(select(SharedSelector.selectIsInactive));
  speeds: number[] = PlaybackSpeeds;
  seekerPos = 20;

  @ViewChild('playerWrapper')
  playerWrapper: ElementRef;

  @ViewChild('player')
    // player: PlayerComponent;
  player: StreamPlayerComponent;

  @ViewChild('timeline')
  timeline: PlaybackTimelineComponent;

  @ViewChild('controls')
  controls: PlayerControlsComponent;

  @ViewChild('qualitySelector')
  qualitySelector: QualitySelectorComponent;

  @Input()
  startTime;

  @Input()
  duration;

  @Input()
  time = new Date().getTime();

  @Input()
  timezone;

  src;

  streamBase: string;

  @Input()
  cameraId;

  @Input()
  cameraName;

  speed = 1;

  edgeId;
  edgeRegion: Region;
  localUrl = '';
  locationId;

  locationName$: Observable<string | undefined>;

  sessionId = '';

  tag = new Date().getTime();

  isLocal$: Observable<boolean>;
  isLocal: boolean;

  playing = true;

  playing$: Observable<boolean>;
  time$: Observable<number>;

  extendInterval;
  reloadInterval;

  error = false;
  reloading = false;
  stopped = false;
  loading = false;

  baseTime: number;
  baseUpdate: number;

  waitingTime = 0;
  waitingTimeIntervalId = null;

  bufferNudgeOnStallTime = 0;
  bufferNudgeOnStallIntervalId = null;

  videoNotStartedErrorTime = 0;
  videoNotStartedErrorIntervalId = null;

  bufferStalledErrorTime = 0;
  bufferStalledErrorIntervalId = null;

  bufferAppendErrorTime = 0;
  bufferAppendErrorIntervalId = null;

  visibilityChangeTime = 0;
  visibilityChangeIntervalId = null;

  private pendingSeekStart$ = new Subject<void>();
  pollSub: Subscription;

  public archiveTimeline: { start: number; end: number };

  focusReload = false;

  highQuality = false;

  /**
   *  Inactivity Feature declarations
   **/
  public APP_INACTIVITY_THRESHOLD = APP_INACTIVITY_THRESHOLD;
  public APP_INACTIVITY_TIME = APP_INACTIVITY_TIME;

  inactive = false;
  inactivityCountdown$: Observable<number> = this.store.select(SharedSelectors.selectInactivityCountdown);
  countDown = false;

  constructor(
    private store: Store,
    private playbackPlayerService: PlaybackPlayerService,
    private edgeService: EdgeService,
    private snackBar: MatSnackBar,
    private camerasService: CamerasService,
    private utilsService: UtilsV2Service,
  ) {
  }

  ngOnInit(): void {
  }

  public qualityChange(quality: Quality, videoCurrentTime: number) {
    this.highQuality = quality === Quality.HQ;
    this.seek(this.time + videoCurrentTime);
  }

  seek(ts: number) {
    this.loading = true;
    this.player.stopHls();
    this.time = ts;
    this.baseTime = ts;
    this.playbackPlayerService.setPlaying(false);
    this.playbackPlayerService.setTime(this.time);
    this.startPlayback(Math.floor(this.time));
  }

  changeSpeed(speed: number) {
    this.playbackPlayerService.speed(speed);
    this.speed = speed;
    this.player?.stopHls();
    this.startPlayback();
  }

  async maximize() {
    // this.player.maximize();
    const elem = this.playerWrapper?.nativeElement;
    if (!this.isFullscreen) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }

  }

  get isFullscreen(): boolean {
    return this.utilsService.isFullscreen();
  }

  get isPlaying() {
    return this.player?.isPlaying;
  }

  keepPlaying() {
    this.countDown = false;
    this.inactive = false;
    this.store.dispatch(SharedActions.stopInactivityCountdown());
  }

  pauseVid() {
    this.countDown = false;
    this.inactive = false;

    this.store.dispatch(SharedActions.setIsInactive({ isInactive: false }));
    this.store.dispatch(SharedActions.setInactivityTime({ inactivityTime: 0 }));
    this.store.dispatch(SharedActions.stopInactivityCountdown());
    this.stopVid();
  }

  public play() {
    this.error = false;
    this.stopped = false;
    this.inactive = false;
    this.keepPlaying();
    if (this.player?.video?.currentTime > 0 && this.player?.video?.paused) {
      this.player?.playVid();
      return;
    }
    this.startPlayback();
  }

  public startPlayback(ts?: number) {
    const firebase$ = (token: string) =>
      this.edgeService.subscribeToSessionStatus(token, false, PLAYBACK_TIMEOUT)
        .pipe(
          untilDestroyed(this),
          map(res => {
            return { ...res, token: token };
          }),
          catchError(err => {
            this.onPlaybackError();
            return throwError(() => err)
              .pipe();
          }),
        );

    const session$ = (token: string) =>
      this.edgeService.getSessionData<TokenDataMessageBase>(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );

    this.player?.stop();
    this.loading = true;
    this.startExtendInterval();
    this.store
      .select(EdgeSelectors.selectLocalById(this.edgeId))
      .pipe(
        take(1),
        concatMap(isLocal => this.playbackPlayerService.startPlayback(ts, isLocal, this.highQuality)
          .pipe(untilDestroyed(this))),
      )
      .pipe(
        concatMap(res => firebase$(res.sqsMessgaeInfo.token.session)),
        filter(state => state?.status === TokenDataStatus.ERROR || state?.status === TokenDataStatus.COMPLETED),
        concatMap(state => session$(state.token)),
        tap((session: TokenDataMessageBase) => {
          if (session.responseCode === SNSResponseCode.Forbidden) {
            const data: PlaybackResponseSNSMsg = JSON.parse(session.msg);
            this.displayError(data);
          }
          return throwError(() => new Error('Too many playback sessions already playing'));
        }),
        filter(state => state?.status === TokenDataStatus.COMPLETED),
      )
      .subscribe({
        complete: () => {
          const ts = new Date().getTime();
          this.pollReload(ts);
        },
        error: (err: HttpErrorResponse | Error) => {
          console.log('Error');

          let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;

          this.onPlaybackError();
          throw err;
        },
      });
  }


  countWaitingTime() {
    this.waitingTimeIntervalId = setInterval(() => {
      this.waitingTime++;

      console.log(`wait for id: ${this.waitingTimeIntervalId} is: ${this.waitingTime}`);

      if (this.waitingTime === 2) {
        console.log(`wait for id: ${this.waitingTimeIntervalId} reached the limit of: ${this.waitingTime} seconds wait, restarting..`);

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearWaitingTime();
      }
    }, 2000);
  }

  clearWaitingTime() {
    if (!!this.waitingTimeIntervalId) {
      console.log(`clearing wait id: ${this.waitingTimeIntervalId}`);
      clearInterval(this.waitingTimeIntervalId);
      this.waitingTime = 0;
      this.waitingTimeIntervalId = null;
    }
  }

  countBufferNudgeOnStallTime() {
    this.bufferNudgeOnStallIntervalId = setInterval(() => {
      this.bufferNudgeOnStallTime++;

      console.log(`bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} is: ${this.bufferNudgeOnStallTime}`);

      if (this.bufferNudgeOnStallTime === 6) {
        console.log(
          `bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} reached the limit of: ${this.bufferNudgeOnStallTime} seconds wait, restarting..`,
        );

        this.startPlayback();
        this.clearBufferNudgeOnStallTime();
      }
    }, 1000);
  }

  clearBufferNudgeOnStallTime() {
    if (!!this.bufferNudgeOnStallIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferNudgeOnStallIntervalId}`);
      clearInterval(this.bufferNudgeOnStallIntervalId);
      this.bufferNudgeOnStallTime = 0;
      this.bufferNudgeOnStallIntervalId = null;
    }
  }

  countBufferStalledErrorTime() {
    this.bufferStalledErrorIntervalId = setInterval(() => {
      this.bufferStalledErrorTime++;

      console.log(`bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} is: ${this.bufferStalledErrorTime}`);

      if (this.bufferStalledErrorTime === 30) {
        console.log(
          `bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} reached the limit of: ${this.bufferStalledErrorTime} seconds wait, restarting..`,
        );

        this.startPlayback();
        this.clearBufferStalledErrorTime();
      }
    }, 1000);
  }

  clearBufferAppendErrorTime() {
    if (!!this.bufferAppendErrorIntervalId) {
      console.log(`clearing bufferAppendErrorIntervalId id: ${this.bufferAppendErrorIntervalId}`);
      clearInterval(this.bufferAppendErrorIntervalId);
      this.bufferAppendErrorTime = 0;
      this.bufferAppendErrorIntervalId = null;
    }
  }

  countBufferAppendErrorTime() {
    this.bufferAppendErrorIntervalId = setInterval(() => {
      this.bufferAppendErrorTime++;

      console.log(`bufferAppendErrorTime for id: ${this.bufferAppendErrorIntervalId} is: ${this.bufferAppendErrorTime}`);

      if (this.bufferAppendErrorTime === 5) {
        console.log(
          `bufferStalledErrorTime for id: ${this.bufferAppendErrorIntervalId} reached the limit of: ${this.bufferAppendErrorTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearBufferAppendErrorTime();
      }
    }, 1000);
  }

  countVideoNotStartedErrorTime() {
    this.videoNotStartedErrorIntervalId = setInterval(() => {
      this.videoNotStartedErrorTime++;

      console.log(`videoNotStartedErrorTime for id: ${this.videoNotStartedErrorIntervalId} is: ${this.videoNotStartedErrorTime}`);

      if (this.videoNotStartedErrorTime === 5) {
        console.log(
          `videoNotStartedErrorTime for id: ${this.videoNotStartedErrorIntervalId} reached the limit of: ${this.videoNotStartedErrorTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearVideoNotStartedErrorTime();
      }
    }, 1000);
  }

  clearBufferStalledErrorTime() {
    if (!!this.bufferStalledErrorIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferStalledErrorIntervalId}`);
      clearInterval(this.bufferStalledErrorIntervalId);
      this.bufferStalledErrorTime = 0;
      this.bufferStalledErrorIntervalId = null;
    }
  }

  clearVideoNotStartedErrorTime() {
    if (!!this.videoNotStartedErrorIntervalId) {
      console.log(`clearing videoNotStartedErrorIntervalId id: ${this.videoNotStartedErrorIntervalId}`);
      clearInterval(this.videoNotStartedErrorIntervalId);
      this.videoNotStartedErrorTime = 0;
      this.videoNotStartedErrorIntervalId = null;
    }
  }

  clearEventIntervals() {
    this.clearWaitingTime();
    this.clearBufferNudgeOnStallTime();
    this.clearBufferStalledErrorTime();
    this.clearBufferAppendErrorTime();
    this.clearVideoNotStartedErrorTime();
  }

  handleMediaEvents(mediaEvent: MediaEvents) {
    if (mediaEvent.event === 'bufferNudgeOnStall') {
      console.log('checking bufferNudgeOnStall severity');
      if (!this.bufferNudgeOnStallIntervalId) {
        this.countBufferNudgeOnStallTime();
      } else {
        console.log(`bufferNudgeOnStall resolve in progress with id: ${this.bufferNudgeOnStallIntervalId}`);
      }
    }

    if (mediaEvent.event === 'bufferStalledError') {
      console.log('checking bufferStalledError severity');
      if (!this.bufferStalledErrorIntervalId) {
        this.countBufferStalledErrorTime();
      } else {
        console.log(`bufferStalledError resolve in progress with id: ${this.bufferStalledErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'bufferAppendError') {
      console.log('checking bufferAppendError severity');
      if (!this.bufferAppendErrorIntervalId) {
        this.countBufferAppendErrorTime();
      } else {
        console.log(`bufferAppendError resolve in progress with id: ${this.bufferAppendErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'videoNotStartedError') {
      console.log('checking videoNotStartedError severity');
      if (!this.videoNotStartedErrorIntervalId) {
        this.countVideoNotStartedErrorTime();
      } else {
        console.log(`videoNotStartedError resolve in progress with id: ${this.videoNotStartedErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'waiting') {
      console.log('checking wait severity');
      if (!this.waitingTimeIntervalId) {
        this.countWaitingTime();
      } else {
        console.log(`wait resolve in progress with id: ${this.waitingTimeIntervalId}`);
      }
    }

    if (!!mediaEvent.playing) {
      this.clearEventIntervals();

      if (!!this.playing && !!this.loading) {
        this.loading = false;
        this.error = false;
      }
    }

    if (!!mediaEvent.error) {
      this.onStreamError(`player emiited error with message: ${mediaEvent.message || 'unknown error occured'}`);
    }
  }

  setPlaying() {
    this.playing = true;
    this.playbackPlayerService.setPlaying(true);
  }

  timeUpdate(time: number) {
    if (this.stopped) {
      return;
    }
    const timeInMesc = Math.floor(time) * 1000 * this.speed;
    this.playbackPlayerService.setTime(this.baseTime + timeInMesc);
  }

  pollReload(ts?: number) {
    // this.onCancelPendingRequests();
    if (!!this.pollSub) {
      this.pollSub.unsubscribe();
    }
    this.pollSub = this.camerasService
      .pollStreamExists({
        region: this.edgeRegion,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        sessionId: this.sessionId,
        ts,
        localUrl: this.localUrl,
        local: this.isLocal,
        tag: this.tag,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.onCancelPendingRequests()),
        catchError(err => {
          this.player.loader = false;
          this.error = true;
          this.qualitySelector.loading = false;
          return throwError(() => err);
        }),
      )
      .subscribe(async () => {
        console.log('[PLAYBACK] START PLAYBACK');
        this.tabFocus = false;
        this.setSrc(true);
        this.store.dispatch(SharedActions.stopInactivityCountdown());
        this.store.dispatch(SharedActions.setIsInactive({ isInactive: false }));
        this.store.dispatch(SharedActions.setInactivityTime({ inactivityTime: 0 }));
        this.qualitySelector.loading = false;
      });
  }

  onPlaybackError() {
    this.player.loader = false;
    this.error = true;
  }

  setSrc(reload = false) {
    if (!this.sessionId) {
      return;
    }
    if (this.isLocal) {
      this.player.setSrc(this.getLocalPlaybackUrl(this.edgeId, this.cameraId));
    } else {
      this.player.setSrc(this.getPlaybackUrl(this.edgeId, this.cameraId));
    }
    if (this.playing || reload) {
      this.reloadPlayer();
    }
  }

  resetBase() {
    this.playbackPlayerService.setPlaybackBase({
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId,
    });
  }

  getPlaybackUrl(edgeId, cameraId): string {
    return `${this.getStreamRegionPrefix()}/video_recording/${edgeId}/${cameraId}/${this.sessionId}/s.m3u8`;
  }

  getLocalPlaybackUrl(edgeId, cameraId): string {
    return `${this.localUrl}/streams/playback/${edgeId}/${this.cameraId}/${this.sessionId}/s.m3u8`;
  }

  getStreamRegionPrefix() {
    return this.camerasService.getStreamPrefix(this.edgeRegion);
  }

  cameraSnapshot(): Observable<any> {
    return this.camerasService.getCameraSnapshot(this.cameraId);
  }

  public onCancelPendingRequests() {
    return this.pendingSeekStart$.asObservable();
  }

  onStreamError(message = 'unknown error occured') {
    console.log(`live view error occured: ${message}`);
    this.loading = false;

    this.error = true;

    this.snackBar.open(message, '', { duration: 5000 });
  }

  displayError(msg: PlaybackResponseSNSMsg) {
    switch (msg.response) {
      case PlaybackResponseCode.Error:
        this.store.dispatch(SharedActions.showMessage({ error: 'Unknown error occurred' }));
        break;
      case PlaybackResponseCode.Unknown:
        this.store.dispatch(SharedActions.showMessage({ error: 'Unknown error occurred' }));
        break;
      case PlaybackResponseCode.TooManyInProgress:
        this.store.dispatch(SharedActions.showMessage({ warning: 'Too many playback sessions already playing, please try again later' }));
        break;
    }
  }

  startExtendInterval() {
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    this.extendInterval = setInterval(() => this.extend(), 180000);
  }

  clearExtendInterval() {
    clearInterval(this.extendInterval);
  }

  extend() {
    if (this.playing) {
      this.playbackPlayerService.extend()
        .subscribe(_ => {
        });
    }
  }

  stopVid() {
    console.log('tab defocus detected - stopping');
    if (!!this.extendInterval) {
      console.log('DISABLE EXTENDING PLAYBACK');
      clearInterval(this.extendInterval);
    }
    // this.player?.destroyHls();
    if (!!this.player) {
      this.player.stopHls();
    }
    this.loading = false;
    this.clearEventIntervals();

    this.clearVisibilityChangeTime();
  }

  set tabFocus(focus) {
    console.log(`setting tab focus to: ${focus}`);
    this.focusReload = focus;
  }

  get tabFocus() {
    return this.focusReload;
  }

  public async reloadPlayer() {
    this.error = false;
    this.reloading = true;
    await this.player?.reload();
  }

  clearVisibilityChangeTime() {
    if (!!this.visibilityChangeIntervalId) {
      console.log(`clearing visibility time id: ${this.visibilityChangeIntervalId}`);
      clearInterval(this.visibilityChangeIntervalId);
      this.visibilityChangeTime = 0;
      this.visibilityChangeIntervalId = null;
    }
  }

}
