import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { catchError, concatMap, count, filter, finalize, interval, lastValueFrom, map, mergeMap, Observable, of, shareReplay, Subject, Subscription, switchMap, take, takeUntil, takeWhile, tap, throwError, timeout } from 'rxjs';
import { PlaybackPlayerService } from './playback-player.service';
import { PlaybackSpeeds } from './playback-player.model';
import { MediaEvents } from '../../framework/player/player.component';
import { PlayerControlsComponent } from '../player-controls/player-controls.component';
import { ArchiveStorageType, Playback, PlaybackResponseCode, PlaybackResponseSNSMsg } from '../../cameras/playback.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArchiveService } from '../../development/archive.service';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { CamerasService } from '../../cameras/cameras.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreloaderColor, Region } from '@enums/shared.enum';
import { StreamPlayerComponent } from '../../framework/stream-player/stream-player.component';
import { SNSResponseCode, TokenDataMessageBase, TokenDataStatus } from '../../core/messaging.interfaces';
import { EdgeService } from '../../edge/edge.service';
import * as ArchiveAction from '@states/archive/archive.actions';
import * as moment from 'moment';
import * as SharedSelector from '@states/shared/shared.selectors';
import { PlaybackTimelineComponent } from './playback-timeline/playback-timeline.component';
import * as SharedActions from '@states/shared/shared.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Quality, QualitySelectorComponent } from '../ui-kit/ui-quality-selector/quality-selector.component';
import { LocationSelectors } from '@states/location/location.selector-types';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { APP_INACTIVITY_THRESHOLD, APP_INACTIVITY_TIME } from '@consts/general.const';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { WebrtcPlayerV2Component } from '../webrtc-player/webrtc-player-v2.component';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { formatDate } from '@angular/common';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { MediaCacheService } from '../media-cache/media-cache.service';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { VisibilityChanged } from '../directives/visibility-change.directive';
import { withLatestFrom } from 'rxjs/operators';
import { LiveStreamModels } from '@models/live-stream.model';
import { StatsService } from '../../development/stats.service';
import { VideoService } from '../../development/video.service';

export const PLAYBACK_TIMEOUT = 240000;
export const PLAYBACK_OFFLINE_INTERVAL_MS = 100;
export const VISIBILITY_CHANGE_INTERVAL = 1000;
const PLAY_DELAY_TIME = 2000;


@UntilDestroy()
@Component({
  selector: 'app-playback-player',
  templateUrl: './playback-player.component.html',
  styleUrls: ['./playback-player.component.scss'],
})
export class PlaybackPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  public HlsPlaybackErrors = LiveStreamModels.HlsPlaybackErrors;
  public hlsPlaybackErrorsStr: { [Property in LiveStreamModels.HlsPlaybackErrors]: string } = {
    [LiveStreamModels.HlsPlaybackErrors.CONNECTED]: 'Connected',
    [LiveStreamModels.HlsPlaybackErrors.FAILED_TO_JOIN]: 'Oops, we are sorry, this one is on us. Please try again',
    [LiveStreamModels.HlsPlaybackErrors.MAX_WORKERS]: `Looks like there's a jam! The Core is currently handling the maximum number of playback sessions. Please
               close any unnecessary playback windows, or wait a moment and try again. Thank you for your patience.`,
    [LiveStreamModels.HlsPlaybackErrors.COMMAND_SENT_TO_WRONG_SESSION]: 'Oops, we are sorry, this one is on us. Please try again',
    [LiveStreamModels.HlsPlaybackErrors.NOT_FOUND]: 'Oops, we are sorry, this one is on us. Please try again',
    [LiveStreamModels.HlsPlaybackErrors.NO_HLS]: '',
    [LiveStreamModels.HlsPlaybackErrors.NOT_SUPPORTED]: 'Browser does not support the codec required to play this video. Please use a different browser or device.',
  };

  @Output() toggleLiveView = new EventEmitter<void>();
  @Output() pauseEmit = new EventEmitter<void>();
  @Input() public permissionCheck = true;
  public selectIsInactive$: Observable<boolean> = this.store$.pipe(select(SharedSelector.selectIsInactive));
  speeds: number[] = PlaybackSpeeds;
  seekerPos = 20;
  public PreloaderColor = PreloaderColor;

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));
  public selectDragging$: Observable<boolean> = this.store$.select(MultiPlaybackSelectors.selectDragging)
    .pipe(untilDestroyed(this));
  public selectEdgeLastMp4Ts$: Observable<number>;

  public lastMp4Ts: number;

  @ViewChild('playerWrapper')
  playerWrapper: ElementRef;

  @ViewChild('player')
    // player: PlayerComponent;
  player: StreamPlayerComponent;

  @ViewChild('webrtcPlayer')
  public webrtcPlayer: WebrtcPlayerV2Component;

  @ViewChild('webrtcPlayer')
  webrtcPlayerElem: ElementRef;

  @ViewChild('timeline')
  timeline: PlaybackTimelineComponent;

  @ViewChild('controls')
  controls: PlayerControlsComponent;

  @ViewChild('qualitySelector')
  qualitySelector: QualitySelectorComponent;

  @Output() emulate = new EventEmitter<void>();
  @Output() noMoreEvent = new EventEmitter<boolean>();

  @Output() clickPlay = new EventEmitter<void>();
  @Output() clickPause = new EventEmitter<void>();
  @Output() clickFullScreen = new EventEmitter<void>();

  @Input() isLiveView = false;
  @Input() isCameraView = false;

  @Input() dragging = false;

  @Input()
  startTime;

  @Input()
  endTime;

  @Input()
  duration;

  @Input()
  time = new Date().getTime();

  @Input()
  timezone;

  src;

  streamBase: string;

  @Input()
  cameraId;
  @Input()
  edgeId;
  @Input()
  locationId;

  @Input()
  cameraName: any;

  @Input()
  edgeRegion: Region;

  @Input() videoSection = false;

  @Input() aspectRatio = 16 / 9;

  @Input() processing = false;
  @Input() processingTime;

  speed = 1;

  localUrl = '';

  locationName$: Observable<string | undefined>;

  sessionId = '';

  tag = new Date().getTime();

  isLocal$: Observable<boolean>;
  isLocal: boolean;

  playing = true;

  playing$: Observable<boolean>;
  time$: Observable<number>;

  extendInterval;
  reloadInterval;

  error = false;
  reloading = false;
  stopped = false;
  loading = false;

  baseTime: number;
  baseUpdate: number;

  waitingTime = 0;
  waitingTimeIntervalId = null;

  bufferNudgeOnStallTime = 0;
  bufferNudgeOnStallIntervalId = null;

  videoNotStartedErrorTime = 0;
  videoNotStartedErrorIntervalId = null;

  bufferStalledErrorTime = 0;
  bufferStalledErrorIntervalId = null;

  bufferAppendErrorTime = 0;
  bufferAppendErrorIntervalId = null;

  visibilityChangeTime = 0;
  visibilityChangeIntervalId = null;

  private pendingSeekStart$ = new Subject<void>();
  pollSub: Subscription;
  public emulateSub: Subscription;
  public isEmulating: boolean = false;

  public archiveTimeline: { start: number; end: number };

  focusReload = false;

  highQuality = false;

  @Input() webrtc = false;
  @Input() webrtcSeek = true;

  /**
   *  Inactivity Feature declarations
   **/
  public APP_INACTIVITY_THRESHOLD = APP_INACTIVITY_THRESHOLD;
  public APP_INACTIVITY_TIME = APP_INACTIVITY_TIME;

  inactive = false;
  inactivityCountdown$: Observable<number> = this.store$.select(SharedSelectors.selectInactivityCountdown);
  countDown = false;

  timeformat = 'h:mm:ss a';

  offline = false;
  offlinePrev = false;

  offlineStart = false;

  canPlayHls = false;

  public lastPlay = 0;
  public lastPlayTs = 0;

  public noMore = false;

  playerHeight = 0;
  @Input() playerWidth = 0;

  public ptzSupport = false;
  private _forceMp4 = false;

  constructor(
    private store$: Store,
    private playbackPlayerService: PlaybackPlayerService,
    private edgeService: EdgeService,
    private snackBar: MatSnackBar,
    private archiveService: ArchiveService,
    private camerasService: CamerasService,
    private utilsService: UtilsV2Service,
    private camerasThumbnailsService: CamerasThumbnailsService,
    private mediaCacheService: MediaCacheService,
    private cd: ChangeDetectorRef,
    public elemRef: ElementRef,
    private statsService: StatsService,
    private videoService: VideoService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  set tabFocus(focus) {
    this.focusReload = focus;
  }

  get tabFocus() {
    return this.focusReload;
  }

  get isPlaying() {
    return this.webrtcPlayer?.isPlaying;
  }

  get isLive() {
    return this.videoService.isLive;
  }

  get paused() {
    return this.webrtcPlayer?.video?.paused;
  }

  public get playbackErrors() {
    return this.webrtcPlayer?.playbackErrors;
  }

  public get hlsPlaybackError() {
    return !!this.webrtcPlayer?.playbackErrors?.error;
  }

  public get videoLoader() {
    return this.webrtcPlayer?.videoLoader;
  }

  countVisibilityChangeTime() {
    this.visibilityChangeIntervalId = setInterval(() => {
      this.visibilityChangeTime++;
      if (this.visibilityChangeTime === 30) {
        this.inactive = true;
        this.videoService.OnStop();
      }
    }, VISIBILITY_CHANGE_INTERVAL);
  }

  clearVisibilityChangeTime() {
    if (!!this.visibilityChangeIntervalId) {
      clearInterval(this.visibilityChangeIntervalId);
      this.visibilityChangeTime = 0;
      this.visibilityChangeIntervalId = null;
    }
  }

  checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    if (this.isLocal) {
      return;
    }
    if (this.webrtc && this.webrtcPlayer?.disableInactivity) {
      return;
    }
    if (visibilityChanged.hidden) {
      this.countVisibilityChangeTime();
    } else {
      this.clearVisibilityChangeTime();
      if (!!this.playing && !this.tabFocus && this.loading) {
        this.tabFocus = true;
      }
    }
  }

  public get lastFiveMinutes() {
    const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
    return this.time >= lastFiveMinutes;
  }

  public ngOnInit() {
    this.selectIsInactive$.pipe(untilDestroyed(this))
      .subscribe(inactive => {
        if (this.isLocal) {
          return;
        }
        this.inactive = inactive;
        if (!!this.inactive) {
          if (this.isPlaying) {
            this.videoService.OnStop();
            // this.webrtcPlayer.stop(true, true);
          }
          this.countDown = false;
        }
      });
    this.inactivityCountdown$
      .pipe(
        untilDestroyed(this),
        filter(inactivityCountdown => inactivityCountdown === APP_INACTIVITY_TIME - APP_INACTIVITY_THRESHOLD),
      )
      .subscribe(_ => {
        if ((!!this.player?.isPlaying || this.webrtcPlayer?.isPlaying) && !this.inactive && !this.webrtcPlayer?.disableInactivity) {
          this.countDown = true;
        }
      });

    this.selectMove$
      .pipe(withLatestFrom(this.selectDragging$))
      .subscribe(async ([move, dragging]) => {
        if (!this.videoSection) {
          return;
        }
        this.time = move.timestamp;
        const offlinePrev = this.offline;
        if (!dragging) {
          this.offlinePrev = this.offline;
        }
        this.offline = await this.isOffline();

        if (!offlinePrev && this.offline && !this.noMore && !this.isLiveView && !this.lastFiveMinutes && !dragging) {
          this.webrtcPlayer.setPlaceholder();
          this.webrtcPlayer.stop();
          this.emulatePlayback();
        }
        if (offlinePrev && !this.offline && !this.isLiveView && !dragging) {
          // this.startPlayback(this.time);
        }
      });

    this.camerasThumbnailsService.thumbnailsData$.pipe(take(1))
      .subscribe(data => {
        this.timezone = data.timezone;
      });

    this.store$
      .select(CameraSelectors.selectCameraPtzById(this.cameraId))
      .pipe(take(1))
      .subscribe(ptzSupport => {
        this.ptzSupport = ptzSupport;
      });

  }


  public noMoreFiles() {
    this.noMore = true;
    this.noMoreEvent.emit(true);
    this.emulatePlayback();
  }

  private emulatePlayback() {
    this.emulate.emit();
  }

  setMove() {
    const percentage = (this.time - this.startTime) / (this.endTime - this.startTime);
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage, timestamp: this.time } }));
  }

  public async isOffline(time?: number) {
    const offline = await this.mediaCacheService.isOffline(this.edgeId, this.cameraId, time ?? this.time);
    return offline;
  }

  public get timeDate() {
    let timestamp;
    const startTime = this.camerasThumbnailsService.convertTsToZone(this.startTime, this.timezone!, moment.tz.guess());

    if (this.time) {
      timestamp = this.camerasThumbnailsService.convertTsToZone(this.time, this.timezone!, moment.tz.guess());
    }

    return formatDate(timestamp ?? startTime, this.timeformat, 'en-US');
  }

  public get timezoneAbbreviation() {
    const cameraZone = moment()
      .tz(this.timezone ?? moment.tz.guess())
      .format('z');
    return cameraZone;
  }

  ngOnDestroy(): void {
    this.playbackPlayerService.unsubscribeStreamExistSubscription();
    this.closePlayback();
    this.playbackPlayerService.resetPlaybackBase();
    if (this.pollSub) {
      this.pollSub.unsubscribe();
    }
    if (this.emulateSub) {
      this.emulateSub.unsubscribe();
      this.isEmulating = false;
    }
  }

  public cancelPendingRequests() {
    this.pendingSeekStart$.next();
  }

  public onCancelPendingRequests() {
    return this.pendingSeekStart$.asObservable();
  }

  setSrc(reload = false) {
    if (!this.sessionId) {
      return;
    }
    if (this.isLocal) {
      this.player.setSrc(this.getLocalPlaybackUrl(this.edgeId, this.cameraId));
    } else {
      this.player.setSrc(this.getPlaybackUrl(this.edgeId, this.cameraId));
    }
    if (this.playing || reload) {
      this.reloadPlayer();
    }
  }

  resetBase() {
    this.playbackPlayerService.setPlaybackBase({
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId,
    });
  }

  ngAfterViewInit(): void {
    this.initSeek();
    this.playbackPlayerService.setClipDuration(this.duration);
    this.store$.pipe(
        select(CameraSelectors.selectCameraById(this.cameraId)),
        untilDestroyed(this))
      .subscribe(camera => {
        if (!this.edgeId) {
          this.edgeId = camera?.edgeId;

        }
        this.initLastMp4();

        // this.store$
        //   .select(EdgeSelectors.selectEdgeById(this.edgeId))
        //   .pipe(take(1))
        //   .subscribe(edge => {
        //     if (!this.edgeRegion) {
        //       this.edgeRegion = edge.region;
        //     }
        //   });

        if (!this.locationId) {
          this.locationId = camera?.locationId;
        }

        this.locationName$ = this.store$.pipe(untilDestroyed(this), select(LocationSelectors.selectLocationName(this.locationId)));
        this.isLocal$ = this.store$.pipe(select(EdgeSelectors.selectLocalById(this.edgeId)));

        // TODO: inject those parameters
        this.playbackPlayerService.setPlaybackBase({
          cameraId: this.cameraId,
          edgeId: this.edgeId,
          locationId: this.locationId!,
        });

        this.playbackPlayerService.playbackBase$
          .subscribe(res => {
            if (!!res?.sessionId) {
              this.sessionId = res.sessionId;
            }
          });

        this.store$
          .select(CameraSelectors.selectCameraPlaybackSessionIdById(this.cameraId))
          .pipe(take(1))
          .subscribe(sessionId => {
            this.sessionId = sessionId;
          });

        if (!this.webrtc) {
          this.isLocal$.pipe(
              untilDestroyed(this),
              shareReplay())
            .subscribe(isLocal => {
              if (isLocal) {
                this.isLocal = isLocal;
                this.store$
                  .select(EdgeSelectors.selectEdgeById(this.edgeId))
                  .pipe(take(1))
                  .subscribe(edge => {
                    this.localUrl = edge.localUrl;
                    this.setSrc();
                  });
              } else {
                this.setSrc();
              }
            });
        }

        this.resetBase();
      });

    this.playing$ = this.playbackPlayerService.playing$;
    this.playbackPlayerService.setStartTime(this.startTime);
    if (!this.time) {
      this.time = this.startTime;
    }
    this.playbackPlayerService.setTime(this.time);
    this.time$ = this.playbackPlayerService.time$;

    this.time$.pipe(untilDestroyed(this))
      .subscribe(time => {
        this.time = time;
        this.updateSeek(time);
      });

    this.playing$.pipe(untilDestroyed(this))
      .subscribe(playing => {
        this.playing = playing;
        if (this.playing) {
          this.stopped = false;
          this.loading = false;
        }
      });
    if (!this.playerWidth) {
      this.playerHeight = this.playerWrapper.nativeElement.clientWidth / this.aspectRatio;
    } else {
      this.playerHeight = this.playerWidth / this.aspectRatio;
    }
  }

  getPlaybackUrl(edgeId, cameraId): string {
    return `${this.getStreamRegionPrefix()}/video_recording/${edgeId}/${cameraId}/${this.sessionId}/s.m3u8`;
  }

  getLocalPlaybackUrl(edgeId, cameraId): string {
    return `${this.localUrl}/streams/playback/${edgeId}/${this.cameraId}/${this.sessionId}/s.m3u8`;
  }

  getStreamRegionPrefix() {
    return this.camerasService.getStreamPrefix(this.edgeRegion);
  }

  cameraSnapshot(): Observable<any> {
    return this.camerasService.getCameraSnapshot(this.cameraId);
  }

  initSeek() {
    const percentSeek = (this.time / this.duration) * 100;
    this.seekerPos = percentSeek;
  }

  updateSeek(time: number) {
    this.time = time;
    const percentSeek = (time / this.duration) * 100;
    this.seekerPos = percentSeek;
  }

  setPlaying() {
    this.playing = true;
    this.playbackPlayerService.setPlaying(true);
  }

  playerError() {
    if (this.stopped) {
      return;
    }
    this.playing = false;
    this.reloading = false;
  }

  countWaitingTime() {
    this.waitingTimeIntervalId = setInterval(() => {
      this.waitingTime++;

      console.log(`wait for id: ${this.waitingTimeIntervalId} is: ${this.waitingTime}`);

      if (this.waitingTime === 2) {
        console.log(`wait for id: ${this.waitingTimeIntervalId} reached the limit of: ${this.waitingTime} seconds wait, restarting..`);

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearWaitingTime();
      }
    }, 2000);
  }

  clearWaitingTime() {
    if (!!this.waitingTimeIntervalId) {
      console.log(`clearing wait id: ${this.waitingTimeIntervalId}`);
      clearInterval(this.waitingTimeIntervalId);
      this.waitingTime = 0;
      this.waitingTimeIntervalId = null;
    }
  }

  countBufferNudgeOnStallTime() {
    this.bufferNudgeOnStallIntervalId = setInterval(() => {
      this.bufferNudgeOnStallTime++;

      console.log(`bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} is: ${this.bufferNudgeOnStallTime}`);

      if (this.bufferNudgeOnStallTime === 6) {
        console.log(
          `bufferNudgeOnStallTime for id: ${this.bufferNudgeOnStallIntervalId} reached the limit of: ${this.bufferNudgeOnStallTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.clearBufferNudgeOnStallTime();
      }
    }, 1000);
  }

  clearBufferNudgeOnStallTime() {
    if (!!this.bufferNudgeOnStallIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferNudgeOnStallIntervalId}`);
      clearInterval(this.bufferNudgeOnStallIntervalId);
      this.bufferNudgeOnStallTime = 0;
      this.bufferNudgeOnStallIntervalId = null;
    }
  }

  countBufferStalledErrorTime() {
    this.bufferStalledErrorIntervalId = setInterval(() => {
      this.bufferStalledErrorTime++;

      console.log(`bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} is: ${this.bufferStalledErrorTime}`);

      if (this.bufferStalledErrorTime === 30) {
        console.log(
          `bufferStalledErrorTime for id: ${this.bufferStalledErrorIntervalId} reached the limit of: ${this.bufferStalledErrorTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.clearBufferStalledErrorTime();
      }
    }, 1000);
  }

  clearBufferAppendErrorTime() {
    if (!!this.bufferAppendErrorIntervalId) {
      console.log(`clearing bufferAppendErrorIntervalId id: ${this.bufferAppendErrorIntervalId}`);
      clearInterval(this.bufferAppendErrorIntervalId);
      this.bufferAppendErrorTime = 0;
      this.bufferAppendErrorIntervalId = null;
    }
  }

  countBufferAppendErrorTime() {
    this.bufferAppendErrorIntervalId = setInterval(() => {
      this.bufferAppendErrorTime++;

      console.log(`bufferAppendErrorTime for id: ${this.bufferAppendErrorIntervalId} is: ${this.bufferAppendErrorTime}`);

      if (this.bufferAppendErrorTime === 5) {
        console.log(
          `bufferStalledErrorTime for id: ${this.bufferAppendErrorIntervalId} reached the limit of: ${this.bufferAppendErrorTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearBufferAppendErrorTime();
      }
    }, 1000);
  }

  countVideoNotStartedErrorTime() {
    this.videoNotStartedErrorIntervalId = setInterval(() => {
      this.videoNotStartedErrorTime++;

      console.log(`videoNotStartedErrorTime for id: ${this.videoNotStartedErrorIntervalId} is: ${this.videoNotStartedErrorTime}`);

      if (this.videoNotStartedErrorTime === 5) {
        console.log(
          `videoNotStartedErrorTime for id: ${this.videoNotStartedErrorIntervalId} reached the limit of: ${this.videoNotStartedErrorTime} seconds wait, restarting..`,
        );

        // this.startPlayback();
        this.player.recover();
        this.player.play();
        this.clearVideoNotStartedErrorTime();
      }
    }, 1000);
  }

  clearBufferStalledErrorTime() {
    if (!!this.bufferStalledErrorIntervalId) {
      console.log(`clearing bufferNudgeOnStall id: ${this.bufferStalledErrorIntervalId}`);
      clearInterval(this.bufferStalledErrorIntervalId);
      this.bufferStalledErrorTime = 0;
      this.bufferStalledErrorIntervalId = null;
    }
  }

  clearVideoNotStartedErrorTime() {
    if (!!this.videoNotStartedErrorIntervalId) {
      console.log(`clearing videoNotStartedErrorIntervalId id: ${this.videoNotStartedErrorIntervalId}`);
      clearInterval(this.videoNotStartedErrorIntervalId);
      this.videoNotStartedErrorTime = 0;
      this.videoNotStartedErrorIntervalId = null;
    }
  }

  clearEventIntervals() {
    this.clearWaitingTime();
    this.clearBufferNudgeOnStallTime();
    this.clearBufferStalledErrorTime();
    this.clearBufferAppendErrorTime();
    this.clearVideoNotStartedErrorTime();
  }

  onPlaybackError() {
    this.player.loader = false;
    this.error = true;
  }

  onStreamError(message = 'unknown error occured') {
    console.log(`live view error occured: ${message}`);
    this.loading = false;

    this.error = true;

    this.snackBar.open(message, '', { duration: 5000 });
  }

  handleMediaEvents(mediaEvent: MediaEvents) {
    if (mediaEvent.event === 'bufferNudgeOnStall') {
      console.log('checking bufferNudgeOnStall severity');
      if (!this.bufferNudgeOnStallIntervalId) {
        this.countBufferNudgeOnStallTime();
      } else {
        console.log(`bufferNudgeOnStall resolve in progress with id: ${this.bufferNudgeOnStallIntervalId}`);
      }
    }

    if (mediaEvent.event === 'bufferStalledError') {
      console.log('checking bufferStalledError severity');
      if (!this.bufferStalledErrorIntervalId) {
        this.countBufferStalledErrorTime();
      } else {
        console.log(`bufferStalledError resolve in progress with id: ${this.bufferStalledErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'bufferAppendError') {
      console.log('checking bufferAppendError severity');
      if (!this.bufferAppendErrorIntervalId) {
        this.countBufferAppendErrorTime();
      } else {
        console.log(`bufferAppendError resolve in progress with id: ${this.bufferAppendErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'videoNotStartedError') {
      console.log('checking videoNotStartedError severity');
      if (!this.videoNotStartedErrorIntervalId) {
        this.countVideoNotStartedErrorTime();
      } else {
        console.log(`videoNotStartedError resolve in progress with id: ${this.videoNotStartedErrorIntervalId}`);
      }
    }

    if (mediaEvent.event === 'waiting') {
      console.log('checking wait severity');
      if (!this.waitingTimeIntervalId) {
        this.countWaitingTime();
      } else {
        console.log(`wait resolve in progress with id: ${this.waitingTimeIntervalId}`);
      }
    }

    if (!!mediaEvent.playing) {
      this.clearEventIntervals();

      if (!!this.playing && !!this.loading) {
        this.loading = false;
        this.error = false;
      }
    }

    if (!!mediaEvent.error) {
      this.onStreamError(`player emiited error with message: ${mediaEvent.message || 'unknown error occured'}`);
    }
  }

  pollReload(ts?: number) {
    // this.onCancelPendingRequests();
    if (!!this.pollSub) {
      this.pollSub.unsubscribe();
    }
    this.pollSub = this.camerasService
      .pollStreamExists({
        region: this.edgeRegion,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        sessionId: this.sessionId,
        ts,
        localUrl: this.localUrl,
        local: this.isLocal,
        tag: this.tag,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.onCancelPendingRequests()),
        catchError(err => {
          console.log('ERROR');
          this.player.loader = false;
          this.error = true;
          this.qualitySelector.loading = false;

          // this.resetBase();
          return throwError(() => err);
        }),
      )
      .subscribe(async () => {
        console.log('[PLAYBACK] START PLAYBACK');
        this.tabFocus = false;
        this.setSrc(true);
        this.store$.dispatch(SharedActions.stopInactivityCountdown());
        this.store$.dispatch(SharedActions.setIsInactive({ isInactive: false }));
        this.store$.dispatch(SharedActions.setInactivityTime({ inactivityTime: 0 }));
        this.qualitySelector.loading = false;
      });
  }

  pollReloadOrig() {
    this.loading = true;
    this.reloading = false;
    this.stopped = true;
    if (this.playbackPlayerService.isStreamExistSubscriptionSet()) {
      this.playbackPlayerService.unsubscribeStreamExistSubscription();
    }
    this.playbackPlayerService.setStreamExistSubscription(
      interval(2000)
        .pipe(
          tap(async () => {
            console.log('pollReload interval');
            if (!this.playing) {
              await this.reloadPlayer();
            }
          }),
          filter(_ => this.playing),
          tap(_ => {
            console.log('PLAYING!');
            this.tabFocus = false;
            this.loading = false;
            this.reloading = false;
            this.stopped = false;
            this.playbackPlayerService.unsubscribeStreamExistSubscription();
            // this.player?.resetTime();
          }),
          // takeWhile(_ => !this.playing),
          catchError(err => {
            console.log('failed - return of null and proceed');
            this.reloading = false;
            return of(null);
          }),
          timeout(70000),
          untilDestroyed(this),
        )
        .subscribe({
          error: err => {
            console.log('error stream exists', err);
            this.playbackError();
          },
        }),
    );
  }

  public stopPlayer() {
    this.player?.stop();
    // this.playbackPlayerService.setPlaying(false);
  }

  displayError(msg: PlaybackResponseSNSMsg) {
    switch (msg.response) {
      case PlaybackResponseCode.Error:
        this.store$.dispatch(SharedActions.showMessage({ error: 'Unknown error occurred' }));
        break;
      case PlaybackResponseCode.Unknown:
        this.store$.dispatch(SharedActions.showMessage({ error: 'Unknown error occurred' }));
        break;
      case PlaybackResponseCode.TooManyInProgress:
        this.store$.dispatch(SharedActions.showMessage({ warning: 'Too many playback sessions already playing, please try again later' }));
        break;
    }
  }

  async playDelay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public isHlsPlayback(ts?: number) {
    return ts > this.lastMp4Ts && !this._forceMp4;
  }

  initLastMp4() {
    this.selectEdgeLastMp4Ts$ = this.store$.pipe(select(EdgeSelectors.selectEdgeLastMp4Ts(this.edgeId)));
    this.selectEdgeLastMp4Ts$.pipe(untilDestroyed(this))
      .subscribe(ts => {
        if (!!ts) {
          this.lastMp4Ts = ts;
        } else {
          this.statsService.getEdgeLastMp4(this.edgeId)
            .subscribe(lastMp4Ts => {
              this.lastMp4Ts = lastMp4Ts;
            });
        }
      });
  }

  private setDragging(dragging: boolean) {
    this.store$.dispatch(MultiPlaybackActions.setDragging({ dragging }));
  }

  public async startHlsPlayback(ts?: number) {
    this.player?.stop();
    const origOffline = this.offlinePrev;
    this.offline = await this.isOffline();
    if (this.offline && !this.lastFiveMinutes) {
      this.webrtcPlayer.stop();
      if (!origOffline) {
        this.emulatePlayback();
      }
    } else {
      this.webrtcPlayer.play({ ts });
    }

  }

  // public async startPlayback(ts?: number) {
  //   this.inactive = false;
  //   const isHlsPlayback = this.isHlsPlayback(ts);
  //   if (isHlsPlayback) {
  //     return this.startHlsPlayback(ts);
  //   }
  //   if (this.webrtc) {
  //     const origOffline = await this.isOffline();
  //     this.offline = await this.isOffline();
  //     if (this.offline && !this.lastFiveMinutes) {
  //       this.webrtcPlayer.stop();
  //       if (!origOffline) {
  //         this.emulatePlayback();
  //       }
  //     } else {
  //       // this.webrtcPlayer.video.pause();
  //       const playDelta = new Date().getTime() - this.lastPlay;
  //       if (playDelta < PLAY_DELAY_TIME) {
  //         console.log(`[WEBRTC] Waiting ${(PLAY_DELAY_TIME - playDelta) / 1000}s before playing...`);
  //         this.lastPlayTs = ts;
  //         await this.playDelay(PLAY_DELAY_TIME - playDelta);
  //         if (this.lastPlayTs === ts) {
  //           this.webrtcPlayer.play({ ts, start: this.startTime, end: this.endTime });
  //           this.lastPlay = new Date().getTime();
  //         }
  //       } else {
  //         this.webrtcPlayer.play({ ts, start: this.startTime, end: this.endTime });
  //         this.lastPlay = new Date().getTime();
  //       }
  //
  //     }
  //     return;
  //   }
  //   const firebase$ = (token: string) =>
  //     this.edgeService.subscribeToSessionStatus(token, false, PLAYBACK_TIMEOUT)
  //       .pipe(
  //         untilDestroyed(this),
  //         map(res => {
  //           return { ...res, token: token };
  //         }),
  //         catchError(err => {
  //           this.onPlaybackError();
  //           return throwError(() => err)
  //             .pipe();
  //         }),
  //       );
  //
  //   const session$ = (token: string) =>
  //     this.edgeService.getSessionData<TokenDataMessageBase>(token)
  //       .pipe(
  //         map(res => {
  //           return { ...res, token: token };
  //         }),
  //       );
  //
  //   this.player?.stop();
  //   this.loading = true;
  //   this.startExtendInterval();
  //   this.store$
  //     .select(EdgeSelectors.selectLocalById(this.edgeId))
  //     .pipe(
  //       take(1),
  //       concatMap(isLocal =>
  //         this.playbackPlayerService.startPlayback(ts, isLocal, this.highQuality)
  //           .pipe(untilDestroyed(this))),
  //     )
  //     .pipe(
  //       concatMap(res => firebase$(res.sqsMessgaeInfo.token.session)),
  //       filter(state => state?.status === TokenDataStatus.ERROR || state?.status === TokenDataStatus.COMPLETED),
  //       concatMap(state => session$(state.token)),
  //       tap((session: TokenDataMessageBase) => {
  //         if (session.responseCode === SNSResponseCode.Forbidden) {
  //           const data: PlaybackResponseSNSMsg = JSON.parse(session.msg);
  //           this.displayError(data);
  //         }
  //         return throwError(() => new Error('Too many playback sessions already playing'));
  //       }),
  //       filter(state => state?.status === TokenDataStatus.COMPLETED),
  //     )
  //     .subscribe({
  //       complete: () => {
  //         const ts = new Date().getTime();
  //         this.pollReload(ts);
  //       },
  //       error: (err: HttpErrorResponse | Error) => {
  //         console.log('Error');
  //
  //         let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;
  //
  //         this.onPlaybackError();
  //         throw err;
  //       },
  //     });
  // }

  public async startPlayback(ts?: number) {
    this.videoService.OnPlay();
  }


  public locationPlayback(ts: number) {
    this.stopPlayer();
    this.startPlayback(ts);
  }

  pauseVid() {
    this.countDown = false;
    this.inactive = false;

    this.store$.dispatch(SharedActions.setIsInactive({ isInactive: false }));
    this.store$.dispatch(SharedActions.setInactivityTime({ inactivityTime: 0 }));
    this.store$.dispatch(SharedActions.stopInactivityCountdown());
    this.pause();
  }

  stopVid() {
    console.log('tab defocus detected - stopping');
    if (!!this.extendInterval) {
      console.log('DISABLE EXTENDING PLAYBACK');
      clearInterval(this.extendInterval);
    }
    // this.player?.destroyHls();
    if (!!this.player) {
      this.player.stopHls();
    }
    this.loading = false;
    this.clearEventIntervals();

    this.clearVisibilityChangeTime();
  }

  public get videoCurrentTime() {
    return this.webrtcPlayer?.currentTime ?? 0;
  }

  pause(user = false, paused = false, currentTs?: number) {
    if (this.webrtc && this.webrtcPlayer?.isPlaying && this.isLiveView) {
      this.webrtcPlayer.stop(true, !this.paused ? true : false);
      return;
    }
    this.webrtcPlayer?.pauseVideo(user, paused, currentTs);
  }

  keepPlaying() {
    this.countDown = false;
    this.inactive = false;
    this.store$.dispatch(SharedActions.stopInactivityCountdown());
  }

  rewindStart() {
    this.stopped = true;
    this.player?.stopHls();
    this.playbackPlayerService.unsubscribeStreamExistSubscription();
    this.loading = true;
  }

  rewind() {
    this.player?.recover();
    this.player?.stop();
    this.loading = true;
    this.pollReload();
  }

  closePlayback() {
    this.clearExtendInterval();
    this.playbackPlayerService.closePlayback();
  }

  public setBaseTime(time: number) {
    this.baseTime = time;
    // this.baseTime = time;
    // this.timeline.updateRange(time);
  }

  seek(ts: number) {
    // this.loading = true;
    if (!this.webrtc) {
      this.player.stopHls();
      this.playbackPlayerService.setPlaying(false);
    }
    this.time = ts;
    // this.baseTime = ts;
    // this.playbackPlayerService.setTime(this.time);
    this.startPlayback(Math.floor(this.time));
  }

  startExtendInterval() {
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    this.extendInterval = setInterval(() => this.extend(), 180000);
  }

  clearExtendInterval() {
    clearInterval(this.extendInterval);
  }

  extend() {
    if (this.playing) {
      this.playbackPlayerService.extend()
        .subscribe(_ => {
        });
    }
  }

  changeSpeed(speed: number) {
    this.playbackPlayerService.speed(speed);
    this.speed = speed;
    this.player?.stopHls();
    this.startPlayback();
  }

  public async reloadPlayer() {
    this.error = false;
    this.reloading = true;
    await this.player?.reload();
  }

  playbackError() {
    this.error = true;
    this.reloading = false;
    // this.controls.stopPlayer();
  }

  onError() {
    this.error = true;
    this.reloading = false;
  }

  stopPlayback(inactive = false) {
    if (this.webrtc) {
      this.webrtcPlayer.stop(inactive, true);
    }
    this.player?.pauseVid();
  }

  public play() {
    this.error = false;
    this.stopped = false;
    this.inactive = false;
    this.keepPlaying();
    if (!this.isLiveView) {
      this.videoService.setLive(false);
      this.videoService.OnPlay();
    } else {
      this.toggleLiveView.emit(null);
    }
  }

  timeUpdate(time: number) {
    if (this.stopped) {
      return;
    }
    const timeInMesc = Math.floor(time) * 1000 * this.speed;
    this.playbackPlayerService.setTime(this.baseTime + timeInMesc);
  }


  public onArchiveReady(event: { start: number; end: number }) {
    this.archiveTimeline = event;
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'start',
        value: moment(event.start)
          .toString(),
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'end',
        value: moment(event.end)
          .toString(),
      }),
    );
  }

  async maximize() {
    // this.player.maximize();
    const elem = this.playerWrapper?.nativeElement;
    if (!this.isFullscreen) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }
  }

  get isFullscreen(): boolean {
    return this.utilsService.isFullscreen();
  }

  public onArchiveModalShow(data: { start: Date; end: Date }) {
    this.timeline.onArchiveModalShow(data);
  }

  public qualityChange(quality: Quality, videoCurrentTime: number) {
    this.highQuality = quality === Quality.HQ;
    this.seek(this.time + videoCurrentTime);
  }

  public resize() {
    if (!!this.playerWrapper) {
      if (!this.playerWidth) {
        this.playerHeight = this.playerWrapper.nativeElement.clientWidth / this.aspectRatio;
      } else {
        this.playerHeight = this.playerWidth / this.aspectRatio;
      }
    }
    this.cd.detectChanges();
  }

  public get recentVideo() {
    if (this.isHlsPlayback(this.time)) {
      return false;
    }
    return !this.processing && this.offline && Date.now() - this.time < 5 * 60 * 1000 && !this.isLiveView;
  }

  public forceMp4() {
    this._forceMp4 = true;
  }

}
