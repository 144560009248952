import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-object-card',
  templateUrl: './ui-object-card.component.html',
  styleUrls: ['./ui-object-card.component.scss'],
})
export class UiObjectCardComponent implements OnInit {
  @Input() selected: boolean = false;
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() beta = false;
  @Input() icon = 'person_outline';

  constructor() {}

  ngOnInit(): void {}
}
