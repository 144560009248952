import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { AppState } from '../store/app.state';

@UntilDestroy()
@Directive({
  selector: '[ifDeveloper]',
  standalone: true,
})
export class IfDeveloperDirective {
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.isDeveloper));
  private isDeveloper: boolean = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store$: Store<AppState>) {
    this.selectIsDeveloper$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isDeveloper = res;
        this.showIfUserAuthorized();
      });
  }

  async showIfUserAuthorized() {
    this.viewContainer.clear();
    if (this.isDeveloper) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
