import { createAction, props } from '@ngrx/store';
import { UserSettings } from '@models/user-settings';

export const resetToInitialState = createAction('[USER-SETTINGS] Reset to Initial state');

export const setUserSettings = createAction('[USER-SETTINGS] Set User Settings', props<{ userSettings: UserSettings }>());

export const setUserSettingProperty = createAction('[USER-SETTINGS] Set User Settings Property', props<{ prop: string; value: string | number | boolean }>());

export const validateUserSettingProperty = createAction('[USER-SETTINGS] Validate User Settings Property', props<{ prop: string; value: string }>());

export const saveSettings = createAction('[USER-SETTINGS] Save Settings');

export const getMe = createAction('[USER-SETTINGS] Get Me');

export const verifyPhone = createAction('[USER-SETTINGS] Verify Phone');

export const verifyPhoneSuccess = createAction('[USER-SETTINGS] Verify Phone Success', props<{ id: string }>());

export const startConfirmCode = createAction('[USER-SETTINGS] Start Confirm Code', props<{ code: number; id: string }>());
export const confirmCode = createAction('[USER-SETTINGS] Confirm Code', props<{ code: number; id: string }>());

export const setValidateStatus = createAction('[USER-SETTINGS] Set Validate Status', props<{ isValid: boolean }>());

export const confirmCodeSuccess = createAction('[USER-SETTINGS] Confirm Code Success');


export const startUpdateUserSettingsProcess = createAction('[USER-SETTINGS] Start Update User Settings Process', props<{ userSettings: Partial<UserSettings> }>());
export const updateUserSettingsRequest = createAction('[USER-SETTINGS] Update User Settings Request', props<{ userSettings: Partial<UserSettings> }>());
export const updateUserSettingsRequestSuccess = createAction('[USER-SETTINGS] Update User Settings Request Success', props<{ id: string }>());
export const updateUserSettingsRequestFail = createAction('[USER-SETTINGS] Update User Settings Request Fail', props<{ error: string }>());

