<div class="wrapper">
  <div class="alert-tab zone-tab">
    <div fxLayout="column" fxLayoutGap="8px" class="h-100">
      <div #lineSelectorWrapper
           (click)="click($event)"
           (mousemove)="move($event)"
           fxLayout="row"
           fxLayoutAlign="center center"
           class="zone-selector-wrapper"
           [class.not-loaded]="!imageLoaded"
           [style.height]="imageLoaded ? 'auto' : (lineSelectorWrapper.clientWidth * 9/16) + 'px'"
      >
        <ui-how-to-draw [type]="UiHowToDrawType.LINES" #howToDraw (click)="$event.stopPropagation()"></ui-how-to-draw>
        <div #lines></div>
        <div #p1 class="point" [class.hide]="noSelection()" [ngClass]="currentColor()"></div>
        <div #p2 class="point" [class.hide]="noSelection()" [ngClass]="currentColor()"></div>
        <div #line class="line" [class.hide]="noSelection()"></div>
        <div fxFlexFill fxLayoutAlign="center center" *ngIf="!imageLoaded">
          <ui-preloader></ui-preloader>
        </div>
        <img #snapshot (load)="onImageLoad()"
             [errorImage]="{edgeId: edgeId, cameraId: cameraId } | cameraThumbnailImagePipe"
             errorImage="assets/snapshot-placeholder.jpg"
             [lazyLoad]="!selectedSnapshot ?  ({edgeId: edgeId, cameraId: cameraId } | cameraBestImagePipe | async) : selectedSnapshot"
             (error)="snapshot.alt = 'error'"
             [class.hidden]="snapshot.alt === 'error'"
        />
      </div>
      <div class="next-prev flex-row">
          <span matRipple
                matTooltip="Previous snapshot"
                matTooltipClass="sidebar-tooltip"
                class="ui-icon-prev-frame"
                [class.disabled]="snapshotIdx === snapshots?.length - 1"
                (click)="prevSnapshot()"
          ></span>
        <span matRipple
              matTooltip="Next snapshot"
              matTooltipClass="sidebar-tooltip"
              class="ui-icon-next-frame"
              [class.disabled]="snapshotIdx === 0"
              (click)="nextSnapshot()"
        ></span>
      </div>
      <ng-scrollbar class="line-list-wrapper">
        <!--        <h3 class="mb-5">Your lines</h3>-->
        <span class="mb-20 opacity-70" *ngIf="!asLineCrossing">Place your lines in the right order</span>

        <div cdkDropList class="line-list" (cdkDropListDropped)="drop($event)">
          <div *ngFor="let line of trafficControl.lines; let i = index" fxLayout="row" fxLayoutAlign="start center"
               fxLayoutGap="15px" class="line-box" cdkDrag
          >
            <div class="c-p" cdkDragHandle>
              <mat-icon svgIcon="drag-handle"></mat-icon>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" class="zone-icon">
              <div fxLayout="row" fxLayoutAlign="center center" class="zone-icon-inner"
                   [ngClass]="line?.color ?? 'blue'"
              ></div>
            </div>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Name your line" type="text" [value]="line?.name ?? ''"
                     (input)="changeName($event, i)"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="p-r" class="color-selection">
              <div class="color-dot color-dot-selected" [style.background]="AlertEventLineColorsHex[line?.color]"></div>
              <mat-select class="color-selector" appearance="outline" [value]="line?.color"
                          (selectionChange)="changeColor($event, i)"
              >
                <mat-option *ngFor="let color of AlertEventLineColors" [value]="color">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div class="color-dot" [style.background]="AlertEventLineColorsHex[color]"></div>
                    <div>{{ color | titlecase }}</div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="delete-zone-button ml-15 c-p" (click)="delete(i)">
              <mat-icon svgIcon="delete"></mat-icon>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!asLineCrossing">
          <h3 class="mb-5 mt-20">Additional options</h3>
          <span class="mb-20 opacity-70">Provide an extra information</span>
          <div class="mb-5">Distance between entry point and exit point</div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field appearance="outline">
              <input matInput type="number" min="0" [(ngModel)]="trafficControl.distance" (ngModelChange)="onChange()"/>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="trafficControl.distanceUnits" (ngModelChange)="onChange()">
                <mat-option value="meter">Meter</mat-option>
                <mat-option value="feet">Feet</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-scrollbar>
    </div>

    <!--    {{trafficControl | json}}-->
  </div>
</div>
