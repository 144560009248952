import { Injectable } from '@angular/core';
import { WebhookService } from '../../services/webhook.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import { WebhookActions } from '@states/webhook/webhook.action-types';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { WebhookModel } from '@models/webhook.model';
import { SharedActions } from '@states/shared/shared.action-types';
import { UtilsService } from '../../edge/utils.service';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class WebhookEffects {

  public startCreateWebhook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.startCreateWebhook),
      switchMap(({ webhook }) => {
        return [
          WebhookActions.setIsCreatingLoader({ isCreating: true }),
          WebhookActions.sendCreateWebhookRequest({ webhook })];
      }),
    ),
  );

  public sendCreateWebhookRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.sendCreateWebhookRequest),
      switchMap(({ webhook }) => {
        return this.webhookService.create(this.beforeSendWebhook<WebhookModel.CreateDocumentDto>(webhook))
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Webhook has been created' }),
                WebhookActions.setIsCreatingLoader({ isCreating: false }),
                WebhookActions.sendCreateWebhookRequestSuccess({ webhook: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                WebhookActions.setIsCreatingLoader({ isCreating: false }),
                WebhookActions.sendCreateWebhookRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getOrganizationWebhooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.getOrganizationWebhooks),
      switchMap(() => {
        return this.webhookService.getOrganizationWebhooks()
          .pipe(
            switchMap(res => {
              return [
                WebhookActions.getOrganizationWebhooksSuccess({ documents: res }),
              ];
            }),
            catchError(() => {
              return of(
                WebhookActions.getOrganizationWebhooksFail(),
              );
            }),
          );
      }),
    ),
  );

  public startDeleteWebhook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.startDeleteWebhook),
      debounceTime(400),
      exhaustMap(({ id }) => [WebhookActions.sendDeleteWebhookRequest({ id })]),
    ),
  );

  public sendDeleteWebhookRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.sendDeleteWebhookRequest),
      mergeMap(({ id }) => {
        return this.webhookService
          .delete(id)
          .pipe(
            mergeMap(res => {
              return [
                WebhookActions.sendDeleteWebhookRequestSuccess({ id }),
                SharedActions.showMessage({ success: 'Webhook has been removed' }),
              ];
            }),
            catchError(response => {
              const err = this.utilsService.errMessage(response);
              return [
                WebhookActions.sendDeleteWebhookRequestFail({ id }),
                SharedActions.showMessage({ error: err }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public startUpdateWebhook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.startUpdateWebhook),
      switchMap(({ webhook }) => {
        return [
          WebhookActions.setIsCreatingLoader({ isCreating: true }),
          WebhookActions.sendUpdateWebhookRequest({ webhook })];
      }),
    ),
  );

  public sendUpdateWebhookRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebhookActions.sendUpdateWebhookRequest),
      switchMap(({ webhook }) => {
        return this.webhookService.update(this.beforeSendWebhook<WebhookModel.DocumentMongo>(webhook))
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Webhook has been updated' }),
                WebhookActions.setIsCreatingLoader({ isCreating: false }),
                WebhookActions.sendUpdateWebhookRequestSuccess({ webhook: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                WebhookActions.setIsCreatingLoader({ isCreating: false }),
                WebhookActions.sendUpdateWebhookRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(private webhookService: WebhookService,
              private actions$: Actions,
              private store$: Store<AppState>,
              private utilsService: UtilsService) {
  }


  private beforeSendWebhook<T>(webhook: WebhookModel.CreateDocumentDto | WebhookModel.DocumentMongo): T {
    const updatedWebhook = { ...webhook };
    updatedWebhook.queryParams = webhook.queryParams.filter(param => {
      return param.key && param.value;
    });
    updatedWebhook.headers = webhook.headers.filter(param => {
      return param.key && param.value;
    });
    updatedWebhook.body = webhook.body.filter(param => {
      return param.key && param.value;
    });
    return updatedWebhook as T;
  }
}
