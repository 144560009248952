import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomEventsState, selectAll } from './custom-events.reducer';

export const selectCustomEventsState = createFeatureSelector<CustomEventsState>('customEventsState');

export const selectAllCustomEvents = createSelector(selectCustomEventsState, selectAll);


// export const selectUnsavedCustomEvents =
//   createSelector(
//     selectAllCustomEvents,
//     customEvents =>
//       customEvents.filter(customEvent => !customEvent?.isSaved),
//   );

// export const selectCustomEventsByLocationId = (locationId: string) =>
//   createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => Object.values(customEvents.entities)
//     .filter(customEvent => customEvent.locationId === locationId && !!customEvent.isSaved));


export const selectCustomEventNameById = (customEventId: string) =>
  createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[customEventId]?.name);

export const selectCustomEventById = (customEventId: string) =>
  createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[customEventId]);


export const selectPersonById = (id: string) => createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[id]);

