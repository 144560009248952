import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';
import { AnalyticHeartbeatPulsationActions } from './analytic-heartbeat-pulsation-types';

export interface AnalyticPulsationState extends EntityState<PulsationModels.Store.AnalyticPulsation> {
  message?: string;
  error?: string;
}

export const adapter: EntityAdapter<PulsationModels.Store.AnalyticPulsation> = createEntityAdapter<PulsationModels.Store.AnalyticPulsation>({
  selectId: (status: PulsationModels.Store.AnalyticPulsation) => status.cameraId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: AnalyticPulsationState = adapter.getInitialState({});

export const analyticPulsationStatusReducer = createReducer(
  initialState,

  on(AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess, (state, action) => {
    return adapter.setMany(action.payload, state);
  }),

  on(AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  })
);
