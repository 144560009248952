import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebrtcActiveSessionState } from '@states/webrtc-active-sessions/webrtc-active-sessions.reducer';
import { selectAll } from '@states/webrtc-active-sessions/webrtc-active-sessions.reducer';

export const selectWebRTCActiveSessionState = createFeatureSelector<WebrtcActiveSessionState>('webRTCActiveSessionState');

export const selectActiveSessionByEdgeIdCameraId = (selector: { edgeId: string; cameraId: string }) =>
  createSelector(
    selectWebRTCActiveSessionState,
    (activeSessionState: WebrtcActiveSessionState) => activeSessionState.entities[`${selector.edgeId}:${selector.cameraId}`],
  );

export const selectActiveSessions = createSelector(selectWebRTCActiveSessionState, selectAll);

export const selectIsLocal = createSelector(selectWebRTCActiveSessionState, (activeSessionState: WebrtcActiveSessionState) => Object.values(activeSessionState.entities)
  .filter(session => !session.isLocal).length === 0);

export const selectRemoteCameras = createSelector(selectWebRTCActiveSessionState, (activeSessionState: WebrtcActiveSessionState) => Object.values(activeSessionState.entities)
  .filter(session => !session.isLocal)
  .map(session => session.cameraId));
