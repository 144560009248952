<div class="notification-container" *ngIf="!isClosed" [class.minimized]="isMinimized" [style.opacity]="opacityLevel"
  cdkDrag cdkDragBoundary="body">
  <div class="header">
    <div class="drag-handle" cdkDragHandle></div>
    <span class="title">Notification</span>
    <div class="controls">
      <button (click)="toggleMinimize()" class="control-btn">-</button>
      <button (click)="closeNotification()" class="control-btn">&times;</button>
    </div>
  </div>
  <div class="content" *ngIf="!isMinimized">
    <!-- <p>{{ message }}</p> -->
    <div *ngFor="let message of messages" class="notification-message">
      {{ message }}
    </div>
  </div>
  <div class="opacity-controls">
    <button (click)="decreaseOpacity()" class="control-btn">Decrease Opacity</button>
    <button (click)="increaseOpacity()" class="control-btn">Increase Opacity</button>
  </div>
</div>