import { Component, OnInit } from '@angular/core';
import { EdgeService } from '../edge.service';
import { Edge } from '../edge.model';
import { MainContentService } from '../../layout/main-content.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCameraComponent } from '../add-camera/add-camera.component';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-edge-info',
  templateUrl: './edge-info.component.html',
  styleUrls: ['./edge-info.component.scss'],
})
export class EdgeInfoComponent implements OnInit {
  edge: Edge.EdgeDocument;

  constructor(private edgeService: EdgeService, private mainContentService: MainContentService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.edgeService.currentEdge$.subscribe(edge => {
      this.edge = edge;
      const header = { title: this.edge.edgeId || '', isBack: true };
      this.mainContentService.setHeader(header);
    });
  }

  async addCamera() {
    this.dialog
      .open(AddCameraComponent)
      .afterClosed()
      .subscribe(() => {
      });
  }

  async startDiscover() {

  }

  async tabChanged(event: MatTabChangeEvent) {
    if (event.index == 2) {
      this.startDiscover();
    }
  }
}
