import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CameraThumbnailsData } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { environment } from '../../../../environments/environment';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { KeyValuePairs } from '../../../core/interfaces';
import { GeneralService } from '@states/general/general.service';
import { LocationSelectors } from '@states/location/location.selector-types';
import { Store } from '@ngrx/store';

const LOOP_FREQUENCY = 500;
const LOOP_WINDOW = 10000;
const LOOP_BUFFER = 2000;


@Component({
  selector: 'ui-thumbnail-image',
  templateUrl: './ui-thumbnail-image.component.html',
  styleUrls: ['./ui-thumbnail-image.component.scss'],
})

export class UiThumbnailImageComponent implements OnChanges, OnDestroy {
  public defaultImage = 'assets/thumb_placeholder.png';
  @Input() loop = false;
  @Input() events: number[];
  @Input() alertTs: number;
  @Input() options: CameraThumbnailsData;
  @Input() startTime: number;
  @Input() endTime: number;
  @Input() cameraName: string;
  @Input() loopFrequency = LOOP_FREQUENCY;

  @Output() loopTs = new EventEmitter<number>();

  public img: string = '';
  public offline = false;
  public loading = false;
  public error = false;
  public currentTs: number;
  public defaultThumbnail: string;
  @Input() timezone = 'GMT';

  private thumbnailsUris: string[];
  public timestamp: number;
  private percentage: number;
  private loopInterval;
  private baseUrl: string;
  private eventsRaw: KeyValuePairs<number[]> = {};
  private first: string;

  private preloadUrls = [];
  private loopIdx = 0;
  private loopStart;
  private loopEnd;

  constructor(
    private store$: Store,
    private cd: ChangeDetectorRef,
    private cameraThumbnailsService: CamerasThumbnailsService, private generalService: GeneralService) {
  }

  ngOnDestroy(): void {
    if (this.loopInterval) {
      clearInterval(this.loopInterval);
    }
  }

  normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['options'].firstChange || changes['options'].previousValue['cameraId'] !== changes['options'].currentValue['cameraId']) {
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${this.options.edgeId}/${this.options.cameraId}`;
      await this.initThumbnails();
      if (this.loop) {
        const alertTs = this.normalizeTimestamp(this.alertTs);
        this.loopStart = alertTs - LOOP_WINDOW;
        this.loopEnd = alertTs + LOOP_WINDOW;
        this.timestamp = this.loopStart;
        this.percentage = 0;
        this.preloadUrls = [];
        const numThumbs = Math.floor((this.loopEnd - this.loopStart) / 2000);
        for(let i = 0; i < numThumbs; i++) {
          const timestamp = this.loopStart + i * 2000;
          const filename = this.buildFileName(timestamp);
          this.preloadUrls.push(this.buildUrl(filename));
          if (timestamp === alertTs) {
            const filename = this.buildFileName(timestamp, 1);
            this.preloadUrls.push(this.buildUrl(filename));
          }
        }
        this.generalService.preloadImages(this.preloadUrls);
        if (this.loopInterval) {
          clearInterval(this.loopInterval);
        }
        this.loopInterval = setInterval(_ => {
          const timestamp = this.loopStart + this.loopIdx * 2000;
          this.currentTs = timestamp;
          if (this.loopIdx !== this.preloadUrls.length - 1) {
            this.generalService.preloadImages([this.preloadUrls[this.loopIdx + 1]]);
          }
          this.img = this.preloadUrls[this.loopIdx];
          this.cd.detectChanges();
          if (this.loopIdx === this.preloadUrls.length - 1 || timestamp >= (Date.now() - LOOP_BUFFER)) {
            this.loopIdx = 0;
          } else {
            this.loopIdx++;
          }
        }, this.loopFrequency);
      }
    }
  }

  private getThumbUrl(percentage: number) {
    if (!this.events?.length || !this.thumbnailsUris) {
      return '';
    }
    const index = this.loop ? this.getThumbnailIndexLoop(percentage) : this.getThumbnailIndex(percentage);
    if (this.getThumbnailExists(percentage)) {
      if (this.thumbnailsUris[index]) {
        return this.buildUrl(this.thumbnailsUris[index]);
      }
    }
    return '';
  }

  private loadThumb(timeStamp: number, percentage: number): void {
    if (!this.events?.length || !this.thumbnailsUris) {
      return;
    }
    const index = this.loop ? this.getThumbnailIndexLoop(percentage) : this.getThumbnailIndex(percentage);
    if (this.getThumbnailExists(percentage)) {
      if (this.thumbnailsUris[index]) {
        this.img = this.buildUrl(this.thumbnailsUris[index]);
        this.offline = false;
      }
    } else {
      this.offline = true;
    }
  }

  private getThumbnailIndexLoop(percentage: number): number {
    return Math.floor(this.thumbnailsUris?.length * percentage);
  }

  private getThumbnailIndex(percentage: number): number {
    return this.options.offsetResInDurations
      ? Math.floor((this.thumbnailsUris?.length / this.options.offsetResInDurations) * percentage) * this.options.offsetResInDurations
      : Math.floor(this.thumbnailsUris?.length * percentage);
  }

  private getThumbnailExists(percentage: number) {
    const index = this.getThumbnailIndex(percentage);
    return !!this.thumbnailsUris[index];
  }

  private buildUrl(filename: string) {
    return `${this.baseUrl}/thumbnail-${filename}.jpg`;
  }

  public async initThumbnails(swap = false) {
    this.error = false;
    const thumbsData: CameraThumbnailsData = {
      edgeId: this.options.edgeId,
      cameraId: this.options.cameraId,
      timezone: this.options.timezone,
      offsetResInDurations: 600,
    };
    if (!this.events) {
      this.loading = true;
      this.error = false;
      try {
        await this.getThumbnails(thumbsData, this.startTime, this.endTime);
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
      const base = this.getBaseInLocale(new Date(this.startTime));
      const baseEnd = this.getBaseInLocale(new Date(this.endTime));

      if (!!this.eventsRaw[base] || !!this.eventsRaw[baseEnd]) {
        if (base !== baseEnd && baseEnd !== this.endTime) {
          const indexStart = this.cameraThumbnailsService.getEventLocation(this.startTime, base);
          const indexEnd = this.cameraThumbnailsService.getEventLocation(this.endTime, baseEnd);

          // We need to combine 2 days to one array
          let first: number[] = [];
          let second: number[] = [];
          if (!!this.eventsRaw[base]) {
            first = this.eventsRaw[base].slice(indexStart, this.eventsRaw[base].length);
          } else {
            first = new Array(43200).fill(0)
              .slice(indexStart, 43200);
          }
          if (!!this.eventsRaw[baseEnd]) {
            second = this.eventsRaw[baseEnd].slice(0, indexEnd);
          } else {
            second = new Array(43200).fill(0)
              .slice(0, indexEnd);
          }

          this.events = first.concat(second);
        } else {
          if (!!this.eventsRaw[base]) {
            const indexStart = this.cameraThumbnailsService.getEventLocation(this.startTime, base);
            const indexEnd = this.cameraThumbnailsService.getEventLocation(this.endTime, base);
            this.events = this.eventsRaw[base].slice(indexStart, indexEnd);
          }
        }
      }

    }
    this.buildUris();
    this.loading = false;
    this.img = this.buildUrl(this.first);

  }

  private async getThumbnails(thumbsData: CameraThumbnailsData, startTime: number, endTime: number) {
    this.eventsRaw = await this.cameraThumbnailsService.getThumbnails(thumbsData, startTime, endTime);
  }

  private buildFileName(ts: number, replica: number = 0) {
    return `${ts}-${replica}-0`;
  }

  private buildUris() {
    this.thumbnailsUris = [];
    for(let idx in this.events) {
      if (this.events[idx] === 0) {
        this.thumbnailsUris.push('');
        continue;
      }

      const ts = this.getTimestampForIdx(+idx);

      if (!this.first) {
        if (!!this.defaultThumbnail) {
          const ts = +this.defaultThumbnail.split('-')[1];
          const replica = +this.defaultThumbnail.split('-')[2];
          this.first = this.buildFileName(ts, replica);
        } else {
          this.first = this.buildFileName(ts);
        }
        this.currentTs = +this.getTsFromFilename(this.first);
      }

      if (this.events[idx] === 1) {
        this.thumbnailsUris.push(this.buildFileName(ts));
        continue;
      }

      for(let i = 0; i < this.events[idx]; i++) {
        this.thumbnailsUris.push(this.buildFileName(ts, i));
      }
    }
  }

  private getTimestampForIdx(index) {
    return this.startTime + index * 2000;
  }

  private getTsFromFilename(filename: string) {
    return filename?.split('-')[0];
  }

  private getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.options.timezone);
  }
}
