import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'ui-calendar-time-header',
  templateUrl: './ui-calendar-time-header.component.html',
  styleUrls: ['./ui-calendar-time-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCalendarTimeHeaderComponent implements OnInit, OnChanges {
  @Input() calendarLeft: MatCalendar<any>;
  @Input() calendarRight: MatCalendar<any>;

  @Input() activeStartDate: Date;
  @Input() activeEndDate: Date;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  public isSameYear: boolean = true;

  constructor(private _dateAdapter: DateAdapter<any>) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isSameYear = moment(this.activeStartDate)
      .year() === moment(this.activeEndDate)
      .year();
  }

  public previousClicked(mode: 'month' | 'year') {
    this.calendarLeft.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, -1);

    this.calendarRight.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1) // Use the left calendar's date to set the right calendar
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);
  }

  public nextClicked(mode: 'month' | 'year') {
    const momentCalendarRight = moment(this.calendarRight.activeDate);
    if (momentCalendarRight.month() === moment()
      .month() && momentCalendarRight.year() === moment()
      .year()) {
      return;
    }
    this.calendarLeft.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);

    this.calendarRight.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendarLeft.activeDate, 1) // Directly use the updated left calendar's date
        : this._dateAdapter.addCalendarYears(this.calendarLeft.activeDate, 1);
  }

  get clickPrevEnabled(): boolean {
    const minMonth = moment(this.minDate)
      .month();
    const leftCalendarMonth = moment(this.calendarLeft.activeDate)
      .month();
    return leftCalendarMonth > minMonth;
  }

  get clickNextEnabled(): boolean {
    const maxMonth = moment(this.maxDate)
      .month();
    const rightCalendarMonth = moment(this.calendarRight.activeDate)
      .month();
    return maxMonth > rightCalendarMonth;
  }
}
