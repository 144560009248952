import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Buckets, Person } from '@models/people.model';
import { PeopleService } from '../../../development/people.service';
import { Store } from '@ngrx/store';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { selectBucketById } from '@states/people/people.selectors';

@Component({
  selector: 'ui-person-icon',
  templateUrl: './ui-person-icon.component.html',
  styleUrls: ['./ui-person-icon.component.scss'],
})
export class UiPersonIconComponent implements OnInit {

  @Output() add = new EventEmitter<void>();
  @Output() addExisting = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @Input() person: Person;
  @Input() small = false;
  @Input() tiny = false;
  @Input() allowDelete = false;

  img;

  constructor(
    private personService: PeopleService,
    private store$: Store
  ) {
  }


  ngOnInit(): void {
    const person = this.person;
    this.img = this.personService.getPersonImagePath(person);
  }

  public get isSaved() {
    return this.person.hasOwnProperty('personId');
  }

}
