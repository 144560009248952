import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, Observable, Subject, Subscription, tap } from 'rxjs';
import { PlaybackPlayerService } from '../playback-player/playback-player.service';
import { PlaybackPlayerResolutions } from '../playback-player/playback-player.model';
import * as moment from 'moment-timezone';
import { StreamPlayerComponent } from '../../framework/stream-player/stream-player.component';
import { AddArchiveModalComponent } from '../modals/add-archive-modal/add-archive-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as ArchiveAction from '@states/archive/archive.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
})
export class PlayerControlsComponent implements OnInit, OnDestroy {
  resolutions: typeof PlaybackPlayerResolutions = PlaybackPlayerResolutions;

  @Output()
  start: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  stop: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  reload: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  rewindStart: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  rewind: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  maximize: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onArchiveModalShowed: EventEmitter<{ start: Date; end: Date }> = new EventEmitter<{ start: Date; end: Date }>();

  @Input()
  time: number = 0;

  @Input()
  videoCurrentTime: number = 0;

  @Input()
  timezone: string;

  @Input()
  archiveTimeline: { start: number; end: number };

  time$: Observable<number>;

  @Input()
  durationInMs: number;

  // playing$: Observable<boolean>;

  @Input()
  playing = true;

  @Input()
  player: StreamPlayerComponent;

  durationStr = '00:00:00';
  timeStr = '00:00:00';

  subscriptions: Subscription[] = [];
  startSubscription: Subscription;

  rewindSubject = new Subject<number>();
  rewind$: Observable<number> = this.rewindSubject.asObservable();
  rewindAmount = 0;

  constructor(
    private cd: ChangeDetectorRef,
    private playbackPlayerService: PlaybackPlayerService,
    private dialog: MatDialog,
    private store: Store,
  ) {
  }

  pad(num) {
    return num.toString()
      .padStart(2, '0');
  }

  toDurationStr(msec) {
    let seconds = Math.floor(msec / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  timezoneToGmtString() {
    const timezoneGmt = moment.tz(this.timezone)
      .format('z');
    this.timezone = timezoneGmt;
  }

  ngOnInit(): void {
    this.timezoneToGmtString();
    this.durationStr = this.toDurationStr(this.durationInMs);
    this.timeStr = this.toDurationStr(this.time);
    this.time$ = this.playbackPlayerService.time$;
    this.time$.subscribe(time => {
      this.time = time;
    });

    this.subscriptions.push(
      this.rewind$
        .pipe(
          tap(rewind => {
            this.time += this.videoCurrentTime + rewind * 1000;
            this.playbackPlayerService.setTime(this.time);
            this.rewindAmount += rewind;
          }),
          debounceTime(2000),
          tap(a => {
            this.playbackPlayerService.rewind(this.rewindAmount)
              .subscribe(() => {
                this.rewind.emit();
              });
            this.rewindAmount = 0;
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.playbackPlayerService.unsubscribeStreamExistSubscription();
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public startPlaying() {
    this.start.emit();
  }

  public stopPlayer() {
    this.stop.emit();
  }

  rewindPlayer(durationInSecs: number) {
    if (this.rewindAmount !== 0) {
      this.rewindSubject.next(durationInSecs);
      return;
    }
    const video = this.player.video as HTMLVideoElement;
    const seekEnd = video.seekable.end(0);
    const seekTo = video.currentTime + durationInSecs;
    if (seekTo < seekEnd && seekTo > 0) {
      video.currentTime = seekTo;
    } else {
      this.player?.pauseVid();
      this.rewindStart.emit();
      this.rewindSubject.next(durationInSecs);
    }

    return;
  }

  download() {
    this.playbackPlayerService.download();
  }

  speed(speed: number) {
    this.playbackPlayerService.speed(speed);
    this.reload.emit();
  }

  resolution(resolution: PlaybackPlayerResolutions) {
    this.playbackPlayerService.resolution(resolution);
  }

  max() {
    this.maximize.emit();
  }

  public showCreateArchiveModal() {
    this.store.dispatch(
      ArchiveAction.updateAddArchiveModalConfig({
        property: 'disableSelectedCamera',
        disable: true,
      }),
    );
    // this.store.dispatch(ArchiveAction.updateAddArchiveModalConfig({
    //   property: 'disableSelectDateRange', disable: true,
    // }));
    this.dialog
      .open(AddArchiveModalComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
      })
      .afterClosed()
      .subscribe(res => {
        this.onArchiveModalShowed.emit({ start: res.start, end: res.end });
      });
    this.onArchiveModalShowed.emit(null);
  }
}
