import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DoorsSelectors } from '@states/doors/doors.selector-types';
import { DoorsService } from '../../../development/doors.service';
import { DoorsActions } from '@states/doors/doors.action-types';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { DoorModels } from '@models/door.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';

export enum DoorAddState {
  OPEN_STATE,
  CLOSE_STATE,
  NAME,
  NO_DATA
}

export interface UiDoorDialogData {
  door?: DoorModels.Door;
  toExisting?: boolean;
  selector?: boolean;
  list?: DoorModels.Door[];
}

@UntilDestroy()
@Component({
  selector: 'app-ui-door-dialog',
  templateUrl: './ui-door-dialog.component.html',
  styleUrls: ['./ui-door-dialog.component.scss'],
})
export class UiDoorDialogComponent implements OnInit {

  public noSaved = features.doors.noSaved;

  public PreloaderColor = PreloaderColor;
  public selectSavedDoors$: Observable<DoorModels.Door[]> = this.store$.pipe(select(DoorsSelectors.selectSavedDoors));

  public selectedDoorId = '';
  public selectedDoorIds = [];
  loaded = false;
  closed;
  opened

  public query = '';

  public loading = false;

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  public DoorAddState = DoorAddState;
  public addState: DoorAddState = DoorAddState.CLOSE_STATE;

  constructor(
    private store$: Store,
    @Inject(MAT_DIALOG_DATA) public data: UiDoorDialogData,
    private dialogRef: MatDialogRef<UiDoorDialogComponent>) {
  }

  ngOnInit(): void {
    if (this.data?.door) {
      const door = this.data?.door;
      this.closed = `${environment.aws.trainingThumbnailsUrl}/training/${door.edgeId}/${door.cameraId}/${door.closeState}`;
      this.opened = `${environment.aws.trainingThumbnailsUrl}/training/${door.edgeId}/${door.cameraId}/${door.openState}`;
      if (door.name) {
        this.form.patchValue({name: door.name});
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.value.name);
  }

  public ngOnDestroy(): void {
  }

  select(id: string) {
    if (this.data.selector) {
      if (this.selectedDoorIds.includes(id)) {
        this.selectedDoorIds.splice(this.selectedDoorIds.indexOf(id), 1);
      } else {
        this.selectedDoorIds.push(id);
      }
      return;
    }
    this.selectedDoorId = id;
  }

  public filtered(doors: DoorModels.Door[]) {
    return doors.filter(p => p.name.toLowerCase()
      .includes(this.query.toLowerCase()));
  }

  public exists(id: string) {
    return !this.data.list.map(p => p.id)
      .includes(id);
  }

  public yes() {
    switch (this.addState) {
      case DoorAddState.CLOSE_STATE:
        this.addState = DoorAddState.OPEN_STATE;
        break;
      case DoorAddState.OPEN_STATE:
        this.addState = DoorAddState.NAME;
        break;
      case DoorAddState.NAME:
        // save
        this.dialogRef.close(this.form.get('name').value)
        break;
    }
  }

  public no() {
    this.addState = DoorAddState.NO_DATA;
  }

  public done() {
    this.dialogRef.close({delete: true});
  }


}
