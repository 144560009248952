import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() messages: string[] = [];
  isMinimized: boolean = false;
  isClosed: boolean = false;
  opacityLevel: number = 1;

  @Input() isInGrid: boolean = false;

  @HostBinding('class.in-grid') get isGridMode() {
    return this.isInGrid;
 }

  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
  }

  closeNotification() {
    this.isClosed = true;
  }

  decreaseOpacity() {
    const minOpacity = 0.5;
    if (this.opacityLevel > minOpacity) {
      this.opacityLevel -= 0.1;
    }
  }

  increaseOpacity() {
    const maxOpacity = 1;
    if (this.opacityLevel < maxOpacity) {
      this.opacityLevel += 0.1;
    }
  }
}