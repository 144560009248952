import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import { OnRangeSelectedResult, RelativeTime } from '../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { UiCalendarPickerType } from '@enums/shared.enum';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

const HOUR_MIN = 60;
const DAY_MIN = HOUR_MIN * 24;
const WEEK_MIN = DAY_MIN * 7;
const DAY_HOUR = 24;
const WEEK_HOUR = DAY_HOUR * 7;
const WEEK_DAY = 7;

const dateRangeInitial = {
  start: moment()
    .subtract(1, 'day')
    .toString(),
  end: moment()
    .toString(),
};
const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.minutes, value: 5 } };

export const initialPickerState = {
  pickerCustom: undefined,
  minutes: undefined,
  hours: undefined,
  days: undefined,
  weeks: undefined,
  custom: 5,
  customUnit: CustomUnit.minutes,
};

@Component({
  template: ``,
})
export class UiCalendarBase implements OnChanges {
  @Input() selectDateRange: OnRangeSelectedResult;

  @Input() isSaveEmit: boolean = true;

  @Input() showAdvancedOption: boolean = false;
  @Input() showClearButton: boolean = false;

  @Output() onRangeSelected: EventEmitter<OnRangeSelectedResult> = new EventEmitter<OnRangeSelectedResult>();
  public selectedPickerType: UiCalendarPickerType = UiCalendarPickerType.RELATIVE;
  public calendarDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  public pickerTypes = UiCalendarPickerType;
  public pickerState = { ...initialPickerState };
  public custom = false;
  public hideAdvancedOptions$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public calendarSelectedDays$: BehaviorSubject<any> = new BehaviorSubject({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });
  public showInitialDateRange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public now: Date;


  public startDate: Date;
  public endDate: Date;

  public startTime: string;
  public endTime: string;

  public startTimeBetween: string = '00:00';
  public endTimeBetween: string = '23:59';
  /**
   * Array of selected dates to put specific classes for it
   * @private
   */
  protected selectedRange: string[] = [];

  public readonly minuteOptions = UiDatetimeRangePickerModel.minuteOptions;
  public readonly hourOptions = UiDatetimeRangePickerModel.hourOptions;
  public readonly dayOptions = UiDatetimeRangePickerModel.dayOptions;
  public readonly weekOptions = UiDatetimeRangePickerModel.weekOptions;

  private clickCounter = 1;


  public resetTimePickers(): void {
    this.pickerState = {
      ...this.pickerState,
      minutes: undefined,
      hours: undefined,
      days: undefined,
      weeks: undefined,
    };
  }

  public ngOnChanges() {
    this.populateInitialData();
  }

  private populateInitialData() {
    this.pickerState = { ...initialPickerState };
    if (!this.selectDateRange) {
      this.selectDateRange = defaultDateRange;
      this.showInitialDateRange$.next(false);
    }

    this.selectedPickerType = this.selectDateRange.type;
    if (this.selectDateRange?.relative) {
      this.populateRelative(this.selectDateRange.relative);
    }

    if (this.selectDateRange?.absolute) {
      this.startDate = moment(this.selectDateRange.absolute.start)
        .toDate();
      this.endDate = moment(this.selectDateRange.absolute.end)
        .toDate();
      this.startTime = moment(this.selectDateRange.absolute.start)
        .format('HH:mm');
      this.endTime = moment(this.selectDateRange.absolute.end)
        .format('HH:mm');
      this.generateSelectedRange();
    }


  }

  public resetTimes(): void {
    this.pickerState.minutes = undefined;
    this.pickerState.hours = undefined;
    this.pickerState.days = undefined;
    this.pickerState.weeks = undefined;
  }

  public selectHours(hours: number): void {
    this.resetTimes();
    this.pickerState.customUnit = CustomUnit.hours;
    this.pickerState.custom = hours;
    this.pickerState.hours = hours;
    this.dispatchUpdate();
  }

  public selectDays(value: number): void {
    this.resetTimes();
    this.pickerState.customUnit = CustomUnit.days;
    this.pickerState.custom = value;
    this.pickerState.days = value;
    this.dispatchUpdate();
  }

  public selectWeeks(value: number): void {
    this.resetTimes();
    this.pickerState.customUnit = CustomUnit.weeks;
    this.pickerState.custom = value;
    this.pickerState.weeks = value;
    this.dispatchUpdate();
  }

  public selectMinutes(value: number): void {
    this.resetTimes();
    this.pickerState.customUnit = CustomUnit.minutes;
    this.pickerState.custom = value;
    this.pickerState.minutes = value;
    this.dispatchUpdate();
  }

  public setCustom(maxRetentionDays: number): void {
    this.resetTimePickers();
    this.custom = true;
    // compute the total minutes from custom unit and custom value
    const customMinutes = this.pickerState.customUnit === CustomUnit.minutes ? this.pickerState.custom :
      this.pickerState.customUnit === CustomUnit.hours ? this.pickerState.custom * HOUR_MIN :
        this.pickerState.customUnit === CustomUnit.days ? this.pickerState.custom * DAY_MIN :
          this.pickerState.customUnit === CustomUnit.weeks ? this.pickerState.custom * WEEK_MIN : 0;
    const maxMinutes = maxRetentionDays * DAY_MIN;
    if (customMinutes > maxMinutes) {
      this.pickerState.custom = maxRetentionDays;
      this.pickerState.customUnit = CustomUnit.days;
    }
    this.dispatchUpdate();
  }

  protected dispatchUpdate(): void {
    this.populateRelative({ unit: this.pickerState.customUnit, value: this.pickerState.custom });
    if (!this.isSaveEmit && this.isValid) {
      this.emitDateRangeChanged();
    }
  }

  public emitDateRangeChanged() {
    const startTimeSplit = this.startTime.split(':')
      .map(item => parseInt(item));
    const endTimeSplit = this.endTime.split(':')
      .map(item => parseInt(item));
    const result: OnRangeSelectedResult = {
      type: this.selectedPickerType,
      relative: {
        unit: this.pickerState.customUnit,
        value: this.pickerState.custom,
      },
      absolute: {
        start: moment(this.startDate)
          .hours(startTimeSplit[0])
          .minutes(startTimeSplit[1])
          .format('YYYY-MM-DD HH:mm'),
        end: moment(this.endDate)
          .hours(endTimeSplit[0])
          .minutes(endTimeSplit[1])
          .format('YYYY-MM-DD HH:mm'),
      },
      startBetween: this.startTimeBetween,
      endBetween: this.endTimeBetween,
      selectedDays: this.calendarSelectedDays$.getValue(),
    };
    this.showInitialDateRange$.next(true);
    this.onRangeSelected.emit(result);
  }

  private populateRelative(relative: RelativeTime) {
    this.pickerState.customUnit = relative.unit;
    this.pickerState.custom = relative.value;
    switch (relative.unit) {
      case CustomUnit.minutes:
        if (relative.value >= WEEK_MIN) {
          const modulo = relative.value % WEEK_MIN;
          if (!modulo) {
            this.pickerState.weeks = relative.value / WEEK_MIN;
          }
        } else if (relative.value >= DAY_MIN) {
          const modulo = relative.value % DAY_MIN;
          if (!modulo) {
            this.pickerState.days = relative.value / DAY_MIN;
          }
        } else if (relative.value >= HOUR_MIN) {
          const modulo = relative.value % HOUR_MIN;
          if (!modulo) {
            this.pickerState.hours = relative.value / HOUR_MIN;
          }
        }
        this.pickerState.minutes = relative.value;
        break;
      case CustomUnit.hours:
        if (relative.value >= WEEK_HOUR) {
          const modulo = relative.value % WEEK_HOUR;
          if (!modulo) {
            this.pickerState.weeks = relative.value / WEEK_HOUR;
          }
        } else if (relative.value >= DAY_HOUR) {
          const modulo = relative.value % DAY_HOUR;
          if (!modulo) {
            this.pickerState.days = relative.value / DAY_HOUR;
          }
        }
        this.pickerState.hours = relative.value;
        break;
      case CustomUnit.days:
        if (relative.value >= WEEK_DAY) {
          const modulo = relative.value % WEEK_DAY;
          if (!modulo) {
            this.pickerState.weeks = relative.value / WEEK_DAY;
          }
        }
        this.pickerState.days = relative.value;
        break;
      case CustomUnit.weeks:
        this.pickerState.weeks = relative.value;
        break;
    }
  }

  public dateClass = (date: Date) => {
    const formattedDate = moment(date)
      .format('DD-MM-YYYY');
    if (moment(this.startDate)
      .format('DD-MM-YYYY') == formattedDate) {
      if (this.endDate) {
        return 'selected-limit selected-limit-left';
      } else {
        return 'selected-limit-no-bg';
      }
    } else if (moment(this.endDate)
      .format('DD-MM-YYYY') == formattedDate) {
      return 'selected-limit selected-limit-right';
    } else if (this.selectedRange.indexOf(formattedDate) !== -1) {
      return 'selected-date-range';
    } else {
      return '';
    }
  };

  protected generateSelectedRange() {
    this.selectedRange = [];
    let startRangeDate = moment(this.startDate);
    if (this.endDate) {
      const endRangeDate = moment(this.endDate);
      while (startRangeDate.isSameOrBefore(endRangeDate)) {
        this.selectedRange.push(startRangeDate.format('DD-MM-YYYY'));
        startRangeDate = moment(startRangeDate)
          .add(1, 'day');
      }
    } else {
      this.selectedRange.push(startRangeDate.format('DD-MM-YYYY'));
    }
  }


  /**
   * First click always start range.
   * @param ev
   */
  protected dateChanged(ev: Date, timezone?: string) {
    switch (this.clickCounter) {
      case 1:
        this.endDate = null;
        this.startDate = ev;
        if (!this.startTime) {
          this.startTime = moment()
            .format('HH:mm');
        }
        this.clickCounter++;
        break;
      case 2:
        if (moment(ev)
          .isBefore(this.startDate)) {
          this.startDate = ev;
          if (!this.startTime) {
            this.startTime = moment()
              .format('HH:mm');
          }
          this.clickCounter++;
        } else {
          this.endDate = ev;
          this.endTime = moment.tz(timezone)
            .format('HH:mm');
          /**
           * Dispatch update only if entire range is selected
           */
          this.dispatchUpdate();
        }
        this.clickCounter--;
        break;
      default:
        throw Error('Impossible case');
    }
    this.generateSelectedRange();
  }

  public hideAdvancedOptions(): void {
    const currentValue = this.hideAdvancedOptions$.getValue();
    this.hideAdvancedOptions$.next(!currentValue);
  }

  public selectCalendarDay(day: number) {
    const selectedDays = { ...this.calendarSelectedDays$.getValue() };
    if (selectedDays[day]) {
      delete selectedDays[day];
    } else {
      selectedDays[day] = true;
    }
    this.calendarSelectedDays$.next(selectedDays);
    this.dispatchUpdate();
  }

  public get isValid() {
    const selectedDays = this.calendarSelectedDays$.getValue();
    return !!this.endDate && this.startDate && !!Object.keys(selectedDays).length;
  }

  public cancel() {
    this.populateInitialData();
  }

  public clearCalendar() {
    this.onRangeSelected.emit(null);
  }
}
