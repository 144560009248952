import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { SearchConfigurationSendModel } from '@models/search-configuration.model';
import { SelectedCamera } from '@models/alert-events.model';

@Injectable()
export class CameraSearchSettingsService {
  constructor(private http: HttpClient) {}

  public create(data: CameraSearchSettings): Observable<CameraSearchSettings> {
    return this.http.post<CameraSearchSettings>(api.cameraSearchSettings.crud, data);
  }

  public getAll(selectedCameras: SelectedCamera[]): Observable<CameraSearchSettings[]> {
    return this.http.post<CameraSearchSettings[]>(api.cameraSearchSettings.byIds, { selectedCameras });
  }

  public update(data: CameraSearchSettings, id: string): Observable<CameraSearchSettings> {
    return this.http.put<CameraSearchSettings>(api.cameraSearchSettings.one(id), data);
  }
}
