import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, share, switchMap, timeout } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { DHCPActions } from '@states/dhcp/dhcp.action-types';
import { EdgeService } from '../../edge/edge.service';
import { withLatestFrom } from 'rxjs/operators';
import { UtilsService } from '../../edge/utils.service';
import { SharedActions } from '@states/shared/shared.action-types';

@Injectable()
export class DhcpEffects {

  public startGetAddressReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DHCPActions.startGetDhcp),
      exhaustMap(({ dhcp, enabled }) => [
        DHCPActions.setGetDhcpLoading({ getDhcpLoading: true }),
        DHCPActions.serverRequestGetDhcp({ dhcp, enabled }),
      ]),
    ),
  );

  public serverRequestGetAddressReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DHCPActions.serverRequestGetDhcp),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgeEditState)),
      ),
      mergeMap(([{ dhcp, enabled }, { selectedEdgeId }]) => {
        return this.edgeService.toggleDhcp(selectedEdgeId, dhcp, enabled)
            .pipe(
              timeout(10000),
              switchMap(res => {
                const dhcpForm = res.dhcpForm;
                return [
                  DHCPActions.setGetDhcpLoading({ getDhcpLoading: false }),
                  DHCPActions.serverRequestGetDhcpSuccess({ ...res }),
                  DHCPActions.setDhcpForm({ form: dhcpForm }),
                  DHCPActions.setEnabled({ enabled: res.enabled }),
                ];
              }),
              catchError(response => {
                const err = this.utilsService.errMessage(response);
                return [
                  DHCPActions.setGetDhcpLoading({ getDhcpLoading: false }),
                  DHCPActions.serverRequestGetDhcpFail(),
                  SharedActions.showMessage({ error: err }),
                ];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public startAddReservedAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DHCPActions.startSaveReservedAddress),
      exhaustMap(() => [
        DHCPActions.setAddReservedAddressLoading({ addReservedAddressLoading: true }),
        DHCPActions.serverRequestSaveReservedAddress(),
      ]),
    ),
  );


  public serverRequestAddReservedAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DHCPActions.serverRequestSaveReservedAddress),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgeEditState)),
        this.store$.pipe(select(state => state.dhcpState)),
      ),
      mergeMap(([, { selectedEdgeId }, { reservedAddressesNewRecordsMap }]) => {
        return this.edgeService.addNewReservedAddress(selectedEdgeId, Object.values(reservedAddressesNewRecordsMap))
            .pipe(
              timeout(10000),
              switchMap(res => {
                return [
                  DHCPActions.setAddReservedAddressLoading({ addReservedAddressLoading: false }),
                  DHCPActions.serverRequestSaveReservedAddressSuccess({ reservedAddresses: res.reservedAddresses, attachedAddresses: res.attachedAddresses }),
                  SharedActions.showMessage({ success: 'Records have been saved' }),
                ];
              }),
              catchError(response => {
                const err = this.utilsService.errMessage(response);
                return [
                  DHCPActions.setAddReservedAddressLoading({ addReservedAddressLoading: false }),
                  DHCPActions.serverRequestSaveReservedAddressFail(),
                  SharedActions.showMessage({ error: err }),
                ];
              }),
            );
        },
      ),
      share(),
    ),
  );


  public getDhcpConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DHCPActions.getDhcpConfig),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgeEditState)),
      ),
      mergeMap(([, { selectedEdgeId }]) => {
          return this.edgeService.dhcpGetConfig(selectedEdgeId)
            .pipe(
              timeout(10000),
              switchMap(res => {
                const dhcpForm = res.dhcpForm;
                return [
                  DHCPActions.serverRequestGetDhcpSuccess({ reservedAddresses: res.reservedAddresses, attachedAddresses: res.attachedAddresses }),
                  DHCPActions.setEnabled({ enabled: res?.enabled }),
                  DHCPActions.setDhcpForm({ form: dhcpForm }),
                  DHCPActions.setInitialLoaded(),
                ];
              }),
              catchError(response => {
                console.log(response);
                const err = this.utilsService.errMessage(response);
                return [
                  DHCPActions.getDhcpConfigFailed(),
                  DHCPActions.setInitialLoaded(),
                  SharedActions.showMessage({ error: err }),
                ];
              }),
            );
        },
      ),
      share(),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private edgeService: EdgeService,
              private utilsService: UtilsService) {
  }
}
