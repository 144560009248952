<ng-container *ngrxLet="inactivityCountdown$; let inactivityCountdown">
  <div *ngIf="countDown && !inactive" fxLayout="column"
       fxLayoutAlign="center center"
       class="inactive-wrapper w-100 h-100"
       [class.playback]="playback"
  >
    <div fxLayout="column" fxLayoutAlign="space-around center" class="inactive-dialog">
      <mat-icon svgIcon="stopwatch" *ngIf="!playback"></mat-icon>
      <div fxLayout="column" fxLayoutGap="10px">
        <h3 *ngIf="!playback">{{ 'Video will pause in ' + inactivityCountdown + ' seconds due to inactivity' }}</h3>
        <div *ngIf="playback">{{ 'Video will pause in ' + inactivityCountdown + ' seconds due to inactivity' }}</div>
        <div [class.opacity-50]="!playback">It helps saving your bandwidth usage.</div>
      </div>
      <div fxLayout="row" fxLayoutGap="8px">
        <ui-button text="Keep playing"
                   colorBgr="#FFFFFF"
                   colorText="#344054"
                   colorBorder="#D0D5DD"
                   (clickAction)="keepPlaying.emit()"
        ></ui-button>
        <ui-button text="Pause video" colorBgr="#4367F6" colorText="white"
                   (clickAction)="pause.emit()"
        ></ui-button>

      </div>

    </div>
  </div>
  <div *ngIf="inactive" fxLayout="column"
       fxLayoutAlign="center center"
       class="inactive-wrapper w-100 h-100"
       [class.playback]="playback"
  >
    <div fxLayout="column" fxLayoutAlign="space-around center" class="inactive-dialog">
      <mat-icon svgIcon="stopwatch" *ngIf="!playback"></mat-icon>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <h3 *ngIf="!playback">{{ 'Video paused due to inactivity' }}</h3>
        <div *ngIf="playback">{{ 'Video paused due to inactivity' }}</div>
        <div [class.opacity-50]="!playback">It helps saving your bandwidth usage.</div>
      </div>
      <div fxLayout="row" fxLayoutGap="8px">
        <ui-button *ngIf="inactive" text="Resume video" colorBgr="#4367F6" colorText="white"
                   (clickAction)="_resume()"
        ></ui-button>
      </div>

    </div>
  </div>
</ng-container>
