<label
    [class.selected]="selected && !disabled"
    [class.disabled]="disabled"
    fxLayout="column"
    class="ui-object-card-wrapper"
    [for]="name"
    fxLayoutAlign="center center"
    [style.opacity]="disabled ? 0.5 : 1"
    [class.svg-fill-primary]="selected"
>
  <mat-radio-button color="primary" class="checkbox" [value]="selected" [checked]="selected"
                    [disabled]="disabled"></mat-radio-button>
  <mat-icon [svgIcon]="icon" class="mb-5"></mat-icon>
  <div class="person">{{ name }}</div>
  <div class="beta" *ngIf="beta">beta</div>
</label>
