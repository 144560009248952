import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { OrganizationSettingsComponent } from '../organization-settings/organization-settings.component';
import { Observable } from 'rxjs';
import * as SharedSelectors from '@states/shared/shared.selectors';
import * as SettingsSelectors from '@states/settings/settings.selectors';
import { ProfileSettingsComponent } from '../profile-settings/profile-settings.component';
import { SharedEffects } from '@effects/shared.effects';
import * as SharedActions from '@states/shared/shared.actions';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.scss'],
})
export class MainSettingsComponent implements OnInit {
  @ViewChild('tabs')
  tabs: MatTabGroup;

  @ViewChild('organizationSettingsComponent')
  private organizationSettingsComponent: OrganizationSettingsComponent;

  @ViewChild('profileSettingsComponent')
  private profileSettingsComponent: ProfileSettingsComponent;
  public selectIsValid$: Observable<boolean> = this.store$.pipe(select(SettingsSelectors.selectIsValid));

  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsSaving));

  constructor(private store$: Store<AppState>, private sharedEffects: SharedEffects) {}

  ngOnInit(): void {}

  public save() {
    this.store$.dispatch(SharedActions.pressSave());
  }
}
