import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AlertEventsService } from '../../development/alert-events.service';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AlertLogsActions } from '@states/alert-logs/alert-logs.action-types';
import { AlertsService } from '../../development/alerts.service';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { AlertMonitoringService } from '../../development/alert-monitoring.service';
import { getAlertLogsSuccess, resetEntities } from '@states/alert-logs/alert-logs.actions';
import { SharedActions } from '@states/shared/shared.action-types';
import { UtilsV2Service } from '../../services/utils-v2.service';

@Injectable()
export class AlertLogEffect {

  public startLoadingGrantedAccessList = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertLogsActions.beforeGetAlertLogs),
      exhaustMap(() => [
        AlertLogsActions.setIsLoading({ isLoading: true }),
        AlertLogsActions.getAlertLogs(),
      ]),
    ),
  );


  public getAlertNotificationLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertLogsActions.getAlertLogs),
      withLatestFrom(this.store$.pipe(select(state => state.alertLogsState))),
      switchMap(([, { isLastPage, filters, perPage, page }]) => {
          if (!isLastPage) {
            const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);
            return this.alertMonitoringService.getAlertNotificationLogs(
                {
                  ...filters,
                  timeStart: dateRangeFilter.start,
                  timeEnd: dateRangeFilter.end,
                },
                { limit: perPage, offset: page * perPage },
              )
              .pipe(
                switchMap(res => {
                  return [
                    AlertLogsActions.getAlertLogsSuccess({ alertLogs: res }),
                    AlertLogsActions.setIsLoading({ isLoading: false }),
                  ];
                }),
                catchError(response => {
                  return [
                    AlertLogsActions.getAlertLogsFail(),
                    AlertLogsActions.setIsLoading({ isLoading: false }),
                  ];
                }),
              );
          } else {
            return of(SharedActions.doNothing());
          }
        },
      ),
    ),
  );

  public filterTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertLogsActions.setFilter, AlertLogsActions.removeFilter),
      debounceTime(400),
      switchMap(() => [
        AlertLogsActions.resetEntities(),
        AlertLogsActions.beforeGetAlertLogs(),
      ]),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private alertMonitoringService: AlertMonitoringService,
              private utilsV2Service: UtilsV2Service) {
  }

}
