<div class="sentence-option-selector"
     [class.selected]="!!control?.value?.length || selected"
     *ngrxLet="selectOrgShoppingCarts$; let isShoppingCarts"
>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="disable">
    <div class="disabled-sentence">
      {{ placeholder ?? 'objects' }}
    </div>
  </div>
  <mat-select
    *ngIf="!disable"
    [multiple]="multiple"
    [placeholder]="placeholder ?? 'objects'"
    #select
    disableOptionCentering
    panelClass="sentence-option-selector-panel"
    [formControl]="control"
    (selectionChange)="selectionChange($event)"
    matSelectStyle
    dynamicOverlayClass="step-option-selector-overlay"
    panelWidth=""
  >
    <mat-select-trigger>
      <div fxLayout="row" fxLayoutAlign="center center">
            <span #selectedOption class="selected-option">
            <ng-container *ngIf="!multiple; else multiString">
            {{ optionsGroups ? control?.value?.selectName : (selectionStr ? selectionStr[control?.value] : '') }}
            </ng-container>
            <ng-template #multiString>
              <span *ngFor="let option of control?.value; let i = index">
                {{ selectionStr ? optionStr(option) : '' }}{{ i < control?.value.length - 1 ? (displayAnd ? ' and ' : ' or ') : '' }}
              </span>
            </ng-template>
          </span>
      </div>
    </mat-select-trigger>
    <!--      <ng-scrollbar [style.height.px]="filtered.length * 50"-->
    <!--                    [style]="'min-width: ' + maxChars * 7 +'px'"-->
    <!--                    style="max-height: 290px;"-->
    <!--      >-->
    <ng-container *ngFor="let option of filtered; let i = index">
      <mat-option #opt
                  *ngIf="!(+option === ObjectOptions.shopping_carts && !isShoppingCarts)"
                  [value]="option"
                  class="sentence-option-selector"
      >
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="w-100">
          <div *ngIf="optionsIcon && optionsIcon[option]" fxLayoutAlign="center center" class="icon-16">
            <mat-icon [svgIcon]="optionsIcon[option]"></mat-icon>
          </div>
          <div class="sentence-option-selector-option">{{ optionStr(option) }}</div>
          <div
            *ngIf="+option !== ObjectOptions.pets && +option !== ObjectOptions.shopping_carts"
            fxLayout="row"
            fxLayoutAlign="end end"
            class="c-p icon-16"
            (click)="openFilters(i); $event.stopPropagation()"
            [class.svg-fill-primary]="i === 0 ? peopleSelected && isPersonFilters : vehiclesSelected && isVehicleFilters"
          >
            <mat-icon svgIcon="preferences" style="margin-right: 0px !important;"></mat-icon>
          </div>
        </div>
      </mat-option>
    </ng-container>
    <!--      </ng-scrollbar>-->
  </mat-select>
</div>
