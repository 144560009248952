import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AnalyticStatus, AnalyticStatusString, CameraStatus, CameraStatusToString, LocationStatus, LocationStatusString, StreamerComponentStatus } from '../../cameras/camera.model';
import { EdgeHeartBeatStatus, EdgeHeartBeatStatusToString } from '../../edge/edge.model';
import { AlertSyncStatus } from '@enums/alert-events.enum';
import { VariableSyncStatus } from '@models/variable.model';
import { PreloaderColor } from '@enums/shared.enum';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('wrapper')
  wrapper: ElementRef;

  public PreloaderColor = PreloaderColor;

  public CameraStatus = CameraStatus;
  public AlertSyncStatus = AlertSyncStatus;

  @Input()
  status: CameraStatus | EdgeHeartBeatStatus | AlertSyncStatus | VariableSyncStatus | AnalyticStatus | StreamerComponentStatus | LocationStatus =
    CameraStatus.Online;
  statusString = 'online';
  color = 'yellow';

  @Input()
  text = true;

  @Input()
  customTooltip: string;

  @Input()
  device: 'camera' | 'edge' | 'analytic' | 'alert' | 'location' | 'storage' = 'camera';

  @Input() noBg = false;

  @Input() iconClass = 'icon-16';

  initTs: number;

  public get initDone() {
    return Date.now() - this.initTs > 5000 || this.status === 0;
  }

  constructor() {
  }

  public get statusTitle() {
    const str = this.customTooltip || this.statusString;
    return str.charAt(0)
      .toUpperCase() + str.slice(1);
  }

  ngOnInit(): void {
    this.initTs = Date.now();
  }

  ngAfterViewInit(): void {
    if (this.text) {
      this.wrapper?.nativeElement?.classList?.remove('no-text');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setObject();
    this.setColor();
  }

  setObject() {
    if (this.device === 'camera') {
      this.statusString = CameraStatusToString[this.status];
    }

    if (this.device === 'edge') {
      this.statusString = EdgeHeartBeatStatusToString[this.status];
    }

    if (this.device === 'analytic') {
      this.statusString = AnalyticStatusString[this.status];
    }

    if (this.device === 'location') {
      let status = 0;
      switch (this.status) {
        case LocationStatus.Online:
          status = this.status;
          break;
        case LocationStatus.Offline:
          status = this.status;
          break;
        default:
          status = LocationStatus.Unhealthy;
          break;
      }
      this.statusString = LocationStatusString[status];
    }

    if (this.device === 'alert') {
      switch (this.status) {
        case AlertSyncStatus.synced:
          this.status = LocationStatus.Online;
          this.statusString = 'alert is deployed on all cores';
          break;
        case AlertSyncStatus.notSynced:
          this.status = LocationStatus.Offline;
          this.statusString = 'alert is not deployed on any core';
          break;
        case AlertSyncStatus.notHealthy:
          this.status = AlertSyncStatus.notHealthy;
          this.color = 'yellow';
          this.statusString = 'Alert is not deployed on all cores';
          break;
        case AlertSyncStatus.deleted:
          this.status = LocationStatus.Offline;
          this.statusString = 'Alert is deleted but not all cores are updated';
          break;
      }
      return;
    }
  }

  setColor() {
    if (this.device === 'camera') {
      switch (this.status) {
        case CameraStatus.Streaming:
        case CameraStatus.Online:
          this.color = 'green';
          break;
        case CameraStatus.StreamNotFound:
        case CameraStatus.Unknown:
        case CameraStatus.Offline:
        case CameraStatus.Error:
          this.color = 'red';
          break;
        case CameraStatus.Stopped:
        case CameraStatus.NotAuthenticated:
        case CameraStatus.Init:
        case CameraStatus.Connected:
        default:
          this.color = 'yellow';
          break;
      }
      return;
    }

    if (this.device === 'edge') {
      switch (this.status) {
        case EdgeHeartBeatStatus.Online:
          this.color = 'green';
          break;
        case EdgeHeartBeatStatus.Offline:
        case EdgeHeartBeatStatus.Stopped:
        case EdgeHeartBeatStatus.Unknown:
          this.color = 'red';
          break;
        case EdgeHeartBeatStatus.Init:
        default:
          this.color = 'yellow';
          break;
      }
      return;
    }

    if (this.device === 'analytic') {
      switch (this.status) {
        case AnalyticStatus.Online:
          this.color = 'green';
          break;
        case AnalyticStatus.Offline:
          this.color = 'red';
          break;
      }
      return;
    }

    if (this.device === 'storage') {
      switch (this.status) {
        case StreamerComponentStatus.Available:
          this.color = 'green';
          this.status = LocationStatus.Online;
          break;
        case StreamerComponentStatus.Unavailable:
          this.color = 'red';
          this.status = LocationStatus.Offline;
          break;
      }
      return;
    }
  }
}
