import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationState } from '@states/notification/notification.reducer';
import { UserNotificationState } from '@models/notification.model';

export const selectNotificationState = createFeatureSelector<NotificationState>('notificationState');

export const selectUserNotifications = createSelector(selectNotificationState, ({ filteredUserNotifications, notifications }: NotificationState) => {
  const copy = [...filteredUserNotifications];
  return copy.sort((a, b) => notifications[b.id]?.timestamp - notifications[a.id]?.timestamp);
});

export const selectCountUnseen = createSelector(selectNotificationState, ({ filteredUserNotifications }: NotificationState) => {
  return !!filteredUserNotifications ? filteredUserNotifications.filter(notification => notification.state === UserNotificationState.UNSEEN).length : 0;
});

export const selectNotifications = createSelector(selectNotificationState, ({ notifications }: NotificationState) => notifications);

export const selectUnreadOnly = createSelector(selectNotificationState, ({ unreadOnly }: NotificationState) => unreadOnly);
