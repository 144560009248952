<ng-container *ngIf="!loading; else loader">
  <div class="organization-layout" fxLayout="column" style="height: 100%">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <img src="assets/lumana_logo.svg"/>
      <div class="profile" fxLayout="row" fxLayoutAlign="end center">
        <div class="username">
          {{ (user$ | async)?.email }}
        </div>
        <div class="ml-5 c-p user-menu-button" [matMenuTriggerFor]="beforeMenu" #t="matMenuTrigger">
          <mat-icon>{{ t.menuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </div>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <div class="profile-menu">
            <button mat-menu-item (click)="logout()">Sign out</button>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="wrapper" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>
