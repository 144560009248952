<ng-container *ngrxLet="selectEdgeUptime$; let edgeUptime">
  <ng-container *ngIf="edgeUptime; else loader">
    <div fxLayout="row" class="bar-container p-r">
      <div fxFlex="25" class="camera-name" fxLayout="row" fxLayoutAlign="start center">
        <span class="ui-icon-edge-status-alert"></span>
        {{edge.name}}
      </div>
      <div fxFlex="75" fxLayout="row">
        <div
          #bar
          *ngFor="let online of edgeUptime; let i = index"
          class="bar"
          fxLayout="column"
          (mouseenter)="bar.classList.add('over')"
          (mouseleave)="bar.classList.remove('over')"
        >
          <div class="offline" [style.background-color]="uiBarColors[uiUptimeBarColors.offline]" fxFlex></div>
          <div class="online"
               [style.background-color]="uiBarColors[uiUptimeBarColors.online]"
               [style.height]="online + 'px'"
          ></div>
        </div>
      </div>

    </div>
  </ng-container>
</ng-container>
<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue" [diameter]="25"></ui-preloader>
</ng-template>
