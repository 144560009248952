import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';
import * as  moment from 'moment';

export const selectUserState = createFeatureSelector<UserState>('userState');

export const isProfileLoaded = createSelector(selectUserState, (user: UserState) => user.isProfileLoaded);

export const roles = createSelector(selectUserState, (user: UserState) => user.roles);

export const isDeveloper = createSelector(selectUserState, (user: UserState) => user?.roles?.includes('developer'));

export const isDevTeam = createSelector(selectUserState, (user: UserState) => user?.roles?.includes('dev-team'));

export const orgs = createSelector(selectUserState, (user: UserState) => user.orgs);

export const settings = createSelector(selectUserState, (user: UserState) => user.settings);

export const email = createSelector(selectUserState, (user: UserState) => user.email);


export const fullname = createSelector(selectUserState, (user: UserState) => {
  if (!!user.firstname && !!user.lastname) {
    user.firstname + user.lastname;
  }
});

export const userProfile = createSelector(selectUserState, (user: UserState) => user);

export const selectUser = createSelector(selectUserState, (user: UserState) => user);

export const orgRoles = (orgId: string) =>
  createSelector(selectUserState, (user: UserState) => {
    return user.orgs.find(e => e.orgId.toString() === orgId.toString());
  });

export const selectUserTimezone = createSelector(selectUserState, ({ timezone }: UserState) => {
  const browserTZ = moment.tz.guess();
  return timezone ?? browserTZ;
});

export const selectVerifiedPhone = createSelector(selectUserState, (state: UserState) => state.verifiedPhone);
export const selectIsLoginLoading = createSelector(selectUserState, (state: UserState) => state.isLoginLoading);
export const selectTwilioPttDetails = createSelector(selectUserState, (user: UserState) => user.twilioPtt);

