export const DEFAULT_DURATION_TIME_IN_SECS = 300;

export interface PlaybackPlayerOptions {
  duration: number;
  base: number;
}

export enum PlaybackPlayerResolutions {
  AUTO,
  R1080,
  R720,
  R480,
  R360,
  R240,
  R144,
}

export const PlaybackSpeeds: number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3];
