<div fxLayout="row" fxLayoutAlign="end center">
  <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
</div>
<div class="body">
  <div fxLayout="column" class="ui-input-wrapper label-out">
    <label class="label">ethInterface</label>
    <input [(ngModel)]="ethInterface" type="number" class="ui-input" style="width: 100%; box-sizing: border-box" />
  </div>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="end center">
  <button mat-flat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="ok()">Ok</button>
</div>
