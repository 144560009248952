<div fxLayout="column" class="add-archive-modal">
  <ng-container>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
      Password
      <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
    </div>
  </ng-container>
  <div class="body">
    <div class="input-row flex-row start-center">
      <input class="ui-input" [ngModel]="data.password" [disabled]="true">
      <ui-copy-clipboard style="padding: 8px" [copyContent]="data.password"></ui-copy-clipboard>
    </div>
  </div>
</div>
