import { createAction, props } from '@ngrx/store';
import { WebrtcPlayerState } from '../../../shared/webrtc-v2/webrtc-v2.component';

export const resetToInitialState = createAction('[WEBRTC-ACTIVE-SESSION] Reset to Initial state');
export const updateTimestamp = createAction('[WEBRTC-ACTIVE-SESSION] Update timestamp', props<{ edgeId: string, cameraId: string, timestamp: number }>());
export const deleteActiveSession = createAction('[WEBRTC-ACTIVE-SESSION] Delete active session', props<{ edgeId: string, cameraId: string }>());
export const setActiveSession = createAction('[WEBRTC-ACTIVE-SESSION] Set active session state', props<{
  edgeId: string,
  cameraId: string,
  sessionState?: WebrtcPlayerState,
  isLocal?: boolean,
  unmanaged?: boolean
}>());
export const setActiveSessionSuccess = createAction('[WEBRTC-ACTIVE-SESSION] Set active session state success', props<{
  edgeId: string,
  cameraId: string,
  sessionState?: WebrtcPlayerState,
  isLocal?: boolean,
  unmanaged?: boolean
}>());
export const updateSessionLocal = createAction('[WEBRTC-ACTIVE-SESSION] Set active session local state', props<{ edgeId: string, cameraId: string, isLocal: boolean }>());
