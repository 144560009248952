import { SelectorComparator } from './ui-selector-comparator';

export class OrganizationUserComparator implements SelectorComparator<{ email: string; firstname: string; lastname: string }> {
  public compareWith(
    a: { email: string; firstname: string; lastname: string },
    b: { email: string; firstname: string; lastname: string }
  ): number {
    return a.email?.localeCompare(b.email) || a.firstname?.localeCompare(b.firstname) || a.lastname?.localeCompare(b.lastname);
  }
}
