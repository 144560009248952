import { createAction, props } from '@ngrx/store';
import { YoutubeModel } from '@models/youtube.model';

export const resetToInitialState = createAction('[YOUTUBE] Reset to Initial state');

export const startCreateYoutube = createAction('[YOUTUBE] Start Create Youtube', props<{ isSave: boolean, form: YoutubeModel.YoutubeCreateDto }>());

export const serverRequestCreateYoutube = createAction('[YOUTUBE] Server Request Create Youtube', props<{ form: YoutubeModel.YoutubeCreateDto }>());
export const serverRequestCreateYoutubeSuccess = createAction('[YOUTUBE] Server Request Create Youtube Success', props<{ document: YoutubeModel.YoutubeMongoDocument }>());
export const serverRequestCreateYoutubeFail = createAction('[YOUTUBE] Server Request Create Youtube Fail');

export const setIsSaving = createAction('[YOUTUBE] Set Is Saving', props<{ isSaving: boolean }>());

export const getYoutube = createAction('[YOUTUBE] Get Youtube');
export const getYoutubeSuccess = createAction('[YOUTUBE] Get Youtube Success', props<{ documents: YoutubeModel.YoutubeMongoDocument[] }>());
export const getYoutubeFail = createAction('[YOUTUBE] Get Youtube Fail');


export const deleteYoutubeById = createAction('[YOUTUBE] delete Youtube By Id', props<{ id: string }>());
export const deleteYoutubeByIdSuccess = createAction('[YOUTUBE] delete Youtube By Id Success', props<{ id: string }>());
export const deleteYoutubeByIdFail = createAction('[YOUTUBE] delete Youtube By Id Fail', props<{ id: string }>());
