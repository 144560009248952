<div fxLayout="row" fxLayoutAlign="start center">
  <ui-prefix-input
      class="email-input"
      icon="mail_outline"
      placeholder="user@lumix.ai"
      [invalid]="email.invalid && email.touched"
      [formControl]="email"
  >
  </ui-prefix-input>
  <div class="mat-select-wrapper">
    <mat-select class="ui-selector"
                [ngModel]="permissions"
                (ngModelChange)="updatePermissions($event)"
                [multiple]="true"
                placeholder="Select group"
    >
      <mat-option *ngFor="let group of permissionGroups"
                  [value]="group._id"
      >{{ group.name }}
      </mat-option>
    </mat-select>
  </div>
</div>
