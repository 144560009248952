import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { Analytics } from '../../../../analytics/analytics.model';
import IAnalyticObjects = Analytics.IAnalyticObjects;
import { chartMockData } from '../../../../../../chart.mock';
import { AnalyticsChart } from '@models/analytic-chart.models';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Output() pagination: EventEmitter<{ perPage: number; page: number }> = new EventEmitter<{ perPage: number; page: number }>();

  @Input() items: AnalyticsChart[];
  @Input() axisXLabels: string[];
  @Input() page: number;
  @Input() perPage: number;
  @Input() axisYMax: number;
  @Input() legends: string[] = [];
  @Input() isLatestPage: boolean;
  @Input() rtl: boolean;

  @ViewChild('svgContainer', { read: ElementRef, static: true })
  svgContainerRef!: ElementRef<HTMLDivElement>;

  public svg!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private data: { name: string; series: IAnalyticObjects[] }[];
  // todo move to constants
  public colors: string[] = ['#e41a1c', '#377eb8', '#4daf4a', '#800080', '#ffa500'];
  private isRendered = false;
  private margin = { top: 10, right: 30, bottom: 70, left: 50 };
  private height: number = 350;
  private width: number = 0;

  private isTestMode = false;
  private skip = 0;
  private chartMockData = chartMockData;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges) {
    this.svgContainerRef.nativeElement.innerHTML = '';
    this.isRendered = false;
    if (this.axisYMax === 0) {
      this.axisYMax = 1;
    }
    this.createGroupedChart();
  }

  /**
   * For multi-search-analytics.dto.ts mode only
   * @private
   */
  private loadData() {
    if (this.isTestMode) {
      this.data = [...this.chartMockData].slice(this.skip, (this.page + 1) * 20);
      this.isRendered = false;
      this.createGroupedChart();
    }
  }

  private createSVG() {
    this.isRendered = true;
    this.height = 350 - this.margin.top - this.margin.bottom;

    // append the svg object to the svg container
    this.svg = d3
      .select(this.svgContainerRef.nativeElement)
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('width', this.width + this.margin.left + this.margin.right);
  }

  private createGroupedChart() {
    if (!this.isRendered) {
      this.createSVG();
    }
    this.width = this.svgContainerRef.nativeElement.getBoundingClientRect().width - this.margin.left - this.margin.right;

    // color palette = one color per subgroup
    const color = d3.scaleOrdinal().domain(this.legends).range(this.colors);

    // Add X axis
    const x = d3.scaleBand().domain(this.axisXLabels).range([0, this.width]).padding(0.2);
    this.svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .style('font-size', '12px')
      /**
       * Highlight 1-st and last elements
       */
      .style('font-weight', (d, i) => {
        if (i == 0 || i == this.axisXLabels.length - 1) {
          return 'bold';
        }
        return 'normal';
      });

    // Add Y axis
    const y = d3.scaleLinear().domain([0, this.axisYMax]).range([this.height, 0]);
    this.svg
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(y))
      .selectAll('text')
      .text((d: any) => {
        // hide fractions for y Axis
        if (d % 1 == 0) {
          return d;
        } else {
          return '';
        }
      });

    // Another scale for subgroup position
    const xSubgroup = d3.scaleBand().domain(this.legends).range([0, x.bandwidth()]).padding(0.05);
    // Show the bars
    this.svg
      .append('g')
      .attr('class', 'chart-data')

      .selectAll('g')
      // Enter in data = loop group per group
      .data(this.items)
      .enter()
      .append('g')
      .attr('transform', (d: any) => {
        return 'translate(' + x(d.group) + ',0)';
      })
      .selectAll('rect')
      .data(d => {
        return this.legends.map(key => {
          return { key: key, value: isNaN(d[key]) ? 0 : d[key] };
        });
      })
      .enter()
      .append('rect')

      // @ts-ignore
      .attr('x', d => {
        return xSubgroup(d.key);
      })
      .attr('y', d => {
        return y(d.value);
      })
      .attr('width', xSubgroup.bandwidth())
      .attr('height', d => {
        return this.height - y(d.value);
      })
      // @ts-ignore
      .attr('fill', d => {
        return color(d.key);
      });

    d3.selectAll('rect')
      .on('mouseover', () => {
        return tooltip.style('visibility', 'visible');
      })
      // @ts-ignore
      .on('mousemove', (event, data: { key: string; value: any }) => {
        return tooltip
          .text(`${data?.key}, ${data?.value}`)
          .style('top', event.pageY + 'px')
          .style('left', event.pageX + 15 + 'px')
          .style('width', '120px')
          .style('color', '#fff')
          .style('text-align', 'center')
          .style('border-radius', '6px')
          .style('padding', '5px 0')
          .style('background-color', 'black');
      })
      .on('mouseout', () => {
        return tooltip.style('visibility', 'hidden');
      });

    // create a tooltip
    const tooltip = d3
      .select(this.svgContainerRef.nativeElement)
      .append('div')
      .style('position', 'fixed')
      .style('visibility', 'hidden')
      .attr('class', 'tooltiptext');
  }

  public back() {
    const page = this.page ? this.page - 1 : 0;
    this.pagination.emit({ page: page, perPage: this.perPage });
    if (this.isTestMode) {
      this.skip -= 20;
      this.loadData();
    }
  }

  public forward() {
    const page = (this.page += 1);
    this.pagination.emit({ page: page, perPage: this.perPage });
    if (this.isTestMode) {
      this.skip += 20;
      this.loadData();
    }
  }
}
