import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface UiBreadCrumbItem {
  name: string;
  route?: string;
  queryParams?: any;
  fragment?: string;
}


@Component({
  selector: 'ui-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.scss'],
})
export class UiHeaderComponent implements OnInit {
  @Input()
  public breadCrumbs: UiBreadCrumbItem[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
