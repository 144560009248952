import { createAction, props } from '@ngrx/store';
import { UserDeviceModel } from '@models/user-device.model';

export const resetToInitialState = createAction('[USER-DEVICE] Reset to Initial state');

export const getUserDevices = createAction('[USER-DEVICE] Get User Devices');
export const getUserDevicesSuccess = createAction('[USER-DEVICE] Get User Devices Success', props<{ documents: UserDeviceModel.Document[] }>());
export const getUserDevicesFail = createAction('[USER-DEVICE] Get User Devices Fail');

export const toggleEnableDevice = createAction('[USER-DEVICE] Toggle Enable User Devices', props<{ enabled: boolean, deviceUid: string }>());
export const toggleEnableDeviceSuccess = createAction('[USER-DEVICE] Toggle Enable User Devices Success');
export const toggleEnableDeviceFail = createAction('[USER-DEVICE] Toggle Enable User Devices Fail');

export const muteDeviceUntil = createAction('[USER-DEVICE] Mute Device Until', props<{ mutedUntil: number, deviceUid: string }>());
export const muteDeviceUntilSuccess = createAction('[USER-DEVICE] Mute Device Until Success');
export const muteDeviceUntilFail = createAction('[USER-DEVICE] Mute Device Until Fail');

/**
 * Action to apply immediately changes in UI before backend is respond;
 */
export const updateDeviceStore = createAction('[USER-DEVICE] Update Device Store', props<{ _id: string, document: Partial<UserDeviceModel.Document> }>());
