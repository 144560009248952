import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import { catchError, exhaustMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { OperatorActions } from '@states/operator/operator.action-types';
import { OperatorService } from '../../operator/operator.service';

@Injectable()
export class OperatorEffects {

  public submitWhitelist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatorActions.submitWhitelist),
      switchMap(({ emails }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        OperatorActions.sendWhitelistRequest({ emails }),
      ]),
    ),
  );

  public sendWhitelistRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatorActions.sendWhitelistRequest),
      switchMap(({ emails }) =>
        this.operatorService.submitWhiteList({ emails })
          .pipe(
            switchMap(res => {
              return [
                OperatorActions.sendWhitelistRequestSuccess({ result: res }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              SharedActions.showMessage({ error: err?.response?.message }),
              OperatorActions.sendWhitelistRequestFail(),
              SharedActions.setIsSaving({ isSaving: false }),
            ]),
          ),
      ),
      share(),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private operatorService: OperatorService) {
  }
}
