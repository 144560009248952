import { createAction, props } from '@ngrx/store';
import { DateTimeRangePickerState } from './ui-datetime-range-picker.reducer';
import { UiDatetimeRangePickerModel } from '../ui-datetime-range-picker.model';
import PickerType = UiDatetimeRangePickerModel.PickerType;

export const resetToInitialState = createAction('[DATE TIME PICKER] Reset to Initial state');

export const setPickerState = createAction(
  '[DATE TIME PICKER] Set Picker State',
  props<{ dateTimeRangePickerState: DateTimeRangePickerState }>()
);

export const setPickerType = createAction('[DATE TIME PICKER] Set Picker Type', props<{ pickerType: PickerType }>());
