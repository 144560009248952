import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, share, take } from 'rxjs';
import { StatsService } from '../../../../../development/stats.service';
import { StatsEnum } from '@enums/stats.enum';
import { PreloaderColor, UiUptimeBarColors } from '@enums/shared.enum';
import { uiUptimeBarColors } from '@consts/shared.const';
import { EdgeCamera } from '../../../../../cameras/camera.model';
import { CameraSelectors } from "@states/camera/camera.selector-types";
import { Store } from "@ngrx/store";

@Component({
  selector: 'app-camera-stat-row',
  templateUrl: './camera-stat-row.component.html',
  styleUrls: ['./camera-stat-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraStatRowComponent implements OnChanges {
  public selectCameraUptime$: Observable<number[]> = new Observable(null);

  @Input()
  public camera: EdgeCamera.CameraItem;

  @Input() start: number;

  @Input()
  public selectPeriod: StatsEnum;

  @Input()
  public period: number;

  @Input()
  public bar: number;

  @Input() uptime: number[];

  public uiBarColors = uiUptimeBarColors;
  public uiUptimeBarColors = UiUptimeBarColors;
  public preloaderColor = PreloaderColor;
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private timeout;

  public createdAt: number;

  constructor(private store$: Store) {
  }

  public endTs(i: number) {
    return this.start + (i + 1) * this.bar;
  }

  public createdAt$() {
    return this.store$.select(CameraSelectors.selectCameraCreatedAtById(this.camera?.edgeOnly?.cameraId))
      .pipe(take(1));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.createdAt$().subscribe(createdAt => this.createdAt = createdAt);
    if (this.timeout) {
      this.isLoaded$.next(false);
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.isLoaded$.next(true);
    }, 5000);
  }
}
