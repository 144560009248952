import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-save-search-dialog',
  templateUrl: './save-search-dialog.component.html',
  styleUrls: ['./save-search-dialog.component.scss'],
})
export class SaveSearchDialogComponent implements OnInit {
  searchName: string = 'Untitled saved search'

  constructor(private dialogRef: MatDialogRef<SaveSearchDialogComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close(this.searchName);
  }
}
