import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Point } from "@angular/cdk/drag-drop";

export interface UiAreaSelection {
  start: Point;
  end: Point;
}

@Component({
  selector: 'ui-area-selector',
  templateUrl: './ui-area-selector.component.html',
  styleUrls: ['./ui-area-selector.component.scss']
})
export class UiAreaSelectorComponent implements OnInit {

  @ViewChild('areaSelector')
  areaSelector: ElementRef;

  @ViewChild('area')
  area: ElementRef;

  @Output() selected = new EventEmitter<UiAreaSelection>();

  @Input()
  ratio: number;

  start: Point;
  end: Point;

  drawing = false;


  constructor(private renderer2: Renderer2) {
  }

  ngOnInit(): void {
  }


  startDraw(event: MouseEvent) {
    const selector = this.areaSelector.nativeElement;
    const bounds = selector.getBoundingClientRect();
    this.drawing = true;
    const a = this.area.nativeElement;
    this.renderer2.setStyle(a, 'left', `0px`);
    this.renderer2.setStyle(a, 'top', `0px`);
    this.renderer2.setStyle(a, 'width', `0px`);
    this.renderer2.setStyle(a, 'height', `0px`);
    this.start = {
      x: event.clientX - bounds.x,
      y: event.clientY - bounds.y,
    }
    this.end = this.start;
  }

  updateDraw(event: MouseEvent) {
    if (!this.drawing) {
      return;
    }
    const selector = this.areaSelector.nativeElement;
    const bounds = selector.getBoundingClientRect();
    const a = this.area.nativeElement;
    this.end = {
      x: event.clientX - bounds.x,
      y: event.clientY - bounds.y,
    }

    const width = Math.abs(this.end.x - this.start.x);
    let height = Math.abs(this.end.y - this.start.y);
    if (!!this.ratio) {
      height = width * 1 / this.ratio;
    }

    const startX = this.start?.x < this.end?.x ? this.start?.x : this.end?.x;
    const startY = this.start?.y < this.end?.y ? this.start?.y : this.end?.y;
    this.renderer2.setStyle(a, 'left', `${startX}px`);
    this.renderer2.setStyle(a, 'top', `${startY}px`);
    this.renderer2.setStyle(a, 'width', `${width}px`);
    this.renderer2.setStyle(a, 'height', `${height}px`);

  }

  stopDraw(event: MouseEvent) {
    this.drawing = false;
    const selector = this.areaSelector.nativeElement;
    const bounds = selector.getBoundingClientRect();
    let x = event.clientX - bounds.x;
    let y = event.clientY - bounds.y;
    this.end = {
      x,
      y,
    }
    this.selected.emit({
      start: this.start,
      end: this.end
    })

  }

}
