import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel } from '../locations/location.model';
import { CameraStatus, EdgeCamera } from '../cameras/camera.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { LocationEdgeStatusTotalPipe } from './location-edge-status-total.pipe';
import { Edge, EdgeHeartBeatStatus } from '../edge/edge.model';

@Pipe({
  name: 'locationCameraHealth',
})
export class LocationCameraHealthPipe implements PipeTransform {
  constructor(private locationEdgeStatusPipe: LocationEdgeStatusTotalPipe) {
  }

  transform(location: LocationModel.LocationItem,
            allCameras: { timestamp: number; value: Dictionary<EdgeCamera.EdgeCameraStatus> },
            allEdges: { timestamp: number; value: Dictionary<Edge.EdgeStatus> }): CameraStatus {

    const allCamerasList = allCameras.value;
    const edgesList = allEdges.value;

    const onlineArray = [];
    const offlineArray = [];
    const unhealthyArray = [];


    Object.values(location.edges)
      .forEach(res => {
        const edge = res;
        const cameras = res.cameras;

        const selectedEdge = edgesList[edge.edgeId];

        if (!selectedEdge) {
          offlineArray.push(`edgeId:${edge.edgeId}`);
          return;
        }

        if ((
            selectedEdge.edgeStatus == EdgeHeartBeatStatus.Online) &&
          allEdges?.timestamp &&
          allEdges?.timestamp - Number(selectedEdge?.timestamp) < 360000) {
          onlineArray.push(`edgeId:${selectedEdge.edgeId}`);
        } else if (
          selectedEdge?.edgeStatus === EdgeHeartBeatStatus.Offline ||
          !allEdges?.timestamp ||
          allEdges?.timestamp - Number(selectedEdge?.timestamp) > 360000
        ) {
          offlineArray.push(`edgeId:${selectedEdge.edgeId}`);
        } else {
          unhealthyArray.push(`edgeId:${selectedEdge.edgeId}`);

        }


        if (!!cameras) {
          for(let cameraId of Object.keys(cameras)) {
            const camera = allCamerasList[cameraId];
            if ((camera?.cameraStatus === CameraStatus.Online || camera?.cameraStatus === CameraStatus.Streaming) &&
              allCameras?.timestamp &&
              allCameras?.timestamp - Number(camera?.timestamp) < 360000
            ) {
              onlineArray.push(`cameraId:${cameraId}`);
            } else if (camera?.cameraStatus === CameraStatus.Offline ||
              !allCameras?.timestamp ||
              allCameras?.timestamp - Number(camera?.timestamp) > 360000
            ) {
              offlineArray.push(`cameraId:${cameraId}`);
            } else {
              unhealthyArray.push(`cameraId:${cameraId}`);
            }

          }
        }

      });

    if (!!unhealthyArray?.length) {
      return CameraStatus.Init;
    }

    if (!!onlineArray?.length && !!offlineArray?.length) {
      return CameraStatus.Init;
    }

    if (!!onlineArray?.length && !offlineArray?.length) {
      return CameraStatus.Online;
    }

    if (!onlineArray?.length && !!offlineArray?.length) {
      return CameraStatus.Offline;
    }

    return CameraStatus.Init;


  }
}
