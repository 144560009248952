import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.reducer';

export const selectUAuthenticationState = createFeatureSelector<AuthenticationState>('authenticationState');

export const idToken = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.idToken);

export const authProviderId = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.authProviderId);

export const accessToken = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.accessToken);

export const expiresAt = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.expiresAt);

export const isLoggedIn = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.isLoggedIn);

export const isIdle = createSelector(selectUAuthenticationState, (auth: AuthenticationState) => auth.isIdle);

export const getUserProfileDto = createSelector(authProviderId, accessToken, (authProviderId, accessToken) => {
  return { authProviderId, accessToken };
});
