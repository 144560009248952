export namespace DoorModels {

  export interface Door {
    locationId: string;
    edgeId: string;
    cameraId: string;
    orgId: string;
    id: string;
    openState: string;
    closeState: string;
    timestamp?: number;
    type?: number;
    isSaved?: boolean;
    name?: string;
    position?: number[];
  }

  export enum DoorStatus {
    Saved = 'saved',
    Unsaved = 'unsaved',
  }

  export type GetDoorsResponse = Door[];

  export interface AddDoorRequest {
    newName?: string;
    isSaved?: boolean;
  }

  export enum DoorAlertType {
    closed = 0,
    open = 1,
    changed = 2
  }
}



