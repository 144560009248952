import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Update } from '@ngrx/entity/src/models';
import { WebRTCPeerInterface } from '@models/webrtc.model';
import { WebRtcActions } from '@states/webrtc/webrtc.action-types';
import { StringOrEmpty } from '../../../app.model';

export interface WebRtcState extends EntityState<WebRTCPeerInterface> {
  error?: StringOrEmpty;
  mediasoup: boolean;
}

export const adapter: EntityAdapter<WebRTCPeerInterface> = createEntityAdapter<WebRTCPeerInterface>({
  selectId: (peerData: WebRTCPeerInterface) => peerData.sessionId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialWebRtcState: WebRtcState = adapter.getInitialState({
  mediasoup: false,
});

export const webRtcStateReducer = createReducer(
  initialWebRtcState,

  on(WebRtcActions.CreateWebRtcSessionSuccess, (state, { peerData }) => {
    return adapter.addOne(peerData, state);
  }),
  on(WebRtcActions.CreateWebRtcSessionFail, (state, { message }) => {
    return {
      ...initialWebRtcState,
      error: message,
    };
  }),

  on(WebRtcActions.UpdateWebRtcSession, (state, { peerData }) => {
    const update: Update<WebRTCPeerInterface> = {
      id: peerData.sessionId,
      changes: {
        ...peerData,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(WebRtcActions.DeleteWebRtcSession, (state, { sessionId }) => {
    return adapter.removeOne(sessionId, state);
  }),
  on(WebRtcActions.SetMediasoup, (state, { mediasoup }) => {
    return {
      ...state,
      mediasoup,
    };
  }),
);
