import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { validateEmail } from '../../helpers/common.helpers';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { BehaviorSubject, debounceTime, filter, map, Observable, tap } from 'rxjs';
import { OrganizationEffect } from '@effects/organization.effect';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { PreloaderColor } from '@enums/shared.enum';
import { PermissionGroupModels } from '@models/group.model';
import { OrganizationUsersSelectors } from '@states/organization-users/organization-users.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-invite-users-modal',
  templateUrl: './invite-users-modal.component.html',
  styleUrls: ['./invite-users-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUsersModalComponent implements OnInit {
  public emailControl: UntypedFormControl = new UntypedFormControl([], [emailPatterValidation]);
  public permissionsControl: UntypedFormControl = new UntypedFormControl([], Validators.required);
  public invitedEmailGroups: Dictionary<string> = {};
  public loaderColor = PreloaderColor;
  public selectPermissionGroups$: Observable<PermissionGroupModels.PermissionGroupDocumentBase[]> = this.store$.pipe(select(OrganizationUsersSelectors.selectPermissionGroups));
  public selectPermissionGroupsMap$: Observable<Dictionary<PermissionGroupModels.PermissionGroupDocumentBase>> = this.store$.pipe(select(OrganizationUsersSelectors.selectPermissionGroups))
    .pipe(map(groups => {
      const lookup = {};
      groups.forEach(group => {
        lookup[group._id] = group;
      });
      return lookup;
    }));

  public isUserExists$: Observable<boolean> = this.organizationEffects.checkIfUserExists$
    .pipe(
      ofType(OrganizationUsersActions.checkIfUserExistsSuccess),
      map(res => res.isExists),
      tap(() => this.isCheckLoading$.next(false)));

  public isCheckLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private dialogRef: MatDialogRef<InviteUsersModalComponent>,
              private store$: Store,
              private organizationEffects: OrganizationEffect) {
  }

  public ngOnInit(): void {
    this.emailControl.valueChanges
      .pipe(
        tap(() => this.emailControl.markAsTouched()),
        filter(x => this.emailControl.valid),
      )
      .subscribe(email => {
        console.log(email);
        if (email) {
          this.isCheckLoading$.next(true);
          this.store$.dispatch(OrganizationUsersActions.checkIfUserExists({ email }));
        }
      });

    this.organizationEffects.checkIfUserExists$
      .pipe(
        ofType(OrganizationUsersActions.checkIfUserExistsFail),
      )
      .subscribe(res => {
        this.isCheckLoading$.next(false);
      });

  }

  public validateEmail = validateEmail;

  public addEmail(): void {
    const email = this.emailControl.value.trim();
    if (validateEmail(email) && this.permissionsControl.valid) {
      this.invitedEmailGroups[email] = this.permissionsControl.value;
      this.emailControl.reset();
      this.emailControl.setErrors(null);
      this.permissionsControl.reset();
      this.permissionsControl.setErrors(null);
    }
  }

  public removeEmail(email: string): void {
    delete this.invitedEmailGroups[email];
  }

  public sendInvite(): void {
    if (this.emailControl.value) {
      this.invitedEmailGroups = {
        ...this.invitedEmailGroups,
        [this.emailControl.value]: this.permissionsControl.value,
      };
    }
    this.dialogRef.close(this.invitedEmailGroups);
  }

  get emails(): string[] {
    return Object.keys(this.invitedEmailGroups);
  }
}

const emailPatterValidation = (control: AbstractControl): { isEmail: boolean } => {
  const emailRegex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i);
  const isEmailOk = emailRegex.test(control.value);
  return isEmailOk
    ? null : { isEmail: false };
};

