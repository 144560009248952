import { createReducer, on } from '@ngrx/store';
import { CamerasActions } from './cameras.action-types';
import { SortDirection } from '@angular/material/sort';
import { variableMenuLevel2Validation } from '@consts/menu.const';
import { Camera, CameraProfileInterface, EdgeCamera, FileCamera, ScanType, SearchDevicesByIpRangeData, Substream } from '../../../cameras/camera.model';
import { CamerasCreateType } from '@enums/cameras.enum';
import { CapabilityType } from '@models/search-configuration.model';

const selectedCamerasExampleDetails: Camera[] = [
  {
    name: 'HIKVISION DS-2CD2343G0-I',
    cameraId: '08:a1:89:c8:ff:09',
    ipAddress: '192.168.100.162',
    status: 4,
    mac: '08:a1:89:c8:ff:09',
    onvifData: {
      urn: 'urn:uuid:d6750000-5f36-11b3-8199-08a189c8ff09',
      name: 'HIKVISION%20DS-2CD2343G0-I',
      hardware: 'DS-2CD2343G0-I',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.162/onvif/device_service',
        'http://[fe80::aa1:89ff:fec8:ff09]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/Profile/T',
        'onvif://www.onvif.org/MAC/08:a1:89:c8:ff:09',
        'onvif://www.onvif.org/hardware/DS-2CD2343G0-I',
        'onvif://www.onvif.org/name/HIKVISION%20DS-2CD2343G0-I',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
    addState: {
      token: '213a8c8f-3e8a-485c-8027-f1e983cf86a9',
      loading: false,
      error: 'Connection failed: Unauthorized - Invalid username or password (4)',
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:c1',
    ipAddress: '192.168.100.152',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:c1',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4cc1',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.152/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4cc1]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:c1',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
    addState: {
      token: '10e41fd3-db94-485e-b15e-13bda49d2806',
      loading: false,
      error: 'Connection failed: Unknown Error (5)',
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:75',
    ipAddress: '192.168.100.151',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:75',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4c75',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.151/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4c75]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:75',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
    addState: {
      token: '3119b3bf-55a3-4136-9f86-b48096fb64d8',
      loading: false,
      existingStreamCapabilities: [
        {
          streamId: 101,
          resolution: {
            type: CapabilityType.Resolution,
            value: [
              2560,
              1440,
            ],
            options: [
              [
                2560,
                1440,
              ],
              [
                2304,
                1296,
              ],
              [
                1920,
                1080,
              ],
              [
                1280,
                720,
              ],
            ],
          },
          bitrateType: {
            type: CapabilityType.Selector,
            value: 'VBR',
            options: [
              'CBR',
              'VBR',
            ],
          },
          videoQuality: {
            type: CapabilityType.Selector,
            value: '100',
            options: [
              '1',
              '20',
              '40',
              '60',
              '80',
              '100',
            ],
          },
          bitrate: {
            type: CapabilityType.Range,
            value: 3600,
            min: 32,
            max: 8192,
          },
          framerate: {
            type: CapabilityType.Selector,
            value: '20',
            options: [
              '25',
              '22',
              '20',
              '18',
              '16',
              '15',
              '12',
              '10',
              '8',
              '6',
              '4',
              '2',
              '1',
              '0.5',
              '0.25',
              '0.12',
              '0.06',
            ],
          },
          codecType: {
            type: CapabilityType.Selector,
            value: 'H.265',
            options: [
              'H.264',
              'H.265',
            ],
          },
          iframeInterval: {
            type: CapabilityType.Range,
            value: 20,
            min: 0.02,
            max: 8,
          },
          name: 'Main Stream',
        },
        {
          streamId: 102,
          resolution: {
            type: CapabilityType.Resolution,
            value: [
              640,
              360,
            ],
            options: [
              [
                640,
                480,
              ],
              [
                640,
                360,
              ],
              [
                320,
                240,
              ],
            ],
          },
          bitrateType: {
            type: CapabilityType.Selector,
            value: 'VBR',
            options: [
              'CBR',
              'VBR',
            ],
          },
          videoQuality: {
            type: CapabilityType.Selector,
            value: '60',
            options: [
              '1',
              '20',
              '40',
              '60',
              '80',
              '100',
            ],
          },
          bitrate: {
            type: CapabilityType.Range,
            value: 606,
            min: 32,
            max: 8192,
          },
          framerate: {
            type: CapabilityType.Selector,
            value: '20',
            options: [
              '25',
              '22',
              '20',
              '18',
              '16',
              '15',
              '12',
              '10',
              '8',
              '6',
              '4',
              '2',
              '1',
              '0.5',
              '0.25',
              '0.12',
              '0.06',
            ],
          },
          codecType: {
            type: CapabilityType.Selector,
            value: 'H.264',
            options: [
              'H.264',
              'MJPEG',
              'H.265',
            ],
          },
          iframeInterval: {
            type: CapabilityType.Range,
            value: 1,
            min: 0.02,
            max: 8,
          },
          name: 'Substream',
        },
      ],
      suggestedStreamCapabilities: [
        {
          streamId: 101,
          resolution: {
            type: CapabilityType.Resolution,
            value: [
              2560,
              1440,
            ],
            options: [
              [
                2560,
                1440,
              ],
              [
                2304,
                1296,
              ],
              [
                1920,
                1080,
              ],
              [
                1280,
                720,
              ],
            ],
          },
          bitrateType: {
            type: CapabilityType.Selector,
            value: 'VBR',
            options: [
              'CBR',
              'VBR',
            ],
          },
          videoQuality: {
            type: CapabilityType.Selector,
            value: '100',
            options: [
              '1',
              '20',
              '40',
              '60',
              '80',
              '100',
            ],
          },
          bitrate: {
            type: CapabilityType.Range,
            value: 3600,
            min: 32,
            max: 8192,
          },
          framerate: {
            type: CapabilityType.Selector,
            value: '20',
            options: [
              '25',
              '22',
              '20',
              '18',
              '16',
              '15',
              '12',
              '10',
              '8',
              '6',
              '4',
              '2',
              '1',
              '0.5',
              '0.25',
              '0.12',
              '0.06',
            ],
          },
          codecType: {
            type: CapabilityType.Selector,
            value: 'H.265',
            options: [
              'H.264',
              'H.265',
            ],
          },
          iframeInterval: {
            type: CapabilityType.Range,
            value: 20,
            min: 0.02,
            max: 8,
          },
          name: 'Main Stream',
        },
      ],
      streamCapabilities: [
        {
          streamId: 101,
          resolution: {
            type: CapabilityType.Resolution,
            value: [
              2560,
              1440,
            ],
            options: [
              [
                2560,
                1440,
              ],
              [
                2304,
                1296,
              ],
              [
                1920,
                1080,
              ],
              [
                1280,
                720,
              ],
            ],
          },
          bitrateType: {
            type: CapabilityType.Selector,
            value: 'VBR',
            options: [
              'CBR',
              'VBR',
            ],
          },
          videoQuality: {
            type: CapabilityType.Selector,
            value: '100',
            options: [
              '1',
              '20',
              '40',
              '60',
              '80',
              '100',
            ],
          },
          bitrate: {
            type: CapabilityType.Range,
            value: 3600,
            min: 32,
            max: 8192,
          },
          framerate: {
            type: CapabilityType.Selector,
            value: '20',
            options: [
              '25',
              '22',
              '20',
              '18',
              '16',
              '15',
              '12',
              '10',
              '8',
              '6',
              '4',
              '2',
              '1',
              '0.5',
              '0.25',
              '0.12',
              '0.06',
            ],
          },
          codecType: {
            type: CapabilityType.Selector,
            value: 'H.265',
            options: [
              'H.264',
              'H.265',
            ],
          },
          iframeInterval: {
            type: CapabilityType.Range,
            value: 20,
            min: 0.02,
            max: 8,
          },
          name: 'Main Stream',
        },
      ],
      cameraDetails: {
        name: 'HIKVISION%20HWI-D140H-M',
        username: 'admin',
        password: 'lumix12345',
        cameraBrand: 'Hikvision',
        onvifCompliant: true,
        ptzSupport: false,
        networkDetails: {
          ipAddress: '192.168.100.151',
          port: 554,
          macAddress: 'ec:c8:9c:4e:4c:75',
        },
        cameraId: '64b6582f115dde23c1bf2104',
      },
      error: null,
      autoConfigure: true,
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:41',
    ipAddress: '192.168.100.150',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:41',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4c41',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.150/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4c41]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:41',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
    addState: {
      token: '16803ba5-b5d3-4ae1-b67c-9702a4931995',
      loading: false,
      error: 'Connection failed: Unknown Error (5)',
    },
  },
];

const selectedCamerasExample: Camera[] = [
  {
    name: 'HIKVISION DS-2CD2343G0-I',
    cameraId: '08:a1:89:c8:ff:09',
    ipAddress: '192.168.100.162',
    status: 4,
    mac: '08:a1:89:c8:ff:09',
    onvifData: {
      urn: 'urn:uuid:d6750000-5f36-11b3-8199-08a189c8ff09',
      name: 'HIKVISION%20DS-2CD2343G0-I',
      hardware: 'DS-2CD2343G0-I',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.162/onvif/device_service',
        'http://[fe80::aa1:89ff:fec8:ff09]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/Profile/T',
        'onvif://www.onvif.org/MAC/08:a1:89:c8:ff:09',
        'onvif://www.onvif.org/hardware/DS-2CD2343G0-I',
        'onvif://www.onvif.org/name/HIKVISION%20DS-2CD2343G0-I',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:c1',
    ipAddress: '192.168.100.152',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:c1',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4cc1',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.152/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4cc1]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:c1',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:75',
    ipAddress: '192.168.100.151',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:75',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4c75',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.151/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4c75]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:75',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
  },
  {
    name: 'HIKVISION HWI-D140H-M',
    cameraId: 'ec:c8:9c:4e:4c:41',
    ipAddress: '192.168.100.150',
    status: 4,
    mac: 'ec:c8:9c:4e:4c:41',
    onvifData: {
      urn: 'urn:uuid:0d9f0000-cd92-11b5-84b1-ecc89c4e4c41',
      name: 'HIKVISION%20HWI-D140H-M',
      hardware: 'HWI-D140H-M',
      location: 'hangzhou',
      types: [
        'dn:NetworkVideoTransmitter',
        'tds:Device',
      ],
      xaddrs: [
        'http://192.168.100.150/onvif/device_service',
        'http://[fe80::eec8:9cff:fe4e:4c41]/onvif/device_service',
      ],
      scopes: [
        'onvif://www.onvif.org/type/video_encoder',
        'onvif://www.onvif.org/Profile/Streaming',
        'onvif://www.onvif.org/Profile/G',
        'onvif://www.onvif.org/MAC/ec:c8:9c:4e:4c:41',
        'onvif://www.onvif.org/hardware/HWI-D140H-M',
        'onvif://www.onvif.org/name/HIKVISION%20HWI-D140H-M',
        'onvif://www.onvif.org/location/city/hangzhou',
      ],
    },
  },
];

export declare interface CamerasState {
  edgeId: string;
  locationId: string;
  cameras: Camera[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;

  //method tab
  selectedCameraCreateType: CamerasCreateType;
  selectedCamera: Camera;
  cameraDetails: EdgeCamera.CameraDocument;
  snapshotUrl: string;
  // Multiple Cameras
  selectedCameras: Camera[];
  camerasDetails: EdgeCamera.CameraDocument[];
  snapshotUrls: string[];
  cameraProfiles?: CameraProfileInterface[];
  isOnvif: boolean;
  scanData: SearchDevicesByIpRangeData;
  scanType: ScanType;

  createEnabled: boolean;

  file: Partial<File>;
  listFromFile: FileCamera[];

  level2MenuValid: { [key: string]: boolean };
  camerasAutoComplete: { _id: string; name: string }[];
  substreams: Substream[];
  isWebrtc: boolean;
  isWebrtcRelay: boolean;
}

// TODO: REMOVE - FOR DEV PURPOSES
export const initialState: CamerasState = {
  edgeId: null,
  locationId: null,
  cameras: null,
  totalItemsCount: 0,
  page: 0,
  perPage: 10,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  scanData: null,
  scanType: ScanType.Network,
  //method tab

  selectedCameraCreateType: CamerasCreateType.scan,
  selectedCamera: null,
  cameraDetails: null,
  snapshotUrl: null,
  // Multiple cameras
  selectedCameras: [],
  camerasDetails: [],
  snapshotUrls: [],

  cameraProfiles: null,
  isOnvif: true,

  createEnabled: true,

  file: null,
  listFromFile: null,
  substreams: [],

  level2MenuValid: variableMenuLevel2Validation,
  camerasAutoComplete: [],
  isWebrtc: true,
  isWebrtcRelay: false,
};

export const camerasStateReducer = createReducer(
  initialState,
  on(CamerasActions.resetToInitialState, state => {
    return {
      ...initialState,
      cameras: state.cameras,
    };
  }),
  on(CamerasActions.resetToScanOptions, state => {
    return {
      ...initialState,
      edgeId: state.edgeId,
      locationId: state.locationId,
    };
  }),
  on(CamerasActions.resetConnectionData, state => {
    return {
      ...initialState,
      edgeId: state.edgeId,
      locationId: state.locationId,
      selectedCameraCreateType: state.selectedCameraCreateType,
      selectedCamera: state.selectedCamera,
      cameras: state.cameras,
      scanData: state.scanData,
    };
  }),
  on(CamerasActions.resetCameraDetails, state => {
    return {
      ...initialState,
      cameraDetails: null,
      snapshotUrl: null,
    };
  }),
  on(CamerasActions.setCameras, (state, { cameras }) => {
    return {
      ...state,
      cameras,
    };
  }),
  on(CamerasActions.rescan, (state) => {
    return {
      ...state,
      cameras: [],
    };
  }),
  on(CamerasActions.pushCameras, (state, { cameras }) => {
    return {
      ...state,
      cameras: cameras.concat(state.cameras),
    };
  }),
  on(CamerasActions.setFile, (state, { file }) => {
    return {
      ...state,
      file,
    };
  }),
  on(CamerasActions.setIsWebrtc, (state, { isWebrtc }) => {
    return {
      ...state,
      isWebrtc,
    };
  }),
  on(CamerasActions.setIsWebrtcRelay, (state, { isWebrtcRelay }) => {
    return {
      ...state,
      isWebrtcRelay,
    };
  }),
  on(CamerasActions.setListFromFile, (state, { listFromFile }) => {
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.setCreateEnabled, (state, { createEnabled }) => {
    return {
      ...state,
      createEnabled,
    };
  }),
  on(CamerasActions.setCameraSession, (state, { index, session, reset = true }) => {
    const listFromFile = !!state.listFromFile ? [...state.listFromFile] : [];
    if (reset) {
      listFromFile[index] = {
        ...listFromFile[index],
        session,
        loading: true,
        success: false,
        error: false,
      };
    } else {
      listFromFile[index] = {
        ...listFromFile[index],
        session,
      };
    }
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.resetCamersSessions, state => {
    const listFromFile = [...state.listFromFile];

    return {
      ...state,
      listFromFile,
      snapshotUrl: undefined,
      loading: true,
      success: false,
      error: false,
      errorMsg: undefined,
    };
  }),
  on(CamerasActions.replaceCameraSession, (state, { oldSession, newSession }) => {
    const listFromFile = [...state.listFromFile];
    const idx = listFromFile.findIndex(camera => camera.session === oldSession);
    listFromFile[idx] = {
      ...listFromFile[idx],
      session: newSession,
    };
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.setCameraSessionStatus, (state, { session, error, errorMsg, success, loading }) => {
    const listFromFile = [...state.listFromFile];
    const idx = listFromFile.findIndex(camera => camera.session === session);
    listFromFile[idx] = {
      ...listFromFile[idx],
      loading: loading ?? false,
      error: error ?? false,
      errorMsg: errorMsg ?? null,
      success: success ?? false,
    };
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.setCameraCreateStatus, (state, { session, createStatus, createErrorMsg }) => {
    const listFromFile = [...state.listFromFile];
    const idx = listFromFile.findIndex(camera => camera.session === session);
    listFromFile[idx] = {
      ...listFromFile[idx],
      createStatus,
      createErrorMsg,
    };
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.setMultiCameraData, (state, { session, snapshotUrl, camera }) => {
    const listFromFile = [...state.listFromFile];
    const idx = listFromFile.findIndex(camera => camera.session === session);
    listFromFile[idx] = {
      ...listFromFile[idx],
      snapshotUrl,
      camera,
    };
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.updateMultiCamera, (state, { session, camera }) => {
    const listFromFile = [...state.listFromFile];
    const idx = listFromFile.findIndex(camera => camera.session === session);
    listFromFile[idx] = {
      ...listFromFile[idx],
      ...camera,
    };
    return {
      ...state,
      listFromFile,
    };
  }),
  on(CamerasActions.setIsOnvif, (state, { isOnvif }) => {
    return {
      ...state,
      isOnvif,
    };
  }),
  on(CamerasActions.setOrderParams, (state, { orderBy, orderDirection }) => {
    switch (state.selectedCameraCreateType) {
      case CamerasCreateType.onvif:
        const cameras = [...state.cameras];
        cameras.sort((a, b) => {
          const o1 = a[orderBy]?.toString()
            .toLowerCase();
          const o2 = b[orderBy]?.toString()
            .toLowerCase();
          return orderDirection === 'asc' ? o1.localeCompare(o2) : o2.localeCompare(o1);
        });
        return {
          ...state,
          orderBy,
          orderDirection,
          cameras,
        };
        break;

      case CamerasCreateType.file:
        const listFromFile = [...state.listFromFile];
        listFromFile.sort((a, b) => {
          return orderDirection === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        });
        return {
          ...state,
          orderBy,
          orderDirection,
          listFromFile,
        };
        break;
      case CamerasCreateType.manual:
      default:
        return {
          ...state,
          orderBy,
          orderDirection,
        };
    }
  }),
  on(CamerasActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
  on(CamerasActions.setCameraCreateType, (state, { selectedCameraCreateType }) => {
    return {
      ...state,
      selectedCameraCreateType,
    };
  }),
  on(CamerasActions.addValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: true,
      },
    };
  }),
  on(CamerasActions.rmValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: false,
      },
    };
  }),
  on(CamerasActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(CamerasActions.setEdgeId, (state, { edgeId }) => {
    return {
      ...state,
      edgeId,
      cameras: edgeId !== state.edgeId ? null : state.cameras,
    };
  }),
  on(CamerasActions.setCameraProfiles, (state, { cameraProfiles }) => {
    return {
      ...state,
      cameraProfiles,
    };
  }),
  on(CamerasActions.setScanData, (state, { scanData }) => {
    return {
      ...state,
      scanData,
    };
  }),
  on(CamerasActions.setSelectedCameraToken, (state, { token, index }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    selectedCameras[index] = {
      ...selectedCameras[index],
      addState: {
        ...selectedCameras[index]?.addState,
        token,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraLoading, (state, { token, loading, index }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = index || index === 0 ? index : selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        loading,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraExistingStreamCapabilities, (state, { token, index, existingStreamCapabilities }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = index || index === 0 ? index : selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        existingStreamCapabilities,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraSuggestedStreamCapabilities, (state, { token, index, suggestedStreamCapabilities }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = index || index === 0 ? index : selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        suggestedStreamCapabilities,
        streamCapabilities: [...suggestedStreamCapabilities],
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraStreamCapabilities, (state, { token, index, streamCapabilities }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = index || index === 0 ? index : selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        streamCapabilities,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraDetails, (state, { token, cameraDetails }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        cameraDetails: {
          ...cameraDetails,
          origName: cameraDetails.name,
        },
        error: null,
        autoConfigure: true,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraAuth, (state, { index, auth }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    // const idx = selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[index] = {
      ...selectedCameras[index],
      addState: {
        ...selectedCameras[index]?.addState,
        ...auth,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraAutoConfigure, (state, { index, autoConfigure }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    selectedCameras[index] = {
      ...selectedCameras[index],
      addState: {
        ...selectedCameras[index]?.addState,
        autoConfigure,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraSnapshotLoaded, (state, { index }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    selectedCameras[index] = {
      ...selectedCameras[index],
      addState: {
        ...selectedCameras[index]?.addState,
        snapshotLoaded: true,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraName, (state, { index, name }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    if (!selectedCameras[index] || selectedCameras[index]?.addState?.error) {
      return {
        ...state,
      };
    }

    selectedCameras[index] = {
      ...selectedCameras[index],
      name,
      addState: {
        ...selectedCameras[index]?.addState,
        cameraDetails: selectedCameras[index]?.addState?.cameraDetails ? {
          ...selectedCameras[index]?.addState?.cameraDetails,
          name,
        } : null,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraError, (state, { token, index, error }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = index || index === 0 ? index : selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        error,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraCreateSuccess, (state, { token }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        createSuccess: true,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSelectedCameraCreateError, (state, { token, error }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = selectedCameras.findIndex(camera => camera?.addState?.token === token);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        createErrorMsg: error,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.removeSelectedCamera, (state, { index }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    selectedCameras.splice(index, 1);
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setCameraDetails, (state, { cameraDetails, multiple }) => {
    if (multiple) {
      const selectedCameras: Camera[] = [...state.selectedCameras];
      const idx = selectedCameras.findIndex(camera => camera.cameraId === cameraDetails.cameraId);
      selectedCameras[idx] = {
        ...selectedCameras[idx],
        addState: {
          ...selectedCameras[idx]?.addState,
          cameraDetails,
        },
      };
      return {
        ...state,
        selectedCameras,
      };
    }
    return {
      ...state,
      cameraDetails,
    };
  }),
  on(CamerasActions.setName, (state, { name }) => {
    return {
      ...state,
      cameraDetails: {
        ...state.cameraDetails,
        name,
      },
    };
  }),
  on(CamerasActions.setDescription, (state, { description }) => {
    return {
      ...state,
      cameraDetails: {
        ...state.cameraDetails,
        description,
      },
    };
  }),
  on(CamerasActions.setAutoConfigure, (state, { autoConfigure }) => {
    return {
      ...state,
      cameraDetails: {
        ...state.cameraDetails,
        autoConfigure,
      },
    };
  }),
  on(CamerasActions.setMainStream, (state, { connectionString, height, width }) => {
    return {
      ...state,
      cameraDetails: {
        ...state.cameraDetails,
        mainStream: {
          ...state.cameraDetails.mainStream,
          main: {
            ...state.cameraDetails.mainStream.main,
            connectionString,
            height,
            width,
          },
        },
      },
    };
  }),
  on(CamerasActions.setSubStream, (state, { connectionString, height, width }) => {
    return {
      ...state,
      cameraDetails: {
        ...state.cameraDetails,
        mainStream: {
          ...state.cameraDetails.mainStream,
          mainScale: {
            ...state.cameraDetails.mainStream.main,
            connectionString,
            height,
            width,
          },
        },
      },
    };
  }),
  on(CamerasActions.setLocationId, (state, { locationId }) => {
    return {
      ...state,
      locationId,
    };
  }),

  on(CamerasActions.clearSelectedCamera, (state) => {
    return {
      ...state,
      selectedCamera: null,
      cameraDetails: null,
      snapshotUrl: null,
      cameraProfiles: null,

    };
  }),

  on(CamerasActions.setSelectedCamera, (state, { selectedCamera }) => {
    return {
      ...state,
      selectedCamera,
    };
  }),
  on(CamerasActions.setSelectedCameras, (state, { selectedCameras }) => {
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSnapshotUrl, (state, { snapshotUrl, multiple, token }) => {
    if (multiple) {
      const selectedCameras: Camera[] = [...state.selectedCameras];
      const idx = selectedCameras.findIndex(camera => camera?.addState?.token === token);
      selectedCameras[idx] = {
        ...selectedCameras[idx],
        addState: {
          ...selectedCameras[idx]?.addState,
          snapshot: snapshotUrl,
        },
      };
      return {
        ...state,
        selectedCameras,
      };
    }
    return {
      ...state,
      snapshotUrl,
    };
  }),
  on(CamerasActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(CamerasActions.getCamerasAutocompleteSuccess, (state, { camerasAutoComplete }) => {
    return {
      ...state,
      camerasAutoComplete,
    };
  }),
  on(CamerasActions.addSubstream, (state, {}) => {
    const substreams = [...state.substreams];
    substreams.push({
      connectionString: '',
      connected: false,
      loading: false,
    });
    return {
      ...state,
      substreams,
    };
  }),
  on(CamerasActions.updateSubstream, (state, { connectionString, index }) => {
    const substreams = [...state.substreams];
    substreams[index] = {
      ...substreams[index],
      connectionString,
    };
    return {
      ...state,
      substreams,
    };
  }),
  on(CamerasActions.deleteSubstream, (state, { index }) => {
    const substreams = [...state.substreams];
    substreams.splice(index, 1);
    return {
      ...state,
      substreams,
    };
  }),
  on(CamerasActions.testSubstream, (state, { index }) => {
    const substreams = [...state.substreams];
    substreams[index] = {
      ...substreams[index],
      loading: true,
    };
    return {
      ...state,
      substreams,
    };
  }),
  on(CamerasActions.setCameraError, (state, { cameraId, error }) => {
    const selectedCameras: Camera[] = [...state.selectedCameras];
    const idx = selectedCameras.findIndex(camera => camera.cameraId === cameraId);
    selectedCameras[idx] = {
      ...selectedCameras[idx],
      addState: {
        ...selectedCameras[idx]?.addState,
        error,
      },
    };
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(CamerasActions.setSubstreamSession, (state, { index, session }) => {
    const substreams = [...state.substreams];
    substreams[index] = {
      ...substreams[index],
      session,
    };
    return {
      ...state,
      substreams,
    };
  }),
  on(CamerasActions.testSubstreamDone, (state, { session, success, deleteSub, width, height }) => {
    const substreams = [...state.substreams];
    const index = substreams.findIndex(sub => sub.session === session);

    if (deleteSub) {
      substreams.splice(index, 1);
    } else {
      substreams[index] = {
        ...substreams[index],
        loading: false,
        connected: success,
        width,
        height,
      };
    }
    return {
      ...state,
      substreams,
    };
  }),
);
