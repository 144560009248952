import { createReducer, on } from '@ngrx/store';
import * as DevTeamActions from '@states/dev-team/dev-team.actions';
import { Organization } from '@models/organization.model';
import moment from 'moment';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

const dateRangeInitial = {
  start: moment()
    .subtract(10, 'months')
    .toString(),
  end: moment()
    .add(1, 'minutes')
    .toString(),
};
export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.minutes, value: 5 } };

export interface DevTeamState {
  users: any[];
  organizationsLookup: { [key: string]: Organization };
  filters: {
    selectedOrganizations: Organization[];
    /**
     * @deprecated
     * use dateRange instead
     */
    start: string;
    /**
     * @deprecated
     * use dateRange instead
     */
    end: string;
    query: string;
    dateRange: OnRangeSelectedResult;
  };
  page: number;
  perPage: number;
  totalItemsCount: number;
}

const initialState: DevTeamState = {
  users: null,
  organizationsLookup: {},
  filters: {
    selectedOrganizations: [],
    start: moment()
      .subtract(10, 'months')
      .toString(),
    end: moment()
      .add(1, 'minutes')
      .toString(),
    query: '',
    dateRange: defaultDateRange,
  },
  page: 0,
  perPage: 20,
  totalItemsCount: 0,
};

export const devTeamStateReducer = createReducer(
  initialState,
  on(DevTeamActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(DevTeamActions.getUsersSuccess, (state, { users }) => {
    return {
      ...state,
      users,
    };
  }),
  on(DevTeamActions.getOrganizationsSuccess, (state, { organizations }) => {
    return {
      ...state,
      organizationsLookup: organizations,
    };
  }),
  on(DevTeamActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(DevTeamActions.setDateRange, (state, { dateRange }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        dateRange,
      },
    };
  }),
  on(DevTeamActions.setPagination, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(DevTeamActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
);
