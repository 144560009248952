import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddOnOptions, AddOnPrice, BoxConfiguration, BoxPrice, CameraOptions, CameraPrice, DeviceOptions, LicenseTerms, Pricing, PricingCount, PricingLocationInfo, RetentionOptions, StorageOptions } from '@models/pricing.model';
import { addOnPricing, appleTvLicensePricing, boxConfiguration, boxPricing, cameraPricing, costFieldsStr, PricingTermToYears, sensorLicensePricing, speakerLicensePricing, swLicensePricing } from '@consts/pricing.const';

@Injectable({
  providedIn: 'root',
})

export class PriceCalculatorService {

  private costFieldsStr = costFieldsStr;

  constructor(private http: HttpClient) {
  }

  boxConfiguration: BoxConfiguration[] = boxConfiguration;
  boxPricing: BoxPrice[] = boxPricing;
  swLicensePricing: Record<LicenseTerms, number> = swLicensePricing;
  speakerLicensePricing: Record<LicenseTerms, number> = speakerLicensePricing;
  sensorLicensePricing: Record<LicenseTerms, number> = sensorLicensePricing;
  appleTvLicensePricing: Record<LicenseTerms, number> = appleTvLicensePricing;
  addOnPricing: AddOnPrice[] = addOnPricing;
  cameraPricing: CameraPrice[] = cameraPricing;

  getBoxPrice(storage: StorageOptions, device: DeviceOptions, terms: LicenseTerms): number {
    const elem = this.boxPricing.find((item) => {
      return (item.storage === storage && item.device === device);
    });
    if (elem != null)
      return elem.price[terms];
    return null;
  }

  getCameraPrice(resolution: CameraOptions, terms: LicenseTerms, own = false): number {
    const elem = this.cameraPricing.find((item) => {
      return (item.resolution === resolution);
    });
    if (elem != null) {
      if (own && elem.own != null) {
        return elem.own;
      }
      return elem.price[terms];
    }
    return null;
  }

  getAddOnPrice(type: AddOnOptions): number {
    return addOnPricing.find(item => item.type === type).price;
  }

  getSWLicensePrice(terms: LicenseTerms): number {
    return this.swLicensePricing[terms];
  }

  getSpeakerLicensePrice(terms: LicenseTerms): number {
    return this.speakerLicensePricing[terms];
  }

  getSensorLicensePrice(terms: LicenseTerms): number {
    return this.sensorLicensePricing[terms];
  }

  getAppleTvLicensePrice(terms: LicenseTerms): number {
    return this.appleTvLicensePricing[terms];
  }

  getRetentionPrice(count: PricingCount): number {
    let total = 0;
    const prices = {
      metadataStorage: 19,
      cloudStorage: 99,
      externalStorage: 19,
      snapshotStorage: 9,
    };

    for(let retention of ['metadataStorage', 'cloudStorage', 'externalStorage', 'snapshotStorage']) {
      const price = prices[retention];
      for(let multiplier of [1, 2, 3, 6, 12]) {
        if (!!count[`${retention}${multiplier === 12 ? '365' : (multiplier * 30)}`]) {
          total += count[`${retention}${multiplier === 12 ? '365' : (multiplier * 30)}`] * price * multiplier;
        }
      }
    }
    return total;
  }

  getAdditionalOptionsPrice(count: PricingCount, terms: LicenseTerms) {
    return count.additionalSpeakerLicense * this.getSpeakerLicensePrice(terms) +
      count.additionalSensorLicense * this.getSensorLicensePrice(terms) +
      count.additionalAppleTvLicense * this.getAppleTvLicensePrice(terms) +
      count.additionalAlertStorage * 129 +
      count.additionalEventTagsStorage * 39;
  }

  getVerificationPrice(count: PricingCount) {
    return count.verificationEvents * 1200 +
      count.verificationGuns * 480 +
      count.verificationFire * 36;
  }

  getBoxConfiguration(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: CameraOptions): BoxConfiguration {
    return this.boxConfiguration.filter((item) => {
      return (retention === item.retention && resolution === item.resolution && item.device === device && item.storage === storage);
    })[0];
  }

  getMaxCameras(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: CameraOptions): number {
    const boxConfiguration = this.getBoxConfiguration(device, storage, retention, resolution);
    return boxConfiguration.maxNumberOfCameras;
  }


  getMaxBoxConfiguration(numOfCameras: number, retention: RetentionOptions, resolution: CameraOptions, includeOrinNano: boolean = false): BoxConfiguration {
    if (numOfCameras <= 0)
      return null;
    const relevantConfigurations = (includeOrinNano ? boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution);
      }) :
      boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution && item.device != DeviceOptions.Nano8GBOrin);
      }))
      .sort((a, b) => {
        return (a.maxNumberOfCameras - b.maxNumberOfCameras);
      });
    for(let i = 0; i < relevantConfigurations.length; i++) {
      if (numOfCameras <= relevantConfigurations[i].maxNumberOfCameras) {
        return relevantConfigurations[i];
      }
    }
    // return the biggest
    return relevantConfigurations[relevantConfigurations.length - 1];
  }

  public getLocationBoxes(locationInfos: PricingLocationInfo[], include1u = false): { box4tb: number, box1u32: number, box1u64: number, box1u88: number } {
    let box4tb = 0;
    let box1u = 0;
    let box1u32 = 0;
    let box1u64 = 0;
    let box1u88 = 0;
    let array = [];

    let score = 0;
    let cameraScore = 0;
    for(let info of locationInfos) {
      for(let i = 0; i < info.numberOfCameras; i++) {
        array.push({ retention: info.retention, resolution: info.resolution });
      }
      switch (info.resolution) {
        case CameraOptions.Resolution5MP:
          cameraScore += info.numberOfCameras * 1;
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 1;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 2;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 3;
              break;
          }
          break;
        case CameraOptions.Resolution8MP:
          cameraScore += info.numberOfCameras * 1.5;
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 1.5;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 3;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 4;
              break;
          }
          break;
        case CameraOptions.Fisheye12MP:
          cameraScore += info.numberOfCameras * 2;
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 2;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 4;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 6;
              break;
          }
          break;
      }
    }

    if (cameraScore >= 64 && include1u) {
      const howMany = Math.floor(cameraScore / 64);
      let storageScore = 0;
      let resolutionScore = 0;
      let lastIndex = 0;
      for(let [index, info] of array.sort((a, b) => a.retention - b.retention)
        .entries()) {
        switch (info.retention) {
          case RetentionOptions.Days30:
            storageScore += 1;
            break;
          case RetentionOptions.Days60:
            storageScore += 2;
            break;
          case RetentionOptions.Days90:
            storageScore += 3;
            break;
        }
        switch (info.resolution) {
          case CameraOptions.Resolution5MP:
            resolutionScore += 1;
            break;
          case CameraOptions.Resolution8MP:
            resolutionScore += 1.5;
            break;
          case CameraOptions.Fisheye12MP:
            resolutionScore += 2;
            break;
        }

        if (resolutionScore >= 64) {
          if (storageScore <= 64) {
            box1u32++;
          } else if (storageScore <= 128) {
            box1u64++;
          } else if (storageScore <= 196) {
            box1u88++;
          } else {
            console.log('error');
          }
          storageScore = 0;
          resolutionScore = 0;
          lastIndex = index;
        }

      }
      const arr = array.sort((a, b) => a.retention - b.retention);
      let boxScore = 0;
      for(let i = lastIndex + 1; i < arr.length; i++) {
        switch (arr[i].resolution) {
          case CameraOptions.Resolution5MP:
            cameraScore += 1;
            switch (arr[i].retention) {
              case RetentionOptions.Days30:
                boxScore += 1;
                break;
              case RetentionOptions.Days60:
                boxScore += 2;
                break;
              case RetentionOptions.Days90:
                boxScore += 3;
                break;
            }
            break;
          case CameraOptions.Resolution8MP:
            cameraScore += 1.5;
            switch (arr[i].retention) {
              case RetentionOptions.Days30:
                boxScore += 1.5;
                break;
              case RetentionOptions.Days60:
                boxScore += 3;
                break;
              case RetentionOptions.Days90:
                boxScore += 4;
                break;
            }
            break;
          case CameraOptions.Fisheye12MP:
            cameraScore += 2;
            switch (arr[i].retention) {
              case RetentionOptions.Days30:
                boxScore += 2;
                break;
              case RetentionOptions.Days60:
                boxScore += 4;
                break;
              case RetentionOptions.Days90:
                boxScore += 6;
                break;
            }
            break;
        }

      }

      // every 12 cameras we need a 4TB box
      box4tb = Math.ceil(boxScore / 12);

    } else {
      // every 12 cameras we need a 4TB box
      box4tb = Math.ceil(score / 12);

    }


    return { box4tb, box1u32, box1u64, box1u88 };
  }

  getLocationPricing(location: PricingLocationInfo, term: LicenseTerms): { pricing: Pricing, count: PricingCount } {
    const { numberOfCameras, retention, resolution, numberOfNewCameras } = location;
    const termNum = PricingTermToYears[term];
    let cameraLeft = numberOfCameras;
    let pricing: Pricing = { boxes: 0, license: 0, cameras: 0, total: 0, regularPrice: 0, oneTimePrice: 0, shipping: 0 };
    let count: PricingCount = {
      box2tb: 0,
      box4tb: 0,
      box1u32tb: 0,
      box1u64tb: 0,
      box1u88tb: 0,
      license: resolution === CameraOptions.Fisheye12MP ? numberOfCameras * 2 : numberOfCameras,
      camerasTurret8mp: numberOfNewCameras * (resolution === CameraOptions.Resolution8MP ? 1 : 0),
      camerasTurret5mp: numberOfNewCameras * (resolution === CameraOptions.Resolution5MP ? 1 : 0),
      camerasBullet8mp: 0,
      camerasDome5mp: 0,
      camerasBullet5mp: 0,
      camerasFisheye12mp: 0,
      camerasDome8mp: 0,
      camerasPtz: 0,
      camerasTurret5mpZoom: 0,
      ownCamerasTurret5mp: 0,
      ownCamerasBullet5mp: 0,
      ownCamerasDome5mp: 0,
      ownCamerasTurret8mp: 0,
      ownCamerasBullet8mp: 0,
      ownCamerasDome8mp: 0,
      ownCamerasFisheye12mp: 0,
      ownCamerasPtz: 0,
      ownCamerasTurret5mpZoom: 0,
    };
    let box = 0;
    // while (cameraLeft > 0) {
    //   const item = this.getMaxBoxConfiguration(cameraLeft, retention, resolution);
    //   cameraLeft -= item.maxNumberOfCameras;
    //   box++;
    //
    //   // 2TB box is not a recommendation anymore
    //   const boxPrice = this.getBoxPrice(StorageOptions.Storage4TB, item.device, term);
    //   count.box4tb++;
    //   pricing.boxes += boxPrice * termNum;
    // }
    // pricing.total += pricing.boxes;
    const cameraPrice = this.getCameraPrice(resolution, term);
    pricing.cameras = cameraPrice * numberOfNewCameras * termNum;
    pricing.total += pricing.cameras;

    const licensePrice = this.getSWLicensePrice(term);
    pricing.license = licensePrice * numberOfCameras * termNum;
    pricing.total += pricing.license;
    return { pricing, count };
  }

  public getItemPrice(countField: string, term?: LicenseTerms) {
    switch (countField) {
      case 'box2tb':
        return this.getBoxPrice(StorageOptions.Storage2TB, DeviceOptions.OrinNX16GB, term);
      case 'box4tb':
        return this.getBoxPrice(StorageOptions.Storage4TB, DeviceOptions.OrinNX16GB, term);
      case 'box1u32tb':
        return this.getBoxPrice(StorageOptions.Storage32TB, DeviceOptions.RACK1U32TB, term);
      case 'box1u64tb':
        return this.getBoxPrice(StorageOptions.Storage64TB, DeviceOptions.RACK1U64TB, term);
      case 'box1u88tb':
        return this.getBoxPrice(StorageOptions.Storage88TB, DeviceOptions.RACK1U88TB, term);
      case 'camerasTurret5mp':
      case 'camerasDome5mp':
      case 'camerasBullet5mp':
        return this.getCameraPrice(CameraOptions.Resolution5MP, term);
      case 'camerasTurret8mp':
      case 'camerasDome8mp':
      case 'camerasBullet8mp':
        return this.getCameraPrice(CameraOptions.Resolution8MP, term);
      case 'camerasFisheye12mp':
        return this.getCameraPrice(CameraOptions.Fisheye12MP, term);
      case 'camerasPtz':
        return this.getCameraPrice(CameraOptions.PTZ, term);
      case 'camerasTurret5mpZoom':
        return this.getCameraPrice(CameraOptions.OpticalZoom5MP, term);
      case 'license':
        return this.getSWLicensePrice(term);
      case 'cloudStorage30':
        return 99;
      case 'cloudStorage60':
        return 99 * 2;
      case 'cloudStorage90':
        return 99 * 3;
      case 'cloudStorage180':
        return 99 * 6;
      case 'cloudStorage365':
        return 99 * 12;
      case 'externalStorage30':
        return 19;
      case 'externalStorage60':
        return 19 * 2;
      case 'externalStorage90':
        return 19 * 3;
      case 'externalStorage180':
        return 19 * 6;
      case 'externalStorage365':
        return 19 * 12;
      case 'metadataStorage30':
        return 19;
      case 'metadataStorage60':
        return 19 * 2;
      case 'metadataStorage90':
        return 19 * 3;
      case 'metadataStorage180':
        return 19 * 6;
      case 'metadataStorage365':
        return 19 * 12;
      case 'snapshotStorage30':
        return 9;
      case 'snapshotStorage60':
        return 9 * 2;
      case 'snapshotStorage90':
        return 9 * 3;
      case 'snapshotStorage180':
        return 9 * 6;
      case 'snapshotStorage365':
        return 9 * 12;
      case 'verificationEvents':
        return 1200;
      case 'verificationGuns':
        return 480;
      case 'verificationFire':
        return 36;
      case 'additionalSpeakerLicense':
        return this.getSpeakerLicensePrice(term);
      case 'additionalSensorLicense':
        return this.getSensorLicensePrice(term);
      case 'additionalAppleTvLicense':
        return this.getAppleTvLicensePrice(term);
      case 'additionalAlertStorage':
        return 129;
      case 'additionalEventTagsStorage':
        return 39;
      case 'additionalSpeaker':
        return this.getAddOnPrice(AddOnOptions.Speaker);
      case 'ownCamerasTurret5mp':
      case 'ownCamerasDome5mp':
      case 'ownCamerasBullet5mp':
        return this.getCameraPrice(CameraOptions.Resolution5MP, term, true);
      case 'ownCamerasTurret8mp':
      case 'ownCamerasDome8mp':
      case 'ownCamerasBullet8mp':
        return this.getCameraPrice(CameraOptions.Resolution8MP, term, true);
      case 'ownCamerasFisheye12mp':
        return this.getCameraPrice(CameraOptions.Fisheye12MP, term, true);
      case 'ownCamerasPtz':
        return this.getCameraPrice(CameraOptions.PTZ, term, true);
      case 'ownCamerasTurret5mpZoom':
        return this.getCameraPrice(CameraOptions.OpticalZoom5MP, term, true);
      default:
        return 0;
    }
  }


}
