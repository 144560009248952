import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntegrationsModel } from '@models/integrations.model';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {

  constructor(private http: HttpClient) {
  }

  public slackInstallCallback(slackInstallCallback: IntegrationsModel.SlackInstallCallback): Observable<any> {
    return this.http.post(api.integrations.slack, slackInstallCallback);
  }

  public getIntegrations(orgId: string): Observable<IntegrationsModel.Providers[]> {
    const url = api.integrations.get(orgId);
    return this.http.get<IntegrationsModel.Providers[]>(url);
  }

  public listSlackChannels(orgId: string): Observable<IntegrationsModel.IntegrationListSlackChannelsResponse> {
    const url = api.integrations.listSlackChannels(orgId);
    return this.http.get<IntegrationsModel.IntegrationListSlackChannelsResponse>(url);
  }

  public deactivateSlack(orgId: string) {
    const url = api.integrations.deactivateSlack(orgId);
    return this.http.get(url);
  }

  public installTeam3(orgId: string) {
    const url = api.integrations.installTeam3;
    return this.http.post(url, { orgId });
  }

  public uninstallTeam3(orgId: string) {
    const url = api.integrations.uninstallTeam3;
    return this.http.post(url, { orgId });
  }
}
