import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CameraPulsationState, selectAll } from './camera-heartbeat-pulsation.reducer';
import { PulsationModels } from '@models/pulsation.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { entityStatusByEdgeStatus } from '../../../helpers/common.helpers';
import { selectEdgeStatus } from '@states/device-status/device-status.selectors';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;

export const selectCameraStatusState = createFeatureSelector<CameraPulsationState>('cameraPulsationState');

export const selectAllCamerasStatus = createSelector(selectCameraStatusState, selectAll);

export const selectCameraStatusByCameraId = (cameraId: string) =>
  createSelector(selectCameraStatusState, (cameras: CameraPulsationState) => cameras.entities[cameraId]);

export const selectHeartbeatCameraEntities = createSelector(selectCameraStatusState, (cameras: CameraPulsationState) => cameras.entities);

export const selectCameraStatusByEdgeId = (edgeId: string) =>
  createSelector(selectAllCamerasStatus, cameras => cameras.filter(e => e.edgeId?.toString() === edgeId.toString()));

export const selectCameraEntities = createSelector(selectCameraStatusState, selectEdgeStatus, (cameras: CameraPulsationState, edges: Dictionary<ComponentStatusDisplay>) => {
  const cameraEntitiesIds = Object.keys(cameras.entities);
  const cameraStatusesWithEdgeStatuses: Dictionary<PulsationModels.Store.CameraPulsation> = {};
  cameraEntitiesIds.forEach(cameraId => {
    cameraStatusesWithEdgeStatuses[cameraId] = {
      ...cameras.entities[cameraId],
      status: entityStatusByEdgeStatus(cameraId, edges, cameras),
    };
  });
  return cameraStatusesWithEdgeStatuses;
});

export const selectCameraStatusByCameraIdV2 = (cameraId: string) =>
  createSelector(selectEdgeStatus, selectCameraStatusState, (edges: Dictionary<ComponentStatusDisplay>, cameras: CameraPulsationState) => {
    return entityStatusByEdgeStatus(cameraId, edges, cameras);
  });
