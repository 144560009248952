import { createAction, props } from '@ngrx/store';
import { Edge } from '../../../edge/edge.model';
import { Dictionary } from '@ngrx/entity/src/models';

export const resetToInitialState = createAction('[DHCP] Reset to Initial state');

export const getDhcpConfig = createAction('[DHCP] Get Config');
export const getDhcpConfigFailed = createAction('[DHCP] Get Config Failed');


export const startGetDhcp = createAction('[DHCP] Start Get Address Reservations', props<{ dhcp: Edge.Dhcp, enabled: boolean }>());
export const serverRequestGetDhcp = createAction('[DHCP] Server Request Get Address Reservations', props<{ dhcp: Edge.Dhcp, enabled: boolean }>());
export const serverRequestGetDhcpSuccess = createAction('[DHCP] Server Request Get Address Reservations Success', props<{
  reservedAddresses: Edge.DhcpDevice[],
  attachedAddresses: Edge.DhcpDevice[]
}>());
export const serverRequestGetDhcpFail = createAction('[DHCP] Server Request Get Address Reservations Fail');

export const setGetDhcpLoading = createAction('[DHCP] Get Reserved Addresses Loading', props<{ getDhcpLoading: boolean }>());

export const setEnabled = createAction('[DHCP] Set enable', props<{ enabled: boolean }>());

export const addReservedAddress = createAction('[DHCP] Add Reserved Address', props<{ document: Edge.DhcpDevice }>());
export const discardReservedAddress = createAction('[DHCP] Discard Reserved Address');
export const deleteReservedAddress = createAction('[DHCP] Delete Reserved Address', props<{ macAddress: string }>());

export const updateReservedAddress = createAction('[DHCP] Add Reserved Address', props<{ document: Edge.DhcpDevice, oldMacAddress: string }>());

export const startSaveReservedAddress = createAction('[DHCP] Start Add Reserved Address');
export const serverRequestSaveReservedAddress = createAction('[DHCP] Server Request Add Reserved Address');
export const serverRequestSaveReservedAddressSuccess = createAction('[DHCP] Server Request Add Reserved Address Success', props<{
  reservedAddresses: Edge.DhcpDevice[],
  attachedAddresses: Edge.DhcpDevice[]
}>());
export const serverRequestSaveReservedAddressFail = createAction('[DHCP] Server Request Add Reserved Address Fail');

export const setAddReservedAddressLoading = createAction('[DHCP] Set Add Reserved Address Loading', props<{ addReservedAddressLoading: boolean }>());

export const attachDevice = createAction('[DHCP] Attach device', props<{ device: Edge.DhcpDevice }>());
export const setDhcpForm = createAction('[DHCP] Set dhcp form', props<{ form: Edge.Dhcp }>());

export const setInitialLoaded = createAction('[DHCP] Set initial loaded');
