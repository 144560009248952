import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, concatMap, exhaustMap, forkJoin, map, mergeMap, Observable, share, switchMap, withLatestFrom } from 'rxjs';
import { PeopleService } from '../../development/people.service';
import { PeopleActions } from '@states/people/people.action-types';
import { GetPeopleResponse, GetUnsavedResponse, GroupModels, GroupStatus } from '@models/people.model';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { ActiveOrganization } from '@models/organization.model';
import { PeopleSelectors } from '@states/people/people.selector-types';

@Injectable()
export class PeopleEffect {

  public getPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.getPeople),
      mergeMap(({ status, page, size }) => {
          let op: Observable<GetPeopleResponse | GetUnsavedResponse>;
          switch (status) {
            case GroupStatus.Saved:
              op = this.peopleService.getSavedPeople();
              break;
            case GroupStatus.Unsaved:
              op = this.peopleService.getUnsavedPeople(page, size);
              break;
          }

          return op
            .pipe(
              switchMap(res => {
                if (status === GroupStatus.Saved) {
                  return [SharedActions.setIsLoading({ isLoading: false }), PeopleActions.getPeopleSuccess({ people: res as GetPeopleResponse })];
                } else {
                  return [SharedActions.setIsLoading({ isLoading: false }), PeopleActions.getBucketsSuccess({ buckets: (res as GetUnsavedResponse).persons, token: (res as GetUnsavedResponse).token })];
                }
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public addPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.addPerson),
      withLatestFrom(this.store$.pipe(select(PeopleSelectors.selectPeopleState))),
      exhaustMap(([{ person }, { token }]) => {
          return this.peopleService.addPerson(person, token)
            .pipe(
              switchMap(res => {
                return [PeopleActions.resetUnsaved(), PeopleActions.getPeople({ status: GroupStatus.Unsaved }), PeopleActions.getPeople({ status: GroupStatus.Saved })];
                // return [PeopleActions.addPersonSuccess({person: {
                //   ...person,
                //     personId: res
                //   }})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.editPerson),
      mergeMap(({ person }) => {

          return this.peopleService.editPerson(person)
            .pipe(
              switchMap(res => {

                return [PeopleActions.editPersonSuccess({ person })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removePerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.removePerson),
      mergeMap(({ person }) => {

          return this.peopleService.removePerson(person)
            .pipe(
              switchMap(res => {

                return [PeopleActions.removePersonSuccess({ personId: res }), PeopleActions.getPeople({ status: GroupStatus.Unsaved })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  UploadFaceAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAssets),
      exhaustMap(({ request, multiple }) => {
        return [
          SharedActions.setIsSaving({ isSaving: true }),
          PeopleActions.UploadFaceAssetsSend({
            request,
            multiple,
          }),
        ];
      }),
    ),
  );

  uploadFaceAssetsSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAssetsSend),
      withLatestFrom(this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization))),
      map(([{ request, multiple }, org]) => {
        return {
          request: request,
          org,
          multiple,
        };
      }),
      switchMap((data: { request: GroupModels.FileAsset[], org: ActiveOrganization, multiple: boolean }) => {
        const orgId = data.org.orgId;

        const presignedReqeust$ = data.request.map(e => this.peopleService.getFaceAssetPreSignedUrl({ asset: e.asset }, e.file));
        const presignedResponse$ = forkJoin(presignedReqeust$);

        return presignedResponse$
          .pipe(
            mergeMap(res => {

              const uploadReqeust$ = res.map(e => this.peopleService.uploadFaceAssetPresignedUrl({ url: e.url, file: e.file, filename: e.filename }));
              const uploadResponse$ = forkJoin(uploadReqeust$);

              return uploadResponse$;
            }),
            concatMap(uploadResponses => {
              return this.peopleService.learn({
                fileUrls: data.request.map(e => e.asset.filename),
                multiple: data.multiple,
              });
            }),
            switchMap(response => {
              return [PeopleActions.UploadFaceAssetsSendSuccess({ response })];
            }),
            catchError(err => {
              return [PeopleActions.UploadFaceAssetsSendError()];
            }),
          );

        return [];
      }),
      share(),
    ),
  );

  uploadFaceAssetsSearchSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAssetsSearchSend),
      switchMap((data: { request: GroupModels.SearchFaceRequest }) => {
        return this.peopleService.search(data.request)
          .pipe(
            switchMap(res => {
              return [PeopleActions.UploadFaceAssetsSearchSendSuccess({ response: res })];
            }),
          );

        return [];
      }),
      share(),
    ),
  );

  uploadFaceAssetsSendSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAssetsSendSuccess),
      mergeMap(({ response }) => {
        if (response.code === -1) {
          return [SharedActions.setIsSaving({ isSaving: false })];
        }
        const searchRequest: GroupModels.SearchFaceRequest = {
          descriptor: response.faceId,
          faceConfidence: response.faceConfidence,
        };
        return [
          // PeopleActions.UploadFaceAssetsSearchSend({request: searchRequest}),
          SharedActions.setIsSaving({ isSaving: false }),
        ];
      }),
      share(),
    ),
  );

  uploadFaceAssetsSendError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAssetsSendError),
      mergeMap(() => {
        return [
          SharedActions.showMessage({ error: 'Failed to upload image' }),
        ];
      }),
      share(),
    ),
  );

  uploadFaceCreateSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceCreateSend),
      switchMap(({ request }) => {
        return this.peopleService.create(request)
          .pipe(
            switchMap(res => {
              return [PeopleActions.UploadFaceCreateSendSuccess()];
            }),
          );

        return [];
      }),
      share(),
    ),
  );

  uploadFaceAttachSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeopleActions.UploadFaceAttachSend),
      switchMap(({ request }) => {
        return this.peopleService.attach(request)
          .pipe(
            switchMap(res => {
              return [PeopleActions.UploadFaceAttachSendSuccess()];
            }),
          );

        return [];
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private peopleService: PeopleService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

}
