import { Injectable } from '@angular/core';
import { HttpService } from '../../../core/http.service';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';

import { SQSMsgInfo } from "../../../core/interfaces";
import { StorageModel } from "@models/storage.model";

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private httpService: HttpService) {
  }

  getStorageStats(data: StorageModel.GetStorageStatsRequest): Observable<SQSMsgInfo> {
    const url = api.playback.storage;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }
  
}
