import { createAction, props } from '@ngrx/store';
import { Edge } from '../../../edge/edge.model';

export const resetToInitialState = createAction('[CERTIFICATIONS] Reset to Initial state');

export const startGetCertifications = createAction('[CERTIFICATIONS] Start get certifications');
export const getCertifications = createAction('[CERTIFICATIONS] Get certifications');
export const getCertificationsSuccess = createAction('[CERTIFICATIONS] get certifications Success', props<{ entities: Edge.EdgeCertificationManageDocument[] }>());
export const getCertificationsFail = createAction('[CERTIFICATIONS] get certifications Fail');

export const setCertificationsLoading = createAction('[CERTIFICATIONS] Set Certifications Loading', props<{ isLoading: boolean }>());


export const updateEdgeWithExistsCert = createAction('[CERTIFICATIONS] updateEdgeWithExistsCert', props<{ id: string }>());
export const updateEdgeWithExistsCertSuccess = createAction('[CERTIFICATIONS] updateEdgeWithExistsCert Success');
export const updateEdgeWithExistsCertFail = createAction('[CERTIFICATIONS] updateEdgeWithExistsCert Fail');


export const startValidateExistsCert = createAction('[CERTIFICATIONS] start validateExistsCert', props<{ id: string }>());
export const validateExistsCert = createAction('[CERTIFICATIONS] validateExistsCert', props<{ id: string }>());
export const validateExistsCertSuccess = createAction('[CERTIFICATIONS] validateExistsCert Success');
export const validateExistsCertFail = createAction('[CERTIFICATIONS] validateExistsCert Fail');
export const setValidationLoading = createAction('[CERTIFICATIONS] set validation loading', props<{ validationLoading: boolean }>());
