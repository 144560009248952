import { Component, Inject, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

;
import { Person, GroupStatus, Buckets } from '@models/people.model';
import { Observable, take } from 'rxjs';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { PeopleService } from '../../../development/people.service';
import { SharedActions } from '@states/shared/shared.action-types';
import { PeopleActions } from '@states/people/people.action-types';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { selectToken } from "@states/people/people.selectors";

export interface UiPersonDialogData {
  person?: Person;
  toExisting?: boolean;
  selector?: boolean;
  list?: Person[];
}

@UntilDestroy()
@Component({
  selector: 'app-ui-person-dialog',
  templateUrl: './ui-person-dialog.component.html',
  styleUrls: ['./ui-person-dialog.component.scss'],
})
export class UiPersonDialogComponent implements OnInit {

  public noSaved = features.people.noSaved;

  public PreloaderColor = PreloaderColor;
  public selectSavedPeople$: Observable<Person[]> = this.store$.pipe(select(PeopleSelectors.selectSavedPeople));
  public selectToken$: Observable<string> = this.store$.pipe(select(PeopleSelectors.selectToken));

  public selectedPersonId: number;
  public selectedPersonIds = [];
  loaded = false;
  img;

  public query = '';

  public loading = false;

  public token;

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private store$: Store,
    @Inject(MAT_DIALOG_DATA) public data: UiPersonDialogData,
    private peopleService: PeopleService,
    private dialogRef: MatDialogRef<UiPersonDialogComponent>) {
  }

  ngOnInit(): void {
    if (this.data?.person) {
      const person = this.data?.person;
      this.img = this.peopleService.getPersonImagePath(person);
      if (person.name) {
        this.form.patchValue({name: person.name});
      }
    }
    if (this.data.toExisting) {
      this.store$.dispatch(PeopleActions.getPeople({status: GroupStatus.Saved}));
    }
    this.selectToken$.pipe(take(1)).subscribe(token => {
      this.token = token;
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.data.toExisting) {
      if (this.data.selector) {
        const selectedPeople = this.selectedPersonIds.map(personId => {
          const person: Person = {
            personId,
          };
          return person;
        });

        this.dialogRef.close(selectedPeople);
      } else {
        this.peopleService.mergePeople(this.data.person.bucketId, this.selectedPersonId, this.token)
          .subscribe(_ => {
            this.loading = true;
            this.store$.dispatch(SharedActions.showMessage({success: 'Person merged successfully'}));
            this.store$.dispatch(PeopleActions.resetUnsaved());
            this.store$.dispatch(PeopleActions.getPeople({status: GroupStatus.Saved}));
            this.store$.dispatch(PeopleActions.getPeople({status: GroupStatus.Unsaved, page: 0, size: 10}));
            this.dialogRef.close(this.selectedPersonId);
          });
      }
    } else {
      this.form.markAsTouched();
      if (this.form.invalid) {
        return;
      }
      this.dialogRef.close(this.form.value.name);
    }
  }

  public ngOnDestroy(): void {
  }

  select(id: number) {
    if (this.data.selector) {
      if (this.selectedPersonIds.includes(id)) {
        this.selectedPersonIds.splice(this.selectedPersonIds.indexOf(id), 1);
      } else {
        this.selectedPersonIds.push(id);
      }
      return;
    }
    this.selectedPersonId = id;
  }

  public filtered(people: Person[]) {
    return people.filter(p => p.name.toLowerCase()
      .includes(this.query.toLowerCase()));
  }

  public exists(id: number) {
    return !this.data.list.map(p => p.personId)
      .includes(id);
  }

}
