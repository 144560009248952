import { ArchiveTimestampLocation } from '@enums/archive.enum';
import { ArchiveModel, ArchiveResponseFilter } from '@models/archive.model';
import * as moment from 'moment';
import { ArchiveStatus } from '@models/archive.model';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

export const archiveTimestampLocationLookup: { [Property in ArchiveTimestampLocation] } = {
  [ArchiveTimestampLocation.topLeft]: 'Top Left',
  [ArchiveTimestampLocation.topRight]: 'Top Right',
  [ArchiveTimestampLocation.bottomLeft]: 'Bottom Left',
  [ArchiveTimestampLocation.bottomRight]: 'Bottom Right',
};

export const archiveTimestampLocationsArray = [
  ArchiveTimestampLocation.topLeft,
  ArchiveTimestampLocation.topRight,
  ArchiveTimestampLocation.bottomLeft,
  ArchiveTimestampLocation.bottomRight,
];

const dateRangeInitial = {
  start: moment()
    .subtract(2, 'weeks')
    .toString(),
  end: moment()
    .hours(23)
    .minutes(59)
    .toString(),
};


export const initialArchive: ArchiveModel = {
  name: 'Untitled archive',
  selectedCamera: null,
  tags: [],
  timestampLocation: ArchiveTimestampLocation.bottomRight,
  blurFaces: false,
  start: moment()
    .subtract(5, 'minutes')
    .toString(),
  end: moment()
    .subtract(3, 'minutes')
    .toString(),
  timezone: null,
};

export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.weeks, value: 2 } };

export const initialArchiveFilter: ArchiveResponseFilter = {
  query: '',
  selectedCameras: {},
  tags: [],
  dateRange: null,
  status: [],
};

export const archiveStatusLookup: { [Property in ArchiveStatus] } = {
  [ArchiveStatus.COMPLETED]: 'Completed',
  [ArchiveStatus.DELETED]: 'Deleted',
  [ArchiveStatus.UPLOADING]: 'Uploading',
  [ArchiveStatus.ERROR]: 'Error',
};

export const archiveStatusIconLookup: { [Property in ArchiveStatus] } = {
  [ArchiveStatus.COMPLETED]: 'status-online',
  [ArchiveStatus.DELETED]: 'DELETED',
  [ArchiveStatus.UPLOADING]: 'UPLOADING',
  [ArchiveStatus.ERROR]: 'status-offline',
};

export const ARCHIVE_ON_ERROR_MIN = 15;
