import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ThumbnailHistogramColorRGBA } from '@consts/thumbnail.const';

const MAX_EVENTS = 15;

@Component({
  selector: 'app-events-histogram',
  templateUrl: './events-histogram.component.html',
  styleUrls: ['./events-histogram.component.scss'],
})
export class EventsHistogramComponent implements OnInit, AfterViewInit, OnChanges {

  public ThumbnailHistogramColorRGBA: typeof ThumbnailHistogramColorRGBA = ThumbnailHistogramColorRGBA;
  /**
   * Canvas related variables
   */
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('statsCanvas', { static: true })
  statsCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('wrapper')
  wrapper: ElementRef;

  private ctx: CanvasRenderingContext2D;

  @Input()
  color: number;

  @Input()
  events: number[] = [];

  @Input()
  storageStats: number[] = [];

  @Input()
  searchEvents: number[][];

  @Input()
  rtl = false;

  @Input()
  startTs;

  @Input()
  endTs;

  @Input() vertical = true;

  @Input() parent: HTMLDivElement;

  @Input() alertPreview = false;

  constructor() {
  }

  calcSearchEventIdx(searchEvent: number[]): { startIdx: number; endIdx: number } {
    // const avg = Math.abs((searchEvent[0] + searchEvent[1]) / 2);
    let startIdx = Math.ceil((searchEvent[0] - this.startTs) / 2000);
    let endIdx = Math.ceil((searchEvent[1] - this.startTs) / 2000);
    if (startIdx === endIdx) {
      endIdx += 1;
    }
    return { startIdx, endIdx }; // assuming 2000ms is the duration between thumbnails
  }

  drawSearchEvents() {
    const canvas = this.canvas.nativeElement;
    const length = (this.endTs - this.startTs) / 2000;
    const drawArray = new Array(length).fill(0);
    for(let searchEvent of this.searchEvents) {
      let idx = this.calcSearchEventIdx(searchEvent);
      drawArray.fill(1, idx.startIdx, idx.endIdx);
    }
    for(let [idx, d] of drawArray.entries()) {
      if (!!d) {
        this.ctx.fillStyle = '#12b76a';
        this.ctx.beginPath();
        const x = (idx / length) * canvas.width;
        const width = canvas.width / length;
        this.ctx.setTransform(1, 0, 0, -1, 0, canvas.height);
        if (this.vertical) {
          this.ctx.fillRect(this.rtl ? canvas.width - x - width : x, 0, width + 0.5, canvas.height / 4);
        } else {
          this.ctx.fillRect(this.rtl ? canvas.width - x - width : x, canvas.height / 6, width + 0.5, canvas.height / 1.5);
        }
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    }
  }

  drawEvents(stats = false) {
    if (this.searchEvents?.length) {
      return;
    }
    const canvas = this.canvas.nativeElement;
    this.ctx = this.canvas.nativeElement.getContext('2d')!;
    const events = this.events;
    const statsEvents = this.storageStats;
    const length = events?.length;

    if (!this.searchEvents && (!events || length === 0)) {
      this.ctx.fillStyle = '#101828';
      if (this.vertical) {
        this.ctx.setTransform(1, 0, 0, -1, 0, canvas.height);
        this.ctx.fillRect(0, 0, canvas.width, canvas.height / 4);
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      } else {
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
      return;
    }

    let sumEvents = 0;
    // Calculate total length
    for(let eventIdx = 0; eventIdx < events.length; eventIdx++) {
      const event = events[eventIdx];
      sumEvents += event ? event : 1;
    }

    const w = canvas.width / sumEvents;
    this.ctx.setTransform(1, 0, 0, -1, 0, canvas.height);

    let accumulateEvents = 0;
    for(let eventIdx = 0; eventIdx < events.length; eventIdx++) {
      const event = events[eventIdx];
      const statEvent = statsEvents[eventIdx];
      accumulateEvents += event ? event : 1;
      // const x = w * accumulateEvents;
      let opacity = 0;
      let half = 0;
      if (events.length < 500) {
        //
        opacity = event === 1 ? 0 : 0.5 + (event / MAX_EVENTS) * 0.5;
      } else {
        opacity = event === 1 ? 0 : 1;
        half = canvas.width * 0.001;
      }
      if (event === 0) {
        this.ctx.fillStyle = '#101828';
      } else {
        if (this.vertical) {
          this.ctx.fillStyle = `#9BACF2`;
        } else {
          if (opacity) {
            this.ctx.fillStyle = this.color ? `${ThumbnailHistogramColorRGBA[this.color]}, ${opacity})` : `rgba(67, 103, 246, ${opacity})`;
          } else {
            this.ctx.fillStyle = `rgba(67, 103, 246, 0)`;
          }
        }

      }
      const x = (eventIdx / length) * canvas.width;
      const width = canvas.width / length;
      const calcX = this.rtl ? canvas.width - x - width : x;
      // this.ctx.fillRect(this.rtl ? canvas.width - x - width : x + width, canvas.height / 6, width + 0.5, canvas.height)

      if (this.vertical) {
        if (event > 1 || event === 0 || stats) {
          this.ctx.fillRect(calcX, !!statEvent || statEvent === 0 ? canvas.height / 4 : 0,
            events.length < 1000 ? w * (event ? event : 1) : w * (event ? event : 1) + half * 2,
            Math.min(canvas.height, event >= 4 ? canvas.height - 20 : canvas.height / 4 * Math.max(event, 1)));
        }
        if (!!statEvent || statEvent === 0) {
          if (statEvent === 0) {
            this.ctx.fillStyle = `#101828`;
          } else if (statEvent === 1) {
            this.ctx.fillStyle = `#E1E6FA`;
          } else {
            this.ctx.fillStyle = `#748CED`;
          }
          this.ctx.fillRect(calcX, 0, events.length < 1000 ? w * (event ? event : 1) : w * (event ? event : 1) + half * 2, Math.min(canvas.height, canvas.height / 4 * Math.max(event, 1)));
        }
      } else {
        this.ctx.fillRect(calcX, 0, events.length < 1000 ? w * (event ? event : 1) : w * (event ? event : 1) + half * 2, canvas.height);
      }

    }
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // this.drawEvents();
    // if (this.searchEvents) {
    //   this.drawSearchEvents();
    // }
  }

  clear() {
    const canvas = this.canvas.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const canvas = this.canvas.nativeElement;
    if (!canvas) {
      return;
    }
    this.ctx = this.canvas.nativeElement.getContext('2d')!;
    if (changes['parent']) {
      this.canvas.nativeElement.width = this.parent.clientWidth;
    }
    this.clear();
    if (this.events) {
      this.drawEvents();
    }
    if (this.storageStats?.length) {
      // this.drawEvents(true);
    }
    if (this.searchEvents) {
      this.drawSearchEvents();
    }
  }

  public redraw() {
    this.canvas.nativeElement.width = this.parent.clientWidth;
    if (this.events) {
      this.drawEvents();
    }
    if (this.storageStats?.length) {
      // this.drawEvents(true);
    }
    if (this.searchEvents) {
      this.drawSearchEvents();
    }
  }
}
