<div fxLayout="row wrap" fxLayoutAlign="start center">
  <mat-chip-set>
    <ui-chip-select *ngFor="let chip of chips"
                    [value]="chip.value"
                    [selected]="isSelected(chip.value)"
                    (selectChange)="chipsSelected($event)"
    >{{
        chip.name
      }}
    </ui-chip-select>
  </mat-chip-set>
</div>
