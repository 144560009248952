import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, share, switchMap, withLatestFrom } from 'rxjs';
import { UserDeviceActions } from '@states/device/device.action-types';
import { UserDeviceService } from '../../services/user-device.service';
import { toggleEnableDevice } from '@states/device/device.actions';
import { SharedActions } from '@states/shared/shared.action-types';

@Injectable()
export class UserDeviceEffects {

  public getUserDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDeviceActions.getUserDevices),
      switchMap(() => {
        return this.userDeviceService
          .getAll()
          .pipe(
            switchMap(res => {
              return [
                UserDeviceActions.getUserDevicesSuccess({ documents: res }),
              ];
            }),
            catchError(response => {
              return [
                UserDeviceActions.getUserDevicesFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public toggleEnableDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDeviceActions.toggleEnableDevice),
      switchMap(({ enabled, deviceUid }) => {
        return this.userDeviceService
          .update(deviceUid, { enabled })
          .pipe(
            switchMap(res => {
              return [
                UserDeviceActions.toggleEnableDeviceSuccess(),
              ];
            }),
            catchError(response => {
              return [
                UserDeviceActions.toggleEnableDeviceFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public muteDeviceUntil$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDeviceActions.muteDeviceUntil),
      switchMap(({ mutedUntil, deviceUid }) => {
        return this.userDeviceService
          .update(deviceUid, { mutedUntil })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Device has been updated' }),
                UserDeviceActions.muteDeviceUntilSuccess(),
              ];
            }),
            catchError(response => {
              return [
                UserDeviceActions.muteDeviceUntilFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private userDeviceService: UserDeviceService) {
  }
}
