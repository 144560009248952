import { UserSettings } from '@models/user-settings';
import { createReducer, on } from '@ngrx/store';
import * as UserSettingsActions from '@states/user-settings/user-settings.actions';
import { initialUserSettings } from '@consts/user-settings.const';

export interface UserSettingsState {
  userSettings: UserSettings;
  isValid: boolean;
}

export const initialState: UserSettingsState = {
  userSettings: initialUserSettings,
  isValid: false,
};

export const userSettingsStateReducer = createReducer(
  initialState,
  on(UserSettingsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(UserSettingsActions.setUserSettings, (state, {userSettings}) => {
    return {
      ...state,
      userSettings: {
        ...initialUserSettings,
        ...userSettings,
      },
    };
  }),
  on(UserSettingsActions.setUserSettingProperty, (state, {prop, value}) => {
    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        [prop]: value,
      },
    };
  }),
  on(UserSettingsActions.setValidateStatus, (state, {isValid}) => {
    return {
      ...state,
      isValid,
    };
  }),
);
