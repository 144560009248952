import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GeneralActions from '@states/general/general.actions';
import { catchError, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { AppState } from '../app.state';
import { GeneralService } from '@states/general/general.service';
import * as SharedActions from '@states/shared/shared.actions';

@Injectable()
export class GeneralEffect {
  constructor(private actions$: Actions, private store$: Store<AppState>, private generalService: GeneralService) {}

  public getGeneralFromBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getGeneralFromBackend),
      switchMap(() => {
        return this.generalService.getGeneralSettings().pipe(
          map(res => {
            return GeneralActions.setGeneral({ generalState: res });
          }),
          catchError(err => {
            return of(SharedActions.showMessage({ error: err.message || 'Error loading general state' }));
          })
        );
      })
    )
  );
}
