import { Component, Inject, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

;

@Component({
  selector: 'app-set-name-modal',
  templateUrl: './set-name-modal.component.html',
  styleUrls: ['./set-name-modal.component.scss'],
})
export class SetNameModalComponent implements OnInit {
  public inputStyles = UIInputStyle;

  public name: string;
  public modalTitle: string = 'Save as wall';

  constructor(private dialogRef: MatDialogRef<SetNameModalComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                name: string,
                inputLabel: string,
                modalTitle: string
              }) {
    if (data?.name) {
      this.name = data.name;
    }
    if (data?.modalTitle) {
      this.modalTitle = data.modalTitle;
    }
  }

  ngOnInit(): void {
  }


  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (!this.name) {
      this.dialogRef.close('Untitled wall');
    } else {
      this.dialogRef.close(this.name);
    }
  }

}
