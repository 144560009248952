import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as OrganizationActions from '@states/organization/organization.actions';
import * as SettingsActions from '@states/settings/settings.actions';
import * as InviteActions from '@states/invite/invite.actions';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, debounceTime, exhaustMap, mergeMap, of, share, switchMap } from 'rxjs';
import { OrganizationService } from '../../development/organization.service';
import { withLatestFrom } from 'rxjs/operators';
import { LiveViewType, MobileLocalLiveViewType, UserOrganizationDropDown } from '@models/organization.model';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { routerSegments } from '@consts/routes';
import { DevTeamActions } from '@states/dev-team/dev-team.action-types';
import { SocketEvents } from 'src/app/socket/socket.model';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { InvitationStatus } from '@enums/invite.enum';
import { OrganizationsActions } from '@states/organization/organization.actions-types';
import { UserActions } from '@states/user/user.action-types';
import { UserSelectors } from '@states/user/user.selector-types';
import { HttpStatusCode } from '@angular/common/http';
import { NotFoundTemplates } from '@enums/shared.enum';
import { SocketMainService } from 'src/app/socket/socket-main.service';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { renewToken } from '@states/user/user.actions';

@Injectable()
export class OrganizationEffect {

  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(OrganizationActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createOrganization),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), OrganizationActions.sendOrganization()]),
    ),
  );

  public saveOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.saveOrganization),
      exhaustMap(({ orgName, orgEmail }) => [SharedActions.setIsSaving({ isSaving: true }), OrganizationActions.sendOrganization()]),
    ),
  );

  public sendOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.sendOrganization),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile)), this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { email, _id }, { createdOrganization, createdOrganizationInvitedUsers }]) => {
        const invitesLookup = {};
        createdOrganizationInvitedUsers.forEach(item => {
          const email = Object.keys(item)[0];
          invitesLookup[email] = Object.values(item)[0];
        });
        return this.organizationService
          .saveOrganization({
            organization: {
              orgEmail: createdOrganization.orgEmail,
              orgName: createdOrganization.orgName,
              userId: _id,
              userEmail: email,
            },
            invites: invitesLookup,
          })
          .pipe(
            switchMap(res => {
              return [
                // todo move to interceptor
                SharedActions.showMessage({
                  success: 'Organization has been created',
                }),
                OrganizationActions.saveOrganizationSuccess({
                  orgName: res.orgName,
                  orgId: res.orgId,
                }),
                UserActions.UpdateUserProfileSuccess({ payload: res.user }),
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                OrganizationActions.getUserOrganizations(), //update dropdown
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public switchTenantOnCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.switchTenant),
      switchMap(({ tenantId }) => {
        this.authenticationService.switchTenant(tenantId, true);
        return of(SharedActions.doNothing());
      }),
    ),
  );


  public getActiveOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getActiveOrganization),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([, { _id }]) => {
        return this.organizationService.getActiveOrganization()
          .pipe(
            switchMap(res => {
              return !res.isDefaultOrganization
                ? [
                  OrganizationActions.setActiveOrganization({
                    activeOrganization: {
                      orgId: res.activeOrganization._id,
                      orgName: res.activeOrganization.settings.orgName,
                      orgEmail: res.activeOrganization.settings.orgEmail,
                      orgColor: res.activeOrganization.settings.orgColor,
                      roles: res.activeOrganization.users[_id].roles,
                      mfa: res.activeOrganization.settings.mfa,
                      ipRules: res.activeOrganization.settings.ipRules,
                      videoQuality: res.activeOrganization.settings.videoQuality,
                      localLiveStream: res?.activeOrganization?.settings?.localLiveStream ?? false,
                      webSocketPlayback: res?.activeOrganization?.settings?.webSocketPlayback ?? false,
                      forceWebrtcRelay: res?.activeOrganization?.settings?.forceWebrtcRelay ?? false,
                      liveViewType: res?.activeOrganization?.settings?.liveViewType ?? LiveViewType.Webrtc,
                      showCores: res?.activeOrganization?.settings?.showCores ?? true,
                      mobileLocalLiveViewType: res?.activeOrganization?.settings?.mobileLocalLiveViewType ?? MobileLocalLiveViewType.Hls,
                      logoutWhenBrowserClosed: res.activeOrganization.settings.logoutWhenBrowserClosed,
                    },
                  }),
                  OrganizationActions.emitJoinActiveOrganizationEvent({
                    activeOrganization: {
                      orgId: res.activeOrganization._id,
                      orgName: res.activeOrganization.settings.orgName,
                      orgEmail: res.activeOrganization.settings.orgEmail,
                      orgColor: res.activeOrganization.settings.orgColor,
                      roles: res.activeOrganization.users[_id].roles,
                      mfa: res.activeOrganization.settings.mfa,
                      ipRules: res.activeOrganization.settings.ipRules,
                      videoQuality: res.activeOrganization.settings.videoQuality,
                      logoutWhenBrowserClosed: res.activeOrganization.settings.logoutWhenBrowserClosed,
                    },
                  }),
                  SharedActions.setApplicationLoaded(),
                ]
                : [
                  OrganizationActions.setIsDefaultOrganization({
                    isDefaultOrganization: res.isDefaultOrganization,
                  }),
                  SharedActions.setApplicationLoaded(),
                ];
            }),
            catchError(response => {
              return [
                AuthenticationActions.Logout({ reason: 'Get active Organization failed' }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getOrganizationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationUsers),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization, page, perPage, query, orderBy, orderDirection }]) => {
        if (!activeOrganization) {
          return [OrganizationActions.getOrganizationUsersFail()];
        }
        return this.organizationService.getOrganizationUsers(page, perPage, orderBy, orderDirection, query)
          .pipe(
            switchMap(res => {
              return [
                OrganizationActions.setOrganizationUsers({ users: res.users }),
                OrganizationActions.setTotalItemsCount({
                  totalItemsCount: res.totalItems,
                }),
              ];
            }),
            catchError(response => {
              return [OrganizationActions.getOrganizationUsersFail()];
            }),
          );
      }),
      share(),
    ),
  );


  public updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateActiveOrganization),
      exhaustMap(({ organization }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        OrganizationActions.updateOrganizationServer({ toUpdate: organization }),
      ]),
    ),
  );

  public updateOrganizationServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganizationServer),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([{ toUpdate }, { activeOrganization }]) => {
        const isAutoLogoutChanged = typeof toUpdate?.logoutWhenBrowserClosed !== 'undefined' ? activeOrganization.logoutWhenBrowserClosed !== toUpdate.logoutWhenBrowserClosed : false;
        return this.organizationService.updateOrganization(activeOrganization.orgId, toUpdate)
          .pipe(
            switchMap(res => {
              const actions: Action[] = [];
              if (isAutoLogoutChanged) {
                actions.push(UserActions.renewToken({ orgId: activeOrganization.orgId }));
              }
              return [
                ...actions,
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({
                  success: 'Organization has been updated',
                }),
                OrganizationActions.getUserOrganizations(),
                OrganizationActions.updateActiveOrganizationSuccess({
                  activeOrganization: toUpdate,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getUserOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getUserOrganizations),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([, { _id, authProviderId }]) => {
        return this.organizationService.getUserOrganizations(_id)
          .pipe(
            switchMap(res => {
              const defaultOrg: UserOrganizationDropDown = {
                name: 'default',
                id: authProviderId.split('|')[1],
                logo: null,
                color: '#73d306',
                isDeletePermission: false,
              };
              return [
                OrganizationActions.getUserOrganizationsSuccess({
                  organizations: res,
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getInvitesByOrgId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.getInvitesByOrgId),
      withLatestFrom(this.store$.pipe(select(state => state.inviteState))),
      switchMap(([, { query }]) => {
        return this.organizationService.getInvitesByOrganization(query, { status: [InvitationStatus.pending] })
          .pipe(
            switchMap(res => {
              return [
                InviteActions.getInvitesByOrgIdSuccess({
                  invites: res,
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.removeUser),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([{ userId }, { activeOrganization }]) => {
        return this.organizationService.removeUser(userId, activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'User has been deleted' }),
                OrganizationActions.removeUserSuccess({ userId }),
                OrganizationActions.getOrganizationUsers(),//todo remove
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public blockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.blockUser),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([{ userId }, { activeOrganization }]) => {
        return this.organizationService.blockUser(userId, activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'User has been blocked' }),
                OrganizationActions.blockUserSuccess(),
                OrganizationActions.getOrganizationUsers(),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  //todo move to invite effect

  public resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.resendInvite),
      switchMap(({ inviteId }) => {
        return this.organizationService.resend(inviteId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Invite has been sent' }),
                InviteActions.resendInviteSuccess(),
                InviteActions.getInvitesByOrgId(),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public cancelInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.cancelInvite),
      exhaustMap(({ inviteId }) => [
        SharedActions.setIsLoading({ isLoading: true }), //loader on
        InviteActions.cancelInviteServer({ inviteId }), //make request
      ]),
    ),
  );

  public cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.cancelInviteServer),
      switchMap(({ inviteId }) => {
        return this.organizationService.cancel(inviteId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Invite has been canceled',
                }),
                InviteActions.cancelInviteSuccess(),
                InviteActions.getInvitesByOrgId(),
                InviteActions.getActiveInvites(),
                SharedActions.setIsLoading({ isLoading: false }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getActiveInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.getActiveInvites),
      switchMap(() => {
        return this.organizationService.getActive()
          .pipe(
            switchMap(res => {
              if (res) {
                const notAcceptedInvite = sessionStorage.getItem('invite');
                const inviteIndex = res?.findIndex(invite => notAcceptedInvite === invite._id);
                if (inviteIndex > -1) {
                  const invite = res[inviteIndex];
                  sessionStorage.setItem('invite', null);
                  return [InviteActions.acceptInvite({ id: invite._id })];
                }
              }

              return [InviteActions.getActiveInvitesSuccess({ invites: res })];
            }),
            catchError(response => {
              return [SharedActions.doNothing()];
            }),
          );
      }),
      share(),
    ),
  );

  public acceptInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.acceptInvite),
      exhaustMap(({ id }) => [
        SharedActions.setIsLoading({ isLoading: true }), //loader on
        InviteActions.sendAcceptInvite({ id }), //make request
      ]),
    ),
  );

  public sendAcceptInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.sendAcceptInvite),
      exhaustMap(({ id }) => {
        return this.organizationService.accept(id)
          .pipe(
            switchMap(res => {
              if (res?.orgId) {
                this.authenticationService.switchTenant(res.orgId);
                return [InviteActions.sendAcceptSuccess()];
              }
              return [
                // SharedActions.showMessage({
                //   success: 'Invite has been accepted',
                // }),
                InviteActions.sendAcceptSuccess(),
                SharedActions.setIsLoading({ isLoading: false }),
                OrganizationActions.getUserOrganizations(),
                InviteActions.getActiveInvites(),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public setOrganizationUserSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.setQuery),
      debounceTime(400),
      switchMap(() => of(OrganizationActions.getOrganizationUsers())),
    ),
  );

  public setOrganizationUserOrderParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.setOrderParams),
      debounceTime(400),
      switchMap(() => of(OrganizationActions.getOrganizationUsers())),
    ),
  );

  public checkIfOrgNameExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.checkIfOrgNameExist),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      debounceTime(400),
      switchMap(([{ name }, { activeOrganization }]) => {
        if (name === activeOrganization?.orgName) {
          return of(SharedActions.doNothing());
        }
        if (!name) {
          return of(SettingsActions.setValidation({ isValid: false }));
        }
        return [
          OrganizationActions.setOrganizationNameCheckLoader({ loading: true }),
          OrganizationActions.checkIfOrgNameExistOnServer({
            name,
          }),
        ];

      }),
    ),
  );

  public checkIfOrgEmailExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.checkIfOrgEmailExist),
      debounceTime(400),
      switchMap(({ email }) => {
        if (!email) {
          return of(SharedActions.doNothing());
        }
        return of(
          OrganizationActions.checkIfOrgEmailExistOnServer({
            email,
          }),
        );
      }),
    ),
  );


  public updateOrganizationLogo = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganizationLogo),
      switchMap(({ orgId, file }) => {
        return this.organizationService.updateOrganizationLogo(orgId, file)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Logo has been updated',
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public checkIfOrgEmailOrNameExistOnServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.checkIfOrgNameExistOnServer),
      switchMap(({ name }) => {
        return this.organizationService.checkIfOrgNameExist(name)
          .pipe(
            switchMap(res => {
              return [
                OrganizationActions.setOrganizationNameExist({
                  organizationNameExist: res,
                }),
                SettingsActions.setValidation({ isValid: !res }),
                OrganizationActions.setOrganizationNameCheckLoader({ loading: false }),
              ];
            }),
            catchError(response => {
              return [
                OrganizationActions.setOrganizationNameCheckLoader({ loading: false }),
                this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public checkIfOrgEmailExistOnServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.checkIfOrgEmailExistOnServer),
      exhaustMap(({ email }) => {
        return this.organizationService.checkIfOrgEmailExist(email)
          .pipe(
            switchMap(res => {
              return [
                OrganizationActions.setOrganizationEmailExist({
                  organizationEmailExist: res,
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public checkIfActiveOrgIsDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.setIsDefaultOrganization),
      withLatestFrom(this.store$.pipe(select(state => state.inviteState))),
      switchMap(([{ isDefaultOrganization }, { invites }]) => {
        if (isDefaultOrganization) {
          this.router.navigate([`${routerSegments.selectOrganization}`]);
        }
        return of(SharedActions.doNothing());
      }),
      share(),
    ),
  );

  public deleteOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deleteOrg),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      mergeMap(([{ orgId }, { activeOrganization }]) =>
        this.organizationService.delete(orgId)
          .pipe(
            switchMap(res => {
              const actions: Action[] = [
                SharedActions.showMessage({ success: 'Organization has been removed' }),
              ];
              if (orgId === activeOrganization.orgId) {
                actions.push(AuthenticationActions.Logout({ reason: null }));
              } else {
                actions.push(OrganizationActions.getUserOrganizations());
              }
              return actions;
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          ),
      ),
      share(),
    ),
  );

  public leaveOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.leaveOrg),
      withLatestFrom(
        this.store$.pipe(select(UserSelectors.userProfile)),
        this.store$.pipe(select(state => state.organizationState)),
      ),
      switchMap(([{ orgId }, { _id }, { activeOrganization }]) =>
        this.organizationService.leave(_id, orgId)
          .pipe(
            switchMap(res => {
              const actions: Action[] = [
                OrganizationActions.getUserOrganizations(),
                OrganizationActions.setOrganizationGlobalLoader({ loading: false }),
                SharedActions.showMessage({ success: 'You successfully leave organization' }),
              ];
              if (orgId === activeOrganization.orgId) {
                actions.push(AuthenticationActions.Logout({ reason: null }));
              }
              return actions;
            }),
            catchError(response => {
              return [
                OrganizationActions.setOrganizationGlobalLoader({ loading: false }),
                this.catchError(response)];
            }),
          ),
      ),
      share(),
    ),
  );

  public getOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevTeamActions.getOrganizations),
      switchMap(() =>
        this.organizationService.getAll()
          .pipe(
            switchMap(res => {
              const organizationLookup = {};
              res.forEach(org => {
                organizationLookup[org._id] = {
                  ...org?.settings,
                  orgId: org._id,
                  users: org.users,
                };
              });
              return [DevTeamActions.getOrganizationsSuccess({ organizations: organizationLookup })];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          ),
      ),
      share(),
    ),
  );

  public checkIfUserSigned$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.checkIfUserSigned),
      switchMap(({ inviteId }) => {
        return this.organizationService.checkIfUserSigned(inviteId)
          .pipe(
            switchMap(res => {
              return [OrganizationActions.checkIfUserSignedSuccess({ user: res.user, email: res.email, orgId: res.orgId })];
            }),
            catchError(response => {
              const exception = JSON.parse(response?.error?.exception);
              switch (response.status) {
                case HttpStatusCode.NotFound:
                  this.router.navigate([routerSegments.notFound], { skipLocationChange: true, queryParams: { template: NotFoundTemplates.InviteNotFound } });
                  break;
                case HttpStatusCode.Conflict:
                  this.router.navigate([routerSegments.notFound], { skipLocationChange: true, queryParams: { template: NotFoundTemplates.InviteNotFound } });
                  break;
              }

              return [
                OrganizationActions.checkIfUserSignedError(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public emitJoinActiveOrganizationEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.emitJoinActiveOrganizationEvent),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([{ activeOrganization }, { _id }]) => {
        this.socketMainService.emit(SocketEvents.orgJoin, activeOrganization);
        return [];
      }),
      catchError(response => {
        return [SharedActions.doNothing()];
      }),
      share(),
    ),
  );

  public emitLeaveActiveOrganizationEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.emitLeaveActiveOrganizationEvent),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([{ activeOrganization }, { _id }]) => {
        this.socketMainService.emit(SocketEvents.orgLeave, activeOrganization);
        return [];
      }),
      catchError(response => {
        return [SharedActions.doNothing()];
      }),
      share(),
    ),
  );


  public startLoadingGrantedAccessList = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUsersActions.beforeGetOrganizationUsers),
      exhaustMap(() => [OrganizationUsersActions.setIsLoading({ isLoading: true }), OrganizationUsersActions.getOrganizationUsers()]),
    ),
  );


  public getOrganizationUsersV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUsersActions.getOrganizationUsers),
      withLatestFrom(this.store$.pipe(select(state => state.organizationUsersState))),
      switchMap(([, { filters, page, perPage, isLastPage }]) => {
        if (!isLastPage) {
          return this.organizationService
            .getActiveOrganizationUsersPaginated(filters, page, perPage)
            .pipe(
              switchMap(res => {
                return [
                  OrganizationUsersActions.getOrganizationUsersSuccess({ organizationUsers: res }),
                  OrganizationUsersActions.setIsLoading({ isLoading: false }),
                ];
              }),
              catchError(response => {
                return [
                  OrganizationUsersActions.getOrganizationUsersFail(),
                  OrganizationUsersActions.setIsLoading({ isLoading: false }),
                ];
              }),
            );
        } else {
          return of(OrganizationUsersActions.setIsLoading({ isLoading: false }));
        }
      }),
      share(),
    ),
  );


  public filterTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganizationUsersActions.setFilter,
        OrganizationUsersActions.removeFilter,
        OrganizationUsersActions.setOrderDirection,
      ),
      debounceTime(400),
      switchMap(() => [
        OrganizationUsersActions.setIsLoading({ isLoading: true }),
        OrganizationUsersActions.resetEntities(),
        OrganizationUsersActions.beforeGetOrganizationUsers(),
      ]),
    ),
  );


  public revokeAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.revokeAll),
      switchMap(() => {
        return this.organizationService
          .revokeAllInvites()
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'All invites have been revoked' }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Revoke error' }),
              ];
            }),
          );

      }),
      share(),
    ),
  );

  public deleteOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.removeInvite),
      switchMap(({ inviteId }) => {
        return this.organizationService
          .removeInvite(inviteId)
          .pipe(
            switchMap(res => {
              if (res) {
                return [
                  OrganizationActions.setOrganizationGlobalLoader({ loading: false }),
                  InviteActions.removeInviteSuccess({ inviteId }),
                  SharedActions.showMessage({ success: 'Invitation has been removed' }),
                ];
              } else {
                return [
                  OrganizationActions.setOrganizationGlobalLoader({ loading: false }),
                  SharedActions.showMessage({ error: 'Remove error' }),
                ];
              }
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Remove error' }),
              ];
            }),
          );

      }),
      share(),
    ),
  );


  public resendAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.resendAll),
      switchMap(() => {
        return this.organizationService
          .resendAllInvites()
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'All invites have been resent' }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Resend error' }),
              ];
            }),
          );

      }),
      share(),
    ),
  );

  public sendInvitesV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.sendInvites),
      switchMap(({ invites }) => {
        return this.organizationService
          .sendInvites(invites)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Users have been invited' }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: 'Invite users failed' }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public checkIfUserExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUsersActions.checkIfUserExists),
      debounceTime(400),
      switchMap(({ email }) => {
        return this.organizationService
          .checkIfUserExists(email)
          .pipe(
            switchMap(res => {
              return [
                OrganizationUsersActions.checkIfUserExistsSuccess({ isExists: res }),
              ];
            }),
            catchError(response => {
              return [
                OrganizationUsersActions.checkIfUserExistsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getOrganizationPermissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUsersActions.getOrganizationPermissionGroups),
      switchMap(() => {
        return this.organizationService
          .getOrganizationPermissionGroups()
          .pipe(
            switchMap(res => {
              return [
                OrganizationUsersActions.getOrganizationPermissionGroupsSuccess({ permissionGroups: res }),
              ];
            }),
            catchError(response => {
              return [
                OrganizationUsersActions.getOrganizationPermissionGroupsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getOrganizationMaxRetentionDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.getOrganizationMaxRetentionDays),
      switchMap(() => {
        return this.organizationService
          .getMaxRetentionDays()
          .pipe(
            switchMap(res => {
              return [
                OrganizationsActions.getOrganizationMaxRetentionDaysSuccess({ maxRetentionDays: res.maxRetentionDays }),
              ];
            }),
            catchError(response => {
              return [
                OrganizationsActions.getOrganizationMaxRetentionDaysFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getSystemPermissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUsersActions.getSystemPermissionGroups),
      switchMap(() => {
        return this.organizationService
          .getSystemPermissionGroups()
          .pipe(
            switchMap(res => {
              return [
                OrganizationUsersActions.getSystemPermissionGroupsSuccess({ permissionGroups: res }),
              ];
            }),
            catchError(response => {
              return [
                OrganizationUsersActions.getSystemPermissionGroupsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public loginFirewallSaveBeforeSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.loginFirewallSaveBeforeSave),
      exhaustMap(({ data }) => [SharedActions.setIsSaving({ isSaving: true }), OrganizationActions.loginFirewallSave({ data })]),
    ),
  );

  public loginFirewallSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.loginFirewallSave),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([{ data }, { activeOrg }]) => {
        return this.organizationService
          .saveLoginFirewall(activeOrg, data)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                OrganizationActions.loginFirewallSaveSuccess({ data }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                OrganizationActions.loginFirewallSaveFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public acceptInviteNoAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.acceptInviteNoAuth),
      exhaustMap(({ id }) => {
        return this.organizationService.acceptInviteNoAuth(id)
          .pipe(
            switchMap(res => {
              return [
                InviteActions.acceptInviteNoAuthSuccess(),
              ];
            }),
            catchError(response => {
              return [InviteActions.acceptInviteNoAuthFail()];
            }),
          );
      }),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private socketMainService: SocketMainService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

}
