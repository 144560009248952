import { Injectable } from '@angular/core';
import { api } from '@consts/url.const';
import { HttpClient } from '@angular/common/http';
import { SearchConfigurationSendModel } from '@models/search-configuration.model';
import { Observable } from 'rxjs';
import { SelectedCamera } from '@models/alert-events.model';
import { Search } from '../shared/search.model';
import { PaginationResult } from '@models/shared.model';
import { VariableConfig, VariableSendModel } from '@models/variable.model';
import { SortDirection } from '@angular/material/sort';

@Injectable()
export class SavedSearchService {
  constructor(private http: HttpClient) {
  }

  public createOrUpdate(data: Search.SaveSearchRequest): Observable<Search.SaveSearchRequest> {
    return this.http.post<Search.SaveSearchRequest>(api.savedSearch.crud, data);
  }

  public getOne(id: string): Observable<Search.SavedSearch> {
    return this.http.get<Search.SavedSearch>(api.savedSearch.one(id));
  }

  public remove(id: string): Observable<any> {
    return this.http.delete(api.savedSearch.delete(id));
  }

  public getSavedSearches(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
  ): Observable<PaginationResult<Search.SavedSearch>> {
    let url = `${api.savedSearch.crud}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<PaginationResult<Search.SavedSearch>>(url);
  }

  public getSelectedSavedSearch(id: string): Observable<Search.SavedSearch> {
    return this.http.get<Search.SavedSearch>(api.savedSearch.one(id));
  }
}
