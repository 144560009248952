import { createAction, props } from '@ngrx/store';
import { Notification, UserNotification } from '@models/notification.model';

export const resetToInitialState = createAction('[NOTIFICATION] Reset to Initial state');

export const getUserNotifications = createAction(
  '[NOTIFICATION] Get User Notifications',
);

export const getUserNotificationsSuccess = createAction(
  '[NOTIFICATION] Get User Notifications Success',
  props<{
    userNotifications: UserNotification[]
  }>());

export const getUserNotificationsFail = createAction(
  '[NOTIFICATION] Get User Notifications Fail',
);

export const getNotificationById = createAction('[NOTIFICATION] Get Notification ById', props<{ id: string }>());
export const getNotificationByIdSuccess = createAction('[NOTIFICATION] Get Notification ById Success', props<{ notification: Notification }>());
export const getNotificationByIdFail = createAction('[NOTIFICATION] Get Notification ById Fail');

export const seenNotification = createAction('[NOTIFICATION] Seen Notification', props<{ id: string }>());
export const deleteNotification = createAction('[NOTIFICATION] Delete Notification', props<{ id: string }>());
export const deleteAllNotifications = createAction('[NOTIFICATION] Delete All Notifications');


export const seenNotificationSuccess = createAction('[NOTIFICATION] Seen Notification Success');
export const seenNotificationFail = createAction('[NOTIFICATION] Seen Notification Fail');

export const deleteNotificationSuccess = createAction('[NOTIFICATION] Delete Notification Success');
export const deleteNotificationFail = createAction('[NOTIFICATION] Delete Notification Fail');

export const deleteAllNotificationsSuccess = createAction('[NOTIFICATION] Delete All Notifications Success');
export const deleteAllNotificationsFail = createAction('[NOTIFICATION] Delete All Notifications Fail');

export const scheduleNotification = createAction(
  '[NOTIFICATION] Schedule Notification',
  props<{
    data: {
      notificationId: string;
      timeZone: string;
      schedule: string;
    }
  }>(),
);
export const scheduleNotificationSuccess = createAction('[NOTIFICATION] Schedule Success');
export const scheduleNotificationFail = createAction('[NOTIFICATION] Schedule Fail');


export const filterByRead = createAction(
  '[NOTIFICATION] Filter By Read',
  props<{
    read: boolean
  }>());
