<div class="flex-row start-center camera-actions-wrapper gap-4" (click)="$event.stopPropagation();">
    <span matTooltip="Open camera"
          class="ui-icon-camera c-p ui-icon-button no-border"
          (click)="goToCameraClicked.emit()"
    ></span>
  <div class="horizontal-divider"></div>
  @if (!hideQuality && !playback && isTileOpen) {
    <ui-quality-selector-v2
      matTooltip="Change resolution"
      #resolutionSelector [class.hover]="!resolutionSelector?.selector?.panelOpen"
      [(ngModel)]="resolution"
      (click)="$event.stopPropagation()"
      [disableMQ]="isWebrtc"
      (qualityChanged)="qualityChanged.emit($event)"
      [isControl]="true"
      [enableSQ]="enableSQ"
    ></ui-quality-selector-v2>
  }
  <span [matTooltip]="isFullscreen?'Exit full screen':'Full screen'"
        class="c-p ui-icon-button no-border"
        [class.ui-icon-fullscreen-2]="!isFullscreen"
        [class.ui-icon-exit-full-screen]="isFullscreen"
        (click)="fullScreen.emit()"
  ></span>
  @if (isTileOpen && !isFullscreen) {
    <span matTooltip="Cancel"
          class="ui-icon-close c-p ui-icon-button no-border"
          (click)="closeTile.emit()"
    ></span>
  }
</div>
