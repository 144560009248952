<div fxLayout="column" fxLayoutAlign="start start" class="wrapper narrower-input" fxLayoutGap="14px">
  <mat-select [(ngModel)]="unit" (ngModelChange)="unitChanged()" class="ui-selector">
    <mat-option *ngFor="let unit of unitOptions" [value]="unit">{{unit | titlecase}}</mat-option>
  </mat-select>
  <div fxLayout="row" fxLayoutAlign="center center" class="slider-wrapper w-100">
    <ui-range-slider [unit]="unit"
                     [min]="min"
                     [max]="max"
                     [minValue]="start"
                     [maxValue]="end"
                     class="w-100"
                     (rangeChanged)="onRangeChange($event)"
    ></ui-range-slider>
  </div>
</div>
