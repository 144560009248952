import { CameraLookup } from "./camera.model";

export namespace SensorModel {

  // Manage
  export enum Provider {
    HALO = 'halo',
    DISRUPTIVE = 'distruptive'
  }

  export interface Sensor {
    sensorId: string;
    locationId: string;
    vendor: Provider;
    sensorType?: string;
    name: string;
    cameras?: CameraLookup[];
  }

  export interface SensorTest {
    sensorId: string;
    vendor: Provider;
  }

  export interface SensorResponse {
    success: boolean;
    message?: string;
    data?: Sensor;
  }

  export interface SensorListResponse {
    success: boolean;
    message?: string;
    data: Sensor[];
  }

  export enum HaloSensorType {
    HALO_3C = 'halo_3c',
    HALO_3C_PC = 'halo_3c_pc',
    HALO_AMPLIFY = 'halo_amplify',
    HALO_2C = 'halo_2c'
  }

  // Search
  export interface SensorSearchRequest {
    locationIds?: string[];
    sensorIds?: string[];
    startTime?: number;
    endTime?: number;
    type?: string;
    page?: number;
    pageSize?: number;
  }

  export interface SensorSearchData {
    provider?: Provider;
    action?: string;
    sensorId: string;
    isNetworkStatus?: boolean;
    start: number;
    end: number;
    page?: number;
    size?: number;
    eventType?: Halo.HaloEventType;
  }

  export interface SensorSearchResult {
    timestamp: number;
    value: number | string;
  }

  export interface SensorLastReading {
    sensorId: string;
    timestamp: number;
    type: string;
    value: number | string;
  }

  export interface SensorStats {
    sensorId: string;
    type: string;
    min: number;
    max: number;
    avg: number;
    count: number;
    startTime: number;
    endTime: number;
  }

  export enum ValueType {
    NUMBER = 'number',
    STRING = 'string',
  }

  export interface SensorTypeSupport {
    key: string;
    value: number;
    name: string;
    type: ValueType;
  }

  export namespace Halo {
    export enum HaloEventType {
      temp = 'temp',
      rh = 'rh',
      lux = 'lux',
      tvoc = 'tvoc',
      co2eq = 'co2eq',
      pm25 = 'pm25',
      pm10 = 'pm10',
      nh3 = 'nh3',
      no2 = 'no2',
      co = 'co',
      noise = 'noise',
      move = 'move',
      aqi = 'aqi',
    }

    export interface SensorData {
      Temp: number;
      RH: number;
      Lux: number;
      TVOC: number;
      CO2eq: number;
      'PM2.5': number;
      PM10: number;
      NH3: number;
      NO2: number;
      CO: number;
      Noise: number;
      Move: number;
      AQI: number;
      Triggered: string[];
    }
  }

}