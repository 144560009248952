import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CdkDragMove, CdkDragRelease } from '@angular/cdk/drag-drop';
import { PlaybackPlayerService } from '../playback-player.service';
import { filter, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TIMELINE_ASPECT_RATIO, TIMELINE_HOUR_IN_MSECS, TIMELINE_MAX_WINDOW } from './playback-timeline.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import * as moment from 'moment-timezone';
import { PlaybackSelectors } from '@states/playback/playback.selector-types';

enum DownloadAreaDragState {
  DRAG,
  DRAG_LEFT,
  DRAG_RIGHT,
}

@UntilDestroy()
@Component({
  selector: 'app-playback-timeline',
  templateUrl: './playback-timeline.component.html',
  styleUrls: ['./playback-timeline.component.scss'],
})
export class PlaybackTimelineComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('wrapper')
  wrapper: ElementRef;

  @ViewChild('current')
  current: ElementRef;

  @ViewChild('downloadArea')
  downloadArea: ElementRef;

  @Output()
  seekStart: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  seek: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  saveArchive = new EventEmitter<{ start: number; end: number; duration: number; filename: string }>();

  @Output()
  public onArchiveReady = new EventEmitter<{ start: number; end: number }>();

  start: number = new Date().getTime() - TIMELINE_HOUR_IN_MSECS / 2;

  end: number = new Date().getTime() + TIMELINE_HOUR_IN_MSECS / 2;

  @Input()
  numVertical: number = 70;

  @Input()
  videoCurrentTimeRaw: number = 0;

  videoCurrentTime: number = 0;

  @Input()
  numVerticalSpaceRatio = TIMELINE_ASPECT_RATIO;

  numVerticalSpaceRatioDragOffset = 0;

  @Input()
  timezone: string;
  timezoneGmt: string;

  numVerticalSpaceDisplay = this.numVertical / this.numVerticalSpaceRatio;

  @Input()
  currentTs: number = new Date().getTime();
  currentPercentage = 0;

  currentPosition = { x: 0, y: 0 };

  dragging: boolean = false;
  draggingTimeLine: boolean = false;
  draggingTimeLineStartX: number = 0;
  draggingTimeLineStartOrig: number = 0;
  draggingTimeLineEndOrig: number = 0;
  draggingTimeLineCurrentTsOrig: number = 0;

  selectDownloadArea: boolean = false;
  selectDownloadAreaStartOffset = 0;
  selectDownloadAreaWidth = 0;
  selectDownloadAreaWidthPercent = 0;
  selectDownloadAreaStartPercentage = 0;
  selectDownloadAreaStartTs = 0;
  selectDownloadAreaEndTs = 0;
  selectDownloadValid: boolean = false;
  durationAlertOpened = false;

  draggingDownloadArea: boolean = false;
  draggingDownloadAreaState: DownloadAreaDragState = DownloadAreaDragState.DRAG;
  draggingDownloadAreaStartX: number = 0;
  draggingDownloadAreaWidthOrig: number = 0;
  draggingDownloadAreaStartTsOrig: number = 0;
  draggingDownloadAreaEndTsOrig: number = 0;
  draggingDownloadAreaCurrentTsOrig: number = 0;

  verticalLines = Array(this.numVertical + 1)
    .fill(0)
    .map((x, i) => i);
  verticalSpace = Array(this.numVerticalSpaceDisplay + 1)
    .fill(0)
    .map((x, i) => i);
  space: number;

  subs: Subscription[] = [];

  private startTimestampArchive: number;
  private endTimestampArchive: number;

  constructor(
    public playbackPlayerService: PlaybackPlayerService,
    private snackBar: MatSnackBar,
    private store$: Store<AppState>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['videoCurrentTimeRaw']?.currentValue && !this.dragging && !this.draggingTimeLine) {
      this.videoCurrentTime = this.videoCurrentTimeRaw;
      this.updatePosition();
    }
  }

  ngOnDestroy(): void {
    for(let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.current) {
      return;
    }

    this.resizePosition();
    this.resizeDownloadArea();
  }

  ngOnInit(): void {
    this.space = (this.end - this.start) / this.numVerticalSpaceDisplay + 1;
    const timezoneGmt = moment.tz(this.timezone)
      .format('Z');
    this.timezoneGmt = `GMT${timezoneGmt}`;
  }

  ngAfterViewInit(): void {
    const width = this.wrapper.nativeElement.clientWidth;
    this.currentPosition = { x: width * 0.5, y: 0 };
    this.currentPercentage = 0.5;
    setTimeout(() => this.updatePosition(), 0);

    if (this.currentTs) {
      this.videoCurrentTime = 0;
      this.updateRange(this.currentTs);
      this.currentPosition = { x: width * 0.5, y: 0 };
      setTimeout(() => this.updatePosition(), 0);
    }

    // this.playbackPlayerService.timelineInit$.pipe(
    //   untilDestroyed(this))
    //   .subscribe(ts => {
    //
    //     this.currentTs = ts;
    //     this.videoCurrentTime = 0;
    //     this.updateRange(ts);
    //     this.currentPosition = { x: width * 0.5, y: 0 };
    //     setTimeout(() => this.updatePosition(), 0);
    //   });

    this.store$
      .select(PlaybackSelectors.selectTag)
      .pipe(
        untilDestroyed(this),
        filter(tag => !!tag),
      )
      .subscribe((tag: string) => {
        const ts = +tag.split('_')[1] * 1000;
        if (this.dragging || this.draggingTimeLine) {
          return;
        }
        this.currentTs = ts;
        setTimeout(() => this.updatePosition(), 0);
      });

    this.playbackPlayerService.time$.pipe(untilDestroyed(this))
      .subscribe(ts => {
        if (this.dragging || this.draggingTimeLine) {
          return;
        }
        this.currentTs = ts;
        setTimeout(() => this.updatePosition(), 0);
      });
  }

  updatePosition(width?: number) {
    this.currentPercentage = (this.currentTs + this.videoCurrentTime - this.start) / (this.end - this.start);
    if (!width) {
      width = this.wrapper.nativeElement.clientWidth;
    }
    if (this.currentPercentage > 1) {
      this.start = this.currentTs + this.videoCurrentTime - 1000 * 60 * 60;
      this.end = this.currentTs + this.videoCurrentTime;
      return;
    }
    this.currentPosition = { x: width * this.currentPercentage, y: 0 };
  }

  currentMoved(drag: CdkDragMove) {
    const width = this.wrapper.nativeElement.clientWidth;
    const mouseEvent: MouseEvent = <MouseEvent>drag.event;
    const offset = mouseEvent.offsetX;
    const percentage = offset / width;
  }

  currentReleased(release: CdkDragRelease) {
    this.dragging = false;

    const width = this.wrapper.nativeElement.clientWidth;
    const boundDrag = release.source.element.nativeElement.getBoundingClientRect();
    const boundWrapper = this.wrapper.nativeElement.getBoundingClientRect();
    const offsetX = boundDrag.x - boundWrapper.x;

    this.currentPercentage = offsetX / width;
    this.currentTs = this.start + (this.end - this.start) * this.currentPercentage;
    this.seek.emit(this.currentTs);
  }

  dragStarted() {
    this.dragging = true;
    this.currentTs = this.currentTs + this.videoCurrentTime;
    this.videoCurrentTime = 0;
    this.selectDownloadArea = false;
    this.resetDownloadArea();
    if (this.draggingTimeLine) {
      this.draggingTimeLine = false;
    }
    this.seekStart.emit();
  }

  resizePosition() {
    const width = this.wrapper.nativeElement.clientWidth;
    this.currentPosition = { x: width * this.currentPercentage, y: 0 };
  }

  resizeDownloadArea() {
    const width = this.wrapper.nativeElement.clientWidth;
    this.selectDownloadAreaStartOffset = width * this.selectDownloadAreaStartPercentage;
    this.selectDownloadAreaWidth = width * this.selectDownloadAreaWidthPercent;
    this.renderDownloadArea();
  }

  updateCurrent(drag: CdkDragMove) {
    const currentBound = this.current.nativeElement.getBoundingClientRect();
    const wrapperBound = this.wrapper.nativeElement.getBoundingClientRect();
    const offset = currentBound.x - wrapperBound.x;
    if (offset >= wrapperBound.x && offset <= wrapperBound.right) {
      this.currentPosition.x = offset;
    }
    const width = this.wrapper.nativeElement.clientWidth;
    this.currentPercentage = offset / width;
    this.currentTs = this.start + (this.end - this.start) * this.currentPercentage;
    this.playbackPlayerService.setTime(this.currentTs);
  }

  public updateRange(ts: number) {
    this.start = ts - 1000 * 60 * 30;
    this.end = ts + 1000 * 60 * 30;
  }

  startTimelineDrag(event: MouseEvent) {
    if (this.selectDownloadArea) {
      this.selectDownloadAreaEnd(event);
      return;
    }
    this.resetDownloadArea();
    if (this.dragging) {
      return;
    }
    this.seekStart.emit();
    this.currentTs = this.currentTs + this.videoCurrentTime;
    this.videoCurrentTime = 0;
    this.draggingTimeLine = true;
    this.draggingTimeLineStartX = event.offsetX;
    this.draggingTimeLineStartOrig = this.start;
    this.draggingTimeLineEndOrig = this.end;
    this.draggingTimeLineCurrentTsOrig = this.currentTs;
  }

  timelineDrag(event: MouseEvent) {
    if (this.selectDownloadArea) {
      return;
    }

    if (!this.draggingTimeLine) {
      return;
    }

    const delta = event.offsetX - this.draggingTimeLineStartX;
    const percent = Math.abs(delta / (event.target as HTMLElement).clientWidth);
    this.start = this.draggingTimeLineStartOrig + Math.floor(percent * TIMELINE_HOUR_IN_MSECS) * (delta > 0 ? -1 : 1);
    this.end = this.draggingTimeLineEndOrig + Math.floor(percent * TIMELINE_HOUR_IN_MSECS) * (delta > 0 ? -1 : 1);
    this.currentTs = this.draggingTimeLineCurrentTsOrig + Math.floor(percent * TIMELINE_HOUR_IN_MSECS) * (delta > 0 ? -1 : 1);
    this.playbackPlayerService.setTime(this.currentTs);
    this.numVerticalSpaceRatioDragOffset = Math.floor((100 * percent) % TIMELINE_ASPECT_RATIO) * (delta > 0 ? -1 : 1);
  }

  releaseTimelineDrag(event: MouseEvent) {
    if (this.draggingTimeLine === false) {
      return;
    }
    this.draggingTimeLine = false;
    const delta = event.offsetX - this.draggingTimeLineStartX;
    const percent = Math.abs(delta / (event.target as HTMLElement).clientWidth);
    if (percent) {
      this.seek.emit(this.currentTs);
    }
  }

  getTimestampForOffsetX(x: number): number {
    const wrapperWidth = this.wrapper.nativeElement.clientWidth;
    const percent = x / wrapperWidth;
    const timestamp = this.start + Math.floor((this.end - this.start) * percent);
    return timestamp;
  }

  selectDownloadAreaStart(event: MouseEvent) {
    this.selectDownloadArea = true;
    this.downloadArea.nativeElement.style.width = `2px`;
    this.downloadArea.nativeElement.style.left = `${event.offsetX + 36}px`;
    this.selectDownloadAreaStartOffset = event.offsetX;
    const boundWrapper = this.wrapper.nativeElement.getBoundingClientRect();
    const width = this.wrapper.nativeElement.clientWidth;
    this.selectDownloadAreaStartPercentage = event.offsetX / width;
    this.selectDownloadAreaStartTs = this.getTimestampForOffsetX(event.offsetX);
  }

  selectDownloadAreaDrag(event: MouseEvent) {
  }

  calcWidthForDelta(delta: number) {
    const percent = delta / (this.end - this.start);
    return this.wrapper.nativeElement.clientWidth * percent;
  }

  renderDownloadArea() {
    this.downloadArea.nativeElement.style.left = `${this.selectDownloadAreaStartOffset + 36}px`;
    this.downloadArea.nativeElement.style.width = `${this.selectDownloadAreaWidth}px`;
  }

  selectDownloadAreaEnd(event: MouseEvent) {
    this.selectDownloadArea = false;
    const wrapperWidth = this.wrapper.nativeElement.clientWidth;
    const widthR = event.offsetX - this.selectDownloadAreaStartOffset;
    const origSelectDownloadAreaStartOffset = this.selectDownloadAreaStartOffset;
    if (widthR < 0) {
      this.selectDownloadAreaStartOffset = event.offsetX;
      this.downloadArea.nativeElement.style.left = `${event.offsetX + 36}px`;
      this.selectDownloadAreaStartTs = this.getTimestampForOffsetX(event.offsetX);
    }
    const width = Math.abs(widthR);

    this.selectDownloadAreaWidth = width;
    this.selectDownloadAreaWidthPercent = width / wrapperWidth;
    this.downloadArea.nativeElement.style.width = `${width}px`;
    this.selectDownloadAreaEndTs = this.getTimestampForOffsetX(this.selectDownloadAreaStartOffset + width);
    const window = this.selectDownloadAreaEndTs - this.selectDownloadAreaStartTs;
    this.selectDownloadValid = this.selectDownloadAreaEndTs - this.selectDownloadAreaStartTs <= TIMELINE_MAX_WINDOW;
    if (!this.selectDownloadValid) {
      this.snackBar.open('Archive video is limited to 10 minutes', '', { duration: 5000 });
      this.downloadArea.nativeElement.style.width = `${this.calcWidthForDelta(TIMELINE_MAX_WINDOW)}px`;
      this.selectDownloadAreaWidth = this.calcWidthForDelta(TIMELINE_MAX_WINDOW);
      this.selectDownloadAreaWidthPercent = width / wrapperWidth;
      if (widthR < 0) {
        const newStart = origSelectDownloadAreaStartOffset - this.calcWidthForDelta(TIMELINE_MAX_WINDOW);
        this.downloadArea.nativeElement.style.left = `${newStart + 36}px`;
        this.selectDownloadAreaStartTs = this.selectDownloadAreaEndTs - TIMELINE_MAX_WINDOW;
      } else {
        this.selectDownloadAreaEndTs = this.selectDownloadAreaStartTs + TIMELINE_MAX_WINDOW;
      }
      this.selectDownloadValid = true;
    }
    this.onArchiveReady.emit({
      start: this.selectDownloadAreaStartTs,
      end: this.selectDownloadAreaEndTs,
    });
  }

  resetDownloadArea() {
    this.downloadArea.nativeElement.style.width = '0px';
  }

  saveToArchive(filename: string) {
    filename = filename.replace(' ', '-');
    this.saveArchive.emit({
      start: this.selectDownloadAreaStartTs,
      end: this.selectDownloadAreaEndTs,
      filename: `${filename}`,
      duration: this.selectDownloadAreaEndTs - this.selectDownloadAreaStartTs,
    });
    this.selectDownloadArea = false;
    this.resetDownloadArea();
  }

  setDownloadAreaDragState() {
    if (this.draggingDownloadAreaStartX < 15) {
      this.draggingDownloadAreaState = DownloadAreaDragState.DRAG_LEFT;
    } else if (this.selectDownloadAreaWidth - this.draggingDownloadAreaStartX < 15) {
      this.draggingDownloadAreaState = DownloadAreaDragState.DRAG_RIGHT;
    } else {
      this.draggingDownloadAreaState = DownloadAreaDragState.DRAG;
    }
  }

  startDownloadAreaDrag(event: MouseEvent) {
    this.draggingDownloadArea = true;
    this.draggingDownloadAreaStartX = event.offsetX;
    this.draggingDownloadAreaWidthOrig = this.selectDownloadAreaWidth;
    this.draggingDownloadAreaStartTsOrig = this.selectDownloadAreaStartTs;
    this.draggingDownloadAreaEndTsOrig = this.selectDownloadAreaEndTs;
    this.setDownloadAreaDragState();
  }

  downloadAreaDrag(event: MouseEvent) {
    if (!this.draggingDownloadArea) {
      return;
    }

    const width = this.wrapper.nativeElement.clientWidth;
    const delta = event.offsetX - this.draggingDownloadAreaStartX;
    const percent = Math.abs(delta / (event.target as HTMLElement).clientWidth);
    const origOffset = this.selectDownloadAreaStartOffset;
    const origOffsetPercentage = this.selectDownloadAreaStartPercentage;
    const origWidth = this.selectDownloadAreaWidth;
    const origWidthPercent = this.selectDownloadAreaWidthPercent;

    switch (this.draggingDownloadAreaState) {
      case DownloadAreaDragState.DRAG_LEFT:
      case DownloadAreaDragState.DRAG:
        this.selectDownloadAreaStartOffset =
          this.selectDownloadAreaStartOffset + delta <= 0
            ? 0
            : this.selectDownloadAreaStartOffset + delta + this.selectDownloadAreaWidth >= width
              ? width - this.selectDownloadAreaWidth
              : this.selectDownloadAreaStartOffset + delta;
        this.selectDownloadAreaStartPercentage = this.selectDownloadAreaStartOffset / width;

        if (this.draggingDownloadAreaState === DownloadAreaDragState.DRAG_LEFT) {
          this.selectDownloadAreaWidth -= delta;
          this.selectDownloadAreaWidthPercent = this.selectDownloadAreaWidth / width;
        }
        break;
      case DownloadAreaDragState.DRAG_RIGHT:
        this.selectDownloadAreaWidth = this.draggingDownloadAreaWidthOrig + delta;
        this.selectDownloadAreaWidthPercent = this.selectDownloadAreaWidth / width;
        break;
    }
    this.selectDownloadAreaStartTs = this.getTimestampForOffsetX(this.selectDownloadAreaStartOffset);
    this.selectDownloadAreaEndTs = this.getTimestampForOffsetX(this.selectDownloadAreaStartOffset + this.selectDownloadAreaWidth);
    this.selectDownloadValid = this.selectDownloadAreaEndTs - this.selectDownloadAreaStartTs <= TIMELINE_MAX_WINDOW;
    if (!this.selectDownloadValid) {
      if (!this.durationAlertOpened) {
        this.durationAlertOpened = true;
        this.snackBar.open('Archive video is limited to 10 minutes', '', { duration: 5000 });
        setTimeout(_ => {
          this.durationAlertOpened = false;
        }, 5000);
      }
      this.selectDownloadAreaStartOffset = origOffset;
      this.selectDownloadAreaStartPercentage = origOffsetPercentage;
      this.selectDownloadAreaWidth = origWidth;
      this.selectDownloadAreaWidthPercent = origWidthPercent;
    } else {
      this.renderDownloadArea();
    }
    this.onArchiveReady.emit({
      start: this.selectDownloadAreaStartTs,
      end: this.selectDownloadAreaEndTs,
    });
  }

  releaseDownloadAreaDrag(event: MouseEvent) {
    if (this.draggingDownloadArea === false) {
      return;
    }
    this.draggingDownloadArea = false;
    const delta = event.offsetX - this.draggingDownloadAreaStartX;
    const percent = Math.abs(delta / (event.target as HTMLElement).clientWidth);
  }

  public onArchiveModalShow(data: { start: Date; end: Date }) {
    // this.draggingDownloadArea = false;
    this.selectDownloadArea = true;
    this.downloadArea.nativeElement.style.width = `2px`;
    const width = this.wrapper.nativeElement.clientWidth;
    if (data?.start) {
      this.startTimestampArchive = new Date(data.start).getTime() - this.start;
    } else if (!this.startTimestampArchive) {
      this.startTimestampArchive = this.currentTs - this.start - 1000 * 60 * 2;
    }

    if (data?.end) {
      this.endTimestampArchive = new Date(data.end).getTime() - this.start;
    } else if (!this.endTimestampArchive) {
      this.endTimestampArchive = this.currentTs + 1000 * 60 * 2 - this.start;
    }
    const videoLength = this.end - this.start;
    const startOffset = (width * this.startTimestampArchive) / videoLength;
    const endOffset = (width * this.endTimestampArchive) / videoLength;

    this.downloadArea.nativeElement.style.left = `${startOffset + 36}px`;
    this.selectDownloadAreaStartOffset = startOffset;
    this.downloadArea.nativeElement.style.width = `${endOffset - startOffset}px`;
    this.selectDownloadAreaWidth = endOffset - startOffset;
    this.selectDownloadAreaWidthPercent = width / (endOffset - startOffset);

    this.onArchiveReady.emit({
      start: this.startTimestampArchive + this.start,
      end: this.endTimestampArchive + this.start,
    });
  }

  public timezoneAbbreviation() {
    const zone = moment()
      .tz(this.timezone)
      .format('z');
    return zone;
  }
}
