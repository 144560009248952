import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OrgGroupModels } from "@models/org-group.model";
import { DoorModels } from "@models/door.model";
import { Observable, take } from "rxjs";
import { Person, GroupStatus } from "@models/people.model";
import { PeopleSelectors } from "@states/people/people.selector-types";
import { select, Store } from "@ngrx/store";
import { VehicleModels } from "@models/vehicle.model";
import { PeopleActions } from "@states/people/people.action-types";
import _ from "lodash";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { VehiclesSelectors } from "@states/vehicles/vehicles.selector-types";
import { DoorsSelectors } from "@states/doors/doors.selector-types";
import { VehiclesActions } from "@states/vehicles/vehicles.action-types";
import { DoorsActions } from "@states/doors/doors.action-types";
import { DevTeamActions } from "@states/dev-team/dev-team.action-types";
import { AppUser } from "../../../user/user.model";
import { OrganizationUsersSelectors } from "@states/organization-users/organization-users.selector-types";
import { OrganizationUsersActions } from "@states/organization-users/organization-users.action-types";

@UntilDestroy()
@Component({
  selector: 'ui-org-group-member-selector',
  templateUrl: './ui-org-group-member-selector.component.html',
  styleUrls: ['./ui-org-group-member-selector.component.scss']
})
export class UiOrgGroupMemberSelectorComponent implements OnInit, OnChanges {

  @ViewChild('list') list: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  public groupType = OrgGroupModels.OrgGroupType;
  public selectSavedPeople$: Observable<Person[]> = this.store$.pipe(select(PeopleSelectors.selectSavedPeople));
  public selectSavedVehicles$: Observable<VehicleModels.Vehicle[]> = this.store$.pipe(select(VehiclesSelectors.selectSavedVehicles));
  public selectSavedDoors$: Observable<DoorModels.Door[]> = this.store$.pipe(select(DoorsSelectors.selectSavedDoors));
  public selectOrganizationUsersEntities$: Observable<AppUser.User[]> = this.store$.pipe(select(OrganizationUsersSelectors.selectOrganizationUsersEntities));

  @Input() inline = false;
  @Input() type: OrgGroupModels.OrgGroupType;
  @Input() members: OrgGroupModels.OrgGroupMember[] = [{memberId: 627409801}];

  @Output() membersChange = new EventEmitter<OrgGroupModels.OrgGroupMember[]>();

  public all: (DoorModels.Door | Person | VehicleModels.Vehicle | AppUser.User)[] = [];
  // public all: OrgMember[] = [];

  public membersSet = new Set<any>();

  public query = '';

  public filtered: (DoorModels.Door | Person | VehicleModels.Vehicle | AppUser.User)[] = [];
  public selecting = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef?.nativeElement?.contains(event.target)) {
    } else {
      this.selecting = false;
    }
  }

  constructor(private store$: Store, private eRef: ElementRef) {
  }

  isMember(id: string | number): boolean {
    return this.membersSet.has(id);
  }

  public get allByType() {
    switch (this.type) {
      case OrgGroupModels.OrgGroupType.Doors:
        return this.all as DoorModels.Door[];
      case OrgGroupModels.OrgGroupType.People:
        return this.all as Person[];
      case OrgGroupModels.OrgGroupType.Vehicles:
        return this.all as VehicleModels.Vehicle[];
      case OrgGroupModels.OrgGroupType.Users:
        return this.all as AppUser.User[];
    }
  }

  public get allPeople(): Person[] {
    return this.all as Person[];
  }

  public get filteredPeople(): Person[] {
    return this.filtered as Person[];
  }

  public get allUsers(): AppUser.User[] {
    return this.all as AppUser.User[];
  }

  public get filteredUsers(): AppUser.User[] {
    return this.filtered as AppUser.User[];
  }

  public get allVehicles(): VehicleModels.Vehicle[] {
    return this.all as VehicleModels.Vehicle[];
  }

  public get filteredVehicles(): VehicleModels.Vehicle[] {
    return this.filtered as VehicleModels.Vehicle[];
  }

  public get allDoors(): DoorModels.Door[] {
    return this.all as DoorModels.Door[];
  }

  public get filteredDoors(): DoorModels.Door[] {
    return this.filtered as DoorModels.Door[];
  }

  public get filteredByType() {
    switch (this.type) {
      case OrgGroupModels.OrgGroupType.Doors:
        return this.filtered as DoorModels.Door[];
      case OrgGroupModels.OrgGroupType.People:
        return this.filtered as Person[];
      case OrgGroupModels.OrgGroupType.Vehicles:
        return this.filtered as VehicleModels.Vehicle[];
      case OrgGroupModels.OrgGroupType.Users:
        return this.filtered as AppUser.User[];
    }
  }

  ngOnInit(): void {
  }

  emitMembers() {
    const memberList = Array.from(this.membersSet);
    const members: OrgGroupModels.OrgGroupMember[] = memberList.map((member) => {
      const orig = _.find(this.members, {memberId: member});
      return {memberId: member, ...orig ?? {}};
    });
    this.membersChange.emit(members);
    this.members = members;
  }

  toggle(event: Event, id) {
    event.preventDefault();
    if (this.membersSet.has(id)) {
      this.membersSet.delete(id);
    } else {
      this.membersSet.add(id);
    }
    this.emitMembers();
  }

  search(event: any) {
    this.query = event;
    if (!this.query) {
      return;
    }
    switch (this.type) {
      case OrgGroupModels.OrgGroupType.Doors:
        this.filtered = this.all.filter((d) => {
          const door: DoorModels.Door = d as DoorModels.Door;
          return door.name.toLowerCase().includes(this.query.toLowerCase());
        });
        break;
      case OrgGroupModels.OrgGroupType.People:
        this.filtered = this.all.filter((p) => {
          const person: Person = p as Person;
          return person.name.toLowerCase().includes(this.query.toLowerCase());
        });
        break;
      case OrgGroupModels.OrgGroupType.Vehicles:
        this.filtered = this.all.filter((v) => {
          const vehicle: VehicleModels.Vehicle = v as VehicleModels.Vehicle;
          return vehicle?.plate?.toLowerCase().includes(this.query.toLowerCase())
            || vehicle?.owner?.toLowerCase().includes(this.query.toLowerCase())
            || vehicle?.make?.toLowerCase().includes(this.query.toLowerCase())
        });
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      switch (this.type) {
        case OrgGroupModels.OrgGroupType.People:
          this.store$.dispatch(PeopleActions.getPeople({status: GroupStatus.Saved}));
          this.selectSavedPeople$.pipe(untilDestroyed(this)).subscribe((people) => {
            const alreadyMembers = people.filter((p) => this.isMember(p.personId));
            const notMembers = people.filter((p) => !this.isMember(p.personId));
            this.all = [...alreadyMembers, ...notMembers];
          });
          break;
        case OrgGroupModels.OrgGroupType.Vehicles:
          this.store$.dispatch(VehiclesActions.getVehicles());
          this.selectSavedVehicles$.pipe(untilDestroyed(this)).subscribe((vehicles) => {
            const alreadyMembers = vehicles.filter((v) => this.isMember(v.id));
            const notMembers = vehicles.filter((v) => !this.isMember(v.id));
            this.all = [...alreadyMembers, ...notMembers];
          });
          break;
        case OrgGroupModels.OrgGroupType.Doors:
          this.store$.dispatch(DoorsActions.getDoors());
          this.selectSavedDoors$.pipe(untilDestroyed(this)).subscribe((doors) => {
            const alreadyMembers = doors.filter((d) => this.isMember(d.id));
            const notMembers = doors.filter((d) => !this.isMember(d.id));
            this.all = [...alreadyMembers, ...notMembers];
          });
          break;
        case OrgGroupModels.OrgGroupType.Users:
          this.store$.dispatch(OrganizationUsersActions.getOrganizationUsers());
          this.selectOrganizationUsersEntities$.pipe(untilDestroyed(this)).subscribe((users) => {
            this.all = users;
          });
      }
    }

    if (changes['members']) {
      if (!!this.members?.length) {
        for (let member of this.members) {
          this.membersSet.add(member.memberId);
        }
      }
    }
  }
}
