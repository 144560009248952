import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { checkPermission } from '../helpers/common.helpers';
import { PermissionModel } from '@models/permission.model';
import Permissions = PermissionModel.Permissions;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CameraEditGuard implements CanActivate {
  public selectPermissions$: Observable<{
    permissions: string[],
    entityIds: string[]
  }> = this.store$.pipe(select(PermissionSelectors.selectPermissions));

  constructor(private store$: Store<AppState>, private router: Router,
              private route: ActivatedRoute) {
  }

  public canActivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.selectPermissions$
      .pipe(
        untilDestroyed(this),
        map((res) => {
          const perm = checkPermission(Permissions.CameraEdit, res, [currentRoute.params['cameraId'], currentRoute.params['edgeId'], currentRoute.params['locationId']]);
          return perm;
        }),
      );
  }
}
