import { Component, Inject, OnInit } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;

@Component({
  selector: 'app-ui-crop',
  templateUrl: './ui-crop.component.html',
  styleUrls: ['./ui-crop.component.scss'],
})
export class UiCropComponent implements OnInit {
  public croppedImage: string = '';
  public imageChangedEvent: any;

  constructor(public dialogRef: MatDialogRef<UiCropComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.imageChangedEvent = this.data.imageChangedEvent;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
  }

  cropperReady() {
  }

  loadImageFailed() {
  }

  async save() {
    const base64Response = await fetch(this.croppedImage);
    const blob = await base64Response.blob();
    this.dialogRef.close({ file: blob, croppedImage: this.croppedImage });
  }

  public cancel() {
    this.dialogRef.close();
  }
}
