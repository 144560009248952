import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommentRequest } from '@models/comment';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CommentService {

  constructor(private http: HttpClient) {
  }

  public getAll(id: string): Observable<CommentRequest[]> {
    return this.http.get<CommentRequest[]>(api.comment.byEntity(id));
  }

  public create(comment: CommentRequest): Observable<CommentRequest> {
    return this.http.post<CommentRequest>(api.comment.crud, comment);
  }
}
