<div
  [matTooltip]="statusTitle"
  matTooltipClass="sidebar-tooltip"
  #wrapper
  fxLayoutAlign="start center"
  [class]="iconClass + ' status-chip ' + color"
  [class.no-text]="!text"
  [class.no-bg]="noBg"
>
  <ng-container *ngIf="initDone; else load">
    <ng-container [ngSwitch]="status">
      <mat-icon *ngSwitchCase="CameraStatus.Online" svgIcon="status-online"></mat-icon>
      <mat-icon *ngSwitchCase="CameraStatus.Offline" svgIcon="status-offline"></mat-icon>
      <ng-container *ngIf="device === 'alert'">
        <mat-icon *ngSwitchCase="AlertSyncStatus.notHealthy" svgIcon="status-unhealthy"></mat-icon>
      </ng-container>
      <mat-icon *ngSwitchDefault svgIcon="status-unhealthy"></mat-icon>
    </ng-container>
  </ng-container>
  <ng-template #load>
    <ui-preloader [color]="PreloaderColor.blue" [diameter]="16" matTooltip="Loading"
                  matTooltipClass="sidebar-tooltip"></ui-preloader>
  </ng-template>
  <!--  <div *ngIf="text" class="ml-5">{{ statusString }}</div>-->
</div>
