<div class="page-wrapper">
  <div class="page-inner">
    <img src="assets/lumana_logo.svg" alt="Lumana" class="lumix-logo"/>
    <div class="title">Confirm e-mail</div>
    <div class="description">
      We’ve sent you a confirmation link to {{ this.email }}. Please, open your mailbox and follow the instructions.
    </div>
    <div class="confirm-btn" (click)="login()">I verified an email. Go to login</div>
    <div class="logout-btn" (click)="logout()">Back</div>
  </div>
</div>
