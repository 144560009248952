<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="ui-button"
  [class.ui-saving-btn]="loading"
  [class.disabled]="disabled"
  [class.default]="color === uiButtonStyles.default"
  [class.red]="color === uiButtonStyles.red"
  [class.gray]="color === uiButtonStyles.gray"
  [class.green]="color === uiButtonStyles.green"
  [class.no-icon]="!icon && !loading"
  [style.background]="colorBgr"
  [style.border-color]="colorBorder"
  [class.size-default]="size==='default'"
  [class.size-50]="size==='50'"
  [class.size-40]="size==='40'"
  (click)="click()"
>
  <div *ngIf="loading || icon" class="preloader-container ui-small-spinner" fxLayout="row"
       fxLayoutAlign="center center"
  >
    <ui-preloader [color]="preloaderColor"
                  *ngIf="loading"
                  [@fadeIn]="animState"
                  [diameter]="18"
                  class="mr-5"
    ></ui-preloader>
    <mat-icon *ngIf="icon && !loading && (showText2State | async) === false" [@fadeIn]="animState"
              class="icon"
    > {{ icon }}</mat-icon>
    <mat-icon *ngIf="!loading && (showText2State | async) === true" [@fadeIn]="animState" class="icon">done</mat-icon>
  </div>
  <div>
    <div *ngIf="text1 && (showText2State | async) === false"
         [@fadeIn]="animState"
         class="text-container"
         [style.color]="colorText"
    >
      <span>{{ text1 }}</span>
    </div>
    <div *ngIf="text2 && (showText2State | async) === true"
         [@fadeIn]="animState"
         class="text-container"
         [style.color]="colorText"
    >
      {{ text2 }}
    </div>
  </div>
</div>
