import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PermissionGroupModels } from '@models/group.model';
import { PermissionGroupsActions } from '@states/group/group.action-types';
import { removeArrayElementIfExists } from '../../../helpers/common.helpers';
import { PermissionModel } from '@models/permission.model';
import SelectedEntity = PermissionModel.SelectedEntity;
import { UserOrganizationDropDown } from '@models/organization.model';
import { AppUser } from '../../../user/user.model';
import User = AppUser.User;

export interface PermissionGroupState extends EntityState<PermissionGroupModels.PermissionGroupDocumentBase> {
  selectedPermissionGroup: PermissionGroupModels.PermissionGroupDocumentBase,
  filters: PermissionGroupModels.QueryParams; //todo make type once have all picture
  isLoading: boolean;
  page: number;
  perPage: number;
  isLastPage: boolean;
  isInitialLoaded: boolean;
  selectedEntity: PermissionModel.SelectedEntity;
  parentEntity: { [entityId: string]: string };
  organizationUsers: UserOrganizationDropDown[];
  selectedUser: Partial<User>
}

export const adapter: EntityAdapter<PermissionGroupModels.PermissionGroupDocumentBase> = createEntityAdapter<PermissionGroupModels.PermissionGroupDocumentBase>({
  selectId: (role: PermissionGroupModels.PermissionGroupDocumentBase) => role._id,
});


const initialState: PermissionGroupState = adapter.getInitialState({
  selectedPermissionGroup: null,
  filters: {
    query: null,
  },
  isLoading: false,
  page: 0,
  perPage: 20,
  isLastPage: false,
  isInitialLoaded: true,
  selectedEntity: null,
  parentEntity: {},
  organizationUsers: [],
  selectedUser: null,
});

export const permissionGroupStateReducer = createReducer(
  initialState,
  on(PermissionGroupsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(PermissionGroupsActions.setSelectedPermissionGroup, (state, { selectedPermissionGroup }) => {
    return {
      ...state,
      selectedPermissionGroup,
    };
  }),
  on(PermissionGroupsActions.getPermissionGroupsSuccess, (state, { permissionGroups }) => {
    return adapter.addMany(permissionGroups, {
      ...state,
    });
  }),
  on(PermissionGroupsActions.savePermissionGroupSuccess, (state, { permissionGroup }) => {
    const currentList = Object.values(state.entities);
    const newList = [permissionGroup, ...currentList];
    return adapter.setAll(newList, state);
  }),
  on(PermissionGroupsActions.updatePermissionGroupSuccess, (state, { permissionGroup }) => {
    return adapter.updateOne({
      id: permissionGroup._id,
      changes: {
        name: permissionGroup.name,
      },
    }, state);
  }),
  on(PermissionGroupsActions.resetEntities, (state) => {
    return adapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
    });
  }),
  on(PermissionGroupsActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(PermissionGroupsActions.removePermissionGroupSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  }),
  on(PermissionGroupsActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(PermissionGroupsActions.changeUsersSuccess, (state, { users }) => {
    return {
      ...state,
      selectedPermissionGroup: {
        ...state.selectedPermissionGroup,
        users,
      },
    };
  }),
  // on(PermissionGroupsActions.removeGroupUser, (state, { userId }) => {
  //   const groupUsers = { ...state.selectedPermissionGroup.users };
  //   delete groupUsers[userId];
  //   return {
  //     ...state,
  //     selectedPermissionGroup: {
  //       ...state.selectedPermissionGroup,
  //       users: groupUsers,
  //     },
  //   };
  // }),
  on(PermissionGroupsActions.changePermission, (state, { permission }) => {
    return {
      ...state,
      selectedPermissionGroup: selectPermission(permission, state.selectedPermissionGroup, state.selectedEntity, state.parentEntity),
    };
  }),
  on(PermissionGroupsActions.selectPermissionsByEntity, (state, { entity }) => {
    return {
      ...state,
      selectedPermissionGroup: selectPermissionByEntity(entity, state.selectedPermissionGroup, state.selectedEntity, state.parentEntity),
    };
  }),
  on(PermissionGroupsActions.selectAllPermissions, (state) => {
    return {
      ...state,
      selectedPermissionGroup: selectAllPermissions(state.selectedPermissionGroup, state.selectedEntity),
    };
  }),
  on(PermissionGroupsActions.setActiveEntity, (state, { selectedEntity }) => {
    return {
      ...state,
      selectedEntity: selectedEntity,
      selectedPermissionGroup: {
        ...state.selectedPermissionGroup,
      },
    };
  }),
  on(PermissionGroupsActions.makeInherited, (state) => {
    const entityPermissions = { ...state.selectedPermissionGroup.entityPermissions };
    delete entityPermissions[state.selectedEntity.id];
    return {
      ...state,
      selectedPermissionGroup: {
        ...state.selectedPermissionGroup,
        entityPermissions: entityPermissions,
      },
    };
  }),
  on(PermissionGroupsActions.registerParentStructure, (state, { entityId, parentId }) => {
    return {
      ...state,
      parentEntity: {
        ...state.parentEntity,
        [entityId]: parentId,
      },
    };
  }),
  on(PermissionGroupsActions.getOrganizationUsersSuccess, (state, { users }) => {
    return {
      ...state,
      organizationUsers: users,
    };
  }),
  on(PermissionGroupsActions.getSelectedUserPermissionsSuccess, (state, { selectedUserPermissions, user }) => {
    return {
      ...state,
      selectedPermissionGroup: {
        ...state.selectedPermissionGroup,
        ...selectedUserPermissions,
        systemGroupId: '12345', // hardcoded of any string, cause using template which required systemGroup idm but it does not impact to anything in this case
      },
      selectedUser: user,
    };
  }),
);

const selectPermission = (permission: PermissionModel.Permissions, selectedGroup: PermissionGroupModels.PermissionGroupDocumentBase, selectedEntity: SelectedEntity, parentEntity: {
  [entityId: string]: string
}): PermissionGroupModels.PermissionGroupDocumentBase => {
  if (!selectedEntity?.id) {
    const _permissions = [...selectedGroup.permissions] as PermissionModel.Permissions[];
    const updatedPermissions = removeArrayElementIfExists(_permissions, permission);
    return {
      ...selectedGroup,
      permissions: updatedPermissions,
    };
  } else {
    let _permissions = [];
    let selectedEntityPermission = {};
    // check if permissions are specified for this entity
    if (!selectedGroup.entityPermissions[selectedEntity.id]) {
      selectedEntityPermission = { ...createSpecialEntityPermissions(selectedEntity, selectedGroup, parentEntity) };
    } else {
      selectedEntityPermission = { ...selectedGroup.entityPermissions };
    }
    const permissionsFilteredByEntity = Object.keys(PermissionModel.entityPermissionMap[selectedEntity.type]);

    const parentPermissions = getParentPermissions(parentEntity, selectedEntity, selectedEntity.id, selectedGroup, permissionsFilteredByEntity);

    _permissions = [...selectedEntityPermission[selectedEntity.id]?.permissions];

    const updatedPermissions = removeArrayElementIfExists(_permissions, permission);

    const isInheritedSame = parentPermissions.length === updatedPermissions.length && parentPermissions.every(pPermision => updatedPermissions.includes(pPermision));

    if (isInheritedSame) {
      delete selectedEntityPermission[selectedEntity.id];
      return {
        ...selectedGroup,
        entityPermissions: selectedEntityPermission,
      };
    }
    return {
      ...selectedGroup,
      entityPermissions: {
        ...selectedGroup.entityPermissions,
        [selectedEntity.id]: {
          ...selectedEntityPermission[selectedEntity.id],
          permissions: updatedPermissions,
        },
      },

    };
  }
};

const selectPermissionByEntity = (entity: PermissionModel.PermissionListItem, selectedGroup: PermissionGroupModels.PermissionGroupDocumentBase, selectedEntity: SelectedEntity, parentEntity: {
                                    [entityId: string]: string
                                  },
): PermissionGroupModels.PermissionGroupDocumentBase => {
  const copySelectedGroup = { ...selectedGroup };
  const permissions = entity.permissions.map(p => p.value);
  const existingPermissions = getEntityPermissions(selectedEntity, selectedGroup, parentEntity);
  const isExistsAll = permissions.every(selectedPermission => existingPermissions.includes(selectedPermission));
  let updatedPermissions = [];
  if (isExistsAll) {
    updatedPermissions = existingPermissions.filter(function(el) {
      return !permissions.includes(el);
    });
  } else {
    updatedPermissions = [...new Set([...permissions, ...existingPermissions])];
  }
  if (selectedEntity) {
    return {
      ...copySelectedGroup,
      entityPermissions: {
        ...copySelectedGroup.entityPermissions,
        [selectedEntity.id]: {
          ...copySelectedGroup.entityPermissions[selectedEntity.id],
          permissions: updatedPermissions,
        },
      },
    };
  } else {
    return {
      ...copySelectedGroup,
      permissions: updatedPermissions,
    };
  }
};


const selectAllPermissions = (selectedGroup: PermissionGroupModels.PermissionGroupDocumentBase, selectedEntity: PermissionModel.SelectedEntity): PermissionGroupModels.PermissionGroupDocumentBase => {
  const cloneSelectedGroup = {
    ...selectedGroup,
  };

  const getUpdatedPermissions = (entityPermissionMap: Object, selectedPermissions: PermissionModel.Permissions[]): PermissionModel.Permissions[] => {
    let disabledPermissions: PermissionModel.Permissions[] = [];
    if (selectedEntity?.id) {
      disabledPermissions = PermissionModel.entityPermissionsDisabled[selectedEntity.type];
    }
    Object.values(entityPermissionMap)
      .forEach(item => item.forEach(p => {
        if (!disabledPermissions.includes(p.value)) {
          permissions.push(p.value);
        }
      }));
    const isExistsAll = permissions.every(selectedPermission => selectedPermissions.includes(selectedPermission));
    let updatedPermissions = [];
    if (isExistsAll) {
      updatedPermissions = [];
    } else {
      updatedPermissions = [...permissions];
    }
    return updatedPermissions;
  };

  const permissions: PermissionModel.Permissions[] = [];
  /**
   * if not entity selected it means is organization level
   */
  let entityPermissionMap = {};
  if (selectedEntity) {
    entityPermissionMap = PermissionModel.entityPermissionMap[selectedEntity.type];
    const existingEntityPermissions = cloneSelectedGroup.entityPermissions[selectedEntity.id] ? cloneSelectedGroup.entityPermissions[selectedEntity.id].permissions : [];
    const updatedPermissions = getUpdatedPermissions(entityPermissionMap, existingEntityPermissions);
    return {
      ...cloneSelectedGroup,
      entityPermissions: {
        ...cloneSelectedGroup.entityPermissions,
        [selectedEntity.id]: {
          ...cloneSelectedGroup.entityPermissions[selectedEntity.id],
          permissions: updatedPermissions,
        },
      },
    };
  } else {
    entityPermissionMap = PermissionModel.organizationPermissionListMap;
    const updatedPermissions = getUpdatedPermissions(entityPermissionMap, cloneSelectedGroup.permissions);
    return {
      ...cloneSelectedGroup,
      permissions: updatedPermissions,
    };
  }

};

const createSpecialEntityPermissions = (selectedEntity: SelectedEntity, selectedGroup: PermissionGroupModels.PermissionGroupDocumentBase,
                                        parentEntity: { [entityId: string]: string }): { [entityId: string]: PermissionGroupModels.EntityPermission } => {
  if (selectedEntity?.id && !selectedGroup.entityPermissions[selectedEntity.id]) {
    const entityPermissions: PermissionGroupModels.EntityPermission = {
      id: selectedEntity.id,
      type: selectedEntity.type,
      permissions: [],
    };
    const permissionsFilteredByEntity = Object.keys(PermissionModel.entityPermissionMap[selectedEntity.type]);
    entityPermissions.permissions = getParentPermissions(parentEntity, selectedEntity, selectedEntity.id, selectedGroup, permissionsFilteredByEntity);
    return {
      ...selectedGroup.entityPermissions,
      [selectedEntity.id]: entityPermissions,
    };
  }
  return selectedGroup.entityPermissions;
};

export const getParentPermissions = (parentEntity: {
  [entityId: string]: string,
}, selectedEntity: SelectedEntity, entityId: string, selectedPermissionGroup: PermissionGroupModels.PermissionGroupDocumentBase, filterByEntities: string[]): PermissionModel.Permissions[] => {
  const parentId = parentEntity[entityId];
  let result: PermissionModel.Permissions[] = [];
  if (!parentId) {
    result = selectedPermissionGroup.permissions;
  } else {
    if (!selectedPermissionGroup.entityPermissions[parentId]) {
      result = getParentPermissions(parentEntity, selectedEntity, parentId, selectedPermissionGroup, filterByEntities);
    } else {
      result = selectedPermissionGroup.entityPermissions[parentId].permissions;
    }
  }

  return result.filter(permission => {
    const disabledPermissions = PermissionModel.entityPermissionsDisabled[selectedEntity.type];
    const split = permission.split(/(?=[A-Z])/);
    return filterByEntities.includes(split[0].toLowerCase()) && !disabledPermissions.includes(permission);
  });
};


export const getEntityPermissions = (selectedEntity: SelectedEntity, selectedPermissionGroup: PermissionGroupModels.PermissionGroupDocumentBase, parentEntity: {
  [entityId: string]: string,
}) => {
  if (selectedEntity) {
    if (selectedPermissionGroup.entityPermissions[selectedEntity.id]) {
      return selectedPermissionGroup.entityPermissions[selectedEntity.id].permissions;
    } else {
      const permissionsFilteredByEntity = Object.keys(PermissionModel.entityPermissionMap[selectedEntity.type]);
      return getParentPermissions(parentEntity, selectedEntity, selectedEntity.id, selectedPermissionGroup, permissionsFilteredByEntity);
    }
  } else {
    return selectedPermissionGroup.permissions;
  }
};
