import { Pipe, PipeTransform } from '@angular/core';
import { UiLocationSelectItem } from '@models/ui.model';
import { LocationModel } from '../locations/location.model';

@Pipe({
  name: 'locationAddress',
})
export class locationAddressPipe implements PipeTransform {
  transform(location: UiLocationSelectItem | LocationModel.LocationItem, locationName: boolean = true): string {
    if (location) {
      let address = `${location.address}, ${location.city}, ${location.state}`.trim()
        .replace(/^,/, '')
        .replace(/,\s*$/, '');
      if (!locationName) {
        return address;
      }
      return `${location.name} (${address})`;
    } else {
      return 'Location does not have an address';
    }
  }
}
