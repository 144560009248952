import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserDeviceModel } from '@models/user-device.model';
import { UserDeviceActions } from '@states/device/device.action-types';
import { updateDeviceStore } from '@states/device/device.actions';


export interface UserDeviceState extends EntityState<UserDeviceModel.Document> {
  isInitialLoaded: boolean;
}

export const adapter: EntityAdapter<UserDeviceModel.Document> = createEntityAdapter<UserDeviceModel.Document>({
  selectId: (document: UserDeviceModel.Document) => document._id,
});


const initialState: UserDeviceState = adapter.getInitialState({
  isInitialLoaded: false,
});

export const userDeviceStateReducer = createReducer(
  initialState,
  on(UserDeviceActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(UserDeviceActions.getUserDevicesSuccess, (state, { documents }) => {
    return adapter.setAll(documents, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(UserDeviceActions.getUserDevicesFail, (state) => {
    return {
      ...state,
      isInitialLoaded: true,
    };
  }),
  on(UserDeviceActions.updateDeviceStore, (state, { document, _id }) => {
    return adapter.updateOne({
      id: _id,
      changes: {
        ...document,
      },
    }, {
      ...state,
    });
  }),
);
