import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WallV2Model } from '@models/wall-v2.model';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { JsonParseIfValid } from '../helpers/common.helpers';
import { Dictionary } from '@ngrx/entity/src/models';

@Injectable()
export class WallV2Service {

  constructor(private http: HttpClient) {
  }

  public create(dto: WallV2Model.WallCreateDto): Observable<WallV2Model.WallMongoDocument> {
    return this.http.post<WallV2Model.WallMongoDocument>(api.wallV2.crud, dto);
  }

  public update(wallToUpdate: WallV2Model.WallMongoDocument): Observable<any> {
    return this.http.put(`${api.wallV2.crud}/${wallToUpdate._id}`, wallToUpdate);
  }

  public getAllByFilters(queryParams: WallV2Model.QueryFilter, page: number, size: number): Observable<WallV2Model.WallMongoDocument[]> {
    let url = `${api.wallV2.crud}?`;
    if (queryParams.query) {
      url += `query=${queryParams.query}&`;
    }
    if (queryParams.isPrivate) {
      const isPrivate = JsonParseIfValid<boolean[]>(queryParams.isPrivate);
      if (isPrivate)
        url += `isPrivate=${isPrivate.join(',')}&`;
    }

    if (queryParams.cameras) {
      const cameras = JsonParseIfValid<Dictionary<WallV2Model.SelectedCamera>>(queryParams.cameras);
      if (cameras && Object.keys(cameras)?.length) {
        url += `cameras=${Object.keys(cameras)}&`;
      }
    }
    url += `page=${page}&size=${size}`;

    return this.http.get<WallV2Model.WallMongoDocument[]>(url);
  }

  public getById(id: string): Observable<WallV2Model.WallMongoDocument> {
    return this.http.get<WallV2Model.WallMongoDocument>(`${api.wallV2.crud}/${id}`);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${api.wallV2.crud}/${id}`);
  }

  public getAppleTvWalls(): Observable<WallV2Model.WallMongoDocument[]> {
    return this.http.get<WallV2Model.WallMongoDocument[]>(api.appleTv.getWallDropdown);
  }


}
