import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, take } from 'rxjs';
import { AlertMonitoringViewModel } from '@models/alert-monitoring.model';
import * as AlertMonitoringSelectors from '@states/alert-monitoring/alert-monitoring.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { UiBreadCrumbItem } from '../../../../shared/ui-kit/ui-header/ui-header.component';
import { PreloaderColor, UiCalendarPickerType } from '@enums/shared.enum';
import { formatDate } from '@angular/common';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { Router } from '@angular/router';
import { routerSegments } from '@consts/routes';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { analyticClassName } from '@consts/alert-events.const';
import { ShowFiltersModalComponent } from '../../../../shared/modals/show-filters-modal/show-filters-modal.component';
import { SharedActions } from '@states/shared/shared.action-types';
import { ConfirmDialogType } from '../../../../shared/confirm-dialog/confirm-dialog.model';
import { ofType } from '@ngrx/effects';
import { SharedEffects } from '@effects/shared.effects';
import { AppUser } from '../../../../user/user.model';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { ActiveOrganization } from '@models/organization.model';
import { AlertV2TypeGroup } from '@consts/alerts-v2.const';
import { StepOptionSelectorGroupEntry } from '../../../alerts-v2/components/step-option-selector/step-option-selector.component';
import { features } from '@consts/text.const';
import User = AppUser.User;
import { UserSelectors } from '@states/user/user.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-view-list',
  templateUrl: './view-list.component.html',
  styleUrls: ['./view-list.component.scss'],
})
export class ViewListComponent implements OnInit, OnDestroy {

  public noData = features.alertsViewList.noData;
  public noResults = features.alertsViewList.noResults;

  public selectViews$: Observable<AlertMonitoringViewModel[]> = this.store$.select(AlertMonitoringSelectors.selectViews);
  public selectUser$: Observable<User> = this.store$.select(UserSelectors.selectUser);
  public selectOrganization$: Observable<ActiveOrganization> = this.store$.select(OrganizationSelectors.selectActiveOrganization);
  public selectSearchQuery$: Observable<string> = this.store$.select(AlertMonitoringSelectors.selectSearchQuery);
  public selectIsFirstLoad$: Observable<boolean> = this.store$.select(AlertMonitoringSelectors.selectIsFirstLoad);

  public AlertV2TypeGroup = AlertV2TypeGroup;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  public displayedColumns = ['name', 'created', 'filters', 'actions'];
  public breadCrumb: UiBreadCrumbItem[] = [
    { name: 'Alerts', route: `../` },
    { name: 'Saved Alerts' },
  ];

  public preloaderColor = PreloaderColor;
  public itemSize = 72;

  constructor(private store$: Store<AppState>,
              private router: Router,
              private dialog: MatDialog,
              private sharedEffects$: SharedEffects) {
  }

  ngOnInit(): void {
    this.store$.dispatch(AlertMonitoringActions.resetToInitialState());
    this.store$.dispatch(AlertMonitoringActions.getAlertMonitoringViews({ query: null }));
    this.sharedEffects$.confirmation$
      .pipe(
        untilDestroyed(this),
        ofType(SharedActions.showConfirmModalResultConfirm),
      )
      .subscribe(result => {
        this.store$.dispatch(
          AlertMonitoringActions.deleteView({ id: result.params['id'] }),
        );
      });
  }

  objectChips(savedSearch: AlertMonitoringViewModel, showAll: boolean = false) {
    const dateTimeRange = savedSearch.dateRange;
    let timeRange = '';

    if (!dateTimeRange) {
      timeRange = 'Live';
    } else if (dateTimeRange.type === UiCalendarPickerType.RELATIVE) {
      timeRange = 'Last ' + dateTimeRange?.relative?.value + ' ' + dateTimeRange?.relative?.unit;
    } else {
      const start = formatDate(dateTimeRange?.absolute?.start, 'YYYY-MM-dd HH:mm', 'en-US');
      const end = formatDate(dateTimeRange?.absolute?.end, 'YYYY-MM-dd HH:mm', 'en-US');
      timeRange = 'Between ' + start + ' and ' + end;
    }
    const chips = new Set<string>();

    savedSearch?.trackedObjects.forEach(trackedObj => {
      chips.add(`${analyticClassName[trackedObj]}`);
    });

    savedSearch?.flowTypes?.forEach(trackedObj => {
      const options: StepOptionSelectorGroupEntry[] = AlertV2TypeGroup[trackedObj.category]?.options;
      const name = options?.filter((option) => option?.value?.type === trackedObj.flowType)[0]?.name;
      chips.add(`${name}`);
    });

    savedSearch?.events?.forEach(event => {
      chips.add(`${event.name}`);
    });

    for(let camera of savedSearch.selectedCameras) {
      this.getCameraName(camera.cameraId)
        .subscribe(name => {
          if (name) {
            chips.add(name);
          }
        });
    }
    chips.add(timeRange);

    if (savedSearch?.acknowledges?.length) {
      if (savedSearch?.acknowledges.includes(true)) {
        chips.add('Acknowledged alerts');
      }
      if (savedSearch?.acknowledges?.includes(false)) {
        chips.add('Not acknowledged alerts');
      }
    }


    if (showAll) {
      return chips;
    } else {
      return chips.size <= 4
        ? chips
        : Array.from(chips)
          .slice(0, 4)
          .concat([` + ${chips.size - 4} More`]);
    }
  }

  getCameraName(cameraId: string) {
    return this.store$.select(CameraSelectors.selectCameraNameById(cameraId))
      .pipe(take(1));
  }

  public openView(view: AlertMonitoringViewModel) {
    this.router.navigate(['../', routerSegments.monitoring, routerSegments.view, view._id]);
  }

  public search(query: string) {
    this.store$.dispatch(AlertMonitoringActions.resetToInitialState());
    this.store$.dispatch(AlertMonitoringActions.getAlertMonitoringViews({ query }));
    this.store$.dispatch(AlertMonitoringActions.setQuery({ query }));
  }

  public onScroll(ev): void {
    if (ev.target.offsetHeight + ev.target.scrollTop >= ev.target.scrollHeight) {
      this.store$.dispatch(AlertMonitoringActions.nextPageViewList());
      this.store$.dispatch(AlertMonitoringActions.getAlertMonitoringViews({ query: null }));
    }
  }

  public openFilters(selectedSavedSearch: AlertMonitoringViewModel): void {
    this.dialog.open(ShowFiltersModalComponent, {
      width: '400px',
      panelClass: 'modal-no-padding',
      data: {
        title: selectedSavedSearch.name,
        filters: this.objectChips(selectedSavedSearch, true),
      },
    });
  }

  public delete(view: AlertMonitoringViewModel) {
    this.store$.dispatch(SharedActions.showConfirmModal({
      options: {
        type: ConfirmDialogType.CONFIRM,
        msg: `Are you sure you want to delete ${view.name}?`,
        title: `Delete`,
        confirm: 'Yes',
        disableClose: true,
        params: {
          id: view._id,
          isView: true,
        },
      },
    }));
  }

  public goToAlertsMonitoring(): void {
    this.router.navigate([routerSegments.monitoring, routerSegments.view]);
  }

  public ngOnDestroy() {
    this.store$.dispatch(AlertMonitoringActions.cancelNetworkRequests());
  }

}
