import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticPulsationState } from './anaytic-heartbeat-pulsation.reducer';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { Dictionary } from '@ngrx/entity/src/models';
import { entityStatusByEdgeStatus } from '../../../helpers/common.helpers';
import { selectEdgeStatus } from '@states/device-status/device-status.selectors';

export const selectAnalyticStatusState = createFeatureSelector<AnalyticPulsationState>('analyticPulsationState');

export const selectAnalyticStatusByCameraId = (cameraId: string) =>
  createSelector(selectAnalyticStatusState, (cameras: AnalyticPulsationState) => cameras.entities[cameraId]);

export const selectAnalyticsEntities = createSelector(selectAnalyticStatusState, selectEdgeStatus, (cameras: AnalyticPulsationState, edges: Dictionary<ComponentStatusDisplay>) => {
  const cameraEntitiesIds = Object.keys(cameras.entities);
  const cameraStatusesWithEdgeStatuses: Dictionary<PulsationModels.Store.CameraPulsation> = {};
  cameraEntitiesIds.forEach(cameraId => {
    cameraStatusesWithEdgeStatuses[cameraId] = {
      ...cameras.entities[cameraId],
      status: entityStatusByEdgeStatus(cameraId, edges, cameras),
    };
  });
  return cameraStatusesWithEdgeStatuses;
});

export const selectAnalyticStatusByCameraIdV2 = (cameraId: string) =>
  createSelector(selectEdgeStatus, selectAnalyticStatusState, (edges: Dictionary<ComponentStatusDisplay>, cameras: AnalyticPulsationState) => {
    return entityStatusByEdgeStatus(cameraId, edges, cameras);
  });
