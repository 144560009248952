import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Thumbnail, ThumbnailEntity } from '../../cameras/camera-thumbnails/camera-thumbnails.model';
import { FadeInAnimation } from '../../framework/animations';
import { KeyValuePairs } from '../../core/interfaces';
import { filter, interval, lastValueFrom, mergeMap, Observable, skip, Subscription, takeWhile, tap } from 'rxjs';
import { SearchService } from '../search.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { MatDialog } from '@angular/material/dialog';
import { Search } from '../search.model';
import { CameraThumbnailsSort } from '../../cameras/camera-thumbnails/camera-thumbnails.component';
import { map } from 'rxjs/operators';
import { Sort } from '../shared.model';
import { SearchQueryOperator, SearchSelection } from '../advanced-search/advanced-search.model';
import { HttpService } from '../../core/http.service';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { EdgeCamera } from '../../cameras/camera.model';
import * as SharedActions from '@states/shared/shared.actions';
import { LocationSelectors } from '@states/location/location.selector-types';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-multi-search-results',
  templateUrl: './multi-search-results.component.html',
  styleUrls: ['./multi-search-results.component.scss'],
  animations: [FadeInAnimation],
})
export class MultiSearchResultsComponent implements OnInit, OnDestroy, OnChanges {
  public selectObjectSelectionsFormattedAndOuterOperator$: Observable<{
    objectSelectionsFormatted: any;
    outerOperator: SearchQueryOperator;
  }> = this.store.pipe(select(MultiSearchSelectors.selectObjectSelectionsFormattedAndOuterOperator));

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  edgeId: string | undefined;

  @Input()
  cameraId: string | undefined;

  @Input()
  cameraIds: string[];

  @Input()
  type;

  @Input()
  active;

  @Input()
  alpr = {};

  searchSelections: SearchSelection[];

  @Input()
  startInput;

  @Input()
  endInput;

  start;
  end;

  @Input()
  sensitivity = 50;

  @Input()
  timezone: string;

  @Input()
  sort: CameraThumbnailsSort = CameraThumbnailsSort.DESC;

  /**
   * Search related variables
   */
  @ViewChild('startPicker')
  startPicker: ElementRef;

  @ViewChild('endPicker')
  endPicker: ElementRef;

  @ViewChild('scroller')
  scroller: ElementRef;

  events: KeyValuePairs<number[]> = {};

  results: Search.MultiAnalytic[] = [];
  duration = 2000;
  rendered: Thumbnail[] = [];
  thumbs: Thumbnail[][] = [];
  fetching = false;

  loader = false;
  public loadingMore = false;
  more = true;
  started = false;

  markedIdx$: Observable<number[] | undefined>;
  markedIdx: number[] | undefined;

  // Infinite scrolling values
  page = 0;
  size = 10;

  subscriptions: Subscription[] = [];

  latestForPage: Search.MultiAnalyticsInfo[][] = [];
  latestTs: number;
  rtl = false;
  initMsg = true;

  cameras: EdgeCamera.CameraItem[] = [];
  currentSearchCameras: Search.SearchCamera[] = [];
  private outerOperator: SearchQueryOperator = SearchQueryOperator.OR;

  scrollIndex = 0;

  constructor(
    private searchService: SearchService,
    private cameraThumbnailsService: CamerasThumbnailsService,
    private dialog: MatDialog,
    public cd: ChangeDetectorRef,
    public httpService: HttpService,
    private store: Store,
  ) {
  }

  public top() {
    if (!this.scroller?.nativeElement?.scrollTop) {
      return true;
    }
    return this.scroller.nativeElement.scrollTop < 5;
  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.store
      .select(MultiSearchSelectors.selectSelectedCameras)
      .pipe(
        untilDestroyed(this),
        tap(cameras => {
          this.cameras = cameras ?? [];
        }),
        filter(cameras => !!cameras.length),
        mergeMap(_ => this.store.select(LocationSelectors.selectLocationById(this.cameras[0]?.locationId))),
        tap(location => {
          this.timezone = location.timezone;
        }),
      )
      .subscribe();

    this.selectObjectSelectionsFormattedAndOuterOperator$.pipe(untilDestroyed(this), skip(1))
      .subscribe(res => {
        if (res) {
          this.searchSelections = res.objectSelectionsFormatted;
          this.outerOperator = res.outerOperator;
          if (!!this.cameras?.length) {
            this.search();
          } else {
            this.store.dispatch(SharedActions.showMessage({warning: 'Please select at least one camera and search again'}));
          }
        }
      });
  }

  getEvents(base: number, start: number, end: number, edgeId: string, cameraId: string): Observable<ThumbnailEntity[]> {
    return this.cameraThumbnailsService.getThumbnailsByDateFromDb(edgeId, cameraId, start, end);
  }

  normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  populateThumbArray(
    edgeId: string,
    cameraId: string,
    startTime: number,
    endTime: number,
    searchEvents?: number[][],
    bestImage?: Search.SearchObject[],
  ): number[] {
    let thumbnails: number[] = [];
    const base = this.getBaseInLocale(new Date(startTime));
    const keyBase = `${edgeId}-${cameraId}-${base}`;
    const baseEnd = this.getBaseInLocale(new Date(endTime));
    const keyBaseEnd = `${edgeId}-${cameraId}-${baseEnd}`;
    const clipInSeconds = (endTime - startTime) / 1000;

    if (startTime >= endTime) {
      endTime = [startTime, (startTime = endTime)][0];
    }

    startTime -= 10000;
    endTime += 10000;

    if (!!this.events[keyBase] || !!this.events[keyBaseEnd]) {
      if (base !== baseEnd && baseEnd !== endTime) {
        const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
        const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, baseEnd);
        // We need to combine 2 days to one array
        let first: number[] = [];
        let second: number[] = [];
        if (!!this.events[keyBase]) {
          first = this.events[keyBase].slice(indexStart, this.events[keyBase].length);
        }
        if (!!this.events[keyBaseEnd]) {
          second = this.events[keyBaseEnd].slice(0, indexEnd);
        }
        thumbnails = first.concat(second);
      } else {
        if (!!this.events[keyBase]) {
          const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
          const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, base);
          thumbnails = this.events[keyBase].slice(indexStart, indexEnd);
        }
      }
    }

    this.rendered.push({
      thumbnails,
      searchEvents,
      objects: bestImage,
      defaultThumb: !!searchEvents.length ? `thumbnail-${searchEvents[0][0]}-0-0.jpg` : undefined,
      options: {
        startTime,
        endTime,
        duration: this.duration,
        edgeId,
        cameraId,
        // base: !this.events[base] && !!this.events[baseEnd] ? baseEnd : undefined,
        clipInSeconds,
        offsetResInDurations: 1,
      },
    });
    return thumbnails;
  }

  async render(results: Search.MultiAnalytic[]) {
    this.results.push(...results);
    for (let [idx, res] of results.entries()) {
      const searchEvents: number[][] = res.searchEvents;
      const bestImage: Search.SearchObject[] = res.bestImage;
      const startTime = this.normalizeTimestamp(res.window[0]);
      const endTime = this.normalizeTimestamp(res.window[1]);
      const edgeId = res.edgeId;
      const cameraId = res.cameraId;
      const base = this.getBaseInLocale(new Date(startTime));
      const key = `${edgeId}-${cameraId}-${base}`;
      let thumbnails: number[] = [];
      // TODO: Might need to change the logic here, we want to reduce the number of requests to minimum
      if (!this.events[key]) {
        this.loadingMore = true;
        const list = await lastValueFrom(this.getEvents(base, base, endTime, edgeId, cameraId));
        this.loadingMore = false;
        for (let thumb of list) {
          // Compute only if doesn't already exist
          if (!this.events[key]) {
            const events: number[] = [];
            for (let index = 0; index < 5400; index++) {
              let val = thumb.events[index];
              if (val) {
              }
              events.push(val & 0x0000000f);
              events.push((val & 0x000000f0) >> (4 * 1));
              events.push((val & 0x00000f00) >> (4 * 2));
              events.push((val & 0x0000f000) >> (4 * 3));
              events.push((val & 0x000f0000) >> (4 * 4));
              events.push((val & 0x00f00000) >> (4 * 5));
              events.push((val & 0x0f000000) >> (4 * 6));
              events.push((val & 0xf0000000) >> (4 * 7));
            }
            this.events[key] = events;
          }
        }
        this.populateThumbArray(edgeId, cameraId, startTime, endTime, !!searchEvents ? searchEvents : undefined, bestImage);
      } else {
        this.populateThumbArray(edgeId, cameraId, startTime, endTime, !!searchEvents ? searchEvents : undefined, bestImage);
      }
    }
    this.rendered.sort((a, b) => {
      return this.sort === CameraThumbnailsSort.DESC
        ? b.options.startTime - a.options.startTime
        : a.options.startTime - b.options.startTime;
    });
    this.thumbs = _.chunk(this.rendered, 3);
    this.cd.detectChanges();
  }

  isMore(info: Search.MultiAnalyticsInfo[]) {
    for (let i of info) {
      if (!!i.more) {
        return true;
      }
    }
    this.fetching = false;
    this.loadingMore = false;
    return false;
  }

  async parseSearch(page: number, res: Search.MultiAnalyticResponse) {
    // In case there was no movement / analytics in the timeframe
    // we will receive an empty result, but we still have more to go
    this.loader = false;
    this.latestForPage[page] = res?.info!;
    await this.render(res.results);
    this.more = this.isMore(res?.info);
    if (
      this.scrollIndex >= this.thumbs.length - 3 &&
      (this.thumbs[this.thumbs.length - 1]?.length < 3 || this.thumbs.length < 6) &&
      this.more
    ) {
      this.fetching = false;
      this.onScrollDown();
    } else {
      this.loadingMore = false;
    }
  }

  getLatestInfos(page: number): Promise<Search.MultiAnalyticsInfo[]> {
    return lastValueFrom(
      interval(1000)
        .pipe(
          map(() => {
            return this.latestForPage[page];
          }),
          takeWhile(res => res === undefined, true),
        ),
    );
  }

  async searchAnalytic(page: number, sort: Sort, startTs: number, endTs: number) {
    if (page === 0) {
      this.latestForPage = [];
    } else {
      await this.getLatestInfos(page - 1);
      const latestInfos = this.latestForPage[page - 1];
      // Filter out cameras with more = false;
      for (let info of latestInfos) {
        if (!info.more) {
          this.currentSearchCameras = this.currentSearchCameras.filter(i => i.cameraId !== info.cameraId);
        }
      }
      // Set timestamp paging for each camera
      for (let camera of this.currentSearchCameras) {
        const idx = this.latestForPage[page - 1].findIndex(info => info.cameraId === camera.cameraId);
        const latestTs = this.latestForPage[page - 1][idx]?.latestTs;
        camera.start = sort === Sort.ASC ? latestTs : startTs;
        camera.end = sort === Sort.DESC ? latestTs : endTs;
      }
    }

    const searchRequest: Search.MultiAnalyticSearchRequest = {
      cameras: this.currentSearchCameras,
      // searchSelections: this.searchSelections,
      operator: this.outerOperator,
    };

    this.searchService.multiSearchAnalytic(0, this.size, sort, searchRequest)
      .subscribe((res: Search.MultiAnalyticResponse) => {
        this.parseSearch(page, res);
      });
  }

  isSelection() {
    return !!this.searchSelections && !!this.searchSelections[0]?.type;
  }

  public search(page: number = 0) {
    const startTs = new Date(this.start).getTime();
    const endTs = new Date(this.end).getTime();

    if (page === 0) {
      this.initMsg = false;
      this.currentSearchCameras = this.cameras.map(camera => {
        return {edgeId: camera.edgeId, cameraId: camera.edgeOnly.cameraId, start: startTs, end: endTs};
      });
      this.httpService.cancelPendingRequests();
      this.loader = true;
      this.page = 0;
      this.results = [];
      this.rendered = [];
      this.thumbs = [];
      this.more = true;
      this.started = true;
      this.latestForPage = [];
    } else {
      this.loadingMore = true;
    }

    this.cameraThumbnailsService.setThumbnailsData({
      timezone: this.timezone,
    });
    const sort = this.sort === CameraThumbnailsSort.DESC ? Sort.DESC : Sort.ASC;
    this.fetching = true;
    this.searchAnalytic(page, sort, startTs, endTs);
  }

  public onScrollDown() {
    if (!this.started) {
      return;
    }
    if (!this.more) {
      this.loadingMore = false;
      return;
    }
    this.search(++this.page);
  }

  onDateChanged() {
    if (!!this.start && !!this.end && new Date(this.start).getTime() < new Date(this.end).getTime()) {
    }
  }

  clear() {
    this.page = 0;
    this.results = [];
    this.rendered = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startInput']) {
      this.start = changes['startInput'].currentValue;
    }
    if (changes['endInput']) {
      this.end = changes['endInput'].currentValue;
    }

    if (changes.hasOwnProperty('active')) {
      if (!changes['active']?.currentValue) {
        if (!!changes['active'].previousValue) {
          this.clear();
          this.initMsg = true;
        }
        return;
      }
    }

    if (changes.hasOwnProperty('sort')) {
      if (!!changes['sort']?.previousValue || changes['sort']?.previousValue === 0) {
        this.rtl = changes['sort'].currentValue === CameraThumbnailsSort.DESC ? true : false;
        this.search();
      }
    }

    if (changes['startInput']) {
      this.onDateChanged();
    }
    if (changes['endInput']) {
      this.onDateChanged();
    }
  }

  scrolledIndexChange(index: number) {
    this.scrollIndex = index;
    const currentLength = this.thumbs.length;
    if (index >= currentLength - 5) {
      this.onScrollDown();
    }
  }
}
