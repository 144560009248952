import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Search } from '../../search.model';
import { debounceTime } from 'rxjs';
import { ChangeContext } from 'ngx-slider-v2';

@Component({
  selector: 'ui-motion',
  templateUrl: './ui-motion.component.html',
  styleUrls: ['./ui-motion.component.scss'],
})
export class UiMotionComponent implements OnInit {

  @Output() changed = new EventEmitter<Search.MotionSearchParams>();

  minMotion = 50;

  public form: UntypedFormGroup = new UntypedFormGroup({
    sensitivityEnabled: new UntypedFormControl(true),
    minMotion: new UntypedFormControl(50),
  });

  constructor() {
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(1000))
      .subscribe(
        (value) => {
          this.changed.emit(value);
        });
  }

  public updateMinMotion(event: ChangeContext) {
    this.form.patchValue({ minMotion: event.value });
  }


}
