<div #wrapper fxLayout="column" fxFlex="" class="w-100" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="showChips" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="chips">
      <span class="reset-button ui-icon-reset" (click)="resetFilters.emit()"
            matTooltip="Reset filters" matTooltipClass="sidebar-tooltip"
            [class.disabled]="initMsg || fetching || !started"
      >

      </span>
      <ui-filter-chip *ngIf="motionSearch"
                      class="filter-chip"
                      title="Motion search"
                      [allowDelete]="false"
      ></ui-filter-chip>
      <ui-filter-chip *ngIf="unusualEventSearch"
                      class="filter-chip"
                      title="Unusual event search"
                      [allowDelete]="false"
      ></ui-filter-chip>
      <ui-filter-chip *ngIf="customEventSearch"
                      class="filter-chip"
                      title="Event tags search"
                      [allowDelete]="false"
      ></ui-filter-chip>

      <ng-container *ngrxLet="selectDateTimeRange; let dateTimeRange">
        <ui-filter-chip *ngIf="dateTimeRange"
                        class="filter-chip"
                        [title]="dateTimeRange.type === UiCalendarPickerType.RELATIVE ?
                      'Last ' + dateTimeRange.relative.value + ' ' + dateTimeRange.relative.unit :
                      'Between ' + dateTimeRange.absolute.start + ' and ' + dateTimeRange.absolute.end"
                        [allowDelete]="false"
        ></ui-filter-chip>
      </ng-container>
      <ng-container *ngIf="!motionSearch && !customEventSearch && !unusualEventSearch">
        <ng-container *ngrxLet="selectPrecision$; let precision">
          <ui-filter-chip
            class="filter-chip"
            [title]="precisionStr[precision] + ' precision'"
            [allowDelete]="false"
          ></ui-filter-chip>
        </ng-container>
        <ng-container *ngrxLet="selectFaces$; let faces">
          <ui-filter-chip *ngIf="faces?.objects?.length"
                          class="filter-chip"
                          [title]="faces?.objects?.length + ' Faces'"
                          [allowDelete]="false"
          ></ui-filter-chip>
        </ng-container>
        <ng-container *ngFor="let object of objectChips">
          <div [matMenuTriggerFor]="action" (click)="getPropertiesChips(object.index)">
            <ui-filter-chip
              class="filter-chip"
              [title]="replaceBottomLine(object.type) + ' ' + object.typeIndex | titlecase"
              [allowDelete]="true"
              (onRemoveClicked)="removeObject(object.index)"
            ></ui-filter-chip>
          </div>
          <mat-menu #action="matMenu" xPosition="after" yPosition="below" class="property-chips-wrapper">
            <div fxLayout="row wrap" fxLayoutGap="8px grid" class="property-chips">
              <span *ngIf="!propertyChips.length">No properties</span>
              <ng-container *ngFor="let property of propertyChips">
                <ng-container [ngSwitch]="property.object">
                  <ng-container *ngSwitchCase="SearchObjectTypes.PERSON">

                    <ng-container *ngIf="property.key === 'group'">
                      <ng-container *ngrxLet="selectPersonById(property.value); let person">
                        <ui-filter-chip
                          class="filter-chip"
                          [title]="person.name | titlecase"
                          [allowDelete]="false"
                        ></ui-filter-chip>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let prop of property.props">
                      <ui-filter-chip *ngIf="!!prop[1] && prop[0] !== 'colors' && prop[0] !== 'type'"
                                      class="filter-chip"
                                      [title]="prop[0] | titlecase"
                                      [allowDelete]="true"
                                      (onRemoveClicked)="removeProperty(object.index, property, prop[0]);"
                      ></ui-filter-chip>
                    </ng-container>
                    <ng-container *ngFor="let color of property.colors">
                      <ui-filter-chip
                        class="filter-chip"
                        [title]="color + ' ' + personSelectionFormFieldsTitles[property.key] | titlecase"
                        [allowDelete]="true"
                        (onRemoveClicked)="removeProperty(object.index, property, color)"
                      ></ui-filter-chip>
                    </ng-container>
                    <ng-container *ngFor="let type of property.types">
                      <ui-filter-chip
                        class="filter-chip"
                        [title]="type.split('_').join(' ') | titlecase"
                        [allowDelete]="true"
                        (onRemoveClicked)="removeProperty(object.index, property, type)"
                      ></ui-filter-chip>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="SearchObjectTypes.VEHICLE">
                    <ng-container [ngSwitch]="property.key">
                      <ng-container *ngSwitchCase="'additionalProperties'"></ng-container>
                      <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="property.value.enabled">
                          <ui-filter-chip *ngFor="let prop of property.value.value"
                                          class="filter-chip"
                                          [title]="prop | titlecase"
                                          [allowDelete]="true"
                                          (onRemoveClicked)="removeProperty(object.index, property, prop)"
                          ></ui-filter-chip>
                          <!--                      {{ property.value | json }}-->
                        </ng-container>
                      </ng-container>
                    </ng-container>

                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </mat-menu>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!!cameras?.length">
        <div [matMenuTriggerFor]="camerasMenu">
          <ui-filter-chip
            class="filter-chip"
            [title]="cameras.length + ' cameras'"
            [allowDelete]="false"
          ></ui-filter-chip>
        </div>
        <mat-menu #camerasMenu="matMenu" xPosition="after" yPosition="below" class="property-chips-wrapper">
          <div fxLayout="row wrap" fxLayoutGap="8px grid" class="property-chips">
            <ui-filter-chip *ngFor="let camera of cameras"
                            class="filter-chip"
                            [title]="camera?.edgeOnly?.name"
                            [allowDelete]="true"
                            (onRemoveClicked)="removeCamera(camera?.edgeOnly.cameraId)"
            ></ui-filter-chip>
          </div>
        </mat-menu>
      </ng-container>
    </div>
    <!--    <div *ngIf="multiSearch" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">-->
    <!--      <ui-toggle [(ngModel)]="showObjects"></ui-toggle>-->
    <!--      <div style="min-width: 100px;" class="opacity-70">Objects view</div>-->
    <!--    </div>-->
    <div *ngIf="!multiSearch && !hideControls"></div>
    <!--    <ui-button *ngIf="!multiTracker" type="secondary" class="refresh-button" (click)="refresh.emit()">Refresh results-->
    <!--    </ui-button>-->
    <span class="refresh-button ui-icon-forward" *ngIf="!multiTracker && !hideControls"
          [class.disabled]="initMsg || fetching || !started"
          matTooltip="Refresh results" matTooltipClass="sidebar-tooltip" (click)="refresh.emit()"
    >
    </span>
    <div *ngIf="hideControls" fxLayout="row" class="search-results-count">
      {{ count | number }} Search results
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         class="show-objects-wrapper p-r mr-20"
         [class.tracker]="multiTracker"
         (click)="toggleShowObjects()"
    >
      <div class="show-objects-toggle" [class.selected]="showObjects"></div>
      <div fxLayoutAlign="center center" fxFlex="" class="show-objects-label" [class.selected]="!showObjects">Clips
      </div>
      <div fxLayoutAlign="center center" fxFlex="" class="show-objects-label" [class.selected]="showObjects">Objects
      </div>
    </div>
  </div>
  <div *ngIf="!hideControls" fxLayout="row" class="search-results-count">
    {{ count | number }} Search results
  </div>
  <div *ngIf="multiTracker">
    <!--    <ng-container *ngrxLet="trackObject$; let trackObject">-->
    <!--      Tracking Object {{trackObject.idBase}} : {{trackObject.idIndex}}-->
    <!--      &lt;!&ndash;      {{trackObject | json}}&ndash;&gt;-->
    <!--    </ng-container>-->
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="h-100" *ngIf="initMsg">
    <img *ngIf="multiSearch" class="pin-img mb-10" src="assets/no-locations.svg"/>
    <div style="font-size: 16px; font-weight: 400;" class="init-msg">
      <!--      Please select time range{{motionSearch ? ' and ' : ', '}}-->
      <!--      cameras {{ motionSearch ? '' : customEventSearch ? 'and event type' : 'and object to start searching' }}-->
      Please select time range, cameras and filter to start searching
    </div>
    <!--    <div>Please select enter a-->
    <!--      search-->
    <!--      criteria to start searching-->
    <!--    </div>-->
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="h-100 no-results"

    *ngIf="started && !more && !fetching && rendered.length === 0 && thumbs.length === 0"
  >
    <img *ngIf="multiSearch" class="pin-img mb-10" src="assets/no-locations.svg"/>
    <div>No matching results</div>
  </div>
  <!--  <div-->
  <!--    *ngIf="fetching && this.thumbs.length === 0"-->
  <!--    fxLayoutAlign="start center"-->
  <!--    class="w-100"-->
  <!--    [style.height.px]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 88 : 58)"-->
  <!--  >-->
  <!--    <div fxLayoutAlign="center center" [style.width.px]="(wrapper.clientWidth - 60) / 3">-->
  <!--      <ui-preloader [diameter]="35" [color]="PreloaderColor.blue"></ui-preloader>-->
  <!--    </div>-->
  <!--  </div>-->
  <div
    *ngIf="fetching && this.thumbs.length === 0"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="w-100 h-100"
  >
    <div fxLayoutAlign="center center">
      <ui-preloader [diameter]="50" [color]="PreloaderColor.blue"></ui-preloader>
    </div>
  </div>
  <cdk-virtual-scroll-viewport
    fxLayout="column"
    fxFlex=""
    (scrolledIndexChange)="scrolledIndexChange($event)"
    [itemSize]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 70 : 58)"
    [minBufferPx]="((((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 70 : 58)) * 4"
    [maxBufferPx]="((((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 70 : 58)) * 5"
    class="thumbnail-scroller"
  >
    <!--    <div *cdkVirtualFor="let thumb of thumbnails; let i = index" class="example-item">{{thumb}} </div>-->
    <div *ngIf="started" fxLayout="column" fxLayoutAlign="start start">
      <ng-container *cdkVirtualFor="let thumbRow of thumbs">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px grid" class="w-100 mb-0">
          <ng-container *ngFor="let thumb of thumbRow; let i = index">
            <app-thumbnail
              [thumbnailTemplate]="thumbnailTemplate"
              [showEventsCounter]="false"
              [rtl]="rtl"
              *ngIf="!!thumb"
              [optionsInput]="thumb.options"
              [searchEvents]="thumb.searchEvents"
              [displayName]="!!multiTracker || !!multiSearch || !!widgetDataInfo"
              [objects]="thumb.objects"
              [edgeId]="multiTracker || multiSearch || !!widgetDataInfo ? thumb.edgeId : edgeId!"
              [cameraId]="multiTracker || multiSearch || !!widgetDataInfo ? thumb.cameraId : cameraId!"
              [defaultThumbnail]="thumb.defaultThumb"
              [fxFlex]="100 / 3 + '%'"
              [showObjects]="showObjects"
              [customEvents]="customEventSearch"
              [hideHint]="hideHint"
              (hintShown)="hideHint = true"
              tabindex="0"
            ></app-thumbnail>
            <div
              *ngIf="thumbRow.length < 3 && i === thumbRow.length - 1"
              fxLayoutAlign="center center"
              [fxFlex]="100 / 3 + '%'"
              [style.height.px]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 88 : 58)"
            >
              <div *ngIf="fetching" fxLayoutAlign="center center">
                <ui-preloader [diameter]="35" [color]="PreloaderColor.blue"></ui-preloader>
              </div>
              <div *ngIf="started && !more && !fetching">
                {{ results.length > 0 ? 'No more results' : 'No results' }}
              </div>
            </div>
          </ng-container>

          <!--          <div fxFlex="">{{i}}</div>-->
          <!--          <div fxFlex="">{{i + 1}}</div>-->
          <!--          <div fxFlex="">{{i + 2}}</div>-->
        </div>
      </ng-container>
      <ng-container *ngIf="this.thumbs?.length">
        <div
          *ngIf="started && this.thumbs[this.thumbs.length - 1].length === 3"
          fxLayoutAlign="center center"
          [style.width.px]="(wrapper.clientWidth - 60) / 3"
          [style.minHeight]="(((wrapper.clientWidth - 60) / 3) * 9) / 16 + (!!multiTracker || !!multiSearch ? 88 : 58) + 'px'"
        >
          <div *ngIf="!more && !fetching">No more results</div>
          <div *ngIf="more && fetching" fxLayoutAlign="center center">
            <ui-preloader [diameter]="35" [color]="PreloaderColor.blue"></ui-preloader>
          </div>
        </div>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
