<ng-container *ngrxLet="selectAlertEvents$; let events">
  <div [matMenuTriggerFor]="trackerClassMenu" class="flex-row space-between-center ui-selector w-100">
    <div>{{alertGroupType}}</div>
    <span class="ui-icon-expand-arrow"></span>
  </div>
  <mat-menu #trackerClassMenu="matMenu" xPosition="after" yPosition="below" class="menu-no-padding">
    <div [formGroup]="form" class="flex-col start-start ui-tracker-class-menu" (click)="$event.stopPropagation();">
      <mat-radio-group class="flex-col" aria-label="Select an option" formControlName="alertGroupType">
        <mat-radio-button
          [value]="+YAxisTrackerGroupType.All"
        >{{YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.All]}}</mat-radio-button>
        <mat-radio-button
          [value]="+YAxisTrackerGroupType.Group"
        >{{YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.Group]}}</mat-radio-button>
        <ng-container *ngIf="isGroupType">
          <div class="flex-col option-list">
            <mat-checkbox *ngFor="let item of AlertV2TypeGroup"
                          [value]="+item?.value?.category +',' +item?.value?.type"
                          [checked]="isCheckedAlertType('alertType', {category: +item?.value?.category, flowType: +item?.value?.type})"
                          (change)="updateArrayAlertType('alertType', {category: +item?.value?.category, flowType: +item?.value?.type}, $event.checked)"
            >
              <ui-truncated-html>{{ item.name }}</ui-truncated-html>
            </mat-checkbox>
          </div>
        </ng-container>
        <mat-radio-button
          [value]="+YAxisTrackerGroupType.Individual"
        >{{YAxisTrackerGroupTypeStr[YAxisTrackerGroupType.Individual]}}</mat-radio-button>
        <ng-container *ngIf="isIndividualType">
          <div class="flex-col option-list">
            <mat-checkbox *ngFor="let event of events"
                          [value]="event?._id"
                          [checked]="isChecked('eventId', event?._id)"
                          (change)="updateArray('eventId', event?._id, $event.checked)"
            >{{event?.name}}</mat-checkbox>
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </mat-menu>
</ng-container>
