import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EdgePulsationState } from './edge-heartbeat-pulsation.reducer';

/**
 * THIS SELECTOR USED ONLY FOR CALCULATE STATUSES
 * for get edge status use device-status.selecotrs
 */
export const selectEdgeStatusState = createFeatureSelector<EdgePulsationState>('edgePulsationState');

export const selectEdgeStatusByEdgeId = (edgeId: string) =>
  createSelector(selectEdgeStatusState, (edges: EdgePulsationState) => edges.entities[edgeId]);

export const selectEdgeEntities = createSelector(selectEdgeStatusState, (edges: EdgePulsationState) => {
  return edges.entities
});

export const selectLoadingPulsation$ = () =>
  createSelector(selectEdgeStatusState, (state: EdgePulsationState) => state.loading);
