<div
  class="alert-container w-100"
  [class.w-0]="width===0"
  [class.warning]="type === alertTypes.WARNING"
  [class.error]="type === alertTypes.ERROR"
  [class.success]="type === alertTypes.SUCCESS"
  [class.info]="type === alertTypes.INFO"
>
  <!--  //todo replace icons to iconMoon-->
  <div class="left-block">
    <div class="icon-block">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="alertTypes.WARNING">
          <mat-icon class="alert-icon">warning</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="alertTypes.SUCCESS">
          <mat-icon class="alert-icon">check_circle</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="alertTypes.ERROR">
          <mat-icon class="alert-icon">highlight_off</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="alertTypes.INFO">
          <mat-icon class="alert-icon">info</mat-icon>
        </ng-container>
      </ng-container>
    </div>
    <div class="notification-block" [class.no-title]="!title">
      <div *ngIf="title" class="title">{{ title }}</div>
      <div class="description">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div *ngIf="closable" class="close-btn-block c-p">
    <span class="ui-icon-close c-p" (click)="close()"></span>
  </div>
</div>
