<div fxLayout="row"
     fxLayoutAlign="space-between center"
     #chipContainer
     class="ui-filter-chip gap-4"
     [class.c-p]="allowDelete"
>
  <ui-truncated-html class="title"
                     [class.allow-delete]="titleContainer.clientWidth>chipContainer.clientWidth"
  >
    {{ title }}
  </ui-truncated-html>
  <span class="ui-icon-close clear-btn"
        *ngIf="allowDelete"
        (click)="onRemoveClicked.emit({value,field});$event.stopPropagation()"
  ></span>
</div>
<div style="opacity: 0;position: absolute" #titleContainer>
  {{ title }}
</div>
