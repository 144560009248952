<ng-container *ngIf="{
collapsed:collapsed$|async
} as conditions"
>
  <div class="edge-row flex-row space-between-center c-p" (click)="toggleEdge(); $event.stopPropagation()">
    <div class="flex-row start-center gap-16">
      <div [class.disabled]="!cameras.length">
        <mat-checkbox type="checkbox"
                      color="primary"
                      name="select-all"
                      [checked]="selected"
                      (click)="$event.stopPropagation()"
                      (change)="selectEdge($event)"
        ></mat-checkbox>
      </div>

      <div class="flex-row start-center gap-8">
        <span class="ui-icon-edge-status-alert"></span>
        {{ edge.name }}
      </div>
    </div>
    <span
      *ngIf="cameras.length"
      class="ui-icon-expand-arrow"
      [class.rotated]="!conditions.collapsed"
    ></span>
  </div>
  <div *ngIf="!conditions.collapsed" class="camera-list flex-col">
    <camera-selector-camera-row *ngFor="let camera of cameras; trackBy:trackBy" [camera]="camera"
                                [selectedCameras]="selectedCameras"
                                (onCameraSelected)="selectCamera($event)"
    >

    </camera-selector-camera-row>
  </div>
</ng-container>
