import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertEvent, AlertEventSendModel, SelectedCamera } from '@models/alert-events.model';
import { api } from '@consts/url.const';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { SQSMsgInfo } from '../core/interfaces';
import { AlertHttpRequest, AlertHttpRequestTestItem, AlertLogsFilter, AlertV2Document, AlertV2SendModel, EventV2Document, FlowLookup, MissingObjectRequest, MissingObjectResponse } from '@models/alerts-v2.model';
import { CameraLookup } from '@models/camera.model';

@Injectable()
export class AlertEventsService {
  constructor(private http: HttpClient) {
  }

  public createAlertEvents(alertEvent: AlertEventSendModel): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.alertEvents.crud, alertEvent);
  }

  public updateAlertEvents(id: string, alertEvent: AlertEventSendModel): Observable<SQSMsgInfo> {
    return this.http.put<SQSMsgInfo>(`${api.alertEvents.crud}/${id}`, alertEvent);
  }

  public updateAlertEventsV2(id: string, alertEvent: AlertV2SendModel): Observable<SQSMsgInfo[]> {
    return this.http.put<SQSMsgInfo[]>(`${api.alertEvents.crud}/${id}`, alertEvent);
  }

  public createAlertV2(alertV2: AlertV2SendModel): Observable<SQSMsgInfo[]> {
    return this.http.post<SQSMsgInfo[]>(api.alertEvents.v2, alertV2);
  }

  public updateAlertV2(id: string, alertV2: AlertV2SendModel): Observable<SQSMsgInfo> {
    return this.http.put<SQSMsgInfo>(`${api.alertEvents.v2}/${id}`, alertV2);
  }

  public getSqs(id: string): Observable<any> {
    return this.http.get(api.alertEvents.getSqs(id));
  }

  /**
   * @deprecated
   * user getAlertEventsV2 instead
   * @param page
   * @param perPage
   * @param orderBy
   * @param orderDirection
   * @param query
   * @param cameras
   * @param flowTypes
   */
  public getAlertEvents(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
    cameras: CameraLookup[],
    flowTypes: FlowLookup[],
  ): Observable<PaginationResult<AlertEvent>> {
    let url = `${api.alertEvents.list}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<AlertEvent>>(url, { cameras, flowTypes });
  }

  public getSelectedEvent(id: string): Observable<AlertEventSendModel> {
    return this.http.get<AlertEventSendModel>(api.alertEvents.one(id));
  }

  public remove(id: string): Observable<SQSMsgInfo[]> {
    return this.http.post<SQSMsgInfo[]>(api.alertEvents.delete(id), {});
  }

  public enable(id: string): Observable<any> {
    return this.http.put(api.alertEvents.enable(id), {});
  }

  public disable(id: string): Observable<any> {
    return this.http.put(api.alertEvents.disable(id), {});
  }

  public sync(id: string, alert: AlertEventSendModel): Observable<any> {
    return this.http.post(api.alertEvents.sync(id), alert);
  }

  public migrate(id: string, alert: AlertEventSendModel): Observable<any> {
    return this.http.post(api.alertEvents.migrate(id), alert);
  }

  public getAllNoPaging(): Observable<AlertV2Document[]> {
    return this.http.get<AlertV2Document[]>(api.alertEvents.allNoPaging, {});
  }

  public getAlertLogs(filters: AlertLogsFilter, pagination: { page: number; perPage: number }): Observable<PaginationResult<AlertV2Document>> {
    return this.http.post<PaginationResult<any>>(`${api.users.all}?page=${pagination.page}&perPage=${pagination.perPage}`, filters);
  }

  public testHttp(httpRequest: AlertHttpRequest) {
    return this.http.post<AlertHttpRequestTestItem | SQSMsgInfo[]>(api.alertEvents.testHttp, { httpRequest });
  }

  public getAlertEventsV2(
    page: number,
    perPage: number,
    query: string,
    orderBy: string = 'timestamp',
    orderDirection: SortDirection = 'desc',
    cameras?: CameraLookup[],
    flowTypes?: FlowLookup[],
  ): Observable<PaginationResult<EventV2Document>> {
    let url = `${api.alertEvents.list}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<EventV2Document>>(url, { cameras, flowTypes });
  }

  public getMissingObject(missingObjectRequest: MissingObjectRequest) {
    return this.http.post<MissingObjectResponse>(api.alertEvents.missingObject, missingObjectRequest);
  }

}
