<div fxLayout="column" fxLayoutAlign="start start" fxFill class="ui-person-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{ data.toExisting ? 'Add to existing' : 'Save person'}}</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="data.toExisting; else addNew">
    <ng-container *ngrxLet="selectSavedPeople$; let people">
      <div fxLayout="column"
           fxLayoutAlign="start start"
           fxFlex=""
           class="dialog-content narrower-input"
           fxLayoutGap="16px"
      >
        <mat-form-field appearance="outline" class="search-bar icon-24">
          <input matInput [(ngModel)]="query" placeholder="Search people"/>
          <span matPrefix><mat-icon></mat-icon></span>
          <mat-icon svgIcon="search" matPrefix class="search-icon"></mat-icon>
        </mat-form-field>
        <ng-container *ngIf="!people?.length; else hasPeople">
          <div fxLayoutAlign="center center" fxFlex="" class="w-100">
            <ui-no-data
                [imgSrc]="noSaved.imgSrc"
                [title]="noSaved.title"
                [description]="noSaved.description"
            ></ui-no-data>
          </div>
        </ng-container>
        <ng-template #hasPeople>
          <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px grid">
            <ng-container *ngFor="let person of query ? filtered(people) : people; let i = index">
              <ui-person-card
                  *ngIf="!data?.selector || exists(person.personId)"
                  (click)="select(person.personId)"
                  [allowEdit]="false"
                  [personId]="person.personId"
                  [allowSelection]="true"
                  [selected]="data.selector ? selectedPersonIds?.includes(person.personId) : person.personId === selectedPersonId"
                  [name]="person.name"
                  [bestImage]="person.bestImage"
                  [bestImageString]="person.bestImage"
                  [multiSelect]="data?.selector ? true : false"
              ></ui-person-card>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #addNew>
    <div [formGroup]="form" fxLayout="column" fxLayoutAlign="space-around start" fxFlex=""
         class="dialog-content narrower-input" fxLayoutGap="16px"
    >
      <img class="ui-person-dialog-img" [lazyLoad]="img"/>
      <div fxLayout="column" fxLayoutGap="8px" class="w-100">
        <div>Person name</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Name" formControlName="name"/>
        </mat-form-field>
      </div>
    </div>
  </ng-template>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button colorBgr="#4367F6" colorText="white" [text]="data.toExisting ? 'Select' : 'Save'" (clickAction)="save()"
               [disabled]="data.toExisting ? loading : form.invalid"
    ></ui-button>
    <ui-preloader [color]="PreloaderColor.blue" [diameter]="20" *ngIf="loading"></ui-preloader>
  </div>

</div>
