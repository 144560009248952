import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'activityLogUser',
})
export class ActivityLogUserPipe implements PipeTransform {
  transform(value: string, ...fields: string[]): { [key: string]: any } {
    try {
      const user = JSON.parse(value);
      let roleKey = '';
      if (fields.includes('role')) {
        if (environment.env === 'localDev' || environment.env === 'localNoKube') {
          roleKey = 'https://api-dev.lumix.ai/organization/roles';
        } else {
          roleKey = `${environment.apiUrl}/organization/roles`;
        }
      }
      const result = {};
      Object.keys(user)
        .forEach(key => {
          if (key === roleKey) {
            result['role'] = user[key];
          }
          if (fields.includes(key)) {
            result[key] = user[key];
          }
        });
      return result;
    } catch (e) {
      return null;
    }

  }
}
