<div fxLayout="column">
  <span class="label-title">{{ label }}</span>
  <div
    fxLayout="row"
    class="input-with-prefix"
    [style.margin-top]="label ? '8px' : '0'"
    [class.invalid]="invalid">
    <span *ngIf="prefix" class="prefix">{{ prefix }}</span>
    <mat-icon *ngIf="icon && !invalid" class="prefix">{{ icon }}</mat-icon>
    <mat-icon *ngIf="icon && invalid" class="prefix validation"
    >error_outline
    </mat-icon
    >
    <input
        [disabled]="disabled"
        [type]="inputType"
        [ngModel]="value"
        (ngModelChange)="updateValue($event)"
        [placeholder]="placeholder"/>
  </div>
</div>
