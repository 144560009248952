import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceStatusState } from '@states/device-status/device-status.reducer';

export const selectDeviceStatusState = createFeatureSelector<DeviceStatusState>('deviceStatusState');

export const selectTransformedCamerasStatus = createSelector(selectDeviceStatusState, (state: DeviceStatusState) => {
  return state.cameraStatusMap;
});

export const selectEdgeStatus = createSelector(selectDeviceStatusState, (state: DeviceStatusState) => {
  return state.edgeStatusMap;
});

export const selectLocationStatus = createSelector(selectDeviceStatusState, (state: DeviceStatusState) => {
  return state.locationStatusMap;
});

export const selectCamerasStatusByCameraId = (cameraId: string) => createSelector(selectDeviceStatusState, (state: DeviceStatusState) => {
  return state.cameraStatusMap?.[cameraId];
});
