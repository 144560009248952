import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-video-preview-modal',
  templateUrl: './video-preview-modal.component.html',
  styleUrls: ['./video-preview-modal.component.scss'],
})
export class VideoPreviewModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string;
      cameraName: string;
      locationName: string
      timestamp: number;
    },
  ) {
  }

  ngOnInit(): void {
  }

}
