import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Workspace, WorkspaceFavorite, WorkspaceFavoriteRequest, WorkspaceRequest } from '@models/workspace.model';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { VariableConfig } from '@models/variable.model';
import { SortDirection } from '@angular/material/sort';
import { UserService } from '../user/user.service';
import { WidgetType } from '@enums/workspace.enum';

@Injectable()
export class WorkspaceService {
  constructor(private userService: UserService, private http: HttpClient) {}

  public create(data: WorkspaceRequest): Observable<any> {
    return this.http.post(api.workspace.crud, data);
  }

  public update(data: WorkspaceRequest): Observable<any> {
    return this.http.put(api.workspace.one(data._id), data);
  }

  public addToFavorite(data: WorkspaceFavoriteRequest): Observable<any> {
    const userId = this.userService.getUserFromLocalStorage(false).authProviderId;
    data.userId = userId;
    return this.http.put(api.workspace.addFavorite(data._id), data);
  }

  public removeFromFavorites(data: WorkspaceFavoriteRequest): Observable<any> {
    const userId = this.userService.getUserFromLocalStorage(false).authProviderId;
    data.userId = userId;
    return this.http.put(api.workspace.removeFavorite(data._id), data);
  }

  public get(): Observable<PaginationResult<Workspace>> {
    return this.http.get<PaginationResult<Workspace>>(api.workspace.crud);
  }

  public one(id: string): Observable<Workspace> {
    return this.http.get<Workspace>(api.workspace.one(id));
  }

  public remove(id: string): Observable<Workspace> {
    return this.http.delete<Workspace>(api.workspace.one(id));
  }

  public getWidgetData(dto: {
    widgetType: WidgetType;
    variables: string[];
    cameraId?: string;
    relative: {
      value: number;
      unit: CustomUnit;
    };
    absolute: {
      start: number;
      end: number;
      timezone: string;
    };
    relativeCustom: any;
  }): Observable<any> {
    return this.http.post(api.workspace.getWidgetData, dto);
  }

  public getWorkspaces(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string
  ): Observable<PaginationResult<Workspace>> {
    let url = `${api.workspace.crud}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<PaginationResult<Workspace>>(url);
  }

  public getFavorites(): Observable<WorkspaceFavorite[]> {
    let url = `${api.workspace.favorites}`;
    return this.http.get<WorkspaceFavorite[]>(url);
  }
}
