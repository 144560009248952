import { Invite, InviteWithOrgName } from '@models/invite.model';
import { createReducer, on } from '@ngrx/store';
import * as InviteActions from '@states/invite/invite.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserActions } from '@states/user/user.action-types';

export declare interface InviteState extends EntityState<Invite> {
  invites: InviteWithOrgName[]; // User personal invites
  query: string;
  isInitialLoaded: boolean;
  selectedInvite: Invite;
}

export const adapter: EntityAdapter<Invite> = createEntityAdapter<Invite>({
  selectId: (invite: Invite) => invite._id,
});

const initialState: InviteState = adapter.getInitialState({
  invites: null,
  query: null,
  isInitialLoaded: false,
  selectedInvite: null,
});

export const inviteStateReducer = createReducer(
  initialState,
  on(InviteActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(InviteActions.getInvitesByOrgIdSuccess, (state, { invites }) => {
    return adapter.addMany(invites, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(InviteActions.getActiveInvitesSuccess, (state, { invites }) => {
    return {
      ...state,
      invites,
    };
  }),
  on(InviteActions.setQueryParams, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(InviteActions.removeInviteSuccess, (state, { inviteId }) => {
    return adapter.removeOne(inviteId, {
      ...state,
    });
  }),
  on(InviteActions.revokeAll, (state) => {
    return adapter.removeAll({
      ...state,
    });
  }),
  on(UserActions.getSignUpInviteByIdSuccess, (state, { invite }) => {
    return {
      ...state,
      selectedInvite: invite,
    };
  }),
);
