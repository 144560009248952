<ng-container *ngIf="!loading">
  <div
    *ngIf="editEdge"
    class="settings-sidebar"
    [ngClass]="{ opened: settingsOpened }"
    [class.wide]="editEdgeWide"
  >
    <app-edge-settings
      #edgeSettings
      [edgeId]="edgeId"
      [locationId]="locationId"
      (closed)="settingsOpened = !settingsOpened"

    >
    </app-edge-settings>
    <div fxLayoutAlign="center center" class="settings-expand-button" [class.collapsed]="!editEdgeWide"
         (click)="editEdgeWide = !editEdgeWide" [matTooltip]="editEdgeWide ? 'Collapse' : 'Expand'"
         matTooltipPosition="right"
    >
      <mat-icon>{{ editEdgeWide ? 'arrow_forward_ios' : 'arrow_back_ios' }}</mat-icon>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-between start" class="page-wrapper">

    <div class="stepper-wrapper">
      <ui-header [breadCrumbs]="breadCrumbs"></ui-header>
      <div class="stepper-top">

        <div *ngIf="numSteps() !== 1 && !editLocation && !editEdge" fxLayout="column" class="stepper-header mb-20"
             fxLayoutGap="5px"
        >
          <div class="flex-col gap-8">{{ stepper.selectedIndex + 1 }} of {{ numSteps() }}</div>
          <div fxLayout="row" class="stepper-line">
            <div *ngFor="let step of stepsArray()" fxFlex="" [ngClass]="{ finished: step <= stepper.selectedIndex }">
            </div>
          </div>
        </div>
      </div>
      <mat-stepper [linear]="true" #stepper [selectedIndex]="selectedIndex" [ngClass]="{
        'add-cameras-only': baseStep === AddLocationStep.ADD_CAMERAS
      }"
      >
        <mat-step [stepControl]="addLocationFormGroup" *ngIf="stepEnabled(AddLocationStep.ADD_LOCATION)">
          <div [formGroup]="addLocationFormGroup"
               class="flex-col gap-24 add-location-form"
          >
            <ng-template matStepLabel>{{
                editLocation ? 'Edit Location: ' + locationName : 'Add New Location'
              }}
            </ng-template>
            <h2>
              Location Details
            </h2>
            <div class="flex-col gap-8">
              <mat-label>Name</mat-label>
              <input placeholder="" formControlName="name" class="ui-input"/>
            </div>
            <div class="flex-col gap-8">
              <mat-label>Address</mat-label>
              <input placeholder="" formControlName="address" class="ui-input"/>
            </div>
            <div class="flex-col gap-8">
              <mat-label>City</mat-label>
              <input placeholder="" formControlName="city" class="ui-input"/>
            </div>
            <div class="flex-row start-start gap-16 w-100">
              <div class="flex-col flex gap-8">
                <mat-label>State</mat-label>
                <input placeholder="" formControlName="state" class="ui-input flex w-100"/>
              </div>
              <div class="flex-col flex gap-8 w-100">
                <mat-label>Zip Code</mat-label>
                <input placeholder="" formControlName="zip" class="ui-input flex w-100"/>
              </div>
            </div>
            <div class="flex-col gap-8">
              <mat-label>Time Zone</mat-label>
              <input
                type="text"
                placeholder="Pick one"
                aria-label="Number"
                formControlName="timezone"
                [matAutocomplete]="auto"
                class="ui-input"
              />

              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of tzNamesFilter | async" [value]="option">
                  {{ option }} ({{ tzFormat(option) }})
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="flex-col gap-8">
              <mat-label>Contact Name</mat-label>
              <input placeholder="" formControlName="contact" class="ui-input"/>
            </div>
            <div class="flex-col gap-8">
              <mat-label>Phone number</mat-label>
              <input placeholder="" formControlName="phone" class="ui-input"/>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="addLocationFormGroup" *ngIf="stepEnabled(AddLocationStep.LOCATION_COMPLIANCE)">
          <form [formGroup]="addLocationFormGroup" fxFlex="50">
            <div fxLayout="column" fxLayoutGap="24px">
              <ng-template matStepLabel>{{
                  editLocation ? 'Edit Location: ' + locationName : 'Add New Location'
                }}
              </ng-template>
              <h2>
                Compliance and availability
              </h2>
              <div fxLayout="column" fxLayoutGap="8px">
                <div style="width: 350px;">
                  <mat-select class="ui-selector w-100" formControlName="complianceOption">
                    <mat-option *ngFor="let option of ComplianceOptionKeys"
                                [value]="option"
                    >{{ ComplianceOption[option] | titlecase }}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="compliance-description">
                  Some security features are not available in different jurisdictions. Lumana provides assurance that
                  its
                  hardware and software products are in compliance with all relevant rules and regulations.
                </div>
              </div>
              <div
                [class.disabled]="+addLocationFormGroup.get('complianceOption').value !== ComplianceOption.INDEPENDENT"
              >
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <ui-toggle formControlName="faceRecognition"></ui-toggle>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <div class="compliance-label">Face recognition</div>
                      <div class="compliance-description">
                        Identify and verify individuals by analyzing their facial features with stored data.
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <ui-toggle formControlName="genderClassification"></ui-toggle>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <div class="compliance-label">Gender information</div>
                      <div class="compliance-description">
                        Data related to an individual's self-identified or biologically assigned gender.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="addEdgeFormGroup" label="Create a new smart core"
                  *ngIf="stepEnabled(AddLocationStep.ADD_EDGE)"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h2>
              {{
                editEdge ? 'Edit core: ' + edgeName : 'Create a new core'
              }}
            </h2>
            <!--          <button mat-icon-button class="icon-button" (click)="settings(edgeId)">-->
            <!--            <mat-icon>settings</mat-icon>-->
            <!--          </button>-->
          </div>
          <div [formGroup]="addEdgeFormGroup" class="flex-col gap-24 add-edge-form">
            <div class="flex-col gap-8">
              <mat-label>Name</mat-label>
              <input placeholder="" formControlName="name" class="ui-input"/>
            </div>
            <div class="flex-col gap-8">
              <mat-label>Core ID</mat-label>
              <input placeholder="#" formControlName="edgeId" class="ui-input"/>
            </div>
            <ng-container *ngIf="editEdge">
              <mat-label class="mb-8">Max Storage</mat-label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="maxStorage" class="input-40"/>
              </mat-form-field>
            </ng-container>
          </div>
          <div *ngIf="editEdge" fxFlex="50" class="edit-edge-ip-wrapper">
            <mat-label class="mb-8">eth0 IP</mat-label>
            <mat-form-field appearance="outline">
              <mat-progress-bar
                mode="indeterminate"
                *ngIf="ipLoading"
              ></mat-progress-bar>
              <input matInput [value]="eth0 || ''" disabled class="input-40"/>
            </mat-form-field>
            <mat-label class="mb-8">eth1 IP</mat-label>
            <mat-form-field appearance="outline">
              <mat-progress-bar
                mode="indeterminate"
                *ngIf="ipLoading"
              ></mat-progress-bar>
              <input matInput [value]="eth1 || ''" disabled class="input-40"/>
            </mat-form-field>
          </div>

          <div *ngIf="!editEdge"
               fxLayout="row"
               fxLayoutAlign="start center"
               fxLayoutGap="10px"
               class="mt-24"
               style="padding: 3px 0px"
          >
            <button mat-flat-button color="primary" *ngIf="!connectedEdgeId && !connectLoader"
                    [disabled]="!addEdgeFormGroup.valid || connectLoader" (click)="addEdgeToLocation()"
            >
              Check connection
            </button>
            <button mat-flat-button color="primary" *ngIf="connectLoader" [style.opacity]="0.4">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="checking-connection-wrapper">
                <mat-spinner diameter="20"></mat-spinner>
                <div class="flex-col gap-8">Checking connection</div>
              </div>
            </button>

            <div *ngIf="connectedEdgeId" class="connected-card-wrapper blue" fxLayout="row"
                 fxLayoutAlign="start start"
                 fxLayoutGap="15px"
            >
              <div class="svg-fill-primary">
                <mat-icon svgIcon="info"></mat-icon>
              </div>
              <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="7px">
                <div class="bold">Connection established properly</div>
                <div [style.opacity]="0.8">You’re now good to go!</div>
              </div>
            </div>
            <mat-icon class="c-green bold" *ngIf="connectedEdgeId"
            >check
            </mat-icon
            >
            <div class="c-red bold">{{ connectedEdgeIdError }}</div>
          </div>
        </mat-step>

        <mat-step [stepControl]="addCamerasFormGroup" label="Add Cameras"
                  *ngIf="stepEnabled(AddLocationStep.ADD_CAMERAS)"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h2>Setup your cameras</h2>
            <div fxLayout="row" fxLayoutGap="10px">
              <button mat-stroked-button
                      color="primary"
                      (click)="discoverCameras(true)"
                      [disabled]="discoveryInSession"
              >
                Scan for Cameras
              </button>
              <button mat-stroked-button color="primary">
                Network Interface
              </button>
              <button mat-stroked-button color="primary" (click)="addCamera()">
                Add a Camera Manually
              </button>
              <button mat-icon-button>
                <mat-icon>cached</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon>filter_list</mat-icon>
              </button>
            </div>
          </div>
          <div class="cameras-table table-style-1">
            <loader [loading]="camerasLoader" diameter="30"></loader>
            <div *ngIf="!camerasLoader && discoveryError" fxLayoutAlign="center center">
              No cameras found
            </div>
            <table *ngIf="!camerasLoader && !discoveryError" mat-table [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: inherit">
                    <app-status-chip
                      [status]="!isAdded(element) ? element.status : (getCameraStatus(element.cameraId) | async)"
                    ></app-status-chip>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef>Mac</th>
                <td mat-cell *matCellDef="let element">{{ element.mac }}</td>
              </ng-container>

              <ng-container matColumnDef="ip">
                <th mat-header-cell *matHeaderCellDef>IP</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.ipAddress }}
                </td>
              </ng-container>

              <ng-container matColumnDef="onvif">
                <th mat-header-cell *matHeaderCellDef>Onvif</th>
                <td mat-cell *matCellDef="let element">
                  <div class="onvif-compliant" [ngClass]="{
                    true:
                      element.onvifCompliant ||
                      element.onvifCompliant === undefined
                  }"
                  >
                    <img src="assets/onvif-logo.png"/>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div fxLayout="row" fxLayoutAlign="end center" fxFlexFill>
                    <button *ngIf="!isAdded(element)" mat-flat-button color="primary" class="add-camera-button"
                            (click)="addCamera(element)" matTooltip="Add Camera to {{ connectedEdgeId }}"
                    >
                      + Add
                    </button>
                    <div *ngIf="isAdded(element)" fxLayoutAlign="center center" style="
                      border-radius: 4px;
                      background: #258f23;
                      color: #fff;
                      height: 40px;
                      width: 40px;
                    "
                    >
                      <mat-icon>check_circle</mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" class="c-p"></tr>
            </table>
            <!--    <button class="add-btn" mat-fab matTooltip="Add a new camera" (click)="addCamera()">-->
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="footer-actions">
      <div class="flex-col gap-8">
        <button mat-button (click)="cancelFinish()">Cancel</button>
      </div>
      <div fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <ui-button *ngIf="this.stepper.selectedIndex > this.baseStep"
                     type="primary"
                     (clickAction)="back()"
                     text="Back"
          >

          </ui-button>
          <ui-button type="primary" *ngIf="
            !(
              this.baseStep + this.stepper.selectedIndex ===
              AddLocationStep.ADD_CAMERAS
            )
          "
                     [disabled]="
          (this.baseStep === AddLocationStep.ADD_EDGE && !connectedEdgeId) ||
            (this.stepper.selectedIndex === AddLocationStep.ADD_EDGE && !connectedEdgeId) ||
            this.stepper.selectedIndex === AddLocationStep.ADD_CAMERAS
          "
                     [text]="editLocation || editEdge ? 'Save' : 'Save'"
                     (clickAction)="next()"
          >

          </ui-button>
          <mat-spinner diameter="20" *ngIf="edgeUpdate && updateLoader"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading">

</ng-container>
