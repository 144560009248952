import { SQSMsgInfo } from '../../core/interfaces';
import { WebrtcPlayerState } from '../../shared/webrtc-v2/webrtc-v2.component';

export enum SDPType {
  Offer = 'offer',
  Answer = 'answer',
}

export enum WebRtcPeerType {
  CONTROL,
  SDP,
  ICE,
}

export enum WebRTCPeerControlTypes {
  // Live view
  Extend = 'extend',
  Stop = 'stop',
  Close = 'close',
  Start = 'start',
  // Playback,
  PlaybackStart = 'playback-start',
  PlaybackStop = 'playback-stop',
  PlaybackSeek = 'playback-seek',
  PlaybackSpeed = 'playback-speed',
  PlaybackExtend = 'playback-extend',
  PlaybackInfo = 'playback-info',
  PlaybackInfoRequest = 'playback-info-request',
  PlaybackError = 'playback-error',
  PlaybackOldestVids = 'playback-oldest-vids',
}

export enum StreamerAction {
  Start,
  Stop,
  AddAnalytics,
  StopAnalytics,
  Peer
}

export enum WebRtcDirection {
  SQS = 'sqs',
  SNS = 'sns',
}

export interface WebRtcStartRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  type: WebRtcPeerType.CONTROL;
}

export interface WebRTCPeerInterfaceBase {
  locationId: string;
  edgeId: string;
  cameraId: string;
  sessionId?: string;
  timestamp: number;
  type: WebRtcPeerType;
}

export interface WebRTCPeerInterfaceSDP extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.SDP;
  sdp: { type: SDPType; sdp: string };
}

export interface WebRTCPeerInterfaceICE extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.ICE;
  ice: { candidate: string; sdpMLineIndex: number };
}

export interface WebRTCPeerInterfaceControl extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL;
  control: string;
  hq?: boolean;
  rtcConfiguration?: RTCConfiguration;
  cloud?: boolean;
  errorMsg?: string;
}

export interface WebRTCPeerInterfacePlaybackStart extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackStart,
  playbackTS: number, //ms
  duration?: number,   //ms
}

export interface WebRTCPeerInterfacePlaybackStop extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackStop,
}

export interface WebRTCPeerInterfacePlaybackSeek extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackSeek,
  playbackTS: number,
}

export interface WebRTCPeerInterfacePlaybackExtend extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackExtend,
}

export interface WebRTCPeerInterfacePlaybackSpeed extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackSpeed,
  playbackSpeed: number,
}

export interface WebRTCPlaybackInfoRequest extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackInfoRequest,
  startTS: number, //ms
  endTS: number //ms
}

export interface WebRTCPlaybackOldestVidsRequest extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL,
  control: WebRTCPeerControlTypes.PlaybackOldestVids,
}

export interface WebRTCPlaybackInfo extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL;
  control: WebRTCPeerControlTypes.PlaybackInfo;
  smartStorage: number[][]; //array of [openTimeMSec,closeTimeMSec]
  noStorage: number[][];
}

export interface WebRTCPlaybackOldestVideos extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL;
  control: WebRTCPeerControlTypes.PlaybackOldestVids;
  oldestVideoTS: number; //ms,
  oldestSmartVideoTS: number; //ms
}

export interface WebRTCPlaybackError extends WebRTCPeerInterfaceBase {
  type: WebRtcPeerType.CONTROL;
  control: WebRTCPeerControlTypes.PlaybackError;
  error: PlaybackError;
}

export interface WebRTCPlaybackLimitReached extends WebRTCPlaybackError {
  type: WebRtcPeerType.CONTROL;
  control: WebRTCPeerControlTypes.PlaybackError;
  error: PlaybackError.SessionLimitReached;
  limit: number;
}

export type WebRTCPeerInterface =
  | WebRTCPeerInterfaceSDP
  | WebRTCPeerInterfaceICE
  | WebRTCPeerInterfaceControl;

export interface WebRTCPeerTurnCredentials {
  username: string;
  password: string;
  url: string[];
}

export enum PlaybackError {
  SessionLimitReached = 'SessionLimitReached',
  InternalError = 'InternalError',
  NoMoreFiles = 'NoMoreFiles',
}

export interface WebRTCPeerStartResponse extends SQSMsgInfo {
  credentials: WebRTCPeerTurnCredentials;
}

export interface WbRTCActiveSession {
  state?: WebrtcPlayerState;
  edgeId: string;
  cameraId: string;
  sessionId: string;
  timestamp: number;
  id: string;
  isLocal?: boolean;
}

export enum PlaybackWsAction {
  Start = 'start',
  Pending = 'pending',
  Pause = 'pause',
  Seek = 'seek',
  Speed = 'speed',
  Exit = 'exit',
}

export enum PlaybackWsEvent {
  Error = 'error',
  CodecInfo = 'codecInfo',
  WebLeaveRoom = 'webLeaveRoom',
  Control = 'control',
}

export interface PlaybackEvent {
  event: PlaybackWsEvent;
  action?: PlaybackWsAction;
  sessionId: string;
  payload: any;
}

export interface WebrtcStats {
  output: string;
  bitrate: number;
  prevTimestamp: number;
  prevBytesReceived: number;
}

