import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { OrganizationSettingsComponent } from './organization-settings/organization-settings.component';
import { BillingSettingsComponent } from './billing-settings/billing-settings.component';
import { MainSettingsComponent } from './main-settings/main-settings.component';
import { UiKitModule } from '../shared/ui-kit/ui-kit.module';
import { AboutComponent } from './about/about.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../shared/shared.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    ProfileSettingsComponent,
    OrganizationSettingsComponent,
    BillingSettingsComponent,
    MainSettingsComponent,
    AboutComponent,
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    RouterModule.forChild([
      {
        path: '',
        component: MainSettingsComponent,
        children: [
          {
            path: 'profile',
            component: ProfileSettingsComponent,
          },
          {
            path: 'organization',
            component: OrganizationSettingsComponent,
          },
          {
            path: '',
            redirectTo: 'organization',
            pathMatch: 'full',
          },
        ],
      },
    ]),
    UiKitModule,
    LetDirective, PushPipe,
    MatProgressBarModule,
    SharedModule,
    DirectivesModule,
    NgScrollbarModule,
  ],
  exports: [OrganizationSettingsComponent],
})
export class AppSettingsModule {}
