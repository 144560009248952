<div fxLayout="column" fxLayoutAlign="start start" class="w-100 h-100">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 dialog-title">
    <div>Webrtc Stats</div>
    <div class="c-p icon-18 opacity-70">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div fxLayout="column" [innerHTML]="data.stats.output" fxFlex="" class="dialog-content">

  </div>

</div>

