import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertV2Schedule } from '@models/alerts-v2.model';
import { MatDialog } from '@angular/material/dialog';
import { daysSettingSchedule } from '@consts/alert-events.const';
import { DayOfWeek } from '@enums/shared.enum';
import { UiScheduleDialogComponent, UiScheduleDialogData, UiScheduleDialogResult } from './ui-schedule-dialog/ui-schedule-dialog.component';

@Component({
  selector: 'ui-schedule',
  templateUrl: './ui-schedule.component.html',
  styleUrls: ['./ui-schedule.component.scss'],
})
export class UiScheduleComponent implements OnInit, OnChanges {

  public DayOfWeekStr = Object.keys(DayOfWeek)
    .filter(v => isNaN(Number(v)))
    .map(day => day.charAt(0)
      .toUpperCase() + day.slice(1));

  @Input() schedule: AlertV2Schedule[] = [];
  @Input() timezone: string;
  @Output() change: EventEmitter<AlertV2Schedule[]> = new EventEmitter<AlertV2Schedule[]>();

  selected = true;
  scheduleText = 'All time';

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.schedule = this.schedule?.length ? this.schedule : undefined;
    this.change.emit(this.schedule);
  }

  dayEquals(day1: AlertV2Schedule, day2: AlertV2Schedule) {
    return !day1.disabled && !day2.disabled &&
      (day1.allDay && day2.allDay || day1.from === day2.from && day1.to === day2.to);
  }

  // defineScheduleText() {
  //   if (!this.schedule?.length) {
  //     this.scheduleText = 'all times';
  //     return;
  //   }
  //   // Create an object to map day of week enums to day names
  //   const dayNames = {
  //     0: 'Monday',
  //     1: 'Tuesday',
  //     2: 'Wednesday',
  //     3: 'Thursday',
  //     4: 'Friday',
  //     5: 'Saturday',
  //     6: 'Sunday',
  //   };
  //
  //   // Filter out the disabled days from the schedule
  //   const enabledDays = this.schedule?.filter(day => !day.disabled);
  //
  //   // Create an array to store the descriptions for each day
  //   const dayDescriptions = [];
  //
  //   // Loop through each enabled day in the schedule
  //   let i = 0;
  //   while (i < enabledDays.length) {
  //     const day = enabledDays[i];
  //
  //     // If the day is an all-day schedule, add the day name to the descriptions array
  //     if (day.allDay) {
  //       dayDescriptions.push(dayNames[day.day]);
  //       i++;
  //       continue;
  //     }
  //
  //     // Otherwise, find the next day with a different schedule, or the end of the week
  //     let j = i + 1;
  //     while (j < enabledDays.length && (enabledDays[j].allDay || enabledDays[j].day === (enabledDays[j - 1].day + 1) % 7 || enabledDays[j].day === 0) && enabledDays[j].from === day.from && enabledDays[j].to === day.to) {
  //       j++;
  //     }
  //
  //     // Add the day range to the descriptions array
  //     const lastDay = enabledDays[j - 1];
  //     const firstDayName = dayNames[day.day];
  //     const lastDayName = dayNames[lastDay.day];
  //     const timeRange = `${day.from}-${day.to}`;
  //     if (j - i > 2) {
  //       dayDescriptions.push(`${firstDayName}-${lastDayName}, ${timeRange}`);
  //     } else if (j - i === 2) {
  //       dayDescriptions.push(`${firstDayName}, ${lastDayName}, ${timeRange}`);
  //     } else {
  //       dayDescriptions.push(`${firstDayName}, ${timeRange}`);
  //     }
  //
  //     // Set the loop index to the end of the range we just processed
  //     i = j;
  //   }
  //
  //   // Join the descriptions array with commas and return the result
  //   this.scheduleText = dayDescriptions.join(', ');
  // }

  defineScheduleText() {
    if (!this.schedule?.length) {
      this.scheduleText = 'All time';
      return;
    }
    // Create an object to map day of week enums to day names
    const dayNames = {
      0: 'Monday',
      1: 'Tuesday',
      2: 'Wednesday',
      3: 'Thursday',
      4: 'Friday',
      5: 'Saturday',
      6: 'Sunday',
    };

    // Filter out the disabled days from the schedule
    const enabledDays = this.schedule?.filter(day => day.enabled && !!day?.times?.length);

    // Create an array to store the descriptions for each day
    const dayDescriptions = [];

    // Loop through each enabled day in the schedule
    let i = 0;
    while (i < enabledDays.length) {
      const day = enabledDays[i];

      // If the day is an all-day schedule, add the day name to the descriptions array
      if (day.allDay) {
        dayDescriptions.push(dayNames[day.day]);
        i++;
        continue;
      }

      // Otherwise, find the next day with a different schedule, or the end of the week
      let j = i + 1;
      while (j < enabledDays.length && (enabledDays[j].allDay || enabledDays[j].day === (enabledDays[j - 1].day + 1) % 7 || enabledDays[j].day === 0) && enabledDays[j].from === day.from && enabledDays[j].to === day.to) {
        j++;
      }

      // Add the day range to the descriptions array
      const lastDay = enabledDays[j - 1];
      const firstDayName = dayNames[day.day];
      const lastDayName = dayNames[lastDay.day];

      if (j - i >= 2) {
        dayDescriptions.push(`${firstDayName}-${lastDayName}`);
      } else if (j - i === 2) {
        dayDescriptions.push(`${firstDayName}, ${lastDayName}`);
      } else {
        dayDescriptions.push(`${firstDayName}`);
      }

      // Set the loop index to the end of the range we just processed
      i = j;
    }

    // Join the descriptions array with commas and return the result
    this.scheduleText = dayDescriptions.join(', ');
  }


  selectSchedule() {
    const data: UiScheduleDialogData = {
      schedule: this.schedule,
      timezone: this.timezone,
    };
    this.dialog.open(UiScheduleDialogComponent, {
        panelClass: 'modal-no-padding',
        width: '1200px',
        data,
      })
      .afterClosed()
      .subscribe((result: UiScheduleDialogResult) => {
        if (!result) {
          return;
        }
        this.schedule = result.schedule;

        this.defineScheduleText();
        this.change.emit(this.schedule);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule'] && changes['schedule'].currentValue) {
      this.defineScheduleText();
    }
  }

}
