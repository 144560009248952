<div fxLayout="column" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div style="font-size: 18px; font-weight: 600">Playback preview</div>
      <div>{{ data.time | date : 'MMM dd, yyyy h:mm:ss a' : data.timezone }}</div>
    </div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-playback-player
    #playback
    class="w-100"
    [timezone]="data.timezone"
    [cameraName]="data.cameraName"
    [cameraId]="cameraId"
    [startTime]="data.time"
    [time]="data.time"
    [duration]="data.duration"
    (playing)="(playing)"
    fxFlex=""
  ></app-playback-player>
</div>
