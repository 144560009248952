<div
  #selectorInput
  class="ui-clickable ui-selector-item flex-row start-center"
  (click)="toggleDialog()"
>
  <ng-content select="[input]"></ng-content>
</div>
<ng-template #selectorTemplate hasBackdrop="false">
  <ng-container #dialogContainer>
    <ng-content select="[content]"></ng-content>
  </ng-container>
</ng-template>
