import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedEffects } from '@effects/shared.effects';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { InviteUsersModalComponent } from '../invite-users-modal/invite-users-modal.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { OrganizationUsersSelectors } from '@states/organization-users/organization-users.selector-types';
import { AppUser } from '../../user/user.model';
import User = AppUser.User;
import { InviteActions } from '@states/invite/invite.action-types';

declare interface UsersListModalData {
  selectedUsers: Dictionary<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-user-list-modal',
  templateUrl: './users-list-modal.component.html',
  styleUrls: ['./users-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectOrganizationUsersEntities$: Observable<User[]> = this.store$.pipe(select(OrganizationUsersSelectors.selectOrganizationUsersEntities));
  public selectIsInitialLoaded$: Observable<boolean> = this.store$.pipe(select(OrganizationUsersSelectors.selectIsInitialLoaded));
  public loaderColor = PreloaderColor;
  public noResults = features.organizationUsers.noData;

  public selectedUsers$: BehaviorSubject<{ [key: string]: Object }> = new BehaviorSubject({});
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  public viewPortHeight: number;

  constructor(private store$: Store,
              private sharedEffects$: SharedEffects,
              private dialogRef: MatDialogRef<UsersListModalComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: UsersListModalData) {
  }

  ngOnInit(): void {
    if (this.data?.selectedUsers) {
      this.selectedUsers$.next(this.data.selectedUsers);
    }
  }

  public ngAfterViewInit() {
    this.viewPortHeight = this.scrollContainer.nativeElement.offsetHeight;
  }


  public search(query: string) {
    this.store$.dispatch(OrganizationUsersActions.setFilter({ property: 'query', value: query }));
  }

  public close(): void {
    this.dialogRef.close(this.selectedUsers$.getValue());
  }

  public ngOnDestroy() {
    this.store$.dispatch(OrganizationUsersActions.setFilter({ property: 'query', value: null }));
  }

  public openInviteUsersModal(): void {
    this.dialog.open(InviteUsersModalComponent, {
        panelClass: 'modal-no-padding',
        width: '572px',
      })
      .afterClosed()
      .subscribe((invites: Dictionary<string>) => {
        if (Object.keys(invites)?.length) {
          this.store$.dispatch(InviteActions.sendInvites({ invites }));
        }
      });
  }

  public selectUser(id: string) {
    let selectedUsers = { ...this.selectedUsers$.getValue() };
    if (!!selectedUsers[id]) {
      delete selectedUsers[id];
    } else {
      selectedUsers = {
        ...selectedUsers,
        [id]: {},
      };
    }
    this.selectedUsers$.next(selectedUsers);
  }
}
