<!--<div class="p-r">-->
<!--<div style="position: absolute; width: 200px; height: 100px; background: red; z-index: 999; right: 20px; bottom: 20px;">-->
<!--asd-->
<!--</div>-->
<div fxLayout="column" fxFlex="" class="p-wrapper p-r">
  <!--  <div style="position: relative; top: 0px; left: 50%">-->
  <!--    <button mat-flat-button color="primary" (click)="load()">reload</button>-->
  <!--  </div>-->
  <div *ngIf="allowZoom && scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label hover">
    {{ scale * 100 | number : '1.0-0' }}%
  </div>
  <div *ngIf="allowZoom && this.isPlaying" fxLayout="column" fxLayoutGap="5px" class="manual-zoom-wrapper hover c-p">
    <div fxLayoutAlign="center center" (click)="zoomIn()">
      <mat-icon>add</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="zoomOut()">
      <mat-icon>remove</mat-icon>
    </div>
  </div>
  <div
    #preview
    *ngIf="allowZoom && inZoom() && isPlaying"
    class="preview"
    cdkDragBoundary=".p-wrapper"
    cdkDrag
    (cdkDragStarted)="preview.classList.add('move')"
    (cdkDragEnded)="preview.classList.remove('move')"
    [ngClass]="{ invisible: this.scale === 1 }"
  >
    <canvas #previewCanvas id="c1" width="208" height="117"></canvas>
  </div>
  <div
    (mousewheel)="zoom($event)"
    (mousedown)="dragStart($event)"
    (mouseup)="dragging = false"
    (mousemove)="drag($event)"
    [ngClass]="{ grab: dragging }"
  >
    <video #player controls crossorigin playsinline muted="muted" id="player"></video>
  </div>
</div>
<!--</div>-->

<!--<video  #play-->
<!--        controls-->
<!--        crossorigin-->
<!--        playsinline-->
<!--        muted="muted"-->
<!--        id="player"-->
<!--&gt;-->
<!--</video>-->
