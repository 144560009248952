<div class="eula-header">
  <img src="assets/lumana_logo.svg">
</div>
<div fxLayout="column" fxLayoutAlign="start center" class="eula-content-wrapper">
  <div fxLayout="column" class="eula-content" fxFlex="">
    <h1>LUMANA Privacy Policy</h1>
    <div style="font-size: 14px" class="mb-30">Last updated October 30, 2023</div>
    <p>Lumana (“Lumana,” “we,” or “us,” or “our”) cares about your privacy and recognizes the importance of protecting
       your personal information. This Privacy Policy (“Policy”) describes the kind of information we may collect during
       your visit to our website (the “Site”). By accessing the Site, you agree to our collection and use of personal
       information as described in this Policy and in our Terms of Use.</p>
    <p>
    <h2>Personal Information We May Collect</h2>
    <p>We collect your personal information when you visit our Site; create an account to use our services, provide us
       payment information, which is collected and stored by our third-party payment processor on our behalf; submit
       information in connection with a career opportunity at Lumana; or when you otherwise voluntarily provide
       information to us through the Site. We may also collect your personal information from publicly-available
       sources.
    </p>
    <p>The personal information we collect includes your name; email address; physical address; your billing address;
       telephone numbers; and any information you provide on your CV that you send to us through our Site when you apply
       for a job.
    </p>
    <p>We may also collect personal information from you automatically, when you visit our Site. This information may
       include your IP address, your device and browser type, information about your interaction with our Site and
       advertisements.
    <h2>How We May Use Your Personal Information</h2>
    <p>We use your personal information to provide our services to you and to evaluate your applications for a position
       with us. We may also use personal information to contact you from time to time to provide you with customer
       support, or to send you advertisements and marketing promotions and offers about products or services we think
       may
       be of interest to you.
    </p>
    <p>We may also use personal information about your use of our Site to monitor and improve our Site; for internal
       business analysis; to prevent fraud, activities that violate our Terms of Service or that are illegal; and to
       protect our rights and the rights and safety of our users or others.
    </p>
    <h2>How We May Share Your Personal Information</h2>
    <p>We may share your personal information with service providers that help us operate our business, including
       through
       payment processing, managing customer information and providing customer service, facilitating scheduling,
       facilitating email communications, providing security services and cloud-based data storage, hosting our Site and
       assistance with other IT-related functions, advertising and marketing, providing analytics information, legal and
       accounting services, among other business functions.</p>
    <p>We may also share your personal information as required or permitted by law to comply with a subpoena or similar
       legal process or government request, or when we believe in good faith that disclosure is legally required or
       otherwise necessary to protect our rights and property or the rights, property or safety of others, including to
       law
       enforcement agencies, and judicial and regulatory authorities. We may also share your personal information with
       third parties to help detect and protect against fraud or data security vulnerabilities. We may transfer your
       personal information to a third party in the event of an actual or proposed sale, merger, reorganization of our
       entity or other restructuring.</p>
    <h2>Cookies and Analytics</h2>
    <p>Cookies are small, sometimes encrypted text files that are stored on computer hard drives by websites that you
       visit. They are used to help users navigate websites efficiently as well as to provide information to the owner
       of
       the websites. To find out more about cookies, including how to see what cookies have been set and how to manage
       and
       delete them, please visit www.allaboutcookies.org.
    </p>
    <p>When you visit the Site, we may place a “cookie” or other online tracking device (e.g. web beacons) that
       recognizes
       you when you visit the Sites. These are used for technical convenience to store information on your computer or
       other device. For instance, we may use a cookie to store form information that you have entered so that you do
       not
       have to enter such information again. You may set your browser to notify you when you receive a cookie. Many web
       browsers also allow you to block cookies. If you block cookies you may not be able to access certain parts of
       this
       website. You can disable cookies from your computer system by following the instructions on your browser or at
       www.youradchoices.com.
    </p>
    <p>We use Google Analytics to evaluate the use of our website. Google Analytics uses cookies and other identifiers
       to
       collect information, such as how often users visit a website, what pages they visit when they do so, and what
       other
       websites they visited prior to visiting a website. To learn more about how Google Analytics collects personal
       information, review Google’s Privacy Policy.</p>
    <h2>Do Not Track</h2>
    <p>We do not respond to Do Not Track (DNT) header signals at this time</p>
    <h2>California Shine the Light</h2>
    <p>California Civil Code Section 1798.83, also known as the “Shine the Light” law, permits California residents that
       have an established business relationship with a business to annually request, free of charge, information about
       certain categories of Personal Information a business has disclosed to third parties for those parties’ direct
       marketing purposes in the preceding calendar year. We do not disclose Personal Information to third parties for
       their direct marketing purposes.</p>
    <h2>Personal Information of Minors</h2>
    <p>Our products and services are not directed to minors under the age of 13 and we do not knowingly collect the
       Personal Information of minors under 13.</p>
    <h2>How We Keep Your Personal Information Secure</h2>
    <p>We implement and maintain reasonable security measures appropriate to the nature of the Personal Information that
       we
       collect, use, retain, transfer or otherwise process. Those measures include administrative, physical and
       technical
       safeguards to protect the security, confidentiality and integrity of Personal Information. However, data security
       incidents and breaches can occur due to a variety of factors that cannot reasonably be prevented; therefore, our
       safeguards may not always be adequate to prevent all breaches of security.</p>
    <h2>Other Websites and Social Media Buttons</h2>
    <p>Our Site may contain links to other websites or social media buttons. We are not responsible for the privacy
       practices or the content of those websites or social media platforms. This Privacy Policy applies only to our
       business and the personal information we collect.</p>
    <h2>Changes to This Policy</h2>
    <p>We will review and update this Policy as required to keep current with rules and regulations, new technologies
       and
       security standards. We will post those changes on the website or update the “last updated” date of the Privacy
       Policy. If the changes are material, you will be notified via email or a notice on our website.</p>
    <h2>Accessibility</h2>
    <p>We want our communications to be accessible to people with disabilities. To make accessibility-related requests
       or
       report barriers, please contact us at [email].</p>
    <h2>Contact Us</h2>
    <p>If there are any questions regarding this Policy or to request a copy of this Policy in another format you may
       contact us using the information below.
       E-mail: [email]
       Phone: [phone - optional]
       Address: [address - optional]</p>
  </div>
</div>
