import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[APPLE-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetApplePulsationStatusSuccess = createAction('[APPLE-HEARTBEAT-PULSATION] Get Apple PulsationStatusSuccess',
  props<{
    payload: PulsationModels.Store.CameraPulsation[]
  }>());

export const GetApplePulsationStatusFail = createAction('[APPLE-HEARTBEAT-PULSATION] Get Apple PulsationStatusFail', props<{ message?: string }>());

