<ng-container *ngIf="src && show">
  <div class="snapshot w-100 h-100">
    <img
        [class.w-100]="snapshot.naturalWidth >= snapshot.naturalHeight"
        [class.h-100]="snapshot.naturalWidth < snapshot.naturalHeight"
        #snapshot
        [lazyLoad]="src"
        [class.hidden]="snapshot.alt === 'error'"
        (onStateChange)="imgStateChange($event)"
    />
    <!--    <div *ngIf="isError" class="error">-->
    <!--      <img src="assets/video-not-found.svg">-->
    <!--    </div>-->
  </div>
</ng-container>
