import { createReducer, on } from '@ngrx/store';
import { WidgetDeprecated, WidgetDataTypes, Workspace, WorkspaceFavorite } from '@models/workspace.model';
import { WorkspaceActions } from '@states/workspace/workspace.action-types';
import { initialWidget, initialWorkspace } from '@consts/workspace.const';
import { SortDirection } from '@angular/material/sort';
import { WidgetType } from '@enums/workspace.enum';
import * as _ from 'lodash';
import { setInitialFalse } from '@states/workspace/workspace.actions';

export interface WorkspaceState {
  widgets: { [key: string]: WidgetDataTypes };
  workspaces: Workspace[];
  favorites: WorkspaceFavorite[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;

  query: string;
  selectedWidget: WidgetDeprecated;
  selectedWorkspace: Workspace;
  variablesAutoComplete: { _id: string; name: string }[];
  variablesAutoCompleteMap: { [key: string]: { _id: string; name: string } };
  isEditMode: boolean;
  isExpanded: boolean;
  filter: {
    start: any;
    end: any;
  };
}

const initialState: WorkspaceState = {
  widgets: {},
  selectedWidget: null,
  workspaces: null,
  favorites: null,
  totalItemsCount: 0,
  page: 0,
  perPage: 10,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,

  selectedWorkspace: null,
  variablesAutoComplete: [],
  variablesAutoCompleteMap: {},
  isEditMode: false,
  isExpanded: true,
  filter: null,
};

export const workspaceStateReducer = createReducer(
  initialState,
  on(WorkspaceActions.resetToInitialState, state => {
    return {
      ...initialState,
      favorites: state.favorites,
    };
  }),
  /**
   * for Count for now
   */
  on(WorkspaceActions.addWidget, (state, {widgetType, viewMode}) => {
    const localIndex = state.selectedWorkspace.widgets.length;
    const widget: WidgetDeprecated = {
      ...initialWidget,
      type: widgetType,
      viewMode,
      name: `Widget ${localIndex + 1}`,
      localIndex: localIndex,
      variables: widgetType === WidgetType.HEATMAP ? [] : null,
    };
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: state.selectedWorkspace.widgets.concat(widget),
      },
    };
  }),
  on(WorkspaceActions.changeWorkspaceName, (state, {name}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        name,
      },
    };
  }),
  on(WorkspaceActions.setSelectedWidget, (state, {widget}) => {
    return {
      ...state,
      selectedWidget: state.selectedWidget?.localIndex === widget.localIndex ? null : widget,
    };
  }),

  on(WorkspaceActions.getWorkspacesSuccess, (state, {workspaces}) => {
    return {
      ...state,
      workspaces: workspaces,
    };
  }),
  on(WorkspaceActions.getFavoritesSuccess, (state, {favorites}) => {
    return {
      ...state,
      favorites,
    };
  }),
  on(WorkspaceActions.addFavoriteWorkspaceSuccess, (state, {workspace}) => {
    const favorites = !!state.favorites?.length ? [...state.favorites] : [];
    favorites.push({_id: workspace._id, name: workspace.name});
    return {
      ...state,
      favorites,
    };
  }),
  on(WorkspaceActions.removeFavoriteWorkspaceSuccess, (state, {workspaceId}) => {
    const favorites = !!state.favorites?.length ? [...state.favorites] : [];
    const idx = favorites.findIndex(item => item._id === workspaceId);
    favorites.splice(idx, 1);
    return {
      ...state,
      favorites,
    };
  }),
  on(WorkspaceActions.getWorkspaceSuccess, (state, {workspace}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...workspace,
        isInitial: false,
        widgets: workspace.widgets.map((widget, index) => {
          return {
            ...widget,
            localIndex: index,
          };
        }),
      },
    };
  }),
  on(WorkspaceActions.setInitialWorkspace, state => {
    return {
      ...state,
      selectedWorkspace: initialWorkspace,
      selectedWidget: null,
    };
  }),
  on(WorkspaceActions.resetSelectedWorkspace, state => {
    return {
      ...state,
      selectedWorkspace: initialState.selectedWorkspace,
    };
  }),
  on(WorkspaceActions.updateSelectedWidget, (state, {field, value, index}) => {
    return {
      ...state,
      selectedWidget: {
        ...state.selectedWidget,
        [field]: value,
      },
    };
  }),
  on(WorkspaceActions.updateWidget, (state, {field, value, index}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: updateWidgets(index, state.selectedWorkspace.widgets, field, value),
      },
      selectedWidget: {
        ...state.selectedWidget,
        [field]: value,
      },
    };
  }),
  on(WorkspaceActions.removeWidget, (state, {localId}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: deleteWidget(localId, state.selectedWorkspace.widgets),
      },
    };
  }),
  on(WorkspaceActions.getVariablesAutocompleteSuccess, (state, {variablesAutoComplete}) => {
    const variablesAutoCompleteMap = {};
    variablesAutoComplete.forEach(item => {
      variablesAutoCompleteMap[item._id] = item;
    });
    return {
      ...state,
      variablesAutoComplete,
      variablesAutoCompleteMap,
    };
  }),
  on(WorkspaceActions.getWidgetDataSuccess, (state, {data, index}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        // widgets: updateWidgets(index, state.selectedWorkspace.widgets, 'counter', data),
      },
      widgets: {
        ...state.widgets,
        [index]: data,
      },
    };
  }),
  on(WorkspaceActions.updateEditMode, (state, {isEditMode}) => {
    return {
      ...state,
      isEditMode,
    };
  }),
  on(WorkspaceActions.setAbsoluteDate, (state, {start, end, timezone}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: updateWidgetsAbsoluteTimeRangeFilters(state.selectedWorkspace.widgets, start, end, timezone),
      },
      filter: {
        start,
        end,
      },
      widgets: initialState.widgets,
    };
  }),
  on(WorkspaceActions.setQuery, (state, {query}) => {
    return {
      ...state,
      query,
    };
  }),
  on(WorkspaceActions.setIsExpanded, state => {
    return {
      ...state,
      isExpanded: !state.isExpanded,
    };
  }),
  on(WorkspaceActions.clearFilters, state => {
    return {
      ...state,
      filter: initialState.filter,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: rmWidgetsAbsoluteTimeRangeFilters(state.selectedWorkspace.widgets),
      },
      widgets: initialState.widgets,
    };
  }),
  on(WorkspaceActions.resetSelectedWidget, state => {
    return {
      ...state,
      selectedWidget: initialState.selectedWidget,
    };
  }),
  on(WorkspaceActions.updateCustomRelativeTime, (state, {data, index}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: updateRelativeWidgets(index, state.selectedWorkspace.widgets, 'relativeCustom', data),
      },
      selectedWidget: {
        ...state.selectedWidget,
        relativeCustom: data,
        relative: null,
      },
    };
  }),
  on(WorkspaceActions.updateRelativeTime, (state, {data, index}) => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        widgets: updateRelativeWidgets(index, state.selectedWorkspace.widgets, 'relative', data),
      },
      selectedWidget: {
        ...state.selectedWidget,
        relativeCustom: null,
        relative: data,
      },
    };
  }),
  on(WorkspaceActions.setInitialFalse, state => {
    return {
      ...state,
      selectedWorkspace: {
        ...state.selectedWorkspace,
        isInitial: false,
      },
    };
  }),
);

const updateWidgets = (index: number, widgets: WidgetDeprecated[], field: string, value: any): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    if (widget.localIndex === index) {
      return {
        ...widget,
        [field]: value,
      };
    } else {
      return widget;
    }
  });
};

const updateRelativeWidgets = (index: number, widgets: WidgetDeprecated[], field: string, value: any): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    if (widget.localIndex === index) {
      if (field === 'relative') {
        return {
          ...widget,
          relative: value,
          relativeCustom: null,
        };
      } else {
        return {
          ...widget,
          relative: null,
          relativeCustom: value,
        };
      }
    } else {
      return widget;
    }
  });
};

const deleteWidget = (index: number, widgets: WidgetDeprecated[]): WidgetDeprecated[] => {
  const indexWidget = widgets.findIndex(widget => widget.localIndex === index);
  const _widgets = _.cloneDeep(widgets);
  _widgets.splice(indexWidget, 1);
  for (let [index, widget] of _widgets.entries()) {
    widget.localIndex = index;
  }
  return _widgets;
};

const updateWidgetsAbsoluteTimeRangeFilters = (widgets: WidgetDeprecated[], start: string, end: any, timezone: string): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    return {
      ...widget,
      absolute: {
        start,
        end,
        timezone,
      },
      // relative: null,
    };
  });
};

const rmWidgetsAbsoluteTimeRangeFilters = (widgets: WidgetDeprecated[]): WidgetDeprecated[] => {
  return [...widgets].map(widget => {
    return {
      ...widget,
      absolute: null,
      // relative: null,
    };
  });
};
