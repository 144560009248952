import { createAction, props } from '@ngrx/store';
import { DoorModels } from '@models/door.model';

export const resetToInitialState = createAction('[DOORS] Reset to Initial state');
export const resetUnsaved = createAction('[DOORS] Reset unsaved to Initial state');

export const getDoors = createAction('[DOORS] Get Doors');
export const getDoorsSuccess = createAction('[DOORS] Get Doors Success', props<{ doors: DoorModels.Door[] }>());

export const setDoors = createAction('[DOORS] Set Doors', props<{ doors: DoorModels.Door[] }>());

export const addDoor = createAction('[DOORS] Save Door', props<{ door: DoorModels.Door }>());
export const addDoorSuccess = createAction('[DOORS] Save Door Success', props<{ door: DoorModels.Door }>());

export const removeDoor = createAction('[DOORS] Remove Door', props<{ door: DoorModels.Door }>());
export const removeDoorSuccess = createAction('[DOORS] Remove Door Success', props<{ door: DoorModels.Door }>());

export const editDoor = createAction('[DOORS] Edit Door', props<{ door: DoorModels.Door }>());
export const editDoorSuccess = createAction('[DOORS] Edit Door Success', props<{ door: DoorModels.Door }>());









