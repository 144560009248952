import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticsState } from '@states/analytics/analytics.reducer';

export const selectAnalyticState = createFeatureSelector<AnalyticsState>('analyticsState');

export const selectItems = createSelector(selectAnalyticState, ({ items }: AnalyticsState) => items);
export const selectPage = createSelector(selectAnalyticState, ({ page }: AnalyticsState) => page);
export const selectPerPage = createSelector(selectAnalyticState, ({ perPage }: AnalyticsState) => perPage);
export const selectIsLastPage = createSelector(selectAnalyticState, ({ isLastPage }: AnalyticsState) => isLastPage);

export const selectAnalyticsChartItems = createSelector(
  selectAnalyticState,
  ({ analyticsChartItems }: AnalyticsState) => analyticsChartItems
);
export const selectAnalyticsChartLegends = createSelector(
  selectAnalyticState,
  ({ analyticsChartLegends }: AnalyticsState) => analyticsChartLegends
);
export const selectAnalyticsChartAxisYMax = createSelector(
  selectAnalyticState,
  ({ analyticsChartAxisYMax }: AnalyticsState) => analyticsChartAxisYMax
);
export const selectAnalyticsChartAxisXLabels = createSelector(
  selectAnalyticState,
  ({ analyticsChartAxisXLabels }: AnalyticsState) => analyticsChartAxisXLabels
);
