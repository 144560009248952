import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIInputStyle } from '@enums/shared.enum';

@Component({
  selector: 'app-video-signature-modal',
  templateUrl: './video-signature-modal.component.html',
  styleUrls: ['./video-signature-modal.component.scss'],
})
export class VideoSignatureModalComponent implements OnInit {
  public inputStyle = UIInputStyle;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      signature: string;
    }
  ) {}

  ngOnInit(): void {}
}
