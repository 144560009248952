import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../edge/utils.service';
import { lastValueFrom, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { CameraSelectors } from "@states/camera/camera.selector-types";
import { getCameraSnapshotById } from "@states/camera/camera.selectors";

@Pipe({
  name: 'cameraBestImagePipe',
})
export class CameraBestImagePipe implements PipeTransform {
  constructor(
    private store$: Store,
    private utilsService: UtilsService  ) {
  }

  transform(value: { edgeId: string, cameraId: string }): Observable<string> {
    return this.store$.select(CameraSelectors.getCameraSnapshotById(value.cameraId))
    // return this.utilsService.cameraPlaceholder(value.edgeId, value.cameraId)
  }
}
