import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, debounceTime, exhaustMap, mergeMap, of, share, switchMap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { SharedActions } from '@states/shared/shared.action-types';
import { PermissionGroupsActions } from '@states/group/group.action-types';
import { PermissionGroupService } from '../../services/permission-group.service';
import { PermissionActions } from '@states/permissions/permissions.action-types';
import { OrganizationService } from '../../development/organization.service';

@Injectable()
export class PermissionGroupsEffect {
  public beforeGetPermissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.beforeGetPermissionGroups),
      exhaustMap(() => [PermissionGroupsActions.setIsLoading({ isLoading: true }), PermissionGroupsActions.getPermissionGroups()]),
    ),
  );

  public getPermissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.getPermissionGroups),
      withLatestFrom(this.store$.pipe(select((state) => state.permissionGroupState))),
      switchMap(([, { filters, page, perPage, isLastPage }]) => {
        if (!isLastPage) {
          return this.permissionGroupService.findAll(filters, { page, perPage })
            .pipe(
              switchMap((res) => {
                return [PermissionGroupsActions.getPermissionGroupsSuccess({ permissionGroups: res.items }), PermissionGroupsActions.setIsLoading({ isLoading: false })];
              }),
              catchError((response) => {
                return [PermissionGroupsActions.getPermissionGroupsFail(), PermissionGroupsActions.setIsLoading({ isLoading: false })];
              }),
            );
        } else {
          return of(PermissionGroupsActions.setIsLoading({ isLoading: false }));
        }
      }),
      share(),
    ),
  );

  public savePermissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.savePermissionGroup),
      withLatestFrom(this.store$.pipe(select((state) => state.permissionGroupState))),
      switchMap(([{ permissionGroup }]) => {
        const beforeSend = permissionGroup;
        if (beforeSend?._id) {
          return this.permissionGroupService.update(beforeSend)
            .pipe(
              switchMap((res) => {
                return [
                  PermissionGroupsActions.updatePermissionGroupSuccess({ permissionGroup: beforeSend }),
                ];
              }),
              catchError((response) => {
                return [SharedActions.showMessage({ error: 'Group update fail' })];
              }),
            );
        }
        return this.permissionGroupService.create(beforeSend)
          .pipe(
            switchMap((res) => {
              return [PermissionGroupsActions.savePermissionGroupSuccess({ permissionGroup: res })];
            }),
            catchError((response) => {
              return [PermissionGroupsActions.savePermissionGroupFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public getSelectedPermissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.getSelectedPermissionGroup),
      switchMap(({ id }) => {
        return this.permissionGroupService.findOne(id)
          .pipe(
            switchMap((res) => {
              return [PermissionGroupsActions.setSelectedPermissionGroup({ selectedPermissionGroup: res })];
            }),
            catchError((response) => {
              return [PermissionGroupsActions.getSelectedPermissionGroupFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public updatePermissionGroupPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.updatePermissionGroup),
      withLatestFrom(this.store$.pipe(select((state) => state.permissionGroupState))),
      switchMap(([, { selectedPermissionGroup }]) => {
        const beforeSend = selectedPermissionGroup;
        return this.permissionGroupService.update(beforeSend)
          .pipe(
            switchMap((res) => {
              return [SharedActions.doNothing()];
            }),
            catchError((response) => {
              return [SharedActions.showMessage({ error: 'Role update fail' })];
            }),
          );
      }),
      share(),
    ),
  );

  public filterTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.setFilter),
      debounceTime(400),
      switchMap(() => [PermissionGroupsActions.resetEntities(), PermissionGroupsActions.beforeGetPermissionGroups()]),
    ),
  );

  public autoUpdateTrigger = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PermissionGroupsActions.changePermission,
        PermissionGroupsActions.selectPermissionsByEntity,
        PermissionGroupsActions.selectAllPermissions,
        PermissionGroupsActions.makeInherited,
      ),
      debounceTime(400),
      switchMap(() => [PermissionGroupsActions.updatePermissionGroup()]),
    ),
  );

  public changeUsersTriggered = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.changeUsers),
      withLatestFrom(this.store$.pipe(select((state) => state.permissionGroupState))),
      debounceTime(400),
      switchMap(([{ users }, { selectedPermissionGroup }]) =>
        this.permissionGroupService.updateUsers(selectedPermissionGroup._id, users)
          .pipe(
            switchMap((res) => {
              return [
                PermissionGroupsActions.changeUsersSuccess({ users }),
                SharedActions.showMessage({ success: 'Users have been updated' }),
              ];
            }),
            catchError((response) => {
              return [SharedActions.showMessage({ error: response?.error?.message })];
            }),
          ),
      ),
    ),
  );


  public removeGroupUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.removeGroupUser),
      withLatestFrom(this.store$.pipe(select((state) => state.permissionGroupState))),
      mergeMap(([{ userId }, { selectedPermissionGroup }]) => {
          const groupUsers = { ...selectedPermissionGroup.users };
          delete groupUsers[userId];
          return this.permissionGroupService.updateUsers(selectedPermissionGroup._id, groupUsers)
            .pipe(
              mergeMap((res) => {
                return [
                  PermissionGroupsActions.changeUsersSuccess({ users: groupUsers }),
                  SharedActions.showMessage({ success: 'Users have been updated' }),
                ];
              }),
              catchError((response) => {
                return [SharedActions.showMessage({ error: response?.error?.message })];
              }),
            );
        },
      ),
    ),
  );

  public removePermissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.removePermissionGroup),
      switchMap(({ id }) => {
        return this.permissionGroupService.remove(id)
          .pipe(
            switchMap((res) => {
              return [PermissionGroupsActions.removePermissionGroupSuccess({ id })];
            }),
            catchError((response) => {
              return [PermissionGroupsActions.removePermissionGroupFail({ id })];
            }),
          );
      }),
      share(),
    ),
  );

  public getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.getPermissions),
      switchMap(() => {
        return this.permissionGroupService.getUserPermissions()
          .pipe(
            switchMap((res) => {
              return [PermissionActions.getPermissionsSuccess(res)];
            }),
            catchError((response) => {
              return [PermissionActions.getPermissionsFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public getSelectedUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.getSelectedUserPermissions),
      switchMap(({ userId }) => {
        return this.permissionGroupService.getUserPermissionsById(userId)
          .pipe(
            switchMap((res) => {
              return [PermissionGroupsActions.getSelectedUserPermissionsSuccess({ selectedUserPermissions: res, user: res.user })];
            }),
            catchError((response) => {
              return [PermissionGroupsActions.getSelectedUserPermissionsFail()];
            }),
          );
      }),
      share(),
    ),
  );


  public getOrganizationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsActions.getOrganizationUsers),
      switchMap(() => {
        return this.organizationService.getActiveOrganizationUsers()
          .pipe(
            switchMap((res) => {
              return [PermissionGroupsActions.getOrganizationUsersSuccess({ users: res })];
            }),
            catchError((response) => {
              return [PermissionActions.getPermissionsFail()];
            }),
          );
      }),
      share(),
    ),
  );


  public updateUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.updateUserGroups),
      switchMap(({ groupIds, userId }) => {
        return this.permissionGroupService.updateUserGroups(groupIds, userId)
          .pipe(
            switchMap((res) => {
              return [SharedActions.showMessage({ success: 'Permissions has been updated' })];
            }),
            catchError((response) => {
              return [SharedActions.showMessage({ error: 'Permissions update failed' })];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private permissionGroupService: PermissionGroupService,
              private organizationService: OrganizationService) {
  }

  // private beforeUpdateGroup(permissionGroup: PermissionGroupModels.PermissionGroupDocumentBase): PermissionGroupModels.PermissionGroupDocumentBase {
  //   const selectedRoles = permissionGroup?.selectedRoles ? Object.values(permissionGroup.selectedRoles) : [];
  //   return { ...permissionGroup, selectedRoles: selectedRoles };
  // }
}
