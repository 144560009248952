<div class="ui-header">
  <div class="breadcrumb">
    <ng-container *ngFor="let item of breadCrumbs; let idx = index">
      <a *ngIf="!!item?.route; else noLink"
         class="link"
         routerLink="{{ item.route }}"
         fragment="{{ item.fragment }}"
         [queryParams]="item.queryParams"
      >
        <span class="name">{{ item.name }}</span>
      </a>
      <ng-template #noLink>
        <div class="page flex-row start-center">
          <ui-truncated-html style="max-width: 200px">
            <span class="name"
            >{{ item.name }}</span>
          </ui-truncated-html>
        </div>
      </ng-template>
      <span class="ui-icon-expand-arrow" *ngIf="idx !== breadCrumbs.length - 1"></span>
    </ng-container>
  </div>

  <div class="ui-header-content">
    <ng-content>

    </ng-content>
  </div>

</div>

