export enum PreloaderColor {
  white,
  blue,
  gray,
  red
}

export enum UIInputStyle {
  labelInside,
  labelOutside,
  noLabel,
  noLabelThick
}

export enum DayOfWeek {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}

export enum UiButtonStyle {
  red,
  default,
  gray,
  green,
}

export enum Region {
  US_EAST = 'us-east',
  US_WEST = 'us-west',
  ISRAEL = 'israel',
}

export enum ConfirmModalType {
  delete,
  leave,
}

export enum UiAutocompleteTemplate {
  string,
  user,
}

export enum UiUptimeBarColors {
  primary,
  red,
  pink,
  gray,
  green,
  offline,
  online,
  green50
}

export enum UiUserSettingsDateType {
  timeSec,
  time, // time only
  mdTime, //month day time
  mdyTime, // month day year time
  mdy, //month day year
  mdyTimeSec, // month day year time seconds
}

export enum UiUserSettingsDateFormat {
  ddMMyy,
  MMddyy,
}

export enum UiCalendarPickerType {
  RELATIVE,
  ABSOLUTE,
}

/**
 * @deprecated
 */
export enum ThumbnailImageSelectorType {
  Camera,
  Alert
}

export enum NotFoundTemplates {
  Default,
  InviteNotFound,
  InviteAccepted
}

export enum AuthResponseFromUrl {
  EmailVerificationRequired,
  IpRulesRequired,
  LoginRequired,
  Success,
  WrongPassword,
  MFAWrongCode,
  UserBlocked
}
