import { Component, Input, OnInit } from '@angular/core';
import { MsgBoxType } from './msg-box.model';

@Component({
  selector: 'app-msg-box',
  templateUrl: './msg-box.component.html',
  styleUrls: ['./msg-box.component.scss'],
})
export class MsgBoxComponent implements OnInit {
  @Input()
  type: MsgBoxType;

  cleared = false;

  class: string;
  icon = 'check-circle';

  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case MsgBoxType.WARNING:
        this.class = 'yellow';
        this.icon = 'exclamation';
        break;
      case MsgBoxType.ERROR:
        this.class = 'red';
        this.icon = 'x-circle';
        break;
      case MsgBoxType.SUCCESS:
      default:
        this.class = 'green';
        break;
    }
  }

  clear() {
    this.cleared = true;
  }
}
