import { EdgeCamera } from '../../../cameras/camera.model';
import { createReducer, on } from '@ngrx/store';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { AccessoryType, ActionType, AgeType, AlertSettingSendFrequency, AlertType, AnalyticClasses, CarryingType, CarType, ConfigurationFilterType, DetectionType, FootwearType, GenderType, HairType, LowerBodyType, UpperBodyType } from '@enums/alert-events.enum';
import { alertEventsMenuLevel2Validation } from '@consts/menu.const';
import { AlertActionForm, AlertActionNotifications, AlertDirectAction, AlertEvent, AlertEventLineCrossing, AlertEventLineCrossingDirection, AlertEventLineCrossingState, AlertEventNotification, AlertEventTrafficControl, AlertGpioAction, AlertMsgAction, AlertSettings, Configuration, ObjectInterface } from '@models/alert-events.model';
import { SortDirection } from '@angular/material/sort';
import { Zones } from '../../../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import { settingScheduleDefault, settingScheduleDefaultFrom, settingScheduleDefaultTo } from '@consts/alert-events.const';
import { FlowLookup } from '@models/alerts-v2.model';
import { CameraLookup } from '@models/camera.model';
import { resetAlertEventsState } from '@states/alert-events/alert-events.actions';


export declare interface AlertEventsState {
  alertEvents: AlertEvent[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;
  flowTypes: FlowLookup[];
  cameras: CameraLookup[];

  //details tab
  alertName: string;
  selectedCamera: EdgeCamera.CameraItem;
  //
  multiCameras: EdgeCamera.CameraItem[];
  //
  selectedAlertType: AlertType;

  //configuration tab
  trackedObject: AnalyticClasses;
  multipleObjects: ObjectInterface[];
  offenderObject: AnalyticClasses;
  configurationFilterType: ConfigurationFilterType;
  detectionType: DetectionType;
  zones: Zones;
  definedZones: boolean;
  measureCrossZones: boolean;
  markedIdx: number[];
  notifications: AlertEventNotification;
  genderType: GenderType[];
  ageType: AgeType[];
  footWearType: FootwearType[];
  footWearColor: string[];
  lowerBodyType: LowerBodyType[];
  lowerBodyColor: string[];
  upperBodyType: UpperBodyType[];
  upperBodyColor: string[];
  hairType: HairType[];
  hairColor: string[];
  accessoryType: AccessoryType[];
  carryingType: CarryingType[];
  level2MenuValid: { [key: string]: boolean };
  settings: AlertSettings;
  selectedAlertEvent: AlertEvent;
  carColor: string[];
  carMake: string[];
  carModel: string[];
  carType: CarType[];
  lineCrossing: AlertEventLineCrossing;
  trafficControl: AlertEventTrafficControl;
  greenList: string;
  redList: string;
  unrecognized: boolean;
  detectionAdditionalAttributes: { direction?: string; count?: number; sensitivity?: number };
  timezone: string;
  tresholdTime: number;
  actions: AlertActionNotifications;
  isLastPage: boolean;
}

export const initialState: AlertEventsState = {
  alertEvents: null,
  totalItemsCount: 0,
  page: 0,
  perPage: 100,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  flowTypes: [],
  cameras: [],
  //
  alertName: null,
  selectedCamera: null,
  selectedAlertType: AlertType.analytic,
  multiCameras: [],
  //
  trackedObject: AnalyticClasses.person,
  multipleObjects: [],
  offenderObject: null,
  configurationFilterType: ConfigurationFilterType.noFilters,
  detectionType: DetectionType.Apperance,
  zones: {},
  definedZones: false,
  measureCrossZones: false,
  markedIdx: [],
  notifications: {
    orgUsers: [],
    manualUsers: [],
    notificationMethods: {},
  },
  genderType: [],
  ageType: [],
  footWearType: [],
  footWearColor: [],
  lowerBodyType: [],
  lowerBodyColor: [],
  upperBodyType: [],
  upperBodyColor: [],
  hairType: [],
  hairColor: [],
  accessoryType: [],
  carryingType: [],
  level2MenuValid: alertEventsMenuLevel2Validation,
  settings: {
    schedule: settingScheduleDefault,
    frequency: AlertSettingSendFrequency.everyTimeEvenOccur,
    additionalOptions: false,
    // additionalOptions
    pushAlert: true,
    blockNotificationPeriod: null,
    enableNotificationSound: false,
    alertThumbnail: true,
    alertZoomThumbnail: true,
    autoArchive: {
      enabled: false,
      ttlSeconds: 0,
    },
  },
  selectedAlertEvent: null,
  carColor: [],
  carMake: [],
  carModel: [],
  carType: [],
  lineCrossing: {
    p1: undefined,
    p2: undefined,
    d: AlertEventLineCrossingDirection.ANY,
    state: AlertEventLineCrossingState.NO_SELECTION,
  },
  trafficControl: {
    state: AlertEventLineCrossingState.NO_SELECTION,
    lines: [
      // {name: 'First', color: 'blue', 'p1': {'x': 0.1, 'y': 0.35}, 'p2': {'x': 0.65, 'y': 0.35}, 'd': 0},
      // {name: 'Second', color: 'green', 'p1': {'x': 0.1, 'y': 0.45}, 'p2': {'x': 0.65, 'y': 0.45}, 'd': 1},
      // {name: 'Third', color: 'yellow', 'p1': {'x': 0.1, 'y': 0.5}, 'p2': {'x': 0.65, 'y': 0.5}, 'd': 2}
    ],
    distance: undefined,
    distanceUnits: 'meter',
  },
  greenList: '',
  redList: '',
  unrecognized: true,
  detectionAdditionalAttributes: { direction: 'above', count: 0, sensitivity: 0.5 },
  timezone: null,
  tresholdTime: 0,
  actions: null,
  isLastPage: false,
};

export const alertEventsStateReducer = createReducer(
  initialState,
  on(AlertEventsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlertEventsActions.resetAlertEvents, state => {
    return {
      ...initialState,
      alertEvents: [],
    };
  }),
  // on(AlertEventsActions.removeEvent, (state, {id}) => {
  //   const alertEvents = state.alertEvents.filter(event => event._id !== id);
  //   return {
  //     ...initialState,
  //     alertEvents
  //   };
  // }),
  on(AlertEventsActions.setSelectedCamera, (state, { selectedCamera }) => {
    return {
      ...state,
      selectedCamera,
    };
  }),
  on(AlertEventsActions.setTrackedObject, (state, { trackedObject }) => {
    return {
      ...state,
      trackedObject,
    };
  }),
  on(AlertEventsActions.setZones, (state, { zones }) => {
    return {
      ...state,
      zones,
    };
  }),
  on(AlertEventsActions.setDefinedZones, (state, { definedZones }) => {
    return {
      ...state,
      definedZones,
    };
  }),
  on(AlertEventsActions.setMeasureCrossZones, (state, { measureCrossZones }) => {
    return {
      ...state,
      measureCrossZones,
    };
  }),
  on(AlertEventsActions.setMarkedIdx, (state, { markedIdx }) => {
    return {
      ...state,
      markedIdx,
    };
  }),
  on(AlertEventsActions.setAlertType, (state, { alertType }) => {
    return {
      ...state,
      selectedAlertType: alertType,
    };
  }),
  on(AlertEventsActions.setDetectionType, (state, { detectionType }) => {
    const zoneState: any = {};
    switch (detectionType) {
      case DetectionType.TrafficControl:
        zoneState.trafficControl = initialState.trafficControl;
        break;
      case DetectionType.Tailgating:
      case DetectionType.LineCrossing:
        zoneState.lineCrossing = initialState.lineCrossing;
        break;
      default:
        zoneState.zones = initialState.zones;
        zoneState.definedZones = initialState.definedZones;
        zoneState.markedIdx = initialState.markedIdx;
    }
    return {
      ...state,
      detectionType,
      detectionAdditionalAttributes: {
        direction: initialState.detectionAdditionalAttributes.direction,
        count: initialState.detectionAdditionalAttributes.count,
        sensitivity: initialState.detectionAdditionalAttributes.sensitivity,
      }, //refresh
      ...zoneState,
    };
  }),
  on(AlertEventsActions.setConfigurationFilterType, (state, { configurationFilterType }) => {
    return {
      ...state,
      configurationFilterType,
    };
  }),
  on(AlertEventsActions.setNotifications, (state, { notifications }) => {
    return {
      ...state,
      notifications,
    };
  }),
  on(AlertEventsActions.setMultipleObjects, (state, { multipleObjects }) => {
    return {
      ...state,
      multipleObjects,
    };
  }),
  on(AlertEventsActions.setOffenderObject, (state, { offenderObject }) => {
    return {
      ...state,
      offenderObject,
    };
  }),
  on(AlertEventsActions.setAlertName, (state, { alertName }) => {
    return {
      ...state,
      alertName,
    };
  }),
  on(AlertEventsActions.setGenderType, (state, { genderType }) => {
    return {
      ...state,
      genderType: addToArray(state.genderType, genderType),
    };
  }),
  on(AlertEventsActions.setAgeType, (state, { ageType }) => {
    return {
      ...state,
      ageType: addToArray(state.ageType, ageType),
    };
  }),
  on(AlertEventsActions.setFootwearType, (state, { footwearType }) => {
    return {
      ...state,
      footWearType: addToArray(state.footWearType, footwearType),
    };
  }),
  on(AlertEventsActions.setFootwearColor, (state, { footwearColor }) => {
    return {
      ...state,
      footWearColor: footwearColor,
    };
  }),
  on(AlertEventsActions.setLowerBodyType, (state, { lowerBodyType }) => {
    return {
      ...state,
      lowerBodyType: addToArray(state.lowerBodyType, lowerBodyType),
    };
  }),
  on(AlertEventsActions.setLowerBodyColor, (state, { lowerBodyColor }) => {
    return {
      ...state,
      lowerBodyColor,
    };
  }),
  on(AlertEventsActions.setUpperBodyColor, (state, { upperBodyColor }) => {
    return {
      ...state,
      upperBodyColor,
    };
  }),
  on(AlertEventsActions.setUpperBodyType, (state, { upperBodyType }) => {
    return {
      ...state,
      upperBodyType: addToArray(state.upperBodyType, upperBodyType),
    };
  }),
  on(AlertEventsActions.setHairColor, (state, { hairColor }) => {
    return {
      ...state,
      hairColor,
    };
  }),
  on(AlertEventsActions.setHairType, (state, { hairType }) => {
    return {
      ...state,
      hairType: addToArray(state.hairType, hairType),
    };
  }),
  on(AlertEventsActions.setAccessoryType, (state, { accessoryType }) => {
    return {
      ...state,
      accessoryType: addToArray(state.accessoryType, accessoryType),
    };
  }),
  on(AlertEventsActions.setCarryingType, (state, { carryingType }) => {
    return {
      ...state,
      carryingType: addToArray(state.carryingType, carryingType),
    };
  }),
  on(AlertEventsActions.addValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: true,
      },
    };
  }),
  on(AlertEventsActions.rmValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: false,
      },
    };
  }),
  on(AlertEventsActions.setSettingsSendFrequency, (state, { frequency }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        frequency,
      },
    };
  }),
  on(AlertEventsActions.setSettingsBlockNotificationPeriod, (state, { blockNotificationPeriod }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        blockNotificationPeriod,
      },
    };
  }),
  on(AlertEventsActions.setSettingsAdditionalOptions, (state, { additionalOptions }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        additionalOptions,
      },
    };
  }),
  on(AlertEventsActions.setEdgeTimezone, (state, { timezone }) => {
    return {
      ...state,
      timezone,
    };
  }),
  on(AlertEventsActions.setAutoArchive, (state, { enabled }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        autoArchive: {
          ...state.settings.autoArchive,
          enabled,
        },
      },
    };
  }),
  on(AlertEventsActions.setAutoArchiveTtl, (state, { ttlSeconds }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        autoArchive: {
          ...state.settings.autoArchive,
          ttlSeconds,
        },
      },
    };
  }),
  on(AlertEventsActions.setAlertEvents, (state, { alertEvents }) => {
    const existingEvents = state.alertEvents ?? [];
    const newEventsArray = existingEvents.concat(alertEvents);
    return {
      ...state,
      isLastPage: alertEvents?.length < state.perPage,
      page: state.page + 1,
      isInitialLoaded: true,
      alertEvents: newEventsArray,
    };
  }),
  on(AlertEventsActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
  on(AlertEventsActions.setSelectedAlert, (state, { selectedAlertEvent }) => {
    return {
      ...state,
      selectedAlertEvent,
      selectedAlertType: selectedAlertEvent.alertType,
      selectedCamera: selectedAlertEvent.selectedCamera,
      alertName: selectedAlertEvent.name,
      multipleObjects: selectedAlertEvent.configuration.objects,
      offenderObject: selectedAlertEvent.configuration.offender,
      trackedObject: selectedAlertEvent.configuration.object,
      configurationFilterType: getConfigurationFilterType(selectedAlertEvent.configuration.object, selectedAlertEvent.configuration),
      detectionType: selectedAlertEvent.configuration.detection,
      genderType: selectedAlertEvent.configuration.filters?.genderType ?? [],
      ageType: selectedAlertEvent.configuration.filters?.ageType ?? [],
      footWearType: selectedAlertEvent.configuration.filters?.footwearType ?? [],
      footWearColor: selectedAlertEvent.configuration.filters?.footwearColor ?? [],
      lowerBodyType: selectedAlertEvent.configuration.filters?.lowerbodyType ?? [],
      lowerBodyColor: selectedAlertEvent.configuration.filters?.lowerbodyColor ?? [],
      upperBodyType: selectedAlertEvent.configuration.filters?.upperbodyType ?? [],
      upperBodyColor: selectedAlertEvent.configuration.filters?.upperbodyColor ?? [],
      hairType: selectedAlertEvent.configuration.filters?.hairType ?? [],
      hairColor: selectedAlertEvent.configuration.filters?.hairColor ?? [],
      accessoryType: selectedAlertEvent.configuration.filters?.accessoryType ?? [],
      carryingType: selectedAlertEvent.configuration.filters?.carryingType ?? [],
      notifications: selectedAlertEvent.notifications,
      settings: {
        ...selectedAlertEvent.settings,
        pushAlert: selectedAlertEvent.settings?.pushAlert ?? true,
      },
      //zones all
      zones: selectedAlertEvent.zones,
      definedZones: selectedAlertEvent.definedZones,
      measureCrossZones: selectedAlertEvent.measureCrossZones,
      selections: selectedAlertEvent.selections,
      markedIdx: selectedAlertEvent.markedIdx,
      lineCrossing: selectedAlertEvent.lineCrossing,
      trafficControl: selectedAlertEvent.trafficControl,
      // car attributes
      carType: selectedAlertEvent.configuration.filters?.type ?? [],
      carColor: selectedAlertEvent.configuration.filters?.colors ?? [],
      carMake: selectedAlertEvent.configuration.filters?.make ?? [],
      carModel: selectedAlertEvent.configuration.filters?.model ?? [],
      greenList: selectedAlertEvent.configuration.filters?.greenList ?? '',
      redList: selectedAlertEvent.configuration.filters?.redList ?? '',
      detectionAdditionalAttributes: selectedAlertEvent.configuration.detectionAdditionalAttributes ?? null,
      timezone: selectedAlertEvent.timezone,
      tresholdTime: selectedAlertEvent.configuration.tresholdTime,
      actions: selectedAlertEvent.actions,
    };
  }),
  on(AlertEventsActions.setCarColor, (state, { carColor }) => {
    return {
      ...state,
      carColor,
    };
  }),
  on(AlertEventsActions.setCarMake, (state, { carMake }) => {
    return {
      ...state,
      carMake,
      // carMake: addToArray(state.carMake, carMake),
    };
  }),
  on(AlertEventsActions.setCarModel, (state, { carModel }) => {
    return {
      ...state,
      carModel,
    };
  }),
  on(AlertEventsActions.setCarType, (state, { carType }) => {
    return {
      ...state,
      carType: addToArray(state.carType, carType),
    };
  }),
  on(AlertEventsActions.setLineCrossing, (state, { lineCrossing }) => {
    return {
      ...state,
      lineCrossing,
    };
  }),
  on(AlertEventsActions.setTrafficControl, (state, { trafficControl }) => {
    return {
      ...state,
      trafficControl,
    };
  }),
  on(AlertEventsActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
      page: 0,
      perPage: !!query ? 100 : 10,
    };
  }),
  on(AlertEventsActions.setFlowTypes, (state, { flowTypes }) => {
    return {
      ...state,
      flowTypes,
      page: 0,
      perPage: !!flowTypes ? 100 : 10,
    };
  }),
  on(AlertEventsActions.setCameras, (state, { cameras }) => {
    return {
      ...state,
      cameras,
      page: 0,
      perPage: !!cameras ? 100 : 10,
    };
  }),
  on(AlertEventsActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(AlertEventsActions.clearFilter, (state, { filter }) => {
    return {
      ...state,
      [filter]: [],
    };
  }),
  on(AlertEventsActions.setSettingScheduleDayFrom, (state, { day, from }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        schedule: {
          ...state.settings.schedule,
          [day]: {
            ...state.settings.schedule[day],
            from,
          },
        },
      },
    };
  }),
  on(AlertEventsActions.setSettingScheduleDayTo, (state, { day, to }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        schedule: {
          ...state.settings.schedule,
          [day]: {
            ...state.settings.schedule[day],
            to,
          },
        },
      },
    };
  }),
  on(AlertEventsActions.setEnableScheduleDay, (state, { day, enabled }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        schedule: {
          ...state.settings.schedule,
          [day]: enabled
            ? {
              from: settingScheduleDefaultFrom,
              to: settingScheduleDefaultTo,
            }
            : null,
        },
      },
    };
  }),
  on(AlertEventsActions.setGreenListValue, (state, { greenList }) => {
    return {
      ...state,
      greenList,
    };
  }),
  on(AlertEventsActions.setRedListValue, (state, { redList }) => {
    return {
      ...state,
      redList,
    };
  }),
  on(AlertEventsActions.setUnrecognizedValue, (state, { unrecognized }) => {
    return {
      ...state,
      unrecognized,
    };
  }),
  on(AlertEventsActions.setDetectionDirection, (state, { direction }) => {
    return {
      ...state,
      detectionAdditionalAttributes: {
        ...state.detectionAdditionalAttributes,
        direction,
      },
    };
  }),
  on(AlertEventsActions.setDetectionCount, (state, { count }) => {
    return {
      ...state,
      detectionAdditionalAttributes: {
        ...state.detectionAdditionalAttributes,
        count,
      },
    };
  }),
  on(AlertEventsActions.setDetectionSensitivity, (state, { sensitivity }) => {
    return {
      ...state,
      detectionAdditionalAttributes: {
        ...state.detectionAdditionalAttributes,
        sensitivity,
      },
    };
  }),
  on(AlertEventsActions.setSettingsEnableNotificationSound, (state, { enableNotificationSound }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        enableNotificationSound,
      },
    };
  }),
  on(AlertEventsActions.setSettingsPushAlert, (state, { pushAlert }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        pushAlert,
      },
    };
  }),
  on(AlertEventsActions.setSettingsEnableAlertThumbnail, (state, { alertThumbnail }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        alertThumbnail,
      },
    };
  }),
  on(AlertEventsActions.setSettingsEnableAlertZoomThumbnail, (state, { alertZoomThumbnail }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        alertZoomThumbnail,
      },
    };
  }),
  on(AlertEventsActions.setTreshholdTime, (state, { tresholdTime }) => {
    return {
      ...state,
      tresholdTime,
    };
  }),
  on(AlertEventsActions.setActions, (state, { actions }) => {
    return {
      ...state,
      actions: convertActionArrayToObject(actions),
    };
  }),
  on(AlertEventsActions.setMultiCameras, (state, { multiCameras }) => {
    return {
      ...state,
      multiCameras,
    };
  }),
  on(AlertEventsActions.resetAlertEventsState, (state) => {
    return {
      ...state,
      alertEvents: initialState.alertEvents,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
      perPage: initialState.perPage,
    };
  }),
);

export const addToArray = (array: any[], element: string) => {
  const resultArray = [...array];
  const index = array.indexOf(element);
  if (index === -1) {
    resultArray.push(element);
  } else {
    resultArray.splice(index, 1);
  }
  return resultArray;
};

export const getConfigurationFilterType = (object: AnalyticClasses, configuration: Configuration): ConfigurationFilterType => {
  if (!configuration.filters) {
    return ConfigurationFilterType.noFilters;
  }
  const filtersArray = Object.values(configuration.filters);
  const filtersKeys = Object.keys(configuration.filters);
  switch (object) {
    case AnalyticClasses.person:
      const personFilters = filtersArray.some(item => {
        return item.length;
      });
      return personFilters ? ConfigurationFilterType.specificAttributes : ConfigurationFilterType.noFilters;
    case AnalyticClasses.vehicle:
      if (filtersKeys.indexOf('redList') !== -1 || filtersKeys.indexOf('greenList') !== -1) {
        return ConfigurationFilterType.licencePlate;
      }
      const vehicleAttributes = filtersArray.some(item => {
        return item?.length;
      });

      if (vehicleAttributes) {
        return ConfigurationFilterType.specificAttributes;
      }
      return ConfigurationFilterType.noFilters;
    default:
      return ConfigurationFilterType.noFilters;
  }
};

export const convertActionArrayToObject = (actions: AlertActionForm[]): AlertActionNotifications => {
  const result: AlertActionNotifications = {
    gpioActions: [],
    msgActions: [],
    directActions: [],
  };
  actions.forEach(item => {
    switch (item.type) {
      case ActionType.gpio:
        result.gpioActions.push(item.data as AlertGpioAction);
        break;
      case ActionType.https:
        result.msgActions.push(item.data as AlertMsgAction);
        break;
      case ActionType.direct:
        result.directActions.push(item.data as AlertDirectAction);
        break;
    }
  });

  return result;
};
