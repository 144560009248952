<div class="pagination" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="button" [class.invisible]="!page" (click)="back()">
    <img src="assets/icons/arrow.svg" />
  </span>
  <span class="button right" [class.invisible]="isLatestPage" (click)="forward()">
    <img src="assets/icons/arrow.svg" />
  </span>
</div>
<div #svgContainer></div>
<div class="legend-container">
  <div *ngFor="let legend of legends; let i = index" class="legend">
    <span class="rect" [ngStyle]="{ 'background-color': colors[i] }"></span>
    <span [ngStyle]="{ color: colors[i] }">{{ legend }}</span>
  </div>
</div>
