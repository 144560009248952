import { Injectable } from '@angular/core';
import { HttpService } from '../../../core/http.service';
import { Observable } from 'rxjs';
import { ConfigModels } from '@models/config.model';
import { api } from '@consts/url.const';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpService: HttpService) {
  }

  getGeneralSettings(): Observable<ConfigModels.ConfigDocument> {
    const url = api.general.getConfig;
    return this.httpService.get<ConfigModels.ConfigDocument>(url);
  }

  preloadImages(urls: string[]): void {
    for(let url of urls) {
      let img = new Image();
      img.src = url;
    }
  }
}
