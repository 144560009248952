<div fxLayout="row">
  <span
    *ngFor="let day of formattedWeekDays"
    class="day"
    [class.view-mode]="viewMode"
    [class.selected]="isDaySelected(day)"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="viewMode ? null : selectWeekDay(day)"
    >{{ weekDayMap[day] }}</span
  >
</div>
