import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface MainContentPageHeader {
  title: string;
  icon?: string;
  isBack?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MainContentService {
  private onHeader = new BehaviorSubject<MainContentPageHeader>({
    title: '',
    isBack: false,
  });
  public header$ = this.onHeader.asObservable();

  constructor() {}

  setHeader(data: MainContentPageHeader) {
    this.onHeader.next(data);
  }
}
