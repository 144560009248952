import { createFeatureSelector, createSelector } from '@ngrx/store';
import MenuState from '@states/menu/menu.reducer';
import { checkPermission } from '../../../helpers/common.helpers';
import { PermissionModel } from '@models/permission.model';
import { selectPermissionsState } from '@states/permissions/permissions.selectors';
import { PermissionsState } from '@states/permissions/permissions.reducer';
import { selectUserState } from '@states/user/user.selectors';
import { UserState } from '@states/user/user.reducer';

export const selectMenuState = createFeatureSelector<MenuState>('menuState');

export const selectLevel2Menu = createSelector(selectMenuState, ({ level2Menu }: MenuState) => level2Menu);

export const selectLevel3Menu = createSelector(selectMenuState, ({ level3Menu }: MenuState) => level3Menu);

export const selectActiveLevel2 = createSelector(selectMenuState, ({ activeLevel2 }: MenuState) => activeLevel2);

export const selectIsActiveLevel2Visible = createSelector(selectMenuState, ({ isActiveLevel2Visible }: MenuState) => isActiveLevel2Visible);

export const selectActiveLevel3 = createSelector(selectMenuState, ({ activeLevel3 }: MenuState) => activeLevel3);

export const isDeveloper = createSelector(selectUserState, (user: UserState) => {
  return user?.roles?.includes('developer');
});

export const selectPermissions = createSelector(selectPermissionsState, ({ permissions, entityIds }: PermissionsState) => {
  return {
    permissions,
    entityIds,
  };
});

export const selectLevel1Menu = createSelector(selectMenuState, selectPermissions, isDeveloper,
  ({ level1Menu }: MenuState, permissions, isDeveloper) => isDeveloper ? level1Menu : level1Menu
    .filter(item => !item.permissions.length || item.permissions.some(perm => {
        return checkPermission(perm as PermissionModel.Permissions, permissions, []);
      }),
    ));


export const selectActiveLevel1 = createSelector(selectMenuState, ({ activeLevel1 }: MenuState) => activeLevel1);
