import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { UserGroupsActions } from '@states/user-groups/user-groups.action-types';
import { catchError, exhaustMap, of, share, switchMap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { UserGroupsServices } from '../../services/user-groups.services';

@Injectable()
export class UserGroupsEffect {

  public startLoadingGrantedAccessList = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupsActions.beforeGetUserGroups),
      exhaustMap(() => [UserGroupsActions.setIsLoading({ isLoading: true }), UserGroupsActions.getUserGroups()]),
    ),
  );

  public getUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupsActions.getUserGroups),
      withLatestFrom(this.store$.pipe(select(state => state.userGroupState))),
      switchMap(([, { filters, page, perPage, isLastPage }]) => {
        if (!isLastPage) {
          return this.userGroupsService
            .findAll(
              filters,
              { page, perPage },
            )
            .pipe(
              switchMap(res => {
                return [
                  UserGroupsActions.getUserGroupsSuccess({ userGroups: res.items }),
                  UserGroupsActions.setIsLoading({ isLoading: false }),
                ];
              }),
              catchError(response => {
                return [
                  UserGroupsActions.getUserGroupsFail(),
                  UserGroupsActions.setIsLoading({ isLoading: false }),
                  UserGroupsActions.setIsInitialLoaded({ isInitialLoaded: true }),
                ];
              }),
            );
        } else {
          return of(UserGroupsActions.setIsLoading({ isLoading: false }));
        }
      }),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userGroupsService: UserGroupsServices,
  ) {
  }

}
