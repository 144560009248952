import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { alertDayTime, nameDayOfWeek, settingScheduleAllDayFrom, settingScheduleAllDayTo } from '@consts/alert-events.const';
import { DayOfWeek } from '@enums/shared.enum';

@Component({
  selector: 'ui-settings-day-schedule',
  templateUrl: './ui-settings-day-schedule.component.html',
  styleUrls: ['./ui-settings-day-schedule.component.scss'],
})
export class UiSettingsDayScheduleComponent implements OnInit {
  @Input() toggled: boolean;
  @Input() title: DayOfWeek;

  @Input() allDay: boolean;

  @Input() from: string;
  @Input() to: string;
  @Input() disabled: boolean;

  @Output() allDayChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fromChanged: EventEmitter<{ day: string; from: string }> = new EventEmitter<{ day: string; from: string }>();
  @Output() toChanged: EventEmitter<{ day: string; to: string }> = new EventEmitter<{ day: string; to: string }>();
  @Output() dayDisabled: EventEmitter<{
    day: string;
    enabled: boolean;
  }> = new EventEmitter<{
    day: string;
    enabled: boolean;
  }>();

  public alertDayTime = alertDayTime;
  public nameDayOfWeek = nameDayOfWeek;

  constructor() {
  }

  ngOnInit(): void {
  }

  public allDayChecked(value) {
    this.allDayChanged.emit(value);
    if (value) {
      this.from = settingScheduleAllDayFrom;
      this.to = settingScheduleAllDayTo;
      this.fromChanged.emit({
        day: this.nameDayOfWeek[this.title],
        from: this.from,
      });

      this.toChanged.emit({ day: this.nameDayOfWeek[this.title], to: this.to });
    } else {
      this.to = '11:30 PM';
      this.toChanged.emit({ day: this.nameDayOfWeek[this.title], to: this.to });
    }
  }

  public fromSelectionChange(value: MatSelectChange) {
    this.fromChanged.emit({
      day: this.nameDayOfWeek[this.title],
      from: value.value,
    });
  }

  public toSelectionChange(value: MatSelectChange) {
    this.toChanged.emit({
      day: this.nameDayOfWeek[this.title],
      to: value.value,
    });
  }

  public disableDay(enabled: boolean) {
    this.dayDisabled.emit({
      day: this.nameDayOfWeek[this.title],
      enabled,
    });
  }
}
