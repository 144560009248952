<ng-container *ngrxLet="selectActiveOrganization$; let activeOrganization">
  <div fxLayout="column" class="w-100">
    <div class="w-100 narrower-input mb-5">
      <!--      <mat-form-field appearance="outline" class="w-100 search-field icon-18 p-r">-->
      <!--        <mat-icon svgIcon="search" style="position: absolute; top: 3px; left: -25px;"></mat-icon>-->
      <!--        <span matPrefix>-->
      <!--              <mat-icon></mat-icon>-->
      <!--            </span>-->
      <!--        <input matInput-->
      <!--               [(ngModel)]="searchString"-->
      <!--               (input)="onSearchQueryInput($event)"-->
      <!--               placeholder="Search people from organization"-->
      <!--        />-->
      <!--      </mat-form-field>-->
      <mat-form-field appearance="outline" class="w-100 search-field icon-18 p-r">
        <mat-icon svgIcon="search" style="position: absolute; top: 10px; left: -24px;"></mat-icon>
        <span matPrefix>
                    <mat-icon></mat-icon>
                  </span>
        <div class="p-r">
        <input
               type="text"
               placeholder="Notify people from the organization"
               aria-label="Notify people from the organization"
               matInput
               [(ngModel)]="searchString"
               [matAutocomplete]="auto"
               (input)="onSearchQueryInput($event)"
        />
        </div>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText">
          <mat-option *ngFor="let orgUser of filteredUsers" [value]="" (click)="addUser(orgUser)">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <ui-avatar class="user-avatar ml-15"
                         [name]="orgUser.email"
                         [color]="activeOrganization.orgColor"
                         [fontSize]="10"
              ></ui-avatar>
              <div class="person-name">{{ orgUser.firstname ? orgUser.firstname + " " + orgUser.lastname : orgUser.email }}</div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <!--    <div>-->
    <!--      {{selectOrganizationUsers$ | async | json}}-->
    <!--    </div>-->
  </div>
</ng-container>


