import { createAction, props } from '@ngrx/store';
import { ThumbnailModel } from '@models/thumbnail.model';

export const resetThumbnailsCache = createAction('[THUMBNAILS] Reset Thumbnails Cache');

export const setThumbnailsCache = createAction(
  '[THUMBNAILS] Set Thumbnails Cache',
  props<{ thumbnails: ThumbnailModel.ThumbnailDocument[] }>()
);

export const setBitsCache = createAction(
  '[THUMBNAILS] Set Bits Cache',
  props<{ edgeId: string, cameraId: string, cacheId: string; bits: number[] }>()
);
