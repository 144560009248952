import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UIInputStyle } from '@enums/shared.enum';

@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
})
export class UiInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() validationErrors: ValidationErrors;
  @Input() validationEnabled = true;
  @Input() style: UIInputStyle = UIInputStyle.labelInside;
  @Input() disabled: boolean;
  @Input() suffix: string;
  @Input() whiteBg = false;
  @Input() narrow = false;

  public inputStyle = UIInputStyle;
  public value: string;
  public validationKeys: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

  public ngOnChanges() {
    this.validationKeys = [];
    if (this.validationErrors) {
      this.validationKeys = Object.keys(this.validationErrors);
    }
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public updateValue(insideValue: string) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };
}
