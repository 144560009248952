import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, share, switchMap, timeout } from 'rxjs';
import { YoutubeActions } from '@states/youtube/youtube.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { YoutubeService } from '../../services/youtube.service';
import { getYoutube } from '@states/youtube/youtube.actions';

@Injectable()
export class YoutubeEffects {

  public startCreateYoutube$ = createEffect(() =>
    this.actions$.pipe(
      ofType(YoutubeActions.startCreateYoutube),
      switchMap(({ isSave, form }) =>
        [
          YoutubeActions.setIsSaving({ isSaving: true }),
          !isSave ? YoutubeActions.serverRequestCreateYoutube({ form }) : null,
        ]),
    ),
  );

  public serverRequestCreateYoutube$ = createEffect(() =>
    this.actions$.pipe(
      ofType(YoutubeActions.serverRequestCreateYoutube),
      switchMap(({ form }) => {
          return this.yotubeService.create(form)
            .pipe(
              timeout(10000),
              switchMap((res) => {
                return [
                  YoutubeActions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ success: 'Youtube url has been added' }),
                  YoutubeActions.serverRequestCreateYoutubeSuccess({ document: res }),
                ];
              }),
              catchError(() => {
                return [
                  YoutubeActions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ error: 'Adding youtube failed' }),
                ];
              }),
            );
        },
      ),
      share(),
    ));


  public getYoutube$ = createEffect(() =>
    this.actions$.pipe(
      ofType(YoutubeActions.getYoutube),
      switchMap(() => {
          return this.yotubeService.getAllByFilters()
            .pipe(
              timeout(10000),
              switchMap((res) => {
                return [
                  YoutubeActions.getYoutubeSuccess({ documents: res }),
                ];
              }),
              catchError(() => {
                return [
                  YoutubeActions.getYoutubeFail(),
                ];
              }),
            );
        },
      ),
      share(),
    ));


  public deleteYoutubeById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(YoutubeActions.deleteYoutubeById),
      switchMap(({ id }) => {
        return this.yotubeService.delete(id)
            .pipe(
              timeout(10000),
              switchMap((res) => {
                return [
                  YoutubeActions.deleteYoutubeByIdSuccess({ id }),
                ];
              }),
              catchError(() => {
                return [
                  YoutubeActions.deleteYoutubeByIdFail({ id }),
                ];
              }),
            );
        },
      ),
      share(),
    ));


  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private yotubeService: YoutubeService) {
  }
}
