<div #wrapper fxLayout="column" fxFlex="" class="p-wrapper p-r" [class.standalone]="standalone">
  <div fxLayout="row"
       fxLayoutAlign="center center"
       style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 999"
       *ngIf="standalone && !started"
  >
    <div class="play-button" (click)="play()">
      <ng-container *ngIf="!loader">
        <mat-icon>play_circle</mat-icon>
      </ng-container>
      <ng-container *ngIf="loader">
        <mat-spinner diameter="45" color="primary"></mat-spinner>
      </ng-container>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="webrtc-state"
    style="position: absolute; bottom: 10px; left: 10px; z-index: 999">
    <ui-quality-selector #qualitySelector *ngIf="enableQualitySelection && (isConnected || qualityChange)"
      [scale]="!state.hq" (qualityChanged)="changeQuality($event)"></ui-quality-selector>
    <div *ngIf="false" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ui-toggle [(ngModel)]="recover"></ui-toggle>
      <div style="font-weight: 500; color: #fff">Auto recovery</div>
    </div>
  </div>
  <div class="webrtc-state" style="position: absolute; top: 5px; right: 5px; display: none;">
    {{this.state?.pc?.connectionState}}
  </div>
  <div class="webrtc-local" style="position: absolute; bottom: 5px; right: 5px; display: none;">
    {{disableInactivity ? 'LOCAL' : 'RELAY'}}
  </div>
  <div *ngIf="!isCoverMode" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="stats-container">
    <div>{{stats.bitrate | number : '1.0-0' }} Kbit/s</div>
    <div class="stats hover" *ngIf="developer" matTooltip="Show statistics">
      <mat-icon (click)="openStats()">equalizer</mat-icon>
    </div>
  </div>
  <div *ngIf="loader" fxLayoutAlign="center center" style="position: absolute; z-index: 999" class="w-100 h-100">
    <mat-spinner diameter="45" color="primary"></mat-spinner>
  </div>
  <div *ngIf="allowZoom && zoomState.scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label hover"
    (click)="resetZoom()">
    <div class="reset">Reset</div>
    <div class="zoom">{{ zoomState.scale * 100 | number : '1.0-0' }}%</div>
  </div>
  <div *ngIf="allowZoom && showZoomButtons" fxLayout="column" fxLayoutGap="5px" class="manual-zoom-wrapper c-p hover">
    <div fxLayoutAlign="center center" (click)="zoomIn()">
      <mat-icon>add</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="resetZoom()">
      <mat-icon>refresh</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="zoomOut()">
      <mat-icon>remove</mat-icon>
    </div>
  </div>
  <div #preview class="preview" [class.display-none]="!inZoom()" cdkDragBoundary=".p-wrapper" cdkDrag
    (cdkDragStarted)="preview.classList.add('move')" (cdkDragEnded)="preview.classList.remove('move')"
    [ngClass]="{ invisible: zoomState.scale === 1 }">
    <canvas #previewCanvas id="c1" width="208" height="117"></canvas>
  </div>
  <!--  [style.height.px]="(vidWrapper.clientWidth * 9) / 16 + 1"-->

  <div #vidWrapper class="video-wrapper" (mousewheel)="zoom($event)" (mousedown)="dragStart($event)"
    (mouseup)="zoomState.dragging = false" (mousemove)="drag($event)" [ngClass]="{ grab: zoomState.dragging }">
    <video [class.isCoverMode]="isCoverMode" [class.isDefault]="!isCoverMode" #player controls crossorigin playsinline
      muted="muted" id="player" allow="autoplay"></video>
  </div>
</div>
