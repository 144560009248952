export enum WidgetType {
  COUNTER = '[WidgetType] Counter',
  HEATMAP = '[WidgetType] Heatmap',
  GRAPH = '[WidgetType] Graph',
}

export enum WidgetViewMode {
  // Do not change without notice - these are used to fetch file names
  SMALL,
  MEDIUM,
  LARGE,
}
