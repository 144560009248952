import { createReducer, on } from '@ngrx/store';
import { NotificationActions } from '@states/notification/notification.action-types';
import { Notification, UserNotification, UserNotificationState } from '@models/notification.model';

export declare interface NotificationState {
  userNotifications: UserNotification[];
  filteredUserNotifications: UserNotification[];
  notifications: { [key: string]: Notification };
  unreadOnly: boolean;
}

const initialState: NotificationState = {
  userNotifications: null,
  filteredUserNotifications: null,
  notifications: {},
  unreadOnly: false,
};

export const notificationStateReducer = createReducer(
  initialState,
  on(NotificationActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(NotificationActions.getUserNotificationsSuccess, (state, { userNotifications }) => {
    return {
      ...state,
      userNotifications,
      filteredUserNotifications: userNotifications,
    };
  }),
  on(NotificationActions.getNotificationByIdSuccess, (state, { notification }) => {
    if (notification?.id) {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notification.id]: notification,
        },
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(NotificationActions.getUserNotificationsSuccess, (state, { userNotifications }) => {
    return {
      ...state,
      userNotifications,
    };
  }),
  on(NotificationActions.filterByRead, (state, { read }) => {
    return {
      ...state,
      filteredUserNotifications: state.userNotifications.filter(item => read ? item.state === UserNotificationState.UNSEEN : true),
    };
  }),
);
