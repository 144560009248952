import { PulsationModels } from '@models/pulsation.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[SUBSTREAM-HEARTBEAT-PULSATION] Reset to Initial state');

export const GetSubstreamPulsationStatus = createAction('[SUBSTREAM-HEARTBEAT-PULSATION] GetSubstreamPulsationStatus');

export const GetSubstreamPulsationStatusSuccess = createAction('[SUBSTREAM-HEARTBEAT-PULSATION] GetSubstreamPulsationStatusSuccess',
    props<{
        payload: PulsationModels.Store.SubstreamPulsation[]
    }>());

export const GetSubstreamPulsationStatusFail = createAction('[SUBSTREAM-HEARTBEAT-PULSATION] GetSubstreamPulsationStatusFail', props<{ message?: string }>());

