import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UiBreadCrumbItem } from '../shared/ui-kit/ui-header/ui-header.component';

@Injectable()
export class OrganizationSettingsService {
  private breadCrumbs$: BehaviorSubject<UiBreadCrumbItem[]> = new BehaviorSubject<UiBreadCrumbItem[]>([]);

  public setBreadCrumbs(breadCrumbs: UiBreadCrumbItem[]) {
    this.breadCrumbs$.next(breadCrumbs);
  }

  public getBreadCrumbs(): Observable<UiBreadCrumbItem[]> {
    return this.breadCrumbs$.asObservable();
  }
}
