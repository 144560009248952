import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { UserDeviceModel } from '@models/user-device.model';

@Injectable()
export class UserDeviceService {

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<UserDeviceModel.Document[]> {
    return this.http.get<UserDeviceModel.Document[]>(api.userDevices.one);
  }

  public update(deviceUid: string, toUpdate: Partial<UserDeviceModel.Document>): Observable<boolean> {
    return this.http.put<boolean>(api.userDevices.update(deviceUid), toUpdate);
  }
}
