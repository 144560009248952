import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppleTvModels } from '@models/apple-tv.models';
import { AppleTvActions } from '@states/apple-tv/apple-tv.action-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { WallV2Model } from '@models/wall-v2.model';

export interface AppleTvState extends EntityState<AppleTvModels.AppleTvWithWallDocument> {
  isInitialLoaded: boolean;
  filters: {
    query: string
  };
  isSaving: boolean;
  selectedAppleTvDocument: AppleTvModels.AppleTvWithWallDocument;
  walls: Dictionary<WallV2Model.WallMongoDocument>;
  isFormValid: boolean;
  deletingEdgeIds: string[];
  configuringEdgeIds: string[];
}

export const adapter: EntityAdapter<AppleTvModels.AppleTvWithWallDocument> = createEntityAdapter<AppleTvModels.AppleTvWithWallDocument>({
  selectId: (document: AppleTvModels.AppleTvWithWallDocument) => document._id,
});


export const initialState: AppleTvState = adapter.getInitialState({
  isInitialLoaded: false,
  filters: {
    query: null,
  },
  isSaving: false,
  selectedAppleTvDocument: null,
  walls: null,
  isFormValid: false,
  deletingEdgeIds: [],
  configuringEdgeIds: [],
});

export const appleTvStateReducer = createReducer(
  initialState,
  on(AppleTvActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AppleTvActions.getAppleTvSuccess, (state, { documents }) => {
    return adapter.setMany(documents, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(AppleTvActions.setQueryStringFilter, (state, { prop, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [prop]: value,
      },
    };
  }),
  on(AppleTvActions.resetEntities, (state) => {
    return {
      ...state,
      entities: initialState.entities,
    };
  }),
  on(AppleTvActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving: isSaving,
    };
  }),
  on(AppleTvActions.saveAppleTvSetOne, (state, { document }) => {
    return adapter.addOne(document, {
      ...state,
    });
  }),
  on(AppleTvActions.deleteDeviceSuccess, (state, { edgeId }) => {
    const deletingIndex = state.deletingEdgeIds.findIndex(id => id === edgeId);
    let newDeletingArray = [...state.deletingEdgeIds];
    newDeletingArray.splice(deletingIndex, 1);
    return adapter.removeOne(edgeId, {
      ...state,
      deletingEdgeIds: newDeletingArray,
    });
  }),
  on(AppleTvActions.getOneAppleTvSuccess, (state, { document }) => {
    return {
      ...state,
      selectedAppleTvDocument: document,
    };
  }),
  on(AppleTvActions.getWallsSuccess, (state, { walls }) => {
    const wallMap = {};
    walls.forEach(wall => {
      wallMap[wall._id] = wall;
    });
    return {
      ...state,
      walls: wallMap,
    };
  }),
  on(AppleTvActions.updateAppleTvSetOne, (state, { response }) => {
    const configureArray = [...state.configuringEdgeIds];
    const index = configureArray.findIndex(id => id === response.edgeId);
    configureArray.splice(index, 1);
    return adapter.updateOne({
      id: response.edgeId,
      changes: {
        wallId: response.wallId,
        wallName: response.wallName,
      },
    }, {
      ...state,
      configuringEdgeIds: configureArray,
    });
  }),
  on(AppleTvActions.changeFormValidation, (state, { isFormValid }) => {
    return {
      ...state,
      isFormValid,
    };
  }),
  on(AppleTvActions.startDeleteDevice, (state, { edgeId }) => {
    return {
      ...state,
      deletingEdgeIds: [...state.deletingEdgeIds, edgeId],
    };
  }),
  on(AppleTvActions.startConfigureAppleTv, (state, { document }) => {
    return {
      ...state,
      configuringEdgeIds: [...state.configuringEdgeIds, document.edgeId],
    };
  }),
  on(AppleTvActions.updateAppleTvWall, (state, { edgeId, wall, isEqual }) => {
    return adapter.updateOne({
      id: edgeId,
      changes: {
        isEqual,
        deviceWall: wall,
      },
    }, {
      ...state,
    });
  }),
  on(AppleTvActions.rmEdgeIdFromConfigureEdges, (state, { edgeId }) => {
    const configureArray = [...state.configuringEdgeIds];
    const index = configureArray.findIndex(id => id === edgeId);
    configureArray.splice(index, 1);
    return {
      ...state,
      configuringEdgeIds: configureArray,
    };
  }),
);
