import { createAction, props } from '@ngrx/store';
import { MenuItem } from '@models/menu.model';
import { MenuKey } from '@enums/menu.enum';

export const resetToInitialState = createAction('[MENU] Reset to Initial state');

export const setLevel2Menu = createAction('[MENU] Set Level2 Menu', props<{ level2Menu: MenuItem[] }>());

export const setActiveLevel2Menu = createAction('[MENU] Set Active Level2 Menu Point', props<{ activeLevel2: MenuKey }>());

export const setIsActiveLevel2Visible = createAction('[MENU] Set Is Active Level2 Visible', props<{ isActiveLevel2Visible: boolean }>());

export const setLevel3Menu = createAction('[MENU] Set Level3 Menu', props<{ level3Menu: MenuItem[] }>());
export const setLevel3MenuByKey = createAction('[MENU] Set Level3 Menu Key', props<{ key: MenuKey; level3Menu: MenuItem[] }>());

export const setActiveLevel3Menu = createAction('[MENU] Set Active Level3 Menu Point', props<{ activeLevel3: MenuKey }>());

export const disableLevel2Point = createAction('[MENU] Disable Level2 Point', props<{ key: MenuKey }>());

export const renameLevel2Point = createAction('[MENU] Rename Level2 Point', props<{ key: MenuKey; name: string }>());

export const disableLevel3Point = createAction('[MENU] Disable Level3 Point', props<{ level2Key: MenuKey; key: MenuKey }>());

export const renameLevel3Point = createAction('[MENU] Rename Level3 Point', props<{ level2Key: MenuKey; key: MenuKey; name: string }>());

export const enableLevel2Point = createAction('[MENU] Enable Level2 Point', props<{ key: MenuKey }>());

export const enableLevel3Point = createAction('[MENU] Enable Level3 Point', props<{ level2Key: MenuKey; key: MenuKey }>());
