import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { IdentityModel } from '@models/identity.model';

@Injectable({
  providedIn: 'root',
})

export class IdentityService {
  constructor(private http: HttpClient) {
  }

  public getUserIdentities(email: string): Observable<IdentityModel.IdentityDocument[]> {
    return this.http.get<IdentityModel.IdentityDocument[]>(api.identity.identityByEmail(email));
  }


  public changeUserPassword(password: string, newPassword: string): Observable<IdentityModel.IdentityDocument[]> {
    return this.http.post<IdentityModel.IdentityDocument[]>(api.identity.changePassword, { password, newPassword });
  }
}
