import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ThumbnailHistogramColorRGBA } from '@consts/thumbnail.const';

const MAX_EVENTS = 15;

@Component({
  selector: 'app-storage-histogram-old',
  templateUrl: './storage-histogram-old.component.html',
  styleUrls: ['./storage-histogram-old.component.scss'],
})
export class StorageHistogramOldComponent implements OnInit, AfterViewInit, OnChanges {

  public ThumbnailHistogramColorRGBA: typeof ThumbnailHistogramColorRGBA = ThumbnailHistogramColorRGBA;
  /**
   * Canvas related variables
   */
  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('wrapper')
  wrapper: ElementRef;

  private ctx: CanvasRenderingContext2D;

  @Input()
  color: number;

  @Input()
  events: number[] = [];

  @Input()
  searchEvents: number[][];

  @Input()
  rtl = false;

  @Input()
  startTs;

  @Input()
  endTs;

  @Input() vertical = true;

  constructor() {
  }

  calcSearchEventIdx(searchEvent: number[]): { startIdx: number; endIdx: number } {
    // const avg = Math.abs((searchEvent[0] + searchEvent[1]) / 2);
    let startIdx = Math.ceil((searchEvent[0] - this.startTs) / 2000);
    let endIdx = Math.ceil((searchEvent[1] - this.startTs) / 2000);
    if (startIdx === endIdx) {
      endIdx += 1;
    }
    return {startIdx, endIdx}; // assuming 2000ms is the duration between thumbnails
  }

  drawSearchEvents() {
    const canvas = this.canvas.nativeElement;
    const length = (this.endTs - this.startTs) / 2000;
    const drawArray = new Array(length).fill(0);
    for (let searchEvent of this.searchEvents) {
      let idx = this.calcSearchEventIdx(searchEvent);
      drawArray.fill(1, idx.startIdx, idx.endIdx);
    }
    for (let [idx, d] of drawArray.entries()) {
      if (!!d) {
        this.ctx.fillStyle = '#12b76a';
        this.ctx.beginPath();
        const x = (idx / length) * canvas.width;
        const width = canvas.width / length;
        this.ctx.fillRect(this.rtl ? canvas.width - x - width : x, canvas.height / 6, width + 0.5, canvas.height / 1.5);
      }
    }
  }

  drawEvents() {
    const canvas = this.canvas.nativeElement;
    this.ctx = this.canvas.nativeElement.getContext('2d')!;
    const length = this.events?.length;

    if (!this.events || length === 0) {
      this.ctx.fillStyle = '#000000';
      this.ctx.fillRect(0, 0, canvas.width, canvas.height);
      return;
    }

    let sumEvents = 0;
    // Calculate total length
    for (let eventIdx = 0; eventIdx < this.events.length; eventIdx++) {
      const event = this.events[eventIdx];
      sumEvents += event ? event : 1;
    }

    const w = canvas.width / sumEvents;

    let accumulateEvents = 0;
    for (let eventIdx = 0; eventIdx < this.events.length; eventIdx++) {
      const event = this.events[eventIdx];
      accumulateEvents += event ? event : 1;
      // const x = w * accumulateEvents;
      let opacity = 0;
      let half = 0;

      switch (event) {
        case 0:
          this.ctx.fillStyle = '#000000';
          break;
        case 1:
          this.ctx.fillStyle = '#E1E6FA';
          break;
        case 2:
          this.ctx.fillStyle = '#748CED';
          break;
      }
      const x = (eventIdx / length) * canvas.width;
      const width = canvas.width / length;
      const calcX = this.rtl ? canvas.width - x - width : x;
      // this.ctx.fillRect(this.rtl ? canvas.width - x - width : x + width, canvas.height / 6, width + 0.5, canvas.height)
      // this.ctx.fillRect(calcX, 0, this.events.length < 1000 ? w * (event ? event : 1) : w * (event ? event : 1) + half * 2, canvas.height);
      this.ctx.fillRect(calcX, 0, w, canvas.height);

    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.drawEvents();
    // if (this.searchEvents) {
    //   this.drawSearchEvents();
    // }
  }

  clear() {
    const canvas = this.canvas.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const canvas = this.canvas.nativeElement;
    if (!canvas) {
      return;
    }
    this.ctx = this.canvas.nativeElement.getContext('2d')!;
    this.clear();
    this.drawEvents();
    if (this.searchEvents) {
      this.drawSearchEvents();
    }
  }
}
