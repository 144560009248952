import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { LocalStorageService } from '../core/local-storage.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth0 } from './auth0.model';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { UserService } from '../user/user.service';
import { AppState } from '../store/app.state';
import { Observable, of } from 'rxjs';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { BYPASS_AUTHENTICAION_TOKEN } from '../core/authentication-interceptor.service';
import { calculateExpiryDate, parseJwt } from '../helpers/common.helpers';
import { EXPIRES_IN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Service {

  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain,
    responseType: environment.auth0.responseType,
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.redirectUri,
    scope: environment.auth0.scope,
  });

  public user$ = this.userService.user$;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
  }

  isLoggedIn(): boolean {
    const res = !!this.getIdTokenFromLocalStorage() && moment()
      .isBefore(this.getExpiration());
    return res;
  }

  logout(msg: string = null, code: string = null) {
    let queryParams = '';
    if (msg) {
      queryParams = `?msg=${encodeURIComponent(msg)}`;
    }
    const returnToUrl = `${environment.auth0.redirectUri}${queryParams}`; // replace with your return URL
    window.location.href = `https://${environment.auth0.domain}/v2/logout?client_id=${environment.auth0.clientID}&returnTo=${encodeURIComponent(returnToUrl)}`;
  }

  public switchTenant(orgId: string): Observable<Auth0.AuthenticationHTTPResponse> {
    return this.renewToken(orgId);
  }

  public renewToken(orgId?: string): Observable<Auth0.AuthenticationHTTPResponse> {
    const refreshToken = this.localStorageService.getItemWithCheck(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      this.store.dispatch(AuthenticationActions.Logout({ reason: 'No refresh token available' }));
      return of(null);
    }

    const url = `https://${environment.auth0.domain}/oauth/token`;

    const parsedToken = parseJwt(this.localStorageService.getItemWithCheck(ID_TOKEN_KEY));
    const subUser = parsedToken.subUser;
    let orgIdParam;
    if (orgId) {
      orgIdParam = orgId;
    } else {
      orgIdParam = parsedToken.orgId;
    }

    const params = new HttpParams()
      .set('client_id', environment.auth0.clientID)
      .set('grant_type', 'refresh_token')
      .set('scope', 'openid profile read:current_user')
      .set('audience', environment.auth0.audience)
      .set('subUser', subUser)
      .set('org_id', orgIdParam)
      .set('refresh_token', refreshToken);


    return this.http.post<Auth0.AuthenticationHTTPResponse>(url, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
    });
  }


  public socialLoginGetTokenByCode(code: string): Observable<any> {

    const url = `https://${environment.auth0.domain}/oauth/token`;

    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', environment.auth0.clientID)
      .set('redirect_uri', environment.auth0.redirectUri)
      .set('code', code);

    return this.http.post<any>(url, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
    });

  }

  socialLogin(provider: string): Observable<void> {
    return new Observable((observer) => {
      this.auth0.authorize(
        {
          responseType: 'code',
          connection: provider, // 'google-oauth2', 'apple'
          scope: 'openid profile email offline_access read:current_user',
        },
        (err, authResult) => {
          if (err) {
            observer.error(err);
          } else if (authResult && authResult.accessToken && authResult.idToken) {
            observer.next();
            observer.complete();
          }
        },
      );
    });
  }

  getExpirationFromLocalStorage(): string | null {
    return this.localStorageService.getItemWithCheck(EXPIRES_IN_KEY);
  }

  getIdTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItemWithCheck(ID_TOKEN_KEY);
  }


  getExpiration() {
    const expiration = this.getExpirationFromLocalStorage();
    if (!expiration) {
      // TODO: should force expiration
      return 0;
    } else {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
  }

  /**
   * Call after login without refresh the page
   * @param authResult
   */
  public afterTokenReceivedSuccess(authResult: Auth0.AuthenticationResponse) {
    const idTokenPayload = parseJwt(authResult.idToken);
    const expiresAt = idTokenPayload.exp - idTokenPayload.iat;
    const authData: Partial<Auth0.AuthenticationResponse> = {
      ...authResult,
      expiresAt: calculateExpiryDate(expiresAt),
      authProviderId: idTokenPayload.sub,
      autoLogout: idTokenPayload.autoLogout,
    };
    this.store.dispatch(
      AuthenticationActions.Login({
        auth: authData,
      }),
    );
  }

}
