<div
  style="overflow-y: auto"
  fxFlexFill
  class="search-properties-selector"
  *ngrxLet="selectCameraSearchSettingsFilters$; let cameraSearchSettingsFilters"
>
  <ng-container *ngIf="!!selection" [ngSwitch]="selection.type">
    <div fxLayout="column"
         fxLayoutAlign="end center"
         *ngSwitchCase="'pet'"
         class="w-100 mb-15 person p-30"
         fxLayoutGap="10px"
    >
      No additional attributes available for pets at the moment
    </div>

    <div fxLayout="column"
         fxLayoutAlign="end center"
         *ngSwitchCase="'person'"
         class="w-100 mb-15 person"
         fxLayoutGap="10px"
    >
      <div
        *ngFor="let property of selection.properties; let i = index"
        fxLayout="column"
        fxLayoutAlign="space-between start"
        [matTooltip]="
          cameraSearchSettingsFilters[property.name] !== undefined
            ? !cameraSearchSettingsFilters[property.name]
              ? 'This property is disabled for this camera.'
              : null
            : null
        "
        #parent
        class="w-100 property-wrapper"
        [class.opened]="toggle.value"
      >
        <!--        {{ property | json }}-->
        <!--        {{propertyByName(property.value).options | json}}-->
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100" style="padding: 0px 20px">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            [class.disabled]="
              cameraSearchSettingsFilters[property.name] !== undefined ? !cameraSearchSettingsFilters[property.name] : false
            "
          >
            <ui-toggle #toggle [value]="!!property.enabled" (change)="toggleProp($event, i)"></ui-toggle>
            <h4>{{ propertyByName(property.name).label }}</h4>
          </div>
          <app-search-selector
            label=""
            fxFlex="40"
            class="operator operator-selector"
            [value]="selection.properties[0].operator"
            (selection)="i === 0 ? (property.operator = $event) : ''"
            [disabled]="i !== 0"
            [searchSelectorOptions]="SearchSelectorOperators"
          ></app-search-selector>
        </div>
        <div *ngIf="toggle.value" fxLayout="column" class="property-body w-100 p-20">
          <ng-container [ngSwitch]="propertyByName(property.name).type" class="w-100">
            <div fxLayoutAlign="start center" *ngIf="propertyByName(property.name).options" class="w-100">
              <div *ngSwitchCase="'checkbox'">
                <div *ngFor="let option of propertyByName(property.name).options">
                  <mat-checkbox
                    [value]="option.value"
                    color="primary"
                    [checked]="includes(option.value, property.value)"
                    (change)="check($event, property)"
                  >{{ option.name }}
                  </mat-checkbox
                  >
                </div>
              </div>
              <div *ngSwitchCase="'radio'">
                <div *ngFor="let option of propertyByName(property.name).options">
                  <mat-radio-button
                    class="mb-5"
                    [value]="option.value"
                    color="primary"
                    [checked]="includes(option.value, property.value)"
                    (change)="radio($event, property)"
                  >{{ toDisplayStr(option.name) }}
                  </mat-radio-button
                  >
                </div>
              </div>
              <div *ngSwitchCase="'chips'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mb-10">
                <h4>Type</h4>
                <div fxLayout="row wrap" class="advanced-search-chips" *ngSwitchCase="'chips'">
                  <mat-chip-set>
                    <ui-chip-select *ngFor="let option of propertyByName(property.name).options"
                                    [value]="option?.value"
                                    [selected]="chipSelected(option.value, property.value)"
                                    (selectChange)="chip($event, property.value)"
                    >{{ option.name }}
                    </ui-chip-select>
                  </mat-chip-set>
                </div>
              </div>
            </div>
          </ng-container>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <h4 *ngIf="propertyByName(property.name).colors">Color</h4>
            <ui-color-select
              class="ml-5"
              *ngIf="propertyByName(property.name).colors"
              [value]="property.colors"
              (selectionChange)="setColors($event, property)"
            ></ui-color-select>
          </div>
        </div>
      </div>

      <!--      {{selection.properties | json}}-->
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" *ngSwitchCase="'vehicle'" class="w-100 mb-15 traits p-r">
      <!--            <div class="ml-5" fxLayoutGap="10px">-->
      <!--              <mat-label>Advanced</mat-label>-->
      <!--              <mat-slide-toggle-->
      <!--                color="primary"-->
      <!--                [(ngModel)]="selection?.alpr.exists"></mat-slide-toggle>-->
      <!--            </div>-->
      <app-search-selector
        style="position: absolute; top: 10px; right: 10px; min-width: 62px"
        label=""
        fxFlex="40"
        class="operator operator-selector"
        [value]="selection.alpr.operator ?? 'AND'"
        (selection)="selection.alpr.operator = $event"
        [searchSelectorOptions]="SearchSelectorOperators"
      ></app-search-selector>
      <div
        fxLayout="column"
        class="property"
        fxLayoutGap="15px"
        [matTooltip]="
          cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carColor]] !== undefined
            ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carColor]]
              ? 'This property is disabled for this camera.'
              : null
            : null
        "
      >
        <h4 class="ml-5">Color</h4>
        <ui-color-select
          [class.disabled]="
            cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carColor]] !== undefined
              ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carColor]]
              : false
          "
          class="ml-5 mb-10"
          [value]="selection.alpr.colors"
          (selectionChange)="setColors($event, selection.alpr)"
        ></ui-color-select>
      </div>
      <ng-container [formGroup]="formGroup">
        <div
          fxLayout="column"
          class="property w-50"
          fxLayoutGap="15px"
          [matTooltip]="
            cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carMake]] !== undefined
              ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carMake]]
                ? 'This property is disabled for this camera.'
                : null
              : null
          "
        >
          <ui-selector
            placeholder="Select Make"
            [multi]="true"
            title="Make"
            [optionTemplate]="optionCarMakeTemplate"
            [class.disabled]="
              cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carMake]] !== undefined
                ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carMake]]
                : false
            "
            formControlName="selectedCarMakes"
            [options]="carMadeOptions"
            [searchEnabled]="true"
          >
          </ui-selector>
          <div fxLayout="row wrap" class="advanced-search-chips mb-15">
            <mat-chip-set>
              <ui-chip-select *ngFor="let option of formGroup.get('selectedCarMakes').value"
                              [value]="option"
                              [selected]="true"
                              (selectChange)="unselectCarMade(option)"
              >{{ option }}
              </ui-chip-select>
            </mat-chip-set>
          </div>
        </div>
        <div
          fxLayout="column"
          class="property w-50"
          fxLayoutGap="15px"
          [matTooltip]="
            cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carModel]] !== undefined
              ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carModel]]
                ? 'This property is disabled for this camera.'
                : null
              : null
          "
        >
          <ui-selector
            placeholder="Select Model"
            [multi]="true"
            title="Model"
            [class.disabled]="
              cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carModel]] !== undefined
                ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carModel]]
                : false
            "
            [value]="selectedCarModels"
            (change)="selectedCarModelsChanged($event)"
            [optionTemplate]="optionCarMakeTemplate"
            [options]="carModelsOptions"
            [searchEnabled]="true"
          >
          </ui-selector>
          <div fxLayout="row wrap" class="advanced-search-chips mb-15">
            <mat-chip-set>
              <ui-chip-select *ngFor="let option of selectedCarModels"
                              [value]="option"
                              [selected]="true"
                              (selectChange)="unselectCarModel(option)"
              >{{ option }}
              </ui-chip-select>
            </mat-chip-set>
          </div>
        </div>
      </ng-container>
      <div
        fxLayout="column"
        class="property"
        fxLayoutGap="15px"
        [matTooltip]="
          cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carType]] !== undefined
            ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carType]]
              ? 'This property is disabled for this camera.'
              : null
            : null
        "
      >
        <h4 class="ml-5">Type</h4>
        <div
          fxLayout="row wrap"
          class="advanced-search-chips mb-15"
          [class.disabled]="
            cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carType]] !== undefined
              ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carType]]
              : false
          "
        >
          <mat-chip-set>
            <ui-chip-select *ngFor="let option of SearchSelectorCarTypes"
              [value]="option.value"
              [selected]="chipSelected(option.value, selection.alpr.types)"
              (selectChange)="chip($event, selection.alpr.types)"
            >{{ option.name }}
            </ui-chip-select
            >
          </mat-chip-set>
        </div>
      </div>
      <div
        fxLayout="column"
        class="property"
        style="border-bottom: none"
        fxLayoutGap="15px"
        [matTooltip]="
          cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carAdditionalProperties]] !== undefined
            ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carAdditionalProperties]]
              ? 'This property is disabled for this camera.'
              : null
            : null
        "
      >
        <h4>Additional properties</h4>
        <div
          class="row"
          fxLayoutAlign="start center"
          class="w-100"
          [class.disabled]="
            cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carAdditionalProperties]] !== undefined
              ? !cameraSearchSettingsFilters[searchSettingsMap[searchSettings.carAdditionalProperties]]
              : false
          "
        >
          <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Region</mat-label>
            <input matInput type="text" [(ngModel)]="selection.alpr.region"/>
          </mat-form-field>
        </div>
        <div class="row" fxLayoutAlign="start center" class="w-100">
          <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Plate Number</mat-label>
            <input matInput type="text" [(ngModel)]="selection.alpr.plate"/>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--<div *ngFor="let s of searchSelection" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">-->
<!--  <app-search-selector label="Select Type" (selection)="select($event)"-->
<!--                       [searchSelectorOptions]="SearchSelectorTypes" fxFlex="50"></app-search-selector>-->
<!--  <div fxLayouAlign="end center" fxFlex="50">-->
<!--    <div fxFlex="50"></div>-->
<!--    <app-search-selector label=""-->
<!--                         (selection)="select($event)"-->
<!--                         [searchSelectorOptions]="SearchSelectorOperators"-->
<!--                         fxFlex="50"-->
<!--    ></app-search-selector>-->
<!--  </div>-->
<!--</div>-->

<ng-template #optionCarMakeTemplate let-option>
  <span class="option-item">
    {{ option }}
  </span>
</ng-template>
