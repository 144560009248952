<div fxLayout="column" class="inactivity-modal">
  <div>
    <mat-icon svgIcon="timer"></mat-icon>
  </div>
  <div class="title">
    Are you still here ?
  </div>
  <div class="description">
    You will be signed out in {{data.autoLogoutCounter}}
  </div>
  <button mat-flat-button color="primary" (click)="close()">Yes</button>
</div>
