import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-inactivity',
  templateUrl: './inactivity.component.html',
  styleUrls: ['./inactivity.component.scss'],
})
export class InactivityComponent implements OnInit {
  @Input() countDown = false;
  @Input() inactive = false;
  @Input() playback = false;

  @Output() keepPlaying: EventEmitter<void> = new EventEmitter();
  @Output() pause: EventEmitter<void> = new EventEmitter();
  @Output() resume: EventEmitter<void> = new EventEmitter();

  inactivityCountdown$: Observable<number> = this.store$.select(SharedSelectors.selectInactivityCountdown);

  constructor(private store$: Store) {
  }

  ngOnInit(): void {
  }

  _resume() {
    this.resume.emit(null);
  }
}
