import { DashboardModel } from '@models/dashboard.model';
import { UiCalendarPickerType } from '@enums/shared.enum';
import * as moment from 'moment/moment';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { AlertV2TypeGroup } from './alerts-v2.const';

export const initialDashboard: DashboardModel.Dashboard = {
  layout: [
    // {cols: 3, rows: 5, y: 0, x: 0, id: 11111},
    // {cols: 3, rows: 5, y: 0, x: 3, id: 22222},
  ],
  orgIdHash: 0,
  updatedAt: 0,
  name: '',
  isPrivate: false,

};

export const WidgetTypeStr: { [Property in DashboardModel.WidgetType] } = {
  [DashboardModel.WidgetType.ChartOrTable]: 'Chart or table',
  [DashboardModel.WidgetType.Image]: 'Image',
  [DashboardModel.WidgetType.Heatmap]: 'Heatmap',
  [DashboardModel.WidgetType.Text]: 'Text',
};

export const DataSourceStr: { [Property in DashboardModel.DataSource] } = {
  [DashboardModel.DataSource.Objects]: 'Objects',
  [DashboardModel.DataSource.Alerts]: 'Alerts',
};

export const HeatmapDataTypeStr: { [Property in DashboardModel.HeatmapDataType] } = {
  [DashboardModel.HeatmapDataType.Linear]: 'Linear',
  [DashboardModel.HeatmapDataType.Logarithmic]: 'Logarithmic',
};

export const XAxisObjectOptionsStr: { [Property in DashboardModel.XAxisObjectOptions] } = {
  [DashboardModel.XAxisObjectOptions.Time]: 'Time',
  [DashboardModel.XAxisObjectOptions.Locations]: 'Locations',
  [DashboardModel.XAxisObjectOptions.Cameras]: 'Cameras',
};

export const XAxisAlertOptionsStr: { [Property in DashboardModel.XAxisAlertOptions] } = {
  [DashboardModel.XAxisAlertOptions.Time]: 'Time',
  [DashboardModel.XAxisAlertOptions.Locations]: 'Locations',
  [DashboardModel.XAxisAlertOptions.Cameras]: 'Cameras',
  [DashboardModel.XAxisAlertOptions.AlertTypes]: 'Alert types',
};

export const XAxisTimeTypeStr: { [Property in DashboardModel.XAxisTimeType] } = {
  [DashboardModel.XAxisTimeType.None]: '---',
  [DashboardModel.XAxisTimeType.Hour]: 'Hour',
  [DashboardModel.XAxisTimeType.Day]: 'Day',
  [DashboardModel.XAxisTimeType.Week]: 'Week',
  [DashboardModel.XAxisTimeType.Month]: 'Month',
};

export const YAxisMeasureStr: { [Property in DashboardModel.YAxisMeasure] } = {
  [DashboardModel.YAxisMeasure.Count]: 'Total',
  [DashboardModel.YAxisMeasure.Sum]: 'Total',
  [DashboardModel.YAxisMeasure.Average]: 'Average',
  [DashboardModel.YAxisMeasure.Min]: 'Min',
  [DashboardModel.YAxisMeasure.Max]: 'Max',
};

export const YAxisMeasureAppearanceStr: { [Property in Partial<DashboardModel.YAxisMeasureAppearance>] } = {
  [DashboardModel.YAxisMeasure.Count]: 'Total',
  [DashboardModel.YAxisMeasure.Average]: 'Average',
  [DashboardModel.YAxisMeasure.Max]: 'Max',
};
export const YAxisMeasureAppearanceAlertStr: { [Property in Partial<DashboardModel.YAxisMeasureAppearanceAlert>] } = {
  [DashboardModel.YAxisMeasure.Count]: 'Total',
};

export const YAxisMeasureDwellStr: { [Property in Partial<DashboardModel.YAxisMeasureDwell>] } = {
  [DashboardModel.YAxisMeasure.Sum]: 'Total',
  [DashboardModel.YAxisMeasure.Average]: 'Average',
  // [DashboardModel.YAxisMeasure.Min]: 'Min',
  [DashboardModel.YAxisMeasure.Max]: 'Max',
};

export const YAxisTypeStr: { [Property in DashboardModel.YAxisType] } = {
  [DashboardModel.YAxisType.Appearance]: 'Appearance',
  [DashboardModel.YAxisType.DwellTime]: 'Dwell time',
};

export const YAxisTrackerGroupTypeStr: { [Property in DashboardModel.YAxisGroupType] } = {
  [DashboardModel.YAxisGroupType.All]: 'All objects',
  [DashboardModel.YAxisGroupType.Group]: 'Group',
  [DashboardModel.YAxisGroupType.Individual]: 'Individual',
};

export const YAxisTrackerGroupTypeAlertsStr: { [Property in DashboardModel.YAxisGroupType] } = {
  [DashboardModel.YAxisGroupType.All]: 'All alerts',
  [DashboardModel.YAxisGroupType.Group]: 'Group',
  [DashboardModel.YAxisGroupType.Individual]: 'Individual',
};

export const YAxisTrackerGroupStr: { [Property in DashboardModel.YAxisTrackerGroup] } = {
  [DashboardModel.YAxisTrackerGroup.Person]: 'Person',
  [DashboardModel.YAxisTrackerGroup.Vehicle]: 'Vehicle',
  [DashboardModel.YAxisTrackerGroup.Animal]: 'Animal',
  [DashboardModel.YAxisTrackerGroup.ShoppingCart]: 'Shopping cart',
};


export const YAxisTrackerClassStr: { [Property in DashboardModel.YAxisTrackerClass] } = {
  [DashboardModel.YAxisTrackerClass.UnknownPerson]: 'Unknown person',
  [DashboardModel.YAxisTrackerClass.AdultFemale]: 'Adult female',
  [DashboardModel.YAxisTrackerClass.AdultMale]: 'Adult male',
  [DashboardModel.YAxisTrackerClass.Child]: 'Child',
  [DashboardModel.YAxisTrackerClass.Car]: 'Car',
  [DashboardModel.YAxisTrackerClass.Motorcycle]: 'Motorcycle',
  [DashboardModel.YAxisTrackerClass.Bicycle]: 'Bicycle',
  [DashboardModel.YAxisTrackerClass.Bus]: 'Bus',
  [DashboardModel.YAxisTrackerClass.Truck]: 'Truck',
  [DashboardModel.YAxisTrackerClass.Forklift]: 'Forklift',
  [DashboardModel.YAxisTrackerClass.Boat]: 'Boat',
  [DashboardModel.YAxisTrackerClass.Animal]: 'Animal',
  [DashboardModel.YAxisTrackerClass.ShoppingCart]: 'Shopping cart',
};

export const allYAxisTrackerClass = Object.keys(YAxisTrackerClassStr)
  .map(key => +key);

export const allYAxisTrackerGroups = Object.keys(YAxisTrackerGroupStr)
  .map(key => +key);

export const XAxisDefaultItem: DashboardModel.XAxisGroup = { type: DashboardModel.XAxisOptions.Time, value: DashboardModel.XAxisTimeType.None };
export const XAxisDefault: DashboardModel.XAxisGroup[] = [XAxisDefaultItem];
export const YAxisDefaultItem: DashboardModel.YAxis = {
  type: DashboardModel.YAxisType.Appearance,
  measure: DashboardModel.YAxisMeasure.Count,
  trackerOptions: {
    trackerGroupType: DashboardModel.YAxisGroupType.Group,
    trackerClass: [],
    trackerGroup: [DashboardModel.YAxisTrackerGroup.Person, DashboardModel.YAxisTrackerGroup.Vehicle],
  },
  alertsOptions: {
    alertGroupType: DashboardModel.YAxisGroupType.All,
    alertType: [],
    eventId: [],
  },
};
export const YAxisDefaultItemAlerts: DashboardModel.YAxis = {
  type: DashboardModel.YAxisType.Appearance,
  measure: DashboardModel.YAxisMeasure.Count,
  trackerOptions: {
    trackerGroupType: DashboardModel.YAxisGroupType.All,
    trackerClass: [],
    trackerGroup: [],
  },
  alertsOptions: {
    alertGroupType: DashboardModel.YAxisGroupType.All,
    alertType: [],
    eventId: [],
  },
};
export const YAxisDefault: DashboardModel.YAxis[] = [YAxisDefaultItem];

export const VisualizationTypeOptions: DashboardModel.VisualizationTypeData[] = [
  { id: 0, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Bar, label: 'Vertical bar chart', icon: 'ui-icon-bar-chart' },
  { id: 1, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Bar, label: 'Horizontal bar chart', icon: 'ui-icon-horizontal-bar-chart', horizontal: true },
  { id: 2, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Line, label: 'Line chart', icon: 'ui-icon-line-chart' },
  { id: 3, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Bar, label: 'Vertical stacked bar chart', icon: 'ui-icon-stacked-chart', stacked: true },
  {
    id: 4, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Bar, label: 'Horizontal stacked bar chart', icon: 'ui-icon-horizontal-stached-chart', horizontal: true,
    stacked: true,
  },
  // { id: 5, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Scatter, label: 'Scatter chart', icon: 'ui-icon-scatter-chart' },
  // { id: 6, dataType: DashboardModel.DataType.Chart, chartType: DashboardModel.ChartTypes.Pie, label: 'Pie chart', icon: 'ui-icon-pie-chart' },
  { id: 7, dataType: DashboardModel.DataType.Counter, label: 'Counter', icon: 'ui-icon-counter' },
  { id: 8, dataType: DashboardModel.DataType.Table, label: 'Table', icon: 'ui-icon-table', hide: true },
  // {id: 7, chartType: DashboardModel.ChartTypes.Counter, label: 'Counter', icon: 'ui-icon-counter'},
  // { chartType: ChartTypes.Bubble, label: 'Bubble', icon: '' },
  // { chartType: ChartTypes.Doughnut, label: 'Doughnut chart', icon: '' },
  // { chartType: ChartTypes.PolarArea, label: 'Polar Area', icon: '' },
  // { chartType: ChartTypes.Radar, label: 'Radar', icon: '' },
];

export const WidgetTimeFrameStr: { [Property in DashboardModel.WidgetTimeFrame] } = {
  [DashboardModel.WidgetTimeFrame.None]: '---',
  [DashboardModel.WidgetTimeFrame.Today]: 'Today',
  [DashboardModel.WidgetTimeFrame.Yesterday]: 'Yesterday',
  [DashboardModel.WidgetTimeFrame.ThisWeek]: 'This week',
  [DashboardModel.WidgetTimeFrame.LastWeek]: 'Last week',
  [DashboardModel.WidgetTimeFrame.ThisMonth]: 'This month',
  [DashboardModel.WidgetTimeFrame.LastMonth]: 'Last month',
  [DashboardModel.WidgetTimeFrame.DayByDay]: 'Day by day',
  [DashboardModel.WidgetTimeFrame.WeekByWeek]: 'Week by week',
};

const dateRangeInitial = {
  start: moment()
    .subtract(2, 'months')
    .toString(),
  end: moment()
    .hours(23)
    .minutes(59)
    .toString(),
};

export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.weeks, value: 2 } };

export const initialDashboardFilter: DashboardModel.DashboardFilters = {
  selectedCameras: [],
  trackerOptions: {
    trackerClass: [],
    trackerGroupType: DashboardModel.YAxisGroupType.All,
    trackerGroup: [],
  },
  dateRange: defaultDateRange,
};

export const AlertV2TypeGroupFlat = AlertV2TypeGroup.map(item => item.options)
  .flat();

