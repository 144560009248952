import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum PeriodUnit {
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
}

export interface Period {
  unit: PeriodUnit;
  start: number;
  end: number;
}

@Component({
  selector: 'ui-period',
  templateUrl: './ui-period.component.html',
  styleUrls: ['./ui-period.component.scss'],
})
export class UiPeriodComponent implements OnInit {

  @Input() unit: PeriodUnit = PeriodUnit.seconds;
  public start: number;
  public end: number;

  public min = 0;
  public max = 61;

  @Input() value: Period;

  public PeriodUnit = PeriodUnit;

  public unitOptions = Object.keys(PeriodUnit);
  @Output() rangeChanged: EventEmitter<Period> = new EventEmitter<Period>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.value) {
      this.unit = this.value.unit;
      this.start = this.value.start;
      this.end = this.value.end;
    }
  }

  public getStart() {
    return this.start;
  }

  unitChanged() {
    switch (this.unit) {
      case PeriodUnit.seconds:
        this.min = 0;
        this.max = 61;
        break;
      case PeriodUnit.minutes:
        this.min = 0;
        this.max = 61;
        break;
      case PeriodUnit.hours:
        this.min = 0;
        this.max = 25;
        break;

    }
    if (this.end > this.max) {
      this.end = this.max;
    }
    const period: Period = {
      unit: this.unit,
      start: this.start,
      end: this.end,
    };
    this.rangeChanged.emit(period);
  }

  onRangeChange(range: number[]) {
    this.start = range[0];
    this.end = range[1];
    const period: Period = {
      unit: this.unit,
      start: range[0],
      end: range[1],
    };
    this.rangeChanged.emit(period);
  }

}
