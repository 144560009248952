<div *ngIf="title" class="ui-row title-row">
  <span class="ui-sub-title">{{ title }}<span *ngIf="required" class="ui-required">*</span></span>
</div>
<div
  #selectorInput
  class="ui-clickable ui-selector-menu-trigger ui-selector-item"
  [class.ui-select-input]="hasBorder"
  [class.ui-select-no-border]="!hasBorder"
  [class.ui-select-no-background]="!hasBackground"
  [class.ui-ngrx-forms-invalid]="invalid"
  [class.ui-disabled]="disabled"
  [class.ui-readonly]="readonly"
  [class.opened]="opened"
  [class.ui-select-input-40]="size === 40"
  (click)="disabled || readonly ? $event.stopPropagation() : toggleDialog()"
>
  <ui-truncated-html class="ui-selector-selected">
    <ng-container *ngIf="multi && optionValues?.length > 0 && allSelected; else inputContent"> All</ng-container>
    <ng-template #inputContent>
      <ng-container *ngIf="showSelected">
        <ng-container *ngIf="placeholder && selectedValues?.length === 0">
          {{ placeholder }}
        </ng-container>

        <ng-container *ngFor="let selectedValue of selectedValues; first as isFirst">
          <ng-container *ngIf="!isFirst">,</ng-container>
          <ng-container [ngTemplateOutlet]="selectedOptionTemplate"
                        [ngTemplateOutletContext]="{ $implicit: selectedValue }"
          >
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!showSelected">
        {{ placeholder }}
      </ng-container>
    </ng-template>
  </ui-truncated-html>
  <span
    *ngIf="multi && !allSelected && selectedValues?.length > 0; else arrow"
    class="icon-close-2 arrow_icon"
    (click)="clearMulti($event)"
  ></span>
  <ng-template #arrow>
    <span class="icon-arrow-16 arrow_icon icon-size-16" [class.ui-selector-opened]="!opened"></span>
  </ng-template>
</div>
<ng-template #selectorTemplate hasBackdrop="false">
  <ng-container #dialogContainer>
    <div *ngIf="searchEnabled" class="ui-selector-search-bar-container">
      <ui-search placeholder="Type to search..."
                 (click)="$event.stopPropagation(); $event.preventDefault()"
                 (onInput)="search($event)"
                 [noFocus]="true"
      >
      </ui-search>
    </div>
    <div *ngIf="multi && selectAllEnabled"
         (click)="selectAll()"
         class="ui-selector-select-all ui-clickable ui-selector-item"
    >
      <span class="ui-selector-select-all-text">Select All</span>
    </div>
    <cdk-virtual-scroll-viewport
      [itemSize]="itemSize"
      [style.height.px]="selectorItemsViewportHeight"
      class="ui-selector-scroll-bar-container"
    >
      <div
        *cdkVirtualFor="let option of optionValues"
        #item
        class="ui-selector-option ui-clickable ui-selector-item"
        [style.height.px]="itemSize"
        [class.disabled]="!!limit && selectedValues >= limit"
        [ngClass]="
          isValueSelected(option) ? (multi ? 'ui-select-option-active' : 'ui-select-option-active ui-select-active-background') : ''
        "
        (click)="setSelectedValue(option);$event.stopPropagation()"
      >
        <ui-truncated-html [style.width.%]="100">
          <div fxLayoutAlign="start center" class="ui-select-multi-container">
            <ng-container *ngIf="multi">
              <mat-checkbox
                color="primary"
                [checked]="item.classList.contains('ui-select-option-active')"
              >
              </mat-checkbox>
            </ng-container>
            <ng-container [ngTemplateOutlet]="optionTemplate"
                          [ngTemplateOutletContext]="{ $implicit: option }"
            ></ng-container>
          </div>
        </ui-truncated-html>
      </div>
    </cdk-virtual-scroll-viewport>
    <ui-preloader *ngIf="lazySearch && loading"></ui-preloader>
    <div *ngIf="optionValues?.length === 0" class="ui-selector-no-items">
      <div class="ui-selector-no-options-container">No Available Options</div>
    </div>
  </ng-container>
</ng-template>
<ng-template #defaultTemplate let-option>{{ option }}</ng-template>
<span *ngIf="invalid && validationMessage" class="ui-validation-message">{{ validationMessage }}</span>
