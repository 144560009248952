<div fxLayout="column" class="ui-modal">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    Video Signature
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>
  <div class="body">
    <div class="description">This is a hash signature for the video.</div>
    <div class="input-row" fxLayout="row" fxLayoutAlign="space-between end">
      <div class="flex-col input-wrapper">
        <label>MD5 Signature</label>
        <input disabled class="ui-input" [ngModel]="data.signature">
      </div>
      <ui-button
        class="copy-btn"
        type="default"
        text="Copy"
        icon="content_copy"
        [cdkCopyToClipboard]="data.signature"
      ></ui-button>
    </div>
  </div>
</div>
