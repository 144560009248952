<div>
  <div class="location-name"
       [class.edge-selection]="edgeSelection"
       fxLayout="row"
       fxLayoutAlign="space-between center"
       (click)="hide()"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-checkbox
        *ngIf="multi && !edgeSelection"
        type="checkbox"
        color="primary"
        [(ngModel)]="isSelectedAll"
        (ngModelChange)="selectAll($event)"
        (click)="$event.stopPropagation();"
        name="select-all"
      >
      </mat-checkbox>
      <span class="ui-icon-location" style="font-size: 20px; width: 20px; height: 20px;"></span>
      <ui-truncated-html [class.narrow-name]="narrow">{{ location.name }} ({{ location.address }}, {{ location.city }}
                                                                          , {{ location.state }})
      </ui-truncated-html>
    </div>

    <!--    <mat-icon-->
    <!--      style="color: red; margin-left: 16px; cursor: pointer"-->
    <!--      matTooltip="All selected cameras should be from same timezone"-->
    <!--      *ngIf="selectedTimeZone ? location.timezone !== selectedTimeZone : false"-->
    <!--    >-->
    <!--      priority_high-->
    <!--    </mat-icon>-->
    <span
      class="ui-icon-expand-arrow"
      [class.rotated]="!hidden"
    ></span>
  </div>

  <ng-container *ngIf="edgeSelection && !hidden">
    <div fxLayoutAlign="start center" fxLayoutGap="10px"
         class="edge-row"
         *ngFor="let edge of location.edges"
    >
      <mat-radio-button color="primary"
                        [checked]="edgeSelected(edge.id)"
                        (change)="addRemove(edge.id)"
      ></mat-radio-button>
      <div>{{ edge.name }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!edgeSelection && !hidden">
    <div *ngIf="!location.cameras">No cameras found</div>
    <div *ngFor="let edge of location.edges; trackBy:trackByEdgeId">
      <div class="selection-edge-row flex-row space-between-center"
           (click)="edgesHidden[edge.id] = !edgesHidden[edge.id]"
      >
        <div class="flex-row start-center gap-10">
          <mat-checkbox
            *ngIf="multi"
            type="checkbox"
            color="primary"
            (change)="selectAllEdgeCameras($event, edge.id)"
            (click)="$event.stopPropagation();"
            name="select-all"
            [checked]="allEdgeCamerasSelected(edge.id)"
          >
          </mat-checkbox>
          <span class="ui-icon-edge-status-alert"></span>
          <div>{{ edge.name }}</div>
        </div>
        <span
          class="ui-icon-expand-arrow"
          [class.rotated]="!edgesHidden[edge.id]"
        ></span>

      </div>
      <ng-container *ngIf="!edgesHidden[edge.id]">
        <div *ngFor="let camera of edgeCameras(edge.id);trackBy:trackByCameraId">
          <camera-row [camera]="camera"
                      [withAlerts]="withAlerts"
                      [edgeSelection]="edgeSelection"
                      [zonesCache]="zonesCache"
                      [cameraEventsLookup]="cameraEventsLookup"
                      [selectedAlerts]="selectedAlerts"
                      [zoneSelection]="zoneSelection"
                      [selectedCameras]="selectedCameras"
                      [narrow]="narrow"
                      [multi]="multi"
                      (onCameraSelected)="selectCamera($event)"
                      (onEventSelected)="selectEvent($event)"
                      (onZonesSelection)="selectZones($event)"
          >

          </camera-row>

        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
