import { createReducer, on } from '@ngrx/store';
import { ApiKeyModel } from '@models/api-key.models';
import { ApiKeyActions } from '@states/api-key/api-key.action-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GrantedAccessActions from '@states/granted-access/granted-access.actions';
import { resetList } from '@states/api-key/api-key.actions';

export interface ApiKeyState extends EntityState<ApiKeyModel.ApiKeyDocument> {
  apiKeys: ApiKeyModel.ApiKeyDocument[];
  page: number;
  perPage: number;
  isLoading: boolean;
  query: string,
  filters: ApiKeyModel.Filters;
  isLastPage: boolean;
}

export const adapter: EntityAdapter<ApiKeyModel.ApiKeyDocument> = createEntityAdapter<ApiKeyModel.ApiKeyDocument>({
  selectId: (apiKeyModel: ApiKeyModel.ApiKeyDocument) => apiKeyModel._id,
});


export const initialState: ApiKeyState = adapter.getInitialState({
  apiKeys: null,
  page: 0,
  perPage: 20,
  isLoading: false,
  query: null,
  filters: {
    orderBy: 'timestamp',
    orderDirection: 'asc',
  },
  isLastPage: false,
});

export const apiKeyStateReducer = createReducer(
  initialState,
  on(ApiKeyActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ApiKeyActions.getApiKeysSuccess, (state, { apiKeys }) => {
    return adapter.addMany(apiKeys, {
      ...state,
      isLastPage: apiKeys.length < state.perPage,
      page: state.page + 1,
    });
  }),
  on(ApiKeyActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading: isLoading,
    };
  }),
  on(ApiKeyActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(ApiKeyActions.revokeApiKey, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  }),
  on(ApiKeyActions.resetList, (state) => {
    return adapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
    });
  }),
);
