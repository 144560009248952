import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-seeker',
  templateUrl: './player-seeker.component.html',
  styleUrls: ['./player-seeker.component.scss'],
})
export class PlayerSeekerComponent implements OnInit {
  @Input()
  position: number = 50;

  constructor() {}

  ngOnInit(): void {}
}
