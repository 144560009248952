import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { checkPermission } from '../helpers/common.helpers';
import { PermissionModel } from '@models/permission.model';
import Permissions = PermissionModel.Permissions;
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { routerSegments } from '@consts/routes';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CameraCreateGuard implements CanActivate {
  public selectPermissions$: Observable<{
    permissions: string[],
    entityIds: string[]
  }> = this.store$.pipe(select(PermissionSelectors.selectPermissions));

  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  constructor(private store$: Store<AppState>, private router: Router,
              private route: ActivatedRoute) {
  }

  public canActivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.selectPermissions$, this.isDeveloper$])
      .pipe(
        untilDestroyed(this),
        map(([res, isDeveloper]) => {
          const checkPerms = checkPermission(Permissions.CameraCreate, res, []);
          const isNotAuthorised = !checkPerms && !isDeveloper;
          if (isNotAuthorised) {
            this.router.navigate([routerSegments.notFound], { relativeTo: this.route, skipLocationChange: true });
          }
          return !isNotAuthorised;
        }),
      );
  }
}
