import { animate, animateChild, group, query, stagger, style, state, transition, trigger } from '@angular/animations';

export const FadeInAnimation = trigger('fade', [
  transition('void => *', [style({ opacity: 0 }), animate(700, style({ opacity: 1 }))]),
  transition('* => void', [animate(200, style({ opacity: 0 }))]),
]);

export const FadeInAnimationFast = trigger('fadeFast', [
  transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
  transition('* => void', [animate(300, style({ opacity: 0 }))]),
]);

export const FadeOutAnimation = trigger('fadeOut', [
  transition('* => void', [animate(200, style({ opacity: 0 }))]),
]);

export const FadeAndShrink = trigger('fadeAndShrink', [
  transition('void => *', [style({ opacity: 0, height: '0px' }), animate(500, style({ opacity: 1, height: 'auto' }))]),
  transition('* => void', [animate(500, style({ opacity: 0, height: 0 }))]),
]);
