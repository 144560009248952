import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { first, map, Observable, tap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import * as _ from 'lodash';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard  {
  constructor(
    @Inject('allowedRoles') private allowedRoles: string[],
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.user$.pipe(
      map(user => _.intersection(this.allowedRoles, user?.roles).length > 0),
      first(),
      tap(allowed => {
        if (!allowed) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
