import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIInputStyle } from '@enums/shared.enum';

@Component({
  selector: 'app-show-password-modal',
  templateUrl: './show-password-modal.component.html',
  styleUrls: ['./show-password-modal.component.scss'],
})
export class ShowPasswordModalComponent implements OnInit {
  public inputStyle = UIInputStyle;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      password: string;
    },
  ) {
  }

  ngOnInit(): void {
  }

}
