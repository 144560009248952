<ng-container *ngrxLet="selectCameraUptime$;let cameraUptime">
  <div class="cameras-stats-modal">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between">
      {{data.camera.edgeOnly.name}}
      <mat-icon class="ui-mat-icon-16 close-icon" mat-dialog-close>close</mat-icon>
    </div>
    <div class="modal-body">
      <!--      FILTERS-->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="row"
               fxLayoutAlign="space-between center"
               class="preview-btn"
               (click)="isPreviewExpanded=!isPreviewExpanded"
          >
            <span class="ui-icon-camera"></span>
            <span class="title">Camera preview</span>
            <mat-icon class="ui-mat-icon-16" [class.expanded]="isPreviewExpanded">expand_less</mat-icon>
          </div>
          <div #wrapper class="main-status">
            <div class="label" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="status-square-icon online">crop_square</mat-icon>
              {{ cameraUptimeOnlinePercentage }}% online
              <mat-icon class="status-square-icon offline">crop_square</mat-icon>
              {{ 100 - cameraUptimeOnlinePercentage }}% offline
            </div>
          </div>
        </div>
        <mat-select class="ui-selector" [(ngModel)]="selectedPeriod" (ngModelChange)="calculateStartEnd()">
          <mat-option *ngFor="let period of periods" [value]="period.value">
            {{period.title}}
          </mat-option>
        </mat-select>
      </div>

      <!--      CAMERA PREVIEW-->
      <div [hidden]="!isPreviewExpanded" class="camera-preview">
        <img
            class="snapshot-img"
            [lazyLoad]=" {edgeId:data.camera.edgeId, cameraId:data.camera.edgeOnly.cameraId } | cameraBestImagePipe | async"
            [defaultImage]="{edgeId:data.camera.edgeId, cameraId:data.camera.edgeOnly.cameraId } | cameraThumbnailImagePipe"
        />
      </div>
      <!--      CAMERA LIST-->
      <div class="camera-list" #cameraList>
        <div fxLayout="row" class="bar-container p-r">
          <ng-container *ngIf="cameraUptime else checkLoader">
            <div
              #bar
              *ngFor="let online of cameraUptime; let i = index"
              class="bar"
              fxLayout="column"
              [class.empty]="!createdAt || endTs(i) < createdAt"
              (mouseenter)="bar.classList.add('over'); detectChanges();"
              (mouseleave)="bar.classList.remove('over'); detectChanges();"
            >
              <div
                  *ngIf="(!createdAt || endTs(i) >= createdAt) && bar.classList.contains('over')"
                  #tooltip
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  class="bar-tooltip"
                  [style.left]="
                bar.offsetLeft < tooltip.clientWidth / 2 - 64
                  ? tooltip.clientWidth / 2 +'px'
                  : (bar.offsetLeft< cameraList.clientWidth-tooltip.clientWidth)
                  ?bar.offsetLeft+'px'
                  :(cameraList.clientWidth-(tooltip.clientWidth)/2)+'px'
              "
              >

                <div class="bar-tooltip-status"
                     [class.red]="online !== 100"
                     [innerHTML]="toolTipStatus(online, i)"
                ></div>
                <div class="bar-tooltip-time" [innerHTML]="toolTipTime(online, i)"></div>

              </div>
              <div *ngIf="!createdAt || endTs(i) >= createdAt" class="offline"
                   [style.background-color]="uiBarColors[uiUptimeBarColors.offline]" fxFlex></div>
              <div *ngIf="!createdAt || endTs(i) >= createdAt" class="online"
                   [style.background-color]="uiBarColors[uiUptimeBarColors.online]"
                   [style.height]="online + 'px'"
              ></div>
            </div>
          </ng-container>
        </div>
      </div>
      <!--      TIMELINE -->
      <div class="timeline" fxLayout="row" fxLayoutAlign="space-between center">
        <ui-user-settings-date [date]="start"
                               [dateTypeFormat]="format"
        ></ui-user-settings-date>
        <ui-user-settings-date [date]="start + ((end - start) / 4)"
                               [dateTypeFormat]="format"
        ></ui-user-settings-date>
        <ui-user-settings-date [date]="(start + end) / 2"
                               [dateTypeFormat]="format"
        ></ui-user-settings-date>
        <ui-user-settings-date [date]="end - ((end - start) / 4)"
                               [dateTypeFormat]="format"
        ></ui-user-settings-date>
        <ui-user-settings-date [date]="end"
                               [dateTypeFormat]="format"
        ></ui-user-settings-date>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>

<ng-template #checkLoader>
  <ng-container *ngIf="isLoaded$|async; else loader">
    <div class="w-100 h-100" [style.background-color]="uiBarColors[uiUptimeBarColors.offline]"></div>
  </ng-container>
</ng-template>
