<ng-container *ngrxLet="selectCameraStatus$;let cameraStatusMap">
  <ui-device-status [status]="cameraStatusMap[cameraId]?.status"
                    [showStatus]="showStatus"
                    [showTime]="showTime"
                    [playing]="playing"
                    [timezone]="timezone"
                    [iconSizePx]="iconSizePx"
  >
  </ui-device-status>
</ng-container>
