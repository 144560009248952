import { LiveStreamModels } from '@models/live-stream.model';

export const hlsDefaults: LiveStreamModels.HLS = {

  hlsFragmentDuration: 1070,
  hlsMaxLiveFragments: 3,
  hlsMaxStoredFragments: 6,

  // MQ
  hlsMQScaledFragmentDuration: 1070,
  hlsMQMaxLiveFragments: 3,
  hlsMQMaxStoredFragments: 6,
  hlsMQFromMainstream: true, //OZ: I currently ignore it :)
  hlsMQScaledWidth: -2, //OZ: -2 to preserve aspect ratio
  hlsMQScaledHeight: 540,
  hlsMQScaledBitrateBps: 3000000,
  hlsMQScaledPeakBitrateBps: 4000000,
  hlsMQScaledIFrameInterval: 15,
  hlsMQScaledIFrameIntervalFromFPS: false,
  hlsMQScaledRateControlType: LiveStreamModels.RateControlType.CBR, //OZ: "cbr"
  hlsMQScaledPresetLevel: LiveStreamModels.RateControlPresetLevel.UltraFast, //OZ: "ultra_fast"

  // SQ
  hlsSQScaledFragmentDuration: 1070,
  hlsSQMaxLiveFragments: 3,
  hlsSQMaxStoredFragments: 6,
  hlsSQScaledWidth: -2, //OZ: -2 to preserve aspect ratio
  hlsSQScaledHeight: 240,
  hlsSQScaledBitrateBps: 1000000,
  hlsSQScaledPeakBitrateBps: 1500000,
  hlsSQScaledIFrameInterval: 15,
  hlsSQScaledIFrameIntervalFromFPS: false,
  hlsSQScaledRateControlType: LiveStreamModels.RateControlType.CBR, //OZ: "cbr"
  hlsSQScaledPresetLevel: LiveStreamModels.RateControlPresetLevel.UltraFast, //OZ: "ultra_fast"
};
