import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DoorsState, selectAll } from '@states/doors/doors.reducer';
import { LocationState } from '../location/location.reducer';
import { selectLocationState } from '../location/location.selectors';

export const selectDoorsState = createFeatureSelector<DoorsState>('doorsState');

export const selectAllDoors = createSelector(selectDoorsState, selectAll);
export const selectSavedDoors =
  createSelector(
    selectAllDoors,
    doors =>
      doors.filter(door => !!door.isSaved),
  );

export const selectUnsavedDoors =
  createSelector(
    selectAllDoors,
    doors =>
      doors.filter(door => !door?.isSaved),
  );

export const selectDoorsByLocationId = (locationId: string) =>
  createSelector(selectDoorsState, (doors: DoorsState) => Object.values(doors.entities).filter(door => door.locationId === locationId && !!door.isSaved));


export const selectDoorNameById = (doorId: string) =>
  createSelector(selectDoorsState, (doors: DoorsState) => doors.entities[doorId]?.name);

export const selectPersonById = (id: string) => createSelector(selectDoorsState, (doors: DoorsState) => doors.entities[id]);

