import { createAction, props } from '@ngrx/store';
import { AlertV2Document } from '@models/alerts-v2.model';
import { AlertNotificationLog } from '@models/alert-monitoring.model';

export const resetToInitialState = createAction('[ALERT-LOGS] Reset to Initial state');
export const resetEntities = createAction('[ALERT-LOGS] Reset Entities');

export const beforeGetAlertLogs = createAction('[ALERT-LOGS] Before Get Alert Logs');
export const getAlertLogs = createAction('[ALERT-LOGS] Get Alert Logs from Server');
export const getAlertLogsSuccess = createAction('[ALERT-LOGS] Get Alert Logs from Server Success', props<{ alertLogs: AlertNotificationLog[] }>());
export const getAlertLogsFail = createAction('[ALERT-LOGS] Set Alert Logs from Server Fail');

export const setIsLoading = createAction('[ALERT-LOGS] Set Is Loading', props<{ isLoading: boolean }>());

export const setFilter = createAction('[ALERT-LOGS] Set Filter Property', props<{ property: string, value: any }>());
export const removeFilter = createAction('[ALERT-LOGS] Remove Filter', props<{ property: string, value: any }>());
export const setOrderDirection = createAction('[ALERT-LOGS] Set Order Direction', props<{ property: string }>());

