<ng-container *ngrxLet="selectIsInitialLoaded$;let isInitialLoaded">
  <ng-container *ngrxLet="selectedUsers$;let selectedUsers">
    <div class="ui-modal">
      <div class="header flex-row space-between-center">
        <span>Manage users </span>
        <span class="ui-icon-close c-p" mat-dialog-close></span>
      </div>
      <div class="body" *ngrxLet="selectOrganizationUsersEntities$;let users">
        <div class="flex-row space-between-center">
          <div class="search-wrapper">
            <ui-search placeholder="Search for users" (onInput)="search($event)"></ui-search>
          </div>
        </div>
        <div class="invite-content" #scrollContainer>
          <ng-container *ngIf="isInitialLoaded;else loader">
            <ng-container *ngIf="users?.length;else noDataResult">
              <cdk-virtual-scroll-viewport
                [itemSize]="40"
                [style.height.px]="viewPortHeight"
              >
                <div *ngFor="let user of users"
                     class="invite-row flex-row space-between-center"
                >
                  <div>
                    <mat-checkbox color="primary"
                                  [checked]="!!selectedUsers[user._id]"
                                  (change)="selectUser(user._id)"
                    ></mat-checkbox>
                    <ng-container *ngIf="!!user?.firstname || !!user?.lastname;else na">
                      <span>{{ user.firstname }} {{ user.lastname }}</span>
                    </ng-container>
                  </div>

                  <span>{{ user.email }}</span>
                </div>

              </cdk-virtual-scroll-viewport>
            </ng-container>

          </ng-container>
        </div>
      </div>
      <div class="footer" fxLayout="row" fxLayoutAlign="end center">
        <ui-button
          type="primary"
          text="Save"
          (click)="close()"
        ></ui-button>
      </div>
    </div>
  </ng-container>
</ng-container>


<ng-template #loader>
  <ui-preloader [color]="loaderColor.blue"></ui-preloader>
</ng-template>

<ng-template #noDataResult>
  <ui-no-data [imgSrc]="noResults.imgSrc" [title]="noResults.title" [description]="noResults.description"
              [createButtonText]="noResults.createButtonText"
              (onCreate)="openInviteUsersModal()"
  >
  </ui-no-data>
</ng-template>
<ng-template #na>
  <span>&#8722;</span>
</ng-template>
