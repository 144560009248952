import { createAction, props } from '@ngrx/store';
import { UserGroupsModel } from '@models/user-groups.model';

export const resetToInitialState = createAction('[USER-GROUPS] Reset to Initial state');

export const beforeGetUserGroups = createAction('[USER-GROUPS] Before Get User Groups');
export const getUserGroups = createAction('[USER-GROUPS] Get User Groups from Server');
export const getUserGroupsSuccess = createAction('[USER-GROUPS] Get User Groups from Server Success', props<{ userGroups: UserGroupsModel.UserGroupDocument[] }>());
export const getUserGroupsFail = createAction('[USER-GROUPS] Set User Groups from Server Fail');

export const setFilter = createAction('[USER-GROUPS] Set Filter Property', props<{ property: string, value: any }>());
export const removeFilter = createAction('[USER-GROUPS] Remove Filter', props<{ property: string, value: any }>());
export const setOrderDirection = createAction('[USER-GROUPS] Set Order Direction', props<{ property: string }>());

export const setIsLoading = createAction('[USER-GROUPS] Set Is Loading', props<{ isLoading: boolean }>());
export const setIsInitialLoaded = createAction('[USER-GROUPS] Set is Initial Loaded', props<{ isInitialLoaded: boolean }>());
