import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-filters-modal',
  templateUrl: './show-filters-modal.component.html',
  styleUrls: ['./show-filters-modal.component.scss'],
})
export class ShowFiltersModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
    title: string;
    filters: string[]
  }) {
  }

  ngOnInit(): void {
  }

}
