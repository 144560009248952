import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, mergeMap, share, switchMap } from 'rxjs';
import { DoorsActions } from '@states/doors/doors.action-types';
import { DoorsService } from 'src/app/development/doors.service';

@Injectable()
export class DoorsEffect {

  public getDoors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoorsActions.getDoors),
      mergeMap(() => {
          return this.doorsService.getDoors()
            .pipe(
              switchMap(res => {
                return [SharedActions.setIsLoading({isLoading: false}), DoorsActions.getDoorsSuccess({doors: res})];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({isLoading: false}), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public addDoor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoorsActions.addDoor),
      mergeMap(({door}) => {

          return this.doorsService.addDoor(door)
            .pipe(
              switchMap(res => {
                return [DoorsActions.addDoorSuccess({door})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editDoor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoorsActions.editDoor),
      mergeMap(({door}) => {

          return this.doorsService.editDoor(door)
            .pipe(
              switchMap(res => {
                return [DoorsActions.editDoorSuccess({door})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removeDoor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoorsActions.removeDoor),
      mergeMap(({door}) => {

          return this.doorsService.removeDoor(door)
            .pipe(
              switchMap(res => {
                return [DoorsActions.removeDoorSuccess({door})];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private doorsService: DoorsService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({error: response?.error?.message});
  }

}
