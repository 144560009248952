import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, exhaustMap, filter, map, mergeMap, of, share, switchMap, throwError, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { SharedService } from '../../development/shared.service';
import { MsgBoxType } from '../../shared/msg-box/msg-box.model';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { ConfirmDialogSelection, ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { EdgeService } from '../../edge/edge.service';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { SessionDataSuccessResponse } from '@models/shared.model';
import { UtilsService } from '../../edge/utils.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { SessionDataAction } from '@enums/session-data.enum';
import { CameraActions } from '@states/camera/camera.action-types';
import { StorageModel } from '@models/storage.model';
import { StorageActions } from '@states/storage/storage.action-types';
import { LocationActions } from '@states/location/location.action-types';
import { LocationModel } from '../../locations/location.model';
import { EdgeActions } from '@states/edge/edge.action-types';
import * as SearchConfigurationActions from '@states/camera-edit/camera-edit.actions';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';

@Injectable()
export class SharedEffects {

  public startLoadRequiredData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.startLoadRequiredData),
      withLatestFrom(
        this.store$.pipe(select(AuthenticationSelectors.getUserProfileDto)),
        this.store$.pipe(select(UserSelectors.isProfileLoaded)),
      ),
      switchMap(([, auth, isProfileLoaded]) => {
        const isLoggedIn = this.authenticationService.isLoggedIn();
        if (isLoggedIn && !isProfileLoaded) {
          return [
            UserActions.CreateOrGetUserProfile({
              userId: auth.authProviderId!,
              accessToken: auth.accessToken!,
            }),
          ];
        } else {
          return [SharedActions.setApplicationLoaded()];
        }
      }),
      share(),
    ),
  );


  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(SharedActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public showMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showMessage),
      exhaustMap(({ error, warning, success, info }) => {
        if (error) {
          this.sharedService.showCustomAlert(error, MsgBoxType.ERROR);
        }
        if (warning) {
          this.sharedService.showCustomAlert(warning, MsgBoxType.WARNING);
        }
        if (success) {
          this.sharedService.showCustomAlert(success, MsgBoxType.SUCCESS);
        }
        if (info) {
          this.sharedService.showCustomAlert(info, MsgBoxType.INFO);
        }
        return of(SharedActions.doNothing());
      }),
    ),
  );

  public confirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showConfirmModal),
      switchMap(({ options }) => {
        return this.confirm.open(options)
          .pipe(
            map(result => {
              if (result.selection == ConfirmDialogSelection.OK && result.type === ConfirmDialogType.CONFIRM) {
                return SharedActions.showConfirmModalResultConfirm({
                  params: options.params,
                });
              } else {
                return SharedActions.showConfirmModalResultCancel({
                  params: options.params,
                });
              }
            }),
            catchError(err => throwError(() => err)),
          );
      }),
      share(),
    ),
  );

  public startInactivityCountdown$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.startInactivityCountdown),
      switchMap(_ => {
        this.utilsService.startInactivityCountdown();
        return of(SharedActions.doNothing());
      }),
      share(),
    );
  });

  public stoptInactivityCountdown$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.stopInactivityCountdown),
      switchMap(_ => {
        this.utilsService.stopInactivityCountdown();
        return of(SharedActions.doNothing());
      }),
      share(),
    );
  });

  public consoleMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.consoleMessage),
      exhaustMap(({ error, warning, success }) => {
        // if (error) {
        //   console.error(error, MsgBoxType.ERROR);
        // }
        // if (warning) {
        //   console.warn(warning, MsgBoxType.WARNING);
        // }
        // if (success) {
        //   console.info(success, MsgBoxType.SUCCESS);
        // }
        return of(SharedActions.doNothing());
      }),
    ),
  );

  public subscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.subscribeToSessionStatus),
      mergeMap(({ token, sessionDataAction, params }) => {
        const timeout = params?.msTimeout ?? 10000;
        return this.edgeService.subscribeToSessionStatus(token, true, timeout, true, sessionDataAction)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED || state?.status === TokenDataStatus.ERROR),
            mergeMap((state) => {
              params = !!params ? { ...params, ...state } : { ...state };
              return [
                SharedActions.getSessionData({
                  token,
                  sessionDataAction,
                  params,
                }),
              ];
            }),
            catchError((err, caught) => {
              return [
                ...this.onErrorActions(sessionDataAction, params, err),
                SharedActions.setIsSessionDataLoading({
                  isSessionDataLoading: false,
                }),
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatusFail({ session: token, sessionDataAction, err }),
                SharedActions.deleteSessionDataDocument({ token }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  // public subscribeToSessionStatus$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SharedActions.subscribeToSessionStatus),
  //     mergeMap(({ token, sessionDataAction, params }) => {
  //       const timeout = params?.msTimeout ?? 2000;
  //       return this.edgeService.subscribeToSessionStatus(token, true, timeout, true)
  //         .pipe(
  //           filter(state => state?.status === TokenDataStatus.COMPLETED || state?.status === TokenDataStatus.ERROR),
  //           mergeMap(() => {
  //             return [
  //               SharedActions.getSessionData({
  //                 token,
  //                 sessionDataAction,
  //                 params,
  //               }),
  //             ];
  //           }),
  //           catchError((err, caught) => {
  //             return [
  //               SharedActions.setIsSessionDataLoading({
  //                 isSessionDataLoading: false,
  //               }),
  //               SharedActions.setIsSaving({ isSaving: false }),
  //               SharedActions.subscribeToSessionStatusFail({ session: token, sessionDataAction, err }),
  //               SharedActions.deleteSessionDataDocument({ token }),
  //             ];
  //           }),
  //         );
  //     }),
  //     share(),
  //   ),
  // );

  public getSessionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getSessionData),
      mergeMap(({ token, sessionDataAction, params }) => {

        const session$ =
          !!params['result']
            ? of({
              session: token,
              status: params['status'] as TokenDataStatus,
              timestamp: new Date(),
              result: params['result'],
            } as SessionDataSuccessResponse)
            : this.edgeService.getSessionData<SessionDataSuccessResponse>(token, true);

        return session$
          .pipe(
            mergeMap(result => {
              return [
                ...this.onSuccessActions(sessionDataAction, params, result),
                SharedActions.getSessionDataSuccess({ session: token, payload: result, sessionDataAction, params }),
                SharedActions.setIsSessionDataLoading({
                  isSessionDataLoading: false,
                }),
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.deleteSessionDataDocument({ token }),
              ];
            }),
            catchError((err, caught) => {

              return [
                ...this.onErrorActions(sessionDataAction, params, err),
                SharedActions.setIsSessionDataLoading({
                  isSessionDataLoading: false,
                }),
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.getSessionDataFail({ session: token, sessionDataAction, err }),
                SharedActions.deleteSessionDataDocument({ token }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteSessionDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.deleteSessionDataDocument),
      switchMap(({ token }) => {
        return this.edgeService.deleteDocument(token)
          .pipe(
            switchMap(result => {
              return [SharedActions.deleteSessionDataDocumentSuccess({ token })];
            }),
            catchError(() => {
              return [SharedActions.deleteSessionDataDocumentFail({ token })];
            }),
          );
      }),
      share(),
    ),
  );

  /**
   * Need to have all fallback in 1 place.
   */

  public getSessionStatusFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getSessionDataFail, SharedActions.subscribeToSessionStatusFail),
      exhaustMap(({ session, sessionDataAction, err }) => [
        SharedActions.getSessionStatusFailed({
          session,
          sessionDataAction,
          err,
        }),
      ]),
    ),
  );

  public onSuccessActions(sessionDataAction: SessionDataAction, params: { [key: string]: any }, result: SessionDataSuccessResponse) {
    const actions = [];
    switch (sessionDataAction) {
      case SessionDataAction.deleteCameraFromLocation:
        const request = params as unknown as { locationId: string; edgeId: string; cameraId: string };
        actions.push(
          CameraActions.DeleteCameraNoBackendCall({ request }), SharedActions.showMessage({ success: 'Camera deleted successfully' }),
        );
        break;
      case SessionDataAction.updateEdgeInLocation:
        const updateEdgeInLocationRequest: LocationModel.UpdateEdgeInLocationRequest = params['updateEdgeInLocationRequest'];
        if (!!updateEdgeInLocationRequest) {
          actions.push(EdgeActions.UpdateEdgeNoBackendCall({ request: updateEdgeInLocationRequest }));
        } else {
          const request: LocationModel.UpdateEdgeInLocationRequest = result['sqsMessage'] as LocationModel.UpdateEdgeInLocationRequest;
          actions.push(EdgeActions.UpdateEdgeNoBackendCall({ request }));
        }
        actions.push(LocationActions.GetLocations());
        break;
      case SessionDataAction.getStorageStats:
        const res = result.snsMessage.data as unknown as StorageModel.StorageStatsTimeRangeResponse;
        // Build cacheId and set cache
        const storageStats = res.storageStats;
        for(let stat of storageStats) {
          stat.edgeId = result['snsMessage'].edgeId;
          stat.cacheId = `${stat.edgeId}:${stat.cameraId}:${stat.base}`;
        }
        actions.push(StorageActions.getCamerasStorageStatsSuccess({ result: res }));
        break;
      case SessionDataAction.updateSearchConfiguration:
        actions.push(
          SharedActions.setIsSaving({ isSaving: false }),
          SharedActions.showMessage({
            success: 'Search configuration has been updated',
          }),
          SearchConfigurationActions.saveSearchConfigurationSuccess(),
        );
        break;
    }
    return actions;
  }

  public onErrorActions(sessionDataAction: SessionDataAction, params: { [key: string]: string }, err?: any) {
    const actions = [];
    switch (sessionDataAction) {
      case SessionDataAction.deleteCameraFromLocation:
        const request = params as unknown as { locationId: string; edgeId: string; cameraId: string };
        actions.push(
          SharedActions.setIsDeleting({ isDeleting: false }),
          SharedActions.setProcessingId({ processingId: null }),
          CameraActions.SetDeleting({ payload: { cameraId: request?.cameraId, deleting: false } }),
          SharedActions.showMessage({ error: 'Camera delete failed' }));
        break;
      case SessionDataAction.getStorageStats:
        console.log('storageStats error', err);
        break;
    }
    return actions;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private sharedService: SharedService,
    private utilsService: UtilsService,
    private confirm: ConfirmDialogService,
    private edgeService: EdgeService,
    private authenticationService: AuthenticationService,
  ) {
  }
}
