import { SensorModel } from "@models/sensor.model";

export const vendorDisplayMap = {
  [SensorModel.Provider.HALO]: 'HALO',
  [SensorModel.Provider.DISRUPTIVE]: 'Disruptive'
};

export const haloSensorTypeMap: { [Property in SensorModel.HaloSensorType]: string } = {
  [SensorModel.HaloSensorType.HALO_3C]: 'HALO 3C',
  [SensorModel.HaloSensorType.HALO_3C_PC]: 'HALO 3C PC',
  [SensorModel.HaloSensorType.HALO_AMPLIFY]: 'HALO Amplify',
  [SensorModel.HaloSensorType.HALO_2C]: 'HALO 2C'
}

export const sensorTypeDisplayMap = {
  [SensorModel.Provider.HALO]: haloSensorTypeMap,
  [SensorModel.Provider.DISRUPTIVE]: {
    temperature: 'Temperature Sensor',
    humidity: 'Humidity Sensor',
    touch: 'Touch Sensor',
    proximity: 'Proximity Sensor',
    waterDetector: 'Water Detector',
  }
};

export const haloEventTypeUnit: { [Property in SensorModel.Halo.HaloEventType]: string } = {
  [SensorModel.Halo.HaloEventType.temp]: 'F',
  [SensorModel.Halo.HaloEventType.rh]: '%',
  [SensorModel.Halo.HaloEventType.lux]: 'LUX',
  [SensorModel.Halo.HaloEventType.tvoc]: 'PPB',
  [SensorModel.Halo.HaloEventType.co2eq]: 'PPM',
  [SensorModel.Halo.HaloEventType.pm25]: 'µg/m³',
  [SensorModel.Halo.HaloEventType.pm10]: 'µg/m³',
  [SensorModel.Halo.HaloEventType.nh3]: 'PPM',
  [SensorModel.Halo.HaloEventType.no2]: 'PPB',
  [SensorModel.Halo.HaloEventType.co]: 'PPM',
  [SensorModel.Halo.HaloEventType.noise]: 'dB',
  [SensorModel.Halo.HaloEventType.move]: '',
  [SensorModel.Halo.HaloEventType.aqi]: '',
}