export enum NotificationStatus {
  INIT,
  SCHEDULED,
  PROCESSING,
  COMPLETED,
  ERROR,
}

export enum NotificationType {
  PROPER_FITTING,
  DOORS,
  INVOICE,
}

export enum NotificationIconType {
  ERROR,
  WARN,
  INFO
}

export interface NotificationBase {
  type: NotificationType;
  timestamp: number;
  title?: string;
  description?: string;
  data?: string;
}

export interface Notification extends NotificationBase {
  orgId?: string;
  userId?: string;
  id?: string; //firebase
  status: NotificationStatus;
  schedule?: {
    isScheduled?: boolean;
    schedule?: string;
    timeZone?: string;
    msg?: string;
  };
  state?: UserNotificationState;
}

export enum UserNotificationState {
  UNSEEN,
  SEEN,
}

export interface UserNotification {
  id: string;
  state: UserNotificationState;
}


