<video [class.isCoverMode]="!isRespectRatio"
       [class.isDefault]="isRespectRatio"
       #player
       controls
       crossorigin
       playsinline
       muted="muted"
       id="player"
       (timeupdate)="onTimeUpdate($event)"
></video>
