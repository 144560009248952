import { createReducer, on } from '@ngrx/store';
import * as StatsActions from '@states/stats/stats.actions';

export enum StatType {
  Edge,
  Camera,
  Analytic,
}

export interface StatResult {
  type: StatType;
  cameraId?: string;
  stats: number[];
}

export interface StatsState {
  cameraUptime: number[];
  analyticUptime: number[];
  edgeUptime: number[];
  edgeUptimeAll: StatResult[];
}

export const initialState: StatsState = {
  cameraUptime: null,
  analyticUptime: null,
  edgeUptime: null,
  edgeUptimeAll: null,
};

export const statsStateReducer = createReducer(
  initialState,
  on(StatsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(StatsActions.getCameraUptimeSuccess, (state, { cameraUptime }) => {
    return {
      ...state,
      cameraUptime,
    };
  }),
  on(StatsActions.getAnalyticUptimeSuccess, (state, { analyticUptime }) => {
    return {
      ...state,
      analyticUptime,
    };
  }),
  on(StatsActions.getEdgeUptimeSuccess, (state, { edgeUptime }) => {
    return {
      ...state,
      edgeUptime,
    };
  }),
  on(StatsActions.getEdgeUptimeAllSuccess, (state, { edgeUptimeAll }) => {
    return {
      ...state,
      edgeUptimeAll,
    };
  }),
  on(StatsActions.resetEdgeUptimeAll, (state) => {
    return {
      ...state,
      edgeUptimeAll: null,
    };
  }),
);
