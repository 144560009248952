import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CamerasThumbnailsService } from 'src/app/cameras/camera-thumbnails/camera-thumnails.service';
import { environment } from '../../../../environments/environment';
import { StateChange } from 'ng-lazyload-image';
import { PreloaderColor } from '@enums/shared.enum';
import { MediaCacheService } from '../../media-cache/media-cache.service';

export interface UiThumbnailPreviewData {
  edgeId: string;
  cameraId: string;
}

@Component({
  selector: 'ui-thumbnail-preview',
  templateUrl: './ui-thumbnail-preview.component.html',
  styleUrls: ['./ui-thumbnail-preview.component.scss'],
})
export class UiThumbnailPreviewComponent implements OnInit, OnChanges {

  public PreloaderColor = PreloaderColor;

  @Input() data: UiThumbnailPreviewData;
  @Input() ts: number;

  baseUrl: string;

  imageLoader = false;

  offline = false;
  img = '';

  constructor(private cameraThumbnailsService: CamerasThumbnailsService, private mediaCacheService: MediaCacheService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['ts']) {
      this.img = await this._img();
    }
  }

  ngOnInit(): void {
    if (this.data?.edgeId && this.data?.cameraId) {
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${this.data?.edgeId}/${this.data?.cameraId}`;
    }
  }

  normalizeTimestamp(timestamp: number, freq = 2000) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp, freq);
  }

  buildFileName(ts: number, replica: number = 0) {
    return `${ts}-${replica}-0`;
  }

  buildUrl(filename: string) {
    return `${this.baseUrl}/thumbnail-${filename}.jpg`;
  }

  // public get img() {
  //
  //   // [TODO] When implemented on the backend - check if thumbnail exists using the store time intervals show "offline" otherwise
  //   const filename = this.buildFileName(this.normalizeTimestamp(this.ts));
  //   const url = this.buildUrl(filename);
  //   return url;
  // }

  public async noThumbnail() {
    return await this.mediaCacheService.noThumbnail(this.data?.edgeId, this.data?.cameraId, this.ts);
  }

  public async isAlert() {
    return await this.mediaCacheService.isAlert(this.data.edgeId, this.data.cameraId, this.ts);
  }

  public async _img() {
    const { noThumbnail } = await this.noThumbnail();
    if (noThumbnail) {
      if (this.mediaCacheService.isTsAlignedTo20Seconds(this.ts)) {
        this.offline = true;
        return '';
      } else {
        this.offline = false;
        return this.img;
      }
    }
    this.offline = false;
    const { isAlert, replicas } = await this.isAlert();
    let replica;
    if (isAlert && replicas?.length) {
      // compute the replica according to offset between the 2 seconds and the number of replicas
      const offset = this.ts - this.normalizeTimestamp(this.ts);
      replica = Math.max(Math.floor(offset / 2000 * replicas.length), 0);
    }
    let filename = this.buildFileName(this.normalizeTimestamp(this.ts), 0);
    if (isAlert && replicas?.length) {
      filename = replicas[replica];
    }
    const url = this.buildUrl(filename);
    return url;
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        this.imageLoader = true;
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.imageLoader = false;
        break;
      case 'loading-failed':
        this.imageLoader = false;
        break;
    }
  }


}
