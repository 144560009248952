<div [formGroup]="form" class="flex-col gap-16 wrapper">
  <div>Sensitivity</div>
  <div class="flex-row custom-slider">
    <div class="flex-row center-center w-100 gap-8">
      <!--      <ui-toggle formControlName="sensitivityEnabled"></ui-toggle>-->
      <div class="flex-row center-center w-100">
        <div class="range-label left">0</div>
        <ngx-slider [(value)]="sensitivity"
                    [options]="{floor: 0, ceil: 100, showTicks: false, showSelectionBar: true}"
                    (userChangeEnd)="updateSensitivity($event)"
        ></ngx-slider>
        <div class="range-label">100</div>
      </div>
    </div>
  </div>
</div>
