import { createFeatureSelector, createSelector } from '@ngrx/store';
import { archiveAdapter, ArchiveState } from '@states/archive/archive.reducer';
import * as moment from 'moment-timezone';

export const selectArchiveState = createFeatureSelector<ArchiveState>('archiveState');

export const selectSelectedArchive = createSelector(selectArchiveState, ({ selectedArchive }: ArchiveState) => selectedArchive);

export const selectSelectedArchives = createSelector(selectArchiveState, ({ entities }: ArchiveState) => Object.values(entities ?? {}));

export const selectIsArchiveValid = createSelector(selectArchiveState, ({ isArchiveValid }: ArchiveState) => isArchiveValid);

export const selectPage = createSelector(selectArchiveState, ({ page }: ArchiveState) => page);

export const selectPerPage = createSelector(selectArchiveState, ({ perPage }: ArchiveState) => {
  return perPage;
});

export const selectFilters = createSelector(selectArchiveState, ({ filters }: ArchiveState) => {
  return filters;
});

export const selectArchiveDuration = createSelector(selectArchiveState, ({ selectedArchive }: ArchiveState) => {
  const duration = moment.duration(moment(selectedArchive.end)
    .diff(selectedArchive.start));
  return Math.floor(duration.asMinutes());
});

export const selectAddArchiveModalConfig = createSelector(
  selectArchiveState,
  ({ addArchiveModalConfig }: ArchiveState) => addArchiveModalConfig,
);

export const selectArchiveBySessionId = (sessionId: string) => createSelector(selectArchiveState, ({ entities }: ArchiveState) => entities[sessionId]);
export const selectIsLoading = createSelector(selectArchiveState, ({ isLoading }: ArchiveState) => isLoading);

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = archiveAdapter.getSelectors();

export const selectDownloadProgress = createSelector(selectArchiveState, ({ downloadProgress }: ArchiveState) => downloadProgress);
