import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

const HOUR_MIN = 60;
const DAY_MIN = HOUR_MIN * 24;
const WEEK_MIN = DAY_MIN * 7;
const DAY_HOUR = 24;
const WEEK_HOUR = DAY_HOUR * 7;
const WEEK_DAY = 7;

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  constructor() {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: 'few seconds',
        ss: '%d seconds',
        m: 'minute',
        mm: '%d minutes',
        h: 'hour',
        hh: '%d hours',
        d: 'day',
        dd: '%d days',
        w: 'week',
        ww: '%d weeks',
        M: 'month',
        MM: '%d months',
        y: 'year',
        yy: '%d years',
      },
    });
  }

  transform(value: number, unit: UiDatetimeRangePickerModel.CustomUnit): string {
    let result = value;
    let tmpUnit;
    switch (unit) {
      case CustomUnit.minutes:
        if (value >= WEEK_MIN) {
          const modulo = value % WEEK_MIN;
          if (!modulo) {
            result = value / WEEK_MIN;
            tmpUnit = CustomUnit.weeks;
          }
        } else if (value >= DAY_MIN) {
          const modulo = value % DAY_MIN;
          if (!modulo) {
            result = value / DAY_MIN;
            tmpUnit = CustomUnit.days;
          }
        } else if (value >= HOUR_MIN) {
          const modulo = value % HOUR_MIN;
          if (!modulo) {
            result = value / HOUR_MIN;
            tmpUnit = CustomUnit.hours;
          }
        }
        break;
      case UiDatetimeRangePickerModel.CustomUnit.hours:
        if (value >= WEEK_HOUR) {
          const modulo = value % WEEK_HOUR;
          if (!modulo) {
            result = value / WEEK_HOUR;
            tmpUnit = CustomUnit.weeks;
          }
        } else if (value >= DAY_HOUR) {
          const modulo = value % DAY_HOUR;
          if (!modulo) {
            result = value / DAY_HOUR;
            tmpUnit = CustomUnit.days;
          }
        }
        break;
      case UiDatetimeRangePickerModel.CustomUnit.days:
        if (value >= WEEK_DAY) {
          const modulo = value % WEEK_DAY;
          if (!modulo) {
            result = value / WEEK_DAY;
            tmpUnit = CustomUnit.weeks;
          }
        }
        break;
      case UiDatetimeRangePickerModel.CustomUnit.weeks:
        result = value;
        tmpUnit = unit;
        break;
    }
    if (tmpUnit === CustomUnit.weeks) {
      return `${result} ${result === 1 ? 'week' : tmpUnit}`;
    } else if (tmpUnit) {
      return moment().subtract(result, tmpUnit).fromNow();
    } else {
      return `${result} ${result > 1 ? unit : unit.substring(0, unit.length - 1)}`;
    }
  }
}
