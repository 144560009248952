/**
 * Hosted in Kubernetes at GKE lumixai-staging cluster
 * communicates with services at cloud
 */
export const environment = {
  env: 'staging-hosted',
  production: false,
  apiUrl: 'https://api-staging.lumix.ai',
  apiLumana: 'https://api-staging.lumana.ai',
  mediasoupApiUrl: 'https://api-staging.lumix.ai',
  socketUrls: {
    main: 'https://api-staging.lumix.ai',
    edgePlayback: 'https://play-staging.lumix.ai',
  },
  searchUrl: 'https://api-staging.lumix.ai',
  mediasoupUrl: 'wss://mediasoup-staging.lumix.ai:4443',
  groupsUrl: 'https://api-staging.lumix.ai',
  doorsUrl: 'https://api-staging.lumix.ai',
  vehiclesUrl: 'https://api-staging.lumix.ai',
  orgGroupsUrl: 'https://api-staging.lumix.ai',
  customEventsUrl: 'https://api-staging.lumix.ai',
  motionSearchUrl: 'https://api-staging.lumix.ai',
  thumbnailsV2: 'https://api-staging.lumix.ai',
  dashboardUrl: 'https://staging.lumana.ai',
  thumbnailsUrl: 'https://thumbnails.lumix.ai',
  trainingThumbnailsUrl: 'https://training-thumbnails.lumix.ai',
  snapshotsUrl: 'https://snapshots.lumix.ai',
  streamsUrl: 'https://video-streams.lumix.ai',
  streamsUrlEu: 'https://video-streams-eu.lumix.ai',
  playbackUrl: 'https://playback.lumix.ai',
  playbackArchiveUrl: 'https://dkca2oap9x00x.cloudfront.net',
  analyticsTrackId: 'G-JQTLQK5DC7',
  playback: {
    consumer: {
      url: (edgeId: string, cameraId: string, sessionId: string) => `http://34.69.54.105/${edgeId}_${cameraId}_${sessionId}.m3u8`,
    },
  },
  aws: {
    bestCameraImage: (edgeId: string, cameraId: string) => `https://training-thumbnails.lumix.ai/latest/${edgeId}/${cameraId}/best.jpg`,
    faceIdBestImageUrl: 'https://training-thumbnails.lumix.ai',
    faceIdBestImageUrlSavedGroup: 'https://groups-images.lumix.ai',
    trainingThumbnailsUrl: 'https://training-thumbnails.lumix.ai',
  },
  edge: {
    edgeHttpsUrl: edgeId => `https://${edgeId}.lumixai.com`,
  },
  auth0: {
    domain: 'lumixaistaging.us.auth0.com',
    clientID: 'adCRHnmmRMRIlRlmDPLIvYwcnR3W8CCF',
    audience: 'https://lumixaistaging.us.auth0.com/api/v2/',
    redirectUri: 'https://staging.lumana.ai/splash',
    responseType: 'token id_token',
    scope: 'openid profile read:current_user',
    logoutUri: 'https://staging.lumana.ai/logout',
    tokenClaims: {
      AUTH0_CUSTOM_TOKEN_CLAIMS_ORG_ROLES: 'https://api-staging.lumix.ai/organization/roles',
      AUTH0_CUSTOM_TOKEN_CLAIMS_ORG_ID: 'https://api-staging.lumix.ai/organization/orgId',
      AUTH0_CUSTOM_TOKEN_CLAIMS_ORG_NAME: 'https://api-staging.lumix.ai/organization/orgName',
      AUTH0_CUSTOM_TOKEN_CLAIMS_SUPER: 'https://api-staging.lumix.ai/organization/super',
    },
  },
  firebase: {
    apiKey: 'AIzaSyAoFBvwpqnytcSuMwVWngX01bYkKS1ol1M',
    authDomain: 'lumixai.firebaseapp.com',
    projectId: 'lumixai',
    storageBucket: 'lumixai.appspot.com',
    messagingSenderId: '52817547048',
    appId: '1:52817547048:web:9f9dae25e60d5565c54a1d',
  },
};
