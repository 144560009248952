import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
  transform(value: number): number {
    if (value === null) {
      return 0;
    }
    return value - moment()
      .unix();
  }
}
