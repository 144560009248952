import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { ACCESS_TOKEN_KEY, AUTH_PROVIDER_ID_KEY, EXPIRES_IN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../authentication/authentication.service';
import { parseJwt } from '../helpers/common.helpers';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private autoLogoutMemory = null;

  constructor(private sessionStorageService: SessionStorageService) {
    console.log('LocalStorageService');
  }

  get autoLogout(): boolean {
    if (this.autoLogoutMemory != null) {
      return this.autoLogoutMemory;
    } else {
      let token = this.keys[ID_TOKEN_KEY];
      if (!token) {
        token = localStorage.getItem(ID_TOKEN_KEY);
      }
      if (!token) {
        token = sessionStorage.getItem(ID_TOKEN_KEY);
      }

      const tokenData = parseJwt(token);
      this.setAutoLogout(tokenData?.autoLogout);
      return this.autoLogoutMemory;
    }

  }

  private keys = {};

  setItem(key: string, val: any) {
    localStorage.setItem(key, val);
    this.keys[key] = val;
  }

  setItemWitchCheck(key: string, val: any) {
    this.keys[key] = val;
    if (this.autoLogout) {
      this.sessionStorageService.setItem(key, val);
    } else {
      localStorage.setItem(key, val);
      this.keys[key] = val;
    }
  }

  getItem(key: string, cache = true) {
    return cache && this.keys?.[key] ? this.keys[key] : localStorage.getItem(key);
  }


  getItemWithCheck(key: string, cache = true) {
    if (this.autoLogout) {
      return this.sessionStorageService.getItem(key);
    } else {
      return cache && this.keys?.[key] ? this.keys[key] : localStorage.getItem(key);
    }
  }

  removeItem(key: string) {
    if (this.keys && typeof this.keys === 'object') {
      delete this.keys[key];
    }
    this.sessionStorageService.removeItem(key);
    return localStorage.removeItem(key);
  }

  clear() {
    this.keys = {};
    this.setAutoLogout(null);
    this.sessionStorageService.clear();
    localStorage.clear();
  }

  clearAuthCredentials() {
    this.removeItem(ID_TOKEN_KEY);
    this.removeItem(ACCESS_TOKEN_KEY);
    this.removeItem(EXPIRES_IN_KEY);
    this.removeItem(AUTH_PROVIDER_ID_KEY);
    this.removeItem(REFRESH_TOKEN_KEY);
    this.setAutoLogout(null);
  }

  setAutoLogout(autoLogout: boolean) {
    this.autoLogoutMemory = autoLogout;
  }
}
