import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { debounceTime, Observable, Subject } from 'rxjs';
import { CustomEventModel } from '@models/custom-event.model';
import { CustomEventsSelectors } from '@states/custom-events/custom-events.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import * as _ from 'lodash';
import CustomEventFieldType = CustomEventModel.CustomEventFieldType;

@UntilDestroy()
@Component({
  selector: 'ui-event-types',
  templateUrl: './ui-event-types.component.html',
  styleUrls: ['./ui-event-types.component.scss'],
})
export class UiEventTypesComponent implements OnInit {

  changedDebouncer: Subject<void> = new Subject<void>();

  @Output() changed: EventEmitter<CustomEventModel.CustomEvent> = new EventEmitter<CustomEventModel.CustomEvent>();
  @Input() getEvents = true;

  public CustomEventFieldType = CustomEventModel.CustomEventFieldType;
  public CustomEventFieldDecimalOperators = CustomEventModel.CustomEventFieldDecimalOperators;
  public CustomEventFieldNumberOperators = CustomEventModel.CustomEventFieldNumberOperators;
  public CustomEventFieldTextOperators = CustomEventModel.CustomEventFieldTextOperators;

  public CustomEventFieldDecimalOperatorsA = Object.keys(CustomEventModel.CustomEventFieldDecimalOperatorsStr);
  public CustomEventFieldNumberOperatorsA = Object.keys(CustomEventModel.CustomEventFieldNumberOperatorsStr);
  public CustomEventFieldTextOperatorsA = Object.keys(CustomEventModel.CustomEventFieldTextOperatorsStr);

  public CustomEventFieldDecimalOperatorsStr = CustomEventModel.CustomEventFieldDecimalOperatorsStr;
  public CustomEventFieldNumberOperatorsStr = CustomEventModel.CustomEventFieldNumberOperatorsStr;
  public CustomEventFieldTextOperatorsStr = CustomEventModel.CustomEventFieldTextOperatorsStr;

  public selectAllCustomEvents$: Observable<CustomEventModel.CustomEvent[]> = this.store$.pipe(select(CustomEventsSelectors.selectAllCustomEvents))
    .pipe(untilDestroyed(this));

  public selectedCustomEvent: CustomEventModel.CustomEvent;
  public localCustomEvent: CustomEventModel.CustomEvent;

  public prevCustomEvent: CustomEventModel.CustomEvent;

  constructor(private store$: Store) {
  }

  ngOnInit(): void {
    if (this.getEvents) {
      this.store$.dispatch(CustomEventsActions.getCustomEvents());
    }
    this.changedDebouncer.pipe(debounceTime(2000))
      .subscribe(() => {
        const searchCustomEvent = this.searchCustomEvent;
        if (!_.isEqual(this.prevCustomEvent, searchCustomEvent)) {
          this.prevCustomEvent = searchCustomEvent;
          this.changed.emit(searchCustomEvent);
        }
      });
  }

  public clearCustomEvent() {
    delete this.selectedCustomEvent;
    delete this.localCustomEvent;
    this.changedDebouncer.next();
  }

  public customEventSelected(selectedCustomEvent: CustomEventModel.CustomEvent) {
    this.localCustomEvent = _.cloneDeep(selectedCustomEvent);
    for (let field of this.localCustomEvent.fields) {
      field.enabled = false;
      if (field.type === CustomEventFieldType.Boolean) {
        field.value = true;
      }
    }
    this.changedDebouncer.next();
  }

  triggerChange() {
    const event = this.searchCustomEvent;
    if (!!event) {
      this.changedDebouncer.next();
    }
  }

  public get searchCustomEvent() {
    const event = _.cloneDeep(this.localCustomEvent);
    if (!event) {
      return undefined;
    }
    event.fields = event.fields.filter(field => field?.enabled && ((!!field.value || field.value === 0) && (!!field?.operator || field?.operator === 0) || field?.type === CustomEventFieldType.Boolean))
      .map(field => {
        return {
          name: field.name,
          type: field.type,
          operator: field.type === CustomEventFieldType.Boolean ? 0 : +field?.operator,
          value: field.value,
        };
      });
    return event;
  }

}
