import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TerminalState } from '@states/terminal/terminal.reducer';

export const selectTerminalState = createFeatureSelector<TerminalState>('terminalState');

export const selectEdgeId = createSelector(selectTerminalState, ({ edgeId }: TerminalState) => edgeId);

export const selectLocationId = createSelector(selectTerminalState, ({ locationId }: TerminalState) => locationId);

export const selectContext = createSelector(selectTerminalState, (state: TerminalState) => {
  return state;
});
