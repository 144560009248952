import { Component, Input, OnInit } from '@angular/core';
import { PreloaderColor } from '../../../enums/shared.enum';

@Component({
  selector: 'ui-preloader',
  templateUrl: './ui-preloader.component.html',
  styleUrls: ['./ui-preloader.component.scss'],
})
export class UiPreloaderComponent implements OnInit {
  @Input() color: PreloaderColor = PreloaderColor.white;
  @Input() diameter: number;

  public preloader = PreloaderColor;

  constructor() {
  }

  ngOnInit(): void {
  }
}
