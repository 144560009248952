import { createAction, props } from '@ngrx/store';
import { WebRTCPeerInterface, WebRTCPeerInterfaceControl, WebRTCPeerInterfaceICE, WebRTCPeerInterfaceSDP, WebRTCPeerTurnCredentials, WebRtcStartRequest } from '@models/webrtc.model';
import { SessionDataAction } from '@enums/session-data.enum';
import { StringOrEmpty } from '../../../app.model';

export const resetToInitialState = createAction('[WEBRTC] Reset to Initial state');

export const CreateWebRtcSession = createAction(
  '[WEBRTC] Create WebRTC Session',
  props<{ peerData: WebRtcStartRequest, createToken: string }>(),
);

export const CreateWebRtcSessionSuccess = createAction(
  '[WEBRTC] Create WebRTC Session Success',
  props<{ peerData: WebRTCPeerInterface }>(),
);

export const SendWebRtcICE = createAction(
  '[WEBRTC] Send WebRtc ICE',
  props<{ peerData: WebRTCPeerInterfaceICE }>(),
);

export const SendWebRtcSDP = createAction(
  '[WEBRTC] Send WebRtc SDP',
  props<{ peerData: WebRTCPeerInterfaceSDP }>(),
);

export const SendWebRtcControl = createAction(
  '[WEBRTC] Send WebRtc CONTROL',
  props<{ peerData: WebRTCPeerInterfaceControl }>(),
);

export const CreateWebRtcSessionFail = createAction(
  '[WEBRTC] Create WebRTC Session Fail',
  props<{ message?: StringOrEmpty }>(),
);


export const UpdateWebRtcSession = createAction(
  '[WEBRTC] Update WebRTC Session',
  props<{ peerData: Partial<WebRTCPeerInterface> }>(),
);

export const DeleteWebRtcSession = createAction(
  '[WEBRTC] Delete WebRTC Session',
  props<{ sessionId: string }>(),
);

export const SubscribeToSessionData = createAction(
  '[WEBRTC] Subscribe To Session Data',
  props<{ token: string; }>(),
);

export const GetCreatedSessionDetails = createAction(
  '[WEBRTC] Get Created Session ID',
  props<{ createToken: string; sessionId: string, credentials: WebRTCPeerTurnCredentials }>(),
);

export const GetCreatedSessionDetailsSuccess = createAction(
  '[WEBRTC] Get Created Session Details success',
  props<{ createToken: string; sessionId: string, credentials: WebRTCPeerTurnCredentials }>(),
);

export const SetMediasoup = createAction(
  '[WEBRTC] Set Mediasoup',
  props<{ mediasoup: boolean }>(),
);


