import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;
import { AlertEvent } from '@models/alert-events.model';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { concatMap, filter, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlaybackPlayerComponent } from '../playback-player/playback-player.component';
import { PlaybackPlayerService } from '../playback-player/playback-player.service';

export interface PlaybackPlayerDialogData {
  timezone: string;
  cameraId: string;
  cameraName: string;
  time: number;
  duration: number;
}

@UntilDestroy()
@Component({
  selector: 'app-playback-player-dialog',
  templateUrl: './playback-player-dialog.component.html',
  styleUrls: ['./playback-player-dialog.component.scss'],
})
export class PlaybackPlayerDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('playback')
  playback: PlaybackPlayerComponent;

  @Input()
  edgeId: string;

  @Input()
  cameraId: string;

  locationId: string;

  playing = false;

  playerTime = 0;

  src;
  streamBase = '';

  aspectRatio;

  playerDuration;

  currentTs = new Date().getTime();

  base;
  timezone;

  duration;
  time;
  startTime;

  constructor(
    private store: Store,
    private playbackPlayerService: PlaybackPlayerService,
    public dialogRef: MatDialogRef<PlaybackPlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PlaybackPlayerDialogData
  ) { }

  ngOnInit(): void {
    this.timezone = this.data.timezone;
    this.cameraId = this.data.cameraId;
    this.startTime = this.data.time;
    this.duration = this.data.duration;
  }

  play() {
    if (!this.data.time) {
      return;
    }
    this.playerTime = this.data.time;
    this.playbackPlayerService.setTime(this.data.time);
    this.playbackPlayerService.initTimeLine(this.data.time);
    this.playback.setBaseTime(this.data.time);
    if (!this.playing) {
      this.startPlayback();
    } else {
      this.locationPlayback(this.currentTs);
    }
  }

  locationPlayback(ts: number) {
    this.playback.locationPlayback(this.data.time);
  }

  startPlayback() {
    this.playbackPlayerService.setPlaybackBase({
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId!,
    });
    this.playback.startPlayback(this.data.time);
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(untilDestroyed(this), select(CameraSelectors.selectCameraById(this.cameraId)))
      .pipe()
      .subscribe(camera => {
        if (!camera) {
          return;
        }
        this.aspectRatio = camera?.edgeOnly.mainStream.main.width / camera.edgeOnly.mainStream.main.height;
        this.locationId = camera?.locationId;
        const edgeId = camera?.edgeId;
        this.edgeId = edgeId;
        this.store.pipe(untilDestroyed(this), select(EdgeSelectors.selectLocalById(edgeId))).subscribe(isLocal => {
          if (isLocal) {
            this.store.pipe(select(EdgeSelectors.selectEdgeById(edgeId)), take(1)).subscribe(edge => {
              this.streamBase = `${edge.localUrl}/streams/playback/${edgeId}/${this.cameraId}`;
            });
          }
        });

        this.playbackPlayerService.playbackBase$
          .pipe(
            untilDestroyed(this),
            filter(res => !!res.sessionId)
          )
          .subscribe(res => {
            this.src = `${this.streamBase}/${res.sessionId}/s.m3u8`;
            this.playback.pollReload();
          });

        this.playbackPlayerService.playing$.pipe(untilDestroyed(this)).subscribe(playing => {
          this.playing = playing;
        });
      });

    this.play();
  }
}
