import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Search } from '../../search.model';
import { debounceTime } from 'rxjs';
import { ChangeContext } from 'ngx-slider-v2';

@Component({
  selector: 'ui-unusual-event',
  templateUrl: './ui-unusual-event.component.html',
  styleUrls: ['./ui-unusual-event.component.scss'],
})
export class UiUnusualEventComponent implements OnInit {

  @Output() changed = new EventEmitter<Search.UnusualEventSearchParams>();

  sensitivity = 50;

  public form: UntypedFormGroup = new UntypedFormGroup({
    sensitivity: new UntypedFormControl(5),
  });

  constructor() {
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(1000))
      .subscribe(
        (value) => {
          this.changed.emit(value);
        });
  }

  public updateSensitivity(event: ChangeContext) {
    this.form.patchValue({ sensitivity: event.value });
  }


}
