import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { WebRTCPeerControlTypes, WebRTCPeerInterface, WebRTCPeerInterfaceControl, WebRtcPeerType } from '@models/webrtc.model';
import * as uuid from 'uuid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WebRtcEffect } from '@effects/webrtc.effect';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { UiAreaSelection } from '../../shared/ui-kit/ui-area-selector/ui-area-selector.component';
import { Point } from '@angular/cdk/drag-drop';
import { SharedActions } from '@states/shared/shared.action-types';
import { MainMenuService } from '../../layout/main-menu.service';
import { WebrtcDebugDialogComponent, WebrtcDebugDialogData } from './webrtc-debug-dialog/webrtc-debug-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as Ably from 'ably';
import { WebrtcService } from '../../development/webrtc.service';
import { AblyTopics, AblyTopicsStr } from '@models/ably.model';
import { Quality, QualitySelectorComponent } from '../../shared/ui-kit/ui-quality-selector/quality-selector.component';
import { WebRtcActions } from '@states/webrtc/webrtc.action-types';

const DEFAULT_EXTEND_TIME = 180000;

interface WebrtcPlayerState {
  stream?: MediaStream;
  sessionId?: string;
  configuration?: RTCConfiguration;
  hq: boolean;
  pc?: any;
  sessionSubscription?: Subscription;
  incomingIce?: Partial<RTCIceCandidate>[];
  receivedOffer?: boolean;
}

interface WebrtcStats {
  output: string;
  bitrate: number;
  prevTimestamp: number;
  prevBytesReceived: number;
}

interface ZoomState {
  scale: number;
  initX: number;
  initY: number;
  moveX: number;
  moveY: number;
  boundryX: number;
  boundryY: number;
  dragging: boolean;
}

interface AblyState {
  options?: Ably.ClientOptions;
  client?: Ably.Realtime;
  channel?: any;
  subscription?: any;
}

@UntilDestroy()
@Component({
  selector: 'app-webrtc-player',
  templateUrl: './webrtc-player.component.html',
  styleUrls: ['./webrtc-player.component.scss'],
})
export class WebrtcPlayerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('player', { static: true }) playerObj: ElementRef;
  @ViewChild('preview') previewWrapper: ElementRef;
  @ViewChild('previewCanvas') previewCanvas: ElementRef;
  @ViewChild('qualitySelector') qualitySelector: QualitySelectorComponent;

  @Output() onStreamError = new EventEmitter(null);
  @Output() resetError = new EventEmitter(null);
  @Output() playing: EventEmitter<string> = new EventEmitter<string>();
  @Output() isRelay: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public isWallModeHeight: boolean = false;
  @Input() public isWallModeWidth: boolean = false;
  @Input() public isCoverMode: boolean = false;
  @Input() public cameraId;
  @Input() public edgeId;
  @Input() public locationId;
  @Input() public showZoomButtons = true;
  @Input() public allowZoom = true;
  @Input() public autostart = false;
  @Input() public forceRelay = false;
  @Input() public enableQualitySelection = true;
  @Input() public accessToken: string;
  @Input() public standalone = false;

  public inactive = false;

  public ably: AblyState = {};

  rtc_configuration: RTCConfiguration = {
    // iceServers: [{ urls: 'stun:stun.l.google.com:19302' },
    //   {
    //     'urls': 'turn:coturn.lumix.ai:3478?transport=udp',
    //     'credential': 'testPassword',
    //     'username': 'testUser',
    //   },
    // ],
  };

  zoomState: ZoomState = {
    scale: 1,
    initX: 0,
    initY: 0,
    moveX: 0,
    moveY: 0,
    boundryX: 0,
    boundryY: 0,
    dragging: false,
  };

  state: WebrtcPlayerState = {
    hq: false,
    pc: null,
    incomingIce: [],
    receivedOffer: false,
    configuration: {},
  };

  stats: WebrtcStats = {
    output: '',
    bitrate: 0,
    prevTimestamp: 0,
    prevBytesReceived: 0,
  };

  // Additional Flags and declarations
  origHeight = 0;
  origWidth = 0;
  ratio = 0;

  ctx: CanvasRenderingContext2D;

  loader = false;
  started = false;
  statsInterval;

  recover = true;
  qualityChange = false;

  public disableInactivity = true;
  public video;
  public developer = false;
  private _isRelay = false;

  extendInterval;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.zoomState.scale > 1 && !!this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
  }

  constructor(
    private store$: Store,
    private webRtcEffect: WebRtcEffect,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private mainMenuService: MainMenuService,
    private dialog: MatDialog,
    private webrtcService: WebrtcService,
  ) {
  }

  public getPreviewCanvas() {
    return this.previewCanvas;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['cameraId'] && this.autostart) {
      this.resetState();
      this.resetError.emit();
      this.play();
    }
  }

  async initAbly() {
    const ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false,
    };
    const channelName = `${this.edgeId}`;
    this.ably.options = ablyOptions;
    this.ably.client = new Ably.Realtime(ablyOptions); /* inferred type Ably.Realtime */
    this.ably.channel = this.ably.client.channels.get(channelName); /* inferred type Ably.Types.RealtimeChannel */

    const ably = this.ably.client;
    const channel = this.ably.channel;

    await channel.attach();
    // Getting presence on a channel
    let presenceMessage = await channel.presence.get();

    this.ably.subscription = channel.subscribe(AblyTopics[AblyTopics.WebRTCPeer], async (message) => {

      const peerData: WebRTCPeerInterface = message.data;
      if (peerData.sessionId !== this.state.sessionId) {
        return;
      }
      let timestamp = 0;
      if (peerData?.timestamp !== timestamp && peerData?.type !== undefined) {
        switch (peerData.type) {
          case WebRtcPeerType.ICE:
            if (this.state.receivedOffer) {
              await this.onIncomingICE(peerData.ice);
            } else {
              this.state.incomingIce.push(peerData.ice);
            }
            break;
          case WebRtcPeerType.SDP:

            await this.onIncomingSDP(peerData.sdp);
            break;
          default:
            switch (peerData.control) {
              case WebRTCPeerControlTypes.Close:
                this.store$.dispatch(SharedActions.showMessage({ warning: 'WebRTC connection lost' }));
                this.onError();
                break;
              default:
                break;
            }
            break;
        }
      }
    });
    await ably.connection.once('connected');
  }

  publishMsg(msg: any) {
    this.ably.channel.publish(AblyTopicsStr[AblyTopics.WebRTCPeer], JSON.stringify({ name: AblyTopics, data: msg, time: new Date().getTime() }));
  }

  ngOnInit(): void {

    this.initAbly();

    this.mainMenuService.selectIsDeveloper$.pipe(untilDestroyed(this), take(1))
      .subscribe(res => {
        this.developer = res;
      });
  }

  resetState() {
    this.state = {
      hq: this.state.hq,
      pc: null,
      incomingIce: [],
      receivedOffer: false,
    };
    this.started = false;
  }

  ngAfterViewInit(): void {
    const video = this.playerObj.nativeElement;

    this.initPlayer();

    if (this.allowZoom) {
      this.ctx = this.previewCanvas?.nativeElement?.getContext('2d');
      video.addEventListener('play', () => {
        this.timerCallback();
      });
    }

    this.onResize();
    if (this.autostart) {
      this.play();
    }
  }

  initPlayer() {
    this.video = this.playerObj.nativeElement as HTMLVideoElement;
    this.video.muted = true;
    this.video.controls = false;
  }

  public get peerDataBase() {
    return {
      locationId: this.locationId,
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      timestamp: new Date().getTime(),
      sessionId: this.state.sessionId,
    };
  }

  public start() {
    const rtcConfiguration = this.state.configuration;
    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Start,
      rtcConfiguration,
      hq: this.state.hq,
    };
    this.publishMsg(peerData);
  }

  public extend() {
    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Extend,
    };
    this.publishMsg(peerData);

  }

  public stop(inactive?: boolean) {

    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Stop,
    };
    this.publishMsg(peerData);

    if (this.state.sessionSubscription) {
      this.state.sessionSubscription.unsubscribe();
    }
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
    this.state?.pc?.close();
    this.resetState();
    if (inactive) {
      this.inactive = true;
    }
  }

  changeQuality(quality: Quality) {
    this.qualityChange = true;
    this.stop();
    if (quality === Quality.SQ) {
      this.state.hq = false;
    } else {
      this.state.hq = true;
    }
    this.play();
  }

  public async play() {
    this.inactive = false;
    if (this.standalone) {
      this.loader = true;
      if (this.extendInterval) {
        clearInterval(this.extendInterval);
      }
      this.extendInterval = setInterval(() => {
        this.extend();
      }, DEFAULT_EXTEND_TIME);
    }
    const createToken = uuid.v4();
    let timestamp = 0;

    // Call get credentials

    const res = await lastValueFrom(this.webrtcService.getCredentials(this.accessToken));
    this.state.configuration = res;
    this.rtc_configuration = res;
    this.state.sessionId = uuid.v4();
    this.start();
    await this.createCall();
  }

  public zoomIn() {
    this.zoom({ deltaY: -1 });
    this.computeFrame();
  }

  public zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  public zoomArea(area: UiAreaSelection) {
    if (!area?.start?.x || !area?.start?.y || !area?.end?.x || !area?.end?.y) {
      return;
    }
    this.initZoom();
    const wrapper = this.wrapper.nativeElement;
    const topLeft: Point = {
      x: Math.min(area.start.x, area.end.x),
      y: Math.min(area.start.y, area.end.y),
    };
    const width = Math.abs(area.start.x - area.end.x);
    const height = this.ratio * width;
    if (width <= 0 || height <= 0) {
      return;
    }
    this.zoomState.scale = wrapper.clientWidth / width;
    const elem = this.playerObj.nativeElement;

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale})`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    this.zoomState.moveX = this.zoomState.boundryX - topLeft.x;
    this.zoomState.moveY = this.zoomState.boundryY - topLeft.y;

    this.zoom(undefined, true);
  }

  calcBoundaries() {
    const elem = this.playerObj.nativeElement;
    const height = this.origHeight * this.zoomState.scale;
    const width = this.origWidth * this.zoomState.scale;
    this.zoomState.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.zoomState.scale : 0;
    this.zoomState.boundryY = (height - elem.clientHeight) / 2 / this.zoomState.scale;
  }

  public initZoom() {
    const elem = this.playerObj.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }

  public zoom(event, renderOnly = false) {
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj.nativeElement;
    if (!renderOnly) {
      if (event.deltaY < 0) {
        if (this.zoomState.scale < 5) {
          this.zoomState.scale += 0.2;
        } else {
          this.zoomState.scale = 5;
        }
        this.onResize();
        this.cd.detectChanges();
      } else {
        if (this.zoomState.scale >= 1.2) {
          this.zoomState.scale -= 0.2;
        } else {
          this.zoomState.scale = 1;
        }
      }
    }
    if (this.zoomState.scale === 1) {
      this.zoomState.moveX = 0;
      this.zoomState.moveY = 0;
      this.zoomState.boundryX = 0;
      this.zoomState.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.zoomState.moveX);
    const moveYDir = Math.sign(this.zoomState.moveY);
    this.zoomState.moveX = Math.abs(this.zoomState.moveX) > this.zoomState.boundryX ? this.zoomState.boundryX * moveXDir : this.zoomState.moveX;
    this.zoomState.moveY = Math.abs(this.zoomState.moveY) > this.zoomState.boundryY ? this.zoomState.boundryY * moveYDir : this.zoomState.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  dragStart(event: MouseEvent) {
    this.zoomState.dragging = true;
    this.zoomState.initX = event.clientX - this.zoomState.moveX;
    this.zoomState.initY = event.clientY - this.zoomState.moveY;
  }

  drag(event: MouseEvent) {
    if (this.zoomState.scale === 1 || !this.zoomState.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    this.zoomState.moveX += Math.abs(this.zoomState.moveX + event.movementX) >= this.zoomState.boundryX ? 0 : event.movementX;
    this.zoomState.moveY += Math.abs(this.zoomState.moveY + event.movementY) >= this.zoomState.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  timerCallback() {
    if (this.playerObj.nativeElement.paused || this.playerObj.nativeElement.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 0);
  }

  computeFrame() {
    this.ctx.drawImage(this.playerObj.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
  }

  public inZoom() {
    return this.zoomState.scale > 1;
  }

  calculateBitrate(timestamp: number, bytesReceived: number) {

    if (!!this.stats.prevTimestamp && !!this.stats.prevBytesReceived) {
      const bitrate = 8 * (bytesReceived - this.stats.prevBytesReceived) / (timestamp - this.stats.prevTimestamp);
      this.stats.bitrate = Math.max(0, bitrate); // ((bytesReceived - this.prevBytesReceived) / 128) / ((timestamp - this.prevTimestamp) / 1000);

      // Smoothing bitrate - deprecated
      // if (this.savedBitrate.length < 5) {
      //   this.stats.bitrate = bitrate;
      //   this.savedBitrate.push(bitrate);
      // } else {
      //   const avg = _.mean(this.savedBitrate);
      //   if (bitrate > avg * 1.2 || bitrate < avg * 0.8) {
      //     this.stats.bitrate = bitrate;
      //     this.savedBitrate = [];
      //   } else {
      //     this.savedBitrate.shift();
      //     this.savedBitrate.push(bitrate);
      //     this.stats.bitrate = avg;
      //   }
      // }
    }

    this.stats.prevTimestamp = timestamp;
    this.stats.prevBytesReceived = bytesReceived;
  }

  public resetZoom() {
    this.zoomState.scale = 1;
    this.zoom(undefined, true);
  }

  initStats() {
    this.statsInterval = setInterval(() => {
      this.state.pc?.getStats(null)
        .then((stats) => {
          this.stats.output = '';

          if (this.state.stream) {
            this.stats.output += `<h2>Stream settings</h2>\n`;
            if (this.state.stream.getVideoTracks()[0]) {
              const settings = this.state.stream.getVideoTracks()[0]?.getSettings();
              if (settings) {
                for(let [key, value] of Object.entries(settings)) {
                  this.stats.output += `${key}: ${value}<br>\n`;
                }
              }
            }
          }
          let activeCandidatePair;
          stats.forEach(report => {
            if (report.type === 'transport') {
              activeCandidatePair = stats.get(report.selectedCandidatePairId);
            }
          });

          // Firefox workaround
          if (!activeCandidatePair) {
            stats.forEach(report => {
              if (report.type === 'candidate-pair' && report.selected) {
                activeCandidatePair = report;
              }
            });
          }
          if (!!activeCandidatePair) {
            const localCandidate = stats.get(activeCandidatePair.localCandidateId);
            if (localCandidate.candidateType === 'relay') {
              // Stream is not local - enable inactivity feature
              this.disableInactivity = false;
              if (this._isRelay !== true) {
                this._isRelay = true;
                this.isRelay.emit(true);
              }
            } else {
              // Stream is local - disable inactivity feature
              this.disableInactivity = true;
              if (this._isRelay !== false) {
                this._isRelay = false;
                this.isRelay.emit(false);
              }
            }
          }

          stats.forEach((report) => {

            if (report.type === 'inbound-rtp' && report.kind === 'video') {
              this.calculateBitrate(report.timestamp, report.bytesReceived);
            }

            this.stats.output +=
              `<h2>Report: ${report.type}</h2>\n<strong>ID:</strong> ${report.id}<br>\n` +
              `<strong>Timestamp:</strong> ${report.timestamp}<br>\n`;

            // Now the statistics for this report; we intentionally drop the ones we
            // sorted to the top above

            Object.keys(report)
              .forEach((statName) => {
                if (
                  statName !== 'id' &&
                  statName !== 'timestamp' &&
                  statName !== 'type'
                ) {
                  this.stats.output += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
                }
              });
          });
        });
    }, 1000);
  }

  public get isConnected() {
    return this.state?.pc?.connectionState === 'connected';
  }

  async createCall() {
    const constraints = { audio: true, video: true };
    this.state.pc = new RTCPeerConnection(this.rtc_configuration);

    this.state.pc.ontrack = ({ track, streams }) => {
      track.onunmute = () => {
        // if (this.video.srcObject) {
        //   return;
        // }
        this.state.stream = streams[0];
        this.video.srcObject = streams[0];
        this.video.play();
      };
    };

    this.state.pc.onconnectionstatechange = (ev) => {
      const prefix = `[WebRTC][${this.state.sessionId}] `;
      switch (this?.state?.pc?.connectionState) {
        case 'checking':
        case 'connecting':
        case 'new':
          break;
        case 'connected':
          this.playing.emit(null);
          this.started = true;
          this.loader = false;
          this.qualityChange = false;
          if (!!this.qualitySelector) {
            this.qualitySelector.loading = false;
          }
          break;
        case 'disconnected':
          this.onError(true);
          break;
        case 'closed':
          this.onError();
          break;
        case 'failed':
          this.onError();
          break;
        default:
          break;
      }
    };

    this.state.pc.oniceconnectionstatechange = () => {
      if (this?.state?.pc?.iceConnectionState === 'failed') {
        // this.state.pc.restartIce();
        this.onError();
      }
    };

    this.state.pc.onicecandidate = (event) => {
      if (event.candidate == null) {
        return;
      }
      const peerData = {
        ...this.peerDataBase,
        type: WebRtcPeerType.ICE,
        ice: event.candidate.toJSON(),
      };
      this.publishMsg(peerData);
      // this.store$.dispatch(WebRtcActions.SendWebRtcICE({
      //   peerData: {
      //     ...this.peerDataBase,
      //     type: WebRtcPeerType.ICE,
      //     ice: event.candidate.toJSON(),
      //   },
      // }));
    };


    this.initStats();
  }

  onError(recover = true) {
    this.onStreamError.emit();
    if (this.state.sessionSubscription) {
      this.state.sessionSubscription.unsubscribe();
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
    this.state?.pc?.close();
    if (this.video.srcObject) {
      delete this.video.srcObject;
      delete this.video.stream;
    }
    this.resetState();
    if (this.recover) {
      this.resetError.emit();
      this.play();
    }
  }

  async onIncomingSDP(sdp) {
    if (sdp.type === 'offer') {
      await this.state.pc.setRemoteDescription(sdp);
      this.state.receivedOffer = true;
      await this.state.pc.setLocalDescription();
      for(let ice of this.state.incomingIce) {
        await this.onIncomingICE(ice);
      }
      this.state.incomingIce = [];
      const peerData = {
        locationId: this.locationId,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        timestamp: new Date().getTime(),
        sessionId: this.state.sessionId,
        type: WebRtcPeerType.SDP,
        sdp: this.state.pc.localDescription.toJSON(),
      };
      this.publishMsg(peerData);
      // this.store$.dispatch(WebRtcActions.SendWebRtcSDP({
      //   peerData: {
      //     locationId: this.locationId,
      //     edgeId: this.edgeId,
      //     cameraId: this.cameraId,
      //     timestamp: new Date().getTime(),
      //     sessionId: this.state.sessionId,
      //     type: WebRtcPeerType.SDP,
      //     sdp: this.state.pc.localDescription.toJSON(),
      //   },
      // }));
    }

  };


  async onIncomingICE(ice) {
    // let candidate = new RTCIceCandidate(ice);
    try {
      await this.state.pc.addIceCandidate(ice);
    } catch (err) {
      this.onError();
    }
  };

  public takeSnapshot(cameraName: string, locationName: string, ts?: number) {
    let downloadLink = document.createElement('a');
    const time = ts ? new Date(ts).toTimeString() : new Date().toTimeString();
    const playerObj = this.playerObj.nativeElement;
    this.ratio = playerObj.videoWidth / playerObj.videoHeight;
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    let canvas = document.createElement('canvas');
    const width = Math.max(this.origWidth, playerObj.videoWidth);
    const height = Math.max(this.origHeight, playerObj.videoHeight);
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')
      .drawImage(playerObj, 0, 0, width, height);
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(15, 15, 360, 100);
    ctx.font = '25px Arial';
    ctx.fillStyle = '#ffffff';
    ctx.fillText(cameraName, 30, 50);
    ctx.font = '15px Arial';
    ctx.fillText(locationName, 30, 75);
    ctx.fillText(time, 30, 100);
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('download', `[Lumana] ${cameraName} ${time}.png`);
      downloadLink.click();
      downloadLink.remove();
      canvas.remove();
    });
  }

  destroyAbly() {
    if (this.ably.subscription) {
      this.ably.subscription.unsubscribe();
    }
    this.ably.client.close();
  }

  public ngOnDestroy(): void {
    if (this.state.sessionId) {
      this.stop();
    }
    if (!!this.state.pc) {
      this.state?.pc?.close();
      this.resetState();
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
    this.destroyAbly();
  }

  public get isPlaying(): boolean {
    const video = this.video;
    return this.inactive || this.loader || !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }

  public openStats() {
    const data: WebrtcDebugDialogData = {
      stats: this.stats,
    };
    this.dialog.open(WebrtcDebugDialogComponent, {
      panelClass: 'modal-no-padding',
      width: '90vw',
      height: '90vh',
      data,
    });
  }
}

