import { GrantedAccessModel } from '@models/granted-access-model';
import { GrantedAccessType } from '@enums/granted-access.enum';

export const initialGrantedAccessRequest: GrantedAccessModel.CreateGrantedAccessResponse = {
  type: null,
  expired_at: null,
  entityId: null,
  emails: [],
  message: null,
  name: null,
  phones: [],
  allowDownload: false,
  isPassword: false,
  password: null,
  notifyEnabled: false,
  views: 0,
  accessToken: '',
  url: '',
  accessPeriod: 24 * 7,
  confirmPassword: null,
};
// export const initialGrantedAccessRequest: GrantedAccessModelRequest = {
//   entityId: null,
//   isPassword: false,
//   accessPeriod: 1,
//   password: '',
//   confirmPassword: '',
//   message: '',
//   emails: [],
//   phones: [],
//   notifyEnabled: false,
//   allowDownload: true,
//   name: '',

// };


export const grantedAccessTypeMap: { [Property in GrantedAccessType] } = {
  [GrantedAccessType.ARCHIVE]: 'Archive',
  [GrantedAccessType.ALERT]: 'Alert',
  [GrantedAccessType.PLAYBACK]: 'Playback',
  [GrantedAccessType.LIVE_VIEW]: 'Live View',
  [GrantedAccessType.WALL]: 'Wall',
};


export const grantedAccessStatusMap = {
  [1]: 'Available',
  [0]: 'Expired',
};
