import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IntlTelInputComponent } from 'intl-tel-input-ng';

@Component({
  selector: 'ui-phone-input',
  templateUrl: './ui-phone-input.component.html',
  styleUrls: ['./ui-phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiPhoneInputComponent),
      multi: true,
    },
  ],
})
export class UiPhoneInputComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('tell') telInput: IntlTelInputComponent;
  @Input() isVerified: boolean;
  @Input() inputClass: string = '';

  constructor(private renderer: Renderer2) {
  }


  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }


  public value: string;

  onChange: any = (val) => {
  };
  onTouched: any = () => {
  };


  // This is the initial value set to our component
  writeValue(value: any) {
    this.value = value;
    if (this.value && this.telInput) {
      this.telInput.phoneNumber = this.value;
    }
  }

  public ngAfterViewInit() {
    if (this.value) {
      this.telInput.phoneNumber = this.value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public updateValue() {
    if (this.value) {
      this.onChange(this.value);
      this.onTouched();
    }
  }

  public get valid(): boolean {
    return !!this.value;
  }


}
