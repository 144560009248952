import { edgeEditMenuLevel2Validation } from '@consts/menu.const';
import { createReducer, on } from '@ngrx/store';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { Edge } from '../../../edge/edge.model';
import { HeartbeatEntry } from '../../../development/heartbeat.service';
import { ThumbnailEntity } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';

export declare interface EdgeEditState {
  level2MenuValid: { [key: string]: boolean };
  // will need these when routing will be fixed.
  selectedLocationId: string;
  selectedEdgeId: string;
  eth0: string;
  eth1: string;
  selectedEdge: Edge.EdgeDocument; //consist Location Id and edge Id,
  sendUpdate: boolean;
  logs: string[];
  heartbeats: HeartbeatEntry[];
  systemStatus: [];
  systemStatusDateFrom: number;
  systemStatusDateTo: number;
  systemStatuPaginationTimeStamps: number;
  heartBeatsDateFrom: number;
  heartBeatsDateTo: number;
  thumbnailsBitmap: ThumbnailEntity[];
  heartBeatsPaginationTimeStamps: number;
  hasChanged: boolean;
  edgeRecoveryDocument: any;
  edgeHeartBeatStatus: any[];
}

export const initialState: EdgeEditState = {
  level2MenuValid: edgeEditMenuLevel2Validation,
  selectedLocationId: null,
  selectedEdgeId: null,
  eth0: null,
  eth1: null,
  selectedEdge: null,
  sendUpdate: null,
  logs: null,
  heartbeats: null,
  systemStatus: null,
  systemStatusDateFrom: null,
  systemStatusDateTo: null,
  systemStatuPaginationTimeStamps: null,
  heartBeatsDateFrom: null,
  heartBeatsDateTo: null,
  thumbnailsBitmap: null,
  heartBeatsPaginationTimeStamps: null,
  hasChanged: false,
  edgeRecoveryDocument: null,
  edgeHeartBeatStatus: null,
};

export const edgeEditStateReducer = createReducer(
  initialState,
  on(EdgeEditActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(EdgeEditActions.setSelectedLocationId, (state, { selectedLocationId }) => {
    return {
      ...state,
      selectedLocationId,
    };
  }),
  on(EdgeEditActions.setSelectedEdgeId, (state, { selectedEdgeId }) => {
    return {
      ...state,
      selectedEdgeId,
    };
  }),
  on(EdgeEditActions.setEth0, (state, { eth0 }) => {
    return {
      ...state,
      eth0,
    };
  }),
  on(EdgeEditActions.setEth1, (state, { eth1 }) => {
    return {
      ...state,
      eth1,
    };
  }),
  on(EdgeEditActions.setSelectedEdge, (state, { selectedEdge }) => {
    return {
      ...state,
      selectedEdge,
    };
  }),
  on(EdgeEditActions.setSendUpdate, (state, { sendUpdate }) => {
    return {
      ...state,
      sendUpdate,
    };
  }),
  on(EdgeEditActions.setEdgeAWSLogs, (state, { logs }) => {
    return {
      ...state,
      logs,
    };
  }),
  on(EdgeEditActions.getHeartbeatsSuccess, (state, { heartbeats }) => {
    return {
      ...state,
      heartbeats: state.heartbeats ? state.heartbeats.concat(heartbeats) : [].concat(heartbeats),
    };
  }),
  on(EdgeEditActions.getCameraThumbnailsSuccess, (state, { thumbnailsBitmap }) => {
    return {
      ...state,
      thumbnailsBitmap,
    };
  }),
  on(EdgeEditActions.setHeartbeatsDateRAnge, (state, { from, to }) => {
    return {
      ...state,
      heartBeatsDateFrom: from,
      heartBeatsDateTo: to,
      heartBeatsPaginationTimeStamps: initialState.heartBeatsPaginationTimeStamps,
      heartbeats: initialState.heartbeats,
    };
  }),
  on(EdgeEditActions.setEdgeSystemStatusPagination, state => {
    return {
      ...state,
      //@ts-ignore
      systemStatuPaginationTimeStamps: parseInt(state.systemStatus[state.systemStatus.length - 1].timestamp),
    };
  }),
  on(EdgeEditActions.setHasChanged, (state, { hasChanged }) => {
    return {
      ...state,
      hasChanged,
    };
  }),
  on(EdgeEditActions.getCoreRecoveryDocumentSuccess, (state, { edgeRecoveryDocument }) => {
    return {
      ...state,
      edgeRecoveryDocument,
    };
  }),
  on(EdgeEditActions.getEdgeHeartBeatStatusSuccess, (state, { body }) => {
    return {
      ...state,
      edgeHeartBeatStatus: body,
    };
  }),
);
