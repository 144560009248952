import { createAction, props } from '@ngrx/store';
import { FloorModel } from '@models/floor.model';
import { GroupModels } from '@models/people.model';

export const resetToInitialState = createAction('[FLOOR] Reset to Initial state');

export const getBuildings = createAction('[FLOOR] Get Buildings');
export const getBuildingsSuccess = createAction('[FLOOR] Get Buildings Success', props<{ documents: FloorModel.BuildingDocument[] }>());
export const getBuildingsFail = createAction('[FLOOR] Get Buildings Fail');

export const setQueryStringFilter = createAction('[FLOOR] Set Query String Filter', props<{ prop: string; value: string }>());
export const resetEntities = createAction('[FLOOR] Reset Entities');

export const startSaveBuilding = createAction('[FLOOR] Start Save Building', props<{ building: Partial<FloorModel.BuildingDocument>, addFloor?: boolean }>());
export const saveBuildingRequest = createAction('[FLOOR] Save Building Request', props<{ building: Partial<FloorModel.BuildingDocument>, addFloor?: boolean }>());
export const saveBuildingRequestSuccess = createAction('[FLOOR] Save Building Request Success', props<{ building: FloorModel.BuildingDocument, addFloor?: boolean }>());
export const saveBuildingRequestFail = createAction('[FLOOR] Save Building Request Fail');

export const deleteBuilding = createAction('[FLOOR] Start Delete Building', props<{ buildingId: string }>());
export const deleteBuildingRequest = createAction('[FLOOR] Delete Building Request', props<{ buildingId: string }>());
export const deleteBuildingRequestSuccess = createAction('[FLOOR] Delete Building Request Success', props<{ buildingId: string }>());
export const deleteBuildingRequestFail = createAction('[FLOOR] Delete Building Request Fail');

export const deleteFloor = createAction('[FLOOR] Start Delete Floor', props<{ buildingId: string, floorId: string }>());
export const deleteFloorRequest = createAction('[FLOOR] Delete Floor Request', props<{ buildingId: string, floorId: string }>());
export const deleteFloorRequestSuccess = createAction('[FLOOR] Delete Floor Request Success', props<{ buildingId: string, floorId: string }>());
export const deleteFloorRequestFail = createAction('[FLOOR] Delete Floor Request Fail');

export const editFloor = createAction('[FLOOR] Start Edit Floor', props<{ buildingId: string, floorId: string, floor: FloorModel.Floor }>());
export const editFloorRequest = createAction('[FLOOR] Edit Floor Request', props<{ buildingId: string, floorId: string, floor: FloorModel.Floor }>());
export const editFloorRequestSuccess = createAction('[FLOOR] Edit Floor Request Success', props<{ buildingId: string, floorId: string, floor: FloorModel.Floor }>());
export const editFloorRequestFail = createAction('[FLOOR] Edit Floor Request Fail');

export const createFloor = createAction('[FLOOR] Start Create Floor', props<{ buildingId: string, floor: FloorModel.Floor }>());
export const createFloorRequest = createAction('[FLOOR] Create Floor Request', props<{ buildingId: string, floor: FloorModel.Floor }>());
export const createFloorRequestSuccess = createAction('[FLOOR] Create Floor Request Success', props<{ buildingId: string, floor: FloorModel.Floor }>());
export const createFloorRequestFail = createAction('[FLOOR] Create Floor Request Fail');


export const setIsSaving = createAction('[FLOOR] Set Is Saving', props<{ isSaving: boolean }>());

export const saveBuildingSetOne = createAction('[FLOOR] Save Building Set One ', props<{ document: FloorModel.BuildingDocument }>());


export const UploadImages = createAction(
  '[FLOOR] Upload Images',
  props<{
    request: GroupModels.FileAsset[]
  }>(),
);

export const UploadImagesSend = createAction(
  '[FLOOR] Upload Images Send',
  props<{
    request: GroupModels.FileAsset[]
  }>(),
);

export const UploadImagesSendSuccess = createAction(
  '[FLOOR] Upload Images Send Success',
  props<{
    response: any
  }>(),
);

export const UploadImagesSendError = createAction(
  '[FLOOR] Upload Images Send Error',
);
