import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-avatar',
  templateUrl: './ui-avatar.component.html',
  styleUrls: ['./ui-avatar.component.scss'],
})
export class UiAvatarComponent implements OnInit {
  @Input() image: string;
  @Input() name: string;
  @Input() color: string;
  @Input() fontSize: number = 14;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public onImageError(): void {
    this.image = null;
  }
}
