import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { SQSMsgInfo } from '../core/interfaces';
import { SelectedCamera, VariableConfig, VariableConfigDocument, VariableSendModel } from '@models/variable.model';
import { VariableType } from '@enums/variable.enum';

@Injectable()
export class VariableService {
  constructor(private http: HttpClient) {}

  public createVariable(Variable: VariableSendModel): Observable<SQSMsgInfo[]> {
    return this.http.post<SQSMsgInfo[]>(api.variables.crud, Variable);
  }

  public updateVariable(id: string, Variable: VariableSendModel): Observable<SQSMsgInfo> {
    return this.http.put<SQSMsgInfo>(`${api.variables.crud}/${id}`, Variable);
  }

  public getVariables(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string
  ): Observable<PaginationResult<VariableConfig>> {
    let url = `${api.variables.crud}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<PaginationResult<VariableConfig>>(url);
  }

  public getSelectedVariable(id: string): Observable<VariableConfig> {
    return this.http.get<VariableSendModel>(api.variables.one(id));
  }

  public remove(id: string, selectedCameraParams?: SelectedCamera): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.variables.delete(id), selectedCameraParams);
  }

  public enable(id: string, enabled: boolean): Observable<any> {
    return this.http.put(api.variables.enable(id), { enabled });
  }

  public sync(id: string, alert: VariableSendModel): Observable<any> {
    return this.http.post(api.variables.sync(id), alert);
  }

  /**
   *
   * @param query
   * @param notType - which type should not be included to list
   */
  public autocomplete(
    query: string,
    notType: VariableType,
    cameraId?: string,
    variableType?: VariableType
  ): Observable<{ _id: string; name: string }[]> {
    return this.http.post<{ _id: string; name: string }[]>(api.variables.autocomplete, {
      query,
      notType,
      cameraId,
      variableType,
    });
  }
}
