<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="step-range-selector"
  [class.disable-state]="isDisabled"
>
  <div class="flex-row flex space-around-center wrapper">
    <div fxLayoutAlign="center center" (click)="decrement()">
      <span class="ui-icon-minus"></span>
    </div>
    <input #input class="number-input"
           size=""
           type="number"
           [(ngModel)]="value"
           [placeholder]="0"
           (input)="changeValue()"
    >
    <!--    <div *ngIf="!value && value !==0" class="c-p">0</div>-->
    <div fxLayoutAlign="center center" (click)="increment()">
      <span class="ui-icon-plus"></span>
    </div>
  </div>
  <div *ngIf="unit" class="flex-row center-center unit">
    {{unit | titlecase}}
  </div>
</div>
