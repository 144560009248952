import { Component, Inject, OnInit } from '@angular/core';
import { DashboardModel } from '@models/dashboard.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThumbnailTemplate } from '../../../../shared/thumbnail/thumbnail.component';

export interface WidgetDataInfoDialogData {
  search?: DashboardModel.SearchLinkObject;
  alert?: DashboardModel.AlertLinkObject;
};

@Component({
  selector: 'widget-data-info-dialog',
  templateUrl: './widget-data-info-dialog.component.html',
  styleUrl: './widget-data-info-dialog.component.scss',
})
export class WidgetDataInfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WidgetDataInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetDataInfoDialogData,
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  protected readonly ThumbnailTemplate = ThumbnailTemplate;
}
