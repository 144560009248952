import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedEffects } from '@effects/shared.effects';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';

@UntilDestroy()
@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
/**
 * @deprecated
 */
export class ProfileSettingsComponent implements OnInit {
  public timeZones = moment.tz.names();
  public currentTimezone = moment.tz.guess();

  public selectUserTimezone$: Observable<string> = this.store$.select(UserSelectors.selectUserTimezone);

  constructor(private store$: Store<AppState>, private sharedEffects$: SharedEffects) {}

  public ngOnInit(): void {
    this.selectUserTimezone$.pipe(untilDestroyed(this)).subscribe(res => {
      this.currentTimezone = res;
    });

    this.sharedEffects$.pressSave$.pipe(untilDestroyed(this)).subscribe(() => {
      this.save();
    });
  }

  public setTimezone(timezone: string): void {
    this.store$.dispatch(UserActions.setTimezone({ timezone }));
  }

  public save(): void {
    this.store$.dispatch(UserActions.updateUser());
  }
}
