import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebRtcState, selectAll } from '@states/webrtc/webrtc.reducer';

export const selectWebRtcState = createFeatureSelector<WebRtcState>('webRtcSessionsState');
export const selectAllSessions = createSelector(selectWebRtcState, selectAll);

export const selectSessionById = (sessionId: string) =>
  createSelector(selectWebRtcState, (sessions: WebRtcState) => sessions.entities[sessionId]);

export const selectSessionsLookup = createSelector(selectWebRtcState, ({ entities }: WebRtcState) => entities);

export const selectMediasoup = createSelector(selectWebRtcState, ({ mediasoup }: WebRtcState) => mediasoup);


export const selectSessionByCameraId = (cameraId: string) =>
  createSelector(selectAllSessions, sessions => sessions.filter(session => session.cameraId?.toString() === sessions.toString()));
