import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';
import { SmartStorageHeartbeatPulsationActions } from './smart-storage-heartbeat-pulsation-types';

export interface SmartStoragePulsationState extends EntityState<PulsationModels.Store.SmartStoragePulsation> {
  message?: string;
  error?: string;
}

export const adapter: EntityAdapter<PulsationModels.Store.SmartStoragePulsation> = createEntityAdapter<PulsationModels.Store.SmartStoragePulsation>({
  selectId: (status: PulsationModels.Store.SmartStoragePulsation) => status.cameraId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: SmartStoragePulsationState = adapter.getInitialState({});

export const smartStoragePulsationStatusReducer = createReducer(
  initialState,

  on(SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess, (state, action) => {
    return adapter.setMany(action.payload, state);
  }),

  on(SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  })
);
