import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SmartStoragePulsationState } from './smart-storage-heartbeat-pulsation.reducer';
import { PulsationModels } from '@models/pulsation.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { entityStatusByEdgeStatus } from '../../../helpers/common.helpers';
import { selectEdgeStatus } from '@states/device-status/device-status.selectors';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;

export const selectSmartStorageStatusState = createFeatureSelector<SmartStoragePulsationState>('smartStoragePulsationState');

export const selectSmartStorageStatusByCameraId = (cameraId: string) =>
  createSelector(selectEdgeStatus, selectSmartStorageStatusState, (edges: Dictionary<ComponentStatusDisplay>, cameras: SmartStoragePulsationState) => {
    return entityStatusByEdgeStatus(cameraId, edges, cameras);
  });

export const selectSmartStoragePulsationByCameraId = (cameraId: string) =>
  createSelector(selectSmartStorageStatusState, (cameras: SmartStoragePulsationState) => {
    return cameras.entities[cameraId];
  });

export const selectSmartStorageEntities = createSelector(selectSmartStorageStatusState, selectEdgeStatus, (cameras: SmartStoragePulsationState, edges: Dictionary<ComponentStatusDisplay>) => {
  const cameraEntitiesIds = Object.keys(cameras.entities);
  const cameraStatusesWithEdgeStatuses: Dictionary<PulsationModels.Store.CameraPulsation> = {};
  cameraEntitiesIds.forEach(cameraId => {
    cameraStatusesWithEdgeStatuses[cameraId] = {
      ...cameras.entities[cameraId],
      status: entityStatusByEdgeStatus(cameraId, edges, cameras),
    };
  });
  return cameraStatusesWithEdgeStatuses;
});
