import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { WbRTCActiveSession } from '@models/webrtc.model';
import { WebRtcActiveSessionActions } from '@states/webrtc-active-sessions/webrtc-active-sessions.action-types';

export interface WebrtcActiveSessionState extends EntityState<WbRTCActiveSession> {

}

export const adapter: EntityAdapter<WbRTCActiveSession> = createEntityAdapter<WbRTCActiveSession>({
  selectId: (state: WbRTCActiveSession) => state.id,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState = adapter.getInitialState({});

export const webRTCActiveSessionReducer = createReducer(
  initialState,
  on(WebRtcActiveSessionActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(WebRtcActiveSessionActions.setActiveSessionSuccess, (state, { edgeId, cameraId, sessionState, isLocal, unmanaged }) => {
    const activeSession: WbRTCActiveSession = {
      id: unmanaged ? `${edgeId}:${cameraId}:unmanaged` : `${edgeId}:${cameraId}`,
      edgeId,
      cameraId,
      sessionId: sessionState.sessionId,
      timestamp: Date.now(),
      isLocal,
    };
    return adapter.upsertOne(activeSession, {
      ...state,
      isFirstLoaded: true,
    });
  }),
  on(WebRtcActiveSessionActions.updateTimestamp, (state, { edgeId, cameraId }) => {
    const update: Update<WbRTCActiveSession> = {
      id: `${edgeId}:${cameraId}`,
      changes: {
        timestamp: Date.now(),
      },
    };
    return adapter.updateOne(update, {
      ...state,
      isFirstLoaded: true,
    });
  }),
  on(WebRtcActiveSessionActions.updateSessionLocal, (state, { edgeId, cameraId, isLocal }) => {
    const update: Update<WbRTCActiveSession> = {
      id: `${edgeId}:${cameraId}`,
      changes: {
        isLocal,
      },
    };
    return adapter.updateOne(update, {
      ...state,
      isFirstLoaded: true,
    });
  }),

  on(WebRtcActiveSessionActions.deleteActiveSession, (state, { edgeId, cameraId }) => {
    const id = `${edgeId}:${cameraId}`;
    return adapter.removeOne(id, {
      ...state,
      isFirstLoaded: true,
    });
  }),
);

