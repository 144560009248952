import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'people',
})
export class PeoplePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (value === 0) {
      return 'none';
    }
    switch (value) {
      case 1:
        return '1 person';
      default:
        return `${value} people`;
    }
  }
}
