import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringComponent } from './monitoring.component';
import { MonitoringViewComponent } from './pages/monitoring-view/monitoring-view.component';
import { RouterModule } from '@angular/router';
import { UiKitModule } from '../../shared/ui-kit/ui-kit.module';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ViewListComponent } from './pages/view-list/view-list.component';
import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from '../../pipes/pipes.module';
import { AlertPreviewComponent } from './components/alert-preview/alert-preview.component';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../shared/shared.module';
import { AuthorizationModule } from '../../authorization/authorization.module';
import { RbacOrgAllowDirective } from '../../authorization/rbac-org-allow.directive';
import { UiCameraStatusComponent } from '../../shared/ui-kit/ui-camera-status/ui-camera-status.component';


@NgModule({
  declarations: [
    MonitoringComponent,
    MonitoringViewComponent,
    ViewListComponent,
    AlertPreviewComponent,
  ],
  imports: [
    CommonModule,
    MonitoringRoutingModule,
    RouterModule,
    UiKitModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    LetDirective, PushPipe,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    ScrollingModule,
    PipesModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatRippleModule,
    SharedModule, AuthorizationModule, RbacOrgAllowDirective, UiCameraStatusComponent,
  ],
  exports: [
    MonitoringViewComponent,
  ],
})
export class MonitoringModule {
}
