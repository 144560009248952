import { Injectable } from '@angular/core';
import { GrantedAccessService } from '../../development/granted-access.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import * as GrantedAccessAction from '@states/granted-access/granted-access.actions';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { withLatestFrom } from 'rxjs/operators';
import { validateEmail, validatePhone } from '../../helpers/common.helpers';
import { HttpStatusCode } from '@angular/common/http';
import { CommentService } from '../../services/comment.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { AlertEntry } from '../../development/alerts.service';
import { Archive } from '@models/archive.model';
import ArchiveDocument = Archive.ArchiveDocument;
import { GrantedAccessModel } from '@models/granted-access-model';
import AlertParams = GrantedAccessModel.AlertParams;
import { LocationsService } from '../../locations/locations.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { getCameraRetentionDays } from '@states/granted-access/granted-access.actions';
import { StatsService } from '../../development/stats.service';
import { EdgeCamera } from '../../cameras/camera.model';
import { CameraActions } from '@states/camera/camera.action-types';


@Injectable()
export class GrantedAccessEffect {
  public checkGrantedAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.checkGrantedAccess),
      switchMap(({ id, body }) =>
        this.grantedAccessService.getOne(id, body)
          .pipe(
            switchMap(res => {

              if (res.authProviderId) {
                this.authenticationService.authenticateGuestToFirebase({ userId: res.authProviderId }, res.accessToken);
              }

              if (!!res?.accessToken) {
                this.authenticationService.storeShareAccessTokenSession(res.accessToken);
              }

              let edgeId;
              switch (res.type) {
                case GrantedAccessType.LIVE_VIEW:
                  edgeId = (res.entity as GrantedAccessModel.SharedLiveView).edgeId;
                  break;
                case GrantedAccessType.PLAYBACK:
                  edgeId = (res.entity as GrantedAccessModel.SharedPlayback).edgeId;
                  break;
                case GrantedAccessType.ARCHIVE:
                  edgeId = (res.entity as ArchiveDocument).edgeId;
                  break;
                case GrantedAccessType.ALERT:
                  edgeId = (res.entity as GrantedAccessModel.SharedAlert).edgeId;
                  break;
              }

              const actions: Action[] = [
                /**
                 * Type must be set in the beginning, before initial loading is completed
                 */

                GrantedAccessAction.setSelectedType({ selectedType: res.type }),
                GrantedAccessAction.getLocationByEdgeId({ edgeId }),
              ];

              // if (!res.entity) {
              //   actions.push(GrantedAccessAction.setIsRecordRemoved({ isRecordRemoved: true }));
              // }
              switch (res.type) {
                case GrantedAccessType.LIVE_VIEW:
                  actions.push(
                    GrantedAccessAction.setSharedLiveView({ sharedLiveView: res.entity as GrantedAccessModel.SharedLiveView }),
                  );
                  actions.push(
                    GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                  );
                  break;
                case GrantedAccessType.PLAYBACK:
                  actions.push(
                    GrantedAccessAction.setSharedPlayback({ sharedPlayback: res.entity as GrantedAccessModel.SharedPlayback }),
                  );
                  actions.push(
                    GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                  );
                  break;
                case GrantedAccessType.ARCHIVE:
                  if ('edgeId' in res.entity) {
                    actions.push(
                      GrantedAccessAction.getLocationByEdgeId({ edgeId: res.entity.edgeId }),
                    );
                  }
                  actions.push(
                    GrantedAccessAction.getSharedArchiveSuccess({ archive: res.entity as ArchiveDocument }),
                  );
                  actions.push(
                    GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                  );
                  break;
                case GrantedAccessType.ALERT:
                  const entityParams = res.entityParams as AlertParams;
                  const alert = res.entity as AlertEntry;

                  if (entityParams?.archiveId) {
                    actions.push(GrantedAccessAction.getSharedArchive({ archiveId: entityParams.archiveId }));

                  } else {
                    // if no archiveId put loaded
                    actions.push(
                      GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                    );
                  }
                  actions.push(
                    GrantedAccessAction.setSharedAlert({ alert }),
                    GrantedAccessAction.getCameraRetentionDays({ edgeId: alert.edgeId, cameraId: alert.cameraId }),
                  );
                  break;
              }
              return [
                ...actions,
                GrantedAccessAction.checkGrantedAccessSuccess({ entityParams: res.entityParams }),
                GrantedAccessAction.setAuthToken({ accessToken: res.accessToken }),
                GrantedAccessAction.checkGrantedAccessProtected({ isProtected: false }),
              ];
            }),
            catchError(err => {
              if (err.status == HttpStatusCode.BadRequest) {
                const actions: Action[] = [];
                if (body?.password) {
                  actions.push(SharedActions.showMessage({ error: 'Password is not correct.' }));
                }
                return [
                  GrantedAccessAction.checkGrantedAccessProtected({ isProtected: true }),
                  GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                  ...actions,
                ];
              } else {
                return [
                  GrantedAccessAction.checkGrantedAccessFail(),
                  GrantedAccessAction.checkGrantedAccessProtected({ isProtected: false }),
                  GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
                ];
              }
            }),
          ),
      ),
      share(),
    ),
  );

  public getByEntityId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getByEntityId),
      withLatestFrom(this.store$.pipe(select(state => state.grantedAccessState))),
      switchMap(([, { selectedEntityId }]) =>
        this.grantedAccessService.getByEntityId(selectedEntityId)
          .pipe(
            switchMap(res => {
              return [GrantedAccessAction.getByEntitySuccess({ grantedList: res })];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public createGrantedAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.createGrantedAccess),
      exhaustMap(({ grantedAccess }) => [SharedActions.setIsSaving({ isSaving: true }), GrantedAccessAction.sendCreateGrantedAccess({ grantedAccess })]),
    ),
  );

  public sendCreateGrantedAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.sendCreateGrantedAccess),
      switchMap(({ grantedAccess }) =>
        this.grantedAccessService.create({
            ...grantedAccess,
            emails: grantedAccess.emails.filter(item => validateEmail(item)),
            phones: grantedAccess.emails.filter(item => validatePhone(item)),
          })
          .pipe(
            switchMap(res => {
              return [
                // SharedActions.showMessage({ success: 'Link created' }),
                GrantedAccessAction.setSelectedGrantedAccess({ selectedGrantedAccess: res }),
                GrantedAccessAction.getByEntityId(),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              SharedActions.setIsSaving({ isSaving: false }),
              GrantedAccessAction.getByEntityFail(),
            ]),
          ),
      ),
    ),
  );

  public shareWith$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.shareWith),
      exhaustMap(({ id, users, message }) => [SharedActions.setIsSaving({ isSaving: true }), GrantedAccessAction.sendShareWith({ id, users, message })]),
    ),
  );

  public sendShareWith$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.sendShareWith),
      switchMap(({ id, users, message }) =>
        this.grantedAccessService.shareWith(id, {
            emails: users.filter(item => validateEmail(item)),
            phones: users.filter(item => validatePhone(item)),
            message,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Sent notifications successfully' }),
                GrantedAccessAction.getByEntityId(),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              SharedActions.setIsSaving({ isSaving: false }),
              GrantedAccessAction.getByEntityFail(),
            ]),
          ),
      ),
    ),
  );


  public revokeAccess = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.revokeAccess),
      switchMap(({ id }) =>
        this.grantedAccessService.revokeGrantedAccess(id)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Access has been revoked' }),
                GrantedAccessAction.revokeAccessSuccess({ grantedAccess: res }),
              ];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public notifyAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.notifyAccess),
      switchMap(({ id }) =>
        this.grantedAccessService.notify(id)
          .pipe(
            switchMap(res => {
              return [SharedActions.showMessage({ success: 'Sent notifications successfully' })];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public viewShared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.viewShared),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([{ id }, { _id }]) => {
          if (_id) {
            return this.grantedAccessService.viewAuth(id)
              .pipe(
                switchMap(res => {
                  return of(SharedActions.doNothing());
                }),
              );
          } else {
            return this.grantedAccessService.viewNoAuth(id)
              .pipe(
                switchMap(res => {
                  return of(SharedActions.doNothing());
                }),
              );
          }
        },
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public extendAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.extendAccess),
      switchMap(({ id, period }) =>
        this.grantedAccessService.extend(id, period)
          .pipe(
            switchMap(res => {
              return [SharedActions.showMessage({ success: 'Access has been extended' }), GrantedAccessAction.getByEntityId()];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public getLocationByEdgeId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getLocationByEdgeId),
      switchMap(({ edgeId }) =>
        this.grantedAccessService.getLocationByEdgeId(edgeId)
          .pipe(
            switchMap(res => {
              return [GrantedAccessAction.getLocationByEdgeIdSuccess({ location: res })];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );


  public getStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getConfig),
      switchMap(({ id }) =>
        this.grantedAccessService.getStats(id)
          .pipe(
            switchMap(res => {
              return [
                GrantedAccessAction.getConfigSuccess({ stats: res }),
              ];
            }),
            catchError(err => {
              return [GrantedAccessAction.getConfigFail(),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public addComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.addComment),
      exhaustMap(({ comment }) => [SharedActions.setIsSaving({ isSaving: true }), GrantedAccessAction.sendComment({ comment })]),
    ),
  );


  public sendComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.sendComment),
      switchMap(({ comment }) =>
        this.commentService.create(comment)
          .pipe(
            switchMap(res => {
              return [
                GrantedAccessAction.getComments({ id: comment.entityId }),
                SharedActions.showMessage({ success: 'Comment has been added' }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              SharedActions.setIsSaving({ isSaving: false }),
              GrantedAccessAction.addCommentFail(),
            ]),
          ),
      ),
    ),
  );


  public getComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getComments),
      switchMap(({ id }) =>
        this.commentService.getAll(id)
          .pipe(
            switchMap(res => {
              return [
                GrantedAccessAction.getCommentsSuccess({ comments: res }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              SharedActions.setIsSaving({ isSaving: false }),
              GrantedAccessAction.addCommentFail(),
            ]),
          ),
      ),
    ),
  );

  public getPreviewUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getPreviewUrl),
      switchMap(({ entityId, gType }) =>
        this.grantedAccessService.getPreviewUrl({ entityId, type: gType })
          .pipe(
            switchMap(res => {
              return [
                GrantedAccessAction.getPreviewUrlSuccess({ url: res?.url }),
              ];
            }),
            catchError(err => [
              GrantedAccessAction.getPreviewUrlFail({ entityId }),
            ]),
          ),
      ),
      share(),
    ),
  );

  public filterTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GrantedAccessAction.setQuery,
        GrantedAccessAction.setFilter,
        GrantedAccessAction.removeFilter,
        GrantedAccessAction.setOrderDirection,
      ),
      debounceTime(400),
      switchMap(() => [
        GrantedAccessAction.setIsLoading({ isLoading: true }),
        GrantedAccessAction.resetGrantedAccessList(),
        GrantedAccessAction.startLoadingGrantedAccessList(),
      ]),
    ),
  );


  public startLoadingGrantedAccessList = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.startLoadingGrantedAccessList),
      exhaustMap(() => [GrantedAccessAction.setIsLoading({ isLoading: true }), GrantedAccessAction.getGrantedAccessList()]),
    ),
  );


  public getGrantedAccessList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getGrantedAccessList),
      withLatestFrom(this.store$.pipe(select(state => state.grantedAccessState))),
      switchMap(([, { page, perPage, query, isLastPage, lastId, filters }]) => {
        if (!isLastPage) {
          return this.grantedAccessService.getGrantedAccessList(page, perPage, query, {
              ...filters,
              lastId: lastId,
            })
            .pipe(
              switchMap(result => {
                return [
                  GrantedAccessAction.setGrantedAccessList({
                    grantedAccessList: result.items,
                  }),
                  GrantedAccessAction.setTotalItemsCount({
                    totalItemsCount: result.totalItemsCount,
                  }),
                  GrantedAccessAction.setIsLoading({ isLoading: false }),
                ];
              }),
              catchError(err => {
                return of(GrantedAccessAction.setIsLoading({ isLoading: false }));
              }),
            );
        } else {
          return of(GrantedAccessAction.setIsLoading({ isLoading: false }));
        }
      }),
      share(),
    ),
  );

  public getSharedArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getSharedArchive),
      switchMap(({ archiveId }) => {
        return this.grantedAccessService.getSharedArchive(archiveId)
          .pipe(
            switchMap(result => {
              return [
                GrantedAccessAction.getSharedArchiveSuccess({
                  archive: result,
                }),
                GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
              ];
            }),
            catchError(() => [
              GrantedAccessAction.setInitialLoaded({ initialLoaded: true }),
              GrantedAccessAction.getSharedArchiveFail(),
            ]));
      }),
      share(),
    ),
  );

  public revokeAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.revokeAll),
      switchMap(() =>
        this.grantedAccessService.revokeAll()
          .pipe(
            switchMap(res => {
              return [
                GrantedAccessAction.resetGrantedAccessList(),
                GrantedAccessAction.getGrantedAccessList(),
                SharedActions.showMessage({ success: 'All access has been revoked' }),
              ];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public getCameraRetentionDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getCameraRetentionDays),
      switchMap(({ edgeId, cameraId }) =>
        this.grantedAccessService.getCameraRetentionDays({ edgeId, cameraId })
          .pipe(
            switchMap(res => {
              const retentionDays = res.retentionDays;
              return [GrantedAccessAction.getCameraOldestVideoTimestamp({ retentionDays, edgeId, cameraId })];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );

  public getCameraOldestVideoTimestamp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrantedAccessAction.getCameraOldestVideoTimestamp),
      switchMap(({ retentionDays, edgeId, cameraId }) =>
        this.statsService.getCameraOldestVideoFromGrafana({ edgeId, cameraId })
          .pipe(
            switchMap(oldestVideoTimestamp => {
              const camera: EdgeCamera.CameraItem = {
                edgeId,
                edgeOnly: {
                  cameraId: cameraId,
                  retentionDays: retentionDays,
                },
                cameraHealth: {
                  oldestVideoTimestamp,
                },
              };
              console.log('CreateLocationEdgeCameraSuccess');
              return [CameraActions.CreateLocationEdgeCameraSuccess({ payload: camera })];
            }),
          ),
      ),
      catchError(err => [GrantedAccessAction.getByEntityFail()]),
    ),
  );


  constructor(
    private grantedAccessService: GrantedAccessService,
    private authenticationService: AuthenticationService,
    private commentService: CommentService,
    private locationsService: LocationsService,
    private statsService: StatsService,
    private actions$: Actions, private store$: Store<AppState>) {
  }
}
