import { createReducer, on } from '@ngrx/store';
import * as OrganizationActions from '@states/organization/organization.actions';
import { ActiveOrganization, OrganizationUser, UserOrganizationDropDown } from '@models/organization.model';
import { SortDirection } from '@angular/material/sort';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { checkIfOrgNameExistOnServer, loginFirewallSaveSuccess, setOrganizationGlobalLoader, setOrganizationNameCheckLoader, updateActiveOrganizationSuccess } from '@states/organization/organization.actions';

export declare interface OrganizationState {
  activeOrganization: ActiveOrganization;
  isDefaultOrganization: boolean;
  organizationUsers: OrganizationUser[];
  totalItemsCount: number;
  page: number;
  perPage: number;

  createdOrganization: { orgName: string; orgEmail: string };
  createdOrganizationInvitedUsers: { [key: string]: string }[];

  userOrganizations: UserOrganizationDropDown[];

  orderBy: string;
  orderDirection: SortDirection;
  query: string;
  organizationNameExist: boolean;
  organizationEmailExist: boolean;
  maxRetentionDays: number;
  orgNameExistLoading: boolean;
  organizationGlobalLoader: boolean;
}

const initialState: OrganizationState = {
  activeOrganization: null,
  isDefaultOrganization: null,
  organizationUsers: [],
  totalItemsCount: 0,
  page: 0,
  perPage: 1000,
  createdOrganizationInvitedUsers: [],
  createdOrganization: null,
  userOrganizations: null,
  orderBy: 'email',
  orderDirection: 'asc',
  query: null,
  organizationNameExist: false,
  organizationEmailExist: false,
  maxRetentionDays: null,
  orgNameExistLoading: false,
  organizationGlobalLoader: false,
};

export const organizationStateReducer = createReducer(
  initialState,
  on(OrganizationActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(OrganizationActions.setActiveOrganization, (state, { activeOrganization }) => {
    return {
      ...state,
      activeOrganization,
    };
  }),
  on(OrganizationActions.updateActiveOrganizationSuccess, (state, { activeOrganization }) => {
    return {
      ...state,
      activeOrganization: {
        ...state.activeOrganization,
        ...activeOrganization,
      },
    };
  }),
  on(OrganizationActions.setIsDefaultOrganization, (state, { isDefaultOrganization }) => {
    return {
      ...state,
      isDefaultOrganization,
    };
  }),
  on(OrganizationActions.setOrganizationUsers, (state, { users }) => {
    return {
      ...state,
      organizationUsers: users,
    };
  }),
  on(OrganizationActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(OrganizationActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
  on(OrganizationActions.getUserOrganizationsSuccess, (state, { organizations }) => {
    return {
      ...state,
      userOrganizations: organizations,
    };
  }),
  on(OrganizationActions.setOrderParams, (state, { orderBy, orderDirection }) => {
    return {
      ...state,
      orderBy,
      orderDirection,
    };
  }),
  on(OrganizationActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(OrganizationActions.setOrganizationNameExist, (state, { organizationNameExist }) => {
    return {
      ...state,
      organizationNameExist,
    };
  }),
  on(OrganizationActions.setOrganizationEmailExist, (state, { organizationEmailExist }) => {
    return {
      ...state,
      organizationEmailExist,
    };
  }),
  on(OrganizationActions.saveCreatedOrganizationLocally, (state, { createdOrganization }) => {
    return {
      ...state,
      createdOrganization: createdOrganization,
    };
  }),
  on(OrganizationActions.saveCreatedOrganizationInvitedUsers, (state, { invites }) => {
    return {
      ...state,
      createdOrganizationInvitedUsers: invites,
    };
  }),
  on(OrganizationActions.resetCreatedOrganizationLocally, state => {
    return {
      ...state,
      createdOrganizationInvitedUsers: initialState.createdOrganizationInvitedUsers,
      createdOrganization: initialState.createdOrganization,
    };
  }),
  on(OrganizationActions.getOrganizationMaxRetentionDaysSuccess, (state, { maxRetentionDays }) => {
    return {
      ...state,
      maxRetentionDays,
    };
  }),
  on(OrganizationActions.loginFirewallSaveSuccess, (state, { data }) => {
    return {
      ...state,
      activeOrganization: {
        ...state.activeOrganization,
        ipRules: data,
      },
    };
  }),
  on(OrganizationActions.setOrganizationNameCheckLoader, (state, { loading }) => {
    return {
      ...state,
      orgNameExistLoading: loading,
    };
  }),
  on(OrganizationActions.leaveOrg, (state) => {
    return {
      ...state,
      organizationGlobalLoader: true,
    };
  }),
  on(OrganizationActions.deleteOrg, (state) => {
    return {
      ...state,
      organizationGlobalLoader: true,
    };
  }),
  on(OrganizationActions.setOrganizationGlobalLoader, (state, { loading }) => {
    return {
      ...state,
      organizationGlobalLoader: loading,
    };
  }),
);
