import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WebhookModel } from '@models/webhook.model';
import { WebhookActions } from '@states/webhook/webhook.action-types';
import { sendCreateWebhookRequestSuccess, sendUpdateWebhookRequestSuccess, setIsCreatingLoader, startCreateWebhook } from '@states/webhook/webhook.actions';
import { AppleTvActions } from '@states/apple-tv/apple-tv.action-types';


export interface WebhookState extends EntityState<WebhookModel.DocumentMongo> {
  isInitialLoaded: boolean; // show loader until first loading happening
  isCreating: boolean;
  deletingWebhookIds: string[];
  notEmpty: boolean; //show no Data if result empty
}

export const adapter: EntityAdapter<WebhookModel.DocumentMongo> = createEntityAdapter<WebhookModel.DocumentMongo>({
  selectId: (document: WebhookModel.DocumentMongo) => document._id,
});

const { selectAll } = adapter.getSelectors();


const initialState: WebhookState = adapter.getInitialState({
  isInitialLoaded: false,
  isCreating: false,
  deletingWebhookIds: [],
  notEmpty: null,
});


export const webhookStateReducer = createReducer(
  initialState,
  on(WebhookActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(WebhookActions.getOrganizationWebhooksSuccess, (state, { documents }) => {
    return adapter.setAll(documents, {
      ...state,
      isInitialLoaded: true,
      notEmpty: !state.isInitialLoaded ? !!documents.length : state.notEmpty,
    });
  }),
  on(WebhookActions.getOrganizationWebhooksFail, (state) => {
    return {
      ...state,
      isInitialLoaded: true,
    };
  }),
  on(WebhookActions.setIsCreatingLoader, (state, { isCreating }) => {
    return {
      ...state,
      isCreating,
    };
  }),
  on(WebhookActions.startDeleteWebhook, (state, { id }) => {
    return {
      ...state,
      deletingWebhookIds: [...state.deletingWebhookIds, id],
    };
  }),
  on(WebhookActions.sendDeleteWebhookRequestSuccess, (state, { id }) => {
    const deletingIndex = state.deletingWebhookIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingWebhookIds];
    newDeletingArray.splice(deletingIndex, 1);
    return adapter.removeOne(id, {
      ...state,
      deletingWebhookIds: newDeletingArray,
      notEmpty: selectAll(state).length > 1,
    });
  }),
  on(WebhookActions.sendDeleteWebhookRequestFail, (state, { id }) => {
    const deletingIndex = state.deletingWebhookIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingWebhookIds];
    newDeletingArray.splice(deletingIndex, 1);
    return {
      ...state,
      deletingWebhookIds: newDeletingArray,
    };
  }),
  on(WebhookActions.sendCreateWebhookRequestSuccess, (state, { webhook }) => {
    return adapter.setAll([webhook, ...selectAll(state)], { ...state, notEmpty: true });
  }),
  on(WebhookActions.sendUpdateWebhookRequestSuccess, (state, { webhook }) => {
    return adapter.updateOne({
      id: webhook._id,
      changes: {
        ...webhook,
      },
    }, { ...state });
  }),
);
