import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as TerminalActions from '@states/terminal/terminal.actions';
import { catchError, of, share, switchMap, takeUntil, map, pipe } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import * as SharedActions from '@states/shared/shared.actions';
import { SessionDataAction } from '@enums/session-data.enum';
import { EdgeManagementService } from '../../locations/edge-management.service';
import { LocationsService } from 'src/app/locations/locations.service';

@Injectable()
export class TerminalEffect {
  public setCommandFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerminalActions.setCommandFail),
      switchMap(({ msg }) => {
        return of(TerminalActions.setCommandFailError({ msg }));
      }),
      share(),
    ),
  );

  public setEdgeId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerminalActions.setEdgeId),
      withLatestFrom(this.store$.pipe(select(EdgeSelectors.selectEdgeState))),
      switchMap(([{ edgeId }, edges]) => {
        const locationId = edges?.entities[edgeId]?.locationId;
        if (locationId) {
          return this.locationService.getEdgeLookup(locationId, edgeId)
            .pipe(
              map(apiResponse => ({ edgeId, edges, apiResponse })),
            );
        } else {
          return of({
            edgeId, edges, apiResponse: null,
          });
        }
      }),
      switchMap(({ edgeId, edges, apiResponse }) => {
        const locationId = edges?.entities[edgeId]?.locationId;
        if (locationId) {
          return [
            // @ts-ignore
            TerminalActions.setLocationId({ locationId: edges?.entities[edgeId]?.locationId }),
            TerminalActions.setEdgeIdTerminal({ edgeId }),
            // @ts-ignore
            TerminalActions.setContext({ context: apiResponse }),
          ];
        } else {
          return [TerminalActions.setCommandFail({ msg: `Edge with ID ${edgeId} is not found.` })];
        }
      }),
      share(),
    ),
  );

  public manage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerminalActions.manage),
      withLatestFrom(this.store$.pipe(select(state => state.terminalState))),
      switchMap(([{ action, params, msTimeout }, { locationId, edgeId }]) => {

        if ((!params || !params['edgeId']) && (!edgeId || !locationId)) {
          return of(TerminalActions.setCommandFail({
            msg: 'CoreId and location Id is not set. please set context or provide --edgeId parameter',
          }));
        }

        edgeId = !!params && params['edgeId'] ? params['edgeId'] as string : edgeId;

        return this.edgeManagementService
          .manageEdgeAction(action, {
            ...params,
            locationId,
            edgeId,
          })
          .pipe(
            takeUntil(this.actions$.pipe(ofType(TerminalActions.cancelManageRequest))),
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.manageAction,
                  params: {
                    msTimeout,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public dispacth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerminalActions.dispacth),
      withLatestFrom(this.store$.pipe(select(state => state.terminalState))),
      switchMap(([{ action, params, msTimeout }, { locationId, edgeId }]) => {
        if (!edgeId || !locationId) {
          return of(TerminalActions.setCommandFail({ msg: 'CoreId and location Id is not set.' }));
        }

        return this.edgeManagementService
          .dispatchEdgeAction(action, {
            ...params,
            locationId,
            edgeId,
          })
          .pipe(
            takeUntil(this.actions$.pipe(ofType(TerminalActions.cancelDispatchRequest))),
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dispatch,
                  params: {
                    msTimeout,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private edgeManagementService: EdgeManagementService,
    private locationService: LocationsService,
  ) {
  }
}
