import { createAction, props } from '@ngrx/store';
import { Analytics } from '../../../analytics/analytics.model';
import { AnalyticsChart } from '@models/analytic-chart.models';
import { Sort } from '../../../shared/shared.model';

export const resetToInitialState = createAction('[Analytics] Reset to Initial state');

export const resetItems = createAction('[Analytics] Reset items');

export const getAnalyticsOccupancyCounter = createAction('[Analytics] Get Analytics Occupancy Counter');

export const getAnalyticsOccupancyCounterSuccess = createAction(
  '[Analytics] Get Analytics Occupancy Counter Success',
  props<{ items: Analytics.AnalyticDocument[] }>()
);

export const setAnalyticsChartItems = createAction(
  '[Analytics] Set Analytics Chart Items',
  props<{ analyticsChartItems: AnalyticsChart[] }>()
);

export const setAnalyticsChartLegends = createAction(
  '[Analytics] Set Analytics Chart Legends',
  props<{ analyticsChartLegends: string[] }>()
);

export const setAnalyticsChartAxisYMax = createAction(
  '[Analytics] Set Analytics Chart AxisY Max',
  props<{ analyticsChartAxisYMax: number }>()
);

export const setAnalyticsChartAxisXLabels = createAction(
  '[Analytics] Set Analytics Chart AxisX Labels',
  props<{ analyticsChartAxisXLabels: string[] }>()
);

export const getAnalyticsOccupancyCounterFail = createAction('[Analytics] Get Analytics Occupancy Counter Fail');

export const setPagination = createAction('[Analytics] Set Pagination', props<{ perPage: number; page: number }>());

export const setFilterSettings = createAction(
  '[Analytics] Set Filter Settings',
  props<{
    edgeId: string;
    cameraId: string;
    start: number;
    end: number;
  }>()
);

export const setLatestTs = createAction(
  '[Analytics] Set LatestTs',
  props<{
    latestTs: number;
  }>()
);

export const setIsLastPage = createAction(
  '[Analytics] Set Is Last Page',
  props<{
    isLastPage: boolean;
  }>()
);

export const setOrderDirection = createAction(
  '[Analytics] Set Order Direction',
  props<{
    orderDirection: Sort;
  }>()
);

export const setPageTimestamp = createAction(
  '[Analytics] Set Page Timestamp',
  props<{
    pagesTimestamp: number[];
  }>()
);
