import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Analytics } from '../analytics/analytics.model';
import { QueryByEdgeId, ThumbnailsEntry, ThumbnailsQuery } from './thumbnails.service';
import * as moment from 'moment-timezone';

export interface AnalyticEntry {
  _id?: string;
  edgeId: string;
  cameraId: string;
  data: string;
}

export interface AnalyticsQuery extends QueryByEdgeId {
  idIndex?: string;
  idBase?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private http: HttpClient) {
  }

  parseObject(parse = true, timezone?: string) {
    return source$ =>
      source$.pipe(
        map((res: Array<AnalyticEntry>) => {
          return parse
            ? res.map(item => {
              let data = item.data;

              if (data) {
                try {
                  data = JSON.parse(data);
                } catch (error) {
                  data = item.data;
                }
              }

              if (item['timestamp']) {
                item['day'] = timezone
                  ? moment.tz(item['timestamp'], timezone)
                  .format('ddd MMMM D YYYY, HH:mm:ss') + ` (${timezone})`
                  : new Date(+item['timestamp']);
              }

              const e = !!data ? { ...item, ...{ data } } : { ...item };
              return e;
            })
            : res;
        }),
      );
  }

  getAllAnalytics(mongo: boolean, page: number, size = 20, filter?: AnalyticsQuery, timezone?: string): Observable<AnalyticEntry[]> {
    let url = mongo ? `${environment.apiUrl}/analytics?page=${page}&size=${size}` : `${environment.apiUrl}/debugger/analytics?page=${page}&size=${size}`;

    for(const key in filter) {
      const element = filter[key];
      if (!!element) {
        url = url.concat(`&${key}=${element}`);
      }
    }

    return this.http.get<AnalyticEntry[]>(url)
      .pipe(this.parseObject(true, timezone));
  }

  getAnalyticsOccupancyCounter(
    filter: Analytics.AnalyticsOccupancyCounterQuery,
    page = 0,
    size = 10000,
  ): Observable<Analytics.AnalyticsOccupancyCounterResponse> {
    let url = `${environment.apiUrl}/analytics/occupancy?page=${page}&size=${size}`;

    for(const key in filter) {
      const element = filter[key];
      if (!!element) {
        url = url.concat(`&${key}=${element}`);
      }
    }

    return this.http.get<Analytics.AnalyticsOccupancyCounterResponse>(url);
  }
}
