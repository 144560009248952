import { Pipe, PipeTransform } from '@angular/core';
import { RelativeTimePipe } from './relative-time.pipe';
import { OnRangeSelectedResult } from '../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import * as moment from 'moment-timezone';
import { UiCalendarPickerType } from '@enums/shared.enum';

@Pipe({
  name: 'calendarSelectedValue',
})
export class UiCalendarSelectedPipe implements PipeTransform {
  constructor(private relativeTimePipe: RelativeTimePipe) {
  }

  transform(value: OnRangeSelectedResult): string {
    if (value.type === UiCalendarPickerType.RELATIVE) {
      return `Last ${this.relativeTimePipe.transform(value.relative.value, value.relative.unit)}`;
    } else if (value.type === UiCalendarPickerType.ABSOLUTE) {
      return `Between ${moment(value.absolute?.start)
        .format('MMM D HH:mm')} and ${moment(value.absolute?.end)
        .format('MMM D HH:mm')}`;
    }
    return '';
  }
}
