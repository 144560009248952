import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';
import { AppleHeartbeatsPulsationActions } from '@states/apple-heartbeat-pulsation/apple-heartbeat-pulsation-types';

export interface ApplePulsationState extends EntityState<PulsationModels.Store.ApplePulsation> {
  message?: string;
  error?: string;
}

export const adapter: EntityAdapter<PulsationModels.Store.ApplePulsation> = createEntityAdapter<PulsationModels.Store.ApplePulsation>({
  selectId: (status: PulsationModels.Store.ApplePulsation) => status.cameraId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: ApplePulsationState = adapter.getInitialState({});

export const applePulsationStatusReducer = createReducer(
  initialState,

  on(AppleHeartbeatsPulsationActions.GetApplePulsationStatusSuccess, (state, action) => {
    return adapter.setMany(action.payload, state);
  }),

  on(AppleHeartbeatsPulsationActions.GetApplePulsationStatusFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),
);
