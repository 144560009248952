import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-prefix-input',
  templateUrl: './ui-prefix-input.component.html',
  styleUrls: ['./ui-prefix-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiPrefixInputComponent),
      multi: true,
    },
  ],
})
export class UiPrefixInputComponent implements OnInit, ControlValueAccessor {
  @Input() prefix: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() placeholder: string = '';
  @Input() invalid: boolean = false;
  @Input() disabled: boolean;

  @Input() inputType = 'text';
  public value: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    this.value = val;
  }

  public updateValue(insideValue: string) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };
}
