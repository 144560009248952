export const ThumbnailHistogramColor = [
  '#4367F6',
  '#14B8A6',
  '#EC4899',
  '#e5d561',
  '#5ef643',
  '#d5742f',
  '#742ebc',
  '#df3131',
  '#01fee7',
];

export const ThumbnailHistogramColorRGBA = [
  'rgba(67, 103, 246',
  'rgba(20, 184, 166',
  'rgba(236, 72, 153',
  'rgba(229, 213, 97',
  'rgba(94, 246, 67',
  'rgba(213, 116, 47',
  'rgba(116, 46, 188',
  'rgba(223, 49, 49',
  'rgba(1, 254, 231',
];
