<div fxLayout="row" fxLayoutAlign="end center">
  <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
</div>
<div class="body" [formGroup]="form">
  <div class="input-row">
    <div fxLayout="column" class="ui-input-wrapper label-out" style="width: 320px">
      <div class="ui-input-container" (click)="selectCamera()">
        <img class="input-icon-start" src="../../../../../assets/input/camera-outline.svg" />
        <div class="camera-selected-name">
          {{ selectedCamera?.edgeOnly.name ?? 'Select Camera *' }}
        </div>
        <img class="input-icon-end" src="../../../../../assets/input/input-arrow-bottom.svg" />
      </div>
    </div>
  </div>
  <div class="input-row">
    <ui-input
      formControlName="dirPattern"
      label="Dir Pattern *"
      [style]="inputStyle.labelOutside"
      [required]="true"
      [validationEnabled]="true"
      [invalid]="form.get('dirPattern').invalid && form.get('dirPattern').touched"
      [validationErrors]="form.get('dirPattern').errors"
    ></ui-input>
  </div>
  <div class="input-row">
    <ui-input
      formControlName="filePattern"
      label="File Pattern *"
      [style]="inputStyle.labelOutside"
      [required]="true"
      [validationEnabled]="true"
      [invalid]="form.get('filePattern').invalid && form.get('filePattern').touched"
      [validationErrors]="form.get('filePattern').errors"
    ></ui-input>
  </div>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="end center">
  <button mat-flat-button (click)="cancel()" style="margin-right: 10px">Cancel</button>
  <button mat-flat-button color="primary" (click)="ok()">Ok</button>
</div>
