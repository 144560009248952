import { Injectable } from '@angular/core';
import { ExternalStorageService } from '../../services/external-storage.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, mergeMap, of, share, switchMap, timeout, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ExternalStorageActions } from '@states/external-storage/external-storage.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { UtilsService } from '../../edge/utils.service';
import { ExternalStorageModels } from '@models/external-storage.models';
import { CameraActions } from '@states/camera/camera.action-types';

@Injectable()
export class ExternalStorageEffects {

  public getExternalStorageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.getExternalStorage),
      withLatestFrom(
        this.store$.pipe(select(state => state.externalStorageState)),
        this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([, { filters }, { selectedLocationId }]) => {
        return this.externalStorageService
          .getAll({
            locationId: selectedLocationId,
            ...filters,
          })
          .pipe(
            switchMap(res => {
              console.log(res);
              return [
                ExternalStorageActions.getExternalStorageSuccess({ documents: res }),
              ];
            }),
            catchError(response => {
              return [
                ExternalStorageActions.getExternalStorageFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.setQueryStringFilter),
      debounceTime(400),
      exhaustMap(() => [ExternalStorageActions.resetEntities(), ExternalStorageActions.getExternalStorage()]),
    ),
  );

  public startSaveExternalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.startSaveExternalStorage),
      switchMap(({ document, isEdit }) => [ExternalStorageActions.setIsSaving({ isSaving: true }), ExternalStorageActions.saveExternalStorageRequest({ document, isEdit })]),
    ),
  );

  public saveExternalStorageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.saveExternalStorageRequest),
      withLatestFrom(
        this.store$.pipe(select(state => state.locationEditState)),
      ),
      switchMap(([{ document, isEdit }, { selectedLocationId }]) => {
        return this.externalStorageSaveService(isEdit, selectedLocationId, { ...document, name: document.name })
          .pipe(
            switchMap((res) => {
              return [
                ExternalStorageActions.setIsSaving({ isSaving: false }),
                ExternalStorageActions.saveExternalStorageRequestSuccess(),
              ];
            }),
            catchError(response => {
              return [
                ExternalStorageActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                ExternalStorageActions.saveExternalStorageRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public startDeleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.startDeleteExternalStorage),
      debounceTime(400),
      exhaustMap(({ storageId }) => [ExternalStorageActions.deleteExternalStorage({ storageId })]),
    ),
  );

  public deleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.deleteExternalStorage),
      withLatestFrom(
        this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([{ storageId }, { selectedLocationId }]) => {
        return this.externalStorageService
          .delete(selectedLocationId, storageId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Device has been removed' }),
                ExternalStorageActions.deleteExternalStorageSuccess({ storageId }),
                CameraActions.deleteExternalStorageFromCamera({ storageId }),

              ];
            }),
            catchError(response => {
              const err = this.utilsService.errMessage(response);
              return [
                ExternalStorageActions.deleteExternalStorageFail(),
                SharedActions.showMessage({ error: err }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public getOneExternalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.getOneExternalStorage),
      switchMap(({ id }) => {
        return this.externalStorageService
          .getOne(id)
          .pipe(
            switchMap(res => {
              return [
                ExternalStorageActions.getOneExternalStorageSuccess({ document: res }),
              ];
            }),
            catchError(response => {
              return [
                ExternalStorageActions.getOneExternalStorageFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public startConfigureExternalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.startConfigureExternalStorage),
      debounceTime(400),
      exhaustMap(({ document }) => [ExternalStorageActions.saveExternalStorageRequest({ document, isEdit: true })]),
    ),
  );

  public testExternalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.testExternalStorage),
      withLatestFrom(this.store$.pipe(select(state => state.locationEditState)), this.store$.pipe(select(state => state.externalStorageState))),
      switchMap(([, { selectedLocationId, selectedLocation }, { selectedExternalStorageDocument }]) => {
        const locationId = selectedLocationId;
        const edges = Object.keys(selectedLocation.edges);
        const actions = [];
        for(let edgeId of edges) {
          const request: ExternalStorageModels.TestExternalStorageRequest = {
            edgeId,
            ...selectedExternalStorageDocument,
            maxSmartStorageSize: +selectedExternalStorageDocument.maxSmartStorageSize,
            port: +selectedExternalStorageDocument.port,
          };
          actions.push(ExternalStorageActions.testExternalStorageSend({ request }));
        }
        return actions;
      }),
      catchError(err => [ExternalStorageActions.testExternalStorageError({ errorMsg: err })],
      ),
    ),
  );

  public testExternalStorageSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.testExternalStorageSend),
      share(),
      mergeMap(({ request }) => {
        return this.externalStorageService.testExternalStorage(request)
          .pipe(
            switchMap(res => {
              return [
                ExternalStorageActions.testExternalStorageSuccess(),
              ];

            }),
            catchError(response => {
              return [
                ExternalStorageActions.testExternalStorageError({ errorMsg: response.error }),
              ];
            }),
            timeout(10000),
          );
      }),
    ),
  );

  public testExternalStorageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.testExternalStorageSuccess),
      mergeMap(() => {
        return [SharedActions.doNothing()];
      }),
    ),
  );

  public saveExternalStorageRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.saveExternalStorageRequestSuccess),
      mergeMap(() => {
        return [SharedActions.doNothing()];
      }),
    ),
  );


  public deleteExternalStorageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalStorageActions.deleteExternalStorageSuccess),
      mergeMap(() => {
        return [SharedActions.doNothing()];
      }),
    ),
  );


  constructor(private externalStorageService: ExternalStorageService,
              private actions$: Actions,
              private store$: Store<AppState>,
              private utilsService: UtilsService) {
  }

  private externalStorageSaveService(isEdit: boolean, selectedLocationId: string, document: Partial<ExternalStorageModels.ExternalStorageDocument>) {
    if (!isEdit) {
      return this.externalStorageService
        .create({
          accessKey: document.accessKey,
          bucketName: document.bucketName,
          ipAddress: document.ipAddress,
          maxSmartStorageSize: +document.maxSmartStorageSize,
          port: +document.port,
          region: document.region,
          secretKey: document.secretKey,
          storageType: document.storageType,
          locationId: selectedLocationId,
          path: document.path,
          name: document.name,
        });
    } else {
      return this.externalStorageService
        .save({
          _id: document._id,
          accessKey: document.accessKey,
          bucketName: document.bucketName,
          ipAddress: document.ipAddress,
          maxSmartStorageSize: +document.maxSmartStorageSize,
          port: +document.port,
          region: document.region,
          secretKey: document.secretKey,
          storageType: document.storageType,
          locationId: selectedLocationId,
          name: document.name,
          path: document.path,
        });
    }
  }

}
