<div class="flex-col start-start flex-fill" class="dialog">
  <div class="flex-row space-between-center">
    <div class="flex-row gap-8 start-center">
      <div>Participants</div>
    </div>
    <div class="flex-row center-center c-p icon-18 " mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-content narrower-input flex-col start-start gap-16 c-100"
  >
    <ng-container *ngIf="participants; else loader">
      <div class="flex-col table-wrapper start-start c-100"
      >
        <div class="table-header flex-row start-center">
          <div *ngFor="let title of tableTitles">{{ title | titlecase }}</div>
        </div>
        <div *ngFor="let p of participants; let i = index" class="table-row flex-row start-start">
          <div *ngFor="let field of tableFields"
               [style.max-width]="100 / tableTitles?.length + '%'"
          >
            <ui-truncated-html>
              {{ valueParser(p[field], field) }}
            </ui-truncated-html>
          </div>
        </div>
      </div>

    </ng-container>
    <ng-template #loader>
      <ui-preloader [diameter]="50"></ui-preloader>
    </ng-template>
  </div>
  <div class="flex-row space-between-center gap-8">
    <div></div>
    <div class="flex-row gap-8">
      <ui-button type="primary" (clickAction)="close()">Done
      </ui-button>
    </div>
  </div>
</div>
