import { Component, Input, OnInit } from '@angular/core';
import { SearchQueryOperator, SearchSelection, SearchSelectionProperty } from '../advanced-search.model';
import {
  SearchSelectorCarTypes,
  SearchSelectorOperators,
  SearchSelectorPersonProperties,
  SearchSelectorTypes,
} from '../search-selector/search-selector.model';
import { MatRadioChange } from '@angular/material/radio';;
import { carMakes, searchSettingsMap } from '@consts/alert-events.const';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { carMadeModel } from '@consts/carMadeModel';
import { Observable } from 'rxjs';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { SearchSettings } from '@enums/alert-events.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-properties-selector',
  templateUrl: './properties-selector.component.html',
  styleUrls: ['./properties-selector.component.scss'],
})
export class PropertiesSelectorComponent implements OnInit {
  @Input()
  selection: SearchSelection;

  public selectCameraSearchSettingsFilters$: Observable<{ [key: string]: boolean }> = this.store$.select(
    MultiSearchSelectors.selectCameraSearchSettingsFilters
  );

  SearchSelectorTypes: typeof SearchSelectorTypes = SearchSelectorTypes;
  // SearchSelectorPersonAccessories: typeof SearchSelectorPersonAccessories = SearchSelectorPersonAccessories;
  SearchSelectorPersonProperties: typeof SearchSelectorPersonProperties = SearchSelectorPersonProperties;
  SearchSelectorCarTypes: typeof SearchSelectorCarTypes = SearchSelectorCarTypes;
  public carMakes = carMakes.map(item => {
    return { name: item.key, value: item.value };
  });

  SearchSelectorOperators: typeof SearchSelectorOperators = SearchSelectorOperators;

  SearchSelectorPersonPropertiesOptions = this.SearchSelectorPersonProperties.map(p => {
    return { name: p.label, value: p.name };
  });

  public formGroup: UntypedFormGroup;
  public carMadeOptions: string[] = Object.keys(carMadeModel);
  public carModelsOptions: string[] = [];
  public searchSettingsMap = searchSettingsMap;
  public searchSettings = SearchSettings;

  public selectedCarModels: string[] = [];

  constructor(private store$: Store, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      selectedCarMakes: [this.selection.alpr?.make],
    });
    this.selectedCarModels = this.selection.alpr?.model;

    if (this.selection.alpr?.make?.length) {
      this.selection.alpr?.make?.map(make => {
        this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
      });
    } else {
      this.carMadeOptions.map(make => {
        this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
      });
    }

    this.formGroup.get('selectedCarMakes').valueChanges.subscribe(res => {
      this.selection.alpr.make = res;
      let result = [];
      if (res.length) {
        res.map(make => {
          result = result.concat(carMadeModel[make]);
        });
      } else {
        this.carMadeOptions.map(make => {
          result = result.concat(carMadeModel[make]);
        });
      }

      this.carModelsOptions = result;
    });
  }

  addAccessory() {
    const lastIdx = this.selection.properties.length - 1;
    if (lastIdx === 0) {
      this.selection.properties[0].operator = SearchQueryOperator.AND;
    }
    this.selection.properties.push({ value: [] });
  }

  removeAccessory(accIndex: number) {
    this.selection.properties.splice(accIndex, 1);
  }

  propertyByName(name: string) {
    const res = this.SearchSelectorPersonProperties.find(s => s.name === name);
    return res;
  }

  check(event: MatCheckboxChange, prop: SearchSelectionProperty) {
    if (event.checked) {
      prop.value?.push(event.source.value);
      return;
    }
    prop.value.splice(prop.value.indexOf(event.source.value), 1);
    return;
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  toDisplayStr(str: string) {
    return this.replaceAll(str, '_', ' ');
  }

  includes(str: string, array: string[]) {
    if (!array) {
      array = [];
    }
    return array.includes(str);
  }

  radio(event: MatRadioChange, prop: SearchSelectionProperty) {
    prop.value = [event.value];
  }

  chipSelected(value: string, propValue: string[]) {
    return propValue.includes(value);
  }

  chip(event: { value: string; selected: boolean }, propValue: string[]) {
    if (event.selected) {
      propValue.push(event.value);
      return;
    }
    propValue.splice(propValue.indexOf(event.value), 1);
  }

  setColors(colors: string[], prop: SearchSelectionProperty) {
    prop.colors = colors;
  }

  toggleProp(event: Event, index: number) {
    const checked = ((event.currentTarget as HTMLElement).children[0].children[0] as any).checked;
    if (!checked) {
      this.selection.properties[index].value = [];
      if (!!this.selection.properties[index]?.colors) {
        this.selection.properties[index].colors = [];
      }
      this.selection.properties[index].enabled = false;
      return;
    }
    this.selection.properties[index].enabled = true;
  }

  public unselectCarMade(option: string): void {
    const carMakesArray = [...this.formGroup.get('selectedCarMakes').value];
    const index = carMakesArray.indexOf(option);
    if (index > -1) {
      carMakesArray.splice(index, 1);
    }
    this.formGroup.patchValue({ selectedCarMakes: carMakesArray });
  }

  public unselectCarModel(option: string): void {
    const carModelsSelected = [...this.selectedCarModels];
    const index = carModelsSelected.indexOf(option);
    if (index > -1) {
      carModelsSelected.splice(index, 1);
    }
    this.selectedCarModels = carModelsSelected;
    this.selection.alpr.model = carModelsSelected;
  }

  public selectedCarModelsChanged(value) {
    this.selectedCarModels = value;
    this.selection.alpr.model = value;
  }
}
