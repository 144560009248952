<div fxFlexFill>
  <ng-container *ngrxLet="isOrganizationSettingsEditOrganization$; let isOrganizationSettingsEditOrganization">
    <ng-container *ngIf="isAcceptingEnd;else loader">
      <div fxLayout="column" class="organization-settings">
        <ng-container *ngrxLet="selectInvites$; let activeInvites">
          <ng-container *ngIf="activeInvites?.length">
            <div fxLayout="row" fxLayoutAlign="center center">
              <div *ngFor="let invite of activeInvites" class="invites-wrapper">
                <span class="title">{{ invite.invitedByUser.email }} invited you to join the organization</span>
                <span class="subtitle"
                >Invitation expires at
                      <ui-user-settings-date [date]="invite.expired_at"></ui-user-settings-date>
                    </span>
                <div class="org-block">
                  <div fxLayout="row" fxLayoutAlign="center center" class="organization-avatar" style="margin: 0 auto">
                    <ui-avatar style="width: 100%; height: 100%" [name]="invite.orgName" [image]="invite.orgLogo"
                               [color]="invite.orgColor"
                    >
                    </ui-avatar>
                  </div>
                  <div class="subtitle3">You’ve been invited to join</div>
                  <div class="org-name">{{ invite.orgName }}</div>
                  <div class="org-members">{{ invite.members }} members</div>
                  <div class="join-btn" (click)="acceptInvite(invite._id, invite.orgId)">Join this organization</div>
                  <div class="join-btn reject" (click)="cancelInvite(invite._id, activeInvites?.length)">Reject
                                                                                                         invitation
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngrxLet="selectActiveOrganization$; let activeOrganization">
          <div *ngIf="activeOrganization" fxLayout="column" [formGroup]="organizationForm">
            <div class="organization-section" fxLayout="row" fxLayoutAlign="start start">
              <div fxLayout="column" class="left-block">
                <span class="label-title">Organization Settings</span>
                <span class="label-subtitle"> Get your projects up and running faster by inviting your team to collaborate. </span>
              </div>
              <div fxLayout="column" class="right-block" fxFlex>
                <ui-prefix-input
                  [disabled]="!isOrganizationSettingsEditOrganization"
                  [label]="'Name of Organization'"
                  [prefix]="'www.lumana.ai/'"
                  [invalid]="
                (organizationForm.get('orgName')?.invalid && organizationForm.get('orgName')?.touched) ||
                (selectOrganizationNameExist$ | async)
              "
                  formControlName="orgName"
                ></ui-prefix-input>
              </div>
            </div>
            <ng-container *ngIf="isOrganizationSettingsEditOrganization">
              <div
                class="organization-section flex-row" [formGroup]="$any(organizationForm.get('mfa'))"
              >
                <mat-checkbox color="primary" formControlName="enabled"></mat-checkbox>
                <div class="flex-col">
                  <div class="label-title-v2">Two-factor authentication</div>
                  <div class="label-subtitle">Require from users from the organization two forms of identification</div>
                </div>
              </div>
              <div
                class="organization-section flex-row"
              >
                <div class="form-row mr-8">
                  <div class="switch-wrapper">
                    <span class="item c-p"
                          [class.active]="organizationForm.get('videoQuality').value === quality.SQ"
                          (click)="changeQuality(quality.SQ)"
                    >SQ</span>
                    <span class="item c-p"
                          [class.active]="organizationForm.get('videoQuality').value === quality.HQ"
                          (click)="changeQuality(quality.HQ)"
                    >HQ</span>
                  </div>
                </div>
                <div class="flex-col">
                  <div class="label-title-v2">Video quality</div>
                  <div class="label-subtitle">Set globally quality of video on walls and camera live view</div>
                </div>
              </div>
              <div
                class="organization-section flex-row"
              >
                <mat-checkbox color="primary" formControlName="showCores"></mat-checkbox>
                <div class="flex-col">
                  <div class="label-title-v2">Default show cores</div>
                  <div class="label-subtitle">Set the default show cores checkbox that will be displayed in the camera
                                              page.
                    <br>
                                              Users can still change the show core selection on the page
                                              but they cannot change the default.
                  </div>
                </div>
              </div>
              <ng-container *ngIf="selectIsDeveloper$ | ngrxPush">
                <div class="mt-16 flex-col gap-8 developer-options-wrapper">
                  <div class="bold">Developer options</div>
                  <div class="flex-row gap-8 mt-16">
                    <ui-toggle formControlName="localLiveStream"></ui-toggle>
                    <div>Enable local live stream feature</div>
                  </div>
                  <div class="flex-row gap-8 mt-16">
                    <ui-toggle formControlName="webSocketPlayback"></ui-toggle>
                    <div>Enable websocket playback feature</div>
                  </div>
                  <div class="flex-row gap-8 mt-16">
                    <ui-toggle formControlName="forceWebrtcRelay"></ui-toggle>
                    <div>Force WebRTC relay</div>
                  </div>
                  <div class="flex-col gap-8 mt-16">
                    <div class="">Live view type</div>
                    <mat-select class="ui-selector" formControlName="liveViewType" [style.width.px]="200">
                      <mat-option [value]="LiveViewType.Webrtc">Webrtc</mat-option>
                      <mat-option [value]="LiveViewType.Livekit">LiveKit</mat-option>
                      <mat-option [value]="LiveViewType.CloudflareCalls">Cloudflare Calls</mat-option>
                    </mat-select>
                  </div>
                  <div class="flex-col gap-8 mt-16">
                    <div class="">Mobile local live view type:</div>
                    <mat-select class="ui-selector" formControlName="mobileLocalLiveViewType" [style.width.px]="200">
                      <mat-option [value]="mobileLocalLiveViewTypes.None">None</mat-option>
                      <mat-option [value]="mobileLocalLiveViewTypes.Rtsp">Rtsp</mat-option>
                      <mat-option [value]="mobileLocalLiveViewTypes.Hls">Hls</mat-option>
                    </mat-select>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="flex-col gap-8 mt-24 org-id-wrapper">
              <div>Organization ID</div>
              <div class="flex-row start-center input-wrapper">
                <input class="ui-input w-100" disabled [value]="activeOrganization.orgId">
                <ui-copy-clipboard [copyContent]="activeOrganization.orgId"
                                   style="margin-left: 8px"
                                   toolTip="Copy"
                ></ui-copy-clipboard>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

</div>
<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>
