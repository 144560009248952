<div fxLayout="column" fxLayoutAlign="end start" class="ptz" fxLayoutGap="15px">
  <div *ngIf="enabled.value" fxLayout="column" fxLayoutGap="10px" class="ptz-zoom-wrapper">
    <div fxLayoutAlign="center center" class="c-p clickable" (click)="zoomIn()">
      <mat-icon svgIcon="ptz-zoom-in"></mat-icon>
    </div>
    <div fxLayoutAlign="center center" class="c-p clickable" (click)="zoomOut()">
      <mat-icon svgIcon="ptz-zoom-out"></mat-icon>
    </div>
  </div>
  <div *ngIf="enabled.value" class="ptz-joystick-wrapper">
    <div fxLayout="row" fxLayoutAlign="center center" class="ptz-joystick-home clickable" (click)="home()">
      <mat-icon svgIcon="ptz-home"></mat-icon>
    </div>
    <div fxLayoutAlign="center center" class="ptz-joystick-left clickable" (click)="left()">
      <mat-icon svgIcon="ptz-arrow"></mat-icon>
    </div>
    <div fxLayoutAlign="center center" class="ptz-joystick-right clickable" (click)="right()">
      <mat-icon svgIcon="ptz-arrow"></mat-icon>
    </div>
    <div fxLayoutAlign="center center" class="ptz-joystick-up clickable" (click)="up()">
      <mat-icon svgIcon="ptz-arrow"></mat-icon>
    </div>
    <div fxLayoutAlign="center center" class="ptz-joystick-down clickable" (click)="down()">
      <mat-icon svgIcon="ptz-arrow"></mat-icon>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="ptz-setup-wrapper" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" class="ptz-setup" fxLayoutGap="10px">
      <div>PTZ</div>
      <ui-toggle #enabled></ui-toggle>
      <ng-container *ngIf="enabled.value">
        <button mat-icon-button class="ptz-save clickable svg-fill-white flex-row center-center" (click)="setHome()">
          <span class="ui-icon-save"></span>
        </button>
        <button mat-icon-button
                class="ptz-settings clickable flex-row center-center"
                [class.svg-fill-primary]="this.displaySpeed"
                (click)="toggleSpeedDisplay()"
        >
          <span class="ui-icon-settings"></span>
        </button>
      </ng-container>
    </div>
    <div *ngIf="enabled.value && displaySpeed" fxLayout="row" fxLayoutAlign="start center" class="ptz-setup"
         fxLayoutGap="15px"
    >
      <div fxFlex="" class="ptz-speed-label">PTZ Speed</div>
      <div fxLayout="row" fxLayoutAlign="start center" class="ptz-speed-options">
        <ng-container *ngFor="let speedOption of ptzSpeedOptions; let i = index">
          <div (click)="setSpeed(i)" [class.selected]="speed === i">{{ speedOption | titlecase }}</div>
          <div *ngIf="i != ptzSpeedOptions.length - 1" class="divider"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
