import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IdentityModel } from '@models/identity.model';
import { IdentityActions } from '@states/identity/identity.action-types';

export default interface IdentityState extends EntityState<IdentityModel.IdentityDocument> {

}

export const adapter: EntityAdapter<IdentityModel.IdentityDocument> = createEntityAdapter<IdentityModel.IdentityDocument>({
  selectId: (role: IdentityModel.IdentityDocument) => role.provider,
});

export const initialState: IdentityState = adapter.getInitialState({});

export const identityStateReducer = createReducer(
  initialState,
  on(IdentityActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(IdentityActions.getUserIdentitiesSuccess, (state, { identities }) => {
    return adapter.setAll(identities, {
      ...state,
    });
  }),
);
