<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="custom-slider">
  <div fxLayout="row"
       fxLayoutAlign="center center"
       class="min"
  >{{ slider.value }} {{ unit === PeriodUnit.hours ? 'Hrs' : (unit | slice:0: 3) | titlecase }}
  </div>
  <ngx-slider #slider fxLayout="row" fxFlex="" [(value)]="minValue"
              [(highValue)]="maxValue"
              [options]="options"
              (userChangeEnd)="change($event)"
  ></ngx-slider>
  <div fxLayout="row"
       fxLayoutAlign="center center"
       class="max"
  >
    <ng-container *ngIf="(unit === PeriodUnit.minutes || unit === PeriodUnit.seconds) && slider.highValue === 61 || (unit === PeriodUnit.hours && slider.highValue === 25); else highDefault">
      Any
    </ng-container>
    <ng-template #highDefault>
      {{ slider.highValue }} {{ unit === PeriodUnit.hours ? 'Hrs' : (unit | slice:0: 3) | titlecase }}
    </ng-template>
  </div>
</div>
