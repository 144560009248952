<div fxLayout="column" fxLayoutAlign="space-between center" class="playback-timeline" fxFlexFill fxLayoutGap="10px">
  <div
    #downloadArea
    fxLayoutAlign="center center"
    class="bar-line-download-area c-p"
    [ngClass]="{ 'no-border': downloadArea.clientWidth < 5, show: downloadArea.clientWidth > 10 }"
    (mousedown)="startDownloadAreaDrag($event); $event.stopPropagation()"
    (mouseup)="releaseDownloadAreaDrag($event); $event.stopPropagation()"
    (mousemove)="downloadAreaDrag($event); $event.stopPropagation()"
    (mouseleave)="releaseDownloadAreaDrag($event); $event.stopPropagation()"
  ></div>
  <div
    #wrapper
    fxLayoutAlign="start start"
    class="bar-line-current-wrapper"
    (mousedown)="startTimelineDrag($event)"
    (mouseup)="releaseTimelineDrag($event)"
    (mousemove)="timelineDrag($event)"
    (mouseleave)="releaseTimelineDrag($event)"
    (dblclick)="selectDownloadAreaStart($event)"
  >
    <div
      #current
      class="bar-line-current"
      cdkDragLockAxis="x"
      cdkDrag
      cdkDragBoundary=".bar-line-current-wrapper"
      [cdkDragFreeDragPosition]="currentPosition"
      (cdkDragReleased)="currentReleased($event)"
      (cdkDragMoved)="updateCurrent($event)"
      (cdkDragStarted)="dragStarted()"
      (mousedown)="this.dragging = true"
    >
      <div cdkDragHandle fxLayout="row" fxLayoutAlign="center center" class="timeline-handle-wrapper">
        <!--        <mat-icon cdkDragHandle>fiber_manual_record</mat-icon>-->
        <div class="timeline-handle" fxLayoutAlign="center center">
          {{ currentTs + videoCurrentTime | date : 'h:mm:ss a' : timezoneGmt }} {{ timezoneAbbreviation() }}
        </div>
        <div class="bar-line-current-tooltip" *ngIf="false" [ngClass]="{ hide: (!dragging && !draggingTimeLine) || selectDownloadArea }">
          {{ currentTs + videoCurrentTimeRaw | date : 'HH:mm:ss' : timezoneGmt }}
        </div>
      </div>
    </div>
  </div>
  <div fxLayoutAlign="start start" class="w-100 no-us">{{ start | date : 'MMM d' : timezoneGmt }}</div>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="bar-line-wrapper w-100" fxFlex [class.dragging]="draggingTimeLine">
    <div
      fxLayoutAlign="end end"
      *ngFor="let line of verticalLines"
      class="bar-line"
      [ngClass]="{ full: (line + numVerticalSpaceRatioDragOffset) % numVerticalSpaceRatio === 0 }"
    >
      <div></div>
    </div>
  </div>
  <div #timesWrapper fxLayout="row" fxLayoutAlign="space-between center" class="w-100 no-us">
    <div *ngFor="let i of verticalSpace">{{ start + space * i | date : 'h:mm:ss' : timezoneGmt }}</div>
  </div>
</div>
