import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';
import { VisibilityChangeDirective } from './visibility-change.directive';
import { ElementLoadedDirective } from './element-loaded.directive';
import { MatSelectStyleDirective } from './mat-select-style.directive';

@NgModule({
  declarations: [DragDropFileUploadDirective, VisibilityChangeDirective, ElementLoadedDirective, MatSelectStyleDirective],
  imports: [CommonModule],
  exports: [DragDropFileUploadDirective, VisibilityChangeDirective, ElementLoadedDirective, MatSelectStyleDirective],
})
export class DirectivesModule {
}
