<div fxLayout="row" fxLayoutAlign="end center">
  <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
</div>
<div class="body">
  <ui-input [(ngModel)]="lines" label="Lines" [style]="inputStyle.labelOutside"></ui-input>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="end center">
  <button mat-flat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="ok()">Ok</button>
</div>
