import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchSelectorOptions } from './search-selector.model';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchService } from '../advanced-search.service';
import { ObjectAttributesComponent } from '../../object-attributes/object-attributes.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchQueryOperator } from '@enums/search.enum';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-search-selector',
  templateUrl: './search-selector.component.html',
  styleUrls: ['./search-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectorComponent),
      multi: true,
    },
  ],
})
export class SearchSelectorComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('selector')
  selector: MatSelect;

  @Input()
  searchSelectorOptions: SearchSelectorOptions;

  @Output()
  selection: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  showAdvanced = false;

  @Input()
  label: string = 'Search people, vehicles...';

  @Input()
  value: string = SearchQueryOperator.AND;

  @Input()
  disabled: boolean = false;

  @Input()
  asButton = false;

  @Input()
  multi = false;

  constructor(advancedSearchService: AdvancedSearchService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  emitSelection(change: MatSelectChange) {
    const val = change.value;
    if (val === 'advanced') {
      this.selection.emit('');
      return;
    }
    this.selection.emit(val);
    this.updateValue(val);
  }

  openAdvanced() {
    const dialogRef = this.dialog.open(ObjectAttributesComponent, {
      minWidth: '600px',
      width: '70vw',
      maxWidth: '1200px',
      data: {
        multi: this.multi,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public updateValue(insideValue: string) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};
}
