<label class="upload-drop-zone" for="singleFile" appDragDropFileUpload (fileDropped)="upload($event)">
  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="UiUploadLayout.LARGE">
      <div fxLayout="column" fxLayoutAlign="center center" class="file-upload-inner-large" fxLayoutGap="15px">
        <span class="ui-icon-upload"></span>
        <h2>Upload file</h2>
        <div class="description">Drag & Drop (Accepted files: .csv and JSON) or</div>
        <ui-button colorBgr="#4367F6" colorText="white" text="Upload from computer"></ui-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="UiUploadLayout.DEFAULT">
      <div class="file-upload-inner" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span class="ui-icon-upload"></span>
        <h2>Upload a new asset</h2>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span class="upload-text">Drag and Drop here or</span>
          <span class="click-upload">&nbsp;browse files</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</label>
<div *ngIf="uploadedFile && !hideList" fxLayout="row" fxLayoutAlign="space-between center" class="upload-row">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="" fxLayoutGap="10px">
    <span class="filename">{{ uploadedFile.name }}</span>
    <mat-progress-bar
      *ngIf="progressEnabled && (isUploading || (uploadProgress$ | async).state === 'DONE')"
      [mode]="(uploadProgress$ | async).state === 'PENDING' ? 'buffer' : 'determinate'"
      [value]="(uploadProgress$ | async).progress"
    ></mat-progress-bar>

    <span *ngIf="isUploading || (uploadProgress$ | async).state === 'DONE'"> {{ (uploadProgress$ | async).progress }}
      % </span>
    <span *ngIf="!isUploading && (uploadProgress$ | async).state === 'DONE'"> Uploaded </span>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span class="clear-btn ui-icon-close" (click)="clear()"></span>
    <ui-progress-button
      (clickAction)="uploadAction()"
      [loading]="isUploading"
      icon="upload_file"
      text1="Upload"
      text2="Done"
    ></ui-progress-button>
  </div>
</div>
<input type="file" hidden id="singleFile" [multiple]="false" (change)="fileChangeEvent($event)"/>
