import { createReducer, on } from '@ngrx/store';
import * as ActivityLogActions from '@states/activity-log/activity-log.actions';
import { ActivityDailyEventGroup, ActivityLog, ActivityLogFilter, ActivityLogMethod } from '@models/activity-log';
import moment from 'moment';
import { UserOrganizationDropDown } from '@models/organization.model';
import { ActivityLogFilterName } from '@enums/activity-log.enum';
import * as _ from 'lodash';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;


const dateRangeInitial = {
  start: moment()
    .subtract(10, 'day')
    .toString(),
  end: moment()
    .add(1, 'minutes')
    .toString(),
};
export const defaultDateRange = {absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: {unit: CustomUnit.minutes, value: 5}};

export interface ActivityLogState {
  activityLogs: ActivityLog[];
  limit: number;
  isLastPage: boolean;
  lastActivityTimestamp: string;
  filters: ActivityLogFilter;
  orgUsers: UserOrganizationDropDown[];
  groupedLogs: { [key: string]: ActivityDailyEventGroup[] };
}

export const initialState: ActivityLogState = {
  activityLogs: null,
  limit: 30,
  isLastPage: false,
  lastActivityTimestamp: null,
  filters: {
    events: [],
    users: [],
    orgs: [],
    dateRange: defaultDateRange,
  },
  orgUsers: [],
  groupedLogs: null,
};

export const activityLogStateReducer = createReducer(
  initialState,
  on(ActivityLogActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ActivityLogActions.resetToInitialStateWithoutFilters, state => {
    return {
      ...initialState,
      filters: state.filters,
      orgUsers: state.orgUsers,
    };
  }),
  on(ActivityLogActions.nextPage, state => {
    return {
      ...state,
      lastActivityTimestamp: state.activityLogs[state.activityLogs.length - 1].created_at,
    };
  }),
  on(ActivityLogActions.getActivityLogSuccess, (state, {activityLogs}) => {
    return {
      ...state,
      activityLogs: state.activityLogs ? state.activityLogs.concat(activityLogs) : [...activityLogs],
      isLastPage: activityLogs.length < initialState.limit,
    };
  }),
  on(ActivityLogActions.setDateRangeFilter, (state, {dateRange}) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        dateRange,
      },
    };
  }),
  on(ActivityLogActions.setFilter, (state, {filter, value}) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    };
  }),
  on(ActivityLogActions.setActivityReportFilter, (state, {filter, value}) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    };
  }),
  on(ActivityLogActions.getOrgUsersSuccess, (state, {users}) => {
    return {
      ...state,
      orgUsers: users,
    };
  }),
  on(ActivityLogActions.removeFilter, (state, {filter, id}) => {
    return {
      ...state,
      filters: removeFilter(state.filters, filter, id),
    };
  }),
  on(ActivityLogActions.removeActivityReportFilter, (state, {filter, id}) => {
    return {
      ...state,
      filters: removeFilter(state.filters, filter, id),
    };
  }),
  on(ActivityLogActions.refreshDateFilter, state => {
    const startDate = moment()
      .subtract(10, 'day');

    return {
      ...state,
      filters: {
        ...state.filters,
        start: moment(startDate)
          .toString(),
        end: moment()
          .toString(),
      },
    };
  }),
  on(ActivityLogActions.findAndGroupByDailyEventsSuccess, (state, {groupedLogs}) => {
    return {
      ...state,
      groupedLogs,
    };
  }),
);

const removeFilter = (filters: ActivityLogFilter, filter: ActivityLogFilterName, id: any): ActivityLogFilter => {
  const selectedFilters = _.cloneDeep(filters);
  switch (filter) {
    case ActivityLogFilterName.events:
      const eventIndex = (selectedFilters[filter] as ActivityLogMethod[]).findIndex(filter => filter.id === id);
      if (eventIndex > -1) {
        selectedFilters[filter].splice(eventIndex, 1);
      }
      break;
    case ActivityLogFilterName.users:
      const userIndex = (selectedFilters[filter] as UserOrganizationDropDown[]).findIndex(filter => filter._id === id);
      if (userIndex > -1) {
        selectedFilters[filter].splice(userIndex, 1);
      }
      break;
  }
  return selectedFilters;
};
