import { createAction, props } from '@ngrx/store';
import { OrgGroupModels } from '@models/org-group.model';

export const resetToInitialState = createAction('[ORG-GROUPS] Reset to Initial state');

export const getOrgGroups = createAction('[ORG-GROUPS] Get OrgGroups', props<{ orgGroupType: OrgGroupModels.OrgGroupType }>());
export const getOrgGroupsSuccess = createAction('[ORG-GROUPS] Get OrgGroups Success', props<{ orgGroups: OrgGroupModels.OrgGroup[] }>());

export const setOrgGroups = createAction('[ORG-GROUPS] Set OrgGroups', props<{ orgGroups: OrgGroupModels.OrgGroup[] }>());

export const addOrgGroup = createAction('[ORG-GROUPS] Save OrgGroup', props<{ orgGroup: OrgGroupModels.OrgGroup }>());
export const addOrgGroupSuccess = createAction('[ORG-GROUPS] Save OrgGroup Success', props<{ orgGroup: OrgGroupModels.OrgGroup }>());

export const removeOrgGroup = createAction('[ORG-GROUPS] Remove OrgGroup', props<{ orgGroup: OrgGroupModels.OrgGroup }>());
export const removeOrgGroupSuccess = createAction('[ORG-GROUPS] Remove OrgGroup Success', props<{ orgGroup: OrgGroupModels.OrgGroup }>());

export const editOrgGroup = createAction('[ORG-GROUPS] Edit OrgGroup', props<{ orgGroup: OrgGroupModels.OrgGroup }>());
export const editOrgGroupSuccess = createAction('[ORG-GROUPS] Edit OrgGroup Success', props<{ orgGroup: OrgGroupModels.OrgGroup }>());

export const setSelectedOrgGroup = createAction('[ORG-GROUPS] Set Selected OrgGroup', props<{ orgGroup: OrgGroupModels.OrgGroup }>());









