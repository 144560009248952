import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectorComparator } from './comparators/ui-selector-comparator';
import * as fastDeepEqual from 'fast-deep-equal';
import { smartSearch } from '../../../helpers/common.helpers';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'ui-selector',
  templateUrl: './ui-selector.component.html',
  styleUrls: ['./ui-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectorComponent),
      multi: true,
    },
  ],
})
export class UiSelectorComponent<T> implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input() options: any[];
  @Input() title: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() invalid = false;
  @Input() lazySearch = false;
  @Input() loading = false;
  @Input() hasBorder = true;
  @Input() hasBackground = true;
  @Input() multi = false;
  @Input() sortingEnabled = true;
  @Input() disabled = false;
  @Input() searchEnabled = true;
  @Input() selectAllEnabled = false;
  @Input() searchProperty: string | string[];
  @Input() compareWith: (item1: T, item2: T) => boolean;
  @Input() sortCompareWith: SelectorComparator<any>;
  @Input() showSelected = true;

  @Input() panelClasses: string[];

  @Input() optionTemplate: TemplateRef<any>;
  @Input() selectedOptionTemplate: TemplateRef<any>;

  @Input() value: any;
  @Input() validationMessage: string = null;
  @Input() readonly = false;
  @Input() size = 30;

  @Input() limit;
  /**
   * For array of object need identity which prop is value
   */
  @Input() valueProp: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() searchQuery: EventEmitter<any> = new EventEmitter();

  @ViewChild('defaultTemplate')
  private defaultTemplate: TemplateRef<any>;

  @ViewChild('selectorTemplate')
  private selectorTemplate: TemplateRef<any>;

  @ViewChild('selectorInput')
  private selectorInput: ElementRef<HTMLElement>;

  @ViewChild('dialogContainer')
  private dialogContainer: ElementRef<HTMLElement>;

  public sortedOptions: any[];
  public optionValues: any[];

  // Please pay attention to selectedValues. It is always ARRAY.
  // If multi is false, then it should be an array with the length of 1.
  // If multi is false, onChange and change should emit selectedValues[0]
  public selectedValues: any[] = [];

  public selectorItemsViewportHeight: number;
  public itemSize = 28;

  public opened = false;
  public allSelected = false;

  private dialogOpening = false;
  private maxItemsPerPage = 10;

  private dialogRef: MatDialogRef<any>;
  private clickOutsideEventHandler: (event: any) => void;
  private isFirstSetValue: boolean = true;

  constructor(public dialog: MatDialog, private cdr: ChangeDetectorRef, private window: Window) {
  }

  @HostListener('document:click', ['$event'])
  clickOutsideEvent(event: MouseEvent) {
    if (this.clickOutsideEventHandler) {
      this.clickOutsideEventHandler(event);
    }
  }

  public ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public ngAfterViewInit(): void {
    if (!this.optionTemplate) {
      this.optionTemplate = this.defaultTemplate;
    }
    if (!this.selectedOptionTemplate) {
      this.selectedOptionTemplate = this.optionTemplate;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.maxItemsPerPage = 10;
      if (this.searchEnabled) {
        this.maxItemsPerPage -= 2;
      }
      if (this.multi && this.selectAllEnabled) {
        this.maxItemsPerPage--;
      }

      this.sortedOptions = [...this.options];
      if (this.sortingEnabled) {
        this.sortOptions(this.sortedOptions);
      }

      this.resetOptions();
    }

    if (((changes['value'] && !areArraysEqual(changes['value']?.currentValue, changes['value']?.previousValue)) || changes['options']) && this.sortedOptions.length) {
      this.setValue();

      this.allSelected = this.selectedValues.length === this.sortedOptions.length;
    }
  }

  public writeValue(obj: any): void {
    this.value = obj;
    this.setValue();
    this.onChange(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public selectAll(): void {
    if (this.multi) {
      if (this.allSelected) {
        this.selectedValues = [];
      } else {
        this.selectedValues = [...this.sortedOptions];
      }
      this.allSelected = !this.allSelected;

      this.onMultiChange();
    }
  }

  public clearMulti(event: MouseEvent): void {
    if (!this.opened) {
      event.stopPropagation();
    }
    this.selectedValues = [];
    this.onMultiChange();
  }

  public search(query: string): void {
    if (this.lazySearch) {
      this.searchQuery.emit(query);
    } else {
      this.optionValues = smartSearch(this.sortedOptions, query, this.searchProperty);
      this.calculateViewportHeight();
    }
  }

  public isValueSelected(value: any): boolean {
    if (this.selectedValues) {
      if (this.compareWith) {
        return this.selectedValues.some(x => this.compareWith(value, x));
      } else {
        return this.selectedValues.includes(value);
      }
    }
    return false;
  }

  public setSelectedValue(value: any): void {
    if (this.multi) {
      if (this.isValueSelected(value)) {
        if (this.compareWith) {
          this.selectedValues = [...this.selectedValues.filter(x => !this.compareWith(value, x))];
        } else {
          this.selectedValues = [...this.selectedValues.filter(x => x !== value)];
        }
      } else {
        this.selectedValues = [...this.selectedValues, value];
      }

      this.allSelected = this.selectedValues.length === this.sortedOptions.length;
      this.onMultiChange();
    } else {
      this.selectedValues = [value];
      this.emitChange(value);
      if (!this.dialogOpening) {
        this.dialogOpening = true;
        this.dialogRef.close();
      }
    }
  }

  public toggleDialog(): void {
    if (!this.dialogOpening) {
      this.dialogOpening = true;

      if (!this.opened) {
        const position = this.getPosition();

        this.dialogRef = this.dialog.open(this.selectorTemplate, {
          hasBackdrop: false,
          width: `${this.selectorInput.nativeElement.offsetWidth}px`,
          position,
          panelClass: ['ui-selector-panel', ...this.panelClasses || []],
          closeOnNavigation: true,
        });

        this.dialogRef
          .afterOpened()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = this.closeDialogByClickOutsideEvent;
            this.opened = true;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            window.addEventListener('scroll', this.scroll.bind(this), true);
          });

        this.dialogRef
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.onTouch();
            this.resetOptions();
            this.clickOutsideEventHandler = null;
            this.opened = false;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            window.removeEventListener('scroll', this.scroll, true);
          });
      }
    }
  }

  private getPosition(): DialogPosition {
    const elementRectangle = this.selectorInput.nativeElement.getBoundingClientRect();
    const bottomSpace = this.window.innerHeight - elementRectangle.bottom;

    let panelHeight = this.searchEnabled ? this.selectorItemsViewportHeight + 2 * this.itemSize : this.selectorItemsViewportHeight;
    if (this.multi && this.selectAllEnabled) {
      panelHeight += this.itemSize;
    }
    panelHeight += 10;

    if (panelHeight < bottomSpace) {
      return { left: `${elementRectangle.left}px`, top: `${elementRectangle.bottom}px` };
    } else {
      let top = this.window.innerHeight - panelHeight;
      if (top < 5) {
        top = 5;
      }
      return { left: `${elementRectangle.left}px`, top: `${top}px` };
    }
  }

  private scroll(): void {
    if (this.dialogRef && this.opened) {
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }
  }

  private onMultiChange() {
    this.emitChange(this.selectedValues);
  }

  private emitChange(value: any) {
    this.change.emit(value);
    this.onChange(value);
    this.onTouch();
  }

  private closeDialogByClickOutsideEvent(event: MouseEvent) {
    const matDialogContainerElement = this.dialogContainer.nativeElement.parentElement;
    const elementRectangle = matDialogContainerElement.getBoundingClientRect();
    if (
      event.clientX <= elementRectangle.left ||
      event.clientX >= elementRectangle.right ||
      event.clientY <= elementRectangle.top ||
      event.clientY >= elementRectangle.bottom
    ) {
      this.dialogRef.close();
    }
  }

  private calculateViewportHeight(): void {
    if (this.optionValues.length > this.maxItemsPerPage) {
      this.selectorItemsViewportHeight = this.maxItemsPerPage * this.itemSize;
    } else {
      this.selectorItemsViewportHeight = this.optionValues.length * this.itemSize;
    }
  }

  private resetOptions() {
    if (this.sortedOptions !== undefined && this.sortedOptions !== null) {
      this.optionValues = [...this.sortedOptions];
      this.calculateViewportHeight();
    }
  }

  private sortOptions(options: any[]): void {
    if (this.sortCompareWith) {
      options.sort((a, b) => this.sortCompareWith.compareWith(a, b));
    } else {
      options.sort();
    }
  }

  private setValue() {
    if (this.value && this.optionValues?.length) {
      if (this.multi) {
        if (this.compareWith) {
          this.selectedValues = this.sortedOptions.filter(x => this.value.some((z: any) => this.compareWith(x, z)));
        } else {
          if (this.valueProp) {
            this.selectedValues = this.sortedOptions.filter(x => this.value.some((z: any) => x[this.valueProp] === z));
          } else {
            this.selectedValues = this.sortedOptions.filter(x => this.value.some((z: any) => x === z));
          }
        }
        if (!fastDeepEqual(this.value, this.selectedValues) && !this.isFirstSetValue) {
          this.onMultiChange();
        }
      } else {
        if (this.compareWith) {
          this.selectedValues = this.sortedOptions.filter(x => this.compareWith(x, this.value));
        } else {
          this.selectedValues = this.sortedOptions.filter(x => x === this.value);
        }

        const value = this.selectedValues?.[0];

        if (!fastDeepEqual(this.value, value)) {
          this.emitChange(value);
        }
      }
    } else {
      this.selectedValues = [];
    }
    this.isFirstSetValue = false;
  }

  private onChange: any = (value: any) => {
  };

  private onTouch: any = (value: any) => {
  };
}

const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1?.length !== arr2?.length) return false;
  return arr1?.every((value, index) => value === arr2[index]);
};
