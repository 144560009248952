import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { MainContentService } from '../layout/main-content.service';
import { SplashService } from '../framework/splash/splash.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // TODO: retreive all camera details and set all camera sources.
  // cam1source = 'http://d21y8tbtta4g0p.cloudfront.net/video_streams/N0sitmQhJb/HIK41/s.m3u8';
  cam1source = '';

  constructor(private mainContentservice: MainContentService) {}

  ngOnInit(): void {
    this.mainContentservice.setHeader({ title: `Home` });
    // this.splashService.hide();
  }
}
