import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'stringToDate',
})
export class StringToDatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): Date {
    return moment(value).toDate();
  }
}
