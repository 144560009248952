import { MenuItem } from '@models/menu.model';
import { createReducer, on } from '@ngrx/store';
import * as MenuActions from '@states/menu/menu.actions';
import { MenuKey } from '@enums/menu.enum';
import { MainMenuItem } from '../../../layout/main-menu.service';
import { mainMenuItems } from '../../../main-menu-items';

export default interface MenuState {
  level1Menu: MainMenuItem[];
  activeLevel1: MenuKey;
  level2Menu: MenuItem[];
  activeLevel2: MenuKey;
  isActiveLevel2Visible: boolean;
  activeLevel3: MenuKey;
  level3Menu: MenuItem[];
}

export const initialState: MenuState = {
  level1Menu: mainMenuItems,
  activeLevel1: null,
  level2Menu: null,
  activeLevel2: null,
  isActiveLevel2Visible: true,
  activeLevel3: null,
  level3Menu: null,
};

export const menuStateReducer = createReducer(
  initialState,
  on(MenuActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(MenuActions.setLevel2Menu, (state, { level2Menu }) => {
    return {
      ...state,
      level2Menu,
    };
  }),
  on(MenuActions.setActiveLevel2Menu, (state, { activeLevel2 }) => {
    return {
      ...state,
      activeLevel2,
    };
  }),
  on(MenuActions.setIsActiveLevel2Visible, (state, { isActiveLevel2Visible }) => {
    return {
      ...state,
      isActiveLevel2Visible,
    };
  }),
  on(MenuActions.setActiveLevel3Menu, (state, { activeLevel3 }) => {
    return {
      ...state,
      activeLevel3,
    };
  }),
  on(MenuActions.setLevel3Menu, (state, { level3Menu }) => {
    return {
      ...state,
      /**
       * map each menu level 2 and set level 3 for active. Could be changed if load too long.
       */
      level2Menu: state.level2Menu.map(item => {
        if (item.key === state.activeLevel2) {
          return {
            ...item,
            level3: level3Menu,
          };
        }
        return { ...item, level3: [] };
      }),
      // activeLevel2: initialState.activeLevel2,
    };
  }),
  on(MenuActions.setLevel3MenuByKey, (state, { key, level3Menu }) => {
    return {
      ...state,
      /**
       * map each menu level 2 and set level 3 for active. Could be changed if load too long.
       */
      level2Menu: state.level2Menu.map(item => {
        if (item.key === key) {
          return {
            ...item,
            level3: level3Menu,
          };
        }
        return { ...item, level3: [] };
      }),
      activeLevel2: initialState.activeLevel2,
    };
  }),

  on(MenuActions.disableLevel2Point, (state, { key }) => {
    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === key) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
    };
  }),
  on(MenuActions.renameLevel2Point, (state, { key, name }) => {
    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === key) {
          return {
            ...item,
            name,
          };
        }
        return item;
      }),
    };
  }),
  on(MenuActions.enableLevel2Point, (state, { key }) => {
    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === key) {
          return {
            ...item,
            disabled: false,
          };
        }
        return item;
      }),
    };
  }),

  on(MenuActions.enableLevel3Point, (state, { level2Key, key }) => {
    const level2Index = state.level2Menu.findIndex(menu => menu.key === level2Key);
    if (level2Index === -1 || !state.level2Menu[level2Index]?.level3?.length) {
      return {
        ...state,
      };
    }
    const level3Menu = state.level2Menu[level2Index].level3.map(item => {
      if (item.key === key) {
        return {
          ...item,
          disabled: false,
        };
      }
      return item;
    });

    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === level2Key) {
          return {
            ...item,
            level3: level3Menu,
          };
        }
        return { ...item, level3: [] };
      }),
    };
  }),

  on(MenuActions.disableLevel3Point, (state, { level2Key, key }) => {
    const level2Index = state.level2Menu.findIndex(menu => menu.key === level2Key);
    if (level2Index === -1 || !state.level2Menu[level2Index]?.level3?.length) {
      return {
        ...state,
      };
    }

    const level3Menu = state.level2Menu[level2Index].level3.map(item => {
      if (item.key === key) {
        return {
          ...item,
          disabled: true,
        };
      }
      return item;
    });

    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === level2Key) {
          return {
            ...item,
            level3: level3Menu,
          };
        }
        return { ...item, level3: [] };
      }),
    };
  }),
  on(MenuActions.renameLevel3Point, (state, { level2Key, key, name }) => {
    const level2Index = state.level2Menu.findIndex(menu => menu.key === level2Key);
    if (level2Index === -1 || !state.level2Menu[level2Index]?.level3?.length) {
      return {
        ...state,
      };
    }

    const level3Menu = state.level2Menu[level2Index].level3.map(item => {
      if (item.key === key) {
        return {
          ...item,
          name,
        };
      }
      return item;
    });

    return {
      ...state,
      level2Menu: state.level2Menu.map(item => {
        if (item.key === level2Key) {
          return {
            ...item,
            level3: level3Menu,
          };
        }
        return { ...item, level3: [] };
      }),
    };
  }),
);
