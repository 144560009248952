import { createReducer, on } from '@ngrx/store';
import * as TrackObjectActions from '@states/track-object/track-object.actions';
import { UiDatetimeRangePickerModel } from '../ui-datetime-range-picker.model';
import PickerState = UiDatetimeRangePickerModel.PickerState;
import PickerType = UiDatetimeRangePickerModel.PickerType;
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { DateTimeRangePickerActions } from './ui-datetime-range-picker.action-types';

export declare interface DateTimeRangePickerState extends PickerState {
  pickerType: PickerType;
  pickerCustom: boolean;
  // Describes the selected index
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  custom?: number;
  customUnit?: CustomUnit;
}

export const initialState: DateTimeRangePickerState = {
  pickerType: PickerType.RELATIVE,
  pickerCustom: false,
  // Describes the selected index
  minutes: undefined,
  hours: undefined,
  days: undefined,
  weeks: undefined,
  // In seconds
  custom: undefined,
  customUnit: CustomUnit.minutes,
};

export const dateTimeRangePickerReducer = createReducer(
  initialState,
  on(DateTimeRangePickerActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(DateTimeRangePickerActions.setPickerState, (state, { dateTimeRangePickerState }) => {
    return {
      ...state,
      ...dateTimeRangePickerState,
    };
  }),
  on(DateTimeRangePickerActions.setPickerType, (state, { pickerType }) => {
    return {
      ...state,
      pickerType,
    };
  })
);
