import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-software-version-modal',
  templateUrl: './update-software-version-modal.component.html',
  styleUrls: ['./update-software-version-modal.component.scss'],
})
export class UpdateSoftwareVersionModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public version: string;

  constructor(private matDialogRef: MatDialogRef<UpdateSoftwareVersionModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.version);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
