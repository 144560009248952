import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { NotificationService } from '../../services/notification.service';
import { catchError, mergeMap, share, switchMap } from 'rxjs';
import { NotificationActions } from '@states/notification/notification.action-types';
import { withLatestFrom } from 'rxjs/operators';
import { UserSelectors } from '@states/user/user.selector-types';

@Injectable()
export class NotificationEffects {

  public getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getUserNotifications),
      withLatestFrom(this.store$.pipe(select(UserSelectors.userProfile))),
      switchMap(([, { activeOrg, _id }]) => {
        return this.notificationService.getNotificationsByUser(activeOrg, _id)
          .pipe(
            switchMap(res => {
              const notifications = res?.notifications ?? [];
              // notifications.length = 6;
              const actions: Action[] = [];
              notifications.forEach(notification => {
                actions.push(NotificationActions.getNotificationById({ id: notification['id'] }));
              });
              return [
                NotificationActions.getUserNotificationsSuccess({ userNotifications: notifications }),
                ...actions,
              ];
            }),
            catchError(response => {
              return [NotificationActions.getUserNotificationsFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public getNotificationById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getNotificationById),
      mergeMap(({ id }) => {
        return this.notificationService.getNotificationById(id)
          .pipe(
            switchMap(res => {
              return [
                NotificationActions.getNotificationByIdSuccess({ notification: res }),
              ];
            }),
            catchError(response => {
              return [NotificationActions.getNotificationByIdFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public seenNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.seenNotification),
      mergeMap(({ id }) => {
        return this.notificationService.seenNotificationById(id)
          .pipe(
            switchMap(res => {
              return [
                NotificationActions.seenNotificationSuccess(),
              ];
            }),
            catchError(response => {
              return [NotificationActions.seenNotificationFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.deleteNotification),
      mergeMap(({ id }) => {
        return this.notificationService.deleteNotificationById(id)
          .pipe(
            switchMap(res => {
              return [
                NotificationActions.deleteNotificationSuccess(),
              ];
            }),
            catchError(response => {
              return [NotificationActions.deleteNotificationFail()];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.deleteAllNotifications),
      mergeMap(() => {
        return this.notificationService.deleteAllNotifications()
          .pipe(
            switchMap(res => {
              return [
                NotificationActions.deleteAllNotificationsSuccess(),
              ];
            }),
            catchError(response => {
              return [NotificationActions.deleteAllNotificationsFail()];
            }),
          );
      }),
      share(),
    ),
  );


  public scheduleNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.scheduleNotification),
      mergeMap(({ data }) => {
        return this.notificationService.schedule(data)
          .pipe(
            switchMap(res => {
              return [
                NotificationActions.scheduleNotificationSuccess(),
              ];
            }),
            catchError(response => {
              return [NotificationActions.scheduleNotificationFail()];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
  ) {
  }
}
