import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export enum TimeUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum WeekDay {
  Mon = 1,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  Sun,
}

@Component({
  selector: 'ui-relative-time-range-picker',
  templateUrl: './ui-relative-time-range-picker.component.html',
  styleUrls: ['./ui-relative-time-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiRelativeTimeRangePickerComponent implements OnChanges {
  @Input() data: any;

  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;
  public timeUnit = TimeUnit;
  public weekDays = WeekDay;

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.form = this.fb.group({
      unit: [TimeUnit.Day],
      startWeek: [WeekDay.Sun],
      selectedWeekDays: [],
      start: ['00:00'],
      end: ['23:59'],
    });
    this.form.patchValue(this.data);

    this.form.valueChanges.subscribe(res => {
      this.onChanged.emit(res);
    });
  }

  public selectWeekDay(days: WeekDay[]): void {
    this.form.patchValue({ selectedWeekDays: days });
  }

  public isDaySelected(day: WeekDay): boolean {
    const selectedWeekDays = this.form.get('selectedWeekDays').value ?? [];
    return selectedWeekDays.includes(day);
  }
}
