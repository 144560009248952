import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationUsersState } from '@states/organization-users/organization-users.reducer';

export const selectOrganizationUsersState = createFeatureSelector<OrganizationUsersState>('organizationUsersState');

export const selectOrganizationUsersEntities = createSelector(selectOrganizationUsersState, ({entities}: OrganizationUsersState) => entities ? Object.values(entities) : []);
export const selectFilters = createSelector(selectOrganizationUsersState, ({filters}: OrganizationUsersState) => filters);
export const selectIsLoading = createSelector(selectOrganizationUsersState, ({isLoading}: OrganizationUsersState) => isLoading);
export const selectIsInitialLoaded = createSelector(selectOrganizationUsersState, ({isInitialLoaded}: OrganizationUsersState) => isInitialLoaded);
export const selectPermissionGroups = createSelector(selectOrganizationUsersState, ({permissionGroups}: OrganizationUsersState) => permissionGroups);
export const selectSystemPermissionGroups = createSelector(selectOrganizationUsersState, ({ systemPermissionGroups }: OrganizationUsersState) => systemPermissionGroups);

export const selectOrganizationUsersEntitiesByIds = (ids: string[]) => createSelector(selectOrganizationUsersState, ({entities}: OrganizationUsersState) => {
  const users = entities ? Object.values(entities) : [];
  return users.filter(user => ids.includes(user._id));
});

export const selectUserEmailById = (id: string) => createSelector(selectOrganizationUsersState, ({entities}: OrganizationUsersState) => {
  const user = entities ? entities[id] : null;
  return user ? user.email : '';
});
