import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, tap, throwError } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { MenuKey } from '@enums/menu.enum';
import { LocationSelectors } from '@states/location/location.selector-types';
import { SessionDataAction } from '@enums/session-data.enum';
import { VariableActions } from '@states/variable/variable.action-types';
import { VariableSendModel } from '@models/variable.model';
import { VariableService } from 'src/app/development/variable.service';
import { SearchSelection, SearchSelectionCarProperty, SearchSelectionPersonProperty } from '@models/search.model';
import { PersonSelectionFormFields, SearchObjectTypes, VehicleSelectionFormFields } from '@enums/search.enum';
import { ageTypeRadioValues } from '@consts/alert-events.const';
import { AlertType, AnalyticClasses, ConfigurationFilterType, DetectionType } from '@enums/alert-events.enum';
import { AlertEventsConfigurationFilter, AlertEventSendModel } from '@models/alert-events.model';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { VariableType } from '@enums/variable.enum';
import { MenuActions } from '@states/menu/menu.action-types';
import { variableMenuLevel3AlertCreate, variableMenuLevel3SearchCreate } from '@consts/menu.const';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';

@Injectable()
export class VariableEffect {
  public pressContinue$ = createEffect(() => this.actions$.pipe(ofType(VariableActions.continueVariable), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressBack$ = createEffect(() => this.actions$.pipe(ofType(VariableActions.backVariable), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public detailsValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.validateDetails),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { variableName, selectedVariableType }]) => {
        if (variableName && selectedVariableType !== null) {
          return of(VariableActions.addValidMenuPoint({ point: MenuKey.details }));
        } else {
          return [SharedActions.showMessage({ warning: 'All fields are required' })];
        }
      }),
      share(),
    ),
  );

  public dataValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.validateData),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { selectedCameras, selectedVariableType }]) => {
        if (!!selectedCameras.length || selectedVariableType === VariableType.compound) {
          return of(VariableActions.addValidMenuPoint({ point: MenuKey.data }));
        } else {
          return [SharedActions.showMessage({ warning: 'You must select at least one camera' })];
        }
      }),
      share(),
    ),
  );

  public getVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getVariables),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { page, perPage, orderBy, orderDirection, query }]) => {
        return this.variableService.getVariables(page, perPage, orderBy, orderDirection, query)
          .pipe(
            switchMap(result => {
              return [
                VariableActions.setVariables({
                  variables: result.items,
                }),
                VariableActions.setTotalItemsCount({
                  totalItemsCount: result.totalItemsCount,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );
  private variableEditMenuLevel2: any;

  public getSelectedVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getSelectedVariable),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ id }, allCameras]) => {
        return this.variableService.getSelectedVariable(id)
          .pipe(
            switchMap(res => {
              if (res.variableType === VariableType.alert) {
                return [
                  VariableActions.addValidMenuPoint({ point: MenuKey.details }),
                  VariableActions.addValidMenuPoint({ point: MenuKey.data }),
                  VariableActions.addValidMenuPoint({ point: MenuKey.settings }),
                  VariableActions.setSelectedVariable({
                    selectedVariable: {
                      ...res,
                      selectedCamera: {
                        ...allCameras.entities[res.selectedCamera.cameraId],
                        ...res.selectedCamera,
                      },
                    },
                  }),
                  AlertEventsActions.setSelectedAlert({
                    selectedAlertEvent: {
                      ...res.event,
                      name: '',
                      selectedCamera: allCameras.entities[res.selectedCamera.cameraId],
                      multiCameras: [allCameras.entities[res.selectedCamera.cameraId]],
                    },
                  }),
                  MenuActions.setLevel3MenuByKey({
                    key: MenuKey.data,
                    level3Menu: variableMenuLevel3AlertCreate,
                  }),
                  AlertEventsActions.setAlertType({ alertType: res.event?.alertType }),
                  MenuActions.setActiveLevel2Menu({
                    activeLevel2: MenuKey.details,
                  }),
                ];
              }
              return [
                VariableActions.setSelectedVariable({
                  selectedVariable: {
                    ...res,
                    selectedCamera: res.selectedCamera
                      ? {
                        ...allCameras.entities[res.selectedCamera.cameraId],
                        ...res.selectedCamera,
                      }
                      : null,
                  },
                }),
                MenuActions.setLevel3MenuByKey({
                  key: MenuKey.data,
                  level3Menu: null,
                }),
                MenuActions.setActiveLevel2Menu({
                  activeLevel2: MenuKey.details,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public removeVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.removeVariable),
      switchMap(({ id, cameraId, locationId, edgeId }) => {
        return this.variableService
          .remove(id, {
            cameraId,
            locationId,
            edgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.removeEvent,
                }),
                SharedActions.setIsDeleting({ isDeleting: false }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );
  //
  // public enableAlertEvent$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(VariableActions.enableEvent),
  //     switchMap(({alert, enabled}) => {
  //       return this.variableService
  //         .updateAlertEvents(alert._id, {
  //           ...alert,
  //           enabled,
  //         })
  //         .pipe(
  //           switchMap((res) => {
  //             return [
  //               SharedActions.showMessage({
  //                 success: 'Alert has been updated',
  //               }),
  //             ];
  //           }),
  //         );
  //     }),
  //     share(),
  //   ),
  // );

  public syncVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.syncVariable),
      switchMap(({ variable, synced }) => {
        return this.variableService.sync(variable._id, variable)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sync,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.setQuery),
      debounceTime(400),
      switchMap(() => of(VariableActions.getVariables())),
    ),
  );

  public loadLevel3Menu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.loadLevel3Menu),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([{ copy }, { selectedVariableType }]) => {
        switch (selectedVariableType) {
          case VariableType.alert:
            return [MenuActions.setLevel3Menu({ level3Menu: variableMenuLevel3AlertCreate })];
          case VariableType.search:
            return [
              MenuActions.setLevel3Menu({ level3Menu: variableMenuLevel3SearchCreate }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.cameras }),
            ];
          case VariableType.compound:
            return [MenuActions.setLevel3Menu({ level3Menu: null })];
          default:
            return [SharedActions.doNothing()];
        }
      }),
    ),
  );

  public getVariablesAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getVariablesAutocomplete),
      switchMap(({ query, notType }) => {
        return this.variableService.autocomplete(query, notType)
          .pipe(
            switchMap(res => {
              return [VariableActions.getVariablesAutocompleteSuccess({ variablesAutoComplete: res })];
            }),
            catchError(err => of(VariableActions.getVariablesAutocompleteFail())),
          );
      }),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(private actions$: Actions, private store$: Store<AppState>, private variableService: VariableService) {
  }
}

const typeFieldToColorField = (name: string) => {
  return name.replace('Type', 'Color');
};

const filtersAdapter = (searchSelections: SearchSelection[]): any => {
  let result: AlertEventsConfigurationFilter[] = searchSelections.map(searchSelection => {
    const configFilter: AlertEventsConfigurationFilter = {
      accessoryType: [],
      ageType: [],
      carryingType: [],
      colors: [],
      footwearColor: [],
      footwearType: [],
      genderType: [],
      greenList: '',
      hairColor: [],
      hairType: [],
      lowerbodyColor: [],
      lowerbodyType: [],
      make: [],
      model: [],
      redList: '',
      type: [],
      unrecognized: false,
      upperbodyColor: [],
      upperbodyType: [],
    };
    for(let property of Object.values(searchSelection.properties)) {
      if (searchSelection.type === SearchObjectTypes.PERSON) {
        if (!!property.enabled && !!property?.value?.length) {
          const propsToLower = property.value?.map(elem => elem.toLowerCase());
          configFilter[property.name] = propsToLower;
        }
        if (!!property.enabled && !!property.colors?.length) {
          const colorPropertyName = typeFieldToColorField(property.name);
          configFilter[colorPropertyName] = property.colors;
        }
      } else {
        for(let key of Object.keys(searchSelection.properties)) {
          configFilter[key] = searchSelection.properties[key];
        }
      }
    }
    return configFilter;
  });
  return result;
};

const loadConfigurationFilters = (state: AlertEventsState): any => {
  switch (state.trackedObject) {
    case AnalyticClasses.person:
      return {
        ageType: state.ageType,
        carryingType: state.carryingType,
        lowerbodyType: state.lowerBodyType,
        upperbodyType: state.upperBodyType,
        accessoryType: state.accessoryType,
        footwearType: state.footWearType,
        hairType: state.hairType,
        genderType: state.genderType,
        upperbodyColor: state.upperBodyColor,
        lowerbodyColor: state.lowerBodyColor,
        hairColor: state.hairColor,
        footwearColor: state.footWearColor,
      };
    case AnalyticClasses.vehicle:
      if (state.configurationFilterType === ConfigurationFilterType.specificAttributes) {
        return {
          make: state.carMake,
          model: state.carModel,
          type: state.carType,
          colors: state.carColor,
        };
      } else if (state.configurationFilterType === ConfigurationFilterType.licencePlate) {
        return {
          greenList: state.greenList,
          redList: state.redList,
          unrecognized: state.unrecognized,
        };
      }
      return {};
  }
};
