import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-no-data',
  templateUrl: './ui-no-data.component.html',
  styleUrls: ['./ui-no-data.component.scss'],
})
export class UiNoDataComponent implements OnInit {

  @Input() imgSrc: string;
  @Input() imgTooltip: string;
  @Input() title: string;
  @Input() description: string;
  @Input() createButtonText: string;
  @Input() isContentProjection: boolean = false;
  @Output() onCreate: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  create() {
    this.onCreate.emit();
  }

}
