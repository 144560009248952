import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-eth-configuration-modal',
  templateUrl: './eth-configuration-modal.component.html',
  styleUrls: ['./eth-configuration-modal.component.scss'],
})
export class EthConfigurationModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public configurationForm: UntypedFormGroup;

  constructor(private matDialogRef: MatDialogRef<EthConfigurationModalComponent>, private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.configurationForm = this.fb.group({
      dhcp: [false],
      address: ['', [Validators.required]],
      netmask: ['', [Validators.required]],
      network: [''],
      broadcast: [''],
      gateway: [''],
      dnsNameservers: [''],
      dnsSearch: [''],
      ethInterface: [''],
    });
  }

  public ok() {
    this.matDialogRef.close(this.configurationForm.value);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
