import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettings } from '@models/user-settings';
import { select, Store } from '@ngrx/store';
import * as UserSettingsSelectors from '@states/user-settings/user-settings.selectors';
import { AppState } from '../../../store/app.state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HoursFormat } from '@enums/user-settings.enum';
import { UiUserSettingsDateFormat, UiUserSettingsDateType } from '@enums/shared.enum';
import * as moment from 'moment-timezone';

/**
 * @Input() date -> any date or timestamp
 * @Input() dateTypeFormat -> Each date can be show in different formats like MM/YY/DDDD HH:mm or MM/YY,
 * developer is able to customize ouput format
 */

@UntilDestroy()
@Component({
  selector: 'ui-user-settings-date',
  templateUrl: './ui-user-settings-date.component.html',
  styleUrls: ['./ui-user-settings-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiUserSettingsDateComponent implements OnInit {
  public selectSelectedUserSettings$: Observable<UserSettings> = this.store$.pipe(select(UserSettingsSelectors.selectSelectedUserSettings));

  @Input() date; //in case of timestamp set in ms
  @Input() dateTypeFormat: UiUserSettingsDateType = UiUserSettingsDateType.mdyTime;
  @Input() timezone: string = moment.tz.guess();
  @Input() showTimeZone: boolean = true;

  public hoursFormat = HoursFormat;
  public uiUserSettingsDateTypes = UiUserSettingsDateType;
  public uiUserSettingsDateFormats = UiUserSettingsDateFormat;

  constructor(private store$: Store<AppState>) {
  }

  ngOnInit(): void {
  }

  public get timeZoneStr(): string {
    const tz = this.showTimeZone ? moment()
      .tz(this.timezone ?? moment.tz.guess())
      .format('z') : '';
    return tz;
  }
}
