export module PtzModels {
  export enum PtzSpeedOption {
    SLOW,
    MEDIUM,
    FAST,
  }

  export enum OnvifCommand {
    PtzMove,
    GotoHomePosition,
    SetHomePosition,
  }

  export interface IOnvifParamBase {
    xaddr: string;
    username: string;
    password: string;
  }

  export interface IOnvifMessageBase extends IOnvifParamBase {
    action: OnvifCommand;
  }

  export interface ISpeed {
    x: number;
    y: number;
    z: number;
  }

  export interface IPtzMoveData {
    speed: ISpeed;
    timeout: number;
  }

  export interface IPtzMoveDataRequest extends IPtzMoveData {}

  export type PtzSpeed = ISpeed;

  export interface PtzMoveRequestBody {
    locationId: string;
    edgeId: string;
    cameraId: string;
    speed: PtzSpeed;
    timeout: number;
  }

  export interface PtzHomeRequestBody {
    locationId: string;
    edgeId: string;
    cameraId: string;
  }
}
