import { AlertV2SelectedFlow } from './alerts-v2.model';

export namespace PulsationModels {

  export interface CameraStreamerProgressData {
    frameNumber: number;
    format: string;
    fps: number;
    width: number;
    height: number;
    timestamp: number;
  }

  /**
   * IMPORTANT use this ONLY for calculation status, when value is came from BE or socket. For display amd other checks use ComponentStatusDisplay
   */
  export enum ComponentStatus {
    Online = 'online',
    Offline = 'offline',
    Streaming = 'streaming',
    Stopped = 'stopped',
    NotAuthenticated = 'notAuthenticated',
    StreamNotFound = 'streamNotFound',
    Unknown = 'unknown',
    Init = 'init',
    Connected = 'connected',
    Installing = 'installing',
    Rebooting = 'rebooting',
    NotConnected = 'notConnected',
    Exit = 'exit',
    NotRecording = 'notRecording',
  }

  /**
   * IMPORTANT use this ONLY for DISPLAY status.
   * Original device status use ComponentStatus
   */
  export enum ComponentStatusDisplay {
    Offline = 'offline',
    Online = 'online',
    Unhealthy = 'unhealthy',

    // Edges
    Installing = 'Installing',

    //for storage
    NotRecording = 'NotRecording',
  }

  export const convertComponentStatusToComponentStatusDisplay = (cs: ComponentStatus): ComponentStatusDisplay => {
    if (cs === ComponentStatus.Offline) {
      return ComponentStatusDisplay.Offline;
    }
    if (cs === ComponentStatus.Online) {
      return ComponentStatusDisplay.Online;
    }
    if (cs === ComponentStatus.Installing) {
      return ComponentStatusDisplay.Installing;
    }
    if (cs === ComponentStatus.NotRecording) {
      return ComponentStatusDisplay.NotRecording;
    }
    return ComponentStatusDisplay.Unhealthy;
  };

  export enum ComponentStatusAsNumber {
    Online,
    Offline,
    Streaming,
    Stopped,
    NotAuthenticated,
    StreamNotFound,
    Unknown,
    Init,
    Connected,
    Installing,
    Rebooting,
    NotConnected,
    Exit,
    NotRecording
  }

  export const unhealthyStatusArray: ComponentStatus[] = [
    ComponentStatus.Streaming,
    ComponentStatus.Stopped,
    ComponentStatus.NotAuthenticated,
    ComponentStatus.StreamNotFound,
    ComponentStatus.Unknown,
    ComponentStatus.Init,
    ComponentStatus.Connected,
    ComponentStatus.Installing,
    ComponentStatus.Rebooting,
    ComponentStatus.NotConnected,
    ComponentStatus.Exit,
  ];

  export const filteredByDisabledHealthComponentStatusArray: { label: string, value: ComponentStatusDisplay }[] = [
    {
      label: 'Online',
      value: ComponentStatusDisplay.Online,
    },
    {
      label: 'Offline',
      value: ComponentStatusDisplay.Offline,
    },
    {
      label: 'Unhealthy',
      value: ComponentStatusDisplay.Unhealthy,
    },
  ];

  export enum HeartbeatType {
    MultipleHeartbeats = 'multipleHeartbeats',
    Edge = 'edge',
    Camera = 'camera',
    Storage = 'storage',
    SmartStorage = 'smartStorage',
    Analytic = 'analytic',
    Thumbnails = 'thumbnails'
  }

  export interface ComponentStatusEvent<T = any> {
    eventId: string,
    selectedFlow: AlertV2SelectedFlow<T>
  }

  export interface ComponentHeartbeat {
    type: HeartbeatType,
    edgeId: string,
    cameraId: string,
    component: StreamerComponent, //for Sagi
    status: ComponentStatus,
    timestamp: number,
    componentStatusEvents: ComponentStatusEvent[],
    prevStatus: ComponentStatus,
    cameraStreamerProgressData?: CameraStreamerProgressData, //for Sagi
    timeUpdate: boolean, // meaning there is a change in status at edge
    cloudTriggered: boolean,
    offline: boolean,
    thumbmnails?: boolean;
    message?: string;
  }

  export interface AllCameraStatusHeartbeat {
    type: HeartbeatType.MultipleHeartbeats,
    edgeId: string,
    timestamp: number,
    componentHeartbeat: ComponentHeartbeat[]
    timeUpdate: boolean,
    cloudTriggered: boolean,
    offline: boolean
  }

  export enum StreamerComponent {
    Input = 'input', // camera main stream
    Hls = 'hls',
    HlsScaled = 'hlsscaled',
    HlsScaledHQ = 'hlsscaledhq',
    Storage = 'storage', // storage
    SmartStorage = 'smartstorage', // smart storage
    WebRTC = 'webrtc',
    WebRTCHQ = 'webrtchq',
    Analytics = 'analytics', // analytics
    Edge = 'edge', // edge
    SubStream1 = 'substream1', // substream
    Dockers = 'dockers'
  }

  export enum StreamerComponentAsNumber {
    Input,// camera main stream
    Hls,
    HlsScaled,
    HlsScaledHQ,
    Storage, // storage
    SmartStorage, // smart storage
    WebRTC,
    WebRTCHQ,
    Analytics, // analytics
    Edge, // edge
    SubStream1, // substream
    Dockers
  }

  export interface HearbeatFromThumbnails {
    edgeId: string;
    status: ComponentStatus;
    timestamp: number;
    thumbmnails?: boolean;
    message?: string;
  }


  // TODO: this should inherit from EventsBase
  export interface CameraState {
    cameraId: string;
    cameraStatus: ComponentStatus;
    prevCameraStatus: ComponentStatus;
    events: ComponentStatusEvent[];
    timestamp: number;
    message?: string;
  }

  export interface AnalyticState {
    cameraId: string;
    analyticStatus: ComponentStatus;
    prevAnalyticStatus: ComponentStatus;
    events: ComponentStatusEvent[];
    timestamp: number;
    message?: string;
  }

  export interface StorageState {
    cameraId: string;
    storageStatus: ComponentStatus;
    prevStorageStatus: ComponentStatus;
    events: ComponentStatusEvent[];
    timestamp: number;
    message?: string;
  }

  export interface SmartStorageState {
    cameraId: string;
    smartStorageStatus: ComponentStatus;
    prevSmartStorageStatus: ComponentStatus;
    events: ComponentStatusEvent[];
    timestamp: number;
    message?: string;
  }

  export interface SubstreamState {
    cameraId: string;
    substreamStatus: ComponentStatus;
    prevSubstreamStatus: ComponentStatus;
    events: ComponentStatusEvent[];
    timestamp: number;
    message?: string;
  }


  export interface HeartbeatStatusSessionDocument {
    edgeId: string;
    timestamp: number;
    status: ComponentStatus;
    events: ComponentStatusEvent[];
    prevStatus: ComponentStatus;
    cameraStatus: CameraState[];
    analyticStatus: AnalyticState[];
    storageStatus: StorageState[];
    smartStorageStatus: SmartStorageState[];
    substreamStatus: SubstreamState[];
    thumbmnails?: boolean;
    message?: string;
    offline?: boolean;
  }

  export namespace Store {

    export interface EdgePulsation {
      edgeId: string;
      timestamp: number;
      status: ComponentStatus;
      session?: PulsationModels.HeartbeatStatusSessionDocument;
      rebootCounter?: number;
    }

    export interface CameraPulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

    export interface AnalyticPulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

    export interface StoragePulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

    export interface SmartStoragePulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

    export interface SubstreamPulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

    export interface ApplePulsation {
      edgeId: string;
      cameraId: string;
      status: ComponentStatus;
      timestamp: number;
      rebootCounter?: number;
    }

  }


  export const getFilteredPulsationStatus = <T>(pulsationStatus: T & { status: PulsationModels.ComponentStatus, timestamp: number }): PulsationModels.ComponentStatus => {
    if (!pulsationStatus?.status) {
      return PulsationModels.ComponentStatus.Unknown;
    }
    return pulsationStatus.status;
  };

  export type ComponentStatusMajorChangeMessage = {
    orgId: string;
    orgIdHash: number;
    edgeId: string;
    edgeIdHash: number;
    cameraId: string;
    cameraIdHash: number;
    component: number;
    status: number;
    timestamp: number;
    timeUpdate: number;
    cloudTriggered: number;
    statusMChange: number;
    rebootCounter: number;
  };

  export interface PulsationResponse {
    res: ComponentStatusMajorChangeMessage[];
  }

}
