<ng-container *ngrxLet="locationName$; let locationName">
  <div #playerWrapper class="p-r playback-wrapper w-100">
    <div *ngIf="error" fxLayoutAlign="center center" class="reload-button">
      <button matTooltip="Reload playback" mat-icon-button (click)="play()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div *ngIf="loading && !error" fxLayoutAlign="center center" class="reload-button">
      <mat-spinner diameter="45"></mat-spinner>
    </div>
    <ui-area-selector *ngIf="!loading && !error && !player.inZoom()"
                      (selected)="player.zoomArea($event)"
    ></ui-area-selector>
    <ui-quality-selector
      [class.hidden]="isFullscreen || player.inZoom()"
      #qualitySelector
      class="playback-quality-selector"
      (qualityChanged)="qualityChange($event, player?.video?.currentTime * 1000 * speed || 0)"
    ></ui-quality-selector>
    <div
      *ngIf="!error"
      matRipple
      fxLayout="row"
      fxLayoutAlign="center center"
      class="take-snapshot c-p hover"
      matTooltip="Take snapshot"
      (click)="player.takeSnapshot(cameraName, locationName, time + player?.video?.currentTime * 1000 * speed || 0)"
    >
      <mat-icon>camera_alt</mat-icon>
    </div>
    <div
      *ngIf="!error"
      #t="matMenuTrigger"
      matRipple
      fxLayout="row"
      fxLayoutAlign="center center"
      class="speed-selector c-p"
      fxLayoutGap="5px"
      [ngClass]="{ hover: !t.menuOpen }"
      [matMenuTriggerFor]="speedMenu"
    >
      <mat-icon svgIcon="lightning"></mat-icon>
      <div>{{ speed }}x</div>
    </div>
    <mat-menu yPosition="below" xPosition="before" #speedMenu="matMenu" fxLayoutAlign="column" class="speed-options">
      <button *ngFor="let s of speeds" mat-menu-item (click)="changeSpeed(s)">
        <mat-icon>{{ s === speed ? 'check' : '' }}</mat-icon>
        {{ s }}
      </button>
    </mat-menu>
    <div
      *ngIf="!error"
      matRipple
      fxLayout="row"
      fxLayoutAlign="center center"
      class="maximize c-p"
      fxLayoutGap="5px"
      (click)="maximize()"
    >
      <mat-icon svgIcon="maximize"></mat-icon>
    </div>
    <app-inactivity *ngIf="isPlaying"
                    [countDown]="countDown"
                    [inactive]="inactive"
                    (keepPlaying)="keepPlaying()"
                    (pause)="pauseVid()"
                    (resume)="play()"
    ></app-inactivity>
    <stream-player
      #player
      [playback]="true"
      (mediaEvents)="handleMediaEvents($event)"
      (playing)="setPlaying()"
      (timeUpdate)="timeUpdate($event)"
      [src]="src"
      [allowZoom]="true"
      class="w-100"
    ></stream-player>
  </div>
</ng-container>
