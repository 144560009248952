import { createReducer, on } from '@ngrx/store';
import { PricingActions } from '@states/pricing/pricing.action-types';
import { LicenseTerms, Pricing, PricingCount, PricingLocation, PricingLocationInfo, CameraOptions, RetentionOptions } from '@models/pricing.model';

export declare interface PricingState {
  locations: PricingLocation[];
  pricing: Pricing;
  count: PricingCount;
  term: LicenseTerms;
}

export const initialState: PricingState = {
  locations: [],
  pricing: {
    boxes: 0,
    license: 0,
    cameras: 0,
    cloud: 0,
    total: 0,
    regularPrice: 0,
    oneTimePrice: 0,
    shipping: 0,
  },
  count: {
    box2tb: 0,
    box4tb: 0,
    box1u32tb: 0,
    box1u64tb: 0,
    box1u88tb: 0,
    camerasTurret5mp: 0,
    camerasTurret8mp: 0,
    camerasBullet8mp: 0,
    camerasDome5mp: 0,
    camerasBullet5mp: 0,
    camerasFisheye12mp: 0,
    camerasDome8mp: 0,
    camerasPtz: 0,
    camerasTurret5mpZoom: 0,
    ownCamerasTurret5mp: 0,
    ownCamerasBullet5mp: 0,
    ownCamerasDome5mp: 0,
    ownCamerasTurret8mp: 0,
    ownCamerasBullet8mp: 0,
    ownCamerasDome8mp: 0,
    ownCamerasFisheye12mp: 0,
    ownCamerasPtz: 0,
    ownCamerasTurret5mpZoom: 0,
    license: 0,
    cloudStorage30: 0,
    cloudStorage60: 0,
    cloudStorage90: 0,
    cloudStorage180: 0,
    cloudStorage365: 0,
    externalStorage30: 0,
    externalStorage60: 0,
    externalStorage90: 0,
    externalStorage180: 0,
    externalStorage365: 0,
    metadataStorage30: 0,
    metadataStorage60: 0,
    metadataStorage90: 0,
    metadataStorage180: 0,
    metadataStorage365: 0,
    snapshotStorage30: 0,
    snapshotStorage60: 0,
    snapshotStorage90: 0,
    snapshotStorage180: 0,
    snapshotStorage365: 0,
    verificationEvents: 0,
    verificationGuns: 0,
    verificationFire: 0,
    additionalSpeaker: 0,
    additionalSpeakerLicense: 0,
    additionalSensorLicense: 0,
    additionalAppleTvLicense: 0,
    additionalAlertStorage: 0,
    additionalEventTagsStorage: 0,
  },
  term: LicenseTerms.Years3,
};

export const pricingStateReducer = createReducer(
  initialState,
  on(PricingActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(PricingActions.setTerm, (state, { term }) => {
    return {
      ...state,
      term,
    };
  }),
  on(PricingActions.addLocation, (state, { location }) => {
    const locations = [...state.locations, location];
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.editLocation, (state, { index, location }) => {
    const locations = [...state.locations];
    locations[index] = location;
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.removeLocation, (state, { index }) => {
    const locations = [...state.locations];
    locations.splice(index, 1);
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.toggle1U, (state, { index }) => {
    const locations = [...state.locations];

    if (!!locations[index].include1u) {
      locations[index] = {
        ...locations[index],
        include1u: false,
      };
    } else {
      locations[index] = {
        ...locations[index],
        include1u: true,
      };
    }
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.setPricing, (state, { pricing, count }) => {
    return {
      ...state,
      pricing,
      count,
    };
  }),
  on(PricingActions.incrementCount, (state, { field }) => {
    const count = state.count;
    const update = count[field] + 1;
    return {
      ...state,
      count: {
        ...count,
        [field]: update,
      },
    };
  }),
  on(PricingActions.decrementCount, (state, { field }) => {
    const count = state.count;
    const update = count[field] - 1;
    return {
      ...state,
      count: {
        ...count,
        [field]: update,
      },
    };
  }),
  on(PricingActions.updateCount, (state, { field, count }) => {
    const _count = state.count;
    const update = count;
    return {
      ...state,
      count: {
        ..._count,
        [field]: update,
      },
    };
  }),
  // on(PricingActions.setPricing, (state, { pricingState }) => {
  //   return {
  //     ...state,
  //     ...pricingState,
  //   };
  // }),
);
