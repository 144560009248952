import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;

export interface WebrtcDebugDialogData {
  stats: { output: string; };
}

@Component({
  selector: 'app-webrtc-debug-dialog',
  templateUrl: './webrtc-debug-dialog.component.html',
  styleUrls: ['./webrtc-debug-dialog.component.scss'],
})
export class WebrtcDebugDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<WebrtcDebugDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WebrtcDebugDialogData,
  ) {
  }

  ngOnInit(): void {
  }

}
