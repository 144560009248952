import { createReducer, on } from '@ngrx/store';
import * as SettingsActions from '@states/settings/settings.actions';

export declare interface SettingsState {
  isValid: boolean;
}

export const initialState: SettingsState = {
  isValid: true,
};

export const settingsStateReducer = createReducer(
  initialState,
  on(SettingsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(SettingsActions.setValidation, (state, { isValid }) => {
    return {
      ...state,
      isValid,
    };
  })
);
