import { createAction, props } from '@ngrx/store';
import { LocationModel } from '../../../locations/location.model';
import { DeviceModel } from '@models/device.model';

export const resetToInitialState = createAction('[EDIT-LOCATION] Reset to Initial state');
export const resetDeviceState = createAction('[EDIT-LOCATION] Reset to Device state');

export const setSelectedLocationId = createAction('[EDIT-LOCATION] Set Selected Location Id', props<{ selectedLocationId: string }>());
export const setSelectedLocation = createAction('[EDIT-LOCATION] Set Selected Location', props<{ selectedLocation: LocationModel.LocationItem }>());
export const setSelectedSpeaker = createAction('[EDIT-LOCATION] Set Selected Speaker', props<{ selectedSpeaker: DeviceModel.LocationDeviceDocument }>());
export const setSelectedSpeakerById = createAction('[EDIT-LOCATION] Set Selected Speaker by id', props<{ speakerId: string }>());

export const setDeviceState = createAction('[EDIT-LOCATION] Set Device State', props<{ deviceStateChange: Partial<DeviceModel.DeviceState> }>());

export const testSpeaker = createAction('[EDIT-LOCATION] Test Speaker');
export const testSpeakerSend = createAction('[EDIT-LOCATION] Test Speaker Send', props<{ request: DeviceModel.DeviceConnectivityMsg }>());
export const testSpeakerSuccess = createAction('[EDIT-LOCATION] Test Speaker Success');
export const testSpeakerError = createAction('[EDIT-LOCATION] Test Speaker Error', props<{ errorMsg: string }>());

export const testSip = createAction('[EDIT-LOCATION] Test SIP');
export const testSipSend = createAction('[EDIT-LOCATION] Test SIP Send', props<{ request: DeviceModel.DeviceConnectivityMsg }>());
export const testSipSuccess = createAction('[EDIT-LOCATION] Test SIP Success');
export const testSipError = createAction('[EDIT-LOCATION] Test SIP Error', props<{ errorMsg: string }>());

export const addSpeaker = createAction('[EDIT-LOCATION] Add Speaker');
export const addSpeakerSuccess = createAction('[EDIT-LOCATION] Add Speaker Success');
export const addSpeakerError = createAction('[EDIT-LOCATION] Add Speaker Error');

export const deleteSpeaker = createAction('[EDIT-LOCATION] Delete Speaker', props<{ deviceId: string }>());
export const deleteSpeakerSuccess = createAction('[EDIT-LOCATION] Delete Speaker Success');
export const deleteSpeakerError = createAction('[EDIT-LOCATION] Delete Speaker Error');

export const pressSave = createAction('[EDIT-LOCATION] Press Save');
export const pressCreate = createAction('[EDIT-LOCATION] Press Create');

export const setCanDeactivate = createAction('[EDIT-LOCATION] Set Can Deactivate', props<{ canDeactivate: boolean }>());
