import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, shareReplay, tap } from 'rxjs';
import { LocalStorageService } from '../core/local-storage.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppUser } from './user.model';
import { Store } from '@ngrx/store';
import { api } from '@consts/url.const';
import { AppState } from '../store/app.state';
import { PaginationResult } from '@models/shared.model';
import { UserFilters } from '@models/organization.model';
import User = AppUser.User;
import { Invite } from '@models/invite.model';
import { Auth0 } from '../authentication/auth0.model';
import { UserModel } from '@models/user.model';

const USER_PERSISTENCE_KEY = 'user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject<AppUser.User | undefined>(undefined);

  public user$: Observable<AppUser.User | undefined> = this.userSubject.asObservable()
    .pipe(filter(user => !!user));


  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
  }

  createOrGetUserProfile(
    userId: string,
    accessToken: string,
    data = { persist: true, notifyLocalObserver: true },
  ): Observable<AppUser.User> {
    return this.http.put<AppUser.User>(`${environment.apiUrl}/users/${userId}/${accessToken}}`, {})
      .pipe(
        shareReplay(),
        tap(res => {
          if (data?.persist) {
            this.setUserAtLocalStorage(res);
          }

          if (data?.notifyLocalObserver) {
            this.userSubject.next(res);
          }
        }),
      );
  }

  updateUserProfile(
    userId: string,
    updateUserRequest: AppUser.UpdateUserProfileData,
    data = { persist: true, notifyLocalObserver: false },
  ): Observable<AppUser.User> {
    return this.http.patch<AppUser.User>(`${environment.apiUrl}/users/${userId}`, updateUserRequest)
      .pipe(
        shareReplay(),
        tap(res => {
          if (data?.persist) {
            this.setUserAtLocalStorage(res);
          }

          if (data?.notifyLocalObserver) {
            this.userSubject.next(res);
          }
        }),
      );
  }

  onSwUpdate(): Observable<any> {
    return this.http.post(api.users.onSwUpdate, {});
  }

  getUserFromLocalStorage(notifyObserver = true): AppUser.User | null {
    const user = this.localStorageService.getItemWithCheck(USER_PERSISTENCE_KEY);
    if (notifyObserver && !!user) {
      this.userSubject.next(JSON.parse(user));
    }
    return !!user ? JSON.parse(user) : null;
  }

  setUserAtLocalStorage(res): void {
    this.localStorageService.setItemWitchCheck(USER_PERSISTENCE_KEY, JSON.stringify(res));
  }

  public findAll(filters: UserFilters, pagination: { page: number; perPage: number }): Observable<PaginationResult<User>> {
    return this.http.post<PaginationResult<any>>(`${api.users.all}?page=${pagination.page}&perPage=${pagination.perPage}`, filters);
  }

  public changePassword(dto: { password: string; token: string }): Observable<any> {
    return this.http.post<any>(api.users.passwordChange, dto);
  }

  public resetPassword(dto: { email: string }): Observable<any> {
    return this.http.post<any>(api.users.passwordReset, dto);
  }

  public checkTokenIsValid(dto: { token: string }): Observable<any> {
    return this.http.post<any>(api.users.passwordTokenCheck, dto);
  }

  public getUserMfaStatus(): Observable<{ userMfa: boolean, orgsMfa: boolean }> {
    return this.http.get<{ userMfa: boolean, orgsMfa: boolean }>(api.users.mfaStatus);
  }

  public resetPasswordSecured(): Observable<any> {
    return this.http.post<any>(api.users.passwordResetSecured, {});
  }

  public signUpUser(user: Auth0.UserSignUpRequest): Observable<UserModel.LoginResponse> {
    return this.http.post<any>(api.users.signUp, user);
  }

  public getInvite(id: string): Observable<Invite> {
    return this.http.get<Invite>(api.invites.one(id));
  }

  public changeEmailRequest(email: string): Observable<{ _id: string }> {
    return this.http.post<{ _id: string }>(api.verification.email, { email });
  }

  public submitMfaCodeAndLogin(
    code: string,
    mfaToken: string,
    authProviderId: string,
    securityCode: string,
  ): Observable<Auth0.AuthenticationHTTPResponse> {
    let query = `${api.users.mfaSubmitAndLogin}?otp=${code}&mfaToken=${mfaToken}&securityCode=${securityCode}`;
    if (authProviderId) {
      query += `&authProviderId=${authProviderId}`;
    }
    return this.http.get<Auth0.AuthenticationHTTPResponse>(query);
  }

  public login(
    email: string, password: string, subUser?: AppUser.SubUser,
  ): Observable<UserModel.LoginResponse> {
    return this.http.post<{ mfa: boolean, qrCode: string, mfaToken: string, code: string } & Auth0.AuthenticationHTTPResponse>(`${api.users.login}`, { email, password, subUser });
  }
}
