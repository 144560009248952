import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-chip-group',
  templateUrl: './ui-chip-group.component.html',
  styleUrls: ['./ui-chip-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiChipGroupComponent),
      multi: true,
    },
  ],
})
export class UiChipGroupComponent implements OnInit, ControlValueAccessor {
  public value: string[] = [];
  @Input()
  public chips: { name: string; value: string }[] = [];

  constructor() {}

  ngOnInit(): void {}

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: string[]): void {
    if (!!val) {
      this.value = [...val];
    }
  }

  public chipsSelected(ev) {
    const index = this.value.findIndex(item => item === ev.value);
    if (index > -1) {
      this.value.splice(index, 1);
    } else {
      this.value.push(ev.value);
    }
    this.onChange(this.value);
    this.onTouched();
  }

  public isSelected(value: string) {
    return this.value.includes(value);
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};
}
