import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { Playback } from './playback.model';
import { SQSMsgInfo } from '../core/interfaces';
import { api } from '@consts/url.const';

@Injectable({
  providedIn: 'root',
})
export class PlaybackService {
  constructor(private httpService: HttpService, private store: Store) { }

  playbackCodec(data: Playback.PlatbackCodecRequest): Observable<SQSMsgInfo> {
    const url = api.playback.codec();
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackStart(data: Playback.PlaybackStartRequest): Observable<Playback.PlaybackStartResponse> {
    const url = `${environment.apiUrl}/cameras/camera/playback/start`;
    return this.httpService.http.post<Playback.PlaybackStartResponse>(url, data);
  }

  playbackStop(data: Playback.PlaybackStopRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/stop`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackExtend(data: Playback.PlaybackExtendRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/extend`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackExtendProcess(data: Playback.PlaybackExtendProcessRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/extend-process`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackRewind(data: Playback.PlaybackRewindRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/rewind`;
    return this.httpService.http.post<SQSMsgInfo>(url, data).pipe(debounceTime(5000), distinctUntilChanged());
  }

  playbackResolution(data: Playback.PlaybackResolutionRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/resolution`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackSpeed(data: Playback.PlaybackSpeedRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/speed`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackDownload(
    data: Playback.PlaybackDownloadRequest
  ): Observable<{ sqsMessgaeInfo: SQSMsgInfo; filename: string; sessionId: string }> {
    const url = `${environment.apiUrl}/cameras/camera/playback/download`;
    return this.httpService.http.post<{ sqsMessgaeInfo: SQSMsgInfo; filename: string; sessionId: string }>(url, data);
  }

  playbackClose(data: Playback.PlaybackCloseRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/close`;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  playbackLocation(data: Playback.PlaybackLocationRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/cameras/camera/playback/location`;
    return this.httpService.http.post<SQSMsgInfo>(url, data).pipe(debounceTime(5000), distinctUntilChanged());
  }

  async isCodecSupported(data: Playback.PlaybackCodecResponse): Promise<boolean> {
    if ("mediaCapabilities" in navigator) {

      const mediaDecodingConfiguration: MediaDecodingConfiguration = {
        type: "file",
        video: {
          contentType: `video/mp4;codecs=${data.codec}`,
          bitrate: +data.bitrate,
          width: +data.width,
          height: +data.height,
          framerate: +data.framerate
        }
      }

      let codec = await navigator.mediaCapabilities.decodingInfo(mediaDecodingConfiguration);

      return !!codec;

    }

    return false;


  }
}
