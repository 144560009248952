import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Person } from '@models/people.model';
import { DoorModels } from '@models/door.model';

@Component({
  selector: 'ui-door-icon',
  templateUrl: './ui-door-icon.component.html',
  styleUrls: ['./ui-door-icon.component.scss'],
})
export class UiDoorIconComponent implements OnInit {

  @Output() add = new EventEmitter<void>();
  @Output() addExisting = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @Input() door: DoorModels.Door;
  @Input() small = false;
  @Input() allowDelete = false;

  img;

  constructor() {
  }


  ngOnInit(): void {
    const door = this.door;
    this.img = `${environment.aws.trainingThumbnailsUrl}/training/${door.edgeId}/${door.cameraId}/${door.closeState}`;
  }

}
