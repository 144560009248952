import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { DayOfWeek } from '@enums/shared.enum';
import { AlertV2Schedule, TimeRange, UiSchedule } from '@models/alerts-v2.model';
import { daySettingScheduleV2, daysSettingSchedule, nameDayOfWeek } from '@consts/alert-events.const';
import * as moment from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { MatMenuTrigger } from '@angular/material/menu';

export interface UiScheduleDialogData {
  schedule: UiSchedule[];
  timezone?: string;
}

export interface UiScheduleDialogResult {
  schedule: UiSchedule[];
}

@Component({
  selector: 'ui-schedule-dialog',
  templateUrl: './ui-schedule-dialog.component.html',
  styleUrls: ['./ui-schedule-dialog.component.scss'],
})
export class UiScheduleDialogComponent implements OnInit {

  public nameDayOfWeek = nameDayOfWeek;

  public tooltipTime;
  public tooltipPosition;
  public tooltipVisible = false;
  public tooltipIndex;

  public selectionVisible = false;
  public selectionPosition = { x: '100px', y: '100px' };
  public selectionIndex = -1;
  public selectedRangeIndex = -1;
  public currentSelection: TimeRange = { from: '12:00 AM', to: '12:00 AM' };
  public currentSelectionRepeat: number[] = [];

  public inSelection = false;

  public locale12 = !!(new Intl.DateTimeFormat().resolvedOptions().hour12);

  public inRepeat(index: number) {
    return this.currentSelectionRepeat.includes(index);
  }

  public schedule: AlertV2Schedule[];

  public DayOfWeek = DayOfWeek;
  public days: string[] = Object.values(DayOfWeek)
    .filter((day) => typeof day === 'string') as string[];
  public hours = [
    '12 AM',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
    '12 AM',
  ];

  drag = false;
  dragStartX = -1;
  dragStartFrom = -1;
  dragStartTo = -1;

  constructor(private renderer: Renderer2, private dialogRef: MatDialogRef<UiScheduleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UiScheduleDialogData) {
  }

  ngOnInit(): void {
    if (!this.data.schedule || !this.data?.schedule?.length) {
      this.schedule = _.cloneDeep(daySettingScheduleV2);
    } else {
      if (!this.data?.schedule[0]?.times) {
        // Old schedule - need to convert
        this.data.schedule = this.data.schedule.map((day) => {
          const newDay: AlertV2Schedule = {
            day: day.day,
            enabled: !day.disabled,
            times: [{
              from: day.from,
              to: day.to,
            }],
          };
          return newDay;
        });
      }
      this.schedule = _.cloneDeep(this.data.schedule);

    }
  }

  public save() {
    const result: UiScheduleDialogResult = {
      schedule: this.schedule,
    };
    this.dialogRef.close(result);
  }

  public barOffset(range: TimeRange): string {
    const [fromHour, fromMinute] = this.parseTime(range.from);

    const fromInMinutes = fromHour * 60 + fromMinute;

    const totalMinutesInDay = 24 * 60;

    return `${fromInMinutes / totalMinutesInDay * 100}%`;
  }

  public barWidth(range: TimeRange): string {
    const [fromHour, fromMinute] = this.parseTime(range.from);
    const [toHour, toMinute] = this.parseTime(range.to);

    const fromInMinutes = fromHour * 60 + fromMinute;
    const toInMinutes = (toHour === 0 && toMinute === 0) ? 24 * 60 : toHour * 60 + toMinute;

    const totalMinutesInDay = 24 * 60;

    // 24 hour case
    if (fromInMinutes === 0 && toInMinutes === 0) {
      return '100%';
    }

    return `${(toInMinutes - fromInMinutes) / totalMinutesInDay * 100}%`;
  }

  parseTime(time: string): [number, number] {
    let [hourStr, minuteStr] = time.split(':');
    let hour, minute, period;

    period = minuteStr.slice(-2);
    minuteStr = minuteStr.slice(0, -2); // Remove AM or PM
    hour = parseInt(hourStr, 10);
    minute = parseInt(minuteStr, 10);

    // Adjust hour based on AM/PM
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }


    return [hour, minute];
  }

  formatTime(minutes) {
    let hours = Math.floor(minutes / 60);
    let minutesPast = minutes % 60;

    if (hours === 24 && minutesPast === 0) {
      return '12:00 AM';
    }

    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutesPast < 10 ? '0' + minutesPast : minutesPast;

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  // updateTimes(index: number): void {
  //   const range = this.timeRanges[index];
  //   const totalMinutesInDay = 24 * 60;
  //   const fromInMinutes = Math.round(parseFloat(range.left) / 100 * totalMinutesInDay);
  //   const toInMinutes = Math.round((parseFloat(range.left) + parseFloat(range.width)) / 100 * totalMinutesInDay);
  //
  //   range.from = this.formatTime(fromInMinutes);
  //   range.to = this.formatTime(toInMinutes);
  // }

  minutesFromTime(time: string): number {
    const [hour, minute] = this.parseTime(time);
    return hour * 60 + minute;
  }

  delete(trigger: MatMenuTrigger) {
    this.schedule[this.selectionIndex].times.splice(this.selectedRangeIndex, 1);
    this.inSelection = false;
    trigger.closeMenu();
  }

  public get rangeValid() {
    const from = this.currentSelection?.from;
    const to = this.currentSelection?.to;
    if (from > to && (to !== '00:00' && to !== '12:00 AM')) {
      return false;
    }
    return true;
  }

  selectTimes(trigger: MatMenuTrigger) {
    const selection = {
      from: this.getTimeInLocale12(this.currentSelection.from),
      to: this.getTimeInLocale12(this.currentSelection.to),
    };
    for(let day of this.currentSelectionRepeat) {
      if (this.schedule[day]?.times[0]?.from === '12:00 AM' && this.schedule[day]?.times[0]?.to === '12:00 AM') {
        this.schedule[day].times = [];
      }
      if (day === this.selectionIndex && (this.selectedRangeIndex || this.selectedRangeIndex === 0)) {
        this.schedule[day].times[this.selectedRangeIndex] = selection;
      }
      this.schedule[day].times = this.schedule[day].times.filter((time) => !time.placeholder);
      this.schedule[day].times.push(selection);
      this.schedule[day].times = this.mergeTimeRanges(this.schedule[day].times);
    }
    this.selectionVisible = false;
    this.inSelection = false;
    trigger.closeMenu();
  }

  onToggleDay(dayIdx: number) {
    const times = this.schedule[dayIdx]?.times;
    // DEPRECATED - ON DEFAULT REMOVE ENTIRE DAY
    // if (!this.schedule[dayIdx].enabled) {
    //   if (times?.length === 1 && times[0].to === '12:00 AM' && times[0].from === '12:00 AM') {
    //     this.schedule[dayIdx].times = [];
    //     return;
    //   }
    // }
  }

  getMouseTimeAndPosition(event: MouseEvent, wrapper: HTMLDivElement) {
    const timelineRect = wrapper.getBoundingClientRect();
    const mouseX = event.clientX;
    const position = Math.max((mouseX - timelineRect.left) / timelineRect.width, 0);
    const totalMinutesInDay = 24 * 60;
    const minutes = Math.round(position * totalMinutesInDay);
    return { time: this.formatTime(minutes), position };
  }

  setTooltipPosition(event: MouseEvent, wrapper: HTMLDivElement): void {
    const { time, position } = this.getMouseTimeAndPosition(event, wrapper);
    this.tooltipTime = time;
    this.tooltipPosition = position * 100;
  }

  showTooltip(index: number) {
    this.tooltipIndex = index;
    this.tooltipVisible = true;
  }

  hideTooltip(): void {
    this.tooltipVisible = false;
  }

  setTimes() {
  }

  mergeTimeRanges(ranges: TimeRange[]): TimeRange[] {
    // Check if there's a 24-hour range. If yes, return it as the only range.
    for(let range of ranges) {
      if (range.from === '12:00 AM' && range.to === '12:00 AM') {
        return [{ from: '12:00 AM', to: '12:00 AM' }];
      }
    }
    // Convert to 24-hour format and sort
    const sortedRanges = ranges.map(range => ({
        from: moment(range.from, 'hh:mm A')
          .format('HH:mm'),
        to: moment(range.to, 'hh:mm A')
          .format('HH:mm'),
      }))
      .sort((a, b) => moment(a.from, 'HH:mm')
        .valueOf() - moment(b.from, 'HH:mm')
        .valueOf());

    const mergedRanges: TimeRange[] = [];
    let currentRange = sortedRanges[0];

    for(let i = 1; i < sortedRanges.length; i++) {
      const nextRange = sortedRanges[i];
      // Convert back to AM/PM format for comparison
      if (moment(nextRange.from, 'HH:mm')
        .isSameOrBefore(moment(currentRange.to, 'HH:mm'))) {
        currentRange.to = moment.max(moment(currentRange.to, 'HH:mm'), moment(nextRange.to, 'HH:mm'))
          .format('HH:mm');
      } else {
        mergedRanges.push({
          from: moment(currentRange.from, 'HH:mm')
            .format('hh:mm A'),
          to: moment(currentRange.to, 'HH:mm')
            .format('hh:mm A'),
        });
        currentRange = nextRange;
      }
    }

    mergedRanges.push({
      from: moment(currentRange.from, 'HH:mm')
        .format('hh:mm A'),
      to: moment(currentRange.to, 'HH:mm')
        .format('hh:mm A'),
    });

    return mergedRanges;
  }

  public parseTimeInput(time: string): string {
    if (!this.locale12) {
      if (time === '12:00 AM') {
        return '00:00';
      }
      if (time.includes('AM') || time.includes('PM')) {
        let [hourStr, minuteStr] = time.split(':');
        const period = minuteStr.slice(-2);
        minuteStr = minuteStr.slice(0, -2); // Remove AM or PM
        let hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);
        if (period === 'PM' && hour !== 12) {
          hour += 12;
        }
        return `${String(hour)
          .padStart(2, '0')}:${String(minute)
          .padStart(2, '0')}`;
      }
    }
    return time;
  }

  public getTimeInLocale12(time: string) {
    if (!this.locale12) {
      let [hourStr, minuteStr] = time.split(':');
      let hour = parseInt(hourStr, 10);
      let period = 'AM';
      if (hour >= 12) {
        period = 'PM';
        hour -= 12;
      }
      return `${String(hour === 0 ? 12 : hour)
        .padStart(2, '0')}:${String(minuteStr)
        .padStart(2, '0')} ${period}`;
    }
    return time;
  }

  selectionMenu(
    event: MouseEvent,
    trigger: MatMenuTrigger,
    triggerElem: HTMLElement,
    wrapper: HTMLDivElement, day: number, rangeIndex?: number,
  ) {
    if (this.drag) {
      this.drag = false;
      this.dragStartX = -1;
      this.dragStartFrom = -1;
      this.dragStartTo = -1;
      return;
    }
    this.inSelection = true;
    if (rangeIndex || rangeIndex === 0) {
      event.stopPropagation();
    }
    const timelineRect = wrapper.getBoundingClientRect();
    const mouseX = event.clientX;
    const position = (mouseX - timelineRect.left) / timelineRect.width;
    const totalMinutesInDay = 24 * 60;
    let minutes, from, to;
    this.selectionIndex = day;
    this.currentSelectionRepeat = [day];


    if (rangeIndex === undefined) {
      delete this.selectedRangeIndex;
      minutes = Math.round(position * totalMinutesInDay);
      from = this.parseTimeInput(this.formatTime(minutes));
      to = this.parseTimeInput(this.formatTime(minutes + 60));
    } else {
      this.selectedRangeIndex = rangeIndex;
      from = this.parseTimeInput(this.schedule[day].times[rangeIndex].from);
      to = this.parseTimeInput(this.schedule[day].times[rangeIndex].to);
    }
    this.currentSelection = { from, to };
    if (rangeIndex === undefined) {
      this.schedule[day].times.push({ from: this.formatTime(minutes), to: this.formatTime(minutes + 60), placeholder: true });
    }
    this.renderer.setStyle(triggerElem, 'left', `${event.clientX + 5}px`);
    this.renderer.setStyle(triggerElem, 'top', `${event.clientY + 5}px`);
    trigger.openMenu();
    event.preventDefault();
    event.stopPropagation();
  }


  public toggleRepeat(event: Event, index: number) {
    event.stopPropagation();
    if (index === this.selectionIndex) {
      return;
    }
    if (this.currentSelectionRepeat.includes(index)) {
      this.currentSelectionRepeat.splice(this.currentSelectionRepeat.indexOf(index), 1);
      return;
    }
    this.currentSelectionRepeat.push(index);
  }

  public resetSelection() {
    if (this.inSelection) {
      if (!this.selectedRangeIndex && !(this.selectedRangeIndex === 0)) {
        this.schedule[this.selectionIndex].times.pop();
      }
      this.inSelection = false;
    }
    this.selectionIndex = -1;
    this.selectedRangeIndex = -1;
  }

  public reset() {
    this.schedule = _.cloneDeep(daySettingScheduleV2);
  }

  inMinutes(timeString: string, to = false): number {
    if (timeString === '12:00 AM' && to) {
      return 24 * 60;
    }
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':')
      .map(Number);
    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  }

  dragSlot(dayIdx: number, rangeIdx: number, event: CdkDragMove,
           dayWrapper: HTMLDivElement, slotWrapper: HTMLDivElement) {
    this.tooltipVisible = false;
    this.renderer.setStyle(slotWrapper, 'transform', 'translate3d(0px, 0px, 0px)');

    const dayBounds = dayWrapper.getBoundingClientRect();
    const slotBounds = slotWrapper.getBoundingClientRect();
    const x = (event.event as MouseEvent).clientX;
    if (x >= slotBounds.right) {
      event.event.preventDefault();
      event.event.stopPropagation();
    }
    const slot = this.schedule[dayIdx].times[rangeIdx];
    if (this.dragStartX === -1) {
      this.dragStartX = event.pointerPosition.x;
    }
    if (x < dayBounds.left || x > slotBounds.right) {
      return;
    }
    const totalMinutesInDay = 24 * 60;
    const delta = x - this.dragStartX;
    const deltaMinutes = delta / dayBounds.width * totalMinutesInDay;
    const origFromMinutes = this.dragStartFrom !== -1 ? this.dragStartFrom : this.inMinutes(slot.from);
    const origToMinutes = this.dragStartTo !== -1 ? this.dragStartTo : this.inMinutes(slot.to);
    if (this.dragStartFrom === -1) {
      this.dragStartFrom = origFromMinutes;
    }
    if (this.dragStartTo === -1) {
      this.dragStartTo = origToMinutes;
    }
    const fromMinutes = Math.round(origFromMinutes + deltaMinutes);
    const toMinutes = Math.round(origToMinutes + deltaMinutes);
    if (fromMinutes < 0 || toMinutes > totalMinutesInDay) {
      return;
    }
    slot.from = this.formatTime(fromMinutes);
    slot.to = this.formatTime(toMinutes);
  }


  dragLeft(dayIdx: number, rangeIdx: number, event: CdkDragMove,
           dayWrapper: HTMLDivElement, slotWrapper: HTMLDivElement, handle: HTMLDivElement) {
    const dayBounds = dayWrapper.getBoundingClientRect();
    const slotBounds = slotWrapper.getBoundingClientRect();
    const x = (event.event as MouseEvent).clientX;
    if (x >= slotBounds.right) {
      event.event.preventDefault();
      event.event.stopPropagation();
    }
    const slot = this.schedule[dayIdx].times[rangeIdx];
    if (this.dragStartX === -1) {
      this.dragStartX = event.pointerPosition.x;
    }
    if (x < dayBounds.left || x > slotBounds.right) {
      return;
    }
    const { time, position } = this.getMouseTimeAndPosition(event.event as MouseEvent, dayWrapper);
    if (!(this.inMinutes(time) + 30 >= this.inMinutes(slot.to, true))) {
      slot.from = time;
    }

    if (handle) {
      this.renderer.setStyle(handle, 'transform', 'translate3d(0px, 0px, 0px)');
    }
  }

  dragRight(dayIdx: number, rangeIdx: number, event: CdkDragMove, dayWrapper: HTMLDivElement, slotWrapper: HTMLDivElement, handle: HTMLDivElement) {
    const dayBounds = dayWrapper.getBoundingClientRect();
    const slotBounds = slotWrapper.getBoundingClientRect();
    const x = (event.event as MouseEvent).clientX;
    if (x <= slotBounds.left) {
      event.event.preventDefault();
      event.event.stopPropagation();
    }
    const slot = this.schedule[dayIdx].times[rangeIdx];
    if (this.dragStartX === -1) {
      this.dragStartX = event.pointerPosition.x;
    }
    if (x > dayBounds.right || x <= slotBounds.left) {
      return;
    }
    const { time, position } = this.getMouseTimeAndPosition(event.event as MouseEvent, dayWrapper);
    if (!(this.inMinutes(time) - 30 <= this.inMinutes(slot.from))) {
      slot.to = time;
      if (this.inMinutes(time) > 1435) {
        slot.to = '12:00 AM';
      }
    }

    this.renderer.setStyle(handle, 'transform', 'translate3d(0px, 0px, 0px)');
  }


  dragStart() {
    this.drag = true;
  }

  dragEnd(dayIndex: number, handle?: HTMLDivElement) {
    this.drag = false;
    this.dragStartX = -1;
    this.dragStartFrom = -1;
    this.dragStartTo = -1;
    this.tooltipVisible = true;
    if (handle) {
      this.renderer.setStyle(handle, 'transform', 'translate3d(0px, 0px, 0px)');
    }
    this.schedule[dayIndex].times = this.mergeTimeRanges(this.schedule[dayIndex].times);
  }

}
