<div fxLayout="row" fxLayoutAlign="space-between center" class="advanced-search-header">
  <h4>Search attributes</h4>
  <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography advanced-search">
  <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
    <div fxLayout="row" class="w-100">
      <div fxFlex="23" fxLayout="column" fxLayoutAlign="space-between start" class="side-panel">
        <div class="w-100">
          <ng-container *ngFor="let type of ['person', 'vehicle', 'pet']; let idx = index">
            <div fxLayoutAlign="start center"
                 class="subject label"
            >{{ idx === 0 ? 'People' : idx === 1 ? 'Vehicles' : 'Pets' }}</div>
            <ng-container *ngFor="let s of searchSelection; let sIdx = index">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                class="subject c-p"
                [class.selected]="selectedSelection === s"
                *ngIf="s.type === type"
                (click)="select(s)"
              >
                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon>{{
                    s.type === 'vehicle' ? 'directions_car' : s.type === 'person' ? 'person_outline' : s.type === 'pet' ? 'pets' : ''
                    }}</mat-icon>
                  <div>{{ (s.type | titlecase) + ' ' + s.idx }}</div>
                </div>
                <div class="remove-button" (click)="remove(sIdx)">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            </ng-container>
            <div mat-stroked-button
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="10px"
                 class="add-button"
                 (click)="add(type)"
            >
              <mat-icon>add</mat-icon>
              <div>Add {{ idx === 0 ? 'person' : idx === 1 ? 'vehicle' : 'pet' }}</div>
            </div>
          </ng-container>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" class="outer-operator-wrapper">
          <mat-select
            matTooltip="Currently available - OR logic - Show results from all objects attributes"
            [(ngModel)]="outerOperator"
            class="outer-operator"
            disabled
          >
            <mat-option *ngFor="let opt of SearchSelectorOperators" [value]="opt.value">{{ opt.name }}</mat-option>
          </mat-select>
        </div>
        <!-- DISABLED - select menu -->
        <div *ngIf="false" fxLayoutAlign="start center" class="w-100 mt-10 ml-20">
          <button mat-stroked-button class="plus-button" [matMenuTriggerFor]="subjectMenu">
            <mat-icon>add</mat-icon>
            Add Search Subject
          </button>
          <mat-menu #subjectMenu="matMenu" yPosition="above">
            <button mat-menu-item *ngFor="let type of SearchSelectorTypes" (click)="add(type.value)">
              <mat-icon>{{
                type.value === 'vehicle'
                  ? 'directions_car'
                  : type.value === 'person'
                    ? 'person_outline'
                    : type.value === 'pet'
                      ? 'pets'
                      : ''
                }}</mat-icon>
              {{ type.name }}
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="selectedSelection" class="properties-panel" fxFlex="">
        <!--        Selected-->
        <!--        {{selectedSelection | json}}-->
        <app-properties-selector [selection]="selectedSelection"></app-properties-selector>
      </div>
      <div *ngIf="!selectedSelection" fxLayout="column" fxLayoutAlign="center center" fxFlex="">
        <div fxLayout="column" fxLayoutAlign="center center" class="properties-placeholder">
          <img class="pin-img" src="assets/alert.svg" style="height: 120px; width: 120px"/>
          <h1 class="mt-10">Filter results by object attributes</h1>
          <p style="text-align: center; line-height: 24px" class="c-gray">
            Start by choosing an object, and define attributes for it. You<br/>
            can add multiple objects and attributes.
          </p>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="add-object-buttons w-100 mt-20">
            <button mat-stroked-button
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    (click)="add('person')"
                    fxFlex=""
            >
              <mat-icon>person_outline</mat-icon>
              <div>Add person</div>
            </button>
            <button mat-stroked-button
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    (click)="add('vehicle')"
                    fxFlex=""
            >
              <mat-icon>directions_car</mat-icon>
              <div>Add vehicle</div>
            </button>
            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center" (click)="add('pet')" fxFlex="">
              <mat-icon>pets</mat-icon>
              <div>Add Pet</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      matTooltip="High Confidence search will only return objects that match the search criteria with high confidence"
    >
      <ui-toggle [(ngModel)]="highConfidence"></ui-toggle>
      <div style="color: #667085; font-size: 13px" [style.opacity]="highConfidence ? 1 : 0.5">High Confidence</div>
    </div>
    <div>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial (click)="search()">Search</button>
    </div>
  </div>
</mat-dialog-actions>
