import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';

@Injectable()
export class SupportService {
  constructor(private http: HttpClient) {
  }

  public login() {
    return this.http.post<{
      url: string,
      token: string,
      returnTo: string
    }>(api.support.login, {});
  }
}
