import { UiCalendarPickerType } from '@enums/shared.enum';

export module UiDatetimeRangePickerModel {
  export const minuteOptions = [5, 10, 15, 20, 30, 45];
  export const hourOptions = [1, 2, 3, 6, 8, 12];
  export const dayOptions = [1, 2, 3, 4, 5, 6];
  export const weekOptions = [1, 2, 3, 4, 5, 6];

  export const ABSOLUTE_DIALOG_WIDTH = '747px';
  export const RELATIVE_DIALOG_WIDTH = '544px';

  export enum CustomUnit {
    minutes = 'minutes',
    hours = 'hours',
    days = 'days',
    weeks = 'weeks',
    // months = 'months',
  }

  export interface PickerState {
    pickerType: UiCalendarPickerType;
    pickerCustom: boolean;
    // Describes the selected index
    minutes?: number;
    hours?: number;
    days?: number;
    weeks?: number;
    // In seconds
    custom?: number;
  }
}
