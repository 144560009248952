<div fxLayout="column"
     fxLayoutAlign="space-between center"
     class="person-card p-r"
     [class.selected]="selected"
     [class.c-p]="allowSelection"
>
  <ng-container *ngIf="multiSelect; else single">
    <mat-checkbox color="primary"
                  [checked]="selected"
                  class="selected-radio"
                  (click)="$event.preventDefault()"
                  *ngIf="allowSelection"
    ></mat-checkbox>
  </ng-container>
  <ng-template #single>
    <mat-radio-button color="primary"
                      [checked]="selected"
                      class="selected-radio"
                      *ngIf="allowSelection"
    ></mat-radio-button>
  </ng-template>
  <div *ngIf="allowEdit" fxLayoutAlign="center center" class="more-button icon-16" [matMenuTriggerFor]="personMenu">
    <mat-icon svgIcon="more-menu"></mat-icon>
  </div>
  <mat-menu #personMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item (click)="edit.emit()"
    >
      Edit name
    </button>
    <button mat-menu-item (click)="remove.emit()"
    >
      Remove
    </button>
  </mat-menu>
  <img class="person-card-img" [lazyLoad]="img"/>
  <div fxLayout="column" fxLayoutAlign="start center">
    <div class="person-card-name">{{name}}</div>
<!--    <div class="person-card-appearances">{{appearances}} recognitions</div>-->
  </div>
</div>
