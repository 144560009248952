import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Edge } from 'src/app/edge/edge.model';
import { EdgeService } from 'src/app/edge/edge.service';
import { MainContentService } from 'src/app/layout/main-content.service';

/*
 *
 * Todo
 * 1. Create edge using edgeId
 * 2. set model like backend DTO
 * 3. on the BE, createEdgeDto, get userid and implement the repository createEdge,
 *   a. create new document on the edge collection, save id to user catalog
 *   b. create sqsRepository, we should create and save sqs to edge document. b
 * 4. On the UI we should be able to select the edge and add a new camera "create camera"
 *    a. on the core, add camera to cameraList array
 * */

@Component({
  selector: 'app-create-new-edge',
  templateUrl: './create-new-edge.component.html',
  styleUrls: ['./create-new-edge.component.scss'],
})
export class CreateNewEdgeComponent implements OnInit {
  form = this.fb.group({
    edgeId: ['N0sitmQhJb', Validators.required],
    // cameraId: ['HIK41', Validators.required],
    // startYear: ['2022', Validators.required],
    // endYear: ['2036', Validators.required],
  });

  get edgeId() {
    return this.form.controls['edgeId'];
  }

  get cameraId() {
    return this.form.controls['cameraId'];
  }

  get startYear() {
    return this.form.controls['startYear'];
  }

  get endYear() {
    return this.form.controls['endYear'];
  }

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private edgeService: EdgeService,
    private mainContentService: MainContentService,
  ) {
  }

  ngOnInit(): void {
    this.mainContentService.setHeader({
      title: 'Provision New edge Device',
      isBack: true,
    });
  }

  onSubmit() {
    // TODO: Manage errors (create toss, etc.)
    if (!this.form.valid) {
      this.snackBar.open('form is invalid...');
      return;
    }

    let data: Edge.EdgeCreateRequest = { ...this.form.value };
    this.edgeService.createEdge(data)
      .subscribe(res => {
        this.snackBar.open('Core is successfully provisioned...');
        this.router.navigate(['/camera-operations']);
      });
  }
}
