import { Pipe, PipeTransform } from '@angular/core';
import { WidgetViewMode } from '@enums/workspace.enum';
import { VariableType } from '@enums/variable.enum';

@Pipe({
  name: 'widgetVariablesFilter',
})
export class WidgetVariablesFilterPipe implements PipeTransform {
  transform(value: { _id: string; name: string; variableType?: VariableType }[], viewMode: WidgetViewMode): any[] {
    if (viewMode !== WidgetViewMode.SMALL) {
      return value.filter(variable => variable.variableType !== VariableType.compound);
    } else {
      return value;
    }
  }
}
