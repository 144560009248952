<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div
      fxLayoutAlign="center center"
      (click)="toggle(color.value)"
      *ngFor="let color of colors"
      [matTooltip]="color.value | titlecase"
      class="ui-color-select-color"
      [ngClass]="{ selected: isSelected(color.value) }"
  >
    <div class="ui-color-select-color-center" [style.background]="color.hex"></div>
  </div>
</div>
