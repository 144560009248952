import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'attribute-row',
  templateUrl: './attribute-row.component.html',
  styleUrls: ['./attribute-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttributeRowComponent),
      multi: true,
    },
  ],
})
export class AttributeRowComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;

  @Input() description: string;

  @Input() hidden: boolean = false;

  @Input() noBoxShadow: boolean = false;

  @Input() panel: boolean = false;

  @Input() beta = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: boolean): void {
    this.hidden = val;
  }

  public updateValue(insideValue: boolean) {
    this.hidden = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };
}
