<ngx-skeleton-loader *ngIf="!data && isFirstInit" style="width: 100%; position: relative" count="5" appearance="line"></ngx-skeleton-loader>
<div fxLayout="row" fxLayoutAlign="center center" class="nothing" *ngIf="!data?.length && !isFirstInit">Nothing to show there</div>
<div fxLayout="row" fxLayoutAlign="start start">
  <div *ngIf="data?.length" class="legend" fxFlex="20">
    <div class="title" (click)="selectAll()">
      <mat-checkbox class="example-margin" style="pointer-events: none" color="primary" [checked]="selectedAll"> </mat-checkbox>
      Select All
    </div>
    <div class="body" *ngFor="let legend of legends; let i = index">
      <label fxLayout="row" fxLayoutAlign="start center" (click)="hideLegend(legend)">
        <!--        TODO: ADD SELECTED FOR EACH LEGEND SEPARATELY-->
        <mat-checkbox class="example-margin" [checked]="selectedAll" color="primary"> </mat-checkbox>
        <ui-truncated-html class="truncated-text">
          <span [ngStyle]="{ color: colors[i] }">{{ legend }}</span>
        </ui-truncated-html>
      </label>
    </div>
  </div>
  <div fxFlex="80" class="graph">
    <div #svgContainer></div>
  </div>
</div>
