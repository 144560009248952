<div fxLayout="column" fxLayoutAlign="start center" class="page-wrapper">
  <div fxLayout="column" class="inner" fxFlex="">
    <div #wrapper style="position: relative; background: #dddddd; width: 100%; height: 100%; max-height: 33vh">
      <ui-zone-selector #selector style="position: absolute" (update)="update($event)" [value]="zones"> </ui-zone-selector>
    </div>
    <div fxLayout="column">
      <button mat-flat-button color="primary" (click)="selector.clear()">Clear</button>
      <div *ngFor="let zone of zones | keyvalue" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <!--      <div>{{zone.key}}</div>-->
        <div fxLayout="column" fxLayoutAlign="center center" class="zone-icon">
          <div
            [style.border]="'2px solid ' + selector.getSelectionColorByName(zone.value.color).line"
            [style.background]="selector.getSelectionColorByName(zone.value.color).area"
            class="zone-icon-inner"
          ></div>
        </div>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Name your zone"
            type="text"
            [value]="zone.value.name"
            (input)="selector.renameZone(zone.key, $event)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="p-r">
          <div class="color-dot color-dot-selected" [style.background]="selector.getSelectionColorByName(zone.value.color).line"></div>
          <mat-select
            class="color-selector"
            appearance="outline"
            [value]="zone.value.color"
            (selectionChange)="selector.colorZone(zone.key, $event)"
          >
            <mat-option *ngFor="let color of SelectionColors" [value]="color.name">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div class="color-dot" style="background: {{ color.line }};"></div>
                <div>{{ color.name | titlecase }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="delete-zone-button ml-15 c-p" (click)="selector.deleteZone(zone.key)">
          <mat-icon svgIcon="delete"></mat-icon>
        </div>
      </div>
      {{ zones | json }}
      {{ markedIdx | json }}
    </div>
  </div>
</div>
