import { Component, OnInit } from '@angular/core';
import { AddCameraComponent } from '../add-camera/add-camera.component';
import { MatDialog } from '@angular/material/dialog';
import { EdgeService } from '../edge.service';
import { Edge } from '../edge.model';
import EdgeDocument = Edge.EdgeDocument;
import { Camera, CameraStopRequest, CameraStartRequest } from '../../cameras/camera.model';

@Component({
  selector: 'app-camera-list',
  templateUrl: './camera-list.component.html',
  styleUrls: ['./camera-list.component.scss'],
})
export class CameraListComponent implements OnInit {
  edge: EdgeDocument;
  cameras: string[];
  displayedColumns: string[] = ['cameraId', 'actions'];
  dataSource: Camera[];
  loading = true;

  constructor(private dialog: MatDialog, private edgeService: EdgeService) {
  }

  ngOnInit(): void {
    this.edgeService.currentEdge$.subscribe(edge => {
      this.edge = edge;
    });
  }

  async addCamera() {
    this.dialog
      .open(AddCameraComponent)
      .afterClosed()
      .subscribe(() => {
      });
  }

  async stopCamera(cameraId) {
    if (!this.edge.edgeId) {
      return;
    }
    const req: CameraStopRequest = {
      edgeId: this.edge.edgeId,
      cameraId,
    };
    this.edgeService.cameraStop(req)
      .subscribe(() => {
      });
  }

  async startCamera(cameraId) {
    if (!this.edge.edgeId) {
      return;
    }
    const req: CameraStartRequest = {
      locationId: '',
      edgeId: this.edge.edgeId,
      cameraId,
    };
    this.edgeService.cameraStart(req)
      .subscribe(() => {
      });
  }
}
