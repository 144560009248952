<div class="camera-overlay" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="5px"
       class="status"
       [class.notext]="!displayStr"
       [ngClass]="{ green: green }"
  >
    <mat-icon>fiber_manual_record</mat-icon>
    <div *ngIf="displayStr && (!!status || status === 0)">{{ statusStr }}</div>
  </div>
  <div *ngIf="name" class="name">{{ name }}</div>
</div>
