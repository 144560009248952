<div *ngIf="howToDraw" fxLayout="column" fxLayoutAlign="center center" class="how-to-draw-wrapper"
     (click)="$event.stopPropagation()">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" class="how-to-draw">
    <h2 *ngIf="type === UiHowToDrawType.ZONES">Start drawing your zones</h2>
    <h2 *ngIf="type === UiHowToDrawType.LINES">Start drawing your lines</h2>
    <div *ngIf="type === UiHowToDrawType.ZONES" class="how-to-draw-description">
      Just click on the camera preview and create any shape you want. At
      the end,
      connect to your first point.
    </div>
    <div *ngIf="type === UiHowToDrawType.LINES" class="how-to-draw-description">
      Click on the preview to indicate the line starting point, and click second-time to indicate the line ending point.
    </div>
    <ui-button
        text="Got it, I'm ready"
        colorBgr="#fff"
        colorText="#000"
        (clickAction)="closeHowToDraw()"
        (click)="$event.stopPropagation(); $event.preventDefault()"
    ></ui-button>
    <mat-checkbox
        *ngIf="type === UiHowToDrawType.ZONES"
        color="primary"
        [(ngModel)]="settings.account.howToDrawZones">
      <div class="white">Don't show again</div>
    </mat-checkbox>
    <mat-checkbox
        *ngIf="type === UiHowToDrawType.LINES"
        color="primary"
        [(ngModel)]="settings.account.howToDrawLines">
      <div class="white">Don't show again</div>
    </mat-checkbox>
  </div>
</div>
