import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { debounceTime, exhaustMap, of, share, timestamp, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CameraActions } from '@states/camera/camera.action-types';
import { LocationsService } from '../../locations/locations.service';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { ThumbnailsService } from '../../development/thumbnails.service';
import { EdgeCamera } from '../../cameras/camera.model';
import { LocationActions } from '@states/location/location.action-types';
import * as SharedActions from '@states/shared/shared.actions';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { EdgeService } from '../../edge/edge.service';
import * as SearchConfigurationActions from '@states/camera-edit/camera-edit.actions';
import { CameraHeartbeatPulsationSelectors } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.selector-types';
import { PulsationModels } from '@models/pulsation.model';
import { GetLocationCameraByIdFail, GetLocationEdgesCamerasSuccess } from '@states/camera/camera.actions';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';

@Injectable()
export class CameraEffects {
  GetLocationEdgesCamerasSnapshots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.GetLocationEdgesCamerasSnapshots),
      map(action => {
        return action.payload;
      }),
      concatMap((payload: EdgeCamera.CameraItem[]) => {
        return this.camerasThumbnailsService.getLastAllSnapshots()
          .pipe(
            map(response =>
              CameraActions.GetLocationEdgesCamerasSnapshotsSuccess({
                payload: response,
              }),
            ),
            catchError((err: Error) => {
              return of(
                CameraActions.GetLocationEdgesCamerasSnapshotsFail({
                  message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to save snapshots',
                }),
              );
            }),
          );
      }),
      catchError((err: Error) =>
        of(
          CameraActions.GetLocationEdgesCamerasSnapshotsFail({
            message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to save snapshots',
          }),
        ),
      ),
    ),
  );

  public CreateLocationEdgeCameraSuccess$ = createEffect(() => this.actions$.pipe(ofType(CameraActions.CreateLocationEdgeCameraSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  CreateLocationEdgeCameraNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.CreateLocationEdgeCameraNoBackendCall),
      map(action => action.request),
      tap(res => {
        // this.edgeStatusService.subscribeToEdgeStatusFirestore(res.edgeId!)
        this.edgeStatusService.getPulsationFromSinglestore(res.edgeId!);
      }),
      switchMap(response => [

        CameraActions.CreateLocationEdgeCameraSuccess({ payload: { ...response, createdAt: Date.now() } }),
        CameraActions.SetCameraSnapshotManually({ cameraId: response?.edgeOnly?.cameraId, url: `https://dib7tx0qzyy42.cloudfront.net/${response['defaultSnapshot']}` }),
      ]),
      catchError((err: Error) =>
        of(
          CameraActions.CreateLocationEdgeCameraFail({
            message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to save camera',
          }),
        ),
      ),
    ),
  );

  UpdateLocationEdgeCameraNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.UpdateLocationEdgeCameraNoBackendCall),
      map(action => action.request),
      switchMap(response => [
        CameraActions.UpdateLocationEdgeCameraSuccess({ payload: response }),
      ]),
      catchError((err: Error) =>
        of(
          CameraActions.UpdateLocationEdgeCameraFail({
            message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to save camera',
          }),
        ),
      ),
    ),
  );

  DeleteCameraNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.DeleteCameraNoBackendCall),
      map(action => action.request),
      map(response => CameraActions.DeleteCameraSuccess({ response })),
      catchError((err: Error) =>
        of(
          CameraActions.DeleteCameraFail({
            message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to delete camera',
          }),
        ),
      ),
    ),
  );

  UploadCameraAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.UploadCameraAsset),
      exhaustMap(({ file, edgeId, locationId, cameraId, asset }) => {
        return [
          SharedActions.setIsSaving({ isSaving: true }),
          CameraActions.UploadCameraAssetSend({
            file,
            edgeId,
            locationId,
            cameraId,
            asset,
          }),
        ];
      }),
    ),
  );

  UploadCameraAssetSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.UploadCameraAssetSend),
      switchMap(({ locationId, edgeId, cameraId, file, asset }) => {
        return (
          this.locationService
            // .uploadCameraAsset(locationId, edgeId, cameraId, file, asset)
            .getCameraAssetPreSignedUrl({ locationId, edgeId, cameraId, asset })
            .pipe(
              concatMap(res => {
                return this.locationService.uploadCameraAssetPresignedUrl({
                  url: res.url,
                  file,
                });
              }),
              concatMap(res => {
                return this.locationService.notifyCameraAssetUploaded({
                  locationId,
                  cameraId,
                  edgeId,
                  filename: asset.filename,
                });
              }),
              mergeMap(res => {
                return [
                  CameraActions.startSendCameraAssetSubscribeToSessionStatus({
                    token: res.token.session,
                  }),
                  SharedActions.showMessage({
                    success: 'Camera asset has been uploaded',
                  }),
                  SharedActions.setIsSaving({ isSaving: false }),
                  SearchConfigurationActions.getCameraAssets(),
                ];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false })];
              }),
            )
        );
      }),
      share(),
    ),
  );

  public CameraStartSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.StartSearchQuery),
      debounceTime(400),
      switchMap(({ searchQuery }) => of(CameraActions.SetSearchQuery({ searchQuery }))),
    ),
  );

  public startSendCameraAssetSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.startSendCameraAssetSubscribeToSessionStatus),
      exhaustMap(({ token }) => {
        return [SharedActions.setIsLoading({ isLoading: true }), CameraActions.sendCameraAssetSubscribeToSessionStatus({ token })];
      }),
    ),
  );

  public sendCameraAssetSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.sendCameraAssetSubscribeToSessionStatus),
      mergeMap(({ token }) => {
        return this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            filter(state => state?.status === TokenDataStatus.COMPLETED),
            mergeMap(res => {
              return [
                SharedActions.setIsLoading({ isLoading: false }),
                CameraActions.sendCameraAssetSessionStatusChanged({
                  token: token,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), SharedActions.showMessage({ error: 'Timout occurred' })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraAssetSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.sendCameraAssetSessionStatusChanged),
      mergeMap(({ token }) => {
        return this.edgeService.getSessionData<any>(token)
          .pipe(
            mergeMap(session => {
              return [
                SharedActions.showMessage({
                  success: 'File downloaded successfully by camera',
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getIdsByStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.getIdsByStatus),
      withLatestFrom(this.store.pipe(select(CameraHeartbeatPulsationSelectors.selectCameraEntities), timestamp())),
      switchMap(([{ status }, cameraStatusEntities]) => {
        const cameraIds = Object.values(cameraStatusEntities.value)
          .filter(camera => {
            if (status.length === 1) {
              if (status.includes(0)) {
                return (
                  (camera.status == PulsationModels.ComponentStatus.Online || camera.status === PulsationModels.ComponentStatus.Streaming) &&
                  cameraStatusEntities?.timestamp &&
                  cameraStatusEntities?.timestamp - Number(camera?.timestamp) < 360000
                );
              }
              if (status.includes(1)) {
                return (
                  (camera.status != PulsationModels.ComponentStatus.Online && camera.status !== PulsationModels.ComponentStatus.Streaming) ||
                  (cameraStatusEntities?.timestamp && cameraStatusEntities?.timestamp - Number(camera?.timestamp) > 360000)
                );
              }
            } else if (status.length === 2) {
            }
            return true;
          })
          .map(camera => camera.cameraId);

        return of(LocationActions.filterCamerasByStatus({ cameraIds }));
      }),
      share(),
    ),
  );

  GetLocationCameraById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.GetLocationCameraById),
      switchMap(({ locationId, edgeId, cameraId }) => {
        return this.locationService.getCameraById(locationId, edgeId, cameraId)
          .pipe(
            switchMap(res => {
              return [CameraActions.GetLocationEdgesCamerasSuccess({ payload: [res] })];
            }),
            catchError((err: Error) =>
              of(
                CameraActions.GetLocationEdgesCamerasSnapshotsFail({
                  message: err?.message || '[CAMERA-EFFECTS] unkown error occured when trying to save snapshots',
                }),
              ),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private edgeStatusService: EdgeStatusService,
    private locationService: LocationsService,
    private thumbnailsService: ThumbnailsService,
    private edgeService: EdgeService,
    private camerasThumbnailsService: CamerasThumbnailsService,
  ) {
  }
}
