<ng-container *ngrxLet="selectIsInitialLoaded$;let isInitialLoaded">
  <div class="ui-modal">
    <div class="header flex-row space-between-center">
      <span>Pending invitations </span>
      <span class="ui-icon-close c-p" mat-dialog-close></span>
    </div>
    <div class="body" *ngrxLet="selectOrganizationInvites$;let invites">
      <div class="flex-row space-between-center">
        <div class="search-wrapper">
          <ui-search placeholder="Search user invitation" (onInput)="search($event)"></ui-search>
        </div>
        <div class="flex-row">
          <ui-button
            type="noBorderPrimary"
            text="Revoke all"
            (clickAction)="revokeAll()"
          ></ui-button>

          <ui-button
            type="primary"
            text="Resend all"
            (clickAction)="resendAll()"
          ></ui-button>
        </div>
      </div>
      <div class="invite-content" #scrollContainer>
        <ng-container *ngIf="isInitialLoaded;else loader">
          <ng-container *ngIf="invites?.length;else noDataResult">
            <cdk-virtual-scroll-viewport
              [itemSize]="40"
              [style.height.px]="viewPortHeight"
            >
              <div *ngFor="let invite of invites"
                   class="invite-row flex-row space-between-center"
              >
                <span>{{invite.userEmail}}</span>
                <div class="hover">
          <span class="ui-icon-resend c-p icon"
                matTooltip="Resend invite"
                matTooltipClass="sidebar-tooltip"
                (click)="resendInvite(invite._id)"
          ></span>
                  <span class="ui-icon-delete c-p icon"
                        matTooltip="Revoke invite"
                        matTooltipClass="sidebar-tooltip"
                        (click)="cancelInvite(invite._id)"
                  ></span>
                </div>
              </div>

            </cdk-virtual-scroll-viewport>
          </ng-container>

        </ng-container>
      </div>
    </div>
    <div class="footer" fxLayout="row" fxLayoutAlign="end center">
      <ui-button
        type="default"
        text="Done"
        (click)="close()"
      ></ui-button>
    </div>
  </div>
</ng-container>


<ng-template #loader>
  <ui-preloader [color]="loaderColor.blue"></ui-preloader>
</ng-template>

<ng-template #noDataResult>
  <ui-no-data [imgSrc]="noResults.imgSrc" [title]="noResults.title" [description]="noResults.description"
              [createButtonText]="noResults.createButtonText"
              (onCreate)="openInviteUsersModal()"
  >
  </ui-no-data>
</ng-template>
