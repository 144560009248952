<div fxLayout="column" fxLayoutAlign="center start" class="wrapper">
  <div fxLayout="row" fxLayoutAlign="end center" class="save-block">
    <ui-progress-button
      [loading]="selectIsSaving$ | ngrxPush"
      [disabled]="!(selectIsValid$ | ngrxPush)"
      icon="save"
      text1="Save"
      text2="Saved"
      (clickAction)="save()"
    >
    </ui-progress-button>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between start" class="main-settings mw-m p-30">
    <h1>Settings</h1>
    <div fxLayout="row" class="tab-containers" fxFill>
      <div class="view-tab" routerLink="profile" routerLinkActive="active">Profile</div>
      <div class="view-tab" routerLink="organization" routerLinkActive="active">Organization</div>
      <div class="view-tab" routerLink="password" routerLinkActive="active">Password</div>
    </div>
    <div style="height: calc(100vh - 290px); width: 100%; padding: 20px 0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
