import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secToMin',
})
export class SecToMinsDurationPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      const min = Math.floor(value / 60);
      const sec = Math.floor(value % 60);
      return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
    }
    return '--';
  }
}
