import { createAction, props } from '@ngrx/store';
import { IntegrationsModel } from '@models/integrations.model';

export const resetToInitialState = createAction('[INTEGRATIONS] Reset to Initial state');

export const getIntegrations = createAction('[INTEGRATIONS] Get integrations');
export const deactivateSlack = createAction('[INTEGRATIONS] Deactivate Slack');
export const installTeam3 = createAction('[INTEGRATIONS] Install Team3');
export const uninstallTeam3 = createAction('[INTEGRATIONS] Uninstall Team3');
export const getIntegrationsSuccess = createAction('[INTEGRATIONS] Set integrations', props<{ integrations: IntegrationsModel.Providers[] }>());

