<div fxLayoutAlign="center center" class="controls" fxFlexFill>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="wrapper" fxFlexFill>
    <div fxLayoutAlign="start center" fxLayoutGap="8px">
      <button mat-icon-button (click)="rewindPlayer(-5)">
        <div fxLayoutAlign="center center" class="svg-fill-gray">
          <mat-icon svgIcon="backward" style="line-height: 0px"></mat-icon>
        </div>
      </button>
      <button mat-icon-button>
        <div fxLayoutAlign="center center">
          <mat-icon *ngIf="!playing" class="play" color="primary" (click)="startPlaying()">play_arrow</mat-icon>
          <mat-icon *ngIf="playing" class="play" color="primary" (click)="stopPlayer()">pause</mat-icon>
        </div>
      </button>
      <button mat-icon-button (click)="rewindPlayer(5)">
        <div fxLayoutAlign="center center" class="svg-fill-gray">
          <mat-icon svgIcon="forward" style="line-height: 0px"></mat-icon>
        </div>
      </button>
    </div>
    <!--    <div fxLayoutAlign="start center">-->
    <!--      &lt;!&ndash;      {{ toDurationStr(time) }}&ndash;&gt;-->
    <!--      {{ time + videoCurrentTime | date: 'HH:mm:ss z' : timezone  }}-->
    <!--    </div>-->
    <div></div>
    <div *ngIf="false" fxLayoutAlign="start center" fxLayoutGap="8px" class="svg-fill-gray right-actions c-p">
      <!--      <mat-icon-->
      <!--        matTooltip="Not implemented yet"-->
      <!--        style="height: 16px"-->
      <!--        svgIcon="picture"></mat-icon>-->
      <mat-icon (click)="max()" style="height: 22px" svgIcon="maximize"></mat-icon>
      <!--            <mat-icon style="height: 18px;" svgIcon="addFile"></mat-icon>-->
      <!--      <mat-icon matTooltip="Download video" (click)="download()"-->
      <!--        >save_alt</mat-icon-->
      <!--      >-->
      <button mat-button mat-icon-button [matMenuTriggerFor]="optionsMenu" class="search">
        <mat-icon style="height: 19px" svgIcon="player-settings"></mat-icon>
      </button>
      <mat-menu yPosition="above"
                xPosition="before"
                #optionsMenu="matMenu"
                fxLayoutAlign="column"
                class="controls-options"
      >
        <button mat-menu-item [matMenuTriggerFor]="quality">
          <mat-icon>tune</mat-icon>
          Quality
        </button>
      </mat-menu>
      <mat-menu #quality="matMenu" class="controls-options">
        <button mat-menu-item (click)="resolution(resolutions.R1080)">1080p</button>
        <button mat-menu-item (click)="resolution(resolutions.R720)">720p</button>
        <button mat-menu-item (click)="resolution(resolutions.R480)">480p</button>
        <button mat-menu-item (click)="resolution(resolutions.R360)">360p</button>
        <button mat-menu-item (click)="resolution(resolutions.R240)">240p</button>
        <button mat-menu-item (click)="resolution(resolutions.R144)">144p</button>
        <button mat-menu-item (click)="resolution(resolutions.AUTO)">Auto</button>
      </mat-menu>
    </div>
    <div class="c-p">
      <mat-icon (click)="showCreateArchiveModal()">system_update_alt</mat-icon>
    </div>
  </div>
</div>
