import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { api } from '@consts/url.const';
import { HttpService } from '../core/http.service';
import { OrgGroupModels } from '@models/org-group.model';
import { AddPeopleRequest, Person } from "@models/people.model";

@Injectable()
export class OrgGroupsService {
  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  public getOrgGroups(orgGroupType: OrgGroupModels.OrgGroupType): Observable<OrgGroupModels.GetOrgGroupsResponse> {
    return this.http.get<OrgGroupModels.GetOrgGroupsResponse>(api.orgGroups.byType(orgGroupType));
  }

  public getUnsavedOrgGroups(page?: number, size?: number): Observable<OrgGroupModels.GetOrgGroupsResponse> {
    let url = `${api.orgGroups.crud}?status=unsaved`;
    if ((page || page === 0) && size) {
      url += `&pageNumber=${page}&pageSize=${size}`;
    }
    return this.http.get<OrgGroupModels.GetOrgGroupsResponse>(url);
  }

  public getSavedOrgGroups(): Observable<OrgGroupModels.GetOrgGroupsResponse> {
    const url = `${api.orgGroups.crud}?status=saved`;
    return this.http.get<OrgGroupModels.GetOrgGroupsResponse>(url);
  }

  public addOrgGroup(orgGroup: OrgGroupModels.OrgGroup) {
    const request: OrgGroupModels.AddOrgGroupRequest = orgGroup;
    return this.http.post<OrgGroupModels.OrgGroup>(api.orgGroups.add, request);
  }

  public editOrgGroup(orgGroup: OrgGroupModels.OrgGroup) {
    const request: OrgGroupModels.AddOrgGroupRequest = orgGroup;
    return this.http.post<OrgGroupModels.OrgGroup>(api.orgGroups.edit, request);
  }

  public removeOrgGroup(orgGroup: OrgGroupModels.OrgGroup) {
    return this.http.delete(api.orgGroups.remove(orgGroup.id));
  }

}
