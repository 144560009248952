import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeHeartbeatPulsationActions } from './edge-heartbeat-pulsation-types';

export interface EdgePulsationState extends EntityState<PulsationModels.Store.EdgePulsation> {
  message?: string;
  error?: string;
  loading: boolean;
}

export const adapter: EntityAdapter<PulsationModels.Store.EdgePulsation> = createEntityAdapter<PulsationModels.Store.EdgePulsation>({
  selectId: (status: PulsationModels.Store.EdgePulsation) => status.edgeId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialState: EdgePulsationState = adapter.getInitialState({
  loading: true,
});

export const edgePulsationStatusReducer = createReducer(
  initialState,

  on(EdgeHeartbeatPulsationActions.GetEdgePulsationStatusSuccess, (state, action) => {
    return adapter.setOne(action.payload, {
      ...state,
    });
  }),

  on(EdgeHeartbeatPulsationActions.GetEdgePulsationStatusFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),
  on(EdgeHeartbeatPulsationActions.setLoading, (state, {loading}) => {
    return {
      ...state,
      loading,
    };
  })
);
