import { ActionReducer, MetaReducer } from '@ngrx/store';

export const globalStoreExceptionHandlerMetaReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    try {
      state = reducer(state, action);
    } catch (error) {
      console.error('CRITICAL ERROR! Exception in the store module. \n', error);
    }
    return state;
  };

export const metaReducers: MetaReducer[] = [globalStoreExceptionHandlerMetaReducer];
