export interface Comment {
  replyId: string,
  name: string,
  text: string
}

export enum CommentEntityType {
  grantedAccess
}

export interface CommentRequest extends Comment {
  entityId: string;
  entityType: CommentEntityType;
  timestamp?: number;
}

