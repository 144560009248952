import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as AnalyticsActions from '@states/analytics/analytics.actions';
import { AnalyticsService } from '../../development/analytics.service';
import { catchError, of, share, switchMap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Analytics } from '../../analytics/analytics.model';
import * as moment from 'moment';
import { AnalyticsChart } from '@models/analytic-chart.models';
import { Sort } from '../../shared/shared.model';

@Injectable()
export class AnalyticsEffects {
  public getAnalyticsOccupancyCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getAnalyticsOccupancyCounter),
      withLatestFrom(this.store$.pipe(select(state => state.analyticsState))),
      switchMap(([, { perPage, page, edgeId, cameraId, start, end, latestTs, sortDir, pagesTimestamp }]) => {
        const filterSetting: Analytics.AnalyticsOccupancyCounterQuery = {
          edgeId: edgeId,
          cameraId: cameraId,
          start: start,
          end: end,
          sortDir,
        };
        /**
         * Detect if we paginate back
         */
        if (page < pagesTimestamp.length) {
          if (sortDir === Sort.DESC) {
            filterSetting.end = pagesTimestamp[page];
          } else {
            filterSetting.start = pagesTimestamp[page];
          }
        } else {
          // if we paginate forward make sure that latestTs is present
          if (latestTs) {
            if (sortDir === Sort.DESC) {
              filterSetting.end = latestTs;
            } else {
              filterSetting.start = latestTs;
            }
          }
        }
        return this.analyticsService.getAnalyticsOccupancyCounter(filterSetting, 0, perPage).pipe(
          switchMap(res => {
            // transform data async
            const data = res.results.map(item => {
              return {
                series: item.objects,
                name: moment(item.timestamp).format('h:mm:ss'),
              };
            });
            let analyticsChartAxisYMax = 0;
            const analyticsChartLegends = [];
            const chartDataLookup = {};
            data?.forEach(item => {
              if (!chartDataLookup[item.name]) {
                chartDataLookup[item.name] = {
                  group: item.name,
                };
              }
              item?.series?.forEach(seria => {
                if (analyticsChartAxisYMax < seria.value) {
                  analyticsChartAxisYMax = seria.value;
                }
                // Added legends
                if (analyticsChartLegends.indexOf(seria.name) == -1) {
                  analyticsChartLegends.push(seria.name);
                }
                if (chartDataLookup[item.name][seria.name]) {
                  chartDataLookup[item.name][seria.name] = Math.max(chartDataLookup[item.name][seria.name], seria.value);
                } else {
                  chartDataLookup[item.name][seria.name] = seria.value;
                }
              });
            });

            /**
             * Save pagination history
             */
            let timestamp;
            if (sortDir === Sort.DESC) {
              timestamp = filterSetting.end;
            } else {
              timestamp = filterSetting.start;
            }
            const tmpPagesTimestamp = [...pagesTimestamp];
            if (tmpPagesTimestamp.indexOf(timestamp) === -1) {
              tmpPagesTimestamp.push(timestamp);
            }

            // Object values return unknown
            const analyticsChartItems = Object.values(chartDataLookup) as unknown[] as AnalyticsChart[];
            const analyticsChartAxisXLabels = analyticsChartItems.map(item => item.group);
            const isLastPage = res.results.length < perPage;
            return [
              AnalyticsActions.getAnalyticsOccupancyCounterSuccess({
                items: res.results,
              }),
              AnalyticsActions.setAnalyticsChartItems({
                analyticsChartItems,
              }),
              AnalyticsActions.setAnalyticsChartLegends({
                analyticsChartLegends,
              }),
              AnalyticsActions.setAnalyticsChartAxisYMax({
                analyticsChartAxisYMax,
              }),
              AnalyticsActions.setAnalyticsChartAxisXLabels({
                analyticsChartAxisXLabels,
              }),
              AnalyticsActions.setLatestTs({ latestTs: res.latestTs }),
              AnalyticsActions.setIsLastPage({ isLastPage }),
              AnalyticsActions.setPageTimestamp({
                pagesTimestamp: tmpPagesTimestamp,
              }),
            ];
          }),
          catchError(err => {
            return of(AnalyticsActions.getAnalyticsOccupancyCounterFail());
          })
        );
      }),
      share()
    )
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private analyticsService: AnalyticsService) {}
}
