import { createReducer, on } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { UserActions } from './user.action-types';
import { Nullable, StringOrEmpty } from 'src/app/app.model';
import { AppUser } from '../../../user/user.model';

export interface UserState extends Nullable<AppUser.User> {
  isProfileLoaded: boolean;
  error: StringOrEmpty;
  verifiedPhone: string;
  isLoginLoading: boolean;
}

const initialUserState: UserState = {
  isProfileLoaded: false,
  error: undefined,
  _id: null,
  authStrategy: null,
  authProviderId: null,
  created_at: null,
  updated_at: null,
  email: null,
  firstname: null,
  lastname: null,
  identities: null,
  edges: null,
  roles: null,
  orgs: null,
  settings: null,
  timezone: null,
  verifiedPhone: null,
  isLoginLoading: false,
  twilioPtt: null,
};

export const userReducer = createReducer(
  initialUserState,

  on(UserActions.CreateOrGetUserProfileSuccess, (state, action) => {
    return {
      ...state,
      ...action.payload,
      isProfileLoaded: true,
      verifiedPhone: action.payload.isPhoneVerified ? action.payload.phone : initialUserState.verifiedPhone,
    };
  }),

  on(UserActions.CreateOrGetUserProfileFail, (state, action) => {
    return {
      ...initialUserState,
      error: action.message,
    };
  }),

  on(UserActions.UpdateUserProfileSuccess, (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  }),

  on(UserActions.UpdateUserProfileFail, (state, action) => {
    return {
      ...initialUserState,
      error: action.message,
    };
  }),
  on(UserActions.setTimezone, (state, { timezone }) => {
    return {
      ...state,
      timezone,
    };
  }),
  on(UserActions.setPhone, (state, { phone }) => {
    return {
      ...state,
      phone,
    };
  }),
  on(UserActions.setPhoneVerified, (state, { isPhoneVerified }) => {
    return {
      ...state,
      isPhoneVerified,
    };
  }),
  on(UserActions.setIsLoginLoading, (state, { isLoginLoading }) => {
    return {
      ...state,
      isLoginLoading,
    };
  }),
  on(UserActions.renewTwilioPttTokenSuccess, (state, { twilioPtt }) => {
    return {
      ...state,
      twilioPtt,
    };
  }),
);

export const USER_REDUCER = new InjectionToken<any>('User Reducer', {
  factory: () => userReducer,
});
