import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Operator } from './operator.model';
import { api } from '../consts/url.const';
import { AddToWhitelistResponse, WhiteListRequest } from '@models/operator.model';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  private edgeSubject = new BehaviorSubject<Operator.EdgeDocument | undefined>(undefined);

  public edge$: Observable<Operator.EdgeDocument | undefined> = this.edgeSubject.asObservable()
    .pipe(filter(res => !!res));

  constructor(private http: HttpClient) {
  }

  public cleanEdge(): void {
    this.edgeSubject.next(null);
  }

  createEdge(edgeCreateRequest: Operator.EdgeCreateRequest): Observable<Operator.EdgeDocument> {
    const url = `${environment.apiUrl}/catalog`;
    return this.http.post<Operator.EdgeCreateRequest>(url, edgeCreateRequest)
      .pipe(tap(res => this.edgeSubject.next(res)));
  }

  public download(edgeId: string) {
    return this.http.post(api.catalog.download, { edgeId });
  }

  unproviseEdge(data: { edgeId: string }): Observable<Operator.EdgeDocument> {
    const url = `${environment.apiUrl}/catalog/${data.edgeId}/unprovision`;
    return this.http.post<Operator.EdgeCreateRequest>(url, data)
      .pipe(tap(res => this.edgeSubject.next(res)));
  }

  getEdge(data: { edgeId: string }): Observable<Operator.EdgeDocument> {
    const url = `${environment.apiUrl}/catalog/${data.edgeId}`;
    return this.http.post<Operator.EdgeCreateRequest>(url, data)
      .pipe(tap(res => this.edgeSubject.next(res)));
  }

  public submitWhiteList(data: WhiteListRequest): Observable<AddToWhitelistResponse[]> {
    return this.http.post<AddToWhitelistResponse[]>(api.operator.whitelist, data);
  }
}
