import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { DeviceModel } from '@models/device.model';
import { Observable } from 'rxjs';
import { SQSMsgInfo } from '../core/interfaces';


@Injectable()
export class LocationEditService {
  constructor(private http: HttpClient) {
  }


  public testSpeaker(request: DeviceModel.DeviceConnectivityMsg): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.location.deviceConnectivity, request);
  }

  public addSpeaker(request: DeviceModel.DeviceAdd): Observable<{ deviceId: string }> {
    return this.http.put<{ deviceId: string }>(api.location.device, request);
  }

  public deleteSpeaker(locationId: string, deviceId: string) {
    const url = `${api.location.device}/${locationId}/${deviceId}`;
    return this.http.delete(url);
  }

}
