import { CameraBrands, EdgeCamera } from '../../cameras/camera.model';
import { SelectedCamera } from '@models/alert-events.model';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { ScanZones } from '../../pages/camera-edit/tabs/search-select-zones/search-select-zones.model';
import { CameraPrivacy } from '@models/camera.model';
import { KeyValuePairs } from '../../core/interfaces';

export interface SearchConfigurationBase {
  _id?: string;
  zones: ScanZones;
  zonesExclude?: boolean;
  definedZones: boolean;
  protectiveGear?: boolean;
  forklift?: boolean;
  container?: boolean;
  shoppingCart?: boolean;
  markedIdx: number[];
  objectsToScan: AnalyticClasses[];
  licensePlates: boolean;
  vehicleMMC: boolean;
  privacy: CameraPrivacy;
}

export interface SearchConfigurationSendModel extends SearchConfigurationBase {
  selectedCamera: SelectedCamera;
}

export declare interface SearchConfigurationModel extends SearchConfigurationBase {
  selectedCamera: EdgeCamera.CameraItem;
}

interface DeviceInfo {
  serialNumber: string,
  model: string,
  firmwareVersion: string
  brand?: CameraBrands
}

export enum IpAddressType {
  dhcp = 'dynamic',
  static = 'static'
}

export interface NetworkParams {
  ipAddress?: string,
  addressType?: IpAddressType,
  subnetMask?: string,
  defaultGateway?: string,
  macAddress?: string
}

export enum CapabilityType {
  String = 'String',
  Selector = 'Selector',
  Range = 'Range',
  Resolution = 'Resolution'
}

export interface StringInputField {
  type: CapabilityType.String;
  value: string;
}

export interface SelectorInputField {
  type: CapabilityType.Selector;
  value: string;
  options: string[];
}

export interface RangeInputField {
  type: CapabilityType.Range;
  value: number;
  min: number;
  max: number;
}

export interface ResolutionInputField {
  type: CapabilityType.Resolution;
  value: [number, number];
  options: [number, number][];
}

export type InputField = StringInputField | SelectorInputField | RangeInputField | ResolutionInputField

export enum UserLevel {
  Admin = 'Admin',
  Viewer = 'Viewer'
}

export interface CameraStreamCapabilities {
  enabled?: boolean;
  streamId?: number;
  name?: string;
  resolution?: ResolutionInputField;
  bitrateType?: InputField;
  videoQuality?: InputField;
  compression?: RangeInputField;
  bitrate?: InputField;
  framerate?: InputField;
  codecType?: InputField;
  iframeInterval?: InputField;
  url?: string;
}

export interface CameraUser {
  id?: string,
  username: string,
  password?: string,
  userLevel: UserLevel
}

export interface VpnConfiguration {
  deviceInfo: DeviceInfo,
  networkParams: NetworkParams,
  users: CameraUser[],
  streamsCapabilities: CameraStreamCapabilities[]
}

export interface VpnUpdateError {
  vpnConfiguration?: VpnConfiguration;
  networkError: KeyValuePairs<string>; // <field> -> msg
  usersError: KeyValuePairs<string>; // <idx> -> msg
  streamsError: KeyValuePairs<string>; // <streamId> -> msg
}
