import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, StorageState } from './storage.reducer';
import * as moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { ThumbnailModel } from '@models/thumbnail.model';

const FREQUENCY = 2000;
const BASE_16 = 16000;

export const selectStorageState = createFeatureSelector<StorageState>('storageState');

export const selectAllStorage = createSelector(selectStorageState, selectAll);

export const selectCurrentOfflineStats = createSelector(selectStorageState,
  (storage: StorageState) => storage.currentOfflineStorage);
export const selectCurrentSmartStorageStats = createSelector(selectStorageState,
  (storage: StorageState) => storage.currentSmartStorage);

export const selectStorageEventsByEdgeIdCameraIdAndBase = (selector: { edgeId: string; cameraId: string; base: number }) =>
  createSelector(
    selectStorageState,
    (storage: StorageState) => storage.entities[`${selector.edgeId}:${selector.cameraId}:${selector.base}`],
  );

export const selectStorageEventsByEdgeIdCameraIdAndBaseExist = (selector: { edgeId: string; cameraId: string; base: number }) =>
  createSelector(
    selectStorageState,
    (storage: StorageState) =>
      !!(storage.ids as string[]).includes(`${selector.edgeId}:${selector.cameraId}:${selector.base}`) &&
      !!storage.entities[`${selector.edgeId}:${selector.cameraId}:${selector.base}`]?.noStorage &&
      !!storage.entities[`${selector.edgeId}:${selector.cameraId}:${selector.base}`]?.smartStorage
    ,
  );

// export const selectStorageEventsByEdgeIdCameraIdAndRange = (selector: { edgeId: string; cameraId: string; start: number; end: number }) =>
//   createSelector(selectStorageState, (thumbnails: StorageState) => getSlice(selector, thumbnails));

const getBaseInLocale = (dateRaw: Date, tz: string = 'GMT') => {
  /**
   * dateRaw is given in browser timezone, and should be 'converted' to edge timezone
   * conversion is done by calculating the offset between the browser timezone and the edge timezone
   * then the offset is added to the original date, so it will be aligned with the edge time.
   * Then, we calculate the GMT timestamp using a conversion with moment and the original timezone
   * (which is the browser tz)
   */
  const browserTz = moment.tz.guess(); // ex. 'America/San Jose'
  const timezone = 'GMT';
  const dateInEdgeTz = moment.tz(dateRaw, timezone)
    .toString();
  const startOfDay = DateTime.fromJSDate(new Date(dateInEdgeTz))
    .setZone(timezone)
    .startOf('day')
    .toJSDate();
  const currentLocale = moment.tz(startOfDay, timezone)
    .format('YYYY-MM-DD HH:mm');
  return moment.tz(currentLocale, 'GMT')
    .unix() * 1000;
};

const getEventLocation = (timestamp: number, base: number, freq: number = FREQUENCY) => {
  return Math.floor((timestamp - base) / freq);
};

const spreadEvents = (eventsRaw: number[]): number[] => {
  if (!eventsRaw?.length) {
    return [];
  }
  const events: number[] = [];
  for(let index = 0; index < eventsRaw?.length; index++) {
    let val = eventsRaw[index];
    if (val) {
    }
    events.push(val & 0x0000000f);
    events.push((val & 0x000000f0) >> (4 * 1));
    events.push((val & 0x00000f00) >> (4 * 2));
    events.push((val & 0x0000f000) >> (4 * 3));
    events.push((val & 0x000f0000) >> (4 * 4));
    events.push((val & 0x00f00000) >> (4 * 5));
    events.push((val & 0x0f000000) >> (4 * 6));
    events.push((val & 0xf0000000) >> (4 * 7));
  }
  return events;
};

// const getSlice = (selector: ThumbnailModel.SelectSlice, state: StorageState) => {
//   if (!state || !state.entities) {
//     return [];
//   }
//   let events = [];
//   const startTime = selector.start;
//   const endTime = selector.end;
//   const base = getBaseInLocale(new Date(selector.start));
//   const baseEnd = getBaseInLocale(new Date(selector.end));
//   const eventsBaseRaw = state.entities[`${selector.edgeId}:${selector.cameraId}:${base}`]?.stats;
//   const eventsBaseEndRaw = state.entities[`${selector.edgeId}:${selector.cameraId}:${baseEnd}`]?.stats;
//   const eventsBase = spreadEvents(eventsBaseRaw);
//   const eventsBaseEnd = spreadEvents(eventsBaseEndRaw);
//
//
//   if (!!eventsBase || !!eventsBaseEnd) {
//     if (base !== baseEnd && baseEnd !== endTime) {
//       const indexStart = getEventLocation(startTime, base);
//       const indexEnd = getEventLocation(endTime, baseEnd);
//
//       // We need to combine 2 days to one array
//       let first: number[] = [];
//       let second: number[] = [];
//       if (!!eventsBase) {
//         first = eventsBase.slice(indexStart, eventsBase.length);
//       } else {
//         first = new Array(43200).fill(0)
//           .slice(indexStart, 43200);
//       }
//       if (!!eventsBaseEnd) {
//         second = eventsBaseEnd.slice(0, indexEnd);
//       } else {
//         second = new Array(43200).fill(0)
//           .slice(0, indexEnd);
//       }
//
//       events = first.concat(second);
//     } else {
//       if (!!eventsBase) {
//         const indexStart = getEventLocation(startTime, base);
//         const indexEnd = getEventLocation(endTime, base);
//         events = eventsBase.slice(indexStart, indexEnd);
//       }
//     }
//     return events;
//   } else {
//     return [];
//   }
// }
