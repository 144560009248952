import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { WebhookModel } from '@models/webhook.model';

@Injectable()
export class WebhookService {

  constructor(private http: HttpClient) {
  }

  public getOrganizationWebhooks(): Observable<WebhookModel.DocumentMongo[]> {
    return this.http.get<WebhookModel.DocumentMongo[]>(api.integrations.getWebhooks);
  }

  public create(data: WebhookModel.CreateDocumentDto): Observable<WebhookModel.DocumentMongo> {
    return this.http.post<WebhookModel.DocumentMongo>(api.integrations.installWebhook, data);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(api.integrations.getOneWebhook(id));
  }

  public update(data: WebhookModel.DocumentMongo): Observable<WebhookModel.DocumentMongo> {
    return this.http.put<WebhookModel.DocumentMongo>(api.integrations.getOneWebhook(data._id), data);
  }
}
