import { createReducer, on } from '@ngrx/store';
import * as IntegrationsActions from '@states/integrations/integrations.actions';
import { IntegrationsModel } from '@models/integrations.model';

export declare interface IntegrationsState {
  integrations: IntegrationsModel.Providers[];
}

export const initialState: IntegrationsState = {
  integrations: null,
};

export const integrationsStateReducer = createReducer(
  initialState,
  on(IntegrationsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(IntegrationsActions.getIntegrationsSuccess, (state, { integrations }) => {
    return {
      ...state,
      integrations,
    };
  }),
);
