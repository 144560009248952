import { Component, Input, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { AppState } from '../../../../store/app.state';
import { Archive, ArchiveStatus } from '@models/archive.model';
import { GrantedAccessType } from '@enums/granted-access.enum';
import * as LocationModel from '../../../../locations/location.model';
import ArchiveDocument = Archive.ArchiveDocument;

@Component({
  selector: 'app-shared-archive',
  templateUrl: './shared-archive.component.html',
  styleUrls: ['./shared-archive.component.scss', '../shared-alert/shared-alert.component.scss'],
})
export class SharedArchiveComponent implements OnInit {
  @Input() sharedArchive: ArchiveDocument;
  @Input() archiveUrl: string;

  public selectSelectedLocation$: Observable<LocationModel.LocationModel.LocationItem> = this.store$.pipe(select(GrantedAccessSelectors.selectSelectedLocation));
  public selectAccessToken$: Observable<string> = this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken));
  public selectIsRecordRemoved$: Observable<boolean> = this.store$.pipe(select(GrantedAccessSelectors.selectIsRecordRemoved));

  public grantedAccessTypes = GrantedAccessType;
  public archiveStatuses = ArchiveStatus;

  constructor(private store$: Store<AppState>) {
  }

  ngOnInit(): void {

  }


  public openMail() {
    window.open(`mailto:sales@lumana.ai`);
  }

}
