<div fxLayout="column" fxLayoutAlign="center center">
  <div>Crop image to fit icon dimensions</div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    [roundCropper]="true"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <div fxLayout="row" fxLayoutAlign="end end" class="button-container">
    <button mat-button (click)="cancel()">Cancel</button>
    <ui-button colorBgr="#4367F6" colorText="white" icon="save" iconSize="16" colorIcon="white" text="Save" (clickAction)="save()">
    </ui-button>
  </div>
</div>
