import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SearchSelectorOperators, SearchSelectorPersonProperties, SearchSelectorTypes } from './search-selector/search-selector.model';
import { SearchQueryOperator, SearchSelection, SearchSelectionProperty } from './advanced-search.model';
import { SearchService } from '../search.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';
import * as MultiSearchActions from '@states/multi-search/multi-search.actions';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';

import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
/**
 * @deprecated
 */
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  SearchSelectorTypes: typeof SearchSelectorTypes = SearchSelectorTypes;
  // SearchSelectorPersonAccessories: typeof SearchSelectorPersonAccessories = SearchSelectorPersonAccessories;
  SearchSelectorPersonProperties: typeof SearchSelectorPersonProperties = SearchSelectorPersonProperties;
  SearchSelectorOperators: typeof SearchSelectorOperators = SearchSelectorOperators;
  SearchSelectorPersonPropertiesOptions = this.SearchSelectorPersonProperties.map(p => {
    return {name: p.label, value: p.name};
  });

  searchSelection: SearchSelection[] = [];

  selectedSelection: SearchSelection;

  // Color picker configurations
  hideColorPicker: boolean = true;
  hideTextInput: boolean = true;
  colorPalette: Array<any> = [
    '#660099',
    '#FFC0CB',
    '#FF0000',
    '#964B00',
    '#FF681F',
    '#FFD700',
    '#FFFF00',
    '#00FF00',
    '#40E0D0',
    '#0000FF',
    '#808080',
    '#FFFFFF',
    '#000000',
  ];

  subscriptions: Subscription[] = [];

  peopleCounter = 1;
  vehicleCounter = 1;
  petsCounter = 1;

  outerOperator = SearchQueryOperator.OR;

  highConfidence = true;

  constructor(
    private searchService: SearchService,
    private dialogRef: MatDialogRef<AdvancedSearchComponent>,
    private cd: ChangeDetectorRef,
    private store$: Store,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { multi: boolean }
  ) {
  }

  ngOnInit(): void {
    this.store$
      .select(MultiSearchSelectors.selectSearchHighConfidence)
      .pipe(take(1))
      .subscribe(highConfidence => {
        this.highConfidence = highConfidence;
      });
    if (!this.data.multi) {
      this.searchService.searchSelections$.pipe(take(1)).subscribe(searchSelection => {
        this.searchSelection = searchSelection;
        this.selectedSelection = this.searchSelection[0];
        // this.outerOperator = this.searchSelection[0].operator ?? SearchQueryOperator.AND;
      });
    } else {
      this.store$
        .select(MultiSearchSelectors.selectSearchSelections)
        .pipe(take(1))
        .subscribe(searchSelection => {
          if (!!searchSelection.length) {
            // this.searchSelection = _.cloneDeep(searchSelection);
            this.selectedSelection = this.searchSelection[0];
            // this.outerOperator = this.searchSelection[0].operator ?? SearchQueryOperator.AND;
          }
        });
    }
  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  hasAccessories(type: string) {
    // Need to make it more robust in order to add more types that has accessories or traits, right now we allow for person
    return type === 'person';
  }

  isVehicle(type: string) {
    return type === 'vehicle';
  }

  // select(type: string, index: number) {
  //   this.searchSelection[index].type = type;
  //   if (this.hasAccessories(type)) {
  //     this.searchSelection[index].properties = [{value: []}];
  //   }
  //
  //   if (this.isVehicle(type)) {
  //     this.searchSelection[index].alpr = {types: [], colors: []};
  //   }
  // }

  select(selection: SearchSelection) {
    // if (selection.type === 'pet') {
    //   return;
    // }
    this.selectedSelection = selection;
  }

  updateAccessory(accessory: string, index: number, accIndex: number) {
    this.searchSelection[index].properties[accIndex].name = accessory;
  }

  updateAccessoryOp(operator: string, index: number, accIndex: number) {
    this.searchSelection[index].properties[accIndex].operator = <SearchQueryOperator>operator;
  }

  // updateColor(color: string, index: number, accIndex: number) {
  //   const colorName: string = GetColorName(color);
  //   this.searchSelection[index].properties[accIndex].color = colorName.toLowerCase();
  // }

  updateOp(operator: string, index: number) {
    this.searchSelection[index].operator = <SearchQueryOperator>operator;
  }

  getNextIndex(type: string): number {
    const idxMapping = this.searchSelection.filter(item => item.type === type).map(item => item.idx);
    if (idxMapping.length === 0) {
      return 1;
    }
    const min = _.min(idxMapping);
    const max = _.max(idxMapping);
    if (max === idxMapping.length) {
      return max + 1;
    } else {
      for (let i = 1; i < max; i++) {
        if (!idxMapping.includes(i)) {
          return i;
        }
      }
    }
    return 0;
  }

  add(type?: string) {
    const lastIdx = this.searchSelection.length - 1;
    if (lastIdx === 0) {
      this.searchSelection[lastIdx].operator = SearchQueryOperator.OR;
    }

    if (!!type) {
      const selection: SearchSelection = {
        type,
      };
      selection.idx = this.getNextIndex(type);
      if (this.hasAccessories(type)) {
        const personProps: SearchSelectionProperty[] = this.SearchSelectorPersonProperties.map(item => {
          return {
            name: item.name,
            value: [],
            colors: item.colors ? [] : undefined,
          };
        });
        personProps[0].operator = SearchQueryOperator.AND;
        selection.properties = [...personProps];
      }

      if (this.isVehicle(type)) {
        selection.alpr = {types: [], colors: [], make: [], operator: SearchQueryOperator.AND};
      }
      if (type === 'pet') {
      }
      this.searchSelection.push(selection);
      if (this.searchSelection.length === 1) {
        this.selectedSelection = this.searchSelection[0];
      } else {
        this.selectedSelection = this.searchSelection[this.searchSelection.length - 1];
      }
    } else {
      this.searchSelection.push({type: ''});
    }
    this.searchSelection = this.searchSelection.sort((a, b) => a?.idx - b?.idx);
  }

  addAccessory(index: number) {
    const lastIdx = this.searchSelection[index].properties.length - 1;
    if (lastIdx === 0) {
      this.searchSelection[index].properties[0].operator = SearchQueryOperator.OR;
    }
    this.searchSelection[index].properties.push({value: []});
  }

  remove(index: number) {
    if (this.selectedSelection === this.searchSelection[index]) {
      this.selectedSelection = this.searchSelection[0];
    }
    this.searchSelection.splice(index, 1);
    if (this.searchSelection.length === 1) {
      this.selectedSelection = this.searchSelection[0];
    }
    if (this.searchSelection.length === 0) {
      this.selectedSelection = undefined;
    }
  }

  // remove(s: SearchSelection) {
  //   const idx = this.searchSelection.findIndex(item => s === item);
  //   this.searchSelection.splice(idx, 1);
  //   if (this.searchSelection.length === 1) {
  //     this.selectedSelection = this.searchSelection[0];
  //   }
  //   if (this.searchSelection.length === 0) {
  //     this.selectedSelection = undefined;
  //   }
  // }

  removeAccessory(index: number, accIndex: number) {
    this.searchSelection[index].properties.splice(accIndex, 1);
  }

  // translateColors() {
  //   for (let entry of this.searchSelection) {
  //     if (entry.type !== 'person') {
  //       continue;
  //     }
  //     for (let acc of entry.properties) {
  //       if (!!acc.color && acc.color.includes('#')) {
  //         const colorName: string = GetColorName(acc.color);
  //         acc.color = colorName.toLowerCase();
  //       }
  //     }
  //   }
  // }

  search() {
    if (!this.searchSelection?.length) {
      this.snackBar.open('You must select at least one subject to start searching', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
      });
      return;
    }
    this.store$.dispatch(MultiSearchActions.setHighConfidence({highConfidence: this.highConfidence}));
    this.searchSelection[0].operator = this.outerOperator;
    if (!this.data.multi) {
      this.searchService.setSearchSelections(this.searchSelection);
    } else {
      // this.store$.dispatch(MultiSearchActions.setSearchSelections({ searchSelections: this.searchSelection }));
    }
    this.dialogRef.close();
  }

  propertyByName(name: string) {
    return this.SearchSelectorPersonProperties.find(s => s.name === name);
  }

  check(event: MatCheckboxChange, prop: SearchSelectionProperty) {
    if (event.checked) {
      prop.value?.push(event.source.value);
      return;
    }
    prop.value.splice(prop.value.indexOf(event.source.value), 1);
    return;
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  toDisplayStr(str: string) {
    return this.replaceAll(str, '_', ' ');
  }

  includes(str: string, array: string[]) {
    return array.includes(str);
  }

  radio(event: MatRadioChange, prop: SearchSelectionProperty) {
    prop.value = [event.value];
  }

  chipSelected(value: string, propValue: string[]) {
    return propValue.includes(value);
  }

  chip(event: { value: string; selected: boolean }, propValue: string[]) {
    if (event.selected) {
      propValue.push(event.value);
      return;
    }
    propValue.splice(propValue.indexOf(event.value), 1);
  }

  setColors(colors: string[], prop: SearchSelectionProperty) {
    prop.colors = colors;
  }

  get people() {
    return this.searchSelection.filter(item => item.type === 'person');
  }

  get vehicles() {
    return this.searchSelection.filter(item => item.type === 'vehicle');
  }

  get pets() {
    return this.searchSelection.filter(item => item.type === 'pet');
  }

  subIdx(s: SearchSelection) {
    const type = s.type;
    let arr: SearchSelection[];
    switch (type) {
      case 'person':
        arr = this.people;
        break;
      case 'vehicle':
        arr = this.vehicles;
        break;
      case 'pet':
        arr = this.pets;
        break;
    }

    return arr.findIndex(item => item === s);
  }
}
