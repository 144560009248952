import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { IdentityService } from '../../services/identity.service';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { share } from 'rxjs';
import { IdentityActions } from '@states/identity/identity.action-types';
import { startChangePassword } from '@states/identity/identity.actions';
import { SharedActions } from '@states/shared/shared.action-types';
import { HttpStatusCode } from '@angular/common/http';
import { errorMsg } from '@consts/text.const';
import { auth0ErrorHandler } from '../../helpers/common.helpers';

@Injectable()
export class IdentityEffect {

  public getSignUpInviteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdentityActions.getUserIdentities),
      withLatestFrom(this.store$.pipe(select(state => state.userState))),
      switchMap(([, { email }]) =>
        this.identityService.getUserIdentities(email)
          .pipe(
            switchMap((res) => {
              return [
                IdentityActions.getUserIdentitiesSuccess({ identities: res }),
              ];
            }),
            catchError(err => {
              return [
                IdentityActions.getUserIdentitiesFail(),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public startChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdentityActions.startChangePassword),
      switchMap(({ password, newPassword }) =>
        [
          SharedActions.setIsLoading({ isLoading: true }),
          IdentityActions.changePasswordRequest({ password, newPassword }),
        ],
      ),
      share(),
    ),
  );


  public changePasswordRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdentityActions.changePasswordRequest),
      switchMap(({ password, newPassword }) =>
        this.identityService.changeUserPassword(password, newPassword)
          .pipe(
            switchMap((res) => {
              return [
                IdentityActions.changePasswordSuccess(),
                SharedActions.setIsLoading({ isLoading: false }),
                SharedActions.showMessage({ success: 'Password has been changed' }),
                IdentityActions.getUserIdentities(),
              ];
            }),
            catchError(err => {
              let errorDescription = auth0ErrorHandler(err);
              return [
                IdentityActions.changePasswordFail({ description: errorDescription }),
                SharedActions.setIsLoading({ isLoading: false }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private identityService: IdentityService) {
  }
}
