import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eth-device-info-modal',
  templateUrl: './eth-device-info-modal.component.html',
  styleUrls: ['./eth-device-info-modal.component.scss'],
})
export class EthDeviceInfoModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public ethInterface: number = null;

  constructor(private matDialogRef: MatDialogRef<EthDeviceInfoModalComponent>) {}

  ngOnInit(): void {}

  public ok() {
    this.matDialogRef.close(this.ethInterface);
  }

  public cancel() {
    this.matDialogRef.close(null);
  }
}
