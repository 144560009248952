import { Pipe, PipeTransform } from '@angular/core';
import { TimeUnit } from '../shared/ui-kit/ui-relative-time-range-picker/ui-relative-time-range-picker.component';

@Pipe({
  name: 'timeUnitTransform',
})
export class TimeUnitTransform implements PipeTransform {
  transform(value: TimeUnit, ...args: unknown[]): string {
    switch (value) {
      case TimeUnit.Day:
        return 'Daily';
      case TimeUnit.Week:
        return 'Weekly';
      case TimeUnit.Month:
        return 'Monthly';
    }
  }
}
