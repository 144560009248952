import { Component, OnInit } from '@angular/core';
import { EdgeService } from '../edge.service';
import { Edge } from '../edge.model';

@Component({
  selector: 'app-camera-discover',
  templateUrl: './camera-discover.component.html',
  styleUrls: ['./camera-discover.component.scss'],
})
export class CameraDiscoverComponent implements OnInit {
  edge: Edge.EdgeDocument;
  loading = true;

  constructor(private edgeService: EdgeService) {
  }

  ngOnInit(): void {
    /**
     * ALways:
     * - when you manually subscribe() to observables you should always unsubscribe on destroy.
     * - if otherwise you use the "async" pipe -> angular will do it for you.
     */
    this.edgeService.currentEdge$.subscribe(edge => {
      this.edge = edge;
    });
    this.edgeService.discover$.subscribe(res => {
      this.loading = false;
    });
  }
}
