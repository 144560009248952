<div class="eula-wrapper">
  <div class="eula-header">
    <img src="assets/lumana_logo.svg">
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" class="eula-content-wrapper">
    <div fxLayout="column" class="eula-content" fxFlex="">
      <h1>LUMANA SOLUTION Terms of Use</h1>
      <div style="font-size: 14px" class="mb-30">Last updated October 30, 2023</div>
      <p>These Terms of Use (these “Terms of Use”) govern Your use of LUMANA’s hardware and LUMANA’s online
         subscription-based AI-centric video management software service that provides corporations and individuals with
         real-time visual intelligence (the “LUMANA Solution”). The LUMANA Solution includes (a) all products, including
         hardware products, and services offered by Lumana, Inc. (“LUMANA”) including at www.lumix.ai and LUMANA’s
         mobile
         device applications; and (b) all guides and other reference materials generally furnished by LUMANA with
         respect
         to the foregoing, whether in print or electronic form, as updated by LUMANA from time to time
         (“Documentation”).
         These Terms of Use govern Your use of all versions of the LUMANA Solution, including free trials and versions
         of
         the LUMANA Solution that offer limited functionality.</p>
      <p class="bold">If You do not agree with these Terms of Use, please do not purchase or use the Lumana
                      Solution.</p>
      <p>Please read these Terms of Use carefully. By executing an order form for the LUMANA Solution (an “Order Form”)
         or clicking on the box indicating Your acceptance of these Terms of Use on the registration page for the LUMANA
         Solution, You are stating that You have read and understand, and agree to be bound by, these Terms of Use.</p>
      <p>“You” (and all derivations thereof) means You or, if You are accepting these Terms of Use on behalf of a legal
         entity, such as a company or limited liability partnership, such legal entity. If You are entering into these
         Term of Use on behalf of a legal entity, You represent and warrant that You have the authority to bind such
         legal entity. If You do not have such authority or do not agree to these Terms of Use, neither You nor any such
         legal entity are permitted to use the LUMANA Solution.</p>
      <p>IMPORTANT NOTICE REGARDING ARBITRATION: THESE TERMS OF USE CONTAIN A MANDATORY ARBITRATION AGREEMENT, REQUIRING
         YOU TO RESOLVE ANY DISPUTE BETWEEN YOU AND LUMANA THROUGH FINAL AND BINDING INDIVIDUAL ARBITRATION, SUBJECT TO
         LIMITED EXCEPTIONS, RATHER THAN IN COURT, AND REQUIRING YOU TO FOREGO JURY TRIALS, CLASS, COLLECTIVE,
         AGGREGATE,
         MASS, REPRESENTATIVE, OR CONSOLIDATED ACTIONS OR PROCEEDINGS, AND ALL OTHER TYPES OF COURT PROCEEDINGS OF ANY
         AND EVERY KIND. YOU WILL BE BOUND BY THIS ARBITRATION AGREEMENT, UNLESS YOU OPT OUT OF THE ARBITRATION
         AGREEMENT
         BY FOLLOWING THE OPT-OUT PROCEDURES SET FORTH BELOW. BY ENTERING INTO THESE TERMS OF USE, YOU EXPRESSLY
         ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL THE TERMS OF THE ARBITRATION AGREEMENT AND HAVE TAKEN TIME TO
         CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.</p>
      <p>Dispute Resolution – Binding Individual Arbitration and Class Action/Jury Trial Waiver (“Arbitration
         Agreement”).</p>
      <p>Mandatory Binding Arbitration of Disputes. You and LUMANA agree that, subject to limited exceptions specified
         in
         this Arbitration Agreement, all disputes, causes of action, or claims arising out of, in connection with, or
         related to these Terms of Use or this Arbitration Agreement, the LUMANA Solution, or any aspect of the
         relationship
         between You, on the one hand, and LUMANA or its suppliers or sellers, on the other hand, or the breach,
         termination, enforcement, interpretation, or validity of the Terms of Use or this Arbitration Agreement
         (collectively, “Disputes”), will be resolved through final and binding, individual arbitration in accordance
         with
         the rules and procedures of the American Arbitration Association (“AAA”), instead of in a court in any
         jurisdiction by a judge or jury. You and LUMANA agree, that an arbitrator, and not any federal, state, or local
         court or agency, shall have exclusive authority to resolve any disputes relating to the interpretation,
         applicability, enforceability, or formation of this Arbitration Agreement, including any claim that all or any
         part of this Arbitration Agreement is void or voidable. The arbitrator shall also be responsible for
         determining
         all threshold arbitrability issues, including issues relating to whether the Terms of Use are unconscionable or
         illusory and any defense to arbitration, including waiver, delay, laches, or estoppel. Notwithstanding this
         Arbitration Agreement, You and LUMANA each retain the right to bring an individual action in small claims court
         if
         it qualifies. Each party also retains the right to seek injunctive or other equitable relief in a court of
         competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a
         party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights.</p>
      <p>Class Action / Jury Trial Waiver. You and LUMANA agree that each party is waiving the right to trail by a jury
         or
         to participate in any purported class, collective, aggregate, mass, representative, or consolidated action,
         arbitration, or other proceeding. Unless both You and LUMANA agree in writing, each party may bring claims
         against
         the other only in Your or its individual capacity, and not as a plaintiff or class member in any purported
         class,
         collective, aggregate, mass, representative, or consolidated action, arbitration, or other proceeding. If the
         parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims
         with
         Your claims, and may not otherwise preside over any form of a representative, mass, or class proceeding. If the
         foregoing sentence is found to be unenforceable, then the entirety of this Arbitration Agreement section shall
         be
         null and void, and You and LUMANA shall be deemed not to have agreed to arbitrate disputes on a mass or class
         basis. This Arbitration Agreement shall survive any termination of these Terms.</p>
      <p>Opt-Out Procedures. You can choose to reject this Arbitration Agreement by sending LUMANA a written opt-out
         notice
         (“Opt-Out Notice”) within 30 days following the date You first agree to these Terms of Use by email at
         support&#64;lumix.ai. If mailed, the Opt-Out Notice must be postmarked no later than 30 days following the date You
         first agree to these Terms of Use. To be effective, the Opt-Out Notice must contain Your name, address, and
         signature. If You opt-out of the Arbitration Agreement, all other parts of these Terms of Use will continue to
         apply to You. Opting out of this Arbitration Agreement has no effect on any previous, other, or future
         arbitration
         agreements that You may have with LUMANA</p>
      <p>Rules and Procedures. The arbitration will be administered by the AAA under its Consumer Arbitration Rules and
         any
         supplementary rules (the “AAA Rules”) then in effect, except as modified by these Terms. The AAA Rules are
         available at www.adr.org or by calling the AAA at 1-800-778-7879. The rules of the arbitral forum will govern
         all
         aspects of this arbitration, except to the extent those rules conflict with these Terms.</p>
      <p>A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to
         the
         other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at www.adr.org. You
         can
         contact the AAA for more information on how to commence an arbitration proceeding at www.adr.org or
         1-800-778-7879. Any arbitration hearings will take place in the county where You live or at another mutually
         agreed location</p>
      <p>
        It is the intent of the parties that the AAA Rules and the U.S. Federal Arbitration Act (“FAA”) shall preempt
        all
        state laws to the fullest extent permitted by law. If the AAA Rules and the FAA are found to not apply to any
        issue that arises under this Arbitration Agreement or the enforcement thereof, then that issue shall be resolved
        under the laws of the state of California, without regard to its choice or conflict of law provisions.
        Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules.
        LUMANA will pay for all filing, administration and arbitrator fees and expenses if Your Dispute is for $5,000 or
        less, unless the arbitrator finds Your Dispute frivolous.</p>
      <p>
        Changes to Arbitration Agreement. Notwithstanding Section 2.7 below, if LUMANA changes any of the terms of this
        Arbitration Agreement after the date You first accepted these Terms of Use (or accepted any subsequent changes
        to
        these Terms of Use), You may reject any such change by sending us written notice of such rejection within 30
        days
        of the date such change became effective. The written notice must be provided by email at support&#64;lumix.ai. To
        be
        effective, Your notice must include Your full name and clearly indicate Your intent to reject changes to this
        Arbitration Agreement. By rejecting any change, You are agreeing that You will arbitrate any Dispute between You
        and LUMANA in accordance with the terms of this Arbitration Agreement as of the date You first accepted these
        Terms
        of Use (or accepted any subsequent changes to these Terms of Use).

      <h2>1. Evaluation Trial</h2>
      <p>1.1. If You register for an evaluation trial of the LUMANA Solution (an “Evaluation Trial”), LUMANA will make
         those
         portions of the LUMANA Solution listed as part of the Evaluation Trial available to You until the earlier of
         (a)
         the
         end of the term of such Evaluation Trial; or (b) the start date of the standard subscription for the LUMANA
         Solution
         that You order. Additional terms and conditions applicable to Your Evaluation Trial may appear on the
         registration
         form for such Evaluation Trial and all such additional terms and conditions are incorporated into these Terms
         of
         Use
         by reference and are legally binding.</p>
      <p>
        1.2. ANY DATA THAT YOU ENTER INTO THE LUMANA SOLUTION AND ANY ACCOUNT CONFIGURATIONS TO THE LUMANA SOLUTION THAT
        YOU
        MAKE DURING THE TERM OF YOUR EVALUATION TRIAL WILL BE PERMANENTLY LOST UNLESS YOU PURCHASE A STANDARD
        SUBSCRIPTION
        TO THE LUMANA SOLUTION OR EXPORT YOUR DATA BEFORE THE END OF THE TERM OF YOUR EVALUATION TRIAL.</p>
      <h2>2. Provision of the LUMANA Solution</h2>
      <p>2.1. Limited License. Subject to Your compliance with these Terms of Use, and during the term in accordance
         with
         Section 10, LUMANA hereby grants You a nonexclusive, nontransferable, revocable, license, without right of
         sublicense, to access and use, and to allow You and any users You authorize using the LUMANA Solution to access
         and
         use, the LUMANA Solution for Your internal use. The foregoing license and Your use of the LUMANA Solution is
         also
         subject to any and all Documentation provided in connection therewith.</p>
      <p>
        2.2. License to Lumana. During the License Term, Customer will transfer Customer Data to Lumana while using the
        Products. Customer grants Lumana a non-exclusive right and license to use, reproduce, modify, store, and process
        Customer Data solely to maintain the Products and provide them to Customer. Customer represents and warrants
        that
        it
        possesses the necessary rights and authority to grant Lumana the rights set forth in this Section 2.2 with
        respect
        to
        Customer Data.</p>
      <p>
        2.3. Accessing LUMANA Mobile Device Applications. The following terms apply to any LUMANA mobile device
        application
        accessed through or downloaded from any app store or distribution platform (such as the Apple App Store or
        Google
        Play) where the LUMANA mobile device application may now or in the future be made available (each an “App
        Provider”).
        You acknowledge and agree that:</p>
      <p class="ml-10">
        (a) These Terms of Use are concluded between You and LUMANA, and not with the App Provider, and LUMANA (not the
        App
        Provider), is solely responsible for the LUMANA mobile device applications.</p>
      <p class="ml-10">
        (b) The App Provider has no obligation to furnish any maintenance and support services with respect to the
        LUMANA
        mobile device applications.</p>
      <p class="ml-10">
        (c) In the event of any failure of the LUMANA mobile device applications to conform to any applicable warranty,
        You
        may notify the App Provider, and the App Provider will refund the purchase price for the LUMANA mobile device
        applications to You (if applicable) and, to the maximum extent permitted by applicable law, the App Provider
        will
        have no other warranty obligation whatsoever with respect to the LUMANA mobile device applications. Any other
        claims,
        losses, liabilities, damages, costs, or expenses attributable to any failure to conform to any warranty will be
        the
        sole responsibility of LUMANA.</p>
      <p class="ml-10">
        (d) The App Provider is not responsible for addressing any claims You have or any claims of any third party
        relating
        to the LUMANA mobile device applications or Your possession and use of the LUMANA mobile device applications,
        including, but not limited to: (i) product liability claims; (ii) any claim that the LUMANA mobile device
        applications fail to conform to any applicable legal or regulatory requirement; and (iii) claims arising under
        consumer protection or similar legislation.</p>
      <p class="ml-10">
        (e) In the event of any third party claim that the LUMANA mobile device applications or Your possession and use
        of
        a
        LUMANA mobile device application infringes that third party’s intellectual property rights, LUMANA will be
        solely
        responsible for the investigation, defense, settlement and discharge of any such intellectual property
        infringement
        claim to the extent required by these Terms</p>
      <p class="ml-10">
        (f) The App Provider, and its subsidiaries, are third-party beneficiaries of these Terms of Use as related to
        Your
        license to the LUMANA mobile device applications, and that, upon Your acceptance of the Terms of Use, the App
        Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use as
        related to your license of the LUMANA mobile device applications against You as a third-party beneficiary
        thereof.</p>
      <p class="ml-10">
        (g) You represent and warrant that (i) You are not located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii) You are
        not
        listed on any U.S. Government list of prohibited or restricted parties.</p>
      <p class="ml-10">
        (h) You must also comply with all applicable third party terms of service when using the LUMANA mobile device
        applications.</p>
      <p>
        2.4. LUMANA’s Responsibilities. LUMANA will make the LUMANA Solution available to You during the term of Your
        subscriptions pursuant to the terms and conditions set forth herein and in any applicable Order Forms. LUMANA
        will:
        (a) provide the LUMANA Solution in accordance with applicable laws and government regulations; (b) provide You
        with
        LUMANA’s basic support for the LUMANA Solution at no additional charge; (c) protect all digital data and
        information
        submitted by You to LUMANA, including any digital video data (“Your Video Data”) (collectively, “Your Data”) in
        accordance with LUMANA’s Online Privacy Policy
        <a href="https://app.lumana.ai/privacy" target="_blank">https://app.lumana.ai/privacy</a>, the terms of which
        are
        hereby
        incorporated herein by
        reference; and (d) use commercially reasonable efforts to make the LUMANA Solution available twenty-four (24)
        hours
        a
        day, seven (7) days a week, excluding (a) scheduled maintenance or repairs; or (b) any unavailability caused by
        circumstances beyond LUMANA’s reasonable control, including acts of God, acts of government, floods, fires,
        earthquakes, civil unrest, acts of terror, strikes or other labor problems, Internet service provider failures
        or
        delays, or denial of service attacks. To the extent practicable, maintenance and repairs will be scheduled
        outside
        of the hours of 8:00AM EST to 10:00PM EST and will be scheduled over the weekend (6:00PM EST Friday to 6:00AM
        EST
        Monday).</p>
      <p>
        2.5. LUMANA Support Services. LUMANA will provide technical support to You during the term in accordance with
        Section
        10, subject to Your payment of all applicable fees and compliance with the Terms of Use. LUMANA may access the
        hardware for maintenance purposes, and to implement bug fixes, planned upgrades, and feature changes. LUMANA
        will
        provide support for errors, bugs, or other reported issues, except that LUMANA will not be responsible for
        addressing
        or resolving problems that are caused by You. LUMANA may access the hardware to diagnose reported problems. You
        will
        be obligated to provide LUMANA will sufficient access, information and data to enable LUMANA to reproduce a
        reported
        issue. Support will be provided through email and telephone communication during LUMANA’s normal business hours
        (9:00AM EST to 5:00PM EST on regular U.S. business days). LUMANA will respond to support requests within two (2)
        business days; resolution times will vary depending on the type and severity of the issue. Support services that
        are
        outside the scope of LUMANA’s support obligations include support for software and/or hardware that has been
        subject
        to unauthorized modification by You or for which all required maintenance releases have not been implemented by
        You;
        support requested due to a cause beyond LUMANA’s reasonable control (e.g., floods, fires, loss of electricity or
        other utilities); and negligence, operator error, or improper use of software and/or hardware by You or any
        third
        party.</p>
      <p>
        2.6. LUMANA Solution Platform Support. You may request that LUMANA provide certain support services related to
        Your
        use of the LUMANA Solution, including, by way of example, customization or training of Your personnel. Unless
        otherwise agreed between the parties in a separate Order Form and / or a statement of work, LUMANA will have no
        obligation to provide or perform such services for You or on behalf of You.</p>
      <p>
        2.7. Revisions to Terms of Use. You acknowledge that, from time to time, LUMANA may amend these Terms of Use or
        other
        terms governing Your use of the LUMANA Solution, including with respect to LUMANA’s security and privacy
        policies
        (collectively, “Revised Terms of Use”). LUMANA will notify You by email of any Revised Terms of Use and will
        post
        such Revised Terms of Use through the LUMANA Solution. You acknowledge that You will be bound by the Revised
        Terms
        of
        Use as of their effective date set forth in the notice. If You do not agree to the Revised Terms of Use, You may
        terminate Your subscriptions as set forth in Section 10.2.</p>
      <h2>3. Your Responsibilities</h2>
      <p>3.1. Account. You are responsible for maintaining the confidentiality of Your account password and You are
         solely
         responsible for all activities that occur through Your account. You agree to immediately notify LUMANA of any
         breach
         or unauthorized use of any of Your account. LUMANA reserves the right to require You to alter Your password if
         LUMANA
         believes that Your password is no longer secure. You agree that You will be solely responsible for any loss or
         damage You suffer as a result of Your failure to adequately safeguard account information and that You may also
         be
         liable for the losses of LUMANA or others due to such failure.</p>
      <p>
        3.2. Use of the LUMANA Solution
        (a) You will (a) use the LUMANA Solution only for Your internal business purposes; (b) use commercially
        reasonable
        efforts to prevent unauthorized access to or use of the LUMANA Solution and promptly notify LUMANA of any such
        unauthorized access or use of which You become aware; and (c) comply with all local, state and federal laws
        applicable use of the LUMANA Solution. You acknowledge that the LUMANA Solution is accessed via the Internet and
        on
        mobile devices. Other than any hardware and/or system software provided to You as part of LUMANA Solution in the
        applicable Order Form, You are responsible for providing all hardware, system software, access devices, networks
        and
        telecommunications or other connections required for You and Your authorized users to access the LUMANA Solution
        and
        paying all telephony, data transmission and other costs associated with such access.
        (b) You agree the LUMANA Solution hardware shall be located at Your facilities or Your managed facilities and be
        maintained in Your direct possession. You will impose the restrictions contained in these Terms of Use on any
        employee, affiliate, contractor, or other party who may use the LUMANA Solution on Your behalf, and a breach of
        these
        Terms of Use by any such party shall be considered a breach by You. You shall not lease, loan, resell,
        sublicense
        or
        otherwise distribute the LUMANA Solution to third parties.</p>
      <p>
        3.3. Updates. LUMANA may, in its sole discretion, modify, update or delete features or services or modify the
        appearance of the LUMANA Solution at any time in its sole discretion. In connection with such updates and
        modifications, You may be required to implement upgrades or modifications to Your networks and other systems and
        will implement any such upgrades and modifications at Your sole expense.</p>
      <p>
        3.4. Restrictions. You will not use the LUMANA Solution or any component thereof except as expressly authorized
        by
        these Terms of Use and by applicable law. Except as expressly permitted herein, You will not copy, reproduce,
        display, perform, modify or create derivative works of LUMANA, including without limitation the Documentation.
        You
        will not market, sell, license, sublicense, lease, act as a service bureau, transfer, assign, sell, alter,
        repair,
        encumber or assign a security interest in the LUMANA Solution or any component thereof. You will not, and will
        not
        instruct, permit, allow or induce any person, directly or indirectly, to: (a) decompile, disassemble or
        otherwise
        reverse engineer the LUMANA Solution or any portion thereof, or otherwise attempt to derive the source code or
        other
        trade secrets embodied in the LUMANA Solution, except to the extent required to be permitted by law; (b) use the
        LUMANA Solution to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to
        store
        or
        transmit material in violation of third-party privacy rights; (c) use the LUMANA Solution in a managed services
        arrangement; (d) attempt to gain unauthorized access to or use of the LUMANA Solution or damage, disrupt, or
        impede
        the operation of the LUMANA Solution or LUMANA’s other services or systems; (e) upload or transmit any viruses,
        worms,
        defects, Trojan horses, or any programming of a destructive nature through the LUMANA Solution; or (f) access
        the
        LUMANA Solution in order to build a competitive product or service. LUMANA reserves the right to immediately
        terminate
        Your subscription(s), without any further obligation to You and without any obligation to refund any Fees
        previously
        paid, if You tamper with or modify the LUMANA Solution without LUMANA’s prior written authorization or otherwise
        use
        the LUMANA Solution in violation of these Terms of Use.</p>
      <p>
        3.5. Your Data. You will be responsible for the accuracy, quality and legality of Your Data and the legality of
        the
        means by which You acquired Your Data. You further acknowledge that in connection with Your use of the LUMANA
        Solution, LUMANA may have access to or process Your Data, including system-specific data and personal,
        proprietary
        information and, subject to LUMANA’s compliance with its confidentiality obligations under Section 11, You
        hereby
        authorize LUMANA to do so as required to provide the LUMANA Solution to You.</p>
      <h2>4. Fees and Payment</h2>
      <p>4.1. Fees. You will pay LUMANA the fees, if any, set forth on the registration page for the LUMANA Solution
         that
         You
         purchase or, if You have executed an Order Form(s), in such Order Form(s) (the “Fees”). All sales are final and
         nonrefundable unless otherwise provided by law. All Fees are listed in and all payments will be made in U.S.
         Dollars.</p>
      <p>
        4.2. Taxes. All Fees are exclusive of taxes, including import or export duties, sales, use, value added,
        withholding
        and excise taxes assessable by any local, state, provincial, federal or foreign jurisdiction (collectively
        “Taxes”).
        If LUMANA has the legal obligation to pay or collect Taxes for which You are responsible, such Taxes will either
        be
        included on LUMANA’s invoices to You or listed on the registration page for the LUMANA Solution, as applicable,
        and
        You will pay such Taxes to LUMANA, unless You provide LUMANA with a valid tax exemption certificate authorized
        by
        the
        appropriate taxing authority.</p>
      <p>
        4.3. Payment
        (a) You acknowledge and agree that You are responsible for paying all Fees in a timely manner and for providing
        a
        valid credit card or other form of payment acceptable to LUMANA. IN ADDITION, YOU ACKNOWLEDGE THAT IF YOU
        REGISTER
        FOR THE LUMANA SOLUTION ONLINE, YOUR ONLINE ACCEPTANCE CONSTITUTES A BINDING AGREEMENT BETWEEN YOU AND LUMANA
        AND
        SIGNIFIES YOUR INTENT TO BE BOUND TO SUCH ONLINE ACCEPTANCE.
        (b) Unless otherwise stated in an applicable Order Form, any invoiced charges are due net thirty (30) days from
        LUMANA’s invoice date. You are responsible for providing complete and accurate billing and contact information
        to
        LUMANA and notifying LUMANA of any changes to such information. LUMANA accepts payment by credit card and any
        other
        form of payment that LUMANA may make available for the LUMANA Solution. LUMANA may charge Your account’s listed
        payment
        method the amounts due for the LUMANA Solution, including any taxes or other additional amounts indicated on the
        transaction page for the LUMANA Solution or on the Order Form. You further agree to abide by any relevant terms
        of
        service or other legal agreement, including with a third party payment services provider, that governs Your use
        of
        a
        given payment processing method. LUMANA may add or remove payment processing methods in its sole discretion and
        without notice to You.
        (c) By providing LUMANA with a payment method, You: (i) represent that You are authorized to use the payment
        method
        that You provided; and (ii) authorize LUMANA to charge You for the subscriptions to the LUMANA Solution that You
        order
        using Your payment method. LUMANA will notify You in advance of any price changes for recurring subscription
        services. Provided that automatic renewals are allowed in Your country, if You selected automatic renewal when
        You
        signed up for the LUMANA Solution, LUMANA may automatically renew Your subscription to the LUMANA Solution at
        the
        end
        of Your then-current subscription term. LUMANA will inform You by e-mail prior to each renewal and remind You
        that
        Your designated payment method will be billed. LUMANA will also provide You with instructions on how to cancel
        the
        LUMANA Solution. You must cancel the LUMANA Solution prior to the renewal date to avoid being billed for renewal
        of
        Your subscriptions.
        (d) If You believe there is an error in the Fees You have been charged or for which You have been invoiced, as
        applicable, You must notify LUMANA within thirty (30) days after the error first appears. LUMANA will then
        promptly
        investigate the charge. Subject to applicable laws, if You do not notify LUMANA within that time, You release
        LUMANA
        from all liability and claims of loss resulting from the error and LUMANA will not be required to fix the error
        or
        provide a refund. If LUMANA identifies a billing error, LUMANA will correct the error within sixty (60)
        days.</p>
      <p>
        4.4. Late Fees. If You fail to pay any Fees or other charges due by their applicable due date, at LUMANA’s
        discretion, such charges may accrue late charges at the rate of one percent (1%) of the outstanding balance per
        month or the maximum rate permitted by law, whichever is less, from the date such payment was due until the date
        paid. All amounts paid will first be applied against any late charges due, with the balance applied against the
        outstanding Fees and charges. To the extent permitted by law, You will pay for all reasonable costs LUMANA
        incurs
        to
        collect any past due amounts, including reasonable attorneys’ fees and other legal fees and costs.</p>
      <p>
        4.5. Suspension of Service. If any undisputed Fees or charges are overdue more than thirty (30) days, LUMANA
        will
        have the right upon written notice to You, without limiting its other rights and remedies, to suspend the LUMANA
        Solution until such amounts are paid in full.</p>
      <h2>5. Intellectual Property Rights</h2>
      <p>5.1. Ownership of the LUMANA Solution. As between You and LUMANA, LUMANA owns and retains all right, title and
         interest, including all intellectual property rights in and to (a) the LUMANA Solution, (b) LUMANA’s
         Confidential
         Information (defined in Section 11) and any and all other information relating to the LUMANA Solution or its
         implementation, marketing, promotion, or sale; (c) all work product, content, ideas, know-how, concepts,
         methods
         and
         techniques created or employed by LUMANA in the delivery of the LUMANA Solution, whether pre-existing or
         developed
         in
         the course of providing the LUMANA Solution; and (d) any derivative works based on any of the foregoing
         (collectively, the “LUMANA IP”). In addition, You agree and acknowledge that LUMANA has an unlimited,
         irrevocable,
         perpetual right and license to use, incorporate into the LUMANA Solution, distribute and otherwise exploit all
         suggestions, ideas, enhancement requests, feedback, recommendations or other information (excluding Your Video
         Data,
         as defined below) relating to the LUMANA Solution provided by You or any of your authorized users, all of which
         is
         deemed part of the LUMANA IP. In the event any right, title or interest arises or vests at any time in You to
         any
         LUMANA IP, You hereby assign to LUMANA all such right, title and interest.</p>
      <p>
        5.2. Ownership of Your Data. You own and will retain all right, title and interest, including all intellectual
        property rights, in and to Your Data.</p>
      <p>
        5.3. Usage Statistics. LUMANA may aggregate and/or use for LUMANA’s internal business purposes those portions of
        Your
        Data that relate to how You use the LUMANA Solution (“Usage Statistics”) and may disclose the Usage Statistics
        to
        third parties for the purpose of improving the LUMANA Solution or for LUMANA’s marketing and promotional
        purposes
        so
        long as the Usage Statistics used for marketing and promotional purposes are disclosed only in the aggregate, do
        not
        include Your Confidential Information and are not disclosed in a manner so that they would be attributable
        specifically to You, Your projects or customers or in a manner that would provide the ability to identify
        individuals or Your customers.</p>
      <p>
        5.4. Usage of Your Video Data by LUMANA. Through Your acceptance of these Terms of Use, and through Your use of
        the
        LUMANA Solution, LUMANA will automatically receive for storage Your Video Data, which includes any video,
        photographic, and/or audio data captured through Your use of the LUMANA Solution. LUMANA may use Your Video Data
        for
        LUMANA’s internal business purposes that relate to how You use the LUMANA Solution (“Video Data Sharing”),
        unless
        You
        opt out of such Video Data Sharing by following the Video Data Sharing opt-out procedures described in this
        Section
        5.4. You can choose to reject such Video Data Sharing by sending LUMANA a written Video Data Sharing opt-out
        notice
        (“Video Data Sharing Opt-Out Notice”) within 30 days following the date You first agree to these Terms of Use by
        email at support&#64;lumix.ai. To be effective, the Video Data Sharing Opt-Out Notice must contain Your name,
        address,
        and signature. If You opt-out of the Video Data Sharing, all other parts of these Terms of Use will continue to
        apply to You. Opting out of Video Data Sharing under these Terms of Use has no effect on any previous, other, or
        future Video Data Sharing arrangements that You may have with LUMANA.</p>
      <p>
        5.5. Reservation of Rights. Other than the explicit rights granted herein, nothing in these Terms of Use will be
        construed or interpreted as granting to You any rights or licenses in or to the LUMANA IP, including any rights
        of
        ownership, intellectual property rights or any other proprietary rights.</p>
      <h2>6. Publicity</h2>
      <p>6.1. If You are a legal entity, LUMANA has the right to list Your legal entity as a user of the LUMANA Solution
         in
         descriptions of the LUMANA Solution and in related promotional and marketing materials and press releases.
         LUMANA
         will
         not use any of Your trademarks on such materials without Your prior written consent.</p>
      <h2>7. Warranties and Disclaimers</h2>
      <p>7.1. Your Data. You represent and warrant that You have made all disclosures and obtained all rights and
         permissions
         required to use and transfer Your Data within and outside the country where Your Data originates and for LUMANA
         to
         use Your Data as set forth in Section 3.5.</p>
      <p>
        7.2. Disclaimers. YOU ACKNOWLEDGE THAT THE LUMANA SOLUTION AND ANY OTHER SERVICES PROVIDED BY LUMANA, AND ALL
        COMPONENTS OF ALL OF THE FOREGOING, ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND
        WHATSOEVER. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, LUMANA DISCLAIMS ANY AND ALL WARRANTIES OF ANY
        KIND,
        WHETHER EXPRESS OR IMPLIED, INCLUDING (A) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, PERFORMANCE, SUITABILITY, TITLE OR NON-INFRINGEMENT; (B) RELATING TO THE PERFORMANCE OF THE LUMANA
        SOLUTION
        OR LUMANA’S PERFORMANCE OF ANY OTHER SERVICES; (C) WITH RESPECT TO ANY RESULTS TO BE OBTAINED FROM THE LUMANA
        SOLUTION; (D) THAT USE OF THE LUMANA SOLUTION WILL BE UNINTERRUPTED OR ERROR FREE; OR (E) WITH RESPECT TO THE
        ACCURACY, QUALITY, RELIABILITY, SUITABILITY, OR EFFECTIVENESS OF ANY DATA, RESULTS, CONTENT OR OTHER INFORMATION
        OBTAINED OR GENERATED BY YOU OR YOUR AUTHORIZED USERS THROUGH USE OF THE LUMANA SOLUTION. YOU UNDERSTAND AND
        AGREE
        THAT YOUR USE OF THE LUMANA SOLUTION IS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE
        FOR
        ANY DAMAGE ARISING THEREFROM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM LUMANA OR
        THROUGH THE LUMANA SOLUTION WILL CREATE ANY WARRANTY FROM LUMANA NOT EXPRESSLY MADE HEREIN. THE LUMANA SOLUTION
        IS
        CONTROLLED AND OFFERED BY LUMANA FROM ITS FACILITIES IN THE UNITED STATES OF AMERICA. LUMANA MAKES NO
        REPRESENTATIONS
        THAT THE LUMANA SOLUTION IS APPROPRIATE OR AVAILABLE FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE
        LUMANA
        SOLUTION FROM OTHER JURISDICTIONS DO SO AT THEIR OWN RISK AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.
        7.3. Hardware Warranty. LUMANA represents and warrants that the hardware provided to You by LUMANA as part of
        the</p>
      <p>
        LUMANA Solution will perform in accordance with the Documentation provided by LUMANA in connection with such
        hardware.
        If the hardware fails to perform in accordance with such Documentation, Your sole and exclusive remedy shall be
        to
        require LUMANA to repair or replace such hardware, at LUMANA’s cost and expense.</p>
      <h2>8. Indemnification</h2>
      <p>8.1. You will indemnify, defend and hold harmless LUMANA (and its officers, directors, employees, shareholders
         and
         agents) from and against any and all claims, liabilities, losses, damages, fines, injuries, interest or
         expenses
         (including reasonable attorneys’ fees and costs of investigation and defense) (“Losses”) incurred by LUMANA
         arising
         from or relating to Your breach of these Terms of Use; provided that LUMANA provides You with (a) prompt
         written
         notice of such claim or action, (b) sole control and authority over the defense or settlement of such claim or
         action; (c) proper and full information and reasonable assistance to defend and/or settle any such claim or
         action,
         and (d) You may not settle any such claim in any manner that binds LUMANA without LUMANA’s prior written
         consent.
         Notwithstanding the foregoing, LUMANA may, at its own expense, participate in the defense and settlement of any
         such
         claim that is subject to indemnification.</p>
      <h2>9. Limitation of Remedies and Damages</h2>
      <p>9.1. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS OF USE, IN NO EVENT WILL EITHER PARTY BE
         RESPONSIBLE
         FOR ANY INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES WHETHER IN CONTRACT, IN TORT
         (INCLUDING
         BREACH OF WARRANTY, NEGLIGENCE AND STRICT LIABILITY IN TORT) OR OTHERWISE RESULTING FROM ITS PERFORMANCE OR ANY
         FAILURE TO PERFORM UNDER THESE TERMS OF USE OR FROM THE LUMANA IP (INCLUDING LOSS OF DATA OR LOSS OF
         ANTICIPATED
         PROFITS OR BENEFITS), EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
      <p>
        9.2. LUMANA’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THESE TERMS OF USE, LUMANA OR THE LUMANA IP, WHETHER
        IN
        CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED THE GREATER OF: (A) $1,000; AND (B) THE TOTAL FEES DUE LUMANA FOR
        YOUR
        USE OF THE LUMANA SOLUTION OVER THE PRECEDING TWELVE (12) MONTHS.</p>
      <p>
        9.3. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of
        liability
        for certain damages. Accordingly, some of the limitations and disclaimers set forth in Sections 7 and 9 may not
        apply to You. To the extent that LUMANA may not, as a matter of applicable law, disclaim any implied warranty or
        limit its liabilities, the scope and duration of such warranty and the extent of LUMANA’s liability will be the
        minimum permitted under such applicable law.</p>
      <h2>10. Term and Termination</h2>
      <p>10.1. Term. The term of these Terms of Use will commence on the date You accept them and will continue until
         all
         Your account subscriptions for the LUMANA Solution have expired or been terminated. If You register to use the
         LUMANA
         Solution for an Evaluation Trial and do not purchase a standard subscription before the end of the term of the
         Evaluation Trial, Your subscription will terminate at the end of such Evaluation Trial. The term of each of
         Your
         account subscriptions will commence on the start date set forth in the applicable Order Form or on the
         applicable
         registration page and will continue for the term set forth therein. The term of each account subscription will
         automatically renew for successive periods equal to the original subscription term at LUMANA’s then-current
         pricing
         for the LUMANA Solution unless either party provides the other party with written notice at least thirty (30)
         days
         prior to the end of the then-current term.</p>
      <p>
        10.2. Termination for Convenience. You may terminate any or all of Your subscriptions to the LUMANA Solution or
        these
        Terms of Use at any time, with or without cause, upon written notice to LUMANA; provided, however, that if You
        terminate any subscription prior to the expiration, You will pay the termination fees set forth in the
        applicable
        Order Form.</p>
      <p>
        10.3. Termination for Breach. If at any time during the Term, either party materially defaults in its
        performance
        of
        or breaches any of the terms and conditions of these Terms of Use and such breach is not cured within thirty
        (30)
        days after the breaching party receives notice of such breach from the non-breaching party, the other party will
        have the right to terminate these Terms of Use effective immediately upon written notice to the breaching
        party.</p>
      <p>
        10.4. Effect of Termination. Upon the expiration or termination of these Terms of Use for any reason, You will
        immediately cease all use of the LUMANA Solution and all licenses granted to You hereunder and all account
        subscriptions will immediately terminate. Immediately prior to the expiration or termination of these Terms of
        Use,
        You will be able to download or otherwise save Your Data from the LUMANA Solution. [Upon expiration or
        termination
        of
        these Terms of Use for any reason, You will irretrievably destroy or return (at LUMANA’s request), all copies of
        the
        LUMANA Solution and Confidential Information in every form.] If You register to use the LUMANA Solution for an
        Evaluation Trial, You will return the LUMANA Solution provided under such Evaluation Trial within ten (10)
        business
        days of the date of expiration or termination of such Evaluation Trial. The following Sections will survive the
        expiration or termination of these Terms of Use for any reason: 5 (Intellectual Property Rights), 7 (Warranties
        and
        Disclaimers), 8 (Indemnification), 9 (Limitation of Remedies and Damages), 10.4 (Effect of Termination), 11
        (Confidential Information), and 13 (Miscellaneous).</p>
      <h2>11. Confidential Information</h2>
      <p>11.1. Confidential Information. “Confidential Information” means information that is disclosed by a party
         (“Discloser”) to the other party (“Recipient”) whether orally or in writing, that is designated as confidential
         at
         the time of disclosure or that reasonably should be understood to be confidential given the nature of the
         information and the circumstances of disclosure. Without limitation of the foregoing, Your Confidential
         Information
         includes Your Data and LUMANA’s Confidential Information includes the LUMANA Solution, the Order Forms and the
         terms
         of Your subscriptions for use of the LUMANA Solution, as well as all information relating to LUMANA’s business,
         including product and service development plans and technical information. Notwithstanding the foregoing,
         Confidential Information does not include information that Recipient demonstrates: (a) was already known to
         Recipient at the time of disclosure by Discloser; (b) was disclosed to Recipient by a third party who had the
         right
         to make such disclosure without any confidentiality restrictions; (c) is, or through no fault of Recipient has
         become, generally available to the public; or (d) was independently developed by Recipient without access to,
         or
         use
         of, Discloser’s Confidential Information.</p>
      <p>
        11.2. Protection of Information. Recipient will not use any Confidential Information of Discloser for any
        purpose
        not expressly permitted by these Terms of Use, and will disclose the Confidential Information of Discloser only
        to
        the employees or contractors of Recipient who have a need to know such Confidential Information for purposes of
        its
        performance under these Terms of Use and who are under a duty of confidentiality no less restrictive than
        Recipient’s duty hereunder. Recipient will protect Discloser’s Confidential Information from unauthorized use,
        access, or disclosure in the same manner as Recipient protects its own confidential or proprietary information
        of
        a
        similar nature and with no less than reasonable care.</p>
      <p>
        11.3. Required Disclosure. Notwithstanding anything in these Terms of Use to the contrary, addition, Recipient
        may
        disclose the Confidential Information of Discloser to the extent that such disclosure is required by law or by
        the
        order of a court of similar judicial or administrative body, provided that Recipient (a) notifies Discloser of
        such
        required disclosure promptly and in writing in order to permit Discloser to seek a protective order or other
        appropriate relief; (b) cooperates with Discloser in such efforts; and (c) discloses only the Confidential
        Information required to be disclosed. Recipient will continue to treat any Confidential Information disclosed
        pursuant to this Section 11.3 as Confidential Information for all other purposes.</p>
      <h2>12. Data Security</h2>
      <p>12.1. Security. LUMANA shall use reasonable efforts to identify internal and external risks to the security,
         confidentiality and integrity of Your Confidential Information in its environment and shall employ and maintain
         reasonable physical, electronic and procedural safeguards and software security measures to maintain the
         security
         of
         the LUMANA Solution and Your Confidential Information, consistent with legal requirements (“Legal
         Requirements”),
         including the rules, regulations and binding interpretations of any Regulatory Authority. Such security
         measures
         shall include the use of up-to-date firewalls and virus detection software designed to prevent unauthorized
         access
         to the LUMANA Solution and Your Confidential Information, physical access controls and intrusion detection, and
         secure off-site backup and shall be at least commensurate with applicable industry standards. LUMANA shall not
         modify
         any of the foregoing in a way that degrades the level of security and protection available to Your Confidential
         Information or the systems used to provide the LUMANA Solution in any material respect without Your prior
         written
         consent. Without limitation of the foregoing, LUMANA shall use and process personal information (defined in
         LUMANA’s
         Online Privacy Policy <a href="https://app.lumana.ai/privacy" target="_blank">https://app.lumana.ai/privacy</a>)
         in
         accordance with
         applicable local, state, federal and foreign privacy,
         security and data protection legislation. For the purpose of these Terms of Use, “Regulatory Authority” means a
         governmental agency, authority, regulator or self-regulatory organization (to the extent such self-regulatory
         organization obtains authority by Legal Requirements or by contract, in the latter case in relation to
         mandatory
         terms) with jurisdiction over You or LUMANA.</p>
      <p>
        12.2. Security Events. LUMANA shall notify You in writing within 24 hours of LUMANA first having reasonable
        suspicion
        of any unauthorized loss, transfer, acquisition, or misuse of Your Confidential Information. LUMANA shall
        investigate
        the incident, take any actions that are necessary to mitigate resulting damage, and provide any consumer notices
        required by law as a result of such a security breach. You acknowledge that if Your Confidential Information has
        been disclosed to or accessed by an unauthorized party (a “Security Event”), Legal Requirements may require that
        LUMANA notify those persons whose personal information was disclosed that a Security Event has occurred. LUMANA
        shall
        notify You promptly if it learns or has a reasonable basis to believe a Security Event has occurred. Unless
        otherwise required by a Legal Requirement, LUMANA shall not notify the persons affected until it first consults
        with
        You and You has had an opportunity to review (but not approve) any such notice.</p>
      <p>
        12.3. Backup and Disaster Recovery. LUMANA shall act in accordance with LUMANA’s data backup plan and will
        maintain
        a
        disaster recovery plan that is at least commensurate with applicable industry standards. Such plan shall include
        provision for a geographically remote disaster recovery facility, along with required hardware, software and
        Internet connectivity, in the event that LUMANA’s production facilities were to be rendered unavailable.</p>
      <h2>13. Miscellaneous</h2>
      <p>13.1. Entire Agreement. These Terms of Use and any additional terms to which You agree in an Order Form or
         otherwise
         with respect to the LUMANA Solution (all of which are incorporated herein by reference) constitute the entire
         agreement between You and LUMANA pertaining to the subject matter hereof, and any and all written or oral
         agreements,
         understandings and representations, written or oral, previously existing between the parties are expressly
         canceled.</p>
      <p>
        13.2. Notices. Any notice, request or communication required or permitted to be given under these Terms of Use
        will
        be in writing and will be deemed to be given when actually received if delivered personally or by facsimile or
        electronic mail, two (2) business days after the date deposited with the U.S. postal service if sent by
        certified
        or
        registered mail, or one (1) business day after the date delivered to a reputable next-day courier service. All
        notices to You will be sent to the address or email address designated by You on the applicable registration
        page
        or
        in the Order Form for the LUMANA Solution You have purchased.</p>
      <p>
        13.3. No Assignment. Neither the rights nor the obligations arising under these Terms of Use are assignable or
        transferable by You, and any such attempted assignment or transfer will be void and without effect.</p>
      <p>
        13.4. Relationship of the Parties. The parties are independent contractors and nothing in these Terms of Use
        will
        be
        construed as establishing a joint venture, partnership, employment or agency relationship between the
        parties.</p>
      <p>
        13.5. No Third Party Beneficiaries. Subject to Section 8, there are no third party beneficiaries to these Terms
        of
        Use.</p>
      <p>
        13.6. Waiver; Severability; No Amendment. If any provision of these Terms of Use is held to be invalid, illegal
        or
        unenforceable, all remaining provisions will remain in full force and effect. These Terms of Use will be binding
        upon and inure to the benefit of the parties and their respective successors and assigns and may not be amended
        except by a writing signed by the duly authorized representatives of both parties. The failure of a party to
        require
        performance of any provision of these Terms of Use will in no manner affect its right to subsequently enforce
        the
        provision, and no delay or failure by either party to exercise any right or remedy will operate as a waiver
        thereof.</p>
      <p>
        13.7. Conflict. To the extent of any conflict or inconsistency between these Terms of Use and any Order Form or
        any
        other document related to the parties’ obligations hereunder, these Terms of Use will govern unless otherwise
        expressly agreed by the parties in writing. Notwithstanding any language to the contrary therein, no terms or
        conditions stated in Your purchase order or in any other order documentation (excluding Order Forms) will be
        incorporated into or form any part of these Terms of Use, and all such terms or conditions will be null and
        void.</p>
      <p>
        13.8. Interpretation. The words “include,” “includes” and “including” will be deemed to be followed by the words
        “without limitation;” and the words “such as”, “for example” “e.g.” and any derivatives thereof will mean by way
        of
        example and the items that follow these words will not be deemed an exhaustive list. The descriptive headings
        and
        labels of the articles, sections, and subsections of these Terms of Use are for convenience and reference only
        and
        will not affect these Terms of Use’s construction or interpretation.</p>
    </div>
  </div>
</div>
