import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Upload } from './ui-upload.models';

@Injectable({
  providedIn: 'root',
})
export class UiUploadService {
  private uploadProgressSubject = new BehaviorSubject<Upload>({state: 'PENDING', progress: 0, fileProgress: {}});
  public uploadProgress$: Observable<Upload> = this.uploadProgressSubject.asObservable();

  constructor() {
  }

  getProgress(): Upload {
    return this.uploadProgressSubject.value;
  }

  setProgress(upload: Upload) {
    this.uploadProgressSubject.next(upload);
  }

  clearProgress() {
    this.uploadProgressSubject.next({state: 'PENDING', progress: 0, fileProgress: {}});
  }
}
