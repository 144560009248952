import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService {
  constructor(private authenticationService: AuthenticationService,
              private store: Store<AppState>) {
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();

    if (!isLoggedIn || !authProviderId) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.authenticationService
        .authenticateToFirebase({ userId: authProviderId })
        .then(res => {
          if (!!res && !!isLoggedIn) {
            resolve(true);
          } else {
            this.store.dispatch(AuthenticationActions.Logout({ reason: 'Login is required' }));
            resolve(false);
          }
        })
        .catch(err => {
          this.store.dispatch(AuthenticationActions.Logout({ reason: 'Login is required' }));
          resolve(false);
        });
    });
  }
}
