import { Component, OnDestroy, OnInit } from '@angular/core';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent implements OnInit, OnDestroy {

  constructor(private store$: Store<AppState>) {
  }

  ngOnInit(): void {}

  public ngOnDestroy() {
    this.store$.dispatch(AlertMonitoringActions.resetToInitialState());
  }
}
