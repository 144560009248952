<div fxLayout="row"
     fxLayoutAlign="start center"
     class="option-toggle-wrapper p-r mr-20"
     (click)="toggle()"
>
  <div class="option-toggle-toggle" [class.selected]="value"></div>
  <div fxLayoutAlign="center center" fxFlex="" class="option-toggle-label" [class.selected]="!value">{{ options[0] }}
  </div>
  <div fxLayoutAlign="center center" fxFlex="" class="option-toggle-label" [class.selected]="value">{{ options[1] }}
  </div>
</div>
