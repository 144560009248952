import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePairs } from '../core/interfaces';

@Pipe({
  name: 'fromJson',
})
export class FromJsonPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): KeyValuePairs<any> {
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
      }
    }
    return {};
  }
}
