import { Component, Input, OnInit } from '@angular/core';
import { UserSettings } from "../../../user/settings.model";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs";
import * as _ from "lodash";
import { AppUser } from "../../../user/user.model";
import { AuthenticationService } from "../../../authentication/authentication.service";
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';

export enum UiHowToDrawType {
  ZONES,
  LINES
}

@Component({
  selector: 'ui-how-to-draw',
  templateUrl: './ui-how-to-draw.component.html',
  styleUrls: ['./ui-how-to-draw.component.scss']
})
export class UiHowToDrawComponent implements OnInit {

  @Input() howToDraw = true;
  @Input() type: UiHowToDrawType = UiHowToDrawType.ZONES;

  public UiHowToDrawType = UiHowToDrawType;
  settings: UserSettings.Settings = {
    account: {
      staySignedIn: false,
      howToDrawZones: false,
      howToDrawLines: false,
    },
    password: {
      isMfaEnabled: false,
    },
  };

  constructor(
    private authService: AuthenticationService,
    private store$: Store,
  ) {
  }

  ngOnInit(): void {
    this.store$.pipe(select(UserSelectors.settings), take(1)).subscribe(settings => {
      this.settings = _.cloneDeep(settings);

      switch (this.type) {
        case UiHowToDrawType.ZONES:
          if (this.settings?.account?.howToDrawZones !== undefined) {
            this.howToDraw = !this.settings.account.howToDrawZones;
          } else {
            this.settings = {
              ...this.settings,
              account: {
                ...this.settings.account,
                howToDrawZones: true
              }
            }
            this.howToDraw = true;
          }
          break;
        case UiHowToDrawType.LINES:
          if (this.settings?.account?.howToDrawLines !== undefined) {
            this.howToDraw = !this.settings.account.howToDrawLines;
          } else {
            this.settings = {
              ...this.settings,
              account: {
                ...this.settings.account,
                howToDrawLines: true
              }
            }
            this.howToDraw = true;
          }
          break;
      }
    });
  }

  public saveHowToDraw() {
    const auth = this.authService.getSession();
    const data: AppUser.UpdateUserProfileData = {
      settings: {...this.settings},
    };
    this.store$.dispatch(UserActions.UpdateUserProfile({userId: auth.authProviderId!, data}));
  }

  closeHowToDraw() {
    this.saveHowToDraw();
    this.howToDraw = false;
    this.settings = _.cloneDeep(this.settings);
  }

  public displayHowToDraw() {
    this.howToDraw = true;
  }

}
