<div fxLayout="column" class="p-r" fxFlexFill>
  <div mat-icon-button class="close-button c-p" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <app-thumbnail
    [name]="data.name"
    [isShareVisible]="data?.isShareVisible"
    [shareName]="data?.shareName"
    [alertId]="data?.alertId"
    [alertInstanceId]="data?.alertInstanceId"
    [optionsInput]="data.options"
    [rtl]="rtl"
    [events]="data.events"
    [searchEvents]="data.searchEvents"
    [objects]="data.objects"
    [isDialog]="true"
    [cameraId]="data.cameraId"
    (showChart)="showChart($event)"
    [defaultThumbnail]="data.defaultThumb"
    [seconds]="data.seconds"
    [type]="data.type"
    (closeDialog)="close()"
    [showObjects]="data.showObjects"
    [dialogRef]="dialogRef"
    [allowTrack]="data.allowTrack"
    [alertName]="data.alertName"
    [alertTs]="data.alertTs"
    [initialTs]="data.initialTs"
    [cameraView]="data.cameraView"
    fxFlex=""
    tabindex="0"
  ></app-thumbnail>
</div>
