import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionGroupModels, SystemSecurityGroupModels } from '@models/group.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { PermissionModel } from '@models/permission.model';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ui-email-role',
  templateUrl: './ui-email-role.component.html',
  styleUrls: ['./ui-email-role.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiEmailRoleComponent),
      multi: true,
    },
  ],
})
export class UiEmailRoleComponent implements OnInit, ControlValueAccessor {
  @Input()
  public permissionGroups: SystemSecurityGroupModels.SystemSecurityGroupDocument[] = [];

  @Output()
  onError: EventEmitter<any> = new EventEmitter();

  public value: { [key: string]: string[] };
  public email: UntypedFormControl = new UntypedFormControl('', [Validators.email]);
  public permissions: PermissionModel.Permissions[] = [];

  public isInvalid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  ngOnInit(): void {
    this.email.valueChanges.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.updateEmail();
      });
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: { [key: string]: string }): void {
    this.email.patchValue(Object.keys(val)[0]);
  }

  public updateValue(insideValue: { [key: string]: string[] }) {
    if (!insideValue) {
      this.isInvalid$.next(true);
    }
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  public updateEmail() {
    if (this.permissions.length && this.email.valid && this.email.value) {
      this.updateValue({ [this.email.value]: this.permissions });
      this.onError.emit(null);
    }

    if (this.email.value && this.email.invalid) {
      this.onError.emit('invalid');
    }

    if (!this.permissions.length && this.email.invalid) {
      this.onError.emit('invalid');
    }

    if (this.permissions.length && !this.email.value) {
      this.onError.emit('invalid');
    }

    if (!this.permissions.length && !this.email.value) {
      this.onError.emit(null);
    }

  }

  public updatePermissions(permissions: PermissionModel.Permissions[]) {
    this.permissions = permissions;
    if (!this.permissions.length && this.email.value) {
      this.onError.emit('invalid');
    }
    if (!!this.permissions.length && this.email.valid) {
      this.updateValue({ [this.email.value]: this.permissions });
      this.onError.emit(null);
    }

    if (!this.permissions.length && this.email.invalid) {
      this.onError.emit('invalid');
    }

    if (this.permissions.length && !this.email.value) {
      this.onError.emit('invalid');
    }

    if (!this.permissions.length && !this.email.value) {
      this.onError.emit(null);
    }
  }

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };
}
