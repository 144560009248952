<div class="ui-title-popup">
  <div class="header">Save as view</div>
  <div class="body">
    <div class="description">
      You can filter the results page and save it as a new view. It will appear as tab in the top part of the page.
    </div>
    <div class="input-container">
      <ui-input [style]="inputStyles.labelOutside" [(ngModel)]="name" label="View name" placeholder="Provide the view name"></ui-input>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="radio-wrapper">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="isPublic">
        <mat-radio-button class="radio-button" color="primary" [value]="true">Public </mat-radio-button>
        <mat-radio-button class="radio-button" color="primary" [value]="false">Private </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="end center">
    <ui-button
      colorText="var(--color-secondary-gray-800)"
      colorBorder="var(--color-secondary-gray-400)"
      text="Cancel"
      (clickAction)="close()"
    ></ui-button>
    <ui-progress-button [disabled]="!name" text1="Save as view" text2="Saving" icon="save" (clickAction)="save()"> </ui-progress-button>
  </div>
</div>
