import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActiveOrganizationResponse, ApiKeyDto, CreateOrganizationParams, CreateOrganizationResponse, GetOrganizationUsersResponse, IpRules, Organization, OrganizationUserFilters, UserOrganizationDropDown } from '@models/organization.model';
import { map, Observable, of } from 'rxjs';
import { api } from '@consts/url.const';
import { Invite, InvitePaginatedResponse, InviteWithOrgName } from '@models/invite.model';
import { SortDirection } from '@angular/material/sort';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiKeyModel } from '@models/api-key.models';
import { InvitationStatus } from '@enums/invite.enum';
import { PaginationResult } from '@models/shared.model';
import { PermissionGroupModels, SystemSecurityGroupModels } from '@models/group.model';
import { AppUser } from '../user/user.model';
import User = AppUser.User;
import { parseJwt } from '../helpers/common.helpers';

@Injectable()
export class OrganizationService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
  }

  public saveOrganization(data: {
    organization: CreateOrganizationParams;
    invites: { [key: string]: string };
  }): Observable<CreateOrganizationResponse> {
    return this.http.post<CreateOrganizationResponse>(api.organization.crud, data);
  }

  public getActiveOrganization(): Observable<{ activeOrganization: ActiveOrganizationResponse; isDefaultOrganization: boolean }> {
    const token = this.authenticationService.getIdTokenFromLocalStorage();
    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();
    const payload = parseJwt(token) ?? null;

    const orgId = payload?.orgId;
    if (!!orgId && orgId.toString() === authProviderId.split('|')[1].toString()) {
      const result = {
        activeOrganization: null,
        isDefaultOrganization: true,
      };

      return of(result);
    }

    return this.http.get<ActiveOrganizationResponse>(api.organization.crud)
      .pipe(
        map(res => {
          return {
            activeOrganization: res,
            isDefaultOrganization: false,
          };
        }),
      );
  }

  public getOrganizationUsers(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
  ): Observable<GetOrganizationUsersResponse> {
    let url = `${api.organization.getOrganizationUsers}?limit=${perPage}&offset=${page * perPage
    }&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.get<GetOrganizationUsersResponse>(url);
  }

  public sendInvites(invites: { [key: string]: string }, orgId?: string): Observable<any> {
    return this.http.post<any>(`${api.organization.invite}`, {
      invites,
      orgId,
    });
  }

  public updateOrganization(id: string, toUpdate: Partial<Organization>): Observable<boolean> {
    return this.http.put<boolean>(`${api.organization.crud}/${id}`, toUpdate);
  }

  public getUserOrganizations(userId: string): Observable<UserOrganizationDropDown[]> {
    return this.http.get<UserOrganizationDropDown[]>(api.organization.getUserOrganizations(userId));
  }

  public getInvitesByOrganization(query: string, filters: { status: InvitationStatus[] }): Observable<Invite[]> {
    let url = `${api.invites.get}?`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<Invite[]>(url, filters);
  }

  public blockUser(userId: string, orgId: string): Observable<any> {
    return this.http.put<any>(`${api.organization.blockUser(userId)}`, {
      orgId,
    });
  }


  //todo move to invite Service

  public resend(inviteId: string): Observable<any> {
    return this.http.put<any>(`${api.invites.resend}`, {
      id: inviteId,
    });
  }

  public cancel(inviteId: string): Observable<any> {
    return this.http.put<any>(`${api.invites.cancel}`, {
      id: inviteId,
    });
  }

  public getActive(): Observable<InviteWithOrgName[]> {
    return this.http.get<InviteWithOrgName[]>(`${api.invites.getActive}`);
  }

  public accept(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${api.invites.accept}`, { id });
  }

  public updateOrganizationLogo(orgId: string, file: Blob) {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('orgId', orgId);
    return this.http.post(api.organization.updateLogo, formData);
  }

  public checkIfOrgNameExist(name: string): Observable<boolean> {
    return this.http.get<boolean>(`${api.organization.checkIfOrgNameExist}/${name}`);
  }

  public checkIfOrgEmailExist(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${api.organization.checkIfOrgEmailExist}/${email}`);
  }


  public getActiveOrganizationUsers(): Observable<UserOrganizationDropDown[]> {
    return this.http.get<UserOrganizationDropDown[]>(`${api.organization.getActiveOrganizationUsers}`);
  }

  public getActiveOrganizationUsersPaginated(filters: OrganizationUserFilters,
                                             page: number,
                                             perPage: number): Observable<User[]> {
    let url = `${api.organization.getActiveOrganizationUsers}?page=${page}&size=${perPage
    }&paginated=true`;
    if (filters?.groups?.length) {
      url += `&permissionGroups=${filters.groups.join(',')}`;
    }
    if (filters.query) {
      url += `&query=${encodeURIComponent(filters.query)}`;
    }
    return this.http.get<User[]>(url);
  }

  public delete(orgId: string) {
    return this.http.delete(`${api.organization.crud}/${orgId}`);
  }

  public removeUser(userId: string, orgId: string): Observable<any> {
    return this.http.put<any>(`${api.organization.removeUser(userId)}`, {
      orgId,
    });
  }

  public leave(userId: string, orgId: string) {
    return this.http.put<any>(api.organization.leave(userId), {
      orgId,
    });
  }

  public getAll(): Observable<ActiveOrganizationResponse[]> {
    return this.http.get<ActiveOrganizationResponse[]>(api.organization.all);
  }

  public checkIfUserSigned(inviteId: string): Observable<any> {
    return this.http.get<boolean>(`${api.invites.checkIfUserSigned}?inviteId=${inviteId}`);
  }

  public getUsersByOrg(dto: { orgIds: string[] }): Observable<UserOrganizationDropDown[]> {
    return this.http.post<UserOrganizationDropDown[]>(api.organization.orgUsers, dto);
  }

  public getApiKeys(page: number, perPage: number, query: string, filters: ApiKeyModel.Filters): Observable<PaginationResult<ApiKeyModel.ApiKeyDocument>> {
    let url = `${api.organization.apiKeys}?limit=${perPage}&page=${page}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<ApiKeyModel.ApiKeyDocument>>(url, filters);
  }

  public generateApiKey(dto: ApiKeyDto): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(api.organization.generateApi, dto);
  }

  public revokeApiKey(id: string): Observable<ApiKeyModel.ApiKeyDocument> {
    return this.http.post<ApiKeyModel.ApiKeyDocument>(`${api.organization.revokeApiKey}/${id}`, {});
  }

  public extentApiKey(dto: { apiKey: string, extend: number }): Observable<ApiKeyModel.ApiKeyDocument> {
    return this.http.post<ApiKeyModel.ApiKeyDocument>(api.organization.extendApiKey, dto);
  }

  public revokeAllInvites(): Observable<any> {
    return this.http.post<any>(`${api.invites.revokeAll}`, {});
  }

  public removeInvite(inviteId: string): Observable<InvitePaginatedResponse> {
    return this.http.delete<InvitePaginatedResponse>(api.invites.one(inviteId));
  }

  public resendAllInvites(): Observable<any> {
    return this.http.post<any>(`${api.invites.resendAll}`, {});
  }

  public checkIfUserExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(api.organization.checkIfUserExists(email));
  }

  public getOrganizationPermissionGroups(): Observable<PermissionGroupModels.PermissionGroupDocumentBase[]> {
    return this.http.get<PermissionGroupModels.PermissionGroupDocumentBase[]>(api.permissionGroups.getOrganizationPermissionGroups);
  }

  public getSystemPermissionGroups(): Observable<SystemSecurityGroupModels.SystemSecurityGroupDocument[]> {
    return this.http.get<SystemSecurityGroupModels.SystemSecurityGroupDocument[]>(api.permissionGroups.getSystemPermissionGroups);
  }

  public getMaxRetentionDays(): Observable<{ maxRetentionDays: number }> {
    return this.http.get<{ maxRetentionDays: number }>(api.organization.getMaxRetentionDays);
  }

  public saveLoginFirewall(orgId: string, data: IpRules): Observable<boolean> {
    return this.http.put<boolean>(`${api.organization.crud}/${orgId}`, { ipRules: data });
  }

  public acceptInviteNoAuth(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${api.invites.acceptNoAuth}`, { id });
  }
}
