<div class="select-camera-container" fxFlex>
  <ng-container *ngIf="options.edgeId && options.cameraId || loading">
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="error; else noError">
        <div fxLayout="row"
             fxLayoutAlign="center center"
             class="w-100 h-100"
             matTooltip="Retry"
             (click)="initThumbnails()"
        >
          <mat-icon>refresh</mat-icon>
        </div>
      </ng-container>
      <ng-template #noError>
        <div fxLayout="column" fxLayoutAlign="center center" class="thumbnail-offline-overlay"
        >
          <mat-icon *ngIf="offline">cloud_off</mat-icon>
        </div>
        <img
          #image
          class="card-image"
          [style.max-height]=""
          [lazyLoad]="img"
        />
      </ng-template>
    </ng-container>
    <ng-container *ngIf="loading">
      <div fxLayoutAlign="center center" class="c-p svg-fill-primary no-camera-wrapper w-100 h-100">
        <mat-spinner [diameter]="28"></mat-spinner>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="cameraName"
       class="camera-name flex-row start-center"
  >
    <span class="ui-icon-status-online"></span>
    {{ cameraName }}
  </div>
</div>


