<div fxLayout="column" fxLayoutGap="8px" class="h-100" fxFlexFill>
  <div #lineSelectorWrapper
       (click)="click($event)"
       (mousemove)="move($event)"
       fxLayout="row"
       fxLayoutAlign="center center"
       class="zone-selector-wrapper"
       [class.not-loaded]="!imageLoaded"
       [style.height.px]="!imageLoaded ? lineSelectorWrapper.clientWidth * 9/16 : 'auto'"
  >
    <ui-how-to-draw [type]="UiHowToDrawType.LINES" #howToDraw (click)="$event.stopPropagation()"></ui-how-to-draw>

    <div #p1 class="point" [class.hide]="noSelection()" [class.selected]="true"></div>
    <div #p2 class="point" [class.hide]="noSelection()" [class.selected]="true"></div>
    <div #line class="line" [class.hide]="noSelection()">
      <div class="direction" [class.hide]="!p2Selected()" (click)="$event.stopPropagation(); changeDir()">
        <mat-icon *ngIf="lineCrossing?.d === AlertEventLineCrossingDirection.ANY">remove</mat-icon>
        <mat-icon *ngIf="lineCrossing?.d === AlertEventLineCrossingDirection.CCW">arrow_upward</mat-icon>
        <mat-icon *ngIf="lineCrossing?.d === AlertEventLineCrossingDirection.CW">arrow_downward</mat-icon>
      </div>
    </div>
    <div fxFlexFill fxLayoutAlign="center center" *ngIf="!imageLoaded">
      <ui-preloader></ui-preloader>
    </div>
    <img #snapshot (load)="onImageLoad()"
         [errorImage]="{edgeId: edgeId, cameraId: cameraId } | cameraThumbnailImagePipe"
         errorImage="assets/snapshot-placeholder.jpg"
         [lazyLoad]="!selectedSnapshot ?  ({edgeId: edgeId, cameraId: cameraId } | cameraBestImagePipe | async) : selectedSnapshot"
         [class.hidden]="snapshot.alt === 'error'"
    />
  </div>
  <div class="next-prev flex-row">
          <span matRipple
                matTooltip="Previous snapshot"
                matTooltipClass="sidebar-tooltip"
                class="ui-icon-prev-frame"
                [class.disabled]="snapshotIdx === snapshots?.length - 1"
                (click)="prevSnapshot()"
          ></span>
    <span matRipple
          matTooltip="Next snapshot"
          matTooltipClass="sidebar-tooltip"
          class="ui-icon-next-frame"
          [class.disabled]="snapshotIdx === 0"
          (click)="nextSnapshot()"
    ></span>
  </div>
  <!--  <h3 *ngIf="p2Selected()" class="mb-15">Your line</h3>-->
  <div *ngIf="p2Selected()" fxLayout="row" fxLayoutAlign="start center" class="narrow-input">
    <div fxLayout="column" fxLayoutAlign="center center" class="zone-icon">
      <div fxLayout="row" fxLayoutAlign="center center" class="zone-icon-inner"></div>
    </div>
    <mat-form-field appearance="outline" class="ml-15">
      <input matInput placeholder="Name your line" type="text" [value]="lineCrossing?.name ?? ''"
             (input)="changeName($event)"
      />
    </mat-form-field>
    <div class="delete-zone-button ml-15 c-p" (click)="clear()">
      <mat-icon svgIcon="delete"></mat-icon>
    </div>
  </div>
</div>
