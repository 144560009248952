import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { api } from '@consts/url.const';
import { FloorModel } from '@models/floor.model';
import { map, Observable } from 'rxjs';
import { GroupModels } from '@models/people.model';
import { HttpService } from '../core/http.service';

@Injectable({
  providedIn: 'root',
})
export class FloorService {


  constructor(private http: HttpClient, private store$: Store, private httpService: HttpService) {
  }

  public getBuildings(): Observable<FloorModel.BuildingDocument[]> {
    return this.http.get<FloorModel.BuildingDocument[]>(api.floors.crud);
  }

  public createBuilding(request: Partial<FloorModel.BuildingDocument>): Observable<FloorModel.BuildingDocument> {
    return this.http.post<FloorModel.BuildingDocument>(api.floors.crud, request);
  }

  public updateBuilding(request: Partial<FloorModel.BuildingDocument>): Observable<FloorModel.BuildingDocument> {
    return this.http.patch<FloorModel.BuildingDocument>(api.floors.crud, request);
  }

  public deleteBuilding(buildingId: string) {
    return this.http.delete(api.floors.deleteBuilding(buildingId));
  }

  public deleteFloor(buildingId: string, floorId: string) {
    return this.http.delete(api.floors.deleteFloor(buildingId, floorId));
  }

  public editFloor(buildingId: string, floorId: string, floor: FloorModel.Floor) {
    return this.http.put(api.floors.editFloor(buildingId, floorId), floor);
  }

  public createFloor(buildingId: string, floor: FloorModel.Floor) {
    return this.http.put(api.floors.createFloor(buildingId), floor);
  }


  public getImagePreSignedUrl(request: FloorModel.GetFloorAssetPresignedUrlRequest, file: Blob): Observable<{
    url: string;
    filename: string,
    file: Blob
  }> {
    return this.http.post<{
        url: string;
        filename: string
      }>(api.floors.upload, request)
      .pipe(
        map(res => {
          return {
            ...res,
            file,
          };
        }),
      );
  }

  uploadImagePresignedUrl(request: FloorModel.UploadFloorAssetPresignedUrlRequest) {
    return this.httpService.uploadPresignedUrl(request);
  }

}
