import { AlertEntry } from '../../development/alerts.service';
import { AlertsV2ShowSettings } from '@models/alerts-v2.model';

export namespace AlertModel {

  export const filterAlertsByShowSettings = (alerts: AlertEntry[], showSettings: AlertsV2ShowSettings): AlertEntry[] => {
    return alerts.filter(
      (alert) =>
        alert?.event?.settings?.show === undefined ||
        (!alert?.event?.settings?.show?.disabled &&
          alert?.event?.settings?.show?.on?.includes(showSettings)),
    );
  };

}
