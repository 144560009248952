import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;

@Component({
  selector: 'ui-confirm',
  templateUrl: './ui-confirm.component.html',
  styleUrls: ['./ui-confirm.component.scss'],
})
export class UiConfirmComponent implements OnInit {
  public question: string;
  constructor(public dialogRef: MatDialogRef<UiConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.question = this.data.question;
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
