<div class="body" [formGroup]="configurationForm">
  <div class="input-row">
    <mat-checkbox formControlName="dhcp" color="primary">dhcp</mat-checkbox>
  </div>
  <div class="input-row">
    <ui-input
      formControlName="address"
      label="Address"
      [style]="inputStyle.labelOutside"
      [required]="true"
      [validationEnabled]="true"
      [invalid]="configurationForm.get('address').invalid && configurationForm.get('address').touched"
      [validationErrors]="configurationForm.get('address').errors"
    ></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="netmask" label="Netmask" [style]="inputStyle.labelOutside" [required]="true"></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="network" label="Network" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="broadcast" label="Broadcast" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="gateway" label="Gateway" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="dnsNameservers" label="DnsNameservers" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="input-row">
    <ui-input formControlName="dnsSearch" label="DnsSearch" [style]="inputStyle.labelOutside"></ui-input>
  </div>
  <div class="ui-input-wrapper label-out input-row">
    <label class="label">EthInterface</label>
    <input formControlName="ethInterface" type="number" class="ui-input" style="width: 100%; box-sizing: border-box" />
  </div>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="end center">
  <button mat-flat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="ok()">Ok</button>
</div>
