import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-chip-select',
  templateUrl: './ui-chip-select.component.html',
  styleUrls: ['./ui-chip-select.component.scss'],
})
export class UiChipSelectComponent implements OnInit {
  @Input()
  selected: boolean = false;

  @Input()
  value: string;

  @Output()
  selectChange: EventEmitter<{ value: string; selected: boolean }> = new EventEmitter<{ value: string; selected: boolean }>();

  mouseover = false;

  constructor() {}

  ngOnInit(): void {}

  click() {
    this.selected = !this.selected;
    this.selectChange.emit({ value: this.value, selected: this.selected });
  }
}
