import { EdgeCamera } from '../cameras/camera.model';
import { CreateEdgeToken } from './sessions/create-edge-session';
import { SearchDevicesManualGetCameraDetailsToken } from './sessions/searce-devices-manual-get-camera-details-session';

export declare module Messaging {
  export interface SqsMessage<T> {
    sqsMessage?: T;
  }

  export interface OperationResult<T> {
    result?: T;
  }

  export interface GetCameraManuallyDetailsPayload {
    locationId: string;
    sqsMessage: ISearchDevicesManualGetCameraDetailsOutgoingMsg;
    camera?: EdgeCamera.CameraDocument;
    snapshotUrl?: string;
    snapshotResponseCode?: CameraConnectionResults;
  }

  export interface GetCameraDetailsPayload {
    locationId: string;
    cameraId?: string;
    create?: boolean;
    camera?: EdgeCamera.CameraDocument;
    snapshotUrl: string;
    snapshotResponseCode: CameraConnectionResults;
  }

  export interface CreateCameraPayload {
    camera: EdgeCamera.CameraDocument;
    locationId: string;
    // detailsToken?: string;
  }

  // export interface CreateEdgePayload extends SqsMessage<SqsMessages.ICreateEdgeOutgoingMsg>, OperationResult<Location.AddEdgeToLocationResponse> { }
}

export enum SNSNotificationTypes {
  SubscriptionConfirmation = 'SubscriptionConfirmation',
  Notification = 'Notification',
}

export interface SnsMsg {
  msg: string;
  topicArn: string;
  token?: string;
}

export enum SNSResponseCode {
  OK = 200,
  Created = 201,
  Accepted = 202,
  Updated = 204,
  Deleted = 208,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotAllowed = 405,
  RequestTimeout = 408,
  Conflict = 409,
  InternalError = 500,
  NotImplemented = 501,
  ServiceUnavailable = 503,
  Unknown = 999,
}

export interface SNSResponseStatusInterface {
  responseCode: SNSResponseCode;
  data?: string;
}

export interface SNSMsgInterface {
  edgeId: string;
  timestamp: string;
  data: string;
  status: SNSResponseStatusInterface;
  token: string;
}

export enum TokenDataStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  ERROR = 'error',
  LIVE = 'live'
}

export type TokenDataResponseCode = SNSResponseCode;

export interface TokenDataMessageBase {
  status: TokenDataStatus;
  timestamp: Date;
  msg?: string;
  responseCode?: TokenDataResponseCode;
  responseCodeR?: string;
}

export interface TokenDataMsg<SQS, SNS, SESSION, RESULT> extends TokenDataMessageBase {
  session?: SESSION;
  sqsMessage?: SQS;
  snsMessage?: SNS;
  result?: RESULT;
}

export interface AddCameraTokenData {
  locationId: string;
  detailsFetched?: boolean;
  cameraId?: string;
}

export interface DiscoveredCamerasPayload {
  discoveredCameras: string;
}

export enum CameraConnectionResults {
  OK,
  Timeout,
  Refused,
  NotFound,
  Unauthorized,
  UnknownError,
}

export interface ISearchDevicesManualGetCameraDetailsOutgoingMsg {
  username: string;
  password: string;
  ipAddress: string;
  port: number;
  connectionString: string;
  width: number;
  height: number;
}

export interface CreateEdgePayload {
  name: string;
  locationId: string;
}

export type TokenDataPayload =
  | AddCameraTokenData
  | DiscoveredCamerasPayload
  | Messaging.GetCameraManuallyDetailsPayload
  | Messaging.GetCameraDetailsPayload
  | Messaging.CreateCameraPayload
  | CreateEdgePayload;

export type SessionMessages = CreateEdgeToken.AllSessionData | SearchDevicesManualGetCameraDetailsToken.AllSessionData;
