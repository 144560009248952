import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VehiclesState, selectAll } from '@states/vehicles/vehicles.reducer';

export const selectVehiclesState = createFeatureSelector<VehiclesState>('vehiclesStateReducer');

export const selectAllVehicles = createSelector(selectVehiclesState, selectAll);
export const selectSavedVehicles = selectAllVehicles

export const selectUnsavedVehicles =
  createSelector(
    selectVehiclesState,
    (state: VehiclesState) => state.unsaved.filter((vehicle) => !!vehicle.bestImage),
  );
