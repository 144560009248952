import { EdgeCamera, Substream } from '../../../cameras/camera.model';
import { createReducer, on } from '@ngrx/store';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { cameraEditMenuLevel2Validation } from '@consts/menu.const';
import { CameraAsset, CameraPrivacy, PrivacyType } from '@models/camera.model';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { ScanZones } from '../../../pages/camera-edit/tabs/search-select-zones/search-select-zones.model';
import { VpnConfiguration } from '@models/search-configuration.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { CameraEditActions } from '@states/camera-edit/camera-edit.action-types';

export declare interface CameraEditState {
  selectedCamera: EdgeCamera.CameraItem;
  zones: ScanZones;
  zonesExclude?: boolean;
  definedZones: boolean;
  markedIdx: number[];
  objectsToScan: AnalyticClasses[];
  objectsToPixelate: AnalyticClasses[];
  selectedSearchConfigurationId: string;
  licensePlates: boolean;
  vehicleMMC: boolean;
  level2MenuValid: { [key: string]: boolean };
  cameraAssets: CameraAsset[];
  addCameraManuallySuccess: boolean;
  cameraSearchSettings: CameraSearchSettings;
  selectedCameraId: string;
  selectedLocationId: string;
  selectedEdgeId: string;
  privacy: CameraPrivacy;
  vpnConfiguration: VpnConfiguration;
  vpnConfigurationErr: KeyValuePairs<string>;
  protectiveGear?: boolean;
  forklift?: boolean;
  container?: boolean;
  shoppingCart?: boolean;
  orgProtectiveGear?: boolean;
  orgForklift?: boolean;
  orgContainer?: boolean;
  orgShoppingCart?: boolean;
  subStreams: Substream[];
  isTestSubStreamSuccess: boolean;
}

export const initialState: CameraEditState = {
  selectedCamera: null,
  zones: {},
  zonesExclude: false,
  definedZones: true,
  markedIdx: [],
  objectsToScan: [AnalyticClasses.pet, AnalyticClasses.vehicle, AnalyticClasses.person],
  objectsToPixelate: [],
  selectedSearchConfigurationId: null,
  licensePlates: false,
  vehicleMMC: false,
  level2MenuValid: cameraEditMenuLevel2Validation,
  cameraAssets: null,
  addCameraManuallySuccess: false,
  cameraSearchSettings: null,
  selectedCameraId: null,
  selectedLocationId: null,
  selectedEdgeId: null,
  privacy: {
    zones: {},
    definedZones: true,
    markedIdx: [],
    objectsToPixelate: [],
    privacyType: PrivacyType.pixelate,
  },
  vpnConfiguration: null, //vpnConfigurationExample,
  vpnConfigurationErr: {},
  protectiveGear: false,
  forklift: false,
  container: false,
  shoppingCart: false,
  orgProtectiveGear: false,
  orgForklift: false,
  orgContainer: false,
  subStreams: [],
  isTestSubStreamSuccess: false,
};

export const cameraEditStateReducer = createReducer(
  initialState,
  on(CameraEditActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(CameraEditActions.setSelectedCamera, (state, { selectedCamera }) => {
    return {
      ...state,
      selectedCamera,
    };
  }),
  on(CameraEditActions.setZones, (state, { zones }) => {
    return {
      ...state,
      zones,
    };
  }),
  on(CameraEditActions.setZonesExclude, (state, { zonesExclude }) => {
    return {
      ...state,
      zonesExclude,
    };
  }),
  on(CameraEditActions.setDefinedZones, (state, { definedZones }) => {
    return {
      ...state,
      definedZones,
    };
  }),
  on(CameraEditActions.setProtectiveGear, (state, { protectiveGear }) => {
    return {
      ...state,
      protectiveGear,
    };
  }),
  on(CameraEditActions.setContainer, (state, { container }) => {
    return {
      ...state,
      container,
    };
  }),
  on(CameraEditActions.setForklift, (state, { forklift }) => {
    return {
      ...state,
      forklift,
    };
  }),
  on(CameraEditActions.setShoppingCart, (state, { shoppingCart }) => {
    return {
      ...state,
      shoppingCart,
    };
  }),
  on(CameraEditActions.setMarkedIdx, (state, { markedIdx }) => {
    return {
      ...state,
      markedIdx,
    };
  }),
  on(CameraEditActions.setObjectToPixelate, (state, { objectToPixelate }) => {
    return {
      ...state,
      objectsToPixelate: addObjToPixelate(state.objectsToPixelate, objectToPixelate),
    };
  }),
  on(CameraEditActions.setObjectToScan, (state, { objectToScan }) => {
    return {
      ...state,
      objectsToScan: addObjToScan(state.objectsToScan, objectToScan),
    };
  }),
  on(CameraEditActions.setObjectsToScan, (state, { objectsToScan }) => {
    return {
      ...state,
      objectsToScan,
    };
  }),
  on(CameraEditActions.getSelectedCameraVPNConfigurationSuccess, (state, { vpnConfiguration }) => {
    return {
      ...state,
      vpnConfiguration,
    };
  }),
  on(CameraEditActions.setVpnConfiguration, (state, { vpnConfiguration }) => {
    return {
      ...state,
      vpnConfiguration,
    };
  }),
  on(CameraEditActions.getSelectedSearchConfigurationSuccess, (state, { selectedSearchConfiguration }) => {
    return {
      ...state,
      selectedCamera: selectedSearchConfiguration.selectedCamera,
      zones: selectedSearchConfiguration.zones,
      zonesExclude: selectedSearchConfiguration.zonesExclude,
      definedZones: selectedSearchConfiguration.definedZones,
      protectiveGear: selectedSearchConfiguration.protectiveGear,
      forklift: selectedSearchConfiguration.forklift,
      container: selectedSearchConfiguration.container,
      shoppingCart: selectedSearchConfiguration.shoppingCart,
      markedIdx: selectedSearchConfiguration.markedIdx,
      objectsToScan: selectedSearchConfiguration.objectsToScan,
      selectedSearchConfigurationId: selectedSearchConfiguration._id,
      licensePlates: selectedSearchConfiguration.licensePlates,
      vehicleMMC: selectedSearchConfiguration.vehicleMMC,
      privacy: selectedSearchConfiguration.privacy ?? initialState.privacy,
    };
  }),
  on(CameraEditActions.setLicensePlates, (state, { licensePlates }) => {
    return {
      ...state,
      licensePlates,
    };
  }),
  on(CameraEditActions.setVehicleMMC, (state, { vehicleMMC }) => {
    return {
      ...state,
      vehicleMMC,
    };
  }),
  on(CameraEditActions.getCameraAssetsSuccess, (state, { cameraAssets }) => {
    return {
      ...state,
      cameraAssets,
    };
  }),
  on(CameraEditActions.setIsAddCameraManuallySuccess, (state, { addCameraManuallySuccess }) => {
    return {
      ...state,
      addCameraManuallySuccess,
    };
  }),
  on(CameraEditActions.getCameraSearchSettingsSuccess, (state, { cameraSearchSettings }) => {
    return {
      ...state,
      cameraSearchSettings: cameraSearchSettings,
    };
  }),
  on(CameraEditActions.setSelectedLocationId, (state, { selectedLocationId }) => {
    return {
      ...state,
      selectedLocationId,
    };
  }),
  on(CameraEditActions.setSelectedEdgeId, (state, { selectedEdgeId }) => {
    return {
      ...state,
      selectedEdgeId,
    };
  }),
  on(CameraEditActions.setSelectedCameraId, (state, { selectedCameraId }) => {
    return {
      ...state,
      selectedCameraId,
    };
  }),

  on(CameraEditActions.setPrivacy, (state, { privacy }) => {
    return {
      ...state,
      privacy,
    };
  }),
  on(CameraEditActions.setOrgProtectiveGear, (state, { orgProtectiveGear }) => {
    return {
      ...state,
      orgProtectiveGear,
    };
  }),
  on(CameraEditActions.setOrgForklift, (state, { orgForklift }) => {
    return {
      ...state,
      orgForklift,
    };
  }),
  on(CameraEditActions.setOrgShoppingCart, (state, { orgShoppingCart }) => {
    return {
      ...state,
      orgShoppingCart,
    };
  }),
  on(CameraEditActions.updateSubStream, (state, { connectionString, index }) => {
    const subStreams = [...state.subStreams];
    subStreams[index] = {
      ...subStreams[index],
      connectionString,
    };
    return {
      ...state,
      subStreams,
    };
  }),
  on(CameraEditActions.testSubStream, (state, { index }) => {
    const subStreams = [...state.subStreams];
    subStreams[index] = {
      ...subStreams[index],
      loading: true,
    };
    return {
      ...state,
      subStreams,
      isTestSubStreamSuccess: initialState.isTestSubStreamSuccess,
    };
  }),
  on(CameraEditActions.setSubStreamSession, (state, { index, session }) => {
    const subStreams = [...state.subStreams];
    subStreams[index] = {
      ...subStreams[index],
      session,
    };
    return {
      ...state,
      subStreams,
    };
  }),
  on(CameraEditActions.testSubStreamSuccess, (state) => {
    return {
      ...state,
      isTestSubStreamSuccess: true,
    };
  }),
  on(CameraEditActions.testSubStreamInit, (state) => {
    return {
      ...state,
      isTestSubStreamSuccess: false,
    };
  }),
  on(CameraEditActions.setOrgContainer, (state, { orgContainer }) => {
    return {
      ...state,
      orgContainer,
    };
  }),
  on(CameraEditActions.testSubStreamFail, (state, { session, success, deleteSub, width, height }) => {
    const subStreams = [...state.subStreams];
    const index = subStreams.findIndex(sub => sub.session === session);

    if (deleteSub) {
      subStreams.splice(index, 1);
    } else {
      subStreams[index] = {
        ...subStreams[index],
        loading: false,
        connected: success,
        width,
        height,
      };
    }
    return {
      ...state,
      subStreams,
    };
  }),
  on(CameraEditActions.updateCameraCoordsSuccess, (state, { coords, address }) => {
    return {
      ...state,
      selectedCamera: {
        ...state.selectedCamera,
        cloudOnly: {
          ...state.selectedCamera.cloudOnly,
          coords,
          address,
        },
      },
    };
  }),
);

const addObjToScan = (array: AnalyticClasses[], object: AnalyticClasses): AnalyticClasses[] => {
  const result = [...array];
  const index = result.indexOf(object);
  if (index === -1) {
    result.push(object);
  } else {
    result.splice(index, 1);
  }
  return result;
};

const addObjToPixelate = (array: AnalyticClasses[], object: AnalyticClasses): AnalyticClasses[] => {
  const result = [...array];
  const index = result.indexOf(object);
  if (index === -1) {
    result.push(object);
  } else {
    result.splice(index, 1);
  }
  return result;
};
