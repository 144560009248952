export declare module Canvas {
  export interface Point {
    x: number;
    y: number;
  }
}

export interface SelectionColor {
  name?: string;
  line: string;
  area: string;
}

export const SelectionColors: SelectionColor[] = [
  { name: 'green', line: '#00FF19', area: 'rgba(0, 255, 25, 0.3)' },
  { name: 'blue', line: '#0BB7FF', area: 'rgba(53, 182, 238, 0.3)' },
  { name: 'yellow', line: '#ffe100', area: 'rgba(238,210,53,0.3)' },
  { name: 'purple', line: '#b700ff', area: 'rgba(173,0,255,0.3)' },
  { name: 'cyan', line: '#00ffea', area: 'rgba(0,255,220,0.3)' },
];

export interface Selections {
  [key: string]: Point[];
}

export interface Point {
  x: number;
  y: number;
}

export class Square {
  constructor(private ctx: CanvasRenderingContext2D) {}

  draw(x: number, y: number, z: number) {
    this.ctx.fillRect(z * x, z * y, z, z);
  }
}

export class Circle {
  constructor(private ctx: CanvasRenderingContext2D) {}

  draw(x: number, y: number, z?: number) {
    this.ctx.beginPath();
    this.ctx.arc(x, y, 6, 0, 2 * Math.PI);
    this.ctx.fill();
  }
}

export class Line {
  constructor(private ctx: CanvasRenderingContext2D) {}

  draw(x1: number, y1: number, x2: number, y2: number) {
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke();
  }
}
