import { Component, Inject, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { DoorModels } from '@models/door.model';
import { select, Store } from '@ngrx/store';
import { DoorsSelectors } from '@states/doors/doors.selector-types';
import { LocationSelectors } from '@states/location/location.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationModel } from '../../../locations/location.model';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UiDoorSelectorDialogData {
  selectedDoors: DoorModels.Door[];
}

export interface LocationRow {
  locationId: string;
  name: string;
  expanded: boolean;
  selected: boolean;
  doors: DoorModels.Door[];
}

@UntilDestroy()
@Component({
  selector: 'app-ui-door-selector',
  templateUrl: './ui-door-selector.component.html',
  styleUrls: ['./ui-door-selector.component.scss'],
})
export class UiDoorSelectorComponent implements OnInit {

  public selectedDoors: DoorModels.Door[];

  public selectSavedDoors$: Observable<DoorModels.Door[]> = this.store$.pipe(select(DoorsSelectors.selectSavedDoors));
  public selectLocations$: Observable<LocationModel.LocationItem[]> = this.store$.pipe(select(LocationSelectors.selectAllLocations));

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public query;

  locations: LocationRow[] = [];

  constructor(public store$: Store, public dialogRef: MatDialogRef<UiDoorSelectorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UiDoorSelectorDialogData,
  ) {
  }

  public locationDoors(locationId: string) {
    return this.store$.pipe(select(DoorsSelectors.selectDoorsByLocationId(locationId)));
  }

  ngOnInit(): void {
    this.selectedDoors = this.data?.selectedDoors ? _.cloneDeep(this.data.selectedDoors) : [];
    this.selectLocations$.pipe(untilDestroyed(this), take(1))
      .subscribe((locations) => {
        this.locations = locations.map((location) => {
          return {
            locationId: location._id,
            name: location.name,
            expanded: false,
            selected: false,
            doors: [],
          };
        });
      });

    for(let location of this.locations) {
      this.locationDoors(location.locationId)
        .pipe(untilDestroyed(this))
        .subscribe((doors) => {
          location.doors = doors;
        });
    }

    for(let location of this.locations) {
      if (location?.doors?.length > 0) {
        location.expanded = true;
        break;
      }
    }
  }

  public search(query: string) {
    this.query = query;
  }

  public atLeastOneInOrg() {
    return this.selectedDoors.length > 0;
  }

  public atLeastOneDoor(location: LocationRow) {
    return this.selectedDoors.map(door => door.locationId)
      .includes(location.locationId);
  }

  public selectOrg() {
    if (this.atLeastOneInOrg()) {
      this.selectedDoors = [];
    } else {
      for(let location of this.locations) {
        for(let door of location.doors) {
          if (!this.doorSelected(door.id)) {
            this.selectedDoors.push(door);
          }
        }
      }
    }
  }

  public selectAll(location: LocationRow) {
    if (this.atLeastOneDoor(location)) {
      for(let door of location.doors) {
        if (this.doorSelected(door.id)) {
          this.removeDoor(door.id);
        }
      }
    } else {
      for(let door of location.doors) {
        if (!this.doorSelected(door.id)) {
          this.selectedDoors.push(door);
        }
      }
    }
  }

  public toggleExpand(location: LocationRow) {
    location.expanded = !location.expanded;
  }

  public img(door: DoorModels.Door) {
    return `${environment.aws.trainingThumbnailsUrl}/training/${door.edgeId}/${door.cameraId}/${door.closeState}`;
  }

  public doorSelected(doorId: string) {
    return this.selectedDoors.map(door => door.id)
      .includes(doorId);
  }

  public selectDoor(door: DoorModels.Door) {
    if (!this.doorSelected(door.id)) {
      this.selectedDoors.push(door);
    } else {
      this.removeDoor(door.id);
    }
  }

  public removeDoor(doorId: string) {
    this.selectedDoors = this.selectedDoors.filter(door => door.id !== doorId);
  }

  public select() {
    this.dialogRef.close(this.selectedDoors ?? []);
  }

  public filtered(doors: DoorModels.Door[]) {
    return doors.filter(p => p.name.toLowerCase()
      .includes(this.query.toLowerCase()));
  }

}
