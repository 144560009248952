import { createReducer, on } from '@ngrx/store';
import * as OrgGroupsActions from '@states/org-groups/org-groups.actions';
import { OrgGroupModels } from '@models/org-group.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface OrgGroupsState extends EntityState<OrgGroupModels.OrgGroup> {
  selectedOrgGroup: OrgGroupModels.OrgGroup;
}

export const adapter: EntityAdapter<OrgGroupModels.OrgGroup> = createEntityAdapter<OrgGroupModels.OrgGroup>({
  selectId: (orgGroup: OrgGroupModels.OrgGroup) => orgGroup.id,
});

export const {selectAll, selectEntities, selectIds, selectTotal} = adapter.getSelectors();

const testState: OrgGroupsState = adapter.getInitialState({
  ids: [605815355, 605815354],
  entities: {
    605815355: {
      'id': 605815355,
      'name': 'test1',
      'type': 'people',
      'members': [
        {
          'memberId': 605815355,
        },
        {
          'memberId': 605815354,
        },
      ],
    },
    605815354: {
      'id': 605815354,
      'name': 'test2',
      'type': 'people',
      'members': [
        {
          'memberId': 605815355,
        },
        {
          'memberId': 605815354,
        },
      ],
    },

  },
  selectedOrgGroup: null,
});

const initialState: OrgGroupsState = adapter.getInitialState({
  selectedOrgGroup: null,
}); // testState;
export const orgGroupsStateReducer = createReducer(
  initialState,
  on(OrgGroupsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(OrgGroupsActions.setOrgGroups, (state, {orgGroups}) => {
    return {
      ...state,
    };
  }),
  on(OrgGroupsActions.setSelectedOrgGroup, (state, {orgGroup}) => {
    return {
      ...state,
      selectedOrgGroup: orgGroup,
    };
  }),
  on(OrgGroupsActions.getOrgGroupsSuccess, (state, {orgGroups}) => {
    return adapter.setMany(orgGroups, state);
  }),
  on(OrgGroupsActions.addOrgGroupSuccess, (state, {orgGroup}) => {
    const update: OrgGroupModels.OrgGroup = {
      ...orgGroup,
    };
    return adapter.setOne(update, state);
  }),
  on(OrgGroupsActions.editOrgGroup, (state, {orgGroup}) => {
    const update: OrgGroupModels.OrgGroup = {
      ...orgGroup,
    };
    return adapter.setOne(update, state);
  }),
  on(OrgGroupsActions.removeOrgGroupSuccess, (state, {orgGroup}) => {
    return adapter.removeOne(orgGroup.id, state);
  }),
);
