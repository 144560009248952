import { Component, OnInit } from '@angular/core';
import { MsgBoxType } from '../../shared/msg-box/msg-box.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { routerSegments } from '@consts/routes';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
//todo check if we need that
export class EmailVerificationComponent implements OnInit {
  MsgBoxType: typeof MsgBoxType = MsgBoxType;

  msgType = MsgBoxType.SUCCESS;
  msg = 'Email verification success. Login to continue';
  public email: string;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const description = params['description'];
      this.email = description?.split('email:')[1] || 'your email';
      if (params['success'] == 'true') {
        this.router.navigate([routerSegments.auth]);
      }
      if (params['error']) {
        this.msgType = MsgBoxType.WARNING;
        this.msg = 'Email verification required';
      }
    });
  }

  public login() {
    this.router.navigate([routerSegments.auth]);
  }

  public logout() {
    this.authenticationService.logout();
  }
}
