<div [formGroup]="form">
  <div class="section">
    <mat-label>Every:</mat-label>
    <mat-select class="selector" formControlName="unit">
      <mat-option [value]="timeUnit.Day">Day</mat-option>
      <mat-option [value]="timeUnit.Week">Week</mat-option>
      <mat-option [value]="timeUnit.Month">Month</mat-option>
    </mat-select>
  </div>

  <ng-container *ngIf="form.get('unit').value === timeUnit.Week">
    <div class="section">
      <mat-label>First day of week:</mat-label>
      <mat-select class="selector" formControlName="startWeek">
        <mat-option [value]="weekDays.Mon">Monday</mat-option>
        <mat-option [value]="weekDays.Tue">Tuesday</mat-option>
        <mat-option [value]="weekDays.Wed">Wednesday</mat-option>
        <mat-option [value]="weekDays.Thu">Thursday</mat-option>
        <mat-option [value]="weekDays.Fri">Friday</mat-option>
        <mat-option [value]="weekDays.Sat">Saturday</mat-option>
        <mat-option [value]="weekDays.Sun">Sunday</mat-option>
      </mat-select>
    </div>
    <div class="section">
      <mat-label>Weekdays:</mat-label>
      <ui-week-day-picker
        [selectedWeekDays]="form.get('selectedWeekDays').value"
        [startWeek]="form.get('startWeek').value"
        (onDaySelected)="selectWeekDay($event)"
      >
      </ui-week-day-picker>
    </div>
  </ng-container>

  <div class="section">
    <mat-label>Between hours:</mat-label>
    <div fxLayout="flex" fxLayoutAlign="space-between center">
      <input formControlName="start" type="time" class="selector half-size" />
      <div>To: <input formControlName="end" type="time" class="selector half-size" /></div>
    </div>
  </div>
</div>
