import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';

@Component({
  selector: 'app-thumbnail-layouts-selector',
  templateUrl: './thumbnail-layouts-selector.component.html',
  styleUrls: ['./thumbnail-layouts-selector.component.scss'],
})
export class ThumbnailLayoutsSelectorComponent implements OnInit {

  public WallLayout = WallV2Model.WallLayout;
  @Output() selectedLayout = new EventEmitter<WallV2Model.WallLayout>();

  @Input() thumbnail = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  select(layout: WallV2Model.WallLayout) {
    this.selectedLayout.emit(layout);
  }

}
