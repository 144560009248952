<div fxLayoutAlign="center center" fxFill>
  <div *rbacAllow="['user']" fxLayout="column" fxLayoutAlign="center center" class="wall-wrapper">
    <div fxLayout="row">
      <player [src]="cam1source" fxFlex="33"></player>
      <player [src]="cam1source" fxFlex="33"></player>
      <player [src]="cam1source" fxFlex="33"></player>
      <!-- <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player> -->
    </div>
    <!-- <div fxLayout="row">
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
        </div>
        <div fxLayout="row">
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
            <player source="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" fxFlex="33"></player>
        </div> -->
  </div>

  <div *rbacAllow="['operator']" fxLayout="column" fxLayoutAlign="center center" class="wall-wrapper">OPERATOR PLACE</div>
</div>
