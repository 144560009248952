import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as Plyr from 'plyr';
import Hls, { ErrorData, HlsConfig } from 'hls.js';
import { MediaEvents } from '../player/player.component';
import { UiAreaSelection } from '../../shared/ui-kit/ui-area-selector/ui-area-selector.component';
import { Point } from '@angular/cdk/drag-drop';

@Component({
  selector: 'stream-player',
  templateUrl: './stream-player.component.html',
  styleUrls: ['./stream-player.component.scss'],
})
export class StreamPlayerComponent implements OnInit {

  @ViewChild('wrapper')
  wrapper: ElementRef;

  @ViewChild('player', { static: true })
  playerObj: ElementRef;

  @Output()
  mediaEvents: EventEmitter<MediaEvents> = new EventEmitter<MediaEvents>();

  @Input()
  playback = false;

  @Input()
  src;

  @Input()
  allowZoom = false;

  @Input()
  showZoomButtons = true;

  @Input()
  defaultRatio = true;

  @Output()
  error: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  timeUpdate: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  playing: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  autostart = true;

  public inactive = true;

  player: Plyr;
  public video;

  hls: Hls;

  restartCount = 0;

  stopped: false;
  // Initializing values
  isPlaying = false;

  scale = 1;
  initX = 0;
  initY = 0;
  moveX = 0;
  moveY = 0;
  boundryX = 0;
  boundryY = 0;

  dragging = false;

  origHeight = 0;
  origWidth = 0;
  public ratio = 0;

  @ViewChild('preview')
  previewWrapper: ElementRef;

  @ViewChild('previewCanvas')
  previewCanvas: ElementRef;

  ctx: CanvasRenderingContext2D;

  lastDuration;
  lastStartPosition;
  autoRecoverError = true;
  recoverDecodingErrorDate;
  recoverSwapAudioCodecDate;

  loader = false;

  public checkPlayingInterval;

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.scale > 1 && !!this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
  }

  public setSrc(src: string) {
    this.src = src;
  }

  public getPreviewCanvas() {
    return this.previewCanvas;
  }

  ngOnInit(): void {
    this.calcBoundaries();
  }

  public async play() {
    await this.playVid();
  }

  ngAfterViewInit(): void {
    this.initPlayer();

    if (this.autostart) {
      this.reload();
    }

    const video = this.playerObj.nativeElement;

    video.addEventListener('playing', event => {
      this.loader = false;
      this.lastStartPosition = event.target.currentTime;
      const message = 'player emit playing event';
      this.playing.emit('Playing');
      this.mediaEvents.emit({
        event: 'playing',
        playing: true,
        error: false,
        message,
      });
    });

    video.onplaying = function() {
      this.isPlaying = true;
    };

    video.onpause = function() {
      this.isPlaying = false;
    };

    if (this.allowZoom) {
      this.ctx = this.previewCanvas?.nativeElement?.getContext('2d');
      video.addEventListener('play', () => {
        this.timerCallback();
      });
    }

    this.onResize();
  }

  public async playVid() {
    const video = this.playerObj.nativeElement;
    if (video.paused) {
      this.isPlaying = true;
      return video.play();
    }
  }

  public pauseVid() {
    const video = this.playerObj.nativeElement;
    if (!video.paused) {
      this.isPlaying = false;
      video.pause();
    }
  }

  private setPlayerOptions() {
    const options: Plyr.Options = {
      hideControls: true,
      clickToPlay: false,
    };

    if (this.defaultRatio) {
      options.ratio = '16:9';
    }

    this.player = new Plyr(this.playerObj.nativeElement, options);

    // Remove player controls
    this.player.elements.controls.style.display = 'none';
  }

  private subscribeToPlayerEvents() {
    this.player.on('play', event => {
      const message = 'player emit play event';
      this.mediaEvents.emit({
        event: 'play',
        message,
      });
    });

    this.player.on('enterfullscreen', event => {
      const message = 'player emit enterfullscreen event';

      const elem = this.playerObj.nativeElement;
      this.renderer.setStyle(elem, 'transform', `scale(1)`);

      this.mediaEvents.emit({
        event: 'enterfullscreen',
        message,
      });
    });

    this.player.on('exitfullscreen', event => {
      const message = 'player emit exitfullscreen event';

      const elem = this.playerObj.nativeElement;
      this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);

      this.mediaEvents.emit({
        event: 'exitfullscreen',
        message,
      });
    });

    this.player.on('playing', event => {
      const message = 'player emit playing event';

      this.playing.emit('Player playing event');
      this.isPlaying = true;

      this.mediaEvents.emit({
        event: 'playing',
        playing: true,
        error: false,
        message,
      });
    });

    this.player.on('timeupdate', event => {
    });

    this.player.on('canplay', event => {
      const message = 'player emit canplay event';
    });

    this.player.on('canplaythrough', event => {
      const message = 'player emit canplaythrough event';

      this.mediaEvents.emit({
        event: 'canplaythrough',
        message,
      });
    });

    this.player.on('waiting', event => {
      const message = 'player emit waiting event';

      this.mediaEvents.emit({
        event: 'waiting',
        message,
      });
    });

    this.player.on('stalled', event => {
      const message = 'player emit waiting event';

      this.mediaEvents.emit({
        event: 'stalled',
        message,
      });
    });
  }

  private unsubscribeFromPlayerEvents() {
    console.log('unsebscribing from player events');

    this.player.off('play', e => {
      console.log('removed listener for player play event');
    });
    this.player.off('enterfullscreen', e => {
      console.log('removed listener for player enterfullscreen event');
    });
    this.player.off('exitfullscreen', e => {
      console.log('removed listener for player exitfullscreen event');
    });
    this.player.off('playing', e => {
      console.log('removed listener for player playing event');
    });
    this.player.off('canplay', e => {
      console.log('removed listener for player canplay event');
    });
    this.player.off('canplaythrough', e => {
      console.log('removed listener for player canplaythrough event');
    });
    this.player.off('waiting', e => {
      console.log('removed listener for player waiting event');
    });
    this.player.off('stalled', e => {
      console.log('removed listener for player stalled event');
    });
    this.player.off('error', e => {
      console.log('removed listener for player error event');
    });
    this.player.off('timeupdate', e => {
      console.log('removed listener for player timeupdate event');
    });
  }

  initPlayer() {
    this.video = this.playerObj.nativeElement as HTMLVideoElement;
    this.video.muted = true;
    this.video.controls = false;
  }

  public stopPlayer() {
    if (!!this.player) {
      this.unsubscribeFromPlayerEvents();
      this.player.stop();
      this.player.destroy();
    }
  }

  ngOnDestroy(): void {
    this.stopHls();
    this.stopPlayer();
    clearInterval(this.checkPlayingInterval);
  }

  public stop() {
    this.pauseVid();
  }

  public destroyHls() {
    this.player.stop();
    this.hls?.destroy();
  }

  async playerPause() {
    await this.player.pause();
  }

  async playerPlay() {
    await this.player.play();
    await this.playVid();
  }

  public restart() {
    const video = this.playerObj.nativeElement;
    if (!!this.hls) {
      this.hls.recoverMediaError();
    } else {
      const config: Partial<HlsConfig> = this.playback
        ? {
          backBufferLength: 300,
        }
        : {
          lowLatencyMode: true,
          liveSyncDurationCount: 0,
          liveMaxLatencyDurationCount: 1,
          initialLiveManifestSize: 1,
          backBufferLength: 0,
        };
      this.hls = new Hls(config);
      this.hls.loadSource(this.src);
      this.hls.attachMedia(video);
    }
  }

  public recover() {
    if (!!this.hls) {
      this.hls.recoverMediaError();
      this.play();
    }
  }

  emitError(error) {
    this.error?.emit();
  }

  public resetTime() {
  }

  public resetZoom() {
    this.scale = 1;
    this.zoom(undefined, true);
  }

  public async load() {
    if (this.hls) {
      this.hls.destroy();
    }
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    this.pauseVid();
    this.stop();
    try {
      if (Hls.isSupported() && !!this.src) {
        const config = this.playback
          ? {
            lowLatencyMode: true,
            backBufferLength: 300,
          }
          : {
            lowLatencyMode: true,
            liveSyncDurationCount: 0,
            liveMaxLatencyDurationCount: 1,
            initialLiveManifestSize: 1,
            backBufferLength: 0,
          };
        this.hls = new Hls(config);
        try {
          this.hls.loadSource(this.src);
        } catch (e) {
        }
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, async () => {
          await this.playVid();
          this.player.on('error', this.emitError);
          this.player.on('timeupdate', event => {
            this.timeUpdate.emit(this.player.currentTime);
          });
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.src;
        video.addEventListener('loadedmetadata', async () => {
        });
      }
    } catch (e) {
      console.log('Error loading HLS', e);
    }
  }

  public stopHls() {
    if (!!this.hls) {
      console.log('desroying hls');
      this.hls.off(Hls.Events.ERROR);
      this.hls.off(Hls.Events.MEDIA_ATTACHED);
      this.hls.stopLoad();
      this.hls.destroy();
      this.hls = null;
    }
  }

  public async reload() {
    const video: HTMLVideoElement = this.playerObj.nativeElement;

    try {
      if (Hls.isSupported() && !!this.src) {
        try {
          if (!!this.hls) {
            this.stopHls();
          }

          console.log(`attempting to start HLS with source ${this.src}. Attempt: ${this.restartCount}`);

          const config = this.playback
            ? {
              backBufferLength: 300,
            }
            : {
              enableWorker: true,
              lowLatencyMode: true,
              liveSyncDurationCount: 2,
              liveMaxLatencyDurationCount: 3,
              initialLiveManifestSize: 1,
              backBufferLength: 0,
              highBufferWatchdogPeriod: 1,
              liveDurationInfinity: true,
            };

          this.hls = new Hls(config);

          this.hls.attachMedia(video);

          this.hls.loadSource(this.src);
        } catch (e) {
        }

        this.handleHlsErrors();
        this.handleManifestParsed();
        this.handleMediaAttached();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.src;
        video.addEventListener('loadedmetadata', async () => {
        });
      }
    } catch (e) {
    }
  }

  handleMediaAttached() {
    this.hls.on(Hls.Events.MEDIA_ATTACHED, async () => {
    });
  }

  progress() {
    if (this.video.paused) {
      return;
    }
  }

  handleManifestParsed() {
    this.hls.on(Hls.Events.MANIFEST_PARSED, async (event, data) => {
      if (!!this.player) {
        this.player.muted = true;
        if (this.playback) {
          this.player.on('timeupdate', event => {
            this.timeUpdate.emit(this.player.currentTime);
          });
        }
        if (this.player?.paused) {
          this.player.play();
        }
      }

      this.playVid();
      if (!!this.checkPlayingInterval) {
        clearInterval(this.checkPlayingInterval);
      }
      this.checkPlayingInterval = setInterval(() => {
        if (this.video.currentTime > 0) {
          clearInterval(this.checkPlayingInterval);
        } else {
          this.mediaEvents.emit({ event: 'videoNotStartedError' });
        }
      }, 1000);
    });
  }

  handleHlsErrors() {
    this.hls.on(Hls.Events.ERROR, (eventName, data: any) => {
      console.log('Stream Error: ' + this.src);
      console.warn('Error event:', data);
      if (!data.buffer) {
        console.log('SHOULD GET STUCK NOW...');
        this.mediaEvents.emit({
          event: 'freeze',
          playing: true,
          error: false,
        });
        this.loader = false;
      }
      switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
          try {
            console.error('Cannot load ' + data.context.url);

            if (data.response.code === 0) {
              console.error('This might be a CORS issue');
            }
          } catch (err) {
            console.error('Cannot load ' + data.context.url);
          }
          break;
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
          console.error('Timeout while loading manifest');
          break;
        case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
          console.error('Error while parsing manifest:' + data.reason);
          break;
        case Hls.ErrorDetails.LEVEL_EMPTY_ERROR:
          console.error('Loaded level contains no fragments ' + data.level + ' ' + data.url);
          break;
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
          console.error('Error while loading level playlist ' + data.context.level + ' ' + data.url);
          break;
        case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
          console.error('Timeout while loading level playlist ' + data.context.level + ' ' + data.url);
          break;
        case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
          console.error('Error while trying to switch to level ' + data.level);
          break;
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
          console.error('Error while loading fragment ' + data.frag.url);
          break;
        case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
          console.error('Timeout while loading fragment ' + data.frag.url);
          break;
        case Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
          console.error('Decrypting error:' + data.reason);
          break;
        case Hls.ErrorDetails.FRAG_PARSING_ERROR:
          console.error('Parsing error:' + data.reason);
          break;
        case Hls.ErrorDetails.KEY_LOAD_ERROR:
          console.error('Error while loading key ' + data.frag.decryptdata.uri);
          break;
        case Hls.ErrorDetails.KEY_LOAD_TIMEOUT:
          console.error('Timeout while loading key ' + data.frag.decryptdata.uri);
          break;
        case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
          console.error('Buffer append error');
          break;
        case Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR:
          console.error('Buffer add codec error for ' + data.mimeType + ':' + data.error.message);
          break;
        case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
          console.error('Buffer appending error');
          break;
        case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
          console.error('Buffer stalled error');
          if (data.details === 'bufferNudgeOnStall' && !data.buffer) {
            this.video.currentTime = this.video.buffered.start(0);
          }
          break;
        default:
          break;
      }

      if (data.fatal) {
        console.error(`Fatal error : ${data.details}`);
        switch (data.type) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error(`A media error occurred: ${data.details}`);
            this.handleMediaError();
            break;
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error(`A network error occurred: ${data.details}`);
            break;
          default:
            console.error(`An unrecoverable error occurred: ${data.details}`);
            this.hls.destroy();
            break;
        }
      }
    });
  }

  calcBoundaries() {
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];
    const ratio = elem.videoWidth / elem.videoHeight;
    const height = this.origHeight * this.scale;
    const width = this.origWidth * this.scale;
    this.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.scale : 0;
    this.boundryY = (height - elem.clientHeight) / 2 / this.scale;
  }

  public initZoom() {
    const elem = this.playerObj.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }

  public zoomIn() {
    this.zoom({ deltaY: -1 });
    this.computeFrame();
  }

  public zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  public zoomArea(area: UiAreaSelection) {
    this.initZoom();
    const wrapper = this.wrapper.nativeElement;
    const topLeft: Point = {
      x: Math.min(area.start.x, area.end.x),
      y: Math.min(area.start.y, area.end.y),
    };
    const width = Math.abs(area.start.x - area.end.x);
    const height = this.ratio * width;
    if (width <= 0 || height <= 0) {
      return;
    }
    this.scale = wrapper.clientWidth / width;
    const elem = this.playerObj.nativeElement;
    const bounds = elem.getBoundingClientRect();

    this.renderer.setStyle(elem, 'transform', `scale(${this.scale})`);
    if (this.scale !== 1) {
      this.calcBoundaries();
    }
    this.moveX = this.boundryX - topLeft.x;
    this.moveY = this.boundryY - topLeft.y;

    this.zoom(undefined, true);
  }

  public zoom(event, renderOnly = false) {
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj.nativeElement;
    if (!renderOnly) {
      if (event.deltaY < 0) {
        if (this.scale < 5) {
          this.scale += 0.2;
        } else {
          this.scale = 5;
        }
        this.onResize();
        this.cd.detectChanges();
      } else {
        if (this.scale >= 1.2) {
          this.scale -= 0.2;
        } else {
          this.scale = 1;
        }
      }
    }
    if (this.scale === 1) {
      this.moveX = 0;
      this.moveY = 0;
      this.boundryX = 0;
      this.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
    if (this.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.moveX);
    const moveYDir = Math.sign(this.moveY);
    this.moveX = Math.abs(this.moveX) > this.boundryX ? this.boundryX * moveXDir : this.moveX;
    this.moveY = Math.abs(this.moveY) > this.boundryY ? this.boundryY * moveYDir : this.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
  }

  dragStart(event: MouseEvent) {
    this.dragging = true;
    this.initX = event.clientX - this.moveX;
    this.initY = event.clientY - this.moveY;
  }

  drag(event: MouseEvent) {
    if (this.scale === 1 || !this.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    const height = rect.height;
    const width = rect.height * ratio;
    this.moveX += Math.abs(this.moveX + event.movementX) >= this.boundryX ? 0 : event.movementX;
    this.moveY += Math.abs(this.moveY + event.movementY) >= this.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`);
  }

  public maximize() {
    if (!!this.video.requestFullscreen) {
      this.video.requestFullscreen();
    } else if (!!this.video.mozRequestFullScreen) {
      this.video.mozRequestFullScreen();
    } else if (!!this.video.webkitRequestFullscreen) {
      this.video.webkitRequestFullscreen();
    }
  }

  timerCallback() {
    if (this.playerObj.nativeElement.paused || this.playerObj.nativeElement.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 0);
  }

  computeFrame() {
    this.ctx.drawImage(this.playerObj.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
  }

  public inZoom() {
    return this.scale > 1;
  }

  addVideoEventListeners() {
    const video = this.video;
    video.removeEventListener('resize', this.handleVideoEvent);
    video.removeEventListener('seeking', this.handleVideoEvent);
    video.removeEventListener('seeked', this.handleVideoEvent);
    video.removeEventListener('pause', this.handleVideoEvent);
    video.removeEventListener('play', this.handleVideoEvent);
    video.removeEventListener('canplay', this.handleVideoEvent);
    video.removeEventListener('canplaythrough', this.handleVideoEvent);
    video.removeEventListener('ended', this.handleVideoEvent);
    video.removeEventListener('playing', this.handleVideoEvent);
    video.removeEventListener('error', this.handleVideoEvent);
    video.removeEventListener('loadedmetadata', this.handleVideoEvent);
    video.removeEventListener('loadeddata', this.handleVideoEvent);
    video.removeEventListener('durationchange', this.handleVideoEvent);
    video.addEventListener('resize', this.handleVideoEvent);
    video.addEventListener('seeking', this.handleVideoEvent);
    video.addEventListener('seeked', this.handleVideoEvent);
    video.addEventListener('pause', this.handleVideoEvent);
    video.addEventListener('play', this.handleVideoEvent);
    video.addEventListener('canplay', this.handleVideoEvent);
    video.addEventListener('canplaythrough', this.handleVideoEvent);
    video.addEventListener('ended', this.handleVideoEvent);
    video.addEventListener('playing', this.handleVideoEvent);
    video.addEventListener('error', this.handleVideoEvent);
    video.addEventListener('loadedmetadata', this.handleVideoEvent);
    video.addEventListener('loadeddata', this.handleVideoEvent);
    video.addEventListener('durationchange', this.handleVideoEvent);
  }

  handleVideoEvent(evt) {
    let data: number | string = '';
    // @ts-ignore
    switch (evt.type) {
      case 'durationchange':
        if (evt.target.duration - this.lastDuration <= 0.5) {
          // some browsers report several duration change events with almost the same value ... avoid spamming video events
          return;
        }
        this.lastDuration = evt.target.duration;
        data = Math.round(evt.target.duration * 1000);
        break;
      case 'loadedmetadata':
      case 'loadeddata':
      case 'canplay':
      case 'canplaythrough':
      case 'ended':
      case 'seeking':
      case 'seeked':
      case 'play':
      case 'playing':
        this.lastStartPosition = evt.target.currentTime;
        break;
      case 'pause':
      case 'waiting':
      case 'stalled':
      case 'error':
        data = Math.round(evt.target.currentTime * 1000);
        if (evt.type === 'error') {
          let errorTxt;
          const mediaError = evt.currentTarget.error;
          switch (mediaError.code) {
            case mediaError.MEDIA_ERR_ABORTED:
              errorTxt = 'You aborted the video playback';
              break;
            case mediaError.MEDIA_ERR_DECODE:
              errorTxt =
                'The video playback was aborted due to a corruption problem or because the video used features your browser did not support';
              this.handleMediaError();
              break;
            case mediaError.MEDIA_ERR_NETWORK:
              errorTxt = 'A network error caused the video download to fail part-way';
              break;
            case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorTxt =
                'The video could not be loaded, either because the server or network failed or because the format is not supported';
              break;
          }

          if (mediaError.message) {
            errorTxt += ' - ' + mediaError.message;
          }

          console.error(errorTxt);
        }
        break;
      default:
        break;
    }
  }

  public takeSnapshot(cameraName: string, locationName: string, ts?: number) {
    let downloadLink = document.createElement('a');
    const time = ts ? new Date(ts).toTimeString() : new Date().toTimeString();
    const playerObj = this.playerObj.nativeElement;
    this.ratio = playerObj.videoWidth / playerObj.videoHeight;
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    let canvas = document.createElement('canvas');
    const width = Math.max(this.origWidth, playerObj.videoWidth);
    const height = Math.max(this.origHeight, playerObj.videoHeight);
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')
      .drawImage(playerObj, 0, 0, width, height);
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(15, 15, 360, 100);
    ctx.font = '25px Arial';
    ctx.fillStyle = '#ffffff';
    ctx.fillText(cameraName, 30, 50);
    ctx.font = '15px Arial';
    ctx.fillText(locationName, 30, 75);
    ctx.fillText(time, 30, 100);
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('download', `[Lumana] ${cameraName} ${time}.png`);
      downloadLink.click();
      downloadLink.remove();
      canvas.remove();
    });
  }

  handleMediaError() {
    if (this.autoRecoverError) {
      const now = self.performance.now();
      if (!this.recoverDecodingErrorDate || now - this.recoverDecodingErrorDate > 3000) {
        this.recoverDecodingErrorDate = self.performance.now();
        console.log(', trying to recover media error.');
        this.hls.recoverMediaError();
      } else {
        if (!this.recoverSwapAudioCodecDate || now - this.recoverSwapAudioCodecDate > 3000) {
          this.recoverSwapAudioCodecDate = self.performance.now();
          console.log(', trying to swap audio codec and recover media error.');
          this.hls.swapAudioCodec();
          this.hls.recoverMediaError();
        } else {
          console.log(', cannot recover. Last media error recovery failed.');
        }
      }
    }
  }
}
