import { createAction, props } from '@ngrx/store';
import { Archive } from '@models/archive.model';
import { UserOrganizationDropDown } from '@models/organization.model';
import { Playback } from '../../../cameras/playback.model';
import { GrantedAccessModel } from '@models/granted-access-model';
import ArchiveDocument = Archive.ArchiveDocument;
import { HttpStatusCode } from '@angular/common/http';

export const resetToInitialState = createAction('[ARCHIVE] Reset to Initial state');

export const resetSelectedArchive = createAction('[ARCHIVE] Reset Selected Archive');

export const removeTags = createAction(
  '[ARCHIVE] Remove Filter',
  props<{ value: any }>(),
);

export const changeArchiveProperty = createAction('[ARCHIVE] Change Archive Property', props<{ property: string; value: any }>());

export const changeGrantedAccessParams = createAction('[ARCHIVE] Change Granted Access Params', props<{ property: string; value: any }>());

export const createArchive = createAction('[ARCHIVE] Create Archive');


export const setValid = createAction('[ARCHIVE] Set Archive Valid', props<{ isArchiveValid: boolean }>());

export const startGetArchiveList = createAction('[ARCHIVE] Start Get Archive List');
export const getArchiveList = createAction('[ARCHIVE] Get Archive List');

export const getArchiveListSuccess = createAction(
  '[ARCHIVE] Get Archive List Success',
  props<{ archives: Archive.ArchiveDocument[]; totalItemsCount: number }>(),
);

export const setPaginationParams = createAction('[ARCHIVE] Set Pagination Params', props<{ perPage: number; page: number }>());

export const updateArchiveProgress = createAction('[ARCHIVE] Update Archive Progress', props<{ archive: Archive.UpdateArchiveDownload }>());

export const setFilter = createAction('[ARCHIVE] Set Filter', props<{ filter: string; value: any }>());

export const rmFilter = createAction('[ARCHIVE] Rm Filter', props<{ filter: string; value: any }>());

export const getOrgUsersAutocomplete = createAction('[ARCHIVE] Get User Autocomplete');

export const setUserAutocomplete = createAction('[ARCHIVE] Set User Autocomplete', props<{ users: UserOrganizationDropDown[] }>());

export const setNotificationMessage = createAction('[ARCHIVE] Set Notification Message', props<{ notificationMessage: string }>());

export const deleteArchive = createAction('[ARCHIVE] Delete Archive', props<{ sessionId: string }>());
export const deleteArchiveSuccess = createAction('[ARCHIVE] Delete Archive Success', props<{ sessionId: string }>());

export const downloadArchive = createAction('[ARCHIVE] Download Archive', props<{ archive: Archive.ArchiveDocument }>());
export const downloadArchiveFinished = createAction('[ARCHIVE] Download Archive finished', props<{ archive: Archive.ArchiveDocument }>());
export const retry = createAction('[ARCHIVE] Retry Archive', props<{ archive: ArchiveDocument }>());

export const updateAddArchiveModalConfig = createAction(
  '[ARCHIVE] Update Add Archive Modal Config',
  props<{ property: string; disable: boolean }>(),
);

export const sendArchiveToServer = createAction(
  '[ARCHIVE] Send Archive to the Server',
  props<{
    archive: Playback.PlaybackDownloadRequest;
    grantedAccess: GrantedAccessModel.GrantedAccessModelRequest;
  }>(),
);

export const sendArchiveToServerSuccess = createAction(
  '[ARCHIVE] Send Archive to the Server Success',
  props<{
    filename: string,
    sessionId: string,
    archiveId: string, // !!!IMPORTANT, for search archive in store use SESSION ID
    entityParams: GrantedAccessModel.EntityParams
  }>(),
);

export const sendArchiveToServerFail = createAction(
  '[ARCHIVE] Send Archive to the Server Fail',
);

export const updateArchive = createAction('[ARCHIVE] Update Archive', props<{ archive: Archive.ArchiveDocument }>());

export const setSelectedCameraId = createAction('[ARCHIVE] Set Selected Camera', props<{ cameraId: string }>());

export const resetArchives = createAction('[ARCHIVE] Reset Archives');
export const setIsLoading = createAction('[ARCHIVE] Set Is Loading ', props<{ isLoading: boolean }>());

export const retrySuccess = createAction('[ARCHIVE] Retry Archive Success', props<{ archiveUpdate: Archive.UpdateArchiveDownload }>());

export const archiveProgressSocketUpdate = createAction('[ARCHIVE] Archive Progress Progress Socket Update', props<{ archive: Archive.ArchiveDocument, error: string, statusCode: HttpStatusCode }>());
export const archiveSockedReceived = createAction('[ARCHIVE] Archive Socket Received', props<{ archive: Archive.ArchiveDocument }>());

export const downloadArchiveSuccess = createAction('[ARCHIVE] Download Archive Success', props<{ url: string, name: string }>());
