import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment-timezone';
import { UiDatetimeRangePickerModel } from '../ui-datetime-range-picker/ui-datetime-range-picker.model';
import { MatCalendar } from '@angular/material/datepicker';
import { UiCalendarBase } from '../../../helpers/ui-calendar-base.component';
import { UiCalendarPickerType } from '@enums/shared.enum';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { BehaviorSubject, Observable, shareReplay, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

enum UiCalendarValidationError {
  startAfterEnd,
  dateAfterNow
}

export declare interface RelativeTime {
  value: number;
  unit: CustomUnit;
}

export declare interface OnRangeSelectedResult {
  type: UiCalendarPickerType;
  relative: RelativeTime;
  absolute: { start: string | number; end: string | number, timezone?: string };
  startBetween?: string;
  endBetween?: string;
  selectedDays?: { [key: string]: boolean };
}

@UntilDestroy()
@Component({
  selector: 'ui-calendar-inline',
  templateUrl: './ui-calendar-inline.component.html',
  styleUrls: ['./ui-calendar-inline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiCalendarInlineComponent extends UiCalendarBase implements OnChanges, OnInit {

  @Input()
  timezone: string;

  @Input()
  public relativeEnabled: boolean = true;

  @Input()
  public absoluteEnabled: boolean = false;

  @ViewChild('leftCalendar')
  private leftCalendar: MatCalendar<any>;
  /**
   * @deprecated
   */
  public startDateView: string;
  /**
   * @deprecated
   */
  public endDateView: string;
  public startDateLeftCalendar: Date;

  public pickerType = UiCalendarPickerType;
  public validationErrors = UiCalendarValidationError;
  public startDateError$: BehaviorSubject<UiCalendarValidationError> = new BehaviorSubject<UiCalendarValidationError>(null);
  public endDateError$: BehaviorSubject<UiCalendarValidationError> = new BehaviorSubject<UiCalendarValidationError>(null);

  CustomUnit: typeof UiDatetimeRangePickerModel.CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
  customUnits = Object.values(this.CustomUnit);

  public selectMaxRetentionDays$: Observable<number> = this.store$.pipe(select(OrganizationSelectors.selectMaxRetentionDays));


  public minDate: Date;
  public maxDate: Date;

  constructor(private store$: Store) {
    super();
  }

  public get maxEndtime() {
    return moment(this.now)
      .subtract(2, 'minutes')
      .format('HH:mm');
  }

  public get isEndToday() {
    const defaultDateEnd = moment()
      .format('YYYY-MM-DD');
    return moment(this.endDate)
      .format('YYYY-MM-DD') === defaultDateEnd;
  }

  public timezoneAbbreviation(): string {
    if (this.timezone) {
      return moment()
        .tz(this.timezone)
        .format('z');
    }
    return moment()
      .tz(moment.tz.guess())
      .format('z');
  }

  public changeType(pickerType: UiCalendarPickerType): void {
    this.selectedPickerType = pickerType;
    this.pickerState.custom = 3;
    this.pickerState.customUnit = CustomUnit.days;
    this.emitDateRangeChanged();
  }


  public override dateChanged(ev: Date) {
    super.dateChanged(ev);
    this.leftCalendar.updateTodaysDate();
  }

  public emitAbsoluteDate() {
    const startTimeSplit = this.startTime.split(':');
    let endTimeSplit = this.endTime.split(':');

    if (this.isEndToday) {
      const maxEndtimeSplit = this.maxEndtime.split(':');

      const maxEndHours = maxEndtimeSplit[0];
      const maxEndMinutes = maxEndtimeSplit[1];

      const endHours = endTimeSplit[0];
      const endMinutes = endTimeSplit[1];


      if (endHours > maxEndHours) {
        this.endTime = this.maxEndtime;
        endTimeSplit = this.endTime.split(':');
      }

      if (endHours === maxEndHours) {

        if (endMinutes >= maxEndMinutes) {
          this.endTime = this.maxEndtime;
          endTimeSplit = this.endTime.split(':');
        }

      }

    }

    this.startDate = moment(this.startDate)
      .hours(0)
      .toDate();
    this.startDate = moment(this.startDate)
      .minutes(0)
      .toDate();

    const outputStartDateHrs = moment(this.startDate)
      .add(startTimeSplit[0], 'hours');
    const outputStartDateMinutes = outputStartDateHrs.add(startTimeSplit[1], 'minutes');
    const origEnd = `${this.endDate}`;
    this.endDate = moment(this.endDate)
      .hours(0)
      .toDate();
    this.endDate = moment(this.endDate)
      .minutes(0)
      .toDate();
    // Handle daylight saving time switches
    const origDST = moment(origEnd)
      .isDST();
    const endDST = moment(this.endDate)
      .isDST();

    let dstDelta = 0;
    if (origDST === false && endDST === true) {
      dstDelta = 1;
    }
    if (origDST === true && endDST === false) {
      dstDelta = -1;
    }

    const outputEndDateHrs = moment(this.endDate)
      .add(+endTimeSplit[0] + dstDelta, 'hours');

    const outputEndDateMinutes = outputEndDateHrs.add(endTimeSplit[1], 'minutes');

    this.startDateView = outputStartDateMinutes.format('MMM, DD. h:mm A');
    this.endDateView = outputEndDateMinutes.format('MMM, DD. h:mm A');
    const value = {
      type: UiCalendarPickerType.ABSOLUTE,
      absolute: {
        start: outputStartDateMinutes.format('YYYY-MM-DD HH:mm'),
        end: outputEndDateMinutes.format('YYYY-MM-DD HH:mm'),
      },
      relative: null,
    };
    let isStartValid = true;
    let isEndValid = true;
    if (outputStartDateMinutes.isAfter(outputEndDateMinutes)) {
      this.startDateError$.next(UiCalendarValidationError.startAfterEnd);
      isStartValid = false;
    }
    if (outputStartDateMinutes.isAfter(moment())) {
      isStartValid = false;
      this.startDateError$.next(UiCalendarValidationError.dateAfterNow);
    }

    if (isStartValid) {
      this.startDateError$.next(null);
    }

    if (outputEndDateMinutes.isAfter(moment())) {
      this.endDateError$.next(UiCalendarValidationError.dateAfterNow);
      isEndValid = false;
    } else {
      this.endDateError$.next(null);
    }
    if (isStartValid && isEndValid) {
      this.onRangeSelected.emit(value);
    }
  }


  public changeEndDate(ev: Event) {
    const value = ev.target['value'];
    if (!value) {
      ev.preventDefault();
      return;
    }
    const currentMonth = moment(this.endDate)
      .month();
    const newMonth = moment(value)
      .month();
    this.endDate = moment(value)
      .toDate();
    this.generateSelectedRange();
    if (currentMonth !== newMonth) {
      this.leftCalendar._goToDateInView(this.endDate, 'month');
    }
    this.leftCalendar.updateTodaysDate();
    this.emitDateRangeChanged();

  }


  public changeStartDate(ev: Event) {
    const value = ev.target['value'];
    if (!value) {
      ev.preventDefault();
      return;
    }
    const currentMonth = moment(this.startDate)
      .month();
    const newMonth = moment(value)
      .month();
    this.startDate = moment(value)
      .toDate();
    if (currentMonth !== newMonth) {
      this.leftCalendar._goToDateInView(this.startDate, 'month');
    }
    this.generateSelectedRange();
    this.leftCalendar.updateTodaysDate();
    this.emitDateRangeChanged();
  }

  ngOnInit(): void {
    this.maxDate = moment()
      .toDate();
    this.selectMaxRetentionDays$.pipe(untilDestroyed(this), shareReplay(), take(1))
      .subscribe(
        maxRetentionDays => {
          this.minDate = moment()
            .subtract(maxRetentionDays, 'days')
            .toDate();
        },
      );
  }
}
