import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface UiCounterOptions {
  min: number;
  max: number;
  step: number;
}

@Component({
  selector: 'ui-counter',
  templateUrl: './ui-counter.component.html',
  styleUrls: ['./ui-counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiCounterComponent),
      multi: true,
    },
  ],
})
export class UiCounterComponent implements ControlValueAccessor {

  @ViewChild('input') input: ElementRef;
  @Input() options: UiCounterOptions = { min: 0, max: 100, step: 1 };
  @Input() unit: string;
  value: number = 0;

  isDisabled: boolean = false;

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
      this.onChange(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  increment(): void {
    if (this.value < this.options.max) {
      this.value += this.options.step;
      this.onChange(this.value);
    }
  }

  decrement(): void {
    if (this.value > this.options.min) {
      this.value -= this.options.step;
      this.onChange(this.value);
    }
  }

  changeValue() {
    if (this.value < this.options.min) {
      this.value = this.options.min;
    }
    if (this.value > this.options.max) {
      this.value = this.options.max;
    }
    // if (!this.value && this.value !== 0) {
    //   this.value = 0;
    // }
    this.onChange(this.value ?? 0);
  }
}
