import { Injectable } from '@angular/core';
import { SocketMainService } from './socket-main.service';
import { SocketEvents } from './socket.model';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketMainConsumerService {

  constructor(
    private socketMainService: SocketMainService,
  ) { }



  getSwVersion() {
    return this.socketMainService.consume<void>(SocketEvents.edgeGetSwVersionNotification)
      .pipe(
        tap(res => console.log('getSwVersion', res))
      )
  }



}
