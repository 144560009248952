import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CameraState, selectAll, selectIds } from './camera.reducer';
import { LocationState } from '@states/location/location.reducer';
import { LocationCameraItem, UiLocationSelectItem, UiLocationSelectItemV2 } from '@models/ui.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { Dictionary } from '@ngrx/entity/src/models';

export const selectCameraState = createFeatureSelector<CameraState>('cameraState');
export const selectLocationState = createFeatureSelector<LocationState>('locationState');

export const isFirsCameraLoaded = createSelector(selectCameraState, (cameras: CameraState) => cameras.isFirstLocationEdgeCameraLoaded);

export const selectLocationLookup = createSelector(selectLocationState, ({ locationLookup }: LocationState) => locationLookup);

export const selectAllCameras = createSelector(selectCameraState, selectAll);
export const selectAllCamerasIds = createSelector(selectCameraState, selectIds);

export const selectCameraNames = createSelector(selectAllCameras, cameras => {
    const names: KeyValuePairs<string> = {};
    cameras.forEach(camera => {
      names[camera?.edgeOnly?.cameraId] = camera?.edgeOnly?.name;
    });
    return names;
  },
);

export const selectAllCamerasGroupByLocation = (edgeId: string = null) =>
  createSelector(selectCameraState, selectLocationLookup, (cameras: CameraState, locationsLookup) => {
    const searchQuery = cameras.searchQuery?.toUpperCase();
    const locations: { [key: string]: UiLocationSelectItem } = {};
    let cameraItems = Object.values(cameras.entities);
    if (edgeId) {
      cameraItems = cameraItems.filter(camera => camera.edgeId === edgeId);
    }
    cameraItems.forEach(item => {
      const locationId = item.locationId;
      const camera: LocationCameraItem = {
        edgeId: item.edgeId,
        edgeOnly: item.edgeOnly,
        locationId: item.locationId,
        snapshot: item.snapshot,
      };
      const locationObj: UiLocationSelectItem = {
        _id: locationId,
        name: locationsLookup[locationId]?.name,
        address: locationsLookup[locationId].address,
        city: locationsLookup[locationId].city,
        state: locationsLookup[locationId].state,
        cameras: [camera],
        timezone: locationsLookup[locationId].timezone,
        edges: Object.values(locationsLookup[locationId].edges)
          .map(edge => {
            return {
              name: edge.name,
              id: edge.edgeId,
              cameras: Object.values(edge.cameras ?? {}),
            };
          }),
      };
      let locationSearchOk = true;
      if (searchQuery) {
        locationSearchOk =
          locationObj.name.toUpperCase()
            .indexOf(searchQuery) !== -1 ||
          locationObj.city.toUpperCase()
            .indexOf(searchQuery) !== -1 ||
          locationObj.address.toUpperCase()
            .indexOf(searchQuery) !== -1 ||
          locationObj.state.toUpperCase()
            .indexOf(searchQuery) !== -1 ||
          locationObj.cameras[0].edgeOnly.name.toUpperCase()
            .indexOf(searchQuery) !== -1;
      }
      if (locationSearchOk) {
        if (!locations[locationId]) {
          locations[locationId] = locationObj;
        } else {
          locations[locationId].cameras.push(camera);
        }
      }
    });
    return Object.values(locations);
  });

export const selectCameraById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]);

export const selectCameraRetentionById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.edgeOnly?.retentionDays);

export const selectCameraLocationIdById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.locationId);

export const selectCameraHasSubstreamsById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => !!cameras.entities[cameraId]?.edgeOnly?.subStreams?.length);


export const selectCameraPtzById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.edgeOnly?.ptzSupport);

export const selectCamerasLookup = createSelector(selectCameraState, ({ entities }: CameraState) => entities);

export const getCameraLocationIdById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.locationId);

export const getCameraSnapshotById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.snapshot);

export const getLastVideoTimestamp = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.lastVideoTimestamp);

export const getCameraHealth = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.cameraHealth);

export const getCameraOldestVideo = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.cameraHealth?.oldestVideoTimestamp);


export const getCameraDeleting = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.deleting);

export const getDefaultCameraSnapshotById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.cloudOnly.defaultSnapshot);

export const selectCameraNameById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.edgeOnly.name);

export const selectCameraPlaybackSessionIdById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.playbackSessionId);

export const selectCameraCreatedAtById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.createdAt);


export const selectCamerasByEdgeId = (edgeId: string) =>
  createSelector(selectAllCameras, cameras => cameras.filter(e => e.edgeId?.toString() === edgeId.toString()));

export const selectCamerasMacs = createSelector(selectAllCameras, cameras => cameras.map(camera => camera?.edgeOnly?.networkDetails?.macAddress));
export const selectCamerasIps = createSelector(selectAllCameras, cameras => cameras.map(camera => camera?.edgeOnly?.networkDetails?.ipAddress));

export const selectCameraErrorCounterById = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.errorCounter);


export const selectCameraByEdgeIdAndIp = (edgeId: string, ip: string) =>
  createSelector(
    selectAllCameras,
    cameras =>
      cameras.filter(
        e => e.edgeId?.toString() === edgeId?.toString() && e.edgeOnly?.networkDetails?.ipAddress?.toString() === ip?.toString(),
      )[0],
  );

export const selectCameraByMac = (mac: string) =>
  createSelector(
    selectAllCameras,
    cameras =>
      cameras.filter(
        e => e.edgeOnly?.networkDetails?.macAddress?.toString() === mac.toString(),
      )[0],
  );

export const selectCameraIp = (ip: string, mac: string) =>
  createSelector(
    selectAllCameras,
    cameras =>
      cameras.filter(
        e => e.edgeOnly?.networkDetails?.ipAddress?.toString() === ip?.toString() && e.edgeOnly?.networkDetails?.macAddress?.toString() === mac?.toString(),
      )[0],
  );

export const selectCamerasByLocationId = (locationId: string) =>
  createSelector(selectAllCameras, cameras => cameras.filter(e => e.locationId?.toString() === locationId.toString()));

export const selectCamerasByLocationIdAndEdgeId = (locationId: string, edgeId: string) =>
  createSelector(selectAllCameras, cameras =>
    cameras.filter(e => e.locationId?.toString() === locationId.toString() && e.edgeId?.toString() === edgeId.toString()),
  );

/**
 * TODO: select camera name by cameraID, from the UI you can do async, example with firebase status
 * like
 *                             <app-status-chip [status]="(cameraStatus(camera.value.edgeOnly.cameraId) | async ) || 0">
 *                             </app-status-chip>
 */

export const selectCamerasByLocationIdsAndEdgeIds = (locationIds: string[], edgeIds: string[]) =>
  createSelector(selectAllCameras, cameras => {
    if (locationIds?.length || edgeIds?.length) {
      return cameras.filter(e => locationIds.includes(e.locationId) || edgeIds.includes(e.edgeId));
    } else {
      return cameras;
    }
  });

export const selectCamerasEventsLookup = createSelector(selectCameraState, (state: CameraState) => state.cameraEvents);

export const selectCamerasEventsArrayLookup = createSelector(selectCameraState, (state: CameraState) => {
  const lookup = state.cameraEvents;
  const result = {};
  Object.keys(lookup)
    .forEach(cameraId => {
      result[cameraId] = Object.values(lookup[cameraId]);
    });
  return result;
});

export const selectCamerasEventsArray = createSelector(selectCameraState, (state: CameraState) => {
  let result = [];
  Object.values(state.cameraEvents)
    .forEach(cameraEvent => {
      result = result.concat(Object.values(cameraEvent));
    });
  return result;
});

export const selectEventsLookup = createSelector(selectCameraState, (state: CameraState) => {
  let result = {};
  Object.values(state.cameraEvents)
    .forEach(cameraEvent => {
      result = {
        ...result,
        ...cameraEvent,
      };
    });
  return result;
});


export const selectProperFitting = createSelector(selectCameraState, (state: CameraState) => state.properFitting);
export const selectScanToken = createSelector(selectCameraState, (state: CameraState) => state.scanToken);

export const selectHlsConfig = (cameraId: string) =>
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities[cameraId]?.cloudOnly?.hlsConfig);


export const selectCamerasEntities =
  createSelector(selectCameraState, (cameras: CameraState) => cameras.entities);


