import { createAction, props } from '@ngrx/store';
import { IdentityModel } from '@models/identity.model';

export const resetToInitialState = createAction('[IDENTITY] Reset to Initial state');

export const getUserIdentities = createAction('[IDENTITY] getUserIdentities');
export const getUserIdentitiesSuccess = createAction('[IDENTITY] getUserIdentities Success', props<{ identities: IdentityModel.IdentityDocument[] }>());
export const getUserIdentitiesFail = createAction('[IDENTITY] getUserIdentities Fail');

export const startChangePassword = createAction('[IDENTITY] Start Change password', props<{ password: string; newPassword: string }>());
export const changePasswordRequest = createAction('[IDENTITY] Change password Request', props<{ password: string; newPassword: string }>());
export const changePasswordSuccess = createAction('[IDENTITY] change password Success');
export const changePasswordFail = createAction('[IDENTITY] change password fail', props<{ description: string; }>());
