<webrtc-player
  [class.hidden]="!webrtc"
  (onStreamError)="error()"
  (onError)="resetError()"
  #webrtcPlayer
  [edgeId]="camera.edgeId"
  [cameraId]="camera.cameraId"
  [locationId]="camera.locationId"
  [allowZoom]="true"
  class="w-100"
  (playing)="playing()"
  [forceRelay]="webrtcRelay"
  [enableQualitySelection]="false"
  style="height: 50vh"
  (onIsRelay)="setRelayStatus($event)"
  (onPlaying)="onPlaying()"
>
</webrtc-player>
