import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, mergeMap, share, switchMap } from 'rxjs';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import { CustomEventsService } from 'src/app/development/custom-events.service';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';

@Injectable()
export class CustomEventsEffect {

  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(CustomEventsActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public addEditCustomEventSuccess$ = createEffect(() => this.actions$.pipe(ofType(CustomEventsActions.addCustomEventSuccess, CustomEventsActions.editCustomEventSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public getCustomEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomEventsActions.getCustomEvents),
      mergeMap(() => {
          return this.customEventsService.getCustomEvents()
            .pipe(
              switchMap(res => {
                return [CustomEventsActions.getCustomEventsSuccess({ customEvents: res.customEvents }), SharedActions.setIsLoading({ isLoading: false })];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public addCustomEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomEventsActions.addCustomEvent),
      mergeMap(({ customEvent }) => {

          return this.customEventsService.addCustomEvent(customEvent)
            .pipe(
              switchMap(res => {
                return [CustomEventsActions.addCustomEventSuccess({ customEvent })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editCustomEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomEventsActions.editCustomEvent),
      mergeMap(({ customEvent }) => {

          return this.customEventsService.editCustomEvent(customEvent)
            .pipe(
              switchMap(res => {
                return [CustomEventsActions.editCustomEventSuccess({ customEvent })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removeCustomEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomEventsActions.removeCustomEvent),
      mergeMap(({ customEvent }) => {

          return this.customEventsService.removeCustomEvent(customEvent)
            .pipe(
              switchMap(res => {
                return [CustomEventsActions.removeCustomEventSuccess({ customEvent })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private customEventsService: CustomEventsService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

}
