import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel } from '../locations/location.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';

@Pipe({
  name: 'locationPulsationHealth',
})
/**
 * @deprecated
 * todo remove
 */
export class LocationPulsationHealthPipe implements PipeTransform {
  constructor() {
  }

  transform(location: LocationModel.LocationItem,
            allCameras: { timestamp: number; value: Dictionary<PulsationModels.Store.CameraPulsation> },
            allEdges: { timestamp: number; value: Dictionary<PulsationModels.Store.EdgePulsation> }): PulsationModels.ComponentStatus {

    const allCamerasList = allCameras?.value;
    const edgesList = allEdges?.value;

    const edgesCount = Object.values(location?.edges)?.length;
    const onlineArray = [];
    const offlineArray = [];
    const unhealthyArray = [];


    Object.values(location.edges)
      .forEach(res => {

        const edge = res;
        const cameras = res.cameras;

        const selectedEdge = edgesList[edge.edgeId];

        // if (!selectedEdge) {
        //   offlineArray.push(`edgeId:${edge.edgeId}`);
        //   return;
        // }

        if (!selectedEdge) {
          offlineArray.push(`edgeId:${edge.edgeId}`);
        } else if ((
            selectedEdge?.status == PulsationModels.ComponentStatus.Online) &&
          allEdges?.timestamp
          // && allEdges?.timestamp - Number(selectedEdge?.timestamp) < 600000
        ) {
          onlineArray.push(`edgeId:${selectedEdge.edgeId}`);
        } else if (
          selectedEdge?.status === PulsationModels.ComponentStatus.Offline ||
          !allEdges?.timestamp
          // || allEdges?.timestamp - Number(selectedEdge?.timestamp) > 600000
        ) {
          offlineArray.push(`edgeId:${selectedEdge.edgeId}`);
        } else {
          unhealthyArray.push(`edgeId:${selectedEdge.edgeId}`);
        }

        if (!!cameras) {
          for(let cameraId of Object.keys(cameras)) {
            const camera = allCamerasList[cameraId];
            if ((camera?.status === PulsationModels.ComponentStatus.Online || camera?.status === PulsationModels.ComponentStatus.Streaming) &&
              allCameras?.timestamp
              // && allCameras?.timestamp - Number(camera?.timestamp) < 600000
            ) {
              onlineArray.push(`cameraId:${cameraId}`);
            } else if (camera?.status === PulsationModels.ComponentStatus.Offline ||
              !allCameras?.timestamp
              // || allCameras?.timestamp - Number(camera?.timestamp) > 600000
            ) {
              offlineArray.push(`cameraId:${cameraId}`);
            } else {
              unhealthyArray.push(`cameraId:${cameraId}`);
            }

          }
        }

      });

    if (offlineArray?.filter(item => item.includes('edgeId:'))?.length === edgesCount) {
      return PulsationModels.ComponentStatus.Offline;
    }

    // if (!!unhealthyArray?.length) {
    //   return PulsationModels.ComponentStatus.Unhealthy;
    // }
    //
    // if (!!onlineArray?.length && !!offlineArray?.length) {
    //   return PulsationModels.ComponentStatus.Unhealthy;
    // }

    if (!!onlineArray?.length && !offlineArray?.length) {
      return PulsationModels.ComponentStatus.Online;
    }

    if (!onlineArray?.length && !!offlineArray?.length) {
      return PulsationModels.ComponentStatus.Offline;
    }

    return undefined;


  }
}
