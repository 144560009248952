import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-failed-dialog',
  templateUrl: './update-failed-dialog.component.html',
  styleUrls: ['./update-failed-dialog.component.scss']
})
export class UpdateFailedDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
}
