import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UiAutocompleteTemplate, UIInputStyle } from '@enums/shared.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-search',
  templateUrl: './ui-search.component.html',
  styleUrls: ['./ui-search.component.scss'],
})
export class UiSearchComponent implements OnInit {

  @ViewChild('inputElement') inputElement: ElementRef;

  @Input() placeholder: string = '';
  @Input() autocompleteEnabled: boolean = false;
  @Input() autocompleteOptions: Observable<any[]>;
  @Input() template: UiAutocompleteTemplate = UiAutocompleteTemplate.string;
  public inputStyle = UIInputStyle;
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public searchQuery: string;

  @Input()
  public heightPx = 32;

  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

  @Input() noFocus: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public change(ev: string) {
    this.onInput.emit(ev);
  }

  public clear() {
    this.searchQuery = '';
    this.onInput.emit('');
  }

  public focus() {
    this.inputElement.nativeElement.focus();
  }
}
