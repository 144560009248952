import { UserSettings } from '@models/user-settings';
import { HoursFormat } from '@enums/user-settings.enum';
import { UiUserSettingsDateFormat } from '@enums/shared.enum';

export const initialUserSettings: UserSettings = {
  dateFormat: UiUserSettingsDateFormat.MMddyy,
  hoursFormat: HoursFormat.hours12,
  autoLogout: 60, //value in minutes
  autoLogoutEnabled: false,
  darkTheme: false,
  alertMonitoringMuted: true,
};

export const uiUserSettingsDateFormatMap: { [Property in UiUserSettingsDateFormat]: string } = {
  [UiUserSettingsDateFormat.MMddyy]: 'MM/dd/yy',
  [UiUserSettingsDateFormat.ddMMyy]: 'dd/MM/yy',
};
