<div class="flex-col start-start dialog flex-fill">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Upload image</div>
    <div class="center-center c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="flex-row start-start flex dialog-content">
    {{ imageError }}
    <div *ngIf="!selected" class="drag-drop-wrapper" fxLayout="column" fxLayoutAlign="center center"
         fxLayoutGap="16px" [class.error-msg]="!!uploadErrorMsg"
         appDragDropFileUpload (fileDropped)="onFileDrop($event)"
    >
      <div fxLayoutAlign="center center" class="drag-drop-icon icon-16">
        <mat-icon svgIcon="add-image"></mat-icon>
      </div>
      <h2>Drag and drop</h2>
      <div class="drag-drop-description">
        {{ uploadErrorMsg ? uploadErrorMsg : 'Accepted file types are: png, jpg.' }}
      </div>
      <ui-button type="primary" [text]="uploadErrorMsg ? 'Upload different images' : 'Or upload from your computer'"
                 (clickAction)="fileInput.click()"
      ></ui-button>
      <div>
        <input #fileInput type="file" hidden multiple name="files" (change)="onFileChange($event)"/>
        <!-- <ui-upload #uploadComponent (clickUpload)="upload($event)" [isUploading]="selectIsSaving$ | ngrxPush"></ui-upload> -->
      </div>
    </div>
    <div *ngIf="selected" fxLayout="column" fxLayoutAlign="start center" class="file-list-wrapper p-r">
      <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="loader-wrapper">
        <ui-preloader [color]="PreloaderColor.gray" [diameter]="80">{{ progress }}%</ui-preloader>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngFor="let file of selectedFiles"
           class="w-100"
      >
        <div class="icon-16" fxLayoutAlign="center center">
          <mat-icon svgIcon="images"></mat-icon>
        </div>
        <div>{{ file.name }}</div>
      </div>
    </div>
  </div>
  <div class="flex-row end-center flex" fxLayoutGap="10px">
    <ui-button type="primary"
               [disabled]="!selectedFiles?.length"
               (clickAction)="save()"
    >Add
    </ui-button>
  </div>

</div>

