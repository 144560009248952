export const routerSegments = {
  cameraEdit: 'camera-edit',
  main: 'main',
  searchZones: 'search-zones',
  privacy: 'privacy',
  zones: 'zones',
  network: 'network',
  alertEventsMonitoring: 'alert-event-monitoring',
  view: 'view',
  multiSearch: 'search',
  trackObject: 'track',
  assets: 'assets',
  details: 'details',
  videoAudio: 'video-audio',
  hlsManagement: 'hls-management',
  ptz: 'ptz',
  muxer: 'muxer',
  edgeEdit: 'edge-edit',
  configuration: 'configuration',
  configurations: 'configurations',
  swUpdate: 'sw-update',
  data: 'data',
  uploadConfiguration: 'upload-configuration',
  document: 'document',
  info: 'info',
  management: 'management',
  create: 'create',
  edit: 'edit',
  logs: 'logs',
  searchSettings: 'search-settings',
  storageSettings: 'storage-settings',
  thumbnailsBitmap: 'thumbnails-bitmap',
  lineCrossing: 'line-crossing',
  trafficControl: 'traffic-control',
  settings: 'settings',
  notifications: 'notifications',
  alerts: 'alerts',
  alertsV2: 'alert',
  variables: 'variables',
  selectOrganization: 'select-organization',
  noAuth: 'no-auth',
  organization: 'organization',
  organizations: 'organizations',
  uploadVideoStorage: 'upload-video-storage',
  archive: 'archive',
  grantedAccess: 'granted-access',
  uptime: 'uptime',
  analyticConfiguration: 'analytic-configuration',
  actions: 'actions',
  account: 'account',
  security: 'security',
  auditLog: 'audit-logs',
  auditReport: 'audit-reports',
  devTeam: 'dev-team',
  users: 'users',
  mainSettings: 'main-settings',
  dashboards: 'dashboards',
  alertDetails: 'alert-details',
  selectAlert: 'select-alert',
  cameras: 'cameras',
  variableCameras: 'variable-cameras',
  objects: 'objects',
  logins: 'logins',
  method: 'method',
  camera: 'camera',
  location: 'location',
  page: 'page',
  configure: 'configure',
  uploadFile: 'upload-file',
  previewData: 'preview-data',
  import: 'import',
  dashboardHealth: 'dashboard-health',
  search: 'search',
  saved: 'saved',
  notFound: 'not-found',
  monitoring: 'monitoring',
  viewList: 'view-list',
  pricing: 'pricing',
  list: 'list',
  walls: 'walls',
  logout: 'logout',
  terms: 'terms',
  emailVerification: 'email-verification',
  templates: 'templates',
  scan: 'scan',
  multiple: 'multiple',
  people: 'people',
  pulsationState: 'pulsation-state',
  iconsPreview: 'icons-preview',
  apiSettings: 'api-settings',
  terminal: 'terminal',
  speakers: 'speakers',
  editLocation: 'edit-location',
  addSpeaker: 'add-speaker',
  editSpeaker: 'edit-speaker',
  locationV2: 'location',
  cameraView: 'camera-view',
  liveView: 'live-view',
  sharing: 'sharing',
  database: 'database',
  operator: 'operator',
  whitelist: 'whitelist',
  alertLogs: 'alert-logs',
  doors: 'doors',
  errorReport: 'error-report',
  ntp: 'ntp',
  changePassword: 'change-password',
  resetPassword: 'reset-password',
  auth: 'auth',
  coreInfo: 'core-info',
  eventTypes: 'event-types',
  testCamera: 'test-camera',
  groups: 'groups',
  permissions: 'permissions',
  recovery: 'recovery',
  heartBeatStatus: 'heartbeat-status',
  vehicles: 'vehicles',
  roles: 'roles',
  userPermissions: 'user-permissions',
  loginFirewall: 'login-firewall',
  public: 'public',
  downloadApp: 'download-app',
  sharedSearch: 'shared-search',
  certifications: 'certifications',
  syncQueue: 'sync-queue',
  login: 'login',
  signUp: 'sign-up',
  identities: 'identities',
  help: 'help',
  sections: 'sections',
  home: 'home',
  support: 'support',
  appleTV: 'apple-tv',
  integrations: 'integrations',
  alertVideo: 'alert-video',
  devices: 'devices',
  mfa: 'mfa',
  webhooks: 'webhooks',
  floors: 'floors',
  cameraLocation: 'camera-location',
  dhcp: 'dhcp',
  map: 'map',
  externalStorage: 'external-storage',
  wallV2: 'wall-v2',
  openMap: 'open-map',
  youtube: 'youtube',
  display: 'display',
  quota: 'quota',
  genea: 'genea',
  kisi: 'kisi',
  shortcut: 'shortcut',
  alarms: 'alarms',
  schedule: 'schedule',
  connectionTest: 'connection-test',
  grafana: 'grafana',
  entrances: 'entrances',
  reports: 'reports',
  sensors: 'sensors',
};

export const PublicRoutesRedirect = [
  routerSegments.grantedAccess,
  routerSegments.terms,
  routerSegments.privacy,
  routerSegments.emailVerification,
  routerSegments.notFound,
  routerSegments.noAuth,
  routerSegments.auth,
  routerSegments.public,
  routerSegments.alertVideo,
];

