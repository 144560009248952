import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { map, Observable } from 'rxjs';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { routerSegments } from '@consts/routes';

export const isDefaultOrgGuard: CanActivateFn = (route, state) => {
  const store$: Store<AppState> = inject(Store<AppState>);
  const selectIsDefaultOrganization$: Observable<boolean> = store$.pipe(select(OrganizationSelectors.selectIsDefaultOrganization));
  const router: Router = inject(Router);

  return selectIsDefaultOrganization$.pipe(
    map((res: boolean) => {
      if (!res) {
        router.navigate([routerSegments.home]);
      }
      return res;
    }),
  );
};
