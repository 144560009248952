import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';
import { DayOfWeek } from '@enums/shared.enum';
import {
  settingScheduleAllDayFrom,
  settingScheduleAllDayTo,
  settingScheduleDefaultFrom,
  settingScheduleDefaultTo,
} from '@consts/alert-events.const';

export const selectAlertEventsState = createFeatureSelector<AlertEventsState>('alertEventsState');

export const selectSelectedCamera = createSelector(selectAlertEventsState, ({ selectedCamera }: AlertEventsState) => selectedCamera);

export const selectTrackedObject = createSelector(selectAlertEventsState, ({ trackedObject }: AlertEventsState) => trackedObject);

export const selectConfigurationFilter = createSelector(
  selectAlertEventsState,
  ({ configurationFilterType }: AlertEventsState) => configurationFilterType
);

export const selectZones = createSelector(selectAlertEventsState, ({ zones }: AlertEventsState) => zones);
export const selectMultipleObjects = createSelector(selectAlertEventsState, ({ multipleObjects }: AlertEventsState) => multipleObjects);
export const selectOffenderObject = createSelector(selectAlertEventsState, ({ offenderObject }: AlertEventsState) => offenderObject);

export const selectDefinedZones = createSelector(selectAlertEventsState, ({ definedZones }: AlertEventsState) => definedZones);

export const selectMeasureCrossZones = createSelector(
  selectAlertEventsState,
  ({ measureCrossZones }: AlertEventsState) => measureCrossZones
);

export const selectMarkedIdx = createSelector(selectAlertEventsState, ({ markedIdx }: AlertEventsState) => markedIdx);

export const selectSelectedAlertType = createSelector(
  selectAlertEventsState,
  ({ selectedAlertType }: AlertEventsState) => selectedAlertType
);

export const selectDetectionType = createSelector(selectAlertEventsState, ({ detectionType }: AlertEventsState) => detectionType);

export const selectNotifications = createSelector(selectAlertEventsState, ({ notifications }: AlertEventsState) => notifications);

export const selectAlertName = createSelector(selectAlertEventsState, ({ alertName }: AlertEventsState) => alertName);

export const selectGenderType = createSelector(selectAlertEventsState, ({ genderType }: AlertEventsState) => genderType);

export const selectAgeType = createSelector(selectAlertEventsState, ({ ageType }: AlertEventsState) => ageType);

export const selectFootWearType = createSelector(selectAlertEventsState, ({ footWearType }: AlertEventsState) => footWearType);
export const selectFootWearColor = createSelector(selectAlertEventsState, ({ footWearColor }: AlertEventsState) => footWearColor);

export const selectLowerBodyType = createSelector(selectAlertEventsState, ({ lowerBodyType }: AlertEventsState) => lowerBodyType);

export const selectLowerBodyColor = createSelector(selectAlertEventsState, ({ lowerBodyColor }: AlertEventsState) => lowerBodyColor);

export const selectUpperBodyType = createSelector(selectAlertEventsState, ({ upperBodyType }: AlertEventsState) => upperBodyType);

export const selectUpperBodyColor = createSelector(selectAlertEventsState, ({ upperBodyColor }: AlertEventsState) => upperBodyColor);

export const selectHairType = createSelector(selectAlertEventsState, ({ hairType }: AlertEventsState) => hairType);

export const selectHairColor = createSelector(selectAlertEventsState, ({ hairColor }: AlertEventsState) => hairColor);

export const selectAccessoryType = createSelector(selectAlertEventsState, ({ accessoryType }: AlertEventsState) => accessoryType);

export const selectCarryingType = createSelector(selectAlertEventsState, ({ carryingType }: AlertEventsState) => carryingType);

export const selectLevel2MenuValid = createSelector(selectAlertEventsState, ({ level2MenuValid }: AlertEventsState) => level2MenuValid);

export const selectSettings = createSelector(selectAlertEventsState, ({ settings }: AlertEventsState) => settings);

export const selectSettingsSchedule = createSelector(selectAlertEventsState, ({ settings }: AlertEventsState) => {
  const scheduleKeys = Object.keys(settings.schedule);
  return scheduleKeys.map(key => {
    const schedule = settings.schedule[key];
    if (schedule) {
      const allDay = schedule.from === settingScheduleAllDayFrom && schedule.to === settingScheduleAllDayTo;
      return {
        day: DayOfWeek[key],
        allDay: allDay,
        from: schedule.from,
        to: schedule.to,
        disabled: false,
      };
    } else {
      return {
        day: DayOfWeek[key],
        allDay: false,
        from: settingScheduleDefaultFrom,
        to: settingScheduleDefaultTo,
        disabled: true,
      };
    }
  });
});

export const selectAlertEvents = createSelector(selectAlertEventsState, ({ alertEvents }: AlertEventsState) => alertEvents);

export const selectCarColor = createSelector(selectAlertEventsState, ({ carColor }: AlertEventsState) => carColor);

export const selectCarType = createSelector(selectAlertEventsState, ({ carType }: AlertEventsState) => carType);

export const selectCarMake = createSelector(selectAlertEventsState, ({ carMake }: AlertEventsState) => carMake);

export const selectCarModel = createSelector(selectAlertEventsState, ({ carModel }: AlertEventsState) => carModel);

export const selectLineCrossing = createSelector(selectAlertEventsState, ({ lineCrossing }: AlertEventsState) => lineCrossing);

export const selectTrafficControl = createSelector(selectAlertEventsState, ({ trafficControl }: AlertEventsState) => trafficControl);

export const selectTotalItemsCount = createSelector(selectAlertEventsState, ({ totalItemsCount }: AlertEventsState) => totalItemsCount);

export const selectPage = createSelector(selectAlertEventsState, ({ page }: AlertEventsState) => {
  return page;
});

export const selectPerPage = createSelector(selectAlertEventsState, ({ perPage }: AlertEventsState) => perPage);

export const selectSearchQuery = createSelector(selectAlertEventsState, ({ query }: AlertEventsState) => query);

export const selectSelectedAlertEvent = createSelector(
  selectAlertEventsState,
  ({ selectedAlertEvent }: AlertEventsState) => selectedAlertEvent
);

export const selectWhiteList = createSelector(selectAlertEventsState, ({ greenList }: AlertEventsState) => greenList);

export const selectBlackList = createSelector(selectAlertEventsState, ({ redList }: AlertEventsState) => redList);

export const selectUnrecognized = createSelector(selectAlertEventsState, ({ unrecognized }: AlertEventsState) => unrecognized);

export const selectDetectionAdditionalAttributes = createSelector(
  selectAlertEventsState,
  ({ detectionAdditionalAttributes }: AlertEventsState) => detectionAdditionalAttributes
);

export const selectTimezone = createSelector(selectAlertEventsState, ({ timezone }: AlertEventsState) => timezone);

export const selectTresholdTime = createSelector(selectAlertEventsState, ({ tresholdTime }: AlertEventsState) => tresholdTime);

export const selectDetectionTypeAndAlertType = createSelector(
  selectAlertEventsState,
  ({ trackedObject, detectionType, selectedAlertType }: AlertEventsState) => {
    return {
      trackedObject,
      detectionType,
      selectedAlertType,
    };
  }
);

export const selectActions = createSelector(selectAlertEventsState, ({ actions }: AlertEventsState) => actions);

export const selectMultiCameras = createSelector(selectAlertEventsState, ({ multiCameras }: AlertEventsState) => multiCameras);
